import configs from "config";

export const stripStart = (str: string, key: string) => {
  if (!str) return str;
  if (str.startsWith(key)) {
    return str.slice(key.length);
  }
  return str;
};

const httpWrap = (base: string, url: string) => {
  if (String(url).startsWith("http")) return url;
  return `${base}/${stripStart(url, "/")}`;
};

const baseWrap = (base: string, url: string) => {
  if (String(url).startsWith(base)) return url;
  if (String(url).startsWith("http")) return httpWrap(base, url);

  return `${base}/${stripStart(url, "/")}`;
};

export const wrapBaseApi = (url: string) => {
  try {
    let useAsIs = false;
    const apiBase = configs.BASE_URL;
    if (useAsIs) return baseWrap(apiBase, url);
    else {
      const walletApiBase = configs.WALLET_BASE_URL;
      const p2pApiBase = configs.P2P_BASE_URL;

      if (String(url).startsWith("p2p/") || String(url).startsWith("/p2p/")) return baseWrap(p2pApiBase, url);
      if (String(url).startsWith("/wallet/")) return baseWrap(walletApiBase, stripStart(url, "/wallet/"));
      if (String(url).startsWith("wallet/")) return baseWrap(walletApiBase, stripStart(url, "wallet/"));

      const isWalletApi = String(url).includes(`${apiBase}/wallet/`);
      const isP2PApi = String(url).includes(`${apiBase}/p2p/`);

      if (isP2PApi) return String(url).replace(apiBase, p2pApiBase);
      if (isWalletApi) return String(url).replace(`${apiBase}/wallet`, walletApiBase);
    }
    return httpWrap(apiBase, url);
  } catch (err) {
    return url;
  }
};

export const apiWrapper = (url: string) => {
  return wrapBaseApi(url);
};
