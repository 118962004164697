import {
  Box,
  Button,
  FormControl,
  Heading,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { ConditionalRender, InputLabel, Uploader, useModalView } from "components";
import { format } from "date-fns";
import { useSelector } from "hooks";
import { VerificationForm } from "interfaces";
import { nanoid } from "nanoid";
import { useMemo, useState } from "react";
import { selectUser } from "store/slices";
import { switchStyle, uploadFile } from "utils";

import Level2VerificationIllustration from "assets/images/lv2-verification-illustration.png";
import { UploadResponse } from "react-aws-s3-typescript/dist/types";

export function StepTwoNigerian(props: VerificationForm) {
  const { formState, set } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onNext, onPrev } = useModalView();
  const nanoId = nanoid(10);

  const file = formState?.idFile;
  const files = [file!].filter(Boolean);

  const back_file = formState?.backPageIdFile;
  const back_files = [back_file!].filter(Boolean);

  // const { colorMode } = useColorMode();

  // const { profile } = useSelector(selectUser);

  // const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const collectBackPage = useMemo(
    () => ["primary-national_id", "primary-driver_licence"].includes(formState?.docType!),
    [formState]
  );

  const handleFile = async () => {
    if (!file) return;

    const to_run: Promise<UploadResponse>[] = [uploadFile(file!, `front-page-id-docs-${nanoId}-${Date.now()}`)];
    if (!!back_file) to_run.push(uploadFile(back_file!, `back-page-id-docs-${nanoId}-${Date.now()}`));

    setLoading(true);
    // const uploadedFile = await uploadFile(file!, `id-docs-${nanoId}`);
    const [front_page, back_page] = await Promise.allSettled(to_run);

    setLoading(false);

    console.log("Upload results", { front_page, back_page });

    if (front_page.status === "fulfilled" /*uploadedFile.location*/) {
      formState.docLink = front_page.value.location;
      formState.rawDocLink = front_page.value.location;

      if (back_page && back_page.status === "fulfilled") {
        formState.backDocLink = back_page.value.location;
      }

      console.log("StepTwoA Form State", formState);
      onNext();
    }
  };

  return (
    <Stack p="8px">
      <Stack my="38px !important">
        <Heading as="h6" fontSize="sm">
          Step 2/3 - ID Card Upload
        </Heading>

        <Box mt="24px !important">
          <Text fontSize="sm" fontWeight="600">
            Instructions:
          </Text>
          <UnorderedList mt="12px">
            <ListItem fontSize="14px" fontWeight="500">
              Upload a clear picture of your ID below
            </ListItem>
          </UnorderedList>
        </Box>
      </Stack>

      {/* <VStack mb="28px !important">
        <Box w="100%" maxH="160px" borderRadius="8px" overflow="hidden">
          <Image src={Level2VerificationIllustration} alt="Level 2 Verification Illustration" />
        </Box>
        <Text fontWeight="500" color={switchStyle(colorMode, { light: "grey.300", dark: "grey.300" })} fontSize="xs">
          Recommended Upload
        </Text>
      </VStack> */}

      <FormControl mb="32px !important">
        {!!collectBackPage && <InputLabel>Front Page</InputLabel>}
        <Uploader files={files} onFiles={(files) => set({ idFile: files[0] })} />
      </FormControl>

      <ConditionalRender shouldRender={collectBackPage}>
        <FormControl mb="32px !important">
          <InputLabel>Back Page</InputLabel>
          <Uploader files={back_files} onFiles={(files) => set({ backPageIdFile: files[0] })} />
        </FormControl>
      </ConditionalRender>

      <VStack mt="34px !important" px="46px" w="100%">
        <Button
          minW="unset"
          w="100%"
          onClick={handleFile}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={loading}
          disabled={loading || !file}
        >
          Next
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" disabled={loading} onClick={onPrev}>
          Back
        </Button>
      </VStack>
    </Stack>
  );
}

export function StepTwoOthers(props: VerificationForm) {
  const { formState } = props;
  const { onNext, onPrev } = useModalView();
  const nanoId = nanoid(10);

  const { colorMode } = useColorMode();

  const { profile } = useSelector(selectUser);

  const emailLabel = useMemo(() => profile?.email ?? "youremail@smtin.com", [profile]);

  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const handleFile = async () => {
    setLoading(true);
    const uploadedFile = await uploadFile(file!, `id-docs-${nanoId}`);

    setLoading(false);

    if (uploadedFile.location) {
      formState.docLink = uploadedFile.location;
      formState.rawDocLink = uploadedFile.location;
      onNext();
    }
  };

  return (
    <Stack p="8px">
      <Stack my="38px !important">
        <Heading as="h6" fontSize="sm">
          Step 2/3 - ID Card Upload
        </Heading>

        <Box mt="16px">
          <Text fontSize="sm" fontWeight="600">
            Instructions:
          </Text>
          <UnorderedList mt="2px">
            <ListItem fontSize="14px" fontWeight="500">
              Place your ID card on a plain sheet of paper and write this at the top of the paper with the accurate information:
              <Box mt="28px">
                <Text fontSize="sm" fontWeight="600">
                  BITMAMA VERIFICATION
                </Text>
                <Text fontSize="sm" fontWeight="600">
                  EMAIL: &nbsp; {emailLabel}
                </Text>
                <Text fontSize="sm" fontWeight="600">
                  Date: &nbsp; {format(new Date(), "eee dd, MMM yyyy")}
                </Text>
              </Box>
            </ListItem>
          </UnorderedList>
          <Text mt="20px" fontSize="14px" fontWeight="500">
            Please note that the date has to be the current date for when you complete the level 2 verification
          </Text>
        </Box>
      </Stack>

      <VStack mb="28px !important">
        <Box w="100%" maxH="160px" borderRadius="8px" overflow="hidden">
          <Image src={Level2VerificationIllustration} alt="Level 2 Verification Illustration" />
        </Box>
        <Text fontWeight="500" color={switchStyle(colorMode, { light: "grey.300", dark: "grey.300" })} fontSize="xs">
          Recommended Upload
        </Text>
      </VStack>

      <FormControl mb="32px !important">
        <Uploader onFiles={(files) => setFile(files[0])} />
      </FormControl>

      <VStack mt="34px !important" px="46px" w="100%">
        <Button
          minW="unset"
          w="100%"
          onClick={handleFile}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={loading}
          disabled={loading || !file}
        >
          Next
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" disabled={loading} onClick={onPrev}>
          Back
        </Button>
      </VStack>
    </Stack>
  );
}
