import { Box, Button, Heading, Image, ModalProps, Text, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useGetCardWishlistEligibilityQuery } from "apis";
import ValImage from "assets/images/valentine-balloons.png";
import { AbstractModal } from "components";
import configs from "config";
import { useColor } from "hooks";
import ls from "utils/secureStorage";

const patternUrl: string = require("../../../assets/svg/xmaspattern.svg").default;
const happy_val_text: string = require("../../../assets/svg/happy-valentines-day.svg").default;

interface Props extends Omit<ModalProps, "children"> {}

// @Dayo, pls fix this * (Fixed) temporarily observing the behavious.
export function ValentineUpdate(props: Props) {
  const { isOpen, onClose } = props;

  const color = useColor();
  //   const { shadow } = useDefaultStyle();
  //   const [dontShow, SetDontShow] = useState(false);
  const { data } = useGetCardWishlistEligibilityQuery("fund");

  const handleClose = () => {
    onClose();
    // if (!!dontShow)
    ls.set(configs.XMAS_DONT_SHOW, { cycle: data?.cycle });
  };

  const goToCard = () => {
    handleClose();
    navigate(`/cards`);
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "540px",
        // borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        borderRadius: "0px",
        position: { base: "absolute", "1sm": "initial" },
        padding: "20px",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
        overflow: "hidden",
        bg: `url("${patternUrl}")`,
        bgColor: "primary.default",
        backgroundSize: "cover",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <Box minH="570px" bg={color("white", "dark.bg")} borderRadius={{ base: "30px 30px 0px 0px", "1sm": "14px" }}>
        <VStack p="30px">
          <VStack w="100%" bg="linear-gradient(180deg, #FFC5D0 0%, #F79CAD 100%)" h="230px" borderRadius="12px" overflow="hidden">
            <Image
              mt="20px"
              src={ValImage}
              alt="christmas image"
              borderRadius="14px"
              h="100%"
              overflow="hidden"
              objectFit="contain"
            />
          </VStack>

          <VStack py="16px">
            <Image src={happy_val_text} alt="happy val text" borderRadius="14px" overflow="hidden" objectFit="contain" />
            <Heading
              as="h6"
              my="10px"
              fontSize="14px"
              fontWeight="300"
              textTransform="uppercase"
              textAlign="center"
              color="#7D5A60"
            >
              IT’S THE SEASON OF LOVE
            </Heading>

            <Box my="40px">
              <Text w="248px" textAlign="center" fontWeight="500" fontSize="16px" color={color("#462F32", "white")}>
                Create or fund a card to stand a chance to win a gift
              </Text>
            </Box>
          </VStack>

          <VStack mt="12px !important">
            <Button
              minW="321px"
              maxW="321px"
              h="74px"
              onClick={goToCard}
              bg="#FF406E"
              _hover={{ bg: "#FF406E" }}
              _active={{ bg: "#FF406E" }}
            >
              Let's Go
            </Button>
            <Button
              minW="unset"
              minH="unset"
              maxW="fit-content"
              maxH="fit-content"
              h="fit-content"
              variant="outline"
              mt="16px !important"
              onClick={handleClose}
            >
              Close
            </Button>
          </VStack>

          {/* <VStack>
            <Checkbox
              // dir="rtl"
              colorScheme="primary"
              isChecked={dontShow}
              onChange={(e) => SetDontShow(e.target.checked)}
              sx={{
                "chakra-checkbox__control[data-focus]": {
                  shadow,
                },
              }}
            >
              Don't show again
            </Checkbox>
          </VStack> */}
        </VStack>
      </Box>
    </AbstractModal>
  );
}

// export function ChristmasUpdate(_props: Props) {
//   return null;
// }
