import { Box, Button, FormControl, Heading, HStack, Input, Stack, VStack } from "@chakra-ui/react";
import { InputLabel, Option, RadixSelect } from "components";
import configs from "config";
import { toCoinLocale, when } from "utils";
import { IStateExtension } from "./Add";
import { useMemo } from "react";
import { capitalize } from "lodash";
import { useDisclosures } from "hooks";
import { SuccessModal } from "ui";
import { PriceAlertOrderPreview } from "ui/Account/Modals/CryptoAlert/PriceAlertOrderPreview";

interface TradeOnAlertFormProps extends IStateExtension {}

type ModalTypes = "success" | "preview";
export function TradeOnAlertForm(props: TradeOnAlertFormProps) {
  const { state, set } = props;
  //   const toast = useToast();

  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  console.log("State", state);

  // const [_createOrder, { isLoading }] = useCreatePriceAlertOrderMutation();

  // const { data: coinData } = useGetRateQuery(`${state?.coin}ngn`, { skip: !state?.coin });

  // const actionType = useMemo(() => when(!!state?.direction && state?.direction === "up", "sell", "buy"), [state?.direction]);
  const actionType = useMemo(() => state?.scheduleType ?? "buy", [state?.scheduleType]);
  const actionVerb = useMemo(() => when(actionType === "sell", "sold", "bought"), [actionType]);
  // const orderField = useMemo(() => when(actionType === "sell", "cryptoAmount", "fiatAmount"), [actionType]);

  // const current_fiat_market_price: number = useMemo(
  //   () => (coinData?.exchangeRate ?? {})[actionType] ?? 0,
  //   [actionType, coinData?.exchangeRate]
  // );
  // console.log("Rate ", coinData, current_fiat_market_price);

  // const createOrder = async () => {
  //   const amount = when(
  //     actionType === "sell",
  //     +(state?._temp_coin_amount ?? 0),
  //     +(state?._temp_coin_amount ?? 0) * current_fiat_market_price
  //   );

  //   const response: any = await _createOrder({
  //     ...(state as NonNullable<createPriceAlertOrderDto>),
  //     orderType: actionType,
  //     [orderField]: amount,
  //   });

  //   if (!!response) {
  //     open("success")();
  //     reset();
  //     navigate(-1);
  //   }
  // };

  // console.log(createOrder);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    open("preview")();

    // if (response.data) {
    //   toast({
    //     title: "Success",
    //     status: "success",
    //     duration: 4000,
    //     description: response.data.message,
    //     isClosable: true,
    //     position: "bottom-right",
    //   });

    //   navigate("/account/pricealerts");
    // }
  };

  const isDisabled = useMemo(() => !(state?.coin && state?._temp_coin_amount && state?.spotPrice && state?.coin), [state]);

  return (
    <Stack my="18px" px="3px">
      <HStack mb="36px">
        <Box>
          <Heading as="h4" fontSize="16px">
            {capitalize(actionType)} at this price
          </Heading>
        </Box>
      </HStack>

      <Stack as="form" onSubmit={handleSubmit}>
        <FormControl mb="28px !important">
          <InputLabel htmlFor="coin">Coin</InputLabel>

          <RadixSelect
            use_coin_icon
            value={state?.coin}
            aria-label="Wallet"
            placeholder="Select Wallet"
            onChange={(value) => set({ coin: value })}
            disabled
            _trigger={{
              w: "100%",
            }}
          >
            {configs.priceAlertCoins.map((coin) => (
              <Option key={coin} value={coin} coin={coin as any}>
                {toCoinLocale(coin)}
              </Option>
            ))}
          </RadixSelect>
        </FormControl>

        <FormControl mb="28px !important" display="none">
          <InputLabel htmlFor="price">Spot Price (Price your coin will be {actionVerb} at)</InputLabel>
          <Input
            step="any"
            type="number"
            // placeholder={`Enter amount in ${payload?.type ?? "..."}`}
            id="price"
            name="price"
            value={state?.spotPrice}
            disabled
            // onChange={(e) => set({ price: e.target.value })}
            isRequired
          />
        </FormControl>

        <FormControl mb="28px !important">
          <InputLabel htmlFor="price">
            Amount of {toCoinLocale(state?.coin ?? "btc")} to be {actionVerb}
          </InputLabel>
          <Input
            step="any"
            type="number"
            // placeholder={`Enter amount in ${payload?.type ?? "..."}`}
            id="price"
            name="price"
            value={state?._temp_coin_amount}
            onChange={(e) => set({ _temp_coin_amount: e.target.value })}
            isRequired
          />
          {/* {coin && (
            <Text as="span" display="block" mt="8px">
              Current price in USD: {Number(coinData?.exchangeRate.buy ?? 0).toFixed(2)} USD
            </Text>
          )} */}
        </FormControl>

        {/* <FormControl mb="28px !important">
          <InputLabel htmlFor="price">Transaction price</InputLabel>
          <Input
            step="any"
            type="number"
            // placeholder={`Enter amount in ${payload?.type ?? "..."}`}
            id="price"
            name="price"
            value={state?.price}
            onChange={(e) => set({ price: e.target.value })}
            isRequired
          />
        </FormControl> */}

        <VStack mt="40px !important" fontFamily="var(--bitmama-fonts-heading)">
          <Button
            // isLoading={isLoading}
            // disabled={!coin || !direction || !price || isLoading}
            isDisabled={isDisabled}
            type="submit"
            minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
            // onClick={open('preview')}
          >
            Proceed
          </Button>
        </VStack>
      </Stack>

      <PriceAlertOrderPreview isOpen={isOpen("preview")} onClose={close("preview")} {...props} />

      <SuccessModal
        isOpen={isOpen("success")}
        onClose={close("success")}
        message={`Your price and ${actionType}ing alert for ${state?.coin} have been set`}
      />
    </Stack>
  );
}
