import configs from "config";
import { IUrchinTrackingModule } from "interfaces/apiResponse.interface";

import ls from "utils/secureStorage";

export const getUTM = (): IUrchinTrackingModule | null => {
  let utm = ls.get(configs.UTM_KEY);
  if (!!utm) utm = JSON.parse(utm);
  else utm = null;

  if (!!utm && !!utm?.exp && Date.now() >= utm?.exp) return null;
  return utm;
};

export const storeUTM = (dto: Omit<IUrchinTrackingModule, "exp">) => {
  const current_time = Date.now();
  let utm = getUTM();

  if (!!utm && !!utm?.exp && current_time >= utm?.exp) return;
  const exp = Date.now() + 60 * 60 * 1000;
  ls.set("utm", JSON.stringify({ ...dto, exp }));
};
