import { Box, Button, HStack, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { AddButton, Icon, MainLayoutContainer, PageMotion, Span, StakeInfo, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { PageProps } from "interfaces";

import CoinEarningsPNG from "assets/images/coin-earnings.png";
import { toCoinLocale } from "utils";
import { useBuiltInFn } from "hooks";

interface EarningsPageProps extends PageProps {}

export default function EarningsPage(props: EarningsPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  const handleBack = () => {
    // navigate("/cards");
    goBack();
  };

  return (
    <PageMotion key="Earnings">
      <Topbar pageTitle="" {...props} />

      <MainLayoutContainer m="0 auto" pb="60px">
        <VStack alignItems="flex-start">
          <SidePageProvider>
            <Box mb="42px">
              <Button
                size="sm"
                minW="fit-content"
                maxW="fit-content"
                variant="link"
                leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                onClick={handleBack}
                px="2px !important"
              >
                Back
              </Button>

              <HStack justifyContent="space-between">
                <Text mt="12px" as="h4" fontSize="18px" fontWeight="bold" fontFamily="var(--bitmama-fonts-heading)">
                  Coins Available
                </Text>

                <AddButton size="xs" onClick={() => navigate(`/earn/staking/subscribe`)}>
                  Subscribe
                </AddButton>
              </HStack>
            </Box>

            <Box
              bg={`linear-gradient(0deg, rgba(4, 24, 21, 0.28), rgba(4, 24, 21, 0.28)), url(${CoinEarningsPNG})`}
              borderRadius="24px"
              minW="100%"
              minH="280px"
              p="52px 36px"
              color="white"
              fontStyle="italic"
              fontWeight="600"
              textTransform="uppercase"
            >
              <Text fontSize="18px">
                <Span color="#E8C229">Earn more</Span> by holding your
              </Text>
              <Text fontSize="18px">Crypto coins over time</Text>
            </Box>

            <Stack mt="56px !important" gridGap="8px">
              {["tbtc", "eth", "usdt", "celo"].map((coin, i) => (
                <StakeInfo
                  key={`${coin}-stake-info-${i}`}
                  coin={coin}
                  title={toCoinLocale(coin)}
                  subtitle={"90 days"}
                  onClick={() => navigate(`/earn/earnings/${coin}`)}
                >
                  20%
                </StakeInfo>
              ))}
            </Stack>
          </SidePageProvider>
        </VStack>
      </MainLayoutContainer>
    </PageMotion>
  );
}
