import { Box, BoxProps, Grid, HStack, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import Card, { CardProps } from "components/Card/Card";
import { useDefaultStyle } from "hooks";
import { toLower } from "lodash";
import { useMemo } from "react";
import { currencyFormat, isFiat, toPrecision, when } from "utils";
import { format, parseISO } from "date-fns";

interface TransactionItemProps extends CardProps {
  title?: string;
  description?: string;
  type?: string;
  createdAt?: string;
  unit?: string;
  use_type?: boolean;
  value?: { $numberDecimal: string };
}

interface IndicatorProps extends BoxProps {
  type?: "credit" | "debit" | any;
}

function Indicator(props: IndicatorProps) {
  const bg = useMemo(() => {
    return (
      {
        credit: "#14c005",
        debit: "red.400",
        approved: "#14c005",
        pending: "#e7c406",
        cancelled: "red.400",
        denied: "red.400",
      } as Record<string, string>
    )[props.type ?? "debit"];
  }, [props.type]);
  return <Box boxSize="14px" borderRadius="50%" bg={bg} />;
}

export default function TransactionItem(props: TransactionItemProps) {
  const { unit, value, title, description, type, createdAt, use_type = true, ...xprops } = props;

  const { shadow } = useDefaultStyle();
  const bg = useColorModeValue("transparent", "transparent");
  const color = useColorModeValue("primary.default", "secondar.default");
  const borderColor = useColorModeValue("grey.100", "#00000026");

  const txType = useMemo(() => when(toLower(type).includes("credit"), "credit", "debit"), [type]);
  const cursor = when(!!props.onClick, "pointer", "default");

  return (
    <Card
      mt="0"
      as="button"
      p={{ base: "9px", "1sm": "12px", "2sm": "12px 16px" }}
      minH="64px"
      shadow="none"
      bg={bg}
      border="0.5px solid transparent"
      borderTopColor={borderColor}
      borderBottomColor={borderColor}
      borderRadius="2px"
      cursor={cursor}
      _focus={{ shadow }}
      {...xprops}
      outline="1px solid transparent"
      sx={{
        ":first-child": {
          borderTopColor: "transparent",
        },
        ":last-child": {
          borderBottomColor: "transparent",
        },
      }}
    >
      <HStack justifyContent="space-between">
        <Grid
          flex={{ base: "0.95", "1sm": ".7" }}
          templateColumns={{ base: "16px 1fr", "2sm": "32px 1fr" }}
          alignItems="center"
          gap="8px"
        >
          <Indicator type={when(!!use_type, txType, type)} />
          <Stack justifyContent="flex-start" alignItems="baseline">
            <Text
              maxW="246px"
              w="100%"
              fontSize={{ base: "12px", "2sm": "14px" }}
              fontWeight="500"
              color={color}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={{ base: "normal", "1sm": "nowrap" }}
              textAlign="left"
            >
              {description ?? "Fund withdrawal"}
            </Text>
            <Text mt="0 !important" fontSize={{ base: "10px", "2sm": "12px" }} fontWeight="500" color="grey.500">
              {(!!createdAt && format(parseISO(createdAt), "eee, dd MMM yyyy, HH:mm")) ?? "Wed, 18 May 2022, 14:36"}
            </Text>
          </Stack>
        </Grid>

        <Text fontSize="14px" fontWeight="500" color={color} margin="0 !important">
          {when(txType === "debit", "-", "")}
          {unit && isFiat(unit) && currencyFormat(unit as any).format(+value?.$numberDecimal! ?? 0)}
          {unit && !isFiat(unit) && toPrecision(+value?.$numberDecimal! ?? 0, unit)}
        </Text>
      </HStack>
    </Card>
  );
}
