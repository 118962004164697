import { Box, BoxProps, HStack } from "@chakra-ui/react";
import InputLabel, { InputLabelProps } from "components/InputLabel/InputLabel";
import { useMemo } from "react";
import { renderComponent } from "utils";

export interface TitleBarProps extends BoxProps {}
export interface TitleProps extends InputLabelProps {}
export interface TitleBarActionProps extends BoxProps {}

type TitleBarComponents = "TitleBarTitle" | "TitleBarActions";

export function Title(props: TitleProps) {
  return <Box {...props}>{props.children}</Box>;
}

export function TitleBarActions(props: TitleBarActionProps) {
  return <Box {...props}>{props.children}</Box>;
}

export default function TitleBar(props: TitleBarProps) {
  const { children, ...xprops } = props;

  //   const children = useMemo(() =>
  //     filterChildren<TitleBarComponents>(childs, ["TitleBarTitle"]), [childs]
  //   );

  const title = useMemo(() => {
    const c = renderComponent<TitleBarComponents>(children, "TitleBarTitle", {
      as: InputLabel,
      colorScheme: "primary",
      fontFamily: "var(--bitmama-fonts-heading)",
      _text: {
        fontWeight: 600,
      },
    });
    return (c ?? [])[0];
  }, [children]);

  const actions = useMemo(() => {
    const c = renderComponent<TitleBarComponents>(children, "TitleBarActions", {
      colorScheme: "primary",
    });
    return (c ?? [])[0];
  }, [children]);

  return (
    <HStack justifyContent="space-between" {...xprops}>
      {title} {actions}
    </HStack>
  );
}

TitleBar.displayName = "TitleBar";
Title.displayName = "TitleBarTitle";
TitleBarActions.displayName = "TitleBarActions";
