import { useMemo } from "react";
import { selectUser } from "store/slices";
import { when } from "utils";
import useSelector from "./useSelector";

type CountryMapType = { currency: string; enabled: boolean; iso2: string; iso3: string; code: string };

export const currencyMap: Record<string, CountryMapType> = {
  nigeria: { code: "+234", currency: "ngn", enabled: true, iso2: "ng", iso3: "nga" },
  ghana: { code: "+233", currency: "ghs", enabled: true, iso2: "gh", iso3: "gha" },
  kenya: { code: "+254", currency: "kes", enabled: true, iso2: "ke", iso3: "ken" },
  cameroon: { code: "+237", currency: "xaf", enabled: true, iso2: "cm", iso3: "cmr" },
  benin: { code: "+229", currency: "xof", enabled: true, iso2: "bj", iso3: "ben" },
  togo: { code: "+228", currency: "xof", enabled: true, iso2: "tg", iso3: "tgo" },
  senegal: { code: "+221", currency: "xof", enabled: true, iso2: "sn", iso3: "sen" },
  "burkina faso": { code: "+226", currency: "xof", enabled: true, iso2: "bf", iso3: "bfa" },
  "ivory coast": { code: "+225", currency: "xof", enabled: true, iso2: "ci", iso3: "civ" },
  "cote d'ivoire": { code: "+225", currency: "xof", enabled: true, iso2: "ci", iso3: "civ" },
  congo: { code: "+242", currency: "cdf", enabled: true, iso2: "cg", iso3: "cog" },
  "democratic republic of the congo": { code: "+243", currency: "cdf", enabled: true, iso2: "cd", iso3: "cod" },
  "south africa": { code: "+27", currency: "zar", enabled: true, iso2: "za", iso3: "zaf" },
  canada: { code: "+1", currency: "cad", enabled: false, iso2: "ca", iso3: "can" },
  "united kingdom": { code: "+44", currency: "gbp", enabled: false, iso2: "gb", iso3: "gbr" },
  default: { code: "+234", currency: "ngn", enabled: true, iso2: "ng", iso3: "nga" },
};

export function useCurrency() {
  const { profile } = useSelector(selectUser);
  const country: string = useMemo(() => String(profile?.country ?? "default").toLowerCase(), [profile]);
  const mapKeys = Object.keys(currencyMap);
  // console.log("MapKeys", mapKeys, country, mapKeys.includes(country), currencyMap.default.currency);
  return when(!!mapKeys.includes(country), currencyMap[country]?.currency, currencyMap?.default?.currency);
}

export function useCountryFullInfo() {
  const { profile } = useSelector(selectUser);
  const country: string = useMemo(() => String(profile?.country ?? "default").toLowerCase(), [profile]);
  const mapKeys = Object.keys(currencyMap);
  return when(!!mapKeys.includes(country), { ...currencyMap[country], name: country }, { ...currencyMap.default, name: country });
}
