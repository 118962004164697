import { Box, BoxProps, HStack, ImageProps, Text } from "@chakra-ui/react";
import { DownloadAnnouncementPatternSVG, DownloadOnAppleStoreSVG, DownloadOnGooglePlaySVG } from "assets";

import DownloadMockup from "assets/images/download_mockup.png";
import Link from "components/Link/Link";
import { useDefaultStyle } from "hooks";

interface DownloadAnnouncementProps extends BoxProps {
  _img?: ImageProps;
}

export function DownloadAnnouncement(props: DownloadAnnouncementProps) {
  const { _img, ...xprops } = props;

  const { shadow, borderColor } = useDefaultStyle();

  return (
    <Box p="30px" minH="246px" pos="relative" bg="#FAF3D4" borderRadius="16px" overflow="hidden" {...xprops}>
      <Box
        color="primary.default"
        textTransform="capitalize"
        fontWeight="600"
        fontFamily="var(--bitmama-fonts-heading)"
        pos="relative"
        zIndex="2"
        top="50px"
        fontSize="16px"
      >
        <Text lineHeight="19px">Download the</Text>
        <Text lineHeight="19px">Bitmama App</Text>
        <Text lineHeight="19px">Now</Text>
      </Box>

      <HStack justifyContent="flex-start" position="relative" top="60px">
        <Box
          as="button"
          borderRadius="4px"
          _focus={{ shadow, borderColor }}
          onClick={() => window.open("https://play.google.com/store/apps/details?id=com.bitmama.bitmama&hl=en&gl=US", "blank")}
        >
          <Box as={DownloadOnGooglePlaySVG} w="65px" h="20px" />
        </Box>
        <Box
          ml="2px !important"
          as="button"
          borderRadius="4px"
          _focus={{ shadow, borderColor }}
          onClick={() => window.open("https://apps.apple.com/us/app/bitmama/id1561857024", "blank")}
        >
          <Box as={DownloadOnAppleStoreSVG} w="65px" h="20px" />
        </Box>
      </HStack>

      <Box pos="absolute" bottom="20px" left="calc(100% - 100px)" zIndex={3}>
        <Link to="#" color="white" fontSize="12px" fontWeight="500" textDecoration="underline">
          T&Cs Apply
        </Link>
      </Box>

      <Box
        as="img"
        alt="earn annoucement image"
        pointerEvents="none"
        objectPosition="top"
        src={DownloadMockup}
        pos="absolute"
        bottom="-20px"
        right="0"
        w="100%"
        zIndex={2}
        {..._img}
      />

      <Box as={DownloadAnnouncementPatternSVG} pointerEvents="none" pos="absolute" top="0" left="0" zIndex={1} opacity="0.2" />
    </Box>
  );
}
