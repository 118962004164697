import { mode } from "@chakra-ui/theme-tools";
// import {StyleFunctionProps} from '@chakra-ui/react'

const styles = {
  global: (props: any) => ({
    // ":root": {
    //   "--focusShadowThickness": "3px",
    //   "--focusColor": "#3ac1a14f",
    //   "--focusShadow": `0 0 0 3px red`,
    // },

    "html, body": {
      bg: mode("grey.100", "dark.bg")(props),
      color: mode("black", "white")(props),
      transition: "all .5s ease-in",
    },
    // "*": {
    //   outline: "2px solid rgba(75, 75, 0, 0.5) !important",
    // },
  }),
};

export default styles;
