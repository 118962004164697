import { Box, BoxProps } from "@chakra-ui/react";
import { BannerProvider } from "contexts/banner.context";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

interface PageMotionProps extends PropsWithChildren<BoxProps> {
  subpage?: boolean;
}

const MotionBox = motion(Box);

export default function PageMotion(props: PageMotionProps) {
  const { subpage = false, children, key, ...xprops } = props;

  return (
    <MotionBox
      id={key}
      minH="100vh"
      overflowY={"overlay" as any}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{
        staggerChildren: 0.5,
        opacity: { type: "spring", stiffness: 100 },
        y: { type: "spring", stiffness: 100 },
      }}
      key={key}
      {...xprops}
    >
      {subpage && children}
      {!subpage && <BannerProvider>{children}</BannerProvider>}
    </MotionBox>
  );
}
