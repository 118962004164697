import { Table, Thead, Tr, Th, Tbody, Td, TableProps, Skeleton, HStack, IconButton, Text } from "@chakra-ui/react";
import Icon from "components/Icon/Icon";
import React from "react";
import { when } from "utils";

export interface GenericTableProps extends TableProps {
  headers: string[];
  isLoading?: boolean;
}

export interface GenericTableItemProps {
  cols: Array<React.ReactNode>;
  onClick?: () => void;
  isClickable?: boolean;
  isLast?: boolean;
}

export default function GenericTable(props: GenericTableProps) {
  const { headers, isLoading, children, ...xprops } = props;

  return (
    <Table variant="simple" {...(xprops as any)}>
      <Thead bg="brand.neutral50" h="74px">
        <Tr>
          {headers.map((header, i) => {
            return when(
              i === 0,
              <Th
                textTransform="capitalize"
                fontSize="14px"
                fontWeight="500"
                color="grey.400"
                fontFamily="var(--bitmama-fonts-body)"
                textAlign="center"
              >
                <HStack>
                  <IconButton variant="max" bg="transparent" aria-label="sort button" icon={<Icon type="swapArrow" />} />
                  <Text m="0 auto">{header}</Text>
                </HStack>
              </Th>,
              <Th
                textTransform="capitalize"
                fontSize="14px"
                fontWeight="500"
                color="grey.400"
                fontFamily="var(--bitmama-fonts-body)"
                textAlign="center"
              >
                {header}
              </Th>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {isLoading &&
          Array(10)
            .fill(0)
            .map((_, i) => (
              <Tr key={`header-row:${i}`}>
                {headers.map((header, i) => (
                  <Td key={`header-loading:${i}`}>
                    <Skeleton isLoaded={!isLoading ?? true} borderRadius="12px" h="14px">
                      {header}
                    </Skeleton>
                  </Td>
                ))}
              </Tr>
            ))}

        {!isLoading && children}

        {!isLoading && !children && <>No Data</>}
      </Tbody>
    </Table>
  );
}

export function GenericTableItem(props: GenericTableItemProps) {
  const { cols, onClick, isClickable = false } = props;
  return (
    <Tr onClick={onClick} _hover={{ cursor: isClickable ? "pointer" : "default", bg: "#F7F8F98f" }} borderBottom="none">
      {cols?.map((col, i) => (
        <Td key={`col:${i}`}>{col}</Td>
      ))}
    </Tr>
  );
}
