import { useEffect, useState } from "react";
import { Box, Button, FormControl, Heading, HStack, Input, Stack, useColorModeValue, useToast, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useAddBankMutation, useGetBanksByCountryQuery, useGetUserBankDetailsQuery, useResolveAccountMutation } from "apis";
import { CircularLoader, Icon, InputLabel, MainLayoutContainer, PageMotion, Select } from "components";
import { useBuiltInFn, useRedebounce } from "hooks";
import { PageProps } from "interfaces";
import { sortBy } from "lodash";

interface AddBankProps extends PageProps {}

export default function AddBank(props: AddBankProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountAddBank" overflowY="scroll">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              Banks
            </Heading>
          </HStack>
        </Box>

        <Form {...props} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

function Form(props: AddBankProps) {
  const toast = useToast();
  const { user } = props;

  const country = user && String(user?.country).toLowerCase();

  const countryCodeMap: any = {
    nigeria: "ng",
    ghana: "gh",
  };

  const countryCode = countryCodeMap[country!];
  const { refetch: refetchUserBankDetails } = useGetUserBankDetailsQuery({});
  const selectedCountry = countryCode !== undefined ? countryCode : "ng";

  const { data: banks } = useGetBanksByCountryQuery(selectedCountry, { skip: !country });

  const [_resolveBank, { data: resolvedBank, isLoading: isResolvedLoading, isSuccess: isResolvedSuccess }] =
    useResolveAccountMutation();

  const [_addBank, { isLoading: isAddingBank }] = useAddBankMutation();

  const isDisabled = () => !(bankCode && bankAccountNumber) || isAddingBank || !isResolvedSuccess;

  const debounce = useRedebounce();

  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankCode, setBankCode] = useState("");

  useEffect(() => {
    if (bankCode.length >= 2 && bankAccountNumber.length >= 8) {
      debounce(
        () =>
          _resolveBank({
            bankCode,
            bankAccountNumber,
          }),
        "resolveBank",
        2000
      )();
    }
  }, [bankCode, bankAccountNumber, debounce, _resolveBank]);

  const handleAddBank = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    try {
      const response: any = await _addBank({
        bankCode,
        bankAccountNumber,
        country: countryCode,
      });

      if (response.data) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: response?.data?.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        refetchUserBankDetails();
        navigate("/account/banks");
      }
    } catch (error) {}
  };

  console.log("Banks", banks);

  return (
    <Stack my="42px">
      <HStack mb="36px">
        <Heading as="h6" fontSize="sm" color="secondary.400">
          Add a Bank
        </Heading>
      </HStack>

      {/* <FormControl mb="28px !important">
        <InputLabel>Select Country</InputLabel>
        <Select placeholder="Select" textTransform="capitalize">
          {countries?.map(({ country }, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </Select>
      </FormControl> */}

      <Stack as="form" onSubmit={handleAddBank}>
        <FormControl mb="28px !important">
          <InputLabel htmlFor="bankCode">Choose Bank</InputLabel>

          <Select placeholder="Select Bank" id="bankCode" name="bankCode" onChange={(event) => setBankCode(event.target.value)}>
            {country === "nigeria" &&
              sortBy(banks?.bankPayloads ?? [], "bankName").map((res: any, index: number) => (
                <option key={index} value={res?.bankCode}>
                  {res?.bankName}
                </option>
              ))}

            {country === "ghana" &&
              sortBy(banks?.ghanaBankPayloads ?? [], "bankName").map((res: any, index: number) => (
                <option key={index} value={res?.bankCode}>
                  {res?.bankName}
                </option>
              ))}
          </Select>
        </FormControl>

        <FormControl mb="28px !important">
          <InputLabel htmlFor="bankAccountNumber">Account Number</InputLabel>
          <Input
            placeholder="Enter Account Number"
            id="bankAccountNumber"
            name="bankAccountNumber"
            onChange={(event) => setBankAccountNumber(event.target.value)}
          />
        </FormControl>

        <FormControl mb="28px !important">
          <InputLabel>Account Name</InputLabel>
          <Input placeholder="Account Name" defaultValue={resolvedBank?.account_name} disabled />
          {isResolvedLoading && <CircularLoader />}
        </FormControl>

        <VStack mt="40px !important" fontFamily="var(--bitmama-fonts-heading)">
          <Button
            isLoading={isAddingBank}
            type="submit"
            disabled={isDisabled()}
            minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
          >
            Save Bank
          </Button>
        </VStack>
      </Stack>
    </Stack>
  );
}
