import {
  Avatar,
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useGetNotificationsQuery } from "apis";
import { LogoutSVG } from "assets";
import Icon from "components/Icon/Icon";
import CircleLoader from "components/Loader/CircularLoader";
import { useSidePage } from "contexts";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth, useDefaultStyle, useSupport } from "hooks";
import { PageProps } from "interfaces";
import { useMemo } from "react";
import { switchStyle, when } from "utils";

import DroidStar from "../../assets/images/droid-star.png";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

interface BarWidgetProps extends PageProps {}

export default function BarWidget(props: BarWidgetProps) {
  const { user, isFetchingUser } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  // const bg = useColorModeValue("#fafafa", "dark.cardBg");
  // const borderColor = useColorModeValue("#e6e4e4", "#232323");

  const { logout } = useAuth();
  const { bootWithProps } = useSupport();

  const { data: notifications } = useGetNotificationsQuery({ page: 1, limit: 1, seen: false });
  const totalUnreadNotificationCount = useMemo(() => notifications?.totalUnseenCount ?? 0, [notifications]);

  console.log("notifications", notifications);

  const { onOpen } = useSidePage();
  const { shadow, borderColor } = useDefaultStyle();
  const buttonColor = useColorModeValue("black", "white");

  // const bg = switchStyle(colorMode, { dark: "whiteAlpha.100", light: "#F2F4F3" });
  const bg = switchStyle(colorMode, { dark: "transparent", light: "transparent" });

  return (
    <Box
      as={HStack}
      w="100%"
      p={{ base: "0px", "1sm": "8px 16px" }}
      justifyContent="center"
      alignItems="center"
      borderRadius="10px"
      bg={bg}
      gridGap={{ base: "46px", sm: "12px", "1sm": "28px", "4sm": "" }}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={`bar-widget-${when(!!isFetchingUser && !user, "loading", "loaded")}`}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
          style={{ maxHeight: "34px" }}
        >
          {isFetchingUser && !user && <CircleLoader />}

          {!isFetchingUser && !!user && (
            <Menu>
              <MenuButton>
                <Fullname user={user!} {...props} />
              </MenuButton>
              <MenuList borderRadius="10px" py="0" bg={switchStyle(colorMode, { dark: "#1E1E1E", light: "white" })}>
                <MenuItem
                  py="12px"
                  onClick={() => onOpen({ key: "Notifications" })}
                  display={{ base: "none", sm: "block", "1sm": "none" }}
                  id="notification-btn"
                >
                  <HStack>
                    <Icon type="notification" color={buttonColor} />
                    <Text fontSize="sm" fontWeight="500" fontFamily="var(--bitmama-fonts-heading)">
                      Notifications
                    </Text>
                    <Box flex="1" />
                    {totalUnreadNotificationCount > 0 && (
                      <Badge
                        pos="absolute"
                        // top="0px"
                        right="20px"
                        bg={"red.500"}
                        borderRadius="10px"
                        fontSize="10px"
                        h="14px"
                        minW="14px"
                        color="white"
                        // transform={"translate(3px, -6px)"}
                      >
                        {when<any>(totalUnreadNotificationCount > 99, `99+`, totalUnreadNotificationCount)}
                      </Badge>
                    )}
                  </HStack>
                </MenuItem>
                <MenuItem id="support-btn" py="12px" onClick={bootWithProps} display={{ base: "none", sm: "block", "1sm": "none" }}>
                  <HStack>
                    <Icon type="support" color={buttonColor} />
                    <Text fontSize="sm" fontWeight="500" fontFamily="var(--bitmama-fonts-heading)">
                      Support
                    </Text>
                  </HStack>
                </MenuItem>
                <MenuItem py="12px" onClick={logout}>
                  <HStack>
                    <Box as={LogoutSVG} />
                    <Text fontSize="sm" fontWeight="500" fontFamily="var(--bitmama-fonts-heading)">
                      Log Out
                    </Text>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </motion.div>
      </AnimatePresence>

      <HStack ml="0px !important" gridGap={{ base: "16px", sm: "8px", "1sm": "8px" }}>
        <IconButton
          id="toggle-mode"
          ml="0px !important"
          bg={switchStyle(colorMode, { dark: "whiteAlpha.100", light: "#F2F4F3" })}
          minW="38px"
          minH="38px"
          maxW="unset"
          maxH="unset"
          boxSize="38px"
          p="0"
          borderRadius="50%"
          variant="transparent"
          onClick={toggleColorMode}
          aria-label="theme-toggle"
          icon={when(
            colorMode === "light",
            <MoonIcon
              fontSize="sm"
              color={switchStyle(colorMode, {
                light: "gray.600",
                dark: "secondary.300",
              })}
            />,
            <SunIcon
              fontSize="sm"
              color={switchStyle(colorMode, {
                light: "black",
                dark: "white",
              })}
            />
          )}
        />

        <Button
          id="notification-btn"
          borderRadius="6px"
          variant="unstyled"
          minW="fit-content"
          minH="fit-content"
          h="fit-content"
          _focus={{ shadow, borderColor }}
          onClick={() => onOpen({ key: "Notifications" })}
          pos="relative"
          display={{ base: "block", sm: "none", "1sm": "block" }}
        >
          <Icon type="notification" color={buttonColor} />
          {totalUnreadNotificationCount > 0 && (
            <Badge
              pos="absolute"
              top="0px"
              right="0px"
              bg={"red.500"}
              borderRadius="10px"
              fontSize="10px"
              h="14px"
              minW="14px"
              color="white"
              transform={"translate(3px, -6px)"}
            >
              {when<any>(totalUnreadNotificationCount > 99, `99+`, totalUnreadNotificationCount)}
            </Badge>
          )}
        </Button>
        <Button
          id="support-btn"
          borderRadius="6px"
          variant="unstyled"
          minW="fit-content"
          minH="fit-content"
          h="fit-content"
          _focus={{ shadow, borderColor }}
          onClick={bootWithProps}
          display={{ base: "block", sm: "none", "1sm": "block" }}
        >
          <Icon type="support" color={buttonColor} />
        </Button>
      </HStack>
    </Box>
  );
}

function Fullname(props: any) {
  const { user, ...xprops } = props;

  return (
    <HStack pos="relative" justifyContent="center" {...xprops}>
      <Avatar boxSize={{ base: "20px", sm: "34px", "4sm": "40px" }} src={DroidStar} name={user?.fullName} bg="#31B7A9" />

      <Box display={{ base: "block", xs: "none", sm: "block", "1sm": "block" }}>
        <Text
          display={{ base: "none", "1sm": "block" }}
          w="100%"
          fontSize="14px"
          fontFamily="var(--bitmama-fonts-heading)"
          fontWeight="600"
          whiteSpace="nowrap"
        >
          {user?.fullName}
        </Text>
        <Text
          display={{ base: "block", "1sm": "none" }}
          w="100%"
          fontSize="12px"
          fontFamily="var(--bitmama-fonts-heading)"
          whiteSpace="nowrap"
        >
          {user?.firstName}
        </Text>
      </Box>
      <Icon type="chevronRightArrow" boxSize="8px" transform="rotate(90deg)" />
    </HStack>
  );
}
