import { Badge, Box, Button, Checkbox, FormControl, HStack, Stack, useDisclosure, VStack } from "@chakra-ui/react";
import { useGetDataPlansQuery, useGetRateQuery, useGetSupportedCountriesQuery } from "apis";
import {
  ConditionalRender,
  FormInfo,
  InputLabel,
  Option,
  PhoneInput,
  RadixSelect,
  Select,
  useModalView as useSteps,
} from "components";
import { useWalletBalance } from "hooks";
import { BillPlanProductRo, BillPlanRo } from "interfaces";
import { useCallback, useEffect, useMemo } from "react";

// import toUpper from "lodash/toUpper";
import configs from "config";
import { toCoinLocale, toPrecision, when } from "utils";
import Input from "components/Input/Input";
import DataPreviewModal from "ui/Dashboard/Modals/DataPreview";
import { useSidePage } from "contexts";
import { useCountryFullInfo, useCurrency } from "hooks/useCurrency";
import { useAirtimeDataState } from "../AirtimeAndData";

interface DataProps {}

export interface IState {
  country: string;
  dialCode: string;
  countryCode: string;
  countryName: string;
  operatorId: string | null;
  operatorInfo: BillPlanRo | null;
  phone: string;
  topupType: string | null;
  amount: string | null;
  wallet: string;
  productId: string;
  productInfo: Partial<BillPlanProductRo>;
  ported: boolean;
  pin: string;
}

export default function Data(props: DataProps) {
  const { onNext } = useSteps();
  const { /*data: page_data, params,*/ updateSidePageConfigs } = useSidePage();

  const currency = useCurrency();
  const { iso2 } = useCountryFullInfo();

  const { getBalance } = useWalletBalance();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data: countries, isLoading: isCountriesLoading } = useGetSupportedCountriesQuery("data");

  // const getCountryByDialCode = useCallback(
  //   (code?: string) => {
  //     const country = (countries ?? []).find((c) => c?.dialCode === (code ?? ""));

  //     return when(
  //       !!country && !!code,
  //       {
  //         ...page_data?.state,
  //         country: country?.id,
  //         countryCode: country?.code,
  //         dialCode: country?.dialCode,
  //         countryName: country?.name,
  //         // operatorId: null,
  //         // operatorInfo: null,
  //       },
  //       { ...page_data?.state }
  //     );
  //   },
  //   [countries, page_data]
  // );

  const getCountryByIso2 = useCallback(
    (iso2: string) => {
      const country = (countries ?? []).find((c) => (c?.code ?? "").toLowerCase() === iso2);

      if (!country) return {};

      return {
        country: country?.id,
        countryCode: country?.code,
        dialCode: country?.dialCode,
        countryName: country?.name,
      };
    },
    [countries]
  );

  // const [state, set, reset] = usePartialState<IState>(
  //   {
  //     phone: params?.phone,
  //     ...getCountryByIso2(iso2),
  //     ...getCountryByDialCode(params?.dialCode),
  //   },
  //   [getCountryByIso2]
  // );

  const stateContext = useAirtimeDataState(
    {
      state_for: "data",
      ...getCountryByIso2(iso2),
      // ...getCountryByDialCode(params?.dialCode),
    },
    [getCountryByIso2, iso2]
  );
  const { sharedState: state, setSharedState: set, resetSharedState: reset } = stateContext;

  const { data: planData, isFetching: isPlansLoading } = useGetDataPlansQuery(state?.country!, {
    skip: !state?.country,
  });

  const ticker = useMemo(() => `usd${when(!!currency, currency, "ngn")}`, [currency]);
  const { data: rateData /*, isFetching: isUpdatingRate */ } = useGetRateQuery(ticker, { skip: !state?.operatorInfo?.currency });

  const operators = useMemo(() => planData?.plans ?? [], [planData]);
  const dataPlans = useMemo(() => {
    return !!operators && !!state?.operatorId ? operators?.find((p) => p.operatorId === state.operatorId)?.products ?? [] : [];
  }, [operators, state?.operatorId]);
  const balance = useMemo(() => (!!state?.wallet ? getBalance(state.wallet as any) ?? 0 : 0), [getBalance, state?.wallet]);
  const sortedCountries = useMemo(() => [...(countries ?? [])].sort((a, b) => a?.name?.localeCompare(b?.name)), [countries]);

  const getCountry = (id: string) => (countries ?? []).find((c) => c?.id === id);
  const getOperator = (id: string) => (operators ?? []).find((p) => p?.operatorId === id);

  const getProductPriceInfo = useCallback(
    (productId: string) => {
      const product = state?.operatorInfo?.products?.find((p) => p.productId === productId);

      const map: Record<string, () => Partial<BillPlanProductRo>> = {
        fixed: () => ({
          plan: product?.name,
          operatorPrice: product?.operatorPrice,
          userPrice: product?.userPrice,
        }),
        range: () => ({
          plan: product?.name,
          operatorMaxPrice: product?.operatorMaxPrice,
          operatorMinPrice: product?.operatorMinPrice,
          userMinPrice: product?.userMinPrice,
          userMaxPrice: product?.userMaxPrice,
        }),
        empty: () => ({}),
      };

      return map[product?.priceType ?? "empty"]();
    },
    [state?.operatorInfo]
  );

  const prices = useMemo(() => {
    if (state?.operatorInfo && state?.productId && rateData?.exchangeRate) {
      const product = state?.operatorInfo?.products?.find((p) => p.productId === state?.productId);
      return {
        fiat: +(product?.operatorPrice ?? 0),
        crypto: +(product?.userPrice ?? 0) / rateData?.exchangeRate?.sell,
      };
    }
  }, [state?.operatorInfo, state?.productId, rateData]);

  const handleCountry = (id: string) => {
    const country = getCountry(id);
    set({
      country: id,
      countryCode: country?.code,
      dialCode: country?.dialCode,
      countryName: country?.name,
      operatorId: null,
      operatorInfo: null,
    });
  };

  const handleOperator = (id: string) => {
    const operator = getOperator(id);
    set({ operatorId: id, operatorInfo: operator as BillPlanRo });
  };

  const handleSeeSavedNumbers = () => {
    if (!!operators) updateSidePageConfigs({ data: { plans: operators } });
    onNext();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onOpen();
  };

  const isDisabled = useMemo(
    () =>
      !(state?.country && state?.operatorId && state?.productId && state?.phone && state?.wallet) ||
      isCountriesLoading ||
      (!!prices?.crypto && !!balance && prices.crypto > balance),
    [state, isCountriesLoading, prices, balance]
  );

  useEffect(
    () => {
      if (!state?.country) set({ ...getCountryByIso2(iso2) });
    },
    //eslint-disable-next-line
    [getCountryByIso2, iso2]
  );

  return (
    <Stack as="form" py={{ base: "32px", "1sm": "52px" }} onSubmit={handleSubmit}>
      <VStack
        // templateColumns={{ base: "1fr", "1sm": "repeat(2, 1fr)" }}
        // gap="20px"
        // mb={{ base: "20px !important", "1sm": "44px !important" }}
        mb={{ base: "20px !important", "1sm": "28px !important" }}
        gridGap={{ base: "20px !important", "1sm": "28px !important" }}
      >
        <FormControl>
          <HStack w="100%" justifyContent="space-between">
            <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
            <ConditionalRender shouldRender={!!state?.country && !!operators}>
              <Button variant="link" fontSize="14px" textDecoration="underline" mt="10px" onClick={handleSeeSavedNumbers}>
                Beneficiaries
              </Button>
            </ConditionalRender>
          </HStack>
          <PhoneInput
            id="phone_input"
            countryValue={state?.country}
            onCountryChange={(value) => handleCountry(value)}
            placeholder="Enter a phone number"
            isDisabled={!state?.country}
            value={state?.phone ?? ""}
            onChange={(e) => set({ phone: e.target.value })}
            _select={{
              use_image_icon: true,
              "aria-label": "Select Country",
              placeholder: "Select Country",
            }}
          >
            {sortedCountries.map((country) => (
              <Option key={`country-${country.id}`} value={country?.id} image={country?.flag}>
                {country.name}
              </Option>
            ))}
          </PhoneInput>
        </FormControl>

        <FormControl display="none">
          <InputLabel isLoading={isCountriesLoading}>Country</InputLabel>
          {/* <Select
            fontSize={{ base: "14px", "2sm": "16px" }}
            placeholder="Select Country"
            value={state?.country ?? ""}
            onChange={(e) => handleCountry(e.target.value)}
          >
            {sortedCountries.map((country) => (
              <option key={`country-${country.id}`} value={country.id}>
                {country.name}
              </option>
            ))}
          </Select> */}

          <RadixSelect
            use_image_icon
            aria-label="Select Country"
            placeholder="Select Country"
            value={state?.country}
            // defaultValue={getCountryByIso2(iso2)?.country}
            onChange={(value) => handleCountry(value)}
            _trigger={{ w: "100%", fontSize: { base: "14px", "2sm": "16px" } }}
          >
            {sortedCountries.map((country) => (
              <Option key={`country-${country.id}`} value={country?.id} image={country?.flag}>
                {country.name}
              </Option>
            ))}
          </RadixSelect>
        </FormControl>

        <FormControl>
          <InputLabel isLoading={isPlansLoading}>Mobile Operator</InputLabel>
          {/* <Select
            // fontSize={{ base: "14px", "2sm": "16px" }}
            placeholder="Select Operator"
            isDisabled={!state?.country}
            value={state?.operatorId ?? ""}
            onChange={(e) => handleOperator(e.target.value)}
          >
            {(operators ?? []).map((operator) => (
              <option key={`operator-${operator?.operatorId}`} value={operator?.operatorId}>
                {operator?.name}
              </option>
            ))}
          </Select> */}

          <RadixSelect
            use_image_icon
            aria-label="Operator"
            placeholder="Select Operator"
            preload_network_images={operators.map((opr) => opr?.brandLogo)}
            disabled={!state?.country || isPlansLoading}
            value={state?.operatorId as any}
            onChange={(value) => handleOperator(value)}
            _trigger={{ w: "100%", fontSize: { base: "14px", "2sm": "16px" } }}
          >
            {(operators ?? []).map((operator) => (
              <Option key={`operator-${operator?.operatorId}`} value={operator?.operatorId} image={operator?.brandLogo}>
                {operator?.name}
              </Option>
            ))}
          </RadixSelect>
        </FormControl>
      </VStack>

      <VStack
        // templateColumns={{ base: "1fr", "1sm": "repeat(2, 1fr)" }}
        // gap="20px"
        // mb={{ base: "20px !important", "1sm": "44px !important" }}
        mb={{ base: "20px !important", "1sm": "28px !important" }}
        gridGap={{ base: "20px !important", "1sm": "28px !important" }}
      >
        <FormControl>
          <InputLabel>Plan</InputLabel>
          <Select
            isInvalid={!!balance && !!prices?.crypto && prices.crypto > balance}
            placeholder="Select Plan"
            isDisabled={!state?.operatorId}
            value={state?.productId ?? ""}
            onChange={(e) =>
              set({
                productId: e.target.value,
                productInfo: getProductPriceInfo(e.target.value),
              })
            }
          >
            {dataPlans?.map((plan, i) => (
              <option key={i} value={plan?.productId ?? ""}>
                {plan?.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>Fiat Price</InputLabel>
          <Input
            isInvalid={!!balance && !!prices?.crypto && prices.crypto > balance}
            disabled
            value={!!prices?.fiat && state?.operatorInfo?.currency ? `${Number(prices.fiat).toLocaleString()}` : ""}
            endAdornment={
              <Box pr="22px" fontFamily="var(--bitmama-fonts-heading)">
                {state?.operatorInfo?.currency ? `${state?.operatorInfo?.currency}` : ""}
              </Box>
            }
          />
        </FormControl>
      </VStack>

      <VStack
        // templateColumns={{ base: "1fr", "1sm": "repeat(2, 1fr)" }} gap="20px"
        mb={{ base: "20px !important", "1sm": "28px !important" }}
        gridGap={{ base: "20px !important", "1sm": "28px !important" }}
      >
        <FormControl display="none">
          <InputLabel>Phone Number</InputLabel>
          <Input
            placeholder="Enter a phone number"
            isDisabled={!state?.country}
            value={state?.phone ?? ""}
            startAdornment={state?.dialCode ? `+${state?.dialCode}` : null}
            onChange={(e) => set({ phone: e.target.value })}
            _startAdornment={{
              pl: when(!!state?.country, "22px", "0px"),
              fontWeight: "medium",
              fontFamily: "var(--bitmama-fonts-heading)",
            }}
            pl={when(!!state?.country, "72px", "18px")}
          />

          <ConditionalRender shouldRender={!!state?.country && !!operators}>
            <Button variant="link" fontSize="12px" textDecoration="underline" mt="10px" onClick={handleSeeSavedNumbers}>
              See Saved Numbers
            </Button>
          </ConditionalRender>
        </FormControl>

        <FormControl>
          <InputLabel>Wallet</InputLabel>
          {/* <Select placeholder="Select Wallet" value={state?.wallet ?? ""} onChange={(e) => set({ wallet: e.target.value })}>
            {configs.stableCoins.map((coin) => (
              <option key={`coin-${coin}`} value={coin}>
                {toCoinLocale(coin)}
              </option>
            ))}
          </Select> */}

          <RadixSelect
            use_coin_icon
            aria-label="Select Wallet"
            placeholder="Select Wallet"
            value={state?.wallet}
            onChange={(value) => set({ wallet: value })}
            _trigger={{ w: "100%" }}
          >
            {configs.stableCoins.map((coin) => (
              <Option key={`coin-${coin}`} value={coin} coin={coin as any}>
                {toCoinLocale(coin)}
              </Option>
            ))}
          </RadixSelect>

          {!!balance && state?.wallet && (
            <FormInfo mt="10px" info="Balance: " description={toPrecision(balance, state.wallet)} fontSize="sm" />
          )}
        </FormControl>
      </VStack>

      {!!state?.wallet && !!prices?.crypto && (
        <FormControl>
          <HStack mt="20px !important" alignSelf="flex-start">
            <InputLabel>Crypto Price:</InputLabel>
            <Badge fontSize="sm" colorScheme={!!balance && prices.crypto > balance ? "red" : "secondary"} textTransform="unset">
              {toPrecision(prices.crypto ?? 0, state?.wallet, true, 2)}
            </Badge>
          </HStack>
        </FormControl>
      )}

      {state?.phone && (
        <HStack mt="14px !important" w="100%" alignItems="flex-start">
          <Checkbox
            size="md"
            isChecked={state?.ported}
            colorScheme="green"
            onChange={(e) => set({ ported: e.target.checked })}
            sx={{
              ".chakra-checkbox__label": {
                fontWeight: "500",
              },
            }}
          >
            This number is ported from another network
          </Checkbox>
        </HStack>
      )}

      <DataPreviewModal isOpen={isOpen} onClose={onClose} state={state} set={set} reset={reset} prices={prices!} />

      <VStack mt="60px !important">
        <Button type="submit" disabled={isDisabled} minW={{ base: "100%", smx: "336px", "4sm": "400px" }}>
          Buy
        </Button>
      </VStack>
    </Stack>
  );
}
