import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

import { ErrorType } from "interfaces";
import { RootState } from "../index";

interface ErrorState {
  previous: ErrorType;
  next: ErrorType;
}

type ClearErrorType = "next" | "previous" | "both";

const initialState: ErrorState = {
  previous: {
    id: null,
    message: null,
    status: null,
    action: null,
    showUser: false,
  },
  next: {
    id: null,
    message: null,
    status: null,
    action: null,
    showUser: false,
  },
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<Partial<ErrorType>>) {
      const id = `${action.payload?.action?.type ?? "error"}-${nanoid(7)}`;
      if (state?.previous?.id === null && state?.next?.id === null) {
        state.previous = { ...action.payload, id };
        state.next = { ...action.payload, id };
        return;
      } else if (state?.previous?.id !== null && state?.next?.id === null) {
        state.previous = { ...state.previous };
        state.next = { ...action.payload, id };
        return;
      } else {
        state.previous = { ...state?.next };
        state.next = { ...action.payload, id };
        return;
      }
    },
    clearError(state, action: PayloadAction<ClearErrorType>) {
      const mapActions = (type: ClearErrorType) => {
        const map = {
          next: () => {
            state.previous = { ...state.next };
            state.next = { ...initialState.next };
          },
          previous: () => {
            state.previous = { ...state.next };
            state.next = { ...initialState.next };
          },
          both: () => {
            state = { ...initialState };
          },
        };

        return map[type]();
      };

      mapActions(action.payload);
    },
  },
});

export const selectError = (state: RootState) => state.error;
export const { actions, reducer: ErrorReducer } = errorSlice;
export const { setError, clearError } = actions;
