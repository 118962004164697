import { Box } from "@chakra-ui/react";
import { Steps } from "components";
import { SidePageViewProps } from "contexts";
import CardDetails from "./Details";
import FundCreditCard from "./Fund";
import WithdrawCreditCard from "./Withdraw";

interface VirtualCardDetailsIndexProps extends SidePageViewProps {}

export default function VirtualCardDetailsIndex(props: VirtualCardDetailsIndexProps) {
  return (
    <Box overflowX="hidden">
      <Steps hideIndicator>
        <CardDetails />
        <FundCreditCard />
        <WithdrawCreditCard />
      </Steps>
    </Box>
  );
}

VirtualCardDetailsIndex.displayName = "SidePageView";
