import {
  Box,
  Button,
  Center,
  FormControl,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";

import { Group, Icon, InputLabel, Link, Logo, ShowCustomerSupport } from "components";
import Input from "components/Input/Input";
import configs, { isProd } from "config";
import { useCallback, useEffect, useMemo, useRef } from "react";
import Recaptcha from "react-google-recaptcha";
import { AuthImage, WhyBVNModal } from "ui";
import { when } from "utils";
import { IntercomProvider } from "react-use-intercom";
import { useAuth, useColor, useDebounce, useDisclosures, usePartialState, useSelector, useSignupInputCheck } from "hooks";
import { selectAuth } from "store/slices";
import { FormWarning } from "./Signup";
import { join } from "lodash";
import { navigate, useLocation } from "@reach/router";
import { isSafeRedirect } from "utils/urls";
import { useCaptureBVNMutation } from "apis";

import ls from "utils/secureStorage";

type ModalType = "whyBVN";

interface IFormState {
  bvn: string;
}

export default function CaptureBVN(props: { path: string }) {
  const { auth, logout: auth_logout, rehydrateAuth } = useAuth();

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const recaptchaRef = useRef<any>(null);

  const hColor = useColorModeValue("#303131", "white");

  const logoColor = useColorModeValue("primary.default", "secondary.500");

  console.log("Capture BVN", auth);

  const color = useColor();
  const debounce = useDebounce();
  const isBVNProperlyChecked = useRef(false);
  const { isOpen, open, close } = useDisclosures<ModalType>();

  const [form, set] = usePartialState<IFormState>();

  const { checks } = useSelector(selectAuth);
  const { makeCheck, check } = useSignupInputCheck();
  const [captureBVN, { isLoading }] = useCaptureBVNMutation();

  // eslint-disable-next-line
  const bvnData = useMemo(() => checks?.bvn?.bvnData, [checks?.bvn?.bvnData]);

  const isAlreadyInUse = useCallback((type: "bvn") => check(type).inUse, [check]);
  const isBVNInvalid = useMemo(() => !!form?.bvn && form?.bvn.length < 11, [form?.bvn]);
  const isChecking = useCallback((type: "bvn") => check(type).status === "fetching", [check]);

  const handleCheckableInput = useCallback(
    (update: IFormState) => {
      set(update);
      isBVNProperlyChecked.current = false;
      if (!!update?.bvn && update?.bvn?.length === 11) debounce(() => makeCheck("bvn", update?.bvn!), "makeBVNCheck", 500)();
    },
    [makeCheck, debounce, set]
  );

  const isDisabled = useMemo(
    () => !form?.bvn || isBVNInvalid || isAlreadyInUse("bvn") || isChecking("bvn") || isLoading,
    [form, isBVNInvalid, isAlreadyInUse, isChecking, isLoading]
  );

  const makeRequest = async (bvn?: string) => {
    if (!form?.bvn) return;

    // const captcha = await recaptchaRef.current?.executeAsync();

    const response = await captureBVN({
      bvn: bvn ?? form?.bvn,
      //   humanKey: captcha,
    }).unwrap();

    console.log("Captured BVN Data", response);

    // recaptchaRef.current?.reset();

    if (!!response) {
      ls.set(configs.AUTH_TOKEN_KEY, { token: response.token, isSignedIn: true, verification: [], exp: response?.exp });
      rehydrateAuth();
      //   window.location.replace(configs.paths.dashboard);
      navigate(configs.paths.dashboard, { replace: true });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await makeRequest();
  };

  const logout = useCallback(() => {
    auth_logout();
    const win: Window = window;
    win.location.replace("/login");
  }, [auth_logout]);

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const [isMobile] = useMediaQuery("(max-width: 1023px)");

  useUpdateEffect(() => {
    if (check("bvn").status === "success") {
      isBVNProperlyChecked.current = true;
    }
  }, [isBVNProperlyChecked, check]);

  useEffect(() => {
    if ((!!auth && !!auth?.label && !["BVN_PROFILE_REQUIRED"].includes(auth?.label)) || (!!auth && auth?.isSignedIn)) {
      const rto = params.get("rto");
      const isSafeRto = isSafeRedirect({ url: rto!, skip: !isProd });
      const path = when(!rto, configs.paths.dashboard, when(isSafeRto, rto!, configs.paths.dashboard));
      console.log("Capture BVN rto path", path);
      //   (window.location as any) = path;
      navigate(path, { replace: true });
    }
  }, [auth, params]);

  return (
    <IntercomProvider appId={configs.INTERCOM_TOKEN}>
      <Box
        minH="100vh"
        pos="relative"
        sx={{
          "--w": isLargerThan1080 ? "580px" : "480px",
        }}
      >
        <AuthImage isMobile={isMobile} />

        <Box minH="100vh" pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
          <Group maxWidth="523px" margin="auto" padding={{ sm: "32px 20px 43px", "2sm": "80px 20px 43px" }}>
            <Logo fontSize="54px" mb={{ sm: "12px", "2sm": "52px" }} _name={{ w: "110px" }} color={logoColor} />

            <Box mb="26px !important">
              <Heading fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
                Capture BVN
              </Heading>

              <Text fontWeight="600" color={hColor} fontSize="16px" margin="12px 0 21px">
                Please enter your Bank Verification Number
              </Text>
            </Box>

            <Stack as="form" gridGap="24px" onSubmit={handleSubmit}>
              <FormControl>
                <InputLabel htmlFor="bvn" isInvalid={isAlreadyInUse("bvn") || isBVNInvalid} isLoading={isChecking("bvn")}>
                  Bank Verification Number (BVN)
                </InputLabel>
                <Input
                  isRequired
                  isInvalid={isBVNInvalid || isAlreadyInUse("bvn")}
                  variant="filled"
                  id="bvn"
                  placeholder="Enter BVN"
                  type="text"
                  maxLength={11}
                  value={form?.bvn ?? ""}
                  onChange={(e) => handleCheckableInput({ bvn: e.target.value })}
                />

                <Box p="4px">
                  <Link mt="8px" type="button" onClick={open("whyBVN")}>
                    <HStack>
                      <Icon type="infoRounded" boxSize="22px" color={color("primary.default", "secondary.500")} />
                      <Text fontSize="13px" fontWeight="500" color={color("primary.default", "secondary.500")}>
                        Why we need your BVN
                      </Text>
                    </HStack>
                  </Link>
                  {!!form?.bvn && isBVNInvalid && <FormWarning info={"BVN is an 11-digit number"} isValid={!isBVNInvalid} />}
                  {!!form?.bvn && !isChecking("bvn") && !isBVNInvalid && bvnData && (
                    <FormWarning
                      info={when(
                        isAlreadyInUse("bvn") && !isChecking("bvn"),
                        `BVN verification failed`,
                        `${join([bvnData?.firstName, bvnData?.lastName], " ")}`
                      )}
                      isValid={!isAlreadyInUse("bvn")}
                    />
                  )}
                </Box>
              </FormControl>

              <VStack mb="81px !important" mt="36px !important" alignItems="center">
                <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />
                <Button
                  type="submit"
                  minW="initial"
                  maxW="399px"
                  width="100%"
                  isLoading={isLoading}
                  disabled={isDisabled}
                  rightIcon={<Icon type="arrowRight" color="white" />}
                >
                  Continue
                </Button>
              </VStack>
            </Stack>

            <Center flexDir="column">
              <Text mt="42px" onClick={logout} cursor="pointer">
                Logout
              </Text>
            </Center>
          </Group>
        </Box>
      </Box>
      <ShowCustomerSupport />

      <WhyBVNModal isOpen={isOpen("whyBVN")} onClose={close("whyBVN")} />
    </IntercomProvider>
  );
}
