import { MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { PageProps } from "interfaces";
import { Crypto, Fiat } from "ui";
import WalletPage from "./Wallet";

export default function WalletIndex(props: PageProps) {
  return (
    <PageMotion key="Wallet">
      <Topbar pageTitle="Wallet" {...props} />

      <MainLayoutContainer pb="60px">
        <SidePageProvider>
          <WalletPage {...props} />

          <Fiat key="Fiat" hasBackButton />
          <Crypto key="Crypto" hasBackButton />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}
