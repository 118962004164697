import configs from "config";

const coinMap = configs.coins;

// console.log("COIN MAP", coinMap);

const isSupported = (coin: string) => Object.keys(coinMap).includes(coin);

const toCoinLocale = (coin: string) => {
  if (!isSupported(coin)) return String(coin).toUpperCase();
  return (coinMap as any)[coin].label;
};

export default toCoinLocale;
