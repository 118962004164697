import { Box, Button, Heading, HStack, useColorModeValue } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import { format, parseISO } from "date-fns";
import { DetailItem, DetailItemProps, Group, Icon, MainLayoutContainer, PageMotion } from "components";
import { useGetInviteHistoryDetailsQuery } from "apis";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";

import { TransactionItemList } from "./TransactionItemList";

interface HistoryDetailsProps extends PageProps {}

export default function InviteHistoryDetails(props: HistoryDetailsProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const bg = useColorModeValue("#F6F8F74F", "#f6f8f703");

  const { id } = useParams();

  const { data } = useGetInviteHistoryDetailsQuery(id);

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountRules" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              User Details
            </Heading>
          </HStack>
        </Box>

        <Group my="28px" bg={bg} borderRadius="16px" p="24px 24px">
          <HistoryDetailItem title="Username" description={`@${data?.referredUser?.username}`} />
          <HistoryDetailItem title="Full Name" description={`${data?.referredUser?.firstName} ${data?.referredUser?.lastName}`} />
          <HistoryDetailItem
            title="Registration/Sign Up Date"
            description={data?.registeredOn && format(parseISO(data?.registeredOn), "MMMM e, yyyy")}
          />
          <HistoryDetailItem title="Verification Status" description={`Level ${data?.referredUser?.kycLevel} verification`} />
        </Group>

        <TransactionItemList id={data?.referredUser?._id} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

function HistoryDetailItem(props: DetailItemProps) {
  return (
    <DetailItem
      py="0px"
      mb="32px !important"
      borderBottomColor="transparent"
      _title={{ fontSize: "18px", fontWeight: "500", color: "inherit" }}
      _description={{ fontSize: "14px", fontWeight: "500", color: "inherit" }}
      {...props}
    />
  );
}
