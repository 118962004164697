import { when } from "utils";

export function transformBuyCryptoDataToEngagement(data: any) {
  return {
    "Buy Currency": data?.coin,
    "Buy Amount": +(data?.cryptoAmount ?? 0),
    "Get Currency": data?.currency,
    "Get Amount": data?.price,
  };
}

export function transformSellCryptoDataToEngagement(data: any) {
  return {
    "Sell Currency": data?.fiatCurrency,
    "Sell Amount": data?.fiatAmount,
    "Get Currency": data?.coin,
    "Get Amount": data?.amountOfCryptoToSell,
  };
}

export function transformSwapCryptoDataToEngagement(data: any) {
  return {
    "You Buy": data?.destToken,
    Amount: data?.sourceAmount,
    Currency: data?.sourceToken,
    "You Receive": data?.destAmount,
  };
}

///################################### CARD FEATURE #####################################

export function transformCardCreationDataToEngagement(data: any) {
  return {
    Currency: data?.coin,
    "Fund Amount": data?.fundAmount,
    "Payment Wallet": data?.coin,
    Type: data?.cardType,
    Color: data?.color,
  };
}

export function transformCardLabelDataToEngagement(data: any) {
  return {
    Label: data?.label,
  };
}

export function transformCardFundingDataToEngagement(data: any) {
  return {
    Currency: data?.coin,
    Amount: data?.amount,
  };
}

export function transformCardWithdrawalDataToEngagement(data: any) {
  return {
    Currency: data?.coin,
    Amount: data?.amount,
  };
}

export function transformCardRequestStatementDataToEngagement(data: any) {
  return {
    From: data?.from,
    To: data?.to,
    "Card Name": data?.cardName,
    "Card Type": data?.cardType,
  };
}

///################################### GREENBOX FEATURE #####################################

export function transformGreenboxFundingDataToEngagement(data: any) {
  return {
    Wallet: data?.coin,
    Amount: data?.amount,
  };
}

export function transformGreenboxCreationDataToEngagement(data: any) {
  return {
    Wallet: data?.unit,
    Amount: data?.amountPerUser,
  };
}

export function transformGreenboxWithdrawalDataToEngagement(data: any) {
  return {
    Wallet: data?.coin,
    Amount: data?.amount,
  };
}

export function transformGreenboxClaimDataToEngagement(data: any) {
  return {
    // Code: data,
  };
}

///################################### AIRTIME / DATA FEATURE #####################################

export function transformAirtimeDataToEngagement(data: any) {
  return {
    Country: data?.country,
    "Mobile Operator": data?.airtimeNetwork,
    "Topup Type": data?.name,
    "Phone Number": data?.phone,
  };
}

export function transformDataToEngagement(data: any) {
  return {
    Country: data?.country,
    "Mobile Operator": data?.airtimeNetwork,
    Plan: data?.name,
    "Flat Price": data?.amount,
  };
}

///################################### FIAT FEATURE #####################################

export function transformFiatDepositDataToEngagement(data: any) {
  return {
    "Wallet category": data?.currency,
    "Wallet sub category": data?.currency,
    Amount: data?.amount,
    "Wallet Type": "Fiat",
    "Choose Option": "Deposit",
    "Deposit Type": data?.depositType,
  };
}

export function transformFiatWithdrawalToEngagement(data: any) {
  return {
    "Wallet category": data?.coin,
    "Wallet sub category": data?.coin,
    Amount: data?.amount,
    "Wallet Type": "Fiat",
    "Choose Option": "Withdrawal",
  };
}

///################################### CRYPTO FEATURE #####################################

export function transformCryptoDepositDataToEngagement(data: any) {
  return {
    "Wallet category": data?.coin,
    "Wallet sub category": data?.coin,
    Amount: data?.amount,
    "Wallet Type": "Crypto",
    "Choose Option": "Deposit",
  };
}

export function transformCryptoWithdrawalToEngagement(data: any) {
  return {
    "Wallet category": data?.coin,
    "Wallet sub category": data?.destinationCoin,
    Amount: data?.amount,
    "Wallet Type": "Crypto",
    "Choose Option": "Withdraw",
  };
}

///################################### EARN / STAKING FEATURE #####################################

export function transformEarnDataToEngagement(data: any) {
  return {
    Coin: data?.coin,
    "Subscription Type": when(!!data?.flexible, "flexible", "fixed"),
    Amount: data?.amount,
    Duration: data?.duration,
    "Automatic Rollover": data?.autoRenew,
  };
}

export function transformStakingTransferDataToEngagement(data: any) {
  return {
    Category: "Staking",
    From: data?.from,
    To: data?.to,
    Coin: data?.coin,
    Amount: data?.amount,
  };
}

///################################### P2P FEATURE #####################################

export function transformP2PAdCreationDataToEngagement(data: any) {
  return {
    Type: data?.adType,
  };
}

export function transformP2PBuyDataToEngagement(data: any) {
  return {
    "Buy Currency": data?.currency,
    Buyer: data?.buyer,
    Coin: data?.coin,
    "Payment Method": data?.method,
  };
}

export function transformP2PSellDataToEngagement(data: any) {
  return {
    "Sell Currency": data?.currency,
    Seller: data?.seller,
    Coin: data?.coin,
    "Payment Method": data?.method,
  };
}
