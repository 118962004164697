import { Box } from "@chakra-ui/react";
import { Steps, useModalView } from "components";
import { Level3FormState, PageProps } from "interfaces";
import { OtherStepOne, FrancStepOne } from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { usePartialState } from "hooks";
import { useCurrency } from "hooks/useCurrency";
import configs from "config";
import { useMemo } from "react";

interface Level3VerificationFormProps extends PageProps {}

export interface IStateExtension {
  state: Partial<Level3FormState>;
  set: (updates: Partial<Level3FormState>) => void;
  reset: () => void;
}

export default function Level3VerificationForm({ user }: Level3VerificationFormProps) {
  const [state, set, reset] = usePartialState<Level3FormState>();
  const { setCurrentView } = useModalView();

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;

  //   setFormState({
  //     ...formState,
  //     [name]: value,
  //   });
  // };

  const currency = useCurrency();
  const isFranc = useMemo(() => configs.francoCoins.includes(currency), [currency]);

  const goBack = () => setCurrentView(0);

  return (
    <Box mt={{ base: "16px", md: "36px" }}>
      <Steps hideIndicator>
        {!isFranc && <OtherStepOne state={state} set={set} reset={reset} />}
        {isFranc && <FrancStepOne state={state} set={set} reset={reset} />}
        <StepTwo state={state} set={set} reset={reset} />
        <StepThree state={state} set={set} reset={reset} goBack={goBack} />
      </Steps>
    </Box>
  );
}
