import React, {
  useMemo,
  // useCallback,
  PropsWithChildren,
  useEffect,
  useRef,
} from "react";
import { ButtonProps } from "@chakra-ui/react";
import { ViewSwitcherProps } from "./interfaces";

import { motion, AnimatePresence } from "framer-motion";
import { useModalView } from "./ModalView";

// import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { isEmpty } from "lodash";

interface ViewChildButtonProps {
  next?: ButtonProps;
  back?: ButtonProps;
}

let count = 0;

export default function ViewSwitcher(props: PropsWithChildren<ViewSwitcherProps>) {
  const { children, _transitions } = props;

  const {
    initialMax,
    updateModal,
    onNext,
    onPrev,
    currentView,
    hasPrev,
    hasNext,
    direction,
    nextButtonProps,
    backButtonProps,
    // setMaxViews,
    setViewConfigs,
    initialNextButtonPropsList,
    initialBackButtonPropsList,
  } = useModalView();

  // const previousViewIdx = useRef(currentView ?? 0);

  const isSet = useRef(false);

  const views = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return;

        return React.cloneElement<any>(child, {
          updateModal,
          onNext,
          onPrev,
          currentView,
          hasPrev,
          hasNext,
          nextButtonProps,
          backButtonProps,
        });
      }) as React.ReactElement<any, string | React.JSXElementConstructor<any>>[],
    [children, onNext, onPrev, currentView, hasPrev, hasNext, nextButtonProps, backButtonProps, updateModal]
  );

  const maxViews = useMemo(() => (views ?? []).length, [views]);
  const renderView = useMemo(() => (views ?? [])[currentView!], [views, currentView]);

  const buttonProps = useMemo<ViewChildButtonProps[]>(() => (views ?? []).map((view) => view.props?.buttonProps ?? {}), [views]);

  const viewNextButtonProps = useMemo<ViewChildButtonProps["next"][]>(
    () => (buttonProps ?? []).map((props) => props.next).filter(Boolean),
    [buttonProps]
  );

  const viewBackButtonProps = useMemo<ViewChildButtonProps["back"][]>(
    () => (buttonProps ?? []).map((props) => props.back).filter(Boolean),
    [buttonProps]
  );

  const inverseTranslationValue = useMemo(() => Number(50 * (direction === 1 ? -1 : 1)), [direction]);

  // const initialTranslationValue = useMemo(
  //   () => Number(50 * (direction ?? 1)),
  //   [direction]
  // );

  //   const viewBackButtonProps = useMemo<ViewChildButtonProps['back'][]>(() => (buttonProps ?? []).map(props => props.next), [buttonProps])

  // console.log("RENDER VIEW", renderView);

  // console.log(
  //   "MAX STEPS",
  //   maxViews,
  //   views,
  //   viewNextButtonProps,
  //   nextButtonProps
  // );

  useEffect(() => {
    // console.log({
    //   initialMax,
    //   maxViews,
    //   viewNextButtonProps,
    //   initialNextButtonPropsList,
    //   viewBackButtonProps,
    //   initialBackButtonPropsList,
    // });

    if (
      initialMax !== maxViews ||
      (!isEmpty(viewNextButtonProps) &&
        !isEmpty(initialNextButtonPropsList) &&
        !isEqual(viewNextButtonProps, initialNextButtonPropsList)) ||
      (!isEmpty(viewBackButtonProps) &&
        !isEmpty(initialBackButtonPropsList) &&
        !isEqual(viewBackButtonProps, initialBackButtonPropsList))
    ) {
      count++;
      console.log(`[ViewSwitcher useEffect] :: Ran this ${count} times`);
      setViewConfigs!!({
        maxViews,
        nextButtonProps: (viewNextButtonProps as ButtonProps[]) ?? [],
        backButtonProps: (viewBackButtonProps as ButtonProps[]) ?? [],
      });

      isSet.current = true;
    }

    return () => {
      isSet.current = false;
    };
  }, [
    initialMax,
    setViewConfigs,
    maxViews,
    viewNextButtonProps,
    viewBackButtonProps,
    initialNextButtonPropsList,
    initialBackButtonPropsList,
  ]);

  // console.log("PROGRESS", currentView, previousViewIdx.current);

  // useEffect(() => {
  //   if (previousViewIdx.current !== currentView) {
  //     const progressPercentage = (currentView / (maxViews - 1)) * 100;
  //     updateModal && updateModal({ progress: progressPercentage });

  //     console.log("🪁 %cPROGRESS(%) %d%%", "color:blue", progressPercentage);
  //     previousViewIdx.current = currentView;
  //   }
  // }, [previousViewIdx, currentView, maxViews, updateModal]);

  return (
    <AnimatePresence exitBeforeEnter initial={false} {..._transitions?.presence}>
      <motion.div
        key={`view-swticher-motion-${currentView}`}
        initial={{
          opacity: 0,
          scale: 0.98,
          x: 50,
        }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        exit={{ opacity: 0, scale: 0.98, x: inverseTranslationValue }}
        {..._transitions?.view}
        style={{ overflow: "auto" }}
      >
        {renderView}
      </motion.div>
    </AnimatePresence>
  );
}
