import { Box, Button, ButtonProps } from "@chakra-ui/react";
import { CopySVG } from "assets";

interface CopyButtonProps extends ButtonProps {}

export default function CopyButton(props: CopyButtonProps) {
  const { children, ...xprops } = props;
  return (
    <Button
      variant="transparent"
      leftIcon={<Box as={CopySVG} boxSize="16px" />}
      minW="unset"
      maxW="unset"
      minH="unset"
      maxH="unset"
      h="fit-content"
      w="fit-content"
      px="1"
      py="0"
      fontSize="12px"
      color="secondary.400"
      sx={{ span: { mr: 0 } }}
      {...xprops}
    >
      {!children ? "Copy" : children}
    </Button>
  );
}
