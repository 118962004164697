import { BoxProps, Button, Checkbox, Heading, HStack, Input, Stack, Text, useToast, VStack } from "@chakra-ui/react";
import { useDeleteContactsMutation, useGetContactsQuery, useGetSupportedCountriesQuery } from "apis";
import { EmptyCrate, Group, PageLoading, useModalView as useSteps } from "components";
import { ActionButton } from "components/P2P/AdvertimentItem";
import { useSidePage } from "contexts";
import { ContactRo } from "interfaces";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useMemo } from "react";

import uniq from "lodash/uniq";
import { when } from "utils";
// import { useLocation } from "@reach/router";
import { useDefaultStyle, useDisclosures, usePartialState } from "hooks";
import Confirmation from "ui/Common/Modals/Confirmation";

// import toLower from "lodash/toLower";
import { useLocation } from "@reach/router";
import { useAirtimeDataState } from "./AirtimeAndData";

export interface BeneficiaryProps extends BoxProps {}
export interface BeneficiaryItemProps extends BoxProps {
  deleteMode: boolean;
  dialCodes: string[];
  isChecked: boolean;
  toggleItemToDelete: (id: string) => void;
  contact: ContactRo;
}

type ModalTypes = "confirmDelete";
export function Beneficiary(props: BeneficiaryProps) {
  const toast = useToast();
  const location = useLocation();
  const { hasPrev, setCurrentView } = useSteps();
  const { updateSidePageConfigs } = useSidePage();
  // const { sharedState, setSharedState } = useAirtimeDataState();

  const { isOpen, open, close } = useDisclosures<ModalTypes>();

  const [state, set] = usePartialState<{ deleteMode: boolean; itemsToDelete: string[] }>({ deleteMode: false });

  const params = useMemo(() => new URLSearchParams(location?.search), [location]);

  const tab = useMemo(() => {
    if (!!params.has("subpage") && !!params.get("subpage")) {
      const [, tab] = params.get("subpage")!.split(":");
      return when(!!tab, tab, "data");
    } else return "data";
  }, [params]);

  const { data, isLoading } = useGetContactsQuery({});
  const [_deleteContacts, { isLoading: isDeleting }] = useDeleteContactsMutation();

  const { data: countries } = useGetSupportedCountriesQuery(tab as any);
  const dialCodes = useMemo(() => (countries ?? []).map((country) => country?.dialCode), [countries]);

  const contacts = useMemo(() => data?.data ?? [], [data]);
  const hasContacts = useMemo(() => (contacts ?? []).length > 0, [contacts]);

  // console.log("Contacts", dialCodes, tab);

  const toggleItemToDelete = useCallback(
    (id: string) => {
      const items = state?.itemsToDelete ?? [];
      if (items.includes(id)) return set({ itemsToDelete: items.filter((item) => item !== id) });
      else return set({ itemsToDelete: uniq([...items, id]) });
    },
    [state?.itemsToDelete, set]
  );

  const goBack = () => {
    setCurrentView(0);
    // setSharedState({...sharedState});
  };

  useEffect(() => {
    if (hasPrev) updateSidePageConfigs({ _back: { text: "Back", onClick: goBack } });
    // eslint-disable-next-line
  }, []);

  const deleteContactsAfterConfirm = async () => {
    try {
      const result = await _deleteContacts({ contacts: state?.itemsToDelete ?? [] }).unwrap();
      if (!!result) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: "Beneficiaries successfully deleted",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        set({ deleteMode: false, itemsToDelete: [] });
      }
    } catch (error: any) {
      // toast({
      //   position: "bottom-right",
      //   title: "Oops",
      //   description: error?.message ?? "Unable to delete beneficiaries",
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
    }
  };

  const handleDelete = () => {
    if (!!state?.deleteMode && (state?.itemsToDelete ?? []).length > 0) open("confirmDelete")();
    else set({ deleteMode: !state?.deleteMode });
  };

  return (
    <Group mt="0" p="4px" overflowX="hidden">
      <HStack justifyContent="space-between" maxWidth="100%">
        <Heading as="h4" fontSize="md">
          Beneficiary List
        </Heading>

        {/* <Box flex="100" /> */}

        <HStack>
          {!!state?.deleteMode && <ActionButton w="unset" actionFor="cancel" onClick={() => set({ deleteMode: false })} />}
          <ActionButton
            w="unset"
            actionFor="delete"
            isLoading={isDeleting}
            label={`Delete ${when((state?.itemsToDelete ?? []).length > 0, `(${state?.itemsToDelete?.length}) items`, "")}`}
            isDisabled={(!!state?.deleteMode && (state?.itemsToDelete ?? []).length < 1) || isLoading || isDeleting}
            onClick={handleDelete}
          />
        </HStack>
      </HStack>

      <Input mt="40px !important" placeholder="Search by name" w="100%" type="search" />

      <VStack my="26px !important" w="100%">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`contact-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
            style={{ width: "100%" }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {!isLoading &&
              hasContacts &&
              contacts.map((contact, i) => (
                <BeneficiaryItem
                  deleteMode={!!state?.deleteMode}
                  key={i}
                  dialCodes={dialCodes}
                  toggleItemToDelete={toggleItemToDelete}
                  isChecked={(state?.itemsToDelete ?? []).includes(contact._id)}
                  // {...omit(contact, ["visibility"])}
                  contact={contact}
                />
              ))}

            {!isLoading && !hasContacts && <EmptyCrate type="transaction" />}
          </motion.div>
        </AnimatePresence>
      </VStack>

      <Confirmation isOpen={isOpen("confirmDelete")} onClose={close("confirmDelete")} onConfirm={deleteContactsAfterConfirm}>
        <VStack py="24px">
          <Text fontWeight="500">Are you sure you want to delete all ({(state?.itemsToDelete ?? []).length}) beneficiaries?</Text>
        </VStack>
      </Confirmation>
    </Group>
  );
}

export function BeneficiaryItem(props: BeneficiaryItemProps) {
  const { shadow } = useDefaultStyle();
  const { deleteMode, contact, dialCodes, isChecked, toggleItemToDelete } = props;
  const { _id, contactName, contactNumber, network } = contact;
  const { hasPrev, setCurrentView } = useSteps();
  // const { data/*, updateSidePageConfigs */ } = useSidePage();
  const { sharedState, setSharedState } = useAirtimeDataState();

  const [dialCode, phone] = getPhoneAndDialCode(dialCodes, contactNumber);

  // const operator = useMemo(
  //   () => ((data?.plans ?? []) as BillPlanRo[]).find((pln) => toLower(pln?.name) === network),
  //   [data?.plans, network]
  // );

  // console.log("Operator", data?.plans, operator);
  // console.log(`Contact::${contactName}`, { dialCode, phone });

  const handleClick = () => {
    if (!!deleteMode) return;
    // added setTimeout for button click performance reasons only, fixes the lag on the button when clicked.
    // const state = { operatorId: operator?.operatorId, operatorInfo: operator };
    setSharedState({ phone: phone!, dialCode: dialCode!, state_for: sharedState?.state_for });

    console.log("Beneficiary", { phone: phone!, dialCode: dialCode! });
    // setTimeout(() => updateSidePageConfigs({ params: { phone: phone!, dialCode: dialCode! }, data: { state } }), 300);
    if (hasPrev) setCurrentView(0);
  };

  return (
    <HStack w="100%">
      {!!deleteMode && (
        <Checkbox
          // dir="rtl"
          colorScheme="primary"
          isChecked={isChecked}
          onChange={() => toggleItemToDelete(_id)}
          sx={{
            "chakra-checkbox__control[data-focus]": {
              shadow,
            },
          }}
        />
      )}

      <Button
        px="10px"
        py="10px"
        w="100%"
        variant="transparent"
        h="fit-content"
        minH="unset"
        minW="unset"
        borderRadius="4px"
        onClick={handleClick}
      >
        <HStack w="100%" justifyContent="space-between">
          <HStack>
            <Text color="primary.default">{contactName}</Text>
          </HStack>
          <Stack alignItems="flex-end">
            <Text fontSize="sm" color="initial">
              {contactNumber}
            </Text>
            <Text fontSize="xs" color="initial" fontWeight="600" textTransform="capitalize">
              {network}
            </Text>
          </Stack>
        </HStack>
      </Button>
    </HStack>
  );
}

function getPhoneAndDialCode(dialCodes: string[], contactNumber: string) {
  const dialCode = dialCodes.find((code) => when(!!String(contactNumber).startsWith(code), code, undefined));
  const [, phone] = when<(string | null)[]>(!!dialCode, String(contactNumber).split(dialCode!), [null, null]);
  return [dialCode, phone];
}
