import { Box, Grid, HStack, Text, useColorMode } from "@chakra-ui/react";
import {
  AccountLink,
  CopyButton,
  DetailItem,
  EmptyCrate,
  Group,
  PageLoading,
  Paginator,
  Steps,
  Title,
  TitleBar,
  useModalView as useSteps,
} from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { useCopy, usePartialState } from "hooks";
import { useEffect, useMemo } from "react";
import { switchStyle, toPrecision, toQueryString, when } from "utils";
import { AnimatePresence, motion } from "framer-motion";

import capitalize from "lodash/capitalize";
import { useGetGreenboxRecipientsQuery, useGetGreenboxTransactionDetailsQuery } from "apis";
import { format, formatDistance, parseISO } from "date-fns";

import isEmpty from "lodash/isEmpty";
import { useLocation } from "@reach/router";

interface GreenBoxDetailsSetupProps extends SidePageViewProps {}

interface PropsWithState extends GreenBoxDetailsSetupProps, IStateExtension {}

interface IStateExtension {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  reset: () => void;
}

type CriteriaType = "ALL_USERS" | "NEW_USERS" | "SELECTED_USERS" | "NEW_USERS_KYC_L2";

export interface IState {
  page: number;
  limit: number;
  sortBy: string;
}

export interface RecipientSenderDetailsProps extends PropsWithState {}

export default function GreenBoxDetails(props: GreenBoxDetailsSetupProps) {
  const [state, set, reset] = usePartialState({
    page: 1,
    limit: 6,
    sortBy: "createdAt:desc",
  });

  //   console.log("Greenbox Fund", coin);

  return (
    <Box overflowX="hidden">
      <Steps hideIndicator>
        <Details {...{ ...props, state, set, reset }} />
        <RecipientSenderDetails {...{ ...props, state, set, reset }} />
      </Steps>
    </Box>
  );
}

export function Details(props: PropsWithState) {
  const { state, set } = props;
  const { params } = useSidePage();
  // console.log("Details id", params?.id);
  //   const { state,  } = props;

  const { colorMode } = useColorMode();

  const { data: greenbox, isLoading } = useGetGreenboxTransactionDetailsQuery(params?.id as string, { skip: !params?.id });
  const { data: _recipients, isLoading: isRecipientsLoading } = useGetGreenboxRecipientsQuery(
    { id: params?.id as string, queries: toQueryString(state) },
    { skip: !params?.id }
  );

  const location = useLocation();
  const host = useMemo(() => location.host, [location]);
  const protocol = useMemo(() => location.protocol, [location]);

  const recipients = useMemo(() => _recipients?.data ?? [], [_recipients]);
  const totalCount = useMemo(() => _recipients?.totalCount ?? 0, [_recipients]);

  console.log("Details recipients", _recipients);

  const link: string = `${protocol}//${host}/gbx/${greenbox?.product?.code}`;
  const { onCopy } = useCopy(link);

  const { onNext } = useSteps();
  const { data, onClose: onSidePageclose, updateSidePageConfigs } = useSidePage();

  const criteria = useMemo(() => {
    const map: Record<CriteriaType, string> = {
      ALL_USERS: "All Bitmama Users",
      SELECTED_USERS: "Selected Users",
      NEW_USERS: "All New Users",
      NEW_USERS_KYC_L2: "New but Verified Users",
    };

    const crit = (greenbox?.product?.criteria ?? "ALL_USERS") as CriteriaType;

    return map[crit];
  }, [greenbox?.product?.criteria]);

  // const;

  const handleRecipientClick = (id: string) => {
    onNext();
    updateSidePageConfigs({ data: { ...data, boxId: id } });
  };

  useEffect(() => {
    // set the sidepage back button click handler to go back to previous view.
    updateSidePageConfigs({ _back: { onClick: onSidePageclose, text: "Close" } });

    //eslint-disable-next-line
  }, []);

  return (
    <Box id="send-a-greenbox" px="40px" {...props} overflow="hidden">
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={when(isLoading, "loading", "loaded")}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
        >
          {isLoading && <PageLoading py="120px" isLoading={isLoading} />}

          {!isLoading && !!greenbox && (
            <>
              <TitleBar>
                <Title fontSize="20px">Greenbox Details</Title>
              </TitleBar>

              <Group mt="20px">
                <DetailItem title="Greenbox Name" description={greenbox?.product?.title} _description={{ fontSize: "20px" }} />
                {["box_out"].includes(greenbox?.actionType) && (
                  <DetailItem
                    title="Greenbox Shareable Link"
                    description={
                      <HStack>
                        <Text
                          cursor="pointer"
                          textDecoration="underline"
                          color={switchStyle(colorMode, { light: "primary.default", dark: "seconary.400" })}
                          fontSize="md"
                          onClick={onCopy}
                        >
                          {/* {protocol}//{host}/gbx/{greenbox?.product?.code} */}
                          {link}
                        </Text>
                        <CopyButton onClick={onCopy} />
                      </HStack>
                    }
                    _description={{ fontSize: "20px" }}
                  />
                )}

                <Grid templateColumns="repeat(2, 1fr)" gap="42px">
                  <DetailItem
                    title="Gift Amount (per person)"
                    // description={toPrecision(
                    //   +(greenbox?.value?.$numberDecimal ?? 1) / +(greenbox?.product?.totalRecipients ?? 1),
                    //   greenbox?.unit
                    // )}
                    description={toPrecision(+(greenbox?.product?.amtPerUser ?? 0), greenbox?.unit)}
                    _description={{ fontSize: "20px" }}
                  />
                  <DetailItem
                    title="Recipients"
                    description={greenbox?.product?.totalRecipients}
                    _description={{ fontSize: "20px" }}
                  />
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap="42px">
                  <DetailItem
                    title="Total Amount"
                    // description={toPrecision(+(greenbox?.value?.$numberDecimal ?? 1), greenbox?.unit ?? "usdt")}
                    description={toPrecision(
                      +(greenbox?.product?.amtPerUser ?? 1) * +(greenbox?.product?.totalRecipients ?? 1),
                      greenbox?.unit ?? "usdt"
                    )}
                    _description={{ fontSize: "20px" }}
                  />

                  {/* <DetailItem
                    title="Distribution"
                    description={capitalize(greenbox?.product?.distributionType)}
                    _description={{ fontSize: "20px" }}
                  /> */}
                  <DetailItem title="Status" description={capitalize(greenbox?.status ?? "")} _description={{ fontSize: "20px" }} />
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap="42px">
                  {!["box_in"].includes(greenbox?.actionType) && (
                    <DetailItem title="Criteria for qualification" description={criteria} _description={{ fontSize: "20px" }} />
                  )}
                  {!!greenbox?.boxOwner && ["box_in"].includes(greenbox?.actionType) && (
                    <DetailItem
                      title="Giver"
                      description={`@${greenbox?.boxOwner?.username}`}
                      _description={{ fontSize: "20px" }}
                    />
                  )}
                  {!["expired", "claimed"].includes(greenbox?.status) &&
                    !!greenbox?.product?.expirationDate &&
                    !!greenbox?.product?.startDate && (
                      <DetailItem
                        title="Expiration"
                        description={`Expires in ${formatDistance(
                          parseISO(greenbox?.product?.expirationDate),
                          parseISO(greenbox?.product?.startDate)
                        )}`}
                        // description={`Expires in 3 days`}
                        _description={{ fontSize: "20px" }}
                      />
                    )}
                </Grid>
              </Group>

              {!!greenbox?.actionType && !["box_in", "box_cancel"].includes(greenbox?.actionType!) && (
                <Group>
                  <TitleBar>
                    <Title fontSize="20px">{when(["box_out"].includes(greenbox?.actionType!), "Recipients", "Sender")}</Title>
                  </TitleBar>

                  <AnimatePresence exitBeforeEnter initial={false}>
                    <motion.div
                      key={`recipients-${when(isLoading, "loading", "loaded")}`}
                      initial={{ opacity: 0, scale: 0.98 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.98 }}
                    >
                      <Group mt="10px">
                        {isRecipientsLoading && <PageLoading py="60px" isLoading={isRecipientsLoading} />}
                        {!isRecipientsLoading &&
                          (recipients ?? []).map((recip, i) => (
                            <AccountLink
                              key={`recipient-${i}`}
                              label={`@${recip?.userDetails?.username}`}
                              // to="#"
                              fontFamily="var(--bitmama-fonts-heading)"
                              fontSize="18px"
                              fontWeight="300"
                              px="4px"
                              borderBottomColor="grey.150"
                              onClick={() => handleRecipientClick(recip?._id)}
                            />
                          ))}

                        {!isRecipientsLoading && (recipients ?? []).length > state?.limit! && (
                          <Paginator
                            py="60px"
                            limit={state?.limit!}
                            onPageChange={(page: number) => set({ page })}
                            page={state?.page!}
                            totalCount={totalCount ?? 0}
                          />
                        )}

                        {!isRecipientsLoading && isEmpty(recipients) && (
                          <EmptyCrate
                            type="transaction"
                            description={`You've got no ${when(
                              ["box_out"].includes(greenbox?.actionType!),
                              "recipients",
                              "sender"
                            )}.`}
                          />
                        )}
                      </Group>
                    </motion.div>
                  </AnimatePresence>
                </Group>
              )}
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}

function RecipientSenderDetails(props: RecipientSenderDetailsProps) {
  const { state } = props;

  const { hasPrev, onPrev } = useSteps();
  const { data, params, updateSidePageConfigs } = useSidePage();

  const queries = useMemo(() => toQueryString(state), [state]);

  const { data: recipients, isLoading } = useGetGreenboxRecipientsQuery(
    { id: params?.id as string, queries },
    { skip: !params?.id }
  );

  const details = useMemo(() => (recipients?.data ?? []).find((recip) => recip?._id === data?.boxId), [recipients, data?.boxId]);

  console.log("Preclaim", params?.id, details);

  useEffect(() => {
    // set the sidepage back button click handler to go back to previous view.
    if (hasPrev) updateSidePageConfigs({ _back: { onClick: onPrev, text: "Greenbox Details" } });
    //eslint-disable-next-line
  }, []);

  return (
    <Box id="send-a-greenbox" px="40px" {...props} overflowY="scroll">
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={`preclaims-${when(isLoading, "loading", "loaded")}`}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
        >
          {isLoading && <PageLoading py="120px" isLoading={isLoading} />}

          {!isLoading && !!details && (
            <>
              <TitleBar>
                <Title fontSize="20px">Transaction Summary</Title>
              </TitleBar>

              <Group mt="20px">
                <DetailItem
                  title={"Recipient Username"}
                  description={`@${details?.userDetails?.username}`}
                  _description={{ fontSize: "20px" }}
                />
                <DetailItem
                  title="Amount Received"
                  description={toPrecision(details?.amountClaimed, details?.unit)}
                  _description={{ fontSize: "20px" }}
                />
                <DetailItem
                  title="Date of Transaction"
                  description={format(parseISO(details?.claimedAt), "EEE dd, MMM yyyy")}
                  _description={{ fontSize: "20px" }}
                />
                <DetailItem
                  title="Time of Transaction"
                  description={format(parseISO(details?.claimedAt), "hh:mm aa")}
                  _description={{ fontSize: "20px" }}
                />
              </Group>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}

GreenBoxDetails.displayName = "SidePageView";
