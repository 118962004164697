import { Box, TabList, TabPanel, Tabs } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Group, MainLayoutContainer, Steps, Tab, TabPanels, Title, TitleBar } from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { PageProps } from "interfaces";
import { useEffect, useState } from "react";
import { BuyAndSellTransactionDetails } from "ui/Common/BuyAndSellTransactionDetails";

import BuyAndSellTransactions from "../../../Common/BuyAndSellTransactions";
import BuyCryptoForm from "./Form";

interface BuyTabsProps extends PageProps {}

interface BuyViewProps extends SidePageViewProps, PageProps {}

export default function BuyView(props: BuyViewProps) {
  // const {} = props;
  return (
    <Box overflowX="hidden">
      <Steps hideIndicator>
        <Box px={{ base: "20px", "2sm": "32px" }} {...props} overflowY="scroll">
          <TitleBar>
            <Title fontSize="20px">Buy Crypto</Title>
          </TitleBar>

          <MainLayoutContainer maxW="2xl" mt="28px" px={{ base: "0", "2sm": "20px", "3sm": "40px" }}>
            <Group mt="0">
              <BuyTabs {...props} />
            </Group>
          </MainLayoutContainer>
        </Box>

        <BuyAndSellTransactionDetails />
      </Steps>
    </Box>
  );
}

function BuyTabs(props: BuyTabsProps) {
  const { key, updateSidePageConfigs, onClose } = useSidePage();
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    buy: 0,
    transactions: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("subpage")?.split(":")[1] as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("subpage", `${key}:${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    updateSidePageConfigs({ _back: { text: "Close", onClick: () => onClose() } });
    // eslint-disable-next-line
  }, []);

  return (
    <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange}>
      <TabList borderRadius="8px" justifyContent="space-between">
        <Tab>Buy</Tab>
        <Tab>Transactions</Tab>
      </TabList>
      <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
        <TabPanel px={["2", "0", "0", "1"]}>
          <BuyCryptoForm {...props} />
        </TabPanel>
        <TabPanel px={["2", "0", "0", "1"]}>
          <BuyAndSellTransactions subjectOf="buy_debit" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

BuyView.displayName = "SidePageView";
