import { Badge, Box, Button, HStack, Tab, TabList, TabPanel, TabProps, Tabs, useColorModeValue } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { AchievementsLayout, Icon, PageMotion, Span, TabPanels, Title, TitleBar } from "components";
import { useColor } from "hooks";
import { PageProps } from "interfaces";
import { useState } from "react";
import { AchievementsBadgesPanel, AchievementsChallengesPanel, AchievementsRewardsPanel } from "ui";

interface AchievementsProps extends PageProps {}

export default function Achievements(props: AchievementsProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const color = useColor();
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search as string);
  const tabMap = {
    badges: 0,
    rewards: 1,
    challenges: 2,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);
  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", Object.keys(tabMap)[index]);
    navigate(`badges?${searchParams.toString()}`);
  };

  return (
    <PageMotion subpage key="AccountAchievements" overflowY="scroll">
      <Tabs index={tabIndex} onChange={handleTabChange}>
        <AchievementsLayout>
          <Box mt="40px">
            <Button
              size="sm"
              minW="fit-content"
              maxW="fit-content"
              variant="link"
              leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
              onClick={() => navigate("/account/achievements")}
            >
              Go Back
            </Button>

            <HStack justifyContent="space-between" mt="10px" mb="50px">
              <TitleBar>
                <Title fontSize={{ sm: "20px", "2sm": "22px", md: "24px" }} fontWeight="600" color="primary.800">
                  Rewards and Badges
                </Title>
              </TitleBar>
            </HStack>
          </Box>

          <TabList
            borderColor="transparent"
            p={{ sm: "10px", "2sm": "18px 14px", md: "18px 14px" }}
            bg={color("grey.250", "dark.cardBg")}
            w={{ sm: "100%", "2sm": "fit-content", md: "fit-content" }}
            overflowX={{ sm: "scroll", md: "hidden" }}
            borderRadius="6px"
          >
            <RBTab>
              <Span fontSize={{ sm: "20px", "2sm": "28px", md: "30px" }} mr={{ sm: "8px", "2sm": "10px", md: "12px" }}>
                🏅
              </Span>
              Badges
              <Badge
                borderRadius="50%"
                bg="red"
                boxSize="28px"
                fontSize="12px"
                fontWeight="700"
                color="grey.100"
                // display="flex"
                justifyContent="center"
                alignItems="center"
                ml="12px"
                display="none"
              >
                4
              </Badge>
            </RBTab>
            <RBTab>
              <Span fontSize={{ sm: "20px", "2sm": "28px", md: "30px" }} mr={{ sm: "8px", "2sm": "10px", md: "12px" }}>
                🎁
              </Span>
              Rewards
            </RBTab>
            <RBTab>
              <Span fontSize={{ sm: "20px", "2sm": "28px", md: "30px" }} mr={{ sm: "8px", "2sm": "10px", md: "12px" }}>
                🎯
              </Span>
              Challenges
            </RBTab>
          </TabList>
        </AchievementsLayout>

        <Box w="100%" h="8px" bg={color("grey.250", "dark.cardBg")} mt="48px"></Box>

        <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
          <TabPanel>
            <AchievementsBadgesPanel />
          </TabPanel>

          <TabPanel>
            <AchievementsRewardsPanel />
          </TabPanel>
          <TabPanel>
            <AchievementsChallengesPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageMotion>
  );
}

function RBTab(props: TabProps) {
  const { children, ...xprops } = props;

  const color = useColor();

  return (
    <Tab
      p={{ sm: "10px 20px", "2sm": "12px 30px", md: "14px 38px" }}
      borderRadius={{ sm: "6px", "2sm": "8px", md: "10px" }}
      fontSize={{ sm: "16px", "2sm": "18px", md: "18px" }}
      fontWeight="500"
      color={color("grey.500", "grey.200")}
      _selected={{
        bg: color("primary.default", "secondary.400"),
        fontWeight: "700",
        color: "#fff",
        borderColor: "none",
        // transition: "all ease-in .3s",
      }}
      {...xprops}
    >
      {children}
    </Tab>
  );
}
