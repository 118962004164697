import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  FormControl,
  Grid,
  Heading,
  HStack,
  Progress,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  Card,
  ConditionalRender,
  EmptyCrate,
  Icon,
  IconNames,
  InputLabel,
  Link,
  LinkProps,
  MainLayoutContainer,
  PageLoading,
  PageMotion,
  Paginator,
  Select,
  Title,
  TitleBar,
  Topbar,
} from "components";
import { AnimatePresence, motion } from "framer-motion";
import { SidePageProvider, useSidePage } from "contexts";
import { useColor, useDefaultStyle } from "hooks";
import { PageProps } from "interfaces";
import { toCoinLocale, toPrecision, toQueryString, when } from "utils";

import { EarnTopRightPatternSVG } from "assets";
import { useGetEarnYieldHistoryQuery, useGetEarnYieldPlansQuery } from "apis";
import { useCallback, useMemo, useRef, useState } from "react";
import { YieldDetailsFeature, YieldFundFeature } from "ui";
import { format, parseISO } from "date-fns";
import { navigate } from "@reach/router";
import { capitalize } from "lodash";
// import { parseISO } from "date-fns";

interface YieldPageProps extends PageProps {}

export default function YieldPage(props: YieldPageProps) {
  //   const color = useColor();
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const handleBack = () => {
    // window.history.back();
    navigate("/earn");
  };

  return (
    <PageMotion key="Earn">
      <Topbar pageTitle="Earn" {...props} />

      <MainLayoutContainer pb="60px">
        <SidePageProvider>
          <VStack maxW="540px" alignItems="flex-start">
            <Box>
              <Box mb="42px">
                <Button
                  size="sm"
                  minW="fit-content"
                  maxW="fit-content"
                  variant="link"
                  leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                  onClick={handleBack}
                  px="2px !important"
                >
                  Back
                </Button>

                <Text mt="12px" as="h4" fontSize="24px" fontWeight="bold">
                  Yield
                </Text>
              </Box>
            </Box>

            <YieldList />

            <YieldTransactionList />
          </VStack>

          {/* <EarnSubPages key="EarnPages" hasBackButton /> */}

          <YieldFundFeature key="YieldFund" hasBackButton _back={{ text: "Close" }} />
          <YieldDetailsFeature key="YieldDetails" hasBackButton _back={{ text: "Close" }} />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}

function YieldList(props: any) {
  const { onOpen } = useSidePage();
  const { yields, isLoading } = useYields();

  return (
    <Stack w="100%">
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={`tx-list-${when(isLoading, "loading", "loaded")}`}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
        >
          {isLoading && <PageLoading isLoading={isLoading} />}

          {!isLoading && (
            <Stack gridGap="16px">
              {yields.map((y, id) => (
                <YieldCard
                  key={id}
                  label={y?.label!}
                  description={y?.yieldLabel!}
                  info={y.info}
                  arrowIcon="arrowRight"
                  bg={y.bg}
                  progressBarColor={y.progressBarColor}
                  color={y.color}
                  gain={y?.gain}
                  progress={y?.progress}
                  in_progress={y?.started}
                  onClick={() => {
                    if (!y?.started) onOpen({ key: "YieldFund", params: { id: y?.productId!! } });
                    else onOpen({ key: "YieldDetails", params: { id: y?.productId!! } });
                  }}
                />
              ))}
            </Stack>
          )}

          {/* {!isTxLoading && !isFetching && !hasTx && <EmptyCrate type="transaction" />} */}
        </motion.div>
      </AnimatePresence>
    </Stack>
  );
}

export function useYields() {
  // const color = useColor();
  //   const { onOpen } = useSidePage();
  const { getYieldScheme } = useYieldSchemes();
  const { data, isLoading } = useGetEarnYieldPlansQuery();
  const { data: history, isLoading: is_loading_history } = useGetEarnYieldHistoryQuery(`actionType=yield_in,yield_out`);
  console.log("Yield History", history);

  const plans = useMemo(() => data?.plans?.plans ?? [], [data?.plans]);

  const yields = useMemo(
    () =>
      plans.map((p, i) => {
        // const plan = plans.find((p) => p?.durationValue === y.interval);
        const schemes: YieldSchemeName[] = ["default", "default", "green_forest", "yellow_leave"];
        const record = (history?.records ?? []).find(
          (r) => r?.product?.productId === p?.productId && ["active"].includes(r?.currentStatus)
        );
        const scheme = getYieldScheme(schemes[i] ?? "default");

        // const createdDate = addDays(parseISO(record?.createdAt ?? new Date().toISOString()), record?.product?.tenor ?? 0) as Date;

        // const redeemDate = !record?.product?.expiresOn ? createdDate : parseISO(record?.product?.expiresOn);

        // const daysLeft = (redeemDate && differenceInDays(redeemDate, new Date())) ?? 0;

        // const start_date = createdDate.getTime();
        // const end_date = redeemDate.getTime();

        // const dist = end_date - start_date;
        // const remaining_dis = end_date - new Date().getTime();

        // const progress = dist - remaining_dis / dist;
        const progress = 0;

        // console.log(`Unique ${p?.label}`, { progress, end_date, start_date }, record?.product?.expiresOn, record?.createdAt);
        const info = "USDT/USDC Only";
        return { ...p, ...record?.product, ...record, progress, info, started: !!record, period: 1, ...scheme };
      }),
    //eslint-disable-next-line
    [plans, history?.records]
  );

  console.log("Yields", { yields, records: history?.records });

  return { yields, isLoading: isLoading || is_loading_history, is_loading_history, history };
}

interface YieldCardProps extends Omit<LinkProps, "to"> {
  label: string;
  to?: string;
  icon?: string;
  noBorderLink?: boolean;
  arrowIcon?: "chevronRightArrow" | "arrowRight";
  use_scheme?: boolean;
  description: string;
  progressBarColor: string;
  info: string;
  progress?: number;
  in_progress?: boolean;
  gain?: number;
}

function YieldCard(props: YieldCardProps) {
  const {
    label,
    to,
    icon,
    arrowIcon,
    noBorderLink,
    description,
    progressBarColor,
    info,
    progress = 0,
    in_progress = true,
    // gain = 0.5,
    ...xprops
  } = props;

  const color = useColor();

  // const bc = useColorModeValue("grey.100", "dark.border");

  // const borderColor = when(!!noBorderLink, "transparent", bc);
  const as = when<any>(!!to, Link, "button");
  const arrow = when(!!arrowIcon, arrowIcon as YieldCardProps["arrowIcon"], "chevronRightArrow");

  return (
    <Link
      as={as}
      to={to ?? ""}
      mt="0 !important"
      p="18px 16px"
      // border="1px solid transparent"
      // borderBottomColor={borderColor}
      _hover={{ textDecoration: "none" }}
      pos="relative"
      overflow="hidden"
      bg={color("#022A1E", "secondary.500")}
      color={"white"}
      borderRadius="10px"
      {...xprops}
    >
      <Stack justifyContent="space-between" textAlign="left">
        {/* <HStack>
          {icon && <Icon type={icon as any} />}

          <Text textDecoration="none" ml="12px !important">
            {label}
          </Text>
        </HStack> */}

        <Stack>
          <Heading fontSize="22px">{label}</Heading>
          <Text mt="0 !important" fontWeight="500" fontSize="sm" textAlign="left">
            {/* Yield: {gain}% per month */}
            Yield: {description}
          </Text>
        </Stack>

        <Icon
          type={arrow as any}
          color="inherit"
          alignSelf="flex-end"
          pos="absolute"
          top="50%"
          right="18px"
          transform="translateY(-50%)"
          mt="0 !important"
        />

        <Stack mt="20px !important">
          <ConditionalRender shouldRender={in_progress}>
            <Progress
              value={progress}
              h="8px"
              borderRadius="8px"
              sx={{
                "[role=progressbar]": {
                  // bg: color("secondary.400", "secondary.400"),
                  bg: progressBarColor,
                },
              }}
            />
          </ConditionalRender>
          <Text fontWeight="500" fontSize="sm">
            {info ?? "USDT/USDC Only"}
          </Text>
        </Stack>

        <Box as={EarnTopRightPatternSVG} pos="absolute" top="-6px" right="0" />
      </Stack>
    </Link>
  );
}

type YieldSchemeName = "green_forest" | "yellow_leave" | "default";
interface YieldScheme {
  bg: string;
  progressBarColor: string;
  color: string;
}

function useYieldSchemes() {
  const color = useColor();

  const getYieldScheme = useCallback(
    (scheme_name: YieldSchemeName = "default") => {
      const map: Record<YieldSchemeName, YieldScheme> = {
        default: {
          bg: color("#022A1E", "#022A1E"),
          progressBarColor: color("secondary.400", "secondary.400"),
          color: "white",
        },
        green_forest: {
          bg: color("secondary.500", "secondary.500"),
          progressBarColor: color("primary.default", "secondary.400"),
          color: "white",
        },
        yellow_leave: {
          bg: color("#E8C229", "#E8C229"),
          progressBarColor: color("secondary.400", "secondary.400"),
          color: "black",
        },
      };
      return map[scheme_name];
    },
    [color]
  );

  return { getYieldScheme };
}

interface YieldTransactionProps extends ButtonProps {
  // badge: GamificationBadgesType;
  value?: number;
  index?: number;
  anotherValue?: number;
  title?: string;
  description?: string;
  status?: string;
  coin?: string;
  createdAt?: string;
}

interface YieldTransactionListProps extends BoxProps {}

function YieldTransactionList(props: YieldTransactionListProps) {
  const LIMIT = 3;
  const ACTION_TYPE = "yield_in,yield_out";

  const [filter, setFilter] = useState<"all" | "active">("all");
  // const [, /*query_value*/ setQuerValue] = useState<string | null>(null);
  const [page, setPage] = useState(1);

  const queries = useMemo(() => {
    if (filter === "all") return toQueryString({ limit: LIMIT, page, actionType: ACTION_TYPE });
    return toQueryString({ status: filter, limit: LIMIT, page, actionType: ACTION_TYPE });
  }, [filter, page]);

  const { data: history, isLoading } = useGetEarnYieldHistoryQuery(queries);
  const records = useMemo(() => history?.records ?? [], [history]);

  const handleFilter = (value: string) => {
    setFilter(value as any);
    // if (value === "all") setQuerValue(null);
    // else setQuerValue(toQueryString({ status: value }));
  };

  return (
    <Stack w="100%">
      <Box mt="100px !important" w="100%">
        <TitleBar mb="22px">
          <Title fontSize="18px">Transactions</Title>
        </TitleBar>
        <FormControl>
          <InputLabel>Filter by</InputLabel>
          <Select value={filter} onChange={(e) => handleFilter(e.target.value)}>
            <option value="all">All</option>
            <option value="active">Active</option>
          </Select>
        </FormControl>
      </Box>

      <Stack w="100%" mt="40px !important">
        {isLoading && <PageLoading isLoading={isLoading} />}

        {!isLoading && (
          <Stack gridGap="16px">
            {records.map((r, id) => (
              <YieldTransaction
                key={`yield-tx-${id}`}
                index={id}
                mt="0 !important"
                title={r?.description}
                coin={r?.unit}
                status={r?.currentStatus === "" ? r?.status : r?.currentStatus}
                value={r?.actionType === "yield_out" ? r?.amountRedeemed?.volume : r?.amountAtStake?.volume}
              />
            ))}
          </Stack>
        )}

        {records.length > 0 && (
          <Paginator totalCount={history?.totalCount} page={page} limit={LIMIT} onPageChange={(page: number) => setPage(page)} />
        )}

        {!isLoading && records.length < 1 && <EmptyCrate type="transaction" />}
      </Stack>
    </Stack>
  );
}

export function YieldTransaction(props: YieldTransactionProps) {
  const {
    index = 0,
    value = 210,
    status,
    coin = "usdt",
    createdAt = new Date().toISOString(),
    title,
    description,
    ...xprops
  } = props;

  const played = useRef(false);

  const { borderColor, shadow } = useDefaultStyle();

  const status_color = useMemo(() => {
    return (
      {
        [status!]: { bg: "#ebe9e97a", fg: "#777777" },
        active: { bg: "#14c00521", fg: "#14c005" },
        redeemed: { bg: "#14c00521", fg: "#14c005" },
        terminated: { bg: "#f5656524", fg: "red.400" },
        pending: { bg: "#e7c40633", fg: "#e7c406" },
        cancelled: { bg: "#f5656524", fg: "red.400" },
        denied: { bg: "#f5656524", fg: "red.400" },
      } as Record<string, { bg: string; fg: string }>
    )[status ?? "active"];
  }, [status]);

  const status_icon = useMemo(() => {
    return (
      {
        [status!]: "logout",
        active: "yield",
        redeemed: "yield",
        terminated: "logout",
        pending: "account",
        cancelled: "logout",
        denied: "logout",
      } as Record<string, IconNames>
    )[status ?? "active"];
  }, [status]);

  const computed_title = useMemo(() => {
    return (
      {
        [status!]: `${capitalize(status)} ${toCoinLocale(coin)} subscription`,
        active: title,
      } as Record<string, string>
    )[status ?? "active"];
  }, [title, status, coin]);

  return (
    <Card
      as="button"
      w="100%"
      //   minW={{ base: "initial", "1sm": "340px" }}
      p="18px 28px"
      border="1px solid transparent"
      _focus={{ shadow, borderColor }}
      _hover={{ borderColor }}
      {...(xprops as any)}
      outline="1px solid transparent"
      initial="hidden"
      animate="visible"
      // variants={!played.current ? list : {}}
      custom={index}
      onAnimationComplete={() => (played.current = true)}
      {...xprops}
    >
      <HStack justifyContent="space-between">
        <Grid templateColumns={{ sm: "48px 1fr", md: "65px 1fr" }} alignItems="center" gap={{ base: "15px", "2sm": "18px" }}>
          {/* <BadgeIcon boxSize={{ sm: "48px", md: "68px" }} badge={"newBitStar"} /> */}
          <Box
            boxSize={{ sm: "48px", md: "68px" }}
            bg={status_color.bg}
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon type={status_icon} color={status_color.fg} />
          </Box>

          <Stack justifyContent="flex-start" alignItems="baseline">
            <Text
              fontWeight="500"
              fontSize="14px"
              fontFamily="var(--bitmama-fonts-heading)"
              textAlign="left"
              color={status_color.fg}
            >
              {computed_title ?? "New Subscription"}
            </Text>
            <Text mt="6px !important" fontSize="16px" fontWeight="500" color="black" textAlign="left">
              {toPrecision(value, coin as any)}
            </Text>
          </Stack>
        </Grid>

        <HStack>
          <Text mt="0 !important" fontSize={{ base: "10px", "2sm": "12px" }} fontWeight="500" color="grey.500">
            {(!!createdAt && format(parseISO(createdAt), "eee, dd MMM yyyy")) ?? "Wed, 18 May 2022"}
          </Text>

          <Icon type="chevronRightArrow" sx={{ path: { strokeWidth: "2px" } }} />
        </HStack>
      </HStack>
    </Card>
  );
}
