import { Box, Button, ButtonProps, Text, useColorModeValue } from "@chakra-ui/react";
import Icon, { IconNames } from "components/Icon/Icon";
import { useColor } from "hooks";
import { useMemo } from "react";

export type QuickActionType =
  | "buy"
  | "sell"
  | "cards"
  | "bcpl"
  | "staking"
  | "more"
  | "airtime"
  | "bills"
  | "cryptoAlert"
  | "greenbox"
  | "playToEarn"
  | "swap"
  | "autoBuySell"
  | "yield";
type DirType = "horizontal" | "vertical";

export interface QuickActionProps extends ButtonProps {
  action: QuickActionType;
  dir?: DirType;
}

// export function

export default function QuickAction(props: QuickActionProps) {
  const { action, dir = "vertical", color: iconColor, ...xprops } = props;

  const tcolor = useColor();

  // const bg = useColorModeValue("white", "dark.cardBg");
  const shadow = useColorModeValue("0px 0.786517px 3.14607px rgba(229, 229, 229, 0.47)", "none");
  const color = useColorModeValue("grey.800", "grey.100");

  const info = useMemo(() => {
    const map: Record<QuickActionType, { icon: IconNames; label: string; color?: string; bg?: string }> = {
      buy: { icon: "buy", label: "Buy", color: "#365314", bg: tcolor("#F7FEE7", "#F7FEE7") },
      sell: { icon: "sell", label: "Sell", color: "#14532D", bg: tcolor("#F0FDF4", "#F0FDF4") },
      cards: { icon: "filledCards", label: "Cards", color: "#064E3B", bg: tcolor("#ECFDF5", "#ECFDF5") },
      bcpl: { icon: "bcpl", label: "BCPL", color: "white", bg: tcolor("secondary.400", "secondary.400") },
      staking: { icon: "staking", label: "Earn", color: "white", bg: tcolor("#FF7456", "#FF7456") },
      playToEarn: { icon: "playToEarn", label: "Play To Earn", color: "#1E3A8A", bg: tcolor("#EFF6FF", "#EFF6FF") },
      autoBuySell: { icon: "autoBuySell", label: "Auto Buy/Sell", color: "#581C87", bg: tcolor("#F5F3FF", "#F5F3FF") },
      yield: { icon: "yield", label: "Yield", color: "#0C4A6E", bg: tcolor("#F0F9FF", "#F0F9FF") },
      // --- more --- //
      more: { icon: "circleMore", label: "More", color: "white", bg: tcolor("secondary.400", "secondary.400") },
      bills: { icon: "bills", label: "Bills", color: "white", bg: tcolor("secondary.400", "secondary.400") },
      airtime: { icon: "airtimeAndData", label: "Airtime & Data", color: "#312E81", bg: tcolor("#EEF2FF", "#EEF2FF") },
      swap: { icon: "swap", label: "Swap", color: "#164E63", bg: tcolor("#ECFEFF", "#ECFEFF") },
      // cryptoAlert: {
      //   icon: "cryptoAlert",
      //   label: "Crypto Price Alert",
      //   color: "white",
      //   bg: tcolor("#2E2708", "#2E2708"),
      // },
      cryptoAlert: {
        icon: "cryptoAlert",
        label: "Crypto Price Alert",
        color: "#2E2708",
        bg: tcolor("#feffee", "#feffee"),
      },
      greenbox: {
        icon: "roundedGift",
        label: "Green Box",
        color: "#134E4A",
        bg: tcolor("#F0FDFA", "#F0FDFA"),
      },
    };

    return map[action];
  }, [action, tcolor]);

  const dirStyle = useMemo(() => {
    const map: Record<DirType, any> = {
      horizontal: {
        flexDir: "row",
        label: {
          ml: "10px",
          mt: "0",
        },
      },
      vertical: {
        flexDir: "column !important",
        justifyContent: "center",
        label: {
          mt: "10px",
          ml: "0",
        },
      },
    };

    return map[dir ?? "horizontal"];
  }, [dir]);

  return (
    <Button
      variant="unstyled"
      //   as={Card}
      minW="fit-content"
      maxW="fit-content"
      minH="fit-content"
      maxH="fit-content"
      h="fit-content"
      //   bg="white"
      shadow="none"
      borderRadius="10px"
      display="flex"
      placeSelf="center"
      {...dirStyle}
      {...xprops}
    >
      {/* <HStack w="100%" justifyContent="center"> */}
      <Box
        w="52px"
        h="52px"
        bg={info.bg}
        shadow={shadow}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
      >
        <Icon type={info.icon} boxSize="26px" color={info?.color ?? iconColor} />
      </Box>

      <Text
        fontFamily="var(--bitmama-fonts-heading)"
        color={color}
        fontSize="14px"
        fontWeight="600"
        ml={{ base: "0", "2sm": dirStyle?.label?.ml }}
        mt={dirStyle?.label?.mt}
      >
        {info.label}
      </Text>
      {/* </HStack> */}
    </Button>
  );
}
