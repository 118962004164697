const breakpoints = {
  base: "0",
  sm: "320px",
  smx: "375px",
  "1sm": "480px",
  "2sm": "768px",
  "3sm": "820px",
  "4sm": "1024px",
  md: "1180px",
  mdx: "1280px",
  lg: "1300px",
  xl: "1800px",
};

export default breakpoints;
