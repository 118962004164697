import { Box, Button, ButtonProps, Divider, Grid, Heading, HStack, Text, TextProps, useColorMode, VStack } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import { GreenBoxWallet, Group, Icon, IconProps } from "components";
import { useSidePage } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { useDefaultStyle, useDisclosures, useGreenboxWalletBalance, usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { useEffect, useMemo } from "react";
import { ClaimGreenBoxModal, FeatureUnavailableModal, FundWithdrawTransactions, GreenBoxTransactions, PreclaimGreenBox } from "ui";
import { switchStyle, when } from "utils";

interface GreenBoxPageProps extends PageProps {}
interface ActionButtonProps extends Omit<ButtonProps, "type"> {
  type: ActionButtonType;
}

interface ActionButtonTypeInfo {
  icon?: IconProps;
  button?: ButtonProps;
  label?: { text?: string } & TextProps;
}

type ActionButtonType = "send" | "claim";

interface IState {
  coin: string;
}

type ModalTypes = "preclaim" | "claim" | "featureUnavailable";

export default function GreenBoxPage(props: GreenBoxPageProps) {
  const { onOpen } = useSidePage();

  const { code } = useParams();

  const { appfigs } = useAppConfig();
  const { greenboxConfig } = appfigs ?? {};

  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const { getBalance, isLoading } = useGreenboxWalletBalance();

  const [state, set] = usePartialState<IState>({ coin: "usdt" });

  const isClaimDisabled = useMemo(() => greenboxConfig?.disableGreenboxClaim ?? false, [greenboxConfig]);
  const isCreateDisabled = useMemo(() => greenboxConfig?.disableGreenboxCreate ?? false, [greenboxConfig]);
  const isFundingDisabled = useMemo(() => greenboxConfig?.disableGreenboxFunding ?? false, [greenboxConfig]);
  const isWithdrawDisabled = useMemo(() => greenboxConfig?.disableGreenboxWithdraw ?? false, [greenboxConfig]);

  const balance = useMemo(() => getBalance(state?.coin ?? "usdt"), [getBalance, state?.coin]);
  //   const stroke = useColorModeValue("primary.600", "secondary.200");
  //   const accLinkBg = useColorModeValue("white", "dark.cardBg");
  //   const bColor = useColorModeValue("grey.150", "dark.border");

  //   const shadow = useColorModeValue("0px 2px 8px rgba(229, 229, 229, 0.47)", "0px 2px 8px rgba(9, 9,9, 4.7)");
  //   const { links, staking } = useLinks();

  //   const handleBack = () => {
  //     window.history.back();
  //   };

  useEffect(() => {
    if (code) open("preclaim")();
    // eslint-disable-next-line
  }, []);

  return (
    <VStack alignItems="flex-start">
      <Grid w="100%" templateColumns={{ base: "1fr", "3sm": "1fr .1fr", "4sm": "1fr .1fr 1fr" }} gap="100px">
        <Box w="100%" maxW="438px">
          <Box mb="42px">
            <Heading mt="12px" as="h4" fontSize="24px" fontWeight="bold">
              Greenbox
            </Heading>
          </Box>

          <Group mt="0">
            <GreenBoxWallet
              isLoading={isLoading}
              coin={state?.coin}
              value={balance ?? 0}
              onChange={(coin) => set({ coin })}
              onFund={when(isFundingDisabled, open("featureUnavailable"), () =>
                onOpen({ key: "FundGreenBox", data: { coin: state?.coin } })
              )}
              onWithdraw={when(isWithdrawDisabled, open("featureUnavailable"), () =>
                onOpen({ key: "WithdrawGreenBox", data: { coin: state?.coin } })
              )}
              // _fund={{ disabled: isFundingDisabled }}
              // _withdraw={{ disabled: isWithdrawDisabled }}
            />

            <HStack justifyContent="space-between" mt="28px !important">
              <ActionButton
                // disabled={isCreateDisabled}
                type="send"
                w="50%"
                onClick={when(isCreateDisabled, open("featureUnavailable"), () =>
                  onOpen({ key: "SendAGreenBox", data: { coin: state?.coin } })
                )}
              />
              <ActionButton type="claim" onClick={when(isClaimDisabled, open("featureUnavailable"), open("claim"))} />
            </HStack>
          </Group>

          <GreenBoxTransactions />
        </Box>

        <Box p={{ base: "0", "3sm": "20px" }} pos="relative">
          <Divider
            color="transparent"
            bg="transparent"
            orientation="vertical"
            pos="absolute"
            left="50%"
            transform="translateX(-50%)"
          />
        </Box>

        <FundWithdrawTransactions pos="sticky" top="126px" />
      </Grid>

      <ClaimGreenBoxModal isOpen={isOpen("claim")} onClose={close("claim")} />
      <PreclaimGreenBox isOpen={isOpen("preclaim")} onClose={close("preclaim")} />

      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </VStack>
  );
}

function ActionButton(props: ActionButtonProps) {
  const { type, ...xprops } = props;

  const { colorMode } = useColorMode();
  const { shadow, borderColor } = useDefaultStyle();

  const info = useMemo(() => {
    const map: Record<ActionButtonType, ActionButtonTypeInfo> = {
      send: {
        button: {
          bg: switchStyle(colorMode, { light: "#356660", dark: "#356660" }),
          color: switchStyle(colorMode, { light: "white", dark: "white" }),
          _focus: {
            shadow,
            borderColor,
          },
        },
        label: {
          text: "Send Greenbox",
        },
        icon: {
          type: "sendGreenbox",
          color: "#FCFDFD",
        },
      },
      claim: {
        button: {
          bg: switchStyle(colorMode, { light: "#F4E194", dark: "#F4E194" }),
          color: switchStyle(colorMode, { light: "#303131", dark: "#303131" }),
          _focus: {
            shadow,
            borderColor,
          },
        },
        label: {
          text: "Claim Greenbox",
        },
        icon: {
          type: "claimGreenbox",
          color: "#303131",
        },
      },
    };

    return map[type];
  }, [type, colorMode, shadow, borderColor]);

  return (
    <Button variant="unstyled" minW="200px" minH="96px" maxH="96px" borderRadius="13px" {...info?.button} {...xprops}>
      <VStack>
        <Icon type="sendOutline" {...info?.icon} />
        <Text mt="0 !important" fontWeight="300" fontFamily="var(--bitmama-fonts-heading)" {...info?.label}>
          {info?.label?.text}
        </Text>
      </VStack>
    </Button>
  );
}
