import { Box, BoxProps, Grid, useDisclosure } from "@chakra-ui/react";
import { AchievementsLayout, BadgeInfo, ConditionalRender, EmptyCrate, PageLoading, Title, TitleBar } from "components";
import BadgeDetailsModal from "../../../Modals/Achievements/BadgeDetails";
import { useGetBadgesQuery } from "apis";
import { useMemo, useState } from "react";

interface AchievementsBadgesPanelProps extends BoxProps {}

export function AchievementsBadgesPanel(props: AchievementsBadgesPanelProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [selected, setSelected] = useState("none");
  const { data, isLoading } = useGetBadgesQuery();

  console.clear();

  const badges = useMemo(() => {
    const keys = Object.keys(data ?? {});
    return keys.map((key) => ({ ...(data ?? {})[key], slug: key }));
  }, [data]);

  console.log("Badges ", { badges });

  const selected_badge_details = useMemo(() => badges.find((b) => b?.slug === selected), [badges, selected]);

  const handleClick = (e: any, slug: string) => {
    e.preventDefault();
    setSelected(slug);
    onOpen();
  };

  return (
    <AchievementsLayout pos="relative" px={{ sm: "0px", "2sm": "20px", md: "20px" }}>
      <TitleBar mb="58px">
        <Title fontWeight="700">All Badges</Title>
      </TitleBar>

      <Grid
        templateColumns={{ sm: "1fr", "2sm": "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
        gridRowGap={{ sm: "20px", "2sm": "20px", md: "43px" }}
        gridColumnGap={{ "2sm": "20px", md: "20px" }}
      >
        <ConditionalRender shouldRender={!isLoading && (badges ?? []).length > 0}>
          {badges.map((b, i) => (
            <BadgeInfo
              key={`badge-${i}`}
              name={b?.gameDetails?.name}
              maxProgressValue={b?.maxPoint}
              progressValue={b?.currentPoint}
              isLocked={!b?.lastLevel}
              currentLevel={b?.lastLevel}
              imgUrl={b?.gameDetails?.logoUrl}
              nextLevel={b?.nextLevel ?? 1}
              description={b?.description}
              levels={b?.gameDetails?.levelTarget ?? []}
              onClick={(e) => handleClick(e, b?.slug)}
              userLevels={b?.userLevels?.data}
            />
          ))}
        </ConditionalRender>
      </Grid>

      {!!isLoading && (badges ?? []).length < 1 && <PageLoading isLoading={true} />}

      {!isLoading && (badges ?? []).length < 1 && <EmptyCrate type="achievement" />}

      <BadgeDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        details={selected_badge_details}
        imgUrl={selected_badge_details?.gameDetails?.logoUrl}
      />

      <Box h="160px" display={{ sm: "block", md: "none" }} />
    </AchievementsLayout>
  );
}
