import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren } from "react";

interface FadeInOutProps {
  key: string;
}

export default function FadeInOut(props: PropsWithChildren<FadeInOutProps>) {
  const { key, children } = props;

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.div
        key={key}
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.98 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
