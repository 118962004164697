import { Box, VStack } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import { Link, Logo } from "components";
import { useDisclosures } from "hooks";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { PreclaimGreenBox } from "ui";
import { storeUTM } from "utils";
// import { when } from "utils";

interface PreclaimPageProps {
  path: string;
}

type ModalTypes = "preclaim";

// new Date().getTime();

export default function Preclaim(props: PreclaimPageProps) {
  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const { code } = useParams();

  // const storeUTM = useCallback(() => {
  //   const current_time = Date.now();
  //   let utm = ls.get(configs.UTM_KEY);
  //   if (!!utm) utm = JSON.parse(utm);
  //   else utm = {};

  //   // console.log("UTM", utm, typeof utm, !!utm);
  //   if (!!utm && !!utm?.exp && current_time >= utm?.exp) return;

  //   const exp = Date.now() + 60 * 60 * 1000;
  //   utm = { src_mode: "greenbox", src_ref: code, exp } as IUrchinTrackingModule;
  //   ls.set("utm", JSON.stringify(utm));
  // }, [code]);

  useEffect(() => {
    open("preclaim")();

    storeUTM({ src_mode: "greenbox", src_ref: code });
    //eslint-disable-next-line
  }, []);

  const host = window?.location?.host;

  return (
    <Box w="100%" h="100vh" overflow="hidden" bg="primary.default">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hurray 🎊 </title>
        <link rel="canonical" href={`${host}/gbx/${code}`} />
        <meta
          name="description"
          content="You just received a gift in your Bitmama Greenbox. Click this link or copy the code to claim your gift"
        />
      </Helmet>
      <VStack py="60px">
        <Link to="/">
          <Logo fontSize="54px" mb={{ sm: "12px", "2sm": "52px" }} _name={{ w: "110px" }} color="white" />
        </Link>
      </VStack>
      <PreclaimGreenBox isOpen={isOpen("preclaim")} onClose={close("preclaim")} />
    </Box>
  );
}
