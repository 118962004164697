import { useGetBanksDetailsQuery } from "apis";

export default function useBanks() {
  const { data: banks, isLoading, isFetching, isError, error } = useGetBanksDetailsQuery();

  return {
    banks,
    isLoading,
    isFetching,
    isError,
    error,
  };
}
