import { Button, Heading, ListItem, Text, UnorderedList, useMediaQuery, VStack } from "@chakra-ui/react";
import { AbstractModal, Icon } from "components";

interface WhyBVNModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function WhyBVNModal(props: WhyBVNModalProps) {
  const { isOpen, onClose } = props;

  //   const color = useColorModeValue("grey.400", "white");

  const [isMobile] = useMediaQuery("(max-width: 425px)");

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      showCloseButton={false}
      _content={{ maxW: isMobile ? "370px" : "618px" }}
    >
      <VStack p={{ base: "32px 8px", md: "74px" }}>
        <VStack m="0 auto" mb="40px">
          <Icon mb="24px !important" type="infoRounded" boxSize="43px" color="secondary.400" />

          <Heading as="h4" fontSize="24px" mb="24px !important" textAlign="center">
            We only need your BVN to access your:
          </Heading>
          {/* <Text textAlign="center" mt="20px" color={color}>
            Complete the security operation by clicking{" "}
            <Button
              fontSize="14px"
              maxW="unset"
              minH="unset"
              maxH="unset"
              px="1"
              py="0"
              variant="transparent"
              minW="unset"
              h="fit-content"
            >
              SEND CODE
            </Button>{" "}
            for Email & Phone verification, which will be sent to your email and phone respectively
          </Text> */}
          <UnorderedList pl="10px" alignSelf="flex-start">
            <ListItem fontWeight="500">First and Last names</ListItem>
            <ListItem fontWeight="500">Phone number</ListItem>
            <ListItem fontWeight="500">Date of Birth</ListItem>
          </UnorderedList>
        </VStack>

        <Text mb="8px !important" fontWeight={700} color="primary.default">
          P.S: We do not have access to any of your financial transactions
        </Text>
        <Text mb="33px !important" fontWeight={500} fontSize="14px">
          You can check your BVN by simply dialing the code *565*0# using the phone number you used to register your BVN
        </Text>
        <VStack px="46px" w="100%">
          <Button
            minW="unset"
            w={{ base: "initial", md: "100%" }}
            maxW="399px"
            fontFamily="var(--bitmama-fonts-heading)"
            onClick={onClose}
          >
            Got It
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
