import { Box, BoxProps, Grid, HStack, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import Card, { CardProps } from "components/Card/Card";
import { useDefaultStyle } from "hooks";
import { toLower } from "lodash";
import { useMemo } from "react";
import { currencyFormat, when } from "utils";
import { format, parseISO } from "date-fns";

type StatusType = "credit" | "debit" | "refund";

interface CreditCardTransactionItemProps extends CardProps {
  title?: string;
  description?: string;
  status?: StatusType;
  createdAt?: string;
  currency?: "usd";
  value?: { $numberDecimal: string };
}

interface IndicatorProps extends BoxProps {
  status?: StatusType;
}

function Indicator(props: IndicatorProps) {
  const bg = useMemo(() => {
    return {
      credit: "primary.500",
      debit: "error",
      refund: "primary.500",
    }[props.status ?? "credit"];
  }, [props.status]);
  return <Box boxSize="14px" borderRadius="50%" bg={bg} />;
}

export default function CreditCardTransactionItem(props: CreditCardTransactionItemProps) {
  const { currency, value, title, description, status, createdAt, ...xprops } = props;

  const { shadow } = useDefaultStyle();
  const bg = useColorModeValue("transparent", "transparent");
  const color = useColorModeValue("primary.default", "secondar.default");
  const borderColor = useColorModeValue("grey.100", "#00000026");

  return (
    <Card
      mt="0"
      as="button"
      p="12px 16px"
      minH="64px"
      shadow="none"
      bg={bg}
      border="0.5px solid transparent"
      borderTopColor={borderColor}
      borderBottomColor={borderColor}
      borderRadius="2px"
      _focus={{ shadow }}
      {...xprops}
      outline="1px solid transparent"
    >
      <HStack justifyContent="space-between">
        <Grid flex=".7" templateColumns="32px 1fr" alignItems="center" gap="8px">
          <Indicator status={toLower(status) as any} />
          <Stack justifyContent="flex-start" alignItems="baseline">
            <Text
              w="246px"
              fontSize="14px"
              fontWeight="500"
              color={color}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              textAlign="left"
            >
              {description ?? "Fund withdrawal"}
            </Text>
            <Text mt="0 !important" fontSize="12px" fontWeight="500" color="grey.500">
              {(!!createdAt && format(parseISO(createdAt), "eee, dd MMM yyyy, HH:mm")) ?? "Wed, 18 May 2022, 14:36"}
            </Text>
          </Stack>
        </Grid>

        <Text fontSize="14px" fontWeight="500" color={color}>
          {when(toLower(status) === "debit", "-", "")}
          {currency && currencyFormat(currency as any).format(+value?.$numberDecimal! ?? 0)}
        </Text>
      </HStack>
    </Card>
  );
}
