import { when } from "utils";
import en from "./en.json";

type StringKeyValue = { [key: string]: string | StringKeyValue };

const lang = { en };

function getCountryShortCode(country: string) {
  country = String(country).toLowerCase();
  const map: Record<string, string> = {
    nigeria: "ngn",
    ghana: "ghs",
    kenya: "kes",
    default: "ngn",
  };

  const _country = when(Object.keys(map).includes(country), country, "default");
  return map[_country];
}

export function geti18nTitle(country: string, key: string) {
  const keys = String(key).toLowerCase().split(".");
  const shortCode = getCountryShortCode(country);
  let temp: StringKeyValue | string = {};
  keys.forEach((key, i) => {
    if (!Object.keys(temp).length) temp = (en as any)[key];
    else {
      if (key !== "title") temp = (temp as StringKeyValue)[key];
      if (key === "title" && keys.length - 1 === i) temp = (temp as StringKeyValue)["title"] as StringKeyValue;
    }
  });
  if (!keys.includes("title")) temp = temp["title"] as StringKeyValue;
  return temp[shortCode] as string;
}

export function geti18n(country: string, language = "en") {
  const short_code = getCountryShortCode(country);
  const dict = (lang as any)[language];
  return function (key: string) {
    // TODO: Validate key. Key should be a dot separated string. eg. account.name.title or account.name or account;
    const keys = String(key).toLowerCase().split(".");
    let current_value: StringKeyValue = dict;

    for (const key of keys) {
      if (typeof current_value !== "object") continue;
      if (!Object.hasOwn(current_value, key)) return null; //throw new Error(`${key} not found in ${short_code}.json file`);
      current_value = (current_value as any)[key];
    }

    return {
      value: current_value,
      country_value: (current_value as any)[short_code] ?? null,
    };
  };
}
