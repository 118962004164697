import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { AbstractModal, Icon } from "components";
import configs from "config";
// import { isTesting } from "config";
// import { useSidePage } from "contexts";
import ls from "utils/secureStorage";

interface Enable2FAModalProps extends Omit<ModalProps, "children"> {}

export default function Enable2FAModal(props: Enable2FAModalProps) {
  const { ...xprops } = props;
  //   const bColor = useColorModeValue("grey.150", "dark.border");

  const handleRemind = (e: any) => {
    e.preventDefault();
    ls.set(configs.TWO_FA_REMIND, "remind");
    props.onClose();
  };

  return (
    <AbstractModal
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        // position: { base: "absolute", "1sm": "relative" },
      }}
      {...xprops}
    >
      <Box p={{ base: "32px 40px", "1sm": "44px 80px" }}>
        <VStack mb="60px !important">
          <Icon type="errorMoji" boxSize="44px" />
          <Heading mb="10px !important" as="h4" fontSize="32px">
            Enable 2FA
          </Heading>
          <Text fontWeight="500">2FA adds an extra level of security to your account</Text>
        </VStack>

        <VStack mt="24px !important" px="46px" w="100%">
          <Button minW="unset" w="100%" onClick={() => navigate(`/account/profile?tab=security`)}>
            Enable 2FA Now
          </Button>
          <Button variant="outline" textDecoration="underline" minW="unset" w="100%" onClick={handleRemind}>
            Remind me later
          </Button>
        </VStack>
      </Box>
    </AbstractModal>
  );
}
