import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { AbstractModal, GenericTable, TableItem } from "components";
import { useSelector } from "hooks";
import { P2PListingTradeRo } from "interfaces";
import { useCallback } from "react";
import { selectUser } from "store/slices";
import { IStateExtension } from "ui/P2P/Features/CreateAd";
import { when } from "utils";

interface AdPreviewModalProps extends Omit<ModalProps, "children">, IStateExtension {
  onPost: () => void;
  isUpdate?: boolean;
}

export default function AdPreviewModal(props: AdPreviewModalProps) {
  const { isOpen, onClose, state, isUpdate, onPost } = props;

  const ro = useTransformStateToP2PListingTradeRo(state);

  console.log("Preview Ro", ro);

  return (
    <AbstractModal isOpen={isOpen} onClose={onClose} _content={{ minW: "calc(100% - 40vw)" }}>
      <VStack py="80px" px="20px">
        <Box display="flex" alignItems="center" flexDir="column">
          <Heading as="h5" fontSize="24px" fontWeight="bold">
            Preview your Ad
          </Heading>

          <Text mt="38px" mb="38px">
            This is how your Ad will appear on the listing page
          </Text>
        </Box>

        <Box bg="#fff" borderRadius="20px" p="48px 36px">
          <GenericTable headers={["Available Rates", "Payment Method", "Seller Details", "Action"]}>
            <TableItem key={`p2p-create-ad-preview`} {...ro} _text={{ color: "black" }} _username={{ _link: { color: "black" } }} />
          </GenericTable>
        </Box>

        <VStack mt="74px !important">
          <Button onClick={onPost}>Proceed to {when(!!isUpdate, "Update", "Post")}</Button>
          <Button variant="transparent" textDecoration="underline" onClick={onClose}>
            Go back
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}

function useTransformStateToP2PListingTradeRo(state: IStateExtension["state"]) {
  const { profile } = useSelector(selectUser);

  const adsType = `${state?.type ?? "buy"}ing`;

  const mapSrcUnitField = useCallback(
    (type: string) => {
      const buyMap: Record<string, "destinationUnit" | "sourceUnit"> = {
        sell: "destinationUnit",
        buy: "sourceUnit",
      };
      return state[buyMap[type]];
    },
    [state]
  );

  const currency = mapSrcUnitField(state?.type!);
  const volume = { $numberDecimal: state?.volume };
  const userData = { username: profile?.username, presence: { status: "online" } } as any;

  const tradeOption = {
    acceptPartialFilling: false,
    exactFilling: 0,
    unitCost: state?.unitCost ?? 0,
    maximumFilling: +(state?.maximumFilling ?? 0),
    minimumFilling: +(state?.minimumFilling ?? 0),
  } as P2PListingTradeRo["tradeOption"];

  const _paymentOptions = !!state?.useInternalWallet
    ? [...(state?.paymentOptions ?? []), { method: state?.selectedMethod!, source: "internal" }]
    : [...(state?.paymentOptions ?? [])];

  const paymentOption: any[] = _paymentOptions.map((opt) => ({
    ...opt,
    source: when(state?.selectedMethod! === "bankTransfer", "external", "internal"),
  }));

  return {
    ...state,
    volume,
    adsType,
    userData,
    currency,
    tradeOption,
    paymentOption,
    coin: state?.token!,
    currentVolume: volume,
  } as Partial<P2PListingTradeRo>;
}
