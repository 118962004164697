import { Badge, Button, FormControl, HStack, Stack, Text, useDisclosure, useUpdateEffect, VStack } from "@chakra-ui/react";
import { CryptoTxInput, FormInfo, Icon, InputLabel, Option, PageLoading } from "components";
import { usePartialState, useWalletBalance } from "hooks";

import configs, { isTesting } from "config";
import { useCallback, useMemo } from "react";
import { useGetMinimumWithdrawalAmountQuery, useGetPairsQuery, useGetSwapRateQuery, useSwapMutation } from "apis";
import { uniq } from "lodash";
import { toCoinLocale, toPrecision } from "utils";
import SwapPreview from "../Modals/SwapPreview";
import { PageProps } from "interfaces";
import ls from "utils/secureStorage";

interface NewSwapProps extends PageProps {}

export interface IState {
  fromCoin: string;
  toCoin: string;
  rate: 0;
  fromAmount: string;
  toAmount: string;

  changeRef: "from" | "to";

  agreed: boolean;
}

interface InfoProps {
  label: string;
  description: string;
}

export default function NewSwap(props: NewSwapProps) {
  const { isFetchingUser } = props;

  // const { shadow } = useDefaultStyle();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const bg = useColorModeValue("primary.400", "secondary.600");

  const { getBalance, cryptos, isLoading: isWalletLoading } = useWalletBalance();

  const lastSwappedTokens = useMemo(() => {
    type TokenType = { from: string; to: string };
    const _toks = ls.get(configs.LAST_SWAP_TOKENS);
    console.log("Last swap tokens", _toks);
    const tokens: TokenType | undefined = !!_toks && typeof _toks === "string" ? JSON.parse(_toks) : _toks;
    if (!!tokens) return tokens;
    return { from: isTesting ? "tbtc" : "btc", to: "usdt" };
  }, []);

  const [state, set, reset] = usePartialState<IState>(
    {
      fromCoin: lastSwappedTokens.from,
      toCoin: lastSwappedTokens.to,
      rate: 0,
    },
    [lastSwappedTokens]
  );

  const { pairs, isFetchingPairs, coinMap } = useSwapPairs(state?.fromCoin!);

  const { data: rateData, isFetching: isRating } = useGetSwapRateQuery(
    { base: coinMap(state?.fromCoin!), sub: coinMap(state?.toCoin!) },
    { skip: !state?.fromCoin && !state?.toCoin }
  );

  const { data: minimumWithdrawData, isLoading: isLoadingMinimum } = useGetMinimumWithdrawalAmountQuery(
    { coin: state?.fromCoin! },
    {
      skip: !state?.fromCoin,
    }
  );

  console.log("Checked swap rate", rateData);

  const rate = useMemo(() => {
    // return rateData?.rate /// this was the initial rate field used to convert swap rate data
    return rateData?.swapRate;
  }, [rateData]);

  const [_swapPreview, { data: swapDataPreview, isLoading: isSwapPreviewLoading }] = useSwapMutation();

  const swapFee = useMemo(
    () => swapDataPreview?.fee ?? +(state?.toAmount ?? 0) * (configs.swap.fee / 100),
    [state?.toAmount, swapDataPreview?.fee]
  );

  const swapFeeWithPrecision = useMemo(() => {
    const map: Record<string, number> = {
      [state?.toCoin!]: 5,
      btc: 8,
      eth: 8,
      tbtc: 8,
      teth: 8,
      usdt: 4,
      cusd: 4,
      usdc: 4,
      ceur: 4,
      celo: 4,
    };

    const precision = map[state?.toCoin ?? "btc"];
    return toPrecision(swapFee ?? 0, state?.toCoin!, true, precision, true);
  }, [swapFee, state?.toCoin]);

  const balance = useMemo(() => getBalance((state?.fromCoin ?? "ngn") as any) ?? 0, [getBalance, state?.fromCoin]);

  const possibleMax = useMemo(() => {
    if (balance <= 0) return 0;
    else return +toPrecision(balance - 0.01, state?.fromCoin ?? "btc", false);
  }, [balance, state?.fromCoin]);

  const isPageLoading = useMemo(() => isFetchingUser || isWalletLoading, [isFetchingUser, isWalletLoading]);
  const isInsufficientBalance = useMemo(() => !!state?.fromAmount && +state?.fromAmount > +balance, [state?.fromAmount, balance]);
  const minimumWithdraw = useMemo(() => {
    return (minimumWithdrawData?.min ?? 0) as number;
  }, [minimumWithdrawData]);

  const isLessThanMinimum = useMemo(
    () => !!state?.fromAmount && Number(state?.fromAmount) < +minimumWithdraw,
    [state?.fromAmount, minimumWithdraw]
  );

  const isDisabled = useMemo(
    () =>
      !(state?.fromAmount && state?.toAmount && state?.fromCoin && state?.toCoin) ||
      isRating ||
      isFetchingPairs ||
      isInsufficientBalance ||
      isSwapPreviewLoading ||
      isLessThanMinimum,
    [state, isRating, isFetchingPairs, isInsufficientBalance, isLessThanMinimum, isSwapPreviewLoading]
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const result = await _swapPreview({
      swapRate: state?.rate,
      destToken: state?.toCoin,
      sourceToken: state?.fromCoin,
      sourceAmount: state?.fromAmount,
      destAmount: state?.toAmount,
      isPreview: true,
    }).unwrap();

    console.log("Swap Preview result", result);

    onOpen();

    // onOpen();
  };

  useUpdateEffect(() => {
    // if (rate && state?.fromAmount && state?.changeRef === "from") {
    //   if (configs.stableCoins.includes(state?.fromCoin!)) set({ rate, toAmount: String(+state?.fromAmount / rate) });
    //   if (!configs.stableCoins.includes(state?.fromCoin!)) set({ rate, toAmount: String(+state?.fromAmount * rate) });
    // }
    // if (rate && state?.toAmount && state?.changeRef === "to") {
    //   if (configs.stableCoins.includes(state?.fromCoin!)) set({ rate, fromAmount: String(+state?.toAmount * rate) });
    //   if (!configs.stableCoins.includes(state?.fromCoin!)) set({ rate, fromAmount: String(+state?.toAmount / rate) });
    // }

    if (rate && state?.fromAmount && state?.changeRef === "from") {
      set({ rate, toAmount: String(+state?.fromAmount * rate) });
    }
    if (rate && state?.toAmount && state?.changeRef === "to") {
      set({ rate, fromAmount: String(+state?.toAmount / rate) });
    }
  }, [rate, state?.fromAmount, state?.toAmount, state?.changeRef]);

  return (
    <>
      {isPageLoading && <PageLoading isLoading={isPageLoading} />}
      {!isPageLoading && (
        <Stack as="form" py={{ base: "24px", "3sm": "52px" }} onSubmit={handleSubmit}>
          <FormControl mb="40px !important">
            <HStack justifyContent="space-between">
              <InputLabel>You Swap</InputLabel>

              {!isNaN(balance) && (
                <FormInfo
                  isInvalid={isInsufficientBalance}
                  mt="10px"
                  info="Available Balance: "
                  description={`${toPrecision(balance, state?.fromCoin! as any)}`}
                />
              )}
            </HStack>
            <CryptoTxInput
              isInvalid={isInsufficientBalance || isLessThanMinimum}
              placeholder={`Enter ${toCoinLocale(state?.fromCoin ?? "btc")} Amount`}
              coinValue={state?.fromCoin}
              value={state?.fromAmount ?? ""}
              onMax={() => set({ fromAmount: String(possibleMax), changeRef: "from" })}
              onCoinChange={(coin) => set({ fromCoin: coin, changeRef: "from" })}
              onChange={(e) => set({ fromAmount: e.target.value, changeRef: "from" })}
              // _rightEl={{
              //   right: { base: "99px", "2sm": "118px" },
              //   px: "10px",
              // }}
              px="0"
              pl="20px"
            >
              {(cryptos ?? [])?.map((crypto) => (
                <Option key={`buy-crypto-${crypto?.coin}`} value={crypto?.coin} coin={crypto?.coin as any}>
                  {toCoinLocale(crypto?.coin)}
                </Option>
              ))}
            </CryptoTxInput>

            {!isLoadingMinimum && minimumWithdraw && (
              <FormInfo
                mt="10px"
                isInvalid={isLessThanMinimum}
                info={`Minimum ${toCoinLocale(state?.fromCoin!)} to swap:`}
                description={`${toPrecision(minimumWithdraw, state?.fromCoin!)}`}
              />
            )}
          </FormControl>

          <FormControl>
            <InputLabel isLoading={isFetchingPairs || isRating}>You get approximately</InputLabel>
            <CryptoTxInput
              // isInvalid={isInsufficientBalance}
              placeholder={`Enter ${toCoinLocale(state?.toCoin ?? "btc")} Amount`}
              coinValue={state?.toCoin}
              value={state?.toAmount ?? ""}
              onCoinChange={(coin) => set({ toCoin: coin, changeRef: "from" })}
              onChange={(e) => set({ toAmount: e.target.value, changeRef: "to" })}
            >
              {(pairs ?? [])?.map((coin) => (
                <Option key={`pair-${coin}`} value={coin} coin={coin as any}>
                  {toCoinLocale(coin)}
                </Option>
              ))}
            </CryptoTxInput>
          </FormControl>

          <Stack mt="48px !important">
            {/* <Info label="Network fee" description="0.00801861 ETH" /> */}
            {/* <Info label="Estimated arrival" description="Instant" /> */}
            {/* {!isNaN(swapFee) && <Info label="Swap fee 0.15%" description={`${swapFeeWithPrecision}`} />} */}
            {!isNaN(swapFee) && <Info label="Swap fee" description={`${swapFeeWithPrecision}`} />}

            {/* <Checkbox
              mt="32px !important"
              size="lg"
              colorScheme="green"
              isChecked={state?.agreed ?? false}
              onChange={(e) => set({ agreed: e.target.checked })}
              sx={{
                ".chakra-checkbox__control": {
                  borderRadius: "50%",
                  _focus: {
                    shadow,
                  },
                },
                ".chakra-checkbox__control[data-checked]": {
                  bg,
                },
                ".chakra-checkbox__label": {
                  fontSize: "sm",
                },
              }}
            >
              I agree with Terms of Use, Privacy Policy and AML/KYC
            </Checkbox> */}
          </Stack>

          <VStack mt="60px !important">
            <Button
              type="submit"
              isDisabled={isDisabled}
              isLoading={isSwapPreviewLoading}
              minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
            >
              Swap
            </Button>
          </VStack>
        </Stack>
      )}

      <SwapPreview
        {...{
          state,
          set,
          reset,
          rate,
          isOpen,
          onClose,
          swapFee: swapFeeWithPrecision as string,
          previewData: swapDataPreview,
          isSwapPreviewLoading,
        }}
      />
    </>
  );
}

function Info(props: InfoProps) {
  const { label, description } = props;

  return (
    <HStack fontSize="sm" fontWeight="500">
      <HStack>
        <Icon boxSize="18px" type="info" />
        <Text color="#2F80ED">{label}</Text>
      </HStack>

      <Badge colorScheme="green">{description}</Badge>
    </HStack>
  );
}

function useSwapPairs(coin: string) {
  const coinMap = useCallback((token: string) => {
    const map = {
      [token]: token,
      tbtc: "btc",
      teth: "eth",
    };

    return map[token ?? "tbtc"];
  }, []);

  const { data: pairData, isFetching: isFetchingPairs } = useGetPairsQuery(coinMap(coin), { skip: !coin });

  const pairs = useMemo(
    () => uniq(pairData?.pairs ?? []).filter((pair) => !["usdt-tron", "usdt-matic", "usdc-matic"].includes(pair as string)),
    [pairData]
  ) as string[];

  return { pairs, isFetchingPairs, coinMap };
}
