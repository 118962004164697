import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, FormControl, Heading, HStack, useColorModeValue, useToast, VStack } from "@chakra-ui/react";
import { Group, Icon, InputLabel, MainLayoutContainer, PageMotion, RadixSelect, Option } from "components";
// import { useBuiltInFn } from "hooks";
import { useSetDefaultCurrencyMutation } from "apis";
import { useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "hooks";
import { getProfile, selectUser } from "store/slices";
import configs from "config";
import { toCoinLocale } from "utils";

interface DefaultProps {}

export default function Default(props: DefaultProps) {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isCurrencySet = useRef(false);
  const { profile } = useSelector(selectUser);
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const [currency, setCurrency] = useState("ngn");
  const defaultCurrency = useMemo(() => profile?.defaultCurrency, [profile?.defaultCurrency]);
  const [_setCurrency, { isLoading, isSuccess, reset }] = useSetDefaultCurrencyMutation();
  // const { goBack } = useBuiltInFn();

  const updateProfile = useCallback(() => dispatch(getProfile()), [dispatch]);

  console.clear();
  console.log("Default Currency", defaultCurrency);

  const goBack = () => {
    navigate(-1);
  };

  const handleCurrency = (value: string /* event: React.ChangeEvent<HTMLSelectElement> */) => {
    setCurrency(value);
  };

  const handleSubmit = async (event: React.FormEvent<any>) => {
    event.preventDefault();
    await _setCurrency({ currency });
  };

  useEffect(() => {
    if (!isCurrencySet.current && !!defaultCurrency) {
      setCurrency(defaultCurrency);
      isCurrencySet.current = true;
    }

    return () => {
      isCurrencySet.current = false;
    };
  }, [defaultCurrency]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        position: "bottom-right",
        title: "Success",
        description: "Default currency updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      updateProfile();
      reset();
      isCurrencySet.current = false;
    }
  }, [isSuccess, toast, reset, updateProfile]);
  return (
    <PageMotion subpage key="AccountBankList" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              Default Currency
            </Heading>
          </HStack>
        </Box>

        <Box as="form" onSubmit={handleSubmit}>
          <Group>
            <FormControl>
              <InputLabel htmlFor="currency" mb="10px">
                Currency
              </InputLabel>
              {/* <Select id="currency" name="currency" value={currency} onChange={handleCurrency}>
                <option value="ngn">NGN</option>
                <option value="ghs">GHS</option>
                <option value="kes">KES</option>
              </Select> */}

              <RadixSelect
                use_coin_icon
                value={currency}
                aria-label="Currency"
                placeholder="Select Currrency"
                onChange={handleCurrency}
                _trigger={{
                  w: "100%",
                }}
              >
                {configs.fiats.map((coin) => (
                  <Option key={coin} value={coin} coin={coin as any}>
                    {toCoinLocale(coin)}
                  </Option>
                ))}
              </RadixSelect>
            </FormControl>

            <VStack mt="70px !important">
              <Button
                type="submit"
                isLoading={isLoading}
                fontFamily="var(--bitmama-fonts-heading)"
                minW={{ base: "100%", "4sm": "400px" }}
              >
                Save Changes
              </Button>
            </VStack>
          </Group>
        </Box>
      </MainLayoutContainer>
    </PageMotion>
  );
}
