import { geti18nTitle as getTitle } from "i18n/getters";
import { useCallback } from "react";
import { selectUser } from "store/slices";
import { when } from "utils";
import useSelector from "./useSelector";

export function useI18n() {
  const { profile } = useSelector(selectUser);

  const geti18nTitle = useCallback(
    (key: string, dfault?: string) => when(!!profile, getTitle(profile?.country!, key), !!dfault ? dfault : "Missing Title"),
    [profile]
  );

  return { geti18nTitle };
}
