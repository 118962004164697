import { useAppConfig } from "contexts/appconfig.context";
import { useAuth, useInlineWebPush, useServiceWorkerReg, useShortcutKeys } from "hooks";
import { EventManager } from "libs";
import { mountListeners } from "listeners";
import { useEffect, useRef } from "react";
import Router from "Router";
// import { driver } from "driver.js";
// import "driver.js/dist/driver.css";
// import "styles/driver.scss";

import ls from "utils/secureStorage";

// let is_tour_started = false;

function App() {
  const { appfigs } = useAppConfig();
  const initiated = useRef(false);

  const { auth } = useAuth();
  useServiceWorkerReg();
  useInlineWebPush(!initiated.current);

  console.log("App Configs", appfigs);

  useShortcutKeys(["/", "c", "l", "s"], () => {
    ls.clear();
    window?.location?.reload();
    console.log("Local Storage Cleared");
  });

  // const driverObj = driver({
  //   showProgress: true,
  //   nextBtnText: "Next",
  //   prevBtnText: "Previous",

  //   steps: [
  //     {
  //       element: "#toggle-mode",
  //       popover: { title: "Toggle Dark/Light Mode 🔆", description: "Toggle between light mode or dark mode" },
  //     },
  //     {
  //       element: "#notification-btn",
  //       popover: { title: "App Notifications 🔔", description: "Check all your App notifications here" },
  //     },
  //     {
  //       element: "#support-btn",
  //       popover: { title: "Support/Help 📞", description: "Get instant help from our customer support team" },
  //     },
  //     {
  //       element: "#total-assets",
  //       popover: {
  //         title: "Fiat Wallets Overview 🗂",
  //         description:
  //           "A fiat wallet is a digital wallet that stores your government-issued currency e;g Naira, Cedis, Shillings. To carry out transactions on Bitmama like Buy Crypto, you first of all have to fund your Naira Wallet and then exchange it with any Cryptocurrency like USDT",
  //       },
  //     },
  //     {
  //       element: "#buy-feature",
  //       popover: {
  //         title: "Buy Crypto 💵",
  //         description: "Buy any Cryptocurrency like Bitcoin,Ethereum directly at a good rate into your crypto wallets",
  //       },
  //     },
  //     {
  //       element: "#sell-feature",
  //       popover: {
  //         title: "Sell Crypto 💵",
  //         description:
  //           "Ready to sell your crypto? With our Sell feature, you can easily convert your BTC, ETH, or other crypto back into fiat currency like Naira. Just select the cryptocurrency you want to sell and the amount, and the rest is taken care of automatically!",
  //       },
  //     },
  //     {
  //       element: "#vcc-feature",
  //       popover: {
  //         title: "Crypto Cards 💳",
  //         description:
  //           "Our virtual cards are a convenient way to make online purchases like shopping and paying for Ads. With Bitmama, you can easily create virtual cards that work just like a regular debit card except this time, instead of Naira, you fund your card with stable-coins like USDT, USDC or cUSD. You can use them to pay for services online, without having to worry about security or fraud.",
  //       },
  //     },
  //     {
  //       element: "#greenbox-feature",
  //       popover: {
  //         title: "Greenbox 🎁",
  //         description:
  //           "Lorem ipsum dolor sit amet consectetur. Lorem mi vel justo aliquet id proin neque. Fermentum sit dignissim vulputate placerat quis tellus Fermentum sit dignissim vulputate placerat quis tellus.",
  //       },
  //     },
  //     {
  //       element: "#airtime-feature",
  //       popover: {
  //         title: "Data and Airtime 📱",
  //         description:
  //           "Simply navigate to the Data or Airtime tab on your Bitmama app, select your preferred network provider and the amount you want to purchase. Then, confirm the transaction and the Data or Airtime will be added to your phone instantl",
  //       },
  //     },
  //     // { element: ".top-nav", popover: { title: "Title", description: "Description" } },
  //     // { element: ".sidebar", popover: { title: "Title", description: "Description" } },
  //     // { element: ".footer", popover: { title: "Title", description: "Description" } },
  //   ],
  // });
  // useEventListener("Buy Initiated", (data) => console.log("Buy Initiated", data));

  useEffect(() => {
    if (!!auth?.isSignedIn && initiated.current === false) {
      initiated.current = true;
    }
    return () => {
      initiated.current = false;
    };
  }, [auth?.isSignedIn]);

  useEffect(() => {
    mountListeners();
    EventManager.subscribeEvents();

    return () => {
      EventManager.unsubscribeEvents();
    };
  }, []);

  // useEffect(() => {
  //   if (!is_tour_started) {
  //     driverObj.drive();
  //   }
  // }, [driverObj]);

  return <Router />;
}

export default App;
