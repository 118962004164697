export function dataURLtoFile(dataurl: string, filename: string) {
  // var arr = dataurl.split(","),
  //   mime = arr[0]?.match(/:(.*?);/)[1],
  // bstr = atob(arr[1]),

  let tokens = dataurl.split(",");
  let mime = tokens[0].match(/:(.*?);/)?.[1];
  let imageType = mime?.split("/")[1];
  let buffer = Buffer.from(tokens[1], "base64");
  // let n = buffer.length;
  // let u8arr = new Uint8Array(n);

  // console.log("Picture Tokens", tokens, mime, buffer);

  // while (n--) {
  //   u8arr[n] = buffer.charCodeAt(n);
  // }

  return new File([buffer], `${filename}.${imageType}`, { type: mime });
}

//return a promise that resolves with a File instance
// function urltoFile(url, filename, mimeType){
//     return (fetch(url)
//         .then(function(res){return res.arrayBuffer();})
//         .then(function(buf){return new File([buf], filename,{type:mimeType});})
//     );
// }
