import {
  Box,
  BoxProps,
  Button,
  Heading,
  HStack,
  IconButton,
  IconButtonProps,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ConditionalRender, CopyButton, Icon, IconNames, PageLoading, useModalView, Username } from "components";
import { P2PSessionRo, PageProps } from "interfaces";
import { useCallback, useMemo } from "react";

import toLower from "lodash/toLower";
import { useColor, useCopy, useDisclosures, useISOCountDown, useSelector, useSupport, useUpdateP2POrder } from "hooks";
import { currencyFormat, toCoinLocale, toPrecision, when } from "utils";
import { AnimatePresence, motion } from "framer-motion";
import { Rating } from "@mui/material";
import { ConfirmationModal } from "ui";
import { useParams } from "@reach/router";
import { useRateTradeMutation } from "apis";
import { selectUser } from "store/slices";
import { useIntercom } from "react-use-intercom";
import { UploadP2PPaymentProof } from "ui/P2P/Modals/UploadPaymentProof";
// import { StarIcon } from "@chakra-ui/icons";

interface TransactionHubProps extends PageProps, Partial<P2PSessionRo> {
  isLoading?: boolean;
  mapKey: string;

  onChatClick?: () => void;
  onChatOff?: (update?: any) => void;
  setHideBackButton: (value: boolean) => void;
}

interface ActionButtonProps extends Omit<IconButtonProps, "icon" | "aria-label"> {
  icon: IconNames;
}

type ModalType = "uploadProof";
export function TransactionHub(props: TransactionHubProps) {
  const { isLoading, counterParties, id } = props;

  const color = useColor();
  const { onCopy } = useCopy(id!);
  const view = useContentMapper(props);
  const bg = useColorModeValue("#041815", "#041815");

  const { isOpen, open, close } = useDisclosures<ModalType>();
  const { profile } = useSelector(selectUser);

  const tradeOwner = useMemo(() => counterParties?.find((cp) => toLower(cp.role) === "tradeowner"), [counterParties]);
  const trader = useMemo(() => counterParties?.find((cp) => toLower(cp.role) === "trader"), [counterParties]);

  const hasPaymentProof = useMemo(() => (!!tradeOwner?.proofOfWaiver || !!trader?.proofOfWaiver) ?? false, [tradeOwner, trader]);
  const isProofUploader = useMemo(() => {
    // the buyer will always be the one to upload a proof of payment
    const buyer = counterParties?.find((cp) => cp?.perspective === "buyer");
    if (buyer?.user?.userId === profile?._id) return true;
    return false;
  }, [counterParties, profile]);

  console.log("IsProofUploader", isProofUploader);

  // const proofOfWaiverFileName = useMemo(() => {
  //   const tokens = String(tradeOwner?.proofOfWaiver ?? trader?.proofOfWaiver ?? "").split("/");
  //   return tokens[tokens?.length - 1];
  // }, [tradeOwner, trader]);

  return (
    <Box mt="50px">
      <HStack
        flexDir={{ sm: "column", "2sm": "row", "3sm": "row", md: "row" }}
        alignItems={{ sm: "flex-start", "2sm": "center", "3sm": "center", md: "center" }}
        my="20px"
        justifyContent="space-between"
      >
        <HStack>
          <Text fontSize="14px" mr="0px !important" fontWeight="600" color={color("black", "white")}>
            Contract ID:
          </Text>

          <CopyButton ml="0 !important" onClick={onCopy}>
            {id}
          </CopyButton>
        </HStack>

        <ConditionalRender shouldRender={hasPaymentProof}>
          <HStack ml={{ sm: "0px !important" }} px={{ sm: "0", "2sm": "4px", "3sm": "4px", md: "4px" }}>
            <Text fontSize="14px" mr="10px !important" fontWeight="600" color={color("black", "white")}>
              Proof of payment
            </Text>

            <Button
              ml="0 !important"
              w="fit-content"
              // leftIcon={<Box boxSize="16px" as={FileIcon} />}
              fontSize="sm"
              fontWeight="600"
              variant="link"
              color="secondary.400"
              textTransform="unset"
              textDecoration="underline"
              onClick={() => window.open(tradeOwner?.proofOfWaiver ?? trader?.proofOfWaiver, "_blank")}
            >
              {/* {proofOfWaiverFileName} */}
              Click to view
            </Button>
          </HStack>
        </ConditionalRender>
        <ConditionalRender shouldRender={isProofUploader && !hasPaymentProof}>
          <HStack ml={{ sm: "0px !important" }} px={{ sm: "0", "2sm": "4px", "3sm": "4px", md: "4px" }}>
            <Button
              ml="0 !important"
              w="fit-content"
              // leftIcon={<Box boxSize="16px" as={FileIcon} />}
              size="sm"
              fontSize="sm"
              fontWeight="600"
              variant="solid"
              // color="secondary.400"
              textTransform="unset"
              onClick={open("uploadProof")}
            >
              {/* {proofOfWaiverFileName} */}
              Upload Proof Of Payment
            </Button>
          </HStack>

          <UploadP2PPaymentProof isOpen={isOpen("uploadProof")} onClose={close("uploadProof")} />
        </ConditionalRender>
      </HStack>

      <Box py="34px" bg={bg} borderRadius="16px">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`is-${when(!!isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {!isLoading && view}
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  );
}

function ReleaseAsset(props: TransactionHubProps) {
  const { user, counterParties, contractState, volume, amount, coin, currency, onChatClick, onChatOff, setHideBackButton } = props;

  const { ref } = useParams();
  const { shutdown } = useIntercom();
  const [id, frozenRef] = String(ref ?? "").split("-");
  const { isOpen, /*onOpen,*/ onClose } = useDisclosure();
  const { updateOrder, isUpdating } = useUpdateP2POrder(id, frozenRef);

  const { onNext } = useModalView();

  const nextView = () => {
    onNext();
    setHideBackButton(true);
    onChatOff && onChatOff();
  };

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  const ownerRole = useMemo(() => appropriateSessionOwner?.role, [appropriateSessionOwner]);

  const trader = useMemo(
    () => (counterParties ?? [])?.find((cp) => toLower(cp.role) === (ownerRole === "trader" ? "tradeowner" : "trader")),
    [counterParties, ownerRole]
  );

  const { timer: countDown, expired } = useISOCountDown(trader?.timeline?.dueAt ?? "", `${trader?.timeline?.duration} : 00mins`);

  const currentTradeOwnerStatus = useMemo(() => `${contractState}&${trader?.obligationStatus}`, [contractState, trader]);
  const isPaid = useMemo(() => currentTradeOwnerStatus === "open&fulfilled", [currentTradeOwnerStatus]);

  const handleChat = () => {
    !!onChatClick && onChatClick();
    shutdown();
  };

  return (
    <VStack>
      <VStack maxW="380px" textAlign="center" color="white" my="30px !important">
        <Icon type="timer" boxSize="28px" />
        <Heading as="h4" mt="12px !important" fontSize="20px" fontFamily="var(--bitmama-fonts-heading)">
          {when(!isPaid, "Waiting for Payment", "Buyer has confirmed payment")}
        </Heading>
        <Heading as="h6" mt="10px !important" fontSize="14px" fontFamily="var(--bitmama-fonts-heading)" color="error">
          {when(
            isPaid,
            expired ? "Release the coins now or dispute the payment" : `Release coins before ${countDown}`,
            expired ? countDown : `Payment to be made in ${countDown}`
          )}
        </Heading>
        <Text mt="28px !important" fontSize="1.5rem" fontWeight="900">
          {toPrecision(volume ?? 0, coin!)} - {currencyFormat(currency as any).format(amount ?? 0)}
        </Text>
        <Text mt="28px !important" fontWeight="500">
          {when(
            isPaid && !expired,
            "If you do not receive the payment after the countdown runs out, you can raise a dispute to resolve the trade manually with an admin witness.",
            "Click any of the actions below to follow up on your transaction with"
          )}
        </Text>
        <Username username={`@${trader?.user?.username}`} hidePresence _link={{ color: "white" }} />
      </VStack>

      {/* <HStack mt="32px !important" gridGap="30px">
        {isPaid && expired && (
          <ActionButton isLoading={isUpdating} icon="dispute" disabled={!expired || isUpdating} onClick={onOpen} />
        )}
        <ActionButton icon="chat" />
        <ActionButton disabled icon="inAppCall" />
      </HStack> */}

      <Actions
        onChatOff={onChatOff}
        _dispute={{ hide: !(isPaid && expired), disabled: !expired || isUpdating, onClick: nextView }}
        _chat={{ onClick: handleChat }}
      />

      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        title="Confirm Trade Dispute"
        onConfirm={() => updateOrder("DISPUTE", "Payment was not received")}
      >
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            Are you sure you want to dispute this order?
          </Text>
        </VStack>
      </ConfirmationModal>
    </VStack>
  );
}

function OrderIndispute(props: TransactionHubProps) {
  const { user, counterParties, contractState, volume, amount, coin, currency, onChatClick, onChatOff } = props;
  const { shutdown } = useIntercom();

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  const ownerRole = useMemo(() => appropriateSessionOwner?.role, [appropriateSessionOwner]);

  const trader = useMemo(
    () => (counterParties ?? [])?.find((cp) => toLower(cp.role) === (ownerRole === "trader" ? "tradeowner" : "trader")),
    [counterParties, ownerRole]
  );

  const { expired } = useISOCountDown(trader?.timeline?.dueAt ?? "", `${trader?.timeline?.duration} : 00mins`);

  const currentTradeOwnerStatus = useMemo(() => `${contractState}&${trader?.obligationStatus}`, [contractState, trader]);
  const isPaid = useMemo(() => currentTradeOwnerStatus === "open&fulfilled", [currentTradeOwnerStatus]);

  const handleChat = () => {
    !!onChatClick && onChatClick();
    shutdown();
  };

  return (
    <VStack>
      <VStack maxW="480px" textAlign="center" color="white" my="30px !important">
        <Icon type="timer" boxSize="28px" />
        <Heading as="h4" mt="12px !important" fontSize="20px" fontFamily="var(--bitmama-fonts-heading)">
          {when(!isPaid, "Transaction Pending", ownerRole === "trader" ? "You confirmed payment" : "Buyer has confirmed payment")}
        </Heading>
        <Text my="28px !important" fontSize="1.5rem" fontWeight="900">
          {toPrecision(volume ?? 0, coin!)} - {currencyFormat(currency as any).format(amount ?? 0)}
        </Text>
        <Heading as="h6" fontSize="14px" fontFamily="var(--bitmama-fonts-heading)" color="#ED1C00">
          This trade is currently under dispute and our support team will initiate a three way conversation to resolve this issue.
        </Heading>
      </VStack>

      {/* <HStack mt="12px !important" gridGap="30px">
        {isPaid && expired && <ActionButton icon="dispute" disabled={!expired} />}
        <ActionButton icon="chat" />
        <ActionButton disabled icon="inAppCall" />
      </HStack> */}

      <Actions
        onChatOff={onChatOff}
        _dispute={{ hide: !(isPaid && expired), disabled: !expired }}
        _chat={{ onClick: handleChat }}
      />
    </VStack>
  );
}

function WaitForAssetRelease(props: TransactionHubProps) {
  const { user, counterParties, volume, amount, contractState, coin, currency, onChatClick, onChatOff, setHideBackButton } = props;
  const { ref } = useParams();
  const { shutdown } = useIntercom();
  const [id, frozenRef] = String(ref ?? "").split("-");
  const { isOpen, /*onOpen,*/ onClose } = useDisclosure();
  const { updateOrder, isUpdating } = useUpdateP2POrder(id, frozenRef);

  const { onNext } = useModalView();

  const nextView = () => {
    onNext();
    setHideBackButton(true);
    onChatOff && onChatOff();
  };

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  const trader = useMemo(
    () =>
      (counterParties ?? [])?.find(
        (cp) => toLower(cp.role) === (appropriateSessionOwner?.role === "trader" ? "tradeowner" : "trader")
      ),
    [counterParties, appropriateSessionOwner?.role]
  );
  // const tradeOwner = useMemo(() => counterParties?.find((cp) => toLower(cp.role) === "tradeowner"), [counterParties]);
  const { timer: countDown, expired } = useISOCountDown(trader?.timeline?.dueAt ?? "", `${trader?.timeline?.duration} : 00mins`);

  const currentTradeOwnerStatus = useMemo(
    () => `${contractState}&${appropriateSessionOwner?.obligationStatus}`,
    [contractState, appropriateSessionOwner]
  );
  const isPaid = useMemo(() => currentTradeOwnerStatus === "open&fulfilled", [currentTradeOwnerStatus]);

  const handleChat = () => {
    !!onChatClick && onChatClick();
    shutdown();
  };

  return (
    <VStack>
      <VStack maxW="390px" textAlign="center" color="white" my="30px !important">
        <Icon type="timer" boxSize="28px" />
        <Heading as="h4" mt="12px !important" fontSize="20px" fontFamily="var(--bitmama-fonts-heading)">
          Transaction Pending
        </Heading>
        <Heading as="h6" mt="10px !important" fontSize="14px" fontFamily="var(--bitmama-fonts-heading)" color="error">
          {when(expired, countDown, `${toCoinLocale(coin ?? "btc")} to be released in ${countDown}`)}
        </Heading>
        <Text mt="28px !important" fontSize="1.5rem" fontWeight="900">
          {toPrecision(volume ?? 0, coin!)} - {currencyFormat(currency as any).format(amount ?? 0)}
        </Text>
        <Text mt="28px !important" fontWeight="500">
          Click any of the actions below to follow up on your transaction with
        </Text>
        <Username username={`@${trader?.user?.username}`} hidePresence _link={{ color: "white" }} />
      </VStack>

      {/* <HStack mt="32px !important" gridGap="30px">
        {isPaid && expired && (
          <ActionButton isLoading={isUpdating} icon="dispute" disabled={!expired || isUpdating} onClick={onOpen} />
        )}
        <ActionButton icon="chat" />
        <ActionButton disabled icon="inAppCall" />
      </HStack> */}

      <Actions
        onChatOff={onChatOff}
        _dispute={{ hide: !(isPaid && expired), disabled: !expired || isUpdating, onClick: nextView }}
        _chat={{ onClick: handleChat }}
      />

      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        title="Confirm Trade Dispute"
        onConfirm={() => updateOrder("DISPUTE", "Payment was not received")}
      >
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            Are you sure you want to dispute this order?
          </Text>
        </VStack>
      </ConfirmationModal>
    </VStack>
  );
}

function OrderCompleted(props: TransactionHubProps) {
  const { user, counterParties, tradeRef, volume, amount, coin, currency, ratings } = props;

  const { profile } = useSelector(selectUser);

  const [tradeId, frozenRef] = String(tradeRef).split("-");
  const [_rateTrade, { isLoading: isRating }] = useRateTradeMutation();

  const rateTrade = useCallback(
    (rating: number) =>
      tradeId &&
      frozenRef &&
      _rateTrade({
        id: tradeId,
        frozenRef,
        rating,
      }),
    [tradeId, frozenRef, _rateTrade]
  );

  const rate = useMemo(() => ratings?.find((rate) => rate?.userId === profile?.userId), [profile, ratings]);

  console.log("user rating", rate);

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  const ownerRole = useMemo(() => appropriateSessionOwner?.role, [appropriateSessionOwner]);

  const trader = useMemo(
    () => (counterParties ?? [])?.find((cp) => toLower(cp.role) === (ownerRole === "trader" ? "tradeowner" : "trader")),
    [counterParties, ownerRole]
  );

  console.log("Appropriate Session Owner", appropriateSessionOwner);

  return (
    <VStack>
      <VStack maxW="380px" textAlign="center" color="white" my="30px !important">
        <Icon type="sendOutline" boxSize="28px" />
        <Heading as="h4" mt="12px !important" fontSize="20px" fontFamily="var(--bitmama-fonts-heading)">
          Transaction Complete
        </Heading>
        <Text mt="28px !important" fontSize="1.5rem" fontWeight="900">
          {toPrecision(volume ?? 0, coin!)} - {currencyFormat(currency as any).format(amount ?? 0)}
        </Text>
        <Text mt="28px !important" fontWeight="500">
          {when(
            ownerRole === "trader",
            "Order is completed succesfully and the coin has been transfered to your wallet",
            `Your transaction has been completed and your coins has been sent to @${trader?.user?.username ?? ""}`
          )}
        </Text>
      </VStack>

      <HStack mt="32px !important" gridGap="30px">
        <VStack>
          <Heading as="h5" fontSize="18px" color="white" mb="28px !important">
            Rate this Trade
          </Heading>
          <Box sx={{ ".MuiRating-icon": { color: "currentColor" } }}>
            <Rating
              disabled={isRating || !!rate?.rating}
              name="half-rating"
              defaultValue={0.5}
              precision={0.5}
              color="red"
              value={rate?.rating}
              onChange={(event, newValue) => {
                !!newValue && rateTrade(newValue);
              }}
              // emptyIcon={<StarIcon style={{ opacity: 0.55, stroke: "#F4A51D" }} fontSize="inherit" />}
            />
          </Box>
        </VStack>
      </HStack>
    </VStack>
  );
}

function OrderTimedOut(props: TransactionHubProps) {
  const { user, counterParties, volume, amount, coin, currency } = props;

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  const ownerRole = useMemo(() => appropriateSessionOwner?.role, [appropriateSessionOwner]);

  const trader = useMemo(
    () => (counterParties ?? [])?.find((cp) => toLower(cp.role) === (ownerRole === "trader" ? "tradeowner" : "trader")),
    [counterParties, ownerRole]
  );

  console.log("Appropriate Session Owner", appropriateSessionOwner);

  return (
    <VStack>
      <VStack maxW="340px" textAlign="center" color="white" my="30px !important">
        <Icon
          type="timer"
          boxSize="28px"
          sx={{
            path: {
              stroke: "#ED1C00",
            },
          }}
        />
        <Heading as="h4" mt="12px !important" fontSize="20px" fontFamily="var(--bitmama-fonts-heading)">
          Transaction Timedout
        </Heading>
        <Heading as="h6" mt="10px !important" fontSize="14px" fontFamily="var(--bitmama-fonts-heading)" color="error">
          TIMED OUT
        </Heading>
        <Text mt="28px !important" fontSize="1.5rem" fontWeight="900">
          {toPrecision(volume ?? 0, coin!)} - {currencyFormat(currency as any).format(amount ?? 0)}
        </Text>
        <Text mt="28px !important" fontWeight="500">
          {when(
            ownerRole === "trader",
            "Order automatically cancelled because you didn't pay on time.",
            `Your trade with @${trader?.user?.username ?? ""} timedout`
          )}
        </Text>
      </VStack>
    </VStack>
  );
}

function OrderCancelled(props: TransactionHubProps) {
  const { adsType, user, counterParties, volume, amount, coin, currency } = props;

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  const perspective = useMemo(() => {
    if (adsType === "buying" && appropriateSessionOwner?.perspective === "buyer") return "buyer";
    else return "seller";
  }, [appropriateSessionOwner, adsType]);

  const buyer = useMemo(() => (counterParties ?? [])?.find((cp) => cp.perspective === "buyer"), [counterParties]);
  const seller = useMemo(() => (counterParties ?? [])?.find((cp) => cp.perspective === "seller"), [counterParties]);

  console.log("Appropriate Session Owner", appropriateSessionOwner);
  const info = useMemo(() => {
    const map: Record<string, Record<string, string>> = {
      buying: {
        buyer: "You cancelled this order",
        seller: `@${buyer?.user?.username ?? ""} cancelled the order`,
      },
      selling: {
        buyer: `@${seller?.user?.username ?? ""} cancelled the order`,
        seller: "You cancelled this order",
      },
    };

    return map[adsType!][perspective];
  }, [buyer, seller, perspective, adsType]);

  return (
    <VStack>
      <VStack maxW="340px" textAlign="center" color="white" my="30px !important">
        <Icon
          type="sendOutline"
          boxSize="28px"
          sx={{
            path: {
              stroke: "#ED1C00",
            },
          }}
        />
        <Heading as="h4" mt="12px !important" fontSize="20px" fontFamily="var(--bitmama-fonts-heading)">
          Transaction Cancelled
        </Heading>
        <Text mt="28px !important" fontSize="1.5rem" fontWeight="900">
          {toPrecision(volume ?? 0, coin!)} - {currencyFormat(currency as any).format(amount ?? 0)}
        </Text>
        <Text mt="28px !important" fontWeight="500">
          {/* {when(perspective === "buyer", "You cancelled this order", `@${seller?.user?.username ?? ""} cancelled the order`)} */}
          {info}
        </Text>
      </VStack>
    </VStack>
  );
}

function ActionButton(props: ActionButtonProps) {
  const { icon, ...xprops } = props;

  const label = useMemo(() => {
    const map: Record<string, string> = {
      [icon]: icon,
      chat: "Chat",
      dispute: "Dispute",
      messageCircle: "Chat Support",
    };

    return map[icon];
  }, [icon]);

  return (
    <VStack ml="0 !important">
      <IconButton
        p="0 !important"
        minW="unset"
        maxW="unset"
        maxH="unset"
        minH="unset"
        w="52px"
        h="52px"
        icon={<Icon type={icon} />}
        {...xprops}
        aria-label={icon}
      />

      <Text mt="10px !important" fontSize="14px" fontFamily="var(--bitmama-fonts-heading)" color="white" fontWeight="600">
        {label}
      </Text>
    </VStack>
  );
}

function useContentMapper(props: TransactionHubProps) {
  const { mapKey } = props;

  const renderView = useMemo(() => {
    const map: Record<string, JSX.Element> = {
      /////////////////////////////////////////////////////////
      //// TRADER BUYING
      "trader&buying&open&fulfilled": <ReleaseAsset {...props} />,
      "trader&buying&open&pending": <ReleaseAsset {...props} />,
      "trader&buying&closed&fulfilled": <OrderCompleted {...props} />,
      "trader&buying&timeout&fulfilled": <OrderTimedOut {...props} />,
      "trader&buying&cancelled&fulfilled": <OrderCancelled {...props} />,
      "trader&buying&indispute&pending": <OrderIndispute {...props} />,
      //////////////////////////////////////////////////////////
      //// TRADER SELLING
      "trader&selling&open&fulfilled": <WaitForAssetRelease {...props} />,
      "trader&selling&closed&fulfilled": <OrderCompleted {...props} />,
      "trader&selling&timeout&pending": <OrderTimedOut {...props} />,
      "trader&selling&cancelled&pending": <OrderCancelled {...props} />,
      "trader&selling&indispute&fulfilled": <OrderIndispute {...props} />,
      ////////////////////////////////////////////////////////////
      //// TRADE OWNER BUYING
      "tradeowner&buying&open&fulfilled": <WaitForAssetRelease {...props} />,
      "tradeowner&buying&closed&fulfilled": <OrderCompleted {...props} />,
      "tradeowner&buying&timeout&pending": <OrderTimedOut {...props} />,
      "tradeowner&buying&cancelled&pending": <OrderCancelled {...props} />,
      "tradeowner&buying&indispute&fulfilled": <OrderIndispute {...props} />,
      ////////////////////////////////////////////////////////////
      //// TRADER OWNER SELLING
      "tradeowner&selling&open&pending": <ReleaseAsset {...props} />,
      "tradeowner&selling&closed&fulfilled": <OrderCompleted {...props} />,
      "tradeowner&selling&timeout&pending": <OrderTimedOut {...props} />,
      "tradeowner&selling&cancelled&pending": <OrderCancelled {...props} />,
      "tradeowner&selling&indispute&pending": <OrderIndispute {...props} />,
    };

    return map[mapKey];
  }, [mapKey, props]);

  return renderView;
}

type ActionPropType = { hide?: boolean };
interface ActionsProps extends BoxProps {
  _dispute?: Omit<ActionButtonProps, "icon"> & ActionPropType;
  _chat?: Omit<ActionButtonProps, "icon"> & ActionPropType;
  _support?: Omit<ActionButtonProps, "icon"> & ActionPropType;

  onChatOff?: (update?: any) => void;
}

function Actions(props: ActionsProps) {
  const { _dispute, _chat, _support, onChatOff, ...xprops } = props;

  const { bootWithProps } = useSupport();

  const handleSupport = () => {
    bootWithProps();
    !!onChatOff && onChatOff({ isSupportOn: true });
  };

  return (
    <HStack mt="32px !important" gridGap="30px" {...xprops}>
      {/* {isPaid && expired && (
        <ActionButton isLoading={isUpdating} icon="dispute" disabled={!expired || isUpdating} onClick={onOpen} />
      )}
      <ActionButton icon="chat" />
      <ActionButton disabled icon="inAppCall" /> */}

      {[
        { ..._dispute, icon: "dispute" },
        { ..._chat, icon: "chat" },
        { ..._support, icon: "messageCircle", disabled: false, onClick: handleSupport },
      ].map((props, idx) => when(!props?.hide, <ActionButton key={idx} {...(props as any)} />, null))}
    </HStack>
  );
}
