import { checkEmail, checkUsername, checkPhone, checkBVN, selectAuth } from "store/slices";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

type InputType = "email" | "username" | "phone" | "bvn";

export default function useSignupInputCheck() {
  const { checks } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const mapChecks = (type: InputType) => (value: string) => {
    const map = {
      email: (value: string) => checkEmail(value),
      username: (value: string) => checkUsername(value),
      phone: (value: string) => checkPhone(value),
      bvn: (value: string) => checkBVN(value),
    };

    return map[type](value);
  };

  const getChecks = useCallback(
    (type: InputType) => {
      return checks[type];
    },
    [checks]
  );

  const makeCheck = useCallback((type: InputType, value: string) => dispatch(mapChecks(type)(value) as any), [dispatch]);

  return { makeCheck, check: getChecks };
}
