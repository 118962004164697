import { Box, BoxProps, useMediaQuery } from "@chakra-ui/react";
import { SidePageProvider } from "contexts";
import { Notifications, Swap } from "ui";
import Main from "../Main/Main";
import Sidebar from "../Sidebar/Sidebar";

interface LayoutProps {
  path?: any;
  location?: any;
  _sidebar?: BoxProps;
  _main?: BoxProps;
  children: any;
}

export default function Layout(props: LayoutProps) {
  const { _sidebar, _main, location, children } = props;
  const [is1024px] = useMediaQuery("(max-width: 1024px)"); // 1300px

  return (
    <Box
      id="bitmama_layout"
      sx={{
        "--banner-h": "64px",
      }}
    >
      <Box pos="relative" display="flex">
        <SidePageProvider>
          <Sidebar is1024px={is1024px} {...({ ..._sidebar, location } as any)} />
          <Main position="relative" is1024px={is1024px} {..._main}>
            {/* {wrap(true, children)()} */}
            {children}
          </Main>

          <Swap key="Swap" hasBackButton _back={{ text: "back" }} />
          <Notifications key="Notifications" hasBackButton _back={{ text: "Go Back" }} />
        </SidePageProvider>
      </Box>
    </Box>
  );
}

// function wrap(showBanner: boolean, children: ReactNode) {
//   // const [showBanner, ] = useState(false)
//   return (props?: BoxProps) => {
//     return when<any>(
//       showBanner,
//       <Box id="child-wrapper" position="relative" top="var(--banner-h)" {...props}>
//         {children}
//       </Box>,
//       children
//     );
//   };
// }
