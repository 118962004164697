export const isProd = String(process.env.NODE_ENV).toLowerCase().includes("prod");
export const isCProd = String(process.env.REACT_APP_NODE_ENV).toLowerCase().includes("prod");
export const isDev = String(process.env.REACT_APP_NODE_ENV).toLowerCase().includes("dev");
export const isStaging = String(process.env.REACT_APP_NODE_ENV).toLowerCase().includes("staging");
export const hideDevLogs = String(process.env.REACT_APP_HIDE_DEV_LOG).toLowerCase().includes("true");

export const isTesting = (isDev || isStaging) && !isCProd;

const configs = {
  TEMP_PIN: "*tempp", // used to store tx pin temporarily to be reused without asking the user's pin everything within a context
  BASE_URL: process.env.REACT_APP_API_ENDPOINT as string,
  WALLET_BASE_URL: process.env.REACT_APP_WALLET_API_ENDPOINT as string,
  P2P_BASE_URL: process.env.REACT_APP_P2P_API_ENDPOINT as string,

  containerW: "8xl",

  PUSH_STORE_KEY: "btm_www_push",
  LAST_SWAP_TOKENS: "last-swap-tokens",
  VAPID_PUBLIC_KEY: process.env.REACT_APP_VAPIDKEY as string,

  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
  REACT_APP_PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY as string,
  REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT as string,
  REACT_APP_PUSHER_HOST: process.env.REACT_APP_PUSHER_HOST as string,
  REACT_APP_PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER as string,

  DO_BUCKET: process.env.REACT_APP_DO_BUCKET as string,
  DO_SPACES_ENDPOINT: process.env.REACT_APP_DO_SPACES_ENDPOINT as string,
  DO_SPACES_KEY: process.env.REACT_APP_DO_SPACES_KEY as string,
  DO_SPACES_SECRET: process.env.REACT_APP_DO_SPACES_SECRET as string,
  DO_SPACES_URL: process.env.REACT_APP_DO_SPACES_URL as string /* optional */,

  IDB_KEY: "bitmama.io",
  AUTH_TOKEN_KEY: "authkey",
  TEMP_PROFILE: "tempProfile",
  USER_DATA_KEY: "userData",
  UTM_KEY: "utm",
  FIAT_DEPOSIT_KEY: (id: string) => `fiatDepositCache-${id}`,
  FLUTTERWAVE_PUBKEY: process.env.REACT_APP_FLUTTERWAVE_PUBKEY as string,

  GREENBOX_CODE_CACHE: "gbxID",
  TWO_FA_REMIND: "2fa_announce",
  PYUSD_REMIND: "pyusd",
  XMAS_REMIND: "xmas",
  XMAS_DONT_SHOW: "xsho",
  GAME_REMIND: (id: string) => `gmup-${id}`,

  INTERCOM_TOKEN: process.env.REACT_APP_INTERCOM_TOKEN as string,
  INTERCOM_SECRET: process.env.REACT_APP_INTERCOM_SECRET as string,

  WEB_ENGAGE_LICENSE: (process.env.REACT_APP_WEB_ENGAGE_LICENSE as string) ?? "14507d112",

  CHAT_SUPPORT_SECRET: process.env.REACT_APP_CHAT_SUPPORT_SECRET as string,
  GUEST_CHAT_SUPPORT_STORE_KEY: "tmp-support-id",
  CHATWOOT_WEBTOKEN: process.env.REACT_APP_CHATWOOT_WEBTOKEN as string,

  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA as string,
  ENABLE_LOGS: (process.env.REACT_APP_ENABLE_LOGS ?? "false") as string,

  paths: {
    app: "/app",
    login: "/login",
    register: "/signup",
    resetPassword: "/reset",
    verifyEmail: "/verify",
    verify: "/verify",
    verifyResetCode: "/verifycode",
    forgotPassword: "/forgot",
    verifyTwoFa: "/2faenter",
    captureBVN: "/capturebvn",
    resetTwoFa: "/reset2FA",
    enableTwoFa: "/enable2FA",
    greenBox: "/greenbox",
    gbx: "/gbx",

    profile: "/profile",
    wallet: "/wallet",
    cards: "/cards",
    dashboard: "/home",
    account: "/account",
    p2p: "/p2p",
    earn: "/earn",

    // HOME: "/dashboard/home",
    // APP: "/app",
    // LOGIN: "/",
    // FORGOT: "/forgot",
    // RESET: "/reset",
    // TwoFAReset: "/reset2FA",
    // SIGNUP: "/signup",
    // RESET_CODE: "/resetcode",
    // PHONE_NUMBER: "/phonenumber",
    // VERIFY_PHONE: "/confirmnumber",
    // VERIFY_EMAIL: "/verifyemail",
    // VERIFY_TWO_FA: "/2faenter",
    // GENERATE_TWO_FA: "/2fagenerate",
    // VERIFY_EMAIL_PASSWORD: "/verify-email-password",

    // TECH_POINT_WIDGET: "/techpoint/widget",
  },

  coins: {
    xlm: {
      precision: 3,
      label: "XLM",
      roundValue: 0.001,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.01, minimum: 10 },
      },
    },
    xrp: {
      precision: 2,
      label: "XRP",
      roundValue: 0.01,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.00002, minimum: 15 },
      },
    },
    eth: {
      precision: 5,
      label: "ETH",
      roundValue: 0.0001,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.008, minimum: 0.02 },
      },
    },
    btc: {
      precision: 6,
      label: "BTC",
      roundValue: 0.00001,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.0005, minimum: 0.001 },
      },
    },
    teth: {
      precision: 5,
      roundValue: 0.0001,
      label: "tETH",
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.008, minimum: 0.02 },
      },
    },
    tbtc: {
      precision: 6,
      label: "tBTC",
      roundValue: 0.00001,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.0005, minimum: 0.001 },
      },
    },
    cusd: {
      precision: 2,
      label: "cUSD",
      roundValue: 0.01,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.01, minimum: 2 },
      },
    },
    ceur: {
      precision: 2,
      label: "cEUR",
      roundValue: 0.01,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.008, minimum: 0.008 },
      },
    },
    usdt: {
      precision: 2,
      label: "USDT",
      roundValue: 0.01,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 1, minimum: 5 },
      },
    },
    usdc: {
      precision: 2,
      label: "USDC",
      roundValue: 0.01,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 20, minimum: 25 },
      },
    },
    pyusd: {
      precision: 2,
      label: "PYUSD",
      roundValue: 0.01,
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 20, minimum: 25 },
      },
    },
    celo: {
      precision: 3,
      label: "CELO",
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0.001, minimum: 0.02 },
      },
    },
    matic: {
      precision: 5,
      label: "POL",
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0, minimum: 0.02 },
      },
    },
    ton: {
      precision: 5,
      label: "TON",
      wallet: {
        deposit: { fee: 0.008, minimum: 0.008 },
        withdraw: { fee: 0, minimum: 0.02 },
      },
    },
    // "usdt-tron": {
    //   precision: 5,
    //   label: "USDT TRON",
    //   wallet: {
    //     deposit: { fee: 0.008, minimum: 0.008 },
    //     withdraw: { fee: 1, minimum: 1 },
    //   },
    // },

    ngn: { precision: 2, roundValue: 0.01, label: "NGN" },
    ghs: { precision: 2, roundValue: 0.01, label: "GHS" },
    kes: { precision: 2, roundValue: 0.01, label: "KES" },
  },
  maps: {
    countryToCurrency: {
      nigeria: "ngn",
      ghana: "ghs",
      kenya: "kes",
    },
  },
  swap: {
    fee: 0.28,
  },
  // stableCoins: ["usdt", "cusd", "usdt-tron", "usdc"],
  stableCoins: ["usdt", "cusd", "usdc", "pyusd"],
  fiats: ["ngn", "ghs", "kes", "xaf", "xof", "cdf", "zar"],
  francoCoins: ["xaf", "xof", "cdf"],
  priceAlertCoins: ["btc", "eth", "celo", "xrp", "xlm", "matic", "ton"],
  supportedLoanableCoins: isProd ? ["btc", "eth", "celo"] : ["tbtc", "teth", "celo"],
  supportedStakableCoins: isProd ? ["tbtc", "teth", "usdt"] : ["tbtc", "teth", "usdt"],
  supportedPeerCoins: isTesting ? ["tbtc", "teth", "usdt", "celo", "cusd"] : ["btc", "eth", "usdt", "celo", "cusd"],
};

export default configs;

const log = (window as any).log || console.log;

log(`Environment: ${String(process.env.REACT_APP_NODE_ENV)}, isTesting: ${isTesting}`);
