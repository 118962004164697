import { Stack, useColorModeValue } from "@chakra-ui/react";
import { AccountLink, Steps, useModalView } from "components";
import { PageProps } from "interfaces";
import ChangePassword from "./ChangePassword";
import TwoFactorAuthentication from "./2FA";

interface ProfileSecurityPanelProps extends PageProps {}

export default function ProfileSecurityPanel(props: ProfileSecurityPanelProps) {
  return (
    <Stack my={{ base: "20px", "3sm": "40px" }}>
      <Steps hideIndicator>
        <Links />
        <ChangePassword />
        <TwoFactorAuthentication />
      </Steps>
    </Stack>
  );
}

function Links() {
  const { setCurrentView } = useModalView();

  const bColor = useColorModeValue("grey.200", "dark.border");

  // useEffect(() => {
  //   return () => {
  //     setCurrentView(0);
  //   };
  // }, [setCurrentView]);

  return (
    <Stack width="100%">
      <AccountLink
        borderBottomColor={bColor}
        py="20px"
        to="#changePassword"
        label="Change Password"
        onClick={() => setCurrentView(1)}
      />
      <AccountLink borderBottomColor={bColor} py="20px" to="pin" label="Transaction Pin" />
      <AccountLink
        borderBottomColor={bColor}
        py="20px"
        to="#2fa"
        label="Two-factor Authentication"
        onClick={() => setCurrentView(2)}
      />
    </Stack>
  );
}
