import { Avatar, Box, Grid, HStack, Image, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import Card, { CardProps } from "components/Card/Card";
import LeaderIMG from "assets/images/king.png";
import { useMemo } from "react";
import { when } from "utils";
import Icon from "components/Icon/Icon";

export interface LeaderboardItemProps extends CardProps {
  rank: number;
  name: string;
  username: string;
  points: number;
}

export function ArrowNumber({ value = 4, isLeader = false }) {
  return <VStack>{isLeader && <Image boxSize="18px" src={LeaderIMG} mt="0px !important" alt="" />}</VStack>;
}

export function LeaderItem({ rank = 1, name, username, value, ...xprops }: any) {
  const tColor = useColorModeValue("primary.700", "secondary.500");

  const color = useMemo(() => {
    const map: Record<string, string> = {
      [rank]: "#6FBE45",
      1: "#E8C229",
    };

    return map[rank];
  }, [rank]);

  const rankBoxW = when(rank === 1, "42px", "32px");

  return (
    <VStack {...xprops}>
      <ArrowNumber value={rank} isLeader={rank === 1} />
      <Box boxSize={rank === 1 ? "99px" : "60px"} borderRadius="100%" bg={color} pos="relative">
        <Avatar
          bg="#0D4740"
          color="white"
          boxSize={rank === 1 ? "93px" : "56px"}
          name={name}
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      </Box>

      <VStack fontFamily="var(--bitmama-fonts-heading)">
        <Box
          mt={`calc(${rankBoxW} / -1.5)`}
          zIndex="2"
          pos="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          color="#fff"
          fontWeight="600"
          boxSize={rankBoxW}
          bg={color}
        >
          {rank}
        </Box>
        <Icon color={color} mt="0px !important" boxSize="20px" type="medal" />
      </VStack>

      <VStack>
        <Text
          fontSize="16px"
          color={tColor}
          fontWeight="500"
          maxW="80px"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {username}
        </Text>
        <Text color={color} fontSize="16px" fontWeight="600" mt="0px !important" fontFamily="var(--bitmama-fonts-heading)">
          {value ? Number(value).toFixed(2) : 0}
        </Text>
      </VStack>
    </VStack>
  );
}

// eslint-disable-next-line
export function Leaders({ items }: any) {
  const third = useMemo(() => (items ?? [])[2], [items]);
  const second = useMemo(() => (items ?? [])[1], [items]);
  const first = useMemo(() => (items ?? [])[0], [items]);

  return (
    <HStack
      m="0 auto"
      my="40px"
      alignItems="flex-end"
      justifyContent="center"
      pos="relative"
      gridGap={{ base: "8px", smx: "21px" }}
    >
      <LeaderItem
        rank={2}
        bottom="0"
        right="0"
        ml="0px !important"
        zIndex="2"
        name={`${second?.firstName} ${second?.lastName}`}
        username={`@${second ? second.username : ""}`}
        value={second?.usdEarning}
      />
      <LeaderItem
        rank={1}
        bottom="0"
        left="50%"
        ml="0px !important"
        name={`${first?.firstName} ${first?.lastName}`}
        username={`@${first ? first?.username : ""}`}
        value={first?.usdEarning}
      />

      <LeaderItem
        rank={3}
        bottom="0"
        left="0"
        zIndex="2"
        name={`${third?.firstName} ${third?.lastName}`}
        username={`@${third ? third?.username : ""}`}
        value={third?.usdEarning}
      />
    </HStack>
  );
}

export function LeaderboardItem(props: LeaderboardItemProps) {
  const { name, username, rank, points, ...xprops } = props;
  return (
    <Card
      p="12px"
      w="100%"
      sx={{
        "--rank-box-size": "22px",
      }}
      {...xprops}
    >
      <Grid
        // templateColumns=".4fr 1.1fr .3fr"
        templateColumns={{ base: ".2fr 1.1fr .3fr", "4sm": ".4fr 1.1fr .3fr" }}
        justifyContent="center"
      >
        <VStack alignItems="flex-start" fontFamily="var(--bitmama-fonts-heading)">
          <Box
            zIndex="2"
            pos="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            color="#fff"
            fontWeight="600"
            fontSize="12px"
            boxSize="var(--rank-box-size)"
            bg="primary.500"
          >
            {rank}
          </Box>
          <Icon color="red.500" mt="0px !important" boxSize="20px" type="medal" />
        </VStack>

        <HStack>
          <Avatar bg="#0D4740" color="white" border="2px solid #6FBE45" size="md" name={name ?? "Tom Cruise"} />
          <Text
            fontSize="16px"
            // color={tColor}
            fontWeight="500"
            maxW="80px"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {username ?? "@maryLu"}
          </Text>
        </HStack>

        <HStack px="14px">
          <Text fontSize="18px" color="primary.500" fontWeight="600">
            {points ?? 200}
          </Text>
        </HStack>
      </Grid>
    </Card>
  );
}

export function useLeaderboardDummyData() {
  const leaders: { firstName: string; lastName: string; usdEarning: number; username: string }[] = [
    { firstName: "Mary", lastName: "Lu", usdEarning: 350, username: "maryLu" },
    { firstName: "Jonson", lastName: "Baiber", usdEarning: 230, username: "jonson" },
    { firstName: "Aliyah", lastName: "Comfort", usdEarning: 200, username: "aliyah" },
  ];

  const items: { rank: number; name: string; usdEarning: number; username: string }[] = [
    { rank: 4, name: "Mary Lu", usdEarning: 350, username: "@maryLu" },
    { rank: 5, name: "Jonson Baiber", usdEarning: 230, username: "@jonson" },
    { rank: 6, name: "Tom Cruise", usdEarning: 200, username: "@tom" },
    { rank: 7, name: "Yetunde Temide", usdEarning: 198, username: "@yetunde" },
    { rank: 8, name: "Jonny Near", usdEarning: 197, username: "@nearjonny" },
    { rank: 9, name: "Peps Cat", usdEarning: 196, username: "@cat" },
    { rank: 10, name: "Talking tom", usdEarning: 195, username: "@talker" },
    { rank: 11, name: "Paul Walker", usdEarning: 184, username: "@fire_walker" },
    { rank: 12, name: "Scorpion Chet", usdEarning: 100, username: "@jinx" },
  ];

  return { leaders, items };
}
