import { Box, CloseButton, HStack, Text } from "@chakra-ui/react";
import { Link } from "components";
// import { Icon } from "components";
// import { Link } from "components";
import { motion } from "framer-motion";
import isEmpty from "lodash/isEmpty";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { when } from "utils";
import IDB from "utils/idb";

// import { useSupport } from "hooks";

const BannerContext = createContext({});

export function BannerProvider(props: PropsWithChildren<any>) {
  const { children } = props;

  const key = "banner-version5";
  const [visible, setVisibility] = useState(false);

  // const { bootWithProps } = useSupport();

  const handleClose = () => {
    IDB.transaction("rw", (IDB as any)?.announcements, function* (): Generator<any, any, any> {
      let data = yield IDB?.table("announcements").where("name").equalsIgnoreCase(key).toArray();

      data[0] &&
        data[0].id &&
        (yield IDB?.table("announcements")?.update(data[0]?.id, {
          name: key,
          value: false,
        }));

      if (isEmpty(data)) {
        yield IDB?.table("announcements")?.add({
          name: key,
          value: false,
        });
      }

      setVisibility(false);
    }).catch((err: any) => {
      console.error(err.stack || err);
    });
  };

  // const handleClick = () => bootWithProps();

  useEffect(() => {
    (async () => {
      if (!visible) {
        const showBanner = (await IDB?.table("announcements")?.where("name").equalsIgnoreCase(key).toArray())[0];
        console.log("Banner", showBanner);
        if (!showBanner) setVisibility(true);
        else setVisibility(showBanner?.value ?? false);
      }
    })();

    //eslint-disable-next-line
  }, []);

  return (
    <BannerContext.Provider value={{}}>
      {/* <AnimatePresence initial={false} exitBeforeEnter> */}
      <motion.div
        key={when(visible, "visible", "invisible")}
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -100, opacity: 0 }}
      >
        {visible && (
          <Box
            h="var(--banner-h)"
            p="20px"
            bg="#faeeb8"
            top="0"
            w="100%"
            position="relative"
            zIndex="100"
            justifyContent="center"
            alignItems="center"
            display="flex"
            fontWeight="500"
          >
            <HStack>
              <Text color={"black"}>Want to get the Bitmama Physical crypto card?</Text>
              <Link
                onClick={() =>
                  window.open(
                    "https://docs.google.com/forms/d/13iJSH1xELjfcAMSh79ZOkZP6s2RvzZ-e-pJ5jtFOVeM/edit?ts=66ced494",
                    "blank"
                  )
                }
              >
                Join the Waitlist
              </Link>
            </HStack>
            <CloseButton onClick={handleClose} pos="absolute" right="20px" color="black" />
          </Box>
        )}
        {/* {visible && (
          <Box
            h="var(--banner-h)"
            p="20px"
            bg="#faeeb8"
            top="0"
            w="100%"
            position="relative"
            zIndex="100"
            justifyContent="center"
            alignItems="center"
            display="flex"
            fontWeight="500"
          >
            <Text color={"black"}>
              We're currently experiencing delays with deposits and withdrawal and the team is working to fix it. Please bear with
              us
            </Text>
            <CloseButton onClick={handleClose} pos="absolute" right="20px" color="black" />
          </Box>
        )} */}
        {/* {visible && (
          <Box
            h="var(--banner-h)"
            p="20px"
            bg="#faeeb8"
            top="0"
            w="100%"
            position="relative"
            zIndex="100"
            justifyContent="center"
            alignItems="center"
            display="flex"
            fontWeight="500"
          >
            <Text color={"black"}>
              Encountering any issues?{" "}
              <Link textDecoration="underline" onClick={handleClick}>
                Click here to have a chat with our team
              </Link>{" "}
            </Text>
            <CloseButton onClick={handleClose} pos="absolute" right="20px" color="black" />
          </Box>
        )} */}
        {/* {visible && (
          <Box
            h="var(--banner-h)"
            p="20px"
            bg="#faeeb8"
            top="0"
            w="100%"
            position="relative"
            zIndex="100"
            justifyContent="center"
            alignItems="center"
            display="flex"
            fontWeight="500"
          >
            <HStack>
              <Icon type="warning" boxSize="24px" color="#ffa200" />
              <Text color={"black"}>Our virtual card services are restored.</Text>
            </HStack>
            <CloseButton onClick={handleClose} pos="absolute" right="20px" color="black" />
          </Box>
        )} */}
      </motion.div>
      {children}
      {/* </AnimatePresence> */}
    </BannerContext.Provider>
  );
}
