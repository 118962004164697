import { HStack, TabPanel, Tabs, useDisclosure, useToast, VStack, Text } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { useGetP2PProfileQuery, useTogglePresenceMutation } from "apis";
import { MainLayoutContainer, PageMotion, TabPanels, Topbar } from "components";
import { PageProps } from "interfaces";
import { useCallback, useMemo, useRef, useState, createContext } from "react";

import { AllTradesPanel, ConfirmationModal, FeatureUnavailableModal, MyTradesPanel } from "ui";
import { lessThanSomeMinutesAgo } from "utils";

// import { useAppConfig } from "contexts/appconfig.context";
import { useDisclosures } from "hooks";
export const presenceContext = createContext({});

interface P2pProps extends PageProps {}

interface P2pPageTabsProps {}

export default function P2P(props: P2pProps) {
  return (
    <>
      {/* <ViewPort responsive={false} /> */}

      <PageMotion key="P2p">
        <Topbar pageTitle="P2P" {...props} />

        <MainLayoutContainer pb="60px">
          {/* p2p */}
          {/* <SidePageProvider> */}
          <P2pPageTabs />
          {/* <Fiat key="Fiat" hasBackButton />
          <Crypto key="Crypto" hasBackButton /> */}
          {/* </SidePageProvider> */}
        </MainLayoutContainer>
      </PageMotion>
    </>
  );
}

type ModalType = "featureUnavailable";

function P2pPageTabs(props: P2pPageTabsProps) {
  // const { key } = useSidePage();
  const location = useLocation();

  // const { appfigs } = useAppConfig();
  // const { p2pConfig } = appfigs ?? {};

  const { isOpen, close } = useDisclosures<ModalType>();

  const searchParams = useMemo(() => new URLSearchParams(location?.search as string), [location?.search]);

  const tabMap = {
    allTrades: 0,
    myTrades: 1,
  };

  const tab = useMemo(() => searchParams.get("tab")?.split(":")[0], [searchParams]);

  const [tabIndex, changeTab] = useState((tabMap as any)[tab as string] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", `${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const {
    data: p2pProfile,
    isLoading: isLoadingP2PProfile,
    isFetching: isFetchingP2PProfile,
    // isUninitialized,
    refetch: refetchP2PProfile,
  } = useGetP2PProfileQuery({});

  const [
    togglePresence,
    { isLoading: isTogglingPresence, isSuccess: isTogglingPresenceSuccess, isError: isTogglingPresError, error: togglingPresError },
  ] = useTogglePresenceMutation();

  const { isOpen: isPresencePreviewOpen, onOpen: onPresencePreviewOpen, onClose: onPresencePreviewClose } = useDisclosure();
  const lastToggled = useRef<string>();
  const toast = useToast();

  // const isCreateListingDisabled = useMemo(
  //   () => !!p2pConfig && !!p2pConfig?.disableBuyListing && !!p2pConfig?.disableSellListing,
  //   [p2pConfig]
  // );

  // const handleCreateListing = () => {
  //   if (isCreateListingDisabled) {
  //     return open("featureUnavailable")();
  //   } else navigate("/p2p/create");
  // };

  const disabledPresenceToggle = useMemo(() => {
    if (isTogglingPresence) return true;
    if (isLoadingP2PProfile) return true;
    if (isFetchingP2PProfile) return true;
    if (lastToggled.current) {
      const waitSomeMins = 2;
      if (lessThanSomeMinutesAgo(lastToggled.current, waitSomeMins)) return true;
    }
    if (p2pProfile?.profile?.presence?.updatedAt) {
      const waitSomeMins = 2;
      if (lessThanSomeMinutesAgo(p2pProfile?.profile?.presence?.updatedAt, waitSomeMins)) return true;
    }
  }, [isTogglingPresence, isLoadingP2PProfile, isFetchingP2PProfile, p2pProfile?.profile?.presence?.updatedAt]);

  const handleTogglePresence = () => {
    if (presenceState?.presence === "online" && presenceState?.goOffline) presenceState.goOffline();
    if (presenceState?.presence === "offline" && presenceState?.goOnline) presenceState.goOnline();
  };

  const toastPresence = useCallback(
    (success: boolean, msg = "Something went wrong") => {
      toast({
        position: "bottom-right",
        title: success ? "Success" : "Oops",
        description: msg,
        status: success ? "success" : "error",
        duration: 5000,
        isClosable: true,
      });
    },
    [toast]
  );

  const presenceState = useMemo(() => {
    return {
      disabled: disabledPresenceToggle,
      hide: isLoadingP2PProfile || !p2pProfile?.profile,
      presence: p2pProfile?.profile?.presence?.status || "online",
      goOffline: onPresencePreviewOpen,
      goOnline: async () => {
        try {
          const resp = await togglePresence("online").unwrap();
          console.log("Toggle Response", resp);
          if (!!resp) {
            lastToggled.current = new Date().toISOString();
            await refetchP2PProfile();
            toastPresence(true, "Your listings are back online");
          } else {
            toastPresence(false, "Something went wrong. Try again later");
          }
        } catch (err) {
          toastPresence(false, "Something went wrong. Try again later");
          console.log("[@goOnline] ", err, {
            isTogglingPresenceSuccess,
            isTogglingPresError,
            togglingPresError,
          });
        }
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    disabledPresenceToggle,
    isTogglingPresError,
    isTogglingPresenceSuccess,
    togglingPresError,
    isLoadingP2PProfile,
    p2pProfile?.profile,
    p2pProfile?.profile?.presence?.status,
  ]);

  const presenceStyles = useMemo(() => {
    const map: Record<string, any> = {
      online: {
        color: "secondary.400",
        label: "Online",
        isChecked: true,
      },
      offline: {
        color: "red.400",
        label: "Offline",
        isChecked: false,
      },
    };

    return map[presenceState?.presence ?? "online"];
  }, [presenceState?.presence]);
  return (
    <HStack w="100%">
      <Tabs w="100%" variant="ghost" index={tabIndex} onChange={handleTabChange}>
        {/* <HStack w="100%" justifyContent="space-between" alignItems="center">
          <TabList
            p="8px"
            bg={switchStyle(colorMode, { light: "white", dark: "dark.cardBg" })}
            borderRadius="8px"
            justifyContent="space-between"
          >
            <Tab color={switchStyle(colorMode, { light: "#797A7A", dark: "#ffffff" })}>All Trades</Tab>
            <Tab color={switchStyle(colorMode, { light: "#797A7A", dark: "#ffffff" })}>Trade History</Tab>
          </TabList>

          <HStack>
            {presenceState && !presenceState?.hide && (
              <FormControl display="flex" alignItems="center" justifyContent="center">
                <InputLabel
                  id="online-presence"
                  fontSize="sm"
                  htmlFor="online-presence"
                  // mb="0 !important"
                  // ml="6px"
                  color={presenceStyles?.color}
                  _text={{ mb: "0 !important", fontWeight: "500" }}
                >
                  {presenceStyles?.label}
                </InputLabel>
                <Switch
                  id="online-presence"
                  colorScheme="secondary"
                  disabled={presenceState?.disabled}
                  isChecked={presenceStyles?.isChecked}
                  onChange={handleTogglePresence}
                  sx={{
                    "--switch-track-offset": "3.5px",
                    "--switch-track-diff": "calc((var(--switch-track-width) - 10px) - var(--switch-track-offset))",
                    "--switch-track-y": "calc((var(--switch-track-height) - 10px) / 2)",
                    ".chakra-switch__thumb": {
                      boxSize: "10px",
                      transform: "translate(var(--switch-track-offset), var(--switch-track-y))",
                    },
                    ".chakra-switch__thumb[data-checked]": {
                      transitionProperty: "background-color",
                      transitionDuration: "var(--bitmama-transition-duration-normal)",
                      transform: "translate(var(--switch-track-diff), var(--switch-track-y))",
                    },
                    ".chakra-switch__track[data-checked]": {
                      bg: "secondary.400",
                    },
                  }}
                />
              </FormControl>
            )}

            <Button
              minH="42px"
              minW="205px"
              p="0"
              leftIcon={<Icon type="addCircle" />}
              onClick={handleCreateListing}
              // isDisabled={isCreateListingDisabled}
            >
              Create a listing
            </Button>
          </HStack>
        </HStack> */}
        <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
          <TabPanel px={["2", "0", "0", "1"]}>
            <presenceContext.Provider value={{ presenceState, presenceStyles, handleTogglePresence }}>
              <AllTradesPanel {...props} />
            </presenceContext.Provider>
          </TabPanel>
          <TabPanel px={["0", "0", "0", "1"]}>
            <MyTradesPanel {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ConfirmationModal
        isOpen={isPresencePreviewOpen}
        onClose={onPresencePreviewClose}
        // isLoading={isTogglingPresence}
        onConfirm={async () => {
          try {
            const resp = await togglePresence("offline").unwrap();
            if (!!resp) {
              lastToggled.current = new Date().toISOString();
              await refetchP2PProfile();
              toastPresence(true, "Some or all of your listings have been taken offline");
              onPresencePreviewClose();
            } else {
              toastPresence(false, "Something went wrong. Try again later");
              onPresencePreviewClose();
            }
          } catch (err) {
            toastPresence(false, "Something went wrong. Try again later");
            onPresencePreviewClose();
            console.log("[@goOffline] ", err, {
              isTogglingPresenceSuccess,
              isTogglingPresError,
              togglingPresError,
            });
          }
        }}
        title="Confirm"
        labels={["Go, Offline", "No"]}
      >
        <VStack mt="30px">
          <Text textAlign="center" fontWeight="500">
            Some or all of your listings would be taken off the market immediately until you toggle back online. Are you sure you
            want to go offline?
          </Text>
        </VStack>
      </ConfirmationModal>

      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </HStack>
  );
}

// function Tab(props: TabProps) {
//   const { children, ...xprops } = props;

//   return (
//     <CTab
//       minW="280px"
//       minH="44px"
//       borderRadius="8px"
//       fontWeight="300"
//       fontFamily="var(--bitmama-fonts-heading)"
//       _selected={{ bg: "secondary.500", color: "white", fontWeight: "bold" }}
//       {...xprops}
//     >
//       {children}
//     </CTab>
//   );
// }
