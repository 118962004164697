import { Box, Button, FormControl, Heading, HStack, Input, Stack, useDisclosure, VStack } from "@chakra-ui/react";
import { InputLabel, Steps, RadixSelect, Option, Select, FormInfo, MovementRadioGroup } from "components";
import configs from "config";
import { currencyFormat, toCoinLocale, when } from "utils";
import { usePartialState } from "hooks";
import { PageProps } from "interfaces";
import ConfirmCryptoAlertModal from "ui/Account/Modals/CryptoAlert/Confirm";
import { TradeOnAlertForm } from "./TradeOnAlert";
import { useMemo } from "react";
import { useGetCoinToUsdRateQuery } from "apis";

interface AddFeatureProps extends PageProps {}

interface IState {
  coin: string;
  direction: string;
  price: string; // the value for the (percentage | price) type selected
  type: string; // price type, 'percentage' | 'price'
  priceAlertId: string;

  spotPrice: number;
  fiatAmount: number; // required field for a buy order
  cryptoAmount: number; // required field for a sell order

  _temp_coin_amount: string; // a temporary fied to hold the coin amount enterred by the user in the buy/sell order screen.
  scheduleType: "buy" | "sell";
}

export interface IStateExtension {
  state: ReturnType<typeof usePartialState<IState>>[0];
  set: ReturnType<typeof usePartialState<IState>>[1];
  reset: ReturnType<typeof usePartialState<IState>>[2];
}

export function AddFeature(props: AddFeatureProps) {
  const [state, set, reset] = usePartialState<IState>({ type: "price" });

  return (
    <Box>
      <Steps hideIndicator>
        <AddForm {...{ state, set, reset }} />
        <TradeOnAlertForm {...{ state, set, reset }} />
      </Steps>
    </Box>
  );
}

interface AddFormProps extends IStateExtension {}

export function AddForm(props: AddFormProps) {
  const { state, set } = props;
  // const toast = useToast();
  // const [_addPriceAlert, { isLoading }] = useCreatePriceAlertMutation();

  const { isOpen, onClose, onOpen } = useDisclosure();

  // console.log("State", state, onOpen);

  const { data: coinData } = useGetCoinToUsdRateQuery(state?.coin ?? "btc", { skip: !state?.coin });

  const actionType = useMemo(() => when(!!state?.direction && state?.direction === "up", "sell", "buy"), [state?.direction]);
  const current_market_price = useMemo(() => (coinData?.exchangeRate ?? {})[actionType] ?? 0, [actionType, coinData?.exchangeRate]);

  const is_price_invalid = useMemo(() => {
    if (state?.direction === "down" && state?.type === "price")
      return +(state?.price ?? 0) <= 0 || +(state?.price ?? 0) >= current_market_price;
    if (state?.direction === "up" && state?.type === "price") return +(state?.price ?? 0) <= current_market_price;
    return false;
  }, [state, current_market_price]);

  const is_percentage_invalid = useMemo(() => +(state?.price ?? 0) < 1 || +(state?.price ?? 0) > 100, [state?.price]);

  const is_price_field_disabled = useMemo(() => {
    if (!state?.price) return false;
    else if (!!state?.price && state?.type === "price" && is_price_invalid) return true;
    else if (!!state?.price && state?.type === "percentage" && is_percentage_invalid) return true;
  }, [state?.price, state?.type, is_price_invalid, is_percentage_invalid]);

  const handleSubmit = async (e: any) => {
    if (!state?.coin) return;
    e.preventDefault();
    onOpen();

    // const response = await _addPriceAlert({
    //   ticker: `${state?.coin}usdt`,
    //   ...omit(state, ["spotPrice", "priceAlertId"]),
    // }).unwrap();

    // const alert = (response?.data as any)?.alert;

    // if (!!alert) {
    //   onOpen();
    //   const percentage_to_price = when(
    //     state?.direction === "up",
    //     current_market_price + (current_market_price * +(state?.price ?? 0)) / 100,
    //     current_market_price - (current_market_price * +(state?.price ?? 0)) / 100
    //   );
    //   const spot_price = when(state?.type === "price", +(state?.price ?? 0), percentage_to_price);
    //   set({ priceAlertId: alert?._id, spotPrice: spot_price });
    // }

    // console.log("Alert Response", response);
  };

  const isDisabled = useMemo(
    () => !(state?.coin && state?.direction && state?.price) || is_price_field_disabled,
    [state, is_price_field_disabled]
  );

  return (
    <Stack my="18px">
      <HStack mb="36px">
        <Box>
          <Heading as="h4" fontSize="sm">
            Add New Alert
          </Heading>
        </Box>
      </HStack>

      <Stack as="form" onSubmit={handleSubmit} px="4px">
        <FormControl mb="28px !important">
          <InputLabel htmlFor="coin">Select coin you want to set a price alert for</InputLabel>

          {/* <Select
            placeholder="Select Coin"
            id="coin"
            name="coin"
            value={state?.coin ?? ""}
            onChange={(e) => set({ coin: e.target.value })}
          >
            {configs.priceAlertCoins.map((coin) => (
              <option key={`${coin}`} value={coin}>
                {toCoinLocale(coin)}
              </option>
            ))}
          </Select> */}

          <RadixSelect
            use_coin_icon
            value={state?.coin}
            aria-label="Wallet"
            placeholder="Select Wallet"
            onChange={(value) => set({ coin: value })}
            _trigger={{
              w: "100%",
            }}
          >
            {configs.priceAlertCoins.map((coin) => (
              <Option key={coin} value={coin} coin={coin as any}>
                {toCoinLocale(coin)}
              </Option>
            ))}
          </RadixSelect>
        </FormControl>

        <FormControl mb="28px !important">
          <InputLabel mb="16px" htmlFor="coin">
            Select Movement
          </InputLabel>

          <MovementRadioGroup value={state?.direction ?? ""} onChange={(direction) => set({ direction })} />
        </FormControl>

        <FormControl mb="28px !important">
          <InputLabel htmlFor="type">Alert</InputLabel>

          <Select
            placeholder="Select Alert Type"
            id="type"
            name="type"
            value={state?.type}
            onChange={(e) => set({ type: e.target.value })}
          >
            <option value="price">Price</option>
            <option value="percentage">Percentage</option>
          </Select>
        </FormControl>

        <FormControl mb="28px !important">
          <InputLabel htmlFor="price" isInvalid={is_price_field_disabled}>
            {when(state?.type === "price", "Price to be alerted at in USD", "Percentage to be alerted at (%)")}
          </InputLabel>
          <Input
            step="any"
            type="number"
            isInvalid={is_price_field_disabled}
            // placeholder={`Enter amount in ${payload?.type ?? "..."}`}
            id="price"
            name="price"
            value={state?.price}
            onChange={(e) => set({ price: e.target.value })}
            isRequired
          />

          {!!state?.price && state?.direction === "up" && is_price_invalid && (
            <FormInfo
              info=""
              isInvalid={true}
              description="Price must be greater than the current market price"
              _description={{ ml: "0 !important", fontSize: "sm", fontWeight: "500", mt: "4px !important" }}
            />
          )}

          {!!state?.price && state?.direction === "down" && is_price_invalid && (
            <FormInfo
              info=""
              isInvalid={true}
              description="Price must not be greater than the current market price, less than or equal to zero (0)"
              _description={{ ml: "0 !important", fontSize: "sm", fontWeight: "500", mt: "4px !important" }}
            />
          )}

          {!!state?.price && state?.type === "percentage" && is_percentage_invalid && (
            <FormInfo
              info=""
              isInvalid={true}
              description="Percentage must not be less than one (1) or greater than 100"
              _description={{ ml: "0 !important", fontSize: "sm", fontWeight: "500", mt: "4px !important" }}
            />
          )}

          {!!coinData && (
            <FormInfo mt="6px" info="Current price in USD: " description={currencyFormat("usd").format(current_market_price)} />
            // <Text display="block" mt="8px" fontSize="sm" fontWeight="500">

            // </Text>
          )}
        </FormControl>

        <VStack mt="40px !important" fontFamily="var(--bitmama-fonts-heading)">
          <Button isDisabled={isDisabled} type="submit" minW={{ base: "100%", smx: "330px", "4sm": "400px" }}>
            Proceed
          </Button>
        </VStack>
      </Stack>

      <ConfirmCryptoAlertModal direction={state?.direction as any} isOpen={isOpen} onClose={onClose} {...props} />
    </Stack>
  );
}
