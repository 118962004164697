import {
  Box,
  Button,
  FormHelperText,
  FormControl,
  Checkbox,
  Heading,
  HStack,
  Text,
  useColorModeValue,
  useRadioGroup,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useDeleteAccountMutation } from "apis";
import { Group, Icon, MainLayoutContainer, PageMotion, Radio, Link, InputLabel, ConditionalRender } from "components";
import { useBuiltInFn, useDisclosures, usePartialState, useSelector, useDefaultStyle, useColor } from "hooks";
import { useMemo } from "react";
import { selectUser } from "store/slices";
import { Enter2FAModal, Reset2FAModal } from "ui";
import { Setup2FAModal } from "ui/Account/Modals/DeleteAccount/Setup2FA";
import { AccountDeletionTerms } from "ui/Account/Modals/DeleteAccount/AccountDeletionTerms";
import { recursiveObjectSearch } from "utils";
import Textarea from "components/Input/Textarea";

interface DeleteAccountProps {}

interface IState {
  reason: string;
  twoFaCode: string;
  agreedToTerms: boolean;
  type: string;
}
type ModalTypes = "enter2fa" | "reset2fa" | "setup2fa" | "success" | "terms";

export default function DeleteAccount(props: DeleteAccountProps) {
  const color = useColor();
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { profile } = useSelector(selectUser);

  const { shadow } = useDefaultStyle();

  const { goBack } = useBuiltInFn();
  const toast = useToast();

  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const [state, set] = usePartialState<IState>();

  console.log("Delete State", state);

  const [_deleteAccount, { isLoading }] = useDeleteAccountMutation();

  const handle2FASetup = () => {
    close("setup2fa")();
    navigate("/account/profile?tab=security#2fa");
  };

  const handleDelete = (e: any) => {
    e.preventDefault();
    if (!profile?.twoFactor) return open("setup2fa")();
    else return open("enter2fa")();
  };

  const handleCheckbox = (e: any) => {
    // if (!state?.agreedToLimitations) {
    //   open("agreement")();
    //   return;
    // }
    set({ agreedToTerms: e.target.checked });
  };

  const handleSubmit = async (code?: string) => {
    close("enter2fa")();

    try {
      const response: any = await _deleteAccount({
        ...state,
        reason: state?.reason ?? state?.type,
        twoFaCode: state?.twoFaCode ?? code,
      });

      console.log("Response", response);

      if (response?.data) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        localStorage.clear();

        setTimeout(() => {
          const win: Window = window;
          win.location = "/login";
        }, 1000);
      } else if (!!response?.error) {
        const message = String(recursiveObjectSearch(response?.error, "error") ?? "").toLowerCase();

        // if (message.includes("2fa code is required") && !profile?.twoFactor) navigate("/account/profile?tab=security#2fa");
        // else if (message.includes("2fa code is required") && profile?.twoFactor) open("enter2fa")();

        if (message.includes("2fa code is invalid") && !!profile?.twoFactor) setTimeout(open("enter2fa"), 250);
      }
    } catch (error: any) {}
  };

  const isDisabled = useMemo(
    () => !state?.type || isLoading || !state?.agreedToTerms || (state?.type === "other" && !state?.reason),
    [state, isLoading]
  );

  return (
    <PageMotion subpage key="AccountDeleteAccount" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize={{ base: "18px", "3sm": "24px" }}>
              Account Deletion
            </Heading>

            {/* <AddButton onClick={() => navigate("banks/add")}>Add bank</AddButton> */}
          </HStack>
        </Box>

        <Group as="form" onSubmit={handleDelete}>
          <Box>
            <Heading as="h3" fontSize={{ base: "14px", "2sm": "16px" }}>
              We’re going to miss you!
            </Heading>
            <Text
              mt="14px"
              fontSize={{ base: "12px", "2sm": "14px" }}
              lineHeight={{ base: "16px", "2sm": "19px" }}
              fontWeight="500"
            >
              Kindly select a reason for deleting your account to help us serve you better next time
            </Text>
          </Box>

          <RadioOptionGroup value={state?.type ?? ""} onChange={(type) => set({ type })} />

          <Box mt="52px !important" bg={color("#ebebeb", "dark.cardBg")} p="16px 16px" borderRadius="6px">
            <ConditionalRender shouldRender={!!state?.type?.includes("other")}>
              <FormControl mb="20px">
                <InputLabel _text={{ fontWeight: 600 }}>Let us know your reasons</InputLabel>
                <Textarea value={state?.reason} onChange={(e) => set({ reason: e.target.value })} />
              </FormControl>
            </ConditionalRender>

            <FormControl>
              <FormHelperText m="0" fontWeight="500">
                <HStack
                  justifyContent={["flex-start", "space-between"]}
                  alignItems={["flex-start", "flex-start", "center"]}
                  flexDir={["column", "column", "row"]}
                >
                  {/* {console.log({ state })} */}
                  <Checkbox
                    // dir="rtl"
                    colorScheme="primary"
                    isChecked={state?.agreedToTerms}
                    onChange={handleCheckbox}
                    sx={{
                      ".chakra-checkbox__control[data-focus]": {
                        shadow,
                      },
                      ".chakra-checkbox__control": {
                        borderColor: color("#303131", "#303131"),
                        borderRadius: "4px",
                        boxSize: "16px",
                        borderWidth: "1px",
                      },
                      ".chakra-checkbox__control[data-checked]": {
                        borderColor: color("primary.500", "primary.500"),
                      },
                      ".chakra-checkbox__label": {
                        color: color("#303131", "white"),
                        fontSize: "14px",
                      },
                    }}
                  >
                    I agree to the terms and conditions of deleting my account
                  </Checkbox>
                </HStack>
              </FormHelperText>
            </FormControl>

            <Link
              mt="10px"
              ml="24px"
              fontSize="12px"
              fontWeight="700"
              color={color("primary.default", "secondary.500")}
              onClick={open("terms")}
              type="button"
            >
              See TERMS & CONDITIONS
            </Link>
          </Box>

          <VStack mt="70px !important">
            <Button
              // onClick={handleDelete}
              type="submit"
              fontFamily="var(--bitmama-fonts-heading)"
              isLoading={isLoading}
              isDisabled={isDisabled}
              minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
            >
              Delete Account
            </Button>
          </VStack>
        </Group>

        <Setup2FAModal isOpen={isOpen("setup2fa")} onClose={close("setup2fa")} onContinue={handle2FASetup} />
        <Enter2FAModal
          isOpen={isOpen("enter2fa")}
          onClose={close("enter2fa")}
          onContinue={handleSubmit}
          onResetOpen={open("reset2fa")}
          onPin={(twoFaCode) => set({ twoFaCode })}
        />
        <Reset2FAModal isOpen={isOpen("reset2fa")} onClose={close("reset2fa")} />

        <AccountDeletionTerms isOpen={isOpen("terms")} onClose={close("terms")} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

interface RadioOptionGroupProps {
  value?: string;
  onChange?: (value: string) => void;
}

function RadioOptionGroup(props: RadioOptionGroupProps) {
  const { value, onChange } = props;

  const options = [
    { label: "No longer interested in the service", value: "no_interest" },
    { label: "Just testing around", value: "just_testing" },
    { label: "Gotten a better option", value: "got_better_option" },
    { label: "Not satisfied", value: "not_satisfied" },
    { label: "Other", value: "other" },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "delete-account-radio-group",
    defaultValue: value,
    onChange: (value: string) => {
      onChange && onChange(value);
    },
  });

  const group = getRootProps();

  return (
    <Group mt="46px !important" {...group} gridGap="10px" fontSize="8px">
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value });
        return (
          <Radio key={value} _after={{ opacity: 0 }} {...radio}>
            {label}
          </Radio>
        );
      })}
    </Group>
  );
}
