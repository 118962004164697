import { Box, Button, Heading, HStack, Stack, Text, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
import { AccountLink, ShareActions, EarnAnnouncement, Icon, IconNames, Link, MainLayoutContainer, PageMotion } from "components";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { useSelector } from "hooks";
import JoinPartners from "ui/Account/Modals/Partners/JoinPartners";
import { useMemo } from "react";

interface PartnersProps extends PageProps {}

type LinkType = { icon: IconNames; label: string; link: string; onClick?: () => void; isPartner?: boolean };

export default function Partners(props: PartnersProps) {
  const { user } = props;
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const accLinkBg = useColorModeValue("white", "dark.cardBg");
  const bColor = useColorModeValue("grey.150", "dark.border");

  const isAffiliatePartner = useMemo(() => user?.affiliatePartner, [user]);

  const { goBack } = useBuiltInFn();
  const { links, join } = usePartnersFeatureList(isAffiliatePartner);
  const { isOpen, onClose } = join;

  const { profile } = useSelector((state) => state.user);

  return (
    <PageMotion subpage key="AccountPartners" overflowY="hidden" maxH="100%">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize={{ base: "18px", "3sm": "24px" }}>
              Bitmama Partners
            </Heading>
          </HStack>
        </Box>

        <Box my="40px">
          <EarnAnnouncement minH="210px" width="100%" _img={{ w: "calc(100% / 1.8)" }} />

          <VStack maxW="320px" m="0 auto" my="46px" textAlign="center">
            <Heading as="h6" fontSize={{ base: "14px", "2sm": "16px" }} lineHeight={{ base: "17px", "2sm": "19px" }}>
              We Earn, You Earn...
            </Heading>
            <Text fontWeight="500" fontSize={{ base: "14px", "2sm": "12px" }} lineHeight={{ base: "16px", "2sm": "19px" }}>
              ....When a user you have referred complete transactions on selected service
            </Text>

            <Link
              to="#"
              fontSize={{ base: "12px", "2sm": "10px" }}
              lineHeight={{ base: "16px", "2sm": "13px" }}
              fontWeight="500"
              textDecoration="underline"
            >
              All T&Cs apply
            </Link>
          </VStack>

          <ShareActions title={profile?.refCode} copyText="Copy Referral Code" />

          <Stack>
            {links.map((link, i) => (
              <>
                {!!link?.isPartner && link.link === "history" && (
                  <AccountLink
                    to={link.link}
                    bg={accLinkBg}
                    icon={link.icon}
                    label={link.label}
                    mt="18px !important"
                    arrowIcon="arrowRight"
                    key={`partner-link-${i}`}
                    borderBottomColor={bColor}
                    onClick={link?.onClick}
                    fontSize="14px"
                  />
                )}

                {!link?.isPartner && !(link.link === "history") && (
                  <AccountLink
                    to={link.link}
                    bg={accLinkBg}
                    icon={link.icon}
                    label={link.label}
                    mt="18px !important"
                    arrowIcon="arrowRight"
                    key={`partner-link-${i}`}
                    borderBottomColor={bColor}
                    onClick={link?.onClick}
                    fontSize="14px"
                  />
                )}
              </>
            ))}
          </Stack>
        </Box>

        <JoinPartners isOpen={isOpen} onClose={onClose} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

function usePartnersFeatureList(isAffiliatePartner = false) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const links: LinkType[] = [
    { icon: "joinPartner", label: "Join Bitmama Partners Program", link: "#", onClick: onOpen, isPartner: isAffiliatePartner },
    { icon: "joinPartner", label: "View Bitmama Partners Program", link: "history", isPartner: isAffiliatePartner },
    {
      icon: "resources",
      label: "Resources",
      link: "#",
      onClick: () => window.open("https://drive.google.com/drive/folders/1_owkrCizRH9ip5Ra9Xw70UN4NyUJBRgy?usp=sharing", "_blank"),
    },
    { icon: "regulations", label: "Rules and Regulations", link: "rules" },
    { icon: "leaderboard", label: "Leaderboard", link: "leaderboard" },
  ];

  return { links, join: { isOpen, onClose } };
}
