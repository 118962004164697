import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { AbstractModal, BadgeIcon, ConditionalRender } from "components";
import { useColor } from "hooks";
import { GameHistoryRo } from "interfaces";
import { useMemo } from "react";

interface Props extends Omit<ModalProps, "children">, Partial<GameHistoryRo> {}

// @Dayo, pls fix this * (Fixed) temporarily observing the behavious.
export function NewEarnBadgeUpdate(props: Props) {
  const { isOpen, onClose, logoUrl, description, title, reward } = props;

  // console.log("IS SHOWN");

  const color = useColor();
  //   const { shadow } = useDefaultStyle();
  //   const [dontShow, SetDontShow] = useState(false);

  // const data = { cycle: 0 };

  const handleClose = () => {
    onClose();
    // if (!!dontShow) ls.set(configs.XMAS_DONT_SHOW, { cycle: data?.cycle });
  };

  // const goTo = () => {
  //   handleClose();
  //   navigate(`/account/achievements/badges?tab=badges`);
  // };

  const goToReward = () => {
    handleClose();
    navigate(`/account/achievements/badges?tab=rewards`);
  };

  const hasReward = useMemo(() => {
    if (!!reward && reward?.amount > 0) return true;
    return false;
  }, [reward]);

  const hasLogo = useMemo(() => {
    if (!logoUrl) return false;
    if (logoUrl?.length < 1 || ["http:dummy.com", ""].includes(logoUrl)) return false;
    return true;
  }, [logoUrl]);

  // if (dontShow) {
  //   return null;
  // }

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "579px",
        borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        position: { base: "absolute", "1sm": "initial" },
        padding: { base: "0", "1sm": "0", md: "20px" },
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
        overflow: "hidden",
        backgroundSize: "cover",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <Box minH={{ base: "auto", md: "570px" }} borderRadius={{ base: "30px 30px 0px 0px", "1sm": "14px" }}>
        <VStack p={{ base: "16px", md: "30px" }} pos="relative">
          <VStack py="16px">
            <BadgeIcon
              boxSize="112px"
              imgUrl={logoUrl}
              badge="newBitStar"
              //   locked={isLocked}
              use_image={hasLogo}
            />

            <Text
              mt="12px !important"
              fontSize="12px"
              fontFamily="var(--bitmama-fonts-heading)"
              bg={color("white", "black")}
              shadow="0px 2px 20px 0px #00000026"
              p="10px 16px"
              borderRadius="30px"
              fontWeight="600"
            >
              🌟 New Badge Alert! 🏆
            </Text>

            <Box my={{ base: "16px !important", md: "32px !important" }} maxW={{ base: "80%", md: "68%" }}>
              <Heading
                as="h6"
                my="10px"
                fontSize="18px"
                // w="285px"
                fontFamily="var(--bitmama-fonts-heading)"
                textAlign="center"
                //   color="white"
              >
                {title ?? "You have gained 1 USDT For Unlocking the Bitstar Badge"}
              </Heading>
              <Text mt="22px" fontWeight="500" fontSize="14px" color={color("black", "white")} textAlign="center">
                {description ?? "Add this badge to your badge collection by carrying out up to 5 Buy or Sell Transactions per day"}
              </Text>
            </Box>
          </VStack>

          <VStack mt="12px !important" alignItems="revert-layer">
            {/* <ConditionalRender shouldRender={!hasReward}>
              <Button fontFamily="var(--bitmama-fonts-heading)" onClick={goTo}>
                Share this with your circle
              </Button>
            </ConditionalRender> */}

            <ConditionalRender shouldRender={hasReward}>
              <Button fontFamily="var(--bitmama-fonts-heading)" onClick={goToReward}>
                Click to view reward
              </Button>
            </ConditionalRender>

            <Button
              minW={{ base: "initial", md: "400px" }}
              variant="outline"
              mt={{ base: "0px !important", md: "16px !important" }}
              color="black"
              onClick={handleClose}
            >
              Close
            </Button>
          </VStack>

          {/* <VStack>
            <Checkbox
              // dir="rtl"
              //   colorScheme="primary"
              color="white"
              isChecked={dontShow}
              onChange={(e) => SetDontShow(e.target.checked)}
              sx={{
                "chakra-checkbox__control[data-focus]": {
                  shadow,
                },
              }}
            >
              Don't show again
            </Checkbox>
          </VStack> */}
        </VStack>
      </Box>
    </AbstractModal>
  );
}

// export function ChristmasUpdate(_props: Props) {
//   return null;
// }
