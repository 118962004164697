import { SupportedColorModesType } from "theme/foundations/colors";

type InputType<T> = { [key in SupportedColorModesType]: T };

export default function switchStyle<T>(
  mode: SupportedColorModesType,
  input: InputType<T>
) {
  return input[mode];
}
