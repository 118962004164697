import { BoxProps } from "@chakra-ui/react";
import MainLayoutContainer from "components/MainLayoutContainer/MainLayoutContainer";

interface LayoutProps extends BoxProps {}

export function AchievementsLayout(props: LayoutProps) {
  const { children, ...xprops } = props;
  return (
    <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px" {...xprops}>
      {children}
    </MainLayoutContainer>
  );
}
