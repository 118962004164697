import { Textarea as ChakraTextarea, TextareaProps as ChakraTextareaProps } from "@chakra-ui/react";
import { useColor } from "hooks";
import { FC } from "react";

interface TextareaProps extends ChakraTextareaProps {}

const Textarea: FC<TextareaProps> = (props) => {
  const color = useColor();

  return (
    <ChakraTextarea
      isRequired
      //   minH="50px"
      px="20px"
      fontFamily="var(--bitmama-fonts-heading)"
      border="1px solid transparent"
      borderRadius="8px"
      borderColor={color("grey.100", "white")}
      errorBorderColor="red.400"
      bg={color("#f2f2f2", "dark.cardBg")}
      _focus={{
        // borderWidth: "1px",
        bg: color("#f2f2f2", "dark.cardBg"),
        borderColor: color("#0D4740", "secondary.400"),
        // shadow: mode("outline", "darkOutline")(props),
        shadow: "none",
      }}
      _hover={{
        borderColor: "brand.primary",
      }}
      sx={{
        "::placeholder": {
          color: "grey.300",
        },
      }}
      {...props}
    />
  );
};

export default Textarea;
