import { Box, useColorModeValue } from "@chakra-ui/react";
import { capitalize } from "@mui/material";
import {
  Card,
  DetailItem as DI,
  DetailItemProps,
  Group,
  MainLayoutContainer,
  Title,
  TitleBar,
  useModalView as useSteps,
} from "components";
import { useSidePage } from "contexts";
import { format, parseISO } from "date-fns";
import { WalletTransactionRo } from "interfaces";
import { useEffect, useMemo } from "react";
import { useSelector } from "hooks";
import { currencyFormat, isFiat, toPrecision } from "utils";

export function BuyAndSellTransactionDetails(props: any) {
  const { hasPrev, setCurrentView } = useSteps();
  const { updateSidePageConfigs } = useSidePage();
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const tx = useSelector((state) => state.filter.transactionDetails) as WalletTransactionRo;

  // const tx = useMemo(() => when<Partial<WalletTransactionRo>>(!!data?.tx, data?.tx, {}), [data?.tx]);

  const datetime = useMemo(() => {
    if (!!tx && !!tx?.createdAt) return format(parseISO(tx?.createdAt!), "dd MMM, yyyy, hh:mm aa");
    return "";
  }, [tx]);

  const amount = useMemo(() => {
    if (!tx || !tx?.unit) return "0.00";
    if (!!tx?.unit && isFiat(tx?.unit)) return currencyFormat(tx?.unit as any).format(+tx?.value?.$numberDecimal! ?? 0);
    if (!!tx?.unit && !isFiat(tx?.unit)) return toPrecision(+tx?.value?.$numberDecimal! ?? 0, tx?.unit);

    return "0.00";
  }, [tx]);

  useEffect(() => {
    if (hasPrev) {
      console.log("setting back click....");
      updateSidePageConfigs({ _back: { text: "Back", onClick: () => setCurrentView(0) } });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box px={{ base: "20px", "2sm": "32px" }} {...props} overflowY="scroll">
      <TitleBar>
        <Title fontSize="20px">Transaction Details</Title>
      </TitleBar>

      <MainLayoutContainer maxW="2xl" mt="28px" px={{ base: "0", "2sm": "20px", "3sm": "40px" }}>
        <Group mt="0" w="100%" py="52px" {...props}>
          <Card bg={bg} w="100%" p="24px 34px" overflow="hidden">
            <DetailItem title="Transaction Type" description={capitalize(tx?.extraSubjectOfTransaction ?? "")} />
            <DetailItem title="Date & Time" description={datetime} />
            <DetailItem title="Amount" description={amount} />
            <DetailItem title="Transaction Ref" description={tx?._id} isCopyable />
          </Card>
        </Group>
      </MainLayoutContainer>
    </Box>
  );
}

function DetailItem(props: DetailItemProps) {
  const titleColor = useColorModeValue("black", "white");
  const descColor = useColorModeValue("grey.500", "white");

  return (
    <DI
      borderColor="transparent"
      _title={{ color: titleColor }}
      _description={{ color: descColor, fontWeight: "normal", fontSize: "16px" }}
      {...props}
    />
  );
}
