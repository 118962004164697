export const calculateAge = (birthDate: Date) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const birthYear = new Date(birthDate).getFullYear();
  const birthMonth = new Date(birthDate).getMonth();
  const birthDay = new Date(birthDate).getDate();
  let calculatedAge = currentYear - birthYear;

  if (currentMonth < birthMonth - 1) {
    calculatedAge--;
  }
  if (birthMonth - 1 === currentMonth && currentDay < birthDay) {
    calculatedAge--;
  }
  return calculatedAge;
};
