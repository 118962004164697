import { Box, Button, Image, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useGetUserCardsQuery } from "apis";

import VirtualCardsEmpty from "assets/images/card-empty.png";
import { PageLoading } from "components";
import { SidePageProvider } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { AnimatePresence, motion } from "framer-motion";
import { useDisclosures, useSelector } from "hooks";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { selectUser } from "store/slices";
import VirtualCardDetails from "ui/Cards/Features/Virtual/CardDetailsIndex";
import FeatureUnavailableModal from "ui/Common/Modals/FeatureUnavailable";
import { toQueryString, when } from "utils";
import CardsView from "./CardsView";
import { CardFeatureAvalability } from "ui/Cards/Modals/CardFeatureAvailability";

import ls from "utils/secureStorage";

interface VirtualProps {}

type ModalTypes = "featureUnavailable" | "cardFeatureAvailability";

function EmptyVirtualCard() {
  const color = useColorModeValue("grey.300", "white");

  const { profile } = useSelector(selectUser);
  const isEligible = useMemo(() => ["two", "three"].includes(profile?.kycLevel ?? "one"), [profile]);

  const { appfigs } = useAppConfig();
  const { cardConfig } = appfigs ?? {};

  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const isCreateCardDisabled = useMemo(() => cardConfig?.disableCardCreate ?? false, [cardConfig]);

  const handleCreateCard = () => {
    if (isCreateCardDisabled) open("featureUnavailable")();
    else navigate("/cards/request");
  };

  return (
    <VStack py="86px" width="100%">
      <Image maxW="152px" src={VirtualCardsEmpty} />
      <Text mt="28px !important" fontWeight="500" color={color}>
        No Cards added
      </Text>

      <Button
        mt={{ base: "59px !important", "3sm": "90px !important" }}
        onClick={handleCreateCard}
        minW={{ base: "100%", smx: "336px", "4sm": "400px" }}
        disabled={!isEligible}
      >
        Request Virtual Card
      </Button>

      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </VStack>
  );
}

const DISABLED_CARD_REMIND = "dcr";
export default function Virtual(props: VirtualProps) {
  const [queries, setQueries] = useState({ active: true });
  const { appfigs } = useAppConfig();
  const { data, isLoading, isFetching } = useGetUserCardsQuery(toQueryString({ ...queries }));
  const { isOpen, close, onOpen } = useDisclosures<ModalTypes>();

  const cards = useMemo(() => data?.card, [data]);

  console.log("CArds", data);

  const { cardConfig } = appfigs ?? {};

  const handleClose = () => {
    close("cardFeatureAvailability")();
    ls.set(DISABLED_CARD_REMIND, "remind");
  };

  useEffect(() => {
    const remind = ls.get(DISABLED_CARD_REMIND);
    if (!!cardConfig?.disableFundCard && !isOpen("cardFeatureAvailability") && !remind) onOpen("cardFeatureAvailability");
  }, [cardConfig, onOpen, isOpen]);

  return (
    <VStack>
      <Box
        maxW={{ sm: "100%", "2sm": "container.lg" }}
        w={{ sm: "100%", "3sm": "100%", md: "100%", lg: "fit-content" }}
        m="0"
        alignSelf="flex-start"
      >
        <SidePageProvider>
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div
              key={`virtual-card-${when(isLoading, "loading", "loaded")}`}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.98 }}
            >
              <VStack pos="relative" w="100%">
                {isLoading && isEmpty(cards ?? []) && <PageLoading px="300px" py="200px" isLoading={isLoading} />}
                {/* {!isLoading && !isEmpty(cards ?? []) && ( */}
                <CardsView
                  totalCount={data?.totalCount ?? 0}
                  cards={cards ?? []}
                  isLoading={isLoading}
                  isFetching={isFetching}
                  setQueries={setQueries}
                />
                {/* )} */}

                {!isLoading && isEmpty(cards) && <EmptyVirtualCard />}
              </VStack>
            </motion.div>
          </AnimatePresence>

          <VirtualCardDetails key="VirtualCardDetails" hasBackButton _back={{ text: "Close" }} />
          <CardFeatureAvalability isOpen={isOpen("cardFeatureAvailability")} onClose={handleClose} />
        </SidePageProvider>
      </Box>
    </VStack>
  );
}
