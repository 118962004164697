import { ButtonProps, Grid, HStack, Stack, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import BadgeIcon, { GamificationBadgesType } from "components/Icon/BadgeIcon";
import Icon from "components/Icon/Icon";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { useDefaultStyle } from "hooks";
import { useMemo, useRef } from "react";
import { when } from "utils";

const list: Variants = {
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transformOrigin: "top",
    rotateX: "0deg",
    transition: {
      type: "spring",
      bounce: 0.36,
      duration: 1,
      delay: i * 0.05,
    },
  }),
  hidden: (i: number) => ({
    opacity: 0,
    y: 40,
    rotateX: "-28deg",
    transformOrigin: "top",
    transition: {
      type: "spring",
      bounce: 0.46,
      duration: 1,
      delay: i * 0.05,
    },
  }),
};

export function AchievementHistoryInfoList({ children, isLoading, ...xprops }: any) {
  const MotionGroup = motion<any>(Stack);

  const key = useMemo(() => when(isLoading, "history-list-open", "history-list-close"), [isLoading]);

  return (
    <AnimatePresence initial={false} key={key} exitBeforeEnter>
      <MotionGroup sx={{ perspective: "1000px" }} key={key} p="0" m="0" {...xprops}>
        {children}
      </MotionGroup>
    </AnimatePresence>
  );
}

export interface HistoryInfoProps extends ButtonProps {
  badge: GamificationBadgesType;
  value?: number;
  index?: number;
  anotherValue?: number;
  title?: string;
  description?: string;
  logoUrl?: string;
}

export function AchievementHistoryInfo(props: HistoryInfoProps) {
  const { index = 0, badge, value, anotherValue, title, description, logoUrl, ...xprops } = props;

  const played = useRef(false);

  const { borderColor, shadow } = useDefaultStyle();

  const hasLogo = useMemo(() => {
    if (!logoUrl) return false;
    if (logoUrl?.length < 1 || ["http:dummy.com", ""].includes(logoUrl)) return false;
    return true;
  }, [logoUrl]);

  const imgUrl = useMemo(() => when(hasLogo, logoUrl, undefined), [hasLogo, logoUrl]);

  console.log("History Image URL", logoUrl, imgUrl);

  return (
    <Card
      as="button"
      w="100%"
      //   minW={{ base: "initial", "1sm": "340px" }}
      p="18px 28px"
      border="1px solid transparent"
      _focus={{ shadow, borderColor }}
      _hover={{ borderColor }}
      {...(xprops as any)}
      outline="1px solid transparent"
      initial="hidden"
      animate="visible"
      variants={!played.current ? list : {}}
      custom={index}
      onAnimationComplete={() => (played.current = true)}
    >
      <HStack justifyContent="space-between">
        <Grid templateColumns={{ sm: "48px 1fr", md: "65px 1fr" }} alignItems="center" gap={{ base: "15px", "2sm": "18px" }}>
          <BadgeIcon imgUrl={imgUrl} boxSize={{ sm: "48px", md: "68px" }} badge={badge ?? "newBitStar"} use_image={hasLogo} />

          <Stack justifyContent="flex-start" alignItems="baseline">
            <Text fontWeight="500" fontFamily="var(--bitmama-fonts-heading)" textAlign="left">
              {title ?? "You have unlocked the New Bitstar Badge"}
            </Text>
            <Text mt="0 !important" fontSize="14px" fontWeight="700" color="secondary.500" textAlign="left">
              {description ?? "Congrats on making your first Transaction on Bitmama!"}
            </Text>
          </Stack>
        </Grid>

        <Icon type="chevronRightArrow" sx={{ path: { strokeWidth: "2px" } }} />
      </HStack>
    </Card>
  );
}
