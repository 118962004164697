import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  //   useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useLocation, useParams } from "@reach/router";
import { useGetCoinToUsdRateQuery, useGetSellListingByIdQuery } from "apis";
import {
  AmountInput,
  Card,
  ConditionalRender,
  CopyButton,
  FormInfo,
  Icon,
  InputLabel,
  MainLayoutContainer,
  PageLoading,
  PaymentMethod,
  Select,
  ShareActions,
  Span,
  Steps,
  Uploader,
  Username,
  useModalView as useSteps,
} from "components";
import {
  useCoin,
  useColor,
  useCopy,
  useDisclosures,
  useISOCountDown,
  usePartialState,
  useSelector,
  useUpdateP2POrder,
} from "hooks";
import { P2PListingTradeRo, P2PPendingTradeCounterPartRo } from "interfaces";
import { useMemo, useState } from "react";
import { clamp, currencyFormat, deduction, toCoinLocale, toPrecision, uploadFile, when } from "utils";

import toLower from "lodash/toLower";
import { ScreenMapperProps, useP2PSession } from "../..";
import { ConfirmationModal } from "ui";
import { selectUser } from "store/slices";

interface TradeOwnerMakePaymentProps extends ScreenMapperProps {}
interface InfoHubProps extends Partial<P2PListingTradeRo> {
  contract: ScreenMapperProps;
}

interface FormProps extends Partial<P2PListingTradeRo>, IStateExtension {
  unitCost?: number;
  contract: ScreenMapperProps;
}
interface DetailsProps extends Partial<P2PListingTradeRo> {
  unitCost?: number;
  contract: ScreenMapperProps;
}

interface IStateExtension {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  reset: () => void;
}

interface IPaymentOption {
  method: string; // bankTransfer | changeraId - (left out for momentarily) | bitmamaWallet
  receivingIntoLabel: string; // same as bank name
  receivingIntoAddress: string; // same as account number;
  accountName: string; // bank account name
  saveRecord: boolean;
  payingToLabel: string; // same as bankName, used when state?.type is buy;
}

interface IState {
  method: string;
  amount: string;
  volume: string;
  saveRecord: boolean;
  paymentOption: IPaymentOption[];
  proof: File;
}

export function TraderMakePayment(props: TradeOwnerMakePaymentProps) {
  // const { ref } = useParams();

  // const [id] = String(ref ?? "").split("-");

  const [state, set, reset] = usePartialState();

  // const { data, isLoading } = useGetSellListingByIdQuery(id);

  // const reMapCoin = (coin: string) => {
  //   const map: Record<string, string> = {
  //     [coin]: coin,
  //     tbtc: "btc",
  //     teth: "eth",
  //     usdt: "usd",
  //     cusd: "usd",
  //   };
  //   return map[coin];
  // };

  // const { data: rate } = useGetCoinToUsdRateQuery(reMapCoin(data?.coin ?? "btc"), { skip: (data?.coin ?? "btc").includes("usd") });

  // const exRate = useMemo(() => +(rate?.exchangeRate?.buy ?? 1), [rate]);

  // const unitCost = useMemo(() => {
  //   if (data?.dynamic) return data?.coin?.includes("usd") ? 1 * data?.price : exRate * data?.price;
  //   return data?.tradeOption?.unitCost;
  // }, [data, exRate]);

  // console.log("Data", data, rate);

  return (
    // <Box w="100%">
    //   {isLoading && <PageLoading my="60px" isLoading={isLoading} />}

    //   {!isLoading && (
    //     <>
    //       <InfoHub {...data} />

    //       <Grid my="40px" templateColumns="repeat(2,1fr)" gap="22px">
    //         <PaymentDetails {...{ ...data, state, set, reset, unitCost, contract: props }} />
    //         <AdDetails unitCost={unitCost} {...data} />
    //       </Grid>
    //     </>
    //   )}
    // </Box>

    <Steps hideIndicator>
      {/* <StepOneSelect {...{ state, set, reset, contract: props }} /> */}
      <MarkAsPaid {...{ state, set, reset, contract: props }} />
      <UploadAndConfirm {...{ state, set, reset, contract: props }} />
    </Steps>
  );
}

type ModalTypes = "paid" | "cancel";

const floatString = (n: string | number): number | string =>
  (n && (Number.isInteger(n) ? Number(n).toFixed(2) : n.toString())) ?? "";

export function StepOneSelect(props: FormProps) {
  const { ref } = useParams();
  const [id] = String(ref ?? "").split("-");

  const { data, isLoading } = useGetSellListingByIdQuery(id);
  // const { data: sessionData } = useGetBuyTradeSessionQuery(ref);

  // console.log()

  const reMapCoin = (coin: string) => {
    const map: Record<string, string> = {
      [coin]: coin,
      tbtc: "btc",
      teth: "eth",
      usdt: "usd",
      cusd: "usd",
    };
    return map[coin];
  };

  const { data: rate } = useGetCoinToUsdRateQuery(reMapCoin(data?.coin ?? "btc"), { skip: (data?.coin ?? "btc").includes("usd") });

  const exRate = useMemo(() => +(rate?.exchangeRate?.buy ?? 1), [rate]);

  const unitCost = useMemo(() => {
    if (data?.dynamic) return data?.coin?.includes("usd") ? 1 * data?.price : exRate * data?.price;
    return data?.tradeOption?.unitCost;
  }, [data, exRate]);

  console.log("Data", data, rate);

  return (
    <Box w="100%">
      {isLoading && <PageLoading my="60px" isLoading={isLoading} />}

      {!isLoading && (
        <>
          <InfoHub contract={props.contract} {...data} />

          <Grid my="40px" templateColumns="repeat(2,1fr)" gap="22px">
            <PaymentDetails {...{ ...data, unitCost, ...props }} />
            <AdDetails contract={props.contract} unitCost={unitCost} {...data} />
          </Grid>
        </>
      )}
    </Box>
  );
}

function MarkAsPaid(props: FormProps) {
  const {
    contract: { counterParties, currency, amount, setHideBackButton },
    // state,
  } = props;

  const { ref } = useParams();
  const color = useColor();
  const { onPrev } = useSteps();
  // const { profile } = useSelector(selectUser);

  const { setChatState } = useP2PSession();

  const [id, frozenRef] = String(ref ?? "").split("-");
  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const [isUploading /*setIsUploading*/] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const { isUpdating, updateOrder } = useUpdateP2POrder(id, frozenRef);

  const trader = useMemo(
    () => (counterParties ?? [])?.find((p) => p?.role === "trader"),
    [counterParties]
  ) as P2PPendingTradeCounterPartRo;

  const { timer: countDown, isApproachingLimit } = useISOCountDown(
    trader?.timeline?.dueAt ?? "",
    `${trader?.timeline?.duration} : 00mins`,
    { timedOutText: "00:00" }
  );

  const handleSubmit = async () => {
    // onNext()
    open("paid")();

    // if (!state?.proof) return;
    // setIsUploading(true);
    // const res = await uploadFile(state?.proof, `${profile?.username}-waiver-${id}`);
    // const result = await updateOrder("CONFIRMPAYMENT", "Confirm Payment");
    // setIsUploading(false);
    // console.log("Confirm P2P payment result", result, onNext);
    // if
  };

  const handlePrev = () => {
    onPrev();
    setShowButton(false);
    setHideBackButton(false);
  };

  // const isDisabled = useMemo(() => isUploading || isUpdating || !state?.proof, [isUploading, isUpdating, state]);
  const isDisabled = useMemo(() => isUploading || isUpdating, [isUploading, isUpdating]);

  return (
    <MainLayoutContainer maxW={["426px"]} m="0" px="4px" py="4px">
      <Box mb="20px">
        {showButton && (
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={color("primary.600", "secondary.200")} />}
            onClick={handlePrev}
            px="2px !important"
          >
            Back
          </Button>
        )}
        <HStack mt="12px" justifyContent="space-between">
          <Heading as="h4" fontSize="24px" fontWeight="bold">
            Seller details
          </Heading>

          <IconButton
            p="0 !important"
            minW="unset"
            maxW="unset"
            maxH="unset"
            minH="unset"
            w="32px"
            h="32px"
            // bg="transparent"
            aria-label="chat button"
            icon={<Icon type="messageCircle" />}
            onClick={() => setChatState({ chatOpened: true })}
          />
        </HStack>
      </Box>

      <BankDetails frozenRef={frozenRef} {...trader} />

      <FormControl mb="34px">
        <HStack my="20px" alignItems="flex-start">
          <Icon type="infoErrorRounded" />

          <Box>
            <Text fontSize="14px" fontWeight="500" color={color("black", "white")}>
              Please ensure payment is made within{" "}
              <Span fontWeight="600" color={when(isApproachingLimit, "red", color("secondary.500", "secondary.400"))}>
                {countDown}
              </Span>
              , else transaction would be cancelled.
            </Text>
            <Text fontSize="14px" fontWeight="500" color={color("error", "error")}>
              Do not include any crypto related keyword in the payment narration.
            </Text>
          </Box>
        </HStack>
      </FormControl>

      <FormControl>
        <Heading fontSize="sm">Mark payment as paid</Heading>
        <Text fontSize="14px" fontWeight="500" textAlign="left" mx="0" my="28px">
          Please confirm that you have make payment of {currencyFormat((currency as any) ?? "ngn").format(amount ?? 0)} to the
          buyer's bank account.
        </Text>
      </FormControl>

      <VStack mt="50px">
        <Button isLoading={isUpdating || isUploading} disabled={isDisabled} onClick={handleSubmit}>
          Mark As Paid
        </Button>
        <Button disabled={isUploading || isUpdating} variant="transparent" textDecoration="underline" onClick={open("cancel")}>
          Cancel Trade
        </Button>
      </VStack>

      {/* <ConfirmationModal isOpen={isOpen("paid")} onClose={close("paid")} title="Confirm Payment" onConfirm={() => handleSubmit()}>
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            Please confirm that you have make payment of {currencyFormat((currency as any) ?? "ngn").format(amount ?? 0)} to seller
            bank account.
          </Text>

          <Uploader onFiles={(files) => set({ proof: files[0] })} />
        </VStack>
      </ConfirmationModal> */}

      <ConfirmationModal
        isOpen={isOpen("cancel")}
        onClose={close("cancel")}
        title="Confirm Cancel Trade"
        onConfirm={() => updateOrder("CANCEL")}
      >
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            By cancelling this order, the coins would be returned back to the seller, and the funds are not refundable.
          </Text>
        </VStack>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={isOpen("paid")}
        onClose={close("paid")}
        title="Mark As Paid"
        onConfirm={() => updateOrder("CONFIRMPAYMENT", "Confirm Payment")}
      >
        <VStack>
          <Text fontSize="16px" fontWeight="500" textAlign="center" my="32px">
            Confirm payment has been made and mark this payment as paid.
          </Text>
        </VStack>
      </ConfirmationModal>
    </MainLayoutContainer>
  );
}

function UploadAndConfirm(props: FormProps) {
  const {
    contract: { counterParties, currency, amount, setHideBackButton },
    state,
    set,
  } = props;

  const { ref } = useParams();
  const color = useColor();
  const { onPrev } = useSteps();
  const { profile } = useSelector(selectUser);

  const { setChatState } = useP2PSession();

  const [id, frozenRef] = String(ref ?? "").split("-");
  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const [isUploading, setIsUploading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const { isUpdating, updateOrder } = useUpdateP2POrder(id, frozenRef);

  const trader = useMemo(
    () => (counterParties ?? [])?.find((p) => p?.role === "trader"),
    [counterParties]
  ) as P2PPendingTradeCounterPartRo;

  // const { timer: countDown, isApproachingLimit } = useISOCountDown(
  //   trader?.timeline?.dueAt ?? "",
  //   `${trader?.timeline?.duration} : 00mins`,
  //   { timedOutText: "00:00" }
  // );

  const handleSubmit = async () => {
    if (!state?.proof) return;
    setIsUploading(true);
    const res = await uploadFile(state?.proof, `${profile?.username}-waiver-${id}`);
    await updateOrder("CONFIRMPAYMENT", "Confirm Payment", res?.location);
    setIsUploading(false);
  };

  const handlePrev = () => {
    onPrev();
    setShowButton(false);
    setHideBackButton(false);
  };

  const isDisabled = useMemo(() => isUploading || isUpdating || !state?.proof, [isUploading, isUpdating, state]);

  return (
    <MainLayoutContainer maxW={["426px"]} m="0" px="4px" py="4px">
      <Box mb="20px">
        {showButton && (
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={color("primary.600", "secondary.200")} />}
            onClick={handlePrev}
            px="2px !important"
          >
            Back
          </Button>
        )}
        <HStack mt="12px" justifyContent="space-between">
          <Heading as="h4" fontSize="24px" fontWeight="bold">
            Seller details
          </Heading>

          <IconButton
            p="0 !important"
            minW="unset"
            maxW="unset"
            maxH="unset"
            minH="unset"
            w="32px"
            h="32px"
            // bg="transparent"
            aria-label="chat button"
            icon={<Icon type="messageCircle" />}
            onClick={() => setChatState({ chatOpened: true })}
          />
        </HStack>
      </Box>

      <BankDetails frozenRef={frozenRef} {...trader} />

      {/* <FormControl mb="34px">
        <HStack my="20px" alignItems="flex-start">
          <Icon type="infoErrorRounded" />

          <Box>
            <Text fontSize="14px" fontWeight="500" color={color("black", "white")}>
              Please ensure payment is made within{" "}
              <Span fontWeight="600" color={when(isApproachingLimit, "red", color("secondary.500", "secondary.400"))}>
                {countDown}
              </Span>
              , else transaction would be cancelled.
            </Text>
            <Text fontSize="14px" fontWeight="500" color={color("error", "error")}>
              Do not include any crypto related keyword in the payment narration.
            </Text>
          </Box>
        </HStack>
      </FormControl> */}

      <FormControl>
        <Heading fontSize="sm">Upload Bank Receipt</Heading>
        <Text fontSize="14px" fontWeight="500" textAlign="left" mx="0" my="28px">
          Please confirm that you have make payment of {currencyFormat((currency as any) ?? "ngn").format(amount ?? 0)} to the
          buyer's bank account.
        </Text>

        <Uploader onFiles={(files) => set({ proof: files[0] })} />
      </FormControl>

      <VStack mt="50px">
        <Button isLoading={isUpdating || isUploading} disabled={isDisabled} onClick={handleSubmit}>
          Upload Payment Proof
        </Button>
        <Button disabled={isUploading || isUpdating} variant="transparent" textDecoration="underline" onClick={open("cancel")}>
          Skip
        </Button>
      </VStack>

      {/* <ConfirmationModal isOpen={isOpen("paid")} onClose={close("paid")} title="Confirm Payment" onConfirm={() => handleSubmit()}>
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            Please confirm that you have make payment of {currencyFormat((currency as any) ?? "ngn").format(amount ?? 0)} to seller
            bank account.
          </Text>

          <Uploader onFiles={(files) => set({ proof: files[0] })} />
        </VStack>
      </ConfirmationModal> */}

      <ConfirmationModal
        isOpen={isOpen("cancel")}
        onClose={close("cancel")}
        title="Confirm Cancel Trade"
        onConfirm={() => updateOrder("CANCEL")}
      >
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            By cancelling this order, the coins would be returned back to the seller, and the funds are not refundable.
          </Text>
        </VStack>
      </ConfirmationModal>
    </MainLayoutContainer>
  );
}

function PaymentDetails(props: FormProps) {
  // const { profile } = useSelector(selectUser);
  const { state, set, paymentOption, coin, currency, unitCost, tradeOption, currentVolume } = props;

  // const color = useColor();

  const { onNext } = useSteps();
  // const [isUploading, setIsUploading] = useState(false);
  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const { counterParties, volume, amount, setHideBackButton } = props.contract;
  const { ref } = useParams();
  const [id, frozenRef] = String(ref ?? "").split("-");

  const trader = useMemo(
    () => (counterParties ?? [])?.find((p) => p?.role === "trader"),
    [counterParties]
  ) as P2PPendingTradeCounterPartRo;

  // const { timer: countDown, isApproachingLimit } = useISOCountDown(
  //   trader?.timeline?.dueAt ?? "",
  //   `${trader?.timeline?.duration} : 00mins`,
  //   { timedOutText: "00:00" }
  // );

  //   const ckbScheme = useColorModeValue("primary", "secondary");

  //   const [makeSell, { isLoading: isSelling }] = useSellToBuyListingMutation();

  const { isUpdating, updateOrder } = useUpdateP2POrder(id, frozenRef);

  const options = useMemo(
    () =>
      (paymentOption ?? []).map((opt) => {
        const label = toLower(`${opt?.label ?? ""} ${opt?.receivingIntoLabel ?? ""} ${opt?.payingToLabel ?? ""}`);
        if (label.includes("bitmama") && opt?.source === "internal") return { key: "bitmamaWallet", label: "Bitmama Wallet" };
        if (label.includes("changera") && opt?.source === "external") return { key: "changera", label: "Changera ID" };
        if (label.includes("bank") && opt?.source === "external") return { key: "bankTransfer", label: "Bank Transfer" };
        return { key: "bankTransfer", label: "Bank Transfer" };
      }),
    [paymentOption]
  );

  const fee = useMemo(
    () =>
      (floatString(
        isNaN(deduction(parseFloat(state?.amount ?? ""))) ? "0.00" : deduction(parseFloat(state?.amount ?? ""))
      ) as string) ?? "0.00",
    [state?.amount]
  );

  //   const isMinimumGreaterThanUnfrozenBalance = useMemo(
  //     () => (tradeOption?.minimumFilling ?? 0) > clamp(unfrozenBalance ?? 0, 0, tradeOption?.maximumFilling ?? 0),
  //     [tradeOption, unfrozenBalance]
  //   );

  const isCoinAmountInvalid = useMemo(
    () =>
      (!!state?.amount && parseFloat(state.amount ?? "") < +(tradeOption?.minimumFilling ?? 0)) ||
      (!!state?.amount &&
        parseFloat(state.amount ?? "") > (+(tradeOption?.maximumFilling ?? 0) || +(currentVolume?.$numberDecimal ?? 0))),
    [state, tradeOption, currentVolume]
  );

  const isVolumeInvalid = useMemo(
    () =>
      (!!state?.volume && +(state?.volume ?? 0) < Number((tradeOption?.minimumFilling ?? 0) * (unitCost ?? 0))) ||
      (!!state?.volume &&
        +(state?.volume ?? 0) > Number((tradeOption?.maximumFilling ?? 0 ?? currentVolume?.$numberDecimal ?? 0) * (unitCost ?? 0))),
    [unitCost, currentVolume, tradeOption, state?.volume]
  );

  const isCoinAmountLessThanMinimumAmount = useMemo(
    () => !!state?.amount && parseFloat(state?.amount) - parseFloat(fee) < (tradeOption?.minimumFilling ?? 0),
    // eslint-disable-next-line
    [state?.amount, fee, tradeOption]
  );

  const isDisabled = useMemo(() => !state?.method /*|| isUploading || isUpdating*/, [state?.method]);

  const handleVolume = (e: string) => {
    const amount = toPrecision(floatString(Number(parseFloat(e) / (unitCost ?? 0))) as number, coin ?? "btc", false) as string;
    set({ amount, volume: e });
  };

  const handleAmount = (e: string) => {
    const volume = floatString(Number(parseFloat(e) * (unitCost ?? 0)).toFixed(2)) as string;
    set({ volume, amount: e });
  };

  const handleNext = () => {
    onNext();
    setHideBackButton(true);
  };

  // const handleSubmit = async () => {
  //   if (!state?.proof) return;
  //   setIsUploading(true);
  //   const res = await uploadFile(state?.proof, `${profile?.username}-waiver-${id}`);
  //   await updateOrder("CONFIRMPAYMENT", "Confirm Payment", res?.location);
  //   setIsUploading(false);
  // };

  return (
    <Card as="form" w="100%" p="44px 48px" borderRadius="16px">
      <Heading as="h6" fontSize="20px" fontWeight="600" mb="38px">
        Payment Details
      </Heading>

      <FormControl mb="34px">
        <InputLabel>Amount of {toCoinLocale(coin ?? "btc")}*</InputLabel>
        <AmountInput
          isInvalid={isCoinAmountLessThanMinimumAmount || isCoinAmountInvalid}
          isDisabled={true}
          defaultValue={volume}
          value={isNaN(+(state?.amount ?? "")) ? "" : state.amount}
          onChange={(e) => handleAmount(e.target.value)}
        />

        {isCoinAmountInvalid && (
          <FormHelperText fontSize="sm" mt="10px" fontWeight="500" color="error">
            Coin amount must not exceed the {tradeOption?.minimumFilling} {toCoinLocale(coin ?? "ngn")} min and{" "}
            {tradeOption?.maximumFilling ?? currentVolume?.$numberDecimal} {toCoinLocale(coin ?? "ngn")} max range.
          </FormHelperText>
        )}
      </FormControl>

      <FormControl mb="34px">
        <InputLabel>Amount of {toCoinLocale(currency ?? "ngn")}*</InputLabel>
        <AmountInput
          isInvalid={isVolumeInvalid}
          isDisabled={true}
          defaultValue={amount}
          value={isNaN(+(state?.volume ?? "")) ? "" : state?.volume}
          placeholder="0.00"
          onChange={(e) => handleVolume(e.target.value)}
        />
      </FormControl>

      <FormControl mb="34px">
        <InputLabel>Receive with</InputLabel>
        <Select placeholder="Select Payment Option" value={state?.method ?? ""} onChange={(e) => set({ method: e.target.value })}>
          {options?.map((opt) => (
            <option key={`${opt?.key}-payment-option`} value={opt?.key}>
              {opt?.label}
            </option>
          ))}
        </Select>
      </FormControl>

      {/* {state?.method === "bankTransfer" && <BankDetails frozenRef={frozenRef} {...trader} />} */}

      <ConditionalRender shouldRender={state?.method === "bankTransfer" && 2 + 2 === 100}>
        <BankDetails frozenRef={frozenRef} {...trader} />
      </ConditionalRender>

      {/* <FormControl mb="34px">
        <HStack my="20px" alignItems="flex-start">
          <Icon type="info" />

          <Box>
            <Text fontSize="14px" fontWeight="500" color={color("black", "white")}>
              Please ensure payment is made within{" "}
              <Span fontWeight="600" color={when(isApproachingLimit, "red", color("secondary.600", "secondary.400"))}>
                {countDown}
              </Span>
              , else transaction would be cancelled.
            </Text>
            <Text fontSize="14px" fontWeight="500" color={color("error", "error")}>
              Do not include any crypto related keyword in the payment narration.
            </Text>
          </Box>
        </HStack>
      </FormControl> */}

      {/* <VStack mt="100px">
        <Button isLoading={isUpdating || isUploading} disabled={isDisabled} onClick={open("paid")}>
          I have Paid
        </Button>
        <Button variant="transparent" textDecoration="underline" disabled={isUpdating || isUploading} onClick={open("cancel")}>
          Cancel Trade
        </Button>
      </VStack> */}

      <VStack mt="100px">
        <Button isLoading={isUpdating} disabled={isDisabled} onClick={handleNext}>
          Sell {toCoinLocale(coin ?? "btc")}
        </Button>
        <Button variant="transparent" textDecoration="underline" onClick={open("cancel")}>
          Cancel Trade
        </Button>
      </VStack>

      {/* <ConfirmationModal isOpen={isOpen("paid")} onClose={close("paid")} title="Confirm Payment" onConfirm={() => handleSubmit()}>
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            Please confirm that you have made payment of {currencyFormat((currency as any) ?? "ngn").format(amount ?? 0)} to
            seller's bank account.
          </Text>

          <Uploader onFiles={(files) => set({ proof: files[0] })} />
        </VStack>
      </ConfirmationModal> */}

      <ConfirmationModal
        isOpen={isOpen("cancel")}
        onClose={close("cancel")}
        title="Confirm Cancel Trade"
        onConfirm={() => updateOrder("CANCEL")}
      >
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            By cancelling this order, the coins would be returned back to the seller, and the funds are not refundable.
          </Text>
        </VStack>
      </ConfirmationModal>
    </Card>
  );
}

function AdDetails(props: DetailsProps) {
  const {
    unitCost,
    userData,
    presence,
    currency,
    coin,
    tradeOption,
    currentVolume,
    paymentOption,
    country,
    terms,
    timeout,
    unfrozenBalance,
  } = props;

  const location = useLocation();

  const color = useColor();

  const price = useMemo(
    () =>
      `${currencyFormat((currency as any) ?? "ngn").format(unitCost ?? 0)} ${toCoinLocale(currency ?? "ngn")}/${toCoinLocale(
        coin ?? "btc"
      )}`,
    [unitCost, coin, currency]
  );

  const limits = useMemo(
    () =>
      `${toPrecision(+(tradeOption?.minimumFilling ?? 0), coin ?? "btc")} - ${toPrecision(
        +(currentVolume?.$numberDecimal ?? 0),
        coin ?? "btc"
      )}`,
    [tradeOption, currentVolume, coin]
  );

  const methods = useMemo(() => {
    return (paymentOption ?? [])?.map((option) => {
      const map: Record<string, string> = {
        external: "Bank Transfer",
        internal: "Bitmama Wallet",
      };

      return map[option?.source];
    });
  }, [paymentOption]);

  const isMinimumGreaterThanUnfrozenBalance = useMemo(
    () => (tradeOption?.minimumFilling ?? 0) > clamp(unfrozenBalance ?? 0, 0, tradeOption?.maximumFilling ?? 0),
    [tradeOption, unfrozenBalance]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(isMinimumGreaterThanUnfrozenBalance);
  };

  const shareableLink = useMemo(() => {
    const { protocol, host, pathname } = location;
    return `${protocol}//${host}${pathname}`;
  }, [location]);

  return (
    <Card as="form" w="100%" p="44px 48px" borderRadius="16px" onSubmit={handleSubmit}>
      <HStack mb="38px">
        <Heading as="h6" fontSize="20px" fontWeight="600">
          Ad Details
        </Heading>

        {isMinimumGreaterThanUnfrozenBalance && (
          <Badge colorScheme="red" p="4px 10px" fontSize="10px" borderRadius="12px">
            Trade is busy
          </Badge>
        )}
      </HStack>

      <FormInfo
        mb="40px"
        fontSize="md"
        info="Seller:"
        description={
          <Username username={`@${userData?.username}`} presence={(presence?.status as any) ?? "online"} dir="horizontal" />
        }
      />
      <FormInfo mb="40px" fontSize="md" info="Price:" description={price} _description={{ color: color("black", "white") }} />
      <FormInfo mb="40px" fontSize="md" info="Limits:" description={limits} _description={{ color: color("black", "white") }} />
      <FormInfo
        mb="40px"
        fontSize="md"
        info="Payment methods:"
        description={
          <HStack>
            {methods.map((method, i) => (
              <PaymentMethod key={`payment-method-${i}`}>{method}</PaymentMethod>
            ))}
          </HStack>
        }
        _description={{ color: color("black", "white") }}
      />
      <FormInfo
        mb="40px"
        fontSize="md"
        info="Location:"
        description={country}
        _description={{ color: color("black", "white"), textTransform: "capitalize" }}
      />
      <FormInfo
        mb={when(!!terms, "40px", "80px")}
        fontSize="md"
        info="Payment window:"
        description={`${timeout} Minutes`}
        _description={{ color: color("black", "white") }}
      />
      {!!terms && (
        <FormInfo mb="80px" fontSize="md" info="Terms:" description={terms} _description={{ color: color("black", "white") }} />
      )}

      <Divider />

      <ShareActions mt="48px" title="Share this Ad" copyText="Copy Ad Link" link={shareableLink} />
    </Card>
  );
}

function InfoHub(props: InfoHubProps) {
  const { coin, userData } = props;
  const { label } = useCoin(coin as any);

  console.log("Coin Info", coin, label);
  return (
    <Box p="46px 48px" w="100%" borderRadius="16px" minH="138px" bg="#041815">
      <VStack>
        <HStack>
          <Heading as="h6" fontSize="24px" color="white">
            Sell {label} to
          </Heading>

          <Username username={`@${userData?.username ?? ""}`} dir="horizontal" hidePresence _link={{ color: "white" }} />
        </HStack>
      </VStack>
    </Box>
  );
}

interface BankDetailsProps extends P2PPendingTradeCounterPartRo {
  frozenRef: string;
}
interface BankDetailsItemProps {
  title: string;
  value: string;
  canCopy?: boolean;
}

// function BankDetails(props: BankDetailsProps) {
//   const { payTo, frozenRef } = props;

//   const bankInfo = (payTo ?? []).find((opt) => opt?.source === "external");
//   return (
//     <Box w="fit-content" p="20px" borderRadius="10px" bg="#E2F2DA6f">
//       <Stack>
//         <BankDetailsItem title="Account Name" value={bankInfo?.accountName ?? ""} />
//         <BankDetailsItem title="Account Number" value={bankInfo?.receivingIntoAddress ?? ""} canCopy />
//         <BankDetailsItem title="Bank" value={bankInfo?.receivingIntoLabel ?? ""} />
//         <BankDetailsItem title="Reference" value={frozenRef ?? ""} canCopy />
//       </Stack>
//     </Box>
//   );
// }

function BankDetails(props: BankDetailsProps) {
  const { payTo, frozenRef } = props;

  const bankInfo = (payTo ?? []).find((opt) => opt?.source === "external");
  return (
    <Box w="fit-content" p="20px" borderRadius="10px" bg="#E2F2DA6f">
      <Stack>
        <BankDetailsItem title="Account Name" value={bankInfo?.accountName ?? ""} />
        <BankDetailsItem title="Account Number" value={bankInfo?.receivingIntoAddress ?? ""} canCopy />
        <BankDetailsItem title="Bank" value={bankInfo?.receivingIntoLabel ?? ""} />
        <BankDetailsItem title="Reference" value={frozenRef ?? ""} canCopy />
      </Stack>
    </Box>
  );
}

function BankDetailsItem(props: BankDetailsItemProps) {
  const { title, value, canCopy } = props;

  const color = useColor();
  const { onCopy } = useCopy(value);

  return (
    <HStack>
      <Text fontWeight="500" color={color("grey.400", "grey.150")}>
        {title}:
      </Text>

      <HStack>
        <Text fontWeight="600" color={color("black", "white")}>
          {value}
        </Text>
        {canCopy && <CopyButton onClick={onCopy} />}
      </HStack>
    </HStack>
  );
}
