import { ReactNode, Children, cloneElement, isValidElement } from "react";

export default function filterChildren<C, P extends {} = {}>(children: ReactNode, names: C[], props?: P) {
  return Children.map(children, (child) => {
    if (!isValidElement(child)) return;
    if (!names.includes((child.type as any).displayName ?? (child.type as any).name)) {
      return props ? cloneElement(child, props) : child;
    }
  });
}

export function filterChildrenWithKey(children: ReactNode, key: string) {
  return Children.map(children, (child) => {
    if (!isValidElement(child)) return;
    if (child.key === `.$${key}` || child.key === `.$.$${key}` || child.key === key) return child;
    return;
  });
}
