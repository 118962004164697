import { Box, BoxProps, Button, ButtonProps, HStack, Text } from "@chakra-ui/react";
import Icon, { IconNames, IconProps } from "components/Icon/Icon";
import { useMemo } from "react";
import { when } from "utils";

interface AddButtonProps extends ButtonProps {
  icon?: IconNames;
  _layout?: BoxProps;
  _icon?: IconProps;
  iconPosition?: "left" | "right";
}

export default function AddButton(props: AddButtonProps) {
  const { children, iconPosition = "left", icon, _layout, _icon, ...xprops } = props;

  const defaultIcon = when(!!icon, icon as IconNames, "plus");

  const iconDir = useMemo(() => {
    const map = {
      left: { dir: "row", tMr: "0 !important", tMl: "10px !important" },
      right: { dir: "row-reverse", tMl: "0 !important", tMr: "10px !important" },
    };

    return map[iconPosition];
  }, [iconPosition]);

  return (
    <Button variant="add" minW="unset" w="fit-content" justifyContent="flex-start" {...xprops}>
      <HStack flexDir={iconDir.dir as any} {..._layout}>
        <Box
          boxSize={{ base: "17px", "4sm": "29px" }}
          bg="primary.default"
          color="white"
          borderRadius="6px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignSelf="flex-end"
        >
          <Icon color="inherit" type={defaultIcon} {..._icon} />
        </Box>

        <Text fontSize="14px" px="6px" ml={iconDir.tMl} mr={iconDir.tMr}>
          {children}
        </Text>
      </HStack>
    </Button>
  );
}
