import { CircularProgress, CircularProgressProps, useColorModeValue } from "@chakra-ui/react";

export default function CircleLoader(props: CircularProgressProps) {
  const stroke = useColorModeValue("transparent", "transparent");
  const progressColor = useColorModeValue("primary.600", "secondary.300");

  return (
    <CircularProgress
      isIndeterminate
      color={progressColor}
      size="16px"
      sx={{
        ".chakra-progress__track": {
          stroke,
        },
      }}
      {...props}
    />
  );
}
