import { useMemo } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { SupportedCoinType, useCoin, useColor } from "hooks";

interface CoinIconProps extends BoxProps {
  coin: SupportedCoinType;
}

export default function CoinIcon(props: CoinIconProps) {
  const tcolor = useColor();
  const { coin, boxSize = "32px", color = tcolor("black", "white"), ...xprops } = props;

  const { icon } = useCoin(coin);
  const radius = useMemo(() => (["btc", "celo", "usdt", "ceur", "cusd"].includes(coin) ? "0%" : "50%"), [coin]);
  const scale = useMemo(() => (["ghs", "kes"].includes(coin) ? "1.72" : "1"), [coin]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      boxSize={boxSize}
      overflow="hidden"
      {...xprops}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        boxSize={`calc(${boxSize} - 2px)`}
        overflow="hidden"
      >
        <Box
          borderRadius={radius}
          boxSize={`calc(${boxSize} - 4px)`}
          as={icon}
          transform={`scale(${scale})`}
          overflow="hidden"
          color={color}
        />
      </Box>
    </Box>
  );
}
