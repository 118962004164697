import { Box, BoxProps, Button, HStack, Image, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { Span } from "components";
import { useMemo } from "react";

import Bitcoin from "assets/images/404-bitcoin.png";
import Sphere from "assets/images/404-sphere.png";
import DefaultPrimaryCube from "assets/images/404-dp-cube.png";
import PrimaryLightCube from "assets/images/404-pl-cube.png";
import YellowCube from "assets/images/404-ylw-cube.png";
import Cylinder from "assets/images/404-cylinder.png";
import { navigate } from "@reach/router";

import { ReactComponent as Trail } from "assets/svg/404-trail.svg";
import { ReactComponent as TrailHead } from "assets/svg/404-trail-head.svg";
// will use this trick to get the resource location for the trail vector
// since the usual import doesn't give me the full file path for the vector
// const trailSource = require("assets/svg/404-trail.svg").default as string;

type ObjectTypes = "Sphere" | "DefaultPrimaryCube" | "PrimaryLightCube" | "YellowCube" | "Cylinder";
interface PlaceObjectProps extends BoxProps {
  name: ObjectTypes;
}

export default function NotFound(props: any) {
  const bg = useColorModeValue("white", "dark.bg");
  const trailHeadColor = useColorModeValue("primary.default", "secondary.400");

  return (
    <VStack bg={bg} minW="100vw" minH="100vh" justifyContent="center" pos="relative">
      <HStack gridGap="20px" pos="relative" top="-50px">
        <Span
          transform="rotate(-27deg)"
          fontSize="277px"
          fontFamily="var(--bitmama-fonts-heading)"
          fontWeight="700"
          position="relative"
          lineHeight={0.9}
          pointerEvents="none"
        >
          4
        </Span>
        <Image maxH="300px" src={Bitcoin} alt="404 bitcoin image" />
        <Span
          transform="rotate(27deg)"
          fontSize="277px"
          fontFamily="var(--bitmama-fonts-heading)"
          fontWeight="700"
          position="relative"
          lineHeight={0.9}
          pointerEvents="none"
        >
          4
        </Span>
      </HStack>

      <VStack>
        <Text fontSize="48px" lineHeight="60px" letterSpacing="2px" fontWeight="300">
          Oops! This page cannot be found :(
        </Text>
        <Text mt="42px !important" fontSize="24px" fontWeight="500">
          .....You probably missed your way or clicked on a wrong link
        </Text>
      </VStack>

      <VStack pos="absolute" bottom="123px">
        <Button minW="268px" minH="55px" maxW="268px" maxH="55px" onClick={() => navigate("/home")}>
          Let’s take you back Home
        </Button>

        <Box pos="absolute" boxSize="var(--trailHeadSize)" top="-24px" right="-50px" sx={{ "--trailHeadSize": "40px" }}>
          <Box as={TrailHead} pos="absolute" boxSize="var(--trailHeadSize)" color={trailHeadColor} borderRadius="50%" zIndex="2" />
          <Box
            as={Trail}
            pos="absolute"
            top="calc((var(--trailHeadSize) / 2) - 8px)"
            left="calc((var(--trailHeadSize) - 8px))"
            zIndex="1"
          />
        </Box>
      </VStack>

      {/* Objects placed by the right side of the 404 ui */}
      <PlaceObject name="Sphere" top="calc(100vh / 4)" right="140px" />
      <PlaceObject name="PrimaryLightCube" top="calc(100vh / 1.3)" right="152px" boxSize="68px" />

      {/* Objects placed by the left side of the 404 ui */}
      <PlaceObject name="Cylinder" top="calc(100vh / 1.8)" left="calc(100vw / 14)" />
      <PlaceObject name="YellowCube" top="calc(100vh / 7)" left="calc(100vw / 5.5)" boxSize="68px" />
      <PlaceObject name="DefaultPrimaryCube" top="calc(100vh / 1.1)" left="calc(100vw / 4)" boxSize="68px" />
    </VStack>
  );
}

function PlaceObject(props: PlaceObjectProps) {
  const { name, ...xprops } = props;

  const objectSource = useMemo(() => {
    const map: Record<ObjectTypes, string> = {
      Sphere,
      Cylinder,
      DefaultPrimaryCube,
      PrimaryLightCube,
      YellowCube,
    };

    return map[name];
  }, [name]);

  return (
    <Box
      as="img"
      pos="absolute"
      boxSize="54px"
      objectFit="contain"
      src={objectSource}
      alt={`${name} object`}
      pointerEvents="none"
      {...xprops}
    />
  );
}
