import { Box, Button, Heading, HStack, useColorModeValue } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { AddButton, Icon, MainLayoutContainer, PageMotion } from "components";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { BanksPanel } from "ui";

interface BankListProps extends PageProps {}

export default function BankList(props: BankListProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountBankList" overflowY="scroll">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              Banks
            </Heading>

            <AddButton onClick={() => navigate("banks/add")}>Add bank</AddButton>
          </HStack>
        </Box>

        {/* <Steps hideIndicator> */}
        {/* <ProfileTabs {...props} /> */}
        <BanksPanel {...props} />
        {/* <NameChange {...props} /> */}
        {/* </Steps> */}
      </MainLayoutContainer>
    </PageMotion>
  );
}

// function ProfileTabs(props: any) {
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location?.search as string);

//   const tabMap = {
//     banks: 0,
//     cards: 1,
//   };

//   const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

//   const handleTabChange = (index: number) => {
//     // block cards tab temporarily
//     if (index === 1) index = 0;

//     changeTab(index);
//     searchParams.set("tab", Object.keys(tabMap)[index]);
//     navigate(`?${searchParams.toString()}`);
//   };

//   return (
//     <>
//       <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange} mt="42px !important">
//         <TabList borderRadius="8px">
//           <Tab>Banks</Tab>
//           <Tab onClick={() => navigate("/cards")}>Cards</Tab>
//         </TabList>
//         <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
//           <TabPanel px={["2", "0", "0", "1"]}>
//             <BanksPanel {...props} />
//           </TabPanel>
//           <TabPanel px={["0", "0", "0", "1"]}></TabPanel>
//         </TabPanels>
//       </Tabs>
//     </>
//   );
// }
