import { useCallback, useMemo } from "react";
import configs from "config";

export default function useTicker(coin: string, fiat: string) {
  const coinMap = useCallback((token: string) => {
    const map: Record<string, string> = {
      [token]: token,
      tbtc: "btc",
      teth: "eth",
      ceur: "eur",
    };

    return map[token ?? "tbtc"];
  }, []);

  const ticker = useMemo(() => {
    const _coin = configs.stableCoins.includes(coin) ? "usd" : coin;

    return `${coinMap(_coin)}${fiat}`;
  }, [coin, fiat, coinMap]);

  const getTicker = useCallback(
    (coin: string, fiat: string) => {
      const _coin = configs.stableCoins.includes(coin) ? "usd" : coin;

      return `${coinMap(_coin)}${fiat}`;
    },
    [coinMap]
  );

  return { ticker, getTicker };
}
