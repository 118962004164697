import { QuickActionType } from "components/QuickAction/QuickAction";
import { useCurrency } from "hooks/useCurrency";
import { useMemo } from "react";

export function useAvailableCountryFeatures() {
  const currency = useCurrency();

  const quickactions_not_available = useMemo(() => {
    // map of quick actions to filter out for each supported countries via their currency
    const filter_map: Record<string, QuickActionType[]> = {
      [currency]: ["buy", "sell"],
      kes: ["buy"],
      ghs: [],
      cdf: ["sell"],
      xaf: ["sell"],
      xof: ["sell"],
      ngn: [],
    };

    return filter_map[currency];
  }, [currency]);

  const account_links_not_available = useMemo(() => {
    // map of quick actions to filter out for each supported countries via their currency
    const filter_map: Record<string, string[]> = {
      [currency]: ["banks", "momo"],
      kes: ["banks"],
      ghs: ["banks"],
      cdf: ["banks"],
      xaf: ["banks"],
      xof: ["banks"],
      ngn: ["momo"],
    };

    return filter_map[currency];
  }, [currency]);

  return { quickactions_not_available, account_links_not_available };
}
