import { Button, HStack, Stack, Text, Box, ButtonProps, TextProps, BoxProps, useColorModeValue, Badge } from "@chakra-ui/react";
import Icon from "components/Icon/Icon";
import { useCoin, useDefaultStyle } from "hooks";
import { useMemo } from "react";

type StatusType = "info" | "terminated" | "active" | "redeemed" | "rolledover";

interface StakeInfoProps extends ButtonProps {
  coin: string;
  title?: string;
  subtitle?: string;
  _title?: TextProps;
  _subtitle?: TextProps;
  _child?: BoxProps;
  status?: StatusType;
}

export default function StakeInfo(props: StakeInfoProps) {
  const { coin, status, title, subtitle, onClick, _title, _subtitle, _child, children, ...xprops } = props;

  const { icon } = useCoin(coin as any);

  const { shadow, borderColor } = useDefaultStyle();
  const bg = useColorModeValue("white", "dark.cardBg");

  const badgeStyle = useMemo(() => {
    const map = {
      info: { bg: "#F7EBB8", color: "black" },
      terminated: { bg: "rgba(219, 102, 110, 0.15)", color: "#DB666E" },
      active: { bg: "rgba(249, 216, 36, 0.15)", color: "#edca33" },
      redeemed: { bg: "rgba(111, 190, 69, 0.15)", color: "secondary.400" },
      rolledover: { bg: "rgba(249, 216, 36, 0.15)", color: "#edca33" },
    };

    return map[status ?? "info"];
  }, [status]);

  return (
    <Button
      display="flex"
      bg={bg}
      flexDir="row"
      variant="unstyled"
      minW="100%"
      maxH="fit-content"
      maxW="fit-content"
      borderRadius="10px"
      border="1px solid transparent"
      //   borderColor="#EFF0F6"
      rightIcon={<Icon type="arrowRight" boxSize="32px" />}
      justifyContent="space-between"
      p="20px"
      h="fit-content"
      pos="relative"
      _focus={{
        borderColor,
        shadow,
      }}
      onClick={onClick}
      {...xprops}
    >
      <HStack>
        <Box as={icon} boxSize="32px" />

        <Stack alignItems="flex-start" ml="10px !important">
          <Text fontSize="14px" fontWeight="600" textTransform="none" {..._title}>
            {title}
          </Text>
          {subtitle && (
            <Text mt="0 !important" fontSize="12px" fontWeight="600" {..._subtitle}>
              {subtitle}
            </Text>
          )}
        </Stack>
      </HStack>

      {children && (
        <Box pos="absolute" right="68px" top="50%" transform="translateY(-50%)">
          <Badge textTransform="capitalize" borderRadius="20px" p="2px 14px" fontSize="12px" fontWeight="600" {...badgeStyle}>
            {children}
          </Badge>
        </Box>
      )}
    </Button>
  );
}
