import { Box, Button, FormControl, HStack, Text, VStack } from "@chakra-ui/react";
import { useYields } from "Pages/Earn/Yield/Yield";
import { useGetEarnWalletQuery, useGetEarnYieldSupportedCoinsQuery, useSubscribeToYieldMutation } from "apis";
import { AmountInput, FormInfo, Group, Icon, InputLabel, Option, RadixSelect, Title, TitleBar } from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { useDisclosures, usePartialState } from "hooks";
import { toLower } from "lodash";
import { useEffect, useMemo } from "react";
import { SubmitPinModal, SuccessModal } from "ui";
import { currencyFormat, toCoinLocale, toPrecision } from "utils";

interface YieldFundProps extends SidePageViewProps {}

interface IState {
  coin: string;
  amount: string;
  pin: string;
}

type ModalTypes = "success" | "pin";

const usd_format = currencyFormat("usd").format;

export default function YieldFundFeature(props: YieldFundProps) {
  const { params, updateSidePageConfigs, onClose: closeSidePage } = useSidePage();

  const [state, set, reset] = usePartialState<IState>({ coin: "usdt" });

  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const product_id = useMemo(() => params?.id!, [params]);

  const { data: wallets } = useGetEarnWalletQuery({});
  const { data: supported_coins, isLoading: is_loading_supported_coins } = useGetEarnYieldSupportedCoinsQuery();

  const [_subscribe, { isLoading: is_subscribing }] = useSubscribeToYieldMutation();

  console.log("Earn Wallets", { wallets, supported_coins });

  const wallet_balance = useMemo(() => {
    const wallet = wallets?.find((w) => toLower(w?.unit) === toLower(state?.coin));
    return wallet?.balance?.$numberDecimal ?? "0";
  }, [state?.coin, wallets]);

  const { yields } = useYields();
  const product = useMemo(() => yields.find((y) => y?.productId === product_id), [yields, product_id]);

  const insufficient_balance = useMemo(() => +(state?.amount ?? 0) > +(wallet_balance ?? 0), [state?.amount, wallet_balance]);

  const does_not_match_min_max = useMemo(() => {
    const amt = +(state?.amount ?? 0);

    if (!state?.amount) return false;
    if (+(product?.minAmount ?? 0) > amt)
      return { bool: true, msg: `Amount should be greater than ${usd_format(product?.minAmount ?? 0)}` };
    if (+(product?.maxAmount ?? 0) < amt)
      return { bool: true, msg: `Amount should be less than ${usd_format(product?.maxAmount ?? 0)}` };
    return false;
  }, [product, state]);

  const yield_per_month = useMemo(() => {
    const amt = +(state?.amount ?? 0);
    const y = amt * (product?.gain ?? 0);
    return y;
  }, [state?.amount, product]);

  const total_yield = useMemo(() => {
    return yield_per_month * (product?.period ?? 1) + +(state?.amount ?? 0);
  }, [yield_per_month, product?.period, state?.amount]);

  //   const title = useMemo(() => {
  //     const map: Record<string, string> = {
  //       monthly: "Fund 3 Months Yield",
  //       "6-monthly": "Fund 6 Months Yield",
  //       annum: "Fund 12 Months Yield",
  //     };

  //     return map[product?.durationValue ?? "monthly"];
  //   }, [product?.durationValue]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    open("pin")();
  };

  const subscribeToYield = async (pin?: string) => {
    if (!state?.coin && !state?.amount && !product_id) return;

    close("pin")();

    const result = await _subscribe({
      coin: state?.coin!,
      amount: +state?.amount!,
      pin: pin ?? state?.pin!,
      productId: product_id,
    }).unwrap();

    console.log("Yield subscriptions result", result);
    open("success")();
    reset();
  };

  const is_disabled = useMemo(
    () => !(state?.coin && state?.amount && product_id) || is_subscribing || !!does_not_match_min_max || !!insufficient_balance,
    [state, is_subscribing, product_id, does_not_match_min_max, insufficient_balance]
  );

  useEffect(() => {
    updateSidePageConfigs({
      _back: {
        text: "Close",
        onClick: closeSidePage,
      },
    });

    //eslint-disable-next-line
  }, []);

  return (
    <Group px={{ base: "20px", "1sm": "40px" }} mt="2px">
      <TitleBar>
        <Title fontSize="22px">{product?.label}</Title>
      </TitleBar>

      <Group as="form" mt="0" onSubmit={handleSubmit}>
        <NoteCard title="Note">
          <NoteCardItem title="Min" description={usd_format(product?.minAmount ?? 0)} />
          <NoteCardItem title="Max" description={usd_format(product?.maxAmount ?? 0)} />
        </NoteCard>

        <FormControl my="32px !important">
          <InputLabel isLoading={is_loading_supported_coins} mb="10px" _text={{ fontSize: "14px", fontWeight: "500" }}>
            Wallet
          </InputLabel>

          <RadixSelect
            use_coin_icon
            value={state?.coin}
            aria-label="Wallet"
            placeholder="Select Wallet"
            onChange={(value) => set({ coin: value })}
            _trigger={{
              w: "100%",
            }}
          >
            {(supported_coins ?? [])?.map((sc) => (
              <Option key={sc.coin} value={sc.coin} coin={sc.coin as any}>
                {toCoinLocale(sc.coin)}
              </Option>
            ))}
          </RadixSelect>

          {!!wallet_balance && !!state?.coin && (
            <FormInfo mt="12px" info="Available Balance: " description={toPrecision(+wallet_balance, state?.coin ?? "usdt")} />
          )}
        </FormControl>

        <FormControl mb="32px !important">
          <InputLabel mb="10px" _text={{ fontSize: "14px" }}>
            Amount
          </InputLabel>

          <AmountInput
            // isInvalid={isInsufficientBalance}
            placeholder="0.00"
            value={state?.amount ?? ""}
            // onMax={() => set({ amount: String(Number(balance)) })}
            onChange={(e) => set({ amount: e.target.value })}
          />

          {insufficient_balance && (
            <FormInfo
              info=""
              mt="12px"
              isInvalid={insufficient_balance}
              description="Insufficient balance"
              _description={{ ml: "0 !important" }}
            />
          )}

          {!!does_not_match_min_max && (
            <FormInfo
              info=""
              mt="12px"
              isInvalid={does_not_match_min_max?.bool}
              description={does_not_match_min_max?.msg}
              _description={{ ml: "0 !important" }}
            />
          )}

          {/* {!!state?.coin && (
            <FormInfo
              mt="12px"
              isInvalid={insufficient_balance}
              info="Available: "
              description={toPrecision(balance, state?.coin)}
            />
          )} */}
        </FormControl>

        <NoteCard title="Calculator">
          <NoteCardItem title="Capital" description={usd_format(+(state?.amount ?? 0))} />
          <NoteCardItem title={`Yield per ${product?.durationValue ?? "month"}`} description={usd_format(yield_per_month)} />
          <NoteCardItem title="Total yield" description={usd_format(total_yield)} color="black" />
        </NoteCard>

        <VStack mt="22px !important">
          <HStack>
            <Icon type="accountReward" />
            <Text fontFamily="var(--bitmama-fonts-heading)">You will earn 10 points for this transaction</Text>
          </HStack>
        </VStack>

        <VStack mt="82px !important">
          <Button type="submit" isDisabled={is_disabled} disabled={is_disabled} isLoading={is_subscribing}>
            Submit
          </Button>
        </VStack>
      </Group>

      <SubmitPinModal
        isOpen={isOpen("pin")}
        onClose={close("pin")}
        onPin={(pin) => set({ pin })}
        onSubmit={subscribeToYield}
        onComplete={subscribeToYield}
        // onComplete={(pin) => {
        //   close("pin")();
        //   open("success")();
        // }}
      />

      <SuccessModal
        isOpen={isOpen("success")}
        onClose={() => {
          close("success")();
          reset();
        }}
        message={`Your yield subscription was created successfully`}
        onContinue={closeSidePage}
      />
    </Group>
  );
}

function NoteCard(props: any) {
  const { title, children, ...xprops } = props;
  return (
    <Box borderRadius="10px" bg="#f0f7ff" p="12px 16px" {...xprops}>
      <Text fontWeight="600" fontSize="15px" mb="4px">
        {title}:
      </Text>

      {children}
    </Box>
  );
}

function NoteCardItem(props: any) {
  const { title, description, ...xprops } = props;

  return (
    <HStack fontWeight="600" fontSize="14px" color="#4B5563" {...xprops}>
      <HStack>
        <Text>{title}</Text>
        <Text ml="2px !important">:</Text>
      </HStack>
      <Text ml="4px !important">{description}</Text>
    </HStack>
  );
}

YieldFundFeature.displayName = "SidePageView";
