import { useClipboard, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const useCopy = (initialValue: string) => {
  console.log("useCopy", initialValue);
  const toast = useToast();

  const [value, setValue] = useState(initialValue);
  const { hasCopied, onCopy } = useClipboard(initialValue ?? value);

  useEffect(() => {
    if (hasCopied)
      toast({
        title: `Copied!! 🥳🎉🤝`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
  }, [hasCopied, toast, initialValue]);

  return { onCopy, value, setValue };
};

export default useCopy;
