import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  // BoxProps,
  // Button,
  HStack,
  SelectProps,
  // List,
  // ListItem,
  Select as S,
  // SelectProps as SP,
  Text,
  // useStyleConfig,
} from "@chakra-ui/react";
// import Card from "components/Card/Card";
import CoinIcon from "components/Icon/CoinIcon";
import { motion, Variants } from "framer-motion";
import { useDefaultStyle } from "hooks";
// import { capitalize } from "lodash";
import { useState } from "react";
import useMeasure, { RectReadOnly } from "react-use-measure";
import { when } from "utils";
import Dropdown from "./Dropdown";
import { DropdownIcon } from "./DropdownIcon";

interface AbstractSelectProps {
  bounds?: RectReadOnly;
}

// const context = createContext<>()

const arrow: Variants = {
  opened: {
    rotate: "180deg",
  },
  closed: {
    rotate: "0deg",
  },
};

export default function AbstractSelect(props: AbstractSelectProps) {
  const { bounds } = props;
  const { shadow, borderColor } = useDefaultStyle();
  // const styles = useStyleConfig("Select");
  // const boxRef = useRef<HTMLDivElement>();
  const MoArrow = motion(ChevronDownIcon);

  // console.log("Box ref", boxRef, boxRef.current?.getBoundingClientRect());
  // const defaultBounds = useMemo(() => boxRef.current?.getBoundingClientRect(), [boxRef.current]);

  const [ref, defaultBounds] = useMeasure();

  const [isOpen, setIsOpen] = useState(false);

  console.log("Select ", isOpen);

  return (
    <Box w="100%" position="relative">
      <Box
        w="100%"
        ref={ref}
        borderRadius="14px"
        overflow="hidden"
        minH="60px"
        border="1px solid transparent"
        transition="all .3s ease-in-out"
        _focusWithin={{ shadow, borderColor, transition: "all .3s ease-in-out" }}
      >
        <Box
          pos="absolute"
          w="100%"
          h="100%"
          px="24px"
          minH="60px"
          as="select"
          opacity="0"
          // zIndex={100}
          // hidden
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
        />
        <HStack px="24px" bg="#CACBCA" w="100%" h="100%" minH="60px" zIndex={-1} justifyContent="space-between">
          <HStack>
            <CoinIcon boxSize="26px" coin="btc" />

            <Text>Btc</Text>
          </HStack>

          <MoArrow initial="closed" animate={when(isOpen, "opened", "closed")} exit="closed" variants={arrow} />
        </HStack>
      </Box>

      {/* <Portal>{isOpen && <Dropdown isOpen={isOpen} bounds={bounds ?? defaultBounds}></Dropdown>}</Portal> */}
      {isOpen && <Dropdown isOpen={isOpen} bounds={bounds ?? defaultBounds}></Dropdown>}
    </Box>
  );
}

export function Select(props: SelectProps) {
  return (
    <S icon={<DropdownIcon />} {...props}>
      {props.children}
    </S>
  );
}
