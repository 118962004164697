import { parseISO } from "date-fns";
import { useMemo, useState } from "react";
import { useEffect } from "react";

const _THRESHOLD = 2 * 60 * 1000;

type Opts = {
  threshold?: number;
  timedOutText?: string;
};

export function useISOCountDown(isoDate: string, initial = "", opts?: Opts) {
  const [timer, setTimer] = useState(initial);
  const [expired, setExpired] = useState(false);
  const [isApproachingLimit, setIsApproachingLimit] = useState(false);

  const THRESHOLD = useMemo(() => opts?.threshold ?? _THRESHOLD, [opts?.threshold]);

  const time = parseISO(isoDate).getTime();
  console.log("[ISO]", parseISO(isoDate).getTime());

  //   console.log("time", time);

  useEffect(() => {
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      const distance = time - now;

      // console.log("[distance]", distance);
      // Time calculations for days, hours, minutes and seconds

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // Display the result in the element with id="demo"
      setTimer(`${String(minutes).length < 2 ? "0" : ""}${minutes} : ${String(seconds).length < 2 ? "0" : ""}${seconds}mins`);

      setIsApproachingLimit(distance <= THRESHOLD);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setExpired(true);
        setTimer(opts?.timedOutText ?? "TIMED OUT");
      }
    }, 1000);

    return () => clearInterval(x);
  }, [time, THRESHOLD, opts?.timedOutText]);

  return { timer, expired, isApproachingLimit };
}
