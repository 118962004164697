import { MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { PageProps } from "interfaces";
import { AllGreenBoxTransactions, FundGreenBox, GreenBoxDetails, PreviewTransactions, SendAGreenBox, WithdrawGreenBox } from "ui";
import GreenBoxPage from "./GreenBox";

export default function GreenBoxIndex(props: PageProps) {
  return (
    <PageMotion key="GreenBox">
      <Topbar pageTitle="" {...props} />

      <MainLayoutContainer pb="60px">
        <SidePageProvider>
          <GreenBoxPage />

          <FundGreenBox key="FundGreenBox" hasBackButton />
          <SendAGreenBox key="SendAGreenBox" hasBackButton />
          <GreenBoxDetails key="GreenBoxDetails" hasBackButton />
          <WithdrawGreenBox key="WithdrawGreenBox" hasBackButton />
          <AllGreenBoxTransactions key="AllGreenBoxTransactions" hasBackButton />
          <PreviewTransactions key="PreviewTransactions" hasBackButton />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}
