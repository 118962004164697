import { ColorHues } from "@chakra-ui/react";

export type UiColorType = string | Partial<ColorHues & { default: string }>;

export type SupportedColorModesType = "dark" | "light";

export type UiColorModesType = {
  [key in SupportedColorModesType]: UiColorType;
};

export interface IUiColor {
  [key: string]: string | UiColorType | UiColorModesType;
}

// TODO: get primary, secondary and accent colors hues and map them.
export default {
  transparent: "transparent",
  black: "#303131",
  white: "#FBFBFB",
  error: "#ED1C00",
  errorAlpha: "rgba(255, 0, 0, .3)",
  border: "#dcdcdc82",

  dark: {
    bg: "#121212", // background
    cardBg: "#1E1E1E8f", // card background
    border: "#2727278f",
  },
  // grey: {
  //   50: "#E5E5E5",
  //   100: "#f7fafc",
  //   900: "#1a202c",
  // },
  grey: {
    default: "#797A7A",
    50: "#f9f9f9",
    "100": "#F2F2F2",
    "150": "#DBDBDB",
    "200": "#C4C4C4",
    "250": "#eaeaea",
    "300": "#ADADAD",
    "400": "#969696",
    "500": "#808080",
    "600": "#666666",
    "700": "#4D4D4D",
    "800": "#333333",
    "900": "#1A1A1A",
  },
  primary: {
    default: "#0D4740",
    50: "#e2fdf3",
    100: "#bef3e1",
    200: "#97ead0",
    300: "#71e2c2",
    400: "#4edab5",
    500: "#3ac1a1",
    600: "#2b9681",
    700: "#1d6b5e",
    800: "#0c413a",
    900: "#001711",
  },

  secondary: {
    default: "#16260E",
    50: "#eef9e8",
    55: "#E2F2DA",
    100: "#d3eac7",
    200: "#b6dda4",
    300: "#9ace80",
    400: "#7ec05c",
    500: "#64a743",
    600: "#4e8233",
    700: "#375d24",
    800: "#203815",
    900: "#081402",
  },
  accent: {
    default: "#FE8D6C",
    50: "#ffe9e1",
    100: "#ffc3b1",
    200: "#fe9d80",
    300: "#fd774f",
    400: "#fc501e",
    500: "#e33705",
    600: "#b02a02",
    700: "#7f1d01",
    800: "#4d1000",
    900: "#1f0300",
  },

  misc: {
    cream: "#FAF3D4",
  },
} as IUiColor;
