import { useCallback, useMemo } from "react";
import useInlineScript from "./useInlineScript";
import { useSelector } from "react-redux";
import { selectUser } from "store/slices";
import { getGuestID } from "utils";

const clientId = "d4273415-89d9-44e0-87eb-0d25738b6d34";

type DetailType = {
  clientId: string;
  email?: string;
  name: string;
  phone?: string;
  country?: string;
  city?: string;
  userId?: string;
};
const script = ({ clientId, ...details }: DetailType) => `
    const widgetElement = document.createElement("charla-widget");
    widgetElement.setAttribute("p", "${clientId}");
    document.body.appendChild(widgetElement);
    const widgetCode = document.createElement("script");
    widgetCode.src = "https://app.getcharla.com/widget/widget.js";
    document.body.appendChild(widgetCode);

    const charla_event = new CustomEvent("charla:updateVisitorAttributes", {
        "detail": {
            "email": "${details?.email}",
            "name": "${details?.name}",
            "phone": "${details?.phone}",
            "country": "${details?.country}",
            "city": "${details?.city}",
            "userId": "${details?.userId}",
        }
    });

    document.dispatchEvent(charla_event);
`;

export function useCharlaChat() {
  const { profile: user } = useSelector(selectUser);

  const guestId = getGuestID();
  const config: Omit<DetailType, "clientId"> = useMemo(
    () => ({
      name: user?.firstName || "Guest",
      email: user?.email ?? "unknown",
      phone: user?.phone,
      country: user?.country,
      city: user?.city,
      userId: user?.userId ?? guestId,
    }),
    [user, guestId]
  );

  const { injectScript } = useInlineScript(script({ ...config, clientId }), "cs-chat", true);

  const boot = useCallback(
    (_props?: any) => {
      injectScript({ inlineScript: script({ ...(_props ?? config), clientId }) });
      const charla_event = new CustomEvent("charla:updateVisitorAttributes", {
        detail: { ...config },
      });
      document.dispatchEvent(charla_event);
    },
    [injectScript, config]
  );

  const bootWithProps = useCallback(() => {
    return boot();
  }, [boot]);

  return { boot, bootWithProps };
}
