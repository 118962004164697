import React from "react";

export default function hasChildWithName<N>(name: N | string, children: React.ReactNode): boolean {
  let result = false;
  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return;
    if ((child.type as any).displayName === name || (child.type as any).name === name) {
      result = true;
      return;
    }
  });

  return result;
}
