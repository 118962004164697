import {
  Button,
  FormControl,
  FormHelperText,
  Stack,
  VStack,
  Input,
  Heading,
  Divider,
  HStack,
  Box,
  Badge,
  CircularProgress,
} from "@chakra-ui/react";
import { useGetCountriesQuery, useGetPreorderFeeQuery, useGetPreorderStatusQuery, usePreorderPhysicalCardMutation } from "apis";
import { FormInfo, InputLabel, Select } from "components";
import { useDisclosures, usePartialState, useWalletBalance } from "hooks";
import { useEffect, useMemo } from "react";

import capitalize from "lodash/capitalize";
import configs from "config";
import { toCoinLocale, toPrecision } from "utils";
import { SubmitPinModal, SuccessModal } from "ui";
import { navigate } from "@reach/router";
import { PageProps } from "interfaces";

import isEmpty from "lodash/isEmpty";

interface PreorderPhysicalCardProps extends PageProps {}

interface IState {
  streetNo: string;
  streetAddress: string;
  zipCode: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  coin: string;
  firstName: string;
  lastName: string;
  otherNames: string;
  pin: string;
}

type ModalTypes = "pin" | "success";

export default function PreorderPhysicalCard(props: PreorderPhysicalCardProps) {
  const { user } = props;
  const [state, set] = usePartialState<IState>();

  const { getBalance } = useWalletBalance();
  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const { data: countries, isLoading: isLoadingCountries } = useGetCountriesQuery();

  const { data: feeData, isLoading: isFeeLoading } = useGetPreorderFeeQuery({});
  const { data: preorderStatusData, isLoading: isPreorderStatusLoading } = useGetPreorderStatusQuery({});

  const cards = useMemo(
    () => (preorderStatusData?.data ?? [])?.filter((card: any) => card?.status !== "declined"),
    [preorderStatusData]
  );

  const fee = useMemo(() => feeData?.actualFee ?? 7, [feeData]);
  console.log("PREORDER", cards, feeData);

  const [preorderCard, { isLoading }] = usePreorderPhysicalCardMutation();

  const balance = useMemo(() => getBalance((state?.coin as any) ?? "btc") ?? 0, [getBalance, state?.coin]);

  const handlePreorder = async (pin?: string) => {
    close("pin")();
    const reqData = {
      pin: pin ?? state?.pin,
      coin: state?.coin,
      addressData: {
        streetNo: state?.streetNo,
        streetAddress: state?.streetAddress,
        zipCode: state?.zipCode,
        postalCode: state?.postalCode,
        city: state?.city,
        state: state?.state,
        country: state?.country,
      },
      cardDetails: {
        firstName: state?.firstName,
        lastName: state?.lastName,
        otherNames: state?.otherNames,
      },
    };

    console.log("PRE-ORDER REQ DATA", reqData);
    const result = await preorderCard(reqData).unwrap();
    if (!!result) open("success")();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    open("pin")();
  };

  const handleContinue = () => {
    navigate(`/cards?tab=physical`);
  };

  const isDisabled = useMemo(
    () =>
      !(
        state?.firstName &&
        state?.lastName &&
        state?.streetAddress &&
        state?.streetNo &&
        state?.postalCode &&
        state?.zipCode &&
        state?.state &&
        state?.city &&
        state?.coin &&
        state?.country
      ) ||
      isLoading ||
      balance < fee ||
      isPreorderStatusLoading,
    [state, isLoading, balance, fee, isPreorderStatusLoading]
  );

  useEffect(() => {
    if (user?.kycLevel !== "three" || (!isEmpty(cards) && !isPreorderStatusLoading)) {
      navigate("/cards?tab=physical");
    }
  }, [user, cards, isPreorderStatusLoading]);

  return (
    <Stack as="form" onSubmit={handleSubmit}>
      <Heading as="h4" fontSize="md" fontWeight="600">
        Name on Card
      </Heading>
      <FormControl mt="20px !important">
        <InputLabel>Firstname</InputLabel>
        <Input placeholder="Enter firstname" value={state?.firstName ?? ""} onChange={(e) => set({ firstName: e.target.value })} />
      </FormControl>
      <FormControl mt="6px !important">
        <InputLabel>Othernames</InputLabel>
        <Input
          placeholder="Enter othername (optional)"
          value={state?.otherNames ?? ""}
          onChange={(e) => set({ otherNames: e.target.value })}
        />
      </FormControl>
      <FormControl mt="6px !important">
        <InputLabel>Lastname</InputLabel>
        <Input placeholder="Enter lastname" value={state?.lastName ?? ""} onChange={(e) => set({ lastName: e.target.value })} />
      </FormControl>

      <Divider my="20px !important" />

      <Heading as="h4" fontSize="md" fontWeight="600">
        Shipping Address
      </Heading>
      <FormControl mt="20px !important">
        <InputLabel>Street</InputLabel>
        <Input
          placeholder="Enter street name"
          value={state?.streetAddress ?? ""}
          onChange={(e) => set({ streetAddress: e.target.value })}
        />
      </FormControl>

      <FormControl mt="6px !important">
        <InputLabel>Flat No, suite, unit, building or floor number</InputLabel>
        <Input
          // type="number"
          placeholder="Enter flat no..."
          pattern="^[a-zA-Z0-9]*$"
          value={state?.streetNo ?? ""}
          onChange={(e) => set({ streetNo: e.target.value })}
        />
      </FormControl>

      <FormControl mt="6px !important">
        <InputLabel>Postal Code</InputLabel>
        <Input
          placeholder="Enter postal code"
          value={state?.postalCode ?? ""}
          onChange={(e) => set({ postalCode: e.target.value })}
        />
      </FormControl>

      <FormControl mt="6px !important">
        <InputLabel>Zip Code</InputLabel>
        <Input placeholder="Enter zip code" value={state?.zipCode ?? ""} onChange={(e) => set({ zipCode: e.target.value })} />
      </FormControl>

      <FormControl mt="6px !important">
        <InputLabel>Town/City</InputLabel>
        <Input placeholder="Enter town/city" value={state?.city ?? ""} onChange={(e) => set({ city: e.target.value })} />
      </FormControl>

      <FormControl mt="6px !important">
        <InputLabel>State/District</InputLabel>
        <Input placeholder="Enter state/district" value={state?.state ?? ""} onChange={(e) => set({ state: e.target.value })} />
      </FormControl>

      <FormControl mb="32px !important">
        <InputLabel isLoading={isLoadingCountries}>Select Country</InputLabel>
        <Select placeholder="Select Country" value={state?.country ?? ""} onChange={(e) => set({ country: e.target.value })}>
          {countries?.map((country, i) => (
            <option key={`country-${i}`} value={country?.country}>
              {capitalize(country.country)} ({country?.countryCode})
            </option>
          ))}
        </Select>

        <FormHelperText fontSize="sm">
          {!isFeeLoading ? (
            <HStack as="span">
              <Box as="strong">Card fee ${feeData?.fee}</Box>{" "}
              {feeData?.isInDiscountPeriod && (
                <Badge colorScheme="purple">
                  +{feeData?.discount}
                  {feeData?.discountUnit} discount = ${fee}
                </Badge>
              )}
            </HStack>
          ) : (
            <CircularProgress isIndeterminate size="14px" color="green.400" />
          )}
        </FormHelperText>
      </FormControl>

      <FormControl mb="32px !important">
        <InputLabel>Crypto Wallet (Select Wallet to debit from)</InputLabel>
        <Select placeholder="Select Wallet" value={state?.coin ?? ""} onChange={(e) => set({ coin: e.target.value })}>
          {configs.stableCoins.map((coin) => (
            <option key={`${coin}-wallet`} value={coin}>
              {toCoinLocale(coin)}
            </option>
          ))}
        </Select>

        <FormHelperText fontWeight="500">Note: Delivery charges will be communicated once the cards are ready</FormHelperText>
        {balance < fee && (
          <FormInfo isInvalid={balance < fee} info="" description="Insufficient balance" _description={{ ml: "0 !important" }} />
        )}

        {state?.coin && !!balance && (
          <FormInfo isInvalid={balance < fee} info="Available balance: " description={toPrecision(balance, state?.coin)} />
        )}
      </FormControl>

      <VStack m="0 auto" mt="40px !important">
        <Button type="submit" isLoading={isLoading} disabled={isDisabled}>
          Submit
        </Button>
      </VStack>

      <SubmitPinModal
        isOpen={isOpen("pin")}
        onClose={close("pin")}
        onPin={(pin) => set({ pin })}
        onSubmit={handlePreorder}
        onComplete={handlePreorder}
      />
      <SuccessModal
        isOpen={isOpen("success")}
        onClose={close("success")}
        onContinue={handleContinue}
        message="Your card will be ready in a minimum of 60 days. Delivery
          charges will be commmunicated once the cards are ready."
      />
    </Stack>
  );
}
