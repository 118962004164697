import { FC, ReactNode } from "react";
import {
  BoxProps,
  Heading,
  HStack,
  Stack,
  Skeleton,
  // Switch,
  // FormControl,
  // FormLabel,
  useColorModeValue,
  Box,
  Button,
} from "@chakra-ui/react";

import MainLayoutContainer from "../MainLayoutContainer/MainLayoutContainer";

import { BarWidget, Icon } from "components";
import { BiticonSVG } from "assets";
import { useTourGuide } from "contexts";

interface TopbarProps extends BoxProps {
  isLoading?: boolean;
  pageTitle?: string;
  action?: ReactNode;
  showTourGuideToggle?: boolean;
}

const Topbar: FC<TopbarProps> = (props) => {
  const { pageTitle, action, isLoading, children, showTourGuideToggle = false, ...xprops } = props;

  const { start } = useTourGuide();

  // const { toggleColorMode, colorMode } = useColorMode();
  const bg = useColorModeValue("#fafafa", "dark.cardBg");
  const borderColor = useColorModeValue("#e6e4e4", "#232323");

  return (
    <HStack
      justifyContent="space-between"
      h="fit-content"
      top="0"
      pos="sticky"
      zIndex="99"
      bg={bg}
      minH={{ base: "79px", md: "100px" }}
      mb="26px"
      borderBottom="1px solid transparent"
      borderColor={borderColor}
      backdropFilter="blur(10px)"
      {...xprops}
    >
      <MainLayoutContainer>
        <HStack w="100%" justifyContent="space-between">
          <Stack display={{ base: "block", "2sm": "none" }}>
            <Box width="39px" height="35px" as={BiticonSVG} color="inherit" />
          </Stack>

          <Stack display={{ base: "none", "2sm": "block" }}>
            <HStack>
              <Skeleton isLoaded={!isLoading ?? true}>
                <Heading fontSize={{ base: "1xl", "2sm": "2xl", md: "3xl" }} fontWeight="700" letterSpacing="1.24px">
                  {pageTitle}
                </Heading>
              </Skeleton>
              {!!showTourGuideToggle && !isLoading && (
                <Button
                  size="sm"
                  onClick={start}
                  fontFamily="var(--bitmama-fonts-heading)"
                  rightIcon={<Icon type="tour" boxSize="20px" />}
                >
                  Take a Tour
                </Button>
              )}
            </HStack>

            {action && action}
          </Stack>

          <HStack>
            <BarWidget {...props} />

            {/* <FormControl display={{ base: "none", "2sm": "flex" }} alignItems="center">
              <FormLabel htmlFor="theme-mode" mb="0">
                <SunIcon
                  fontSize="sm"
                  color={switchStyle(colorMode, {
                    light: "black",
                    dark: "white",
                  })}
                />


              </FormLabel>
              <Switch
                id="theme-mode"
                isChecked={switchStyle(colorMode, {
                  light: false,
                  dark: true,
                })}
                onChange={toggleColorMode}
                colorScheme="secondary"
              />
              <FormLabel ml="12px" htmlFor="theme-mode" mb="0">
                <MoonIcon
                  fontSize="sm"
                  color={switchStyle(colorMode, {
                    light: "gray.300",
                    dark: "secondary.300",
                  })}
                />
              </FormLabel>
            </FormControl> */}
          </HStack>
        </HStack>
      </MainLayoutContainer>
    </HStack>
  );
};

export default Topbar;
