import React, { Children, cloneElement, isValidElement, useMemo, useRef } from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
// import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import "./styles.scss";
import { DropdownIcon } from "./DropdownIcon";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box as B, BoxProps, Image } from "@chakra-ui/react";
import CoinIcon from "components/Icon/CoinIcon";
import { SupportedCoinType, supportedCoinType, usePartialState } from "hooks";
import { when } from "utils";

const Box = B as any;

export interface SelectProps extends Select.SelectProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  "aria-label": string;
  placeholder?: string;

  _trigger?: BoxProps;
  use_coin_icon?: boolean;
  use_image_icon?: boolean;
  preload_network_images?: string[];
  hide_value?: boolean;

  _content?: Select.SelectContentProps;
}

export function RadixSelect(props: SelectProps) {
  const {
    value,
    defaultValue,
    disabled,
    onChange,
    "aria-label": label,
    placeholder,
    use_coin_icon,
    use_image_icon,
    hide_value,
    children,
    _trigger,
    _content,
  } = props;

  const initial_value = useMemo(() => value ?? defaultValue, [value, defaultValue]);

  const [proppedChildren, map] = useMemo(() => {
    const map = new Map();

    const propped = Children.map(children, (child, i) => {
      if (!isValidElement(child)) return;
      map.set((child.props as any).value, child.props);

      return cloneElement(child, {
        ...child.props,
        use_coin_icon: use_coin_icon,
        use_image_icon: use_image_icon,
      } as SelectItemProps);
    });

    return [propped, map];
  }, [children, use_coin_icon, use_image_icon]);

  const value_cache = useRef<string>("none");

  const icon_key = when(!!use_coin_icon, "coin", !!use_image_icon ? "image" : "icon");

  const [selected_icon, setIconValue] = usePartialState<string | null>(
    when(map.has(initial_value), (map.get(initial_value) ?? {})[icon_key] ?? null, null),
    [map, initial_value]
  );

  const [selected_image, setSelectedImage] = usePartialState<string | null>(
    when(map.has(initial_value), (map.get(initial_value) ?? {})["image"] ?? null, null),
    [map, initial_value]
  );

  const is_coin_supported = supportedCoinType.includes((selected_icon as any) ?? "btc");
  const show_coin_icon = useMemo(
    () => is_coin_supported && !!selected_icon && use_coin_icon,
    [is_coin_supported, selected_icon, use_coin_icon]
  );

  const show_image_icon = useMemo(() => !!use_image_icon && !!selected_image, [use_image_icon, selected_image]);

  const handleChange = (value: string) => {
    value_cache.current = value;
    // const icon_key = when(!!use_coin_icon, "coin", !!use_image_icon ? "image" : "icon");

    if (map.has(value)) {
      const child_props = map.get(value);
      if (!!child_props) setIconValue(child_props[icon_key] ?? null);
    }

    if (!!use_image_icon && map.has(value)) {
      const image_src = map.get(value)["image"];
      setSelectedImage(image_src);
      // console.log("selected image", image_src);
    }

    !!onChange && onChange(value);

    // console.log("Selected Value", value);
  };

  return (
    <Select.Root
      key={new Date().getTime()}
      defaultValue={defaultValue}
      value={value}
      onValueChange={handleChange}
      disabled={disabled}
    >
      <Box as={Select.Trigger} className="SelectTrigger" aria-label={label} {..._trigger}>
        {show_coin_icon && <CoinIcon boxSize="28px" coin={selected_icon as any} />}
        {/* {show_image_icon && <Box as={Image} boxSize="28px" borderRadius="50%" src={selected_icon} alt="" />} */}
        {show_image_icon && <Box as={Image} boxSize="28px" borderRadius="50%" src={selected_image} alt="" />}
        <Select.Value placeholder={placeholder} hidden={hide_value} />
        <Box flex="1" />
        <Select.Icon className="SelectIcon">
          <DropdownIcon />
        </Select.Icon>
      </Box>
      <Select.Portal>
        <Select.Content className="SelectContent" {..._content}>
          <Select.ScrollUpButton className="SelectScrollButton">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className="SelectViewport">
            {/* <SelectItem value="apple" icon={(props: any) => <CoinIcon boxSize="24px" coin="btc" {...props} />}>
              Apple
            </SelectItem>
            <SelectItem value="banana">Banana</SelectItem>
            <SelectItem value="blueberry">Blueberry</SelectItem>
            <SelectItem value="grapes">Grapes</SelectItem>
            <SelectItem value="pineapple">Pineapple</SelectItem>

            <SelectItem value="aubergine">Aubergine</SelectItem>
            <SelectItem value="broccoli">Broccoli</SelectItem>
            <SelectItem value="carrot" disabled>
              Carrot
            </SelectItem>
            <SelectItem value="courgette">Courgette</SelectItem>
            <SelectItem value="leek">Leek</SelectItem>

            <SelectItem value="beef">Beef</SelectItem>
            <SelectItem value="chicken">Chicken</SelectItem>
            <SelectItem value="lamb">Lamb</SelectItem>
            <SelectItem value="pork">Pork</SelectItem> */}
            {proppedChildren}

            {/* {supportedCoinType.map((coin) => (
              <SelectItem value={coin} coin={coin}>
                {toCoinLocale(coin)}
              </SelectItem>
            ))} */}
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}

interface SelectItemProps extends BoxProps {
  value: string;
  disabled?: boolean;
  icon?: any;

  /** Pass in the coin name, when the options has known coin icon coresponding to the coin name you'd like to display with the select option.
   * To make this work properly, you are required to pass in `use_coin_icon` in from the parent component.
   * */
  coin?: SupportedCoinType;
  /** Pass in the image, when the options has images you'd like to display with the select option.
   * To make this work properly, you are required to pass in `use_image_icon` from the parent component.
   **/
  image?: string;

  /** This property will be passed down implicitly into the Option (SelectItem) component from it's parent.  */
  use_image_icon?: boolean;
  /** This property will be passed down implicitly into the Option (SelectItem) component from it's parent.  */
  use_coin_icon?: boolean;
}

export const SelectItem = React.forwardRef<any, SelectItemProps>((props, forwardedRef) => {
  const { children, className, icon, coin, image, use_image_icon, use_coin_icon, ...xprops } = props;
  // const [xvalue, image] = String(value).split(";");

  const show_coin_icon = useMemo(() => !!use_coin_icon && !!coin && supportedCoinType.includes(coin), [coin, use_coin_icon]);
  const show_image_icon = useMemo(() => !!use_image_icon && !!image, [image, use_image_icon]);

  return (
    <Box as={Select.Item} className={classnames("SelectItem", className)} {...xprops} ref={forwardedRef}>
      {!!icon && <Box as={icon} className="SelectItemIcon" boxSize="8px" />}
      {!!show_coin_icon && <Box as={CoinIcon} coin={coin} className="SelectItemIcon" boxSize="28px" />}
      {!!show_image_icon && <Box as={Image} boxSize="28px" borderRadius="50%" src={image} alt={props.value} />}
      <Select.ItemText className="SelectItemText">{children}</Select.ItemText>
      <Select.ItemIndicator className="SelectItemIndicator">
        <CheckIcon fontSize="12px" />
      </Select.ItemIndicator>
    </Box>
  );
});

export const value_with_image = (value: string, image: string) => {
  return String(value).concat(";").concat(image);
};

// export default SelectDemo;

// type UnionToParm<U> = U extends any ? (k: U) => void : never;
// type UnionToSect<U> = UnionToParm<U> extends (k: infer I) => void ? I : never;
// type ExtractParm<F> = F extends { (a: infer A): void } ? A : never;

// type SpliceOne<Union> = Exclude<Union, ExtractOne<Union>>;
// type ExtractOne<Union> = ExtractParm<UnionToSect<UnionToParm<Union>>>;

// type ToTuple<Union> = ToTupleRec<Union, []>;
// type ToTupleRec<Union, Rslt extends any[]> = SpliceOne<Union> extends never
//   ? [ExtractOne<Union>, ...Rslt]
//   : ToTupleRec<SpliceOne<Union>, [ExtractOne<Union>, ...Rslt]>;
