import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { useTurnOnAndOff2FAMutation } from "apis";
import { AbstractModal, Icon, useAbstractModal } from "components";
import { useDispatch } from "hooks";
import { getProfile } from "store/slices";

interface Disable2FAModalProps extends Omit<ModalProps, "children"> {}

export default function Disable2FAModal(props: Disable2FAModalProps) {
  const { ...xprops } = props;
  //   const bColor = useColorModeValue("grey.150", "dark.border");

  return (
    <AbstractModal
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        // position: { base: "absolute", "1sm": "relative" },
      }}
      {...xprops}
    >
      <Content {...props} />
    </AbstractModal>
  );
}

function Content(props: any) {
  const { onClose } = props;

  const { updateModal } = useAbstractModal();

  const dispatch = useDispatch();

  const [_turnOff, { isLoading }] = useTurnOnAndOff2FAMutation();

  const handleTurnOff2FA = async () => {
    const response = await _turnOff("turnOffTwoFa");

    console.log("Turn off 2FA", response);

    if (!!response) {
      dispatch(getProfile());

      updateModal({
        isSuccess: true,
        success: {
          description: `You have successfully disabled Google Authenticator to protect your Account`,
          actions: [{ label: "Continue", onClick: onClose }],
        },
      });
    }

    // set({ isOn: false });
  };

  return (
    <Box p={{ base: "32px 40px", "1sm": "44px 80px" }}>
      <VStack mb="60px !important">
        <Icon type="infoError" boxSize="44px" />
        <Heading mb="10px !important" as="h4" fontSize="32px">
          Disable 2FA
        </Heading>
        <Text fontWeight="500" textAlign="center">
          Turn off 2F validation for your account
          <br /> This is not recommended so as to keep your account safe
        </Text>
      </VStack>

      <VStack mt="24px !important" px="46px" w="100%">
        <Button minW="unset" w="100%" onClick={handleTurnOff2FA} isLoading={isLoading} disabled={isLoading}>
          Disable 2FA Now
        </Button>
        <Button variant="outline" textDecoration="underline" minW="unset" w="100%" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </VStack>
    </Box>
  );
}
