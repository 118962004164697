import { Box, Button, VStack, useColorModeValue } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import { useGetBuyTradeSessionQuery } from "apis";
import { ConditionalRender, Icon, Messanger } from "components";
import { useBuiltInFn, useEventListener, usePartialState } from "hooks";
import { P2PPendingTradeCounterPartRo, P2PSessionRo, PageProps } from "interfaces";
import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";

import toLower from "lodash/toLower";
import { DefaultScreen, OrderCompletedScreen, TradeOwnerMakePayment, TraderMakePayment } from "./Screens";

interface SessionFeatureProps extends PageProps {}

export interface ScreenMapperProps extends SessionFeatureProps, Partial<P2PSessionRo> {
  setHideBackButton: (update: boolean) => void;
  isLoading?: boolean;
}

interface IP2PSessionChatState {
  chatOpened: boolean;
  chatInFocus: boolean;
  unReadChats: any[];
  isSupportOn: boolean;
}
interface IP2PSessionContext {
  ref: string;
  tradeId: string;
  frozenRef: string;
  isSessionActive: boolean;
  trader: P2PPendingTradeCounterPartRo;
  tradeOwner: P2PPendingTradeCounterPartRo;
  chatState: Partial<IP2PSessionChatState>;
  setChatState: (update: Partial<IP2PSessionChatState>) => void;
  getStatus: (role: string) => string;
}

interface UseScreenMapperProps extends ScreenMapperProps {
  mapKey: string;
  getStatus: (role: string) => string;
}

const P2PSessionContext = createContext<IP2PSessionContext | null>(null);

export function P2PSessionProvider(props: PropsWithChildren<ScreenMapperProps>) {
  const { ref } = useParams();
  const { counterParties, contractState, children, isLoading } = props;
  const [tradeId, frozenRef] = (ref ?? "").split("-") ?? [];

  const [chatState, setChatState] = usePartialState<IP2PSessionChatState>({
    chatOpened: false,
    chatInFocus: false,
    unReadChats: [],
    isSupportOn: false,
  });

  const tradeOwner = useMemo(() => (counterParties ?? [])?.find((cp) => toLower(cp.role) === "tradeowner"), [counterParties]);
  const trader = useMemo(() => (counterParties ?? [])?.find((cp) => toLower(cp.role) === "trader"), [counterParties]);

  const getStatus = useCallback(
    (role: string) => {
      const map: Record<string, P2PPendingTradeCounterPartRo | undefined> = { trader, tradeowner: tradeOwner };
      return `${contractState}&${map[role]?.obligationStatus}`;
    },
    [trader, tradeOwner, contractState]
  );

  const isSessionActive = useMemo(
    () =>
      !!getStatus("trader") &&
      getStatus("trader") !== "cancelled&pending" &&
      getStatus("trader") !== "closed&fulfilled" &&
      getStatus("trader") !== "timeout&pending",
    [getStatus]
  );

  return (
    <P2PSessionContext.Provider
      value={{
        ref,
        tradeId,
        frozenRef,
        getStatus,
        trader: trader!,
        tradeOwner: tradeOwner!,
        isSessionActive,
        chatState,
        setChatState,
      }}
    >
      {children}

      <ConditionalRender shouldRender={!!tradeId && !!frozenRef && !isLoading}>
        <VStack my="44px">
          <Box display={["none", "none", "block"]}>
            <Messanger
              sessionActive={isSessionActive}
              tradeId={tradeId}
              frozenRef={frozenRef}
              trader={trader}
              tradeOwner={tradeOwner}
              chatState={chatState}
              setChatState={setChatState}
              // userInitialisedChat={chatInitialised}
            />
          </Box>
        </VStack>
      </ConditionalRender>
    </P2PSessionContext.Provider>
  );
}

export function useP2PSession() {
  const context = useContext(P2PSessionContext);
  if (!context) throw Error("useP2PSession must be used within a P2PSessionProvider");
  return context;
}

export default function SessionFeature(props: SessionFeatureProps) {
  const { ref } = useParams();

  const { goBack } = useBuiltInFn();
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const [hideBackButton, setHideBackButton] = useState(false);

  const { data, isLoading, refetch } = useGetBuyTradeSessionQuery(ref);
  useEventListener("refetch_p2p_trade_session", () => refetch());

  console.log("Session Data", data);

  return (
    <Box mb="42px">
      <ConditionalRender shouldRender={!hideBackButton}>
        <Button
          size="sm"
          minW="fit-content"
          maxW="fit-content"
          variant="link"
          leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
          onClick={goBack}
          px="2px !important"
        >
          Back
        </Button>
      </ConditionalRender>

      <ScreenMapper setHideBackButton={setHideBackButton} {...{ ...props, ...data, isLoading }} />
    </Box>
  );
}

function ScreenMapper(props: ScreenMapperProps) {
  //   const { ref } = useParams();
  const { counterParties, contractState, user, adsType } = props;
  // const { onCopy } = useCopy(data?.id ?? "");
  //   const [id, frozenRef] = (ref ?? "").split("-") ?? [];

  const tradeOwner = useMemo(() => (counterParties ?? [])?.find((cp) => toLower(cp.role) === "tradeowner"), [counterParties]);
  const trader = useMemo(() => (counterParties ?? [])?.find((cp) => toLower(cp.role) === "trader"), [counterParties]);

  const getStatus = useCallback(
    (role: string) => {
      const map: Record<string, P2PPendingTradeCounterPartRo | undefined> = { trader, tradeowner: tradeOwner };
      return `${contractState}&${map[role]?.obligationStatus}`;
    },
    [trader, tradeOwner, contractState]
  );

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  console.log("Screen Mapper", getStatus("trader"), appropriateSessionOwner);

  const mapKey = useMemo(
    () => `${appropriateSessionOwner?.role}&${adsType}&${getStatus(appropriateSessionOwner?.role ?? "trader")}`,
    [appropriateSessionOwner, adsType, getStatus]
  );

  const screen = useScreenMapper({ ...props, getStatus, mapKey });

  return <P2PSessionProvider {...props}>{screen}</P2PSessionProvider>;
}

function useScreenMapper(props: UseScreenMapperProps) {
  const { mapKey } = props;

  console.log(mapKey);

  // const partialMapKey = useMemo(() => {
  //   if(mapKey.includes('trader')) return mapKey.
  // }, [mapKey])

  const renderView = useMemo(() => {
    const map: Record<string, JSX.Element> = {
      [mapKey]: <DefaultScreen {...props} />,
      //////////////////////////////////////////////////////////////
      //// TRADER BUYING SCREENS
      "trader&buying&closed&fulfilled": <OrderCompletedScreen {...props} />,
      //////////////////////////////////////////////////////////////
      //// TRADER SELLING SCREENS
      "trader&selling&open&pending": <TraderMakePayment {...props} />,
      "trader&selling&closed&fulfilled": <OrderCompletedScreen {...props} />,

      //////////////////////////////////////////////////////////////
      //// TRADE OWNER BUYING SCREENS
      "tradeowner&buying&open&pending": <TradeOwnerMakePayment {...props} />,
      "tradeowner&buying&closed&fulfilled": <OrderCompletedScreen {...props} />,
      //////////////////////////////////////////////////////////////
      //// TRADE OWNER SELLING SCREENS
      "tradeowner&selling&closed&fulfilled": <OrderCompletedScreen {...props} />,
    };

    return map[mapKey];
  }, [mapKey, props]);

  return renderView;
}
