import {
  Box,
  Button,
  FormControl,
  Grid,
  Heading,
  Input,
  ModalProps,
  Text,
  useColorMode,
  useColorModeValue,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import {
  AbstractModal,
  AmountInput,
  DetailItem,
  FormInfo,
  Group,
  Icon,
  InputLabel,
  Option,
  Radio,
  RadixSelect,
  Select,
  Steps,
  Title,
  TitleBar,
  useModalView as useSteps,
} from "components";
import configs from "config";
import { SidePageViewProps, useSidePage } from "contexts";
import { useCopy, useDisclosures, useGreenboxWalletBalance, usePartialState } from "hooks";
import { useEffect, useMemo } from "react";
import { switchStyle, toCoinLocale, toPrecision } from "utils";

import toLower from "lodash/toLower";
import capitalize from "lodash/capitalize";
import { SubmitPinModal } from "ui";
import { useSendGreenboxMutation, useWithdrawGreenboxMutation } from "apis";
import { AppAnalyticEvent, SendGreenboxDto } from "interfaces";
import { formatDistance, isPast, parseISO } from "date-fns";
import { RocketSVG } from "assets";
import { Emitter } from "libs";

interface SendAGreenBoxProps extends SidePageViewProps {}

interface PropsWithState extends SendAGreenBoxProps, IStateExtension {}

interface IStateExtension {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  reset: () => void;
}

type CriteriaType = "ALL_USERS" | "NEW_USERS" | "SELECTED_USERS" | "NEW_USERS_KYC_L2";

export interface IState {
  name: string;
  recipients: string;
  distribution: string;
  criteria: CriteriaType;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  coin: string;
  amount: string;
  pin: string;
}

type ModalTypes = "pin" | "success";

export default function SendAGreenBoxSetup(props: SendAGreenBoxProps) {
  const { data: params } = useSidePage();
  const coin = useMemo(() => (params?.coin ? toLower(params?.coin) : undefined), [params]);
  const [state, set, reset] = usePartialState<IState>({ coin, distribution: "evenly" });

  console.log("Greenbox Fund", coin);

  return (
    <Box overflowX="hidden">
      <Steps hideIndicator>
        <SendAGreenBox {...{ ...props, state, set, reset }} />
        <Preview {...{ ...props, state, set, reset }} />
      </Steps>
    </Box>
  );
}

export function SendAGreenBox(props: PropsWithState) {
  //   const { setCurrentView } = useSteps();
  const { state, set } = props;

  const { hasNext, onNext } = useSteps();

  const { getBalance, isLoading } = useGreenboxWalletBalance();
  const { onClose: onSidePageclose, updateSidePageConfigs } = useSidePage();

  const { colorMode } = useColorMode();

  const balance = useMemo(() => getBalance(state?.coin ?? "usdt"), [state?.coin, getBalance]);

  const [, { isLoading: isFunding }] = useWithdrawGreenboxMutation();

  // const isLessThanMinimum = useMemo(() => !!state?.amount && +state.amount < +(minimumWithdraw ?? 0), [state, minimumWithdraw]);

  const isInsufficientBalance = useMemo(() => !!state?.amount && +state?.amount > balance, [state, balance]);
  const maxRecipients = useMemo(() => {
    // if (!state?.amount) return 2000;
    // return Math.floor(balance / +state?.amount);
    return 2000;
  }, []);

  const isLessOrGreaterThanMinMax = useMemo(
    () => (!!state?.recipients && +state.recipients < 5) || (!!state?.recipients && +state?.recipients > maxRecipients),
    [state?.recipients, maxRecipients]
  );

  const isStartDatePast = useMemo(
    () => !!state?.startDate && !!state?.startTime && isPast(parseISO(toDateISOString(state?.startDate, state?.startTime))),
    [state?.startDate, state?.startTime]
  );

  const isEndDatePast = useMemo(
    () => !!state?.endDate && !!state?.endTime && isPast(parseISO(toDateISOString(state?.endDate, state?.endTime))),
    [state?.endDate, state?.endTime]
  );

  const isDisabled = useMemo(
    () =>
      !(state?.amount && state?.coin && state?.name && state?.startDate && state?.endDate && state?.startTime && state?.endTime) ||
      !hasNext ||
      isLessOrGreaterThanMinMax ||
      isInsufficientBalance ||
      isFunding ||
      isLoading ||
      isStartDatePast ||
      isEndDatePast,
    [state, isInsufficientBalance, isFunding, isLoading, hasNext, isLessOrGreaterThanMinMax, isStartDatePast, isEndDatePast]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  useEffect(() => {
    // reset the sidepage close handler incase user is coming from the next view.
    updateSidePageConfigs({ _back: { onClick: onSidePageclose } });

    //eslint-disable-next-line
  }, []);

  return (
    <Box id="send-a-greenbox" px="40px" {...props} overflowY="scroll">
      <TitleBar>
        <Title fontSize="20px">Send a Greenbox</Title>
      </TitleBar>

      <Group as="form" onSubmit={handleSubmit}>
        {/* <Heading as="h5" fontSize="md" mb="42px">
          Select Wallet Destination
        </Heading> */}

        <FormControl mb="32px !important">
          <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
            Name of the Greenbox
          </InputLabel>

          <Input
            placeholder="Choose a Name. e.g; “Bitmama Giveaway”"
            value={state?.name ?? ""}
            onChange={(e) => set({ name: e.target.value })}
          />
        </FormControl>

        <FormControl mb="32px !important">
          <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
            Coin to gift
          </InputLabel>

          {/* <Select placeholder="Select Coin" value={state?.coin ?? ""} onChange={(e) => set({ coin: e.target.value as any })}>
            {configs.stableCoins.map((coin) => (
              <option key={`${coin}-stable-coin`} value={coin}>
                {toCoinLocale(coin)}
              </option>
            ))}
          </Select> */}

          <RadixSelect
            use_coin_icon
            value={state?.coin}
            aria-label="Select Coin"
            placeholder="Select Coin"
            onChange={(value) => set({ coin: value })}
            _trigger={{
              w: "100%",
            }}
          >
            {configs.stableCoins.map((coin) => (
              <Option key={coin} value={coin} coin={coin as any}>
                {toCoinLocale(coin)}
              </Option>
            ))}
          </RadixSelect>

          {/* {fee && <FormInfo mt="12px" info="Fee: " description={toPrecision(fee, coin)} />} */}
        </FormControl>

        <FormControl mb="32px !important">
          <InputLabel mb="10px" _text={{ fontSize: "14px" }}>
            Amount to gift (per person)
          </InputLabel>
          <AmountInput
            isInvalid={isInsufficientBalance}
            placeholder="Enter Coin Amount"
            value={state?.amount ?? ""}
            onMax={() => set({ amount: String(Number(balance)) })}
            onChange={(e) => set({ amount: e.target.value })}
          />

          {isInsufficientBalance && (
            <FormInfo
              info=""
              mt="12px"
              isInvalid={isInsufficientBalance}
              description="Insufficient balance"
              _description={{ ml: "0 !important" }}
            />
          )}
          {!!state?.coin && (
            <FormInfo
              mt="12px"
              isInvalid={isInsufficientBalance}
              info="Available: "
              description={toPrecision(balance, state?.coin)}
            />
          )}
        </FormControl>

        <FormControl mb="32px !important">
          <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
            Number of recipients
          </InputLabel>

          <Input
            type="number"
            min={5}
            max={2000}
            isInvalid={isLessOrGreaterThanMinMax}
            value={state?.recipients ?? ""}
            onChange={(e) => set({ recipients: e.target.value })}
          />

          <FormInfo
            info=""
            mt="12px"
            isInvalid={isLessOrGreaterThanMinMax}
            description={`Min - Max no of recipients: 5 - ${maxRecipients}`}
            _description={(props) => ({
              ml: "0 !important",
              color: props?.isInvalid ? "error" : switchStyle(colorMode, { light: "primary.default", dark: "secondary.500" }),
            })}
          />
        </FormControl>

        <FormControl mb="32px !important">
          <InputLabel mb="10px" _text={{ fontSize: "14px" }}>
            Total Amount ($)
          </InputLabel>
          <AmountInput isDisabled value={Number(+(state?.amount ?? 0) * +(state?.recipients ?? 0)).toLocaleString()} />
        </FormControl>

        <FormControl mb="32px !important">
          <InputLabel mb="10px" _text={{ fontSize: "14px" }}>
            Type of distribution
          </InputLabel>

          <RadioOptionGroup value={state?.distribution ?? ""} onChange={(distribution) => set({ distribution })} />
        </FormControl>

        <FormControl mb="32px !important">
          <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
            Criteria for users to access Greenbox
          </InputLabel>

          <Select
            placeholder="Select Criteria"
            value={state?.criteria ?? ""}
            onChange={(e) => set({ criteria: e.target.value as any })}
          >
            {[
              { label: "new users", value: "NEW_USERS" },
              { label: "all bitmama users", value: "ALL_USERS" },
              { label: "New but verified users", value: "NEW_USERS_KYC_L2" },
            ].map(({ label, value }) => (
              <option key={`${value}-criteria`} value={value}>
                {capitalize(label)}
              </option>
            ))}
          </Select>
        </FormControl>

        <Grid templateColumns="repeat(2, 1fr)" gap="42px">
          <FormControl mb="32px !important">
            <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
              Select start date
            </InputLabel>

            <Input type="date" value={state?.startDate ?? ""} onChange={(e) => set({ startDate: e.target.value })} />

            {isStartDatePast && (
              <FormInfo
                mt="10px"
                isInvalid={isStartDatePast}
                info=""
                description="The start date and time must not be earlier than today"
              />
            )}
          </FormControl>

          <FormControl mb="32px !important">
            <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
              Select start time
            </InputLabel>

            <Input type="time" value={state?.startTime ?? ""} onChange={(e) => set({ startTime: e.target.value })} />
          </FormControl>
        </Grid>

        <Grid templateColumns="repeat(2, 1fr)" gap="42px">
          <FormControl mb="32px !important">
            <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
              Select end date
            </InputLabel>

            <Input type="date" value={state?.endDate ?? ""} onChange={(e) => set({ endDate: e.target.value })} />

            {isEndDatePast && (
              <FormInfo
                mt="10px"
                isInvalid={isEndDatePast}
                info=""
                description="The end date and time must not be earlier than today"
              />
            )}
          </FormControl>

          <FormControl mb="32px !important">
            <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
              Select end time
            </InputLabel>

            <Input type="time" value={state?.endTime ?? ""} onChange={(e) => set({ endTime: e.target.value })} />
          </FormControl>
        </Grid>

        <VStack mt="62px !important">
          <Button type="submit" disabled={isDisabled} isLoading={isFunding}>
            Preview
          </Button>
          <Button variant="outline" isLoading={isFunding} onClick={onSidePageclose}>
            Cancel
          </Button>
        </VStack>
      </Group>
    </Box>
  );
}

export function Preview(props: PropsWithState) {
  const { state, set } = props;

  const { hasPrev, onPrev } = useSteps();
  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const { onClose: onSidePageclose, updateSidePageConfigs } = useSidePage();

  const [send, { data, isLoading }] = useSendGreenboxMutation();

  const link = useMemo(() => `${window.location.protocol}//${window.location.host}/gbx/${data?.product?.code ?? ""}`, [data]);
  const { onCopy } = useCopy(link);

  console.log("Successful Data", data);

  const criteria = useMemo(() => {
    const map: Record<CriteriaType, string> = {
      ALL_USERS: "All Bitmama Users",
      SELECTED_USERS: "Selected Users",
      NEW_USERS: "All New Users",
      NEW_USERS_KYC_L2: "New but Verified Users",
    };

    return map[state?.criteria ?? "ALL_USERS"];
  }, [state?.criteria]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log("clicked submit");
    open("pin")();
  };

  const sendAGreenBox = async (pin?: string) => {
    close("pin")();
    const reqData: SendGreenboxDto = {
      title: state?.name!,
      unit: state?.coin!,
      amountPerUser: state?.amount!,
      totalRecipients: +state?.recipients!,
      startDate: toDateISOString(state?.startDate!, state?.startTime!),
      expirationDate: toDateISOString(state?.endDate!, state?.endTime!),
      criteria: state?.criteria!,
      pin: pin ?? state?.pin!,
    };

    Emitter.emit(AppAnalyticEvent.CREATE_GREENBOX_INITIATED, reqData);

    // console.log(reqData);
    const result = await send(reqData).unwrap();
    console.log("Send result", result);
    if (!!result) {
      Emitter.emit(AppAnalyticEvent.CREATE_GREENBOX_SUBMITTED, reqData);
      open("success")();
    }
  };

  useEffect(() => {
    // set the sidepage back button click handler to go back to previous view.
    if (hasPrev) updateSidePageConfigs({ _back: { onClick: onPrev, text: "Back" } });
    //eslint-disable-next-line
  }, []);

  return (
    <Box id="send-a-greenbox" px="40px" {...props} overflowY="scroll">
      <TitleBar>
        <Title fontSize="20px">Preview Greenbox</Title>
      </TitleBar>

      <Group as="form" onSubmit={handleSubmit}>
        <DetailItem title="Greenbox Name" description={state?.name ?? ""} _description={{ fontSize: "20px" }} />

        <Grid templateColumns="repeat(2, 1fr)" gap="42px">
          <DetailItem
            title="Gift Amount (per person)"
            description={toPrecision(+(state?.amount ?? 0), state?.coin!)}
            _description={{ fontSize: "20px" }}
          />
          <DetailItem title="Recipients" description={state?.recipients ?? ""} _description={{ fontSize: "20px" }} />
        </Grid>

        <Grid templateColumns="repeat(2, 1fr)" gap="42px">
          <DetailItem
            title="Total Amount (all recipients)"
            description={toPrecision(+(state?.amount ?? 0) * +(state?.recipients ?? 0), state?.coin!)}
            _description={{ fontSize: "20px" }}
          />
          <DetailItem
            title="Distribution"
            description={capitalize(state?.distribution ?? "")}
            _description={{ fontSize: "20px" }}
          />
        </Grid>

        <Grid templateColumns="repeat(2, 1fr)" gap="42px">
          <DetailItem title="Criteria for qualification" description={criteria} _description={{ fontSize: "20px" }} />
          <DetailItem
            title="Expiration"
            description={`Expires in ${formatDistance(parseISO(toDateISOString(state?.endDate!, state?.endTime!)), new Date())}`}
            _description={{ fontSize: "20px" }}
          />
        </Grid>
      </Group>

      <VStack mt="162px !important">
        <Button type="submit" onClick={open("pin")} isLoading={isLoading} disabled={isLoading}>
          Proceed
        </Button>
        <Button variant="outline" onClick={onSidePageclose} disabled={isLoading}>
          Cancel
        </Button>
      </VStack>

      <SubmitPinModal
        isOpen={isOpen("pin")}
        onClose={close("pin")}
        onPin={(pin) => set({ pin })}
        onSubmit={() => sendAGreenBox()}
        onComplete={(pin) => sendAGreenBox(pin)}
      />
      <Success
        isOpen={isOpen("success")}
        onClose={() => {
          close("success")();
          //   reset();
        }}
        message={`Greenbox, [${state?.name ?? "Greenbox Title"}], created successfully`}
        onContinue={() => {
          onCopy();
          onSidePageclose();
        }}
      />
    </Box>
  );
}

interface RadioOptionGroupProps {
  value?: string;
  onChange?: (value: string) => void;
}

function RadioOptionGroup(props: RadioOptionGroupProps) {
  const { value, onChange } = props;

  const options = [{ label: "Evenly Distributed", value: "evenly" }];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "send-greenbox-radio-group",
    defaultValue: value,
    onChange: (value: string) => {
      onChange && onChange(value);
    },
  });

  const group = getRootProps();

  return (
    <Group m="0" {...group} gridGap="10px" fontSize="8px">
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value });
        return (
          <Radio key={value} _label={{ fontWeight: "bold" }} _after={{ opacity: 0 }} {...radio}>
            {label}
          </Radio>
        );
      })}
    </Group>
  );
}

SendAGreenBoxSetup.displayName = "SidePageView";

function toDateISOString(date: string, time: string) {
  const [h, m] = (time ?? "04:30").split(":");
  const _date = new Date(date);
  _date.setHours(+h, +m);
  //   console.log("toDateISOString", date, time);
  return _date.toISOString();
}

interface SuccessModalProps extends Omit<ModalProps, "children"> {
  onContinue?: () => void;
  closeOnContinue?: boolean;
  message: string;
}

function Success(props: SuccessModalProps) {
  const { message, isOpen, onClose, onContinue, closeOnContinue = true, ...xprops } = props;
  // const bColor = useColorModeValue("grey.500", "gray.100");
  const successMessageColor = useColorModeValue("grey.500", "grey.150");
  const hoverColor = useColorModeValue("black", "white");

  const handleContinue = () => {
    onContinue && onContinue();
    closeOnContinue && onClose();
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "620px",
        position: { base: "absolute", "1sm": "initial" },
        bottom: 0,
        margin: { base: "0", "1sm": "initial" },
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
      }}
      {...xprops}
    >
      <VStack p={{ base: "24px 0px", "2sm": "48px" }}>
        <Box mb="24px !important" as={RocketSVG} />

        <Heading as="h4" fontSize="24px" fontWeight="600" mb="30px !important">
          Success
        </Heading>

        <VStack>
          <Text maxW="340px" color={successMessageColor} mb="40px !important" fontWeight="500" textAlign="center">
            {message}
          </Text>
        </VStack>

        <VStack mt="24px !important" px="46px" w="100%">
          <Button minW="unset" w="100%" onClick={handleContinue} rightIcon={<Icon type="claimButtonIcon" color="grey.50" />}>
            Share Greenbox Link
          </Button>
          <Button
            mt="16px !important"
            variant="transparent"
            minW="unset"
            w="100%"
            // border="1px solid transparent"
            // borderColor={bColor}
            textDecoration="underline"
            onClick={onClose}
            _hover={{ color: hoverColor }}
          >
            Close
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
