import React, { useState } from "react";
import { Button, FormControl, Heading, Input, ModalProps, VStack } from "@chakra-ui/react";
import { AbstractModal, InputLabel } from "components";
import { useDispatch } from "hooks";
import { setHistoryDetailsDateFilter } from "store/slices/filter";

interface HistoryFilterProps extends Omit<ModalProps, "children"> {}

export default function HistoryDetails(props: HistoryFilterProps) {
  const { isOpen, onClose } = props;
  const [state, setState] = useState({
    afterDate: "",
    beforeDate: "",
  });

  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleFilter = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch(setHistoryDetailsDateFilter(state));
    onClose();
  };

  return (
    <AbstractModal isOpen={isOpen} onClose={onClose} _content={{ maxW: "560px" }}>
      <VStack as="form" p="45px 100px" onSubmit={handleFilter}>
        <VStack maxW="320px" m="0 auto" mb="40px">
          <Heading as="h6" fontSize="18px">
            Filter
          </Heading>
        </VStack>

        <FormControl mb="16px !important">
          <InputLabel htmlFor="afterDate">Start Date</InputLabel>
          <Input defaultValue={state.afterDate} type="date" name="afterDate" isRequired id="afterDate" onChange={handleChange} />
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="beforeDate">End Date</InputLabel>
          <Input type="date" defaultValue={state.beforeDate} name="beforeDate" isRequired id="beforeDate" onChange={handleChange} />
        </FormControl>

        <VStack mt="74px !important" px="46px" w="100%">
          <Button minW="unset" w="100%" type="submit" fontFamily="var(--bitmama-fonts-heading)">
            Apply
          </Button>
          <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" type="submit" onClick={onClose}>
            Cancel
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
