import { RepeatIcon } from "@chakra-ui/icons";
import { Box, TabList, TabPanel, TabProps, Tabs, Tab, useColorModeValue, HStack, IconButton } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { HubBox, P2PFilter, PeerCoins, Steps, TabPanels, useModalView as useSteps } from "components";
import { PageProps } from "interfaces";
import { Emitter } from "libs";
import { useContext, useMemo, useState } from "react";
import AdvertisementsPanel from "./Advertisements";
import BuyPanel from "./Panels/AllTrades/Buy";
import SellPanel from "./Panels/AllTrades/Sell";
import { presenceContext } from "Pages/P2P/P2P";
import { usePartialState } from "hooks";
import { isTesting } from "config";

interface AllTradesPanelProps extends PageProps {}

interface AdsTabProps extends TabProps {}

export default function AllTradesPanel(props: AllTradesPanelProps) {
  const presence = useContext(presenceContext);

  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location?.search as string), [location?.search]);

  const tabMap = {
    buy: 0,
    sell: 1,
  };

  const tab = useMemo(() => searchParams.get("tab")?.split(":")[1], [searchParams]);
  console.log(tab);
  // const adsType = tab === undefined || tab === "buy" ? "selling" : "buying";
  // console.log(adsType);
  const [tabIndex, changeTab] = useState((tabMap as any)[tab as string] ?? 0);
  const [filter, setFilter] = usePartialState<IFilter>({
    endpoint: "",
    queries: {
      page: 1,
      limit: 10,
      adsStatus: "open",
      // adsType: "selling",
      sortBy: "createdAt:desc",
      coins: isTesting ? "tbtc" : "btc",
    },
  });
  // const isBuyFromListingDisabled = useMemo(() => !!p2pConfig && !!p2pConfig?.disableBuyListing, [p2pConfig]);
  // const isSellToListingDisabled = useMemo(() => !!p2pConfig && !!p2pConfig?.disableSellToListing, [p2pConfig]);

  const handleTabChange = (index: number) => {
    // setFilter((prev) => {
    //   return {
    //     endpoint: prev.endpoint,
    //     queries: { ...prev.queries, adsType: tab === "sell" ? "buying" : "selling" },
    //   };
    // });
    changeTab(index);
    searchParams.set("tab", `allTrades:${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  console.log(presence);
  console.log({ tab, tabIndex });
  const cardBg = useColorModeValue("#F2F4F3", "dark.cardBg");
  return (
    <Steps hideIndicator>
      <Box>
        <HubBox coin={filter?.queries?.coin} />

        <Tabs py="40px" w="100%" variant="ghost" index={tabIndex} onChange={handleTabChange}>
          <TabList h="72px" p="8px" borderRadius="8px" justifyContent="center" m="5px">
            <HStack w="100%" justifyContent="center">
              <Box flex="1" />
              <HStack w="60%" justifyContent="center" bg={cardBg} borderRadius="7px" p="4px 8px">
                <AdsTab>Buy</AdsTab>
                <AdsTab>Sell</AdsTab>
              </HStack>

              <Box flex="1" />

              <IconButton
                p="10px"
                bg="transparent"
                color="secondary.400"
                alignSelf="center"
                minW="unset"
                minH="unset"
                h="fit-content"
                aria-label="refresh button"
                icon={<RepeatIcon color="currentColor" />}
                onClick={() => Emitter.emit("refetch_p2p", {})}
              />
            </HStack>
          </TabList>
          <Control {...props} filter={filter} setFilter={setFilter} />

          <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
            <TabPanel overflow="hidden" px={["0", "0", "0", "1"]} pt="0px">
              <BuyPanel {...props} filter={filter} setFilter={setFilter} />
            </TabPanel>
            <TabPanel px={["0", "0", "0", "1"]} pt="0px">
              <SellPanel {...props} filter={filter} setFilter={setFilter} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <AdvertisementsPanel {...props} />
    </Steps>
  );
}

function AdsTab(props: AdsTabProps) {
  const color = useColorModeValue("grey.default", "white");
  const bg = useColorModeValue("#FCFDFD", "");
  const selectedColor = useColorModeValue("#FCFDFD", "white");
  const selectedBackground = useColorModeValue("primary.default", "secondary.500");
  return (
    <Tab
      w="50%"
      p="14px 0"
      fontSize={{ sm: "14px", md: "24px" }}
      color={color}
      borderRadius="7px"
      bg={bg}
      fontWeight="600"
      fontFamily="var(--bitmama-fonts-heading)"
      willChange="auto"
      transition="all .4s cubic-bezier(0.4, 0, 0.2, 1)"
      _selected={{
        fontSize: { sm: "14px", md: "24px" },
        fontWeight: 700,
        color: selectedColor,
        backgroundColor: selectedBackground,
        transition: "all .4s cubic-bezier(0.4, 0, 0.2, 1)",
      }}
      {...props}
    >
      {props.children}
    </Tab>
  );
}
interface IQueryState {
  page: number;
  limit: number;
  adsStatus: string;
  /**
   * Use `selling` for the buying ads page, and `buying` for the selling ads page.
   */
  adsType: "selling" | "buying";
  /**
   * Options are `createdAt:desc` | `createdAt:asc` | `price:asc`
   * `price:desc` | `unitCost:desc` | `totalCost:desc` ... and more.
   *  Use the format ``field:{desc | asc}``
   */
  coin: string;
  sortBy: string;
  coins: string;
  currency: string;
  fiatRanges: string;
  range: string;
  paymentOption: string;
}
interface IFilter {
  endpoint: string; // --> /own, /others
  amount: string;
  queries: Partial<IQueryState>;
}
interface ControlProps extends PageProps {
  filter: Partial<IFilter>;
  setFilter: (update: Partial<IFilter>) => void;
}

function Control(props: ControlProps) {
  const { user, filter, setFilter } = props;
  const { setCurrentView, currentView } = useSteps();

  const handleCoinChange = (coin: string) => {
    setFilter({
      queries: { ...filter?.queries, page: 1, coin, coins: coin, fiatRanges: undefined, currency: undefined, sortBy: "price:desc" },
    });
    if (!!filter?.amount) {
      setFilter({ queries: { ...filter?.queries, range: `${filter?.amount ?? 0},40` } });
    }
  };
  const isSellToListingDisabled = false;
  return (
    <HStack flexDir={{ sm: "column", "3sm": "row", md: "row" }} justifyContent="space-between" mt="50px">
      {!isSellToListingDisabled && (
        <HStack>
          {/* <Text fontFamily="var(--bitmama-fonts-heading)" fontSize="18px" fontWeight="600">
              You want to <Span color="primary.300">Sell</Span> Bitcoin?
            </Text> */}
          <PeerCoins
            variant="transparent"
            _coin={{ color: "grey.500" }}
            value={filter?.queries?.coins ?? ""}
            onPeerChange={(coin) => handleCoinChange(coin)}
          />
        </HStack>
      )}

      <P2PFilter<IFilter> user={user} filters={filter} set={setFilter} setView={setCurrentView} currentView={currentView} />
    </HStack>
  );
}
