import { Avatar, Box, Heading, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { AccountLink, Card, ConditionalRender, IconNames, MainLayoutContainer, PageMotion, Topbar } from "components";
import { useI18n, useSupport } from "hooks";
import { PageProps } from "interfaces";
import { PropsWithChildren, useMemo } from "react";
import styles from "./styles.module.scss";

import DroidStar from "../../assets/images/droid-star.png";
import { useAvailableCountryFeatures } from "Pages/Dashboard/useAvailableCountryFeatures";

type AccountLinkType = {
  id: string;
  label: string;
  icon: IconNames | string;
  link?: string;
  onClick?: () => void;
  noBorder?: boolean;
};

export default function AccountIndex(props: PropsWithChildren<PageProps>) {
  const { user, children } = props;

  const links = useAccountLinks();
  const { pathname } = useLocation();
  console.log(pathname, "ROUTER");

  const color = useColorModeValue("#000", "white");

  const checkRootPage = pathname === "/account";

  const isAchievementsPages = useMemo(() => pathname.includes("achievements"), [pathname]);

  return (
    <PageMotion
      key="Account"
      // bg="#FBFBFB"
    >
      <Topbar pageTitle="Account" mb="0" {...props} />

      <ConditionalRender shouldRender={!isAchievementsPages}>
        <MainLayoutContainer
          pt={{ base: "0", "2sm": "40px" }}
          pb={{ base: "90px", "2sm": "60px" }}
          pl={{ base: "0", "2sm": "initial" }}
          pr={{ base: "0", "2sm": "initial" }}
          pos="relative"
          bg={{ base: "initial", "2sm": "initial" }}
          minH={"calc(100vh - 100px)"}
          sx={{
            "--links-gutter": { base: "50px", "3sm": "25px", sm: "50px", md: "50px", xl: "100px" },
            "--links-left": "40px",
            "--links-w": { base: "380px", sm: "100%", "3sm": "380px", md: "440px", xl: "480px" },
            // "--account-links-w": { base: "0", "3sm": "52vw", "4sm": "42vw", md: "32vw", xl: "22vw" },
          }}
        >
          <Card
            pos={{ base: "initial", "3sm": "absolute" }}
            // p={{ base: "20px", "2sm": "16px", "3sm": "50px" }}
            px={{ base: "20px", "3sm": "38px" }}
            py={{ base: "20px", "3sm": "50px" }}
            left="var(--links-left)"
            maxW="var(--links-w)"
            width="100%"
            borderRadius={{ base: "none", sm: "none", "1sm": "none", "4sm": "30px" }}
            margin="auto"
            // minW="var(--account-links-w)"
            // bg="#FCFDFD"
            // boxShadow="0px 2px 8px rgba(229, 229, 229, 0.47)"
            display={{ base: checkRootPage ? "block" : "none", "4sm": "block" }}
          >
            <VStack
              mb="20px"
              px={{ base: "20px", "3sm": "38px" }}
              alignItems={{ base: "center", sm: "flex-start", "4sm": "center" }}
            >
              <Avatar
                boxSize={{ base: "90px", sm: "58px", "1sm": "80px" }}
                borderRadius="50%"
                src={DroidStar}
                name={user?.fullName}
                bg="transparent"
              />
              <Heading as="h6" fontSize="18px">
                {user?.fullName}
              </Heading>
            </VStack>
            <Stack pt="10px">
              {links.map((link, i) => (
                <AccountLink
                  key={`account-link-${i}`}
                  to={link.link}
                  icon={link.icon}
                  label={link.label}
                  noBorderLink={link?.noBorder}
                  // maxWidth={{ base: "initial", "2sm": "334px" }}
                  w="100%"
                  boxShadow={{ base: "0px 2px 8px rgba(229, 229, 229, 0.47)", sm: "none", "2sm": "none" }}
                  borderRadius={{ base: "10px", "2sm": "0px" }}
                  padding={{ base: "14px 16px", "2sm": "16px" }}
                  onClick={link?.onClick}
                />
              ))}
            </Stack>
          </Card>

          <Box
            // ml={{
            //   base: "auto",
            //   "4sm": "calc(60px + var(--account-links-w))",
            //   md: "calc(100px + var(--account-links-w))",
            //   lg: "calc(40px + var(--account-links-w))",
            // }}
            ml={{
              base: "auto",
              "4sm": "calc(var(--links-w) + var(--links-left) + var(--links-gutter))",
            }}
            overflowY={"overlay" as any}
            maxWidth="623px"
            mt={{ base: "-1rem", "2sm": "initial" }}
            pt={{ base: checkRootPage ? "0" : "20px", "2sm": "0" }}
            // ml='auto'
            mr="auto"
            // minW="calc(100% - var(--account-links-w))"
            // width="100%"
            className={styles.accountMainPage}
          >
            {checkRootPage && (
              <VStack height="80vh" justifyContent="center" display={{ base: "none", "4sm": "flex" }}>
                <Text fontSize="18px" color={color} textAlign="center" fontWeight="500">
                  Nothing to show <br /> Click on a Menu to get started
                </Text>
              </VStack>
            )}
            {children && children}
          </Box>
        </MainLayoutContainer>
      </ConditionalRender>

      <ConditionalRender shouldRender={isAchievementsPages}>{children && children}</ConditionalRender>
    </PageMotion>
  );
}

function useAccountLinks() {
  const { bootWithProps } = useSupport();

  const { geti18nTitle } = useI18n();
  const { account_links_not_available } = useAvailableCountryFeatures();
  const title = useMemo(() => geti18nTitle("account.mobilemoney"), [geti18nTitle]);

  const links: AccountLinkType[] = [
    { id: "achievements", label: "My Achievements", icon: "achievements", link: "achievements" },
    { id: "profileInfo", label: "Profile Info", icon: "accountProfile", link: "profile" },
    { id: "defaultCurrency", label: "Default Currency", icon: "defaultCurrency", link: "currency" },
    { id: "banks", label: "Banks", icon: "accountBank", link: "banks" },
    { id: "momo", label: title, icon: "accountMomo", link: "momo" },
    { id: "partners", label: "Bitmama Partners", icon: "accountPartner", link: "partners" },
    { id: "verification", label: "Verification", icon: "verificationCompleted", link: "verification" },
    { id: "priceAlert", label: "Crypto Price Alert", icon: "accountPriceAlert", link: "pricealerts" },
    { id: "reward", label: "Claim Reward", icon: "accountReward", link: "rewards" },
    {
      id: "support",
      label: "Support",
      icon: "accountSupport",
      // link: "#",
      noBorder: true,
      onClick() {
        console.log("Shoulda booted cs-chat");
        bootWithProps();
      },
    },
    { id: "deleteAccount", label: "Delete Acccount", icon: "deleteAccount", link: "delete" },
  ].filter(({ id }) => !account_links_not_available.includes(id));

  return links;
}
