import { ReactNode, Children, isValidElement, cloneElement } from "react";

const renderComponent = <C extends string, P = {}>(
  children: ReactNode | readonly ReactNode[],
  component?: C,
  props?: P
) =>
  Children.map(children, (child) => {
    if (!isValidElement(child)) return;
    if (
      (component && (child.type as any).name === component) ||
      (component && (child.type as any).displayName === component)
    ) {
      return props ? cloneElement(child, props) : child;
    }

    // if (!component && props) {
    //   return cloneElement(child, props);
    // }
    return;
  });

export default renderComponent;
