import { useUpdateTradeSessionMutation } from "apis";
import { UpdateP2POrderDto } from "interfaces";
import { useCallback } from "react";

export function useUpdateP2POrder(tradeId: string, frozenRef: string) {
  const [_updateTradeSession, { isLoading: isUpdating, isSuccess }] = useUpdateTradeSessionMutation();

  const updateOrder = useCallback(
    async (operation: UpdateP2POrderDto["operation"], reason = "Payment was not received", paymentProof?: string) =>
      tradeId &&
      frozenRef &&
      (await _updateTradeSession({
        id: tradeId,
        frozenRef,
        operation,
        reason,
        paymentProof,
      })),
    [tradeId, frozenRef, _updateTradeSession]
  );

  return { updateOrder, isUpdating, isSuccess };
}
