import React, { useState } from "react";
import { Button, FormControl, Heading, Input, ModalProps, VStack } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { AbstractModal, InputLabel } from "components";
import { useDispatch } from "hooks";
import { setInviteHistoryDateFilter, setRewardHistoryDateFilter } from "store/slices/filter";

interface HistoryFilterProps extends Omit<ModalProps, "children"> {}

export default function HistoryFilter(props: HistoryFilterProps) {
  const { isOpen, onClose } = props;
  const [state, setState] = useState({
    afterDate: "",
    beforeDate: "",
  });

  const { search } = useLocation();

  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  /**
   *
   * Invite history filter
   */
  const handleInviteHistory = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch(setInviteHistoryDateFilter(state));
    onClose();
  };

  /**
   *
   * Invite history filter
   */
  const handleRewardHistory = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch(setRewardHistoryDateFilter(state));
    onClose();
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "560px",
        position: { base: "absolute", "1sm": "initial" },
        bottom: 0,
        margin: { base: "0", "1sm": "initial" },
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
      }}
    >
      <VStack
        as="form"
        p={{ base: "24px 20px 30px", "2sm": "45px 100px" }}
        onSubmit={search === "" || search === "?tab=invite" ? handleInviteHistory : handleRewardHistory}
      >
        <VStack maxW="320px" m="0 auto" mb={{ base: "26px", "2sm": "48px" }}>
          <Heading as="h6" fontSize="18px">
            Filter
          </Heading>
        </VStack>

        <FormControl mb="16px !important">
          <InputLabel htmlFor="afterDate">Start Date</InputLabel>
          <Input
            minH={{ base: "56px", "2sm": "60px" }}
            defaultValue={state.afterDate}
            type="date"
            name="afterDate"
            isRequired
            id="afterDate"
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="beforeDate">End Date</InputLabel>
          <Input
            minH={{ base: "56px", "2sm": "60px" }}
            type="date"
            defaultValue={state.beforeDate}
            name="beforeDate"
            isRequired
            id="beforeDate"
            onChange={handleChange}
          />
        </FormControl>

        <VStack mt={{ base: "32px !important", "2sm": "49px !important" }} px={{ base: "0", "2sm": "46px" }} w="100%">
          <Button
            minH={{ base: "56px", "2sm": "60px" }}
            minW="unset"
            w="100%"
            type="submit"
            fontFamily="var(--bitmama-fonts-heading)"
          >
            Apply
          </Button>
          <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" type="submit" onClick={onClose}>
            Cancel
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
