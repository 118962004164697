import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { useGetCardWishlistStatusQuery, useMarkWishlistStatusAsSeenMutation } from "apis";
import { AbstractModal } from "components";
import { useColor } from "hooks";
import Lottie from "react-lottie";

const patternUrl: string = require("../../../assets/svg/xmaspattern.svg").default;
const giftConfettiAnimationData = require("../../../assets/gift-confetti.json");

interface Props extends Omit<ModalProps, "children"> {}

// @Dayo, pls fix this * (Fixed) temporarily observing the behavious.
export function ValentineWishlistStatus(props: Props) {
  const { isOpen, onClose } = props;

  const color = useColor();
  //   const { shadow } = useDefaultStyle();
  //   const [dontShow, SetDontShow] = useState(false);
  const { data } = useGetCardWishlistStatusQuery("false");
  const [markAsSeen, { isLoading }] = useMarkWishlistStatusAsSeenMutation();

  const handleClose = async () => {
    // if (!!dontShow)
    if (!data?._id) return;
    await markAsSeen({ wishlistId: data?._id }).unwrap();
    onClose();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: giftConfettiAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "540px",
        // borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        borderRadius: "0px",
        position: { base: "absolute", "1sm": "initial" },
        padding: "20px",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
        overflow: "hidden",
        bg: `url("${patternUrl}")`,
        bgColor: "primary.default",
        backgroundSize: "cover",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <Box minH="570px" bg={color("white", "dark.bg")} borderRadius={{ base: "30px 30px 0px 0px", "1sm": "14px" }}>
        <VStack p="30px">
          <VStack w="100%" bg="linear-gradient(180deg, #FFC5D0 0%, #F79CAD 100%)" h="230px" borderRadius="12px" overflow="hidden">
            {/* <Image
              mt="20px"
              src={ValImage}
              alt="christmas image"
              borderRadius="14px"
              h="100%"
              overflow="hidden"
              objectFit="contain"
            /> */}

            <Lottie options={defaultOptions} height={400} width={400} isStopped={false} isPaused={false} />
          </VStack>

          <VStack py="16px">
            <Heading as="h6" my="10px" fontSize="2xl" fontWeight="600" textAlign="center" color={color("black", "white")}>
              Congratulations! 🎉
            </Heading>

            <Box my="40px">
              <Text w="248px" textAlign="center" fontWeight="500" fontSize="18px" color={color("#462F32", "white")}>
                Cupid found you and has granted you a {data?.gift ?? "item"} this valentine.
              </Text>
            </Box>
          </VStack>

          <VStack mt="12px !important">
            <Button
              minW="321px"
              maxW="321px"
              h="74px"
              onClick={handleClose}
              bg="#FF406E"
              _hover={{ bg: "#FF406E" }}
              _active={{ bg: "#FF406E" }}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Close
            </Button>
            {/* <Button
              minW="unset"
              minH="unset"
              maxW="fit-content"
              maxH="fit-content"
              h="fit-content"
              variant="outline"
              mt="16px !important"
              onClick={handleClose}
            >
              Close
            </Button> */}
          </VStack>

          {/* <VStack>
            <Checkbox
              // dir="rtl"
              colorScheme="primary"
              isChecked={dontShow}
              onChange={(e) => SetDontShow(e.target.checked)}
              sx={{
                "chakra-checkbox__control[data-focus]": {
                  shadow,
                },
              }}
            >
              Don't show again
            </Checkbox>
          </VStack> */}
        </VStack>
      </Box>
    </AbstractModal>
  );
}

// export function ChristmasUpdate(_props: Props) {
//   return null;
// }
