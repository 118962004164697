import { Box, Button } from "@chakra-ui/react";
import { SidePageProvider, useSidePage } from "contexts";
import RequestStatementContent from "./RequestStatementContent";
import { useColor } from "hooks";

function ActionButton() {
  const { onOpen } = useSidePage();

  const color = useColor();

  const handleOpen = () => {
    onOpen({ key: "RequestCardStatement" });
  };

  return (
    <Box>
      <Box>
        <Button
          size="xs"
          borderRadius="20px"
          fontSize="12px"
          border="1px solid transparent"
          borderColor={color("#0D4740", "secondary.500")}
          bg="transparent"
          padding="5px 12px"
          fontWeight="700"
          minH="25px"
          minW={{ base: "auto", md: "155px" }}
          color={color("#0D4740", "secondary.500")}
          _hover={{
            bg: color("#0D4740", "secondary.500"),
            color: "#fff",
          }}
          onClick={handleOpen}
        >
          Request Card Statement
        </Button>
      </Box>
    </Box>
  );
}

export default function RequestCardStatement() {
  return (
    <SidePageProvider>
      <ActionButton />

      <RequestStatementContent key="RequestCardStatement" hasBackButton _back={{ text: "Close" }} />
    </SidePageProvider>
  );
}

RequestCardStatement.displayName = "SidePageView";
