import { Box, TabList, TabPanel, Tabs, Text, useDisclosure, Flex } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Tab, TabPanels } from "components";
import { useSelector } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { selectUser } from "store/slices";
import { VirtualPanel, PhysicalPanel, CardEligibilityModal } from "ui";
import RequestCardStatement from "ui/Cards/Panels/Virtual/RequestCardStatement";
import { when } from "utils";

interface CardsPageProps {}
interface CardsPageTabsProps {}

export default function CardsPage(props: CardsPageProps) {
  // const stroke = useColorModeValue("primary.600", "secondary.200");

  // const navigate = useNavigate();
  // const handleBack = () => {
  //   navigate(-1);
  // };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { profile } = useSelector(selectUser);
  const isEligible = useMemo(() => when(!profile, true, !!profile && [/*"two",*/ "three"].includes(profile?.kycLevel)), [profile]);

  console.log("Card Eligibility", isEligible);

  useEffect(() => {
    if (!isEligible) onOpen();
  }, [profile, isEligible, onOpen]);

  return (
    <Box>
      <Box mb="42px">
        {/* <Button
          size="sm"
          minW="fit-content"
          maxW="fit-content"
          variant="link"
          leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
          onClick={handleBack}
          px="2px !important"
        >
          Back
        </Button> */}

        <Flex mt="12px" alignItems="center" justifyContent="space-between" maxW="800px" width="100%">
          <Text as="h4" fontSize="24px" fontWeight="bold">
            Cards
          </Text>

          <RequestCardStatement />
        </Flex>
      </Box>

      <CardsPageTabs />

      <CardEligibilityModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

function CardsPageTabs(props: CardsPageTabsProps) {
  // const { key } = useSidePage();
  const location = useLocation();

  const tabMap = {
    virtual: 0,
    physical: 1,
  };

  const searchParams = new URLSearchParams(location?.search as string);
  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", `${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    changeTab((tabMap as any)[searchParams.get("tab") as any] ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabMap]);

  return (
    <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange}>
      <TabList maxW="container.sm" borderRadius="8px" justifyContent="space-between">
        <Tab>Virtual</Tab>
        <Tab>Physical</Tab>
      </TabList>
      <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
        <TabPanel px={["2", "0", "0", "1"]}>
          <VirtualPanel />
        </TabPanel>
        <TabPanel px={["0", "0", "0", "1"]}>
          <PhysicalPanel />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
