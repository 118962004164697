import { Driver, driver } from "driver.js";
import { createContext, useContext } from "react";
import "styles/driver.scss";
import { Avatar, Button, Text, VStack } from "@chakra-ui/react";
import { AbstractModal, Title, TitleBar } from "components";
import { useDisclosures, useSelector } from "hooks";

import AvatarSrc from "assets/images/droid-star.png";
import { selectUser } from "store/slices";
import { capitalize } from "lodash";

type ModalTypes = "welcome_note";
type ContextValueType = Driver & { start: () => void };
const TourGuideContext = createContext<ContextValueType | null>(null);

export function TourGuideProvider(props: any) {
  const { children } = props;

  const { isOpen, open, close } = useDisclosures<ModalTypes>();

  const steps = [
    {
      element: "#toggle-mode",
      popover: { title: "Toggle Dark/Light Mode 🔆", description: "Toggle between light mode or dark mode" },
    },
    {
      element: "#notification-btn",
      popover: { title: "App Notifications 🔔", description: "Check all your App notifications here" },
    },
    {
      element: "#support-btn",
      popover: { title: "Support/Help 📞", description: "Get instant help from our customer support team" },
    },
    {
      element: "#total-assets",
      popover: {
        title: "Fiat Wallets Overview 🗂",
        description:
          "A fiat wallet is a digital wallet that stores your government-issued currency e,g Naira, Cedis, Shillings. To carry out transactions on Bitmama like Buy Crypto, you first have to fund your Naira Wallet and then exchange it with any Cryptocurrency like USDT",
      },
    },
    {
      element: "#buy-feature",
      popover: {
        title: "Buy Crypto 💵",
        description: "Buy any Cryptocurrency like Bitcoin,Ethereum directly at a good rate into your crypto wallets",
      },
    },
    {
      element: "#sell-feature",
      popover: {
        title: "Sell Crypto 💵",
        description:
          "Ready to sell your crypto? With our Sell feature, you can easily convert your BTC, ETH, or other crypto back into fiat currency like Naira. Just select the cryptocurrency you want to sell and the amount, and the rest is taken care of automatically!",
      },
    },
    {
      element: "#vcc-feature",
      popover: {
        title: "Crypto Cards 💳",
        description:
          "Our virtual cards are a convenient way to make online purchases like shopping and paying for Ads. With Bitmama, you can easily create virtual cards that work just like a regular debit card except this time, instead of Naira, you fund your card with stable-coins like USDT, USDC or cUSD. You can use them to pay for services online, without having to worry about security or fraud.",
      },
    },
    {
      element: "#greenbox-feature",
      popover: {
        title: "Greenbox 🎁",
        description:
          "Send crypto your way!  Bitmama GreenBox lets you gift USDT, cUSD & USDC instantly using a code or link. It's the perfect way to share crypto for any occasion.",
      },
    },
    {
      element: "#airtime-feature",
      popover: {
        title: "Data and Airtime 📱",
        description:
          "Purchase data and airtime on your bitmama app. Select your preferred network provider and the amount your want to purchase. Then, confirm the transaction and the Data or Airtime will be added to your phone instantly",
      },
    },
    {
      element: "#staking-feature",
      popover: {
        title: "Earn 💹",
        description:
          "Don't Let Your Crypto Gather Dust. Turn your idle holdings into passive income. Invest your crypto on Bitmama and watch them grow over time.",
      },
    },
    {
      element: "#pricealert-feature",
      popover: {
        title: "Crypto Price Alert 🚨",
        description: "Stay on top of the market and make informed trading decisions with Bitmama Crypto Price Alerts!",
      },
    },
    // { element: ".top-nav", popover: { title: "Title", description: "Description" } },
    // { element: ".sidebar", popover: { title: "Title", description: "Description" } },
    // { element: ".footer", popover: { title: "Title", description: "Description" } },
  ];

  const driver_instance = driver({
    showProgress: true,
    nextBtnText: "Next",
    prevBtnText: "Previous",
    // onPrevClick: (_, step, opts) => {
    //   console.log("Previous Clicked", { step, opts });
    // },
    steps,
    disableActiveInteraction: true,
    allowClose: false,
  });

  return (
    <TourGuideContext.Provider value={{ ...driver_instance, start: open("welcome_note") }}>
      {children}

      <WelcomeNoteModal isOpen={isOpen("welcome_note")} onClose={close("welcome_note")} />
    </TourGuideContext.Provider>
  );
}

export function useTourGuide() {
  const context = useContext(TourGuideContext);
  if (!context) throw new Error("useTourGuide should be used within the TourGuideContext.Provider!");
  return context;
}

interface WelcomeNoteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function WelcomeNoteModal(props: WelcomeNoteModalProps) {
  const { isOpen, onClose } = props;
  const { profile } = useSelector(selectUser);
  const { drive } = useTourGuide();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onClose();
    drive();
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "600px",
        borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        position: { base: "absolute", "1sm": "initial" },
        padding: "0",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
      }}
    >
      <VStack as="form" p="48px 68px" onSubmit={handleSubmit}>
        <Avatar src={AvatarSrc} boxSize="96px" bg="transparent" />
        <TitleBar>
          <Title fontSize="24px" fontFamily="var(--bitmama-fonts-heading)">
            Welcome to Bitmama, {capitalize(profile?.firstName)}
          </Title>
        </TitleBar>

        <Text textAlign="center" fontWeight="500">
          We know you're new to crypto or just getting started, but don't worry, we're here to guide you every step of the way.
          Let’s show you around the Bitmama App
        </Text>

        <VStack mt="48px !important">
          <Button type="submit" fontFamily="var(--bitmama-fonts-heading)" fontWeight="600">
            Let's go
          </Button>
          <Button variant="transparent" fontWeight="500" fontSize="14px" textDecoration="underline" onClick={onClose}>
            No Thanks
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
