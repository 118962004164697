import { useMemo } from "react";
import { Box, BoxProps, Image, Text } from "@chakra-ui/react";
import Icon from "./Icon";
import { when } from "utils";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import { ReactComponent as LockLocked } from "assets/svg/lock_locked.svg";
import { useColor } from "hooks";

const gamification_badges = ["newBitStar"] as const;

export type GamificationBadgesType = (typeof gamification_badges)[number];

interface BadgeIconProps extends BoxProps {
  badge: GamificationBadgesType;
  locked?: boolean;
  use_lock_icon?: boolean;
  use_image?: boolean;
  imgUrl?: string;
}

export default function BadgeIcon(props: BadgeIconProps) {
  const { locked = false, use_lock_icon = false, badge, boxSize = "68px", imgUrl, use_image } = props;

  const color = useColor();
  const iconType = useMemo(() => `${badge}_level_1`, [badge]);

  return (
    <Box
      minW={boxSize}
      minH={boxSize}
      boxSize={boxSize}
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      pos="relative"
      // mixBlendMode={when(!!locked, "luminosity", "normal")}
    >
      {!use_image && (
        <Icon
          minW={boxSize}
          minH={boxSize}
          boxSize={boxSize}
          type={iconType as any}
          filter={when(!!locked, "grayscale(1)", undefined)}
          // mixBlendMode={when(!!locked, "luminosity", "normal")}
        />
      )}

      {!!use_image && !!imgUrl && <Image src={imgUrl} boxSize={boxSize} filter={when(!!locked, "grayscale(1)", undefined)} />}

      <ConditionalRender shouldRender={locked}>
        <Box
          w="100%"
          h="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          boxSize="100%"
          clipPath="polygon(50% 1%, 92% 25%, 92% 75%, 50% 99%, 7% 75%, 8% 25%)"
          bg="#ffffffbd"
        >
          {!use_lock_icon && (
            <Text fontWeight="700" fontSize="xs" color={color("black", "black")}>
              Locked
            </Text>
          )}

          {!!use_lock_icon && <Box boxSize="50%" as={LockLocked} />}
        </Box>
      </ConditionalRender>
    </Box>
  );
}
