import { BoxProps, Heading, HeadingProps, HStack, PinInputProps, Text, TextProps, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { Link, PinInput } from "components";
import { useSelector } from "hooks";
import { useMemo } from "react";
import { selectUser } from "store/slices";

interface PinViewProps extends BoxProps {
  _pin?: Omit<PinInputProps, "children">;
  onPin?: (pin: string) => void;
  _infoContainer?: BoxProps;
  _heading?: HeadingProps;
  _text?: TextProps;

  heading?: string;
  text?: string;
  showCreatePin?: boolean;
  on_close?: () => void;
  on_complete?: (pin: string) => void;
}

export default function PinView(props: PinViewProps) {
  const {
    _pin,
    text,
    heading,
    onPin,
    _infoContainer,
    _heading,
    _text,
    showCreatePin = false,
    on_close,
    on_complete,
    ...xprops
  } = props;
  const { profile } = useSelector(selectUser);

  const canCreatePin = useMemo(
    () => (!!profile && !profile?.hasPin) || (profile?.hasPin && showCreatePin),
    [profile, showCreatePin]
  );

  return (
    <VStack {...xprops}>
      <VStack maxW={{ base: "230", "1sm": "320px" }} m="0 auto" mb={{ base: "20px", "2sm": "40px" }} {..._infoContainer}>
        <Heading as={"h6" as any} fontSize={{ base: "18px", "2sm": "24px" }} {..._heading}>
          {heading ?? "Authorize Transaction"}
        </Heading>
        <Text fontSize={{ base: "12px", "2sm": "16px" }} textAlign="center" mt="20px" maxW="240px" fontWeight="500" {..._text}>
          {text ?? "Enter your 6-digits transaction pin to authorize this transaction"}
        </Text>
      </VStack>

      <PinInput {..._pin} onChange={(pin) => onPin && onPin(pin)} onComplete={on_complete} _container={{ mb: "32px !important" }} />

      {canCreatePin && (
        <VStack>
          <HStack fontWeight="500">
            <Text>Don’t have pin?</Text>
            <Link
              type="button"
              // to={"/account/profile/pin/#reset"}
              color="secondary.500"
              fontWeight="600"
              onClick={(e) => {
                e.preventDefault();
                !!on_close && on_close();
                console.log("Should have closed the damn modal", on_close);
                navigate("/account/profile/pin/#reset");
              }}
            >
              Create New
            </Link>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}
