import { Box, Text } from "@chakra-ui/react";
import { Group, MainLayoutContainer, Steps, Title, TitleBar } from "components";
import { SidePageViewProps } from "contexts";
import PlayToEarnOverview from "./Overview";
import { usePartialState } from "hooks";
import PlayToEarnWithdraw from "./Withdraw";
import PlayToEarnFund from "./Fund";

interface PlayToEarnViewProps extends SidePageViewProps {}

export interface IPlayToEarnState {
  coin: string;
}

export default function PlayToEarnView(props: PlayToEarnViewProps) {
  const [state, set] = usePartialState<IPlayToEarnState>({ coin: "usdt" });

  return (
    <Box px={{ base: "20px", "2sm": "32px" }} {...props} overflowY="scroll">
      <Box>
        <TitleBar>
          <Title fontSize="20px">Play To Earn</Title>
        </TitleBar>

        <Text fontWeight={500} fontSize="md">
          Earn money by playing games{" "}
        </Text>
      </Box>

      <MainLayoutContainer maxW="xl" mt="28px" px={{ base: "0", "2sm": "10px" }}>
        <Group mt="0">
          <Steps hideIndicator>
            <PlayToEarnOverview {...{ state, set }} />
            <PlayToEarnWithdraw {...{ state, set }} />
            <PlayToEarnFund {...{ state, set }} />
          </Steps>
        </Group>
      </MainLayoutContainer>
    </Box>
  );
}

PlayToEarnView.displayName = "SidePageView";
