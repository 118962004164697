import { Box, Button, FormControl, FormHelperText, Stack, useColorModeValue, useUpdateEffect, VStack } from "@chakra-ui/react";
import { useFundCardMutation, useGetCardConfigQuery, useGetCardWishlistEligibilityQuery } from "apis";
import { AmountInput, FormInfo, InputLabel, Select, Title, TitleBar, useModalView as useSteps } from "components";
import configs from "config";
import { useSidePage } from "contexts";
import { useDisclosures, usePartialState, useWalletBalance } from "hooks";
import { AppAnalyticEvent } from "interfaces";
import { Emitter } from "libs";
import { useEffect, useMemo } from "react";
import { SuccessModal } from "ui";
import CardWithdrawalNotice from "ui/Cards/Modals/CardWithdrawalNotice";
import SubmitPin from "ui/Common/Modals/SubmitPin";
import WishlistModal from "ui/Common/Modals/Wishlist";
import { currencyFormat, toCoinLocale, toPrecision, when } from "utils";

interface FundCreditCardProps {
  wishlist_for?: "christmas" | "valentine";
}

interface IState {
  wallet: string;
  amount: string;
}

type ModalTypes = "pin" | "success" | "withdrawal-notice" | "wishlist";

export default function FundCreditCard(props: FundCreditCardProps) {
  const { wishlist_for = "valentine" } = props;
  const { params, updateSidePageConfigs } = useSidePage();

  console.log("provider number", params);

  const { data: config } = useGetCardConfigQuery();
  const { data: wishlist } = useGetCardWishlistEligibilityQuery("fund");

  console.log("Wishlist", wishlist);
  console.log("Card Config", config);

  const minimumAmount = useMemo(() => config?.funding?.min ?? 0, [config]);
  const maximumFundingAmount = useMemo(() => config?.funding?.max ?? 0, [config]);
  const fundFee = 2;

  // const { shadow, borderColor } = useDefaultStyle();
  const infoColor = useColorModeValue("grey.500", "grey.150");

  const { setCurrentView, hasPrev } = useSteps();
  const [state, set] = usePartialState<IState>({});
  const { isOpen, open, close } = useDisclosures<ModalTypes>();

  const { getBalance } = useWalletBalance();
  const [fundCard, { data, isLoading: isFunding, isSuccess }] = useFundCardMutation();

  const balance = useMemo(() => (!!state?.wallet ? (getBalance(state?.wallet as any) as number) : 0), [state?.wallet, getBalance]);
  const isInsufficientBalance = useMemo(() => !!state?.amount && +(state?.amount ?? 0) > balance, [state?.amount, balance]);

  const isSmallerThanMinimum = useMemo(
    () => !!state?.wallet && !!state?.amount && +(state?.amount ?? 0) < minimumAmount,
    [state, minimumAmount]
  );

  const isMoreThanMaximum = useMemo(
    () => !!state?.wallet && !!state?.amount && +(state?.amount ?? 0) > maximumFundingAmount,
    [state, maximumFundingAmount]
  );

  const calcfundingFee = useMemo(() => (!!state?.amount ? +state?.amount * (fundFee / 100) : 1), [state?.amount]);
  const fundingFee = useMemo(() => Math.max(1, calcfundingFee), [calcfundingFee]);

  const isDisabled = useMemo(
    () => !(state?.amount && state?.wallet) || isFunding || isInsufficientBalance || isSmallerThanMinimum || isMoreThanMaximum,
    [state, isFunding, isInsufficientBalance, isSmallerThanMinimum, isMoreThanMaximum]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    open("pin")();
  };

  const handleFundCard = async (pin: string) => {
    close("pin")();
    const reqData = { id: params?.cardId, coin: state?.wallet, amount: state?.amount, pin };
    Emitter.emit(AppAnalyticEvent.CARD_FUNDING_INITIATED, reqData);
    const result = await fundCard(reqData).unwrap();
    if (!!result) {
      Emitter.emit(AppAnalyticEvent.CARD_FUNDING_SUBMITTED, reqData);
    }
  };

  const handleContinue = () => {
    // if (!!wishlist && !!wishlist?.eligible && isTesting) open("wishlist")();
    // else if (!!wishlist && !!wishlist?.eligible && !wishlist?.applied && !isTesting) open("wishlist")();

    close("success")();
    setCurrentView(0);
  };

  useUpdateEffect(() => {
    if (isSuccess && !!data) {
      // open("success")();
      if (!!wishlist && !!wishlist?.eligible && !wishlist?.applied) open("wishlist")();
      else open("success")();
    }
  }, [isSuccess, data]);

  useEffect(() => {
    // open("withdrawal-notice")();
    if (hasPrev) updateSidePageConfigs({ _back: { text: "Back", onClick: () => setCurrentView(0) } });
    // eslint-disable-next-line
  }, []);

  return (
    <Box id="virtual-card-details-wrapper" px="40px" {...props} overflowY="scroll">
      <TitleBar>
        <Title fontSize="20px">Cards</Title>
      </TitleBar>

      {/* <HStack mt="24px">
        <Button
          variant="unstyled"
          minW="fit-content"
          minH="fit-content"
          h="fit-content"
          _focus={{ shadow, borderColor }}
          onClick={() => setCurrentView(0)}
        >
          <Icon type="circleLeftArrow" boxSize="20px" />
        </Button>
        <Text fontSize="16px" fontFamily="var(--bitmama-fonts-heading)">
          Fund Card
        </Text>
      </HStack> */}

      <Stack as="form" my="42px" onSubmit={handleSubmit}>
        <FormControl mb="30px !important">
          <InputLabel fontSize="16px">Wallet</InputLabel>
          <Select placeholder="Select Wallet" value={state?.wallet ?? ""} onChange={(e) => set({ wallet: e.target.value })}>
            {configs.stableCoins.map((coin) => (
              <option key={`stable-${coin}-coin`} value={coin}>
                {toCoinLocale(coin)}
              </option>
            ))}
          </Select>

          <Stack mt="10px">
            {state?.wallet && (
              <FormInfo
                isInvalid={isInsufficientBalance}
                info={`Available Balance: `}
                description={toPrecision(balance, state.wallet)}
              />
            )}
            {fundingFee && state?.wallet && (
              <FormInfo
                isInvalid={isInsufficientBalance}
                info={`Funding fee(${fundFee}%):`}
                description={currencyFormat("usd").format(fundingFee)}
              />
            )}
          </Stack>
        </FormControl>

        <FormControl mb="30px !important">
          <InputLabel fontSize="16px">Amount</InputLabel>
          <AmountInput
            isRequired
            isInvalid={isInsufficientBalance || isSmallerThanMinimum || isMoreThanMaximum}
            placeholder="Enter Amount"
            value={state?.amount ?? ""}
            onMax={() => set({ amount: Number(balance).toFixed(2) })}
            onChange={(e) => set({ amount: e.target.value })}
            isMaxDisabled={!state?.wallet}
          />

          {isInsufficientBalance && state?.wallet && (
            <FormHelperText fontSize="sm" color="red.400" fontWeight="500">
              {toCoinLocale(state?.wallet)} wallet balance is insufficient
            </FormHelperText>
          )}

          {state?.wallet && (
            <FormHelperText fontSize="sm" color={when(isSmallerThanMinimum, "red.400", infoColor)} fontWeight="500">
              Minimum funding amount is {currencyFormat("usd").format(minimumAmount)}
            </FormHelperText>
          )}
          {state?.wallet && isMoreThanMaximum && (
            <FormHelperText fontSize="sm" color={when(isMoreThanMaximum, "red.400", infoColor)} fontWeight="500">
              Maximum funding amount is {currencyFormat("usd").format(maximumFundingAmount)}
            </FormHelperText>
          )}
        </FormControl>

        <VStack mt="64px !important">
          <Button maxW="400px" fontFamily="var(--bitmama-fonts-heading)" type="submit" isLoading={isFunding} disabled={isDisabled}>
            Fund
          </Button>
        </VStack>
      </Stack>

      <SubmitPin isOpen={isOpen("pin")} onClose={close("pin")} onSubmit={handleFundCard} onComplete={handleFundCard} />
      <SuccessModal
        isOpen={isOpen("success")}
        onClose={close("success")}
        onContinue={handleContinue}
        closeOnContinue={false}
        message={`Card has been successfully funded with ${toPrecision(+(state?.amount ?? 0), state?.wallet!)}`}
      />

      <CardWithdrawalNotice isOpen={isOpen("withdrawal-notice")} onClose={close("withdrawal-notice")} />

      {!!wishlist && (
        <WishlistModal
          isOpen={isOpen("wishlist")}
          wishlistId={wishlist?.wishlistId}
          onClose={() => {
            close("wishlist")();
            setCurrentView(0);
          }}
          gifts={wishlist?.gifts ?? []}
          // message={`You successfully funded your card with ${toPrecision(
          //   +(state?.amount ?? 0),
          //   state?.wallet!
          // )}. Make a wish and Santa might just grant it`}
          message={`You successfully funded your card with ${toPrecision(+(state?.amount ?? 0), state?.wallet!)}. ${when(
            wishlist_for !== "christmas",
            "Make a wish and Cupid might just grant it",
            "Make a wish and Santa might just grant it"
          )}`}
        />
      )}
    </Box>
  );
}
