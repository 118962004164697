import { navigate } from "@reach/router";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import configs from "config";
import {
  ApiResponse,
  AppStatus,
  AuthRo,
  LoginDto,
  RequestEmailCodeDto,
  RequestPhoneCodeDto,
  SendCodeDto,
  SignUpDto,
  SignUpRo,
} from "interfaces";
import { BVNDataRo, RequestResetCodeDto } from "interfaces/auth.interface";
import isEmpty from "lodash/isEmpty";
import { RootState } from "store";
import { get, getUTM, post, toQueryString, when } from "utils";
import { wrapBaseApi } from "utils/apiWrapper";

import ls from "utils/secureStorage";

export type AuthPayload = {
  token: string | null;
  isSignedIn: boolean;
  verification?: string[];
  exp: number | null;
  isVerified?: boolean;
  loginStatus?: string;
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;
  userId: string | null;
  label?: string;
};

type FailedLoginWithPayload = {
  data: {
    errorCode: string;
    token: string;
    exp?: number;
    isVerified?: boolean;
    emailVerified?: boolean;
    loginStatus?: string;
    userId: string;
    label?: string;
  };
  error: string;
  status: string;
  verification: string[];
};

type RequestCodeRo = {
  nextRequestInSecs?: number;
};

interface IState {
  verifications: string[];
  payload?: AuthPayload;

  status: "logging_in" | "signing_up" | "idle" | "error" | "success";

  checks: {
    email: { status: AppStatus; inUse: boolean };
    username: { status: AppStatus; inUse: boolean };
    phone: { status: AppStatus; inUse: boolean };
    bvn: { status: AppStatus; inUse: boolean; bvnData?: BVNDataRo };
  };

  verify: {
    phone: { status: AppStatus; isSentEarlier: boolean; send?: { status: AppStatus; payload?: AuthPayload } } & RequestCodeRo;
    email: { status: AppStatus; isSentEarlier: boolean; send?: { status: AppStatus; payload?: AuthPayload } } & RequestCodeRo;
  };

  resetPassword: {
    status: AppStatus;
    isSentEarlier: boolean;
    message?: string;
  } & RequestCodeRo;

  twoFa: {
    data: {
      qrCodeLink: "";
      secret: "";
    };
    status: AppStatus;
  };
}

const initialState: IState = {
  status: "idle",
  verifications: [],

  payload: {
    isSignedIn: false,
    token: null,
    exp: null,
    userId: null,
  },

  checks: {
    email: {
      status: "idle",
      inUse: false,
    },
    username: {
      status: "idle",
      inUse: false,
    },
    phone: {
      status: "idle",
      inUse: false,
    },
    bvn: {
      status: "idle",
      inUse: false,
    },
  },

  // 10 mins default nextRequestInSecs
  resetPassword: { status: "idle", isSentEarlier: false },

  verify: {
    phone: { status: "idle", isSentEarlier: false, send: { status: "idle" } },
    email: { status: "idle", isSentEarlier: false, send: { status: "idle" } },
  },

  twoFa: {
    status: "idle",
    data: {
      qrCodeLink: "",
      secret: "",
    },
  },
};

export const login = createAsyncThunk("auth/login", async (input: LoginDto, { rejectWithValue }) => {
  try {
    const response = await post<ApiResponse<AuthRo>, LoginDto>(wrapBaseApi(`/v2/auth/signin`), input);
    const user = response?.data;

    const we_user = (window as any)?.webengage?.user;
    try {
      if (!!user) {
        we_user.login(user?.userId);
        we_user.setAttribute("we_email", user.email);
        we_user.setAttribute("we_first_name", user.firstName);
        we_user.setAttribute("we_last_name", user.lastName);
        we_user.setAttribute("Kyc_Status", user.kycLevel);
      }
    } catch (error) {}
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (input: RequestResetCodeDto, { rejectWithValue }) => {
  try {
    const { humanKey, ...body } = input;
    const response = await post<ApiResponse<RequestCodeRo>, { email: string }>(
      wrapBaseApi(`/auth/forgotPassword?humanKey=${humanKey}`),
      {
        ...body,
      }
    );
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const signup = createAsyncThunk("auth/signup", async (input: Partial<SignUpDto>, { rejectWithValue }) => {
  try {
    const { refCode, srcMode, srcRef, ...body } = input;
    // const url_old = when(
    //   !!refCode,
    //   `/v2/auth/signup?refCode=${refCode}`,
    //   "/v2/auth/signup"
    // );

    const url = `/v2/auth/signup?${toQueryString({ refCode, src_mode: srcMode, src_ref: srcRef })}`;
    const response = await post<ApiResponse<SignUpRo>, Partial<SignUpDto>>(wrapBaseApi(url), body);
    return { ...response, data: { ...response.data, utmRef: srcRef } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const requestPhoneCode = createAsyncThunk(
  "auth/requestPhoneCode",
  async (input: RequestPhoneCodeDto, { rejectWithValue }) => {
    try {
      const { delivery, humanKey } = input;
      const deliveryBinary = when(delivery === "call", 1, delivery === "sms" ? 0 : 2);
      const url = `/auth/verify-phone?channel=${delivery}&call=${deliveryBinary}&humanKey=${humanKey}`;
      const response = await get<ApiResponse<RequestCodeRo>>(wrapBaseApi(url));
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendPhoneCode = createAsyncThunk("auth/sendPhoneCode", async (input: SendCodeDto, { rejectWithValue }) => {
  try {
    const { code, humanKey } = input;
    const url = `/v2/auth/verify-phone?humanKey=${humanKey}`;
    const response = await post<ApiResponse<RequestCodeRo>, Omit<SendCodeDto, "humanKey">>(wrapBaseApi(url), { code });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const requestEmailCode = createAsyncThunk(
  "auth/requestEmailCode",
  async (input: RequestEmailCodeDto, { rejectWithValue }) => {
    try {
      const { humanKey } = input;
      const url = `/auth/verify-email?humanKey=${humanKey}`;
      const response = await get<ApiResponse<RequestCodeRo>>(wrapBaseApi(url));
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendEmailCode = createAsyncThunk("auth/sendEmailCode", async (input: SendCodeDto, { rejectWithValue }) => {
  try {
    const { code, humanKey } = input;
    const url = `/v2/auth/verify-email?humanKey=${humanKey}`;
    const response = await post<ApiResponse<RequestCodeRo>, Omit<SendCodeDto, "humanKey">>(wrapBaseApi(url), { code });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const checkEmail = createAsyncThunk("auth/checkEmail", async (email: string, _) => {
  const response = await get<any>(wrapBaseApi(`/auth/check?email=${email}`));
  return response.data;
});

export const checkUsername = createAsyncThunk("auth/checkUsername", async (username: string, _) => {
  const response = await get<any>(wrapBaseApi(`/auth/check?username=${username}`));
  return response.data;
});

export const checkBVN = createAsyncThunk("auth/checkBVN", async (bvn: string, { rejectWithValue }) => {
  try {
    const response = await post<any, any>(wrapBaseApi(`/banks/bvn/resolve`), { bvn });
    console.log("BVN Check response", response.data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const checkPhone = createAsyncThunk("auth/checkPhone", async (phone: string, _) => {
  const response = await get<any>(wrapBaseApi(`/auth/check?phone=${phone}`));
  return response.data;
});

export const generate2FA = createAsyncThunk("auth/twoFa", async (_, { rejectWithValue }) => {
  try {
    const response = await post<ApiResponse<any>, any>("/auth/2fa/generate", {});
    return response.data;
  } catch (error) {
    rejectWithValue(error);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    rehydrate: (state) => {
      const auth = ls.get(configs.AUTH_TOKEN_KEY) as AuthPayload;
      state.payload = auth;
    },
    dehydrate: (state) => {
      ls.remove(configs.AUTH_TOKEN_KEY);
      return { ...initialState };
    },

    reset: (state) => {
      state.status = "idle";
      state.checks = initialState.checks;
      state.payload = initialState.payload;
    },
    resetPhoneRequestTime: (state) => {
      state.verify.phone.nextRequestInSecs = undefined;
    },

    updateNextRequestTime: (
      state,
      action: PayloadAction<{ for: "resetPassword" | "verifyPhone" | "verifyEmail"; count: number }>
    ) => {
      const { for: _for, count } = action.payload;
      if (_for === "resetPassword") state.resetPassword.nextRequestInSecs = count;
      if (_for === "verifyEmail") state.verify.email.nextRequestInSecs = count;
      if (_for === "verifyPhone") state.verify.phone.nextRequestInSecs = count;
    },
  },
  extraReducers: (builder) => {
    ////////////////////
    /// Forgot/Reset Password
    builder.addCase(forgotPassword.pending, (state) => {
      state.resetPassword.status = "loading";
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.resetPassword.status = "success";
      const { data, message } = action.payload;

      state.resetPassword.message = message;
      state.resetPassword.nextRequestInSecs = data?.nextRequestInSecs;

      console.log("Reset password payload", action.payload);
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.resetPassword.status = "error";
      console.log("Reset password error", action);
    });

    ////////////////////
    /// Login
    builder.addCase(login.pending, (state) => {
      state.status = "logging_in";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.status = "success";

      console.log(action, "action payload");
      //hydrate auth
      state.payload = {
        userId: action?.payload?.data?.userId ?? null,
        token: action?.payload?.data?.token ?? null,
        exp: action?.payload?.data?.exp ?? null,
        isSignedIn: (!!action?.payload?.data?.token && +action.payload?.data?.exp > new Date().getTime()) ?? false,
        verification: action?.payload?.data?.verification ?? [],
        label: action?.payload?.data?.label,
      };

      if (action.payload.message === "2Fa is required" || ["2FA_REQUIRED"].includes(action.payload.data?.label ?? "")) {
        navigate(configs.paths.verifyTwoFa);
      }

      if (["BVN_PROFILE_REQUIRED"].includes(action.payload.data?.label ?? "")) {
        state.payload.isSignedIn = false;
        navigate(configs.paths.captureBVN);
      }

      ls.set(configs.AUTH_TOKEN_KEY, state.payload);
    });
    builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
      state.status = "error";
      console.log("Rejected Error", action);
      const payload = action?.payload as FailedLoginWithPayload;
      const token = payload?.data?.token;
      const verification = payload?.verification;

      // if token and verification exists, means email, phone or both are not verified
      if (!!token && !isEmpty(verification ?? [])) {
        //hydrate partial auth
        state.payload = {
          token: token ?? null,
          // TODO: ask Rasheed to send the exp with the failed login data
          exp: null,
          isSignedIn: false,
          loginStatus: "pending",
          isVerified: false,
          isEmailVerified: false,
          verification: verification ?? [],
          userId: null,
          label: payload?.data?.label,
        };
        console.log("FAILED LOGIN PAYLOAD", state.payload);
        ls.set(configs.AUTH_TOKEN_KEY, state.payload);
      }
    });

    ////////////////////
    /// Signup
    builder.addCase(signup.pending, (state) => {
      state.status = "signing_up";
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.status = "success";

      const { data, verification } = action.payload;

      //hydrate partial auth
      state.payload = {
        token: data?.token ?? null,
        exp: data?.exp ?? null,
        isSignedIn: false,
        loginStatus: data?.loginStatus,
        isVerified: data?.isVerified,
        isEmailVerified: data?.emailVerified,
        verification: verification ?? [],
        userId: data?.userId ?? null,
      };
      console.log("SIGNUP PAYLOAD", { data, verification });

      const utm = getUTM();
      if (!!utm && data?.utmRef && !!utm?.src_ref && utm.src_ref === data.utmRef) {
        ls.remove(configs.UTM_KEY);
      }

      ls.set(configs.AUTH_TOKEN_KEY, state.payload);
    });

    builder.addCase(signup.rejected, (state, action) => {
      state.status = "error";
      console.log("Rejected Error", action);
    });

    ////////////////////
    /// Request Phone Code
    builder.addCase(requestPhoneCode.pending, (state) => {
      state.verify.phone = {
        status: "loading",
        isSentEarlier: true,
        // nextRequestInSecs: 600, // 10 minutes by default
      };
    });
    builder.addCase(requestPhoneCode.fulfilled, (state, action) => {
      const { data } = action?.payload;
      state.verify.phone = {
        status: "success",
        nextRequestInSecs: data?.nextRequestInSecs,
        // isSentEarlier: toLower(message).includes("already been processed"),
        isSentEarlier: true,
      };

      console.clear();
      console.log("Request Phone Code Payload", action?.payload, state.verify.phone);
    });
    builder.addCase(requestPhoneCode.rejected, (state, action) => {
      state.verify.phone.status = "error";
    });

    ////////////////////
    /// Send Phone Code
    builder.addCase(sendPhoneCode.pending, (state) => {
      state.verify.phone = {
        ...state?.verify?.phone,
        send: {
          status: "loading",
        },
      };
    });
    builder.addCase(sendPhoneCode.fulfilled, (state, action) => {
      const {
        data: { token, loginStatus, exp, isVerified, emailVerified, userId },
        verification,
      } = action?.payload as FailedLoginWithPayload;

      state.payload = {
        token: token ?? null,
        exp: exp ?? null,
        isSignedIn: loginStatus !== "pending",
        loginStatus: loginStatus,
        isVerified: isVerified,
        isEmailVerified: emailVerified,
        verification: verification ?? [],
        userId: userId ?? null,
      };

      state.verify.phone = {
        ...state?.verify?.phone,
        send: {
          status: "success",
          payload: state?.payload,
        },
      };

      ls.set(configs.AUTH_TOKEN_KEY, state.payload);

      console.clear();
      console.log("Send Phone Code Payload", state.verify.phone);
    });
    builder.addCase(sendPhoneCode.rejected, (state, action) => {
      state.verify.phone = {
        ...state?.verify?.phone,
        send: {
          status: "error",
        },
      };
    });

    ////////////////////
    /// Request Email Code
    builder.addCase(requestEmailCode.pending, (state) => {
      state.verify.email = {
        status: "loading",
        isSentEarlier: true,
      };
    });
    builder.addCase(requestEmailCode.fulfilled, (state, action) => {
      const { data } = action?.payload;
      state.verify.email = {
        status: "success",
        nextRequestInSecs: data?.nextRequestInSecs,
        // isSentEarlier: toLower(message).includes("already been processed"),
        isSentEarlier: true,
      };

      console.clear();
      console.log("Request Email Code Payload", action);
    });
    builder.addCase(requestEmailCode.rejected, (state, action) => {
      state.verify.email.status = "error";
    });

    ////////////////////
    /// Send Email Code
    builder.addCase(sendEmailCode.pending, (state) => {
      state.verify.email = {
        ...state?.verify?.email,
        send: {
          status: "loading",
        },
      };
    });
    builder.addCase(sendEmailCode.fulfilled, (state, action) => {
      const {
        data: { token, loginStatus, exp, isVerified, emailVerified, userId },
        verification,
      } = action?.payload as FailedLoginWithPayload;

      state.payload = {
        token: token ?? null,
        exp: exp ?? null,
        isSignedIn: loginStatus !== "pending",
        loginStatus: loginStatus,
        isVerified: isVerified,
        isEmailVerified: emailVerified,
        verification: verification ?? [],
        userId: userId ?? null,
      };

      state.verify.email = {
        ...state?.verify?.email,
        send: {
          status: "success",
          payload: state?.payload,
        },
      };

      ls.set(configs.AUTH_TOKEN_KEY, state.payload);

      console.clear();
      console.log("Send Email Code Payload", state.verify.email);
    });
    builder.addCase(sendEmailCode.rejected, (state, action) => {
      state.verify.email = {
        ...state?.verify?.email,
        send: {
          status: "error",
        },
      };
    });

    ////////////////////
    /// Check Email
    builder.addCase(checkEmail.pending, (state, action) => {
      state.checks.email.status = "fetching";
    });
    builder.addCase(checkEmail.fulfilled, (state, { payload }) => {
      state.checks.email.status = "success";
      state.checks.email.inUse = false;
    });
    builder.addCase(checkEmail.rejected, (state, action) => {
      state.checks.email.status = "error";
      state.checks.email.inUse = true;
    });

    ////////////////////
    /// Check Username
    builder.addCase(checkUsername.pending, (state, action) => {
      state.checks.username.status = "fetching";
    });
    builder.addCase(checkUsername.fulfilled, (state, { payload }) => {
      state.checks.username.status = "success";
      state.checks.username.inUse = false;
    });
    builder.addCase(checkUsername.rejected, (state, action) => {
      state.checks.username.status = "error";
      state.checks.username.inUse = true;
    });

    ////////////////////
    /// Check BVN
    builder.addCase(checkBVN.pending, (state, action) => {
      state.checks.bvn.status = "fetching";
      state.checks.bvn.inUse = false;
      state.checks.bvn.bvnData = undefined;
    });
    builder.addCase(checkBVN.fulfilled, (state, { payload }) => {
      state.checks.bvn.status = "success";
      // inUse here means we were unable to retrieve the bvn data
      state.checks.bvn.inUse = !payload?.bvnData;
      state.checks.bvn.bvnData = payload?.bvnData;
    });
    builder.addCase(checkBVN.rejected, (state, action) => {
      state.checks.bvn.status = "error";
      state.checks.bvn.inUse = true;
    });

    ////////////////////
    /// Check Phone
    builder.addCase(checkPhone.pending, (state, action) => {
      state.checks.phone.status = "fetching";
    });
    builder.addCase(checkPhone.fulfilled, (state, { payload }) => {
      state.checks.phone.status = "success";
      state.checks.phone.inUse = false;
    });
    builder.addCase(checkPhone.rejected, (state, action) => {
      state.checks.phone.status = "error";
      state.checks.phone.inUse = true;
    });

    /// Generate 2FA Qr Code
    builder.addCase(generate2FA.pending, (state) => {
      state.twoFa.status = "fetching";
    });
    builder.addCase(generate2FA.fulfilled, (state, { payload }) => {
      state.twoFa.status = "success";
      state.twoFa.data = payload;
    });
    builder.addCase(generate2FA.rejected, (state) => {
      state.twoFa.status = "error";
    });
  },
});

const { actions, reducer: AuthReducer } = authSlice;
export const selectAuth = (state: RootState) => state.auth;
export const { dehydrate, rehydrate, reset: resetAuth, resetPhoneRequestTime, updateNextRequestTime } = actions;
export default AuthReducer;
