import { Box, TabList, TabPanel, Tabs } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Group, MainLayoutContainer, Tab, TabPanels, Title, TitleBar } from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { useState } from "react";

import NewSwap from "../Panels/New";
import SwapTransactions from "../Panels/SwapTransaction";

interface SwapTabsProps {}

interface SwapViewProps extends SidePageViewProps {}

// interface SwapProps {}

export default function SwapView(props: SwapViewProps) {
  return (
    <Box px={{ base: "10px", "2sm": "32px" }} {...props} overflowY="scroll">
      <TitleBar>
        <Title pl={{ base: "20px", "2sm": "0" }} fontSize="20px">
          Swap
        </Title>
      </TitleBar>

      <MainLayoutContainer maxW="2xl" mt="28px" padding={{ base: "0", "2sm": "20px" }}>
        <Group mt="0">
          <SwapTabs />
        </Group>
      </MainLayoutContainer>
    </Box>
  );
}

function SwapTabs(props: SwapTabsProps) {
  const { key } = useSidePage();
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    buy: 0,
    transactions: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("subpage")?.split(":")[1] as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("subpage", `${key}:${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange}>
      <TabList borderRadius="8px" justifyContent="space-between">
        <Tab>New</Tab>
        <Tab>History</Tab>
      </TabList>
      <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
        <TabPanel px={["2", "0", "0", "1"]}>
          <NewSwap />
        </TabPanel>
        <TabPanel px={["0", "0", "0", "1"]}>
          <SwapTransactions subjectOf="sell_credit" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

SwapView.displayName = "SidePageView";
