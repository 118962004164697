import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  IconButton,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useTurnOnAndOff2FAMutation, useVerify2FAPinMutation } from "apis";
import { DownloadOnAppleStoreSVG, DownloadOnGooglePlaySVG, Enable2FASVG } from "assets";
import {
  Card,
  ConditionalRender,
  CopyButton,
  Group,
  Icon,
  InputLabel,
  Logo,
  ShowCustomerSupport,
  Steps,
  useModalView,
} from "components";
import Input from "components/Input/Input";
import configs from "config";
import { useCopy, useDefaultStyle, useDispatch, usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getProfile, selectAuth, selectUser } from "store/slices";
import { generate2FA } from "store/slices/auth";

import { AuthImage, SuccessModal, AuthProgress } from "ui";
import Forgot2FA from "./Forgot2FA";
import { useRegistrationProgress } from "contexts";
import { IntercomProvider } from "react-use-intercom";

// import { isSafeRedirect } from "utils/urls";

interface Enable2FAProps extends PageProps {}

interface IState {
  isOn: boolean;
}

export function Enable2FA(props: Enable2FAProps) {
  const logoColor = useColorModeValue("primary.default", "secondary.500");
  const { setProgress } = useRegistrationProgress();

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const [isMobile] = useMediaQuery("(max-width: 1023px)");

  useEffect(() => {
    setProgress(4);
    // eslint-disable-next-line
  }, []);

  return (
    <IntercomProvider appId={configs.INTERCOM_TOKEN}>
      <Box
        minH="100vh"
        pos="relative"
        sx={{
          "--w": isLargerThan1080 ? "580px" : "480px",
        }}
      >
        <AuthImage isMobile={isMobile} />

        <Box minH="100vh" pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
          <AuthProgress />
          <Group maxWidth="523px" margin="auto" padding={{ sm: "32px 20px 43px", "2sm": "80px 20px 43px" }}>
            <Logo fontSize="54px" /*mb={{ sm: "12px", "2sm": "52px" }}*/ _name={{ w: "110px" }} color={logoColor} />

            <Steps hideIndicator>
              <Index />
              <Toggle />
              <LinkTwoFA />
              <EnterOTP />
            </Steps>
          </Group>
        </Box>
      </Box>

      <ShowCustomerSupport />
    </IntercomProvider>
  );
}

export function Index(props: Enable2FAProps) {
  const { onNext } = useModalView();
  const hColor = useColorModeValue("#303131", "white");
  const pColor = useColorModeValue("grey.500", "grey.150");

  const { profile } = useSelector(selectUser);
  const twoFaStatus = useMemo(() => profile?.twoFactor, [profile]);

  useEffect(() => {
    if (!!twoFaStatus) {
      onNext();
    }
  }, [twoFaStatus, onNext]);

  return (
    <>
      <Box mb="26px !important">
        <HStack w="100%" justifyContent="center">
          <Box as={Enable2FASVG} />
        </HStack>

        <Heading mt="60px" fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
          One last step
        </Heading>

        {/* <Text fontWeight="600" color={hColor} fontSize="16px" margin="39px 0 21px">
              Enter OTP
            </Text> */}

        <Text mt="20px" color={pColor} fontSize="16px">
          Activate the Two-Factor Authentication security system to safeguard yourself from malicious action from unauthorized
          personnel. To use 2FA, you need to have the Google Authenticator App on your device.
        </Text>
      </Box>

      <VStack mt="70px !important" mb="66px !important">
        <Button
          minW="initial"
          maxW="399px"
          width="100%"
          onClick={onNext}
          //   isLoading={isLoading}
          //   disabled={isDisabled}
        >
          Activate 2FA
        </Button>

        <Button
          mt="40px !important"
          w="fit-content"
          variant="link"
          minW="initial"
          maxW="fit-content"
          onClick={() => navigate(configs.paths.dashboard, { replace: true })}
          //   isLoading={isLoading}
          //   disabled={isDisabled}
        >
          Skip for later
        </Button>
      </VStack>
    </>
  );
}

function Toggle(props: Enable2FAProps) {
  const toast = useToast();
  const { profile } = useSelector(selectUser);

  const { onNext, onPrev, setCurrentView } = useModalView();

  const twoFaStatus = useMemo(() => profile?.twoFactor, [profile]);

  const dispatch = useDispatch();
  const { shadow, borderColor } = useDefaultStyle();

  const tColor = useColorModeValue("grey.400", "grey.100");
  const dColor = useColorModeValue("grey.500", "grey.150");
  const hColor = useColorModeValue("#303131", "white");
  const noteColor = useColorModeValue("primary.800", "secondary.600");

  const [state, set] = usePartialState<IState>({
    isOn: twoFaStatus,
  });

  const isDisabled = useMemo(() => !state?.isOn, [state]);

  const handle2FA = () => {
    if (twoFaStatus) return setCurrentView(3);
    dispatch(generate2FA());
    onNext();
  };

  const [_turnOff] = useTurnOnAndOff2FAMutation();

  const handleTurnOff2FA = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    const response: any = twoFaStatus && (await _turnOff("turnOffTwoFa"));

    if (response?.data === null) {
      dispatch(getProfile());
      //   setCurrentView(2);

      toast({
        description: "You have successfully disabled 2FA Authentication",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
    }

    set({ isOn: checked });
  };

  return (
    <Box p="6px">
      <Box mb="26px !important">
        <Heading mb={{ sm: "12px", "2sm": "52px" }} fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
          Set-up 2FA
        </Heading>

        <HStack>
          <IconButton
            size="xs"
            minW="fit-content"
            maxW="fit-content"
            minH="fit-content"
            maxH="fit-content"
            p="0 !important"
            bg="transparent"
            variant="transparent"
            aria-label="back button"
            icon={<Icon boxSize="20px" type="circleLeftArrow" />}
            onClick={onPrev}
          />

          <Heading as="h6" fontSize="16px">
            Two-factor Authentication
          </Heading>
        </HStack>
      </Box>

      <Box>
        <Text fontSize="14px" fontWeight="500" color={tColor} mt="24px" mb="20px">
          Turn on 2FA validation for your account. This is recommended so as to keep your account safe.
        </Text>
        <Text fontSize="14px" fontWeight="500" color={noteColor} mt="0px" mb="46px">
          <strong>Note:</strong> Before you turn this on, download the Google Authenticator so that you can get the codes for
          whenever you login to the App
        </Text>
      </Box>

      <FormControl as={HStack} w="100%" alignItems="center" justifyContent="space-between" my="24px !important">
        <InputLabel fontSize="md" htmlFor="2fa" _text={{ mb: 0, fontWeight: "600" }}>
          Google Authenticator
        </InputLabel>
        <Switch
          id="2fa"
          size="sm"
          colorScheme="secondary"
          defaultChecked={twoFaStatus}
          isChecked={state.isOn}
          onChange={handleTurnOff2FA}
        />
      </FormControl>
      <Text fontSize="sm" maxW="246px" fontWeight="500" color={tColor}>
        Protect your Account and Transactions with Google Authenticator
      </Text>

      {state?.isOn && (
        <VStack mt="68px">
          <Card p="20px" borderRadius="20px" bg="#FAF3D45f" shadow="none" maxW="380px" textAlign="center">
            <Heading as="h5" fontSize="md">
              Download Google Authenticator App
            </Heading>

            <Text mt="20px" textAlign="center" fontSize="14px" fontWeight="500" color={dColor}>
              Download the Authenticator App below from your App Store or Google Play Store, and click the continue button when
              you’re done.
            </Text>

            <HStack justifyContent="center" mt="36px">
              <a href="https://play.google.com/store/apps/details?id=com.bitmama.bitmama" target="_blank" rel="noopener noreferrer">
                <Box as="button" borderRadius="10px" _focus={{ shadow, borderColor }}>
                  <Box as={DownloadOnGooglePlaySVG} />
                </Box>
              </a>

              <a href="https://apps.apple.com/us/app/bitmama/id1561857024" target="_blank" rel="noopener noreferrer">
                <Box as="button" borderRadius="10px" _focus={{ shadow, borderColor }}>
                  <Box as={DownloadOnAppleStoreSVG} />
                </Box>
              </a>
            </HStack>
          </Card>
        </VStack>
      )}

      <VStack my="100px">
        <Button
          fontFamily="var(--bitmama-fonts-heading)"
          disabled={isDisabled}
          onClick={handle2FA}
          minW={{ base: "100%", "4sm": "400px" }}
        >
          Continue
        </Button>

        <ConditionalRender shouldRender={!twoFaStatus}>
          <Button
            mt="40px !important"
            w="fit-content"
            variant="link"
            minW="initial"
            maxW="fit-content"
            onClick={() => navigate(configs.paths.dashboard, { replace: true })}
          >
            Skip for later
          </Button>
        </ConditionalRender>
      </VStack>
    </Box>
  );
}

function LinkTwoFA(props: any) {
  // const dispatch = useDispatch();
  const { twoFa } = useSelector(selectAuth);
  const { profile } = useSelector(selectUser);
  const hColor = useColorModeValue("#303131", "white");

  const { data } = twoFa ?? {};

  const { onPrev, onNext } = useModalView();

  const { onCopy } = useCopy(data?.secret ?? "");
  const tColor = useColorModeValue("white", "black");

  const twoFaStatus = useMemo(() => profile?.twoFactor, [profile]);
  const [_turnOn, { isLoading }] = useTurnOnAndOff2FAMutation();

  // const logout = useCallback(() => {
  //   dispatch(dehydrate());
  //   const win: Window = window;
  //   win.location = "/login";
  // }, [dispatch]);

  const turnOn2FA = async () => {
    await _turnOn("turnOnTwoFa");
    onNext();
    // navigate(configs.paths.verifyTwoFa);
  };

  return (
    <Box p="6px">
      <Box mb="26px !important">
        <Heading mb={{ sm: "12px", "2sm": "52px" }} fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
          Set-up 2FA
        </Heading>

        <HStack>
          <IconButton
            size="xs"
            minW="fit-content"
            maxW="fit-content"
            minH="fit-content"
            maxH="fit-content"
            p="0 !important"
            bg="transparent"
            variant="transparent"
            aria-label="back button"
            icon={<Icon boxSize="20px" type="circleLeftArrow" />}
            onClick={onPrev}
          />

          <Heading as="h6" fontSize="16px">
            Two-factor Authentication
          </Heading>
        </HStack>
      </Box>

      <Text fontSize="sm" fontWeight="500">
        To add your Bitmama Account, click the <strong>“+”</strong> button on the Google Authentication App and scan the{" "}
        <strong>QR code</strong> below. Or enter the <strong>code</strong> below and click <strong>“continue”</strong>
      </Text>

      <Card mt="26px" borderRadius="20px" textAlign="center" py="20px" w="100%" shadow="0px 4px 10px rgba(67, 55, 55, 0.12)">
        <VStack px="60px">
          <Text fontSize="14px" fontWeight="600" maxW="234px">
            Scan this QR Code in the Google Authenticator App
          </Text>

          <Box
            mt="34px !important"
            mb="42px !important"
            bgRepeat="no-repeat"
            bgSize="contain"
            bgImage={`url(${data.qrCodeLink})`}
            height="217px"
            width="217px"
          />

          <Text fontSize="12px" fontWeight="600" maxW="242px">
            If you are unable to scan the QR code, please enter the code below manually into the App
          </Text>

          <Box borderRadius="8px" p="12px 28px" bg="secondary.400">
            <HStack>
              <Text
                // fontSize="14px"
                fontSize={{ base: "10px", "2sm": "14px" }}
                maxWidth={{ base: "170px", "2sm": "270px" }}
                fontWeight="600"
                color={tColor}
                // maxWidth="270px"
                width="100%"
              >
                {data.secret}
              </Text>
              <CopyButton color="primary.800" onClick={onCopy} />
            </HStack>
          </Box>
        </VStack>
      </Card>

      <VStack my="100px">
        <Button
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={turnOn2FA}
          minW={{ base: "100%", "4sm": "400px" }}
        >
          Continue
        </Button>

        {/* <Button mt="40px !important" w="fit-content" variant="link" minW="initial" maxW="fit-content" onClick={logout}>
          Logout
        </Button> */}

        <ConditionalRender shouldRender={!twoFaStatus}>
          <Button
            mt="40px !important"
            w="fit-content"
            variant="link"
            minW="initial"
            maxW="fit-content"
            onClick={() => navigate(configs.paths.dashboard, { replace: true })}
          >
            Skip for later
          </Button>
        </ConditionalRender>
      </VStack>
    </Box>
  );
}

function EnterOTP(props: any) {
  const { setCurrentView, onPrev } = useModalView();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { profile } = useSelector(selectUser);
  const twoFaStatus = useMemo(() => profile?.twoFactor, [profile]);

  const hColor = useColorModeValue("#303131", "white");
  const tColor = useColorModeValue("grey.400", "grey.100");
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    setCurrentView(0);
    dispatch(getProfile());
    navigate(configs.paths.dashboard, { replace: true });
  };

  const [twoFaCode, setCode] = useState("");

  const handleCode = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCode(value);
    if (value.length >= 6) await handleFinish(value);
  };

  const [_verify2FACode, { isLoading }] = useVerify2FAPinMutation();

  const handleFinish = async (code?: string) => {
    const response: any = await _verify2FACode({
      twoFaCode: code ?? twoFaCode,
    }).unwrap();

    if (!!response) {
      onOpen();
    }
  };

  return (
    <Box p="6px">
      <Box mb="26px !important">
        <Heading mb={{ sm: "12px", "2sm": "52px" }} fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
          Set-up 2FA
        </Heading>

        <HStack>
          <IconButton
            size="xs"
            minW="fit-content"
            maxW="fit-content"
            minH="fit-content"
            maxH="fit-content"
            p="0 !important"
            bg="transparent"
            variant="transparent"
            aria-label="back button"
            icon={<Icon boxSize="20px" type="circleLeftArrow" />}
            // onClick={() => setCurrentView(1)}
            onClick={onPrev}
          />

          <Heading as="h6" fontSize="16px">
            Enter OTP
          </Heading>
        </HStack>
      </Box>

      <Text fontSize="sm" fontWeight="500">
        Enter the 6 digit OTP shown on the Google Authenticator App
      </Text>

      <FormControl mt="28px">
        <Input placeholder="Enter Google Verification Code" onChange={handleCode} />
        <FormHelperText fontSize="sm" fontWeight="500" color={tColor}>
          Enter the 6 digit code from Google Authenticator
        </FormHelperText>
      </FormControl>

      <VStack my="100px">
        <Button
          isLoading={isLoading}
          disabled={twoFaCode.length !== 6 || isLoading}
          fontFamily="var(--bitmama-fonts-heading)"
          onClick={() => handleFinish()}
          minW={{ base: "100%", "4sm": "400px" }}
          // mb="80px !important"
        >
          Authenticate
        </Button>

        <ConditionalRender shouldRender={!twoFaStatus}>
          <Button
            mt="40px !important"
            mb="80px !important"
            w="fit-content"
            variant="link"
            minW="initial"
            maxW="fit-content"
            onClick={() => navigate(configs.paths.dashboard, { replace: true })}
          >
            Skip for later
          </Button>
        </ConditionalRender>

        <Forgot2FA />
      </VStack>

      <SuccessModal
        isOpen={isOpen}
        onClose={handleClose}
        _content={{ maxW: "580px" }}
        message="You have successfully enabled Google Authenticator to protect your Account"
      />
    </Box>
  );
}
