import { Box, Stack, TabList, TabPanel, Tabs, useColorModeValue } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { useGetWithdrawalsQuery } from "apis";
import { Card, EmptyCrate, Group, PageLoading, Paginator, Tab, TabPanels, Title, TitleBar, TransactionItem } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { usePartialState } from "hooks";
import { useMemo, useState } from "react";
import { toQueryString, when } from "utils";

interface AllTransactionsProps {}

export function AllTransactions(props: AllTransactionsProps) {
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search as string);
  const tabMap = {
    overview: 0,
    bcpl: 1,
    affiliates: 2,
    earn: 3,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("subpage")?.split(":")[1] as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
  };

  return (
    <Box id="crypto-withdraw" px="40px" {...props} overflowY="scroll">
      <TitleBar>
        <Title fontSize="20px">All Transactions</Title>
      </TitleBar>

      <Tabs my="10px" variant="ghost" index={tabIndex} onChange={handleTabChange}>
        <TabList py="10px" borderRadius="8px" justifyContent="space-evenly">
          <CryptoTab>History</CryptoTab>
          {/* <CryptoTab>BCPL</CryptoTab> */}
          <CryptoTab>Withdrawals</CryptoTab>
        </TabList>

        <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
          <TabPanel px={{ base: "0", "1sm": "24px", "2sm": "32px", "4sm": 4 }}>
            <Box>something</Box>
          </TabPanel>
          <TabPanel px={{ base: "0", "1sm": "24px", "2sm": "32px", "4sm": 4 }}>
            <Withdrawals />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

function Withdrawals(props: any) {
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const [queries, set] = usePartialState({
    page: 1,
    limit: 8,
    sortBy: "createdAt:desc",
  });

  const { data, isLoading, isFetching } = useGetWithdrawalsQuery(toQueryString(queries));

  console.log("Withdrawals", data);

  const totalCount = useMemo(() => data?.totalCount, [data]);
  const transactions = useMemo(() => data?.withdrawals ?? [], [data]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);

  // console.log("Transactions Data", data);

  const handlePageChange = (page: number) => {
    set({ page });
  };

  return (
    <Group mt="0" w="100%" {...props}>
      <Card bg={bg} w="100%" p="8px" overflow="hidden">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {!isLoading && (
              <Stack>
                {(transactions ?? []).map((tx: any, idx: number) => (
                  <TransactionItem
                    mt="0 !important"
                    p={{ base: "9px 0", "1sm": "12px", "2sm": "12px 16px" }}
                    w="100%"
                    key={`transaction-${idx}`}
                    unit={tx?.unit}
                    type={tx?.type}
                    value={tx?.value}
                    createdAt={tx?.createdAt}
                    description={tx?.description}
                  />
                ))}
              </Stack>
            )}

            {!isLoading && !isFetching && !hasTx && <EmptyCrate py="140px" type="transaction" />}
          </motion.div>
        </AnimatePresence>
      </Card>

      <Box w="100%">
        <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
      </Box>
    </Group>
  );
}

function CryptoTab({ children, ...xprops }: any) {
  const selectColor = useColorModeValue("secondary.500", "primary.500");
  return (
    <Tab
      padding={{ base: 0, "1sm": "inherit" }}
      color="grey.500"
      willChange="font-weight"
      _selected={{ color: selectColor, fontWeight: "600", textDecoration: "underline" }}
      {...xprops}
    >
      {children}
    </Tab>
  );
}
