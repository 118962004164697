import { currencyFormat, toCoinLocale, toPrecision, toQueryString } from "utils";

import BitcoinIMG from "assets/images/bitcoin.png";
import isEmpty from "lodash/isEmpty";
import { usePartialState } from "hooks";
import { P2PListingTradeRo } from "interfaces";
import { isTesting } from "config";
import { useMemo } from "react";
import { useGetAdvertiserListingByIdQuery, useGetCoinToUsdRateQuery, useGetListingQuery } from "apis";
import { Box, Button, ButtonProps, FormControl, HStack, Image, Select, Stack, Text, VStack } from "@chakra-ui/react";
import { Paginator, PaymentMethod } from "components";
import { useParams } from "@reach/router";

interface ActiveAdsPanelProps {}
interface AdItemProps extends Partial<P2PListingTradeRo> {}

interface IQueryState {
  page: number;
  limit: number;
  adsStatus: string;
  /**
   * Use `selling` for the buying ads page, and `buying` for the selling ads page.
   */
  adsType: "selling" | "buying";
  /**
   * Options are `createdAt:desc` | `createdAt:asc` | `price:asc`
   * `price:desc` | `unitCost:desc` | `totalCost:desc` ... and more.
   *  Use the format ``field:{desc | asc}``
   */
  coin: string;
  sortBy: string;
  coins: string;
  currency: string;
  fiatRanges: string;
  range: string;
  paymentOption: string;
}
interface IFilter {
  endpoint: string; // --> /own, /others
  amount: string;
  queries: Partial<IQueryState>;
}

export function ActiveAdsPanel(props: ActiveAdsPanelProps) {
  const { username: param } = useParams();

  const [, username] = String(param).split("@");

  const [filter, setFilter] = usePartialState<IFilter>({
    endpoint: "",
    queries: {
      page: 1,
      limit: 3,
      adsStatus: "open",
      // adsType: "selling",
      sortBy: "createdAt:desc",
      coins: isTesting ? "tbtc" : "btc",
    },
  });

  const queries = useMemo(
    () => `${filter?.endpoint ?? ""}/?${toQueryString(filter?.queries ?? {})}`,
    [filter?.endpoint, filter?.queries]
  );
  const {
    data: listing,
    isLoading,
    isFetching,
    // isUninitialized,
    // refetch,
  } = useGetListingQuery(queries);

  const { data } = useGetAdvertiserListingByIdQuery(username);

  console.log("Advertiser Data", data);

  const trades = useMemo(() => listing?.trades ?? [], [listing]);
  const totalCount = useMemo(() => listing?.totalCount ?? 0, [listing]);

  return (
    <Box my="26px" px="38px">
      <FormControl maxW="140px">
        <Select>
          <option>All Ads</option>
        </Select>
      </FormControl>

      {!isLoading && !isEmpty(trades) && (
        <Stack my="20px" gridGap="16px">
          {trades.map((trade, i) => (
            <AdItem key={`active-ads-${i}`} {...trade} />
          ))}
        </Stack>
      )}

      {totalCount > (filter?.queries?.limit ?? 0) && (
        <VStack>
          <Paginator
            isLoading={isFetching}
            totalCount={totalCount ?? 0}
            limit={filter?.queries?.limit}
            page={filter?.queries?.page}
            w="fit-content"
            onPageChange={(page: number) => setFilter({ queries: { ...filter?.queries, page } })}
          />
        </VStack>
      )}
    </Box>
  );
}

function AdItem(props: AdItemProps) {
  const { adsType, volume, tradeOption, coin, currentVolume, paymentOption, dynamic, price, currency } = props;

  const info = useMemo(() => {
    const map: Record<string, ButtonProps & { label: string }> = {
      buying: { bg: "accent.400", label: "Sell", _active: { bg: "accent.500" }, _hover: { bg: "accent.300" } },
      selling: { bg: "primary.800", label: "Buy" },
    };

    return map[adsType ?? "buying"];
  }, [adsType]);

  const label = (source: string) => {
    const map: Record<string, string> = {
      // [method]: `${capitalize(method)}`,
      external: "Bank Transfer",
      internal: "Bitmama Wallet",
    };

    return map[source];
  };

  const reMapCoin = (coin: string) => {
    const map: Record<string, string> = {
      [coin]: coin,
      tbtc: "btc",
      teth: "eth",
      usdt: "usd",
      cusd: "usd",
    };
    return map[coin];
  };

  const adsTypeMap = (type: string) => {
    const map: Record<string, string> = {
      selling: "buy",
      buying: "sell",
    };
    return map[type];
  };

  const { data: rate } = useGetCoinToUsdRateQuery(reMapCoin(coin ?? "btc"), { skip: (coin ?? "btc").includes("usd") });

  const exRate = useMemo(() => {
    const r = rate?.exchangeRate ?? {};
    if (!isEmpty(r)) return (r as any)[adsTypeMap(adsType ?? "buying")] as number;
    return 1;
  }, [adsType, rate]);

  const unitCost = useMemo(() => {
    if (dynamic) return coin?.includes("usd") ? 1 * (price ?? 0) : exRate * (price ?? 0);
    return tradeOption?.unitCost;
  }, [coin, price, dynamic, exRate, tradeOption]);

  const priceLabel = useMemo(
    () =>
      `${currencyFormat((currency as any) ?? "ngn").format(unitCost ?? 0)} ${toCoinLocale(currency ?? "ngn")}/${toCoinLocale(
        coin ?? "btc"
      )}`,
    [unitCost, currency, coin]
  );

  return (
    <Box bg="#f4f4f4" p="18px 30px" border="1px solid transparent" borderBottomColor="#CACBCA">
      <HStack justifyContent="space-between">
        <Box>
          <HStack alignItems="flex-start">
            <Image boxSize="48px" src={BitcoinIMG} alt="bitcoin image" mt="-10px" />
            <Box fontFamily="var(--bitmama-fonts-heading)">
              <Text fontSize="14px" fontWeight="600" color={info?.bg ?? ""}>
                {/* N12,545,931 NGN/Btc */}
                {priceLabel}
              </Text>
              <Text fontSize="12px" fontWeight="600" color={info?.bg ?? ""}>
                Available: {toPrecision(+(volume?.$numberDecimal ?? 0), coin ?? "btc")}
              </Text>
              <Text fontSize="10px" fontWeight="500" color="#797A7A">
                Minimum: {toPrecision(+(tradeOption?.minimumFilling ?? 0), coin ?? "btc")}
              </Text>
              <Text fontSize="10px" fontWeight="500" color="#797A7A">
                Maximum: {toPrecision(+(currentVolume?.$numberDecimal ?? 0), coin ?? "btc")}
              </Text>
            </Box>
          </HStack>

          <HStack mt="21px">
            {(paymentOption ?? []).map((option, i) => (
              <PaymentMethod key={`p2p-payment-method-${i}`}>{label(option?.source)}</PaymentMethod>
            ))}
          </HStack>

          <Text fontSize="12px" fontWeight="500" mt="12px">
            Completion time: 0s
          </Text>
        </Box>

        <Button minW="110px" size="sm" {...info}>
          {info?.label ?? ""}
        </Button>
      </HStack>
    </Box>
  );
}
