import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Grid,
  HStack,
  Skeleton,
  Stack,
  Tag,
  Text,
  TextProps,
  useColorModeValue,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { navigate, useParams } from "@reach/router";
import { useGetStakingByIdQuery, useUpdateSubscriptionMutation } from "apis";
import { Icon, IconNames, MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { addDays, differenceInDays, format, formatDistanceToNow, isPast, parseISO } from "date-fns";
import { useBuiltInFn, useDefaultStyle, useDisclosures, usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { capitalize, toLower } from "lodash";
import { ReactNode, useCallback, useMemo } from "react";
import { ConfirmationModal, FeatureUnavailableModal, SuccessModal } from "ui";
import { toCoinLocale, toPrecision, when } from "utils";

interface StakingPageProps extends PageProps {}

type ModalTypes =
  | "redeem::confirm"
  | "redeem::success"
  | "terminate::confirm"
  | "terminate::success"
  | "rollover::confirm"
  | "rollover::success"
  | "featureUnavailable";

export default function StakingPage(props: StakingPageProps) {
  const { errShadow } = useDefaultStyle();
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const [state, set] = usePartialState<any>();
  const { modal, isOpen, close, open } = useDisclosures<ModalTypes>();

  const { coin, id } = useParams();
  const { goBack } = useBuiltInFn();

  const { appfigs } = useAppConfig();
  const { stakingConfig } = appfigs ?? {};

  const { data: stake, isLoading } = useGetStakingByIdQuery(id);
  const [_updateSub, { isLoading: isUpdating, isSuccess }] = useUpdateSubscriptionMutation();

  // is [redeem | rollover | terminate] updating
  const is_Updating = useCallback((name: ModalTypes) => isUpdating && modal === name, [isUpdating, modal]);

  // console.log("Staking Detailss", stake);

  const redeemSub = (): void => {
    _updateSub({ id, operation: "redeem" });
  };
  const rolloverSub = (): void => {
    _updateSub({ id, operation: "rollover" });
  };
  const terminateSub = (): void => {
    _updateSub({ id, operation: "cancel" });
  };

  const isRedeemDisabled = useMemo(() => !!stakingConfig?.disableStakingRedeem ?? false, [stakingConfig]);

  const isTerminateDisabled = useMemo(() => !!stakingConfig?.disableStakingTerminate ?? false, [stakingConfig]);

  const createdDate = useMemo(
    () =>
      !!stake?.createdAt && !!stake?.product?.tenor
        ? (addDays(parseISO(stake?.createdAt), stake?.product?.tenor ?? 0) as Date)
        : undefined,
    [stake]
  );

  const redeemDate = useMemo(
    () => (!stake?.product?.expiresOn ? createdDate : parseISO(stake?.product?.expiresOn)),
    [stake, createdDate]
  );

  const isExpired = useMemo(() => isPast(redeemDate as Date), [redeemDate]);

  const apr = useMemo(() => Number(+(stake?.product?.originalApr ?? 0) * 100).toFixed(2), [stake]);

  const daysLeft = useMemo(() => (redeemDate && differenceInDays(redeemDate, new Date())) ?? 0, [redeemDate]);

  const hoursMinsLeft = useMemo(() => redeemDate && formatDistanceToNow(redeemDate), [redeemDate]);

  const accuringInterest = useMemo(
    () => (+(stake?.product?.originalApr ?? 0) * (+(stake?.product?.tenor ?? 0) - daysLeft)) / 365,
    [stake, daysLeft]
  );

  const fee = useMemo(() => {
    if (stake?.product?.fee?.applicable === "rate") {
      return +accuringInterest * (+stake?.product?.fee?.rate / 100);
    }
    if (stake?.product?.fee?.applicable === "fixed") {
      return +stake?.product?.fee?.fixed;
    }

    return 0;
  }, [accuringInterest, stake?.product?.fee]);

  const reward = useMemo(() => +(stake?.value?.$numberDecimal ?? 0) + +accuringInterest - +fee, [stake, accuringInterest, fee]);

  const hasType = useMemo(() => ["fixed", "flexible"].includes(toLower(stake?.product?.type)), [stake]);

  console.log({ stake });

  const handleBack = () => {
    goBack();
  };

  const handleSuccessContinue = (type: ModalTypes) => {
    navigate(`/earn/earnings/${coin}`);
  };

  useUpdateEffect(() => {
    if (isSuccess && !!modal && !isOpen(modal)) {
      const key = modal?.split("::")[0];
      console.log("GOT HERE!!", { modal, key, isOpen: isOpen(modal) });
      modal?.includes(key) && open(`${key}::success` as any)();
    }
  }, [isSuccess, modal, isOpen, open]);

  return (
    <PageMotion key="Staking">
      <Topbar pageTitle="Earn" {...props} />

      <MainLayoutContainer pb={{ base: "100px", "1sm": "60px" }}>
        <VStack alignItems="flex-start">
          <Box maxW="container.sm" w="100%">
            <SidePageProvider>
              <Box mb={{ base: "21px", "1sm": "42px" }}>
                <Button
                  size="sm"
                  minW="fit-content"
                  maxW="fit-content"
                  variant="link"
                  leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                  onClick={handleBack}
                  px="2px !important"
                >
                  Back
                </Button>

                <Box>
                  <Text mt="12px" as="h4" fontSize="24px" fontWeight="bold">
                    Earn Details
                  </Text>
                </Box>
              </Box>

              <Stack gridGap="20px">
                <HStack justifyContent="space-between" gridGap={{ base: "20px", "1sm": "40px" }} pos="relative">
                  <DetailItem isLoading={isLoading} title="Coin Type" description={toCoinLocale(coin)} />
                  <DetailItem isLoading={isLoading} title="Percentage" description={`${apr}%`} />

                  {stake?.status === "rolledover" && (
                    <Tag
                      size="md"
                      variant="solid"
                      colorScheme="green"
                      fontSize="12px"
                      fontWeight="500"
                      fontFamily="var(--bitmama-fonts-heading)"
                      textTransform="capitalize"
                      pos="absolute"
                      left="56px"
                      bottom="22px"
                    >
                      {stake?.status}
                    </Tag>
                  )}
                </HStack>
                <HStack justifyContent="space-between" gridGap={{ base: "20px", "1sm": "40px" }}>
                  <DetailItem
                    isLoading={isLoading}
                    title="Amount"
                    description={`${toPrecision(+stake?.value?.$numberDecimal! ?? 0, coin)}`}
                  />

                  <HStack w="100%">
                    {!isExpired && !["terminated", "redeemed"].includes(stake?.status ?? "active") && (
                      <DetailItem isLoading={isLoading} icon="timeCircle" title="Countdown" description="Ongoing" />
                    )}

                    {stake?.product?.tenor && +daysLeft > 0 && (
                      <DetailItem
                        display={{ base: "none", "1sm": "flex" }}
                        isLoading={isLoading}
                        title="Days Left"
                        description={`${daysLeft} day${+daysLeft > 1 ? "s" : ""}`}
                        _description={{ color: "secondary.500" }}
                      />
                    )}

                    {!isExpired && stake?.product?.tenor && +daysLeft === 0 && (
                      <DetailItem
                        isLoading={isLoading}
                        title="Days Left"
                        description={`${hoursMinsLeft}`}
                        _description={{ color: "secondary.500" }}
                      />
                    )}

                    {isExpired && redeemDate && (
                      <DetailItem
                        isLoading={isLoading}
                        title="Expired On"
                        description={`${format(redeemDate, "eee dd, MMM yyyy")}`}
                      />
                    )}
                  </HStack>
                </HStack>
                <HStack justifyContent="space-between" gridGap={{ base: "20px", "1sm": "40px" }}>
                  {stake?.currentStatus && (
                    <DetailItem isLoading={isLoading} title="Status" description={capitalize(stake?.currentStatus ?? "")} />
                  )}
                  {hasType && <DetailItem isLoading={isLoading} title="Subscription type" description={stake?.product?.type} />}
                </HStack>
                <HStack justifyContent="space-between" gridGap={{ base: "20px", "1sm": "40px" }}>
                  {accuringInterest && (
                    <DetailItem
                      isLoading={isLoading}
                      title="Accuring Interest"
                      description={`${toPrecision(accuringInterest, coin, true, 6, true)}`}
                    />
                  )}
                  {stake?.product?.tenor && (
                    <DetailItem
                      isLoading={isLoading}
                      title="Duration"
                      description={`${stake?.product?.tenor} day${+daysLeft > 1 ? "s" : ""}`}
                    />
                  )}
                </HStack>

                <HStack justifyContent="space-between" gridGap={{ base: "20px", "1sm": "40px" }}>
                  <DetailItem
                    isLoading={isLoading}
                    title="Automatic Rollover"
                    description={stake?.product?.autoRenew ? "Yes" : "No"}
                  />
                  <HStack w="100%">
                    <DetailItem
                      isLoading={isLoading}
                      title="Reward"
                      description={toPrecision(isNaN(reward) ? 0 : reward, coin, true, 6, true)}
                    />

                    {fee > 0 && (
                      <DetailItem
                        isLoading={isLoading}
                        title="Fee"
                        description={toPrecision(isNaN(fee) ? 0 : fee, coin, true, 8, true)}
                      />
                    )}
                  </HStack>
                </HStack>

                <HStack>
                  {stake?.product?.tenor && +daysLeft > 0 && (
                    <DetailItem
                      display={{ base: "flex", "1sm": "none" }}
                      isLoading={isLoading}
                      title="Days Left"
                      description={`${daysLeft} day${+daysLeft > 1 ? "s" : ""}`}
                      _description={{ color: "secondary.500" }}
                    />
                  )}
                </HStack>

                <VStack mt="56px !important">
                  <Grid templateColumns="repeat(2, 1fr)" w="100%" gap="16px" justifyContent="center">
                    {stake?.currentStatus === "active" && isExpired && (
                      <Button
                        minW="100%"
                        maxW="100%"
                        onClick={when(isRedeemDisabled, open("featureUnavailable"), open("redeem::confirm"))}
                        isLoading={is_Updating("redeem::confirm")}
                        disabled={isUpdating}
                      >
                        Redeem
                      </Button>
                    )}

                    {stake?.currentStatus === "active" && isExpired && (
                      <Button
                        minW="100%"
                        maxW="100%"
                        onClick={open("rollover::confirm")}
                        isLoading={is_Updating("rollover::confirm")}
                        disabled={isUpdating}
                      >
                        Rollover
                      </Button>
                    )}
                  </Grid>

                  {stake?.currentStatus === "active" && !isExpired && (
                    <Button
                      maxW="320px"
                      minW={{ base: "100%", "1sm": "400px" }}
                      variant="outline"
                      borderColor="error"
                      color="error"
                      _focus={{ shadow: errShadow }}
                      _hover={{ shadow: errShadow }}
                      _active={{ shadow: errShadow }}
                      onClick={when(isTerminateDisabled, open("featureUnavailable"), open("terminate::confirm"))}
                      isLoading={is_Updating("terminate::confirm")}
                      disabled={isUpdating}
                    >
                      Terminate
                    </Button>
                  )}
                </VStack>
              </Stack>

              <ConfirmationModal
                isOpen={isOpen("redeem::confirm")}
                onClose={close("redeem::confirm")}
                onConfirm={redeemSub}
                labels={["Redeem"]}
                disableConfirmation={!isExpired && !state.isChecked}
              >
                <Stack>
                  {!isExpired && (
                    <Text
                      fontWeight="500"
                      textAlign={{ base: "center", "1sm": "left" }}
                      py={{ base: "20px", "1sm": "40px" }}
                      color="grey.700"
                    >
                      Redeeming this subscription before its end date means that you lose the interest accrued on it.
                    </Text>
                  )}

                  {isExpired && (
                    <Text
                      fontWeight="500"
                      textAlign={{ base: "center", "1sm": "left" }}
                      py={{ base: "20px", "1sm": "40px" }}
                      color="grey.700"
                    >
                      Are you sure you want to redeem this subscription?
                    </Text>
                  )}

                  {!isExpired && (
                    <Checkbox
                      mt="40px"
                      isChecked={state?.isChecked}
                      onChange={(e) => set({ isChecked: e.target.checked })}
                      colorScheme="green"
                      alignItems="flex-start"
                      sx={{
                        ".chakra-checkbox__control": {
                          mt: "2px",
                        },
                        ".chakra-checkbox__label": {
                          fontSize: "sm",
                          color: "grey.500",
                          fontWeight: "500",
                        },
                      }}
                    >
                      I am aware of the redemption rules and confirm my early redemption.
                    </Checkbox>
                  )}
                </Stack>
              </ConfirmationModal>

              <ConfirmationModal
                isOpen={isOpen("terminate::confirm")}
                onClose={close("terminate::confirm")}
                onConfirm={terminateSub}
                labels={["Terminate"]}
              >
                <Text
                  fontWeight="500"
                  textAlign={{ base: "center", "1sm": "left" }}
                  py={{ base: "20px", "1sm": "40px" }}
                  color="grey.700"
                >
                  Terminating this subscription before its end date means that you lose the interest accrued on it.
                </Text>
              </ConfirmationModal>

              <ConfirmationModal
                isOpen={isOpen("rollover::confirm")}
                onClose={close("rollover::confirm")}
                onConfirm={rolloverSub}
                labels={["Rollover"]}
              >
                <Text
                  fontWeight="500"
                  textAlign={{ base: "center", "1sm": "left" }}
                  py={{ base: "20px", "1sm": "40px" }}
                  color="grey.700"
                >
                  Are you sure you want to rollover this subscription?
                </Text>
              </ConfirmationModal>

              <SuccessModal
                isOpen={isOpen("redeem::success")}
                message={`Subscription redeemed successfully`}
                onClose={close("redeem::success")}
                onContinue={() => handleSuccessContinue("redeem::success")}
              />

              <SuccessModal
                isOpen={isOpen("rollover::success")}
                message={`Subscription rolledover successfully`}
                onClose={close("rollover::success")}
                onContinue={() => handleSuccessContinue("rollover::success")}
              />

              <SuccessModal
                isOpen={isOpen("terminate::success")}
                message={`Subscription terminated successfully`}
                onClose={close("terminate::success")}
                onContinue={() => handleSuccessContinue("terminate::success")}
              />

              <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
            </SidePageProvider>
          </Box>
        </VStack>
      </MainLayoutContainer>
    </PageMotion>
  );
}

interface DetailItemProps extends BoxProps {
  icon?: IconNames;
  title: string;
  description: ReactNode;
  isLoading?: boolean;
  _title?: TextProps;
  _description?: TextProps;
}

function DetailItem(props: DetailItemProps) {
  const { icon, title, description, isLoading, _title, _description, ...xprops } = props;

  //  const isComponent = useMemo(() => typeof description !== "string", [description]);

  const color = useColorModeValue("primary.default", "secondary.500");
  const borderColor = useColorModeValue("border", "dark.border");

  return (
    <Stack w="100%" p="20px 4px" borderBottom="1px solid transparent" borderBottomColor={borderColor} {...xprops}>
      <Skeleton
        isLoaded={!isLoading ? true : false}
        w="fit-content"
        mb={isLoading ? "2px" : 0}
        maxH={isLoading ? "12px" : "fit-content"}
      >
        <Text fontSize="14px" color="grey.400" {..._title}>
          {title}
        </Text>
      </Skeleton>

      <Skeleton
        isLoaded={!isLoading ? true : false}
        w="fit-content"
        mb={isLoading ? "2px" : 0}
        maxH={isLoading ? "12px" : "fit-content"}
      >
        <HStack>
          {icon && <Icon type={icon} />}
          <Text color={color} fontWeight="600" mt="0 !important" {..._description}>
            {description}
          </Text>
        </HStack>
      </Skeleton>
    </Stack>
  );
}
