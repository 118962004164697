import { MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { PageProps } from "interfaces";
import { EarnSubPages } from "ui";
import EntryPage from "./Entry";
// import EarnPage from "./Earn";

export default function EarnIndex(props: PageProps) {
  return (
    <PageMotion key="Earn">
      <Topbar pageTitle="Earn" {...props} />

      <MainLayoutContainer pb="60px">
        <SidePageProvider>
          {/* <EarnPage /> */}
          <EntryPage />

          <EarnSubPages key="EarnPages" hasBackButton />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}
