import { Box, Stack, useColorModeValue } from "@chakra-ui/react";
import { Card, EmptyCrate, Group, GroupProps, PageLoading, Paginator, TransactionItem, useModalView as useSteps } from "components";

import { useGetWalletTransationsV2Query } from "apis";
import { toQueryString, when } from "utils";
import { useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { usePartialState } from "hooks";
import { useSidePage } from "contexts";
import { WalletTransactionRo } from "interfaces";
import { setTransactions } from "store/slices/filter";
import { useDispatch } from "react-redux";

export interface BuyAndSellTransactionsProps extends GroupProps {
  subjectOf: "sell_credit" | "buy_debit";
}

export default function BuyAndSellTransactions(props: BuyAndSellTransactionsProps) {
  const { subjectOf } = props;
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const { hasNext, onNext } = useSteps();
  const { updateSidePageConfigs } = useSidePage();

  const [queries, set] = usePartialState({
    page: 1,
    limit: 7,
    sortBy: "createdAt:desc",
    subjectOfTransaction: subjectOf,
  });

  const { data, isLoading, isFetching } = useGetWalletTransationsV2Query(toQueryString(queries));

  const totalCount = useMemo(() => data?.totalCount, [data]);
  const transactions = useMemo(() => data?.transactions, [data]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);

  //   console.log("Transactions Data", data);

  const dispatch = useDispatch();
  const handlePageChange = (page: number) => {
    set({ page });
  };

  const handleItemClick = (tx: WalletTransactionRo) => {
    if (hasNext) {
      updateSidePageConfigs({ params: { txId: tx?._id }, data: { tx } });

      dispatch(setTransactions(tx));
      onNext();
    }
  };

  return (
    <Group mt="0" w="100%" py="52px" {...props}>
      <Card bg={bg} w="100%" p="8px" overflow="hidden">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {!isLoading && (
              <Stack>
                {(transactions ?? []).map((tx, id) => (
                  <TransactionItem
                    mt="0 !important"
                    w="100%"
                    key={`transaction-${id}`}
                    unit={tx?.unit}
                    type={tx?.type}
                    value={tx?.value}
                    createdAt={tx?.createdAt}
                    description={tx?.description}
                    onClick={() => handleItemClick(tx)}
                  />
                ))}
              </Stack>
            )}

            {!isLoading && !isFetching && !hasTx && <EmptyCrate py="140px" type="transaction" />}
          </motion.div>
        </AnimatePresence>
      </Card>

      <Box w="100%">
        <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
      </Box>
    </Group>
  );
}
