import { useEffect, useMemo } from "react";
import { Box, Grid, List, Stack, Text } from "@chakra-ui/react";
import { take, orderBy } from "lodash";
import { LeaderboardItem, Leaders } from "components";
import { PageProps } from "interfaces";
import { useSelector } from "hooks";
import { useAffiliateLeaderboardMutation } from "apis";

interface TopEarnersPanelProps extends PageProps {}

export default function TopEarnersPanel(props: TopEarnersPanelProps) {
  const { user } = props;
  const [getLeaderboard, { data }] = useAffiliateLeaderboardMutation();

  const referrals = useMemo(() => data?.data, [data]);

  const topLeaders = useMemo(() => take(orderBy(referrals, ["usdEarning"], ["desc"]), 3), [referrals]);
  const filteredList = useMemo(() => orderBy(referrals, ["usdEarning"], ["desc"]), [referrals]);

  const { afterDate, beforeDate } = useSelector((state) => state.filter.leaderboard);

  const filteredData = filteredList.filter(({ userId }) => {
    return data && data?.currentUserSpot !== undefined && data?.currentUserSpot._id !== userId;
  });

  useEffect(() => {
    getLeaderboard({
      page: 1,
      limit: 10,
      sortBy: "usdEarning:desc",
      afterDate: afterDate ? afterDate : undefined,
      beforeDate: beforeDate ? beforeDate : undefined,
    });
  }, [afterDate, beforeDate, getLeaderboard]);

  return (
    <Box>
      <Leaders items={topLeaders} />

      <Stack my="30px">
        <Grid
          templateColumns={{ base: ".2fr 1.1fr .3fr", "4sm": ".4fr 1.1fr .3fr" }}
          fontSize="16px"
          color="grey.300"
          fontWeight="500"
        >
          <Text>Position</Text>
          <Text>Avatar & Username</Text>
          <Text>Points</Text>
        </Grid>

        <List minH="352px">
          {data && data?.currentUserSpot !== undefined && (
            <>
              <LeaderboardItem
                mb="16px"
                rank={data?.currentUserSpot.ranking!}
                name={`${user?.firstName} ${user?.lastName}`}
                username={user?.username!}
                points={Number(Number(data?.currentUserSpot.usdEarning).toFixed(2))}
              />
            </>
          )}

          {filteredData.slice(3).map(({ firstName, lastName, username, usdEarning, ranking }, i) => (
            <LeaderboardItem
              key={`leaderboard-item-${i}`}
              mb="16px"
              rank={ranking}
              name={`${firstName} ${lastName}`}
              username={username}
              points={Number(Number(usdEarning).toFixed(2))}
            />
          ))}
        </List>
      </Stack>
    </Box>
  );
}
