import { Box, Heading, useColorModeValue } from "@chakra-ui/react";
import { Group, Span } from "components";
import MockUpPNG from "assets/images/auth-mockup.png";
import MoonWebp from "assets/images/auth-bg.webp";
import { FC } from "react";

interface IAuthImageProps {
  isMobile: boolean;
}
const AuthImage: FC<IAuthImageProps> = ({ isMobile }) => {
  const spanColor = useColorModeValue("#e8c32a", "#e8c32a");
  const illColor = useColorModeValue("#072420d1", "#17280ed1");

  return (
    <>
      {isMobile ? (
        ""
      ) : (
        <Box id="auth-illustration" pos="fixed" width="100%" maxW="var(--w)" left={0} top={0}>
          <Group bg={illColor} p={{ sm: "60px 84px", md: "60px 120px" }} m="0 !important" minH="100vh" h="100%">
            <Heading fontSize="32px" textAlign="center" maxW="306px" margin="0 auto 68px !important" color="white">
              The easiest way to <Span color={spanColor}>buy</Span> and <Span color={spanColor}>sell</Span> Crypto
            </Heading>

            <Box as={"img"} src={MockUpPNG} alt="Mock" />
          </Group>

          <Box
            as={"img"}
            src={MoonWebp}
            alt="Moon"
            pos="absolute"
            w="100%"
            h="100%"
            top="0"
            left="0"
            zIndex="-1"
            objectFit="cover"
          />
        </Box>
      )}
    </>
  );
};

export default AuthImage;
