import { Box, IconButton, Stack, useColorModeValue } from "@chakra-ui/react";
import {
  Card,
  EmptyCrate,
  Group,
  GroupProps,
  PageLoading,
  Paginator,
  Title,
  TitleBar,
  TitleBarActions,
  TransactionItem,
} from "components";

import { useGetWalletTransationsV2Query } from "apis";
import { toQueryString, when } from "utils";
import { useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { usePartialState } from "hooks";
import { RepeatIcon } from "@chakra-ui/icons";
// import { useSidePage } from "contexts";

export interface WalletTransactionsProps extends GroupProps {}

export default function WalletTransactions(props: WalletTransactionsProps) {
  // const { onOpen } = useSidePage();
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const [queries, set] = usePartialState({
    page: 1,
    limit: 8,
    sortBy: "createdAt:desc",
  });

  const { data, isLoading, isFetching, refetch } = useGetWalletTransationsV2Query(toQueryString(queries));

  const totalCount = useMemo(() => data?.totalCount, [data]);
  const transactions = useMemo(() => data?.transactions, [data]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);

  // console.log("Transactions Data", data);

  const handlePageChange = (page: number) => {
    set({ page });
  };

  return (
    <Group mt="0" w="100%" {...props}>
      <TitleBar>
        <Title fontSize="18px" isLoading={isFetching && !isLoading}>
          Wallet Transactions
        </Title>

        <TitleBarActions>
          <IconButton
            p="10px"
            bg="transparent"
            color="secondary.400"
            alignSelf="center"
            minW="unset"
            minH="unset"
            h="fit-content"
            aria-label="refresh button"
            icon={<RepeatIcon color="currentColor" />}
            // onClick={() => Emitter.emit("refetch_p2p", {})}
            onClick={refetch}
          />
        </TitleBarActions>
      </TitleBar>
      <Card bg={bg} w="100%" p="8px" overflow="hidden">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {!isLoading && (
              <Stack>
                {(transactions ?? []).map((tx, id) => (
                  <TransactionItem
                    mt="0 !important"
                    p={{ base: "9px 0", "1sm": "12px", "2sm": "12px 16px" }}
                    w="100%"
                    key={`transaction-${id}`}
                    unit={tx?.unit}
                    type={tx?.type}
                    value={tx?.value}
                    createdAt={tx?.createdAt}
                    description={tx?.description}
                  />
                ))}
              </Stack>
            )}

            {!isLoading && !isFetching && !hasTx && <EmptyCrate py="140px" type="transaction" />}
          </motion.div>
        </AnimatePresence>
      </Card>

      <Box w="100%">
        <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
      </Box>
    </Group>
  );
}
