import {
  Button,
  Heading,
  HStack,
  ListItem,
  ListItemProps,
  ModalProps,
  OrderedList,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { AbstractModal } from "components";

import { switchStyle } from "utils";

interface AccountDeletionTermsProps extends Omit<ModalProps, "children"> {}

export function AccountDeletionTerms(props: AccountDeletionTermsProps) {
  const { colorMode } = useColorMode();

  return (
    <AbstractModal {...props}>
      <VStack px="60px" my="42px">
        <VStack mt="60px" mb="24px !important" color={switchStyle(colorMode, { dark: "secondary.400", light: "primary.800" })}>
          <Heading as="h4" fontSize="24px" color="inherit">
            Account Deletion
          </Heading>
          <Text mt="14px !important" textDecoration="underline" fontFamily="var(--bitmama-fonts-heading)" color="inherit">
            Terms & conditions
          </Text>
        </VStack>

        <OrderedList>
          <Item mb="32px">&nbsp; Once you delete your account, you cannot use the same e-mail and BVN to sign-up in future</Item>
          <Item>
            &nbsp; Deleting your account may result in difficulties using certain services, such as creating virtual cards
            elsewhere, as other providers will recognise your previous association with Bitmama.
          </Item>
        </OrderedList>

        <Button my="42px !important" onClick={props.onClose}>
          Close
        </Button>
      </VStack>
    </AbstractModal>
  );
}

interface ItemProps extends ListItemProps {}

function Item(props: ItemProps) {
  const { children } = props;
  return (
    <ListItem my="32px">
      <HStack alignItems="flex-start">
        <Text fontWeight="500" lineHeight="24px">
          {children}
        </Text>
      </HStack>
    </ListItem>
  );
}
