import { Box, Button, ModalProps, VStack } from "@chakra-ui/react";
import { AbstractModal } from "components";
import { useState } from "react";
import PinView from "../PinView";
// import { isTesting } from "config";
// import { useSidePage } from "contexts";

interface SubmitPinModalProps extends Omit<ModalProps, "children"> {
  labels?: string[];
  onSubmit?: (pin: string) => void;
  onPin?: (pin: string) => void;
  onComplete?: (pin: string) => void;
  closeOnSubmit?: boolean;

  heading?: string;
  subheading?: string;
}

export default function SubmitPin(props: SubmitPinModalProps) {
  const { heading, subheading, labels, onSubmit, onPin, onComplete, isOpen, onClose, closeOnSubmit = true, ...xprops } = props;
  //   const bColor = useColorModeValue("grey.150", "dark.border");

  const [pin, setPin] = useState<string | null>(null);

  const handlePin = (pin: string) => {
    setPin(pin);
    onPin && onPin(pin);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(pin as string);
    closeOnSubmit && onClose();
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "600px",
        minH: { base: "100vh", "1sm": "initial" },
        borderRadius: { base: "0px", "1sm": "20px" },
      }}
      {...xprops}
    >
      <Box p={{ base: "32px 20px", "2sm": "44px 80px" }}>
        <PinView
          onPin={handlePin}
          heading={heading}
          text={subheading}
          on_close={onClose}
          on_complete={onComplete}
          _heading={{ textAlign: "center" }}
          _infoContainer={{ maxW: "340px" }}
        />

        <VStack mt="24px !important" px="46px" w="100%">
          <Button minW="unset" w="100%" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose}>
            Close
          </Button>
        </VStack>
      </Box>
    </AbstractModal>
  );
}
