import { Box, Button, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { useCoin, useColor, useDisclosures, useUpdateP2POrder } from "hooks";
import { P2PSessionRo, PageProps } from "interfaces";
import { useMemo } from "react";
import { ConfirmationModal } from "ui";
import { toPrecision } from "utils";
import toLower from "lodash/toLower";
import { useParams } from "@reach/router";

type ModalTypes = "release";

interface HeaderInfoProps extends PageProps, Partial<P2PSessionRo> {
  mapKey: string;
}

interface UseActionMapperProps extends HeaderInfoProps {
  disclosures: ReturnType<typeof useDisclosures<ModalTypes>>;
}

export function HeaderInfo(props: HeaderInfoProps) {
  const { coin, user, counterParties, adsType } = props;
  const { label, icon } = useCoin((coin as any) ?? "btc");

  // const disclosures = useDisclosures<ModalTypes>();
  // const actionsView = useActionsMapper({ ...props, disclosures });

  const tradeOwner = useMemo(() => counterParties?.find((cp) => toLower(cp.role) === "tradeowner"), [counterParties]);

  const tradeType = useMemo(() => {
    const map: Record<"trader" | "tradeowner", { [key: string]: string }> = {
      trader: { selling: "Buy", buying: "Sell" },
      tradeowner: { selling: "Sell", buying: "Buy" },
    };
    return map[tradeOwner?.user?.userId === user?.userId ? "tradeowner" : "trader"][adsType!];
  }, [adsType, tradeOwner, user?.userId]);

  return (
    <HStack mt="12px" justifyContent="space-between">
      <Heading as="h4" fontSize="24px" fontWeight="bold" display="flex" alignItems="center">
        {tradeType} {label} <Box as={icon} ml="10px" boxSize="32px" />
      </Heading>

      {/* {actionsView} */}
    </HStack>
  );
}

function TraderReleaseAction(props: UseActionMapperProps) {
  const { disclosures, user, amount, coin, contractState, counterParties } = props;
  const { isOpen, close, open } = disclosures;

  const { ref } = useParams();
  const color = useColor();
  const [id, frozenRef] = String(ref).split("-");

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  const ownerRole = useMemo(() => appropriateSessionOwner?.role, [appropriateSessionOwner]);

  const trader = useMemo(
    () => (counterParties ?? [])?.find((cp) => toLower(cp.role) === (ownerRole === "trader" ? "tradeowner" : "trader")),
    [counterParties, ownerRole]
  );

  const currentTradeOwnerStatus = useMemo(() => `${contractState}&${trader?.obligationStatus}`, [contractState, trader]);
  const isPaid = useMemo(() => currentTradeOwnerStatus === "open&fulfilled", [currentTradeOwnerStatus]);

  const { updateOrder, isUpdating } = useUpdateP2POrder(id, frozenRef);

  return (
    <HStack>
      <Button
        minW="200px"
        maxW="200px"
        bg="#6FBE45"
        fontFamily="var(--bitmama-fonts-heading)"
        disabled={!isPaid || isUpdating}
        isLoading={isUpdating}
        onClick={open("release")}
      >
        Release Coins
      </Button>

      <ConfirmationModal
        isOpen={isOpen("release")}
        onClose={close("release")}
        onConfirm={() => updateOrder("RELEASETRADE")}
        title="Confirm Release"
        _content={{ maxW: "700px" }}
      >
        <VStack m="40px auto" maxW="400px">
          <Text fontSize="16px" fontWeight="500" textAlign="center" color={color("black", "white")}>
            Please confirm that you have received payment from @{user?.username ?? ""} and want to release{" "}
            {toPrecision(amount ?? 0, coin ?? "btc")}{" "}
          </Text>
        </VStack>
      </ConfirmationModal>
    </HStack>
  );
}

export function useActionsMapper(props: UseActionMapperProps) {
  const { mapKey } = props;

  const renderView = useMemo(() => {
    const map: Record<string, JSX.Element> = {
      // Map views for users selling to a buy listing. PS: Sell tab items.
      "trader&buying&open&fulfilled": <TraderReleaseAction {...props} />,
      "trader&buying&open&pending": <TraderReleaseAction {...props} />,
      "trader&buying&closed&fulfilled": <></>,

      "tradeowner&selling&open&pending": <TraderReleaseAction {...props} />,
    };

    return map[mapKey];
  }, [mapKey, props]);

  return renderView;
}
