import { Box, Button, Heading, useColorModeValue } from "@chakra-ui/react";
import { Icon, MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { BuyAdFeature } from "ui";

interface BuyAdPageProps extends PageProps {}

export default function BuyAdPage(props: BuyAdPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  // we are buy from a sellAd, so the title should be SellAd
  return (
    <PageMotion key="BuyFromSellListingPage">
      <Topbar pageTitle="P2P" {...props} />

      <MainLayoutContainer m="0" pb="60px">
        <SidePageProvider>
          <Box mb="42px">
            <Button
              size="sm"
              minW="fit-content"
              maxW="fit-content"
              variant="link"
              leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
              onClick={goBack}
              px="2px !important"
            >
              Back
            </Button>

            <Heading mt="12px" as="h4" fontSize="24px" fontWeight="bold">
              {/* Sell Ad */}
              Ad Details
            </Heading>
          </Box>

          <BuyAdFeature {...props} />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}
