import { Box, Button, Heading, HStack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { Link, navigate } from "@reach/router";
import { useDeleteBankMutation, useGetUserBankDetailsQuery } from "apis";
import { AddButton, BankInfoCard, Icon, MainLayoutContainer, PageMotion } from "components";
import { useBuiltInFn, useI18n } from "hooks";
import { PageProps } from "interfaces";
import { useMemo } from "react";

interface MobileMoneyListProps extends PageProps {}

export default function MobileMoneyList(props: MobileMoneyListProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { geti18nTitle } = useI18n();
  const { goBack } = useBuiltInFn();
  const { data } = useGetUserBankDetailsQuery({});

  const title = useMemo(() => geti18nTitle("account.mobilemoney"), [geti18nTitle]);

  return (
    <PageMotion subpage key="AccountMobileMoneyList" overflowY="hidden">
      <MainLayoutContainer
        // px="140px"
        px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }}
        py="10px"
      >
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize={{ base: "18px", "3sm": "24px" }}>
              {title}
            </Heading>

            {data && data.bank.filter(({ type }: any) => type === "mobilemoney").length === 0 ? (
              ""
            ) : (
              <AddButton onClick={() => navigate("momo/add")}>Add {title}</AddButton>
            )}
          </HStack>
        </Box>

        <MomoList {...props} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

function MomoList(props: MobileMoneyListProps) {
  const { data, refetch } = useGetUserBankDetailsQuery({});

  const [_deleteBank, { isLoading }] = useDeleteBankMutation();

  const handleDelete = async (id: string) => {
    await _deleteBank(id);
    refetch();
  };

  return (
    <VStack my="40px">
      {data && data.bank.filter(({ type }) => type === "mobilemoney").length === 0 ? (
        <EmptyState />
      ) : (
        data?.bank
          .filter(({ type }) => type === "mobilemoney")
          .map(({ _id, bankName, bankAccountName, bankAccountNumber }) => (
            <BankInfoCard
              key={_id}
              bankName={bankName}
              accountName={bankAccountName}
              accountNumber={bankAccountNumber}
              onDelete={() => handleDelete(_id)}
              onDeleteLoading={isLoading}
            />
          ))
      )}
    </VStack>
  );
}

function EmptyState() {
  const { geti18nTitle } = useI18n();
  const title = useMemo(() => geti18nTitle("account.mobilemoney"), [geti18nTitle]);
  return (
    <VStack py={{ base: "80px", "3sm": "140px" }}>
      <Text color="grey.300" fontWeight="bold">
        No {title} added
      </Text>
      <Text color="grey.300" fontWeight="500">
        Click ‘Add {title}’ to get started
      </Text>

      <Link to="/account/momo/add">
        <AddButton>Add {title}</AddButton>
      </Link>
    </VStack>
  );
}
