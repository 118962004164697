export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

/**
 * Remaps n from range (start1 - stop1) to range (start2 - stop2)
 *
 * @example
 * Remap mouseX from 0 - 100 to 0 - 50
 * remap(mouseX, 0, 100, 0, 50)
 * @param n
 * @param start1
 * @param stop1
 * @param start2
 * @param stop2
 * @returns number
 */
export const remap = (n: number, start1: number, stop1: number, start2: number, stop2: number): number => {
  return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
};
