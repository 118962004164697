import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  List,
  ListItem,
  ListItemProps,
  Select,
  Text,
  TextProps,
  useColorModeValue,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import {
  useCancelFundDepositMutation,
  useFundFiatWalletMutation,
  useFundFrancoFiatWalletMutation,
  useGetDepositConfigQuery,
  useGetFrancoDepositQuery,
  useGetLatestFiatDepositQuery,
  useMarkFiatFundingAsPaidMutation,
  useProcessPaymentMutation,
  useUploadFiatFundingProofMutation,
} from "apis";
import {
  AbstractModal,
  AmountInput,
  BackButton,
  Card,
  ConditionalRender,
  CopyButton,
  FormInfo,
  Icon,
  InputLabel,
  Link,
  LinkProps,
  ModalView,
  Uploader,
  useAbstractModal,
  useModalView,
  ViewSwitcher,
  ViewSwitcherChildProps,
} from "components";
import configs from "config";
import { useBanks, useColor, useCopy, useEventListener, usePartialState, useSelector, useValidatePhoneNumber } from "hooks";
import { AppAnalyticEvent, FundFrancoWalletDto, FundWalletDto, FundWalletRo } from "interfaces";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { currencyFormat, uploadFile, when } from "utils";

import { appendScriptPromise, removeScript } from "hooks/useScript";
import { nanoid } from "nanoid";
import { selectUser } from "store/slices";
import ls from "utils/secureStorage";
// import Input from "components/Input/Input";
import { isEqual } from "lodash";
import { useCurrency } from "hooks/useCurrency";
import { RocketSVG } from "assets";
import { Emitter, transformFiatDepositDataToEngagement } from "libs";
// import { CloseSVG } from "assets";

interface FundFiatModalProps {
  isOpen: boolean;
  onClose: () => void;
  coin?: string;
}

interface IState {
  via: "bank_transfer" | "momo" | "flutter";
  amount: string;
  paymentProof: File;
  currentView: number;

  // ui managed
  hasPrev: boolean;
  onClick: () => void;
  bank: string;
}

export interface ViewProps extends ViewSwitcherChildProps {
  state: Partial<IState>;
  set: ReturnType<typeof usePartialState<IState>>[1];
  reset: () => void;
  coin: string;
}

function Item(
  props: ListItemProps & {
    canCopy?: boolean;
    value?: string;
    name: string;
    _value?: TextProps;
    link?: LinkProps;
  }
) {
  const { canCopy, value, name, _value, link, ...xprops } = props;
  const { onCopy } = useCopy(value ?? "");

  const nameColor = useColorModeValue("grey.500", "grey.100");
  const valueColor = useColorModeValue("grey.800", "grey.50");

  return (
    <ListItem py="8px" fontSize={{ base: "12px", "1sm": "16px" }} {...xprops}>
      <HStack justifyContent="space-between">
        <Text color={nameColor}>{name}</Text>
        {!link && (
          <HStack justifyContent="flex-start">
            <Text fontWeight="600" color={valueColor} {..._value}>
              {value}
            </Text>
            {canCopy && <CopyButton onClick={onCopy} />}
          </HStack>
        )}

        {!!link && <Link {...link}>{link?.children ?? "Click"}</Link>}
      </HStack>
    </ListItem>
  );
}

function Fund(props: ViewProps) {
  const { state, set, coin: fiat, reset } = props;

  const color = useColor();
  const { onClose } = useAbstractModal();
  const { profile } = useSelector(selectUser);
  const { onNext, setCurrentView } = useModalView();

  const currency = useCurrency();
  const { banks, isLoading } = useBanks();
  const cacheKey = useDepositCacheKey();
  const { formatPhone } = useValidatePhoneNumber(profile?.country!);

  const coin = useMemo(() => fiat ?? currency ?? "ngn", [fiat, currency]);

  console.log("Funding State", state);

  const {
    submit: submitFlutterWave,
    isLoading: isFWLoading,
    isProcessing: isFWProcessing,
  } = useFlutterWaveCheckout({ state, set, currency: coin, user: profile, reset, onClose });

  const [_fundFrancoWallet, { isLoading: isFundingFranco }] = useFundFrancoFiatWalletMutation();

  const fundFrancoWallet = async () => {
    console.log("Phone number", formatPhone(state?.bank ?? ""));
    const reqData: FundFrancoWalletDto = {
      currency: coin,
      amount: +state?.amount!,
      depositType: "momo",
      // mobileNumber: formatPhone(state?.bank ?? ""),
      // mobileNumber: "+80000000002",
      // mobileNumber: state?.bank ?? "",
      bankId: state?.bank ?? "",
    };

    Emitter.emit(AppAnalyticEvent.FUNDING_INITIATED, transformFiatDepositDataToEngagement(reqData));
    const result = await _fundFrancoWallet(reqData).unwrap();
    console.log("Franco Wallet Result", result);
    if (!!result) {
      Emitter.emit(AppAnalyticEvent.FUNDING_SUBMITTED, transformFiatDepositDataToEngagement(reqData));
      ls.set(cacheKey, result);
      onNext();
    }
  };

  const isFranc = useMemo(() => configs.francoCoins.includes(coin), [coin]);

  // pass in momo, flutterwave, mpesa, or bank as method;
  const method = useMemo(() => {
    const map: Record<"bank_transfer" | "momo" | "flutter", string> = {
      [state?.via!]: state?.via as string,
      bank_transfer: "bank",
      flutter: "flutterwave",
      momo: "momo",
    };

    return map[state?.via ?? "bank_transfer"];
  }, [state?.via]);

  const { data: depoConfig, isLoading: isDepoConfLoading } = useGetDepositConfigQuery(method, { skip: !state?.via });

  const accounts = useMemo(() => (banks ?? []).filter((b) => b?.type === "mobilemoney"), [banks]);

  const fee = useMemo(
    () => (!!depoConfig ? (depoConfig?.fees as { [key: string]: number })[coin ?? "ghs"] : 0),
    [coin, depoConfig]
  );
  const min = useMemo(
    () => (!!depoConfig ? (depoConfig?.mins as { [key: string]: number })[coin ?? "ghs"] : 0),
    [coin, depoConfig]
  );
  // const max = useMemo(() => (depoConfig?.maxs as { [key: string]: number })[coin ?? "ghs"], [coin, depoConfig]);

  console.log("Deposit Config", depoConfig, fee);

  const options = useMemo(() => {
    const map: Record<string, { name: string; value: IState["via"] }[]> = {
      ngn: [{ name: "Bank Transfer", value: "bank_transfer" }],
      ghs: [
        { name: "Flutter Wave", value: "flutter" },
        { name: "Mobile Money", value: "momo" },
      ],
      kes: [{ name: "MPESA", value: "momo" }],
      xof: [{ name: "Mobile Money", value: "momo" }],
      xaf: [{ name: "Mobile Money", value: "momo" }],
      cdf: [{ name: "Mobile Money", value: "momo" }],
      zar: [{ name: "Mobile Money", value: "momo" }],
    };

    return map[coin ?? "ngn"];
  }, [coin]);

  const isLessThanMinimum = useMemo(() => !!state?.amount && +state?.amount < min, [state?.amount, min]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    switch (state?.via) {
      case "bank_transfer":
        setCurrentView(1);
        break;
      case "flutter":
        await submitFlutterWave();
        break;
      case "momo":
        if (!isFranc) setCurrentView(1);
        else await fundFrancoWallet();
        break;
      default:
        break;
    }
    onNext();
  };

  const isDisabled = useMemo(
    () =>
      !(state?.amount && state?.via) ||
      isLessThanMinimum ||
      isFWProcessing ||
      isFWLoading ||
      isDepoConfLoading ||
      (isFranc && !state?.bank) ||
      isFundingFranco,
    [state, isLessThanMinimum, isFWLoading, isFWProcessing, isDepoConfLoading, isFranc, isFundingFranco]
  );

  // manage modal back button
  // hide back button
  useEffect(() => {
    if (state?.hasPrev) {
      set({ hasPrev: false, onClick: () => {} });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <VStack as="form" p={{ base: "0px 20px", "1sm": "48px 34px", "3sm": "68px" }} onSubmit={handleSubmit}>
      <FormControl>
        <InputLabel htmlFor="via">Fund Via</InputLabel>
        <Select
          id="withdraw-via"
          placeholder="Choose option"
          value={state?.via ?? ""}
          onChange={(e) => set({ via: e.target.value as IState["via"] })}
        >
          {options.map((opt) => (
            <option key={`withdraw-option-${opt.value}`} value={opt.value}>
              {opt.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl mt={{ base: "22px !important", "1sm": "42px !important" }}>
        <InputLabel isInvalid={isLessThanMinimum} htmlFor="via" isLoading={isDepoConfLoading}>
          Amount
        </InputLabel>
        <AmountInput
          isRequired
          isInvalid={isLessThanMinimum}
          placeholder="Enter amount"
          value={state?.amount ?? ""}
          onChange={(e) => set({ amount: e.target.value })}
        />
      </FormControl>

      {/* <ConditionalRender shouldRender={configs.francoCoins.includes(coin) && state?.via === "momo"}>
        <FormControl minH={{ base: "56px", "1sm": "60px" }} mt={{ base: "16px !important", "2sm": "42px !important" }}>
          <InputLabel isLoading={isLoading} htmlFor="withdraw-account">
            Phone Number
          </InputLabel>
          <Input
            type="text"
            isRequired
            placeholder="Enter Mobile Money "
            id="phoneNumber"
            min={0}
            name="bankAccountNumber"
            onChange={(e) => set({ bank: e.target.value })}
          />
        </FormControl>
      </ConditionalRender> */}

      <ConditionalRender shouldRender={configs.francoCoins.includes(coin) && state?.via === "momo"}>
        <FormControl minH={{ base: "56px", "1sm": "60px" }} mt={{ base: "16px !important", "2sm": "42px !important" }}>
          <InputLabel isLoading={isLoading} htmlFor="funding-account">
            Select Account
          </InputLabel>
          <Select
            id="withdraw-account"
            placeholder="Select account"
            value={state?.bank ?? ""}
            onChange={(e) => set({ bank: e.target.value })}
          >
            {(accounts ?? []).map((acc: any) => (
              <option key={`bank-${acc?._id}`} value={acc?._id}>
                {acc?.bankName} - {acc?.bankAccountNumber}
              </option>
            ))}

            {accounts?.length < 1 && <option disabled>You haven't added a {state?.via} account.</option>}
          </Select>

          {accounts?.length < 1 && (
            <FormHelperText fontWeight="500">
              <HStack>
                <Text color={color("grey.300", "grey.200")}>You haven't added a {state?.via} account. Add it</Text>
                <Link
                  ml="3px !important"
                  to={"/account/momo"}
                  color={color("secondary.400", "secondary.400")}
                  fontWeight="500"
                  onClick={onClose}
                >
                  here
                </Link>
              </HStack>
            </FormHelperText>
          )}
        </FormControl>
      </ConditionalRender>

      <FormControl>
        {/* <FormInfo mt="10px" info="Transaction Fee:" description={currencyFormat(coin as any).format(fee)} /> */}
        <FormInfo info="Minimum deposit:" description={currencyFormat(coin as any).format(min)} />
      </FormControl>

      <VStack mt={{ base: "96px !important", "1sm": "100px !important" }} width={{ base: "100%", "3sm": "initial" }}>
        <Button
          type="submit"
          fontFamily="var(--bitmama-fonts-heading)"
          disabled={isDisabled}
          isLoading={isFundingFranco}
          minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
        >
          Submit
        </Button>
      </VStack>
    </VStack>
  );
}

function AwaitFrancDeposit(props: ViewProps) {
  const { reset, coin } = props;
  const cacheKey = useDepositCacheKey();
  const { onClose, updateModal } = useAbstractModal();
  // const { onNext, hasPrev, onPrev, currentView } = useModalView();

  const prevStat = useRef("pending");

  const cardBg = useColorModeValue("rgba(49, 183, 169, 0.10)", "linear-gradient(269deg, #00000029, #58d58617)");

  const depositCache = useMemo(() => {
    const dep = ls.get(cacheKey) as FundWalletRo;
    if (!!dep) return dep;
    return null;
  }, [cacheKey]);

  const {
    data: depositData,
    isLoading,
    refetch,
  } = useGetFrancoDepositQuery(depositCache?._id!, { skip: !depositCache?._id, pollingInterval: 2000 });
  const [cancelDeposit, { isLoading: isCancelling }] = useCancelFundDepositMutation();

  const deposit = useMemo(() => depositData ?? depositCache, [depositCache, depositData]);
  console.log("Deposit Cache", deposit);

  useEventListener("refetch_deposit", (e) => {
    console.log("Refetch Deposit", e);
    refetch();
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleCancel = async () => {
    if (!depositCache?._id) return;

    try {
      const cancelledDepo = await cancelDeposit(depositCache?._id!).unwrap();
      console.log("Cancelled Deposit", cancelledDepo);
      if (!!cancelledDepo) {
        ls.remove(cacheKey);
        handleClose();
      }
    } catch (error) {
      console.log(error, "THE ERROR");
    }
  };

  useUpdateEffect(() => {
    if (!!deposit?.status && !isEqual(prevStat.current, deposit?.status)) {
      prevStat.current = deposit?.status!;
      if (deposit?.status === "approved") {
        ls.remove(cacheKey);

        updateModal &&
          updateModal({
            isSuccess: true,
            success: {
              description: `Your wallet has been successfully funded with ${currencyFormat(coin as any).format(
                +(deposit?.amount?.$numberDecimal ?? 0)
              )}.`,
            },
          });

        reset();
      }
    }
  }, [prevStat, deposit]);

  return (
    <VStack p={{ base: "34px 20px", "1sm": "48px 50px", "3sm": "45px 100px" }}>
      <VStack m="0 auto" mb={{ base: "0", "1sm": "40px" }}>
        <Heading as="h6" fontSize="18px">
          Fund Wallet
        </Heading>
        <Text
          fontSize="16px"
          fontWeight="500"
          textAlign="center"
          lineHeight="28px"
          margin={{ base: "20px 0 39px !important", "1sm": "40px 0 0  !important" }}
        >
          Funding wallet successfully initiated, kindly copy transaction ref id to complete transaction on the third party. Your
          wallet will be credited once transaction complete on third party app
        </Text>
      </VStack>

      <Card
        minW={{ base: "initial", "2sm": "428px" }}
        width={{ base: "100%", "2sm": "initial" }}
        px={{ base: "20px", "2sm": "40px" }}
        py={{ base: "20px", "2sm": "40px" }}
        borderRadius="25px"
        shadow="none"
        bg={cardBg}
      >
        <List>
          <Item
            name="Total Amount To Pay:"
            // value={depositCache?.amount?.$numberDecimal ?? "0.00"}
            value={currencyFormat(coin as any).format(Number(deposit?.amount?.$numberDecimal ?? "0"))}
          />
          <Item name="Transaction Reference:" value={deposit?.reference ?? ""} canCopy />
          <Item name="Status:" value={deposit?.status ?? "Pending"} _value={{ textTransform: "capitalize" }} />
        </List>
      </Card>

      <VStack mt={{ base: "0", "1sm": "24px !important" }} px={{ base: "0", "1sm": "46px" }} w="100%">
        <ConditionalRender shouldRender={deposit?.status === "pending"}>
          <Button
            onClick={handleCancel}
            isLoading={isCancelling}
            disabled={isCancelling || isLoading}
            minW="unset"
            w="100%"
            fontFamily="var(--bitmama-fonts-heading)"
          >
            Cancel
          </Button>
        </ConditionalRender>

        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={handleClose}>
          Close
        </Button>
      </VStack>
    </VStack>
  );
}

function ConfirmDeposit(props: ViewProps) {
  const { state, set, reset, coin } = props;

  const cacheKey = useDepositCacheKey();

  const { onNext, hasPrev, onPrev, currentView } = useModalView();
  const { onClose } = useAbstractModal();

  const [fundWallet, { isLoading }] = useFundFiatWalletMutation();

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const reqData = {
      currency: coin,
      amount: +state?.amount!,
      depositType: state?.via === "bank_transfer" ? "bank" : state?.via,
    };

    Emitter.emit(AppAnalyticEvent.FUNDING_INITIATED, transformFiatDepositDataToEngagement(reqData));

    const result = await fundWallet(reqData as FundWalletDto).unwrap();
    if (!!result) {
      Emitter.emit(AppAnalyticEvent.FUNDING_SUBMITTED, transformFiatDepositDataToEngagement(reqData));
      ls.set(cacheKey, result);
      onNext();
    }
  };

  // manage modal back button
  // hide back button
  useEffect(() => {
    if (!state?.hasPrev) {
      set({ hasPrev, onClick: onPrev, currentView });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <VStack p={{ base: "34px 20px", "1sm": "48px 50px", "3sm": "45px 100px" }}>
      <VStack m="0 auto" mb={{ base: "0", "1sm": "40px" }}>
        <Heading as="h6" fontSize="18px">
          Confirm Deposit
        </Heading>
        <Text
          fontSize="16px"
          fontWeight="500"
          textAlign="center"
          lineHeight="28px"
          margin={{ base: "20px 0 39px !important", "1sm": "40px 0 0  !important" }}
        >
          3rd party payments will not be approved and cannot be refunded.
        </Text>
      </VStack>

      <VStack mt={{ base: "0", "1sm": "24px !important" }} px={{ base: "0", "1sm": "46px" }} w="100%">
        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading}
          minW="unset"
          w="100%"
          fontFamily="var(--bitmama-fonts-heading)"
        >
          Continue
        </Button>

        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={handleClose}>
          Close
        </Button>
      </VStack>
    </VStack>
  );
}

function UploadProof(props: ViewProps) {
  const { state, set /*, reset , coin */ } = props;
  const { onClose } = useAbstractModal();
  const { setCurrentView, hasPrev, onPrev, currentView } = useModalView();
  // const { updateModal } = useAbstractModal();

  const { deposit: depositData } = useDeposit();

  // const cacheKey = useDepositCacheKey();

  // const depositData = useMemo(() => {
  //   const dep = ls.get(cacheKey) as FundWalletRo;
  //   if (!!dep) return dep;
  //   return null;
  // }, [cacheKey]);

  const [isUploading, setIsUploading] = useState(false);

  const [uploadProof, { isLoading /*, isSuccess, isError, data, reset: resetApiState */ }] = useUploadFiatFundingProofMutation();
  // const [cancelDeposit, { isLoading: isCancelling, isError: isCancelError, isSuccess: isCancelSuccess }] =
  //   useCancelFundDepositMutation();

  // const handleCancel = async () => {
  //   if (depositData?._id) cancelDeposit(depositData?._id);
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!state?.paymentProof || !depositData?._id!) return;

    try {
      setIsUploading(true);
      const res = await uploadFile(state?.paymentProof, `${nanoid(20)}-fiat-fund-receipt`);
      setIsUploading(false);
      !!res?.location && (await uploadProof({ id: depositData?._id!, paymentUploadUrl: res?.location }));
      setCurrentView(3);
    } catch (error) {
      setIsUploading(false);
    }
  };

  // manage modal back button
  // hide back button
  useEffect(() => {
    if (!state?.hasPrev) {
      set({ hasPrev, onClick: onPrev, currentView });
    }

    // eslint-disable-next-line
  }, []);

  const isSubmiting = useMemo(() => isUploading || isLoading, [isUploading, isLoading]);
  const isDisabled = useMemo(() => !state?.paymentProof || isSubmiting /*|| isCancelling */, [state, isSubmiting]);

  // useEffect(() => {
  //   if (isSuccess && !isError && data) {
  //     setCurrentView(3);
  //     ls.remove(cacheKey);

  //     updateModal &&
  //       updateModal({
  //         isSuccess: true,
  //         success: {
  //           description: `Your deposit will be processed and your wallet will be funded with ${currencyFormat(coin as any).format(
  //             +(state?.amount ?? 0)
  //           )} soon.`,
  //         },
  //       });

  //     reset();
  //   }

  //   if (isSuccess) {
  //     set({ currentView: 1 });
  //   }

  //   return () => {
  //     resetApiState();
  //   };
  // }, [isSuccess, isError, data, onNext, reset, resetApiState, state?.amount, updateModal, set, coin, cacheKey]);

  // useEffect(() => {
  //   if (isCancelSuccess && !isCancelError) {
  //     ls.remove(cacheKey);
  //     onClose();
  //     reset && reset();
  //   }

  //   // eslint-disable-next-line
  // }, [isCancelSuccess, isCancelError, onClose, reset]);

  return (
    <VStack as="form" p={{ base: "0px 20px", "2sm": "45px 50px", "3sm": "45px 100px" }} onSubmit={handleSubmit}>
      <VStack maxW={{ base: "100%", "2sm": "320px" }} m="0 auto" mb={{ base: "25px", "2sm": "40px" }}>
        <Heading as="h6" fontSize="18px">
          Upload Proof of Payment
        </Heading>
        <Text textAlign="center" mt="20px">
          Kindly upload proof of payment in order for your money to reflect in your Bitmama Wallet
        </Text>
      </VStack>

      <Uploader onFiles={(files) => set({ paymentProof: files[0] })} />

      <VStack mt={{ base: "37px !important", "2sm": "74px !important" }} w={{ base: "100%", "2sm": "initial" }}>
        <Button
          minW={{ base: "100%", "2sm": "330px", "4sm": "350px" }}
          w={{ base: "100%", "2sm": "initial" }}
          onClick={handleSubmit}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isSubmiting}
          disabled={isDisabled}
        >
          Upload
        </Button>
        <Button
          variant="transparent"
          textDecoration="underline"
          minW="unset"
          w="100%"
          // onClick={() => setCurrentView(3)}
          onClick={() => onClose()}
          // onClick={handleCancel}
          // isLoading={isCancelling}
          // disabled={isSubmiting || isCancelling}
        >
          Skip
        </Button>
      </VStack>
    </VStack>
  );
}

function BankInfo(props: ViewProps) {
  const { state, set, reset, currentView } = props;
  const { onClose } = useAbstractModal();
  const { onNext } = useModalView();

  const currency = useCurrency();
  const cacheKey = useDepositCacheKey();

  const { deposit: depositData } = useDeposit();

  // const depositData = useMemo(() => {
  //   const dep = ls.get(cacheKey) as FundWalletRo;
  //   if (!!dep) return dep;
  //   return null;
  // }, [cacheKey]);

  const cardBg = useColorModeValue("rgba(49, 183, 169, 0.10)", "linear-gradient(269deg, #00000029, #58d58617)");

  const [cancelDeposit, { isLoading: isCancelling }] = useCancelFundDepositMutation();

  const [markAsPaid, { isLoading: isMarking }] = useMarkFiatFundingAsPaidMutation();

  const handleCancel = useCallback(async () => {
    if (!depositData?._id) return;
    const result = await cancelDeposit(depositData?._id).unwrap();
    if (!!result) {
      reset();
      onClose();
      ls.remove(cacheKey);
    }
    // try {
    //   if (depositData?._id) {
    //     await cancelDeposit(depositData?._id);
    //   }
    // } catch (error) {
    //   console.log(error, "THE ERROR");
    // }
  }, [cancelDeposit, depositData?._id, onClose, reset, cacheKey]);

  const handleMarkAsPaid = async () => {
    if (!depositData?._id) return;
    const result = await markAsPaid(depositData?._id!).unwrap();
    if (!!result && !(result as any)?.error) {
      onNext();
    }
  };

  const isDisabled = useMemo(() => isCancelling || isMarking, [isCancelling, isMarking]);

  // manage modal back button
  // hide back button
  useEffect(() => {
    if (state?.hasPrev) {
      set({ hasPrev: false, onClick: () => {}, currentView });
    }

    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (isCancelSuccess && !isCancelError) {
  //     reset();
  //     onClose();
  //     ls.remove(cacheKey);
  //   } else if (isCancelError) {
  //     reset();
  //     onClose();
  //     ls.remove(cacheKey);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isCancelSuccess, isCancelError]);

  const coin = useMemo(() => {
    return depositData?.currency ?? currency ?? "ngn";
  }, [depositData?.currency, currency]);

  // console.log("Deposit Data", depositData);

  return (
    <VStack p={{ base: "0px 20px", "2sm": "45px 50px", "3sm": "45px 100px" }}>
      <VStack maxW={{ base: "100%", "2sm": "320px" }} m="0 auto" mb="40px">
        <Text textAlign="center" mt={{ base: "0", "2sm": "20px" }}>
          Use the bank details below to fund your wallet. Once you have made the transfer, proceed by clicking on the button below.
        </Text>
      </VStack>

      <Card
        minW={{ base: "initial", "2sm": "428px" }}
        width={{ base: "100%", "2sm": "initial" }}
        px={{ base: "20px", "2sm": "40px" }}
        py={{ base: "20px", "2sm": "40px" }}
        borderRadius="25px"
        shadow="none"
        bg={cardBg}
      >
        <HStack pb={{ base: "20px", "2sm": "40px" }} justifyContent="center">
          <Avatar boxSize="42px" />
          <Text fontWeight="500">{depositData?.depositBankTo?.bankName ?? "First City Monument Bank"}</Text>
        </HStack>

        <List>
          <Item name="Account Name:" value={depositData?.depositBankTo?.bankAccountName ?? "Bitmama Inc."} />
          <Item name="Account Number:" value={depositData?.depositBankTo?.bankAccountNumber ?? "0123456789"} canCopy />
          <Item name="Transaction Reference:" value={depositData?.reference ?? ""} canCopy />
          <Divider />
          <Item
            name="Amount to Pay:"
            // value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal ?? 0))}
            value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal) + Number(depositData?.fee))}
          />
          <Item
            name="Amount to be credited:"
            // value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal) - Number(depositData?.fee))}
            value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal))}
          />
        </List>
      </Card>

      <VStack mt="74px !important" w={{ base: "100%", "2sm": "initial" }}>
        <Button
          textTransform="unset"
          minW={{ base: "100%", "2sm": "330px", "4sm": "350px" }}
          w={{ base: "100%", "2sm": "initial" }}
          fontFamily="var(--bitmama-fonts-heading)"
          onClick={handleMarkAsPaid}
          isLoading={isMarking}
          disabled={isDisabled}
        >
          Mark as paid
        </Button>
        <Button
          variant="transparent"
          textDecoration="underline"
          minW="unset"
          w="100%"
          onClick={handleCancel}
          isLoading={isCancelling}
          disabled={isDisabled}
        >
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function DepositInfo(props: ViewProps) {
  const { state, set, currentView } = props;
  const { onClose } = useAbstractModal();
  const { setCurrentView } = useModalView();

  const prevDepositStatus = useRef("none");

  const currency = useCurrency();

  const cacheKey = useDepositCacheKey();
  const cachedDeposit = useMemo(() => ls.get(cacheKey) as FundWalletRo, [cacheKey]);
  const { deposit: depositData } = useDeposit({ depositId: cachedDeposit?._id, pollInterval: 10000 });

  console.log("Deposit Info", depositData);

  const cardBg = useColorModeValue("rgba(49, 183, 169, 0.10)", "linear-gradient(269deg, #00000029, #58d58617)");

  // manage modal back button
  // hide back button
  useEffect(() => {
    if (state?.hasPrev) {
      set({ hasPrev: false, onClick: () => {}, currentView });
    }

    // eslint-disable-next-line
  }, []);

  useUpdateEffect(() => {
    if (!isEqual(prevDepositStatus.current, depositData?.status)) {
      prevDepositStatus.current = depositData?.status;
      if (depositData?.status === "denied") return setCurrentView(5);
      else if (depositData?.status === "approved") return setCurrentView(6);
    }
  }, [depositData]);

  const coin = useMemo(() => {
    return depositData?.currency ?? currency ?? "ngn";
  }, [depositData?.currency, currency]);

  const amount = useMemo(() => {
    return +(depositData?.amount?.$numberDecimal ?? 0);
  }, [depositData?.amount]);

  // console.log("Deposit Data", depositData);

  return (
    <VStack p={{ base: "0px 20px", "2sm": "45px 50px", "3sm": "45px 100px" }}>
      <VStack maxW={{ base: "100%", "2sm": "320px" }} m="0 auto" mb="40px">
        <Text textAlign="center" mt={{ base: "0", "2sm": "20px" }} fontWeight="500">
          Your deposit will be processed and your wallet will be funded with {currencyFormat(coin as any).format(amount)} soon.
        </Text>
      </VStack>

      <Card
        minW={{ base: "initial", "2sm": "428px" }}
        width={{ base: "100%", "2sm": "initial" }}
        px={{ base: "20px", "2sm": "40px" }}
        py={{ base: "20px", "2sm": "40px" }}
        borderRadius="25px"
        shadow="none"
        bg={cardBg}
      >
        <HStack pb={{ base: "20px", "2sm": "40px" }} justifyContent="center">
          <Avatar boxSize="42px" />
          <Text fontWeight="500">{depositData?.depositBankTo?.bankName ?? "First City Monument Bank"}</Text>
        </HStack>

        <List>
          <Item name="Account Name:" value={depositData?.depositBankTo?.bankAccountName ?? "Bitmama Inc."} />
          <Item name="Account Number:" value={depositData?.depositBankTo?.bankAccountNumber ?? "0123456789"} canCopy />
          <Item name="Transaction Reference:" value={depositData?.reference ?? ""} canCopy />
          <Divider />
          <Item
            name="Total amount to be paid:"
            // value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal ?? 0))}
            value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal) + Number(depositData?.fee))}
            canCopy
          />
          <Item
            name="Amount you receive:"
            // value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal) - Number(depositData?.fee))}
            value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal))}
          />
          <Item
            name="Transaction Fee:"
            // value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal) - Number(depositData?.fee))}
            value={currencyFormat(coin as any).format(Number(depositData?.fee))}
          />
          <Item
            name="Status"
            // value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal) - Number(depositData?.fee))}
            value={String(depositData?.status ?? "paid").toUpperCase()}
          />
          {!!depositData?.paymentUploadUrl && (
            <Item
              name="Payment proof"
              // value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal) - Number(depositData?.fee))}

              link={{
                onClick: () => window.open(depositData?.paymentUploadUrl),
                children: "View Proof",
                color: "secondary.500",
              }}
            />
          )}
          {!depositData?.paymentUploadUrl && (
            <Item
              name="Payment proof"
              // value={currencyFormat(coin as any).format(Number(depositData?.amount?.$numberDecimal) - Number(depositData?.fee))}

              link={{
                onClick: () => setCurrentView(4),
                children: "Upload Proof",
                color: "white",
                textDecoration: "none",
                bg: "secondary.500",
                borderRadius: "26px",
                p: "8px 20px",
                _hover: {
                  textDecoration: "none",
                  bg: "secondary.600",
                },
              }}
            />
          )}
        </List>
      </Card>

      <VStack mt="74px !important" w={{ base: "100%", "2sm": "initial" }}>
        <Button
          variant="transparent"
          textDecoration="underline"
          minW="unset"
          w="100%"
          onClick={onClose}
          // isLoading={isCancelling}
          // disabled={isDisabled}
        >
          Close
        </Button>
      </VStack>
    </VStack>
  );
}

function DepositDenied(props: ViewProps) {
  const { reset } = props;
  const currency = useCurrency();
  const { setCurrentView } = useModalView();
  const { onClose } = useAbstractModal();
  const cacheKey = useDepositCacheKey();
  const deposit = useMemo(() => ls.get(cacheKey) as FundWalletRo, [cacheKey]);

  // reset deposit fetch configs incase the user decides to perform another transaction
  useDeposit({ depositId: null, pollInterval: 0 });

  const coin = useMemo(() => {
    return deposit?.currency ?? currency ?? "ngn";
  }, [deposit?.currency, currency]);

  const amount = useMemo(() => {
    return +(deposit?.amount?.$numberDecimal ?? 0);
  }, [deposit?.amount]);

  const handleAnotherTx = () => {
    reset();
    setCurrentView(0);
    ls.remove(cacheKey);
  };

  const handleClose = () => {
    onClose();
    ls.remove(cacheKey);
  };

  return (
    <VStack p={{ base: "20px 24px", "2sm": "48px" }}>
      <VStack maxW={{ base: "100%", "2sm": "420px" }} m="0 auto" mb="40px">
        <Icon mb="24px !important" type="errorMoji" boxSize="62px" />
        <Heading
          mt={{ base: "0 !important", "2sm": "initial" }}
          as="h4"
          fontSize="24px"
          fontWeight="600"
          mb={{ base: "20px !important", "2sm": "30px !important" }}
        >
          Deposit Denied
        </Heading>
        <Text textAlign="center" mt={{ base: "0", "2sm": "20px" }} fontWeight="500">
          Your deposit of {currencyFormat(coin as any).format(amount)} was denied, kindly reach out to our customer service if you
          have any complaints.
        </Text>
      </VStack>

      <VStack
        px={{ base: "20px", "2sm": "108px" }}
        mb={{ base: "20px !important", "2sm": "40px !important" }}
        w="100%"
        justifyContent="center"
      >
        <Button variant="solid" minW="unset" maxW="unset" w="100%" onClick={handleAnotherTx}>
          Perform another transaction
        </Button>

        <Button variant="transparent" minW="unset" maxW="unset" w="100%" onClick={handleClose}>
          Close
        </Button>
      </VStack>
    </VStack>
  );
}

function DepositSuccess(props: ViewProps) {
  const { reset } = props;

  const { onClose } = useAbstractModal();
  const { setCurrentView } = useModalView();

  const cacheKey = useDepositCacheKey();
  // reset deposit fetch configs incase the user decides to perform another transaction
  useDeposit({ depositId: null, pollInterval: 0 });

  const handleAnotherTx = () => {
    reset();
    setCurrentView(0);
    ls.remove(cacheKey);
  };

  const handleClose = () => {
    ls.remove(cacheKey);
    onClose();
  };

  return (
    <VStack p={{ base: "20px 24px", "2sm": "48px" }}>
      <Box mb="24px !important" as={RocketSVG} />

      <Heading
        mt={{ base: "0 !important", "2sm": "initial" }}
        as="h4"
        fontSize="24px"
        fontWeight="600"
        mb={{ base: "20px !important", "2sm": "30px !important" }}
      >
        Success
      </Heading>

      <VStack mt="0 !important">
        <Text
          maxW="320px"
          mb={{ base: "20px !important", "2sm": "40px !important" }}
          color="brand.greyText"
          fontWeight="500"
          textAlign="center"
        >
          Your deposit was approved and your wallet has been successfully funded
        </Text>
      </VStack>

      <VStack
        px={{ base: "20px", "2sm": "108px" }}
        mb={{ base: "20px !important", "2sm": "40px !important" }}
        w="100%"
        justifyContent="center"
      >
        <Button variant="solid" minW="unset" maxW="unset" w="100%" onClick={handleAnotherTx}>
          Perform another transaction
        </Button>

        <Button variant="transparent" minW="unset" maxW="unset" w="100%" onClick={handleClose}>
          Close
        </Button>
      </VStack>
    </VStack>
  );
}

function Views(props: ViewProps) {
  const { state, set, coin } = props;
  const { setCurrentView, currentView } = useModalView();
  // const { onClose } = useAbstractModal();

  const prevDeposit = useRef<any>(null);
  const cacheKey = useDepositCacheKey();

  const { deposit: data } = useDeposit();

  // const [fetchConfig, setFetchConfig] = usePartialState<FetchConfigType>({ depositId: null, pollInterval: 0 });

  // // const cachedDeposit = useMemo(() => ls.get(cacheKey) as FundWalletRo, [cacheKey]);
  // const { data, isLoading, refetch } = useGetLatestFiatDepositQuery(fetchConfig?.depositId, {
  //   pollingInterval: fetchConfig?.pollInterval,
  // });

  const isFranc = useMemo(() => configs.francoCoins.includes(coin), [coin]);
  const canView = useMemo(() => ["bank_transfer", "momo"].includes(state?.via!), [state?.via]);
  const depositData = useMemo(() => {
    if (!!data) return data as FundWalletRo;
    return null;
    // return ls.get(cacheKey) as FundWalletRo;
  }, [data]);

  console.log("Latest Desposit", depositData);

  useEffect(() => {
    // const depositData = ls.get(cacheKey) as FundWalletRo;
    if (!!depositData?._id && !isEqual(prevDeposit.current, depositData)) {
      prevDeposit.current = depositData;
      ls.set(cacheKey, depositData); // make sure the deposit cache is update to date.
      if (!isFranc) {
        set({ via: "bank_transfer" });
        if (depositData?.status === "pending" && currentView !== 2) setCurrentView(2);
        else if (depositData?.status === "paid" && currentView !== 3) setCurrentView(3);
        // else if (depositData?.status === "paid" && !depositData?.paymentUploadUrl) setCurrentView(3);
        // else if (depositData?.status === "paid" && !!depositData?.paymentUploadUrl) setCurrentView(4);
      } else {
        set({ via: "momo" });
        setCurrentView(1);
      }
    }
    // else if (!depositData?._id && currentView > 2) {
    //   onClose();
    // }
    set({ currentView });
    // eslint-disable-next-line
  }, [set, depositData]);

  return (
    <ViewSwitcher>
      <Fund {...props} />
      {canView && isFranc && <AwaitFrancDeposit {...props} />}
      {canView && !isFranc && <ConfirmDeposit {...props} />}
      {canView && !isFranc && <BankInfo {...props} />}
      {canView && !isFranc && <DepositInfo {...props} />}
      {canView && !isFranc && <UploadProof {...props} />}
      {canView && !isFranc && <DepositDenied {...props} />}
      {canView && !isFranc && <DepositSuccess {...props} />}
    </ViewSwitcher>
  );
}

type FetchConfigType = { depositId: string | null; pollInterval: number };
interface IDepositContext {
  state: ReturnType<typeof usePartialState<IState>>[0];
  set: ReturnType<typeof usePartialState<IState>>[1];
  reset: ReturnType<typeof usePartialState<IState>>[2];
  deposit: FundWalletRo;
  isLoading: boolean;
  setFetchConfig: (config: Partial<FetchConfigType>) => void;
}

const DepositContext = createContext<IDepositContext | null>(null);

function DepositProvider(props: Pick<IDepositContext, "state" | "set" | "reset"> & { children: any }) {
  const { children, state, set, reset } = props;

  const [fetchConfig, setFetchConfig] = usePartialState<FetchConfigType>({ depositId: null, pollInterval: 0 });

  // const cachedDeposit = useMemo(() => ls.get(cacheKey) as FundWalletRo, [cacheKey]);
  const { data, isLoading, refetch } = useGetLatestFiatDepositQuery(fetchConfig?.depositId, {
    pollingInterval: fetchConfig?.pollInterval,
  });

  const deposit = when<FundWalletRo>(!!data?._id, data!, (data as any)?.deposit!);

  const handleFetchConfig = (config: Partial<FetchConfigType>) => {
    if (isEqual(fetchConfig, config)) return;
    setFetchConfig(config);
  };

  useEventListener("refetch_deposit", (e) => {
    console.log("Refetch Deposit", e);
    refetch();
  });

  return (
    <DepositContext.Provider value={{ state, set, reset, deposit, isLoading, setFetchConfig: handleFetchConfig }}>
      {children}
    </DepositContext.Provider>
  );
}

export function useDeposit(config?: Partial<FetchConfigType>) {
  const context = useContext(DepositContext);
  if (!context) throw new Error(`useDeposit should be used within the DepositContext Provider`);
  if (!!config) context.setFetchConfig(config);
  return context;
}

export default function FundFiatModal(props: FundFiatModalProps) {
  const { isOpen, onClose, coin } = props;

  const [state, set, reset] = usePartialState<IState>({
    hasPrev: false,
    onClick: () => {},
    currentView: 0,
  });

  const viewCount = useMemo(() => state.currentView === 1, [state.currentView]);
  const currentViewCount = useMemo(() => state.currentView, [state.currentView]);

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxWidth: { base: "425px", "1sm": "520px", "4sm": "640px" },
        minH: { base: viewCount ? "initial" : "100vh", "1sm": "initial" },
        height: { base: viewCount ? "385px" : "initial", "1sm": "initial" },
        borderRadius: { base: viewCount ? "30px 30px 0px 0px" : "0", "1sm": "20px" },
        pb: { base: viewCount ? "0" : "48px", "1sm": "0" },
        padding: "0",
        bottom: { base: viewCount ? "0" : "initial", "1sm": "initial" },
        position: { base: viewCount ? "absolute" : "initial", "1sm": "initial" },
        margin: { base: "0", "1sm": "initial" },
      }}
    >
      <DepositProvider state={state} set={set} reset={reset}>
        {(state?.hasPrev! || currentViewCount === 0) && (
          <BackButton
            display={{
              "1sm": currentViewCount === 0 ? "none" : "block",
            }}
            left={{ base: "15px", "2sm": "26px" }}
            onClick={currentViewCount === 0 ? onClose : state?.onClick}
          />
        )}

        <ModalView>
          <Box
            mt={{ base: currentViewCount === 3 || currentViewCount === 0 ? "48px" : "0px", "1sm": "0" }}
            padding={{ base: viewCount || currentViewCount === 3 ? "10px 20px" : "20px", "1sm": "0" }}
            cursor="pointer"
            display={{ base: "block", "1sm": "none" }}
          >
            <Text color="brand.black" fontSize="18px" fontWeight="600">
              {viewCount ? "" : "Fund wallet"}
            </Text>
          </Box>

          <Views {...{ state, set, reset, coin: coin ?? "ngn" }} />
        </ModalView>
      </DepositProvider>
    </AbstractModal>
  );
}

const countryMap = (curr: string) => {
  curr = String(curr).trim().toLowerCase();
  const map: Record<string, string> = {
    ngn: "NG",
    kes: "KE",
    ghs: "GH",
  };

  return map[curr] || "";
};

function useFlutterWaveCheckout(props: any) {
  const { currency, state, user, reset, onClose } = props;
  const scriptUrl = "https://checkout.flutterwave.com/v3.js";
  const checkout = useRef((window as any)?.FlutterwaveCheckout);

  type IState = {
    status: string;
  };

  const [, set] = usePartialState<IState>();

  const [fundWallet, { isLoading }] = useFundFiatWalletMutation();
  const [processPayment, { isLoading: isProcessing }] = useProcessPaymentMutation();

  const cacheKey = useDepositCacheKey();
  const [cancelDeposit] = useCancelFundDepositMutation();

  const makeCancelDepositRequest = async (depositData: FundWalletRo) => {
    if (!depositData?._id) return;

    try {
      const result = await cancelDeposit(depositData?._id).unwrap();
      if (!!result) {
        reset();
        onClose();
        ls.remove(cacheKey);
      }
    } catch (error) {
      console.error("[FLUTTERWAVE_DEPOSIT::OnCancel]", error);
    }
  };

  const makePayment = (payload: any) => {
    if (checkout?.current) {
      const pay = checkout.current;
      // console.log("checkout", pay);
      Emitter.emit(
        AppAnalyticEvent.FUNDING_INITIATED,
        transformFiatDepositDataToEngagement({ ...payload, depositType: "flutterwave" })
      );

      pay(payload);
    } else throw new Error("Failed to launch checkout action");
  };

  const handleSubmit = async () => {
    await appendScriptPromise(scriptUrl);
    checkout.current = (window as any)?.FlutterwaveCheckout;

    const obj = {
      currency: currency ?? "ghs",
      amount: +(state?.amount ?? 0),
      depositType: "flutterwave",
    };

    const depositData = await fundWallet(obj as any).unwrap();

    // const userData = depositData?.userDetails;

    console.log("FLUTTER DEPOSIT DATA", obj, depositData, configs.FLUTTERWAVE_PUBKEY);

    depositData &&
      makePayment({
        public_key: configs.FLUTTERWAVE_PUBKEY,
        tx_ref: depositData?.reference,
        amount: Number(depositData?.amount["$numberDecimal"]),
        currency: String(depositData?.currency).toUpperCase(),
        country: countryMap(depositData?.currency),
        payment_options: "card, ussd",
        customer: {
          email: user?.email,
          phone_number: user?.phone,
          name: `${user?.fullName}`,
        },
        onclose: async (incomplete: any) => {
          console.log("YOU JUST CANCELLED THE FLUTTERWAVE INLINE PAYMENT", incomplete);
          await makeCancelDepositRequest(depositData);
        },
        callback: async function (data: any) {
          // specified callback function
          if (data?.status === "successful") {
            try {
              Emitter.emit(
                AppAnalyticEvent.FUNDING_SUBMITTED,
                transformFiatDepositDataToEngagement({
                  currency: String(depositData?.currency).toUpperCase(),
                  amount: Number(depositData?.amount["$numberDecimal"]),
                  depositType: "flutterwave",
                })
              );
              // attempt to fast track receipt, incase webhook failed
              if (data?.transaction_id) processPayment({ txId: data.transaction_id });
              else if (data.id) processPayment({ txId: data.id });
              // console.log("posted flutterwave payment processing", data);
            } catch (err) {
              console.log("error posting flutterwave payment processing");
            }

            await new Promise((resolve) => {
              setTimeout(() => resolve(true), 1000);
            });
            removeScript(scriptUrl);
            window.location.replace(window.location.origin + `/wallet?coin=${currency}&subpage=Fiat`);
          } else {
            if (2 + 2 === 4) return; //TODO: debug the setState
          }
        },
        customizations: {
          title: `Funds Deposit`,
          description: `Deposit of ${String(depositData?.currency).toUpperCase()}${Number(depositData?.amount["$numberDecimal"])}`,
          logo: "https://prod-doc.fra1.cdn.digitaloceanspaces.com/btm-assets/logo.png",
        },
      });
  };

  useEffect(() => {
    (async () => {
      await appendScriptPromise(scriptUrl);
      checkout.current = (window as any)?.FlutterwaveCheckout;

      const status = window?.location?.pathname?.substring(31);
      set({ status });
      // console.log("Checkout", checkout, (window as any)?.FlutterwaveCheckout);
    })();
    return () => {
      removeScript(scriptUrl);
    };

    //eslint-disable-next-line
  }, []);

  return { submit: handleSubmit, isLoading, isProcessing };
}

function useDepositCacheKey() {
  const { profile } = useSelector(selectUser);
  return configs.FIAT_DEPOSIT_KEY(profile?._id!);
}
