import {
  Box,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  BoxProps,
} from "@chakra-ui/react";
import { FC } from "react";

interface InputProps extends ChakraInputProps {
  startAdornment?: any;
  endAdornment?: any;
  _startAdornment?: BoxProps;
  _endAdornment?: BoxProps;
  disabled?: boolean;
}

const Input: FC<InputProps> = (props) => {
  const { startAdornment, endAdornment, _startAdornment } = props;

  return (
    <InputGroup>
      {startAdornment && (
        <InputLeftElement
          h="100%"
          children={
            <Box pl="20px" {..._startAdornment}>
              {startAdornment}
            </Box>
          }
        />
      )}
      <ChakraInput
        isRequired
        // minH="56px"
        border="1px solid transparent"
        // borderRadius="12px"
        errorBorderColor="red.400"
        // bg="brand.neutral"
        pl={startAdornment ? "52px" : "20px"}
        pr={endAdornment ? "62px" : "20px"}
        {...props}
      />
      {endAdornment && <InputRightElement h="100%" children={endAdornment} />}
    </InputGroup>
  );
};

export default Input;
