import { Button, Heading, Text, useColorModeValue, useMediaQuery, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { AbstractModal, Icon } from "components";

interface SignupModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SignupModal(props: SignupModalProps) {
  const { isOpen, onClose } = props;

  const color = useColorModeValue("grey.400", "white");

  const handleProceed = () => {
    navigate("/verify");
    onClose();
  };

  const [isMobile] = useMediaQuery("(max-width: 425px)");

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      showCloseButton={false}
      _content={{ maxW: isMobile ? "370px" : "560px" }}
    >
      <VStack p={{ base: "32px 8px", md: "74px" }}>
        <VStack m="0 auto" mb="40px">
          <Icon mb="24px !important" type="coffee" boxSize="56px" />

          <Heading as="h4" fontSize="24px" mb="24px !important">
            You’re almost In!
          </Heading>
          {/* <Text textAlign="center" mt="20px" color={color}>
            Complete the security operation by clicking{" "}
            <Button
              fontSize="14px"
              maxW="unset"
              minH="unset"
              maxH="unset"
              px="1"
              py="0"
              variant="transparent"
              minW="unset"
              h="fit-content"
            >
              SEND CODE
            </Button>{" "}
            for Email & Phone verification, which will be sent to your email and phone respectively
          </Text> */}
          <Text textAlign="center" mt="20px" color={color}>
            Complete the security operation by clicking on the "send code" on the verification page, which will be sent to your
            email and phone respectively
          </Text>
        </VStack>

        <VStack px="46px" w="100%">
          <Button
            minW="unset"
            w={{ base: "initial", md: "100%" }}
            maxW="399px"
            fontFamily="var(--bitmama-fonts-heading)"
            rightIcon={<Icon type="arrowRight" color="#fff" mt="4px" />}
            onClick={handleProceed}
          >
            Proceed to verification
          </Button>
          <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose}>
            Back
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
