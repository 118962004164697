import { FC, useMemo } from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { Card, DetailItem, GroupProps, MainLayoutContainer, PageLoading, Title, TitleBar } from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { format, parseISO } from "date-fns";

import { useGetGreenboxTransactionDetailsQuery } from "apis";
import { AnimatePresence, motion } from "framer-motion";
import { currencyFormat, isFiat, toPrecision, when } from "utils";
import { capitalize } from "lodash";

export interface GreenBoxTransactionsProps extends Omit<GroupProps, "key">, SidePageViewProps {}
const PreviewTransactions: FC<GreenBoxTransactionsProps> = () => {
  const { params } = useSidePage();
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");
  const { data, isLoading } = useGetGreenboxTransactionDetailsQuery(params?.id as string, { skip: !params?.id });

  const datetime = useMemo(() => {
    if (!!data && !!data?.createdAt) return format(parseISO(data?.createdAt!), "dd MMM, yyyy, hh:mm aa");
    return "";
  }, [data]);

  const amount = useMemo(() => {
    if (!data || !data?.unit) return "0.00";
    if (!!data?.unit && isFiat(data?.unit)) return currencyFormat(data?.unit as any).format(+data?.value?.$numberDecimal! ?? 0);
    if (!!data?.unit && !isFiat(data?.unit)) return toPrecision(+data?.value?.$numberDecimal! ?? 0, data?.unit);

    return "0.00";
  }, [data]);

  return (
    <Box px={{ base: "16px", md: "40px" }} overflow="hidden">
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={when(isLoading, "loading", "loaded")}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
        >
          <TitleBar>
            <Title fontSize="20px">Transaction Details</Title>
          </TitleBar>

          {isLoading && <PageLoading py="120px" isLoading={isLoading} />}

          {!isLoading && (
            <>
              <MainLayoutContainer maxW="2xl" mt="28px" px="0">
                <Card bg={bg} w="100%" p={{ base: "16px", md: "24px 34px" }} overflow="hidden">
                  <DetailItem title="Transaction Type" description={capitalize(data?.actionType)} />
                  <DetailItem title="Date & Time" description={datetime} />
                  <DetailItem title="Amount" description={amount} />
                  <DetailItem title="Transaction Ref" description={data?._id} isCopyable />
                </Card>
              </MainLayoutContainer>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default PreviewTransactions;

PreviewTransactions.displayName = "SidePageView";
