import { RepeatIcon } from "@chakra-ui/icons";
import { Box, BoxProps, HStack, Text, TextProps, useColorMode, VStack } from "@chakra-ui/react";
import Icon from "components/Icon/Icon";
import { ModalView, useModalView, ViewSwitcher, _ViewSwitcherChildProps } from "components/ModalView";
import { useDefaultStyle } from "hooks";
import { isEmpty } from "lodash";
import { PropsWithChildren, useEffect, useMemo, useRef } from "react";
import { switchStyle, when } from "utils";

type VariantType = "default" | "verification";
interface StepViewsProps {
  max?: number;
  current?: number;
  labels?: string[];
  stepsCompleted?: boolean | boolean[];
  hideIndicator?: boolean;
  variant?: VariantType;
  onStepsLoaded?: (props: _ViewSwitcherChildProps) => void;
}

interface StepsProps extends StepViewsProps {
  max: number;
}

interface IndicatorProps {
  index: number;
  isCurrent: boolean;
  isLast: boolean;
  labels?: string[];
  stepsCompleted?: boolean | boolean[];
  variant: VariantType;
}

interface IndicatorsProps {
  //   max: number;
  variant: VariantType;
  labels?: string[];
  stepsCompleted?: boolean | boolean[];
}

interface IndicatorStyle {
  container: BoxProps;
  label: TextProps & { txWidth: number; show: boolean };
  button: BoxProps;
}

function Point(props: any) {
  // return <Box boxSize="10px" bg="white" borderRadius="50%" />;
  return <RepeatIcon color="currentColor" />;
}

function Indicator(props: IndicatorProps) {
  const { index, isCurrent, isLast, labels, stepsCompleted, variant } = props;

  const txRef = useRef<HTMLElement>();

  const { colorMode } = useColorMode();
  const { shadow, borderColor } = useDefaultStyle();
  const { setCurrentView, nextButtonProps, currentView } = useModalView();

  const bg = switchStyle(colorMode, {
    light: when(isCurrent, "#31B7A9", "#31B7A950"),
    dark: when(isCurrent, "#31B7A9", "#31B7A950"),
  });

  const txWidth = useMemo(() => txRef.current?.getBoundingClientRect().width ?? 41.6, [txRef]);

  const label = useMemo(() => {
    if (!!labels && !isEmpty(labels)) {
      if (labels[index]) return labels[index];
      else return index + 1;
    }

    return index + 1;
  }, [labels, index]);

  const isCompleted = useMemo(() => {
    if (stepsCompleted && !Array.isArray(stepsCompleted)) return !!stepsCompleted;
    else if (Array.isArray(stepsCompleted) && !isEmpty(stepsCompleted)) return stepsCompleted[index];
    else return true;
  }, [stepsCompleted, index]);

  // console.log(`Step ${index + 1} Completed`, isCompleted);

  const isDisabled = useMemo(
    () => when(index > currentView, Boolean(nextButtonProps?.disabled), false),
    [index, currentView, nextButtonProps]
  );

  const styles = useMemo(() => {
    const map: Record<VariantType, IndicatorStyle> = {
      default: {
        container: {},
        label: { txWidth: 32, display: "none", show: false },
        button: { children: index + 1 },
      },
      verification: {
        container: { _after: { borderStyle: "solid", borderColor: "#0D47403f" } },
        label: { show: true, txWidth, color: "primary.default", fontSize: "14px", fontWeight: "500" },
        button: {
          bg: "primary.default",
          mt: "6px !important",
          // children: when(isDisabled, undefined, <Icon type="check" />),
          children: when(isDisabled, undefined, isCompleted ? <Icon type="check" /> : <Point />),
          _disabled: { cursor: "not-allowed", opacity: 0.5 },
        },
      },
    };

    return map[variant];
  }, [variant, txWidth, index, isDisabled, isCompleted]);

  // console.log(`${index + 1} is disabled::${isDisabled}`, { ...nextButtonProps, currentView });

  return (
    <VStack
      ml={`calc(var(--stick-offset) * ${when(isLast, 1, index)}) !important`}
      mr={when(isLast, `0 !important`, `var(--stick-offset) !important`)}
      pos="relative"
      sx={{
        "--stick-len": "102px",
        "--indicator-w": "32px",
        "--stick-offset": "6px",
      }}
      left={`calc(var(--stick-len) * ${index})`}
      {...styles.container}
      _after={{
        content: '""',
        position: "absolute",
        w: "var(--stick-len)",
        h: "2px",
        borderRadius: "10px",
        bottom: "calc(var(--indicator-w) / 2)",
        left: `calc(${styles.label.txWidth}px + var(--stick-offset))`,
        borderBottom: "1px dashed #CACBCA",
        display: when(isLast, "none", "inline-block"),
        ...styles.container?._after,
      }}
    >
      {/* {styles.label.show && ( */}
      <Text ref={txRef as any} pos="relative" {...styles.label}>
        {label}
      </Text>
      {/* )} */}

      <Box
        as="button"
        ml="0 !important"
        fontSize="14px"
        fontWeight="700"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxSize="var(--indicator-w)"
        borderRadius="50%"
        color="white"
        bg={bg}
        pos="relative"
        _focus={{
          shadow,
          borderColor,
        }}
        onClick={() => {
          setCurrentView(index);
        }}
        // {...{ isDisabled }}
        disabled={isDisabled}
        _disabled={{ cursor: "not-allowed" }}
        zIndex={2}
        {...styles.button}
      >
        {styles.button.children}
      </Box>
    </VStack>
  );
}

function Indicators(props: IndicatorsProps) {
  const { labels, stepsCompleted, variant } = props;
  const { currentView: current, maxViews: max } = useModalView();

  return (
    <HStack>
      {Array(max)
        .fill(0)
        .map((_, i) => (
          <Indicator
            key={`steps-indicator-${i}`}
            index={i}
            isCurrent={i === current}
            isLast={i === max - 1}
            labels={labels}
            stepsCompleted={stepsCompleted}
            variant={variant}
          />
        ))}
    </HStack>
  );
}

export default function StepViews(props: PropsWithChildren<StepViewsProps>) {
  const { max = 3, labels, children, variant = "default", hideIndicator, stepsCompleted, onStepsLoaded } = props;
  return (
    <ModalView>
      {!hideIndicator && <Indicators variant={variant} labels={labels} stepsCompleted={stepsCompleted} />}
      <Steps max={max} onStepsLoaded={onStepsLoaded}>
        {children}
      </Steps>
    </ModalView>
  );
}

function Steps(props: PropsWithChildren<StepsProps>) {
  const { children, onStepsLoaded } = props;

  const fxProps = useModalView();

  useEffect(() => {
    if (!!onStepsLoaded) onStepsLoaded({ ...fxProps });
    // eslint-disable-next-line
  }, []);

  return <ViewSwitcher>{children}</ViewSwitcher>;
}
