import { Box, Grid, Heading } from "@chakra-ui/react";
import { EmptyCrate, MobileTradeItem, PageLoading, Paginator, TradeItem } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { useTradeHistory } from "hooks";
import { PageProps } from "interfaces";
import { useState } from "react";
import { when } from "utils";

import isEmpty from "lodash/isEmpty";
import { navigate } from "@reach/router";

interface PendingPanelProps extends PageProps {}

export default function PendingPanel(props: PendingPanelProps) {
  const [state, set] = useState({ page: 1, limit: 5 });
  const { count, data, isLoading, isFetching } = useTradeHistory({ ...state }, "open");
  console.log("Pending Trades", data, count);

  return (
    <Box>
      <Heading mt="45px" mb="52px" as="h5" fontSize="lg">
        Pending Trades
      </Heading>

      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={`trades-${when(isLoading, "loading", "loaded")}`}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
        >
          {((isLoading && isEmpty(data)) || isFetching) && <PageLoading my="30px" isLoading={isLoading} />}

          {!isLoading && !isEmpty(data) && (
            <Grid templateRows=".3fr repeat(3, auto)">
              {(data ?? []).map((ro, i) => (
                <TradeItem
                  display={{ sm: "none", "3sm": "grid", md: "grid" }}
                  key={`trade-item-${i}`}
                  status="pending"
                  {...ro}
                  onClick={() => navigate(`/p2p/order/${ro?.tradeRef}`)}
                />
              ))}
            </Grid>
          )}

          {!isLoading && !isEmpty(data) && (
            <Grid templateRows=".3fr repeat(3, auto)">
              {(data ?? []).map((ro, i) => (
                <MobileTradeItem
                  display={{ sm: "grid", "3sm": "none", md: "none" }}
                  key={`trade-item-${i}`}
                  status="pending"
                  {...ro}
                  onClick={() => navigate(`/p2p/order/${ro?.tradeRef}`)}
                />
              ))}
            </Grid>
          )}

          {!isLoading && isEmpty(data) && <EmptyCrate my="80px" type="p2p" description="You don't have pending trades" />}
        </motion.div>
      </AnimatePresence>

      {count > state.limit && (
        <Paginator
          flexDir="row"
          totalCount={count}
          page={state?.page}
          limit={state.limit}
          onPageChange={(page: number) => set((state) => ({ ...state, page }))}
        />
      )}
    </Box>
  );
}
