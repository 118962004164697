import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  IconButton,
  Input,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useTurnOnAndOff2FAMutation, useVerify2FAPinMutation } from "apis";
import { DownloadOnAppleStoreSVG, DownloadOnGooglePlaySVG } from "assets";
import { Card, CopyButton, Icon, InputLabel, Steps, useModalView } from "components";
import { useCopy, useDefaultStyle, useDispatch, usePartialState, useSelector } from "hooks";
import React, { useMemo, useState } from "react";
import { getProfile } from "store/slices";
import { generate2FA } from "store/slices/auth";
import { SuccessModal } from "ui";
import Disable2FAModal from "ui/Account/Modals/Profile/Disable2FA";

interface IState {
  isOn: boolean;
}

interface IndexProps {
  goBack: () => void;
  twoFaStatus: boolean;
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
}

export default function TwoFactorAuthentication(props: any) {
  const { setCurrentView } = useModalView();
  const { profile } = useSelector((state) => state.user);
  const twoFaStatus = useMemo(() => profile?.twoFactor, [profile]);

  const [state, set] = usePartialState<IState>({
    isOn: twoFaStatus ?? false,
  });

  return (
    <Steps hideIndicator>
      <Index goBack={() => setCurrentView(0)} twoFaStatus={twoFaStatus!} set={set} state={state} />
      <LinkTwoFA goBack={() => setCurrentView(0)} twoFaStatus={twoFaStatus!} set={set} state={state} />
      <EnterOTP goBack={() => setCurrentView(0)} twoFaStatus={twoFaStatus!} state={state} set={set} />
    </Steps>
  );
}

function Index(props: IndexProps) {
  const { twoFaStatus, set, state } = props;

  const { setCurrentView } = useModalView();

  const dispatch = useDispatch();
  const { shadow, borderColor } = useDefaultStyle();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const tColor = useColorModeValue("grey.400", "grey.100");
  const dColor = useColorModeValue("grey.500", "grey.150");
  const noteColor = useColorModeValue("primary.800", "secondary.600");

  const isDisabled = useMemo(() => !state?.isOn, [state]);

  const handle2FA = () => {
    dispatch(generate2FA());
    setCurrentView(1);
  };

  const handleTurnOff2FA = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    set({ isOn: checked });
    if (!!twoFaStatus) {
      // setCurrentView(2);
      onOpen();
    }
  };

  return (
    <Box p="6px">
      <HStack>
        <IconButton
          size="xs"
          minW="fit-content"
          maxW="fit-content"
          minH="fit-content"
          maxH="fit-content"
          p="0 !important"
          bg="transparent"
          variant="transparent"
          aria-label="back button"
          icon={<Icon boxSize="20px" type="circleLeftArrow" />}
          onClick={props.goBack}
        />

        <Heading as="h6" fontSize="16px">
          Two-factor Authentication
        </Heading>
      </HStack>

      <Box>
        <Text fontSize="14px" fontWeight="500" color={tColor} mt="24px" mb="20px">
          Turn on 2FA validation for your account. This is recommended so as to keep your account safe.
        </Text>
        <Text fontSize="14px" fontWeight="500" color={noteColor} mt="0px" mb="46px">
          <strong>Note:</strong> Before you turn this on, download the Google Authenticator so that you can get the codes for
          whenever you login to the App
        </Text>
      </Box>

      <FormControl as={HStack} w="100%" alignItems="center" justifyContent="space-between" my="24px !important">
        <InputLabel fontSize="md" htmlFor="2fa" _text={{ mb: 0, fontWeight: "600" }}>
          Google Authenticator
        </InputLabel>
        <Switch
          id="2fa"
          size="sm"
          colorScheme="secondary"
          defaultChecked={twoFaStatus}
          isChecked={state.isOn}
          onChange={handleTurnOff2FA}
        />
      </FormControl>
      <Text fontSize="sm" maxW="246px" fontWeight="500" color={tColor}>
        Protect your Account and Transactions with Google Authenticator
      </Text>

      {state?.isOn && (
        <VStack mt="68px">
          <Card p="20px" borderRadius="20px" bg="#FAF3D45f" shadow="none" maxW="380px" textAlign="center">
            <Heading as="h5" fontSize="md">
              Download Google Authenticator App
            </Heading>

            <Text mt="20px" textAlign="center" fontSize="14px" fontWeight="500" color={dColor}>
              Download the Authenticator App below from your App Store or Google Play Store, and click the continue button when
              you’re done.
            </Text>

            <HStack justifyContent="center" mt="36px">
              <a href="https://play.google.com/store/apps/details?id=com.bitmama.bitmama" target="_blank" rel="noopener noreferrer">
                <Box as="button" borderRadius="10px" _focus={{ shadow, borderColor }}>
                  <Box as={DownloadOnGooglePlaySVG} />
                </Box>
              </a>

              <a href="https://apps.apple.com/us/app/bitmama/id1561857024" target="_blank" rel="noopener noreferrer">
                <Box as="button" borderRadius="10px" _focus={{ shadow, borderColor }}>
                  <Box as={DownloadOnAppleStoreSVG} />
                </Box>
              </a>
            </HStack>
          </Card>
        </VStack>
      )}

      {!twoFaStatus && (
        <VStack my="100px">
          <Button
            fontFamily="var(--bitmama-fonts-heading)"
            disabled={isDisabled}
            onClick={handle2FA}
            minW={{ base: "100%", "4sm": "400px" }}
          >
            Continue
          </Button>
        </VStack>
      )}

      <Disable2FAModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

function LinkTwoFA(props: IndexProps) {
  const { setCurrentView, onPrev } = useModalView();
  const { data } = useSelector((state) => state.auth.twoFa);

  const { onCopy } = useCopy(data?.secret ?? "");
  const tColor = useColorModeValue("white", "black");

  const [_turnOn, { isLoading }] = useTurnOnAndOff2FAMutation();

  const turnOn2FA = async () => {
    await _turnOn("turnOnTwoFa");

    setCurrentView(2);
  };

  return (
    <Box p="6px">
      <HStack mb="12px">
        <IconButton
          size="xs"
          minW="fit-content"
          maxW="fit-content"
          minH="fit-content"
          maxH="fit-content"
          p="0 !important"
          bg="transparent"
          variant="transparent"
          aria-label="back button"
          icon={<Icon boxSize="20px" type="circleLeftArrow" />}
          onClick={onPrev}
        />

        <Heading as="h6" fontSize="md">
          Link your Bitmama Account
        </Heading>
      </HStack>

      <Text fontSize="sm" fontWeight="500">
        To add your Bitmama Account, click the <strong>“+”</strong> button on the Google Authentication App and scan the{" "}
        <strong>QR code</strong> below. Or enter the <strong>code</strong> below and click <strong>“continue”</strong>
      </Text>

      <Card mt="26px" borderRadius="20px" textAlign="center" py="20px" w="100%" shadow="0px 4px 10px rgba(67, 55, 55, 0.12)">
        <VStack px="60px">
          <Text fontSize="14px" fontWeight="600" maxW="234px">
            Scan this QR Code in the Google Authenticator App
          </Text>

          <Box
            mt="34px !important"
            mb="42px !important"
            bgRepeat="no-repeat"
            bgSize="contain"
            bgImage={`url(${data.qrCodeLink})`}
            height="217px"
            width="217px"
          />

          <Text fontSize="12px" fontWeight="600" maxW="242px">
            If you are unable to scan the QR code, please enter the code below manually into the App
          </Text>

          <Box borderRadius="8px" p="12px 28px" bg="secondary.400">
            <HStack>
              <Text
                // fontSize="14px"
                fontSize={{ base: "10px", "2sm": "14px" }}
                maxWidth={{ base: "170px", "2sm": "270px" }}
                fontWeight="600"
                color={tColor}
                // maxWidth="270px"
                width="100%"
              >
                {data.secret}
              </Text>
              <CopyButton color="primary.800" onClick={onCopy} />
            </HStack>
          </Box>
        </VStack>
      </Card>

      <VStack my="100px">
        <Button
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={turnOn2FA}
          minW={{ base: "100%", "4sm": "400px" }}
        >
          Continue
        </Button>
      </VStack>
    </Box>
  );
}

function EnterOTP(props: IndexProps) {
  // const { set, state } = props;
  const { onPrev, setCurrentView } = useModalView();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const tColor = useColorModeValue("grey.400", "grey.100");
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    setCurrentView(0);
    dispatch(getProfile());
    navigate("/account/profile/?tab=security", { replace: true });
  };

  const [twoFaCode, setCode] = useState("");
  const [_verify2FACode, { isLoading }] = useVerify2FAPinMutation();

  const handleFinish = async (code?: string) => {
    const response: any = await _verify2FACode({
      twoFaCode: code ?? twoFaCode,
    }).unwrap();

    if (!!response) {
      onOpen();
    }
  };

  const handleCode = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCode(value);
    if (value.length === 6) {
      await handleFinish(value);
    }
  };

  // const [_turnOff, { isLoading: turnOffLoading }] = useTurnOnAndOff2FAMutation();

  // const handleTurnOff2FA = async () => {
  //   const response: any = await _turnOff("turnOffTwoFa");

  //   if (response?.data === null) {
  //     dispatch(getProfile());

  //     onOpen();
  //   }

  //   set({ isOn: false });
  // };

  return (
    <Box p="6px">
      <HStack mb="12px">
        <IconButton
          size="xs"
          minW="fit-content"
          maxW="fit-content"
          minH="fit-content"
          maxH="fit-content"
          p="0 !important"
          bg="transparent"
          variant="transparent"
          aria-label="back button"
          icon={<Icon boxSize="20px" type="circleLeftArrow" />}
          onClick={onPrev}
        />

        <Heading as="h6" fontSize="md">
          Enter OTP
        </Heading>
      </HStack>

      <Text fontSize="sm" fontWeight="500">
        Enter the 6 digit OTP shown on the Google Authenticator App
      </Text>

      <FormControl mt="28px">
        <Input placeholder="Enter Google Verification Code" onChange={handleCode} />
        <FormHelperText fontSize="sm" fontWeight="500" color={tColor}>
          Enter the 6 digit code from Google Authenticator
        </FormHelperText>
      </FormControl>

      <VStack my="100px">
        <Button
          isLoading={isLoading}
          disabled={twoFaCode.length !== 6 || isLoading}
          fontFamily="var(--bitmama-fonts-heading)"
          onClick={() => handleFinish()}
          minW={{ base: "100%", "4sm": "400px" }}
        >
          Finish
        </Button>
      </VStack>

      <SuccessModal
        isOpen={isOpen}
        onClose={handleClose}
        message={`You have successfully enabled Google Authenticator to protect your Account`}
      />
    </Box>
  );
}
