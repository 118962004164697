import { nanoid } from "nanoid";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Icon, PhotoCapture, Steps, Uploader, useModalView } from "components";
import { VerificationForm } from "interfaces";
import { ReactComponent as Level2IllustrationSVG } from "assets/svg/level2-illustration.svg";
import { uploadFile } from "utils";
import { useVerificationUploadMutation } from "apis";
import { navigate } from "@reach/router";
import { useAuth, useSelector } from "hooks";
import { selectUser } from "store/slices";
import { useKyc } from "Pages";

interface StepThreeProps extends VerificationForm {
  onClose: () => void;
  refetchStatus?: () => void;
}

interface SelfieViewProps extends StepThreeProps {
  setCurrentView: (view: number) => void;
}

export function StepThreeNigerian(props: StepThreeProps) {
  // const { onClose } = props;
  // const toast = useToast();
  // const { formState } = props;
  const { setCurrentView } = useModalView();

  // const illustraBg = useColorModeValue("white", "dark.cardBg");
  // const illustraBorderColor = useColorModeValue("#e6e6e6", "dark.border");

  // const nanoId = nanoid(10);

  // const [file] = useState<File | null>(null);
  // const [loading] = useState(false);

  // const [_verificationUpload, { isLoading, isSuccess, data }] = useVerificationUploadMutation();
  // const [, { isLoading, isSuccess, data }] = useVerificationUploadMutation();

  // const handleFile = async () => {
  //   setLoading(true);
  //   const uploadedFile = await uploadFile(file!, `id-docs-${nanoId}`);

  //   setLoading(false);

  //   if (uploadedFile.location) {
  //     formState.individualLink = uploadedFile.location;
  //     onNext();

  //     await _verificationUpload({
  //       ...formState,
  //       category: formState.docType.split("-")[0],
  //       docType: formState.docType.split("-")[1],
  //     });
  //   }
  // };

  // const isSuccessDisplayed = useRef(false);

  // useEffect(() => {
  //   if (isSuccess && !isSuccessDisplayed.current) {
  //     toast({
  //       title: "Verification",
  //       description: "Your verification request has been sent",
  //       status: "success",
  //       duration: 3000,
  //       isClosable: true,
  //       position: "bottom-right",
  //     });

  //     console.log(data, "DATA");

  //     navigate("/account/verification", { replace: true });
  //     onClose && onClose();
  //     isSuccessDisplayed.current = true;
  //   }

  //   // eslint-disable-next-line
  // }, [data, isSuccess, toast, isSuccessDisplayed]);

  return (
    <Stack p="8px">
      <Stack my="38px !important">
        <Heading as="h6" fontSize="sm">
          Step 3/3 - ID Card Upload
        </Heading>

        <Box mt="24px !important">
          <Text fontSize="sm" fontWeight="600">
            Instructions:
          </Text>
          <UnorderedList mt="12px">
            <ListItem mt="10px" fontSize="14px" fontWeight="500">
              Take a selfie with your device
            </ListItem>
            <ListItem mt="10px" fontSize="14px" fontWeight="500">
              Find good lighting so your face is seen clearly on-screen
            </ListItem>
            <ListItem mt="10px" fontSize="14px" fontWeight="500">
              Try not to smile and remove anything covering your face (e.g glasses, hat or scarf)
            </ListItem>
          </UnorderedList>
        </Box>
      </Stack>

      <Steps hideIndicator>
        <Index setCurrentView={setCurrentView} {...props} />
        <Selfie setCurrentView={setCurrentView} {...props} />
      </Steps>
    </Stack>
  );
}

function Index(props: SelfieViewProps) {
  const { setCurrentView } = props;
  const { onNext } = useModalView();

  return (
    <>
      <VStack mt="34px !important" px="46px" w="100%">
        <Button
          minW="unset"
          // onClick={handleFile}
          w="100%"
          fontFamily="var(--bitmama-fonts-heading)"
          // isLoading={loading || isLoading}
          // disabled={loading || !file || isLoading}
          onClick={onNext}
          rightIcon={<Icon type="camera" />}
        >
          Take Selfie
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={() => setCurrentView(1)}>
          Back
        </Button>
      </VStack>
    </>
  );
}

function Selfie(props: SelfieViewProps) {
  const { formState, onClose } = props;

  const { refetchStatus } = useKyc();
  const { refetchProfile } = useAuth();

  const toast = useToast();
  const { onPrev } = useModalView();
  const { profile } = useSelector(selectUser);

  const photoFilename = `photo_capture__${profile?.lastName ?? ""}__${profile?.country ?? ""}__${Date.now()}`;

  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  // const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCapture = (capFile: File | null, recapture = false) => {
    console.log("Captured File", capFile, recapture);
    setFile(capFile);
  };

  const [_verificationUpload, { isLoading, isSuccess }] = useVerificationUploadMutation();

  const refetchKyc = () => {
    console.log("Should start refetching");
    refetchStatus();
    console.log("Should be done refetching");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!formState?.docLink && !file) return;

    try {
      setIsUploading(true);
      const [photo] = await Promise.all([
        uploadFile(file!),
        // uploadFile(formState.identitficationFile!, `${toLower(formState?.country)}___primaryid___${Date.now()}`),
      ]);

      console.log("Photo", photo);

      if (!!photo.location) {
        formState.individualLink = photo.location;

        const result = await _verificationUpload({
          ...formState,
          category: formState.docType.split("-")[0],
          docType: formState.docType.split("-")[1],
        }).unwrap();

        console.log("Selfie Result", result, isSuccess);
        if (!!result) {
          refetchProfile();
          refetchKyc();
          onClose && onClose();
          navigate("/account/verification", { replace: true });
          // !!refetchStatus && refetchStatus();
          toast({
            title: "Verification",
            description: "Your verification request has been sent",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "bottom-right",
          });
        }
      }
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
    }
  };

  const isDisabled = useMemo(
    () => !formState?.docLink || !file || isUploading || isLoading,
    [formState, file, isLoading, isUploading]
  );

  const isCaptureDisabled = useMemo(() => isUploading || isLoading, [isLoading, isUploading]);

  return (
    <>
      <PhotoCapture
        filename={photoFilename}
        onCapture={handleCapture}
        _capture={{ isDisabled: isCaptureDisabled }}
        _upload={{ isDisabled, onClick: handleSubmit, isLoading: isUploading || isLoading }}
      />
      <VStack mt="34px !important" px="46px" w="100%">
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onPrev}>
          Cancel
        </Button>
      </VStack>

      {/* <SuccessModal isOpen={isOpen} onClose={onClose} message="Level (2) two verification uploaded successfully" /> */}
    </>
  );
}

export function StepThreeOthers(props: StepThreeProps) {
  const { onClose } = props;
  const toast = useToast();
  const { formState } = props;
  const { onNext, onPrev } = useModalView();

  const { refetchStatus } = useKyc();
  const { refetchProfile } = useAuth();
  const illustraBg = useColorModeValue("white", "dark.cardBg");
  const illustraBorderColor = useColorModeValue("#e6e6e6", "dark.border");

  const nanoId = nanoid(10);

  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const [_verificationUpload, { isLoading, isSuccess, data }] = useVerificationUploadMutation();

  const handleFile = async () => {
    setLoading(true);
    const uploadedFile = await uploadFile(file!, `id-docs-${nanoId}`);

    setLoading(false);

    if (uploadedFile.location) {
      formState.individualLink = uploadedFile.location;
      onNext();

      await _verificationUpload({
        ...formState,
        category: formState.docType.split("-")[0],
        docType: formState.docType.split("-")[1],
      });
    }
  };

  const isSuccessDisplayed = useRef(false);

  useEffect(() => {
    if (isSuccess && !isSuccessDisplayed.current) {
      toast({
        title: "Verification",
        description: "Your verification request has been sent",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });

      console.log(data, "DATA");
      refetchProfile();
      refetchStatus();
      navigate("/account/verification", { replace: true });
      onClose && onClose();
      isSuccessDisplayed.current = true;
    }

    // eslint-disable-next-line
  }, [data, isSuccess, toast, isSuccessDisplayed]);

  return (
    <Stack p="8px">
      <Stack my="38px !important">
        <Heading as="h6" fontSize="sm">
          Step 3/3 - ID Card Upload
        </Heading>

        <Box mt="16px">
          <Text fontSize="sm" fontWeight="600">
            Instructions:
          </Text>
          <UnorderedList mt="20px">
            <ListItem fontSize="14px" fontWeight="500">
              Hold up your ID Card close to your face, snap and upload image. See illustration below for sample.
            </ListItem>
          </UnorderedList>
        </Box>
      </Stack>

      <VStack mb="40px !important">
        <VStack
          pt="20px"
          minW="100%"
          justifyContent="center"
          alignItems="center"
          bg={illustraBg}
          borderRadius="10px"
          border="1px solid transparent"
          borderColor={illustraBorderColor}
        >
          <Box as={Level2IllustrationSVG} />
        </VStack>

        <Text color="grey.300" fontSize="14px" fontWeight="500">
          Recommend upload specification
        </Text>
      </VStack>

      <FormControl mb="32px !important">
        <Uploader onFiles={(files) => setFile(files[0])} />
      </FormControl>

      <VStack mt="34px !important" px="46px" w="100%">
        <Button
          minW="unset"
          onClick={handleFile}
          w="100%"
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={loading || isLoading}
          disabled={loading || !file || isLoading}
        >
          Submit
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" disabled={loading} onClick={onPrev}>
          Back
        </Button>
      </VStack>
    </Stack>
  );
}
