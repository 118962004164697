import { Box, Button, Flex, FormControl, Heading, Input, Stack, useColorMode, useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate } from "@reach/router";
import { useGetUserCardsQuery } from "apis";
import { FormInfo, InputLabel, Select } from "components";
import { SidePageProvider, SidePageViewProps, useSidePage } from "contexts";
import { isPast } from "date-fns";
import { usePartialState } from "hooks";
import { AppAnalyticEvent } from "interfaces";
import { Emitter } from "libs";
import { useMemo, useState } from "react";
import { SuccessModal } from "ui";
import { get, switchStyle, toQueryString } from "utils";
import { wrapBaseApi } from "utils/apiWrapper";

interface IState {
  startDate: string;
  endDate: string;
  cardId: string;
}

interface IProps extends SidePageViewProps {}

export default function RequestStatementContent(props: IProps) {
  const { colorMode } = useColorMode();
  const [state, set] = usePartialState<IState>();

  const isStartDatePast = useMemo(() => state?.startDate && isPast(new Date(state?.startDate)), [state?.startDate]);
  const isEndDatePast = useMemo(() => state?.endDate && isPast(new Date(state?.endDate)), [state?.endDate]);

  const isDisabled = useMemo(
    () => Boolean(isStartDatePast) && Boolean(isEndDatePast) && state.cardId?.length! > 2,
    [isEndDatePast, isStartDatePast, state.cardId]
  );

  console.log(isDisabled, "IS DISABLED");

  const queries = {
    active: true,
    showDeleted: true,
  };
  const { data, isLoading } = useGetUserCardsQuery(toQueryString({ ...queries }));

  const cards = useMemo(() => data?.card ?? [], [data]);

  const selectedCard = useMemo(() => (cards ?? []).find((card) => card?.cardId === state?.cardId), [state?.cardId, cards]);

  console.log("Cards [ReqStatement]", selectedCard);

  const navigate = useNavigate();
  const { isOpen, onOpen } = useDisclosure();
  const { onClose } = useSidePage();

  const handleClose = () => {
    onClose();
    navigate("/cards", { replace: true });
  };

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!state?.cardId && !selectedCard) return;

    setLoading(true);

    const eventData = {
      from: state?.startDate,
      to: state?.endDate,
      cardName: selectedCard?.userDetails?.fullName,
      cardType: selectedCard?.cardType?.type,
    };

    try {
      const URL = wrapBaseApi(
        `/wallet/card/generate-card-tx-report?cardId=${state.cardId}&startDate=${state.startDate}&endDate=${state.endDate}`
      );
      const response: any = await get(URL);

      if (response) {
        Emitter.emit(AppAnalyticEvent.REQUEST_CARD_STATEMENT, eventData);
        setLoading(false);
        onOpen();
      }
    } catch (error: any) {
      setLoading(false);

      toast({
        title: "Error",
        description: error?.error,
        position: "bottom-right",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <SidePageProvider>
      <Box px={{ base: "8px", "2sm": "20px", "4sm": "32px" }} height="100vh">
        <Heading
          color="#041815"
          textAlign={{ base: "center", md: "initial" }}
          fontSize={{ base: "18px", md: "24px" }}
          fontWeight="600"
          mb="32px"
        >
          Request Card Statement
        </Heading>

        <form onSubmit={handleSubmit}>
          <Stack spacing={{ base: "24px", md: "29px" }}>
            <Flex gridGap={{ base: "30px", md: "9px" }}>
              <FormControl>
                <InputLabel htmlFor="from">From</InputLabel>
                <Input
                  minH="56px"
                  type="date"
                  id="from"
                  value={state?.startDate ?? ""}
                  onChange={(e) => set({ startDate: e.target.value })}
                  isRequired
                />

                {!isStartDatePast && state.startDate && (
                  <FormInfo
                    isInvalid={!isStartDatePast}
                    info=""
                    mt="8px"
                    fontSize="12px"
                    description="The start date must not be earlier than today"
                  />
                )}
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="to">To</InputLabel>
                <Input
                  minH="56px"
                  type="date"
                  id="to"
                  value={state?.endDate ?? ""}
                  onChange={(e) => set({ endDate: e.target.value })}
                  isRequired
                />

                {!isEndDatePast && state.endDate && (
                  <FormInfo
                    mt="8px"
                    isInvalid={!isEndDatePast}
                    info=""
                    fontSize="12px"
                    description="The end date must not be earlier than today"
                  />
                )}
              </FormControl>
            </Flex>

            <Box>
              <FormControl>
                <InputLabel htmlFor="card" isLoading={isLoading}>
                  Select Card
                </InputLabel>

                <Select
                  id="card"
                  isRequired
                  minH="56px"
                  fontWeight="500"
                  bg={switchStyle(colorMode, { dark: "dark.cardBg", light: "#f2f2f2" })}
                  border="1px solid #A1A3A2 !important"
                  _hover={{
                    borderWidth: "1px",
                    borderColor: switchStyle(colorMode, { dark: "secondary.400 !important", light: "primary.600 !important" }),
                    bg: switchStyle(colorMode, { dark: "dark.cardBg", light: "#f6f6f6" }),
                  }}
                  _focus={{
                    shadow: "none",
                    borderWidth: "1px",
                    borderColor: switchStyle(colorMode, { dark: "secondary.400 !important", light: "primary.600 !important" }),
                    bg: switchStyle(colorMode, { dark: "dark.cardBg", light: "#f6f6f6" }),
                  }}
                  color={switchStyle(colorMode, { dark: "#fff", light: "#303131" })}
                  onChange={(e) => set({ cardId: e.target.value })}
                >
                  <option value="">Select Card</option>
                  {cards?.map(({ cardNumber, _id, label, cardId, deleted }, index) => (
                    <option key={`${_id}-${index}`} value={cardId}>
                      {label?.toUpperCase()} {cardNumber ? `${cardNumber?.slice(-8)}` : ""} {!!deleted ? `[DELETED]` : ""}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Button
            type="submit"
            mt={{ base: "52px", md: "68px" }}
            maxWidth={{ base: "initial", md: "399px" }}
            isLoading={loading}
            isDisabled={loading || !isDisabled}
            _hover={{
              _disabled: {
                opacity: 0.4,
              },
            }}
          >
            Confirm
          </Button>
        </form>
      </Box>

      <SuccessModal
        isOpen={isOpen}
        onClose={handleClose}
        message="Your Card Statement will be sent to your email shortly"
        _content={{ maxW: "568px", py: "0", px: "20px", height: "auto" }}
        _contentWrapper={{ maxW: "495px", mx: "auto" }}
        _messageProps={{
          mb: "24px",
          mt: "-12px",
          color: "#303131",
          maxWidth: "278px",
          letterSpacing: "-0.5px",
          lineHeight: "27.84px",
        }}
      >
        <Button minW="unset" w="100%" onClick={handleClose} mb={{ base: "32px !important ", md: "38px !important" }}>
          Close
        </Button>

        <Box
          textDecor="underline"
          color="#0D4740"
          fontWeight="500"
          fontSize={{ base: "14px", md: "16px" }}
          onClick={handleClose}
          cursor="pointer"
        >
          Go back to Home
        </Box>
      </SuccessModal>
    </SidePageProvider>
  );
}

RequestStatementContent.displayName = "SidePageView";
