import { createSlice } from "@reduxjs/toolkit";

type DateFilter = {
  afterDate: string;
  beforeDate: string;
};

interface IState {
  leaderboard: DateFilter;
  inviteHistory: DateFilter;
  rewardHistory: DateFilter;
  historyDetails: DateFilter;
  transactionDetails: any;
  cardLabel: string;
}

const initialState: IState = {
  leaderboard: {
    afterDate: "",
    beforeDate: "",
  },
  inviteHistory: {
    afterDate: "",
    beforeDate: "",
  },
  rewardHistory: {
    afterDate: "",
    beforeDate: "",
  },
  historyDetails: {
    afterDate: "",
    beforeDate: "",
  },
  cardLabel: '',
  transactionDetails: {}
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setLeaderboardFilter(state: IState, { payload }) {
      state.leaderboard = payload;
    },
    setInviteHistoryDateFilter(state: IState, { payload }) {
      state.inviteHistory = payload;
    },
    setRewardHistoryDateFilter(state: IState, { payload }) {
      state.rewardHistory = payload;
    },
    setHistoryDetailsDateFilter(state: IState, { payload }) {
      state.historyDetails = payload;
    },
    setTransactions(state: IState, { payload }) {
      console.log(payload, 'the payload');
      state.transactionDetails = payload;
    },
    setCardLabel(state: IState, { payload }) {
      state.cardLabel = payload;
    }
  },
  extraReducers: () => { },
});

export const {
  actions: { setLeaderboardFilter, setInviteHistoryDateFilter, setRewardHistoryDateFilter, setHistoryDetailsDateFilter, setTransactions, setCardLabel },
  reducer: FilterReducer,
} = filterSlice;

export default FilterReducer;
