const supportedOrigins = ["https://dashboard.bitmama.io", "https://www.bitmama.io", "https://bitmama.io"]

export function isSafeRedirect({allowedPrefixes = supportedOrigins, url, skip}: {allowedPrefixes?: string[], url?: string, skip?: boolean}) {
    if(skip) return true;
    if(!url) return false;
    if(url.startsWith("http://")) return false;
    if(url.startsWith("https://")) {
        if(allowedPrefixes.every((prefix) => !url.startsWith(prefix))) return false;
    }
    
    if(!url.startsWith("http") && url.includes("://")) return false;

    return true;
}