import { Stack, StackProps } from "@chakra-ui/react";

export interface GroupProps extends StackProps {}

export default function Group(props: GroupProps) {
  const { children, ...xprops } = props;
  return (
    <Stack className="btm-group" my={{ base: "24px", md: "48px" }} {...xprops}>
      {children}
    </Stack>
  );
}
