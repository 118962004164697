import { Box, BoxProps, HStack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import Icon from "components/Icon/Icon";
import Link, { LinkProps } from "components/Link/Link";
import { useMemo } from "react";

type DirType = "horizontal" | "vertical";

export interface UsernameProps extends BoxProps {
  username: string;
  dir?: DirType;
  hidePresence?: boolean;
  presence?: "online" | "offline";
  level?: string;
  _link?: Omit<LinkProps, "to">;
  _presence?: BoxProps;

  _container?: BoxProps;
  _wrapper?: BoxProps;
}

export default function Username(props: UsernameProps) {
  const { username, presence = "online", dir, hidePresence, _container, _wrapper, _presence, _link, ...xprops } = props;

  const color = useColorModeValue("black", "white");

  const direction = useMemo(() => {
    const map: Record<DirType, any> = {
      horizontal: {
        container: {
          flexDir: "row",
          alignItems: "center",
        },
        presence: {
          mt: "0 !important",
          ml: "10px !important",
        },
      },
      vertical: {
        container: {
          flexDir: "column",
        },
      },
    };

    return map[dir ?? "vertical"];
  }, [dir]);

  const presenceColor = useMemo(() => {
    const map = {
      offline: "error",
      online: "secondary.400",
    };

    return map[presence ?? "online"];
  }, [presence]);

  return (
    <VStack {...direction.container} {..._wrapper} {...xprops}>
      <HStack {..._container}>
        <Link
          // to={`/p2p/advertiser/${username}`}
          to={`#`}
          textDecoration="underline"
          fontFamily="var(--bitmama-fonts-heading)"
          fontSize="14px"
          fontWeight="600"
          color={color}
          {..._link}
        >
          {username ?? ""}
        </Link>
        <Icon type="verifiedUser" boxSize="22px" />
      </HStack>

      {!hidePresence && (
        <HStack color={presenceColor} {...direction.presence} {..._presence}>
          <Box boxSize="12px" bg={presenceColor} borderRadius="50%" />
          <Text fontSize="14px" fontWeight="500" textTransform="capitalize">
            {presence}
          </Text>
        </HStack>
      )}
    </VStack>
  );
}
