import { ConditionalRender, MainLayoutContainer, Marquee, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { PageProps } from "interfaces";
import { Buy, Sell, AirtimeAndData, PlayToEarn } from "ui";
import { when } from "utils";
import Home from "./Home";
import capitalize from "lodash/capitalize";
import { useAppConfig } from "contexts/appconfig.context";
import { useMemo } from "react";
// import ls from 'utils/secureStorage';
// import configs from "config";

export default function Dashboard(props: PageProps) {
  const { user } = props;

  const { wishlist } = useAppConfig();

  // const wishlist_dialog_remind = ls.get(configs.XMAS_REMIND);
  const showWishlistBanner = useMemo(() => !wishlist?.hasApplied && !!wishlist?.isEligible, [wishlist]);

  return (
    <PageMotion key="dashboard">
      <Topbar
        mb={when(showWishlistBanner, "0", "26px")}
        pageTitle={when(!!user?.username, `Welcome back, ${capitalize(user?.username)}!`, "")}
        showTourGuideToggle
        {...props}
      />

      <ConditionalRender shouldRender={showWishlistBanner}>
        <Marquee />
      </ConditionalRender>

      <MainLayoutContainer pb="60px">
        <SidePageProvider>
          <Home {...props} />

          <Sell key="Sell" hasBackButton _back={{ text: "go back" }} {...props} />
          <Buy key="Buy" hasBackButton {...props} />
          <AirtimeAndData key="AirtimeAndData" hasBackButton {...props} />
          <PlayToEarn key="PlayToEarn" hasBackButton _back={{ text: "Close" }} {...props} />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}
