import { Button, FormControl, Heading, Input, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
// import { navigate } from "@reach/router";
import Recaptcha from "react-google-recaptcha";
import { AbstractModal, Icon, InputLabel } from "components";
import PasswordInput from "components/Input/PasswordInput";
import configs from "config";
import { useDispatch, usePartialState, useSelector } from "hooks";
import { LoginDto } from "interfaces";
import { useEffect, useMemo, useRef } from "react";
import { login, selectAuth, selectUser } from "store/slices";

interface SignupModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ILoginState extends LoginDto {}

export default function AuthorizationModal(props: SignupModalProps) {
  const { isOpen, onClose } = props;

  const recaptchaRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { profile } = useSelector(selectUser);
  const { status, payload } = useSelector(selectAuth);

  const [state, set] = usePartialState<ILoginState>({
    email: profile?.email,
  });

  const color = useColorModeValue("grey.400", "grey.100");

  const isLoading = useMemo(() => status === "logging_in", [status]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    console.log("Submit", e);

    const captcha = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();
    dispatch(login({ ...state, humanKey: captcha } as LoginDto));
  };

  const isDisabled = useMemo(() => !(state?.email && state?.password) || isLoading, [state, isLoading]);

  useEffect(() => {
    if (payload?.isSignedIn && payload?.token) {
      // window.location.pathname = configs.paths.dashboard;
      // navigate(configs.paths.dashboard);
      onClose();
    }

    // if (!!payload?.token && !isEmpty(payload?.verification ?? [])) {
    //   navigate(configs.paths.verify);
    // }
  }, [payload, onClose]);

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{ maxW: "560px", overflow: "hidden" }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <VStack p="64px">
        <VStack w="100%" mb="40px">
          <Icon mb="24px !important" type="errorMoji" boxSize="56px" />

          <VStack mb="24px !important">
            <Heading as="h4" fontSize="24px">
              Re:Authorize
            </Heading>
            <Text mt="14px !important" color={color} fontWeight="500">
              Your token expired, login again to referesh your token.
            </Text>
          </VStack>

          <Stack as="form" w="100%" onSubmit={handleSubmit}>
            <FormControl mb="24px !important">
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                isRequired
                type="email"
                id="email"
                placeholder="Enter Email"
                value={state?.email ?? ""}
                onChange={(e) => set({ email: e.target.value })}
              />
            </FormControl>

            <FormControl>
              <InputLabel htmlFor="password">Password</InputLabel>
              <PasswordInput
                id="password"
                isRequired
                placeholder="Enter Password"
                value={state?.password ?? ""}
                onChange={(e) => set({ password: e.target.value })}
              />
            </FormControl>

            <VStack mt="42px !important" px="46px" w="100%">
              <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />
              <Button
                minW="unset"
                w="100%"
                type="submit"
                fontFamily="var(--bitmama-fonts-heading)"
                rightIcon={<Icon type="arrowRight" color="white" />}
                isLoading={isLoading}
                disabled={isDisabled}
              >
                Login
              </Button>
            </VStack>
          </Stack>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
