import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { useGetMinimumWithdrawalAmountQuery, useSellCryptoRateMutation } from "apis";
import { CryptoTxInput, FormInfo, InputLabel, Link, Option, PageLoading, Select } from "components";
import { isTesting } from "config";
import { useBanks, useCoin, useDefaultStyle, usePartialState, useTicker, useWalletBalance } from "hooks";
import useRedebounce from "hooks/useDebounce";
import { PageProps } from "interfaces";
import toLower from "lodash/toLower";
import capitalize from "lodash/capitalize";
import { useMemo } from "react";
import SellCryptoPreview from "ui/Dashboard/Modals/SellCryptoPreview";
import { currencyFormat, toCoinLocale, toPrecision, when } from "utils";
import { useCurrency } from "hooks/useCurrency";
import { useSidePage } from "contexts";

interface SellCryptoFormProps extends PageProps {}

type ViaType = "bank" | "momo";

export interface IState {
  fiat: string;
  crypto: string;
  changeRef: "fiat" | "crypto";
  payTobank?: boolean;
  pin: string;
  via: ViaType;

  bank: string;

  rate: number;
  fiatAmount: string;
  cryptoAmount: string;
}

interface SellToLocalAccountProps {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  isLessThanMinimum: boolean;
  minimum: number;
}

export default function SellCryptoForm(props: SellCryptoFormProps) {
  const { user, isFetchingUser } = props;

  const redebounce = useRedebounce();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { params } = useSidePage();

  const crypto_param = useMemo(() => {
    const value = params?.crypto;
    if (!value || value === "btc") return isTesting ? "tbtc" : "btc";
    return value;
  }, [params]);

  const currency = useCurrency();
  // const defaultFiat = selectCountryCurrency(user?.country ?? "nigeria");

  const { shadow, borderColor } = useDefaultStyle();
  const bg = useColorModeValue("primary.400", "secondary.600");

  const [state, set, reset] = usePartialState<IState>(
    {
      crypto: crypto_param,
      fiat: currency,
      changeRef: "crypto",
      payTobank: false,
    },
    [currency]
  );

  const { data: withdrawConfig, isLoading: isWithdrawConfLoading } = useGetMinimumWithdrawalAmountQuery({
    coin: state?.fiat!,
  });
  const min = useMemo(() => (!!withdrawConfig ? withdrawConfig.min : 0), [withdrawConfig]);

  // console.clear();
  // console.log("Minimum withdraw amount", withdrawConfig);

  const { fiats, cryptos, getBalance, isLoading: isWalletLoading } = useWalletBalance();
  const [_sellCryptoRate, { data: sellRate, isLoading: isLoadingRate }] = useSellCryptoRateMutation();

  // console.log("Cryptos", cryptos);

  // const cryptos = useMemo(
  //   () =>
  //     _cryptos
  //       .map((asset) => {
  //         if (["tbtc", "teth"].includes(asset?.coin))
  //           return {
  //             ...asset,
  //             coin: asset.coin.slice(1),
  //           };
  //         else return asset;
  //       })
  //       .sort((a, b) => a.coin.localeCompare(b.coin)),
  //   [_cryptos]
  // );

  const { getWalletInfo } = useCoin();

  // const withdrawInfo = useMemo(() => {
  //   const map: Record<string, { minimum: number; fee: number }> = {
  //     ghs: { minimum: 100, fee: 100 },
  //     ngn: { minimum: 800, fee: 200 },
  //   };

  //   return map[state?.fiat ?? "ngn"];
  // }, [state?.fiat]);

  const { ticker, getTicker } = useTicker(state?.crypto!, state?.fiat!);
  const rate = useMemo(() => sellRate?.rate, [sellRate]);
  const fee = useMemo(() => sellRate?.fee, [sellRate]);

  const label = useMemo(() => getWalletInfo((state?.fiat ?? "ngn") as any).label, [getWalletInfo, state?.fiat]);
  const balance = useMemo(() => getBalance((state?.crypto ?? "ngn") as any) ?? 0, [getBalance, state?.crypto]);
  const possibleMax = useMemo(() => toPrecision(balance, state?.crypto ?? "btc", false) as string, [balance, state?.crypto]);
  const isInsufficientBalance = useMemo(
    () => !!state?.cryptoAmount && +state?.cryptoAmount > +balance,
    [balance, state?.cryptoAmount]
  );
  const isPageLoading = useMemo(() => isFetchingUser || isWalletLoading, [isFetchingUser, isWalletLoading]);

  // const isLocalSellSupported = useMemo(() => toLower(user?.country ?? "nigeria") !== "kenya", [user?.country]);
  const isLocalSellSupported = useMemo(() => !["any"].includes(toLower(user?.country ?? "nigeria")), [user?.country]);

  const isLessThanMinimum = useMemo(
    () => !!state?.payTobank && !!state?.fiatAmount && +state?.fiatAmount < min,
    [state?.payTobank, state?.fiatAmount, min]
  );

  const isDisabled = useMemo(
    () =>
      !(state?.fiat && state?.fiatAmount && state?.crypto && state.cryptoAmount) ||
      +state?.fiatAmount <= 0 ||
      +state?.cryptoAmount <= 0 ||
      isInsufficientBalance ||
      isPageLoading ||
      isLoadingRate ||
      (state?.payTobank && !(state?.bank && state?.via)) ||
      isLessThanMinimum,
    [state, isPageLoading, isLoadingRate, isInsufficientBalance, isLessThanMinimum]
  );

  console.log("Balance", balance, possibleMax);

  const checkSellRate = (price: number, _ticker?: string, _?: { fiat?: string; coin?: string }) => {
    const reqData = {
      coin: _?.coin ?? state?.crypto,
      fiatCurrency: _?.fiat ?? state?.fiat,
      sellPrice: price,
      amountOfCryptoToSell: price,
      ticker: _ticker ?? ticker,
    };

    _sellCryptoRate({ ...reqData });
  };

  const handleCryptoAmount = (value: string) => {
    set({ cryptoAmount: value, changeRef: "crypto" });
    if (+value <= 0) return;
    redebounce(() => checkSellRate(+value), "checkSellRate", 500)();
  };

  const handleFiatAmount = (value: string) => {
    // if (!(parseFloat(value) <= 0)) value = Number(value).toFixed(2);

    set({ fiatAmount: value, changeRef: "fiat" });
    if (+value <= 0) return;
    redebounce(() => checkSellRate(1), "checkSellRate", 500)();
  };

  const handleCryptoChange = (coin: string) => {
    set({ crypto: coin, changeRef: "crypto" });
    if (+(state?.cryptoAmount ?? 0) <= 0) return;
    const ticker = getTicker(coin, state?.fiat ?? "ngn");
    redebounce(() => checkSellRate(+state?.cryptoAmount!, ticker, { coin }), "checkSellRate", 500)();
  };

  const handleFiatChange = (coin: string) => {
    set({ fiat: coin, changeRef: "crypto" });
    if (+(state?.fiatAmount ?? 0) <= 0) return;
    const ticker = getTicker(state?.crypto!, coin);
    redebounce(() => checkSellRate(1, ticker, { fiat: coin }), "checkSellRate", 500)();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onOpen();
  };

  // track 'fiat' | 'crypto' input changes and calc the
  // the coresponding rate, then update the input field value;
  useUpdateEffect(() => {
    if (rate && state?.fiatAmount && state?.changeRef === "fiat") {
      set({ rate, cryptoAmount: String(+state?.fiatAmount / rate) });
    }
    if (rate && state?.cryptoAmount && state?.changeRef === "crypto") {
      set({ rate, fiatAmount: Number(+state?.cryptoAmount * rate).toFixed(2) });
    }
  }, [rate, state?.fiatAmount, state?.cryptoAmount, state?.changeRef]);

  return (
    <>
      {isPageLoading && <PageLoading isLoading={isPageLoading} />}
      {!isPageLoading && (
        <Stack as="form" py="52px" onSubmit={handleSubmit}>
          <FormControl mb="40px !important">
            <InputLabel isLoading={isWithdrawConfLoading}>You Sell</InputLabel>
            <CryptoTxInput
              isLoading={isLoadingRate}
              isInvalid={isInsufficientBalance}
              placeholder={`Enter ${toCoinLocale(state?.crypto ?? "btc")} Amount`}
              coinValue={state?.crypto}
              value={state?.cryptoAmount ?? ""}
              onMax={() => handleCryptoAmount(String(possibleMax))}
              onCoinChange={(coin) => handleCryptoChange(coin)}
              onChange={(e) => handleCryptoAmount(e.target.value)}
              // _rightEl={{
              //   right: { base: "99px", "2sm": "124px" },
              // }}
            >
              {(cryptos ?? [])?.map((crypto) => (
                <Option key={`buy-crypto-${crypto?.coin}`} value={crypto?.coin} coin={crypto?.coin as any}>
                  {toCoinLocale(crypto?.coin)}
                </Option>
              ))}
            </CryptoTxInput>

            <FormInfo
              isInvalid={isInsufficientBalance}
              mt="10px"
              info="Available Balance: "
              description={`${toPrecision(balance, state?.crypto! as any)}`}
            />
          </FormControl>

          <FormControl>
            <InputLabel>You Receive</InputLabel>
            <CryptoTxInput
              isInvalid={isLessThanMinimum}
              placeholder={`${capitalize(label)} Equivalent`}
              coinValue={state?.fiat}
              value={state?.fiatAmount ?? ""}
              onCoinChange={(coin) => handleFiatChange(coin)}
              onChange={(e) => handleFiatAmount(e.target.value)}
            >
              {(fiats ?? [])?.map((fiat) => (
                <Option key={`buy-fiat-${fiat?.coin}`} value={fiat?.coin} coin={fiat?.coin as any}>
                  {toCoinLocale(fiat?.coin)}
                </Option>
              ))}
            </CryptoTxInput>

            {state?.payTobank && <SellToLocalAccount {...{ state, set, isLessThanMinimum, minimum: min }} />}

            {isLocalSellSupported && (
              <FormHelperText fontWeight="500" mt="14px !important">
                <HStack
                  justifyContent={["flex-start", "space-between"]}
                  alignItems={["flex-start", "flex-start", "center"]}
                  flexDir={["column", "column", "row"]}
                >
                  <Checkbox
                    isChecked={state?.payTobank}
                    colorScheme="primary"
                    onChange={(e) => set({ payTobank: e.target.checked })}
                    sx={{
                      "chakra-checkbox__control[data-focus]": {
                        shadow,
                      },
                      ".chakra-checkbox__control[data-checked], .chakra-checkbox__control[data-checked].chakra-checkbox__control[data-hover]":
                        {
                          bg,
                          borderColor,
                        },
                    }}
                  >
                    Sell to my local account
                  </Checkbox>
                </HStack>
              </FormHelperText>
            )}
          </FormControl>

          <VStack mt="60px !important">
            <Button type="submit" isDisabled={isDisabled} minW={{ base: "100%", smx: "330px", "4sm": "400px" }}>
              Sell
            </Button>
          </VStack>
        </Stack>
      )}

      <SellCryptoPreview state={state} set={set} rate={rate} fee={fee} isOpen={isOpen} onClose={onClose} reset={reset} />
    </>
  );
}

function SellToLocalAccount(props: SellToLocalAccountProps) {
  const { state, set, isLessThanMinimum, minimum } = props;

  const accNullColor = useColorModeValue("grey.300", "grey.200");
  const accNullLinkColor = useColorModeValue("secondary.400", "secondary.400");

  const { banks, isLoading } = useBanks();
  const accounts = useMemo(
    () => (banks ?? []).filter((b) => (state?.via === "bank" ? b?.type !== "mobilemoney" : b?.type === "mobilemoney")),
    [state?.via, banks]
  );
  //  ["xaf", "xof", "cdf"],
  const options = useMemo(() => {
    const map: Record<string, { name: string; value: IState["via"] }[]> = {
      ngn: [
        { name: "Bank Account", value: "bank" },
        // { name: "Mobile Money", value: "momo" },
      ],
      ghs: [
        // { name: "Bank Trasfer", value: "bank" },
        { name: "Mobile Money", value: "momo" },
      ],
      xaf: [{ name: "Mobile Money", value: "momo" }],
      xof: [{ name: "Mobile Money", value: "momo" }],
      cdf: [{ name: "Mobile Money", value: "momo" }],
      zar: [{ name: "Mobile Money", value: "momo" }],

      kes: [{ name: "MPESA", value: "momo" }],
    };

    return map[state?.fiat ?? "ngn"];
  }, [state?.fiat]);

  const addAccountNumberLink = useMemo(() => {
    const map: Record<ViaType, string> = {
      bank: "/account/banks",
      momo: "/account/momo",
    };

    return map[state?.via ?? "momo"];
  }, [state?.via]);

  return (
    <>
      <Box py="38px">
        <Divider />
      </Box>

      <FormControl>
        <InputLabel htmlFor="withdraw-via">Sell To</InputLabel>
        <Select
          id="withdraw-via"
          placeholder="Choose option"
          value={state?.via ?? ""}
          onChange={(e) => set({ via: e.target.value as IState["via"] })}
        >
          {(options ?? []).map((opt) => (
            <option key={`withdraw-option-${opt.value}`} value={opt.value}>
              {opt.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl mt="42px !important">
        <InputLabel isLoading={isLoading} htmlFor="withdraw-account">
          {when(state.via === "bank", "Select Account", state?.via === "momo" ? "Select Mobile Money Agent" : "Select Account")}
        </InputLabel>
        <Select
          id="withdraw-account"
          placeholder="Select account"
          value={state?.bank ?? ""}
          onChange={(e) => set({ bank: e.target.value })}
          isDisabled={!state?.via}
        >
          {(accounts ?? []).map((acc) => (
            <option key={`bank-${acc?._id}`} value={acc?._id}>
              {acc?.bankName} - {acc?.bankAccountNumber}
            </option>
          ))}

          {accounts?.length < 1 && <option disabled>You haven't added a {state?.via} account.</option>}
        </Select>
      </FormControl>

      <FormControl>
        {accounts?.length < 1 && (
          <FormHelperText fontWeight="500">
            <HStack>
              <Text color={accNullColor}>You haven't added a {state?.via} account. Add it</Text>
              <Link ml="3px !important" type="button" to={addAccountNumberLink} color={accNullLinkColor} fontWeight="500">
                here
              </Link>
            </HStack>
          </FormHelperText>
        )}

        <HStack alignItems="flex-start" fontSize="14px" fontWeight="500" mt="38px">
          <Box as="strong" lineHeight={1.25}>
            NOTE:
          </Box>
          <Box>
            <FormHelperText mt="0" color={when(isLessThanMinimum, "red.400", "gray.500")}>
              The minimum amount you can sell to your local account is {currencyFormat(state?.fiat as any).format(minimum)}
            </FormHelperText>
            <FormHelperText color="gray.500" mt="0">
              Request might take 6hrs or less to be processed
            </FormHelperText>
          </Box>
        </HStack>
      </FormControl>
    </>
  );
}
