/**
 * Popmotion wrap function
 * @see https://github.com/Popmotion/popmotion/blob/master/packages/popmotion/src/utils/wrap.ts
 *  */
export const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

export const SWIPE_CONFIDENCE_THRESHOLD = 10000;

export const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
