import { Box, Button, Heading, useColorModeValue } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import { useGetListingByIdQuery, useUpdateListingMutation } from "apis";
import { Icon, MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { useBuiltInFn, usePartialState } from "hooks";
import { P2PListingTradeRo, PageProps } from "interfaces";

import { CreateAdFeature } from "ui";

import toLower from "lodash/toLower";
import { useMemo } from "react";

interface EditAdsPageProps extends PageProps {}

export default function EditAdsPage(props: EditAdsPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const { goBack } = useBuiltInFn();

  const { id } = useParams();

  const [state, set] = usePartialState<{ title: string; onBack: () => void }>({ title: "Edit Ad" });

  const { data } = useGetListingByIdQuery(id);

  const initialState: Partial<ReturnType<typeof transformListingToState>> = useMemo(
    () => (!!data ? transformListingToState(data) : {}),
    [data]
  );

  const [updateListing, { isLoading, data: updatedData }] = useUpdateListingMutation();

  const update = async (data: any) => {
    return await updateListing({ id, ...data });
  };

  console.log("Edit State", initialState, id);

  return (
    <PageMotion key="EditAds">
      <Topbar pageTitle="EditAds" {...props} />

      <MainLayoutContainer m="0" pb="60px">
        <SidePageProvider>
          <Box mb="42px">
            <Button
              size="sm"
              minW="fit-content"
              maxW="fit-content"
              variant="link"
              leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
              onClick={state?.onBack || goBack}
              px="2px !important"
            >
              Back
            </Button>

            <Heading mt="12px" as="h4" fontSize="24px" fontWeight="bold">
              {state?.title}
            </Heading>
          </Box>

          <CreateAdFeature
            initialState={initialState as any}
            onSubmit={update as any}
            isLoading={isLoading}
            data={updatedData}
            setPageInfo={set}
            {...props}
          />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}

export const transformListingToState = (listing: P2PListingTradeRo) => {
  const typeMap: Record<string, "sell" | "buy"> = {
    selling: "sell",
    buying: "buy",
  };

  const listingTypeMap: Record<string, "destinationUnit" | "sourceUnit"> = {
    buy: "destinationUnit",
    sell: "sourceUnit",
  };

  const mapUnitField = (type: string) => {
    return listing && listing[listingTypeMap[type]];
  };

  const getPaymentOptions = (data: P2PListingTradeRo) => {
    let options: { key: string; label: string }[] = [];
    data?.paymentOption?.forEach((option) => {
      if (toLower(option.label) === "bitmama wallet" && option.source === "internal")
        options.push({ key: "bitmamaWallet", label: option.label });
      if (
        toLower(option.label) === "external wallet" &&
        option.source === "external" &&
        toLower(option.receivingIntoLabel) === "changera"
      ) {
        options.push({ key: "changeraId", label: option.receivingIntoLabel });
      }
      if (
        option.source === "external" &&
        toLower(option.receivingIntoLabel) !== "changera" &&
        option.source === "external" &&
        toLower(option.receivingIntoLabel) !== "bitmama wallet"
      ) {
        options.push({ key: "bankTransfer", label: "Bank Transfer" });
      }
    });

    return options;
  };

  const options = getPaymentOptions(listing)?.map((m) => m.key);

  return {
    methods: [...options],
    selectedMethod: options?.includes("bitmamaWallet") ? "bitmamaWallet" : "bankTransfer",
    type: typeMap[listing?.adsType],
    token: mapUnitField(typeMap[listing?.adsType]),
    volume: listing?.volume?.$numberDecimal,
    totalCost: +(listing?.totalCost?.$numberDecimal ?? 0),
    sourceUnit: listing?.sourceUnit,
    destinationUnit: listing?.destinationUnit,
    useInternalWallet: options?.includes("bitmamaWallet"),
    dynamic: listing?.dynamic,
    priceType: listing?.dynamic ? "dynamic" : "fixed",
    country: listing?.country,
    minimumFilling: listing?.tradeOption?.minimumFilling,
    maximumFilling: listing?.tradeOption?.maximumFilling,
    acceptPartialFilling: listing?.tradeOption?.acceptPartialFilling,
    price: String(listing?.price ?? ""),
    unitCost: listing?.tradeOption?.unitCost,
    timeout: listing?.timeout,
    terms: listing?.terms ?? "N/A",
    paymentOption: listing?.paymentOption.map((po, i) => ({
      ...po,
      method: options[i],
      receivingIntoAddress: po?.receivingIntoAddress || po?.address,
    })),
    paymentOptions: listing?.paymentOption.map((po, i) => ({
      ...po,
      method: options[i],
      receivingIntoAddress: po?.receivingIntoAddress || po?.address,
    })),
  };
};
