import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { AbstractModal, Icon } from "components";

interface CopyCryptoAddressWarningModalProps extends Omit<ModalProps, "children"> {}

export default function CopyCryptoAddressWarningModal(props: CopyCryptoAddressWarningModalProps) {
  const { ...xprops } = props;

  return (
    <AbstractModal
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        // position: { base: "absolute", "1sm": "relative" },
      }}
      {...xprops}
    >
      <Box p={{ base: "32px 40px", "1sm": "44px 80px" }}>
        <VStack mb="60px !important" textAlign="center">
          <Icon type="warning" boxSize="44px" color="#eb962b" />
          <Heading mb="10px !important" as="h4" fontSize="32px">
            Attention
          </Heading>
          <Text fontWeight="500" textAlign="center">
            You need to copy and use the MEMO along with your TON address for a successful transaction
          </Text>
        </VStack>

        <VStack mt="24px !important" px="46px" w="100%">
          <Button minW="unset" w="100%" onClick={props.onClose}>
            Continue
          </Button>
        </VStack>
      </Box>
    </AbstractModal>
  );
}
