/**
 * DIGITAL OCEAN UPLOADER BASIC SETUP
 **/

import * as AWS from "aws-sdk";

import S3 from "react-aws-s3-typescript";

import configs from "config";

const config = {
  bucketName: configs.DO_BUCKET,
  region: configs.DO_SPACES_ENDPOINT,
  accessKeyId: configs.DO_SPACES_KEY,
  secretAccessKey: configs.DO_SPACES_SECRET,
  s3Url: configs.DO_SPACES_URL /* optional */,
};

const client = new S3(config);

export async function upload(file: File, onProgress?: (value: number) => void) {
  console.log("File ArrayBuffer", await file.arrayBuffer());
  const arrayBuffer = await file.arrayBuffer();
  const dataview = new DataView(arrayBuffer);
  const blob = new Blob([dataview], { type: file.type });
  console.log("File Blob", blob);

  const params = {
    ACL: "public-read",
    Body: blob,
    Bucket: config.bucketName,
    Key: file.name,
    ContentType: file.type,
  };

  return new Promise<AWS.S3.PutObjectOutput>((resolve, reject) => {
    new AWS.S3(config)
      .putObject(params)
      .on("httpUploadProgress", (evt, res) => {
        const progressValue = Math.round((evt.loaded / evt.total) * 100);
        onProgress && onProgress(progressValue);
      })
      .send((err, data) => {
        if (!!err) reject(err);
        if (!!data) resolve(data);
      });
  });
}

export default async function uploadFile(file: File, filename: string | null = null) {
  return await client.uploadFile(file, filename ?? file.name);
}
