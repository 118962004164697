import { Button, Heading, HStack, Input, InputGroup, InputRightElement, useColorModeValue, VStack } from "@chakra-ui/react";
import { useReviewTradeMutation } from "apis";
import { Card, CircularLoader, ConditionalRender, Icon } from "components";
import { useColor, useSelector } from "hooks";
import { P2PSessionRo } from "interfaces";
import { useCallback, useMemo, useState } from "react";
import { selectUser } from "store/slices";
import { when } from "utils";

interface LeaveReviewProps extends P2PSessionRo {}

export function LeaveReview(props: LeaveReviewProps) {
  const { tradeRef, reviewsByUserId } = props;

  const { profile } = useSelector(selectUser);

  const color = useColor();
  const inputBg = useColorModeValue("#F2F4F3", "dark.cardBg");
  const textColor = useColorModeValue("grey.400", "grey.100");

  const [message, setMessage] = useState<string | null>(null);
  const [tradeId, frozenRef] = String(tradeRef).split("-");

  const [_reviewTrade, { isLoading: isReviewing }] = useReviewTradeMutation();

  const review = useMemo(() => {
    const userId = profile?.userId;
    const isEmpty = Object.keys(reviewsByUserId ?? {}).length === 0;
    if (!!userId && !isEmpty) return reviewsByUserId![userId];
    return undefined;
  }, [profile, reviewsByUserId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const reviewTrade = useCallback(
    (message: string) =>
      tradeId &&
      frozenRef &&
      _reviewTrade({
        id: tradeId,
        frozenRef,
        message,
      }),
    [tradeId, frozenRef, _reviewTrade]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    !!message && reviewTrade(message);
  };

  return (
    <VStack as="form" my="40px" onSubmit={handleSubmit}>
      <Card minW="calc(100% - 40vw)" p="24px" borderRadius="16px">
        <HStack>
          <Heading as="h5" mt="16px" mb="20px" fontSize="18px">
            {when(!!review, "Review", "Leave a Review")}
          </Heading>

          {isReviewing && <CircularLoader isIndeterminate />}
        </HStack>

        <InputGroup size="md">
          <Input
            minH="103px"
            bg={inputBg}
            borderRadius="5px"
            pr="8rem"
            type="text"
            disabled={!!review || isReviewing}
            value={message ?? review?.reviewMessage ?? ""}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message"
            _placeholder={{
              color: textColor,
              fontWeight: "600",
              fontSize: "14px",
              fontFamily: "var(--bitmama-fonts-body)",
            }}
            // cursor=""
            _disabled={{ cursor: "default", borderColor: color("#F2F4F3", "#2a2a2a") }}
          />
          <ConditionalRender shouldRender={!review}>
            <InputRightElement w="8rem" pr="1rem">
              <Button
                type="submit"
                variant="transparent"
                size="sm"
                onClick={() => {}}
                rightIcon={<Icon type="sendFilled" />}
                color={textColor}
                isLoading={isReviewing}
                disabled={!message || isReviewing}
              >
                Submit
              </Button>
            </InputRightElement>
          </ConditionalRender>
        </InputGroup>
      </Card>
    </VStack>
  );
}
