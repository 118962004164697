import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useGetListingQuery } from "apis";
import { EmptyCrate, GenericTable, MobileTableItem, Paginator, TableItem /*useModalView as useSteps*/ } from "components";
// import { isTesting } from "config";
import { AnimatePresence, motion } from "framer-motion";
import { useEventListener } from "hooks";
import { PageProps } from "interfaces";
import { useMemo } from "react";
import { toQueryString, when } from "utils";

import isEmpty from "lodash/isEmpty";
import { useAppConfig } from "contexts/appconfig.context";
import { FeatureUnavailableContent } from "ui/Common/Modals/FeatureUnavailable";

interface BuyPanelProps extends PageProps {
  filter: Partial<IFilter>;
  setFilter: (update: Partial<IFilter>) => void;
}

interface IQueryState {
  page: number;
  limit: number;
  adsStatus: string;
  /**
   * Use `selling` for the buying ads page, and `buying` for the selling ads page.
   */
  adsType: "selling" | "buying";
  /**
   * Options are `createdAt:desc` | `createdAt:asc` | `price:asc`
   * `price:desc` | `unitCost:desc` | `totalCost:desc` ... and more.
   *  Use the format ``field:{desc | asc}``
   */
  coin: string;
  sortBy: string;
  coins: string;
  currency: string;
  fiatRanges: string;
  range: string;
  paymentOption: string;
}
interface IFilter {
  endpoint: string; // --> /own, /others
  amount: string;
  queries: Partial<IQueryState>;
}

export default function BuyPanel(props: BuyPanelProps) {
  const { filter, setFilter } = props;

  // const { setCurrentView, currentView } = useSteps();

  const { appfigs } = useAppConfig();
  const { p2pConfig } = appfigs ?? {};

  const isBuyFromListingDisabled = useMemo(() => !!p2pConfig && !!p2pConfig?.disableBuyFromListing, [p2pConfig]);

  // page=1&limit=10&adsStatus=open&adsType=selling&sortBy=createdAt%3Adesc&coins=${coin}
  // const [filter, setFilter] = usePartialState<IFilter>({
  //   endpoint: "",
  //   queries: {
  //     page: 1,
  //     limit: 10,
  //     adsStatus: "open",
  //     adsType: "selling",
  //     sortBy: "createdAt:desc",
  //     coins: isTesting ? "tbtc" : "btc",
  //   },
  // });

  const queries = useMemo(
    () => `${filter?.endpoint ?? ""}/?${toQueryString({ ...filter?.queries, adsType: "selling" })}`,
    [filter?.endpoint, filter?.queries]
  );

  const {
    data: listing,
    isLoading,
    isFetching,
    // isUninitialized,
    refetch,
  } = useGetListingQuery(queries);

  const trades = useMemo(() => listing?.trades ?? [], [listing]);
  const totalCount = useMemo(() => listing?.totalCount ?? 0, [listing]);

  console.log("BUY LISTINGS", totalCount, trades);

  // const handleCoinChange = (coin: string) => {
  //   // sortBy: "price:asc" : "price:desc"
  //   setFilter({
  //     queries: { ...filter?.queries, page: 1, coin, coins: coin, fiatRanges: undefined, currency: undefined, sortBy: "price:asc" },
  //   });
  //   if (!!filter?.amount) {
  //     setFilter({ queries: { ...filter?.queries, range: `${filter?.amount ?? 0},40` } });
  //   }
  // };

  useEventListener("refetch_p2p", () => refetch());

  return (
    <Box>
      {/* <HStack justifyContent="space-between">
        {!isBuyFromListingDisabled && (
          <HStack>
            <Text fontFamily="var(--bitmama-fonts-heading)" fontSize="18px" fontWeight="600">
              You want to <Span color="primary.default">Buy</Span> Bitcoin?
            </Text>

            <PeerCoins
              variant="transparent"
              _coin={{ color: "grey.500" }}
              value={filter?.queries?.coins ?? ""}
              onPeerChange={(coin) => handleCoinChange(coin)}
            />
          </HStack>
        )}

        <P2PFilter<IFilter> user={user} filters={filter} set={setFilter} setView={setCurrentView} currentView={currentView} />
      </HStack> */}

      {!isBuyFromListingDisabled && (
        <VStack w="100%" mt="80px">
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div
              key={`trades-${when(isLoading, "loading", "loaded")}`}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.98 }}
              style={{ width: "100%" }}
            >
              {!isLoading && !isEmpty(trades) && (
                <GenericTable
                  display={{ sm: "none", "3sm": "table", md: "table" }}
                  isLoading={isLoading}
                  headers={["Available Rates", "Payment Method", "Seller Details", "Action"]}
                >
                  {trades.map((trade, i) => (
                    <TableItem key={`p2p-buy-table-item-${i}`} onClick={() => navigate(`/p2p/buy/${trade?._id}`)} {...trade} />
                  ))}
                </GenericTable>
              )}

              {!isLoading && !isEmpty(trades) && (
                <Box display={{ sm: "block", "3sm": "none", md: "none" }}>
                  {trades.map((trade, i) => (
                    <MobileTableItem
                      key={`p2p-buy-table-item-${i}`}
                      onClick={() => navigate(`/p2p/buy/${trade?._id}`)}
                      {...trade}
                    />
                  ))}
                </Box>
              )}

              {!isLoading && isEmpty(trades) && (
                <EmptyCrate my="80px" type="p2p" description="No Listings">
                  <Button
                    my="24px !important"
                    minH="42px"
                    minW="205px"
                    p="0"
                    // leftIcon={<Icon type="addCircle" />}
                    onClick={() => navigate("/p2p/create")}
                  >
                    Create Ad
                  </Button>
                </EmptyCrate>
              )}
            </motion.div>
          </AnimatePresence>

          <HStack
            mt={{ sm: "40px !important", "3sm": "8px !important", md: "8px !important" }}
            flexDir={{ sm: "column", "3sm": "row", md: "row" }}
            w="100%"
            justifyContent="space-between"
          >
            <HStack>
              <Text fontSize="md" fontWeight="500" color="grey.300">
                Want a better price?
              </Text>
              <Button size="sm" onClick={() => navigate(`/p2p/create?adsType=buy`)}>
                Create your Buying Ad
              </Button>
            </HStack>

            {totalCount > (filter?.queries?.limit ?? 0) && (
              <Paginator
                isLoading={isFetching}
                totalCount={totalCount ?? 0}
                limit={filter?.queries?.limit}
                page={filter?.queries?.page}
                w="fit-content"
                flexDir={{ sm: "column", "3sm": "row", md: "row" }}
                onPageChange={(page: number) => setFilter({ queries: { ...filter?.queries, page } })}
              />
            )}
          </HStack>
        </VStack>
      )}

      {isBuyFromListingDisabled && (
        <VStack py="100px" maxW="container.md" m="auto">
          <FeatureUnavailableContent contentType="feature" showSupport />
        </VStack>
      )}
    </Box>
  );
}
