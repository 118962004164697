import { CoinInfo, CoinInfoList, Group, PageLoading } from "components";
import { useSidePage } from "contexts";
import { useWalletBalance } from "hooks";

export default function CryptoPanel() {
  const { cryptos, isLoading } = useWalletBalance();

  const { onOpen } = useSidePage();

  return (
    <Group mt="32px" gridGap="4px" pos="relative" minH="300px">
      {isLoading && <PageLoading isLoading={isLoading} pos="absolute" top="46px" left="50%" transform="translateX(-50%)" />}

      <CoinInfoList>
        {cryptos
          .filter(({ coin }) => coin !== "usdc-base")
          .map((crypto, index) => (
            <CoinInfo
              p={{ base: "16px 13px", "2sm": "18px 28px" }}
              index={index}
              key={`coininfo-${crypto?.coin}`}
              coin={crypto?.coin as any}
              value={crypto?.balance?.$numberDecimal}
              w="100%"
              onClick={() => onOpen({ key: "Crypto", data: { coin: crypto?.coin } })}
            />
          ))}
      </CoinInfoList>
    </Group>
  );
}
