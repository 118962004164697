import { useColorMode } from "@chakra-ui/react";
import { switchStyle } from "utils";

export function useColor() {
  const { colorMode } = useColorMode();

  const color = <T extends any>(light: T, dark: T) => switchStyle<T>(colorMode, { light, dark });

  return color;
}
