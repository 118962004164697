import { Box, Button, Grid, Heading, Stack, TabList, TabPanel, Tabs, useColorModeValue } from "@chakra-ui/react";
import { navigate, useLocation, useParams } from "@reach/router";
import { FormInfo, Icon, MainLayoutContainer, PageMotion, Tab, TabPanels, Topbar, Username } from "components";
import { SidePageProvider } from "contexts";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { useState } from "react";
import { ActiveAdsPanel, ReviewsPanel } from "ui";

interface ListingProps {}
interface AdvertiserInfoProps {}
interface UserDetailsPageProps extends PageProps {}

export default function UserDetailsPage(props: UserDetailsPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion key="UserDetails">
      <Topbar pageTitle="P2P" {...props} />

      <MainLayoutContainer m="0" pb="60px">
        <SidePageProvider>
          <Box mb="42px">
            <Button
              size="sm"
              minW="fit-content"
              maxW="fit-content"
              variant="link"
              leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
              onClick={goBack}
              px="2px !important"
            >
              Back
            </Button>
          </Box>

          <Grid templateColumns=".8fr 1fr" gap="10px">
            <AdvertiserInfo />

            <Listings />
          </Grid>
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}

function AdvertiserInfo(props: AdvertiserInfoProps) {
  const { username } = useParams();

  return (
    <Box>
      <Heading as="h4" fontSize="24px">
        Trader Info
      </Heading>

      <Stack my="42px" gridGap="30px">
        <Username
          flexDir="row-reverse"
          alignItems="flex-end"
          justifyContent="flex-end"
          username={username ?? ""}
          _presence={{ mr: "10px !important" }}
          _link={{ textDecoration: "none", pointerEvents: "none" }}
        />

        <FormInfo fontSize="16px" info="Feedback Score: " description="89%" />
        <FormInfo fontSize="16px" info="Feedback Summary: " description="" />
        <FormInfo fontSize="16px" info="Account Created: " description="3 years ago" />
        <FormInfo fontSize="16px" info="Last Seen: " description="3 minutes ago" />
        <FormInfo fontSize="16px" info="Identity and Residence Proof: " description="Verified" />
        <FormInfo fontSize="16px" info="Peer certification: " description="Certified" />
        <FormInfo fontSize="16px" info="Bank verification: " description="Verified" />
        <FormInfo fontSize="16px" info="Phone number: " description="Verified" />
      </Stack>
    </Box>
  );
}

function Listings(props: ListingProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    ads: 0,
    reviews: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", Object.keys(tabMap)[index]);
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange}>
      <TabList borderRadius="8px">
        <Tab fontWeight="600">Active Ads (3)</Tab>
        <Tab fontWeight="600">Trade Reviews</Tab>
      </TabList>
      <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
        <TabPanel px={["2", "0", "0", "1"]}>
          <ActiveAdsPanel />
        </TabPanel>
        <TabPanel px={["0", "0", "0", "1"]}>
          <ReviewsPanel />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
