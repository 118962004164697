import { Redirect, Router } from "@reach/router";
import {
  Account,
  AccountLevel2Verification,
  AccountLevel3Verification,
  AccountVerification,
  AddBank,
  AddCryptoPriceAlert,
  AddMobileMoney,
  AppDownload,
  BankList,
  Banks,
  BuyAd,
  Cards,
  ClaimReward,
  ClaimRewardIndex,
  CoinEarnings,
  CreateAds,
  CryptoPriceAlert,
  CryptoPriceAlertList,
  DefaultCurrency,
  DefaultCurrencyIndex,
  DeleteAccount,
  DeleteAccountIndex,
  Earn,
  Earnings,
  EditAds,
  EditCryptoPriceAlert,
  Enable2FA,
  Enter2FA,
  ForgotPassword,
  GreenBox,
  Home,
  Login,
  MobileMoneyList,
  NoMatch,
  NotFound,
  P2P,
  P2PSession,
  Partners,
  PartnersHistory,
  PartnersIndex,
  PartnersInviteDetails,
  PartnersLeaderboard,
  PartnersRewardDetails,
  PartnersRules,
  PreclaimGreenBox,
  PreorderPhysicalCard,
  Profile,
  RequestVirtualCard,
  Reset2FA,
  ResetPassword,
  SellAd,
  Signup,
  StakeDetails,
  Staking,
  Subscribe,
  TransactionPin,
  TransferEarnings,
  Verification,
  VerifyResetCode,
  Wallet,
  MyTrades,
  Listings,
  CryptoPriceAlertOrders,
  PriceAlertOrderDetails,
  MyAchievementsPage,
  AchievementsRewardsAndBadgesPage,
  Yield,
  EarnOptions,
  CaptureBVN,
} from "Pages";

import { Layout, ProtectedRoute, RootPage } from "components";

import configs from "config";
import { useAppConfig } from "contexts/appconfig.context";
import UserDetailsPage from "Pages/P2P/UserDetails";
import { Testground } from "Pages/Dashboard/testground";

const AppRouter = () => {
  const { appfigs } = useAppConfig();
  const { greenboxConfig } = appfigs ?? {};

  return (
    <Router>
      <NotFound path="/404" />
      <AppDownload path={configs.paths.app} />
      <Login path={configs.paths.login} />
      <Signup path={configs.paths.register} />
      <Verification path={configs.paths.verifyEmail} />
      <ResetPassword path={configs.paths.resetPassword} />
      <VerifyResetCode path={configs.paths.verifyResetCode} />
      <ForgotPassword path={configs.paths.forgotPassword} />
      <Enter2FA path={configs.paths.verifyTwoFa} />
      <CaptureBVN path={configs.paths.captureBVN} />
      <Reset2FA path={configs.paths.resetTwoFa} />

      <Enable2FA path={configs.paths.enableTwoFa} />

      <RootPage path={configs.paths.gbx}>
        <PreclaimGreenBox path="/:code" />
      </RootPage>

      <Layout path="/">
        <NoMatch default noThrow to="/404" />
        <Redirect from="/" to={configs.paths.dashboard} noThrow />

        <Redirect from="/dashboard/p2p/trades/session/:id" to="/p2p/order/:id" noThrow />

        <RootPage path={configs.paths.dashboard}>
          <NoMatch default noThrow to="/404" />
          <ProtectedRoute path="/" component={Home} />
          <ProtectedRoute path="/testground" component={Testground} />
        </RootPage>
        <RootPage path={configs.paths.wallet}>
          <NoMatch default noThrow to="/404" />
          <ProtectedRoute path="/" component={Wallet} />
        </RootPage>
        <RootPage path={configs.paths.cards}>
          <NoMatch default noThrow to="/404" />
          <ProtectedRoute path="/" component={Cards} />
          <ProtectedRoute path="/request" component={RequestVirtualCard} />
          <ProtectedRoute path="/preorder" component={PreorderPhysicalCard} />
        </RootPage>
        <ProtectedRoute path={configs.paths.account} component={Account}>
          <ProtectedRoute path="/achievements" component={RootPage}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={MyAchievementsPage} />
            <ProtectedRoute path="/badges" component={AchievementsRewardsAndBadgesPage} />
          </ProtectedRoute>

          <ProtectedRoute path="/profile" component={RootPage}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={Profile} />
            <ProtectedRoute path="/pin" component={TransactionPin} />
          </ProtectedRoute>

          <ProtectedRoute path="/banks" component={Banks as any}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={BankList} />
            <ProtectedRoute path="/add" component={AddBank} />
          </ProtectedRoute>

          <ProtectedRoute path="/rewards" component={ClaimRewardIndex as any}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={ClaimReward} />
          </ProtectedRoute>

          <ProtectedRoute path="/delete" component={DeleteAccountIndex as any}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={DeleteAccount} />
          </ProtectedRoute>

          <ProtectedRoute path="/currency" component={DefaultCurrencyIndex as any}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={DefaultCurrency} />
          </ProtectedRoute>

          <ProtectedRoute path="/momo" component={RootPage}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={MobileMoneyList} />
            <ProtectedRoute path="/add" component={AddMobileMoney} />
          </ProtectedRoute>

          <ProtectedRoute path="/partners" component={PartnersIndex as any}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={Partners} />
            <ProtectedRoute path="/rules" component={PartnersRules} />
            <ProtectedRoute path="/history" component={RootPage}>
              <ProtectedRoute path="/" component={PartnersHistory} />
              <ProtectedRoute path="/:id" component={PartnersInviteDetails} />
              <ProtectedRoute path="/reward/:id" component={PartnersRewardDetails} />
            </ProtectedRoute>
            <ProtectedRoute path="/leaderboard" component={PartnersLeaderboard} />
          </ProtectedRoute>

          <ProtectedRoute path="/verification" component={RootPage}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={AccountVerification} />
            <ProtectedRoute path="/level2" component={AccountLevel2Verification} />
            <ProtectedRoute path="/level3" component={AccountLevel3Verification} />
          </ProtectedRoute>

          <ProtectedRoute path="/pricealerts" component={CryptoPriceAlert as any}>
            <NoMatch default noThrow to="/404" />
            <ProtectedRoute path="/" component={CryptoPriceAlertList} />
            <ProtectedRoute path="/add" component={AddCryptoPriceAlert} />
            <ProtectedRoute path="/orders" component={CryptoPriceAlertOrders} />
            <ProtectedRoute path="/orders/:id" component={PriceAlertOrderDetails} />
            <ProtectedRoute path="/edit/:id" component={EditCryptoPriceAlert} />
          </ProtectedRoute>
        </ProtectedRoute>
        <ProtectedRoute path={configs.paths.p2p} component={RootPage}>
          <NoMatch default noThrow to="/404" />
          <ProtectedRoute path="/" component={P2P} />
          <ProtectedRoute path="/create" component={CreateAds} />
          <ProtectedRoute path="/buy/:id" component={BuyAd} />
          <ProtectedRoute path="/sell/:id" component={SellAd} />
          <ProtectedRoute path="/edit/:id" component={EditAds} />
          <ProtectedRoute path="/order/:ref" component={P2PSession} />
          <ProtectedRoute path="/advertiser/:username" component={UserDetailsPage} />
          <ProtectedRoute path="/trades" component={MyTrades} />
          <ProtectedRoute path="/listings" component={Listings} />
        </ProtectedRoute>
        <ProtectedRoute path={configs.paths.earn} component={RootPage}>
          <NoMatch default noThrow to="/404" />
          <ProtectedRoute path="/" component={Earn} />
          <ProtectedRoute path="/options" component={EarnOptions} />
          <ProtectedRoute path="/staking" component={Staking} />
          <ProtectedRoute path="/yield" component={Yield} />
          <ProtectedRoute path="/earnings" component={Earnings} />
          <ProtectedRoute path="/staking/subscribe" component={Subscribe} />
          <ProtectedRoute path="/transfer/:coin" component={TransferEarnings} />
          <ProtectedRoute path="/earnings/:coin" component={CoinEarnings} />
          <ProtectedRoute path="/earnings/:coin/:id" component={StakeDetails} />
        </ProtectedRoute>
        <ProtectedRoute path={configs.paths.greenBox} component={RootPage}>
          <NoMatch default noThrow to="/404" />
          {!greenboxConfig?.disableGreenboxWallet && <ProtectedRoute path="/" component={GreenBox} />}
          {/* <ProtectedRoute path="/staking" component={Staking} />
          <ProtectedRoute path="/earnings" component={Earnings} />
          <ProtectedRoute path="/subscribe" component={Subscribe} />
          <ProtectedRoute path="/transfer/:coin" component={TransferEarnings} />
          <ProtectedRoute path="/earnings/:coin" component={CoinEarnings} />
          <ProtectedRoute path="/earnings/:coin/:id" component={StakeDetails} /> */}
        </ProtectedRoute>
        {/* <ProtectedRoute path={configs.paths.gbx} component={RootPage}>
          <NoMatch default noThrow to="/404" />
          <ProtectedRoute path="/:code" component={GreenBox} />
        </ProtectedRoute> */}
      </Layout>
    </Router>
  );
};

// function WithIntercom(props: any) {
//   return <IntercomProvider appId={configs.INTERCOM_TOKEN}>
//     {props?.children}
//   </IntercomProvider>
// }

export default AppRouter;
