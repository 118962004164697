import { Box, Button, FormControl, Input, Select, Stack, useColorModeValue, VStack } from "@chakra-ui/react";
import { useGetAffiliateEarningSummaryQuery, useGetAffiliateTransactionsQuery } from "apis";
import { Card, EmptyCrate, Group, InputLabel, PageLoading, Paginator, Title, TitleBar, TransactionItem, Wallet } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { useMemo } from "react";
import { toQueryString, when } from "utils";

interface AffiliateWidgetProps extends PageProps {
  coin: string;
}

interface FilterState {
  filterBy: string;
  afterDate: string;
  beforeDate: string;
  txType: string;
}

interface IState extends Omit<FilterState, "filterBy"> {
  page: number;
  limit: number;
  sortBy: string;
}

export function AffiliateWidget(props: AffiliateWidgetProps) {
  const { coin } = props;

  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const [state, set] = usePartialState<IState>({
    page: 1,
    limit: 4,
    sortBy: "createdAt:desc",
  });

  const [filter, setFilter] = usePartialState<FilterState>();
  const queries = useMemo(() => toQueryString({ ...state }), [state]);

  const { data, isLoading, isFetching } = useGetAffiliateTransactionsQuery(queries);
  const { data: summaryData /*isLoading: isSummaryLoading*/ } = useGetAffiliateEarningSummaryQuery();

  const transactions = useMemo(() => data?.earnings, [data]);
  const totalCount = useMemo(() => data?.totalCount, [data]);
  const combinedEarns = useMemo(() => summaryData?.combinedEarns, [summaryData]);
  const balance = useMemo(() => combinedEarns && combinedEarns[coin], [combinedEarns, coin]);

  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);
  const passedLimit = useMemo(() => (totalCount ?? 0) > state?.limit!, [totalCount, state?.limit]);

  const isDisabled = useMemo(
    () =>
      !filter?.filterBy ||
      (!!filter?.filterBy && filter?.filterBy === "date" && !(filter?.afterDate && filter?.beforeDate)) ||
      (!!filter?.filterBy && filter?.filterBy === "transaction_type" && !filter?.txType) ||
      isLoading ||
      isFetching,
    [filter, isLoading, isFetching]
  );

  const applyFilter = () => {
    set({ ...filter });
  };

  return (
    <Group mt="22px">
      <Wallet
        w="100%"
        minW="unset"
        maxW="unset"
        currency={coin as any}
        label="Affiliate Wallet"
        scheme="partner"
        value={balance ?? 0}
        _outline={{ w: "226px", disabled: !balance || balance === 0 }}
        _solid={{ display: "none" }}
        _navigations={{ display: "none" }}
      />

      <Group mt="68px !important">
        <TitleBar>
          <Title>Transation History</Title>
        </TitleBar>

        <FormControl mt="40px !important">
          <InputLabel>Filter By</InputLabel>
          <Select
            placeholder="Select Filter"
            value={filter?.filterBy ?? ""}
            onChange={(e) => setFilter({ filterBy: e.target.value })}
          >
            <option value="date">Date</option>
            <option value="transaction_type">Transaction Type</option>
          </Select>
        </FormControl>

        <VStack mt="40px !important">
          {filter?.filterBy === "date" && (
            <FormControl maxW="300px" m="0 auto">
              <Input
                placeholder="Select Start Date"
                type="date"
                value={filter?.afterDate ?? ""}
                onChange={(e) => setFilter({ afterDate: e.target.value })}
              />
              <Input
                mt="20px"
                placeholder="Select End Date"
                type="date"
                value={filter?.beforeDate ?? ""}
                onChange={(e) => setFilter({ beforeDate: e.target.value })}
              />
            </FormControl>
          )}
          {filter?.filterBy === "transaction_type" && (
            <FormControl maxW="300px" m="0 auto">
              <Select
                placeholder="Select Type"
                textTransform="capitalize"
                value={filter?.txType ?? ""}
                onChange={(e) => setFilter({ txType: e.target.value })}
              >
                {["p2p", "buy", "sell", "swap", "withdrawal", "fund", "cards"].map((opt) => (
                  <option key={`type_${opt}`} value={opt}>
                    {opt}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          <Button
            mt={when(!!filter?.filterBy, "40px !important", "0 !important")}
            isLoading={isLoading || isFetching}
            disabled={isDisabled}
            onClick={applyFilter}
          >
            Apply Filter
          </Button>
        </VStack>
      </Group>

      <Card mt="42px !important" bg={when(hasTx, bg, "transparent")} w="100%" p="8px" overflow="hidden">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {!isLoading && (
              <Stack>
                {(transactions ?? []).map((tx: any, id: any) => (
                  <TransactionItem
                    mt="0 !important"
                    w="100%"
                    key={`transaction-${id}`}
                    unit={tx?.unit}
                    type={tx?.type}
                    value={tx?.value}
                    createdAt={tx?.createdAt}
                    description={tx?.description}
                  />
                ))}
              </Stack>
            )}

            {!isLoading && !isFetching && !hasTx && (
              <EmptyCrate type="transaction" description="Your referrals are yet to complete a transaction" />
            )}
          </motion.div>
        </AnimatePresence>
      </Card>

      {passedLimit && (
        <Box w="100%">
          <Paginator
            limit={state.limit!}
            onPageChange={(page: number) => set({ page })}
            page={state.page!}
            totalCount={totalCount ?? 0}
          />
        </Box>
      )}
    </Group>
  );
}
