import { Box, Button, Center, Input, Heading, Text } from "@chakra-ui/react";
import { useAddEditLabelMutation } from "apis";
import { InputLabel } from "components";
import { useColor } from "hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCardLabel } from "store/slices/filter";

interface StepProps {
  onClose: () => void;
  onContinue: () => void;
  cardId: string;
  useInternalId?: boolean;
  label?: string;
}

const StepTwo = ({ onClose, onContinue, cardId, useInternalId, label }: StepProps) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose();
    onContinue();
  };

  const color = useColor();

  const [cardLabel, _setCardLabel] = useState(label ?? "");
  const [_addEditLabel, { isLoading }] = useAddEditLabelMutation();
  // const [_createLabelWithInternalId, { isLoading: createLoading }] = useAddEditLabelWithInternalIdMutation();

  const handleAddEditLabel = async (e: any) => {
    e.preventDefault();
    dispatch(setCardLabel(cardLabel));
    useInternalId && onClose();

    try {
      const response =
        !useInternalId &&
        (await _addEditLabel({
          cardId,
          label: cardLabel,
        }).unwrap());

      if (response && !useInternalId) {
        onClose();
        onContinue();
      }
    } catch (error) {}
  };

  return (
    <Box maxWidth="405px" mx="auto">
      <Heading textAlign="center" color={color("#303131", "white")} fontSize={{ base: "24px", md: "32px" }} fontWeight="600">
        Add a card label
      </Heading>

      <Box>
        <form onSubmit={handleAddEditLabel}>
          <Box margin={{ base: "18px 0", md: "29px 0 24px" }}>
            <InputLabel htmlFor="cardLabel">Card Label</InputLabel>
            <Input
              isRequired
              type="text"
              id="cardLabel"
              placeholder="Input card label"
              minH={{ base: "56px" }}
              minW={{ base: "initial", md: "335px" }}
              maxW="335px"
              width="100%"
              value={cardLabel}
              onChange={(e) => _setCardLabel(e.target.value)}
            />

            {cardLabel.length > 20 && (
              <Text fontSize="sm" color="error">
                Label must not contain 20 characters or less
              </Text>
            )}
          </Box>

          <Center gridGap="16px">
            <Button
              color="#0D4740"
              minW="initial"
              maxW="159px"
              width="100%"
              border="1px solid #0D4740"
              bgColor="#fff"
              onClick={handleClose}
              _hover={{ bg: "initial" }}
              minH={{ base: "56px" }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              minH={{ base: "56px" }}
              isLoading={isLoading}
              disabled={isLoading || cardLabel.length > 20}
              minW="initial"
              maxW="159px"
              width="100%"
              _hover={{
                _disabled: {
                  opacity: 0.4,
                },
              }}
            >
              Save
            </Button>
          </Center>
        </form>
      </Box>
    </Box>
  );
};

export default StepTwo;
