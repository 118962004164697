import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiResponse, AppStatus, UserRo } from "interfaces";
import { RootState } from "store";
import { get } from "utils";
import { wrapBaseApi } from "utils/apiWrapper";

interface IState {
  profile?: UserRo;
  status: AppStatus;
}

const initialState: IState = {
  status: "idle",
};

export const getProfile = createAsyncThunk("user/getProfile", async (_, { getState, rejectWithValue }) => {
  try {
    // const store = getState() as RootState;
    // console.log("Store", getState() as RootState);
    const response = await get<ApiResponse<UserRo>>(wrapBaseApi(`/auth/currentUser`));
    // console.log("Get Profile Response", (response as any)?.data);
    return response.data;
  } catch (error) {
    console.log("Error", error as any);
    return rejectWithValue(error);
  }
});

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.status = "fetching";
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.status = "success";
      state.profile = action.payload;
    });
    builder.addCase(getProfile.rejected, (state) => {
      state.status = "error";
    });
  },
});

export const { actions, reducer: AuthReducer } = userSlice;
export const selectUser = (state: RootState) => state.user;
export default AuthReducer;
