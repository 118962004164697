import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AccountLink, Icon, InputLabel, MainLayoutContainer, PageMotion, Steps, useModalView } from "components";
import PasswordInput from "components/Input/PasswordInput";
import { useBuiltInFn, useSelector } from "hooks";
import { PageProps } from "interfaces";
import { useUpdateTransactionPinMutation } from "apis";
import ResetTransactionPin from "./ResetTransactionPin";
import CreatePin from "./CreatePin";

interface TransactionPinProps extends PageProps {}

export default function TransactionPin(props: TransactionPinProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountProfile" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <Heading mt="10px" as="h5" fontSize="xl">
            Transaction Pin
          </Heading>
        </Box>

        <Box my={{ base: "20px", "3sm": "40px" }}>
          <Steps hideIndicator>
            <ViewLinks />
            <Update />

            <ResetTransactionPin />
            <CreatePin />
          </Steps>
        </Box>
      </MainLayoutContainer>
    </PageMotion>
  );
}

function ViewLinks(props: TransactionPinProps) {
  const { setCurrentView } = useModalView();

  const { profile } = useSelector((state) => state.user);

  const bColor = useColorModeValue("grey.200", "dark.border");

  return (
    <Stack>
      {profile?.hasPin ? (
        <>
          <AccountLink
            borderBottomColor={bColor}
            py="20px"
            to="#update"
            label="Update Transaction Pin"
            onClick={() => setCurrentView(1)}
            maxWidth={{ base: "initial", "4sm": "334px" }}
          />
          <AccountLink
            borderBottomColor={bColor}
            py="20px"
            to="#reset"
            label="Reset Transaction Pin"
            onClick={() => setCurrentView(2)}
            maxWidth={{ base: "initial", "4sm": "334px" }}
          />
        </>
      ) : (
        <AccountLink borderBottomColor={bColor} py="20px" to="#create" label="Create Pin" onClick={() => setCurrentView(3)} />
      )}
    </Stack>
  );
}

function Update(props: TransactionPinProps) {
  const { setCurrentView } = useModalView();
  const pColor = useColorModeValue("grey.default", "inherit");

  const toast = useToast();

  const [state, setState] = useState({
    oldPin: "",
    newConfirmPin: "",
    newPin: "",
  });

  const pinMatch = state.newConfirmPin === state.newPin;

  const [_updateTransactionPin, { isLoading }] = useUpdateTransactionPinMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { newConfirmPin, newPin, oldPin } = state;

    try {
      const response: any = await _updateTransactionPin({
        newConfirmPin,
        newPin,
        oldPin,
      });

      if (response.data) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: response?.data?.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setCurrentView(0);
      }
    } catch (error: any) {
      toast({
        position: "bottom-right",
        title: "Error",
        description: error?.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleKeyUp = (event: any) => {
    const lastChar = event.target.value.slice(-1);
    if (!/^\d+$/.test(lastChar)) {
      setState({
        ...state,
        [event.target.name]: event.target.value.slice(0, -1),
      });
    }
  };

  console.log(state, "THE STATE");

  return (
    <Box>
      <Stack mb={{ base: "18px", "3sm": "46px" }}>
        <HStack>
          <IconButton
            size="xs"
            minW="fit-content"
            maxW="fit-content"
            minH="fit-content"
            maxH="fit-content"
            p="0 !important"
            bg="transparent"
            variant="transparent"
            aria-label="back button"
            icon={<Icon boxSize="20px" type="circleLeftArrow" />}
            onClick={() => setCurrentView(0)}
          />
          <Heading as="h6" fontSize="sm" color="secondary.400">
            Update Transaction Pin
          </Heading>
        </HStack>

        <Text color={pColor} fontSize="sm" fontWeight="500">
          Kindly enter your old transaction pin to set a new pin{" "}
        </Text>
      </Stack>

      <Stack as="form" onSubmit={handleSubmit}>
        <FormControl mb="20px !important">
          <InputLabel htmlFor="oldPin">Current Pin</InputLabel>
          <PasswordInput onKeyUp={handleKeyUp} value={state.oldPin} isRequired id="oldPin" name="oldPin" onChange={handleChange} />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel htmlFor="newPin">New Pin</InputLabel>
          <PasswordInput onKeyUp={handleKeyUp} value={state.newPin} isRequired id="newPin" name="newPin" onChange={handleChange} />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel htmlFor="newConfirmPin">Confirm Pin</InputLabel>
          <PasswordInput
            onKeyUp={handleKeyUp}
            isRequired
            id="newConfirmPin"
            name="newConfirmPin"
            value={state.newConfirmPin}
            onChange={handleChange}
          />
          {!pinMatch && state.newConfirmPin.length >= 3 && (
            <Text color="error" fontSize="14px">
              Pins do not match
            </Text>
          )}
        </FormControl>

        <VStack mt="40px !important">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!(state.newConfirmPin.length === 6) || !(state.newPin.length === 6) || isLoading || !pinMatch}
            minW={{ base: "100%", "4sm": "400px" }}
          >
            Update
          </Button>
        </VStack>
      </Stack>
    </Box>
  );
}
