import { Box, TabList, TabPanel, Tabs } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { Tab, TabPanels } from "components";
import { usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { useCallback, useState } from "react";

import BuyingPanel from "./Panels/MyAds/Buying";
// import SellingPanel from "./Panels/MyAds/Selling";

interface AdvertisementsPanelProps extends PageProps {}

interface IQueryState {
  page: number;
  limit: number;
  adsStatus: string;
  /**
   * Use `selling` for the buying ads page, and `buying` for the selling ads page.
   */
  adsType: "selling" | "buying";
  /**
   * Options are `createdAt:desc` | `createdAt:asc` | `price:asc`
   * `price:desc` | `unitCost:desc` | `totalCost:desc` ... and more.
   *  Use the format ``field:{desc | asc}``
   */
  coin: string;
  sortBy: string;
  coins: string;
  currency: string;
  fiatRanges: string;
  range: string;
  paymentOption: string;
}

export interface IFilter {
  endpoint: string; // --> /own, /others
  amount: string;
  queries: Partial<IQueryState>;
}

export default function AdvertisementsPanel(props: AdvertisementsPanelProps) {
  // const { user } = props;
  const location = useLocation();

  // const { setCurrentView, currentView } = useSteps();

  // page=1&limit=10&adsStatus=open&adsType=selling&sortBy=createdAt%3Adesc&coins=${coin}
  const [filter, setFilter] = usePartialState<IFilter>({
    endpoint: "/own",
    queries: {
      page: 1,
      limit: 10,
      adsStatus: "open",
      // adsType: "selling",
      sortBy: "createdAt:desc",
    },
  });

  const setQueries = useCallback(
    (updates: Partial<IQueryState>) => setFilter({ ...filter, queries: { ...(filter as any)?.queries, ...updates } }),
    [setFilter, filter]
  );

  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    buyAds: 0,
    sellAds: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    // searchParams.set("tab", Object.keys(tabMap)[index]);
    // navigate(`/wallet?${searchParams.toString()}`);
  };

  return (
    <Box>
      {/* <HubBox mb="68px" />

      <P2PFilter<IFilter>
        user={user}
        filters={filter}
        set={setFilter}
        setView={setCurrentView}
        currentView={currentView}
        initialState={{ listings: "own" }}
      /> */}

      <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange} mt="42px !important">
        <TabList borderRadius="8px" justifyContent="flex-start" w="80%" mx="auto">
          <Tab w="fit-content" pl="20px !important" pr="20px !important" fontFamily="var(--bitmama-fonts-heading)">
            Your Buying Ads
          </Tab>
          <Tab w="fit-content" pl="20px !important" pr="20px !important" fontFamily="var(--bitmama-fonts-heading)">
            Your Selling Ads
          </Tab>
        </TabList>
        <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
          <TabPanel px={["2", "0", "0", "1"]}>
            <BuyingPanel filters={filter} setQueries={setQueries} />
          </TabPanel>
          <TabPanel px={["0", "0", "0", "1"]}>
            {/* <SellingPanel filters={filter} setQueries={setQueries} /> */}
            Selling Panel
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
