import { useEffect, useMemo } from "react";
import { Box, Button, Heading, HStack, Image, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { capitalize, trim } from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import QrReader from "modern-react-qr-reader";
import { Group, Icon, MainLayoutContainer, PageLoading, PageMotion, Steps, useModalView } from "components";
import { useBuiltInFn, usePartialState } from "hooks";
import ClaimRewardPNG from "assets/images/claim-reward.png";
import { toPrecision, when } from "utils";
import { useClaimRewardsMutation } from "apis";

interface ClaimRewardProps {}

interface StepsProps {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  error?: any;
}

interface IState {
  code: string;
}

export default function ClaimReward(props: ClaimRewardProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  const [state, set] = usePartialState<IState>();

  return (
    <PageMotion subpage key="AccountBankList" overflowY="hidden" maxH="100%">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize={{ base: "18px", "3sm": "24px" }}>
              Claim Reward
            </Heading>
          </HStack>
        </Box>

        <Steps hideIndicator>
          <StepOne {...{ state, set }} />
          <StepTwo {...{ state, set }} />
          <StepThree {...{ state, set }} />
        </Steps>
      </MainLayoutContainer>
    </PageMotion>
  );
}

function StepOne(props: StepsProps) {
  const { onNext } = useModalView();

  return (
    <Group>
      <Box>
        <Heading as="h3" fontSize="lg">
          Scan
        </Heading>
        <Text mt="14px" lineHeight="18px" fontSize="14px" fontWeight="500">
          Scan the QR Code on your Bitmama Reward Card and get amazing rewards to enjoy your Bitmama App
        </Text>
      </Box>

      <VStack>
        <Image mt="46px !important" src={ClaimRewardPNG} w="70%" alt="claim reward placeholder" />
      </VStack>

      <VStack mt="70px !important">
        <Button fontFamily="var(--bitmama-fonts-heading)" onClick={onNext} minW={{ base: "100%", smx: "330px", "4sm": "400px" }}>
          Scan
        </Button>
      </VStack>
    </Group>
  );
}

function StepTwo(props: StepsProps) {
  const { set } = props;
  const { currentView, onNext } = useModalView();

  const handleScan = (data: any) => {
    if (data !== null) {
      set({ code: data });
      onNext();
    }
  };

  return (
    <Box mt="46px" borderRadius="8px" overflow="hidden">
      {currentView === 1 && (
        <Box
          // ref={ref}
          as={QrReader as any}
          borderRadius="8px"
          facingMode={"user"}
          minW="100%"
          h="100%"
          delay={100}
          onError={(e) => console.log(e)}
          onScan={handleScan}
          style={{ borderRadius: "8px" }}
        />
      )}
    </Box>
  );
}

function StepThree(props: StepsProps) {
  const { state } = props;

  const [claimReward, { data, isLoading, isError, error, isSuccess }] = useClaimRewardsMutation();

  useEffect(() => {
    if (state?.code) {
      console.log("CODE", state?.code);
      claimReward({ qrCode: state.code });
    }
  }, [state, claimReward]);

  const successMessage = useMemo(() => {
    const message = `You have successfully claimed your ${toPrecision(data?.reward ?? 1, data?.unit ?? "cUSD")} reward`;
    return message;
  }, [data]);

  return (
    <Box my="66px">
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={`${when(isLoading, "claiming", "claimed")}-reward`}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
        >
          {isLoading && <PageLoading isLoading={isLoading} />}

          {isSuccess && (
            <Text fontWeight="500" textAlign="center">
              Hooray! 🥳👏🤝 {successMessage}
            </Text>
          )}
          {isError && <Failed error={error!} {...props} />}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}

function Failed({ error }: StepsProps) {
  const { setCurrentView } = useModalView();

  const errorMessage = useMemo(() => {
    const err = String(error?.data?.error).toLowerCase().split("error:");
    return capitalize(trim(err[1]));
  }, [error]);

  return (
    <VStack my="80px !important">
      <Icon type="failed" boxSize="48px" />
      <Text mt="26px !important" color="error" fontSize="20px" fontWeight="500">
        {errorMessage}
      </Text>
      <Button
        mt="10px !important"
        size="sm"
        onClick={() => setCurrentView(1)}
        minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
      >
        Try again
      </Button>
    </VStack>
  );
}
