import configs from "config";

const coinMap = configs.coins;

// console.log("COIN MAP", coinMap);

type utilsMapType = Partial<{
  enactPrecision: (...args: any[]) => number;
  enactPrecisionWithUnit: (...args: any[]) => string;
  enactUnit: (...args: any[]) => string | number;
}>;

const isSupported = (coin: string) => Object.keys(coinMap).includes(coin);

const toPrecision = (number: number, coin: string, showUnit = true, precision = 8, forcePrecisionParam = false) => {
  const utilsMap: utilsMapType = {
    enactPrecision: (_precision: number) => normaliseExponents(Number(Number(number).toFixed(_precision))),
    enactPrecisionWithUnit: (_precision: number, _coin: string) =>
      `${normaliseExponents(Number(Number(number).toFixed(_precision)))} ${String(_coin)}`,
  };

  utilsMap.enactUnit = (_precision, _coin) =>
    showUnit ? utilsMap?.enactPrecisionWithUnit!(_precision ?? precision, _coin) : utilsMap?.enactPrecision!(_precision);

  if (!isSupported(coin)) return utilsMap.enactUnit(precision, String(coin).toUpperCase());

  return utilsMap.enactUnit(forcePrecisionParam ? precision : (coinMap as any)[coin]?.precision, (coinMap as any)[coin].label);
};

export default toPrecision;

function normaliseExponents(value: number) {
  let x: any = value;
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}
