import { Text, HStack } from "@chakra-ui/layout";
import { BoxProps, TextProps, CircularProgressProps, useColorModeValue } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { FC } from "react";
import { when } from "utils";
import CircularLoader from "../Loader/CircularLoader";

export interface InputLabelProps extends BoxProps {
  isLoading?: boolean;
  htmlFor?: string;

  isInvalid?: boolean;

  _text?: TextProps;
  _progress?: CircularProgressProps;
}

const InputLabel: FC<InputLabelProps> = (props) => {
  const { isLoading, isInvalid, color: cc, children, fontSize = "sm", htmlFor, _text, _progress, ...xprops } = props;

  const color = useColorModeValue(when(isInvalid ?? false, "red.400", "black"), when(isInvalid ?? false, "red.400", "white"));

  return (
    <HStack alignItems="center" fontSize={fontSize} {...xprops}>
      <Text
        as="label"
        htmlFor={htmlFor}
        display="inline-block"
        fontSize="inherit"
        fontWeight="500"
        color={cc ?? color}
        mb="4px !important"
        {..._text}
      >
        {children}
      </Text>

      <AnimatePresence initial={false}>
        <motion.div
          key={`input-label-motion-${isLoading ? "loading" : "stale"}`}
          style={{ display: "inherit", marginTop: "-3px" }}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          {isLoading && <CircularLoader {..._progress} />}
        </motion.div>
      </AnimatePresence>
    </HStack>
  );
};

export default InputLabel;
