import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
  useToast,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { Group, Icon, Link, Logo } from "components";
import Recaptcha from "react-google-recaptcha";
import { useEffect, useMemo, useRef, useState } from "react";
import { useColor, useDispatch, usePartialState, useSelector } from "hooks";
import { forgotPassword, selectAuth } from "store/slices";
import PinView from "ui/Common/PinView";
import { navigate, useLocation } from "@reach/router";
import configs from "config";
import { convertNumberMS, switchStyle, when } from "utils";
import isEqual from "lodash/isEqual";
import { AuthImage } from "ui";
import { AnimatePresence, motion } from "framer-motion";

interface IState {
  code: string;
  initialTime: number;
  action: "verifycode" | "resend" | "none";
  timerStarted: boolean;
}

export default function VerifyResetCode(props: { path: string }) {
  const toast = useToast();
  const location = useLocation();
  const recaptchaRef = useRef<any>(null);
  const dispatch = useDispatch();
  const {
    resetPassword: { status, message, nextRequestInSecs },
  } = useSelector(selectAuth);

  console.log("Time", nextRequestInSecs);

  const prevTime = useRef(0);
  const { colorMode } = useColorMode();
  const color = useColor();
  const timerColor = useColorModeValue("primary.default", "secondary.500");

  const [delivery, setDelivery] = useState<"sms" | "call">("sms");
  const [state, set] = usePartialState<IState>({ initialTime: 0, timerStarted: false });
  const isLoading = useMemo(() => status === "loading" && state?.action === "verifycode", [status, state?.action]);
  const isResending = useMemo(() => status === "loading" && state?.action === "resend", [status, state?.action]);

  const isTimerComplete = useMemo(() => (state?.initialTime ?? 0) === 0, [state?.initialTime]);
  const formattedTime = useMemo(() => convertNumberMS(String(state?.initialTime!)), [state?.initialTime]);

  const toggleDelivery = (e: any) => {
    e?.preventDefault();
    if (delivery === "call") setDelivery("sms");
    else setDelivery("call");
  };

  const verifyCode = async (code?: string) => {
    const captcha = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();
    dispatch(forgotPassword({ email: (location.state as any)?.email as string, code: code ?? state?.code, humanKey: captcha }));
    set({ action: "verifycode" });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await verifyCode();
  };

  const handleResendCode = async (e: any) => {
    e.preventDefault();
    const captcha = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();
    dispatch(forgotPassword({ email: (location.state as any)?.email as string, humanKey: captcha, call: delivery === "call" }));
    set({ action: "resend" });
  };

  console.log("Location", location.state);

  const isResendDisabled = useMemo(
    () => !(location?.state as any)?.email || isResending || !isTimerComplete,
    [location, isResending, isTimerComplete]
  );
  const isDisabled = useMemo(() => !state?.code || (!!state?.code && state.code.length < 6) || isLoading, [state, isLoading]);

  useEffect(() => {
    if (!!nextRequestInSecs && !isEqual(prevTime.current, nextRequestInSecs ?? 1)) {
      set({ initialTime: nextRequestInSecs, timerStarted: true });
      prevTime.current = nextRequestInSecs;
    }

    // eslint-disable-next-line
  }, [nextRequestInSecs, prevTime]);

  useUpdateEffect(() => {
    if (status === "success" && state?.action === "verifycode") {
      toast({
        position: "bottom-right",
        title: "Success",
        description: `A reset link has been sent to your email address`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate(configs.paths.login, { replace: true });
      set({ action: "none" });
    }

    if (status === "success" && state?.action === "resend") {
      toast({
        position: "bottom-right",
        title: "Success",
        description: message,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      set({ initialTime: nextRequestInSecs ?? 600, action: "none" });
    }
  }, [toast, status, state?.action, message, nextRequestInSecs]);

  useEffect(() => {
    let timer: any;
    if (state?.initialTime! > 0 && state?.timerStarted) {
      setTimeout(() => {
        set({ initialTime: state?.initialTime! - 1 });
      }, 1000);
    }

    return () => {
      !!timer && clearTimeout(timer);
    };

    // eslint-disable-next-line
  }, [state?.initialTime, state?.timerStarted]);

  const [isMobile] = useMediaQuery("(max-width: 1023px)");
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  return (
    <Box
      minH="100vh"
      pos="relative"
      sx={{
        "--w": isLargerThan1080 ? "580px" : "480px",
      }}
    >
      <AuthImage isMobile={isMobile} />
      <Box pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
        <Group maxWidth="700px" margin="auto" padding={{ sm: "32px 20px 43px", "2sm": "80px 20px 43px" }}>
          <Logo
            color={switchStyle(colorMode, { light: "primary.default", dark: "secondary.500" })}
            fontSize="54px"
            mb={{ sm: "12px", "2sm": "52px" }}
            _name={{ w: "110px" }}
          />

          <Box mb="40px !important">
            <Heading
              fontSize={{ sm: "24px", "2sm": "32px" }}
              color={switchStyle(colorMode, { light: "#303131", dark: "grey.100" })}
            >
              Reset Code
            </Heading>
            <Text color={switchStyle(colorMode, { light: "grey.800", dark: "grey.100" })} fontSize="18px" mt="14px">
              Enter the 6-digit reset code sent to your phone
            </Text>
          </Box>

          <Stack as="form" maxW="526px" gridGap="24px" onSubmit={handleSubmit}>
            <PinView
              _infoContainer={{ display: "none" }}
              showCreatePin={false}
              onPin={(pin) => set({ code: pin })}
              on_complete={async (code) => await verifyCode(code)}
            />

            <VStack mt="66px !important" alignItems="center">
              <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />

              <Button
                type="submit"
                minW={{ base: "100%", tb: "400px" }}
                width="100%"
                isLoading={isLoading}
                disabled={isDisabled}
                rightIcon={<Icon type="arrowRight" color="white" />}
              >
                Submit
              </Button>

              <VStack mt="40px !important" minW="400px">
                {state?.timerStarted && (
                  <Text fontWeight="700" color={timerColor} my="22px !important">
                    {formattedTime}
                  </Text>
                )}
                <Button
                  variant="max"
                  minW="fit-content"
                  maxW="fit-content"
                  p="0 10px"
                  fontSize="14px"
                  isLoading={isResending}
                  disabled={isResendDisabled}
                  onClick={handleResendCode}
                >
                  {when(delivery === "sms", prevTime.current === 0 ? "send code" : "resend code", "dial")}
                </Button>

                <AnimatePresence exitBeforeEnter initial={false}>
                  <motion.div
                    key={`delivery-${when(delivery === "sms", "sms", "call")}`}
                    initial={{ opacity: 0, scale: 0.98 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.98 }}
                  >
                    <HStack
                      fontSize="14px"
                      fontWeight="600"
                      mt="10px !important"
                      as="a"
                      href="#"
                      color={color("primary.default", "secondary.400")}
                      onClick={toggleDelivery}
                      textDecoration="underline"
                      minH="28px"
                    >
                      {delivery === "sms" && (
                        <>
                          <Icon type="calling" color={color("primary.default", "secondary.400")} />
                          <Text>Call me via phone instead</Text>
                        </>
                      )}

                      {delivery === "call" && <Text>Send code via sms.</Text>}
                    </HStack>
                  </motion.div>
                </AnimatePresence>

                <Link mt="160px !important" to="/" fontWeight="500" fontSize="sm" textDecoration="underline">
                  Back to Login
                </Link>
              </VStack>
            </VStack>
          </Stack>
        </Group>
      </Box>
    </Box>
  );
}

// function useSimpleCountdown(count: number, setCount: (count: number) => void) {
//   const isComplete = useMemo(() => count === 0, [count]);

//   useEffect(() => {
//     let timer: any;
//     if (count > 0)
//       setInterval(() => {
//         setCount(count - 1);
//       }, 1000);
//     return () => {
//       !!timer && clearInterval(timer);
//     };

//     // eslint-disable-next-line
//   }, [count]);

//   const formatted = convertNumberMS(String(count));

//   return { count, formatted, isComplete };
// }
