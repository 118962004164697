import { Box, LinkProps, Text, useColorMode, Center } from "@chakra-ui/react";
import { FC } from "react";
import Link from "components/Link/Link";
import { motion, AnimatePresence } from "framer-motion";
import { switchStyle } from "utils";

interface SidebarLinkProps extends LinkProps {
  icon?: React.ReactNode | any;
  activeIcon?: React.ReactNode | any;
  label: string;
  to: string;
  isCurrent?: boolean;
  _current?: LinkProps;
}

const BottomLink: FC<SidebarLinkProps> = (props) => {
  const { icon, activeIcon, label, to, isCurrent, _current, ...xprops } = props;

  const { colorMode: mode } = useColorMode();

  const whenCurrent = (): LinkProps =>
    isCurrent
      ? {
          bg: "transparent",
          color: switchStyle(mode, {
            light: "primary.800",
            dark: "secondary.400",
          }),
          pos: "relative",
          fontWeight: "500",
          _after: {
            content: '""',
            // pos: "absolute",
            // w: "100%",
            // h: "90%",
            // top: "50%",
            // transform: "translateY(-50%)",
            bg: switchStyle(mode, {
              light: "grey.default",
              dark: "rgba(249, 250, 249, 0.05)",
            }),
            // right: -5,
            // borderLeftRadius: "50px",
            // transition: "all .3s ease-in-out",
            // willChange: "width box-shadow",
            // zIndex: -1,
          },
          ..._current,
        }
      : {};

  const syntheticProps = { ...xprops, ...whenCurrent() };

  return (
    <Link
      to={to}
      // p="14px 16px"
      // p="0px 16px"
      // w="100%"
      color="grey.default"
      _hover={{
        bg: "transparent",
      }}
      // overflow="hidden"
      fontWeight="500"
      _focus={{
        shadow: "none",
        transition: "all .3s ease-in-out",
      }}
      {...syntheticProps}
    >
      <Center flexDir="column">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`icon-${isCurrent ? "active" : "inactive"}`}
            initial={{ scale: 0.9, x: 4, opacity: 0 }}
            animate={{ scale: 1, x: 0, opacity: 1 }}
            exit={{ scale: 0.9, x: 4, opacity: 0 }}
          >
            {!isCurrent && <Box boxSize="20px" as={icon} />}
            {isCurrent && <Box boxSize="20px" as={activeIcon} />}
          </motion.div>
        </AnimatePresence>

        <Text mt="5px" fontSize="12px" fontWeight="inherit" color="inherit">
          {label}
        </Text>
      </Center>
    </Link>
  );
};

export default BottomLink;
