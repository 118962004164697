import { Badge, BadgeProps, Box, Button, HStack, IconButton, Image, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { toCoinLocale, toPrecision, when } from "utils";

import { navigate } from "@reach/router";
import BitcoinIMG from "assets/images/bitcoin.png";
import { useP2PBestRates } from "hooks";
import Span from "components/Common/Span";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import Icon from "components/Icon/Icon";
import { toUpper } from "lodash";

interface BestRateProps {
  coin: string;
  currency: string;
  adsType: "sell" | "buy";
}

const adsTypeMap = (type: string) => {
  const map: Record<string, "selling" | "buying"> = {
    buy: "selling",
    sell: "buying",
  };
  return map[type];
};

export default function BestRate(props: BestRateProps) {
  const { coin, currency, adsType } = props;
  console.log(adsTypeMap(adsType));

  // const [sliderValue, setSliderValue] = useState(50);

  const { bestRates, isLoading } = useP2PBestRates(coin, currency, adsTypeMap(adsType));

  const best_one = useMemo(() => bestRates[0], [bestRates]);

  console.log("The best one is ", best_one);

  const id = useMemo(() => best_one?._id, [best_one]);
  const price = useMemo(() => best_one?.unitCost ?? 0, [best_one]);
  const dynamic = useMemo(() => best_one?.dynamic ?? 0, [best_one]);
  // const isDynamicListing = useMemo(() => bestRates[0]?.dynamic === true, [bestRates]);
  // const paymentOptions = useMemo(() => bestRates[0]?.paymentOption ?? [], [bestRates]);

  const tooltipLabel = useMemo(() => `Dynamic listing @ ${best_one?.price} ${toUpper(currency)}/USD`, [currency, best_one]);

  // const label = (source: string) => {
  //   const map: Record<string, string> = {
  //     // [method]: `${capitalize(method)}`,
  //     external: "Bank Transfer",
  //     internal: "Bitmama Wallet",
  //   };

  //   return map[source];
  // };

  // console.log("P2P Widget Best Rates", adsType, bestRates);

  const info = useMemo(() => {
    const map: Record<BestRateProps["adsType"], { label: string; btnBg: string; btnLabel: string; onClick: () => void }> = {
      sell: {
        label: "Selling Price",
        btnBg: "#ff6347",
        btnLabel: when(!price, "Create a Buy Ad", "Sell Now"),
        onClick() {
          if (!price) return navigate(`/p2p/create?adsType=buy`);
          else return navigate(`/p2p/${adsType}/${id}`);
        },
      },
      buy: {
        label: "Buying Price",
        btnBg: "#6FBE45",
        btnLabel: when(!price, "Create a Sell Ad", "Buy Now"),
        onClick() {
          if (!price) return navigate(`/p2p/create?adsType=sell`);
          else return navigate(`/p2p/${adsType}/${id}`);
        },
      },
    };

    return map[adsType];
  }, [adsType, price, id]);

  return (
    <Box
      p={{ sm: "16px", "2sm": "30px", md: "30px" }}
      minW={{ sm: "100%", "2sm": "100%", "3sm": "380px", md: "380px" }}
      maxW="380px"
      borderRadius="10px"
      bg="whiteAlpha.200"
    >
      <HStack>
        <Image boxSize={{ sm: "30px", "2sm": "40px", md: "40px" }} src={BitcoinIMG} alt="bitcoin image" />
        <Text
          fontSize={{ sm: "xs", "2sm": "md", "3sm": "md", md: "md" }}
          fontFamily="var(--bitmama-fonts-heading)"
          fontWeight="600"
        >
          {info.label}
        </Text>
        {/* <Text fontSize="12px" color="red.500" fontWeight="500">
          Best Price
        </Text> */}

        <ConditionalRender shouldRender={!!dynamic}>
          <Tooltip hasArrow label={tooltipLabel} placement="top-start">
            <IconButton
              bg="transparent"
              color="transparent"
              aria-label="dyn"
              _hover={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              icon={<Icon type="bolt" boxSize="18px" />}
              sx={{ all: "unset" }}
            />
          </Tooltip>
        </ConditionalRender>
      </HStack>

      {/* <Box>
        <Slider aria-label="slider-ex-4" value={sliderValue} onChange={(val) => setSliderValue(val)}>
          <SliderMark value={10_000}>10k</SliderMark>
          <SliderTrack bg="accent.50">
            <SliderFilledTrack bg="accent.default" />
          </SliderTrack>
          <SliderMark value={sliderValue} textAlign="center" bg="blue.500" color="white" mt="-10" ml="-5" w="12">
            {sliderValue}%
          </SliderMark>
          <SliderThumb boxSize={5}>
            <Box color="tomato" fontSize="10px">
              ₦
            </Box>
          </SliderThumb>
        </Slider>

        <Input placeholder="Amount" size="xs" minH="40px" h="20px" borderRadius="8px" maxH="20px" />
      </Box> */}

      {!!price && (
        <HStack my="12px" justifyContent="space-between">
          <VStack alignItems="start">
            <HStack
              fontSize={{ sm: "15px", "1sm": "xl", "2sm": "2xl", md: "32px" }}
              fontWeight="600"
              fontFamily="var(--bitmama-fonts-heading)"
            >
              <Span>
                {toCoinLocale(currency)}
                {/* {toCoinLocale(coin)} {currencyFormat(String(currency) as any).format(Number(toPrecision(price, currency, false)))} */}
              </Span>
              <Span color={info.btnBg}>
                {Number(toPrecision(price, currency, false)).toLocaleString()}
                {/* {toCoinLocale(coin)} {currencyFormat(String(currency) as any).format(Number(toPrecision(price, currency, false)))} */}
              </Span>
            </HStack>
          </VStack>

          {/* <Button
            p="8px 6px"
            variant="max"
            color="white"
            borderRadius="5px"
            minW="fit-content"
            bg="whiteAlpha.200"
            fontSize="14px"
            fontWeight="500"
            disabled={isLoading}
            leftIcon={<Icon type="convert" color="inherit" />}
          >
            {toCoinLocale(currency)}
          </Button> */}
        </HStack>
      )}
      {/* 
      {!!price && isDynamicListing && (
        <Text fontSize="12px" fontWeight="600">
          {`(Dynamic Listing)`}
        </Text>
      )} */}

      {!price && (
        <Text my="10px " fontSize="18px" fontWeight="600">
          {when(!isLoading, "No Listings", "Loading...")}
        </Text>
      )}

      <Button
        p={{ sm: "8px", "3sm": "12px 24px", md: "initial" }}
        size={{ sm: "xxs", "3sm": "xs", md: "sm" }}
        mt="10px"
        variant="solid"
        color="white"
        minW="50%"
        minH="fit-content"
        fontSize="12px"
        fontWeight="600"
        bg={info.btnBg}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={info.onClick}
        fontFamily="var(--bitmama-fonts-heading)"
      >
        {info.btnLabel}
      </Button>

      {/* {!!price && (
        <HStack mt="24px" w="100%" flexWrap="wrap">
          {paymentOptions.map((opt) => (
            <Fragment key={Math.random() * 76767}>
              <Method>{label(opt?.source)}</Method>
            </Fragment>
          ))}
        </HStack>
      )} */}
    </Box>
  );
}

export function Method(props: BadgeProps) {
  return (
    <Badge
      borderRadius="50px"
      p="2px 5px"
      color="black"
      bg="#F7EBB8"
      fontSize="10px"
      fontWeight="600"
      my="4px"
      textTransform="capitalize"
      {...props}
    >
      {props.children}
    </Badge>
  );
}
