import { Box, Stack, useColorModeValue } from "@chakra-ui/react";
import { useGetWalletTransationsV2Query } from "apis";
import {
  Card,
  EmptyCrate,
  Group,
  // Link,
  MainLayoutContainer,
  PageLoading,
  Paginator,
  Title,
  TitleBar,
  // TitleBarActions,
  TransactionItem,
  Wallet,
} from "components";
import { SidePageViewProps, useModals, useSidePage } from "contexts";
import { AnimatePresence, motion } from "framer-motion";
import { useCoin, usePartialState, useSelector, useWalletBalance } from "hooks";
import { useMemo } from "react";
import { selectUser } from "store/slices";
import { toQueryString, when } from "utils";

import toLower from "lodash/toLower";
import { useAppConfig } from "contexts/appconfig.context";

interface FiatProps extends SidePageViewProps {}

export default function Fiat(props: FiatProps) {
  const { profile } = useSelector(selectUser);
  const { data } = useSidePage();
  const { open } = useModals();

  const { appfigs } = useAppConfig();
  const { getBalance, isLoading } = useWalletBalance();

  const { label } = useCoin(data?.coin);
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const balance = useMemo(() => getBalance(data?.coin), [getBalance, data?.coin]);

  const [queries, set] = usePartialState({
    page: 1,
    limit: 4,
    unit: data?.coin,
    sortBy: "createdAt:desc",
  });

  const { data: tx, isLoading: isTxLoading, isFetching } = useGetWalletTransationsV2Query(toQueryString(queries));

  const { depositConfig, withdrawalConfig } = appfigs ?? {};

  const totalCount = useMemo(() => tx?.totalCount, [tx]);
  const transactions = useMemo(() => tx?.transactions, [tx]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);
  const passedLimit = useMemo(() => (totalCount ?? 0) > queries.limit!, [totalCount, queries.limit]);

  const isFiatDepositDisabled = useMemo(() => !!depositConfig?.disableFiatDeposit, [depositConfig]);
  const isFiatWithdrawalDisabled = useMemo(() => !!withdrawalConfig?.disableFiatWithdrawal, [withdrawalConfig]);

  const canFundWallet = useMemo(() => {
    const map: Record<string, string> = {
      nigeria: "ngn",
      ghana: "ghs",
      kenya: "kes",
    };

    return map[toLower(profile?.country ?? "nigeria")] === data?.coin;
  }, [profile, data?.coin]);

  const canWithdrawWallet = useMemo(() => {
    const map: Record<string, { currency: string; enabled: boolean }> = {
      nigeria: { currency: "ngn", enabled: true },
      ghana: { currency: "ghs", enabled: true },
      kenya: { currency: "kes", enabled: true },
    };

    const opt = map[toLower(profile?.country ?? "nigeria")];

    return opt?.currency === data?.coin && opt?.enabled;
  }, [profile, data?.coin]);

  // console.log("Fiat Tx Data", tx);

  const handlePageChange = (page: number) => {
    set({ page });
  };

  return (
    <Box px={{ base: "0", "1sm": "32px" }} {...props} overflowY="scroll">
      <TitleBar px={{ base: "20px", "1sm": "0" }}>
        <Title fontSize="20px">{label} Wallet</Title>
      </TitleBar>

      <MainLayoutContainer maxW="2xl" mt="28px">
        <Wallet
          w="100%"
          maxW="unset"
          value={balance ?? 0}
          isLoading={isLoading}
          currency={data?.coin}
          _outline={{
            disabled: !canWithdrawWallet || isFiatWithdrawalDisabled,
            onClick: open("fiat:withdraw", { coin: data?.coin }),
          }}
          _solid={{ disabled: !canFundWallet || isFiatDepositDisabled, onClick: open("fiat:fund", { coin: data?.coin }) }}
          _navigations={{ display: "none" }}
        />

        <Group>
          <TitleBar>
            <Title isLoading={isFetching} fontSize="18px">
              Wallet Transactions
            </Title>

            {/* {hasTx && passedLimit && (
              <TitleBarActions>
                <Link to="/">See All</Link>
              </TitleBarActions>
            )} */}
          </TitleBar>
          <Card bg={bg} w="100%" p="8px" overflow="hidden">
            <AnimatePresence exitBeforeEnter initial={false}>
              <motion.div
                key={`tx-list-${when(isTxLoading, "loading", "loaded")}`}
                initial={{ opacity: 0, scale: 0.98 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.98 }}
              >
                {isTxLoading && <PageLoading isLoading={isTxLoading} />}
                {!isTxLoading && (
                  <Stack>
                    {(transactions ?? []).map((tx, id) => (
                      <TransactionItem
                        mt="0 !important"
                        w="100%"
                        key={`transaction-${id}`}
                        unit={tx?.unit}
                        type={tx?.type}
                        value={tx?.value}
                        createdAt={tx?.createdAt}
                        description={tx?.description}
                      />
                    ))}
                  </Stack>
                )}

                {!isTxLoading && !isFetching && !hasTx && <EmptyCrate type="transaction" />}
              </motion.div>
            </AnimatePresence>
          </Card>

          {passedLimit && (
            <Box w="100%">
              <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
            </Box>
          )}
        </Group>
      </MainLayoutContainer>
    </Box>
  );
}

Fiat.displayName = "SidePageView";
