import { Box, BoxProps, Button, Grid, Heading, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useSellCryptoMutation } from "apis";
import { AbstractModal, ModalView, useAbstractModal, useModalView, ViewSwitcher } from "components";
import { useTicker } from "hooks";
import { toUpper } from "lodash";
import { useEffect, useMemo } from "react";
import PinView from "ui/Common/PinView";
import { currencyFormat, toCoinLocale, toPrecision, when } from "utils";
import { IState } from "../Features/Sell/SellForm";
import { AppAnalyticEvent } from "interfaces";
import { Emitter } from "libs";

interface SellCryptoPreviewProps {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  rate: number;
  fee: number;
  isOpen: boolean;
  onClose: () => void;
  reset: () => void;
}

interface TransactionSummaryProps extends SellCryptoPreviewProps {}

interface ItemProps extends BoxProps {
  info: string;
  description: string;
}

interface UseSellCrytoProps extends SellCryptoPreviewProps {}

export default function SellCryptoPreview(props: SellCryptoPreviewProps) {
  const { isOpen, onClose } = props;

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _mobileSuccessStyles={{
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
      }}
      _content={{
        borderRadius: { base: "0", "1sm": "20px" },
      }}
    >
      <ModalView>
        <Preview {...props} />
      </ModalView>
    </AbstractModal>
  );
}

function TransactionSummary(props: TransactionSummaryProps) {
  const { state, fee, onClose } = props;

  const { hasNext, onNext } = useModalView();
  const bcolor = useColorModeValue("#B7DFA280", "#B7DFA220");

  const { sellCrypto, finalQuantityInFiat, /*swapFee, */ isLoading } = useSellCryto(props);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (state?.payTobank && hasNext) {
      onNext && onNext();
      return;
    }

    await sellCrypto();
  };

  return (
    <VStack
      as="form"
      p={{ base: "95px 20px", "1sm": "45px 40px", "2sm": "45px 100px" }}
      minH={{ base: "100vh", "1sm": "initial" }}
      onSubmit={handleSubmit}
    >
      <VStack maxW="460px" m="0 auto" mb="40px">
        <Heading as="h6" fontSize="18px">
          Transaction Summary
        </Heading>

        <Box mt="40px !important">
          <Grid templateColumns="repeat(2, 1fr)">
            <Item
              info={`${toCoinLocale(state?.crypto ?? "btc")} Quantity`}
              description={toPrecision(+(state?.cryptoAmount ?? 0), state?.crypto ?? "btc", false) as string}
              borderRightColor={bcolor}
              borderBottomColor={bcolor}
            />
            <Item
              info={`Amount in ${toUpper(state?.fiat)}`}
              description={currencyFormat((state?.fiat ?? "ngn") as any).format(+(state?.fiatAmount ?? 0))}
              borderBottomColor={bcolor}
            />
          </Grid>
          <Grid templateColumns="repeat(2, 1fr)">
            <Item
              info={`Final Quantity`}
              // description={toPrecision(finalQuantity, state?.crypto ?? "btc", false) as string}
              description={currencyFormat((state?.fiat ?? "ngn") as any).format(finalQuantityInFiat)}
              borderRightColor={bcolor}
            />
            <Item
              info={`Fee ( ${fee * 100}% )`}
              description={toPrecision(Number(state?.cryptoAmount) * fee, state?.crypto ?? "btc") as string}
            />
            {/* <Item info={`Fee ( ${fee * 100}% )`} description={currencyFormat((state?.fiat ?? "ngn") as any).format(swapFee)} /> */}
          </Grid>
        </Box>
      </VStack>

      <VStack mt="14px !important" px={{ base: "20px", "1sm": "46px" }} w="100%">
        <Button
          minW="unset"
          w="100%"
          onClick={handleSubmit}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {state?.payTobank ? "Continue" : "Sell"}
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose} disabled={isLoading}>
          Back
        </Button>
      </VStack>
    </VStack>
  );
}

function TransactionPin(props: SellCryptoPreviewProps) {
  const { state, set, onClose } = props;

  const { sellCrypto, isLoading } = useSellCryto(props);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    sellCrypto();
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    onClose();
  };

  const isDisabled = useMemo(() => !state?.pin || (!!state?.pin && state?.pin?.length < 6) || isLoading, [state?.pin, isLoading]);

  return (
    <VStack
      as="form"
      p={{ base: "95px 20px", "1sm": "45px 40px", "2sm": "45px 100px" }}
      minH={{ base: "100vh", "1sm": "initial" }}
      onSubmit={handleSubmit}
    >
      <PinView
        onPin={(pin) => set({ pin })}
        on_complete={async (pin) => {
          set({ pin });
          await sellCrypto(pin);
        }}
      />

      <VStack mt="74px !important" px={{ base: "20px", "1sm": "46px" }} w="100%">
        <Button
          minW="unset"
          w="100%"
          onClick={handleSubmit}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isLoading}
          disabled={isDisabled}
        >
          Sell
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function Preview(props: SellCryptoPreviewProps) {
  const { state } = props;

  return (
    <ViewSwitcher>
      <TransactionSummary {...props} />
      {state?.payTobank && <TransactionPin {...props} />}
    </ViewSwitcher>
  );
}

function Item(props: ItemProps) {
  const { info, description, ...xprops } = props;

  const icolor = useColorModeValue("grey.400", "grey.100");
  const dcolor = useColorModeValue("grey.800", "white");

  return (
    <VStack border="1px solid transparent" p={{ base: "24px 12px", "1sm": "24px 40px" }} {...xprops}>
      <Text color={icolor} fontSize={{ base: "12px", "1sm": "14px" }} fontWeight="500">
        {info}
      </Text>
      <Text color={dcolor} fontSize={{ base: "16px", "1sm": "18px" }} fontWeight="500">
        {description}
      </Text>
    </VStack>
  );
}

function useSellCryto(props: UseSellCrytoProps) {
  const { state, rate, fee, reset, onClose } = props;

  const { updateModal } = useAbstractModal();

  const [_sellCrypto, { data: sellData, isLoading, isSuccess, isError, reset: resetApiState }] = useSellCryptoMutation();

  const { ticker } = useTicker(state?.crypto!, state?.fiat!);
  const swapFee = useMemo(() => +(state?.fiatAmount ?? "0") * (fee ?? 0), [state?.fiatAmount, fee]);
  const finalQuantity = useMemo(() => (+(state?.fiatAmount ?? 0) - swapFee) / rate, [state?.fiatAmount, rate, swapFee]);
  const finalQuantityInFiat = useMemo(() => finalQuantity * rate, [finalQuantity, rate]);

  // console.log("Final Quatity in Fiat", finalQuantityInFiat);

  const sellCrypto = async (pin?: string) => {
    const reqData = {
      coin: state?.crypto,
      fiatCurrency: state?.fiat,
      sellPrice: rate,
      amountOfCryptoToSell: state?.cryptoAmount,
      fiatAmount: state?.fiatAmount,
      ticker,
    };

    Emitter.emit(AppAnalyticEvent.SELL_INITIATED, reqData);
    if (state?.payTobank) Object.assign(reqData, { bankId: state?.bank, pin: pin ?? state?.pin });

    const result = await _sellCrypto({ ...reqData }).unwrap();
    if (!!result) {
      Emitter.emit(AppAnalyticEvent.SELL_SUBMITTED, reqData);
    }
  };

  const successMessage = useMemo(() => {
    const map: Record<string, string> = {
      // wallet: `${toPrecision(
      //   +(state?.cryptoAmount ?? 0),
      //   state?.crypto ?? "btc",
      //   false
      // )} has been deposited into your ${toCoinLocale(state?.crypto ?? "btc")} Wallet`,
      wallet: `${toPrecision(+(state?.cryptoAmount ?? 0), state?.crypto ?? "btc")} sold successfully!`,
      bank: `${toPrecision(+(state?.fiatAmount ?? 0), state?.fiat ?? "ngn")} will be deposited into your ${
        state?.via === "momo" ? "mobile money" : state.via
      } account`,
    };

    const key = when(state?.payTobank ?? false, "bank", "wallet");

    return map[key];
  }, [state]);

  useEffect(() => {
    const close = () => {
      reset();
      onClose();
      updateModal({ isSuccess: false });
    };

    if (isSuccess && !isError && sellData) {
      updateModal &&
        updateModal({
          isSuccess: true,
          success: {
            description: successMessage,
            actions: [
              {
                label: "Close",
                onClick: () => {
                  close();
                },
              },
            ],
          },
        });
    }

    return () => {
      resetApiState();
    };
  }, [isSuccess, isError, sellData, reset, onClose, resetApiState, state, updateModal, successMessage]);

  return { sellCrypto, isLoading, finalQuantity, ticker, swapFee, finalQuantityInFiat };
}
