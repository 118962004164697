export default function recursiveObjectSearch(object: any, key: string) {
  let value;
  // eslint-disable-next-line
  Object.keys(object).some(function (k) {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === "object") {
      value = recursiveObjectSearch(object[k], key);
      return value !== undefined;
    }

    // return false;
  });
  return value;
}
