import { Box, Button, Heading, HStack, useColorModeValue } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import { format, parseISO } from "date-fns";
import { useGetAffiliateEarningDetailsQuery } from "apis";
import { DetailItem, DetailItemProps, Group, Icon, MainLayoutContainer, PageMotion } from "components";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";

interface HistoryDetailsProps extends PageProps {}

export default function RewardHistoryDetails(props: HistoryDetailsProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const bg = useColorModeValue("#F6F8F74F", "#f6f8f703");

  const { id } = useParams();

  const { data } = useGetAffiliateEarningDetailsQuery(id);

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountRules" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              User Details
            </Heading>
          </HStack>
        </Box>

        <Group my="28px" bg={bg} borderRadius="16px" p="24px 24px">
          <HistoryDetailItem title="Username" description={`@${data?.earning?.referredUserId?.username}`} />
          <HistoryDetailItem title="Full Name" description={data?.earning?.referredUserId?.fullName} />
          <HistoryDetailItem
            title="Registration/Sign Up Date"
            description={data?.earning?.createdAt && format(parseISO(data?.earning?.createdAt), "MMMM e, yyyy")}
          />
          <HistoryDetailItem title="Verification Status" description="Level 3 Verification" />
        </Group>

        {/* <Group my="28px">
          <Heading as="h5" fontSize="18px">
            Transaction History
          </Heading>

          <Stack mt="36px !important">
            <FormControl>
              <InputLabel>Filter by</InputLabel>
              <Select placeholder="Filter by">
                <option>Date</option>
              </Select>
            </FormControl>

            <VStack mt="32px !important">
              <Button>Apply</Button>
              <Button variant="outline">Cancel</Button>
            </VStack>
          </Stack>
        </Group> */}
      </MainLayoutContainer>
    </PageMotion>
  );
}

function HistoryDetailItem(props: DetailItemProps) {
  return (
    <DetailItem
      py="0px"
      mb="32px !important"
      borderBottomColor="transparent"
      _title={{ fontSize: "18px", fontWeight: "500", color: "inherit" }}
      _description={{ fontSize: "14px", fontWeight: "500", color: "inherit" }}
      {...props}
    />
  );
}
