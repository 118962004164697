import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Heading,
  HStack,
  Input,
  useColorModeValue,
  //   useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { navigate, useParams } from "@reach/router";
import { useGetCoinToUsdRateQuery, useGetSavedBanksQuery, useGetSellListingByIdQuery, useSellToBuyListingMutation } from "apis";
import {
  AmountInput,
  Card,
  ConditionalRender,
  FormInfo,
  Icon,
  InputLabel,
  MainLayoutContainer,
  PageLoading,
  PaymentMethod,
  Select,
  ShareActions,
  Steps,
  Username,
  useModalView as useSteps,
} from "components";
import { useCoin, useColor, useDisclosures, usePartialState, useWalletBalance } from "hooks";
import { AppAnalyticEvent, P2PListingTradeRo, P2PSellToBuyListingRo } from "interfaces";
import { useMemo, useState } from "react";
import { clamp, currencyFormat, deduction, toCoinLocale, toPrecision, when } from "utils";

import toLower from "lodash/toLower";
import { SuccessModal } from "ui";
import { Emitter } from "libs";

interface SellAdFeatureProps {
  setHideBackButton: (update: boolean) => void;
}
interface InfoHubProps extends Partial<P2PListingTradeRo> {}

interface FormProps extends Partial<P2PListingTradeRo>, IStateExtension {
  unitCost?: number;
  isLoading?: boolean;
  setHideBackButton: (update: boolean) => void;
}
interface DetailsProps extends Partial<P2PListingTradeRo> {
  unitCost?: number;
}

interface IStateExtension {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  reset: () => void;
}

interface IPaymentOption {
  method: string; // bankTransfer | changeraId - (left out for momentarily) | bitmamaWallet
  receivingIntoLabel: string; // same as bank name
  receivingIntoAddress: string; // same as account number;
  accountName: string; // bank account name
  saveRecord: boolean;
  payingToLabel: string; // same as bankName, used when state?.type is buy;
}

interface IState {
  method: string;
  amount: string;
  volume: string;
  saveRecord: boolean;
  paymentOption: IPaymentOption[];

  receiveIntoInternalWallet: boolean;
}

const floatString = (n: string | number): number | string =>
  (n && (Number.isInteger(n) ? Number(n).toFixed(2) : n.toString())) ?? "";

type ModalTypes = "success";

export default function SellAdFeature(props: SellAdFeatureProps) {
  const { id } = useParams();
  const [state, set, reset] = usePartialState<IState>();

  const { data, isLoading } = useGetSellListingByIdQuery(id);

  return (
    <Steps hideIndicator>
      <StepOne {...{ state, set, reset, isLoading, ...data, ...props }} />
      <BankDetails {...{ state, set, reset, isLoading, ...data, ...props }} />
    </Steps>
  );
}

function StepOne(props: FormProps) {
  // const { id } = useParams();

  const { state, set, reset, coin, dynamic, price, tradeOption, isLoading, setHideBackButton } = props;

  const reMapCoin = (coin: string) => {
    const map: Record<string, string> = {
      [coin]: coin,
      tbtc: "btc",
      teth: "eth",
      usdt: "usd",
      cusd: "usd",
    };
    return map[coin];
  };

  const { data: rate } = useGetCoinToUsdRateQuery(reMapCoin(coin ?? "btc"), { skip: (coin ?? "btc").includes("usd") });

  const exRate = useMemo(() => +(rate?.exchangeRate?.buy ?? 1), [rate]);

  const unitCost = useMemo(() => {
    const _price = price ?? 0;
    if (dynamic) return coin?.includes("usd") ? 1 * _price : exRate * _price;
    return tradeOption?.unitCost;
  }, [dynamic, price, tradeOption, exRate, coin]);

  // console.log("Data", data, rate);

  return (
    <Box w="100%">
      {isLoading && <PageLoading my="60px" isLoading={isLoading} />}

      {!isLoading && (
        <>
          <InfoHub {...props} />

          <Grid
            my="40px"
            display={{ sm: "flex", md: "grid" }}
            flexDir={{ sm: "column", md: "inherit" }}
            templateColumns="repeat(2,1fr)"
            gap="22px"
          >
            <PaymentDetails {...{ ...props, state, set, reset, unitCost, setHideBackButton }} />
            <AdDetails unitCost={unitCost} {...props} />
          </Grid>
        </>
      )}
    </Box>
  );
}

function BankDetails(props: FormProps) {
  const { state, set, reset, paymentOption, currency, userId, coin, setHideBackButton } = props;
  const color = useColor();

  const { id } = useParams();
  const { onPrev } = useSteps();
  const { open, close, isOpen } = useDisclosures<ModalTypes>();
  const [showButton, setShowButton] = useState(true);

  const { data: _savedBanks, isLoading: isBanksLoading } = useGetSavedBanksQuery();
  const savedBanks = useMemo(() => _savedBanks?.banks ?? [], [_savedBanks]);

  const [makeSell, { isLoading: isSelling }] = useSellToBuyListingMutation();

  console.log("Saved Banks", savedBanks);
  const { bankInfo, handleBankInfo } = useFormHandlers({ state, set, reset });

  const options = useMemo(() => {
    let _opts = (paymentOption ?? []).map((opt) => {
      const label = toLower(`${opt?.label ?? ""} ${opt?.receivingIntoLabel ?? ""} ${opt?.payingToLabel ?? ""}`);
      if (label.includes("bitmama") && opt?.source === "internal") return { key: "bitmamaWallet", label: "Bitmama Wallet" };
      if (label.includes("changera") && opt?.source === "external") return { key: "changera", label: "Changera ID" };
      if (label.includes("bank") && opt?.source === "external") return { key: "bankTransfer", label: "Bank Transfer" };
      return { key: "bankTransfer", label: "Bank Transfer" };
    });
    // console.log("Options", _opts, _opts.map((o) => o?.key).includes("bankTransfer"));
    const hasBankTransfer = _opts.map((o) => o?.key).includes("bankTransfer");
    if (hasBankTransfer && savedBanks.length > 0) _opts.push({ key: "selectAccounts", label: "Select Account" });
    return _opts;
  }, [paymentOption, savedBanks]);

  const fee = useMemo(
    () =>
      (floatString(
        isNaN(deduction(parseFloat(state?.amount ?? ""))) ? "0.00" : deduction(parseFloat(state?.amount ?? ""))
      ) as string) ?? "0.00",
    [state?.amount]
  );

  const handlePrev = () => {
    onPrev();
    setShowButton(false);
    setHideBackButton(false);
  };

  const handleMethod = (value: string) => {
    const receiveIntoInternalWallet = value === "bitmamaWallet";
    set({ method: value, receiveIntoInternalWallet });
  };

  const handleSelectedAccount = (bankId: string) => {
    const bank = savedBanks?.find((bank: any) => String(bank._id) === String(bankId));
    console.log("Saved Bank", bank);
    handleMethod("bankTransfer");
    handleBankInfo({
      accountName: bank?.bankAccountName,
      receivingIntoLabel: bank?.bankName,
      receivingIntoAddress: bank?.bankAccountNumber,
      saveRecord: !!bank?.rawData?.saveRecord,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const eventData = { currency, coin, seller: userId, method: state?.method };
    Emitter.emit(AppAnalyticEvent.P2P_SELL_INITIATED, eventData);
    const result = (await makeSell({
      id,
      ...state,
      volume: parseFloat(state?.amount ?? ""),
      method: null,
      useInternalWallet: state?.method === "bitmamaWallet",
    })) as { data: P2PSellToBuyListingRo };

    const data = result?.data;
    if (!!data) Emitter.emit(AppAnalyticEvent.P2P_SELL_SUBMITTED, eventData);

    console.log("Sell Result", data, result);
    if (!!data && data.tradeStatus === "inSession") navigate(`/p2p/order/${data?._id}-${data?.tradeRef}`);
    if (!!data && data.paymentStatus === "paid") open("success")();
  };

  const isDisabled = useMemo(
    () =>
      !state?.method ||
      (!!state?.method && state.method === "selectAccounts") ||
      (!!state?.method &&
        state.method === "bankTransfer" &&
        !(bankInfo?.accountName && bankInfo?.receivingIntoAddress && bankInfo?.receivingIntoLabel)) ||
      isSelling,
    [state, bankInfo, isSelling]
  );

  return (
    <MainLayoutContainer maxW={["426px"]} m="0" px="4px" py="4px">
      <Box mb="20px">
        {showButton && (
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={color("primary.600", "secondary.200")} />}
            onClick={handlePrev}
            px="2px !important"
          >
            Back
          </Button>
        )}
        <Heading mt="12px" as="h4" fontSize="24px" fontWeight="bold">
          Payment details
        </Heading>
      </Box>

      <Box as="form" onSubmit={handleSubmit}>
        <FormControl mb="34px">
          <InputLabel>Receive with</InputLabel>
          <Select placeholder="Select Payment Option" value={state?.method ?? ""} onChange={(e) => handleMethod(e.target.value)}>
            {options?.map((opt) => (
              <option key={`${opt?.key}-payment-option`} value={opt?.key}>
                {opt?.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <ConditionalRender shouldRender={state?.method === "bankTransfer"}>
          <FormControl mb="34px">
            <InputLabel>Account Name</InputLabel>
            <Input
              placeholder="Enter Account Name"
              value={bankInfo?.accountName ?? ""}
              onChange={(e) => handleBankInfo({ accountName: e.target.value })}
            />
          </FormControl>

          <FormControl mb="34px">
            <InputLabel>Bank Name</InputLabel>
            <Input
              placeholder="Enter Bank Name"
              value={bankInfo?.receivingIntoLabel ?? ""}
              onChange={(e) => handleBankInfo({ receivingIntoLabel: e.target.value })}
            />
          </FormControl>

          <FormControl mb="34px">
            <InputLabel>Account Number</InputLabel>
            <Input
              placeholder="Enter Account Number"
              value={bankInfo?.receivingIntoAddress ?? ""}
              onChange={(e) => handleBankInfo({ receivingIntoAddress: e.target.value })}
            />
          </FormControl>
        </ConditionalRender>

        <ConditionalRender shouldRender={state?.method === "selectAccounts"}>
          <FormControl mb="34px">
            <InputLabel isLoading={isBanksLoading}>Select account</InputLabel>
            <Select
              placeholder="Select an account"
              value={state?.method ?? ""}
              onChange={(e) => handleSelectedAccount(e.target.value)}
            >
              {savedBanks.map((bank, i) => (
                <option key={bank._id} value={bank?._id}>
                  {bank.bankName} {bank?.bankAccountNumber}
                </option>
              ))}
            </Select>
          </FormControl>
        </ConditionalRender>

        <FormControl mb="34px">
          <HStack justifyContent="space-between" my="20px">
            <FormInfo info="Fees: " description={toPrecision(+fee, coin ?? "btc")} />

            <ConditionalRender shouldRender={["bankTransfer"].includes(state?.method ?? "")}>
              <Checkbox
                size="md"
                mt="20px"
                colorScheme={"secondary"}
                isChecked={bankInfo?.saveRecord ?? false}
                onChange={(e) => handleBankInfo({ saveRecord: e.target.checked })}
                sx={{
                  ".chakra-checkbox__label": {
                    fontSize: "14px",
                    textDecoration: "underline",
                    color: "secondary.500",
                    fontWeight: "500",
                  },
                }}
              >
                Save this Account for later
              </Checkbox>
            </ConditionalRender>
          </HStack>
        </FormControl>

        <VStack mt="100px">
          <Button
            minW={{ sm: "100px", "2sm": "400px", "3sm": "400px", md: "400px" }}
            type="submit"
            isLoading={isSelling}
            disabled={isDisabled}
          >
            Continue
          </Button>
          <Button
            minW={{ sm: "100px", "2sm": "400px", "3sm": "400px", md: "400px" }}
            variant="transparent"
            textDecoration="underline"
            disabled={isSelling}
          >
            Cancel
          </Button>
        </VStack>

        <SuccessModal
          isOpen={isOpen("success")}
          onClose={close("success")}
          message="Your trade was successful"
          onContinue={() => navigate("/wallet")}
        />
      </Box>
    </MainLayoutContainer>
  );
}

function PaymentDetails(props: FormProps) {
  const { id } = useParams();
  const { state, set, coin, currency, unitCost, tradeOption, paymentOption, unfrozenBalance, currentVolume, setHideBackButton } =
    props;

  const { onNext } = useSteps();
  const { getBalance } = useWalletBalance();
  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const [makeSell, { isLoading: isSelling }] = useSellToBuyListingMutation();

  //   const ckbScheme = useColorModeValue("primary", "secondary");

  const options = useMemo(() => {
    let _opts = (paymentOption ?? []).map((opt) => {
      const label = toLower(`${opt?.label ?? ""} ${opt?.receivingIntoLabel ?? ""} ${opt?.payingToLabel ?? ""}`);
      if (label.includes("bitmama") && opt?.source === "internal") return { key: "bitmamaWallet", label: "Bitmama Wallet" };
      if (label.includes("changera") && opt?.source === "external") return { key: "changera", label: "Changera ID" };
      if (label.includes("bank") && opt?.source === "external") return { key: "bankTransfer", label: "Bank Transfer" };
      return { key: "bankTransfer", label: "Bank Transfer" };
    });
    // console.log("Options", _opts, _opts.map((o) => o?.key).includes("bankTransfer"));
    return _opts;
  }, [paymentOption])[0].key;

  console.log("Payment Details", { options });

  const fee = useMemo(
    () =>
      (floatString(
        isNaN(deduction(parseFloat(state?.amount ?? ""))) ? "0.00" : deduction(parseFloat(state?.amount ?? ""))
      ) as string) ?? "0.00",
    [state?.amount]
  );

  const maximumFilling = useMemo(() => {
    return Math.min(unfrozenBalance ?? 0, +(tradeOption?.maximumFilling ?? 0));
  }, [tradeOption, unfrozenBalance]);

  const balance = useMemo(() => getBalance((coin ?? "btc") as any), [coin, getBalance]);

  const isMinimumGreaterThanUnfrozenBalance = useMemo(
    () => (tradeOption?.minimumFilling ?? 0) > clamp(unfrozenBalance ?? 0, 0, tradeOption?.maximumFilling ?? 0),
    [tradeOption, unfrozenBalance]
  );

  const isCoinAmountInvalid = useMemo(
    () =>
      (!!state?.amount && parseFloat(state.amount ?? "") < +(tradeOption?.minimumFilling ?? 0)) ||
      (!!state?.amount && parseFloat(state.amount ?? "") > maximumFilling),
    [state, tradeOption, maximumFilling]
  );

  const isVolumeInvalid = useMemo(
    () =>
      (!!state?.volume && +(state?.volume ?? 0) < Number((tradeOption?.minimumFilling ?? 0) * (unitCost ?? 0))) ||
      (!!state?.volume && +(state?.volume ?? 0) > Number(maximumFilling * (unitCost ?? 0))),
    [unitCost, maximumFilling, tradeOption, state?.volume]
  );

  const isAmountGreaterThanAvailableVolume = useMemo(
    () => !!state?.amount && +(state?.amount ?? 0) > +(currentVolume?.$numberDecimal ?? 0),
    [state?.amount, currentVolume]
  );

  console.log("Current Volume", currentVolume, +(state?.volume ?? 0), +(currentVolume?.$numberDecimal ?? 0));

  const isCoinAmountLessThanMinimumAmount = useMemo(
    () => !!state?.amount && parseFloat(state?.amount) - parseFloat(fee) < (tradeOption?.minimumFilling ?? 0),
    // eslint-disable-next-line
    [state?.amount, fee, tradeOption]
  );

  const isDisabled = useMemo(
    () =>
      isCoinAmountInvalid ||
      !(/*state?.method &&*/ (state?.volume && state?.amount)) ||
      isCoinAmountLessThanMinimumAmount ||
      isAmountGreaterThanAvailableVolume ||
      isSelling,
    /*|| (!!state?.method &&
        state.method === "bankTransfer" &&
        !(bankInfo?.accountName && bankInfo?.receivingIntoAddress && bankInfo?.receivingIntoLabel)) */ [
      isCoinAmountInvalid,
      state,
      isCoinAmountLessThanMinimumAmount,
      isAmountGreaterThanAvailableVolume,
      isSelling,
    ]
  );

  // const handleNext = () => {
  //   onNext();
  // };

  const handleVolume = (e: string) => {
    const amount = toPrecision(floatString(Number(parseFloat(e) / (unitCost ?? 0))) as number, coin ?? "btc", false) as string;
    set({ amount, volume: e });
  };

  const handleAmount = (e: string) => {
    const volume = floatString(Number(parseFloat(e) * (unitCost ?? 0))) as string;
    set({ volume, amount: e });
  };

  // const handleMethod = (value: string) => {
  //   const receiveIntoInternalWallet = value === "bitmamaWallet";
  //   set({ method: value, receiveIntoInternalWallet });
  // };

  // const handleSelectedAccount = (bankId: string) => {
  //   const bank = savedBanks?.find((bank) => String(bank._id) === String(bankId));
  //   console.log("Saved Bank", bank);
  //   handleMethod("bankTransfer");
  //   handleBankInfo({
  //     accountName: bank?.bankAccountName,
  //     receivingIntoLabel: bank?.bankName,
  //     receivingIntoAddress: bank?.bankAccountNumber,
  //     saveRecord: !!bank?.rawData?.saveRecord,
  //   });
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (options === "bitmamaWallet") {
      const result = await makeSell({
        id,
        volume: parseFloat(state?.amount ?? ""),
        method: null,
        receiveIntoInternalWallet: true,
      }).unwrap();

      if (!!result && !(result as any).error) {
        if (result.paymentStatus === "paid") {
          open("success")();
          return;
        }
      }
      return;
    }

    onNext();
    setHideBackButton(true);
  };

  return (
    <Card
      as="form"
      w="100%"
      p={{ sm: "20px", "2sm": "44px 48px", "3sm": "44px 48px", md: "44px 48px" }}
      borderRadius={{ sm: "8px", md: "16px" }}
      onSubmit={handleSubmit}
    >
      <Heading as="h6" fontSize="20px" fontWeight="600" mb="38px" display="none">
        Payment Details
      </Heading>

      <FormControl mb="34px">
        <InputLabel isInvalid={isAmountGreaterThanAvailableVolume}>Amount of {toCoinLocale(coin ?? "btc")}*</InputLabel>
        <AmountInput
          isInvalid={isCoinAmountLessThanMinimumAmount || isCoinAmountInvalid || isAmountGreaterThanAvailableVolume}
          isDisabled={isMinimumGreaterThanUnfrozenBalance}
          value={isNaN(+(state?.amount ?? "")) ? "" : state.amount}
          onChange={(e) => handleAmount(e.target.value)}
        />

        {isCoinAmountInvalid && (
          <FormHelperText fontSize="sm" mt="10px" fontWeight="500" color="error">
            Coin amount must not exceed the {tradeOption?.minimumFilling} {toCoinLocale(coin ?? "ngn")} min and {maximumFilling}{" "}
            {toCoinLocale(coin ?? "ngn")} max range
          </FormHelperText>
        )}

        {isAmountGreaterThanAvailableVolume && (
          <FormInfo
            isInvalid={isAmountGreaterThanAvailableVolume}
            info=""
            mt="10px"
            description="Amount is greater than the available order volume"
            _description={{ ml: "0 !important" }}
          />
        )}

        <FormHelperText>
          Max {toCoinLocale(coin ?? "btc")} amount: {toPrecision(tradeOption?.maximumFilling ?? 0, coin ?? "btc")}
        </FormHelperText>
        <FormHelperText>Balance: {toPrecision(balance, coin ?? "btc")}</FormHelperText>
      </FormControl>

      <FormControl mb="34px">
        <InputLabel>Amount of {toCoinLocale(currency ?? "ngn")}*</InputLabel>
        <AmountInput
          isInvalid={isVolumeInvalid}
          isDisabled={isMinimumGreaterThanUnfrozenBalance}
          value={isNaN(+(state?.volume ?? "")) ? "" : Number(state?.volume).toFixed(2)}
          placeholder="0.00"
          onChange={(e) => handleVolume(e.target.value)}
        />
      </FormControl>

      {/* <FormControl mb="34px">
        <InputLabel>Receive with</InputLabel>
        <Select placeholder="Select Payment Option" value={state?.method ?? ""} onChange={(e) => handleMethod(e.target.value)}>
          {options?.map((opt) => (
            <option key={`${opt?.key}-payment-option`} value={opt?.key}>
              {opt?.label}
            </option>
          ))}
        </Select>
      </FormControl> */}

      {/* <ConditionalRender shouldRender={state?.method === "bankTransfer"}>
        <FormControl mb="34px">
          <InputLabel>Account Name</InputLabel>
          <Input
            placeholder="Enter Account Name"
            value={bankInfo?.accountName ?? ""}
            onChange={(e) => handleBankInfo({ accountName: e.target.value })}
          />
        </FormControl>

        <FormControl mb="34px">
          <InputLabel>Bank Name</InputLabel>
          <Input
            placeholder="Enter Bank Name"
            value={bankInfo?.receivingIntoLabel ?? ""}
            onChange={(e) => handleBankInfo({ receivingIntoLabel: e.target.value })}
          />
        </FormControl>

        <FormControl mb="34px">
          <InputLabel>Account Number</InputLabel>
          <Input
            placeholder="Enter Account Number"
            value={bankInfo?.receivingIntoAddress ?? ""}
            onChange={(e) => handleBankInfo({ receivingIntoAddress: e.target.value })}
          />
        </FormControl>
      </ConditionalRender> */}

      {/* <ConditionalRender shouldRender={state?.method === "selectAccounts"}>
        <FormControl mb="34px">
          <InputLabel isLoading={isBanksLoading}>Select account</InputLabel>
          <Select
            placeholder="Select an account"
            value={state?.method ?? ""}
            onChange={(e) => handleSelectedAccount(e.target.value)}
          >
            {savedBanks.map((bank, i) => (
              <option key={bank._id} value={bank?._id}>
                {bank.bankName} {bank?.bankAccountNumber}
              </option>
            ))}
          </Select>
        </FormControl>
      </ConditionalRender> */}

      {/* <FormControl mb="34px">
        <HStack justifyContent="space-between" my="20px">
          <FormInfo info="Fees: " description={toPrecision(+fee, coin ?? "btc")} />

          <ConditionalRender shouldRender={["bankTransfer"].includes(state?.method ?? "")}>
            <Checkbox
              size="md"
              mt="20px"
              colorScheme={"secondary"}
              isChecked={bankInfo?.saveRecord ?? false}
              onChange={(e) => handleBankInfo({ saveRecord: e.target.checked })}
              sx={{
                ".chakra-checkbox__label": {
                  fontSize: "14px",
                  textDecoration: "underline",
                  color: "secondary.500",
                  fontWeight: "500",
                },
              }}
            >
              Save this Account for later
            </Checkbox>
          </ConditionalRender>
        </HStack>
      </FormControl> */}

      <VStack mt="100px">
        <Button
          minW={{ sm: "100px", "2sm": "400px", "3sm": "400px", md: "400px" }}
          type="submit"
          isLoading={isSelling}
          disabled={isDisabled}
        >
          Sell
        </Button>
        <Button
          minW={{ sm: "100px", "2sm": "400px", "3sm": "400px", md: "400px" }}
          variant="transparent"
          textDecoration="underline"
        >
          Cancel
        </Button>
      </VStack>

      <SuccessModal
        isOpen={isOpen("success")}
        onClose={close("success")}
        message="Your trade was successful"
        onContinue={() => navigate("/wallet")}
      />
    </Card>
  );
}

function AdDetails(props: DetailsProps) {
  const {
    _id,
    unitCost,
    userData,
    presence,
    currency,
    coin,
    tradeOption,
    currentVolume,
    paymentOption,
    country,
    terms,
    timeout,
    unfrozenBalance,
  } = props;
  const receivingPaymentMethods = paymentOption?.map((opt) => opt.label);
  const paymentMethodIsWallet = receivingPaymentMethods?.includes("Bitmama Wallet");
  const color = useColorModeValue("black", "white");

  const price = useMemo(
    () =>
      `${currencyFormat((currency as any) ?? "ngn").format(unitCost ?? 0)} ${toCoinLocale(currency ?? "ngn")}/${toCoinLocale(
        coin ?? "btc"
      )}`,
    [unitCost, coin, currency]
  );

  const availableOrderVolume = useMemo(
    () => Math.min(unfrozenBalance ?? 0, +(currentVolume?.$numberDecimal ?? 0)),
    [unfrozenBalance, currentVolume]
  );

  const maximumFilling = useMemo(() => {
    return Math.min(unfrozenBalance ?? 0, +(tradeOption?.maximumFilling ?? 0));
  }, [tradeOption, unfrozenBalance]);

  console.log("Max Filling", maximumFilling, unfrozenBalance, tradeOption?.maximumFilling);

  const limits = useMemo(
    () => `${toPrecision(+(tradeOption?.minimumFilling ?? 0), coin ?? "btc")} - ${toPrecision(maximumFilling, coin ?? "btc")}`,
    [tradeOption, maximumFilling, coin]
  );

  const methods = useMemo(() => {
    return (paymentOption ?? [])?.map((option) => {
      const map: Record<string, string> = {
        external: "Bank Transfer",
        internal: "Bitmama Wallet",
      };

      return map[option?.source];
    });
  }, [paymentOption]);

  const isMinimumGreaterThanUnfrozenBalance = useMemo(
    () => (tradeOption?.minimumFilling ?? 0) > clamp(unfrozenBalance ?? 0, 0, tradeOption?.maximumFilling ?? 0),
    [tradeOption, unfrozenBalance]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(isMinimumGreaterThanUnfrozenBalance);
  };

  const copyableLink = useMemo(() => {
    const host = window.location.host;
    return `${host}/p2p/sell/${_id}`;
  }, [_id]);

  return (
    <Card
      as="form"
      w="100%"
      p={{ sm: "20px", "2sm": "44px 48px", "3sm": "44px 48px", md: "44px 48px" }}
      borderRadius={{ sm: "8px", md: "16px" }}
      onSubmit={handleSubmit}
    >
      <HStack mb="38px">
        <Heading as="h6" fontSize="20px" fontWeight="600">
          Ad Details
        </Heading>

        {isMinimumGreaterThanUnfrozenBalance && (
          <Badge colorScheme="red" p="4px 10px" fontSize="10px" borderRadius="12px">
            Trade is busy
          </Badge>
        )}
      </HStack>

      <FormInfo
        mb="40px"
        fontSize="md"
        info="Buyer:"
        description={
          <Username username={`@${userData?.username}`} presence={(presence?.status as any) ?? "online"} dir="horizontal" />
        }
      />
      <FormInfo mb="40px" fontSize="md" info="Price:" description={price} _description={{ color }} />
      <FormInfo mb="40px" fontSize="md" info="Limits:" description={limits} _description={{ color }} />
      <FormInfo
        mb="40px"
        fontSize="md"
        info="Available Order Volume:"
        description={toPrecision(availableOrderVolume, coin ?? "btc")}
        _description={{ color }}
      />
      <FormInfo
        mb="40px"
        fontSize="md"
        info="Payment methods:"
        description={
          <HStack>
            {methods.map((method, i) => (
              <PaymentMethod key={`payment-method-${i}`}>{method}</PaymentMethod>
            ))}
          </HStack>
        }
        _description={{ color }}
      />
      <FormInfo
        mb="40px"
        fontSize="md"
        info="Location:"
        description={country}
        _description={{ color, textTransform: "capitalize" }}
      />
      <FormInfo
        mb={when(!!terms, "40px", "80px")}
        fontSize="md"
        info="Payment window:"
        description={paymentMethodIsWallet ? "Instant" : `${timeout} minutes`}
        _description={{ color }}
      />
      {!!terms && <FormInfo mb="80px" fontSize="md" info="Terms:" description={terms} _description={{ color }} />}

      <Divider />

      <ShareActions mt="48px" title="Share this Ad" copyText="Copy Ad Link" link={copyableLink} />
    </Card>
  );
}

function InfoHub(props: InfoHubProps) {
  const { coin, userData } = props;
  const { label } = useCoin(coin as any);

  console.log("Coin Info", coin, label);
  return (
    <Box p="46px 48px" w="100%" borderRadius="16px" minH="138px" bg="#041815">
      <VStack>
        <HStack flexDir={{ sm: "column", "2sm": "row", "3sm": "row", md: "row" }}>
          <Heading as="h6" fontSize="24px" color="white">
            Sell {label} to
          </Heading>

          <Username username={`@${userData?.username ?? ""}`} dir="horizontal" hidePresence _link={{ color: "white" }} />
        </HStack>
      </VStack>
    </Box>
  );
}

function useFormHandlers(engine: IStateExtension) {
  const { state, set } = engine;

  const bankInfo = useMemo(() => state?.paymentOption?.find((opt) => opt?.method === "bankTransfer"), [state?.paymentOption]);

  const handleBankInfo = (updates: Partial<IPaymentOption>) => {
    // In case we have multiple payment options, make to keep them
    const otherOptions = state?.paymentOption?.filter((opt) => opt?.method !== "bankTransfer") ?? [];
    const obj = { ...bankInfo, ...updates, method: "bankTransfer" };
    set({ paymentOption: [...otherOptions, obj as IPaymentOption] });
  };

  return { bankInfo, handleBankInfo };
}
