import { Box, BoxProps, HStack } from "@chakra-ui/react";
import { BiticonSVG, BitnameSVG, ChristmasCapsSVG } from "assets";
// import { useRef } from "react";

interface LogoProps extends BoxProps {
  _icon?: BoxProps;
  _name?: BoxProps;
  _cap?: BoxProps;
  its_christmas?: boolean;
}

export default function Logo(props: LogoProps) {
  // const iconRef = useRef<HTMLDivElement>(null);
  // const nameRef = useRef<HTMLDivElement>(null);
  const { _icon, _name, _cap, its_christmas, ...xprops } = props;

  return (
    <Box
      pos="relative"
      // bg="primary.light.default"
      w="fit-content"
      // p="50px 100px"
      {...xprops}
    >
      <HStack pos="relative">
        <Box as={BiticonSVG} color="inherit" {..._icon} />
        <Box ml="10px !important" as={BitnameSVG} color="inherit" {..._name} />
        {!!its_christmas && <Box pos="absolute" as={ChristmasCapsSVG} {..._cap} />}
      </HStack>
    </Box>
  );
}
