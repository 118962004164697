import { Box, BoxProps, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { AbstractModal, Icon } from "components";
import { useSupport } from "hooks";
import { useMemo } from "react";

type ContentType = "feature" | "service" | "product" | "none";

interface FeatureUnavailableModalProps extends Omit<ModalProps, "children"> {
  contentType?: ContentType;
  _content?: BoxProps;
}

interface FeatureUnavailableContentProps extends BoxProps {
  contentType?: ContentType;
  onClose?: () => void;
  showSupport?: boolean;
}

export default function FeatureUnavailableModal(props: FeatureUnavailableModalProps) {
  const { contentType = "none", _content, ...xprops } = props;
  //   const bColor = useColorModeValue("grey.150", "dark.border");

  return (
    <AbstractModal
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        // position: { base: "absolute", "1sm": "relative" },
      }}
      {...xprops}
    >
      <FeatureUnavailableContent contentType={contentType} onClose={props.onClose} {..._content} />
    </AbstractModal>
  );
}

export function FeatureUnavailableContent(props: FeatureUnavailableContentProps) {
  const { contentType = "none", onClose, showSupport, ...xprops } = props;

  const { bootWithProps } = useSupport();

  const type = useMemo(() => {
    const map: Record<string, string> = {
      feature: "This feature",
      service: "This service",
      product: "This product",
      none: "The service, product or feature",
    };

    return map[contentType];
  }, [contentType]);

  const handleClose = (e: any) => {
    e.preventDefault();
    !!onClose && onClose();
  };

  return (
    <Box p={{ base: "32px 40px", "1sm": "44px 80px" }} {...xprops}>
      <VStack mb="60px !important">
        <Icon type="infoError" boxSize="44px" />
        {/* <Heading mt="24px !important" mb="10px !important" as="h4" fontSize="24px">
            Feature not available
          </Heading> */}
        <Heading mt="24px !important" mb="10px !important" as="h4" fontSize="24px" textAlign="center">
          You can't use this at the moment.
        </Heading>
        <Text fontWeight="500" textAlign="center">
          {type} is temporarily disabled or undergoing maintenance. Check back later or contact our customer support for assistance
        </Text>
      </VStack>

      <VStack mt="24px !important" px="46px" w="100%">
        {!!onClose && (
          <Button minW="unset" w="100%" onClick={handleClose}>
            Close
          </Button>
        )}
        {!!showSupport && (
          <Button minW="unset" w="100%" onClick={() => bootWithProps()} leftIcon={<Icon type="support" color="currentcolor" />}>
            Support
          </Button>
        )}
      </VStack>
    </Box>
  );
}
