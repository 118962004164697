import {
  Box,
  TabList,
  TabPanel,
  TabProps,
  Tabs,
  Tab as CTab,
  useColorMode,
  HStack,
  Checkbox,
  Button,
  ListItem,
  Text,
  List,
  useUpdateEffect,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { CircularLoader, EmptyCrate, PageLoading, Paginator, TabPanels, Title, TitleBar } from "components";
import { SidePageViewProps } from "contexts";
import { useDefaultStyle, usePartialState } from "hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { switchStyle, when } from "utils";
import { AnimatePresence, motion } from "framer-motion";
import { useGetNotificationsQuery, useMarkBatchAsSeenMutation } from "apis";
import { NotificationRo } from "interfaces";

import join from "lodash/join";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import toLower from "lodash/toLower";
import take from "lodash/take";
import { format, parseISO } from "date-fns";
import { Emitter } from "libs";

interface NotificationsProps extends SidePageViewProps {}

export default function Notifications(props: NotificationsProps) {
  const location = useLocation();
  const { colorMode } = useColorMode();

  const limit = 7;

  const { shadow, borderColor } = useDefaultStyle();
  const [isInSelectMode, setMode] = useState(false);

  const searchParams = new URLSearchParams(location?.search as string);

  const [count, setCount] = usePartialState({ all: 0, unread: 0, read: 0 });
  const { data: notifications } = useGetNotificationsQuery({ page: 1, limit: 1 });

  // you can only select {limit} items at a time
  const [checkedItems, setCheckedItems] = useState(Array(limit).fill(null));

  const isAllChecked = useMemo(() => checkedItems.every(Boolean), [checkedItems]);
  const isIndeterminate = useMemo(() => checkedItems.some(Boolean) && !isAllChecked, [checkedItems, isAllChecked]);

  // const { data } = useGetNotificationsQuery({ page: 1, limit: 8 });

  // const totalCount = data?.totalCount ?? 0;
  // const totalUnseenCount = data?.totalUnseenCount ?? 0;

  console.log("Notifications checked items", { checkedItems, isAllChecked, isIndeterminate });

  const tabMap = {
    all: 0,
    unread: 1,
    read: 2,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", Object.keys(tabMap)[index]);
    navigate(`?${searchParams.toString()}`);
    setMode(false);
    setCheckedItems(Array(limit).fill(null));
  };

  return (
    <Box id="virtual-card-details-wrapper" px={{ base: "20px", "2sm": "40px" }} {...props} overflowY="scroll">
      <TitleBar>
        <Title fontSize="20px">Notifications</Title>
      </TitleBar>

      <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange} mt="32px !important">
        <HStack w="100%" justifyContent="space-between">
          <TabList borderRadius="8px" gridGap="6px">
            <Tab>All ({notifications?.totalCount ?? 0})</Tab>
            <Tab>Unread ({notifications?.totalUnseenCount ?? 0})</Tab>
            <Tab>Read ({count?.read ?? 0})</Tab>
          </TabList>

          <HStack>
            <Checkbox
              // size="sm"
              isChecked={isAllChecked}
              isIndeterminate={isIndeterminate}
              onChange={(e) => Emitter.emit("NOTIFICATION_CHECK_ALL", { checked: e.target.checked })}
              colorScheme="primary"
              sx={{
                ".chakra-checkbox__control": {
                  display: when(isInSelectMode, "inline-block", "none"),
                },
              }}
            >
              <Button
                variant="link"
                textDecoration="underline"
                fontWeight="700"
                color={switchStyle(colorMode, { dark: "secondary.500", light: "primary.700" })}
                onClick={() => setMode(!isInSelectMode)}
                _focus={{ shadow, borderColor }}
              >
                <motion.div
                  key={`in-selection-mode-${when(isInSelectMode, "select", "unselect")}`}
                  initial={{ opacity: 0, scale: 0.98 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.98 }}
                >
                  {when(isInSelectMode, "Unselect", "Select")}
                </motion.div>
              </Button>
            </Checkbox>
          </HStack>
        </HStack>
        <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
          <TabPanel px={["2", "0", "0", "1"]}>
            <NotificationList isInSelectMode={false} setCount={setCount} />
          </TabPanel>
          <TabPanel px={["0", "0", "0", "1"]}>
            <NotificationList
              seen={false}
              isInSelectMode={isInSelectMode}
              setCount={setCount}
              checkedItems={checkedItems}
              onItemChecked={setCheckedItems}
            />
          </TabPanel>
          <TabPanel px={["0", "0", "0", "1"]}>
            <NotificationList seen={true} isInSelectMode={false} setCount={setCount} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

function Tab(props: TabProps) {
  const { children } = props;

  const { colorMode } = useColorMode();
  return (
    <CTab
      borderRadius="60px"
      p={{ base: "5px 13px", "2sm": "10px 14px" }}
      bg={switchStyle(colorMode, { dark: "whiteAlpha.100", light: "#F2F4F3" })}
      _selected={{
        bg: switchStyle(colorMode, { dark: "secondary.500", light: "primary.700" }),
        color: switchStyle(colorMode, { dark: "white", light: "white" }),
      }}
      fontSize="14px"
      fontWeight="700"
    >
      {children}
    </CTab>
  );
}

interface NotificationItemProps extends NotificationRo {
  isInSelectMode: boolean;
  isChecked?: boolean;
  onCheck?: (value: boolean) => void;
}

interface NotificationListProps {
  isInSelectMode: boolean;
  seen?: boolean | null;
  setCount: (update: { all?: number; unread?: number; read?: number }) => void;
  checkedItems?: (string | null)[];
  onItemChecked?: (update: (string | null)[]) => void;
}

function NotificationList(props: NotificationListProps) {
  const { isInSelectMode, seen = null, setCount, onItemChecked, checkedItems } = props;

  const spinnerBg = useColorModeValue("white", "whiteAlpha.200");

  const isCountSet = useRef(false);
  const prevData = useRef<any>();
  const toast = useToast();
  const [state, set] = usePartialState({ page: 1, limit: 7 });
  const { data, isLoading, isFetching } = useGetNotificationsQuery({ ...state, seen });

  const [markBatchAsSeen, { isLoading: isMarking, isSuccess }] = useMarkBatchAsSeenMutation();

  const notifications: NotificationRo[] = useMemo(() => data?.notifications ?? [], [data]);
  const totalCount = data?.totalQueriedCount ?? 0;

  const isChecked = (id: string) => (checkedItems ?? []).includes(id);

  const handleCheck = (id: string, index: number) => {
    if (!onItemChecked || !checkedItems) return;

    if ((checkedItems ?? []).includes(id)) {
      const newCheckList = [...checkedItems.filter((itm) => itm !== id), null];
      onItemChecked(newCheckList);
      return;
    }

    const oldList = checkedItems.filter((itm) => itm !== null);
    const updateReminder = (state?.limit ?? 7) - 1 - oldList.length;
    const newCheckList: (string | null)[] = [...Array(updateReminder).fill(null), ...oldList, id];
    onItemChecked(newCheckList);
  };

  const handleMarkAsSeen = async () => {
    if (!onItemChecked || !checkedItems) return;
    const ids = checkedItems.filter((id) => id !== null) as string[];
    // console.log("IDS", ids);
    await markBatchAsSeen({ ids });
    onItemChecked(Array(state?.limit ?? 7).fill(null));
  };

  useEffect(() => {
    const handler = (payload: { checked: boolean }) => {
      if (!onItemChecked || !checkedItems) return;
      const { checked } = payload;
      console.log("All checked", payload);

      const limit = state?.limit ?? 7;
      const first_limit = take(notifications, limit).map((nt) => nt?._id);

      // payload is value is flipped
      // checked will be true when all is checked.
      // checked will be false when all is not checked.
      if (checked) onItemChecked(first_limit);
      if (!checked) onItemChecked(Array(limit).fill(null));
    };
    Emitter.on("NOTIFICATION_CHECK_ALL", handler);
    return () => {
      Emitter.off("NOTIFICATION_CHECK_ALL", handler);
    };
  }, [state?.limit, checkedItems, onItemChecked, notifications]);

  useUpdateEffect(() => {
    if (isSuccess) {
      toast({
        position: "bottom-right",
        title: "Success",
        description: "Successfully marked notifications as seen",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    // if ((!!data && seen === null && !isCountSet.current) || (!!data && !isEqual(prevData.current, data) && seen === null)) {
    //   setCount({ all: data?.totalCount });
    //   isCountSet.current = true;
    //   prevData.current = data;
    // }
    // if ((!!data && seen === false && !isCountSet.current) || (!!data && !isEqual(prevData.current, data) && seen === false)) {
    //   setCount({ unread: data?.totalQueriedCount });
    //   isCountSet.current = true;
    //   prevData.current = data;
    // }
    if ((!!data && seen === true && !isCountSet.current) || (!!data && !isEqual(prevData.current, data) && seen === true)) {
      setCount({ read: data?.totalQueriedCount });
      isCountSet.current = true;
      prevData.current = data;
    }
  }, [data, seen, isCountSet, prevData, setCount]);

  const isMarkDisabled = useMemo(
    () => !checkedItems || isEmpty(checkedItems.filter(Boolean)) || isMarking,
    [checkedItems, isMarking]
  );

  return (
    <>
      {isFetching && !isLoading && (
        <Box
          pos="absolute"
          top="40px"
          right="0px"
          boxSize="28px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          p="10px"
          bg={spinnerBg}
          borderRadius="full"
        >
          <CircularLoader />
        </Box>
      )}
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={`notification-list-${when(isLoading, "loading", "loaded")}`}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
        >
          {isLoading && isEmpty(notifications) && <PageLoading py="60px" isLoading={isLoading} />}
          {!isEmpty(notifications ?? []) && (
            <List py="32px">
              {(notifications ?? []).map((nt, i) => (
                <NotificationItem
                  isInSelectMode={isInSelectMode}
                  key={`notification-${nt._id}`}
                  {...nt}
                  isChecked={isChecked(nt?._id)}
                  onCheck={() => handleCheck(nt?._id, i)}
                />
              ))}
            </List>
          )}

          {!isLoading && isEmpty(notifications) && <EmptyCrate type="notification" />}

          {!isLoading && !isEmpty(notifications) && !isInSelectMode && (
            <Paginator
              totalCount={totalCount}
              page={state?.page}
              limit={state?.limit}
              onPageChange={(page: number) => set({ page })}
            />
          )}

          {!isEmpty(notifications) && !isLoading && isInSelectMode && (
            <Button
              minW="fit-content"
              minH="fit-content"
              fontSize="12px"
              maxW="fit-content"
              isLoading={isMarking}
              onClick={handleMarkAsSeen}
              disabled={isMarkDisabled}
            >
              Mark as seen
            </Button>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
}

function NotificationItem(props: NotificationItemProps) {
  const { isInSelectMode, title, content, createdAt, onCheck, isChecked } = props;
  const { colorMode } = useColorMode();

  const { typeColor } = useTypeColor(title, content);

  return (
    <ListItem mb="16px">
      <Checkbox
        isChecked={isChecked}
        onChange={(e) => onCheck && onCheck(e.target.checked)}
        colorScheme="primary"
        pointerEvents={when(isInSelectMode, "auto", "none")}
        sx={{
          ".chakra-checkbox__control": {
            display: when(isInSelectMode, "inline-block", "none"),
            mr: when(isInSelectMode, "22px", "0px"),
          },
        }}
      >
        <Box>
          <Text fontSize="14px" fontWeight="500" color={typeColor} textTransform="capitalize">
            {title}
          </Text>
          <Text fontSize="16px" fontWeight="500">
            {content}
          </Text>
          <Text fontSize="12px" fontWeight="500" color={switchStyle(colorMode, { dark: "grey.100", light: "grey.500" })}>
            {format(parseISO(createdAt), "dd eee, MMM yyyy, hh:mm a")}
          </Text>
        </Box>
      </Checkbox>
    </ListItem>
  );
}

function useTypeColor(title: string, content: string) {
  const { colorMode } = useColorMode();

  const searchdb = join([title, content], " ");

  const includes = (value: string, array: string[]) => {
    for (let item of array) {
      if (toLower(value).includes(item)) return true;
    }
    return false;
  };

  const type = useMemo(() => {
    if (includes(searchdb, ["initiated", "being processed", "request", "due for payback in"])) return "pending";
    if (
      includes(searchdb, [
        "credit",
        "listed",
        "completed",
        "sell",
        "new virtual card",
        "new",
        "approved",
        "rolled over",
        "paid",
        "above",
        "redeemed",
        "success",
        "reversed",
        "loan payback",
        "fund card",
        "data purchase",
        "vtu data",
        "vtu airtime",
        "swap",
        "airtime purchase",
        "funded",
        "purchase",
        "top up",
      ])
    )
      return "credit";
    if (
      includes(searchdb, [
        "debit",
        "buy",
        "added",
        "due",
        "terminated",
        "cancelled",
        "denied",
        "below",
        "timeout",
        "down",
        "delete",
      ])
    )
      return "debit";

    return "default";
  }, [searchdb]);

  const typeColor = useMemo(() => {
    const map: Record<string, string> = {
      debit: switchStyle(colorMode, { dark: "error", light: "error" }),
      credit: switchStyle(colorMode, { dark: "secondary.500", light: "secondary.500" }),
      pending: switchStyle(colorMode, { dark: "#E8C229", light: "#E8C229" }),
      default: switchStyle(colorMode, { dark: "white", light: "gray.700" }),
    };

    return map[type ?? "default"];
  }, [type, colorMode]);

  return { type, typeColor };
}

Notifications.displayName = "SidePageView";
