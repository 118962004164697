import { Box, HStack, Text, useRadioGroup } from "@chakra-ui/react";
import Group from "components/Group/Group";
import Icon from "components/Icon/Icon";
import { Radio as XRadio, RadioProps as XRadioProps } from "components/Common/Radio";
import { useColor } from "hooks";
import { when } from "utils";

interface RadioOptionGroupProps {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export function MovementRadioGroup(props: RadioOptionGroupProps) {
  const { value, defaultValue, onChange } = props;

  const options = [
    { title: "Upward", subtitle: "This will alert you when the price goes up", value: "up" },
    { title: "Downward", subtitle: "This will alert you when the price goes down", value: "down" },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "alert-movement",
    defaultValue: defaultValue ?? value,
    onChange: (value: string) => {
      onChange && onChange(value);
    },
  });

  const group = getRootProps();

  return (
    <Group m="0" {...group} gridGap="10px" fontSize="8px">
      {options.map(({ title, subtitle, value }) => {
        const radio = getRadioProps({ value });
        return <Radio type={value as any} key={value} title={title} subtitle={subtitle} _after={{ opacity: 0 }} {...radio} />;
      })}
    </Group>
  );
}

interface RadioProps extends XRadioProps {
  title: string;
  subtitle: string;
  type: "up" | "down";
}

function Radio(props: RadioProps) {
  const { title, subtitle, type, ...xprops } = props;

  const color = useColor();

  return (
    <XRadio asText={false} _radioWrapper={{ flexDir: "row-reverse", justifyContent: "space-between" }} cursor="pointer" {...xprops}>
      <HStack ml="0 !important">
        <Box bg="#E2F2DA" boxSize="44px" borderRadius="4px" display="flex" justifyContent="center" alignItems="center">
          <Icon
            type="arrowRight"
            transform={when(type === "up", "rotate(-90deg)", "rotate(90deg)")}
            color={when(type === "up", "primary.600", "error")}
          />
        </Box>

        <Box>
          <Text fontSize="14px" fontWeight="500" color={color("black", "white")}>
            {title}
          </Text>
          <Text fontSize="12px" fontWeight="500" color={color("grey.500", "grey.300")}>
            {subtitle}
          </Text>
        </Box>
      </HStack>
    </XRadio>
  );
}
