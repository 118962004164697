export const properCase = (str: any) => {
	if (str && typeof str === 'string' && str.trim().length === 1) {
		return String(str).trim().toUpperCase()
	}
	if (str && typeof str === 'string') {
		return str
			.trim()
			.split(' ')
			.map((w) => w[0] && w[0].toUpperCase() + w.substr(1).toLowerCase())
			.join(' ')
	}
	if (str && typeof str === 'object' && str.length > 0) {
		return str.map((st: any) =>
			String(st)
				.trim()
				.split(' ')
				.map((w) => w[0] && w[0].toUpperCase() + w.substr(1).toLowerCase())
				.join(' ')
		)
	}
	return str
}

export const pickString = (
	str: string,
	limit: number = 10000,
	ellipsis: string = '...'
) => {
	const name = String(str)
	if (name && name.length > limit) {
		return `${String(name.slice(0, limit))}${ellipsis}`
	}
	return name
}