import { useEffect, useState } from "react";
import { post } from "utils";
import { wait } from "utils/promises";

const appLink = {
  ios: "https://apps.apple.com/us/app/bitmama/id1561857024?referrer=web",
  android: "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.bitmama.bitmama&referrer=web",
};

const webLink = "https://dashboard.bitmama.io";

const parentStyle = {
  height: "100vh",
  display: "grid",
  placeContent: "center",
  marginTop: "-5rem",
};

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }

  return "unknown";
}

export default function AppDownload(props: { path: string }) {
  const [appState, setAppState] = useState({
    state: "loading",
    serverLink: "",
    currentActivity: "",
    os: "",
    error: "" as any,
    data: "",
  });

  useEffect(() => {
    const getDeviceInfo = async () => {
      try {
        setAppState((appState) => ({
          ...appState,
          state: "loading",
          currentActivity: "Getting device information",
          data: "",
          error: "",
        }));
        const os = getMobileOperatingSystem();
        const osMap = {
          "Windows Phone": "windows",
          iOS: "ios",
          Android: "android",
          unknown: "",
        };
        const url = new URL(window.location.href);
        const fallbackQuery = url.searchParams.get("fallback");
        const fallback = Number(fallbackQuery) === 1;
        if (fallback) {
          setAppState((appState) => ({
            ...appState,
            state: "loading",
            currentActivity: `Opening web app ...`,
            data: "",
            error: "",
          }));
        } else {
          try {
            await post(`${process.env.REACT_APP_API_ENDPOINT}/redirectToApp?os=${osMap[os] || "unknown"}`, {});
          } catch (err) {
            // if issues with serverside redirect
            if (osMap[os]) {
              setAppState((appState) => ({
                ...appState,
                state: "loading",
                currentActivity: `${osMap[os]} detected`,
                data: "",
                error: "",
              }));
              await wait(true, 1500);
              setAppState((appState) => ({
                ...appState,
                state: "loading",
                currentActivity: `Opening ${osMap[os]} app ...`,
                data: "",
                error: "",
              }));
              await wait(true, 1500);
            } else {
              setAppState((appState) => ({
                ...appState,
                state: "loading",
                currentActivity: `Opening web app ...`,
                data: "",
                error: "",
              }));
              await wait(true, 1500);
            }
          }
        }
        setAppState((appState) => ({ ...appState, state: "", os: osMap[os], error: "" }));
      } catch (err) {
        setAppState((appState) => ({ ...appState, state: "error", error: err }));
      }
    };
    getDeviceInfo();
  }, []);

  if (appState.state === "loading")
    return (
      <>
        <div style={parentStyle}>
          <div style={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
            <Loader />
          </div>
          {appState?.currentActivity ? <div style={{ color: "#333", fontSize: "12px" }}>{appState.currentActivity}</div> : null}
        </div>
      </>
    );

  if (appState.serverLink) {
    window.location.replace(appState.serverLink);
  } else if (appState.os) {
    window.location.replace(appLink[appState.os as "android"] || webLink);
  } else {
    window.location.replace(webLink);
  }

  return null;
}

const Loader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        background: "0 0",
        display: "block",
        shapeRendering: "auto",
      }}
      width={40}
      height={40}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke="#03a438"
        strokeWidth={10}
        r={35}
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  );
};
