import { Avatar, Box, BoxProps, Button, Grid, Heading, HStack, Text, useColorModeValue, VStack, Flex } from "@chakra-ui/react";
import { useWithdrawCryptoMutation } from "apis";
import { AbstractModal, BackButton, Icon, Steps, useAbstractModal, useModalView } from "components";
import { AppAnalyticEvent, WithdrawCryptoDto } from "interfaces";
import { ReactNode, useEffect, useMemo } from "react";

import capitalize from "lodash/capitalize";
import PinView from "ui/Common/PinView";
import { toPrecision, when } from "utils";
import { pickString } from "utils/textFormatter";
import { IState } from "../Features/Crypto/Withdraw";
import { Emitter, transformCryptoWithdrawalToEngagement } from "libs";
import { toLower } from "lodash";

interface CryptoWithdrawPreviewProps {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  isOpen: boolean;
  onClose: () => void;
  reset: () => void;
  coin: string;
  fee: number;
  isInternal: boolean;
  internalWalletUsername?: string;
  onSuccessClose: () => void;
}

interface ItemProps extends BoxProps {
  info: string;
  description: ReactNode;
}

export default function CryptoWithdrawPreview(props: CryptoWithdrawPreviewProps) {
  const { isOpen, set, onClose } = props;

  const handleClose = () => {
    set({ pin: undefined });
    onClose();
  };

  return (
    <AbstractModal isOpen={isOpen} onClose={onClose}>
      <Steps hideIndicator>
        <Preview {...{ ...props, onClose: handleClose }} />
        <Pin {...{ ...props, onClose: handleClose }} />
        <MakeWithdrawalConfirmation {...{ ...props, onClose: handleClose }} />
      </Steps>
    </AbstractModal>
  );
}

function Pin(props: CryptoWithdrawPreviewProps) {
  const { coin, state, set, onClose, reset, onSuccessClose } = props;

  const { onPrev, onNext } = useModalView();
  const { updateModal } = useAbstractModal();

  const [withdraw, { isLoading: isWithdrawing, reset: resetApiState }] = useWithdrawCryptoMutation();

  const isDisabled = useMemo(
    () => !state?.pin || (!!state?.pin && state.pin.length < 6) || isWithdrawing,
    [state?.pin, isWithdrawing]
  );

  const close = () => {
    reset();
    onClose();
    updateModal({ isSuccess: false });
    onSuccessClose();
  };

  const makeWithdrawal = async (pin?: string) => {
    const reqData: WithdrawCryptoDto = {
      coin,
      reason: "Withdrawal request",
      amount: +state?.amount!,
      destinationAddress: String(state.address).trim(),
      destinationCoin: coin,
      tagNumber: state?.tag,
      memo: state?.memo,
      ...state,
      pin: pin ?? state?.pin!,
    };

    Emitter.emit(AppAnalyticEvent.WITHDRAWAL_INITIATED, transformCryptoWithdrawalToEngagement(reqData));

    const result = await withdraw(reqData as WithdrawCryptoDto)
      .unwrap()
      .catch((err: any) => {
        console.log("Withdrawal Error", err);
        if (["accept_fee_notice"].includes(toLower(err?.data?.data?.label))) {
          set({
            withdrawal_confirmation_fee: err?.data?.data?.fee,
            withdrawal_confirmation_message: err?.data?.error ?? "Are you sure you want to proceed with this withdrawal?",
          });
          onNext();
        }
      });
    console.log("Withdrawal Result", result);

    if (!!result) {
      Emitter.emit(AppAnalyticEvent.WITHDRAWAL_SUBMITTED, transformCryptoWithdrawalToEngagement(reqData));
      updateModal &&
        updateModal({
          isSuccess: true,
          success: {
            description: `Withdraw successful`,
            actions: [
              {
                label: "Close",
                onClick: () => {
                  close();
                },
              },
            ],
          },
        });

      reset();
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await makeWithdrawal();
  };

  useEffect(() => {
    return () => {
      resetApiState();
    };
  }, [resetApiState]);

  return (
    <VStack as="form" p={{ base: "32px 16px", md: "45px 100px" }} onSubmit={handleSubmit}>
      <BackButton onClick={onPrev} disabled={isWithdrawing} />
      <PinView onPin={(pin) => set({ pin })} on_complete={async (pin) => await makeWithdrawal(pin)} />

      <VStack mt="44px !important" px="46px" w="100%">
        <Button minW={{ base: "100%", md: "400px" }} type="submit" disabled={isDisabled} isLoading={isWithdrawing}>
          Submit
        </Button>

        <Button
          minW={{ base: "100%", md: "400px" }}
          variant="transparent"
          textDecoration="underline"
          w="100%"
          disabled={isWithdrawing}
          onClick={onClose}
        >
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function Preview(props: CryptoWithdrawPreviewProps) {
  const { coin, fee, state, onClose, isInternal, internalWalletUsername } = props;

  const { onNext } = useModalView();

  const bcolor = useColorModeValue("#B7DFA280", "grey.150");

  const handleNext = (e: any) => {
    e.preventDefault();
    onNext();
  };

  const isUsername = useMemo(() => ["username"].includes(String(state.destinationFormat)), [state.destinationFormat]);
  const isEmail = useMemo(() => ["email"].includes(String(state.destinationFormat)), [state.destinationFormat]);
  const isCryptoDomain = useMemo(() => ["cryptoDomain"].includes(String(state.destinationFormat)), [state.destinationFormat]);

  return (
    <VStack as="form" p={{ base: "32px 16px", md: "45px 100px" }} onSubmit={handleNext}>
      <VStack maxW="460px" m="0 auto" mb="40px">
        <Heading as="h6" fontSize="18px">
          Transaction Summary
        </Heading>

        <PreviewFormat
          coin={coin}
          fee={fee}
          isInternal={isInternal}
          bcolor={bcolor}
          internalWalletUsername={internalWalletUsername!}
          isCryptoDomain={isCryptoDomain}
          isEmail={isEmail}
          isUsername={isUsername}
          state={state}
        />
      </VStack>

      <VStack mt="14px !important" px="46px" w="100%">
        <Button type="submit" minW="unset" w="100%" fontFamily="var(--bitmama-fonts-heading)">
          Continue
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function MakeWithdrawalConfirmation(props: CryptoWithdrawPreviewProps) {
  const { coin, state, fee, onClose, reset, onSuccessClose } = props;

  const { onPrev } = useModalView();
  const { updateModal } = useAbstractModal();

  const [withdraw, { isLoading: isWithdrawing, reset: resetApiState }] = useWithdrawCryptoMutation();

  const isDisabled = useMemo(
    () => !state?.pin || (!!state?.pin && state.pin.length < 6) || isWithdrawing,
    [state?.pin, isWithdrawing]
  );

  const close = () => {
    reset();
    onClose();
    updateModal({ isSuccess: false });
    onSuccessClose();
  };

  const confirmWithdrawal = async () => {
    if (!state?.pin) return;

    const reqData: WithdrawCryptoDto = {
      coin,
      reason: "Withdrawal request",
      amount: +state?.amount!,
      destinationAddress: String(state.address).trim(),
      destinationCoin: coin,
      tagNumber: state?.tag,
      memo: state?.memo,
      ...state,
      pin: state?.pin!,
      confirmedFeeOk: true,
    };

    const result = await withdraw(reqData as WithdrawCryptoDto).unwrap();

    if (!!result) {
      console.log("Withdrawal Result", result);
      Emitter.emit(AppAnalyticEvent.WITHDRAWAL_SUBMITTED, transformCryptoWithdrawalToEngagement(reqData));
      updateModal &&
        updateModal({
          isSuccess: true,
          success: {
            description: `Withdraw successful`,
            actions: [
              {
                label: "Close",
                onClick: () => {
                  close();
                },
              },
            ],
          },
        });

      reset();
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await confirmWithdrawal();
  };

  useEffect(() => {
    return () => {
      resetApiState();
    };
  }, [resetApiState]);

  return (
    <VStack as="form" p={{ base: "32px 16px", md: "45px 100px" }} onSubmit={handleSubmit}>
      <BackButton onClick={onPrev} disabled={isWithdrawing} />

      <VStack>
        <Icon type="infoError" boxSize="44px" />
        <Heading as="h5" fontSize="20px">
          Confirm Withdrawal
        </Heading>

        <Text mt="20px !important" fontSize="16px" fontWeight="500" textAlign="center">
          {/* {state?.withdrawal_confirmation_message ?? "Are you sure you want to proceed with the fee?"} */}
          {`Withdrawal fee for this request is about ${toPrecision(
            state?.withdrawal_confirmation_fee ?? fee,
            coin
          )}. Are you sure you want to continue?`}
        </Text>
      </VStack>

      <VStack mt="44px !important" px="46px" w="100%">
        <Button minW={{ base: "100%", md: "400px" }} type="submit" disabled={isDisabled} isLoading={isWithdrawing}>
          Confirm
        </Button>

        <Button
          minW={{ base: "100%", md: "400px" }}
          variant="transparent"
          textDecoration="underline"
          w="100%"
          disabled={isWithdrawing}
          onClick={onClose}
        >
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function Item(props: ItemProps) {
  const { info, description, ...xprops } = props;

  const icolor = useColorModeValue("grey.400", "grey.100");
  const dcolor = useColorModeValue("grey.800", "white");

  return (
    <VStack border="1px solid transparent" p="24px 40px" {...xprops}>
      <Text color={icolor} fontSize="14px" fontWeight="500">
        {info}
      </Text>
      <Text color={dcolor} fontSize="18px" fontWeight="500">
        {description}
      </Text>
    </VStack>
  );
}

interface IProps {
  isUsername: boolean;
  isEmail: boolean;
  isInternal: boolean;
  isCryptoDomain: boolean;
  internalWalletUsername: string;
  state: Partial<IState>;
  bcolor: any;
  coin: string;
  fee: number;
}
const PreviewFormat = ({
  isUsername,
  isEmail,
  isInternal,
  state,
  bcolor,
  isCryptoDomain,
  coin,
  internalWalletUsername,
  fee,
}: IProps) => {
  const switchView = useMemo(() => state.network || coin === "xlm" || coin === "xrp", [state, coin]);

  return (
    <Box mt="40px !important">
      <Flex flexDir={switchView ? "column" : "column-reverse"}>
        <Box>
          <Grid templateColumns={switchView ? "repeat(2, 1fr)" : "none"}>
            <Item
              info={when(isUsername || isEmail, `Recipient`, `Address`)}
              description={when<ReactNode>(
                isInternal || isCryptoDomain,
                <HStack>
                  {!isEmail &&
                    when(
                      isCryptoDomain,
                      <></>,
                      <Avatar size="sm" name={when(isUsername, state.address, internalWalletUsername)} />
                    )}
                  <Text fontSize="14px" fontWeight="500">
                    {when(isUsername || isEmail, `${pickString(String(state.address), 10, "...")}`, `@${internalWalletUsername}`)}
                  </Text>
                </HStack>,
                pickString(String(state.address), 10, "...")
              )}
              borderRightColor={switchView ? bcolor : "none"}
              borderBottomColor={switchView ? bcolor : "none"}
            />

            {!!state?.network && <Item info={`Network`} description={capitalize(state.network)} borderBottomColor={bcolor} />}
            {coin === "xlm" && <Item info={`Memo`} description={state.memo} borderBottomColor={bcolor} />}
            {coin === "xrp" && <Item info={`Tag`} description={state.tag} borderBottomColor={bcolor} />}
          </Grid>
        </Box>

        <Box>
          <Grid templateColumns="repeat(2, 1fr)">
            <Item
              info={`Amount`}
              description={toPrecision(+(state?.amount ?? 0), coin) as string}
              borderRightColor={bcolor}
              borderBottomColor={switchView ? "none" : bcolor}
            />

            <Item
              info={`Fee Estimate`}
              description={toPrecision(when(isInternal, 0, fee), coin, true, 5, true) as string}
              borderBottomColor={switchView ? "none" : bcolor}
            />
          </Grid>
        </Box>
      </Flex>
    </Box>
  );
};
