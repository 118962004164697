import { ButtonProps, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { useMemo } from "react";
import { switchStyle } from "utils";

export type SchemeType = "default" | "loan" | "partner" | "earn" | "greenBox" | "totalBalance";
type SchemeValues = {
  bg: string;
  colorScheme: string;
  outlineBtnColor: string;
  loaderColor: string;
  patternColor?: string;
  buttonColor?: string;
  iconColor?: string;
  textColor?: string;
  outline?: ButtonProps;
  solid?: ButtonProps;
  label?: { color?: string; iconColor?: string };
  button?: Record<string, ButtonProps>;
};

export default function useWalletSchemes(scheme: SchemeType) {
  const { colorMode } = useColorMode();
  const bg = useColorModeValue("secondary.55", "secondary.200");
  const colorScheme = useColorModeValue("primary", "secondary");

  const loaderColor = useColorModeValue("primary.600", "primary.800");
  const outlineBtnColor = useColorModeValue("primary.900", "secondary.800");
  const patternColor = useColorModeValue("primary.default", "primary.default");
  const buttonColor = useColorModeValue("primary.default", "secondary.700");

  const schemes = useMemo(() => {
    const map: Record<SchemeType, SchemeValues> = {
      default: {
        bg,
        colorScheme,
        outlineBtnColor,
        loaderColor,
        patternColor,
        buttonColor,
        label: { color: switchStyle(colorMode, { light: "primary.900", dark: "primary.default" }) },

        button: {
          solid: {
            bg: "black",
            color: "white",
            _hover: {
              color: "white",
              bg: switchStyle(colorMode, { light: "primary.600", dark: "secondary.500" }),
              borderColor: "transparent",
            },
            _active: {
              borderColor: switchStyle(colorMode, { light: "primary.700", dark: "secondary.600" }),
            },
          },
          outline: {
            bg: "transparent",
            color: switchStyle(colorMode, { light: "black", dark: "black" }),
            borderColor: switchStyle(colorMode, { light: "black", dark: "black" }),
            _hover: {
              color: "white",
              bg: switchStyle(colorMode, { light: "primary.600", dark: "secondary.500" }),
              borderColor: "transparent",
            },
            _active: {
              borderColor: switchStyle(colorMode, { light: "primary.700", dark: "secondary.600" }),
            },
          },
        },
      },
      greenBox: {
        bg,
        colorScheme,
        outlineBtnColor,
        loaderColor,
        patternColor,
        buttonColor,

        label: { color: switchStyle(colorMode, { light: "white", dark: "primary.default" }) },

        button: {
          solid: {
            bg: "white",
            borderWidth: "2px",
            borderColor: "transparent",
            color: switchStyle(colorMode, { light: "#253F17", dark: "secondary.500" }),
            _hover: {
              color: switchStyle(colorMode, { light: "#253F17", dark: "white" }),
              bg: switchStyle(colorMode, { light: "white", dark: "secondary.500" }),
              borderColor: "transparent",
              shadow: "0px 2.4597px 24.597px rgba(0, 0, 0, 0.15)",
              transition: "box-shadow ease-out .3s",
            },
            _active: {
              borderColor: switchStyle(colorMode, { light: "#9FD483", dark: "#9FD483" }),
            },
          },
          outline: {
            bg: "transparent",
            borderWidth: "2px",
            color: switchStyle(colorMode, { light: "white", dark: "white" }),
            borderColor: switchStyle(colorMode, { light: "white", dark: "white" }),

            _hover: {
              color: switchStyle(colorMode, { light: "#253F17", dark: "white" }),
              bg: switchStyle(colorMode, { light: "white", dark: "secondary.500" }),
              borderColor: "transparent",
              shadow: "0px 2.4597px 24.597px rgba(0, 0, 0, 0.15)",
              transition: "box-shadow ease-out .3s",
            },
            _active: {
              borderColor: switchStyle(colorMode, { light: "#9FD483", dark: "#9FD483" }),
            },
          },
        },
      },
      loan: {
        bg: switchStyle(colorMode, { light: "#072420", dark: "#072420" }),
        colorScheme: switchStyle(colorMode, { light: "white", dark: "white" }),
        loaderColor: switchStyle(colorMode, { light: "primary.600", dark: "secondary.300" }),
        patternColor: switchStyle(colorMode, { light: "grey.150", dark: "grey.150" }),
        buttonColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        iconColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        textColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        outlineBtnColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        label: { color: "white", iconColor: "white" },
        outline: {
          color: "white",
          borderColor: "white",
          _hover: {
            bg: "primary.700",
            borderColor: "transparent",
          },
        },
        solid: {
          bg: "white",
          color: "primary.700",
          borderColor: "white",
          _hover: {
            bg: "primary.700",
            color: switchStyle(colorMode, { light: "white", dark: "white" }),
            borderColor: "transparent",
          },
        },
      },
      totalBalance: {
        bg: switchStyle(colorMode, { light: "#072420", dark: "#072420" }),
        colorScheme: switchStyle(colorMode, { light: "white", dark: "white" }),
        loaderColor: switchStyle(colorMode, { light: "primary.600", dark: "secondary.300" }),
        patternColor: switchStyle(colorMode, { light: "#5A9B78", dark: "#5A9B78" }),
        buttonColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        iconColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        textColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        outlineBtnColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        label: { color: "white", iconColor: "white" },
        outline: {
          color: "white",
          borderColor: "white",
          _hover: {
            bg: "primary.700",
            borderColor: "transparent",
          },
        },
        solid: {
          bg: "secondary.500",
          color: "white",
          borderColor: "white",
          _hover: {
            bg: "primary.700",
            color: switchStyle(colorMode, { light: "white", dark: "white" }),
            borderColor: "transparent",
          },
        },
      },
      earn: {
        bg: switchStyle(colorMode, { light: "primary.900", dark: "primary.900" }),
        colorScheme: switchStyle(colorMode, { light: "secondary", dark: "secondary" }),
        loaderColor: switchStyle(colorMode, { light: "primary.600", dark: "primary.800" }),
        textColor: switchStyle(colorMode, { light: "white", dark: "white" }),
        outlineBtnColor: switchStyle(colorMode, { light: "primary.900", dark: "secondary.800" }),
        patternColor: switchStyle(colorMode, { light: "#F2F4F36F", dark: "primary.600" }),
        label: { color: "white", iconColor: "white" },

        button: {
          solid: {
            bg: "white",
          },
          outline: {
            bg: "transparent",
            color: switchStyle(colorMode, { light: "white", dark: "white" }),
            borderColor: switchStyle(colorMode, { light: "white", dark: "white" }),
            _hover: {
              color: switchStyle(colorMode, { light: "white", dark: "white" }),
            },
          },
        },
      },
      partner: {
        bg: "linear-gradient(70.66deg, #0D4740 23.46%, #1B9B48 84.63%)",
        colorScheme: switchStyle(colorMode, { light: "primary", dark: "secondary" }),
        loaderColor: "primary.200",
        outlineBtnColor: switchStyle(colorMode, { light: "primary.900", dark: "secondary.800" }),
        textColor: "white",
        patternColor: "#0D4740",
        label: { color: "white", iconColor: "white" },
        outline: {
          color: "white",
          borderColor: "white",
          _hover: {
            bg: "primary.800",
            borderColor: "transparent",
          },
        },
      },
    };

    return map[scheme];
  }, [scheme, bg, colorScheme, outlineBtnColor, loaderColor, buttonColor, colorMode, patternColor]);

  return schemes;
}
