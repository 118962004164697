import { Box, useColorModeValue } from "@chakra-ui/react";
import Icon from "components/Icon/Icon";

export function DropdownIcon(props: any) {
  const color = useColorModeValue("#0D4740", "#0D4740");

  return (
    <Box
      boxSize="20px"
      bg="#CFDAD9"
      shadow="0px 0px 4px rgba(59, 104, 255, 0.1)"
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      backdropFilter="blur(1px)"
      border="0.25px solid transparent"
      borderColor="rgba(255, 255, 255, 0.7)"
      {...props}
    >
      <Icon
        color={color}
        type="chevronDownArrow"
        sx={{
          path: {
            strokeWidth: "2px",
          },
        }}
      />
    </Box>
  );
}
