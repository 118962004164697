import { Box, LinkProps, Text, HStack, useColorMode } from "@chakra-ui/react";
import { FC } from "react";
// import capitalize from "lodash/capitalize";
// import hexToRgba from "utils/hexToRgba";
import Link from "components/Link/Link";
import { motion, AnimatePresence } from "framer-motion";
import { switchStyle } from "utils";

interface SidebarLinkProps extends LinkProps {
  icon?: React.ReactNode | any;
  activeIcon?: React.ReactNode | any;
  label: string;
  to: string;
  disabled?: boolean;

  isCurrent?: boolean;
  _current?: LinkProps;
  afterClick?: () => void; // will be called on
}

const SidebarLink: FC<SidebarLinkProps> = (props) => {
  const { icon, activeIcon, label, to, isCurrent, disabled, _current, ...xprops } = props;

  const { colorMode: mode } = useColorMode();

  const whenCurrent = (): LinkProps =>
    isCurrent
      ? {
          bg: "transparent",
          color: switchStyle(mode, {
            light: "primary.800",
            dark: "secondary.400",
          }),
          pos: "relative",
          fontWeight: "600",
          _after: {
            content: '""',
            pos: "absolute",
            w: "100%",
            h: "90%",
            top: "50%",
            transform: " translateY(-50%)",
            bg: switchStyle(mode, {
              light: "grey.100",
              dark: "rgba(249, 250, 249, 0.05)",
            }),
            right: -5,
            borderLeftRadius: "50px",
            transition: "all .3s ease-in-out",
            willChange: "width box-shadow",
            zIndex: -1,
          },
          ..._current,
        }
      : {};

  const syntheticProps = { ...xprops, ...whenCurrent() };

  return (
    <Link
      // as={HStack}
      pos="relative"
      display="flex"
      flexDir="row"
      alignItems="center"
      to={to}
      mb="8px !important"
      p="14px 64px"
      w="100%"
      // border="4px solid transparent"
      // borderRadius="4px"
      color="#ffffff"
      _hover={{
        bg: "transparent",
      }}
      overflow="hidden"
      minH="68px"
      borderRadius="0"
      fontWeight="500"
      _focus={{
        shadow: "none",
        transition: "all .3s ease-in-out",
        _after: {
          content: '""',
          pos: "absolute",
          shadow: "outline",
          w: "100%",
          h: "90%",
          top: "50%",
          right: -5,
          borderLeftRadius: "50px",
          transition: "all .3s ease-in-out",
          transform: " translateY(-50%)",
          zIndex: -1,
        },
      }}
      disabled={disabled}
      {...syntheticProps}
    >
      <HStack>
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`icon-${isCurrent ? "active" : "inactive"}`}
            initial={{ scale: 0.9, x: 4, opacity: 0 }}
            animate={{ scale: 1, x: 0, opacity: 1 }}
            exit={{ scale: 0.9, x: 4, opacity: 0 }}
            // transition={{ type: "spring", stiffness: 100 }}
          >
            {/* <HStack> */}
            {!isCurrent && <Box boxSize="20px" as={icon} />}
            {isCurrent && <Box boxSize="20px" as={activeIcon} />}
          </motion.div>
        </AnimatePresence>

        <Text
          ml="18px !important"
          fontSize="sm"
          fontWeight="inherit"
          color="inherit"
          // color={isCurrent ? "brand.black" : "brand.greyText"}
        >
          {label}
        </Text>
      </HStack>
    </Link>
  );
};

export default SidebarLink;
