import { BoxProps, Button, Heading, HStack, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useMakeBillV2Mutation } from "apis";
import { AbstractModal, BackButton, ModalView, useAbstractModal, useModalView, ViewSwitcher } from "components";
import { useEffect, useMemo, useState } from "react";
import PinView from "ui/Common/PinView";
import { IState } from "../Features/AirtimeAndData/Panels/Airtime";
import { AbstractModalSuccessView } from "./SuccessView";

import toLower from "lodash/toLower";
import { toCoinLocale, toPrecision } from "utils";
import { Emitter } from "libs";
import { AppAnalyticEvent } from "interfaces";

export interface AirtimePreviewModalProps {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  cryptoPrice: number;
  isOpen: boolean;
  onClose: () => void;
  reset: () => void;
  currentView?: number;
  setCurrentView?: (e: number) => void;
}

interface ItemProps extends BoxProps {
  info: string;
  description: string;
}

function Pin(props: AirtimePreviewModalProps) {
  const { state, set, onClose, reset, setCurrentView } = props;

  const { onPrev } = useModalView();
  const { updateModal } = useAbstractModal();

  const [makeBill, { isLoading, isSuccess, isError, reset: resetApiState }] = useMakeBillV2Mutation();

  const handleSubmit = async (pin?: string) => {
    //   const airtimeNetwork = toLower(state?.operatorInfo?.name).split(" ");
    const phoneNumber = `+${state?.dialCode}${state?.phone?.replace(/^0+/, "")}`;
    const reqData = {
      pin: pin ?? state?.pin!,
      billType: "airtime",
      country: toLower(state?.countryCode),
      coin: toLower(state?.wallet),
      amount: +(state?.amount ?? 0),
      phone: phoneNumber,
      operatorId: state?.operatorId,
      productId: state?.productId,
      airtimeNetwork: toLower(state?.operatorInfo?.name),
      ported: state?.ported ?? false,
      ...state?.productInfo,
    };
    console.log("BUY DATA", reqData);

    Emitter.emit(AppAnalyticEvent.BUY_AIRTIME_INITIATED, reqData);
    const result = await makeBill(reqData).unwrap();

    if (!!result) {
      Emitter.emit(AppAnalyticEvent.BUY_AIRTIME_SUBMITTED, reqData);
    }
  };

  const isDisabled = useMemo(() => !state?.pin || (!!state?.pin && state?.pin?.length < 6) || isLoading, [state, isLoading]);

  useEffect(() => {
    // const close = () => {
    // reset();
    // onClose();
    // updateModal({ isSuccess: false });
    // };

    if (isSuccess && !isError) {
      setCurrentView?.(2);
      updateModal &&
        updateModal({
          isSuccess: true,
        });

      // reset();
    }

    return () => {
      resetApiState();
    };

    // eslint-disable-next-line
  }, [isSuccess, isError, reset, resetApiState, state, updateModal]);

  return (
    <VStack p={{ base: "45px 20px", "1sm": "45px 40px", "2sm": "45px 100px" }} height={{ base: "100vh", "1sm": "initial" }}>
      <BackButton onClick={onPrev} />
      <PinView
        onPin={(pin) => set({ pin })}
        on_complete={async (pin) => {
          await handleSubmit(pin);
        }}
      />

      <VStack mt="14px !important" px="46px" w="100%">
        <Button
          minW="unset"
          w="100%"
          onClick={() => handleSubmit()}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isLoading}
          disabled={isDisabled}
        >
          Submit
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function Preview(props: AirtimePreviewModalProps) {
  const { state, cryptoPrice, onClose } = props;

  const { onNext } = useModalView();

  const plan = useMemo(
    () => state?.operatorInfo?.products?.find((p) => p.productId === state?.productId)?.name,
    [state?.productId, state?.operatorInfo?.products]
  );

  const phone = useMemo(() => `+${state?.dialCode}${state?.phone?.replace(/^0+/, "")}`, [state?.dialCode, state?.phone]);

  return (
    <VStack p={{ base: "45px 20px", "1sm": "45px 40px", "2sm": "45px 100px" }} height={{ base: "100vh", "1sm": "initial" }}>
      <VStack maxW="460px" w="100%" m="0 auto" mb="40px">
        <Heading as="h6" fontSize="18px">
          Summary
        </Heading>

        <Stack w="100%" mt="42px !important">
          <Item info="Country" description={state?.countryName ?? ""} />
          <Item info="Mobile Operator" description={state?.operatorInfo?.name ?? ""} />
          {!!plan && <Item info="Plan" description={plan} />}
          <Item info="Amount" description={`${state?.operatorInfo?.currency ?? ""} ${Number(state?.amount).toLocaleString()}`} />
          {!!state?.wallet && <Item info="Wallet" description={toCoinLocale(state?.wallet)} />}
          <Item info="Phone Number" description={phone} />
          {!!state?.wallet && !isNaN(cryptoPrice) && (
            <Item info={`Price in ${toCoinLocale(state?.wallet)}`} description={`${toPrecision(cryptoPrice, state.wallet)}`} />
          )}
        </Stack>
      </VStack>

      <VStack mt="14px !important" px="46px" w="100%">
        <Button minW="unset" w="100%" fontFamily="var(--bitmama-fonts-heading)" onClick={onNext}>
          Continue
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function Item(props: ItemProps) {
  const { info, description, ...xprops } = props;

  const icolor = useColorModeValue("grey.600", "grey.100");
  const dcolor = useColorModeValue("secondary.500", "secondary.400");
  const bcolor = useColorModeValue("border", "dark.border");

  return (
    <HStack
      border="1px solid transparent"
      w="100%"
      p="12px 20px"
      justifyContent="space-between"
      borderBottomColor={bcolor}
      {...xprops}
    >
      <Text color={icolor} fontSize={{ base: "14px", "1sm": "16px" }} fontWeight="500">
        {info}
      </Text>
      <Text color={dcolor} fontSize={{ base: "14px", "1sm": "16px" }} fontWeight="600">
        {description}
      </Text>
    </HStack>
  );
}

export default function AirtimePreviewModal(props: AirtimePreviewModalProps) {
  const { isOpen, onClose } = props;
  const [currentView, setCurrentView] = useState(0);

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _mobileSuccessStyles={{
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
      }}
      _content={{
        borderRadius: { base: "0", "1sm": "20px" },
      }}
    >
      <AbstractModalSuccessView description="Airtime purchase was successful" {...props} />
      <ModalView>
        <Views currentView={currentView} setCurrentView={setCurrentView!} {...props} />
      </ModalView>
    </AbstractModal>
  );
}

function Views(props: AirtimePreviewModalProps) {
  return (
    <ViewSwitcher>
      <Preview {...props} />
      <Pin {...props} />
    </ViewSwitcher>
  );
}
