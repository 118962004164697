import { Box, Button, Grid, HStack, Image, Stack, Text, useColorMode, useColorModeValue, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useGetPreorderStatusQuery } from "apis";

import PhysicalCardsEmpty from "assets/images/physical-card-empty.png";
import { PageLoading, Select, Title, TitleBar } from "components";
import { CreditCardAndInfo } from "components/CreditCard/CreditCard.v1";
import { useDisclosures, usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { useMemo } from "react";
import { switchStyle, when } from "utils";

import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import { AnimatePresence, motion } from "framer-motion";
import { useAppConfig } from "contexts/appconfig.context";
import FeatureUnavailableModal from "ui/Common/Modals/FeatureUnavailable";

interface PhysicalCardsProps extends PageProps {}

interface IState {
  filter: string;
}

function EmptyVirtualCard(props: any) {
  const { isEligible } = props;
  const color = useColorModeValue("grey.300", "white");

  const { appfigs } = useAppConfig();
  const { cardConfig } = appfigs ?? {};

  type ModalTypes = "featureUnavailable";
  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const isCreatePhysicalCardDisabled = useMemo(() => cardConfig?.disablePhysicalCardRequest ?? false, [cardConfig]);

  const handleCreateCard = () => {
    if (isCreatePhysicalCardDisabled) open("featureUnavailable")();
    else navigate("/cards/preorder");
  };

  return (
    <VStack py="86px" width="100%">
      <Image maxW="152px" src={PhysicalCardsEmpty} />
      <Text textAlign="center" maxW="180px" mt="28px !important" fontWeight="500" color={color}>
        Pre-order Bitmama Physical Card
      </Text>
      {isEligible && (
        <Button
          mt={{ base: "59px !important", "3sm": "90px !important" }}
          onClick={handleCreateCard}
          minW={{ base: "100%", smx: "336px", "4sm": "400px" }}
        >
          Pre-order Card
        </Button>
      )}
      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </VStack>
  );
}

export default function PhysicalCards(props: PhysicalCardsProps) {
  const { user } = props;
  const { colorMode } = useColorMode();

  const [state, set] = usePartialState<IState>({ filter: "all" });

  const isEligible = useMemo(() => user?.kycLevel === "three", [user]);

  const { data, isLoading, isFetching } = useGetPreorderStatusQuery({});
  const cards = useMemo(() => data?.data, [data]);

  const filteredCards = useMemo(
    () => cards?.filter((card: any) => (state?.filter === "all" ? card?.status : card?.status === state?.filter)),
    [cards, state?.filter]
  );

  console.log("Cards", cards);

  return (
    <AnimatePresence initial={false}>
      <Box maxW="container.md">
        <Stack mb="34px">
          <HStack justifyContent="space-between">
            <TitleBar>
              <Title
                fontSize="16px"
                isLoading={isFetching}
                color={switchStyle(colorMode, { dark: "secondary.400", light: "primary.default" })}
              >
                Your cards ({(cards ?? []).length})
              </Title>
            </TitleBar>
          </HStack>

          <Select maxW="200px" value={state?.filter ?? ""} onChange={(e) => set({ filter: e.target.value })}>
            {["all", "declined", "cancelled", "pending", "approved", "issued"].map((value) => (
              <option value={value} key={`status-${value}`}>
                {capitalize(value)}
              </option>
            ))}
          </Select>
        </Stack>

        <motion.div
          key={when(isLoading, "loading", "loaded")}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          {isLoading && <PageLoading isLoading={isLoading} />}

          {!isLoading && !isEmpty(filteredCards) && (
            <Grid key={`card-list-layout`} templateColumns="repeat(2, 1fr)" gap="30px">
              {filteredCards.map((card: any, i: number) => (
                <CreditCardAndInfo
                  key={`virtual-card-${i}`}
                  type={"tier5"}
                  name={[card?.cardDetails?.firstName, card?.cardDetails?.otherNames, card?.cardDetails?.lastName]
                    .filter(Boolean)
                    .join(" ")}
                  info="Your Card is being processed"
                  // validity={card?.expiry}
                  // number={card?.cardNumber}
                />
              ))}
            </Grid>
          )}

          {!isLoading && isEmpty(filteredCards) && <EmptyVirtualCard isEligible={isEligible} />}
        </motion.div>
      </Box>
    </AnimatePresence>
  );
}
