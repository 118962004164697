import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import {
  AddButton,
  Card,
  CardProps,
  CircularLoader,
  Group,
  Icon,
  Link,
  MainLayoutContainer,
  PageMotion,
  Paginator,
} from "components";
import { format, parseISO } from "date-fns";
import { useBuiltInFn, useDefaultStyle } from "hooks";
import { PageProps } from "interfaces";

import { currencyFormat, toCoinLocale, when } from "utils";
import capitalize from "lodash/capitalize";
import { useDeletePriceAlertMutation, useGetCoinToUsdRateQuery, useGetPriceAlertsQuery } from "apis";

interface CryptoPriceAlertListProps extends PageProps {}

// interface PriceAlertListProps {}

interface PriceAlertItemProps extends CardProps {
  _id: string;
  coin: string;
  price: number | string;
  movement: string;
  type: "price" | "percentage" | string;
  createdAt: string;
  isLoading?: boolean;
  status: string;
  ticker: string;
}

export default function CryptoPriceAlertList(props: CryptoPriceAlertListProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isSuccess } = useGetPriceAlertsQuery({
    page: currentPage,
    limit: 5,
  });

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountCryptoPriceAlertList" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <HStack justifyContent="space-between" mb="30px">
            <Button
              size="sm"
              minW="fit-content"
              maxW="fit-content"
              variant="link"
              leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
              onClick={goBack}
            >
              Back
            </Button>

            <Button
              size="sm"
              // minW="fit-content"
              // maxW="fit-content"
              // variant="link"
              // leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
              onClick={() => navigate("/account/pricealerts/orders")}
            >
              Orders
            </Button>
          </HStack>

          <HStack justifyContent="space-between" mt="10px">
            <Box>
              <Heading as="h5" fontSize={{ base: "14px", smx: "18px", "3sm": "24px" }}>
                Crypto Price Alert
              </Heading>

              <Text fontSize={{ base: "12px", "2sm": "14px" }} fontWeight="500">
                Get notified when crypto coins moves up or down in the market
              </Text>
            </Box>

            <AddButton iconPosition="left" onClick={() => navigate("/account/pricealerts/add")}>
              Add New
            </AddButton>
          </HStack>

          {isSuccess && (
            <>
              <Group>
                {data.alerts.map(({ _id, createdAt, direction, price, status, ticker, type }) => (
                  <PriceAlertItem
                    movement={direction}
                    type={type}
                    coin={String(ticker).split("usdt")[0]}
                    ticker={ticker}
                    createdAt={createdAt}
                    price={price}
                    status={status}
                    key={_id}
                    _id={_id}
                  />
                ))}

                {data.alerts.length === 0 && <EmptyState />}

                {data.alerts.length > 0 && (
                  <Paginator
                    totalCount={data?.totalCount}
                    page={currentPage}
                    limit={5}
                    onPageChange={(page: number) => setCurrentPage(page)}
                  />
                )}
              </Group>
            </>
          )}
        </Box>
      </MainLayoutContainer>
    </PageMotion>
  );
}

function PriceAlertItem(props: PriceAlertItemProps) {
  const { coin, price, movement, createdAt, type, _id } = props;
  const { shadow, borderColor } = useDefaultStyle();

  // const currentCoinMarketPrice = 20334.165; // in USD;

  const { data: coinData } = useGetCoinToUsdRateQuery(coin ?? "btc", { skip: !coin });

  const actionType = useMemo(() => when(!!movement && movement === "up", "sell", "buy"), [movement]);
  const currentCoinMarketPrice = useMemo(
    () => (coinData?.exchangeRate ?? {})[actionType] ?? 0,
    [actionType, coinData?.exchangeRate]
  );

  const amount = useMemo(() => {
    const real_price = Math.abs(+(price ?? 0));
    // const percentage = Number(currentCoinMarketPrice * (Number(price) / 100) + currentCoinMarketPrice).toFixed(2);
    // The calculation below has been done on the backend, so there's no need to do it here.
    // const percentage_to_price = when(
    //   movement === "up",
    //   currentCoinMarketPrice + (currentCoinMarketPrice * +(real_price ?? 0)) / 100,
    //   currentCoinMarketPrice - (currentCoinMarketPrice * +(real_price ?? 0)) / 100
    // );

    const map: any = {
      price: currencyFormat("usd").format(real_price),
      // percentage: `${Number(currentCoinMarketPrice / +price).toFixed(2)}%`,
      // percentage: currencyFormat("usd").format(percentage_to_price),
      percentage: currencyFormat("usd").format(real_price),
    };

    return map[type];
  }, [type, price]);

  const [_deleteAlert, { isLoading: alertLoading }] = useDeletePriceAlertMutation();

  const handleDelete = async (id: string) => {
    await _deleteAlert(id);
  };

  const handleEdit = (id: string) => {
    navigate(`/account/pricealerts/edit/${id}`);
  };

  console.log(`Coin:  ${coin} --- ${amount}, Price: ${price}, Current Market Price: ${currentCoinMarketPrice}`);

  return (
    <Card p="14px 24px" w="100%" _focus={{ shadow, borderColor }} disabled={true}>
      <HStack justifyContent="space-between" alignItems="flex-start" pos="relative">
        <Stack alignItems="flex-start">
          <Box textAlign="left">
            <Text fontWeight="600">Coin Type: {toCoinLocale(coin)}</Text>
            <Text fontSize="14px" fontWeight="500">
              {capitalize(movement === "up" ? "Above" : "Below")}: {amount}
            </Text>
          </Box>

          <Text mt="14px !important" fontSize="14px">
            {format(parseISO(createdAt), "dd/MM/yyyy")}
          </Text>
        </Stack>

        <Menu>
          <MenuButton
            as={IconButton}
            variant="transparent"
            minW="fit-content"
            minH="fit-content"
            boxSize="28px"
            icon={<Icon type="moreVert" />}
            aria-label="more"
          />
          <MenuList py="0">
            <MenuItem isDisabled={type === "percentage"} onClick={() => handleEdit(_id)}>
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleDelete(_id)}>Delete</MenuItem>
          </MenuList>
        </Menu>

        {alertLoading && <CircularLoader pos="absolute" bottom="6px" right="6px" />}
      </HStack>
    </Card>
  );
}

function EmptyState() {
  return (
    <VStack px={{ base: "20px", "2sm": "80px", md: "40px", xl: "0px" }}>
      <Text color="grey.300" fontWeight="bold">
        No crypto alerts
      </Text>
      <Text color="grey.300" fontWeight="500">
        Click ‘Add new’ to get started
      </Text>

      <Link to="/account/pricealerts/add">
        <AddButton>Add new</AddButton>
      </Link>
    </VStack>
  );
}
