import { useEffect, useRef, useState } from "react";
import { convertNumberMS } from "utils";
import ls from "utils/secureStorage";

type SavedTimeProgressType = { secondsLeft: number; date: number };

export function usePersistentCountdown(key?: string) {
  const restored_progress = useRef(false);
  const [started, setStarted] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(0);

  useEffect(() => {
    // if (secondsLeft <= 0) {
    //   const saved_progress = !!key ? (ls.get(key) as SavedTimeProgressType) : { secondsLeft: 0, date: 0 };
    //   if (saved_progress.secondsLeft < 1 && key) ls.remove(key);
    //   setStarted(false);
    //   return;
    // }

    if (secondsLeft <= 0) return;

    const timeout = setTimeout(() => {
      setSecondsLeft(secondsLeft - 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
      if (key && secondsLeft > 0) ls.set(key, { secondsLeft, date: Date.now() } as SavedTimeProgressType);
      restored_progress.current = false;
    };
  }, [secondsLeft, key]);

  useEffect(() => {
    const saved_progress = !!key ? (ls.get(key) as SavedTimeProgressType) : { secondsLeft: 0, date: 0 };
    if ((!!saved_progress && saved_progress?.secondsLeft < 1) || (!!saved_progress && !!restored_progress.current)) return;

    console.log("Saved countdown progress", saved_progress);

    const time_diff = (Date.now() - (saved_progress?.date ?? 0)) / 1000;
    const remaining_secs = Math.max(0, (saved_progress?.secondsLeft ?? 0) - time_diff);
    startCountdown(remaining_secs);
    restored_progress.current = true;
  }, [key]);

  const startCountdown = (seconds: number) => {
    if (seconds < 1) return;
    setSecondsLeft(seconds);
    setStarted(true);
  };

  const toTimeString = () => {
    return convertNumberMS(String(secondsLeft));
  };

  return { startCountdown, toTimeString, timedout: secondsLeft <= 0, started };
}
