import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";
import { motion, MotionProps } from "framer-motion";
import { forwardRef } from "react";

export interface CardProps
  extends Omit<BoxProps, "onAnimationStart" | "onDrag" | "onDragStart" | "onDragEnd" | "transition" | "style">,
    MotionProps {
  disabled?: boolean;
}

const MotionBox = motion(Box);

const Card = forwardRef((props: CardProps, ref) => {
  const { children, ...xprops } = props;

  const bg = useColorModeValue("white", "dark.cardBg");
  const shadow = useColorModeValue("card", "none");

  return (
    <MotionBox
      ref={ref}
      w="fit-content"
      borderRadius="10px"
      bg={bg}
      p="10px"
      shadow={shadow}
      transition="all .4s cubic-bezier(0.4, 0, 0.2, 1)"
      outline="1px solid transparent"
      {...(xprops as any)}
    >
      {children}
    </MotionBox>
  );
});

Card.displayName = "Card";

export default Card;
