import { Box, Button, Heading, useColorModeValue } from "@chakra-ui/react";
import { ConditionalRender, Icon, MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { useState } from "react";
import { SellAdFeature } from "ui";

interface SellAdPageProps extends PageProps {}

export default function SellAdPage(props: SellAdPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();
  const [hideBackButton, setHideBackButton] = useState(false);

  // we are selling to a buyAd, so the title should be BuyAd
  return (
    <PageMotion key="SellToBuyListingPage">
      <Topbar pageTitle="P2P" {...props} />

      <MainLayoutContainer m="0" pb="60px">
        <SidePageProvider>
          <ConditionalRender shouldRender={!hideBackButton}>
            <Box mb="42px">
              <Button
                size="sm"
                minW="fit-content"
                maxW="fit-content"
                variant="link"
                leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                onClick={goBack}
                px="2px !important"
              >
                Back
              </Button>

              <Heading mt="12px" as="h4" fontSize="24px" fontWeight="bold">
                {/* Buy Ad */}
                Ad Details
              </Heading>
            </Box>
          </ConditionalRender>

          <SellAdFeature setHideBackButton={setHideBackButton} {...props} />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}
