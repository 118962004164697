import {
  Box,
  BoxProps,
  Button,
  forwardRef,
  Grid,
  Text,
  // Image,
  useMediaQuery,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import {
  Card,
  DownloadAnnouncement,
  EarnAnnouncement,
  Group,
  PriceChart,
  QuickAction,
  Title,
  TitleBar,
  Uploader,
  // Wallet,
} from "components";
import { useModals, useSidePage } from "contexts";
import { useDisclosures, usePartialState, useSelector } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { DashboardTransactions, Enable2FAModal, FeatureUnavailableModal } from "ui";

import configs, { isProd, isTesting } from "config";
import { MultiWalletRo, PageProps } from "interfaces";
// import take from "lodash/take";
// import takeRight from "lodash/takeRight";
import { when } from "utils";

// import toLower from "lodash/toLower";
import { selectUser } from "store/slices";
import ls from "utils/secureStorage";
import { upload } from "utils/do";
import { useAppConfig } from "contexts/appconfig.context";
import { useCurrency } from "hooks/useCurrency";
import { useGetWalletTotalQuery } from "apis";
import { useWishlistCheck } from "contexts/modals.context";
import { TotalAssetsWalletCarousel } from "./testground";
import { useAvailableCountryFeatures } from "./useAvailableCountryFeatures";

// import CryptoChartPNG from "assets/images/dummy-chart.png";

interface QuickActionButtonProps extends BoxProps {
  action: string;
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
}

type ModalTypes = "2fa" | "featureUnavailable";

// const QuickActionButton_V1 = forwardRef((props: QuickActionButtonProps, ref) => {
//   const { action, color, onClick, disabled, ...xprops } = props;
//   return (
//     <Card
//       ref={ref}
//       key={`quick-action-${action}`}
//       w="100%"
//       p="0"
//       _focusWithin={{ shadow: "outline" }}
//       disabled={disabled}
//       {...(xprops as any)}
//     >
//       <QuickAction
//         flexDir={{ base: "column", "2sm": "row" }}
//         minW="100%"
//         minH="76px"
//         action={action as any}
//         _focus={{ shadow: "none" }}
//         onClick={onClick}
//         color={color}
//         disabled={disabled}
//       />
//     </Card>
//   );
// });

const QuickActionButton = forwardRef((props: QuickActionButtonProps, ref) => {
  const { action, color, onClick, disabled, ...xprops } = props;
  return (
    <QuickAction
      // flexDir={{ base: "column", "2sm": "row" }}
      // minW="100%"
      minH="76px"
      action={action as any}
      _focus={{ shadow: "none" }}
      onClick={onClick}
      color={color}
      disabled={disabled}
      {...(xprops as any)}
    />
  );
});

export default function Home(props: PageProps) {
  // const { user } = props;
  const { onOpen } = useSidePage();
  const { open, isOpen: isModalOpen } = useModals();

  // const { start } = useTourGuide();

  const { appfigs, countryfigs } = useAppConfig();
  const { profile } = useSelector(selectUser);
  // const { fiats } = useWalletBalance();
  const { isOpen, open: dopen, close } = useDisclosures<ModalTypes>();

  console.log("Country figs", countryfigs);

  const { buySellConfig, greenboxConfig, airtimeDataConfig /*, depositConfig, withdrawalConfig */ } = useMemo(
    () => ({
      buySellConfig: {
        disableBuyCrypto: appfigs?.buySellConfig?.disableBuyCrypto || !countryfigs?.buy,
        disableSellCrypto: appfigs?.buySellConfig?.disableSellCrypto || !countryfigs?.sell,
      },
      greenboxConfig: {
        disableGreenboxWallet: appfigs?.greenboxConfig?.disableGreenboxWallet || !countryfigs?.greenbox,
      },
      airtimeDataConfig: {
        disableAirtimeData: !countryfigs?.airtimeData,
      },
    }),
    [appfigs, countryfigs]
  );

  const _currency = useCurrency();
  // const defaultCurrency = useMemo(() => selectCountryCurrency(user?.country ?? "nigeria"), [user]);
  // const [{ currency }, setCurrency] = usePartialState<{ currency: string }>({ currency: _currency }, [_currency]);
  const [{ currency }, setCurrency] = usePartialState<{ currency: string }>({ currency: isTesting ? "tbtc" : "tbc" }, [_currency]);
  const coin = useMemo(() => currency ?? _currency, [currency, _currency]);

  const { data: walletTotal, isLoading: isLoadingWalletTotal } = useGetWalletTotalQuery(coin);
  console.log("Wallet Total", walletTotal);

  const tokens_and_asset_balance = useMemo(() => {
    const assets = (walletTotal ?? {}) as MultiWalletRo;
    const aggregate_keys = Object.keys(assets);
    return aggregate_keys.map((key) => ({ token: key, balance: assets[key]?.fiatAvailableBalance ?? 0 }));
  }, [walletTotal]);

  console.log("Tokens and Asset Balance", tokens_and_asset_balance);

  const tokens = useMemo(() => {
    if (isTesting) return ["usdt", "tbtc", "teth", _currency as any];
    return ["usdt", "btc", "eth", "usdt", _currency as any];
  }, [_currency]);

  const balance = useMemo(() => walletTotal?.main?.fiatTotalBalance ?? 0, [walletTotal]);
  // const availableCurrencies = useMemo(() => (fiats ?? []).map((fiat) => fiat.coin), [fiats]);

  // console.log("Available Currencies", availableCurrencies);

  // const isDepositFiatDisabled = useMemo(() => !!depositConfig?.disableFiatDeposit ?? false, [depositConfig]);
  // const isWithdrawFiatDisabled = useMemo(() => !!withdrawalConfig?.disableFiatWithdrawal ?? false, [withdrawalConfig]);

  // const canFundWallet = useMemo(() => {
  //   const country_name = toLower(user?.country ?? "nigeria");
  //   const map = currencyMap[country_name] ?? currencyMap["default"];
  //   return map.currency === coin;
  // }, [user, coin]);

  // const canWithdrawWallet = useMemo(() => {
  //   const country_name = toLower(user?.country ?? "nigeria");
  //   const opt = currencyMap[country_name] ?? currencyMap["default"];
  //   return opt?.currency === coin && opt?.enabled;
  // }, [user, coin]);

  const { quickactions_not_available } = useAvailableCountryFeatures();

  const quickActions = useMemo(() => {
    const list = [
      {
        id: "buy-feature",
        action: "buy",
        onClick: when(!!buySellConfig?.disableBuyCrypto, dopen("featureUnavailable"), () => onOpen({ key: "Buy" })),
      },
      {
        id: "sell-feature",
        action: "sell",
        onClick: when(!!buySellConfig?.disableSellCrypto, dopen("featureUnavailable"), () => onOpen({ key: "Sell" })),
      },
      { id: "vcc-feature", action: "cards", onClick: () => navigate("/cards") },
      {
        id: "greenbox-feature",
        action: "greenbox",
        onClick: when(!!greenboxConfig?.disableGreenboxWallet, dopen("featureUnavailable"), () => navigate("/greenbox")),
        color: "secondary.400",
      },
      {
        id: "airtime-feature",
        action: "airtime",
        // onClick: () => onOpen({ key: "AirtimeAndData" }) ,
        onClick: when(!!airtimeDataConfig?.disableAirtimeData, dopen("featureUnavailable"), () =>
          onOpen({ key: "AirtimeAndData" })
        ),
      },
      // { id: "staking-feature", action: "staking", onClick: () => navigate("/earn"), color: "#93522F" },
      {
        id: "yield-feature",
        action: "yield",
        onClick: () => navigate("/earn/yield"),
        color: "#93522F",
        display: when(isProd, "none", "block"),
      },

      // more
      { id: "pricealert-feature", action: "cryptoAlert", onClick: () => navigate("/account/pricealerts") },
      { id: "autobuysell-feature", action: "autoBuySell", onClick: () => navigate("/account/pricealerts/add") },
      {
        id: "play-to-earn",
        action: "playToEarn",
        onClick: () => onOpen({ key: "PlayToEarn" }),
        color: "#93522F",
        display: when(isProd, "none", "flex"),
      },
      // { action: "bills", onClick: () => navigate("/earn"), disabled: true },
      // { action: "bcpl", onClick: () => navigate("/home"), disabled: true },

      // { action: "more", onClick: undefined },
    ];

    return list.filter(({ action }) => !quickactions_not_available.includes(action as any));
  }, [onOpen, buySellConfig, dopen, greenboxConfig, quickactions_not_available, airtimeDataConfig]);

  const [is638px] = useMediaQuery("(max-width: 638px)");

  const { hasNewCycle, hasApplied, isEligible } = useWishlistCheck();

  // const hasNewCycle = false;
  // const isEligible = false;

  console.log("Christmas Check", {
    hasNewCycle,
    isEligible,
  });

  useEffect(() => {
    const faAnnouncement = ls.get(configs.TWO_FA_REMIND);
    const pyusdAnnouncement = ls.get(configs.PYUSD_REMIND);
    const xmasAnnouncement = ls.get(configs.XMAS_REMIND);
    const gameAnnouncement = ls.get(configs.GAME_REMIND(profile?.userId ?? "abc"));

    let greenbox = ls.get(configs.GREENBOX_CODE_CACHE);
    if (typeof greenbox === "string") {
      try {
        greenbox = JSON.parse(greenbox) as { status: "preclaimed"; code: string; claimLock?: string; date?: string };
      } catch {}
    }
    let hasPreclaimActionOngoing = greenbox?.status === "preclaimed";

    console.log("Should Open", {
      isModalNotOPen: !isModalOpen("christmas_updates"),
      hasPreclaimActionOngoing: !hasPreclaimActionOngoing,
      isEligible,
      hasNewCycle,
    });

    if (
      !!profile &&
      !hasPreclaimActionOngoing &&
      !faAnnouncement &&
      !profile?.twoFactor &&
      !!pyusdAnnouncement &&
      !!xmasAnnouncement &&
      !!gameAnnouncement
    )
      dopen("2fa")();
    else if (
      !isModalOpen("christmas_updates") &&
      !hasPreclaimActionOngoing &&
      isEligible &&
      hasNewCycle &&
      // !hasApplied &&
      !xmasAnnouncement
    )
      open("christmas_updates")();
    else if (!isModalOpen("pyusd_feature") && !hasPreclaimActionOngoing && !pyusdAnnouncement && !!xmasAnnouncement)
      open("pyusd_feature")();
    else if (
      !!profile &&
      !!profile?.userId &&
      !isModalOpen("gamification_updates") &&
      !hasPreclaimActionOngoing &&
      !gameAnnouncement
    )
      open("gamification_updates")();
    //eslint-disable-next-line
  }, [profile?.twoFactor, profile?.userId, hasNewCycle, isEligible, hasApplied]);

  return (
    <Box pos="relative">
      {/* <Button pos="absolute" minW="fit-content" top="20px" right="20px" variant="max" onClick={refetch} zIndex={100000}>
        Refetch Config
      </Button> */}
      <Grid display={{ base: "block", "3sm": "grid" }} templateColumns={{ base: "repeat(1, 1fr)", "4sm": "repeat(2, 1fr)" }}>
        <Group my="0" w="100%" justifySelf={{ base: "center", "4sm": "initial" }}>
          {/* <TitleBar w="100%" maxW={{ base: "280px", smx: "290px", sm: "100%", "1sm": "100%", md: "540px" }}>
            <Title isLoading={false} fontSize="18px">
              Wallet Balance
            </Title>

            <TitleBarActions>
              <Button size="xs" display="none" onClick={() => start()}>
                Start Tour
              </Button>
            </TitleBarActions>
          </TitleBar> */}

          <TotalAssetsWalletCarousel
            coins={tokens}
            tokens_and_balance={tokens_and_asset_balance}
            balance={balance}
            coin={currency!}
            isLoading={isLoadingWalletTotal}
            setCoin={(currency) => setCurrency({ currency })}
          />

          {/* <Wallet
            id="total-assets"
            scheme="totalBalance"
            minH="210px"
            minW={{ base: "280px", smx: "290px", sm: "100%", "1sm": "100%", md: "540px" }}
            label="Total Balance"
            tokens={tokens}
            currency={coin as any}
            availableCurrencies={availableCurrencies}
            value={balance ?? 0}
            canBeHidden
            isLoading={isLoadingWalletTotal}
            tooltip="Here, you can select the currency you would like to view your total wallet balance in. The total you see is inclusive of all crypto and fiat wallets"
            _outline={{
              display: when(isFiat(coin), "flex", "none"),
              disabled: !canWithdrawWallet,
              onClick: when(isWithdrawFiatDisabled, dopen("featureUnavailable"), open("fiat:withdraw", { coin })),
            }}
            _solid={{
              display: when(isFiat(coin), "flex", "none"),
              disabled: !canFundWallet,
              onClick: when(isDepositFiatDisabled, dopen("featureUnavailable"), open("fiat:fund", { coin })),
              // onClick={() => onOpen({ key: "Crypto", data: { coin: crypto?.coin } })}
            }}
            onCurrencyChange={(currency) => setCurrency({ currency })}
          /> */}
        </Group>

        {!is638px && (
          <Group mt={{ sm: "20px !important", "2sm": "20px !important", "4sm": "0px !important", md: "0 !important" }} my="0">
            <TitleBar>
              <Title isLoading={false} fontSize="18px">
                Announcement
              </Title>
            </TitleBar>

            <Grid templateColumns="repeat(2, 1fr)" gap="16px">
              <EarnAnnouncement minH="210px" _img={{ w: "calc(100% / 1.8)" }} />
              <DownloadAnnouncement minH="210px" _img={{ w: "calc(100% / 1.2)", mt: "12px !important" }} />
            </Grid>
          </Group>
        )}
      </Grid>

      {/* <TestUpload /> */}

      <Group mt="48px" mb="0">
        <TitleBar>
          <Title isLoading={false} fontSize="18px">
            Quick Actions
          </Title>
        </TitleBar>

        <Grid
          mt="38px !important"
          mb="20px !important"
          templateColumns={{ base: "repeat(4, 1fr)", sm: "repeat(3, 1fr)", "1sm": "repeat(4, 1fr)", "4sm": "repeat(10, 1fr)" }}
          gridColumnGap="14px"
          gridRowGap="26px"
          // bg="white"
          // w="fit-content"
        >
          {quickActions.map(({ id, action, color, onClick, display }) => (
            <QuickActionButton
              id={id}
              key={`quick-action-${action}`}
              action={action}
              onClick={onClick}
              color={color}
              display={display ?? "flex"}
              // disabled={disabled}
            />
          ))}

          {/* <Popover placement="top-start" returnFocusOnClose={false}>
            <PopoverTrigger>
              <QuickActionButton action="more" />
            </PopoverTrigger>
            <PopoverContent w="fit-content" borderRadius="14px" p="6px 0px" _dark={{ bg: "dark.bg", borderColor: "#212121" }}>
              <PopoverArrow />

              <PopoverBody w="fit-content">
                <Grid templateColumns="repeat(3, 1fr)" gap="14px" justifyContent="center">
                  {takeRight(quickActions, 3).map(({ action, onClick }) => (
                    <QuickAction
                      dir="vertical"
                      key={`quick-action-${action}`}
                      action={action as any}
                      onClick={onClick}
                      // disabled={disabled}
                    />
                  ))}
                </Grid>
              </PopoverBody>
            </PopoverContent>
          </Popover> */}
        </Grid>
      </Group>

      <Enable2FAModal isOpen={isOpen("2fa")} onClose={close("2fa")} />
      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />

      <Grid
        mt="48px !important"
        display={{ base: "unset", "2sm": "grid" }}
        gap={{ base: "0", lg: "24px" }}
        templateColumns={{ base: "1fr", "4sm": "1fr .7fr" }}
      >
        <Group mt="0">
          {/* <TitleBar>
            <Title>Stats</Title>
          </TitleBar> */}

          <Card
            margin={{ base: "33px auto 0", "3sm": "33px 0 0" }}
            px="0"
            minH="479px"
            w="100%"
            maxWidth={{ base: "678px", "3sm": "560px", md: "580px", lg: "678px" }}
            overflow="hidden"
          >
            {/* <Image w="100%" src={CryptoChartPNG} alt="dummy-chart" /> */}

            <PriceChart />
          </Card>
        </Group>

        <DashboardTransactions />
      </Grid>

      {is638px && (
        <Group mt="0">
          <TitleBar>
            <Title isLoading={false} fontSize="18px">
              Announcement
            </Title>
          </TitleBar>

          <Box maxWidth="630px" width="100%" overflowX="scroll">
            <Grid templateColumns="repeat(2, 1fr)" gap="16px">
              <EarnAnnouncement minH="210px" width="285px" _img={{ w: "calc(100% / 1.8)" }} />
              <EarnAnnouncement minH="210px" width="285px" _img={{ w: "calc(100% / 1.8)" }} />
            </Grid>
          </Box>
        </Group>
      )}
    </Box>
  );
}

export function TestUpload(props: any) {
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);

  console.log("Test File", file);

  const handleUpload = async () => {
    if (file) {
      const res = await upload(file, (value) => setProgress(value));
      console.log("Res", res);
    }
  };

  return (
    <Box>
      <Text bg="red" color="white">
        {progress}%
      </Text>
      <Uploader onFiles={(files) => setFile(files[0])} />
      <Button onClick={handleUpload}>Upload</Button>
    </Box>
  );
}
