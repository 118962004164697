export const lessThanSomeHoursAgo = (date: Date | string, hoursAgo: number) => {
  const givenDate = date ? new Date(date) : new Date();
  const workingDate = new Date();
  workingDate.setHours(workingDate.getHours() - hoursAgo);
  const timeDifference = workingDate.getTime() - givenDate.getTime();
  return timeDifference < 0;
};

export const lessThanSomeMinutesAgo = (date: Date | string, duration: number) => {
  const givenDate = date ? new Date(date) : new Date();
  const workingDate = new Date();
  workingDate.setMinutes(workingDate.getMinutes() - duration);
  const timeDifference = workingDate.getTime() - givenDate.getTime();
  return timeDifference < 0;
};

export const diffOfTwoDates = (date1: Date | string, date2: Date | string) => {
  const givenDate = date1 ? new Date(date1) : new Date();
  const workingDate = date2 ? new Date(date2) : new Date();
  const timeDifference = Math.abs(workingDate.getTime() - givenDate.getTime());
  return timeDifference / 1000;
};
