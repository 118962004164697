import configs from "config";
import ls from "./secureStorage";
import { nanoid } from "nanoid";

export function getGuestID() {
  let id = ls.get(configs.GUEST_CHAT_SUPPORT_STORE_KEY);
  if (!id) {
    id = `guest-${nanoid(10).toLowerCase()}-${new Date().getTime()}`;
    ls.set(configs.GUEST_CHAT_SUPPORT_STORE_KEY, id);
  } else if (!!id) {
    const splits = (id ?? "").split("-");
    const timestamp = splits[splits.length - 1];
    if (new Date(timestamp).setHours(48, 0) > Date.now()) {
      id = `guest-${nanoid(10).toLowerCase()}-${new Date().getTime()}`;
      ls.set(configs.GUEST_CHAT_SUPPORT_STORE_KEY, id);
    }
  }
  return id;
}
