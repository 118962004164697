import { Box, Button, Grid, HStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useGetListingQuery } from "apis";
import { AdvertimentItem, EmptyCrate, MobileAdvertimentItem, PageLoading, Paginator } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { PageProps } from "interfaces";
import { useMemo } from "react";
import { toQueryString, when } from "utils";
import { IFilter } from "../../Advertisements";

import isEmpty from "lodash/isEmpty";
import { usePartialState } from "hooks";
interface BuyingPanelProps extends PageProps {
  filters: Partial<IFilter>;
  setQueries?: (update: Partial<IFilter["queries"]>) => void;
  // status: string;
}

export default function BuyingPanel(props: BuyingPanelProps) {
  const { filters } = props;
  console.log("BUY FILTER", filters);

  const [state, set] = usePartialState({ page: 1, limit: 5 });

  const queries = useMemo(
    () => `${filters?.endpoint ?? ""}/?${toQueryString({ ...(filters?.queries ?? {}), adsType: "buying", ...state })}`,
    [filters?.endpoint, filters?.queries, state]
  );

  const {
    data: listing,
    isLoading,
    isFetching,
    // isUninitialized,
    // refetch,
  } = useGetListingQuery(queries);
  // const [trading, setTrading] = useState([]);
  const trades = useMemo(() => listing?.trades ?? [], [listing]);
  // useEffect(() => {
  //   const tradesByStatus = status === "all" ? trades : trades.filter((trade, i) => trade.adsStatus === status);
  //   setTrading(tradesByStatus);
  // }, [status]);
  // console.log(status);
  const totalCount = useMemo(() => listing?.totalCount, [listing]);

  console.log("MY BUY LISTINGS", totalCount, trades);

  return (
    <Box my="42px">
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={`trades-${when(isLoading, "loading", "loaded")}`}
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
        >
          {isLoading && isEmpty(trades) && <PageLoading my="30px" isLoading={isLoading} />}

          {!isLoading && !isEmpty(trades) && (
            <Grid templateRows="auto">
              {trades.map((ro, i) => (
                <AdvertimentItem display={{ sm: "none", "3sm": "grid", md: "grid" }} key={`trade-item-${i}`} {...ro} />
              ))}
            </Grid>
          )}

          {!isLoading && !isEmpty(trades) && (
            <Grid templateRows="auto">
              {trades.map((ro, i) => (
                <MobileAdvertimentItem display={{ sm: "grid", "3sm": "none", md: "none" }} key={`trade-item-${i}`} {...ro} />
              ))}
            </Grid>
          )}

          {!isLoading && isEmpty(trades) && (
            <EmptyCrate my="80px" type="p2p" description="You don't have buy listings">
              <Button
                my="24px !important"
                minH="42px"
                minW="205px"
                p="0"
                // leftIcon={<Icon type="addCircle" />}
                onClick={() => navigate("/p2p/create")}
              >
                Create a buy ad
              </Button>
            </EmptyCrate>
          )}
        </motion.div>
      </AnimatePresence>

      {(totalCount ?? 0) > (filters?.queries?.limit ?? 5) && (
        <HStack w="100%" justifyContent="flex-end">
          <Paginator
            w="100%"
            flexDir="row"
            isLoading={isFetching}
            totalCount={totalCount}
            limit={state?.limit ?? 5}
            page={state?.page ?? 1}
            onPageChange={(page: number) => set({ page })}
          />
        </HStack>
      )}
    </Box>
  );
}
