import { Box, BoxProps } from "@chakra-ui/react";
import { AchievementsLayout, EmptyCrate, Title, TitleBar } from "components";

interface AchievementsChallengesPanelProps extends BoxProps {}

export function AchievementsChallengesPanel(props: AchievementsChallengesPanelProps) {
  return (
    <AchievementsLayout px={{ sm: "0px", "2sm": "20px", md: "20px" }}>
      <TitleBar mb="58px">
        <Title _text={{ fontWeight: "700" }}>All Challenges</Title>
      </TitleBar>

      {/* <Grid
        templateColumns={{ sm: "1fr", "2sm": "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
        gridRowGap={{ sm: "20px", "2sm": "20px", md: "43px" }}
        gridColumnGap={{ "2sm": "20px", md: "20px" }}
      >
        {["in_progress", "not_started", "not_started"].map((status, i) => (
          <ChallengeInfo status={status as any} key={`challenge-${i}`} />
        ))}
      </Grid> */}
      <EmptyCrate type="achievement_challenge" title="Coming Soon" />

      <Box h="160px" display={{ sm: "block", md: "none" }} />
    </AchievementsLayout>
  );
}
