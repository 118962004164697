import { Box, Button, HStack, Heading, ModalProps, Text, VStack, useToast } from "@chakra-ui/react";
import { useSurveyRatingMutation } from "apis";
import { AbstractModal, Icon, IconNames, Steps, useModalView } from "components";
import Textarea from "components/Input/Textarea";
import { useDefaultStyle, usePartialState } from "hooks";
import { useCallback, useMemo } from "react";
import { when } from "utils";

interface GeneralRatingModalProps extends Omit<ModalProps, "children"> {}

interface IGeneralRatingState {
  selectedRating: number;
  feedback: string;
}

interface IGeneralRatingViewProps extends GeneralRatingModalProps {
  state: ReturnType<typeof usePartialState<IGeneralRatingState>>[0];
  set: ReturnType<typeof usePartialState<IGeneralRatingState>>[1];
}

export default function GeneralRatingModal(props: GeneralRatingModalProps) {
  const { ...xprops } = props;
  const [state, set] = usePartialState<IGeneralRatingState>();

  return (
    <AbstractModal
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        position: { base: "absolute", "1sm": "initial" },
        padding: { base: "0", "1sm": "0", md: "20px" },
        overflow: "hidden",
        backgroundSize: "cover",
        // position: { base: "absolute", "1sm": "relative" },
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      {...xprops}
    >
      <Steps hideIndicator>
        <Index {...{ ...props, state, set }} />
        <Rating {...{ ...props, state, set }} />
        <RatingReview {...{ ...props, state, set }} onClose={props?.onClose} />
      </Steps>
    </AbstractModal>
  );
}

function Index(props: IGeneralRatingViewProps) {
  const { onClose } = props;
  const { onNext } = useModalView();

  return (
    <Box p={{ base: "32px 16px", "1sm": "50px 80px" }}>
      <VStack mb="56px !important">
        <VStack mb="22px !important" bg="#31B7A933" borderRadius="50%" boxSize="86px" justifyContent="center">
          {/* <Icon type="errorMoji" boxSize="44px" /> */}
          <Text mt="8px !important" fontSize="44px">
            😁
          </Text>
        </VStack>
        <Heading mb="20px !important" as="h5" fontSize="21px">
          You’re good to go!
        </Heading>

        <Text fontWeight="500" textAlign="center">
          You can go ahead and start enjoying Bitmama the way you want. If you need more assistance, remember to always go to
          support
        </Text>
      </VStack>

      <VStack mt="24px !important" px={{ base: "16px", md: "32px" }} w="100%">
        <Button minW="unset" w="100%" onClick={onNext}>
          Continue
        </Button>
        <Button variant="outline" fontSize="12px" minW="unset" minH="38px" w="100%" onClick={onClose}>
          Not now
        </Button>
      </VStack>
    </Box>
  );
}

function Rating(props: IGeneralRatingViewProps) {
  const { state, set, onClose } = props;
  const { onNext } = useModalView();

  //   #FFCC4D;

  const emoji = ["😠", "😕", "😐", "🙂", "😃"];
  const is_selected = useMemo(() => {
    return state?.selectedRating! >= 1 && state?.selectedRating! <= 5;
  }, [state?.selectedRating]);

  return (
    <Box p={{ base: "32px 16px", "1sm": "50px 80px" }}>
      <VStack mb="56px !important">
        <VStack mb="22px !important" bg="#31B7A933" borderRadius="50%" boxSize="86px" justifyContent="center">
          {/* <Icon type="errorMoji" boxSize="44px" /> */}
          <Text mt="8px !important" fontSize="44px">
            {when(is_selected, emoji[state?.selectedRating! - 1], "🤩")}
          </Text>
        </VStack>
        <Heading mb="20px !important" as="h5" fontSize="21px" textAlign={{ base: "center", md: "initial" }}>
          How are you enjoying Bitmama so far?
        </Heading>

        <EmojiRating selectedIndex={state?.selectedRating} onSelect={(index) => set({ selectedRating: index })} />
      </VStack>

      <VStack mt="24px !important" px={{ base: "16px", md: "32px" }} w="100%">
        <Button _hover={{}} minW="unset" w="100%" disabled={!state?.selectedRating} onClick={onNext}>
          Continue
        </Button>
        <Button variant="outline" fontSize="12px" minW="unset" minH="38px" w="100%" onClick={onClose}>
          Not now
        </Button>
      </VStack>
    </Box>
  );
}

function RatingReview(props: IGeneralRatingViewProps) {
  const { state, set, onClose } = props;
  const toast = useToast();

  const [sendSurvey, { isLoading }] = useSurveyRatingMutation();

  const handleSubmit = async () => {
    if (!state?.selectedRating) return;

    const result = await sendSurvey({
      rating: String(state?.selectedRating),
      feedback: state?.feedback,
    }).unwrap();

    if (!!result && !!result?.data) {
      onClose();
      console.log("Survey", result, onClose);
      toast({
        title: "Success",
        description: "Survey successfully sent.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const handleSkip = async () => {
    onClose();
    await handleSubmit();
  };

  return (
    <Box p={{ base: "32px 16px", "1sm": "44px 80px" }}>
      <VStack mb="60px !important" padding={{ base: "0 16px", md: "0" }}>
        <Heading mb="20px !important" as="h5" fontSize="21px">
          Anything to add?
        </Heading>
        <EmojiRating selectedIndex={state?.selectedRating} onSelect={(index) => set({ selectedRating: index })} />

        <Textarea mt="20px !important" w="100%" minH="103px" minW={{ base: "initial", md: "400px" }} />
      </VStack>

      <VStack mt="24px !important" px={{ base: "16px", md: "32px" }} w="100%">
        <Button
          minW={{ base: "initial", md: "400px" }}
          w="100%"
          onClick={handleSubmit}
          disabled={isLoading || !state?.selectedRating}
          isLoading={isLoading}
          _hover={{}}
        >
          Continue
        </Button>
        <Button
          _hover={{}}
          variant="outline"
          fontSize="12px"
          minW={{ base: "initial", md: "400px" }}
          minH="38px"
          w="100%"
          onClick={handleSkip}
        >
          Not now
        </Button>
      </VStack>
    </Box>
  );
}

interface EmojiRatingProps {
  selectedIndex?: number; // 1 to 5 rating;
  onSelect?: (index: number, emoji?: string) => void;
}

function EmojiRating(props: EmojiRatingProps) {
  const emoji_size = "38px";
  const { selectedIndex = 0, onSelect } = props;
  const { shadow } = useDefaultStyle();

  const [state, set] = usePartialState<{ selected: string; index: number }>({ selected: "none", index: selectedIndex }, [
    selectedIndex,
  ]);

  const emoji_in_order: IconNames[] = useMemo(
    () => [
      "emoji_angryFace",
      "emoji_confusedFace",
      "emoji_neutralFace",
      "emoji_slightlySmilingFace",
      "emoji_grinningWithBigEyeFace",
    ],
    []
  );

  const is_selected = useCallback((index: number) => state?.index! - 1 === index, [state?.index]);

  const handleSelect = (emoji: string, index: number) => {
    // index is a 1 to 5 range.
    const _index = index + 1;
    set({ selected: emoji, index: _index });
    onSelect && onSelect(_index, emoji);
    console.log(`EmojiRating::${emoji}, Rating - ${_index}`);
  };

  return (
    <HStack>
      {emoji_in_order.map((emoji, index) => (
        <Button
          p="2px"
          variant="unstyled"
          borderRadius="50%"
          minH={emoji_size}
          minW={emoji_size}
          w={emoji_size}
          h={emoji_size}
          display="flex"
          justifyContent="center"
          alignItems="center"
          _focus={{ shadow }}
          _active={{ shadow }}
          onClick={() => handleSelect(emoji, index)}
        >
          <Icon type={emoji} boxSize={emoji_size} color={when(is_selected(index), "#FFCC4D", "#D9D9D9")} />
        </Button>
      ))}
    </HStack>
  );
}
