import { Box, BoxProps, Button, ButtonProps, HStack, Select, Text, useColorModeValue } from "@chakra-ui/react";
import { useGetCoinToUSDValueQuery, useGetPriceChangeQuery, useGetPriceDataQuery } from "apis";
import Icon from "components/Icon/Icon";
import configs from "config";
import { format } from "date-fns";
import { ECharts, EChartsOption, graphic, init } from "echarts";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useCoin, useDefaultStyle, usePartialState } from "hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { currencyFormat, toCoinLocale, when, wrap } from "utils";

// import omit from "lodash/omit";

// import isEmpty from "lodash/isEmpty";
import { SquareLeftArrowSVG, SquareRightArrowSVG } from "assets";
import InputLabel from "components/InputLabel/InputLabel";
import chunk from "lodash/chunk";

interface PriceChartProps extends BoxProps {
  coin?: string;
  showMarkups?: boolean;
  showDateFilter?: boolean;
  showSelector?: boolean;
  _info?: BoxProps;
}

interface CurrentPriceProps extends BoxProps {
  coin: string;
  showSelector?: boolean;
  setCoin: (coin: string) => void;
}

export function PriceChart(props: PriceChartProps) {
  const { coin = "btc", showMarkups = true, showDateFilter = true, showSelector = true, _info, ...xprops } = props;
  const chartRef = useRef<HTMLDivElement | null>(null);
  const chart = useRef<ECharts | null>(null);

  const [state, set] = usePartialState<{ token: string }>({ token: coin }, [coin]);
  const [fil, setFil] = useState<string>("1y");
  const [dateFilter, setDateFilter] = useState<{ days?: string; hours?: string; interval?: string }>({
    days: "365",
    hours: "24",
    interval: "monthly",
  });

  const token = useMemo(() => state?.token ?? coin, [coin, state?.token]);
  const setToken = useCallback((token: string) => set({ token }), [set]);

  const coinName = useMemo(() => {
    const map = {
      [token!]: token,
      btc: "bitcoin",
      eth: "ethereum",
      tbtc: "bitcoin",
      teth: "ethereum",
      xlm: "stellar",
      xrp: "ripple",
      cusd: "celo-dollar",
      usdt: "tether",
      "usdt-tron": "tether",
      usdc: "usd-coin",
      //   matic: "polygon",
    };

    return map[token!];
  }, [token]);

  console.log("Crypto Details Params", { coin, coinName });

  const { data } = useGetPriceDataQuery(
    {
      coin: coinName,
      days: "30",
      ...dateFilter,
    },
    { pollingInterval: 120000 }
  );

  const [time, series] = useMemo(() => {
    const time: string[] = [];
    const series: number[] = [];
    data?.prices?.forEach((datum: any) => {
      // console.log("DATE", format(datum[0], "yyyy-MM-dd"));
      time.push(format(datum[0], "yyyy-MM-dd"));
      series.push(datum[1]);
    });

    return [time, series];
  }, [data]);

  const options: EChartsOption = useMemo(
    () => ({
      xAxis: {
        type: "category",
        boundaryGap: false,
        // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        data: time,

        axisPointer: {
          show: true,
        },
        axisLine: {
          onZero: false,
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          inside: false,
          show: false,
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      dataZoom: [
        {
          type: "inside",
          throttle: 50,
          show: true,
        },
      ],
      tooltip: {
        trigger: "axis",
        valueFormatter(value) {
          return currencyFormat("usd").format(value as number);
        },
        backgroundColor: "transparent",
      },
      grid: [
        {
          //   bottom: "0%",
          top: 25,
          bottom: 0,
          left: 0,
          right: 0,
        },
        {
          top: 0,
          right: 0,
          //   left: "0%",
        },
      ],
      series: [
        {
          data: series,
          symbol: "circle",
          symbolSize: 12,
          type: "line",
          showSymbol: false,
          smooth: true,

          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#31B7A99f",
              },
              {
                offset: 1,
                color: "#31B7A904",
              },
            ]),
          },

          itemStyle: {
            borderColor: "white",
            color: "#6FBE45",
          },
          lineStyle: {
            color: "#31B7A9",
          },
        },
      ],
      color: ["#31B7A9"],
    }),
    [time, series]
  );

  useEffect(() => {
    chart.current = init(
      chartRef.current as HTMLElement,
      {},
      {
        renderer: "svg",
        useDirtyRect: false,
      }
    );

    if (options && typeof options === "object") {
      chart.current.setOption(options);
    }
    window.addEventListener("resize", chart.current.resize as any);
    return () => {
      window.removeEventListener("resize", (chart.current as ECharts).resize as any);
    };
  }, [options]);

  const tokens = useMemo(
    () => Object.keys(configs.coins).filter((c) => !["ceur", "teth", "tbtc", "ngn", "kes", "ghs", "matic"].includes(c)),
    []
  );

  const handleFilter = (fil: string) => {
    setFil(fil);
    switch (fil) {
      case "1d":
        setDateFilter({ days: "2", hours: "1", interval: "hourly" });
        break;
      case "1w":
        setDateFilter({ days: "7", hours: "24", interval: "weekly" });
        break;
      case "1m":
        setDateFilter({ days: "31", hours: "24", interval: "monthly" });
        break;
      case "1y":
        setDateFilter({ days: "365", hours: "24", interval: "monthly" });
        break;
      case "all":
        setDateFilter({ days: "100000", hours: "24", interval: "monthly" });
        break;
      default:
        setDateFilter({ days: "31", hours: "24", interval: "monthly" });
        break;
    }
  };

  return (
    <Box {...xprops} width="100%">
      {showMarkups && <PriceMarkView tokens={tokens} selectedToken={token} setToken={setToken} />}

      <CurrentPrice showSelector={showSelector} coin={token} setCoin={setToken} {..._info} />

      <Box
        mt="18px"
        ref={chartRef}
        id="price-chart"
        pos="relative"
        h="180px"
        // w="800px"
        overflow="hidden"
      />
      {showDateFilter && (
        <HStack my="28px" w="100%" alignItems="center" justifyContent="center" gridGap={{ base: "20px", md: "44px" }}>
          {["1d", "1w", "1m", "1y", "all"].map((n) => (
            <Btn isSelected={n === fil} onSelect={handleFilter}>
              {n}
            </Btn>
          ))}
        </HStack>
      )}
    </Box>
  );
}

interface BtnProps extends Omit<ButtonProps, "onSelect"> {
  isSelected?: boolean;
  _selected?: ButtonProps;
  onSelect?: (fil: string) => void;
}

function Btn(props: BtnProps) {
  const { isSelected, children, _selected: _s, onSelect } = props;

  const _selected = when<ButtonProps>(
    !!isSelected,
    { bg: "secondary.100", ..._s, "aria-selected": true },
    { "aria-selected": false }
  );

  return (
    <Button
      variant="solid"
      minW="unset"
      maxW="unset"
      minH="unset"
      maxH="unset"
      p="0"
      boxSize={{ base: "32px", "4sm": "56px" }}
      borderRadius="50%"
      bg="transparent"
      color="black"
      fontWeight="500"
      fontSize={{ base: "14px", md: "16px" }}
      textTransform="uppercase"
      _hover={{ bg: "secondary.100" }}
      onClick={() => onSelect && onSelect(children as string)}
      {..._selected}
    >
      {children}
    </Button>
  );
}

function MarkUpDown(props: any) {
  const { coin = "btc", selectedCoin = "btc", onClick } = props;

  const coinName = useMemo(() => {
    const map = {
      [coin]: coin,
      btc: "bitcoin",
      eth: "ethereum",
      tbtc: "bitcoin",
      teth: "ethereum",
      xlm: "stellar",
      xrp: "ripple",
      cusd: "celo-dollar",
      usdt: "tether",
      "usdt-tron": "tether",
      usdc: "usd-coin",
      //   matic: "polygon",
    };

    return map[coin];
  }, [coin]);

  const { icon } = useCoin(coin as any);

  const { data } = useGetPriceChangeQuery({ coin: coinName }, { pollingInterval: 60000 });

  // console.log("Price Change", data);

  const bg = useColorModeValue("#F2F4F3", "#292929");

  const priceInfo = useMemo(() => (data?.marketChart ?? data?.marketCharts ?? [])[0], [data]);
  console.log("Price Change", data, priceInfo);
  const priceChangeIn1h = useMemo(
    () => +Number(priceInfo?.price_change_percentage_1h_in_currency ?? 0).toPrecision(8),
    [priceInfo]
  );
  const priceChangeIn24h = useMemo(() => +Number(priceInfo?.market_cap_change_percentage_24h ?? 0).toPrecision(8), [priceInfo]);

  const is_increased = useMemo(() => priceChangeIn1h < priceChangeIn24h, [priceChangeIn1h, priceChangeIn24h]);

  const handleClick = () => {
    onClick && onClick(coin);
  };

  return (
    <Box
      as="button"
      borderRadius="12px"
      bg={when(selectedCoin === coin, bg, "transparent")}
      p="12px"
      maxH="88px"
      minW="100px"
      onClick={handleClick}
      display="flex"
      flexDirection="column"
    >
      <Box as={icon} boxSize="20px" sx={{ w: "20px", h: "20px" }} />
      <Text fontSize="14px" fontWeight="600" mt={when(["xrp"].includes(coin), "2px", "6px")}>
        {toCoinLocale(coin)}
      </Text>
      <HStack>
        <Icon type={when(!is_increased, "arrowDown", "arrowUp")} boxSize="12px" />
        <Text fontSize="14px" fontWeight="600" color={when(!is_increased, "error", "secondary.400")}>
          {Math.abs(Number((priceChangeIn1h - priceChangeIn24h) * 100)).toFixed(2)}%
        </Text>
      </HStack>
    </Box>
  );
}

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

interface PriceMarkViewProps {
  tokens: string[];
  selectedToken: string;
  setToken: (token: string) => void;
}

function PriceMarkView(props: PriceMarkViewProps) {
  const { tokens, selectedToken, setToken } = props;

  const { shadow, borderColor } = useDefaultStyle();

  const [[page, direction], setPage] = useState([0, 0]);
  const totalPages = useMemo(() => Math.ceil(tokens.length / 5), [tokens]);
  const itemIndex = wrap(0, totalPages, page);
  const paginate = (newDirection: number) => {
    const newPage = page + newDirection;
    setPage([newPage, newDirection]);
  };

  const renderTokens = useMemo(() => {
    const layouts = chunk(tokens, 5).map((tokens, i) => (
      <HStack key={`token-list-layout-${i}`} gridGap="4px">
        {tokens.map((token: string, i: number) => (
          <MarkUpDown coin={token} selectedCoin={selectedToken} onClick={(coin: string) => setToken(coin)} />
        ))}
      </HStack>
    ));

    return layouts[itemIndex];
  }, [tokens, itemIndex, setToken, selectedToken]);

  return (
    <AnimatePresence initial={false} custom={direction}>
      <HStack p="20px 26px">
        <Button
          p="0"
          h="fit-content"
          minW="fit-content"
          minH="fit-content"
          variant="unstyled"
          borderRadius="10px"
          _focus={{ shadow, borderColor }}
          _active={{ shadow, borderColor, transform: "scale(0.9)", transition: "all .3s ease-in-out" }}
          onClick={() => paginate(-1)}
        >
          <Box as={SquareLeftArrowSVG} />
        </Button>
        <HStack w="100%" justifyContent="center">
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            {renderTokens}
          </motion.div>
        </HStack>
        <Button
          p="0"
          h="fit-content"
          minW="fit-content"
          minH="fit-content"
          variant="unstyled"
          borderRadius="10px"
          _focus={{ shadow, borderColor }}
          _active={{ shadow, borderColor, transform: "scale(0.9)", transition: "all .3s ease-in-out" }}
          onClick={() => paginate(1)}
        >
          <Box as={SquareRightArrowSVG} />
        </Button>
      </HStack>
    </AnimatePresence>
  );
}

function CurrentPrice(props: CurrentPriceProps) {
  const { coin, showSelector, setCoin, ...xprops } = props;

  const { label } = useCoin(coin as any);

  const tokens = useMemo(
    () => Object.keys(configs.coins).filter((c) => !["ceur", "teth", "tbtc", "ngn", "kes", "ghs", "matic"].includes(c)),
    []
  );

  // const coinName = useMemo(() => {
  //   const map = {
  //     [coin]: coin,
  //     btc: "bitcoin",
  //     eth: "ethereum",
  //     tbtc: "bitcoin",
  //     teth: "ethereum",
  //     xlm: "stellar",
  //     xrp: "ripple",
  //     cusd: "celo-dollar",
  //     usdt: "tether",
  //     "usdt-tron": "tether",
  //     usdc: "usd-coin",
  //     //   matic: "polygon",
  //   };

  //   return map[coin];
  // }, [coin]);

  // const { data, isLoading } = useGetPriceChangeQuery({ coin: coinName }, { pollingInterval: 60000 });

  // const priceInfo = useMemo(() => (data?.marketChart ?? [])[0], [data]);
  // const currentPrice = useMemo(() => priceInfo?.current_price, [priceInfo]);

  const reMapCoin = (coin: string) => {
    const map: Record<string, string> = {
      [coin]: coin,
      tbtc: "btc",
      teth: "eth",
      usdt: "usd",
      cusd: "usd",
    };
    return map[coin];
  };
  const { data: rate, isLoading } = useGetCoinToUSDValueQuery(reMapCoin(coin ?? "btc"), {
    pollingInterval: 60000,
    skip: (coin ?? "btc").includes("usd"),
  });

  const currentPrice = useMemo(() => ((coin ?? "btc").includes("usd") ? 1 : rate?.price ?? 0), [rate, coin]);

  // const exRate = useMemo(() => {
  //   const r = rate?.exchangeRate ?? {};
  //   if (!isEmpty(r)) return (r as any)[adsTypeMap(adsType ?? "buying")] as number;
  //   return 1;
  // }, [adsType, rate]);

  return (
    <Box p="26px" {...xprops}>
      <InputLabel isLoading={isLoading} fontSize="sm" fontWeight="500" _text={{ mb: 0 }}>
        {label} Price
      </InputLabel>
      <Text fontSize="24px" fontWeight="600" fontFamily="var(--bitmama-fonts-heading)">
        {currencyFormat("usd").format(currentPrice ?? 0)}
      </Text>
      {showSelector && (
        <Select
          size="xs"
          maxW="fit-content"
          maxH="28px"
          fontSize="sm"
          fontWeight="500"
          fontFamily="var(--bitmama-fonts-body)"
          borderRadius="6px"
          sx={{
            // ".chakra-select": {
            minH: "28px !important",
            maxH: "28px !important",
            pl: "10px",

            // },
          }}
          value={coin}
          onChange={(e) => setCoin(e.target.value)}
        >
          {tokens.map((coin) => (
            <option key={coin} value={coin}>
              {toCoinLocale(coin!)}/USD
            </option>
          ))}
        </Select>
      )}
    </Box>
  );
}
