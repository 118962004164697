import { Button, Checkbox, Grid, Stack, Text, VStack } from "@chakra-ui/react";
import { useYields } from "Pages/Earn/Yield/Yield";
import { useUpdateYieldSubscriptionMutation } from "apis";
import { DetailItem, Group, Title, TitleBar } from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { addDays, differenceInDays, format, formatDistanceToNow, isPast, parseISO } from "date-fns";
import { useDefaultStyle, useDisclosures, usePartialState } from "hooks";
import { capitalize } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { ConfirmationModal, SuccessModal } from "ui";
import { toCoinLocale, toPrecision, when } from "utils";

interface YieldDtailsProps extends SidePageViewProps {}

// interface IState {
//   coin: string;
//   amount: string;
//   pin: string;
// }

// type ModalTypes = "success" | "pin";

// const usd_format = currencyFormat("usd").format;

type ModalTypes =
  | "redeem::confirm"
  | "redeem::success"
  | "terminate::confirm"
  | "terminate::success"
  | "rollover::confirm"
  | "rollover::success"
  | "featureUnavailable";

export default function YieldDetailsFeature(props: YieldDtailsProps) {
  const { errShadow } = useDefaultStyle();
  const { params, updateSidePageConfigs, onClose: closeSidePage } = useSidePage();

  const [state, set] = usePartialState<any>();

  const { appfigs } = useAppConfig();
  const { stakingConfig } = appfigs ?? {};

  const { modal, isOpen, open, close } = useDisclosures<ModalTypes>();
  const product_id = useMemo(() => params?.id!, [params]);
  const { yields, isLoading } = useYields();

  const [_updateSub, { isLoading: isUpdating }] = useUpdateYieldSubscriptionMutation();

  //   const { data } = useGetEarnYieldDetailsQuery(product_id, { skip: !product_id });

  console.clear();

  const product = useMemo(() => yields.find((y) => y?.productId === product_id), [product_id, yields]);
  console.log("Yield Details", product);

  const createdDate = useMemo(
    () =>
      !!product?.createdAt && !!product?.product?.tenor
        ? (addDays(parseISO(product?.createdAt), product?.product?.tenor ?? 0) as Date)
        : undefined,
    [product]
  );

  const redeemDate = useMemo(
    () => (!product?.product?.expiresOn ? createdDate : parseISO(product?.product?.expiresOn)),
    [product, createdDate]
  );
  const daysLeft = useMemo(() => (redeemDate && differenceInDays(redeemDate, new Date())) ?? 0, [redeemDate]);

  const accruingInterest = useMemo(
    () => (+(product?.product?.originalApr ?? 0) * (+(product?.product?.tenor ?? 0) - daysLeft)) / 365,
    [product, daysLeft]
  );

  const fee = useMemo(() => {
    if (product?.product?.fee?.applicable === "rate") {
      return +accruingInterest * (+product?.product?.fee?.rate / 100);
    }
    if (product?.product?.fee?.applicable === "fixed") {
      return +product?.product?.fee?.fixed;
    }

    return 0;
  }, [accruingInterest, product?.product?.fee]);

  const reward = useMemo(() => +(product?.value?.$numberDecimal ?? 0) + +accruingInterest - +fee, [product, accruingInterest, fee]);

  const isExpired = useMemo(() => isPast(redeemDate as Date), [redeemDate]);

  const apr = useMemo(() => Number(+(product?.gain ?? 0) * 100).toFixed(2), [product]);
  const hoursMinsLeft = useMemo(() => redeemDate && formatDistanceToNow(redeemDate), [redeemDate]);
  const duration = useMemo(() => {
    if ((product?.tenor ?? 0) < 1) {
      return formatDistanceToNow(addDays(Date.now(), product?.tenor ?? 0));
    }

    return `${product?.tenor} day${+daysLeft > 1 ? "s" : ""}`;
  }, [product, daysLeft]);

  const isRedeemDisabled = useMemo(() => !!stakingConfig?.disableStakingRedeem ?? false, [stakingConfig]);
  const isTerminateDisabled = useMemo(() => !!stakingConfig?.disableStakingTerminate ?? false, [stakingConfig]);

  // is [redeem | rollover | terminate] updating
  const is_Updating = useCallback((name: ModalTypes) => isUpdating && modal === name, [isUpdating, modal]);

  const redeemSub = async () => {
    if (!product?._id) return;
    const result = await _updateSub({ id: product?._id!, operation: "redeem" }).unwrap();
    if (!!result) {
      open("redeem::success")();
    }
  };

  const terminateSub = async () => {
    if (!product?._id) return;
    const result = await _updateSub({ id: product?._id, operation: "cancel" }).unwrap();
    if (!!result) {
      open("terminate::success")();
    }
  };

  const handleSuccessContinue = (type: ModalTypes) => {
    closeSidePage();
  };

  useEffect(() => {
    updateSidePageConfigs({
      _back: {
        text: "Close",
        onClick: closeSidePage,
      },
    });

    //eslint-disable-next-line
  }, []);

  return (
    <Group px={{ base: "20px", "1sm": "40px" }} mt="2px" overflowY="scroll">
      <TitleBar>
        <Title fontSize="22px">Yield Details</Title>
      </TitleBar>

      <Group as="form" mt="0">
        <Group mt="20px">
          <DetailItem title="Name" description={product?.label} _description={{ fontSize: "20px" }} />

          <Grid templateColumns="repeat(2, 1fr)" gap="42px">
            <DetailItem
              title="Coin Type"
              // description={toPrecision(
              //   +(greenbox?.value?.$numberDecimal ?? 1) / +(greenbox?.product?.totalRecipients ?? 1),
              //   greenbox?.unit
              // )}
              description={toCoinLocale(product?.unit ?? "usdt")}
              _description={{ fontSize: "20px" }}
            />
            <DetailItem title="Status" description={product?.currentStatus} _description={{ fontSize: "20px" }} />
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap="42px">
            <DetailItem
              title="Principal"
              // description={toPrecision(
              //   +(greenbox?.value?.$numberDecimal ?? 1) / +(greenbox?.product?.totalRecipients ?? 1),
              //   greenbox?.unit
              // )}
              description={toPrecision(product?.amountAtStake?.volume ?? 0, product?.unit ?? "usdt")}
              _description={{ fontSize: "20px" }}
            />
            {/* <DetailItem
              title="Countdown"
              description={when(["active"].includes(product?.currentStatus ?? "none"), "Ongoing", product?.status)}
              _description={{ fontSize: "20px" }}
            /> */}

            {!isExpired && !["terminated", "redeemed"].includes(product?.status ?? "active") && (
              <DetailItem
                isLoading={isLoading}
                icon="timeCircle"
                title="Countdown"
                description="Ongoing"
                _description={{ fontSize: "20px" }}
              />
            )}
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap="42px">
            <DetailItem
              title="Percentage"
              // description={toPrecision(
              //   +(greenbox?.value?.$numberDecimal ?? 1) / +(greenbox?.product?.totalRecipients ?? 1),
              //   greenbox?.unit
              // )}
              description={`${apr}%`}
              _description={{ fontSize: "20px" }}
            />
            <DetailItem
              title="Accruing Interest"
              description={toPrecision(accruingInterest ?? 0, product?.unit ?? "usdt")}
              _description={{ fontSize: "20px" }}
            />
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap="42px">
            <DetailItem
              title="Current Total Amount"
              // description={toPrecision(
              //   +(greenbox?.value?.$numberDecimal ?? 1) / +(greenbox?.product?.totalRecipients ?? 1),
              //   greenbox?.unit
              // )}
              description={toPrecision(product?.amountAtStake?.volume ?? 0, product?.unit ?? "usdt")}
              _description={{ fontSize: "20px" }}
            />
            <DetailItem title="Duration" description={duration} _description={{ fontSize: "20px" }} />
          </Grid>
          <Grid templateColumns="repeat(2, 1fr)" gap="42px">
            <DetailItem
              title="Type"
              // description={toPrecision(
              //   +(greenbox?.value?.$numberDecimal ?? 1) / +(greenbox?.product?.totalRecipients ?? 1),
              //   greenbox?.unit
              // )}
              description={capitalize(product?.planType ?? "----")}
              _description={{ fontSize: "20px" }}
            />
            {/* <DetailItem title="Days Left" description={`${daysLeft} Day(s)`} _description={{ fontSize: "20px" }} /> */}
            {product?.product?.tenor && +daysLeft > 0 && (
              <DetailItem
                display={{ base: "none", "1sm": "flex" }}
                isLoading={isLoading}
                title="Days Left"
                description={`${daysLeft} day${+daysLeft > 1 ? "s" : ""}`}
                _description={{ color: "secondary.500", fontSize: "20px" }}
              />
            )}

            {!isExpired && product?.product?.tenor && +daysLeft === 0 && (
              <DetailItem
                isLoading={isLoading}
                title="Days Left"
                description={`${hoursMinsLeft}`}
                _description={{ color: "secondary.500", fontSize: "20px" }}
              />
            )}

            {isExpired && redeemDate && (
              <DetailItem
                isLoading={isLoading}
                title="Expired On"
                description={`${format(redeemDate, "eee dd, MMM yyyy")}`}
                _description={{ fontSize: "20px" }}
              />
            )}
          </Grid>
          <Grid templateColumns="repeat(2, 1fr)" gap="42px">
            <DetailItem
              title="Total Reward"
              // description={toPrecision(
              //   +(greenbox?.value?.$numberDecimal ?? 1) / +(greenbox?.product?.totalRecipients ?? 1),
              //   greenbox?.unit
              // )}
              description={toPrecision(reward, product?.unit ?? "usdt")}
              _description={{ fontSize: "20px" }}
            />
            <DetailItem
              title="Fee Charge"
              description={toPrecision(fee, product?.unit ?? "usdt")}
              _description={{ fontSize: "20px" }}
            />
          </Grid>
        </Group>

        <VStack mt="10px !important" py="6px">
          {/* <Button>Submit</Button> */}

          {product?.currentStatus === "active" && isExpired && (
            <Button
              maxW="320px"
              minW={{ base: "100%", "1sm": "400px" }}
              onClick={when(isRedeemDisabled, open("featureUnavailable"), open("redeem::confirm"))}
              isLoading={is_Updating("redeem::confirm")}
              disabled={isUpdating}
            >
              Redeem
            </Button>
          )}

          {product?.currentStatus === "active" && !isExpired && (
            <Button
              maxW="320px"
              minW={{ base: "100%", "1sm": "400px" }}
              variant="outline"
              borderColor="error"
              color="error"
              _focus={{ shadow: errShadow }}
              _hover={{ shadow: errShadow }}
              _active={{ shadow: errShadow }}
              onClick={when(isTerminateDisabled, open("featureUnavailable"), open("terminate::confirm"))}
              isLoading={is_Updating("terminate::confirm")}
              disabled={isUpdating}
            >
              Terminate
            </Button>
          )}
        </VStack>
      </Group>

      <ConfirmationModal
        isOpen={isOpen("redeem::confirm")}
        onClose={close("redeem::confirm")}
        onConfirm={redeemSub}
        labels={["Redeem"]}
        disableConfirmation={!isExpired && !state.isChecked}
      >
        <Stack>
          {!isExpired && (
            <Text
              fontWeight="500"
              textAlign={{ base: "center", "1sm": "left" }}
              py={{ base: "20px", "1sm": "40px" }}
              color="grey.700"
            >
              Redeeming this subscription before its end date means that you lose the interest accrued on it.
            </Text>
          )}

          {isExpired && (
            <Text
              fontWeight="500"
              textAlign={{ base: "center", "1sm": "left" }}
              py={{ base: "20px", "1sm": "40px" }}
              color="grey.700"
            >
              Are you sure you want to redeem this subscription?
            </Text>
          )}

          {!isExpired && (
            <Checkbox
              mt="40px"
              isChecked={state?.isChecked}
              onChange={(e) => set({ isChecked: e.target.checked })}
              colorScheme="green"
              alignItems="flex-start"
              sx={{
                ".chakra-checkbox__control": {
                  mt: "2px",
                },
                ".chakra-checkbox__label": {
                  fontSize: "sm",
                  color: "grey.500",
                  fontWeight: "500",
                },
              }}
            >
              I am aware of the redemption rules and confirm my early redemption.
            </Checkbox>
          )}
        </Stack>
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isOpen("terminate::confirm")}
        onClose={close("terminate::confirm")}
        onConfirm={terminateSub}
        labels={["Terminate"]}
      >
        <Text fontWeight="500" textAlign={{ base: "center", "1sm": "left" }} py={{ base: "20px", "1sm": "40px" }} color="grey.700">
          Terminating this subscription before its end date means that you lose the interest accrued on it.
        </Text>
      </ConfirmationModal>

      <SuccessModal
        isOpen={isOpen("terminate::success")}
        message={`Subscription terminated successfully`}
        onClose={close("terminate::success")}
        onContinue={() => handleSuccessContinue("terminate::success")}
        onCloseComplete={() => handleSuccessContinue("redeem::success")}
      />

      <SuccessModal
        isOpen={isOpen("redeem::success")}
        message={`Subscription redeemed successfully`}
        onClose={close("redeem::success")}
        onContinue={() => handleSuccessContinue("redeem::success")}
        onCloseComplete={() => handleSuccessContinue("redeem::success")}
      />
    </Group>
  );
}

YieldDetailsFeature.displayName = "SidePageView";
