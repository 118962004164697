import { Box, BoxProps, Button, Grid, Heading, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useBuyCryptoMutation } from "apis";
import { AbstractModal, useAbstractModal } from "components";
import { useTicker } from "hooks";
import { toUpper } from "lodash";
import { useEffect, useMemo } from "react";
import { currencyFormat, toCoinLocale, toPrecision } from "utils";
import { IState } from "../Features/Buy/Form";
import { Emitter } from "libs";
import { AppAnalyticEvent } from "interfaces";

interface BuyCryptoPreviewProps {
  state: Partial<IState>;
  rate: number;
  fee: number;
  isOpen: boolean;
  onClose: () => void;
  reset: () => void;
}

interface ItemProps extends BoxProps {
  info: string;
  description: string;
}

export default function BuyCryptoPreview(props: BuyCryptoPreviewProps) {
  const { isOpen, onClose } = props;

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _mobileSuccessStyles={{
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
      }}
      _content={{
        borderRadius: { base: "0", "1sm": "20px" },
      }}
    >
      <Preview {...props} />
    </AbstractModal>
  );
}

function Preview(props: BuyCryptoPreviewProps) {
  const { state, rate, fee, onClose, reset } = props;

  console.log({ ...state, fee }, "STATE");

  const { updateModal } = useAbstractModal();

  const bcolor = useColorModeValue("#B7DFA280", "#B7DFA220");

  const [_buyCrypto, { data: buyData, isLoading, isSuccess, isError, reset: resetApiState }] = useBuyCryptoMutation();

  const { ticker } = useTicker(state?.crypto!, state?.fiat!);
  const swapFee = useMemo(() => +(state?.fiatAmount ?? "0") * (fee ?? 0), [state?.fiatAmount, fee]);
  // const finalQuantity = useMemo(() => +(state?.fiatAmount ?? 0) - swapFee, [state?.fiatAmount, swapFee]);
  const finalQuantity = useMemo(() => (+(state?.fiatAmount ?? 0) - swapFee) / rate, [state?.fiatAmount, rate, swapFee]);

  const buyCrypto = async () => {
    const reqData = {
      coin: state?.crypto,
      currency: state?.fiat,
      price: state?.fiatAmount,
      buyPrice: rate,
      ticker,
    };

    Emitter.emit(AppAnalyticEvent.BUY_INITIATED, { ...reqData, cryptoAmount: state?.cryptoAmount });
    const result = await _buyCrypto({ ...reqData });
    if (!!result)
      Emitter.emit(AppAnalyticEvent.BUY_SUBMITTED, {
        ...reqData,
        cryptoAmount: state?.cryptoAmount,
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await buyCrypto();
  };

  useEffect(() => {
    const close = () => {
      reset();
      onClose();
      updateModal({ isSuccess: false });
    };

    if (isSuccess && !isError && buyData) {
      updateModal &&
        updateModal({
          isSuccess: true,
          success: {
            description: `${toPrecision(
              +(finalQuantity ?? 0),
              state?.crypto ?? "btc",
              false
            )} has been deposited into your ${toCoinLocale(state?.crypto ?? "btc")} Wallet`,
            actions: [
              {
                label: "Close",
                onClick: () => {
                  close();
                },
              },
            ],
          },
        });

      reset();
    }

    return () => {
      resetApiState();
    };

    // eslint-disable-next-line
  }, [isSuccess, isError, buyData, reset, resetApiState, state, updateModal]);

  return (
    <VStack
      as="form"
      p={{ base: "95px 20px", "1sm": "45px 40px", "2sm": "45px 100px" }}
      minH={{ base: "100vh", "1sm": "initial" }}
      onSubmit={handleSubmit}
    >
      <VStack maxW="460px" m="0 auto" mb="40px">
        <Heading as="h6" fontSize="18px">
          Transaction Summary
        </Heading>

        <Box mt="40px !important">
          <Grid templateColumns="repeat(2, 1fr)">
            <Item
              info={`${toCoinLocale(state?.crypto ?? "btc")} Quantity`}
              description={toPrecision(+(state?.cryptoAmount ?? 0), state?.crypto ?? "btc", false) as string}
              borderRightColor={bcolor}
              borderBottomColor={bcolor}
            />
            <Item
              info={`Amount in ${toUpper(state?.fiat)}`}
              description={currencyFormat((state?.fiat ?? "ngn") as any).format(+(state?.fiatAmount ?? 0))}
              borderBottomColor={bcolor}
            />
          </Grid>
          <Grid templateColumns="repeat(2, 1fr)">
            <Item
              info={`Final ${toCoinLocale(state?.crypto ?? "btc")} Quantity`}
              description={toPrecision(finalQuantity, state?.crypto ?? "btc", false) as string}
              borderRightColor={bcolor}
            />
            <Item
              info={`Fee ( ${fee * 100}% )`}
              description={toPrecision(Number(state?.cryptoAmount) * fee, state?.crypto ?? "btc") as string}
            />

            {/* <Item info={`Fee ( ${fee * 100}% )`} description={currencyFormat((state?.fiat ?? "ngn") as any).format(swapFee)} /> */}
          </Grid>
        </Box>
      </VStack>

      <VStack mt="14px !important" px={{ base: "20px", "1sm": "46px" }} w="100%">
        <Button
          minW="unset"
          w="100%"
          onClick={handleSubmit}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose} disabled={isLoading}>
          Back
        </Button>
      </VStack>
    </VStack>
  );
}

function Item(props: ItemProps) {
  const { info, description, ...xprops } = props;

  const icolor = useColorModeValue("grey.400", "grey.100");
  const dcolor = useColorModeValue("grey.800", "white");

  return (
    <VStack border="1px solid transparent" p={{ base: "24px 12px", "1sm": "24px 40px" }} {...xprops}>
      <Text color={icolor} fontSize={{ base: "12px", "1sm": "14px" }} fontWeight="500">
        {info}
      </Text>
      <Text color={dcolor} fontSize={{ base: "16px", "1sm": "18px" }} fontWeight="500">
        {description}
      </Text>
    </VStack>
  );
}
