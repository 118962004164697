import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import swtich from "utils/switchStyle";

const Button: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "700",
    textTransform: "capitalize",
    borderRadius: "42px", // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    xs: {
      fontSize: "sm",
      px: 6,
      py: 3,
      minH: "40px",
    },
    sm: {
      fontSize: "sm",
      px: 6,
      py: 3,
      minH: "40px",
    },
    md: {
      minH: "60px",
      minW: "400px",
      fontSize: "md",
      px: 10,
      py: 4,
    },
    xl: {
      fontSize: "md",
      minH: "54px",
      px: "38px",
      py: "10px",
    },
  },
  // Two variants: outline and solid
  variants: {
    // unstyled: (props) => ({
    //   _focus: {
    //     shadow: swtich(props.colorMode, {
    //       dark: "0 0 0 3px var(--outline, #6FBE456f)",
    //       light: "outline",
    //     }),
    //     ,
    //   },
    // }),
    ghost: {
      // minW: "fit-content",
      // minH: "fit-content",
      _hover: {
        bg: "brand.secondary",
      },
      _active: {
        bg: "brand.secondary",
      },
      _focus: {
        shadow: "0 0 0 3px var(--focusColor)",
      },
    },
    link: (props) => ({
      // borderRadius: "4px",
      border: "1px solid transparent",
      minW: "fit-content",
      minH: "fit-content",
      fontWeight: "500",

      color: swtich(props.colorMode, {
        dark: "primary.dark.default",
        light: "primary.light.default",
      }),
      bg: "transparent",
      _hover: {
        color: swtich(props.colorMode, {
          dark: "primary.dark.900",
          light: "primary.light.900",
        }),
        borderColor: "transparent",
        textDecoration: "underline",
      },
      _active: {
        borderColor: "transparent",
        transform: "scale(.99)",
        textDecoration: "underline",
      },
      _focus: {
        textDecoration: "underline",
        shadow: swtich(props.colorMode, {
          dark: "0 0 0 3px var(--outline, #6FBE456f)",
          light: "outline",
        }),
      },
    }),
    max: (props) => ({
      minW: "unset",
      minH: "unset",
      maxW: "unset",
      maxH: "unset",
      p: 0,
      w: "48px",
      h: "36px !important",
      borderRadius: "8px",
      border: "1px solid transparent",
      textTransform: "uppercase",
      fontSize: "12px",
      textDecoration: "none",

      color: swtich(props.colorMode, {
        dark: "secondary.default",
        light: "primary.default",
      }),
      bg: swtich(props.colorMode, {
        dark: "secondary.500",
        light: "#E2F2DA",
      }),
      _hover: {
        color: swtich(props.colorMode, {
          dark: "primary.dark.900",
          light: "primary.light.900",
        }),
        borderColor: "transparent",
        textDecoration: "none",
      },
      _active: {
        borderColor: "transparent",
        transform: "scale(.99)",
        textDecoration: "none",
      },
      _focus: {
        textDecoration: "none",
        shadow: swtich(props.colorMode, {
          dark: "0 0 0 3px var(--outline, #6FBE456f)",
          light: "outline",
        }),
      },
    }),
    add: (props) => ({
      minW: "160px",
      minH: "56px",
      maxW: "unset",
      maxH: "unset",
      p: "0 10px",
      borderRadius: "10px",
      border: "1px solid transparent",
      textTransform: "capitalize",
      fontSize: "md",
      textDecoration: "none",

      color: swtich(props.colorMode, {
        dark: "secondary.default",
        light: "primary.default",
      }),
      bg: swtich(props.colorMode, {
        dark: "secondary.500",
        light: "#E2F2DA8F",
      }),
      _hover: {
        color: swtich(props.colorMode, {
          dark: "primary.dark.900",
          light: "primary.light.900",
        }),
        borderColor: "transparent",
        textDecoration: "none",
      },
      _active: {
        borderColor: "transparent",
        transform: "scale(.99)",
        textDecoration: "none",
      },
      _focus: {
        textDecoration: "none",
        shadow: swtich(props.colorMode, {
          dark: "0 0 0 3px var(--outline, #6FBE456f)",
          light: "outline",
        }),
      },
    }),
    outline: (props) => ({
      // borderRadius: "4px",
      border: "1px solid transparent",
      borderColor: swtich(props.colorMode, {
        dark: "primary.dark.default",
        light: "primary.light.default",
      }),
      color: swtich(props.colorMode, {
        dark: "primary.dark.default",
        light: "primary.light.default",
      }),
      bg: "transparent",
      _hover: {
        color: swtich(props.colorMode, {
          dark: "secondary.500",
          light: "primary.default",
        }),
        borderColor: "transparent",
        bg: swtich(props.colorMode, {
          dark: "transparent",
          light: "transparent",
        }),
      },
      _active: {
        borderColor: "transparent",
        bg: swtich(props.colorMode, {
          dark: "primary.dark.700",
          light: "primary.light.900",
        }),
        transform: "scale(.99)",
      },
      _focus: {
        shadow: swtich(props.colorMode, {
          dark: "0 0 0 3px var(--outline, #6FBE456f)",
          light: "outline",
        }),
      },
    }),
    transparent: (props) => ({
      bg: "transparent",
      // borderRadius: "6px",
      border: "2px solid transparent",
      color: mode("primary.default", "white")(props),
      textTransform: "inherit",
      _hover: {
        color: mode("primary.default", "white")(props),
        borderColor: "transparent",
        bg: swtich(props.colorMode, {
          dark: "primary.dark.600",
          light: "primary.light.800",
        }),
      },
      _active: {
        borderColor: "transparent",
        bg: swtich(props.colorMode, {
          dark: "primary.dark.700",
          light: "primary.light.900",
        }),
        transform: "scale(.99)",
      },
      _focus: {
        shadow: swtich(props.colorMode, {
          dark: "0 0 0 3px var(--outline, #6FBE456f)",
          light: "outline",
        }),
      },
    }),
    solid: (props) => ({
      pos: "relative",
      bg: swtich(props.colorMode, {
        dark: "secondary.500",
        light: "primary.default",
      }),
      color: "white",
      // borderRadius: "4px",
      _hover: {
        bg: swtich(props.colorMode, {
          dark: "secondary.600",
          light: "primary.800",
        }),
      },
      _active: {
        bg: swtich(props.colorMode, {
          dark: "secondary.700",
          light: "primary.700",
        }),
        transform: "scale(.99)",
      },
      _focus: {
        shadow: swtich(props.colorMode, {
          dark: "0 0 0 3px var(--outline, #6FBE456f)",
          light: "outline",
        }),
        transitionProperty: "var(--bitmama-transition-property-common)",
        transitionDuration: "var(--bitmama-transition-duration-ultra-slow)",
        transitionTimingFunction: "var(--bitmama-transition-easing-ease-out)",
      },
    }),
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "solid",
  } as any,
};

export default Button;
