import { Box, Button, ButtonProps, FormControl, Grid, HStack, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useGetHistoryQuery } from "apis";
import {
  AchievementHistoryInfo,
  AchievementHistoryInfoList,
  ConditionalRender,
  EmptyCrate,
  Group,
  Icon,
  InputLabel,
  Link,
  MainLayoutContainer,
  PageLoading,
  PageMotion,
  Select,
  Span,
  Title,
  TitleBar,
  TitleBarActions,
} from "components";
import { useColor } from "hooks";
import { PageProps } from "interfaces";
import { useMemo, useState } from "react";
import { toQueryString } from "utils";

interface AchievementsProps extends PageProps {}

export default function Achievements(props: AchievementsProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const color = useColor();

  const [filter, setFilter] = useState<"all" | "badge" | "reward" | "challenge">("all");
  const [query_value, setQuerValue] = useState<string | null>(null);

  const { data, isLoading } = useGetHistoryQuery(query_value);
  const history = useMemo(() => data?.userAchievements?.data ?? [], [data]);

  console.log("Game History", data);

  const handleFilter = (value: string) => {
    setFilter(value as any);
    if (value === "all") setQuerValue(null);
    else setQuerValue(toQueryString({ gameCategory: value }));
  };

  const goBack = () => {
    navigate("/account");
  };

  return (
    <PageMotion subpage key="AccountAchievements" overflowY="scroll">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box mt="40px">
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <Grid templateColumns={{ sm: "1fr", md: ".8fr 1fr" }}>
            <Group mt="0">
              <HStack w="100%" justifyContent="space-between" mt="10px">
                <TitleBar justifyContent="space-between" w="100%">
                  <Title fontSize={{ sm: "20px", "2sm": "22px", md: "24px" }} fontWeight="600" color="primary.800">
                    My Achievements
                  </Title>

                  <TitleBarActions>
                    <Link
                      to="badges"
                      fontSize="16px"
                      fontFamily="var(--bitmama-fonts-heading)"
                      fontWeight="600"
                      color={color("primary.default", "secondary.400")}
                    >
                      View All
                    </Link>
                  </TitleBarActions>
                </TitleBar>
              </HStack>

              <Box
                w={{ sm: "100%" }}
                mt="28px"
                p={{ sm: "8px 18px", md: "10px 22px" }}
                borderRadius="4px"
                bg={color("#e6ebe8", "dark.cardBg")}
              >
                <HStack justifyContent="center">
                  <VStack justifyContent="center" p={{ sm: "10px", "2sm": "16px", md: "18px" }}>
                    <BadgeType bg="#FFF2D0" onClick={() => navigate("/account/achievements/badges?tab=badges")}>
                      🏅
                    </BadgeType>
                    <Text fontSize="16px" fontWeight="600" fontFamily="var(--bitmama-fonts-heading)">
                      <Span fontSize={{ sm: "22px", "2sm": "24px", md: "28px" }} fontWeight="600">
                        {data?.userTotalBadge ?? 0}
                      </Span>
                      <Span>/{data?.totalBadges ?? 0}</Span>
                    </Text>
                    <Text fontWeight="500" textAlign="center" fontSize={{ sm: "14px", "2sm": "16px" }}>
                      Badges unlocked
                    </Text>
                  </VStack>
                  <VStack p={{ sm: "10px", "2sm": "16px", md: "18px" }}>
                    <BadgeType bg="#EEEEEE" onClick={() => navigate("/account/achievements/badges?tab=rewards")}>
                      🎁
                    </BadgeType>
                    <Text
                      fontSize={{ sm: "22px", "2sm": "24px", md: "28px" }}
                      fontWeight="600"
                      fontFamily="var(--bitmama-fonts-heading)"
                    >
                      {data?.userTotalReward ?? 0}
                    </Text>
                    <Text fontWeight="500" textAlign="center" fontSize={{ sm: "14px", "2sm": "16px" }}>
                      Rewards earned
                    </Text>
                  </VStack>
                  <VStack p={{ sm: "10px", "2sm": "16px", md: "18px" }}>
                    <BadgeType bg="#E2F2DA" onClick={() => navigate("/account/achievements/badges?tab=challenges")}>
                      🎯
                    </BadgeType>
                    <Text
                      fontSize={{ sm: "22px", "2sm": "24px", md: "28px" }}
                      fontWeight="600"
                      fontFamily="var(--bitmama-fonts-heading)"
                    >
                      {data?.userTotalChallenge ?? 0}
                    </Text>
                    <Text fontWeight="500" textAlign="center" fontSize={{ sm: "14px", "2sm": "16px" }}>
                      Challenges won
                    </Text>
                  </VStack>
                </HStack>
              </Box>

              <Box mt="40px !important">
                <Box>
                  <TitleBar mb="22px">
                    <Title fontSize="18px">History</Title>
                  </TitleBar>
                  <FormControl>
                    <InputLabel>Filter by</InputLabel>
                    <Select value={filter} onChange={(e) => handleFilter(e.target.value)}>
                      <option value="all">All</option>
                      <option value="badge">Your Badges</option>
                      <option value="reward">Your Rewards</option>
                      <option value="challenge">Your Challenges</option>
                    </Select>
                  </FormControl>
                </Box>

                <ConditionalRender shouldRender={!isLoading && (history ?? []).length > 0}>
                  <AchievementHistoryInfoList mt="42px" isLoading={isLoading}>
                    {history.map((h, i) => (
                      <AchievementHistoryInfo
                        key={i}
                        badge="newBitStar"
                        logoUrl={h?.logoUrl}
                        title={h?.title}
                        description={h?.description}
                      />
                    ))}
                  </AchievementHistoryInfoList>
                </ConditionalRender>

                {!!isLoading && (history ?? []).length < 1 && <PageLoading isLoading={true} />}

                {!isLoading && (history ?? []).length < 1 && (
                  <EmptyCrate
                    type="achievement"
                    description="You don’t have any unlocked badges, and claimed rewards yet, Complete challenges to claim badges and rewards."
                  />
                )}
              </Box>
            </Group>
          </Grid>
        </Box>

        <Box h="160px" display={{ sm: "block", md: "none" }} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

interface BadgeTypeProps extends ButtonProps {}

function BadgeType(props: BadgeTypeProps) {
  const { children, ...xprops } = props;
  return (
    <Box
      as="button"
      borderRadius="20px"
      boxSize={{ sm: "80px", "2sm": "88px", md: "94px" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...(xprops as any)}
    >
      <Span fontSize={{ sm: "32px", md: "46px" }}>{children}</Span>
    </Box>
  );
}
