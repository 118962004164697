import { Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { AbstractModal, Icon } from "components";

interface Setup2FAProps extends Omit<ModalProps, "children"> {
  onContinue?: () => void;
}

export function Setup2FAModal(props: Setup2FAProps) {
  const { isOpen, onClose, onContinue } = props;

  return (
    <AbstractModal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false} _content={{ maxW: "560px" }}>
      <VStack py="45px" px="60px">
        <VStack maxW="520px" m="0 auto">
          <Icon boxSize="48px" mb="14px !important" type="infoError" />

          <Heading as="h4" fontSize="28px" mb="10px !important">
            2FA is required
          </Heading>
          <Text textAlign="center" mt="20px" fontWeight="500">
            To confirm account ownership, you are required to setup 2FA to complete your request
          </Text>
        </VStack>

        <VStack mt="48px !important" px="46px" w="100%">
          <Button minW="unset" w="100%" onClick={() => !!onContinue && onContinue()} fontFamily="var(--bitmama-fonts-heading)">
            Proceed
          </Button>
          <Button
            mt="20px !important"
            variant="link"
            minW="unset"
            w="fit-content"
            onClick={onClose}
            color="secondary.500"
            fontFamily="var(--bitmama-fonts-heading)"
          >
            Cancel
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
