import { Box, Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderTrack } from "@chakra-ui/react";
import BitcoinPNG from "assets/images/bitcoin.png";
import { useRegistrationProgress } from "contexts";
import { useColor } from "hooks";

interface AuthProgressProps extends SliderProps {}

export function AuthProgress(props: AuthProgressProps) {
  const color = useColor();

  const { state } = useRegistrationProgress(4);

  return (
    <Slider aria-label="slider-ex-4" defaultValue={0} value={state?.progress} pos="absolute" top="10px">
      <SliderTrack h="10px" bg={color("#e6e6e6", "#292929")}>
        <SliderFilledTrack
          transition="all .3s cubic-bezier(0, 0.63, 0.3, 1)"
          willChange="width"
          bg={color("primary.500", "secondary.400")}
        />
      </SliderTrack>
      <SliderThumb bg="transparent" transition="all .3s cubic-bezier(0, 0.63, 0.3, 1)" willChange="width">
        <Box as="img" minW="48px" boxSize="48px" src={BitcoinPNG} />
      </SliderThumb>
    </Slider>
  );
}
