import { Box, BoxProps, ImageProps, Text } from "@chakra-ui/react";
import { EarnAnnouncementIllustration, EarnAnnouncementPattern } from "assets";

import EarnAvatar from "assets/images/earn-avatar.png";
import Link from "components/Link/Link";

interface EarnAnnouncementProps extends BoxProps {
  _img?: ImageProps;
}

export default function EarnAnnouncement(props: EarnAnnouncementProps) {
  const { _img, ...xprops } = props;
  return (
    <Box p="30px" minH="246px" pos="relative" bg="#0B3B35" borderRadius="16px" overflow="hidden" {...xprops}>
      <Box color="#fff" textTransform="uppercase" fontWeight="bold" fontStyle="italic" pos="relative" zIndex="1">
        <Text>We earn</Text>
        <Text ml="20px">You earn</Text>
      </Box>

      <Box pos="absolute" bottom="20px" left="calc(100% - 100px)" zIndex={3}>
        <Link
          color="white"
          fontSize="12px"
          fontWeight="500"
          textDecoration="underline"
          onClick={() => window.open("https://bitmama.io/terms", "blank")}
        >
          T&Cs Apply
        </Link>
      </Box>

      <Box
        as="img"
        alt="earn annoucement image"
        pointerEvents="none"
        objectPosition="top"
        src={EarnAvatar}
        pos="absolute"
        bottom="0"
        w="calc(100% - 240px)"
        zIndex={2}
        {..._img}
      />
      <Box as={EarnAnnouncementIllustration} pointerEvents="none" pos="absolute" top="20px" right="20px" zIndex={1} />
      <Box as={EarnAnnouncementPattern} pointerEvents="none" pos="absolute" bottom="0" right="0" zIndex={1} />
    </Box>
  );
}
