import { Box, BoxProps, ModalProps, Text, VStack } from "@chakra-ui/react";
import { CardSuccessSVG } from "assets";
import { AbstractModal, Steps } from "components";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

interface IProps extends Omit<ModalProps, "children"> {
  onContinue?: () => void;
  _content?: BoxProps;
  cardId: string;
  editCard?: boolean;
  onOpen?: () => void;
  label?: string;
}

const AddEditCardLabel = (props: IProps) => {
  const { isOpen, onClose, onOpen, onContinue, cardId, editCard, label, _content, ...xprops } = props;

  return (
    <>
      {editCard && (
        <Text color="secondary.400" fontWeight="600" cursor="pointer" onClick={onOpen!}>
          Edit
        </Text>
      )}

      <AbstractModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        _content={{
          maxW: "568px",
          position: { base: "absolute", "1sm": "initial" },
          bottom: 0,
          margin: { base: "0", "1sm": "initial" },
          borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
          padding: "0",
          ..._content,
        }}
        {...xprops}
      >
        <VStack p={{ base: "24px 0px", "2sm": "102px 48px" }}>
          <Box mb="8px" as={CardSuccessSVG} />

          {editCard ? (
            <StepTwo onClose={onClose} onContinue={onContinue!} cardId={cardId} label={label} />
          ) : (
            <Steps hideIndicator>
              <StepOne onClose={onClose} onContinue={onContinue!} />

              <StepTwo onClose={onClose} onContinue={onContinue!} cardId={cardId} useInternalId />
            </Steps>
          )}
        </VStack>
      </AbstractModal>
    </>
  );
};

export default AddEditCardLabel;
