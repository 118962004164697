import {
  Box,
  Button,
  ButtonProps,
  Divider,
  FormControl,
  HStack,
  Stack,
  Switch,
  TabList,
  TabPanel,
  Tabs,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import {
  useFreezeUserCardMutation,
  useGetUserCardsQuery,
  useGetUserCardTransactionsQuery,
  useLiquidateCardMutation,
  useUnfreezeUserCardMutation,
} from "apis";
import {
  Card,
  CreditCard,
  CreditCardTransactionItem,
  DetailItem,
  EmptyCrate,
  Group,
  InputLabel,
  PageLoading,
  Paginator,
  Tab,
  TabPanels,
  Title,
  TitleBar,
  TitleBarActions,
  useModalView as useSteps,
} from "components";
import { useSidePage } from "contexts";
import { useColor, useCreditCardDetails, useDisclosures, usePartialState } from "hooks";
import { CardDetailsRo, CardRo } from "interfaces";
import { useMemo, useRef, useState } from "react";
import { commaStrToArr, switchStyle, toQueryString, when } from "utils";

import configs, { isTesting } from "config";
import { AnimatePresence, motion } from "framer-motion";
import capitalize from "lodash/capitalize";
import chunk from "lodash/chunk";
import isEmpty from "lodash/isEmpty";
import { AddEditCardLabel, ConfirmationModal, FeatureUnavailableModal, SubmitPinModal, SuccessModal } from "ui";

import { useAppConfig } from "contexts/appconfig.context";
import RequestCardStatment from "ui/Cards/Modals/RequestStatement";
import ls from "utils/secureStorage";

interface CardDetailsProps {}

interface DetailsPanelProps {
  card: CardDetailsRo;
  isLoading: boolean;
  canFreeze: boolean;
  refetch: () => any;
  currentCard: CardRo;
  is_obfuscated?: boolean;
  obfuscate?: (value: boolean) => void;
}
interface TransactionsPanelProps {}

type DetailsModalTypes = "confirm" | "unsupportedFund" | "liquidated" | "featureUnavailable" | "requestStatement";

export default function CardDetails(props: CardDetailsProps) {
  const { setCurrentView } = useSteps();
  const { params, onClose } = useSidePage();
  const location = useLocation();

  const { appfigs } = useAppConfig();
  const { cardConfig } = appfigs ?? {};

  // console.log("Card Config", cardConfig);

  const [is_obfuscated, obfuscate] = useState(true);
  const { outline, solid } = useDetailsStyles();
  const { isOpen, close, open } = useDisclosures<DetailsModalTypes>();
  const searchParams = useMemo(() => new URLSearchParams(location?.search as string), [location?.search]);

  const [state, set] = useState<{ pin: string | null }>({ pin: null });

  const resetPin = () => set({ ...state, pin: null });
  const tempPin = useMemo(() => state?.pin ?? ls.get(configs.TEMP_PIN), [state?.pin]);
  const { data, isFetching: isGettingAllCards } = useGetUserCardsQuery(toQueryString({ active: true }));
  const cards = useMemo(() => data?.card ?? [], [data]);

  const queries = useMemo(() => toQueryString({ p: searchParams.get("provider") }), [searchParams]);

  const { card, isLoading, isPinOpen, onPinClose, refetch } = useCreditCardDetails(params?.cardId!, tempPin, { queries }, resetPin);
  const currentCard = useMemo(
    () => cards?.find((c) => c.cardId === params?.cardId) ?? card?.internalDetails,
    [cards, params, card]
  );

  console.log("Card Details", { card, currentCard });

  const [liquidateCard, { isLoading: isLiquidating, isSuccess: isLiqSuccess, data: liqData }] = useLiquidateCardMutation();

  const isFundingDisabled = useMemo(
    () =>
      (!commaStrToArr(cardConfig?.cardFundingEnabledProviders ?? "").includes(currentCard?.provider ?? "p3") ||
        cardConfig?.disableFundCard) ??
      false,
    [cardConfig, currentCard?.provider]
  );
  const isWithdrawalDisabled = useMemo(
    () =>
      (!commaStrToArr(cardConfig?.cardWithdrawalEnabledProviders ?? "").includes(currentCard?.provider ?? "p3") ||
        cardConfig?.disableWithdrawFromCard) ??
      false,
    [cardConfig, currentCard]
  );
  const isFreezeDisabled = useMemo(
    () => !commaStrToArr(cardConfig?.cardFreezeEnabledProviders ?? "").includes(currentCard?.provider ?? "p3"),
    [cardConfig, currentCard]
  );

  console.log("FW", { isWithdrawalDisabled, isFundingDisabled });

  console.log("Current Card", currentCard ?? card?.internalDetails);

  // const canPerformAction = useMemo(() => {
  //   return {
  //     withdraw:
  //       (currentCard?.cardVersion === "v3" && currentCard?.provider === "p2" && +currentCard?.balance?.$numberDecimal > 0) ||
  //       ((currentCard?.provider ?? "p1") === "p1" && +(card?.balance ?? 0) > 0),
  //     fund: currentCard?.cardVersion === "v3" && currentCard?.provider === "p2",
  //     freeze: currentCard?.cardVersion === "v3" && currentCard?.provider === "p2",
  //     liquidate: (currentCard?.provider ?? "p2") === "p2" && currentCard?.cardVersion === "v2",
  //   };
  // }, [currentCard, card]);

  const balance = useMemo(() => {
    if (!!card?.internalDetails?.balance && isTesting) return card?.internalDetails?.balance;
    else if (!!card?.balance && !isTesting)
      return when(typeof card?.balance !== "object", +(card.balance ?? 0), (card?.balance as any)?.availableBalance);
    return 0;
  }, [card]);

  console.log("Balance", { balance, card });

  // const canPerformAction = useMemo(() => {
  //   return {
  //     withdraw:
  //       (currentCard?.cardVersion === "v3" &&
  //         ["p3"].includes(currentCard?.provider) &&
  //         +(currentCard?.balance?.$numberDecimal ?? 0) > 0) ||
  //       ((currentCard?.provider ?? "p1") === "p1" && +(balance ?? 0) > 0),
  //     fund: currentCard?.cardVersion === "v3" && ["p3"].includes(currentCard?.provider),
  //     freeze: currentCard?.cardVersion === "v3" && ["p2", "p3"].includes(currentCard?.provider),
  //     liquidate:
  //       !!currentCard?.cardVersion &&
  //       ["p2"].includes(currentCard?.provider ?? "p2") &&
  //       ["v2", "v3"].includes(currentCard?.cardVersion),
  //   };
  // }, [currentCard, balance]);

  const canPerformAction = useMemo(() => {
    let shouldLiquidateP2 =
      !!currentCard?.cardVersion && ["p2"].includes(currentCard?.provider ?? "") && ["v2", "v3"].includes(currentCard?.cardVersion);

    let shouldLiquidateP3 =
      // !currentCard?.disabled &&
      ["p3"].includes(currentCard?.provider ?? "") && ["was-suspended", "should-terminate"].includes(currentCard?.statusTag ?? "");

    let shouldLiquidate = shouldLiquidateP2 || shouldLiquidateP3;

    return {
      withdraw:
        !shouldLiquidate &&
        ((currentCard?.cardVersion === "v3" &&
          !currentCard.disabled &&
          ["p3", "p4", "p5"].includes(currentCard?.provider) &&
          when(
            typeof currentCard?.balance === "object",
            +((currentCard?.balance as any)?.$numberDecimal ?? 0),
            currentCard?.balance as number
          ) > 0) ||
          ((currentCard?.provider ?? "p1") === "p1" && +(balance ?? 0) > 0)),
      fund: currentCard?.cardVersion === "v3" && ["p3", "p4", "p5"].includes(currentCard?.provider) && !currentCard.disabled,
      freeze:
        currentCard?.cardVersion === "v3" &&
        // ["p2", "p3", "p4", "p5"].includes(currentCard?.provider) &&
        !isFreezeDisabled &&
        ["frozen", "active"].includes(currentCard?.status || ""),
      liquidate: shouldLiquidate && !currentCard?.defunded,
    };
  }, [currentCard, balance, isFreezeDisabled]);

  // console.log("Action to that can be perform", canPerformAction, {
  //   version: currentCard?.cardVersion,
  //   provider: currentCard?.provider,
  //   status: currentCard?.status,
  // });

  // console.log("Card Balance: ", balance);

  //   const card = useMemo(() => (!!data?.card ? (data.card as CardRo) : undefined), [data]);

  const tabMap = {
    details: 0,
    transactions: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", Object.keys(tabMap)[index]);
    // navigate(`/wallet?${searchParams.toString()}`);
  };

  const handleFund = () => {
    if (isFundingDisabled) open("featureUnavailable")();
    else if (!canPerformAction.fund) open("unsupportedFund")();
    else setCurrentView(1);
  };

  const handleWithdraw = () => {
    if (isWithdrawalDisabled) open("featureUnavailable")();
    else setCurrentView(2);
  };

  const handleLiquidation = () => {
    liquidateCard({ id: params?.cardId });
  };

  const isLiquidationOpen = useRef(false);

  useUpdateEffect(() => {
    if (isLiqSuccess && liqData && !isLiquidationOpen.current) {
      open("liquidated")();
      isLiquidationOpen.current = true;
    }
  }, [isLiqSuccess, liqData, open, isLiquidationOpen]);

  console.log("Card Data from cards list", card);

  const handleLiquidationSuccessClose = () => {
    close("liquidated")();
    onClose();
  };

  return (
    <Box id="virtual-card-details-wrapper" px="40px" {...props} overflowY="scroll">
      <TitleBar>
        <Title isLoading={isGettingAllCards} fontSize="20px">
          Cards
        </Title>

        <TitleBarActions py="4px">
          <Button size="xs" onClick={open("requestStatement")} display="none">
            Request Statement
          </Button>
        </TitleBarActions>
      </TitleBar>

      <VStack py="42px">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`credit-card-${when((isPinOpen || isLoading) && !!!card, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98, rotate: "-4deg" }}
            animate={{ opacity: 1, scale: 1, rotate: "0deg" }}
            exit={{ opacity: 0, scale: 0.98, rotate: "4deg" }}
          >
            {(isPinOpen || isLoading) && !!!card && <PageLoading isLoading={true} />}
            {!(isPinOpen || isLoading) && !!card && (
              <VStack>
                <CreditCard
                  type={(currentCard as any)?.cardType?.type ?? "black"}
                  name={card?.nameOnCard}
                  validity={card?.expiry}
                  number={card?.cardNumber ?? card?.cardPan}
                  cvv={card?.cvv2}
                  balance={balance}
                  brand={card?.brand}
                  is_obfuscated={is_obfuscated}
                />

                <Box my={{ base: "10px !important", md: "16px !important" }}>
                  {currentCard?.label && (
                    <Text textAlign="center" fontFamily="var(--bitmama-fonts-heading)" fontSize={{ base: "14px", md: "16px" }}>
                      {currentCard?.label}
                    </Text>
                  )}
                </Box>

                <HStack my="0px !important">
                  {/* Fund is placed at the 1 index of the steps,
                    Withdraw is placed at the 2 index of the steps */}
                  {card && canPerformAction.withdraw && (
                    <Button variant="outline" size="sm" minW="110px" {...outline} onClick={handleWithdraw}>
                      Withdraw
                    </Button>
                  )}
                  {card && canPerformAction.liquidate && (
                    <Button
                      variant="outline"
                      size="sm"
                      minW="110px"
                      {...outline}
                      isLoading={isLiquidating}
                      onClick={open("confirm")}
                    >
                      Liquidate
                    </Button>
                  )}
                  {card && canPerformAction.fund && (
                    <Button size="sm" minW="110px" {...solid} onClick={handleFund}>
                      Fund
                    </Button>
                  )}
                </HStack>
              </VStack>
            )}
          </motion.div>
        </AnimatePresence>
      </VStack>

      <Divider />

      <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange} mt="16px !important">
        <TabList borderRadius="8px">
          <Tab>Details</Tab>
          <Tab>Transactions</Tab>
        </TabList>
        <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
          <TabPanel px={["2", "0", "0", "1"]}>
            <DetailsPanel
              isLoading={isLoading}
              card={card!}
              currentCard={currentCard as any}
              canFreeze={!!card && canPerformAction.freeze}
              refetch={() => refetch()}
              is_obfuscated={is_obfuscated}
              obfuscate={obfuscate}
            />
          </TabPanel>
          <TabPanel px={["0", "0", "0", "1"]}>
            <TransactionsPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <SubmitPinModal
        heading={"Enter pin to access card"}
        subheading={"Enter your 6-digits transaction pin to authorize access this card"}
        isOpen={isPinOpen}
        onClose={() => {
          onPinClose();
          onClose();
        }} // onPin={(pin) => set({ pin })}
        onSubmit={(pin) => {
          set({ pin });
          onPinClose();
        }}
        onComplete={(pin) => {
          set({ pin });
          onPinClose();
        }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        closeOnSubmit={false}
      />

      <ConfirmationModal isOpen={isOpen("confirm")} onClose={close("confirm")} onConfirm={handleLiquidation}>
        <VStack py="22px">
          <Text fontWeight="500" textAlign="center">
            Please confirm liquidation of all funds on card to be moved into your USDT wallet.
          </Text>
        </VStack>
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isOpen("unsupportedFund")}
        onClose={close("unsupportedFund")}
        onConfirm={() => {
          onClose();
          navigate("/cards/request");
        }}
        labels={["Create Card", "Close"]}
      >
        <VStack py="22px">
          <Text fontWeight="500">Funding for old cards is disabled. Please create a new card</Text>
        </VStack>
      </ConfirmationModal>

      <SuccessModal
        isOpen={isOpen("liquidated")}
        onClose={handleLiquidationSuccessClose}
        onContinue={handleLiquidationSuccessClose}
        message={"Card successfully liquidated"}
        closeOnContinue
      />

      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
      <RequestCardStatment isOpen={isOpen("requestStatement")} onClose={close("requestStatement")} />
    </Box>
  );
}

type ModalTypes = "confirm" | "success";

function DetailsPanel(props: DetailsPanelProps) {
  const { card, isLoading, canFreeze, refetch, currentCard, is_obfuscated, obfuscate } = props;

  const actionRef = useRef("");
  const hasFreeze = useRef(false);
  const color = useColor();

  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const card_status = useMemo(() => {
    const status = { ...card, ...currentCard }?.status;
    return status;
  }, [card, currentCard]);

  const makeSpacesInFour = (value: string) =>
    chunk(value, 4)
      .map((v) => v.join(""))
      .join(" ");

  const isFrozen = useMemo(
    () => !!card && !!currentCard && ["frozen", "inactive"].includes(card_status),
    [card_status, card, currentCard]
  );

  const [freezeCard, { isLoading: isFreezingCard /*, isSuccess: isFreezeSuccess, reset: rFreeze */ }] = useFreezeUserCardMutation();
  const [unfreezeCard, { isLoading: isUnFreezingCard /*, isSuccess: isUnFreezeSuccess, reset: rUnfreeze */ }] =
    useUnfreezeUserCardMutation();

  const toggleMessage = when(
    isFrozen,
    "Are you sure you want to unfreeze this card?",
    "Are you sure you want to freeze this card?"
  );

  const successMessage = when(
    actionRef.current === "freeze",
    "Card has been successfully freezed",
    "Card has been successfully unfreezed"
  );

  const toggleFreeze = async () => {
    let result = {};

    if (isFrozen) {
      actionRef.current = "unfreeze";
      result = await unfreezeCard(card?.serviceCardId);
    } else {
      actionRef.current = "freeze";
      result = await freezeCard(card?.serviceCardId);
    }

    console.clear();
    console.log("Mutation Result", result);

    !!result && (await refetch());

    hasFreeze.current = false;
  };

  // useUpdateEffect(() => {
  //   (async () => {
  //     if ((isFreezeSuccess || isUnFreezeSuccess) && !hasFreeze.current) {
  //       await refetch();
  //       // if(result) open("success")();
  //       if (actionRef.current === "freeze") rFreeze();
  //       else rUnfreeze();
  //       hasFreeze.current = true;
  //     }
  //   })();
  // }, [isFreezeSuccess, isUnFreezeSuccess, refetch, rFreeze, rUnfreeze, hasFreeze]);

  const { isOpen: labelisOpen, onOpen: labelOnOpen, onClose: labelOnclose } = useDisclosure();

  return (
    <Stack mt="42px">
      <FormControl display="flex" alignItems="center" justifyContent="space-between">
        <InputLabel color="grey.400" fontSize="14px" fontWeight="600" htmlFor="toggle-freeze" mb="0">
          Hide Details
        </InputLabel>
        <Switch
          size="sm"
          colorScheme="primary"
          id="toggle-obfuscate"
          isChecked={is_obfuscated}
          onChange={(e) => !!obfuscate && obfuscate(e.target.checked)}
          // disabled={isUnFreezingCard || isFreezingCard}
        />
      </FormControl>

      <DetailItem isLoading={isLoading} title="Card Label" description={currentCard?.label ? currentCard?.label : "N/A"} isEditable>
        <AddEditCardLabel
          isOpen={labelisOpen}
          onClose={labelOnclose}
          onContinue={() => {}}
          cardId={card?.serviceCardId}
          onOpen={labelOnOpen}
          editCard
          label={currentCard?.label ?? ""}
        />
      </DetailItem>

      {/* <ConditionalRender shouldRender={!["inactive", "frozen"].includes(card_status)}> */}
      {card?.nameOnCard && (
        <DetailItem isLoading={isLoading} title="Card Name" description={card?.nameOnCard} obscure={is_obfuscated} />
      )}
      {(card?.cardNumber || card?.cardPan) && (
        <DetailItem
          isCopyable
          isLoading={isLoading}
          title="Card Number"
          obscure={is_obfuscated}
          description={makeSpacesInFour(card?.cardNumber ?? card?.cardPan)}
          obscureFunc={(desc) => {
            const last4 = desc!.slice(-4);
            const value = String("XXXX").repeat(3).concat(last4);
            return makeSpacesInFour(value);
          }}
        />
      )}
      <DetailItem isCopyable isLoading={isLoading} title="CVV" obscure={is_obfuscated} description={card?.cvv2} />
      {!!card?.pin && card?.pin?.length > 0 && (
        <DetailItem isLoading={isLoading} title="Card Pin" description={card?.pin} obscure={is_obfuscated} />
      )}
      <DetailItem isLoading={isLoading} title="Card Validity" description={card?.expiry} obscure={is_obfuscated} />

      {(!!card?.address?.address || card?.address?.street) && (
        <DetailItem
          isCopyable
          isLoading={isLoading}
          title="Address"
          description={card?.address?.street ?? card?.address?.address}
        />
      )}
      <DetailItem isLoading={isLoading} title="Country" description={card?.address?.country} />
      <DetailItem isCopyable isLoading={isLoading} title="Postal Code" description={card?.address?.postalCode} />
      <DetailItem isCopyable isLoading={isLoading} title="State" description={card?.address?.state} />
      <DetailItem isCopyable isLoading={isLoading} title="City" description={card?.address?.city} />
      {/* </ConditionalRender> */}

      {!!card?.status && (
        <DetailItem
          isLoading={isLoading}
          title="Card Status"
          description={capitalize(card_status)}
          _description={{
            color: when(["inactive", "frozen"].includes(card_status), "error", color("primary.default", "secondary.500")),
          }}
        />
      )}

      {canFreeze && (
        <HStack py="32px">
          <FormControl display="flex" alignItems="center" justifyContent="space-between">
            <InputLabel
              isLoading={isUnFreezingCard || isFreezingCard}
              color="grey.400"
              fontSize="14px"
              fontWeight="600"
              htmlFor="toggle-freeze"
              mb="0"
            >
              {!isFrozen ? "Freeze" : "Unfreeze"} card
            </InputLabel>
            <Switch
              size="sm"
              colorScheme="primary"
              id="toggle-freeze"
              isChecked={isFrozen}
              onChange={open("confirm")}
              disabled={isUnFreezingCard || isFreezingCard}
            />
          </FormControl>
        </HStack>
      )}

      <ConfirmationModal isOpen={isOpen("confirm")} onClose={close("confirm")} onConfirm={toggleFreeze}>
        <VStack py="20px">
          <Text fontWeight="500">{toggleMessage}</Text>
        </VStack>
      </ConfirmationModal>

      <SuccessModal isOpen={isOpen("success")} onClose={close("success")} message={successMessage} closeOnContinue />
    </Stack>
  );
}

function TransactionsPanel(props: TransactionsPanelProps) {
  //   const { coin } = props;

  const { params } = useSidePage();
  const bg = useColorModeValue("rgba(242, 244, 243, 0.5)", "transparent");

  const [queries, set] = usePartialState({
    page: 1,
    limit: 5,
    sortBy: "createdAt:desc",
    // status: filter === "all" ? null : filter,
  });

  const { data, isLoading } = useGetUserCardTransactionsQuery(toQueryString({ ...queries, cardId: params?.cardId }), {
    skip: !params?.cardId,
  });

  console.log("Card Transactions", data, params);

  const totalCount = useMemo(() => data?.totalCount ?? 0, [data]);
  const transactions = useMemo(() => data?.transactions ?? [], [data]);
  const passedLimit = useMemo(() => totalCount > (queries.limit ?? 0), [totalCount, queries.limit]);

  // const totalCount = useMemo(() => data?.totalCount, [data]);

  console.log("Earnings Data", transactions);

  // const handlePageChange = (page: number) => {
  //   set({ page });
  // };

  return (
    <Group mt="0" w="100%">
      <Card w="100%" p="8px" overflow="hidden" bg={bg}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {!isLoading && (
              <Stack>
                {(transactions ?? []).map((tx, id) => (
                  <CreditCardTransactionItem
                    mt="0 !important"
                    w="100%"
                    key={`transaction-${id}`}
                    currency={"usd"}
                    status={tx?.type as any}
                    value={tx?.value}
                    createdAt={tx?.createdAt}
                    description={tx?.description}
                  />
                ))}
              </Stack>
            )}
            {!isLoading && isEmpty(transactions) && <EmptyCrate type="transaction" />}
          </motion.div>
        </AnimatePresence>
      </Card>

      <VStack>
        {passedLimit && (
          <Box w="100%">
            <Paginator
              limit={queries.limit!}
              onPageChange={(page: number) => set({ page })}
              page={queries.page!}
              totalCount={totalCount ?? 0}
            />
          </Box>
        )}
      </VStack>
    </Group>
  );
}

function useDetailsStyles() {
  const { colorMode } = useColorMode();

  const { outline, solid } = switchStyle<{ outline: ButtonProps; solid: ButtonProps }>(colorMode, {
    dark: {
      outline: {
        bg: "transparent",
        borderColor: "white",
        color: "white",
        _hover: { borderColor: "white" },
      },
      solid: {
        bg: "white",
        borderColor: "transparent",
        color: "black",
        _hover: { borderColor: "transparent" },
      },
    },
    light: {
      outline: {
        bg: "transparent",
        borderColor: "black",
        color: "black",
        _hover: { borderColor: "black" },
      },
      solid: {
        bg: "black",
        borderColor: "transparent",
        color: "white",
        _hover: { borderColor: "transparent" },
      },
    },
  });

  return { outline, solid };
}
