import {
  //   NumberInput,
  //   NumberInputField,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
  InputGroupProps,
  InputElementProps,
  Button,
  // NumberInputProps,
} from "@chakra-ui/react";
import CoinIcon from "components/Icon/CoinIcon";
import { SupportedCoinType } from "hooks";
import { when } from "utils";

interface AmountInputProps extends InputProps {
  _group?: InputGroupProps;
  _leftEl?: InputElementProps;
  _rightEl?: InputElementProps;
  onMax?: () => void;
  coin?: SupportedCoinType;
  isMaxDisabled?: boolean;
}

export default function AmountInput(props: AmountInputProps) {
  const { coin, onMax, isMaxDisabled = false, _leftEl, _rightEl, _group, ...xprops } = props;

  const showMax = when(!!onMax, true, false);
  const pr = when(!!onMax, "66px", "48px");
  const pl = when(!!coin, "48px", "20px");

  return (
    <InputGroup>
      {coin && <InputLeftElement h="100%" left="0px" children={<CoinIcon coin={coin} boxSize="24px" />} {..._leftEl} />}

      <Input placeholder="0.00" min={0} pl={pl} pr={pr} w="100%" type="number" step="any" {...xprops} />

      {showMax && (
        <InputRightElement
          h="100%"
          w="fit-content"
          right="18px"
          children={
            <Button w="48px" variant="max" onClick={onMax} disabled={isMaxDisabled}>
              Max
            </Button>
          }
          {..._rightEl}
        />
      )}
    </InputGroup>
  );
}
