import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const input: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "15px",
  },
  sizes: {
    base: {
      fontSize: "sm",
      px: 6,
      py: 3,
      minH: "40px",
    },
    sm: {
      fontSize: "sm",
      px: 6,
      py: 3,
      minH: "40px",
    },
    md: {
      minH: "60px",
      minW: "400px",
      fontSize: "md",
      px: 10,
      py: 4,
    },
    xl: {
      fontSize: "md",
      minH: "54px",
      px: "38px",
      py: "10px",
    },
  },
  variants: {
    filled: (props) => ({
      field: {
        bg: mode("#f2f2f2", "dark.cardBg")(props),
        borderColor: mode("#A1A3A2", "white")(props),
        borderWidth: "1px",
        px: "20px",
        minH: "60px",
        borderRadius: "10px",
        color: mode("#303131", "white")(props),
        // fontWeight: ''
        fontFamily: "var(--bitmama-fonts-heading)",
        _placeholder: {
          color: "#A1A3A2",
          fontFamily: "var(--bitmama-fonts-body)",
        },
        _focus: {
          borderColor: mode("#0D4740", "secondary.400")(props),
          borderWidth: "1px",
          bg: mode("#f2f2f2", "dark.cardBg")(props),
          // shadow: mode("outline", "darkOutline")(props),
          shadow: "none",
        },
        _valid: {
          borderColor: mode("blue", "secondary.400")(props),
          borderWidth: "1px",
          bg: mode("#f2f2f2", "dark.cardBg")(props),
          // shadow: mode("outline", "darkOutline")(props),
          shadow: "none",
        },
        _hover: {
          borderColor: mode("primary.600", "secondary.600")(props),
          borderWidth: "1px",
          bg: mode("#f6f6f6", "dark.cardBg")(props),
          // bg: mode("outline", "darkOutline")(props),
        },
        _active: {
          borderColor: mode("#0D4740", "secondary.400")(props),
          borderWidth: "1px",
          bg: mode("#f2f2f2", "dark.cardBg")(props),
          shadow: "none",
        },
      },
      element: {
        h: "100%",
        w: 14,
        color: "#A1A3A2",
      },
    }),
  },

  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "filled",
  } as any,
};

export default input;
