import { Box, BoxProps, HStack, IconButton, Text } from "@chakra-ui/react";
import { EarnTopRightPatternSVG } from "assets";
import { isFiat, remap, toCoinLocale, toPrecision, when } from "utils";
import { WalletActionsButton, WalletActionsButtonProps } from "./Wallet";

import CircularLoader from "../Loader/CircularLoader";
import CoinIcon from "components/Icon/CoinIcon";
import { useCoin } from "hooks";
import { useMemo } from "react";
import configs from "config";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
// import { useDefaultStyle } from "hooks";

interface AssetWalletProps extends Omit<BoxProps, "onChange"> {
  coin?: string;
  isLoading?: boolean;
  value?: string | number;
  onChange?: (coin: string) => void;
  onFund?: () => void;
  onWithdraw?: () => void;
  _fund?: WalletActionsButtonProps;
  _withdraw?: WalletActionsButtonProps;
  canBeHidden?: boolean;
  isBalanceHidden?: boolean;
  toggleHideBalance?: () => void;
}

export function AssetWallet(props: AssetWalletProps) {
  const {
    coin = "btc",
    value = 0.000089,
    onChange,
    onFund,
    onWithdraw,
    canBeHidden = false,
    isLoading,
    _fund,
    _withdraw,
    isBalanceHidden = false,
    toggleHideBalance,
    ...xprops
  } = props;

  //   const { shadow, borderColor } = useDefaultStyle();
  const { label } = useCoin(coin as any);

  const balance = useMemo(() => {
    // if (true) return Number(Number(155832585168234.211).toFixed(2)).toLocaleString();
    if (!!isBalanceHidden) return `******`;
    if (!isFiat(coin)) return toPrecision(+value, coin, false);
    else if (configs.stableCoins.includes(coin)) return Number(Number(value).toFixed(2)).toLocaleString();
    else return Number(Number(value).toFixed(2)).toLocaleString();
  }, [value, coin, isBalanceHidden]);

  const balance_styles = useMemo(() => {
    const len = String(balance).length;
    // console.log("FontSize", len);
    if (len <= 12)
      return {
        // w: `100%`,
        fontSize: "3rem", // 5xl
        sfontSize: "1.5rem", //2xl
      };
    if (len >= 20)
      return {
        w: `50%`,
        fontSize: "3rem", // 5xl
        sfontSize: "1.5rem", //2xl
      };
    return {
      // w: "100%",
      fontSize: `${remap(len, 1, 100, 2.2, 3)}rem`,
      sfontSize: `${remap(len, 10, 30, 1.1, 1.5)}rem`,
    };
  }, [balance]);

  const bg = useMemo(() => {
    const map: Record<string, string> = {
      [coin]: "primary.700",
      btc: "primary.800",
      tbtc: "primary.800",
      usdt: "#9FD483",
      usd: "#9FD483",
      cusd: "#9FD483",
      usdc: "#9FD483",
      eth: "#E8C229",
      teth: "#E8C229",
    };

    return map[coin];
  }, [coin]);

  return (
    <Box
      bg={bg}
      borderRadius="16px"
      p="22px 20px"
      maxH="230px"
      pos="relative"
      overflow="hidden"
      transition="all .3s ease-in-out"
      {...xprops}
    >
      <HStack w="100%" justifyContent="space-between">
        <Box>
          <HStack>
            <CoinIcon coin={coin as any} boxSize="40px" />
            <Text color="#E2F2DA" fontSize="lg" fontWeight="500" fontFamily="var(--bitmama-fonts-heading)">
              {label}
            </Text>

            {/* {isLoading && <CircularLoader color="accent.800" />} */}
          </HStack>

          <HStack alignItems="baseline">
            <Text
              color="white"
              // fontSize="5xl"
              w={balance_styles.w}
              fontSize={balance_styles.fontSize}
              fontWeight="600"
              fontFamily="var(--bitmama-fonts-heading)"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              // maxW="70%"
              title={String(balance)}
              // {...balance_styles}
            >
              {/* {currencyFormat("usd").format(+value)} */}
              {balance}
            </Text>
            <Text color="white" ml="8px" fontSize={balance_styles.sfontSize} fontWeight="500">
              {toCoinLocale(coin)}
            </Text>
            <ConditionalRender shouldRender={canBeHidden}>
              <IconButton
                mt={when(!!isBalanceHidden, "0px !important", "0px !important")}
                aria-label="Hide Value"
                h="fit-content"
                w="fit-content"
                minH="unset"
                minW="unset"
                bg="transparent"
                _hover={{ bg: "transparent" }}
                icon={when(!isBalanceHidden, <ViewOffIcon />, <ViewIcon />)}
                onClick={toggleHideBalance}
              />
            </ConditionalRender>
          </HStack>
        </Box>
      </HStack>

      <Box as={EarnTopRightPatternSVG} pos="absolute" bottom="7px" left="10px" transform=" rotate(180deg) scale(1.3)" />
      <Box as={EarnTopRightPatternSVG} pos="absolute" top="7px" right="10px" transform="scale(1.3)" />
      {isLoading && <CircularLoader pos="absolute" top="21.5px" right="63.5px" color="white" />}

      <HStack mt="20px !important" justifyContent="center">
        <ActionButton variant="outline" onClick={onWithdraw} {..._withdraw}>
          Withdraw
        </ActionButton>
        <ActionButton variant="solid" onClick={onFund} {..._fund}>
          Fund
        </ActionButton>
      </HStack>
    </Box>
  );
}

interface ActionButtonProps extends WalletActionsButtonProps {}

function ActionButton(props: ActionButtonProps) {
  const { children, ...xprops } = props;
  return (
    <WalletActionsButton scheme="greenBox" minW="170px" minH="56px" fontSize="16px" {...xprops}>
      {children}
    </WalletActionsButton>
  );
}
