import React from "react";
import { render } from "react-dom";
// import { createRoot } from "react-dom/client";
import "styles/index.scss";

// import "styles/radix.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Providers from "./Providers";

import { Buffer } from "buffer";
import configs, { isProd } from "config";
(window as any).Buffer = Buffer;

// const root = createRoot(document.getElementById("root") as HTMLElement);

(window as any).log = console.log;
// Disable console logs in production.
isProd && configs.ENABLE_LOGS === "false" && (console.log = () => {});

render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,

  document.getElementById("root") as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
