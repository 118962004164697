import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// import { logger } from "redux-logger";
// import { hideDevLogs, isProd } from "config";
import errorHandler from "./middlewares/errorHandler";
import { ErrorReducer, AuthReducer, UserReducer, TradeReducer } from "./slices";
import {
  bankApi,
  walletApi,
  swapApi,
  profileApi,
  billsApi,
  cardsApi,
  notificationsApi,
  rateApi,
  p2pApi,
  chartApi,
  greenboxApi,
  gamificationApi,
} from "apis";
import accountApi from "apis/accountApi";
import FilterReducer from "./slices/filter";

// const devMiddlewares = isProd ? [] : [logger];

const store = configureStore({
  reducer: {
    // profile: ProfileReducer,
    trade: TradeReducer,
    [swapApi.reducerPath]: swapApi.reducer,
    [rateApi.reducerPath]: rateApi.reducer,
    [bankApi.reducerPath]: bankApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [chartApi.reducerPath]: chartApi.reducer,
    [p2pApi.reducerPath]: p2pApi.reducer,
    [billsApi.reducerPath]: billsApi.reducer,
    [cardsApi.reducerPath]: cardsApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [greenboxApi.reducerPath]: greenboxApi.reducer,
    // [arbitraryApi.reducerPath]: arbitraryApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [gamificationApi.reducerPath]: gamificationApi.reducer,
    // accountVerification: AccountVerificationReducer,
    auth: AuthReducer,
    user: UserReducer,
    error: ErrorReducer,
    // notifications: NotificationReducer,
    filter: FilterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      // ...devMiddlewares,
      errorHandler,
      bankApi.middleware,
      accountApi.middleware,
      chartApi.middleware,
      p2pApi.middleware,
      rateApi.middleware,
      swapApi.middleware,
      billsApi.middleware,
      cardsApi.middleware,
      walletApi.middleware,
      profileApi.middleware,
      greenboxApi.middleware,
      notificationsApi.middleware,
      gamificationApi.middleware,
      //   arbitraryApi.middleware,
    ]),
  // devTools: !isProd && !hideDevLogs,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useDispatch: AppDispatch = store.dispatch;
export default store;
