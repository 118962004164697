import { Box, BoxProps, Flex, useColorModeValue } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import Icon from "components/Icon/Icon";
import { ActiveHomeIcon, ActiveWalletIcon, ActiveSwapIcon, ActiveAccountIcon } from "components/Sidebar/Sidebar";
import { useSidePage } from "contexts";
import { FC, useCallback } from "react";
import BottomLink from "./BottomLink";

interface IMobileNavbarProps extends BoxProps {
  is1024px: boolean;
}

const HomeIcon = () => <Icon type="home" color="gray.default" />;
const WalletIcon = () => <Icon type="wallet" color="gray.default" />;
// const P2pIcon = () => <Icon type="p2p" color="gray.default" />;
const SwapIcon = () => <Icon type="swap" color="gray.default" />;
const AccountIcon = () => <Icon type="account" color="gray.default" />;

const MobileNavbar: FC<IMobileNavbarProps> = ({ is1024px }) => {
  const { onOpen } = useSidePage();

  const bg = useColorModeValue("#F9FAF9", "#1e1e1e");

  const pageLinks = [
    {
      activeIcon: ActiveHomeIcon,
      icon: HomeIcon,
      label: "Home",
      to: "/home",
    },
    {
      activeIcon: ActiveWalletIcon,
      icon: WalletIcon,
      label: "Wallet",
      to: "/wallet",
    },
    // {
    //   activeIcon: ActiveP2pIcon,
    //   icon: P2pIcon,
    //   label: "P2P",
    //   to: "/p2p",
    // },
    {
      activeIcon: ActiveSwapIcon,
      icon: SwapIcon,
      label: "Swap",
      to: "#",
      onClick: () => onOpen({ key: "Swap" }),
    },

    {
      activeIcon: ActiveAccountIcon,
      icon: AccountIcon,
      label: "Account",
      to: "/account",
    },
  ];

  const location = useLocation();

  const isCurrent = useCallback(
    (path: string) => {
      const pathname = location?.pathname.match(/(\w)+/g),
        name = path.match(/(\w)+/g);

      return ((name && pathname && pathname[0]?.includes(name[0])) || location?.pathname === path) ?? false;
    },
    [location]
  );

  return (
    <Box pos="relative">
      <Box
        overflow="hidden"
        bottom="0"
        pos="fixed"
        padding={{ base: "0 24px", "1sm": "0 42px" }}
        height="80px"
        w="100%"
        bg={bg}
        display={is1024px ? "block" : "none"}
        zIndex="999"
      >
        <Flex justifyContent="space-between" height="80px" alignItems="center" gap="32px">
          {pageLinks.map((link, i) => (
            <BottomLink key={i} {...link} isCurrent={isCurrent(link.to)} />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default MobileNavbar;
