import { Box, BoxProps, Grid, Text, VStack, useDisclosure, useToast } from "@chakra-ui/react";
import { useClaimRewardMutation, useGetRewardsQuery } from "apis";
import { AchievementsLayout, ConditionalRender, EmptyCrate, PageLoading, RewardInfo, Title, TitleBar } from "components";
import { useMemo, useState } from "react";
import { ConfirmationModal } from "ui";

interface AchievementsRewardsPanelProps extends BoxProps {}

export function AchievementsRewardsPanel(props: AchievementsRewardsPanelProps) {
  const toast = useToast();
  const { data, isLoading } = useGetRewardsQuery();
  const [claimReward, { isLoading: isClaiming }] = useClaimRewardMutation();

  const [selected_id, setSelected] = useState<string>("none");
  const { isOpen, onClose, onOpen } = useDisclosure();

  const rewards = useMemo(() => (Array.isArray(data) ? data : data?.data ?? []), [data]);
  console.log("Rewards", { data, rewards });

  const handleClaim = (id: string) => {
    if (!id) return;
    setSelected(id);
    onOpen();
  };

  const confirmClaim = async () => {
    if (!selected_id || selected_id === "none") return;

    const result = await claimReward(selected_id).unwrap();
    if (!!result) {
      toast({
        title: "Reward Claimed",
        description: "You've successfully claimed a reward",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  return (
    <AchievementsLayout px={{ sm: "0px", "2sm": "20px", md: "20px" }}>
      <TitleBar mb="58px">
        <Title _text={{ fontWeight: "700" }}>All Rewards</Title>
      </TitleBar>

      <Grid
        templateColumns={{ sm: "1fr", "2sm": "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
        gridRowGap={{ sm: "20px", "2sm": "20px", md: "43px" }}
        gridColumnGap={{ "2sm": "20px", md: "20px" }}
      >
        <ConditionalRender shouldRender={!isLoading && (rewards ?? []).length > 0}>
          {rewards.map((r, i) => (
            <RewardInfo
              key={`reward-${i}`}
              {...r}
              onClaimClick={() => handleClaim(r?._id)}
              isClaiming={isClaiming && r?._id === selected_id}
            />
          ))}
        </ConditionalRender>
      </Grid>

      {!!isLoading && (rewards ?? []).length < 1 && <PageLoading isLoading={true} />}

      {!isLoading && (rewards ?? []).length < 1 && <EmptyCrate type="achievement" />}

      <ConfirmationModal title="Claim Reward" isOpen={isOpen} onClose={onClose} onConfirm={confirmClaim}>
        <VStack py="20px">
          <Text fontWeight="500" fontSize="lg">
            Please confirm to claim the reward
          </Text>
        </VStack>
      </ConfirmationModal>

      <Box h="160px" display={{ sm: "block", md: "none" }} />
    </AchievementsLayout>
  );
}
