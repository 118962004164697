import toLower from "lodash/toLower";
import configs from "config";

// TODO: Iterate over the available fiats array and filter out countries
// with their currencies unavailable.

export default function selectCountryCurrency(countryName: string, defaultIfNone = "ngn") {
  const map: Record<string, string> = configs.maps.countryToCurrency;
  const keys = Object.keys(map);
  if (!keys.includes(toLower(countryName))) return defaultIfNone;
  return map[toLower(countryName)];
}
