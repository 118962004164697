import { Box, Button, FormControl, Heading, Input, Text, VStack } from "@chakra-ui/react";
import { useClaimGreenboxMutation } from "apis";
import { RocketSVG } from "assets";
import { AbstractModal, Icon, InputLabel, Title, TitleBar, useAbstractModal } from "components";
import { usePartialState } from "hooks";
import { AppAnalyticEvent } from "interfaces";
import { Emitter, transformGreenboxClaimDataToEngagement } from "libs";
import { useMemo } from "react";

interface ClaimGreenBoxModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IState {
  code: string;
  success: boolean;
}

export default function ClaimGreenBoxModal(props: ClaimGreenBoxModalProps) {
  const { isOpen, onClose } = props;

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "600px",
        borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        position: { base: "absolute", "1sm": "initial" },
        padding: "0",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
      }}
    >
      <Form {...props} />
      <SuccessView {...props} />
    </AbstractModal>
  );
}

function Form(props: any) {
  const { onClose } = props;

  const { updateModal } = useAbstractModal();
  const [state, set, reset] = usePartialState<IState>({});

  const [claim, { isLoading }] = useClaimGreenboxMutation();

  const isDisabled = useMemo(() => !state?.code || isLoading, [state, isLoading]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // setTimeout(() => {
    //   updateModal &&
    //     updateModal({
    //       isSuccess: true,
    //       success: {
    //         description: `You have received a Greenbox`,
    //       },
    //     });
    // }, 5000);

    if (!state?.code) return;

    Emitter.emit(AppAnalyticEvent.CLAIMED_GREENBOX_INITIATED, transformGreenboxClaimDataToEngagement(state?.code));
    const result = await claim(state?.code).unwrap();
    if (!!result) {
      console.log("Claim Result", result);
      Emitter.emit(AppAnalyticEvent.CLAIMED_GREENBOX_SUBMITTED, transformGreenboxClaimDataToEngagement(state?.code));

      updateModal &&
        updateModal({
          isSuccess: true,
          success: {
            description: `You have received a Greenbox`,
          },
        });

      reset();
    }
  };

  // useEffect(() => {
  //   return () => {
  //     reset();
  //   };
  // }, [reset]);

  return (
    <VStack as="form" p="48px 68px" onSubmit={handleSubmit}>
      <TitleBar>
        <Title fontSize="24px" fontFamily="var(--bitmama-fonts-heading)">
          Claim Greenbox
        </Title>
      </TitleBar>

      <FormControl my="52px !important">
        <InputLabel>Input Greenbox Code</InputLabel>
        <Input
          disabled={isLoading}
          placeholder="Paste Greenbox Code"
          value={state?.code ?? ""}
          onChange={(e) => set({ code: e.target.value })}
        />
      </FormControl>

      <VStack>
        <Button
          type="submit"
          isLoading={isLoading}
          disabled={isDisabled}
          rightIcon={<Icon type="claimButtonIcon" color="grey.50" />}
        >
          Claim
        </Button>
        <Button variant="outline" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function SuccessView(props: ClaimGreenBoxModalProps) {
  const { onClose } = useAbstractModal();

  return (
    <VStack p={{ base: "20px 24px", "2sm": "48px" }}>
      <Box mb="24px !important" as={RocketSVG} />

      <Heading
        mt={{ base: "0 !important", "2sm": "initial" }}
        as="h4"
        fontSize="24px"
        fontWeight="600"
        mb={{ base: "20px !important", "2sm": "30px !important" }}
      >
        Success
      </Heading>

      <VStack mt="0 !important">
        <Text
          maxW="320px"
          mb={{ base: "20px !important", "2sm": "40px !important" }}
          color="brand.greyText"
          fontWeight="500"
          textAlign="center"
        >
          You have received a Greenbox
        </Text>
      </VStack>

      <VStack
        px={{ base: "20px", "2sm": "108px" }}
        mb={{ base: "20px !important", "2sm": "40px !important" }}
        w="100%"
        justifyContent="center"
      >
        <Button
          variant="solid"
          minW="unset"
          maxW="unset"
          w="100%"
          onClick={onClose}
          rightIcon={<Icon type="claimButtonIcon" color="grey.50" />}
        >
          View your Greenbox Wallet
        </Button>
        <Button variant="outline" minW="unset" maxW="unset" w="100%" onClick={onClose}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

SuccessView.displayName = "AbstractModalSuccessView";
