import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { AbstractModal } from "components";
import { useColor } from "hooks";
const patternUrl: string = require("../../../assets/svg/xmaspattern.svg").default;
const grouped_badges: string = require("../../../assets/svg/gamification-update-badges.svg").default;
const stars_1: string = require("../../../assets/svg/gamification-update-star-1.svg").default;
const stars_2: string = require("../../../assets/svg/gamification-update-star-2.svg").default;

interface Props extends Omit<ModalProps, "children"> {}

// @Dayo, pls fix this * (Fixed) temporarily observing the behavious.
export function GamificationUpdate(props: Props) {
  const { isOpen, onClose } = props;

  // console.log("IS SHOWN");

  const color = useColor();
  //   const { shadow } = useDefaultStyle();
  //   const [dontShow, SetDontShow] = useState(false);

  // const data = { cycle: 0 };

  const handleClose = () => {
    onClose();
    // if (!!dontShow) ls.set(configs.XMAS_DONT_SHOW, { cycle: data?.cycle });
  };

  const goTo = () => {
    handleClose();
    navigate(`/account/achievements/badges?tab=badges`);
  };

  // if (dontShow) {
  //   return null;
  // }

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "540px",
        // borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        borderRadius: "0px",
        position: { base: "absolute", "1sm": "initial" },
        padding: "20px",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
        overflow: "hidden",
        bg: `url("${patternUrl}")`,
        bgColor: "primary.default",
        backgroundSize: "cover",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <Box
        minH="570px"
        bg="linear-gradient(180deg, #0D4740 0%, #2C9084 50%, #0D4740 100%)"
        borderRadius={{ base: "30px 30px 0px 0px", "1sm": "14px" }}
      >
        <VStack p="30px" pos="relative">
          <VStack py="16px">
            <Heading
              as="h6"
              my="10px"
              fontSize="30px"
              w="285px"
              fontFamily="var(--bitmama-fonts-heading)"
              textAlign="center"
              color="white"
            >
              Get Rewarded for Your Achievements
            </Heading>

            <Box as="img" src={grouped_badges} alt="gamification update badges" w="200px" h="105px" my="32px !important" />

            <Box my="30px" maxW="68%">
              <Text fontWeight="600" fontSize="16px" color={color("white", "white")} textAlign="center">
                Complete challenges, earn badges 🥇, and claim amazing rewards & perks 🎁 by leveling up your profile!
              </Text>
            </Box>

            <Box as="img" src={stars_1} pos="absolute" top="24px" left="30px" />
            <Box as="img" src={stars_2} pos="absolute" top="24px" right="30px" />
          </VStack>

          <VStack mt={{ base: "24px !important", md: "32px !important" }} alignItems="revert-layer" width="100%" maxW="400px">
            <Text
              mb={{ base: "16px !important", md: "30px !important" }}
              fontWeight="600"
              fontSize="16px"
              color={color("white", "white")}
              textAlign="center"
            >
              Don’t miss out on the fun!
            </Text>
            <Button
              minW={{ base: "initial", md: "400px" }}
              width="100%"
              bg="white"
              color="primary.800"
              minH="74px"
              _hover={{ color: "white", bg: "primary.default" }}
              onClick={goTo}
            >
              Let's Go
            </Button>
            <Button
              minW={{ base: "initial", md: "400px" }}
              variant="outline"
              mt="16px !important"
              color="white"
              onClick={handleClose}
            >
              Close
            </Button>
          </VStack>

          {/* <VStack>
            <Checkbox
              // dir="rtl"
              //   colorScheme="primary"
              color="white"
              isChecked={dontShow}
              onChange={(e) => SetDontShow(e.target.checked)}
              sx={{
                "chakra-checkbox__control[data-focus]": {
                  shadow,
                },
              }}
            >
              Don't show again
            </Checkbox>
          </VStack> */}
        </VStack>
      </Box>
    </AbstractModal>
  );
}

// export function ChristmasUpdate(_props: Props) {
//   return null;
// }
