import { Button, Heading, ModalProps, Text, useColorMode, VStack } from "@chakra-ui/react";
import { AbstractModal, Icon } from "components";
import { switchStyle } from "utils";

interface MaxCardRequestReachProps extends Omit<ModalProps, "children"> {
  shouldConsent?: boolean;
  setAgreement?: () => void;

  description?: string;
}

export default function MaxCardRequestReach(props: MaxCardRequestReachProps) {
  const { colorMode } = useColorMode();

  return (
    <AbstractModal _content={{ maxW: "506px" }} {...props}>
      <VStack px="60px" py="74px">
        <Icon type="infoErrorRounded" boxSize="30px" />

        <VStack my="24px !important" color={switchStyle(colorMode, { dark: "white", light: "black" })}>
          <Heading as="h4" fontSize="24px" color="inherit">
            Oops!
          </Heading>
          <Text mt="20px !important" textAlign="center" fontFamily="var(--bitmama-fonts-heading)" color="inherit" lineHeight="28px">
            You've reached the maximum card creation limit
          </Text>
        </VStack>

        <Button mt="42px !important" onClick={props.onClose}>
          Close
        </Button>
      </VStack>
    </AbstractModal>
  );
}

// interface ItemProps extends ListItemProps {}

// function Item(props: ItemProps) {
//   const { children } = props;
//   return (
//     <ListItem my="14px">
//       <HStack alignItems="flex-start">
//         <Icon type="filledCards" transform="rotate(-27deg)" minW="34px" />
//         <Text fontWeight="500">{children}</Text>
//       </HStack>
//     </ListItem>
//   );
// }
