import { Box, Button, FormControl, VStack } from "@chakra-ui/react";
import { AmountInput, FormInfo, Group, InputLabel, Option, RadixSelect, useModalView } from "components";
import configs from "config";
import { SidePageViewProps, useSidePage } from "contexts";
import { useDisclosures, usePartialState, useWalletBalance } from "hooks";
import { useEffect, useMemo } from "react";
import { toCoinLocale, toPrecision } from "utils";

import toLower from "lodash/toLower";
import { SubmitPinModal, SuccessModal } from "ui";
import { useFundPlayToEarnMutation } from "apis";
import { FundOrWithdrawPlayToEarnDto } from "interfaces";
import { IPlayToEarnState } from "./PlayToEarn";

interface PlayToEarnFundProps extends SidePageViewProps {
  state: Partial<IPlayToEarnState>;
  set: (update: Partial<IPlayToEarnState>) => void;
}

export interface IState {
  coin: string;
  amount: string;
  pin: string;
}

type ModalTypes = "pin" | "success";

export default function PlayToEarnFund(props: PlayToEarnFundProps) {
  const { setCurrentView } = useModalView();

  const { data: params, updateSidePageConfigs } = useSidePage();
  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const { getBalance, isLoading } = useWalletBalance();

  const coin = useMemo(() => (params?.coin ? toLower(params?.coin) : undefined), [params]);
  const [state, set, reset] = usePartialState<IState>({ coin });

  const balance = useMemo(() => getBalance(state?.coin ?? ("usdt" as any)), [state?.coin, getBalance]);

  const [_fund, { isLoading: isFunding }] = useFundPlayToEarnMutation();

  console.log("Greenbox Fund", coin);

  // const isLessThanMinimum = useMemo(() => !!state?.amount && +state.amount < +(minimumWithdraw ?? 0), [state, minimumWithdraw]);

  const isInsufficientBalance = useMemo(() => !!state?.amount && +state?.amount > balance, [state, balance]);

  const isDisabled = useMemo(
    () => !(state?.amount && state?.coin) || isInsufficientBalance || isFunding || isLoading,
    [state, isInsufficientBalance, isFunding, isLoading]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    open("pin")();
  };

  const fundWallet = async (pin?: string) => {
    close("pin")();
    if (!(state?.amount && state?.coin)) return;
    const reqData = { ...state, amount: +state?.amount, pin: pin ?? state?.pin };
    console.log("Fund Play to Earn Wallet", reqData);

    // Emitter.emit(AppAnalyticEvent.WITHDRAWAL_GREENBOX_INITIATED, reqData);

    const result = await _fund(reqData as FundOrWithdrawPlayToEarnDto).unwrap();
    if (!!result) {
      // Emitter.emit(AppAnalyticEvent.WITHDRAWAL_GREENBOX_SUBMITTED, reqData);
      open("success")();
    }
  };

  useEffect(() => {
    updateSidePageConfigs({
      _back: {
        text: "Back",
        onClick: () => setCurrentView(0),
      },
    });

    //eslint-disable-next-line
  }, []);

  return (
    <Box id="crypto-withdraw" px="8px" {...props} overflowY="scroll">
      <Group as="form" onSubmit={handleSubmit}>
        <FormControl mb="32px !important">
          <InputLabel isLoading={isLoading} mb="10px" _text={{ fontSize: "14px" }}>
            Wallet
          </InputLabel>
          {/* <Select placeholder="Select Wallet" value={state?.coin ?? ""} onChange={(e) => set({ coin: e.target.value as any })}>
            {configs.stableCoins.map((coin) => (
              <option key={`${coin}-stable-coin`} value={coin}>
                {toCoinLocale(coin)}
              </option>
            ))}
          </Select> */}

          <RadixSelect
            use_coin_icon
            value={state?.coin}
            aria-label="Wallet"
            placeholder="Select Wallet"
            onChange={(value) => set({ coin: value })}
            _trigger={{
              w: "100%",
            }}
          >
            {configs.stableCoins.map((coin) => (
              <Option key={coin} value={coin} coin={coin as any}>
                {toCoinLocale(coin)}
              </Option>
            ))}
          </RadixSelect>

          {/* {fee && <FormInfo mt="12px" info="Fee: " description={toPrecision(fee, coin)} />} */}
        </FormControl>

        <FormControl>
          <InputLabel mb="10px" _text={{ fontSize: "14px" }}>
            Amount
          </InputLabel>
          <AmountInput
            isInvalid={isInsufficientBalance}
            placeholder="0.00"
            value={state?.amount ?? ""}
            onMax={() => set({ amount: String(Number(balance)) })}
            onChange={(e) => set({ amount: e.target.value })}
          />

          {isInsufficientBalance && (
            <FormInfo
              info=""
              mt="12px"
              isInvalid={isInsufficientBalance}
              description="Insufficient balance"
              _description={{ ml: "0 !important" }}
            />
          )}
          {!!state?.coin && (
            <FormInfo
              mt="12px"
              isInvalid={isInsufficientBalance}
              info="Available: "
              description={toPrecision(balance, state?.coin)}
            />
          )}
        </FormControl>

        <VStack mt="62px !important">
          <Button type="submit" disabled={isDisabled} isLoading={isFunding}>
            Continue
          </Button>
        </VStack>

        <SubmitPinModal
          isOpen={isOpen("pin")}
          onClose={close("pin")}
          onPin={(pin) => set({ pin })}
          onSubmit={() => fundWallet()}
          onComplete={(pin) => fundWallet(pin)}
        />
        <SuccessModal
          isOpen={isOpen("success")}
          onClose={() => {
            close("success")();
            reset();
          }}
          message="Funding successful"
          onContinue={() => setCurrentView(0)}
        />
      </Group>
    </Box>
  );
}
