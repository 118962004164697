import { addEvent } from "decorators";
import { getWebengage } from "hooks/useWebEngage";
import {
  EventTypes,
  transformBuyCryptoDataToEngagement,
  transformSellCryptoDataToEngagement,
  transformSwapCryptoDataToEngagement,
  transformCardCreationDataToEngagement,
  transformCardLabelDataToEngagement,
  transformCardFundingDataToEngagement,
  transformCardWithdrawalDataToEngagement,
  transformGreenboxCreationDataToEngagement,
  transformGreenboxFundingDataToEngagement,
  transformGreenboxWithdrawalDataToEngagement,
  transformGreenboxClaimDataToEngagement,
  transformAirtimeDataToEngagement,
  transformDataToEngagement,
  transformEarnDataToEngagement,
  transformStakingTransferDataToEngagement,
  transformP2PAdCreationDataToEngagement,
  transformP2PBuyDataToEngagement,
  transformP2PSellDataToEngagement,
  transformCardRequestStatementDataToEngagement,
} from "libs";

export class CommonEventListener {
  ///################################### BUY FEATURE #####################################
  @addEvent("Buy Initiated")
  static async OnBuyInitiated(data: EventTypes["Buy Initiated"]) {
    console.log("Buy Initiated", data);
    getWebengage()?.track("Buy Initiated", transformBuyCryptoDataToEngagement(data));
  }

  @addEvent("Buy Submitted")
  static async OnBuySubmitted(data: EventTypes["Buy Submitted"]) {
    console.log("Buy Submitted", data);
    getWebengage()?.track("Buy Submitted", transformBuyCryptoDataToEngagement(data));
  }

  ///################################### SELL FEATURE #####################################
  @addEvent("Sell Initiated")
  static async OnSellInitiated(data: EventTypes["Sell Initiated"]) {
    console.log("Sell Initiated", data);
    getWebengage()?.track("Sell Initiated", transformSellCryptoDataToEngagement(data));
  }

  @addEvent("Sell Submitted")
  static async OnSellSubmitted(data: EventTypes["Sell Submitted"]) {
    console.log("Sell Submitted", data);
    getWebengage()?.track("Sell Submitted", transformSellCryptoDataToEngagement(data));
  }

  ///################################### SWAP FEATURE #####################################
  @addEvent("Swap Initiated")
  static async OnSwapInitiated(data: EventTypes["Swap Initiated"]) {
    console.log("Swap Initiated", data);
    getWebengage()?.track("Swap Initiated", transformSwapCryptoDataToEngagement(data));
  }

  @addEvent("Swap Submitted")
  static async OnSwapSubmitted(data: EventTypes["Swap Submitted"]) {
    console.log("Swap Submitted", data);
    getWebengage()?.track("Swap Submitted", transformSwapCryptoDataToEngagement(data));
  }

  ///################################### CARD FEATURE #####################################

  @addEvent("Add Card Label")
  static async OnCardLabelAdded(data: EventTypes["Add Card Label"]) {
    console.log("Add Card Label", data);
    getWebengage()?.track("Add Card Label", transformCardLabelDataToEngagement(data));
  }

  @addEvent("Create Card Initiated")
  static async OnCreateCardInitiated(data: EventTypes["Create Card Initiated"]) {
    console.log("Create Card Initiated", data);
    getWebengage()?.track("Create Card Initiated", transformCardCreationDataToEngagement(data));
  }

  @addEvent("Create Card Submitted")
  static async OnCreateCardSubmitted(data: EventTypes["Create Card Submitted"]) {
    console.log("Create Card Submitted", data);
    getWebengage()?.track("Create Card Submitted", transformCardCreationDataToEngagement(data));
  }

  @addEvent("Card Funding Initiated")
  static async OnCardFundingInitiated(data: EventTypes["Card Funding Initiated"]) {
    console.log("Card Funding Initiated", data);
    getWebengage()?.track("Card Funding Initiated", transformCardFundingDataToEngagement(data));
  }

  @addEvent("Card Funding Submitted")
  static async OnCardFundingSubmitted(data: EventTypes["Card Funding Submitted"]) {
    console.log("Card Funding Submitted", data);
    getWebengage()?.track("Card Funding Submitted", transformCardFundingDataToEngagement(data));
  }

  @addEvent("Card Withdrawal Initiated")
  static async OnCardWithdrawalInitiated(data: EventTypes["Card Withdrawal Initiated"]) {
    console.log("Card Withdrawal Initiated", data);
    getWebengage()?.track("Card Withdrawal Initiated", transformCardWithdrawalDataToEngagement(data));
  }

  @addEvent("Card Withdrawal Submitted")
  static async OnCardWithdrawalSubmitted(data: EventTypes["Card Withdrawal Submitted"]) {
    console.log("Card Withdrawal Submitted", data);
    getWebengage()?.track("Card Withdrawal Submitted", transformCardWithdrawalDataToEngagement(data));
  }

  @addEvent("Request Card Statement")
  static async OnRequestCardStatement(data: EventTypes["Request Card Statement"]) {
    console.log("Request Card Statement", data);
    getWebengage()?.track("Request Card Statement", transformCardRequestStatementDataToEngagement(data));
  }

  ///################################### GREENBOX FEATURE #####################################

  @addEvent("Create Greenbox Initiated")
  static async OnCreateGreenboxInitiated(data: EventTypes["Create Greenbox Initiated"]) {
    console.log("Create Greenbox Initiated", data);
    getWebengage()?.track("Create Greenbox Initiated", transformGreenboxCreationDataToEngagement(data));
  }

  @addEvent("Create Greenbox Submitted")
  static async OnCreateGreenboxSubmitted(data: EventTypes["Create Greenbox Submitted"]) {
    console.log("Create Greenbox Submitted", data);
    getWebengage()?.track("Create Greenbox Submitted", transformGreenboxCreationDataToEngagement(data));
  }

  @addEvent("Fund Greenbox Initiated")
  static async OnGreenboxFundingInitiated(data: EventTypes["Fund Greenbox Initiated"]) {
    console.log("Fund Greenbox Initiated", data);
    getWebengage()?.track("Fund Greenbox Initiated", transformGreenboxFundingDataToEngagement(data));
  }

  @addEvent("Fund Greenbox Submitted")
  static async OnGreenboxFundingSubmitted(data: EventTypes["Fund Greenbox Submitted"]) {
    console.log("Fund Greenbox Submitted", data);
    getWebengage()?.track("Fund Greenbox Submitted", transformGreenboxFundingDataToEngagement(data));
  }

  @addEvent("Withdrawal Greenbox Initiated")
  static async OnGreenboxWithdrawalInitiated(data: EventTypes["Withdrawal Greenbox Initiated"]) {
    console.log("Withdrawal Greenbox Initiated", data);
    getWebengage()?.track("Withdrawal Greenbox Initiated", transformGreenboxWithdrawalDataToEngagement(data));
  }

  @addEvent("Withdrawal Greenbox Submitted")
  static async OnGreenboxWithdrawalSubmitted(data: EventTypes["Withdrawal Greenbox Submitted"]) {
    console.log("Withdrawal Greenbox Submitted", data);
    getWebengage()?.track("Withdrawal Greenbox Submitted", transformGreenboxWithdrawalDataToEngagement(data));
  }

  @addEvent("Claimed Greenbox Initiated")
  static async OnClaimGreenboxInitiated(data: EventTypes["Claimed Greenbox Initiated"]) {
    console.log("Claimed Greenbox Initiated", data);
    getWebengage()?.track("Claimed Greenbox Initiated", transformGreenboxClaimDataToEngagement(data));
  }

  @addEvent("Claimed Greenbox Submitted")
  static async OnClaimGreenboxSubmitted(data: EventTypes["Claimed Greenbox Submitted"]) {
    console.log("Claimed Greenbox Submitted", data);
    getWebengage()?.track("Claimed Greenbox Submitted", transformGreenboxClaimDataToEngagement(data));
  }

  ///################################### AIRTIME / DATA FEATURE #####################################

  @addEvent("Buy Airtime Initiated")
  static async OnBuyAirtimeInitiated(data: EventTypes["Buy Airtime Initiated"]) {
    console.log("Buy Airtime Initiated", data);
    getWebengage()?.track("Buy Airtime Initiated", transformAirtimeDataToEngagement(data));
  }

  @addEvent("Buy Airtime Submitted")
  static async OnBuyAirtimeSubmitted(data: EventTypes["Buy Airtime Submitted"]) {
    console.log("Buy Airtime Submitted", data);
    getWebengage()?.track("Buy Airtime Submitted", transformAirtimeDataToEngagement(data));
  }

  @addEvent("Buy Data Initiated")
  static async OnBuyDataInitiated(data: EventTypes["Buy Data Initiated"]) {
    console.log("Buy Data Initiated", data);
    getWebengage()?.track("Buy Data Initiated", transformDataToEngagement(data));
  }

  @addEvent("Buy Data Submitted")
  static async OnBuyDataSubmitted(data: EventTypes["Buy Data Submitted"]) {
    console.log("Buy Data Submitted", data);
    getWebengage()?.track("Buy Data Submitted", transformDataToEngagement(data));
  }

  ///################################### FIAT / CRYPTO FEATURE #####################################

  @addEvent("Funding Initiated")
  static async OnFundingInitiated(data: EventTypes["Funding Initiated"]) {
    console.log("Funding Initiated", data);
    getWebengage()?.track("Funding Initiated", data);
  }

  @addEvent("Funding Submitted")
  static async OnFundingSubmitted(data: EventTypes["Funding Submitted"]) {
    console.log("Funding Submitted", data);
    getWebengage()?.track("Funding Submitted", data);
  }

  @addEvent("Withdrawal Initiated")
  static async OnWithdrawalInitiated(data: EventTypes["Withdrawal Initiated"]) {
    console.log("Withdrawal Initiated", data);
    getWebengage()?.track("Withdrawal Initiated", data);
  }

  @addEvent("Withdrawal Submitted")
  static async OnWithdrawalSubmitted(data: EventTypes["Withdrawal Submitted"]) {
    console.log("Withdrawal Submitted", data);
    getWebengage()?.track("Withdrawal Submitted", data);
  }

  ///################################### EARN / STAKING FEATURE #####################################

  @addEvent("Earn Staking Initiated")
  static async OnEarnStakingInitiated(data: EventTypes["Earn Staking Initiated"]) {
    console.log("Earn Staking Initiated", data);
    getWebengage()?.track("Earn Staking Initiated", transformEarnDataToEngagement(data));
  }

  @addEvent("Earn Staking Submitted")
  static async OnEarnStakingSubmitted(data: EventTypes["Earn Staking Submitted"]) {
    console.log("Earn Staking Submitted", data);
    getWebengage()?.track("Earn Staking Submitted", transformEarnDataToEngagement(data));
  }

  @addEvent("Transfer Funds Staking Initiated")
  static async OnEarnTransformInitiated(data: EventTypes["Transfer Funds Staking Initiated"]) {
    console.log("Transfer Funds Staking Initiated", data);
    getWebengage()?.track("Transfer Funds Staking Initiated", transformStakingTransferDataToEngagement(data));
  }

  @addEvent("Transfer Funds Staking Submitted")
  static async OnEarnTransformSubmitted(data: EventTypes["Transfer Funds Staking Submitted"]) {
    console.log("Transfer Funds Staking Submitted", data);
    getWebengage()?.track("Transfer Funds Staking Submitted", transformStakingTransferDataToEngagement(data));
  }

  ///################################### P2P FEATURE #####################################

  @addEvent("P2P Ad Creation Initiated")
  static async OnP2PAdCreationInitiated(data: EventTypes["P2P Ad Creation Initiated"]) {
    console.log("P2P Ad Creation Initiated", data);
    getWebengage()?.track("P2P Ad Creation Initiated", transformP2PAdCreationDataToEngagement(data));
  }

  @addEvent("P2P Ad Creation Submitted")
  static async OnP2PAdCreationSubmitted(data: EventTypes["P2P Ad Creation Submitted"]) {
    console.log("P2P Ad Creation Submitted", data);
    getWebengage()?.track("P2P Ad Creation Submitted", transformP2PAdCreationDataToEngagement(data));
  }

  @addEvent("P2P Buy Initiated")
  static async OnP2PBuyInitiated(data: EventTypes["P2P Buy Initiated"]) {
    console.log("P2P Buy Initiated", data);
    getWebengage()?.track("P2P Buy Initiated", transformP2PBuyDataToEngagement(data));
  }

  @addEvent("P2P Buy Submitted")
  static async OnP2PBuySubmitted(data: EventTypes["P2P Buy Submitted"]) {
    console.log("P2P Buy Submitted", data);
    getWebengage()?.track("P2P Buy Submitted", transformP2PBuyDataToEngagement(data));
  }

  @addEvent("P2P Sell Initiated")
  static async OnP2PSellInitiated(data: EventTypes["P2P Sell Initiated"]) {
    console.log("P2P Sell Initiated", data);
    getWebengage()?.track("P2P Sell Initiated", transformP2PSellDataToEngagement(data));
  }

  @addEvent("P2P Sell Submitted")
  static async OnP2PSellSubmitted(data: EventTypes["P2P Sell Submitted"]) {
    console.log("P2P Sell Submitted", data);
    getWebengage()?.track("P2P Sell Submitted", transformP2PSellDataToEngagement(data));
  }
}
