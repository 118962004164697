import { useMemo } from "react";
import { Box, Button, Heading, HStack, useColorModeValue, VStack, Stack, useDisclosure, Text } from "@chakra-ui/react";
import { Icon, MainLayoutContainer, PageMotion, DetailItem } from "components";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";

import { toCoinLocale, toPrecision, currencyFormat, when } from "utils";
import { useGetCryptoAlertOrderByIdQuery, useGetRateQuery, useCancelPriceAlertOrderMutation } from "apis";
import { capitalize } from "lodash";
import { useParams } from "@reach/router";
import { format, parseISO } from "date-fns";
import { useColor } from "hooks";
import { ConfirmationModal } from "ui";

interface CryptoPriceAlertOrdersProps extends PageProps {}

export default function PriceAlertOrderDetails(props: CryptoPriceAlertOrdersProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const { goBack } = useBuiltInFn();

  const color = useColor();

  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading } = useGetCryptoAlertOrderByIdQuery(id);
  const [_cancelRequest, { isLoading: isCancelling }] = useCancelPriceAlertOrderMutation();

  const order = useMemo(() => data?.data, [data]);

  const orderType = useMemo(() => order?.action ?? "buy", [order?.action]);
  const { data: coinData, isLoading: isLoadingRate } = useGetRateQuery(`${order?.coin ?? "btc"}${order?.currency ?? "ngn"}`, {
    skip: !order?.coin,
  });

  const rate: number = useMemo(() => (coinData?.exchangeRate ?? {})[orderType] ?? 0, [coinData, orderType]);

  const crypto_amount = useMemo(() => {
    if (orderType === "buy") return +(order?.amount?.$numberDecimal ?? 0) / rate;
    return +(order?.amount?.$numberDecimal ?? 0);
  }, [order, rate, orderType]);

  const fiat_amount = useMemo(() => {
    if (orderType === "buy") return +(order?.amount?.$numberDecimal ?? 0);
    return rate * +(order?.amount?.$numberDecimal ?? 0);
  }, [order, rate, orderType]);

  const cancelPriceAlert = async () => {
    if (!id) return;
    // onOpen();

    const response = await _cancelRequest(id).unwrap();
    console.log("Cancelled Alert Response", response);
    return response;
  };

  console.log("Order By ID", data);

  return (
    <PageMotion subpage key="AccountCryptoPriceAlertOrders" overflowY="scroll">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="20px">
            <Box>
              <Heading as="h5" fontSize="xl">
                {capitalize(order?.action ?? "buy")} Order
              </Heading>
              {/* <Text fontSize={{ base: "12px", "2sm": "14px" }} mt="8px" fontWeight="500">
                Buy/Sell orders when price reached scheduled price
              </Text> */}
            </Box>
          </HStack>

          <Stack my="10px">
            {/* <HStack justifyContent="space-between" gridGap={{ base: "20px", "1sm": "40px" }} pos="relative"> */}
            <DetailItem
              isLoading={isLoading}
              title="Transaction Status"
              description={when(!!order?.cancelled, "cancelled", order?.status ?? "pending")}
              _description={{ textTransform: "uppercase" }}
            />
            <DetailItem
              isLoading={isLoading}
              title="Date & Time"
              description={
                (!!order?.createdAt && format(parseISO(order?.createdAt), "eee, dd MMM yyyy, HH:mm")) ?? "Wed, 18 May 2022, 14:36"
              }
            />
            <DetailItem isLoading={isLoading} title="Coin Type" description={toCoinLocale(order?.coin ?? "btc")} />
            <DetailItem
              isLoading={isLoading}
              title={`${capitalize(order?.action ?? "buy")} when price at`}
              description={currencyFormat("usd").format(+(order?.spotPrice ?? 0))}
            />
            <DetailItem isLoading={isLoading} title="Amount" description={`${toPrecision(crypto_amount, order?.coin ?? "btc")}`} />
            <DetailItem
              isLoading={isLoading || isLoadingRate}
              title={`Total Fiat amount to ${capitalize(order?.action ?? "buy")}`}
              description={currencyFormat((order?.currency as any) ?? "ngn").format(fiat_amount)}
            />
            <DetailItem isLoading={isLoading} title={`Transaction Reference`} description={order?._id} isCopyable />

            {!order?.cancelled && !["processed"].includes(order?.status ?? "unknown") && (
              <VStack mt="56px !important">
                <Button
                  variant="outline"
                  borderColor={color("error", "error")}
                  color="error"
                  minW={{ sm: "unset" }}
                  w="100%"
                  isDisabled={isLoading || isCancelling}
                  isLoading={isCancelling}
                  // onClick={when(isRedeemDisabled, open("featureUnavailable"), open("redeem::confirm"))}
                  // isLoading={is_Updating("redeem::confirm")}
                  // disabled={isUpdating}
                  onClick={onOpen}
                >
                  Cancel Request
                </Button>
              </VStack>
            )}
          </Stack>
        </Box>
      </MainLayoutContainer>

      <ConfirmationModal isOpen={isOpen} onClose={onClose} onConfirm={cancelPriceAlert} title="Confirmation">
        <VStack my="40px">
          <Text fontWeight="500">Are you sure you want to cancel this request?</Text>
        </VStack>
      </ConfirmationModal>
    </PageMotion>
  );
}
