import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  useGetCardConfigQuery,
  useGetFirstRequestCheckQuery,
  useGetLGAsQuery,
  useGetUserCardProfileQuery,
  useGetUserCardsQuery,
} from "apis";
import {
  AmountInput,
  ConditionalRender,
  CreditCard,
  FormInfo,
  InputLabel,
  Select,
  useModalView,
  ViewSwitcherChildProps,
} from "components";
import configs from "config";
import { useAppConfig } from "contexts/appconfig.context";
import { useDefaultStyle, useDisclosures, useWalletBalance } from "hooks";
import { capitalize, toLower } from "lodash";
import join from "lodash/join";
import { useCallback, useEffect, useMemo } from "react";
import CardAgreement from "ui/Cards/Modals/CardAgreement";
import CardCreationNotice from "ui/Cards/Modals/CardCreationNotice";
import { currencyFormat, toCoinLocale, toPrecision, toQueryString, when } from "utils";
import { StepProps } from "./RequestVirtualCard";

interface StepThreeProps extends StepProps, ViewSwitcherChildProps {}

type ModalTypes = "agreement" | "creation-notice";
export default function StepThree(props: StepThreeProps) {
  const { state, set, user } = props;

  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const { getBlackbox } = useAppConfig();
  const { data: config } = useGetCardConfigQuery();
  console.log("Card Config", config);

  const minimumFundingAmount = useMemo(() => config?.funding?.min ?? 0, [config]);
  const maximumFundingAmount = useMemo(() => config?.funding?.max ?? 0, [config]);

  const { appfigs } = useAppConfig();
  const { cardConfig } = appfigs ?? {};
  const disableWithdrawal = useMemo(() => cardConfig?.disableWithdrawFromCard ?? false, [cardConfig]);

  // const fundFee = 1.5; // in usd
  // const maintenanceFee = 0.42; // in usd

  const { shadow } = useDefaultStyle();
  const color = useColorModeValue("gray.600", "grey.100");

  const { hasNext, onNext, nextButtonProps, currentView } = useModalView();

  const handleExtraData = useCallback(
    (newState: any) => {
      set({ extraData: { ...state?.extraData, ...newState } });
    },
    [state?.extraData, set]
  );

  const selected_provider = useMemo(() => {
    // const map: Record<NonNullable<typeof state.brand>, string> = {
    //   visa: "p4",
    //   mastercard: "p3",
    // };

    // return map[state?.brand ?? "mastercard"];
    return state?.cardProvider ?? "p3";
  }, [state]);

  const { data: firstRequestCheck } = useGetFirstRequestCheckQuery(state?.cardProvider);

  const { data: cardProfile } = useGetUserCardProfileQuery(selected_provider);

  console.log("Virtual Card Profile", cardProfile);

  const { data } = useGetUserCardsQuery(toQueryString({ active: true }));
  const cards = useMemo(() => data?.card ?? [], [data]);

  const { data: lgaData, isLoading: isLGALoading } = useGetLGAsQuery(state?.extraData?.state, { skip: !state?.extraData?.state });

  console.log({ lgaData });

  const userState = useMemo(() => cardProfile?.data?.state, [cardProfile]);

  const lgas = useMemo(() => lgaData?.lgas ?? [], [lgaData]);

  const extraData = useMemo(() => state?.extraData ?? {}, [state]);

  const { isLoading: isWalletLoading, getBalance } = useWalletBalance();

  const knownExtraFields = useMemo(
    () => ["bvn", "dob", "lga", "nin", "city", "occupation", "annualsalary", "expectedmonthlyvolume"], // should be in lowercase
    []
  );

  const missingFields = useMemo(
    () => ((cardProfile?.missingFields as string[]) ?? []).filter((field) => knownExtraFields.includes(toLower(field))),
    [cardProfile, knownExtraFields]
  );

  const isBvnValid = useMemo(() => {
    if (!(missingFields ?? []).includes("bvn")) return true;
    else return !!extraData?.bvn && extraData?.bvn?.length === 11;
  }, [extraData, missingFields]);

  const cardCost = useMemo(() => {
    if (firstRequestCheck && firstRequestCheck?.firstTime && ["p3", "p4", "p2"].includes(state?.cardProvider ?? "p3")) return 0;
    if (firstRequestCheck && !firstRequestCheck?.firstTime && ["p5"].includes(state?.cardProvider ?? "p3")) return 0;
    if (firstRequestCheck && firstRequestCheck?.fee) return firstRequestCheck?.fee;
    if ((cards ?? []).length > 0) return 3;
    return 3;
  }, [cards, firstRequestCheck, state?.cardProvider]);

  // const calcfundingFee = useMemo(() => (!!state?.amount ? +state?.amount * (fundFee / 100) : 1), [state?.amount]);
  // const fundingFee = useMemo(() => Math.max(1, calcfundingFee), [calcfundingFee]);
  // const fundingFee = useMemo(() => Math.max(1, 2.5), []);

  const balance = useMemo(() => (state?.wallet ? getBalance(state?.wallet as any) : undefined), [getBalance, state?.wallet]);

  console.log({ cardProfile });

  const handleClick = () => {
    close("creation-notice")();
    onNext();
  };

  const handleCheckbox = (e: any) => {
    if (!state?.agreedToLimitations) {
      open("agreement")();
      return;
    }
    set({ agreedToLimitations: e.target.checked });
  };

  const handleAgreement = () => {
    set({ agreedToLimitations: true });
    close("agreement")();
  };

  const isInsufficientBalance = useMemo(() => !!state?.amount && +state?.amount > (balance ?? 0), [state?.amount, balance]);

  const isLessThanMinimum = useMemo(
    () => !!state?.amount && +state?.amount < minimumFundingAmount,
    [state?.amount, minimumFundingAmount]
  );

  const isMoreThanMaximum = useMemo(
    () => !!state?.amount && +state?.amount > maximumFundingAmount,
    // eslint-disable-next-line
    [state?.amount, maximumFundingAmount]
  );

  const isDisabled = useMemo(
    () =>
      !(state?.currency && state?.type && state?.wallet && state?.amount && state?.brand) ||
      !hasNext ||
      nextButtonProps?.disabled ||
      isWalletLoading ||
      (balance ?? 0) < cardCost ||
      !isBvnValid ||
      isLessThanMinimum ||
      isMoreThanMaximum ||
      !state?.agreedToLimitations ||
      isInsufficientBalance,
    [
      state,
      isWalletLoading,
      cardCost,
      isBvnValid,
      isLessThanMinimum,
      isMoreThanMaximum,
      balance,
      hasNext,
      nextButtonProps,
      isInsufficientBalance,
    ]
  );

  const extrafieldInputName = useCallback((name: string) => {
    const map: Record<string, { label: string; inputType: string }> = {
      [name]: { label: capitalize(name), inputType: "text" },
      lga: { label: "LGA (Local Govt. Area)", inputType: "text" },
      dob: { label: "DOB (Date Of Birth)", inputType: "date" },
      bvn: { label: "BVN (Biometric Verification Number)", inputType: "number" },
      nin: { label: "NIN (National Identity Number)", inputType: "number" },
      occupation: { label: "Occupation", inputType: "text" },
      annualSalary: { label: "Annual Salary (USD)", inputType: "number" },
      expectedMonthlyVolume: { label: "Expected Monthly Volume (USD)", inputType: "number" },
      // city: {label: "City", inputType: "text"},
    };
    return map[name];
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (disableWithdrawal) open("creation-notice")();
    else handleClick();
    // handleClick();
  };

  useEffect(() => {
    const missing_fields = (cardProfile?.missingFields ?? []) as string[];
    if (!!missing_fields && missing_fields.includes("iovationBlackbox") && !state?.extraData?.iovationBlackbox) {
      const blackbox = getBlackbox();
      if (!!blackbox) handleExtraData({ iovationBlackbox: blackbox });
    }
  }, [cardProfile?.missingFields, state?.extraData?.iovationBlackbox, getBlackbox, handleExtraData]);

  return (
    <Box as="form" py="40px" px="8px" onSubmit={handleSubmit}>
      <HStack>
        <Heading as="h6" fontSize="md" fontWeight="600" color={color}>
          Step {currentView + 1}
        </Heading>
        <Text ml="28px !important" fontSize="14px" fontWeight="500">
          Fill in the details below
        </Text>
      </HStack>

      <VStack my="52px">
        <CreditCard brand={state?.brand} type={state?.card ?? "black"} name={join([user?.firstName, user?.lastName], " ")} />
      </VStack>

      <Stack>
        <ConditionalRender shouldRender={missingFields?.length > 0}>
          <Stack mb={when(missingFields?.length > 0, "24px !important", "0")}>
            {(missingFields ?? []).map((field: any) => {
              return (
                <>
                  {field !== "lga" && (
                    <FormControl mt="20px !important">
                      <InputLabel>{extrafieldInputName(field).label}</InputLabel>
                      <Input
                        isRequired
                        type={extrafieldInputName(field).inputType}
                        placeholder={`Enter ${extrafieldInputName(field).label}`}
                        value={(extraData as any)[field] ?? ""}
                        onChange={(e) => handleExtraData({ [field]: e.target.value })}
                      />

                      {!!extraData?.bvn && (extraData?.bvn?.length < 11 || extraData?.bvn?.length > 11) && field === "bvn" && (
                        <FormHelperText fontSize="sm" color="red.400">
                          BVN is not valid
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}

                  {field === "lga" && (
                    <>
                      <FormControl mt="20px !important">
                        <InputLabel>State</InputLabel>
                        <Select
                          placeholder="Select state"
                          value={extraData["state"] ?? ""}
                          onChange={(e) => handleExtraData({ state: e.target.value })}
                        >
                          {!!userState && (
                            <option key={`state-1`} value={String(userState).toLowerCase()}>
                              {userState}
                            </option>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl mt="20px !important" mb="24px !important">
                        <InputLabel isLoading={isLGALoading}>LGA</InputLabel>
                        <Select
                          placeholder="Select LGA"
                          value={(extraData as any)[field] ?? ""}
                          onChange={(e) => handleExtraData({ [field]: e.target.value })}
                        >
                          {lgas?.map((lga: string, i: number) => (
                            <option key={`lga-${i}`} value={String(lga).toLowerCase()}>
                              {lga}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </>
              );
            })}
          </Stack>
        </ConditionalRender>

        <FormControl mb="24px !important">
          <InputLabel>Currency</InputLabel>
          <Select
            placeholder="Select currency"
            defaultValue={"usd"}
            value={state?.currency ?? ""}
            onChange={(e) => set({ currency: e.target.value })}
          >
            <option value="usd">USD</option>
          </Select>
        </FormControl>

        {/* <FormControl mb="24px !important">
          <InputLabel>Card Type</InputLabel>
          <Select placeholder="Select card type" value={state?.brand ?? ""} onChange={(e) => set({ brand: e.target.value as any })}>
            <option value="visa">Visa</option>
            <option value="mastercard">Mastercard</option>
          </Select>
        </FormControl> */}

        {/* <FormControl mb="24px !important"> */}
        {/* <InputLabel>Type</InputLabel>
          <Select placeholder="Select type" value={state?.type ?? ""} onChange={(e) => set({ type: e.target.value })}>
            <option value="virtual">Virtual</option>
          </Select> */}
        {/* <FormHelperText>
            Virtual card costs <Box as="strong">${cardCost}</Box>
          </FormHelperText> */}
        {/* </FormControl> */}

        <FormControl>
          <InputLabel isLoading={isWalletLoading}>Payment Wallet</InputLabel>
          <Select
            isInvalid={(balance ?? 0) < cardCost}
            placeholder="Select Wallet"
            value={state?.wallet ?? ""}
            onChange={(e) => set({ wallet: e.target.value })}
          >
            {configs.stableCoins.map((coin) => (
              <option key={`${coin}-wallet`} value={coin}>
                {toCoinLocale(coin)}
              </option>
            ))}
          </Select>

          {!!state?.wallet && isInsufficientBalance && (
            <FormHelperText fontSize="xs" fontWeight="500" color="red.400">
              Insufficient balance
            </FormHelperText>
          )}

          {!!state?.wallet && (
            <FormInfo
              mt="10px"
              isInvalid={isInsufficientBalance}
              info="Available Balance: "
              description={toPrecision(balance ?? 0, state?.wallet)}
            />
          )}
        </FormControl>

        {state?.wallet && (
          <FormControl mt="20px !important">
            {/* <InputLabel>Fund Amount (Amount to be funded to this card)</InputLabel> */}
            <InputLabel>Initial Funding</InputLabel>

            <AmountInput
              isInvalid={isInsufficientBalance || isLessThanMinimum || isMoreThanMaximum}
              value={state?.amount ?? ""}
              // onMax={() => set({ amount: Number(balance).toFixed(2) })}
              onChange={(e) => set({ amount: e.target.value })}
              isDisabled
            />

            {/* <FormInfo mt="10px" info={`Funding fee(${fundFee}%):`} description={`${currencyFormat("usd").format(fundingFee)}`} /> */}
            {/* <FormInfo mt="10px" info={`Funding fee:`} description={`${currencyFormat("usd").format(fundingFee)}`} /> */}
            <FormInfo info={`Card creation fee: `} description={`${currencyFormat("usd").format(cardCost)}`} />
            <FormInfo
              isInvalid={isLessThanMinimum}
              info={`Minimum funding amount: `}
              description={`${currencyFormat("usd").format(minimumFundingAmount)}`}
            />
            {isMoreThanMaximum && (
              <FormInfo
                isInvalid={isMoreThanMaximum}
                info={`Maximum funding amount: `}
                description={`${currencyFormat("usd").format(maximumFundingAmount)}`}
              />
            )}
          </FormControl>
        )}

        <FormControl mt="38px">
          <FormHelperText fontWeight="500">
            <HStack
              justifyContent={["flex-start", "space-between"]}
              alignItems={["flex-start", "flex-start", "center"]}
              flexDir={["column", "column", "row"]}
            >
              {/* {console.log({ state })} */}
              <Checkbox
                // dir="rtl"
                colorScheme="primary"
                isChecked={state?.agreedToLimitations}
                onChange={handleCheckbox}
                sx={{
                  "chakra-checkbox__control[data-focus]": {
                    shadow,
                  },
                }}
              >
                I am aware of the terms and conditions of this card.
              </Checkbox>
            </HStack>
          </FormHelperText>
        </FormControl>
      </Stack>

      <VStack py="52px">
        <Button fontSize="16px" fontFamily="var(--bitmama-fonts-heading)" type="submit" disabled={isDisabled}>
          Submit
        </Button>
      </VStack>

      <ConditionalRender shouldRender={disableWithdrawal}>
        <CardCreationNotice isOpen={isOpen("creation-notice")} onClose={close("creation-notice")} onProceed={handleClick} />
      </ConditionalRender>

      <CardAgreement
        isOpen={isOpen("agreement")}
        onClose={close("agreement")}
        shouldConsent
        creationFee={cardCost ?? 3} // in usd
        setAgreement={handleAgreement}
      />
    </Box>
  );
}
