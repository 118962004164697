import { Button, ButtonProps } from "@chakra-ui/react";
import Icon from "components/Icon/Icon";
import { useSupport } from "hooks";

interface SupportButtonProps extends ButtonProps {}

export default function SupportButton(props: SupportButtonProps) {
  const { children, ...xprops } = props;
  const { bootWithProps } = useSupport();
  return (
    <Button
      //   variant="transparent"
      size="sm"
      leftIcon={<Icon type="support" color="white" mr="10px" />}
      //   minW="unset"
      //   maxW="unset"
      //   minH="unset"
      //   maxH="unset"
      //   h="fit-content"
      //   w="fit-content"
      //   px="1"
      //   py="0"
      fontSize="12px"
      color="white"
      sx={{ span: { mr: 0 } }}
      onClick={bootWithProps}
      {...xprops}
    >
      {!children ? "Contact Support" : children}
    </Button>
  );
}
