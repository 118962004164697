import { useMemo } from "react";
import { toLower } from "lodash";
import { UserRo } from "interfaces";
import { when } from "utils";

export default function useIDType(user: UserRo) {
  const IDMap = {
    primary: [
      { name: "National ID", value: "national_id", category: "primary" },
      { name: "Voter's Card", value: "voters_card", category: "primary" },
      {
        name: "KRA Certificate",
        value: "kra_certificate",
        category: "primary", // only for kenya users
      },
      {
        name: "Driver's License",
        value: "driver_licence",
        category: "primary",
      },
      {
        name: "International Passport",
        value: "international_passport",
        category: "primary",
      },
      {
        name: "NIMC SLIP",
        value: "nimc_slip",
        category: "primary", // only for nigeria users
      },
      {
        name: "Birth Certificate",
        value: "birth_certificate",
        category: "primary", // only for kenya users
      },
    ],
    secondary: [
      { name: "Work ID", value: "work_id", category: "secondary" },
      { name: "Tax Card", value: "tax_card", category: "secondary" },
      {
        name: "Bank Statement",
        value: "bank_statement",
        category: "secondary",
      },
      {
        name: "School ID Card",
        value: "school_id_card",
        category: "secondary",
      },
      {
        name: "Court Affidavit",
        value: "court_affidavit",
        category: "secondary",
      },
      {
        name: "Birth Certificate",
        value: "birth_certificate",
        category: "secondary",
      },
      {
        name: "Marriage Certificate",
        value: "marriage_certificate",
        category: "secondary",
      },
    ],
  };

  const idTypes = useMemo(() => {
    const map: Record<string, { [key: string]: string }[]> = {
      nigeria: [...IDMap.primary.filter((d) => !["kra_certificate", "birth_certificate"].includes(d.value))],
      ghana: IDMap.primary.filter((d) => !["kra_certificate", "birth_certificate", "nimc_slip"].includes(d.value)),
      kenya: IDMap.primary.filter((d) =>
        ["national_id", "international_passport", "kra_certificate", "birth_certificate"].includes(d.value)
      ),
      default: [...IDMap.primary.filter((d) => d.value === "international_passport")],
    };

    const legacySupport = ["nigeria", "ghana", "kenya"];
    const country = when(
      !!user?.country,
      legacySupport.includes(toLower(user.country!)) ? toLower(user.country!) : "default",
      "default"
    );
    // console.log("ID types - country", country);
    return map[country];
  }, [IDMap.primary, user?.country]);

  const p5CardProviderIdTypes = useMemo(() => {
    const map: Record<string, { [key: string]: string }[]> = {
      default: [
        ...IDMap.primary.filter(
          (d) => !["kra_certificate", "birth_certificate", "nimc_slip", "driver_licence", "voters_card"].includes(d.value)
        ),
      ],
    };
    // console.log("ID types - country", country);
    return map["default"];
  }, [IDMap.primary]);

  // console.log("ID types - user arg", user);

  return { idTypes, p5CardProviderIdTypes };
}
