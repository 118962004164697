import { BoxProps, HStack, Text, TextProps, useColorModeValue } from "@chakra-ui/react";
// import { useDefaultStyle } from "hooks";
import { cloneElement, JSXElementConstructor, ReactElement, ReactNode, useMemo } from "react";
import { when } from "utils";

interface FormInfoProps<T> extends BoxProps {
  leftElement?: ReactElement<any, string | JSXElementConstructor<any>>;
  rightElement?: ReactElement<any, string | JSXElementConstructor<any>>;
  info: string;
  description?: ReactNode;

  isInvalid?: boolean;

  _info?: TextProps | ((props: { isInvalid?: boolean }) => TextProps);
  _description?: TextProps | ((props: { isInvalid?: boolean }) => TextProps);
  _wrapper?: BoxProps;

  _lElement?: BoxProps | T;
  _rElement?: BoxProps | T;
}

export default function FormInfo<T>(props: FormInfoProps<T>) {
  const {
    info,
    description,
    isInvalid,
    leftElement,
    rightElement,
    _lElement,
    _wrapper,
    _rElement,
    _info,
    _description,
    ...xprops
  } = props;

  const dColor = useColorModeValue("secondary.400", "secondary.400");
  const iColor = useColorModeValue("grey.600", "grey.150");

  //   const {fgColor} = useDefaultStyle()

  const lElement = useMemo(() => {
    if (_lElement && leftElement) return cloneElement(leftElement, { ..._lElement });
    return leftElement;
  }, [leftElement, _lElement]);

  const rElement = useMemo(() => {
    if (_rElement && rightElement) return cloneElement(rightElement, { ..._rElement });
    return rightElement;
  }, [rightElement, _rElement]);

  const descriptionProps = useMemo(
    () => (!!_description && typeof _description === "function" ? _description({ isInvalid }) : _description ?? {}),
    [_description, isInvalid]
  );

  return (
    <HStack fontSize="14px" {...xprops}>
      {lElement}

      <HStack {..._wrapper}>
        <Text color={iColor} fontSize="inherit" fontWeight="500" {..._info}>
          {info}
        </Text>
        {description && (
          <Text fontSize="inherit" color={when(!!isInvalid, "red.400", dColor)} fontWeight="600" {...descriptionProps}>
            {description}
          </Text>
        )}
      </HStack>

      {rElement}
    </HStack>
  );
}
