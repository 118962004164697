import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { Icon, InputLabel, PinInput, useModalView } from "components";
// import PasswordInput from "components/Input/PasswordInput";
import { PageProps } from "interfaces";
import { useCreatePinMutation } from "apis";
import { useDispatch } from "hooks";
import { getProfile } from "store/slices";

interface CreatePinProps extends PageProps {}

export default function CreatePin(props: CreatePinProps) {
  const { setCurrentView } = useModalView();
  const pColor = useColorModeValue("grey.default", "inherit");
  const dispatch = useDispatch();

  const toast = useToast();

  const [state, setState] = useState({
    pin: "",
    confirmPin: "",
  });

  const pinMatch = state.pin === state.confirmPin;

  const [_createPin, { isLoading, isSuccess }] = useCreatePinMutation();

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const handleSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { pin, confirmPin } = state;

    try {
      const response: any = await _createPin({
        pin,
        confirmPin,
      });

      if (response.data) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: response?.data?.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        dispatch(getProfile());

        navigate("/account/profile/pin/#update");
      }
    } catch (error: any) {
      toast({
        position: "bottom-right",
        title: "Error",
        description: error?.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    isSuccess && setCurrentView(0);
  }, [setCurrentView, isSuccess]);

  // const handleKeyUp = (event: any) => {
  //   const lastChar = event.target.value.slice(-1);
  //   if (!/^\d+$/.test(lastChar)) {
  //     setState({
  //       ...state,
  //       [event.target.name]: event.target.value.slice(0, -1),
  //     });
  //   }
  // };

  const handlePin = (pin: string) => {
    setState((prev) => ({
      ...prev,
      pin,
    }));
  };

  console.log(state, "STATE");

  return (
    <Box>
      <Stack mb="46px">
        <HStack>
          <IconButton
            size="xs"
            minW="fit-content"
            maxW="fit-content"
            minH="fit-content"
            maxH="fit-content"
            p="0 !important"
            bg="transparent"
            variant="transparent"
            aria-label="back button"
            icon={<Icon boxSize="20px" type="circleLeftArrow" />}
            onClick={() => setCurrentView(0)}
          />
          <Heading as="h6" fontSize="sm" color="secondary.400">
            Create Transaction Pin
          </Heading>
        </HStack>

        <Text color={pColor} fontSize="sm" fontWeight="500">
          Kindly enter 6 digit to create a transaction pin
        </Text>
      </Stack>

      <Stack as="form" onSubmit={handleSubmit}>
        <FormControl px="4px" mb="40px !important">
          <InputLabel mb="10px" htmlFor="pin">
            Pin
          </InputLabel>
          {/* <PasswordInput onKeyUp={handleKeyUp} value={state.pin} isRequired id="pin" name="pin" onChange={handleChange} /> */}
          <PinInput size="sm" onChange={handlePin} id="pin" />
        </FormControl>

        <FormControl px="4px" mb="20px !important">
          <InputLabel mb="10px" htmlFor="confirmPin">
            Confirm Pin
          </InputLabel>
          <PinInput size="sm" onChange={(pin) => setState((prev) => ({ ...prev, confirmPin: pin }))} id="confirmPin" />
          {/* <PasswordInput
            onKeyUp={handleKeyUp}
            value={state.confirmPin}
            isRequired
            id="confirmPin"
            name="confirmPin"
            onChange={handleChange}
            type="number"
          /> */}
          {!pinMatch && state.confirmPin.length >= 6 && (
            <Text color="error" fontSize="14px" mt="20px">
              Pins do not match
            </Text>
          )}
        </FormControl>

        <VStack mt="40px !important">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!(state.pin.length === 6) || !(state.confirmPin.length === 6) || isLoading || !pinMatch}
          >
            Submit
          </Button>
        </VStack>
      </Stack>
    </Box>
  );
}
