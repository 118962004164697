import { Box, BoxProps, HStack, Progress, Stack, Text } from "@chakra-ui/react";
import Span from "components/Common/Span";
import BadgeIcon from "components/Icon/BadgeIcon";

import { ReactComponent as LevelFrameSVG } from "assets/svg/level-text-frame.svg";
import { useColor, useDefaultStyle } from "hooks";
import { useMemo } from "react";
import { when } from "utils";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import { BadgeGameDetailsLevelTargetRo, BadgeUserLevelRo } from "interfaces";

export interface BadgeInfoProps extends BoxProps {
  isLocked?: boolean;
  progressValue?: number;
  maxProgressValue?: number;
  currentLevel?: number;
  nextLevel?: number;
  levels?: BadgeGameDetailsLevelTargetRo[];
  userLevels?: BadgeUserLevelRo[];

  name?: string;
  description?: string;
  imgUrl?: string;
}

export function BadgeInfo(props: BadgeInfoProps) {
  const {
    name,
    description,
    isLocked,
    progressValue,
    maxProgressValue = 100,
    currentLevel = 1,
    nextLevel = 2,
    imgUrl,
    levels,
    userLevels,
    ...xprops
  } = props;
  const color = useColor();

  const { shadow } = useDefaultStyle();

  const progress_value = useMemo(() => progressValue ?? Math.floor(Math.random() * 100), [progressValue]);

  const next_level = useMemo(() => {
    const level = (levels ?? []).find((l) => +(l?.level ?? 1) === (nextLevel ?? currentLevel));
    return level!;
  }, [levels, nextLevel, currentLevel]);

  const has_single_level = useMemo(() => (levels?.length ?? 1) === 1, [levels]);
  const all_levels_unlocked = useMemo(() => (levels ?? []).length <= (userLevels ?? []).length, [userLevels, levels]);

  const is_on_last_level = useMemo(() => {
    const badge_levels_count = props?.levels?.length ?? 1;
    const last_level = props?.currentLevel;

    if (!!last_level && badge_levels_count === last_level) return true;
    return false;
  }, [props?.levels, props?.currentLevel]);

  // const progress_is_about_half = useMemo(
  //   () =>
  //     progress_value >=
  //     when(progress_value < 1, Math.abs(Math.floor(maxProgressValue / 2.5) - 7), Math.floor(maxProgressValue / 2.5) - 7),
  //   [progress_value, maxProgressValue]
  // );

  // console.log(`[${name}]::Progress`, progress_is_about_half);

  const progress_text_color = useMemo(() => {
    // if (progress_is_about_half) return color("white", "white");
    // return color("black", "white");
    return color("#92ff9f", "#dadada");
  }, [color]);

  const hasLogo = useMemo(() => {
    if (!imgUrl || !next_level?.logoUrl) return false;
    if (imgUrl?.length < 1 || ["http:dummy.com", ""].includes(imgUrl)) return false;
    if (next_level?.logoUrl?.length < 1 || ["http:dummy.com", ""].includes(next_level?.logoUrl)) return false;
    return true;
  }, [imgUrl, next_level?.logoUrl]);

  const _description = useMemo(() => {
    const is_next_level_completed =
      userLevels?.findIndex((u) => u?.level === next_level?.level && u?.progressStatus === "completed") !== -1;

    if (!!all_levels_unlocked && !!next_level && !!next_level?.earnedDescription && is_next_level_completed)
      return next_level?.earnedDescription;
    else return next_level?.description ?? description;
  }, [all_levels_unlocked, next_level, description, userLevels]);

  console.log(`[${name}]::Description`, _description);

  return (
    <Box
      // w="fit-content"
      w="100%"
      //   maxW="340px"
      border="1px solid transparent"
      borderColor={color("#d9d9d9", "#CACBCA")}
      borderRadius="10px"
      p="20px 16px"
      bg="transparent"
      _hover={{
        bg: color("#efefef", "rgba(255 255 255 / 5%)"),
        cursor: "pointer",
        shadow,
        borderColor: color("primary.400", "secondary.400"),
      }}
      {...xprops}
    >
      {/* <InfoTag isLocked={isLocked} /> */}

      <HStack w="100%" alignItems="flex-start" gridGap="12px">
        <Box pos="relative">
          <BadgeIcon badge="newBitStar" imgUrl={next_level?.logoUrl ?? imgUrl} locked={isLocked} use_image={hasLogo} />

          {!isLocked && !has_single_level && (
            <LevelFrame level={currentLevel} pos="absolute" bottom="-10px" left="50%" transform="translate(-50%, 0%)" />
          )}
        </Box>

        <Stack w="100%" my="0px !important">
          <Text color={color("black", "white")} fontFamily="var(--bitmama-fonts-heading)" fontSize="15px" fontWeight="600">
            {name ?? "The New Bitstar badge"}
          </Text>
          <Text mt="4px !important" fontSize="14px" fontWeight="500">
            {_description ?? "You earned this badge for performing your first transaction on Bitmama"}
          </Text>

          <HStack mt="20px !important">
            <Progress
              flex="2.5"
              h="16px"
              // mt="22px"
              value={progress_value}
              max={maxProgressValue}
              borderRadius="20px"
              bg={color("#EBEBEB", "#3635358f")}
              sx={{
                "[role=progressbar]": {
                  bg: color("primary.default", "secondary.400"),
                },
              }}
            >
              <ConditionalRender shouldRender={!is_on_last_level}>
                <Text
                  pos="absolute"
                  fontWeight="500"
                  fontSize="xs"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  color={progress_text_color}
                  w="100%"
                  textAlign="center"
                  mixBlendMode="difference"
                >
                  {progress_value.toLocaleString()} / {maxProgressValue.toLocaleString()}
                </Text>
              </ConditionalRender>
            </Progress>
            <ConditionalRender shouldRender={!is_on_last_level}>
              <Text flex=".5" fontWeight="500" fontSize="sm" textAlign="center">
                Lvl. {nextLevel}
              </Text>
            </ConditionalRender>
          </HStack>
        </Stack>
      </HStack>
    </Box>
  );
}

interface InfoTagProps extends BoxProps {
  isLocked?: boolean;
}

export function InfoTag(props: InfoTagProps) {
  const { isLocked = true, ...xprops } = props;

  const color = useColor();

  const bg = useMemo(() => {
    if (isLocked) return color("#CFDAD9", "#CFDAD9");
    return color("#E2F2DA", "#E2F2DA");
  }, [isLocked, color]);

  return (
    <Box w="fit-content" p="6px 14px" borderRadius="20px" bg={bg} color="black" fontSize="12px" fontWeight="600" {...xprops}>
      {when(isLocked, "Locked", "Unlocked")}
      <Span ml="4px">{when(isLocked, "🔒", "🔓")}</Span>
    </Box>
  );
}

interface LevelFrameProps extends BoxProps {
  level?: number;
}

export function LevelFrame(props: LevelFrameProps) {
  const { level = 1 } = props;
  return (
    <Box {...props}>
      <Box w="40px" h="30px" pos="relative">
        <Box w="40px" h="30px" as={LevelFrameSVG} />
        <Text
          pos="absolute"
          fontWeight="600"
          w="40px"
          fontSize="10px"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          Lvl. {level}
        </Text>
      </Box>
    </Box>
  );
}
