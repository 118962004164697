import { useCallback, useEffect, useRef } from "react";
export default function useRedebounce() {
  const debouncer = useRef<Record<string, NodeJS.Timeout | undefined>>({});

  const redebounce = useCallback((cb: TimerHandler, functionName: string, delay: number) => {
    let timeout = debouncer.current[functionName];
    return function () {
      if (timeout) {
        clearTimeout(timeout as NodeJS.Timeout);
        debouncer.current[functionName] = undefined;
      }
      (debouncer.current as any)[functionName] = setTimeout(cb, delay);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dc = debouncer.current;

    return () => {
      const debouncing = Object.keys(dc);
      debouncing.forEach((d) => {
        const timeout = dc[d];
        if (timeout) {
          clearTimeout(timeout as NodeJS.Timeout);
          dc[d] = undefined;
        }
      });
    };
  }, []);

  return redebounce;
}
