import {
  Box,
  Button,
  Center,
  FormControl,
  FormHelperText,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { useVerify2FAPinMutation } from "apis";
import { Group, Icon, Logo, ShowCustomerSupport } from "components";
import Input from "components/Input/Input";
import configs, { isProd } from "config";
import { useCallback, useMemo, useRef, useState } from "react";
import Recaptcha from "react-google-recaptcha";
import { AuthImage } from "ui";
import { when } from "utils";
import ls from "utils/secureStorage";
import { isSafeRedirect } from "utils/urls";
import Forgot2FA from "./Forgot2FA";
import { IntercomProvider } from "react-use-intercom";
import { useAuth } from "hooks";

export default function Enter2FA(props: { path: string }) {
  const { auth, logout: auth_logout, rehydrateAuth } = useAuth();
  const recaptchaRef = useRef<any>(null);
  const [twoFaCode, setTwoFaCode] = useState("");

  const hColor = useColorModeValue("#303131", "white");
  const pColor = useColorModeValue("grey.500", "grey.150");

  const logoColor = useColorModeValue("primary.default", "secondary.500");

  console.log("2fa Auth", auth);

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [_verifyUser, { isLoading }] = useVerify2FAPinMutation();

  const makeRequest = async (code?: string) => {
    const captcha = await recaptchaRef.current?.executeAsync();

    const response: any = await _verifyUser({
      twoFaCode: code ?? twoFaCode,
      humanKey: captcha,
    });

    recaptchaRef.current?.reset();

    if (response.data) {
      const data = response.data;

      const rto = params.get("rto");
      const is_safe_redirect = isSafeRedirect({ url: rto!, skip: !isProd });
      let path = when(!rto, configs.paths.dashboard, when(is_safe_redirect, rto!, configs.paths.dashboard));
      let auth = { token: data?.token, isSignedIn: true, verification: [], exp: data?.exp, label: data?.label };

      if (!!data?.label && ["BVN_PROFILE_REQUIRED"].includes(data?.label)) {
        auth.isSignedIn = false;
        path = configs.paths.captureBVN;
      }

      ls.set(configs.AUTH_TOKEN_KEY, auth);
      rehydrateAuth(); // must be called after inserting the new auth into ls;
      window.location.replace(path);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await makeRequest();
  };

  const handle2FAcode = async (e: any) => {
    const value = e.target.value as string;
    setTwoFaCode(value);
    if (value.length === 6) await makeRequest(value);
  };

  // const logout = () => {
  //   localStorage.clear();

  //   const win: Window = window;
  //   win.location = "/login";
  // };

  const logout = useCallback(() => {
    auth_logout();
    const win: Window = window;
    win.location = "/login";
  }, [auth_logout]);

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const [isMobile] = useMediaQuery("(max-width: 1023px)");

  return (
    <IntercomProvider appId={configs.INTERCOM_TOKEN}>
      <Box
        minH="100vh"
        pos="relative"
        sx={{
          "--w": isLargerThan1080 ? "580px" : "480px",
        }}
      >
        <AuthImage isMobile={isMobile} />

        <Box minH="100vh" pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
          <Group maxWidth="523px" margin="auto" padding={{ sm: "32px 20px 43px", "2sm": "80px 20px 43px" }}>
            <Logo fontSize="54px" mb={{ sm: "12px", "2sm": "52px" }} _name={{ w: "110px" }} color={logoColor} />

            <Box mb="26px !important">
              <Heading fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
                Verify 2FA
              </Heading>

              <Text fontWeight="600" color={hColor} fontSize="16px" margin="39px 0 21px">
                Enter OTP
              </Text>

              <Text color={pColor} fontSize="13px">
                Enter the 6 digit OTP shown on the Google Authenticator App
              </Text>
            </Box>

            <Stack as="form" gridGap="24px" onSubmit={handleSubmit}>
              <FormControl>
                <Input
                  isRequired
                  type="text"
                  value={twoFaCode ?? ""}
                  onChange={handle2FAcode}
                  placeholder="Enter the 6 digit OTP shown on the Google Authenticator App"
                />
                <FormHelperText color="#797A7A">Enter the 6 digit code from Google Authenticator</FormHelperText>
              </FormControl>

              <VStack mb="81px !important" mt="36px !important" alignItems="center">
                <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />
                <Button
                  type="submit"
                  minW="initial"
                  maxW="399px"
                  width="100%"
                  isLoading={isLoading}
                  disabled={twoFaCode.length !== 6 || isLoading}
                  rightIcon={<Icon type="arrowRight" color="white" />}
                >
                  Authenticate
                </Button>
              </VStack>
            </Stack>

            <Center flexDir="column">
              <Forgot2FA />

              <Text mt="42px" onClick={logout} cursor="pointer">
                Logout
              </Text>
            </Center>
          </Group>
        </Box>
      </Box>
      <ShowCustomerSupport />
    </IntercomProvider>
  );
}
