import { Text, TextProps } from "@chakra-ui/react";

interface VersionProps extends TextProps {}

export function Version(props: VersionProps) {
  return (
    <Text fontSize="sm" color="grey.500" fontWeight="600" {...props}>
      v2.1.9
    </Text>
  );
}

// 2.0.4 ---> bug fixes
// 2.1.5 ---> greenbox feature +0.1, and bug fixes on card and authentication.
// 2.1.6 ---> greenbox preclaim fix
// 2.1.7 ---> forgot password flow fix
// 2.0.8 ---> update crypto sell success description, greenbox disabled -0.1.0
// 2.1.8 ---> enabled greenbox for preview +0.1
// 2.1.9 ---> concluded greenbox + bug fixes.
