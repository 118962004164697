import React, { createContext, PropsWithChildren, useState, useCallback, useContext, useMemo, useEffect } from "react";
import { ButtonProps } from "@chakra-ui/react";
import type { _ViewSwitcherChildProps, InjectAbstractProps, IModalViewState } from "./interfaces";

import { useStep } from "hooks";

interface IContext extends _ViewSwitcherChildProps {
  id: string;
  initialMax: number;
  maxViews: number;
  direction: number;
  setMaxViews: (steps: number) => void;
  setViewConfigs: (props: Partial<IModalViewState>) => void;
  setViewNextButtonProps: (props: IModalViewState["nextButtonProps"]) => void;
  setViewBackButtonProps: (props: IModalViewState["backButtonProps"]) => void;
  initialNextButtonPropsList: ButtonProps[];
  initialBackButtonPropsList: ButtonProps[];
}

const ModalViewContext = createContext<IContext | null>(null);

const ContextLists = new Map<string, IContext>();

export default function ModalView(
  props: Partial<
    PropsWithChildren<InjectAbstractProps> & {
      id?: string;
      updateModal: InjectAbstractProps["updateParentState"];
    }
  >
) {
  const { id, children: rawChildren, updateParentState, updateModal } = props;

  const [state, _setState] = useState<IModalViewState>({
    maxViews: 0,
    nextButtonProps: [],
    backButtonProps: [],
  });

  const setState = useCallback((newState: Partial<IModalViewState>) => _setState((state) => ({ ...state, ...newState })), []);

  // console.log("MODAL VIEW STATE", state);

  const { onNext, onPrev, currentStep, hasPrev, hasNext, direction, setCurrentStep, nextButtonProps, backButtonProps } = useStep(
    state.maxViews,
    {
      nextButtonProps: state?.nextButtonProps,
      backButtonProps: state?.backButtonProps,
    }
  );

  const setMaxViews = useCallback((viewCount: number) => setState({ maxViews: viewCount }), [setState]);

  const setViewConfigs = useCallback(
    (props: Partial<IModalViewState>) => {
      setState({ ...props });
      //   console.log("SET NEXT BUTTON PROPS", props);
    },
    [setState]
  );

  const children = useMemo(
    () =>
      React.Children.map(rawChildren, (child) => {
        if (!React.isValidElement(child)) return;
        return React.cloneElement<any>(child, { updateParentState });
      }),
    [rawChildren, updateParentState]
  );

  const _id = useMemo(() => id ?? `modal-view-${ContextLists.size}`, [id]);

  const contextValues = {
    id: _id,
    onNext,
    onPrev,
    hasNext,
    hasPrev,
    direction,
    setMaxViews,
    setViewConfigs,
    nextButtonProps: (nextButtonProps as ButtonProps) ?? {},
    backButtonProps: (backButtonProps as ButtonProps) ?? {},
    currentView: currentStep,
    maxViews: state?.maxViews,
    initialMax: state?.maxViews,
    setCurrentView: setCurrentStep,
    updateModal: updateParentState! ?? updateModal,

    initialNextButtonPropsList: state?.nextButtonProps,
    initialBackButtonPropsList: state?.backButtonProps,
  } as IContext;

  useEffect(() => {
    if (!ContextLists.has(_id)) ContextLists.set(_id, contextValues);
  });

  return <ModalViewContext.Provider value={contextValues}>{children}</ModalViewContext.Provider>;
}

export function useModalView(maxViews?: number, id?: string) {
  let context = useContext(ModalViewContext) as IContext;
  if (!!id && ContextLists.has(id)) context = ContextLists.get(id)!;
  if (!context) throw new Error("ModalView component is not available");
  if (maxViews && maxViews !== context.maxViews) context.setMaxViews(maxViews);
  return context;
}

console.log("Modal Context List", ContextLists);
