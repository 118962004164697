import { useMemo, useState } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import {
  navigate,
  // useLocation
} from "@reach/router";
import { useGetAffiliatesQuery } from "apis";
import { iPhoneSVG } from "assets";
import { Card, CardProps, Group, Icon, Paginator } from "components";
import { useDefaultStyle, useSelector } from "hooks";
import { toQueryString } from "utils";

interface HistoryInvitePanelProps {}

export default function HistoryInvitePanel(props: HistoryInvitePanelProps) {
  // const location = useLocation();

  // The ?${params.toString()} part in the `onClick` event is needed so we can
  // preserve the current tab from screen to screen and back.
  // const params = useMemo(() => new URLSearchParams(location?.search), [location]);

  const handleClick = (id: string | number) => {
    // if (params.has("tab")) {
    //   navigate(`/account/partners/history/${id}?${params.toString()}`);
    //   return;
    // }
    navigate(`/account/partners/history/${id}`);
    return;
  };

  const [state, setState] = useState({
    page: 1,
    limit: 5,
  });

  const filters = useSelector((state) => state.filter.inviteHistory);

  const queries = useMemo(
    () =>
      toQueryString({
        ...state,
        ...filters,
      }),
    [state, filters]
  );

  const { data } = useGetAffiliatesQuery(queries);

  console.log(data, "THE DATA");

  return (
    <Group my="35px">
      {data && data.referrals.length > 0 && (
        <>
          {data?.referrals.map(({ _id, referredUser, registeredOn }) => (
            <PartnerHistoryItem
              key={`history-item-${_id}`}
              onClick={() => handleClick(_id)}
              firstName={referredUser.firstName}
              lastName={referredUser.lastName}
              kycLevel={referredUser.kycLevel}
              registeredOn={registeredOn}
              username={referredUser.username}
            />
          ))}

          <Paginator
            mt="40px !important"
            totalCount={data?.totalCount}
            page={state.page}
            limit={5}
            onPageChange={(page: number) =>
              setState({
                ...state,
                page,
              })
            }
          />
        </>
      )}
    </Group>
  );
}

interface PartnerHistoryItemProps extends CardProps {
  firstName: string;
  lastName: string;
  username: string;
  kycLevel: string;
  registeredOn: string;
}

function PartnerHistoryItem(props: PartnerHistoryItemProps) {
  const { firstName, lastName, username, kycLevel, registeredOn } = props;
  const { shadow, borderColor } = useDefaultStyle();

  return (
    <Card
      as="button"
      w="100%"
      px="34px"
      py="10px"
      border="1px solid transparent"
      _focus={{ shadow, borderColor }}
      mb="10px !important"
      {...props}
    >
      <Box pos="relative">
        <HStack>
          <Text fontSize="14px" fontWeight="500">
            {firstName} {lastName}
          </Text>
          <Text fontSize="14px">@{username}</Text>
        </HStack>

        <HStack mt="18px">
          <Box as={iPhoneSVG} />
          <Text fontSize="14px" fontWeight="500">
            {format(parseISO(registeredOn), "dd-MM-yyyy")}
          </Text>
          <Text textTransform="capitalize" fontSize="14px" color="secondary.500" fontWeight="500">
            Level {kycLevel}
          </Text>
        </HStack>

        <Icon type="arrowRight" pos="absolute" right="0px" top="50%" transform="translateY(-50%)" />
      </Box>
    </Card>
  );
}
