import { Badge, Button, Checkbox, FormControl, FormHelperText, HStack, Stack, useDisclosure, VStack } from "@chakra-ui/react";
import { useGetAirtimePlansQuery, useGetRateQuery, useGetSupportedCountriesQuery } from "apis";
import {
  AmountInput,
  ConditionalRender,
  FormInfo,
  InputLabel,
  Option,
  PhoneInput,
  RadixSelect,
  Select,
  useModalView as useSteps,
} from "components";
import { useWalletBalance } from "hooks";
import { BillPlanProductRo, BillPlanRo } from "interfaces";
import { useCallback, useEffect, useMemo } from "react";
import uniqBy from "lodash/uniqBy";
// import toLower from "lodash/toLower";
import capitalize from "lodash/capitalize";
import toUpper from "lodash/toUpper";
import Input from "components/Input/Input";
import configs from "config";
import { toCoinLocale, toPrecision, when } from "utils";
import AirtimePreviewModal from "ui/Dashboard/Modals/AirtimePreview";
import { useSidePage } from "contexts";
import { useCountryFullInfo, useCurrency } from "hooks/useCurrency";
import { useAirtimeDataState } from "../AirtimeAndData";

interface AirtimeProps {}

export interface IState {
  country: string;
  dialCode: string;
  countryCode: string;
  countryName: string;
  operatorId: string | null;
  operatorInfo: BillPlanRo | null;
  phone: string;
  topupType: string | null;
  amount: string | null;
  wallet: string;
  productId: string;
  productInfo: Partial<BillPlanProductRo>;
  ported: boolean;
  pin: string;
  currrentStep?: number;
}

export default function Airtime(props: AirtimeProps) {
  const { onNext } = useSteps();
  // const stateContext = useAirtimeDataState();
  // const { sharedState, setSharedState, resetSharedState } = stateContext;
  const { data: page_data, params, updateSidePageConfigs } = useSidePage();

  const currency = useCurrency();
  const { iso2 } = useCountryFullInfo();

  const { getBalance } = useWalletBalance();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data: countries, isLoading: isCountriesLoading } = useGetSupportedCountriesQuery("airtime");

  const getCountryByDialCode = useCallback(
    (code?: string) => {
      const country = (countries ?? []).find((c) => c?.dialCode === (code ?? ""));

      return when(
        !!country && !!code,
        {
          ...page_data?.state,
          country: country?.id,
          countryCode: country?.code,
          dialCode: country?.dialCode,
          countryName: country?.name,
          // operatorId: null,
          // operatorInfo: null,
        },
        { ...page_data?.state }
      );
    },
    [countries, page_data]
  );

  const getCountryByIso2 = useCallback(
    (iso2: string) => {
      const country = (countries ?? []).find((c) => (c?.code ?? "").toLowerCase() === iso2);

      if (!country) return {};

      return {
        country: country?.id,
        countryCode: country?.code,
        dialCode: country?.dialCode,
        countryName: country?.name,
      };
    },
    [countries]
  );

  // console.log("State", page_data);
  // const [state, set, reset] = usePartialState<IState>(
  //   {
  //     phone: params?.phone,
  //     ...when(sharedState?.state_for === "airtime", { ...sharedState }, {}),
  //     ...getCountryByDialCode(params?.dialCode),
  //   },
  //   [sharedState]
  // );

  const stateContext = useAirtimeDataState(
    {
      // phone: params?.phone,
      state_for: "airtime",
      ...getCountryByIso2(iso2),
      ...getCountryByDialCode(params?.dialCode),
    },
    [getCountryByIso2, getCountryByDialCode]
  );
  const { sharedState: state, setSharedState: set, resetSharedState: reset } = stateContext;

  // const set = (...arg: Parameters<typeof setState>) => {
  //   setState(...arg);
  //   setSharedState(...arg);
  // };

  // const reset = () => {
  //   resetState();
  //   resetSharedState();
  // };

  // useEffect(() => {
  //   initializeState("airtime")({ phone: params?.phone, ...getCountryByDialCode(params?.dialCode) });
  // }, [initializeState, params, getCountryByDialCode]);

  const { data: plans, isFetching: isPlansLoading } = useGetAirtimePlansQuery(state?.country!, {
    skip: !state?.country,
  });

  const ticker = useMemo(() => `usd${when(!!currency, currency, "ngn")}`, [currency]);
  const { data: rateData /*, isFetching: isUpdatingRate */ } = useGetRateQuery(ticker, { skip: !state?.operatorInfo?.currency });

  const balance = useMemo(() => (!!state?.wallet ? getBalance(state.wallet as any) ?? 0 : 0), [getBalance, state?.wallet]);
  const sortedCountries = useMemo(() => [...(countries ?? [])].sort((a, b) => a?.name?.localeCompare(b?.name)), [countries]);
  const getCountry = (id: string) => (countries ?? []).find((c) => c?.id === id);
  const getOperator = (id: string) => (plans ?? []).find((p) => p?.operatorId === id);
  const getProduct = useCallback(
    (id: string) => (state?.operatorInfo?.products ?? []).find((p) => p?.productId === id),
    [state?.operatorInfo?.products]
  );

  const product = useMemo(() => {
    if (!!state?.operatorInfo?.products) {
      const product = (state?.operatorInfo?.products ?? []).find((p) => p.productId === state?.productId);
      return product;
    }
  }, [state]);

  const getProductPriceInfo = useCallback(
    (productId: string) => {
      const product = uniqBy(state?.operatorInfo?.products, (p) => p.priceType).find((p) => p.productId === productId);

      const map: Record<string, () => Partial<BillPlanProductRo>> = {
        fixed: () => ({
          plan: product?.name,
          operatorPrice: product?.operatorPrice,
          userPrice: product?.userPrice,
        }),
        range: () => ({
          plan: product?.name,
          operatorMaxPrice: product?.operatorMaxPrice,
          operatorMinPrice: product?.operatorMinPrice,
          userMinPrice: product?.userMinPrice,
          userMaxPrice: product?.userMaxPrice,
        }),
        empty: () => ({}),
      };

      // console.log("PRODUCT PRICE INFO", map[product?.priceType ?? "empty"]());

      return map[product?.priceType ?? "empty"]();
    },
    [state?.operatorInfo]
  );

  const cryptoPrice = useMemo(() => {
    if (state?.operatorInfo && state?.amount && rateData?.exchangeRate?.sell && state?.productId && !!product?.priceType) {
      const product = state?.operatorInfo?.products?.find((p) => p.productId === state.productId);
      // return state.amount / rateData?.exchangeRate?.buy;

      const c = +(product?.userMaxPrice ?? 0) / +(product?.operatorMaxPrice ?? 0);

      const calcPrice = (currency: string, priceType: string) => {
        const map = {
          [`${currency}-fixed`]: +(state?.amount ?? 0) / rateData?.exchangeRate?.sell,
          [`${currency}-range`]: (+Number(c).toPrecision(2) * +(state.amount ?? 0)) / rateData?.exchangeRate?.sell,
          "ngn-range": +(state?.amount ?? 0) / rateData?.exchangeRate?.sell,
        };

        return map[`${currency}-${priceType}`];
      };

      return calcPrice(state?.operatorInfo?.currency, product?.priceType as any);
    }
  }, [state?.amount, rateData, state?.operatorInfo, state?.productId, product?.priceType]);

  const handleCountry = (id: string) => {
    const country = getCountry(id);
    set({
      country: id,
      countryCode: country?.code,
      dialCode: country?.dialCode,
      countryName: country?.name,
      operatorId: null,
      operatorInfo: null,
    });
  };

  const handleOperator = (id: string) => {
    const operator = getOperator(id);
    set({ operatorId: id, operatorInfo: operator });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onOpen();
  };

  const handleSeeSavedNumbers = () => {
    if (!!plans) updateSidePageConfigs({ data: { plans } });
    onNext();
  };

  const isLessThanMinimum = useMemo(() => {
    if (!!state?.amount && !!state?.topupType) {
      const info = product;
      const map: Record<string, boolean> = {
        range: +(state?.amount ?? 0) < +(info?.operatorMinPrice ?? 0) || +(state?.amount ?? 0) > +(info?.operatorMaxPrice ?? 0),
      };

      return map[state?.topupType ?? "range"];
    }

    return false;
  }, [state, product]);

  const isDisabled = useMemo(
    () =>
      !(
        state?.country &&
        state?.operatorId &&
        state?.productId &&
        state?.phone &&
        state?.wallet &&
        state?.topupType &&
        state?.amount
      ) ||
      isCountriesLoading ||
      isLessThanMinimum ||
      (!!cryptoPrice && !!balance && cryptoPrice > balance),
    [state, isCountriesLoading, cryptoPrice, balance, isLessThanMinimum]
  );

  useEffect(
    () => {
      if (!state?.country) set({ ...getCountryByIso2(iso2) });
    },
    //eslint-disable-next-line
    [getCountryByIso2, iso2]
  );

  return (
    <Stack as="form" py={{ base: "32px", "1sm": "52px" }} onSubmit={handleSubmit}>
      <VStack
        // templateColumns={{ base: "1fr", "1sm": "repeat(2, 1fr)" }}
        // gap="20px"
        // mb={{ base: "20px !important", "1sm": "44px !important" }}
        mb={{ base: "20px !important", "1sm": "28px !important" }}
        gridGap={{ base: "20px !important", "1sm": "28px !important" }}
      >
        <FormControl>
          <HStack w="100%" justifyContent="space-between">
            <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
            <ConditionalRender shouldRender={!!state?.country}>
              <Button variant="link" fontSize="14px" textDecoration="underline" mt="10px" onClick={handleSeeSavedNumbers}>
                Beneficiaries
              </Button>
            </ConditionalRender>
          </HStack>
          <PhoneInput
            id="phone_input"
            countryValue={state?.country}
            onCountryChange={(value) => handleCountry(value)}
            placeholder="Enter a phone number"
            isDisabled={!state?.country}
            value={state?.phone ?? ""}
            onChange={(e) => set({ phone: e.target.value })}
            _select={{
              use_image_icon: true,
              "aria-label": "Select Country",
              placeholder: "Select Country",
            }}
          >
            {sortedCountries.map((country) => (
              <Option key={`country-${country.id}`} value={country?.id} image={country?.flag}>
                {country.name}
              </Option>
            ))}
          </PhoneInput>
        </FormControl>

        <FormControl display="none">
          <InputLabel isLoading={isCountriesLoading}>Country</InputLabel>
          {/* <Select placeholder="Select Country" value={state?.country ?? ""} onChange={(e) => handleCountry(e.target.value)}>
            {sortedCountries.map((country) => (
              <option key={`country-${country.id}`} value={country.id}>
                {country.name}
              </option>
            ))}
          </Select> */}
          <RadixSelect
            use_image_icon
            aria-label="Select Country"
            placeholder="Select Country"
            value={state?.country}
            // defaultValue={getCountryByIso2(iso2)?.country}
            preload_network_images={sortedCountries.map((sc) => sc?.flag)}
            onChange={(value) => handleCountry(value)}
            _trigger={{ w: "100%", fontSize: { base: "14px", "2sm": "16px" } }}
          >
            {sortedCountries.map((country) => (
              <Option key={`country-${country.id}`} value={country?.id} image={country?.flag}>
                {country.name}
              </Option>
            ))}
          </RadixSelect>
        </FormControl>

        <FormControl>
          <InputLabel isLoading={isPlansLoading}>Mobile Operator</InputLabel>
          {/* <Select
            placeholder="Select Operator"
            isDisabled={!state?.country}
            value={state?.operatorId ?? ""}
            onChange={(e) => handleOperator(e.target.value)}
          >
            {(plans ?? []).map((plan) => (
              <option key={`operator-${plan?.operatorId}`} value={plan?.operatorId}>
                {plan?.name}
              </option>
            ))}
          </Select> */}

          <RadixSelect
            use_image_icon
            aria-label="Operator"
            placeholder="Select Operator"
            preload_network_images={(plans ?? []).map((plan) => plan?.brandLogo)}
            disabled={!state?.country || isPlansLoading}
            value={state?.operatorId as any}
            onChange={(value) => handleOperator(value)}
            _trigger={{ w: "100%", fontSize: { base: "14px", "2sm": "16px" } }}
          >
            {(plans ?? []).map((plan) => (
              <Option key={`operator-${plan?.operatorId}`} value={plan?.operatorId} image={plan?.brandLogo}>
                {plan?.name}
              </Option>
            ))}
          </RadixSelect>
        </FormControl>
      </VStack>

      <VStack
        // templateColumns={{ base: "1fr", "1sm": "repeat(2, 1fr)" }}
        // gap="20px"
        // mb={{ base: "20px !important", "1sm": "44px !important" }}
        mb={{ base: "20px !important", "1sm": "28px !important" }}
        gridGap={{ base: "20px !important", "1sm": "28px !important" }}
      >
        <FormControl>
          <InputLabel>Topup Type</InputLabel>
          <Select
            placeholder="Select topup"
            value={state?.productId ?? ""}
            onChange={(e) =>
              set({
                productId: e.target.value,
                amount: null,
                topupType: getProduct(e.target.value)?.priceType,
                productInfo: getProductPriceInfo(e.target.value),
              })
            }
          >
            {/* {(topupTypes ?? []).map((type, i) => (
              <option key={`topup-type-${i}`} value={toLower(type)}>
                {capitalize(type)}
              </option>
            ))} */}
            {(state?.operatorInfo?.products ?? []).map((product, i) => (
              <option key={`topup-type-${i}`} value={product?.productId}>
                {capitalize(product?.priceType)} - {product?.name}
              </option>
            ))}
          </Select>

          {/* <FormInfo mt="10px" info="Buy range: " description="5 NGN - 50,000 NGN" /> */}
        </FormControl>

        <FormControl display="none">
          <InputLabel>Phone Number</InputLabel>
          <Input
            placeholder="Enter a phone number"
            isDisabled={!state?.country}
            value={state?.phone ?? ""}
            startAdornment={state?.dialCode ? `+${state?.dialCode}` : null}
            onChange={(e) => set({ phone: e.target.value })}
            _startAdornment={{
              pl: when(!!state?.country, "22px", "0px"),
              fontWeight: "medium",
              fontFamily: "var(--bitmama-fonts-heading)",
            }}
            pl={when(!!state?.country, "72px", "18px")}
          />

          <ConditionalRender shouldRender={!!state?.country}>
            <Button variant="link" fontSize="12px" textDecoration="underline" mt="10px" onClick={handleSeeSavedNumbers}>
              See Saved Numbers
            </Button>
          </ConditionalRender>
        </FormControl>
      </VStack>

      {!!state?.topupType && (
        <VStack
          // templateColumns={{ base: "1fr", "1sm": "repeat(2, 1fr)" }} gap="20px"
          mb={{ base: "20px !important", "1sm": "28px !important" }}
          gridGap={{ base: "20px !important", "1sm": "28px !important" }}
        >
          {state?.topupType === "fixed" && (
            <FormControl>
              <InputLabel>Amount</InputLabel>
              <Select
                isInvalid={!!balance && !!cryptoPrice && cryptoPrice > balance}
                isDisabled={!state?.operatorId}
                value={`${state.amount}-${state.productId}` ?? ""}
                onChange={(e) => {
                  const [amount, productId] = String(e.target.value).split("-");
                  set({ amount, productId });
                }}
                placeholder="Select Fixed Amount"
              >
                {state?.operatorInfo?.products
                  ?.filter((p) => p.priceType === "fixed")
                  ?.map((p, i) => (
                    <option key={i} value={`${p.operatorPrice}-${p.productId}`}>
                      {p.name}
                    </option>
                  ))}
              </Select>

              {!!state?.wallet && !!cryptoPrice && !!balance && cryptoPrice > balance && (
                <FormHelperText fontSize="xs" color="red.500" fontWeight="500">
                  Amount exceeds balance in the {toCoinLocale(state?.wallet)} wallet
                </FormHelperText>
              )}
            </FormControl>
          )}

          {state?.topupType === "range" && (
            <FormControl>
              <InputLabel>Amount</InputLabel>
              <AmountInput
                isInvalid={(!!balance && !!cryptoPrice && cryptoPrice > balance) || isLessThanMinimum}
                isDisabled={!state?.operatorId}
                min={+(product?.operatorMinPrice ?? 0)}
                max={+(product?.operatorMaxPrice ?? 0)}
                value={state?.amount ?? ""}
                onChange={(e) => set({ amount: e.target.value, productId: product?.productId })}
                // endAdornment={state?.operatorInfo?.currency ? state?.operatorInfo?.currency : ""}
              />

              {!!state?.wallet && !!cryptoPrice && !!balance && cryptoPrice > balance && (
                <FormHelperText fontSize="xs" color="red.500" fontWeight="500">
                  Amount exceeds balance in the {toCoinLocale(state?.wallet)} wallet
                </FormHelperText>
              )}

              {product && (
                <FormInfo
                  mt="10px"
                  isInvalid={isLessThanMinimum}
                  info={`Buy range: `}
                  description={`${Number(product?.operatorMinPrice ?? 0).toLocaleString()} ${toUpper(
                    state?.operatorInfo?.currency
                  )} -
                ${Number(product?.operatorMaxPrice ?? 0).toLocaleString()} ${toUpper(state?.operatorInfo?.currency)}`}
                  fontSize="sm"
                />
              )}
            </FormControl>
          )}

          <FormControl>
            <InputLabel>Wallet</InputLabel>
            {/* <Select placeholder="Select Wallet" value={state?.wallet ?? ""} onChange={(e) => set({ wallet: e.target.value })}>
              {configs.stableCoins.map((coin) => (
                <option key={`coin-${coin}`} value={coin}>
                  {toCoinLocale(coin)}
                </option>
              ))}
            </Select> */}

            <RadixSelect
              use_coin_icon
              aria-label="Select Wallet"
              placeholder="Select Wallet"
              value={state?.wallet ?? ""}
              onChange={(value) => set({ wallet: value })}
              _trigger={{ w: "100%" }}
            >
              {configs.stableCoins.map((coin) => (
                <Option key={`coin-${coin}`} value={coin} coin={coin as any}>
                  {toCoinLocale(coin)}
                </Option>
              ))}
            </RadixSelect>

            {!!balance && state?.wallet && (
              <FormInfo mt="10px" info="Balance: " description={toPrecision(balance, state.wallet)} fontSize="sm" />
            )}
          </FormControl>
        </VStack>
      )}

      {!!state?.wallet && !!cryptoPrice && (
        <FormControl>
          <HStack mt="20px !important" alignSelf="flex-start">
            <InputLabel>Crypto Price:</InputLabel>
            <Badge fontSize="sm" colorScheme={!!balance && cryptoPrice > balance ? "red" : "secondary"} textTransform="unset">
              {toPrecision(cryptoPrice ?? 0, state?.wallet, true, 2)}
            </Badge>
          </HStack>
        </FormControl>
      )}

      {state?.phone && (
        <HStack mt="14px !important" w="100%" alignItems="flex-start">
          <Checkbox
            size="md"
            isChecked={state?.ported}
            colorScheme="green"
            onChange={(e) => set({ ported: e.target.checked })}
            sx={{
              ".chakra-checkbox__label": {
                fontWeight: "500",
              },
            }}
          >
            This number is ported from another network
          </Checkbox>
        </HStack>
      )}

      <AirtimePreviewModal isOpen={isOpen} onClose={onClose} state={state} set={set} reset={reset} cryptoPrice={cryptoPrice!} />

      <VStack mt="60px !important">
        <Button type="submit" disabled={isDisabled} minW={{ base: "100%", smx: "336px", "4sm": "400px" }}>
          Buy
        </Button>
      </VStack>
    </Stack>
  );
}
