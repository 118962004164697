import { Box, Button, FormControl, FormHelperText, Stack, useColorModeValue, useUpdateEffect, VStack } from "@chakra-ui/react";
import { useWithdrawFromCardMutation } from "apis";
import { AmountInput, FormInfo, InputLabel, Select, Title, TitleBar, useModalView as useSteps } from "components";
import configs, { isTesting } from "config";
import { useSidePage } from "contexts";
import { useCreditCardDetails, useDisclosures, usePartialState } from "hooks";
import { AppAnalyticEvent } from "interfaces";
import { Emitter } from "libs";
import { useEffect, useMemo } from "react";
import { SuccessModal } from "ui";
import SubmitPin from "ui/Common/Modals/SubmitPin";
import { currencyFormat, toCoinLocale, toQueryString, when } from "utils";
import ls from "utils/secureStorage";

interface WithdrawCreditCardProps {}

interface IState {
  wallet: string;
  amount: string;
  pin: string | null;
}

type ModalTypes = "pin" | "success";

export default function WithdrawCreditCard(props: WithdrawCreditCardProps) {
  const minimumAmount = 5;

  // const { shadow, borderColor } = useDefaultStyle();
  const infoColor = useColorModeValue("grey.500", "grey.150");

  const { setCurrentView, hasPrev } = useSteps();
  const [state, set] = usePartialState<IState>({});
  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const { params, onClose, updateSidePageConfigs } = useSidePage();

  const resetPin = () => set({ pin: null });
  const tempPin = useMemo(() => state?.pin ?? ls.get(configs.TEMP_PIN), [state?.pin]);
  const queries = useMemo(() => toQueryString({ p: params?.provider ?? "p2" }), [params]);
  const { card, isFetching, isPinOpen, onPinClose } = useCreditCardDetails(params?.cardId!, tempPin, { queries }, resetPin);

  const [_withdraw, { data, isLoading, isSuccess }] = useWithdrawFromCardMutation();

  const balance = useMemo(() => {
    if (!!card?.internalDetails?.balance && isTesting) return card?.internalDetails?.balance;
    else if (!!card?.balance && !isTesting)
      return when(typeof card?.balance !== "object", +(card.balance ?? 0), (card?.balance as any)?.availableBalance);
    return 0;
  }, [card]);

  const isInsufficientBalance = useMemo(() => !!state?.amount && +(state?.amount ?? 0) > balance, [state?.amount, balance]);

  const isSmallerThanMinimum = useMemo(
    () => !!state?.wallet && !!state?.amount && +(state?.amount ?? 0) < minimumAmount,
    [state, minimumAmount]
  );

  const isDisabled = useMemo(
    () => !(state?.amount && state?.wallet) || isLoading || isInsufficientBalance || isSmallerThanMinimum,
    [state, isLoading, isInsufficientBalance, isSmallerThanMinimum]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    open("pin")();
  };

  const handleWithdraw = async (pin?: string) => {
    close("pin")();
    const reqData = {
      id: params?.cardId,
      coin: state?.wallet,
      amount: state?.amount,
      provider: params?.provider ?? "p2",
      pin: pin ?? state?.pin,
    };

    Emitter.emit(AppAnalyticEvent.CARD_WITHDRAWAL_INITIATED, reqData);
    const result = await _withdraw(reqData).unwrap();
    if (!!result) {
      Emitter.emit(AppAnalyticEvent.CARD_WITHDRAWAL_SUBMITTED, reqData);
    }
  };

  const handleContinue = () => {
    setCurrentView(0);
  };

  useUpdateEffect(() => {
    if (isSuccess && !!data) {
      open("success")();
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (hasPrev) updateSidePageConfigs({ _back: { text: "Back", onClick: () => setCurrentView(0) } });
    // eslint-disable-next-line
  }, []);

  return (
    <Box id="virtual-card-details-wrapper" px="40px" {...props} overflowY="scroll">
      <TitleBar>
        <Title isLoading={isFetching} fontSize="20px">
          Cards
        </Title>
      </TitleBar>

      {/* <HStack mt="24px">
        <Button
          variant="unstyled"
          minW="fit-content"
          minH="fit-content"
          h="fit-content"
          _focus={{ shadow, borderColor }}
          onClick={() => setCurrentView(0)}
        >
          <Icon type="circleLeftArrow" boxSize="20px" />
        </Button>
        <Text fontSize="16px" fontFamily="var(--bitmama-fonts-heading)">
          Withdraw from Card
        </Text>
      </HStack> */}

      <Stack as="form" my="42px" onSubmit={handleSubmit}>
        <FormControl mb="30px !important">
          <InputLabel fontSize="16px">Wallet</InputLabel>
          <Select placeholder="Select Wallet" value={state?.wallet ?? ""} onChange={(e) => set({ wallet: e.target.value })}>
            {configs.stableCoins.map((coin) => (
              <option key={`stable-${coin}-coin`} value={coin}>
                {toCoinLocale(coin)}
              </option>
            ))}
          </Select>

          <Stack mt="10px">
            <FormInfo
              isInvalid={isInsufficientBalance}
              info={`Available Balance: `}
              description={currencyFormat("usd").format(balance)}
            />
          </Stack>
        </FormControl>

        <FormControl mb="30px !important">
          <InputLabel fontSize="16px">Amount</InputLabel>
          <AmountInput
            isRequired
            isInvalid={isInsufficientBalance || isSmallerThanMinimum}
            placeholder="Enter Amount"
            value={state?.amount ?? ""}
            isMaxDisabled={!state?.wallet}
            onMax={() => set({ amount: Number(balance).toFixed(2) })}
            onChange={(e) => set({ amount: e.target.value })}
          />

          {isInsufficientBalance && state?.wallet && (
            <FormHelperText fontSize="sm" color="red.400" fontWeight="500">
              Card balance is insufficient
            </FormHelperText>
          )}

          {state?.wallet && (
            <FormHelperText fontSize="sm" color={when(isSmallerThanMinimum, "red.400", infoColor)} fontWeight="500">
              Minimum withdraw amount is {currencyFormat("usd").format(minimumAmount)}
            </FormHelperText>
          )}
        </FormControl>

        <VStack mt="64px !important">
          <Button maxW="400px" fontFamily="var(--bitmama-fonts-heading)" type="submit" isLoading={isLoading} disabled={isDisabled}>
            Withdraw
          </Button>
        </VStack>
      </Stack>

      <SubmitPin
        isOpen={isOpen("pin")}
        onClose={close("pin")}
        onSubmit={() => handleWithdraw()}
        onComplete={(pin) => handleWithdraw(pin)}
      />
      <SuccessModal
        isOpen={isOpen("success")}
        onClose={close("success")}
        onContinue={handleContinue}
        message={`Your withdrawal will be processed and your wallet will be funded
                  soon.`}
      />

      <SubmitPin
        heading={"Enter pin to access card"}
        subheading={"Enter your 6-digits transaction pin to authorize access this card"}
        isOpen={isPinOpen}
        onClose={() => {
          onPinClose();
          onClose();
        }} // onPin={(pin) => set({ pin })}
        onSubmit={(pin) => {
          set({ pin });
          onPinClose();
        }}
        onComplete={(pin) => {
          set({ pin });
          onPinClose();
        }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        closeOnSubmit={false}
      />
    </Box>
  );
}
