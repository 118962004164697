import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const select: ComponentStyleConfig = {
  variants: {
    filled: (props) => ({
      field: {
        bg: mode("#ebecec", "dark.cardBg")(props),
        borderColor: "transparent",
        borderWidth: "1px",
        px: "20px",
        fontSize: { base: "14px", "2sm": "16px" },
        minH: { base: "56px", "2sm": "60px" },
        borderRadius: "10px",
        fontFamily: "var(--bitmama-fonts-heading)",
        _placeholder: {
          color: "#A1A3A2",
          fontFamily: "var(--bitmama-fonts-body)",
        },
        _focus: {
          borderColor: mode("primary.600", "secondary.600")(props),
          borderWidth: "1.4px",
          shadow: mode("outline", "darkOutline")(props),
        },
        _hover: {
          borderColor: mode("primary.600", "secondary.600")(props),
          borderWidth: "1.4px",
          // bg: mode("outline", "darkOutline")(props),
        },
      },
      element: {
        h: "100%",
        w: 14,
        color: "#A1A3A2",
      },
    }),
  },

  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "filled",
  } as any,
};

export default select;
