import { TabPanels as Panels, TabPanelsProps as PanelsProps } from "@chakra-ui/react";
import SwipeableViews from "react-swipeable-views";

export interface TabPanelsProps extends PanelsProps {
  index?: number;
  onChangeIndex?: (index: number) => void;
}

export default function TabPanels(props: TabPanelsProps) {
  return (
    <Panels as={SwipeableViews as any} {...props} w="80%" mx="auto">
      {props.children}
    </Panels>
  );
}
