import { useGetBestRateQuery, useGetCoinToUsdRateQuery } from "apis";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { fetchBestRates } from "store/slices";
import useDispatch from "./useDispatch";
// import useSelector from "./useSelector";

import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { when } from "utils";
// import { selectCountryCurrency } from "utils";

const reMapCoin = (coin: string) => {
  const map: Record<string, string> = {
    [coin]: coin,
    tbtc: "btc",
    teth: "eth",
    usdt: "usd",
    cusd: "usd",
  };
  return map[coin];
};

const adsTypeMap = (type: string) => {
  const map: Record<string, string> = {
    selling: "buy",
    buying: "sell",
  };
  return map[type];
};

export function useP2PBestRates(coin: string, currency: string, type: "buying" | "selling") {
  const dispatch = useDispatch();
  //   const { profile } = useSelector(selectUser);
  // const { bestRate: rates } = useSelector(selectTrade);
  // const { status, totalCount, data } = rates[type];

  const params = useRef({});

  const { data: best, isLoading } = useGetBestRateQuery({ type, coin, currency });

  console.log("Best Rate", best);

  // Pick a random rate between dynamic and fixed best rate.
  const random_pick = useMemo(() => {
    const rand = Math.floor(Math.random() * 2);
    // console.log("Rand", rand);
    return when(Boolean(rand), "dynamic", "fixed");
  }, []);

  const alter_rand_pick = useMemo(() => {
    const map: Record<string, string> = {
      dynamic: "fixed",
      fixed: "dynamic",
    };
    return map[random_pick];
  }, [random_pick]);

  const the_best_one_at_random = useMemo(() => {
    const pick = (best ?? {})[random_pick];
    if (!!pick) return pick;
    else return (best ?? {})[alter_rand_pick];
  }, [best, random_pick, alter_rand_pick]);

  console.log("Random Rate", random_pick, the_best_one_at_random);

  // const isLoading = useMemo(() => status === "fetching" || status === "loading", [status]);

  const { data: priceRate } = useGetCoinToUsdRateQuery(reMapCoin(coin ?? "btc"), { skip: (coin ?? "btc").includes("usd") });

  //   const currency = selectCountryCurrency(profile?.country ?? "ngn");
  //   const { data: fiatRate } = useGetRateQuery(`usd${currency}`, { skip: !currency || !coin.includes("usd") });

  const exRate = useMemo(() => {
    const r = priceRate?.exchangeRate ?? {};
    if (!isEmpty(r)) return (r as any)[adsTypeMap(type ?? "buying")] as number;
    return 1;
  }, [type, priceRate]);

  //   const fiatExRate = useMemo(() => {
  //     const r = fiatRate?.exchangeRate ?? {};
  //     if (!isEmpty(r)) return (r as any)[adsTypeMap(type ?? "buying")] as number;
  //     return 1;
  //   }, [type, fiatRate]);

  //   console.log("Fiat Rate", fiatRate);

  // const bestRates = useMemo(() => {
  //   const withUnitCost = (data ?? [])?.map((rate) => {
  //     const unitCost = (() => {
  //       if (rate?.dynamic) return coin?.includes("usd") ? 1 * (rate?.price ?? 0) : exRate * (rate?.price ?? 0);
  //       return rate?.tradeOption?.unitCost;
  //     })();

  //     return { ...rate, unitCost };
  //   });

  //   return withUnitCost?.sort((a, b) => a?.unitCost - b?.unitCost);
  // }, [coin, data, exRate]);

  const bestRates = useMemo(() => {
    const withUnitCost = [the_best_one_at_random]?.map((rate) => {
      const unitCost = (() => {
        if (rate?.dynamic) return coin?.includes("usd") ? 1 * (rate?.price ?? 0) : exRate * (rate?.price ?? 0);
        return rate?.tradeOption?.unitCost;
      })();

      return { ...rate, unitCost };
    });

    return withUnitCost?.sort((a, b) => a?.unitCost - b?.unitCost);
  }, [coin, the_best_one_at_random, exRate]);

  const getRates = useCallback(() => dispatch(fetchBestRates({ coin, type, currency })), [coin, currency, type, dispatch]);

  useEffect(() => {
    if (!isEqual(params.current, { coin, currency, type })) {
      getRates();
      // console.log("Triggered Get Rates", params.current, { coin, currency, type });
      params.current = { coin, currency, type };
    }
  }, [getRates, params, coin, currency, type]);

  return { isLoading, totalCount: bestRates?.length, bestRates };
}
