import { Box } from "@chakra-ui/react";
import { Steps } from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { useMemo } from "react";
import { AllTransactions } from "./AllTransactions";
import Deposit from "./Deposit";
import CryptoPanels from "./Panels";
import Withdraw from "./Withdraw";

interface CryptoProps extends SidePageViewProps {
  context?: string;
}

export default function Crypto(props: CryptoProps) {
  const { params } = useSidePage();

  const index = useMemo(() => {
    const map: Record<string, number> = {
      panels: 0,
      withdraw: 1,
      deposit: 2,
      allTx: 3,
    };

    const view = params?.view ?? "panels";
    return map[view];
  }, [params]);

  // useEffect(() => {
  //   if(!!params?.view) setCurrentView()
  // }, [])

  console.log("Params", params, index);

  return (
    <Box overflowX="hidden">
      <Steps
        hideIndicator
        onStepsLoaded={({ setCurrentView }) => {
          if (!!params?.view) setCurrentView(index);
        }}
      >
        <CryptoPanels />
        <Withdraw />
        <Deposit />
        <AllTransactions />
      </Steps>
    </Box>
  );
}

Crypto.displayName = "SidePageView";
