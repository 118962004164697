import { Box, HStack, Stack, Text, VStack, Skeleton, BoxProps, Heading, useColorModeValue, useColorMode } from "@chakra-ui/react";

// import { BsShieldLockFill } from "react-icons/bs";
import { CreditCardChipSVG, CreditCardOrnamentSVG } from "assets";

import chunk from "lodash/chunk";
import Card, { CardProps } from "components/Card/Card";
import { useCreditCardScheme, useDefaultStyle } from "hooks";
import Logo from "components/Logo/Logo";
import { useMemo } from "react";
import { currencyFormat, switchStyle, when } from "utils";

type CardType = "tier1" | "tier2" | "tier3" | "tier4" | "tier5";

interface CreditCardProps extends CardProps {
  type?: CardType;
  number?: string;
  name?: string;
  isLoading?: boolean;
  validity?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
}

interface CardValidityProps extends BoxProps {
  type: CardType;
  validity: string;
  isLoading?: boolean;
}

interface CreditCardAndInfoProps extends Omit<CreditCardProps, "number" | "validity" | "isLoading"> {
  showLimit?: boolean;
  info?: string;
}

function CardValidatity(props: CardValidityProps) {
  const { type, validity, isLoading, ...xprops } = props;

  const { scheme } = useCreditCardScheme(type);

  return (
    <HStack color={scheme.foreground} {...xprops}>
      <Stack alignSelf="flex-end">
        <Text fontSize="7px" fontWeight="500">
          VALID
        </Text>
        <Text fontSize="7px" fontWeight="500" m="0 !important">
          THRU
        </Text>
      </Stack>

      <VStack alignSelf="flex-end">
        <Skeleton mb={isLoading ? "2px !important" : "0 !important"} isLoaded={!isLoading} h={isLoading ? "8px" : "fit-content"}>
          <Text fontSize="6px" fontWeight="500">
            MONTH/YEAR
          </Text>
        </Skeleton>
        <Skeleton mt="0 !important" isLoaded={!isLoading} h={isLoading ? "12px" : "fit-content"}>
          <Text fontSize="sm" fontWeight="600" m="0 !important" lineHeight="16px">
            {validity ?? "00/00"}
          </Text>
        </Skeleton>
      </VStack>
    </HStack>
  );
}

export default function CreditCardV1(props: CreditCardProps) {
  const { type = "tier1", name, number, validity, isSelected = false, isLoading } = props;

  const { colorMode } = useColorMode();

  const { shadow, borderColor } = useDefaultStyle();
  const { scheme } = useCreditCardScheme(type);
  const border = when(type === "tier1", `1px solid ${scheme.borderColor}`, "initial");

  const borderWithSelection = switchStyle(colorMode, {
    light: when(isSelected, `4px solid var(--bitmama-colors-primary-500)`, border),
    dark: when(isSelected, `4px solid var(--bitmama-colors-secondary-300)`, border),
  });

  return (
    <Card
      as="button"
      w="342px"
      h="218px"
      bg={scheme.background}
      border={borderWithSelection}
      // borderColor={`${scheme.borderColor} !important`}
      pos="relative"
      overflow="hidden"
      borderRadius="16px"
      outline="1px solid transparent"
      _focus={{ shadow, borderColor }}
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      p="24px"
      zIndex="2"
    >
      <HStack w="100%" justifyContent="space-between" pos="relative" zIndex={2}>
        <Box pos="relative">
          <Logo color={scheme.foreground} fontSize="32px" _name={{ w: "3.8pc", ml: "4px !important" }} />
          <Text color={scheme.foreground} fontSize="8px" pos="absolute" fontWeight="500" left="36px" top="24px">
            Virtual Card
          </Text>
        </Box>

        <Box as={CreditCardChipSVG} />
      </HStack>

      <HStack mt="20px" fontFamily="'Credit Card', sans-serif" justifyContent="space-between" w="100%">
        {chunk(number ?? "1234567890234567", 4).map((cc) => (
          <Skeleton mt="0 !important" isLoaded={!isLoading} fontFamily="inherit">
            <Text color={scheme.foreground} fontFamily="inherit" m="0 !important" textShadow="0px 2px 2px rgba(0, 0, 0, 0.497979)">
              {cc}
            </Text>
          </Skeleton>
        ))}
      </HStack>

      <HStack w="100%" pos="relative">
        <Text color={scheme.foreground} textTransform="uppercase" fontSize="12px" fontWeight="500">
          {name ?? `tobi john`}
        </Text>

        <CardValidatity type={type} pos="absolute" top="-30px" right="64px" isLoading={isLoading} validity={validity ?? "00/00"} />
      </HStack>

      <Box zIndex={1} as={CreditCardOrnamentSVG} pos="absolute" right="0" top="0" color={scheme.ornament} />
    </Card>
  );
}

export function CreditCardAndInfo(props: CreditCardAndInfoProps) {
  const { type, name, isSelected, showLimit, info, ...xprops } = props;

  const hcolor = useColorModeValue("primary.700", "secondary.500");
  const tcolor = useColorModeValue("grey.500", "white");

  const limit = useMemo(() => {
    const map: Record<CardType, number> = {
      tier1: 5000,
      tier2: 10000,
      tier3: 30000,
      tier4: 50000,
      tier5: 100000,
    };

    return map[type ?? "tier1"];
  }, [type]);

  return (
    <Stack w="fit-content" {...(xprops as any)}>
      <CreditCardV1 type={type} name={name} isSelected={isSelected} />

      <VStack mt="16px !important">
        <Heading color={hcolor} as="h6" fontSize="14px" fontWeight="600">
          {info ?? "Transaction limit"}
        </Heading>
        {showLimit && (
          <Text color={tcolor} fontSize="14px" fontWeight="500">
            {`${currencyFormat("usd").format(limit)} per month per card`}
          </Text>
        )}
      </VStack>
    </Stack>
  );
}

// const Card = ({
//   colorScheme,
//   limit,
//   validity,
//   number,
//   name,
//   balance,
//   currency,
//   showBalance,
//   onClick,
//   isFrozen,
//   isLoading,
//   isUnfreezing,
//   onUnfreeze,
//   ...xprops
// }) => {
//   const { isOpen, onClose, onOpen } = useDisclosure();

//   const handleClick = () => {
//     onClick && onClick({ limit, colorScheme });
//   };

//   const handleUnfreeze = () => {
//     onClose();
//     onUnfreeze && onUnfreeze();
//   };

//   return (
//     <Box m="0 !important" w={["100%", "100%", "340px"]} {...xprops}>
//       <Button
//         w={["100%", "100%", "340px"]}
//         h={["216px"]}
//         border="1px solid transparent"
//         borderColor={`${getColorScheme()?.borderColor} !important`}
//         borderRadius="12px"
//         p="24px 28px"
//         // bgImage="url('/assets/png/virtual-card-bg.png')"
//         pos="relative"
//         overflow="hidden"
//         bg={getColorScheme()?.background}
//         variant="unstyled"
//         _focus={{ shadow: "0 0 0 3px var(--focusColor)" }}
//         onClick={isFrozen ? undefined : handleClick}
//         cursor={isFrozen ? "auto" : "pointer"}
//       >
//         {isFrozen && (
//           <Box
//             pos="absolute"
//             w="100%"
//             h="100%"
//             bg="#000000d1"
//             zIndex="9"
//             top="0"
//             left="0"
//           >
//             <VStack
//               h="100%"
//               justifyContent="flex-end"
//               alignItems="center"
//               pos="relative"
//             >
//               <Tag
//                 size="md"
//                 variant="subtle"
//                 colorScheme="red"
//                 pos="absolute"
//                 right="20px"
//                 top="20px"
//               >
//                 <TagLeftIcon boxSize="12px" as={BsShieldLockFill} />
//                 <TagLabel>Frozen</TagLabel>
//               </Tag>
//               <Box py="28px">
//                 <CustomButton
//                   disabled={isUnfreezing ?? false}
//                   isLoading={isUnfreezing ?? false}
//                   onClick={onOpen}
//                   bg="red.500"
//                   _hover={{ shadow: "0 0 0 3px #f56565" }}
//                   _active={{ shadow: "0 0 0 3px #f56565" }}
//                   _focus={{ shadow: "0 0 0 3px #f56565" }}
//                 >
//                   Unfreeze
//                 </CustomButton>
//               </Box>
//             </VStack>
//           </Box>
//         )}
//         <Image
//           pointerEvents="none"
//           src={map}
//           pos="absolute"
//           top="30%"
//           left="0"
//         />
//         <HStack
//           alignItems={showBalance ? "flex-start" : "center"}
//           justifyContent="space-between"
//           pos="relative"
//           zIndex="2"
//         >
//           <Skeleton isLoaded={!isLoading}>
//             <Box as={VirtualCardLogo} color={getColorScheme()?.foreground} />
//           </Skeleton>
//           {!balance && !showBalance && (
//             <Skeleton isLoaded={!isLoading}>
//               <Box as={VirtualCardChip} />
//             </Skeleton>
//           )}
//           {(balance === 0 || balance > 0) && showBalance && (
//             <VStack
//               justifyContent="flex-end"
//               alignItems="flex-end"
//               color={getColorScheme()?.foreground}
//             >
//               <Skeleton
//                 mb={isLoading ? "4px !important" : "0 !important"}
//                 h={isLoading ? "8px" : "fit-content"}
//                 isLoaded={!isLoading}
//               >
//                 <Text fontSize="xs" textShadow="0px 2px 2px rgba(0, 0, 0, 0.2)">
//                   Balance
//                 </Text>
//               </Skeleton>
//               <Skeleton
//                 mt="0 !important"
//                 isLoaded={!isLoading}
//                 h={isLoading ? "12px" : "fit-content"}
//               >
//                 <Text fontSize="sm" textShadow="0px 2px 2px rgba(0, 0, 0, 0.2)">
//                   {((balance === 0 || balance > 0) &&
//                     currency &&
//                     `${formatter(currency).format(balance)}`) ??
//                     "0"}
//                 </Text>
//               </Skeleton>
//             </VStack>
//           )}
//         </HStack>

//         <Stack
//           mt="40px"
//           pos="relative"
//           zIndex="2"
//           color={getColorScheme()?.foreground}
//         >
//           <HStack
//             fontFamily="'Credit Card', sans-serif"
//             justifyContent="space-between"
//             w="100%"
//           >
//             {chunk(number ?? "1234567890234567", 4).map((cc) => (
//               <Skeleton
//                 mt="0 !important"
//                 isLoaded={!isLoading}
//                 fontFamily="inherit"
//               >
//                 <Text
//                   fontFamily="inherit"
//                   m="0 !important"
//                   textShadow="0px 2px 2px rgba(0, 0, 0, 0.2)"
//                 >
//                   {cc}
//                 </Text>
//               </Skeleton>
//             ))}
//           </HStack>

//           <HStack
//             justifyContent="space-between"
//             mt="24px !important"
//             textShadow="0px 0px 1px rgba(0, 0, 0, 0.5)"
//           >
//             <Skeleton
//               mt="0 !important"
//               isLoaded={!isLoading}
//               h={isLoading ? "12px" : "fit-content"}
//             >
//               <Text fontSize="10px" fontWeight="500">
//                 {toUpper(name ?? "YOUR NAME HERE")}
//               </Text>{" "}
//             </Skeleton>

//             <HStack>
//               <Stack alignSelf="flex-end">
//                 <Text fontSize="7px" fontWeight="500">
//                   VALID
//                 </Text>
//                 <Text fontSize="7px" fontWeight="500" m="0 !important">
//                   THRU
//                 </Text>
//               </Stack>

//               <VStack alignSelf="flex-end">
//                 <Skeleton
//                   mb={isLoading ? "2px !important" : "0 !important"}
//                   isLoaded={!isLoading}
//                   h={isLoading ? "8px" : "fit-content"}
//                 >
//                   <Text fontSize="6px" fontWeight="500">
//                     MONTH/YEAR
//                   </Text>
//                 </Skeleton>
//                 <Skeleton
//                   mt="0 !important"
//                   isLoaded={!isLoading}
//                   h={isLoading ? "12px" : "fit-content"}
//                 >
//                   <Text
//                     fontSize="sm"
//                     fontWeight="600"
//                     m="0 !important"
//                     lineHeight="16px"
//                   >
//                     {validity ?? "00/00"}
//                   </Text>
//                 </Skeleton>
//               </VStack>
//             </HStack>
//           </HStack>
//         </Stack>
//       </Button>

//       {limit && (
//         <Stack py="16px" borderBottom="1px solid  #D9DBE94f">
//           <Text
//             color="#595B67"
//             lineHeight="22px"
//             fontWeight="500"
//             fontSize="13px"
//           >
//             Transaction limit
//           </Text>
//           <HStack mt="0 !important" justifyContent="space-between">
//             <Text color="#868894" fontSize="13px" fontWeight="500">
//               {formatter("usd").format(limit)} per month per card
//             </Text>
//             <IconButton
//               aria-label="right arrow"
//               size="sm"
//               borderRadius="50%"
//               bg="green.400"
//               _hover={{ bg: "green.600" }}
//               _active={{ bg: "green.600" }}
//               _focus={{
//                 bg: "green.600",
//                 shadow: "0 0 0 3px var(--focusColor)",
//               }}
//               icon={<Box color="white" fontSize="md" as={ChevronRightIcon} />}
//               onClick={handleClick}
//             />
//           </HStack>
//         </Stack>
//       )}

//       <Confirmation
//         isOpen={isOpen}
//         onClose={onClose}
//         onConfirm={handleUnfreeze}
//         title="Confirm Card Unfreeze"
//       >
//         <VStack>
//           <Text>Are you sure you want to unfreeze this card?</Text>
//         </VStack>
//       </Confirmation>
//     </Box>
//   );
// };

// export default Card;
