import { Box, Button, FormControl, Heading, Text, VStack } from "@chakra-ui/react";
import { useGetContactsQuery, useSaveContactMutation } from "apis";
import { RocketSVG } from "assets";
import { BackButton, InputLabel, Steps, useAbstractModal, useModalView } from "components";
import Input from "components/Input/Input";
import { usePartialState } from "hooks";

import toLower from "lodash/toLower";
import { useMemo } from "react";
import { when } from "utils";
import { useAirtimeDataState } from "../Features/AirtimeAndData/AirtimeAndData";

interface Props {
  description: string;
  state: Partial<any>;
  set: (update: Partial<any>) => void;
  isOpen: boolean;
  onClose: () => void;
  reset: () => void;
  currentView?: number;
  setCurrentView?: (e: number) => void;
}

export function AbstractModalSuccessView(props: Props) {
  return (
    <Steps hideIndicator>
      <Success {...props} />
      <AddBeneficiary {...props} />
      <BeneficiaryAddedSuccess {...props} />
    </Steps>
  );
}

AbstractModalSuccessView.displayName = "AbstractModalSuccessView";

function Success(props: Props) {
  const { description, onClose } = props;
  const { onNext } = useModalView();
  const { updateModal } = useAbstractModal();

  const { sharedState: state, resetSharedState: reset } = useAirtimeDataState();

  const { data } = useGetContactsQuery({});
  const contacts = useMemo(() => data?.data ?? [], [data]);

  const alreadyExist = useMemo(
    () => (contacts ?? []).findIndex((c) => c?.contactNumber?.includes((state?.phone ?? "").replace(/^0+/, ""))) !== -1,
    [contacts, state]
  );

  const closeModal = () => {
    reset(["country", "countryCode", "countryName", "dialCode", "phone"], "pick");
    onClose();
    updateModal({ isSuccess: false });
  };

  return (
    <VStack p={{ base: "20px 24px", "2sm": "48px" }}>
      <Box mb="24px !important" as={RocketSVG} />

      <Heading
        mt={{ base: "0 !important", "2sm": "initial" }}
        as="h4"
        fontSize="24px"
        fontWeight="600"
        mb={{ base: "20px !important", "2sm": "30px !important" }}
      >
        Success
      </Heading>

      <VStack mt="0 !important">
        <Text
          maxW="320px"
          mb={{ base: "20px !important", "2sm": "40px !important" }}
          color="brand.greyText"
          fontWeight="500"
          textAlign="center"
        >
          {description}
        </Text>
      </VStack>

      <VStack
        px={{ base: "20px", "2sm": "108px" }}
        mb={{ base: "20px !important", "2sm": "40px !important" }}
        w="100%"
        justifyContent="center"
      >
        {!alreadyExist && (
          <Button variant="solid" minW="unset" maxW="unset" w="100%" onClick={onNext}>
            Save as Beneficiary
          </Button>
        )}

        <Button variant={when(alreadyExist, "solid", "transparent")} minW="unset" maxW="unset" w="100%" onClick={closeModal}>
          Close
        </Button>
      </VStack>
    </VStack>
  );
}

function AddBeneficiary(props: Props) {
  const { state, reset, onClose } = props;

  const { updateModal } = useAbstractModal();
  const { onPrev, onNext } = useModalView();

  const [bstate, set] = usePartialState<{ name: string; phone: string }>();

  const [saveContact, { isLoading, reset: resetApiState }] = useSaveContactMutation();

  const closeModal = () => {
    reset();
    onClose();
    updateModal({ isSuccess: false });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const phoneNumber = `+${state?.dialCode}${state?.phone?.replace(/^0+/, "")}`;
    const reqData = {
      contactName: bstate?.name!,
      billsType: "airtime",
      country: toLower(state?.countryCode),
      phone: phoneNumber,
      network: toLower(state?.operatorInfo?.name),
      ported: state?.ported ?? false,
    };
    console.log("Beneficiary Data", reqData);

    try {
      const result = await saveContact(reqData).unwrap();
      if (!!result) {
        console.log("Saved Contact", result);
        // closeModal();
        onNext();
        resetApiState();
      }
    } catch (error) {
      console.log("Error saving contact", error);
    }
  };

  const isDisabled = useMemo(
    () => !(bstate?.name && state?.countryCode && state?.phone && state?.operatorInfo?.name) || isLoading,
    [bstate, state, isLoading]
  );

  return (
    <VStack as="form" p={{ base: "20px 24px", "2sm": "48px" }} onSubmit={handleSubmit}>
      <BackButton onClick={onPrev} />

      <Heading
        mt={{ base: "0 !important", "2sm": "initial" }}
        as="h4"
        fontSize="24px"
        fontWeight="600"
        mb={{ base: "20px !important", "2sm": "30px !important" }}
      >
        Add Beneficiary
      </Heading>

      <FormControl mb="16px !important">
        <InputLabel>Name</InputLabel>
        <Input placeholder="e.g Kelvin" value={bstate?.name} onChange={(e) => set({ name: e.target.value })} />
      </FormControl>

      <FormControl mb="16px !important">
        <InputLabel>Phone Number</InputLabel>
        <Input disabled placeholder="e.g Kelvin" value={`${state?.dialCode ?? ""}${state?.phone}`} />
      </FormControl>

      <VStack
        mt="44px !important"
        px={{ base: "20px", "2sm": "108px" }}
        mb={{ base: "20px !important", "2sm": "40px !important" }}
        w="100%"
        justifyContent="center"
      >
        <Button variant="solid" minW="unset" maxW="unset" w="100%" type="submit" isLoading={isLoading} isDisabled={isDisabled}>
          Save Beneficiary
        </Button>

        <Button variant="transparent" minW="unset" maxW="unset" w="100%" onClick={closeModal}>
          Close
        </Button>
      </VStack>
    </VStack>
  );
}

function BeneficiaryAddedSuccess(props: Props) {
  const { reset, onClose } = props;

  const { updateModal } = useAbstractModal();

  const closeModal = () => {
    reset();
    onClose();
    updateModal({ isSuccess: false });
  };

  return (
    <VStack p={{ base: "20px 24px", "2sm": "48px" }}>
      <Box mb="24px !important" as={RocketSVG} />

      <Heading
        mt={{ base: "0 !important", "2sm": "initial" }}
        as="h4"
        fontSize="24px"
        fontWeight="600"
        mb={{ base: "20px !important", "2sm": "30px !important" }}
      >
        Success
      </Heading>

      <VStack mt="0 !important">
        <Text
          maxW="320px"
          mb={{ base: "20px !important", "2sm": "40px !important" }}
          color="brand.greyText"
          fontWeight="500"
          textAlign="center"
        >
          Beneficiary was successfully added
        </Text>
      </VStack>

      <VStack
        px={{ base: "20px", "2sm": "108px" }}
        mb={{ base: "20px !important", "2sm": "40px !important" }}
        w="100%"
        justifyContent="center"
      >
        <Button variant="solid" minW="unset" maxW="unset" w="100%" onClick={closeModal}>
          Close
        </Button>
      </VStack>
    </VStack>
  );
}
