import { Box, Button, Heading, Image, ModalProps, Text, VStack } from "@chakra-ui/react";
import { AbstractModal } from "components";
import { useColor } from "hooks";
import CardArt from "assets/images/card_art.png";
const patternUrl: string = require("../../../assets/svg/greenbox-preclaim-pattern.svg").default;

interface Props extends Omit<ModalProps, "children"> {}

export function CardFeatureAvalability(props: Props) {
  const { isOpen, onClose } = props;

  const color = useColor();

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "540px",
        borderRadius: "0px",
        position: { base: "absolute", "1sm": "initial" },
        padding: "20px",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
        overflow: "hidden",
        bg: `url("${patternUrl}")`,
        bgColor: "primary.default",
        backgroundSize: "cover",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <Box minH="570px" bg={color("white", "dark.bg")} borderRadius={{ base: "30px 30px 0px 0px", "1sm": "14px" }}>
        <VStack p="30px">
          <Box
            bg={color("#000", "white")}
            borderRadius="14px"
            overflow="hidden"
            w="100%"
            h="220px"
            display="flex"
            // justifyContent="center"
          >
            <Image src={CardArt} alt="card art" objectFit="contain" h="100%" />
          </Box>

          <Box py="16px">
            <Heading as="h6" my="10px" fontSize="lg" fontFamily="var(--bitmama-fonts-heading)" textAlign="center">
              Card Funding is Unavailable
            </Heading>

            <Box my="30px">
              <Text fontWeight="500" fontSize="18px" textAlign="center">
                Bitmama card funding is temporarily unavailable
              </Text>

              {/* <List>
                <Item>Funding your PYUSD wallet through the Buy feature.</Item>
                <Item>Fund your virtual card</Item>
              </List> */}
            </Box>
          </Box>

          <VStack mt="12px !important">
            <Button onClick={onClose}>Okay</Button>
          </VStack>
        </VStack>
      </Box>
    </AbstractModal>
  );
}

// interface ItemProps extends ListItemProps {}

// function Item(props: ItemProps) {
//   const { children } = props;
//   return (
//     <ListItem my="14px">
//       <HStack alignItems="flex-start">
//         {/* <Icon type="filledCards" transform="rotate(-27deg)" minW="34px" /> */}
//         <Text>📌</Text>
//         <Text fontWeight="500">{children}</Text>
//       </HStack>
//     </ListItem>
//   );
// }
