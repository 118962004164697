import { useMemo } from "react";
import { Box, Text, Grid, FormControl, Select, Stack, Heading, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { Card, Icon, Group, InputLabel } from "components";
import { useGetAffiliateTransactionsQuery } from "apis";
import { toQueryString } from "utils";
import { HistoryDetailsModal } from "ui";
import { useSelector } from "hooks";

interface TransactionItemListProps {
  id: string;
}

export function TransactionItemList({ id }: TransactionItemListProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { afterDate, beforeDate } = useSelector((state) => state.filter.historyDetails);

  const queries = useMemo(
    () =>
      toQueryString({
        page: 1,
        limit: 10,
        referredUserId: id,
        beforeDate: beforeDate ? beforeDate : undefined,
        afterDate: afterDate ? afterDate : undefined,
      }),
    [afterDate, beforeDate, id]
  );

  const handleOpen = () => {
    onOpen();
  };

  const { data } = useGetAffiliateTransactionsQuery(queries);

  return (
    <>
      <Group my="28px">
        <Heading as="h5" fontSize="18px">
          Transaction History
        </Heading>

        <Stack mt="36px !important">
          <FormControl>
            <InputLabel htmlFor="filter">Filter by</InputLabel>
            <Select id="filter" placeholder="Filter by" onChange={handleOpen}>
              <option value="date">Date</option>
            </Select>
          </FormControl>

          {/* <VStack mt="32px !important">
                <Button>Apply</Button>
                <Button variant="outline">Cancel</Button>
              </VStack> */}
        </Stack>

        <HistoryDetailsModal isOpen={isOpen} onClose={onClose} />

        <Group mt="30px !important">
          {data &&
            data.earnings.map((res: any, i: number) => (
              <TransactionItem
                amount={res?.value?.$numberDecimal}
                createdAt={res?.createdAt}
                description={res?.description}
                unit={res?.unit}
                key={`tx-${i}`}
              />
            ))}
        </Group>
      </Group>
    </>
  );
}

interface TransactionItemProps {
  description: string;
  unit: string;
  createdAt: string;
  amount: string;
}

function TransactionItem(props: TransactionItemProps) {
  const { description, unit, createdAt, amount } = props;
  const borderColor = useColorModeValue("border", "dark.border");

  return (
    <Card
      w="100%"
      p="20px 20px"
      borderRadius="0"
      bg="transparent"
      border="1px solid transparent"
      borderBottomColor={borderColor}
      shadow="none"
    >
      <Grid templateColumns=".05fr .4fr .4fr .1fr" justifyContent="space-between" alignItems="center">
        <Box boxSize="10px" borderRadius="50%" bg="primary.400" />

        <Box>
          <Text fontSize="14px" fontWeight="500" textTransform="capitalize">
            {description}
          </Text>
          <Text fontSize="12px" color="primary.500">
            {createdAt && format(parseISO(createdAt), "MMMM e, yyyy")}
          </Text>
        </Box>

        <Text textTransform="uppercase">
          {amount} {unit}
        </Text>

        <Icon type="arrowRight" />
      </Grid>
    </Card>
  );
}
