import { useCallback, useEffect, useMemo, useState } from "react";
import useInlineScript from "./useInlineScript";
import configs from "config";
import { IAppAnalyticEventTypes } from "libs";

// type AttachmentObjectType = { webengage: any };

export interface WebEngage {
  init(license: string): void;
  track(eventName: keyof IAppAnalyticEventTypes, attributes: any): void;
  user: {
    getAnonymousId(): string;
    identify(id: string): void;
    login(id: string): void;
    logout(): void;
    setAtrribute(name: string, value: any): void;
    setToken(token: string): void;
  };
}

const getScript = (license: string) => `
   var webengage;!function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),
arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="webPersonalization feedback survey notification notificationInbox".split(z),c="options render clear abort".split(z),p="Prepare Render Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],__v:"6.0",user:{}},i=0;i < l.length;i++)o(r,[l[i]]);for(i=0;i < a.length;i++){for(r[a[i]]={},s=0;s < c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s < p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i < u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://ssl.widgets.webengage.com":"http://cdn.widgets.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}(window,document,"webengage");webengage.init("14507d112");
`;

export function useInstallWebengage() {
  const [is_installed, setInstalled] = useState(false);
  const script = getScript(configs.WEB_ENGAGE_LICENSE);

  //   console.log("WEB_ENGAGE_LICENSE", configs.WEB_ENGAGE_LICENSE);

  const { injectScript } = useInlineScript(script, "webengage", true);

  useEffect(() => {
    if (!is_installed) {
      injectScript({
        id: "_webengage_script_tag",
        inlineScript: script,
      });

      setInstalled(true);
    }
  }, [is_installed, injectScript, script]);

  const webengage: WebEngage = useMemo(() => {
    if (!is_installed) return {};
    const source = (window as any)?.webengage;
    return source;
  }, [is_installed]);

  const getWebengage = useCallback(() => {
    console.log("Webengage is installed", is_installed);
    if (!is_installed) return null;
    const source = (window as any)?.webengage;
    return source as WebEngage;
  }, [is_installed]);

  return { webengage, getWebengage };
}

export function getWebengage() {
  const source = (window as any)?.webengage;
  if (!source) return null;
  return source as WebEngage;
}
