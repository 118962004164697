import { Box, ButtonProps, Grid, HStack, Stack, Text } from "@chakra-ui/react";
import CoinIcon from "components/Icon/CoinIcon";
import Icon from "components/Icon/Icon";
import { motion, Variants } from "framer-motion";
import { SupportedCoinType, useCoin, useDefaultStyle } from "hooks";
import { useMemo, useRef } from "react";
import { isFiat, toPrecision, currencyFormat, when, toCoinLocale } from "utils";
import Card from "../Card/Card";

const list: Variants = {
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transformOrigin: "top",
    rotateX: "0deg",
    transition: {
      type: "spring",
      bounce: 0.36,
      duration: 1,
      delay: i * 0.05,
    },
  }),
  hidden: (i: number) => ({
    opacity: 0,
    y: 40,
    rotateX: "-28deg",
    transformOrigin: "top",
    transition: {
      type: "spring",
      bounce: 0.46,
      duration: 1,
      delay: i * 0.05,
    },
  }),
};

export function CoinInfoList({ children, isLoading }: any) {
  const MotionGroup = motion(Stack);

  const key = when(isLoading, "coin-list-open", "coin-list-close");

  return (
    // <AnimatePresence initial={false}>
    <MotionGroup sx={{ perspective: "1000px" }} key={key} p="0" m="0">
      {children}
    </MotionGroup>
    // </AnimatePresence>
  );
}

export interface CoinInfoProps extends ButtonProps {
  coin: SupportedCoinType;
  value?: number;
  index?: number;
  anotherValue?: number;
}

export default function CoinInfo(props: CoinInfoProps) {
  const { index = 0, coin, value, anotherValue, ...xprops } = props;

  const played = useRef(false);

  const { borderColor, shadow } = useDefaultStyle();

  const { label } = useCoin(coin);

  const walletLabel = useMemo(() => when(isFiat(coin), label, toCoinLocale(coin)), [coin, label]);

  const renderValue = useMemo(() => {
    if (isFiat(coin) && value) return currencyFormat(coin as any).format(value);
    if (!isFiat(coin) && value) return toPrecision(value, coin);
    return toPrecision(0, coin);
  }, [value, coin]);

  return (
    <Card
      as="button"
      minW={{ base: "initial", "1sm": "340px" }}
      p="18px 28px"
      border="1px solid transparent"
      _focus={{ shadow, borderColor }}
      _hover={{ borderColor }}
      {...(xprops as any)}
      outline="1px solid transparent"
      initial="hidden"
      animate="visible"
      variants={!played.current ? list : {}}
      custom={index}
      onAnimationComplete={() => (played.current = true)}
    >
      <HStack justifyContent="space-between">
        <Grid flex=".6" templateColumns="32px 1fr" alignItems="center" gap={{ base: "15px", "2sm": "18px" }}>
          <Box boxSize="32px">
            <CoinIcon boxSize="32px" coin={coin as any} />
          </Box>

          <Stack justifyContent="flex-start" alignItems="baseline">
            <Text fontWeight="500">{walletLabel} Wallet</Text>
            <Text mt="0 !important" fontSize="14px" fontWeight="700" color="secondary.500">
              {anotherValue ? anotherValue : renderValue}
            </Text>
          </Stack>
        </Grid>

        <Icon type="chevronRightArrow" sx={{ path: { strokeWidth: "2px" } }} />
      </HStack>
    </Card>
  );
}
