import { Badge, Box, Checkbox, Divider, Grid, GridProps, HStack, Stack, Text } from "@chakra-ui/react";
import Icon from "components/Icon/Icon";
import { useColor, useISOCountDown, useSelector } from "hooks";
import { P2PCompletedTradeRo, P2PPendingTradeRo } from "interfaces";
import { useMemo } from "react";
import { selectUser } from "store/slices";
import { currencyFormat, toCoinLocale, toPrecision, when } from "utils";
import Username from "./Username";

import toLower from "lodash/toLower";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";

type StatusType = "pending" | "cancelled" | "released" | "indispute";

interface TradeItemProps extends Omit<GridProps, "id">, P2PPendingTradeRo {
  status?: StatusType;
}
interface CompletedTradeItemProps extends Omit<GridProps, "id">, P2PCompletedTradeRo {
  status?: StatusType;
}
interface TradeValueProps extends Partial<P2PPendingTradeRo> {
  statusColor: string;
}
interface PriceAndReferenceProps extends Partial<P2PPendingTradeRo> {
  statusColor: string;
}
interface FollowTraderProps {}
interface StatusProps {
  status: StatusType;
  statusColor: string;
}

export default function TradeItem(props: TradeItemProps) {
  const { children, status = "pending", ...xprops } = props;

  const color = useColor();

  const info = useMemo(() => {
    const map: Record<StatusType, { color: string }> = {
      pending: { color: "#F4A51D" },
      cancelled: { color: "#ED1C00" },
      released: { color: "#6FBE45" },
      indispute: { color: "#5E8480" },
    };

    return map[status];
  }, [status]);

  return (
    <Grid
      p="28px 28px"
      templateColumns=".15fr repeat(3, 1fr)"
      border="1px solid transparent"
      borderBottomColor="grey.150"
      alignItems="center"
      cursor="pointer"
      _hover={{ bg: color("#dedede57", "#2a2a2a57") }}
      {...xprops}
    >
      <Icon type="money" color={info.color} />

      <TradeValue statusColor={info.color} {...xprops} />
      <PriceAndReference statusColor={info.color} {...xprops} />
      <Status statusColor={info.color} status={status} />
    </Grid>
  );
}

export function MobileTradeItem(props: TradeItemProps) {
  const { children, status = "pending", ...xprops } = props;

  const color = useColor();

  const info = useMemo(() => {
    const map: Record<StatusType, { color: string }> = {
      pending: { color: "#F4A51D" },
      cancelled: { color: "#ED1C00" },
      released: { color: "#6FBE45" },
      indispute: { color: "#5E8480" },
    };

    return map[status];
  }, [status]);

  return (
    <Grid
      p="20px 0px"
      templateColumns="1fr"
      border="1px solid transparent"
      borderBottomColor="grey.150"
      alignItems="center"
      cursor="pointer"
      _hover={{ bg: color("#dedede57", "#2a2a2a57") }}
      {...xprops}
    >
      <Icon type="money" color={info.color} />

      <Grid mt="20px" templateColumns="1fr .3fr" gap="10px">
        <Stack>
          <TradeValue statusColor={info.color} {...xprops} />
          <PriceAndReference statusColor={info.color} {...xprops} />
        </Stack>

        <HStack alignSelf="flex-end" justifyContent="flex-end">
          <Status statusColor={info.color} status={status} />
        </HStack>
      </Grid>
    </Grid>
  );
}

export function CompletedTradeItem(props: CompletedTradeItemProps) {
  const { children, status = "pending", ...xprops } = props;

  const color = useColor();

  const info = useMemo(() => {
    const map: Record<StatusType, { color: string }> = {
      pending: { color: "#F4A51D" },
      cancelled: { color: "#ED1C00" },
      indispute: { color: "#5E8480" },
      released: { color: "#6FBE45" },
    };

    return map[status];
  }, [status]);

  return (
    <Grid
      p="28px 28px"
      templateColumns=".15fr repeat(3, 1fr) .3fr"
      border="1px solid transparent"
      borderBottomColor="grey.150"
      alignItems="center"
      cursor={when(!!props?.adId && !!props?.adRef, "pointer", "default")}
      _hover={{ bg: when(!!props?.adId && !!props?.adRef, color("#dedede57", "#2a2a2a57"), "transparent") }}
      {...xprops}
    >
      <Icon type="money" color={info.color} />

      <CompletedTradeValue statusColor={info.color} {...(xprops as any)} />
      <PriceAndReference statusColor={info.color} {...(xprops as any)} />
      <Status statusColor={info.color} status={status} />
      <FollowTrader />
    </Grid>
  );
}

export function MobileCompletedTradeItem(props: CompletedTradeItemProps) {
  const { children, status = "pending", ...xprops } = props;

  const color = useColor();

  const info = useMemo(() => {
    const map: Record<StatusType, { color: string }> = {
      pending: { color: "#F4A51D" },
      cancelled: { color: "#ED1C00" },
      indispute: { color: "#5E8480" },
      released: { color: "#6FBE45" },
    };

    return map[status];
  }, [status]);

  return (
    <Grid
      p="20px 0px"
      templateColumns="1fr"
      border="1px solid transparent"
      borderBottomColor="grey.150"
      alignItems="center"
      cursor={when(!!props?.adId && !!props?.adRef, "pointer", "default")}
      _hover={{ bg: when(!!props?.adId && !!props?.adRef, color("#dedede57", "#2a2a2a57"), "transparent") }}
      {...xprops}
    >
      <Grid>
        <Icon type="money" color={info.color} />

        <Grid mt="20px" templateColumns="1fr .3fr" justifyContent="space-between" gap="10px">
          <Stack>
            <CompletedTradeValue statusColor={info.color} {...(xprops as any)} />
            <PriceAndReference statusColor={info.color} {...(xprops as any)} />
          </Stack>

          <Stack justifyContent="flex-end">
            <Status statusColor={info.color} status={status} />
            <FollowTrader />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

function FollowTrader(prop: FollowTraderProps) {
  return (
    <Checkbox
      size="lg"
      fontWeight="600"
      color="black"
      defaultChecked
      flexDirection="row-reverse"
      colorScheme="primary"
      gridGap="20px"
      visibility="hidden"
      display="none"
      sx={{
        ".chakra-checkbox__label": {
          fontSize: "14px",
        },
      }}
    >
      Follow
    </Checkbox>
  );
}

function Status(props: StatusProps) {
  const { status } = props;

  const color = useColor();

  const info = useMemo(() => {
    const map: Record<StatusProps["status"], { icon: string; label: string; color: string; bg: string }> = {
      pending: { icon: "timer", label: "Pending", color: "#F4A51D", bg: "rgba(244, 165, 29, 0.1)" },
      cancelled: { icon: "cancel", label: "Cancelled", color: "#ED1C00", bg: "rgba(237, 28, 0, 0.1)" },
      indispute: { icon: "lockCheck", label: "In dispute", color: "#5E8480", bg: "rgba(94, 132, 128, 0.1)" },
      released: {
        icon: "lockCheck",
        label: "Released",
        color: color("#0B3B35", "#31B7A9"),
        bg: color("rgba(111, 190, 69, 0.1)", "rgba(49, 183, 169, 0.1)"),
      },
      // advertisements: { icon: "documentDelite", label: "Your Advertisements", color: "#31B7A9", bg: "rgba(49, 183, 169, 0.1)" },
    };

    return map[status];
  }, [status, color]);

  return (
    <HStack flexDir={{ sm: "column", "3sm": "row", md: "row" }} justifyContent="center">
      <Text fontFamily="var(--bitmama-fonts-heading)" fontSize="14px">
        Status:
      </Text>

      <Box
        ml={{ sm: "0px !important", "3sm": "22px !important", md: "22px !important" }}
        mt={{ sm: "10px !important", "3sm": "0px !important", md: "0px !important" }}
        p="10px"
        fontSize="12px"
        fontWeight="600"
        borderRadius="6px"
        fontFamily="var(--bitmama-fonts-heading)"
        color={info.color}
        bg={info.bg}
        transition="all .4s cubic-bezier(0.4, 0, 0.2, 1)"
      >
        <Text fontSize="12px" fontWeight="700" color={info.color}>
          {info.label}
        </Text>
      </Box>
    </HStack>
  );
}

function PriceAndReference(props: PriceAndReferenceProps) {
  const { _id, token, fiat, tradeRate, parties } = props;
  const { unitCost: _unitCost } = props as any;

  const tradeOwner = useMemo(() => (parties ?? []).find((prt) => prt?.role === "tradeowner"), [parties]);

  const unitCost = useMemo(() => {
    if (!!_unitCost) return +_unitCost;
    if (!!tradeRate) return +tradeRate?.unitCost;
    if (!!tradeOwner) return +tradeOwner?.unitCost;
    return 0;
  }, [_unitCost, tradeOwner, tradeRate]);

  console.log("PriceAndReference", unitCost, tradeOwner, tradeRate);

  return (
    <Stack alignItems="flex-start">
      <Text fontFamily="var(--bitmama-fonts-heading)" fontSize="14px" fontWeight="600">
        Price: {currencyFormat((fiat as any) ?? "ngn").format(unitCost ?? 0)} {toCoinLocale(fiat ?? "ngn")}/
        {toCoinLocale(token ?? "btc")}/USD
      </Text>
      <Text mt="0 !important" fontSize="14px" fontWeight="500">
        Reference: {_id}
      </Text>
    </Stack>
  );
}

function TradeValue(props: TradeValueProps) {
  const { profile } = useSelector(selectUser);
  const { token, fiat, volume, amount, parties, statusColor } = props;
  const { originalParty } = props as any;

  const { pendingTradeExtraInfo, isPending } = usePendingTradeMaps(props);

  const appropriateSessionOwner = useMemo(() => {
    return parties?.find((cp) => cp.user.userId === profile?.userId);
  }, [parties, profile]);

  const userRole = useMemo(() => appropriateSessionOwner?.role, [appropriateSessionOwner]);

  const tradeOwner = useMemo(() => (parties ?? []).find((prt) => prt?.role === "tradeowner"), [parties]);
  const trader = useMemo(() => (parties ?? []).find((prt) => prt?.role === "trader"), [parties]);

  const perspective = useMemo(() => tradeOwner?.perspective ?? originalParty?.role ?? "seller", [tradeOwner, originalParty]);

  const user = useMemo(() => {
    if (userRole === "tradeowner") return trader?.user;
    if (userRole === "trader") return tradeOwner?.user;
    return trader?.user;
  }, [trader, tradeOwner, userRole]);

  const tradeType = useMemo(() => {
    const map: Record<string, string> = {
      seller: "Sell",
      buyer: "Buy",
    };

    return map[perspective ?? "seller"];
  }, [perspective]);

  return (
    <Stack alignItems="flex-start" textAlign="left">
      <Username username={`@${user?.username ?? ""}`} hidePresence />
      <Text mt="0 !important" fontFamily="var(--bitmama-fonts-heading)" fontSize="14px" fontWeight="600">
        Value: {toPrecision(volume ?? 0, token ?? "btc")}
      </Text>
      <Text mt="0 !important" fontSize="14px" fontWeight="500">
        Trade Value: {currencyFormat((fiat as any) ?? "ngn").format(amount ?? 0)} {toCoinLocale(fiat ?? "ngn")} ({tradeType})
      </Text>

      <ConditionalRender shouldRender={!!pendingTradeExtraInfo && isPending}>
        <Divider my="14px !important" maxW="80%" borderColor="grey.150" />
        <HStack mt="0 !important" fontFamily="var(--bitmama-fonts-heading)">
          <Text fontSize="14px" color={statusColor} fontWeight="600">
            {pendingTradeExtraInfo?.info}
          </Text>
          {!pendingTradeExtraInfo?.timer?.timedout && (
            <Badge bg="error" color="white">
              {pendingTradeExtraInfo?.timer?.current}
            </Badge>
          )}
        </HStack>
      </ConditionalRender>
    </Stack>
  );
}

function CompletedTradeValue(props: TradeValueProps) {
  const { profile } = useSelector(selectUser);
  const { token, fiat, volume, amount, parties } = props;
  const { originalParty, counterParty } = props as any;

  const appropriateSessionOwner = useMemo(() => {
    return parties?.find((cp) => (cp as any)?.userId === profile?.userId);
  }, [parties, profile]);

  const userRole = useMemo(() => appropriateSessionOwner?.role, [appropriateSessionOwner]);

  const seller = useMemo(() => (parties ?? []).find((prt) => prt?.role === "seller"), [parties]);
  const buyer = useMemo(() => (parties ?? []).find((prt) => prt?.role === "buyer"), [parties]);

  const perspective = useMemo(() => seller?.perspective ?? originalParty?.role ?? "seller", [seller, originalParty]);

  const user = useMemo(() => {
    if (userRole === "buyer") return seller ?? (counterParty as P2PCompletedTradeRo["counterParty"]);
    if (userRole === "seller") return buyer ?? (originalParty as P2PCompletedTradeRo["counterParty"]);
    return counterParty as P2PCompletedTradeRo["counterParty"];
  }, [userRole, counterParty, originalParty, seller, buyer]);

  // const user = useMemo(() => trader?.user ?? party, [trader, party]);

  console.log("Appropriate Owner", appropriateSessionOwner);

  const tradeType = useMemo(() => {
    const map: Record<string, string> = {
      seller: "Sell",
      buyer: "Buy",
    };

    return map[perspective ?? "seller"];
  }, [perspective]);

  return (
    <Stack alignItems="flex-start" textAlign="left">
      <Username username={`@${(user as any)?.username ?? ""}`} hidePresence />
      <Text mt="0 !important" fontFamily="var(--bitmama-fonts-heading)" fontSize="14px" fontWeight="600">
        Value: {toPrecision(volume ?? 0, token ?? "btc")}
      </Text>
      <Text mt="0 !important" fontSize="14px" fontWeight="500">
        Trade Value: {currencyFormat((fiat as any) ?? "ngn").format(amount ?? 0)} {toCoinLocale(fiat ?? "ngn")} ({tradeType})
      </Text>
    </Stack>
  );
}

function usePendingTradeMaps(props: TradeValueProps) {
  const { profile } = useSelector(selectUser);
  const { counterParties, contractState, adsType } = props;

  type PendingTradeExtraInfoType = {
    // is_obligation_fulfilled: boolean;
    is_completed?: boolean;
    info?: string;
    timer?: { current: string; timedout: boolean };
  };

  const isPending = useMemo(() => contractState === "open", [contractState]);

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === profile?.userId);
  }, [counterParties, profile]);

  const trader = useMemo(
    () =>
      (counterParties ?? [])?.find(
        (cp) => toLower(cp.role) === (appropriateSessionOwner?.role === "trader" ? "tradeowner" : "trader")
      ),
    [counterParties, appropriateSessionOwner?.role]
  );

  const { timer: countDown, expired } = useISOCountDown(trader?.timeline?.dueAt ?? "", `${trader?.timeline?.duration} : 00mins`);

  const currentTradeOwnerStatus = useMemo(
    () => `${appropriateSessionOwner?.role}&${adsType}&${contractState}&${appropriateSessionOwner?.obligationStatus}`,
    [contractState, appropriateSessionOwner, adsType]
  );

  const currentTraderStatus = useMemo(() => `${contractState}&${trader?.obligationStatus}`, [contractState, trader]);

  const isPaid = useMemo(() => currentTraderStatus === "open&fulfilled", [currentTraderStatus]);
  const perspective = useMemo(() => appropriateSessionOwner?.perspective ?? "seller", [appropriateSessionOwner]);
  const alternatePerspective = useMemo(() => when(perspective === "seller", "Buyer", "Seller"), [perspective]);

  const pendingTradeExtraInfo = useMemo(() => {
    const map: Record<string, PendingTradeExtraInfoType | null> = {
      [currentTradeOwnerStatus]: null,
      //////////////////////////////////////////////////////////////
      //// TRADER BUYING EXTRAS
      "trader&buying&open&fulfilled": {
        info: when(isPaid, "Awaiting coin release", `Awaiting ${alternatePerspective}'s payment`),
        timer: { current: countDown, timedout: expired },
      },
      "trader&buying&open&pending": {
        info: `${alternatePerspective} is awaiting coin release`,
        timer: { current: countDown, timedout: expired },
      },
      //////////////////////////////////////////////////////////////
      //// TRADER SELLING EXTRAS
      "trader&selling&open&pending": {
        info: `${alternatePerspective} is awaiting your payment`,
        timer: { current: countDown, timedout: expired },
      },
      "trader&selling&open&fulfilled": {
        info: `Awaiting coin release`,
        timer: { current: countDown, timedout: expired },
      },

      //////////////////////////////////////////////////////////////
      //// TRADE OWNER BUYING EXTRAS
      "tradeowner&buying&open&pending": {
        info: `${alternatePerspective} is awaiting your payment`,
        timer: { current: countDown, timedout: expired },
      },
      "tradeowner&buying&open&fulfilled": {
        info: `Awaiting coin release`,
        timer: { current: countDown, timedout: expired },
      },
      //////////////////////////////////////////////////////////////
      //// TRADE OWNER SELLING EXTRAS
      "tradeowner&selling&open&pending": {
        info: when(isPaid, `${alternatePerspective} is awaiting coin release`, `Awaiting ${alternatePerspective}'s payment`),
        timer: { current: countDown, timedout: expired },
      },
      "tradeowner&selling&open&fulfilled": {
        info: `Awaiting coin release`,
        timer: { current: countDown, timedout: expired },
      },
    };

    return map[currentTradeOwnerStatus];
  }, [currentTradeOwnerStatus, countDown, expired, alternatePerspective, isPaid]);

  return { pendingTradeExtraInfo, isPending };
}
