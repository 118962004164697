import { isRejectedWithValue } from "@reduxjs/toolkit";
import store from "store";
import { setError } from "store/slices";
import { recursiveObjectSearch } from "utils";

const rtkQueryErrorHandler = (api: any) => (next: any) => (action: any) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    // console.warn("We got a rejected action!", action);
    // const { status, data } = action.payload;
    // autoSignOut(status, data?.error, true);
    console.log("Rejection Middlware", action);
    store.dispatch(
      setError({
        action,
        // message: action?.payload?.error ?? action?.payload?.message ?? "",
        message: recursiveObjectSearch(action.payload, "error"),
        showUser: true,
      })
    );
  }

  return next(action);
};

export default rtkQueryErrorHandler;
