import { Box, Button, HStack, ListItem, Stack, Text, useColorModeValue, List, BoxProps } from "@chakra-ui/react";
import { Card, Group, GroupProps, PageLoading, Paginator, CoinIcon } from "components";

import { useGetSwapTransactionsQuery } from "apis";
import { toCoinLocale, toPrecision, toQueryString, when } from "utils";
import { useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useColor, usePartialState, useDefaultStyle } from "hooks";
import { format, parseISO } from "date-fns";
import { toLower } from "lodash";

import { SwapRo } from "interfaces/swap.interface";

export interface SwapTransactionsProps extends GroupProps {
  subjectOf: "sell_credit" | "buy_debit";
}

export default function SwapTransactions(props: SwapTransactionsProps) {
  const { subjectOf } = props;
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const [queries, set] = usePartialState({
    page: 1,
    limit: 7,
    sortBy: "createdAt:desc",
    subjectOfTransaction: subjectOf,
  });

  const { data, isLoading } = useGetSwapTransactionsQuery(toQueryString(queries));

  // console.log("swap history", swapHis);

  const totalCount = useMemo(() => data?.totalCount, [data]);
  const transactions = useMemo(() => data?.swap, [data]);

  const handlePageChange = (page: number) => {
    set({ page });
  };

  return (
    <Group mt="0" w="100%" py={{ base: "24px", "3sm": "52px" }} {...props}>
      <Card bg={bg} w="100%" p="8px" overflow="hidden">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {!isLoading && (
              <List>
                {(transactions ?? []).map((tx: any, id: number) => (
                  <TransactionItem mt="0 !important" w="100%" {...tx} isLast={id === (transactions as any)?.length - 1} />
                ))}
              </List>
            )}
          </motion.div>
        </AnimatePresence>
      </Card>

      <Box w="100%">
        <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
      </Box>
    </Group>
  );
}

function CoinAvatarFuse(props: any) {
  const { src, dest } = props;

  const color = useColor();
  const bg = color("white", "dark.bg");

  return (
    <HStack
      pos="relative"
      sx={{
        "--offset": "12px",
      }}
      h="32px"
      w="calc((32px * 2) - (var(--offset) / 2))"
    >
      <Box
        pos="absolute"
        top="50%"
        left="calc(50% - var(--offset))"
        transform="translate(-50%, -50%)"
        boxSize="32px"
        bg={bg}
        shadow="0 0 3px rgba(0,0,0,.1)"
        borderRadius="50%"
        overflow="hidden"
      >
        {/* <Box as={srcIcon} pos="absolute" top="50%" left="50%" boxSize="36px" transform="translate(-50%, -50%)" /> */}
        <CoinIcon coin={src as any} pos="absolute" top="50%" left="50%" boxSize="26px" transform="translate(-50%, -50%)" />
      </Box>
      <Box
        pos="absolute"
        top="50%"
        right="calc(50% - var(--offset))"
        transform="translate(50%, -50%)"
        boxSize="32px"
        bg={bg}
        shadow="0 0 3px rgba(0,0,0,.1)"
        borderRadius="50%"
        overflow="hidden"
      >
        {/* <Box as={destIcon} pos="absolute" top="50%" left="50%" boxSize="38px" transform="translate(-50%, -50%)" /> */}
        <CoinIcon coin={dest as any} pos="absolute" top="50%" left="50%" boxSize="26px" transform="translate(-50%, -50%)" />
      </Box>
    </HStack>
  );
}

interface TransactionItemProps extends SwapRo, BoxProps {
  isLast?: boolean;
}

function TransactionItem(props: TransactionItemProps) {
  const { sourceToken, destToken, createdAt, sourceQuantity, destQuantity, isLast } = props;
  // const { isOpen, onClose /*onOpen*/ } = useDisclosure();

  const color = useColor();
  const { shadow } = useDefaultStyle();

  const getColor = (type: any) => {
    const map: Record<string, string> = {
      credit: "secondary.400",
      debit: "error",
    };

    return map[toLower(type) ?? "credit"];
  };

  const srcAmount = sourceQuantity?.$numberDecimal;
  const destAmount = destQuantity?.$numberDecimal;

  const borderColor = useColorModeValue("grey.100", "#00000026");

  //   const getType = (type) => {
  //     const map = {
  //       [toLower(type)]: toLower(type).includes("debit") ? "debit" : "credit",
  //       contract_credit: "credit",
  //       contract_debit: "debit",
  //     };

  //     return map[toLower(type)];
  //   };

  //   const isFiat = useMemo(() => constants.fiats?.includes(unit), [unit]);

  const Price = ({ type, value, coin }: any) => (
    <HStack m="0 !important" textTransform="initial">
      <Box boxSize="10px" borderRadius="50%" bg={getColor(type)} />
      <Text color={color("primary.default", "secondar.default")} fontSize="sm" fontWeight="400">
        {toPrecision(value, coin)}
      </Text>
    </HStack>
  );

  return (
    <>
      <ListItem
        w="100%"
        // minW="100%"
        as={Button}
        minH="fit-content"
        bg="transparent"
        p="32px 0"
        borderRadius="2px"
        borderBottom={!isLast ? "0.5px solid transparent" : "none"}
        _hover={{ bg: "transparent", shadow: "0 0 0 3px transparent" }}
        _active={{ bg: "transparent", shadow: "0 0 0 3px transparent" }}
        cursor="pointer"
        borderBottomColor={borderColor}
        _focus={{ shadow }}
        // onClick={onOpen}
      >
        <HStack justifyContent="space-between" w="100%" px="4px">
          <HStack alignItems="flex-start">
            <CoinAvatarFuse src={sourceToken} dest={destToken} />

            <Box maxW="180px" ml="18px !important">
              <Text
                textAlign="left"
                fontSize="14px"
                fontWeight="400"
                color={color("primary.default", "secondar.default")}
                w="100%"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                textTransform="initial"
                m="0 !important"
              >
                {/* {description} */}
                {toCoinLocale(sourceToken ?? "celo")} to {toCoinLocale(destToken ?? "btc")} Swap
              </Text>
              {createdAt && (
                <Text textAlign="left" fontSize={{ base: "10px", "2sm": "12px" }} fontWeight="500" mt="2px" color="grey.500">
                  {format(parseISO(createdAt), "eee, dd MMM yyyy, HH:mm")}
                  {/* 2022-03-14 */}
                </Text>
              )}
            </Box>
          </HStack>

          {/* <Text color="#4E4B66" fontSize="14px" fontWeight="600">
            {toLower(type) === "debit" ? "-" : ""}
            {isFiat && formatter(unit).format(value?.$numberDecimal)}
            {!isFiat && toPrecision(value?.$numberDecimal, unit)}
          </Text> */}

          {srcAmount && destAmount && (
            <Stack justifyContent="flex-start" flex=".4" ml="0 !important">
              <Price type="credit" value={destAmount} coin={destToken} />
              <Price type="debit" value={srcAmount} coin={sourceToken} />
            </Stack>
          )}
        </HStack>
      </ListItem>
    </>
  );
}
