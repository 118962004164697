import { Box, Button, Heading, useColorModeValue } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { ConditionalRender, Icon, MainLayoutContainer, ModalView, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { usePartialState } from "hooks";
// import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";

import { CreateAdFeature } from "ui";

interface CreateAdsPageProps extends PageProps {}

export default function CreateAdsPage(props: CreateAdsPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  // const { goBack } = useBuiltInFn();

  const [state, set] = usePartialState<{ title: string; hide: boolean; onBack: () => void }>({ title: "Create Ad", hide: false });

  const handleBack = () => {
    if (!!state?.onBack) {
      state.onBack();
      console.log("Should have been called", state?.onBack);
    } else navigate(-1);
  };

  return (
    <ModalView id="p2p-create-listing-steps-context">
      <PageMotion key="CreateAds">
        <Topbar pageTitle="P2P" {...props} />

        <MainLayoutContainer m="0" pb="60px">
          <SidePageProvider>
            <ConditionalRender shouldRender={!state?.hide}>
              <Box mb="42px">
                <Button
                  size="sm"
                  minW="fit-content"
                  maxW="fit-content"
                  variant="link"
                  leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                  // onClick={() => navigate(-1)}
                  onClick={handleBack}
                  px="2px !important"
                >
                  Back
                </Button>

                <Heading mt="12px" as="h4" fontSize="24px" fontWeight="bold">
                  {state?.title}
                </Heading>
              </Box>
            </ConditionalRender>

            <CreateAdFeature setPageInfo={set} {...props} />
          </SidePageProvider>
        </MainLayoutContainer>
      </PageMotion>
    </ModalView>
  );
}
