import { useCallback, useMemo } from "react";
import { selectUser } from "store/slices";
import useInlineScript from "./useInlineScript";
import useSelector from "./useSelector";
import { getGuestID } from "utils";

const clientId = "Bitmama";

const chatScript = ({ clientId, userData }: { clientId?: string; userData?: Record<string, any> }) => {
  let userDataString = "";
  const includeUserData = false;
  if (userData && includeUserData) {
    const userDataEntries = Object.entries(userData).map(([key, value]) => `${key}: '${value}'`);
    userDataString = `${userDataEntries.join(", ")}`;
  }

  const sharedQuery = {
    source: "webchat",
    os: "web",
  } as Record<string, any>;

  let queryObj: Record<string, any> = userData
    ? {
        fullname: userData?.fullname,
        form_id: "identifier",
        identifier: userData?.userId,
        ...sharedQuery,
      }
    : { ...sharedQuery };

  const userDataEntries = Object.entries(queryObj).map(([key, value]) => `${key}=${value}`);
  const queryUrl = `?${userDataEntries.join("&")}`;

  return `
(function (w, d, s, o, f, js, fjs) {
     w['EBANQO-WIDGET'] = o;
     w[o] = w[o] ||
         function () {
             (w[o].q = w[o].q || []).push(arguments);
         };
     (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
     js.id = o;
     js.src = f;
     js.async = 1;
     fjs.parentNode.insertBefore(js, fjs);
 })(window, document, 'script', 'ebanqo_widget', 'https://widget.ebanqo.io/app.js');
 ebanqo_widget && ebanqo_widget('init', { url: 'https://webchat.ebanqo.io/v2/${clientId}${queryUrl}'${
    userDataString ? `, ${userDataString}` : ","
  } })();
`;
};

export function useEbanqo() {
  const { profile: user } = useSelector(selectUser);

  const guestId = getGuestID();
  console.log("Guest Ebanqo ID", guestId);
  const fullname = !!user?.firstName ? `${String(`${user?.firstName || ""} ${user?.lastName || ""}`).trim()}` : "Guest";

  const bootConfig = useMemo(
    () => ({
      name: user?.firstName ?? "Guest",
      fullname,
      userId: user?.userId ?? guestId,
      email: user?.email,
      phone: user?.phone || undefined,
      createdAt: user?.createdAt,
      utmSource: "web",
    }),
    [user, fullname, guestId]
  );

  const { injectScript } = useInlineScript(chatScript({ clientId, userData: bootConfig }), "cs-chat", true);

  const boot = useCallback(
    (_props: any) => {
      injectScript({ inlineScript: chatScript({ clientId, userData: bootConfig }) });
    },
    [injectScript, bootConfig]
  );

  const bootWithProps = useCallback(() => {
    return boot(bootConfig);
  }, [bootConfig, boot]);

  const minimize = useCallback(() => {
    const chatbotContainer = document.querySelector(".ebanqo-widget-wrapperV2");
    if (chatbotContainer) {
      (chatbotContainer as any).style.display = "";
    }
  }, []);

  const maximize = useCallback(() => {
    const chatbotContainer = document.querySelector(".ebanqo-widget-wrapperV2");
    if (chatbotContainer) {
      (chatbotContainer as any).style.display = "block";
    }
  }, []);

  const shutdown = useCallback(() => {
    minimize();
    const chatbotPrompt = document.querySelector(".initial-prompt-message-wrapper");
    if (chatbotPrompt) {
      (chatbotPrompt as any).style.display = "";
    }
  }, [minimize]);

  const restore = useCallback(() => {
    maximize();
    const chatbotPrompt = document.querySelector(".initial-prompt-message-wrapper");
    if (chatbotPrompt) {
      (chatbotPrompt as any).style.display = "flex";
    }
  }, [maximize]);

  const logout = useCallback(() => {
    // we are unable to access the chat iframe...due to security policy
    // provider should expose a logout api
  }, []);

  return { bootWithProps, boot, shutdown, minimize, maximize, logout, restore };
}
