import { Box, Button, ButtonProps, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useCreatePriceAlertMutation } from "apis";
// import { navigate } from "@reach/router";
import { AbstractModal, Icon, useAbstractModal, useModalView as useSteps } from "components";
import { omit } from "lodash";
import { useMemo, useRef } from "react";
import { IStateExtension } from "ui/Account/Features/CryptoAlert/Add";
import { toCoinLocale, when } from "utils";
// import configs from "config";
// import { isTesting } from "config";
// import { useSidePage } from "contexts";
// import ls from "utils/secureStorage";

interface ConfirmCryptoAlertModalProps extends Omit<ModalProps, "children">, IStateExtension {
  direction?: "up" | "down";
}

export default function ConfirmCryptoAlertModal(props: ConfirmCryptoAlertModalProps) {
  // const { onClose } = props;

  //   const bColor = useColorModeValue("grey.150", "dark.border");

  //   const handleRemind = (e: any) => {
  //     e.preventDefault();
  //     ls.set(configs.TWO_FA_REMIND, "remind");
  //     props.onClose();
  //   };

  // const actionType = useMemo(() => when(!!direction && direction === "up", "sell", "buy"), [direction]);

  return (
    <AbstractModal
      // closeOnOverlayClick={false}
      // closeOnEsc={false}
      _content={{
        maxW: "580px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        // position: { base: "absolute", "1sm": "relative" },
      }}
      {...props}
    >
      <Box p={{ base: "32px 40px", "1sm": "44px 80px" }}>
        <VStack mb="60px !important">
          <Icon type="swapSwitch" boxSize="44px" color="primary.500" />
          <Heading mt="8px !important" mb="10px !important" as="h4" fontSize="28px" textTransform="capitalize" textAlign="center">
            {/* {actionType} */}
            Schedule a Buy/Sell
          </Heading>
          <Text fontWeight="500">Would you like to schedule a buy/sell at this Price?</Text>
        </VStack>

        <Actions {...props} />

        {/* <VStack mt="24px !important" px="46px" w="100%">
         
        </VStack> */}
      </Box>
    </AbstractModal>
  );
}

interface ActionsProps extends ConfirmCryptoAlertModalProps {
  onYes?: () => void;
  onNo?: () => void;
}

function Actions(props: ActionsProps) {
  const { onYes, onNo, onClose, state, set, reset } = props;

  const btnPressed = useRef<string>("none");

  const { onNext } = useSteps();
  const { updateModal } = useAbstractModal();

  const [_addPriceAlert, { isLoading: loading }] = useCreatePriceAlertMutation();

  // const { data: coinData } = useGetCoinToUsdRateQuery(state?.coin ?? "btc", { skip: !state?.coin });

  // const actionType = useMemo(() => when(!!state?.direction && state?.direction === "up", "sell", "buy"), [state?.direction]);
  // const current_market_price = useMemo(() => (coinData?.exchangeRate ?? {})[actionType] ?? 0, [actionType, coinData?.exchangeRate]);

  const isLoading = useMemo(
    () => ({
      buy: btnPressed.current === "buy" && loading,
      sell: btnPressed.current === "sell" && loading,
      createAlert: btnPressed.current === "createAlert" && loading,
    }),
    [loading, btnPressed]
  );

  const createPriceAlert = async () => {
    if (!state?.coin) return;
    // onOpen();

    const response = await _addPriceAlert({
      ticker: `${state?.coin}usdt`,
      ...omit(state, ["spotPrice", "priceAlertId"]),
    }).unwrap();

    const alert = (response?.data as any)?.alert;

    // if (!!alert) {
    //   // onOpen();
    //   const percentage_to_price = when(
    //     state?.direction === "up",
    //     current_market_price + (current_market_price * +(state?.price ?? 0)) / 100,
    //     current_market_price - (current_market_price * +(state?.price ?? 0)) / 100
    //   );
    //   const spot_price = when(state?.type === "price", +(state?.price ?? 0), percentage_to_price);
    //   set({ priceAlertId: alert?._id, spotPrice: spot_price });

    //   return alert;
    // }

    console.log("Alert Response", response);
    return alert;
  };

  const handleAlertAndOrder = (scheduleType: "buy" | "sell") => async () => {
    btnPressed.current = scheduleType;
    const alert = await createPriceAlert();
    if (!!alert) {
      // const percentage_to_price = when(
      //   state?.direction === "up",
      //   current_market_price + (current_market_price * +(state?.price ?? 0)) / 100,
      //   current_market_price - (current_market_price * +(state?.price ?? 0)) / 100
      // );
      // const spot_price = when(state?.type === "price", +(state?.price ?? 0), percentage_to_price);
      set({ priceAlertId: alert?._id, spotPrice: alert?.price, scheduleType });

      onClose();
      onNext();
      onYes && onYes();
    }
  };

  const handleCreateAlert = async () => {
    btnPressed.current = "createAlert";
    const alert = await createPriceAlert();

    if (!!alert) {
      onNo && onNo();
      updateModal({
        isSuccess: true,
        success: {
          description: `Your price alert for ${toCoinLocale(state?.coin ?? "btc")} has been set`,
          actions: [
            {
              label: "Close",
              onClick: () => {
                onClose();
                reset();
                navigate(-1);
              },
            },
          ],
        },
      });
    }
  };

  const buttonStyle = useMemo(() => {
    const map: Record<"up" | "down", { buy: ButtonProps; sell: ButtonProps }> = {
      up: {
        buy: {
          variant: "outline",
          color: "error",
          borderColor: "error",
          minW: "unset",
          mt: "10px !important",
          _focus: {
            shadow: "darkErrOutline",
            borderColor: "error",
          },
          _hover: {
            color: "error",
          },
        },
        sell: {
          variant: "solid",
          minW: "unset",
          fontSize: "14px",
          mt: "0 !important",
        },
      },
      down: {
        buy: {
          variant: "solid",
          minW: "unset",
          fontSize: "14px",
          mt: "0 !important",
        },
        sell: {
          variant: "outline",
          color: "error",
          borderColor: "error",
          minW: "unset",
          mt: "10px !important",
          _focus: {
            shadow: "darkErrOutline",
            borderColor: "error",
          },
          _hover: {
            color: "error",
          },
        },
      },
    };

    const dir = (state?.direction ?? "up") as "up" | "down";
    return map[dir];
  }, [state?.direction]);

  return (
    <VStack mt="24px !important" px="24px" w="100%">
      <VStack w="100%" flexDir={when(state?.direction === "up", "column-reverse", "column")}>
        <Button
          minW="unset"
          w="100%"
          fontSize="14px"
          isLoading={isLoading.buy}
          isDisabled={loading}
          onClick={handleAlertAndOrder("buy")}
          {...buttonStyle.buy}
        >
          Buy at this price
        </Button>
        <Button
          // variant="outline"
          // textDecoration="underline"
          // borderColor="error"
          // color="error"
          // minW="unset"
          fontSize="14px"
          w="100%"
          onClick={handleAlertAndOrder("sell")}
          isLoading={isLoading.sell}
          isDisabled={loading}
          {...buttonStyle.sell}
        >
          Sell at this price
        </Button>
      </VStack>

      <Button
        mt="36px !important"
        variant="outline"
        textDecoration="underline"
        h="fit-content"
        minH="fit-content"
        fontWeight="500"
        p="4px !important"
        textTransform="unset"
        minW="unset"
        // w="100%"
        isDisabled={loading}
        isLoading={isLoading.createAlert}
        onClick={handleCreateAlert}
      >
        I only want to create a price alert
      </Button>

      <Button
        mt="16px !important"
        variant="outline"
        textDecoration="none"
        h="fit-content"
        minH="fit-content"
        fontWeight="500"
        p="4px !important"
        textTransform="unset"
        minW="unset"
        // w="100%"
        isDisabled={loading}
        onClick={onClose}
      >
        Cancel
      </Button>
    </VStack>
  );
}
