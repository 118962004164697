import { Box, TabList, TabPanel, Tabs } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Group, MainLayoutContainer, Steps, Tab, TabPanels, Title, TitleBar, useModalView as useSteps } from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { FeatureUnavailableContent } from "ui/Common/Modals/FeatureUnavailable";
import { when } from "utils";
import { Beneficiary } from "./Beneficiary";

import Airtime from "./Panels/Airtime";
import Data from "./Panels/Data";
import { BillPlanProductRo, BillPlanRo } from "interfaces";
import { usePartialState } from "hooks";

interface AirtimeAndDataTabsProps {}

interface AirtimeAndDataViewProps extends SidePageViewProps {}

interface AirtimeDataSharedState {
  network: string;
  country: string;
  dialCode: string;
  countryCode: string;
  countryName: string;
  operatorId: string | null;
  operatorInfo: BillPlanRo | null;
  phone: string;
  topupType: string | null;
  amount: string | null;
  wallet: string;
  productId: string;
  productInfo: Partial<BillPlanProductRo>;
  ported: boolean;

  state_for: "airtime" | "data";
  currentStep: number;
}

// interface ICombinedAirtimeAndData {
//   data: Partial<AirtimeDataSharedState>;
//   airtime: Partial<AirtimeDataSharedState>;
//   currentStep?: number;
// }

interface IAirtimeDataContext {
  sharedState: ReturnType<typeof usePartialState<AirtimeDataSharedState>>[0];
  setSharedState: ReturnType<typeof usePartialState<AirtimeDataSharedState>>[1];
  resetSharedState: ReturnType<typeof usePartialState<AirtimeDataSharedState>>[2];
  initializeSharedState: ReturnType<typeof usePartialState<AirtimeDataSharedState>>[3];

  // setAirtimeState: ReturnType<typeof usePartialState<AirtimeDataSharedState>>[1];
  // resetDataState: ReturnType<typeof usePartialState<AirtimeDataSharedState>>[2];
  // resetAirtimeState: ReturnType<typeof usePartialState<AirtimeDataSharedState>>[2];
}

const AirtimeDataContext = createContext<IAirtimeDataContext | null>(null);

export default function AirtimeAndDataView(props: AirtimeAndDataViewProps) {
  const [sharedState, setSharedState, resetSharedState, initializeSharedState] = usePartialState<AirtimeDataSharedState>();
  // const [airtime, setAirtimeState, resetAirtimeState] = usePartialState<AirtimeDataSharedState>();

  return (
    // <AirtimeDataContext.Provider value={{ data, airtime, setDataState, setAirtimeState, resetDataState, resetAirtimeState }}>
    <AirtimeDataContext.Provider value={{ sharedState, setSharedState, resetSharedState, initializeSharedState }}>
      <Box px={{ base: "20px", "2sm": "32px" }} {...props} overflowY="scroll">
        <TitleBar>
          <Title fontSize="20px">Airtime & Data</Title>
        </TitleBar>

        <MainLayoutContainer maxW="xl" mt="28px" px={{ base: "0", "2sm": "10px" }}>
          <Group mt="0">
            <Steps hideIndicator>
              <AirtimeAndDataTabs />
              <Beneficiary />
            </Steps>
          </Group>
        </MainLayoutContainer>
      </Box>
    </AirtimeDataContext.Provider>
  );
}

function AirtimeAndDataTabs(props: AirtimeAndDataTabsProps) {
  const location = useLocation();
  const { hasPrev } = useSteps();
  const { key, updateSidePageConfigs, onClose } = useSidePage();
  const { sharedState, setSharedState } = useAirtimeDataState();

  const { appfigs } = useAppConfig();
  const { billsConfig } = useMemo(() => appfigs ?? {}, [appfigs]);

  const isDataDisabled = useMemo(() => !!billsConfig?.disableBills?.data, [billsConfig]);
  const isAirtimeDisabled = useMemo(() => !!billsConfig?.disableBills?.airtime, [billsConfig]);

  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    data: 0,
    airtime: 1,
  };

  const tab_name = String((searchParams.get("subpage")?.split(":")[1] as any) ?? "data").toLowerCase();

  const [tabIndex, changeTab] = useState((tabMap as any)[tab_name] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("subpage", `${key}:${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
    setSharedState({ ...sharedState, state_for: tab_name as any, operatorId: undefined, operatorInfo: undefined });
  };

  useEffect(() => {
    if (!hasPrev) updateSidePageConfigs({ _back: { text: "Close", onClick: onClose } });
    // eslint-disable-next-line
  }, []);

  return (
    <Tabs p="4px" variant="ghost" index={tabIndex} onChange={handleTabChange}>
      <TabList borderRadius="8px" justifyContent="space-between">
        <Tab>Data</Tab>
        <Tab>Airtime</Tab>
      </TabList>
      <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
        <TabPanel px={["2", "0", "0", "1"]}>
          {when(isDataDisabled, <FeatureUnavailableContent showSupport my="80px !important" />, <Data />)}
        </TabPanel>

        <TabPanel px={["0", "0", "0", "1"]}>
          {when(isAirtimeDisabled, <FeatureUnavailableContent showSupport my="80px !important" />, <Airtime />)}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

AirtimeAndDataView.displayName = "SidePageView";

type ArgType = Parameters<typeof usePartialState<AirtimeDataSharedState>>;
export function useAirtimeDataState(initalState?: NonNullable<ArgType[0]>, deps: NonNullable<ArgType[1]> = []) {
  const context = useContext(AirtimeDataContext);
  if (!context) throw new Error("useAirtimeDataSharedState should be used within the AirtimeDataContext");
  return { ...context };
}
