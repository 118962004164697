import { Box, Button, Heading, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { Icon, MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { PageProps } from "interfaces";

import { RequestVirtualCardFeature } from "ui";

interface RequestVirtualCardPageProps extends PageProps {}

export default function RequestVirtualCardPage(props: RequestVirtualCardPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const handleBack = () => {
    navigate("/cards");
  };

  return (
    <PageMotion key="Cards">
      <Topbar pageTitle="Cards" {...props} />

      <MainLayoutContainer pb="60px">
        <VStack alignItems="flex-start">
          <Box>
            <SidePageProvider>
              <Box mb="42px">
                <Button
                  size="sm"
                  minW="fit-content"
                  maxW="fit-content"
                  variant="link"
                  leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                  onClick={handleBack}
                  px="2px !important"
                >
                  Back
                </Button>

                <Text mt="12px" as="h4" fontSize="24px" fontWeight="bold">
                  Cards
                </Text>

                <Heading mt="38px" as="h5" fontSize="md" color="secondary.400">
                  Virtual Card Request
                </Heading>
              </Box>

              <RequestVirtualCardFeature {...props} />
            </SidePageProvider>
          </Box>
        </VStack>
      </MainLayoutContainer>
    </PageMotion>
  );
}
