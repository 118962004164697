import configs from "config";
// import crypto from "crypto";
import Crypto from "crypto-js";

import { useRef } from "react";
import { useIntercom } from "react-use-intercom";
import { selectUser } from "store/slices";
import useSelector from "./useSelector";
import { getGuestID } from "utils";
interface IGenerateHash {
  plainText: string;
  secretKey: string;
}

export function generateHash({ plainText, secretKey }: IGenerateHash) {
  return String(Crypto?.HmacSHA256(plainText, secretKey));
}

export function useIntercomChat() {
  const isReady = useRef(false);
  const { boot } = useIntercom();
  const { profile: user } = useSelector(selectUser);

  const guestId = getGuestID();
  console.log("Guest Intercom ID", guestId);

  const bootConfig = {
    name: user?.firstName || "Guest",
    userId: user?.userId ?? guestId,
    email: user?.email,
    phone: user?.phone || undefined,
    createdAt: user?.createdAt ?? new Date().toISOString(),
    utmSource: "web",
  };

  if (configs.INTERCOM_SECRET && String(configs.INTERCOM_SECRET).length > 0) {
    // const userHash = crypto
    //   .createHmac("sha256", configs.INTERCOM_SECRET)
    //   .update(user?.userId ?? user?._id!)
    //   .digest("hex");
    try {
      const userHash = generateHash({
        plainText: user?.userId ?? guestId,
        secretKey: configs.INTERCOM_SECRET,
      });
      Object.assign(bootConfig, { userHash });
      if (userHash) isReady.current = true;
    } catch (err) {
      console.warn({ err });
    }
  }

  const bootWithProps = () => {
    let attempts = 0;
    while (!isReady.current && attempts++ < 2000) {
      console.warn("loading intercom...", attempts);
    }
    return boot(bootConfig);
  };

  return { bootWithProps, boot };
}
