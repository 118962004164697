import { Box, Button, FormControl, Input, Stack, VStack } from "@chakra-ui/react";
import { InputLabel, useModalView } from "components";
import { PageProps } from "interfaces";

interface ProfilePersonalPanelProps extends PageProps {}

export default function ProfilePersonalPanel(props: ProfilePersonalPanelProps) {
  const { user } = props;

  const { setCurrentView } = useModalView();

  return (
    <>
      <Stack as="form" my="40px">
        <FormControl mb="20px !important">
          <InputLabel>First Name</InputLabel>

          <Input defaultValue={user?.firstName} disabled />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel>Last Name</InputLabel>

          <Input defaultValue={user?.lastName} disabled />
        </FormControl>

        <Box mt="18px !important" mb="48px !important">
          <VStack mb="42px">
            <Button
              variant="link"
              color="secondary.400"
              fontWeight="500"
              textDecoration="underline"
              onClick={() => setCurrentView(1)}
            >
              Request for Name change
            </Button>
          </VStack>

          <Box borderBottom="0.5px solid #CACBCB"></Box>
        </Box>

        <FormControl mb="20px !important">
          <InputLabel>Username</InputLabel>
          <Input defaultValue={user?.username} disabled />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel>Email Address</InputLabel>
          <Input defaultValue={user?.email} disabled />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel>Phone Number</InputLabel>
          <Input defaultValue={user?.phone} disabled />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel>Country</InputLabel>
          <Input defaultValue={user?.country} disabled />
        </FormControl>
      </Stack>
    </>
  );
}
