import { Box, Button, Heading, HStack, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { Icon, MainLayoutContainer, PageMotion } from "components";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { Level2VerificationForm } from "ui";

interface Level2VerificationProps extends PageProps {}

export default function Level2Verification(props: Level2VerificationProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountVerification" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              Account Verification
            </Heading>
          </HStack>
        </Box>

        <Level2VerificationViews {...props} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

function Level2VerificationViews(props: Level2VerificationProps) {
  return (
    <Box my="40px">
      <Stack>
        <Heading as="h6" fontSize="md">
          Level 2 Verification
        </Heading>
        <Text fontSize="14px" fontWeight="500">
          Kindly provide the information required to continue with your Account upgrade{" "}
        </Text>
      </Stack>

      <Level2VerificationForm {...props} />
    </Box>
  );
}
