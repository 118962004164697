import {
  Box,
  BoxProps,
  Grid,
  HStack,
  VStack,
  FormControl,
  Switch,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
// import { P2pBottomRightPatternSVG, P2pTopLeftPatternSVG } from "assets";
import { InputLabel } from "components";
import { isTesting } from "config";
import { useContext, useState } from "react";
import BestRate from "./BestRate";
// import PeerCoins from "./PeerCoins";
import { presenceContext } from "Pages/P2P/P2P";
import hamburger from "assets/svg/menu.svg";
import { navigate } from "@reach/router";
import { useColor } from "hooks";
interface HubBoxProps extends BoxProps {
  coin?: string;
}

export default function HubBox(props: HubBoxProps) {
  const { coin = isTesting ? "tbtc" : "btc" } = props;
  const color = useColor();

  // const [coin /*, setCoin */] = useState(isTesting ? "tbtc" : "btc");
  const [currency /*, setCurrency */] = useState("ngn");
  const presence = useContext(presenceContext);
  const { presenceState, presenceStyles, handleTogglePresence } = presence as any;
  return (
    <Box
      color="white"
      w="100%"
      minH={{ sm: "fit-content", "2sm": "fit-content", "3sm": "380px", md: "380px" }}
      p={{ base: "58px 100px", sm: "40px 10px", "2sm": "40px 20px", md: "58px 100px" }}
      bg="#0D4740"
      mt="33px"
      borderRadius={{ base: "30px", sm: "8px", "2sm": "12px", md: "30px" }}
      pos="relative"
      overflow="hidden"
      pb="58px"
      {...props}
    >
      <VStack flexDir="column">
        <HStack mb="52px" w="80%" justifyContent="space-between" alignItems="flex-end">
          {presenceState && !presenceState?.hide && (
            <FormControl display="flex" alignItems="center" justifyContent="center" w="fit-content">
              <InputLabel
                id="online-presence"
                fontSize="sm"
                htmlFor="online-presence"
                // mb="0 !important"
                // ml="6px"
                color={presenceStyles?.color}
                _text={{ mb: "0 !important", fontWeight: "500" }}
              >
                {presenceStyles?.label}
              </InputLabel>
              <Switch
                id="online-presence"
                colorScheme="secondary"
                disabled={presenceState?.disabled}
                isChecked={presenceStyles?.isChecked}
                onChange={handleTogglePresence}
                sx={{
                  "--switch-track-offset": "3.5px",
                  "--switch-track-diff": "calc((var(--switch-track-width) - 10px) - var(--switch-track-offset))",
                  "--switch-track-y": "calc((var(--switch-track-height) - 10px) / 2)",
                  ".chakra-switch__thumb": {
                    boxSize: "10px",
                    transform: "translate(var(--switch-track-offset), var(--switch-track-y))",
                  },
                  ".chakra-switch__thumb[data-checked]": {
                    transitionProperty: "background-color",
                    transitionDuration: "var(--bitmama-transition-duration-normal)",
                    transform: "translate(var(--switch-track-diff), var(--switch-track-y))",
                  },
                  ".chakra-switch__track[data-checked]": {
                    bg: "secondary.400",
                  },
                }}
              />
            </FormControl>
          )}

          <Box flex="1"></Box>

          <Menu>
            <MenuButton>
              <Image src={hamburger}></Image>
            </MenuButton>
            <MenuList color={color("#000", "#FFF")} p="" bg={color("#FFF", "#303131")}>
              <MenuItem fontWeight="500" onClick={() => navigate(`p2p/create`)}>
                Create Ad
              </MenuItem>
              <MenuItem as="button" fontWeight="500" onClick={() => navigate(`/p2p/trades`)}>
                Trade History
              </MenuItem>
              <MenuItem as="button" fontWeight="500" onClick={() => navigate(`/p2p/listings`)}>
                Manage Listings
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
        {/* <Text>Select any of the Coins below to Buy or Sell</Text>
        <HStack my="20px !important">
          <Select
            maxW="100px"
            minH="44px"
            onChange={(event) => setCurrency(event.target.value)}
            h="44px"
            bg="transparent"
            color={"#838383"}
            _hover={{ bg: "transparent" }}
          >
            <option value="ngn">NGN</option>
            <option value="ghs">GHS</option>
            <option value="kes">KES</option>
          </Select>

          <PeerCoins ml="60px !important" value={coin} onPeerChange={setCoin} />
        </HStack> */}

        <Grid
          w={{ sm: "100%", "2sm": "100%", "3sm": "fit-content", md: "fit-content" }}
          templateColumns="repeat(2, 1fr)"
          gridGap={{ sm: "10px", "2sm": "20px", md: "40px" }}
        >
          <BestRate currency={currency} coin={coin} adsType="buy" />
          <BestRate currency={currency} coin={coin} adsType="sell" />
        </Grid>
      </VStack>

      {/* <Box as={P2pTopLeftPatternSVG} pos="absolute" top="0" left="0" />
      <Box as={P2pBottomRightPatternSVG} pos="absolute" bottom="0" right="0" /> */}
    </Box>
  );
}
