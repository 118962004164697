import { RepeatIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Stack } from "@chakra-ui/react";
import { useGetWalletTransationsV2Query } from "apis";
import { Card, EmptyCrate, Group, PageLoading, Title, TitleBar, TransactionItem } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { usePartialState } from "hooks";
import { useMemo } from "react";
import { toQueryString, when } from "utils";

export default function DashboardTransactions() {
  const [queries] = usePartialState({
    page: 1,
    limit: 9,
    sortBy: "createdAt:desc",
  });

  const { data, isLoading, isFetching, refetch } = useGetWalletTransationsV2Query(toQueryString(queries));

  const transactions = useMemo(() => data?.transactions, [data]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);

  return (
    <Group mt="0" w="100%">
      <HStack justifyContent="space-between">
        <TitleBar>
          <Title fontSize="18px" isLoading={isFetching}>
            Recent Transactions
          </Title>

          {/* <TitleBarActions>
          <Link to="/home" textDecoration="underline">
            See All
          </Link>
        </TitleBarActions> */}
        </TitleBar>

        <IconButton
          p="10px"
          bg="transparent"
          color="secondary.400"
          alignSelf="center"
          minW="unset"
          minH="unset"
          h="fit-content"
          aria-label="refresh button"
          icon={<RepeatIcon color="currentColor" />}
          // onClick={() => Emitter.emit("refetch_p2p", {})}
          onClick={refetch}
        />
      </HStack>
      <Card w="100%" p="8px" overflow="hidden" maxH="596px">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading py="140px" isLoading={isLoading} />}
            {!isLoading && (
              <Stack>
                {(transactions ?? []).map((tx, id) => (
                  <TransactionItem
                    mt="0 !important"
                    w="100%"
                    key={`transaction-${id}`}
                    unit={tx?.unit}
                    type={tx?.type}
                    value={tx?.value}
                    createdAt={tx?.createdAt}
                    description={tx?.description}
                  />
                ))}
              </Stack>
            )}

            {!isLoading && !isFetching && !hasTx && <EmptyCrate py="140px" type="transaction" />}
          </motion.div>
        </AnimatePresence>
      </Card>
    </Group>
  );
}
