import { ThemeConfig, ChakraTheme, extendTheme, ThemeComponents } from "@chakra-ui/react";

// Override default foundations
import colors from "./foundations/colors";
import fonts from "./foundations/fonts";

// Override default components
import Button from "./components/button";
import Input from "./components/input";
import Select from "./components/select";

// Override default styles
import styles from "./styles";
import breakpoints from "./breakpoints";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
  cssVarPrefix: "bitmama",
};

const components: ThemeComponents = {
  Button,
  Input,
  Select,
  Textarea: Input,
};

const theme = extendTheme({
  config,
  colors,
  fonts,
  components,
  styles,
  breakpoints,
  shadows: {
    outline: `0 0 0 3px var(--outline, #48beab9f)`,
    darkOutline: `0 0 0 3px var(--outline, #6FBE456f)`,
    errOutline: `0 0 0 3px var(--errOutline, #ED2E7E4f)`,
    darkErrOutline: `0 0 0 3px var(--errOutline, #ED2E7E4f)`,
    card: `0px 2px 8px rgba(229, 229, 229, 0.6)`,
    error: "errorAlpha",
  },
} as Partial<ChakraTheme>);

export default theme;
