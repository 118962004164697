import { Box, TabList, TabPanel, Tabs } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Group, MainLayoutContainer, Steps, Tab, TabPanels, Title, TitleBar } from "components";
import { SidePageViewProps, useSidePage } from "contexts";
import { PageProps } from "interfaces";
import { useEffect, useState } from "react";
import { BuyAndSellTransactionDetails } from "ui/Common/BuyAndSellTransactionDetails";

import BuyAndSellTransactions from "../../../Common/BuyAndSellTransactions";
import SellCryptoForm from "./SellForm";

interface SellTabsProps extends PageProps {}

interface SellViewProps extends SidePageViewProps, PageProps {}

export default function SellView(props: SellViewProps) {
  const { user, isFetchingUser, ...xprops } = props;
  return (
    <Steps hideIndicator>
      <Box px={{ base: "20px", "2sm": "32px" }} {...xprops} overflowY="scroll">
        <TitleBar>
          <Title fontSize="20px">Sell Crypto</Title>
        </TitleBar>

        <MainLayoutContainer maxW="2xl" mt="28px" px={{ base: "0", "2sm": "20px", "3sm": "40px" }}>
          <Group mt="0">
            <SellTabs {...{ user, isFetchingUser }} />
          </Group>
        </MainLayoutContainer>
      </Box>

      <BuyAndSellTransactionDetails />
    </Steps>
  );
}

function SellTabs(props: SellTabsProps) {
  const { key, updateSidePageConfigs, onClose } = useSidePage();
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    buy: 0,
    transactions: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("subpage")?.split(":")[1] as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("subpage", `${key}:${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    updateSidePageConfigs({ _back: { text: "Close", onClick: () => onClose() } });
    // eslint-disable-next-line
  }, []);

  return (
    <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange}>
      <TabList borderRadius="8px" justifyContent="space-between">
        <Tab>Sell</Tab>
        <Tab>Transactions</Tab>
      </TabList>
      <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
        <TabPanel px={["2", "0", "0", "1"]}>
          <SellCryptoForm {...props} />
        </TabPanel>
        <TabPanel px={["0", "0", "0", "1"]}>
          <BuyAndSellTransactions subjectOf="sell_credit" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

SellView.displayName = "SidePageView";
