import {
  Badge,
  BadgeProps,
  Box,
  Button,
  ButtonProps,
  Grid,
  HStack,
  IconButton,
  Image,
  Stack,
  Td,
  Text,
  TextProps,
  Tooltip,
  Tr,
  useColorMode,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useGetCoinToUsdRateQuery } from "apis";
import BitcoinIMG from "assets/images/bitcoin.png";
import { P2PListingTradeRo } from "interfaces";
import { useMemo } from "react";
import { currencyFormat, switchStyle, toCoinLocale, toPrecision } from "utils";
import Username, { UsernameProps } from "./Username";

import isEmpty from "lodash/isEmpty";
import Icon from "components/Icon/Icon";
import toUpper from "lodash/toUpper";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import { useColor } from "hooks";

// import capitalize from "lodash/capitalize";

interface TableItemProps extends Partial<P2PListingTradeRo> {
  onClick?: () => void;
  _text?: TextProps;
  _username?: Partial<UsernameProps>;
}

interface AvailableRoleProps extends TableItemProps {
  _text?: TextProps;
}
interface PaymentMethodsProps extends TableItemProps {}
interface TraderDetailsProps extends TableItemProps {
  _username?: Partial<UsernameProps>;
}
interface ActionsProps extends TableItemProps {}

export default function TableItem(props: TableItemProps) {
  const bg = useColorModeValue("#ebebeb", "#ffffff0d");
  return (
    <Tr border="1px solid transparent" borderBottomColor="grey.500" _hover={{ cursor: "pointer", bg }}>
      <Td>
        <AvailableRole {...props} />
      </Td>
      <Td>
        <PaymentMethods {...props} />
      </Td>
      <Td>
        <TraderDetails {...props} />
      </Td>
      <Td>
        <Actions {...props} />
      </Td>
    </Tr>
  );
}

function Actions(props: ActionsProps) {
  const { adsType, onClick } = props;

  const { colorMode } = useColorMode();

  const info = useMemo(() => {
    const map: Record<string, ButtonProps & { label: string }> = {
      buying: { bg: "accent.400", label: "Sell", _active: { bg: "accent.500" }, _hover: { bg: "accent.300" } },
      selling: { bg: switchStyle(colorMode, { light: "primary.800", dark: "secondary.600" }), label: "Buy" },
    };

    return map[adsType ?? "buying"];
  }, [adsType, colorMode]);

  console.log("Action Info", info, adsType);

  return (
    // <Td>
    <VStack>
      <Button minW="110px" size="sm" onClick={onClick} {...info}>
        {info?.label ?? ""}
      </Button>
    </VStack>
    // </Td>
  );
}

function TraderDetails(props: TraderDetailsProps) {
  const { userData, presence, _username } = props;
  return (
    // <Td>
    <Username
      username={`@${userData?.username ?? ""}`}
      presence={presence?.status as any}
      {..._username}
      _presence={{ mr: { sm: "10px !important", md: "0" }, mt: { sm: "5px !important", md: "10px !important" } }}
      _wrapper={{ flexDir: { sm: "row-reverse", md: "column" }, justifyContent: { sm: "flex-end", md: "center" } }}
    />
    // </Td>
  );
}

function PaymentMethods(props: PaymentMethodsProps) {
  const { paymentOption, _text, timeout } = props;

  const label = (source: string) => {
    const map: Record<string, string> = {
      // [method]: `${capitalize(method)}`,
      external: "Bank Transfer",
      internal: "Bitmama Wallet",
    };

    return map[source];
  };
  return (
    // <Td>
    <VStack alignItems={{ sm: "flex-start", md: "center" }}>
      <HStack>
        {(paymentOption ?? []).map((option, i) => (
          <Method key={`p2p-payment-method-${i}`}>{label(option?.source)}</Method>
        ))}
      </HStack>

      <Text fontSize="12px" fontWeight="500" {..._text}>
        Completion time: {timeout}mins
      </Text>
    </VStack>
    // </Td>
  );
}

export function Method(props: BadgeProps) {
  return (
    <Badge borderRadius="50px" p="4px 10px" color="black" bg="#F7EBB8" fontWeight="600" textTransform="capitalize" {...props}>
      {props.children}
    </Badge>
  );
}

export function AvailableRole(props: AvailableRoleProps) {
  const { adsType, tradeOption, dynamic, /* volume, */ currentVolume, coin, currency, price, _text } = props;

  // const color = useColor();
  const { colorMode } = useColorMode();

  console.log("Listing info", props);

  const info = useMemo(() => {
    const map: Record<string, { color: string }> = {
      buying: { color: "accent.400" },
      selling: { color: switchStyle(colorMode, { light: "primary.default", dark: "secondary.500" }) },
    };

    return map[adsType ?? "buying"];
  }, [adsType, colorMode]);

  const reMapCoin = (coin: string) => {
    const map: Record<string, string> = {
      [coin]: coin,
      tbtc: "btc",
      teth: "eth",
      usdt: "usd",
      cusd: "usd",
    };
    return map[coin];
  };

  const adsTypeMap = (type: string) => {
    const map: Record<string, string> = {
      selling: "buy",
      buying: "sell",
    };
    return map[type];
  };

  const { data: rate } = useGetCoinToUsdRateQuery(reMapCoin(coin ?? "btc"), { skip: (coin ?? "btc").includes("usd") });

  const exRate = useMemo(() => {
    const r = rate?.exchangeRate ?? {};
    if (!isEmpty(r)) return (r as any)[adsTypeMap(adsType ?? "buying")] as number;
    return 1;
  }, [adsType, rate]);

  const unitCost = useMemo(() => {
    if (dynamic) return coin?.includes("usd") ? 1 * (price ?? 0) : exRate * (price ?? 0);
    return tradeOption?.unitCost;
  }, [coin, price, dynamic, exRate, tradeOption]);

  const priceLabel = useMemo(
    () =>
      `${currencyFormat((currency as any) ?? "ngn").format(unitCost ?? 0)} ${toCoinLocale(currency ?? "ngn")}/${toCoinLocale(
        coin ?? "btc"
      )}`,
    [unitCost, currency, coin]
  );

  const tooltipLabel = useMemo(() => `Dynamic listing @ ${price} ${toUpper(currency)}/USD`, [currency, price]);

  console.log("Price Label", exRate, unitCost, priceLabel);

  return (
    // <Td>
    <HStack alignItems="flex-start">
      <Image display={{ sm: "none", md: "inline-block" }} boxSize="32px" src={BitcoinIMG} alt="bitcoin image" />
      <Box ml={{ sm: "0 !important" }}>
        <HStack>
          <Text fontFamily="var(--bitmama-fonts-heading)" fontSize="14px" fontWeight="600" color={info?.color ?? ""}>
            {/* N12,545,931 NGN/Btc */}
            {priceLabel}
          </Text>

          <ConditionalRender shouldRender={!!dynamic}>
            <Tooltip hasArrow label={tooltipLabel} placement="top-start">
              <IconButton
                bg="transparent"
                color="transparent"
                aria-label="dyn"
                _hover={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                icon={<Icon type="bolt" boxSize="18px" />}
                sx={{ all: "unset" }}
              />
            </Tooltip>
          </ConditionalRender>
        </HStack>
        <Text fontSize="12px" fontWeight="600" color={info?.color ?? ""}>
          Available: {toPrecision(+(currentVolume?.$numberDecimal ?? 0), coin ?? "btc")}
        </Text>
        <Text fontSize="12px" fontWeight="500" {..._text}>
          Minimum: {toPrecision(+(tradeOption?.minimumFilling ?? 0), coin ?? "btc")}
        </Text>
        <Text fontSize="12px" fontWeight="500" {..._text}>
          Maximum: {toPrecision(+(tradeOption?.maximumFilling ?? 0), coin ?? "btc")}
        </Text>
      </Box>
    </HStack>
    // </Td>
  );
}

export function MobileTableItem(props: TableItemProps) {
  const color = useColor();
  return (
    <Box py="16px" border="1px solid transparent" borderBottomColor={color("grey.200", "grey.700")}>
      <Grid templateColumns="1fr .3fr">
        <Stack>
          <AvailableRole {...props} />
          <TraderDetails {...props} />
          <PaymentMethods {...props} />
        </Stack>

        <HStack justifyContent="flex-end">
          <Actions {...props} />
        </HStack>
      </Grid>
    </Box>
  );
}
