import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useAddEditLabelWithInternalIdMutation, useCreateCardMutation, useGetCardWishlistEligibilityQuery } from "apis";
import { useModalView, ViewSwitcherChildProps } from "components";
import { useDisclosures, useSelector } from "hooks";
import { AppAnalyticEvent } from "interfaces";
import { Emitter } from "libs";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setCardLabel } from "store/slices/filter";
import { AddEditCardLabel, SuccessModal } from "ui";
import WishlistModal from "ui/Common/Modals/Wishlist";
import PinView from "ui/Common/PinView";
import { when } from "utils";
import { StepProps } from "./RequestVirtualCard";

type ModalTypes = "wishlist" | "success";

interface StepFourProps extends StepProps, ViewSwitcherChildProps {
  wishlist_for?: "christmas" | "valentine";
}

export default function StepFour(props: StepFourProps) {
  const pinMax = 6;
  const { state, set, wishlist_for = "valentine" } = props;

  const { nextButtonProps } = useModalView();
  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const { isOpen: labelisOpen, onOpen: labelOnOpen, onClose: labelOnclose } = useDisclosure();

  const color = useColorModeValue("gray.600", "grey.100");

  const [, setHasCompleteValues] = useState(false);

  // const handleClick = () => setShow(!show);

  const [createCard, { data: createCardData, isLoading: isCreatingCard, isSuccess }] = useCreateCardMutation();
  const { data: wishlist } = useGetCardWishlistEligibilityQuery("create");
  const [_createLabelWithInternalId, { isSuccess: addLabelSuccess }] = useAddEditLabelWithInternalIdMutation();

  const { cardLabel } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const handlePin = (pin: string) => {
    const isValid = String(state?.pin ?? "").length === pinMax;
    if (!isValid) setHasCompleteValues(false);
    set({ pin });
  };

  const cardType = useMemo(() => {
    const map: Record<string, string> = {
      black: "tier1",
      pink: "tier2",
      green: "tier3",
    };

    return map[state?.cardType ?? "black"];
  }, [state?.cardType]);

  const handleSubmit = async (pin?: string) => {
    // console.log("STATE STEP3", state);
    const reqData = {
      pin: pin ?? state?.pin,
      coin: state?.wallet,
      fundAmount: +(state?.amount ?? 0),
      extraData: state?.extraData ?? {},
      cardType,
      color: state?.cardType,
      brand: state?.brand ?? "mastercard",
      provider: state?.cardProvider ?? "p3",
    };

    Emitter.emit(AppAnalyticEvent.CREATE_CARD_INITIATED, reqData);

    const response: any = await createCard(reqData).unwrap();
    if (!!response && !!response?._id) {
      Emitter.emit(AppAnalyticEvent.CREATE_CARD_SUBMITTED, reqData);

      if (!!cardLabel) {
        const labelData = {
          id: response._id,
          label: cardLabel,
        };

        const result = await _createLabelWithInternalId(labelData).unwrap();
        if (!!result) Emitter.emit(AppAnalyticEvent.ADD_CARD_LABEL, labelData);
        dispatch(setCardLabel(""));
      }
    }
  };

  const handleContinue = () => {
    // if (!!wishlist && !!wishlist?.eligible && isTesting) open("wishlist")();
    // else if (!!wishlist && !!wishlist?.eligible && !wishlist?.applied && !isTesting) open("wishlist")();
    // if (!!wishlist && !!wishlist?.eligible && !wishlist?.applied) open("wishlist")();

    close("success")();
    navigate("/cards");
  };

  const isDisabled = useMemo(
    () => Boolean(nextButtonProps?.disabled) || isCreatingCard || !state?.pin || (state?.pin ?? "")?.length < 6,
    [nextButtonProps, isCreatingCard, state?.pin]
  );

  useUpdateEffect(() => {
    if ((isSuccess && createCardData) || addLabelSuccess) {
      // open("success")();
      if (!!wishlist && !!wishlist?.eligible && !wishlist?.applied) open("wishlist")();
      else open("success")();
    }
  }, [isSuccess, createCardData, open, addLabelSuccess]);

  useEffect(() => {
    if (!cardLabel && !addLabelSuccess) {
      labelOnOpen();
    }
  }, [labelOnOpen, cardLabel, addLabelSuccess]);
  return (
    <Box maxW="container.sm" py="40px">
      <HStack>
        <Heading as="h6" fontSize="md" fontWeight="600" color={color}>
          Step 3
        </Heading>
        <Text ml="28px !important" fontSize="14px" fontWeight="500">
          Fill in the details below
        </Text>
      </HStack>

      <Stack mt="92px" px="8px">
        <PinView
          onPin={handlePin}
          _infoContainer={{ alignItems: "flex-start", ml: 0 }}
          _text={{ textAlign: "left" }}
          // isRequired
          _pin={{ onComplete: () => setHasCompleteValues(true) }}
          on_complete={(pin) => {
            setHasCompleteValues(true);
            handleSubmit(pin);
          }}
        />
      </Stack>

      <VStack mt="82px" mb="52px">
        <Button
          fontSize="16px"
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isCreatingCard}
          disabled={isDisabled}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </VStack>

      <SuccessModal
        isOpen={isOpen("success")}
        onClose={handleContinue}
        onContinue={handleContinue}
        closeOnContinue={false}
        message="Card was created successfully"
      />

      <AddEditCardLabel isOpen={labelisOpen} onClose={labelOnclose} cardId={createCardData?._id} />

      {!!wishlist && (
        <WishlistModal
          isOpen={isOpen("wishlist")}
          wishlistId={wishlist?.wishlistId}
          onClose={() => {
            close("wishlist")();
            navigate("/cards");
          }}
          gifts={wishlist?.gifts ?? []}
          message={`Congratulations on creating your new card!🎉 🎉 Make a wish and Santa might just grant it.`}
        />
      )}

      {!!wishlist && (
        <WishlistModal
          isOpen={isOpen("wishlist")}
          wishlistId={wishlist?.wishlistId}
          onClose={() => {
            close("wishlist")();
            navigate("/cards");
          }}
          gifts={wishlist?.gifts ?? []}
          message={`Congratulations on creating your new card!🎉 🎉 ${when(
            wishlist_for !== "christmas",
            "Make a wish and Cupid might just grant it",
            "Make a wish and Santa might just grant it"
          )}`}
        />
      )}
    </Box>
  );
}
