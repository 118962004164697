import { useEffect, useState } from "react";

export function useOnScreen() {
  const [onScreen, setOnscreen] = useState(true);

  useEffect(() => {
    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    function handleFocus() {
      setOnscreen(true);
    }
    function handleBlur() {
      setOnscreen(false);
    }

    function cleanup() {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    }
    return cleanup;
  }, []);

  return onScreen;
}
