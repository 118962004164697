import { FC } from "react";
import { VStack, InputGroup, Button, Input, InputRightElement, BoxProps, InputProps } from "@chakra-ui/react";
import { useCopy } from "hooks";
import { Icon } from "components";
import { truncate } from "lodash";

interface ShareActionsProps extends BoxProps {
  link?: string;
  title?: string;
  copyText?: string;

  _container?: BoxProps;
  _input?: InputProps;
}

const CopyRefCode: FC<ShareActionsProps> = (props) => {
  const { link, title, copyText, _container, _input } = props;
  const { onCopy } = useCopy(link ?? "");

  return (
    <VStack {..._container}>
      <InputGroup>
        <Input
          padding={{ base: "0 8px", "2sm": "20px" }}
          // disabled
          value={truncate(title, { length: 49 })}
          fontSize={{ base: "12px", "3sm": "14px" }}
          onChange={undefined}
          {..._input}
        />
        <InputRightElement
          px={{ base: "10px", "2sm": "20px" }}
          w="fit-content"
          children={
            <Button
              onClick={onCopy}
              p="0 !important"
              minW="fit-content"
              minH="fit-content"
              maxW="fit-content"
              variant="transparent"
              fontSize={{ base: "12px", "3sm": "14px" }}
              color="secondary.400"
              leftIcon={<Icon color="inherit" type="copy" />}
            >
              {copyText ?? "Copy Link"}
            </Button>
          }
        />
      </InputGroup>
    </VStack>
  );
};

export default CopyRefCode;
