import { Box, BoxProps, Button, Container, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { useMemo } from "react";

import Sphere from "assets/images/404-sphere.png";
import DefaultPrimaryCube from "assets/images/404-dp-cube.png";
import PrimaryLightCube from "assets/images/404-pl-cube.png";
import YellowCube from "assets/images/404-ylw-cube.png";
import Cylinder from "assets/images/404-cylinder.png";

import "../../styles/boundaryStyles.scss";
import { RepeatIcon } from "@chakra-ui/icons";
// import { IntercomProvider } from "react-use-intercom";
// import configs from "config";
// import { ShowCustomerSupport } from "components/Misc/ShowCustomerSupport";

type ObjectTypes = "Sphere" | "DefaultPrimaryCube" | "PrimaryLightCube" | "YellowCube" | "Cylinder";
interface PlaceObjectProps extends BoxProps {
  name: ObjectTypes;
}

export function ErrorContent(props: any) {
  return (
    // <IntercomProvider appId={configs.INTERCOM_TOKEN}>
    <VStack p="0" id="boundary" minW="100vw" minH="100vh" justifyContent="flex-start" pos="relative">
      <Container mt="20vh" id="boundary_container">
        <VStack style={{ padding: "0 20px", zIndex: 10, position: "relative" }}>
          <Heading as="h4" fontFamily="Polysans Trial" fontSize="48px" m="10px" textAlign="center" fontWeight="600">
            Unable to proceed or feeling stuck😩? Fret not, we're here to help.
          </Heading>
          <Text id="text" mt="42px !important" fontSize="24px" fontWeight="400" textAlign="center">
            For swift assistance, please shoot us an email at <a href="mailto:support@bitmama.io">support@bitmama.io</a> or simply
            click the Chatbot on the right side of your screen to chat with our dedicated customer support team. We're here to help
            you every step of the way!
          </Text>
        </VStack>

        <HStack className="hstack" style={{ marginTop: "40px" }}>
          {/* <Button className="btn" minW="268px" minH="55px" maxW="268px" maxH="55px" onClick={() => navigate("/home")}>
            Send us a message
          </Button> */}
          <Button
            className="btn btn--outline"
            minW="268px"
            minH="55px"
            maxW="268px"
            maxH="55px"
            onClick={() => !!window && window.location.reload()}
          >
            <HStack className="hstack">
              <RepeatIcon className="rotate" />
              <Text>Reload the Page</Text>
            </HStack>
          </Button>
        </HStack>
      </Container>

      {/* Objects placed by the right side of the 404 ui */}
      <PlaceObject name="Sphere" top="calc(100vh / 4)" right="140px" />
      <PlaceObject name="PrimaryLightCube" top="calc(100vh / 1.3)" right="152px" boxSize="68px" />

      {/* Objects placed by the left side of the 404 ui */}
      <PlaceObject name="Cylinder" top="calc(100vh / 1.8)" left="calc(100vw / 14)" />
      <PlaceObject name="YellowCube" top="calc(100vh / 7)" left="calc(100vw / 5.5)" boxSize="68px" />
      <PlaceObject name="DefaultPrimaryCube" top="calc(100vh / 1.1)" left="calc(100vw / 4)" boxSize="68px" />

      {/* <ShowCustomerSupport /> */}
      {/* </IntercomProvider> */}
    </VStack>
  );
}

function PlaceObject(props: PlaceObjectProps) {
  const { name, ...xprops } = props;

  const objectSource = useMemo(() => {
    const map: Record<ObjectTypes, string> = {
      Sphere,
      Cylinder,
      DefaultPrimaryCube,
      PrimaryLightCube,
      YellowCube,
    };

    return map[name];
  }, [name]);

  return (
    <Box
      as="img"
      pos="absolute"
      boxSize="54px"
      objectFit="contain"
      src={objectSource}
      alt={`${name} object`}
      pointerEvents="none"
      {...xprops}
    />
  );
}
