import { Button, FormControl, Heading, Input, Stack, VStack } from "@chakra-ui/react";
import { InputLabel, useModalView } from "components";
import { useMemo } from "react";
import { IStateExtension } from "./Form";

interface StepTwoProps extends IStateExtension {}

export default function StepTwo(props: StepTwoProps) {
  const { state, set } = props;
  const { onNext, onPrev } = useModalView();

  const isDisabled = useMemo(() => !(state?.street && state?.address && state?.city && state?.state && state?.zipCode), [state]);

  return (
    <Stack p="8px">
      <Stack my="38px !important">
        <Heading as="h6" fontSize="sm">
          Step 2/3 - Address Verification
        </Heading>
        {/* <Text fontSize="sm" fontWeight="500">
          Take a snapshot of your ID Card and upload
        </Text> */}
      </Stack>

      <FormControl mb="32px !important">
        <InputLabel htmlFor="street">Street Name</InputLabel>
        <Input
          placeholder="Enter Details"
          id="street"
          name="street"
          onChange={(e) => set({ street: e.target.value })}
          value={state?.street ?? ""}
        />
      </FormControl>

      <FormControl mb="32px !important">
        <InputLabel htmlFor="address">Flat, Suite, Unit, Building or Floor Number</InputLabel>
        <Input
          placeholder="Enter Details"
          id="address"
          name="address"
          onChange={(e) => set({ address: e.target.value })}
          value={state?.address ?? ""}
        />
      </FormControl>

      <FormControl mb="32px !important">
        <InputLabel htmlFor="city">Town/City</InputLabel>
        <Input
          placeholder="Enter Details"
          id="city"
          name="city"
          onChange={(e) => set({ city: e.target.value })}
          value={state?.city ?? ""}
        />
      </FormControl>

      {/* <FormControl mb="32px !important">
        <InputLabel htmlFor="state">State/District</InputLabel>
        <Select placeholder="Select State/District" id="state" name="state" onChange={handleChange} defaultValue={formState.state}>
          <option value="lagos">Lagos</option>
        </Select>
      </FormControl> */}

      <FormControl mb="32px !important">
        <InputLabel htmlFor="state">State/District</InputLabel>
        <Input
          placeholder="Enter Details"
          id="state"
          name="state"
          onChange={(e) => set({ state: e.target.value })}
          value={state?.state ?? ""}
        />
      </FormControl>

      <FormControl mb="32px !important">
        <InputLabel htmlFor="zipCode">Zip Code</InputLabel>
        <Input
          placeholder="Enter Details"
          id="zipCode"
          name="zipCode"
          onChange={(e) => set({ zipCode: e.target.value })}
          defaultValue={state?.zipCode ?? ""}
        />
      </FormControl>

      <VStack mt="34px !important" px={{ base: "0", md: "46px" }} w="100%">
        <Button minW="unset" w="100%" onClick={onNext} fontFamily="var(--bitmama-fonts-heading)" isDisabled={isDisabled}>
          Next
        </Button>

        <Button
          variant="transparent"
          textDecoration="underline"
          minW="unset"
          w="100%"
          onClick={onPrev}
          //   isLoading={isCancelling}
          //   disabled={isSubmiting || isCancelling}
        >
          Back
        </Button>
      </VStack>
    </Stack>
  );
}
