import { HStack, Text, useColorModeValue } from "@chakra-ui/react";
import Icon from "components/Icon/Icon";
import Link, { LinkProps } from "components/Link/Link";
import { when } from "utils";

interface AccountLinkProps extends Omit<LinkProps, "to"> {
  label: string;
  to?: string;
  icon?: string;
  noBorderLink?: boolean;
  arrowIcon?: "chevronRightArrow" | "arrowRight";
}

export default function AccountLink(props: AccountLinkProps) {
  const { label, to, icon, arrowIcon, noBorderLink, ...xprops } = props;

  const bc = useColorModeValue("grey.100", "dark.border");

  const borderColor = when(!!noBorderLink, "transparent", bc);
  const as = when<any>(!!to, Link, "button");
  const arrow = when(!!arrowIcon, arrowIcon as AccountLinkProps["arrowIcon"], "chevronRightArrow");

  return (
    <Link
      as={as}
      to={to ?? ""}
      mt="0 !important"
      p="16px"
      border="1px solid transparent"
      borderBottomColor={borderColor}
      _hover={{ textDecoration: "none" }}
      {...xprops}
    >
      <HStack justifyContent="space-between">
        <HStack>
          {icon && <Icon type={icon as any} />}

          <Text textDecoration="none" ml="12px !important">
            {label}
          </Text>
        </HStack>

        <Icon type={arrow as any} />
      </HStack>
    </Link>
  );
}
