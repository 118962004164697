import { Box, Button, Heading, Select, TabList, TabPanel, Tabs, Text, useColorModeValue } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Icon, Tab, TabPanels, Topbar /* useModalView as useSteps */ } from "components";
import { useColor, usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { useCallback, useState } from "react";
import { BuyingPanel, SellingPanel } from "ui";

interface AdvertisementsPanelProps extends PageProps {}

interface IQueryState {
  page: number;
  limit: number;
  adsStatus: string;
  hide: string | boolean;
  /**
   * Use `selling` for the buying ads page, and `buying` for the selling ads page.
   */
  adsType: "selling" | "buying";
  /**
   * Options are `createdAt:desc` | `createdAt:asc` | `price:asc`
   * `price:desc` | `unitCost:desc` | `totalCost:desc` ... and more.
   *  Use the format ``field:{desc | asc}``
   */
  coin: string;
  sortBy: string;
  coins: string;
  currency: string;
  fiatRanges: string;
  range: string;
  paymentOption: string;
}

export interface IFilter {
  endpoint: string; // --> /own, /others
  amount: string;
  queries: Partial<IQueryState>;
}

export default function AdvertisementsPanel(props: AdvertisementsPanelProps) {
  // const { user } = props;
  const location = useLocation();
  const color = useColor();
  const stroke = useColorModeValue("primary.600", "secondary.200");

  // const [status, setStatus] = useState("all");
  // const { setCurrentView, currentView } = useSteps();

  // page=1&limit=10&adsStatus=open&adsType=selling&sortBy=createdAt%3Adesc&coins=${coin}
  const [filter, setFilter] = usePartialState<IFilter>({
    endpoint: "/own",
    queries: {
      page: 1,
      limit: 10,
      adsStatus: "open",
      adsType: "selling",
      sortBy: "createdAt:desc",
    },
  });
  const setQueries = useCallback(
    (updates: Partial<IQueryState>) => setFilter({ ...filter, queries: { ...(filter as any)?.queries, ...updates } }),
    [setFilter, filter]
  );

  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    buyAds: 0,
    sellAds: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    // searchParams.set("tab", Object.keys(tabMap)[index]);
    // navigate(`/wallet?${searchParams.toString()}`);
  };
  const cardBg = useColorModeValue("#FFF", "#1E1E1E8f"); // {color("#FFF", "#303131")}
  const shadow = useColorModeValue("0px 2px 8px rgba(229, 229, 229, 0.47)", "0");

  const handleSelect = (e: any) => {
    // setStatus(e.target.value);
    const value = e.target.value;
    if (value === "inactive") {
      setQueries({ hide: "true" });
    }
    if (value === "active") {
      setQueries({ hide: "false" });
    }
    if (value === "all") {
      setQueries({ hide: "true,false" });
    }
  };
  return (
    <>
      <Topbar pageTitle="P2P" {...props} />

      <Box w="90%" mx="auto" pt="56px">
        {/* <HubBox mb="68px" />

      <P2PFilter<IFilter>
        user={user}
        filters={filter}
        set={setFilter}
        setView={setCurrentView}
        currentView={currentView}
        initialState={{ listings: "own" }}
      /> */}

        <Button
          size="sm"
          minW="fit-content"
          maxW="fit-content"
          variant="link"
          leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
          onClick={() => navigate(-1)}
          px="0px !important"
        >
          Back
        </Button>

        <Heading fontSize="24px" fontWeight="600" color={color("#041815", "white")} mb="74px">
          Manage Listings
        </Heading>
        <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange} mt="42px !important">
          <TabList borderRadius="8px" justifyContent="flex-start">
            <Tab w="fit-content" pl="20px !important" pr="20px !important" fontFamily="var(--bitmama-fonts-heading)">
              Your Buying Ads
            </Tab>
            <Tab w="fit-content" pl="20px !important" pr="20px !important" fontFamily="var(--bitmama-fonts-heading)">
              Your Selling Ads
            </Tab>
          </TabList>
          <Text color={color("grey.default", "white")} fontWeight="500" mb="5px" mt="25px">
            Filter
          </Text>
          <Select
            onChange={handleSelect}
            fontWeight="600"
            width="360px"
            bg={cardBg}
            borderRadius="5px"
            boxShadow={shadow}
            // value={presentTab}
          >
            <option value="all">All</option>
            <option value="inactive">Inactive</option>
            <option value="active">Active</option>
          </Select>
          <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
            <TabPanel px={["2", "0", "0", "1"]}>
              <BuyingPanel filters={filter} setQueries={setQueries} />
            </TabPanel>
            <TabPanel px={["0", "0", "0", "1"]}>
              <SellingPanel filters={filter} setQueries={setQueries} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
