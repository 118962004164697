type CardType = "tier1" | "tier2" | "tier3" | "tier4" | "tier5";

export default function useCreditCardScheme(type: CardType = "tier1") {
  const getColorScheme = (type: CardType) => {
    const map: Record<string, string> = {
      tier1: "whitesmoke",
      tier2: "red",
      tier3: "blue",
      tier4: "green",
      tier5: "black",
    };

    return map[type ?? "tier1"];
  };

  const getScheme = (colorScheme: string) => {
    const map: Record<string, { foreground: string; background: string; borderColor: string; ornament: string }> = {
      white: {
        foreground: "primary.800",
        background: "white",
        borderColor: "#e7e7e7",
        ornament: "#31B7A90f",
      },
      whitesmoke: {
        foreground: "primary.800",
        background: "whitesmoke",
        borderColor: "#e7e7e7",
        ornament: "#31B7A90f",
      },
      red: {
        foreground: "white",
        background: "linear-gradient(294deg, #DA4040 6.76%, #9E2929 88.75%), #DA4040",
        borderColor: "transparent",
        ornament: "#FFFFFF10",
      },
      blue: {
        foreground: "white",
        background: "linear-gradient(294deg, #3D90FF 6.76%, #1D70E1 88.75%), #1D70E1",
        borderColor: "transparent",
        ornament: "#FFFFFF10",
      },
      green: {
        foreground: "white",
        background: "linear-gradient(294deg, #66C961 6.76%, #3F943B 88.75%), #79D775",
        borderColor: "transparent",
        ornament: "#FFFFFF10",
      },
      black: {
        foreground: "white",
        background: "linear-gradient(294deg, #282930 6.76%, #16171C 88.75%), #282930;",
        borderColor: "transparent",
        ornament: "#FFFFFF10",
      },
    };

    return map[colorScheme ?? "whitesmoke"];
  };

  return { scheme: getScheme(getColorScheme(type)), getColorScheme, getScheme };
}
