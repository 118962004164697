import { ReactNode } from "react";
import { when } from "utils";

interface ConditionalRenderProps {
  shouldRender: boolean;
  children: ReactNode;
}

export function ConditionalRender(props: ConditionalRenderProps) {
  const { shouldRender, children } = props;
  return when(!!shouldRender, <>{children}</>, <></>);
}
