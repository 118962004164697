import { useMemo, useState } from "react";
import { Box, Button, Heading, HStack, useColorModeValue, Text, Stack, VStack, IconButton } from "@chakra-ui/react";
import {
  Card,
  Icon,
  MainLayoutContainer,
  PageMotion,
  PageLoading,
  TransactionItem,
  EmptyCrate,
  Paginator,
  Group,
} from "components";
import { navigate } from "@reach/router";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { AnimatePresence, motion } from "framer-motion";
// import { AddFormV1 } from "./_Add";
import { when, toCoinLocale, currencyFormat, toQueryString } from "utils";
import { useGetCryptoAlertOrdersQuery } from "apis";
import { capitalize } from "lodash";
import { RepeatIcon } from "@chakra-ui/icons";

interface CryptoPriceAlertOrdersProps extends PageProps {}

export default function AddCryptoPriceAlert(props: CryptoPriceAlertOrdersProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");
  const { goBack } = useBuiltInFn();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const { data, isLoading, isFetching, refetch } = useGetCryptoAlertOrdersQuery(
    toQueryString({ page: currentPage, limit, cancelled: false })
  );

  const orders = useMemo(() => data?.data ?? [], [data]);
  const totalCount = useMemo(() => data?.totalCount ?? 0 ?? [], [data?.totalCount]);
  const hasOrders = useMemo(() => orders?.length > 0 || totalCount > 0, [orders, totalCount]);

  const handleItemClick = (id: string) => {
    navigate(`/account/pricealerts/orders/${id}`);
  };

  console.log("Orders", data);

  return (
    <PageMotion subpage key="AccountCryptoPriceAlertOrders" overflowY="scroll">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <HStack w="100%" justifyContent="space-between">
              <Box>
                <Heading as="h5" fontSize="xl">
                  {/* Price Alert Orders */} Auto Buy/Sell Orders
                </Heading>

                <Text fontSize={{ base: "12px", "2sm": "14px" }} mt="8px" fontWeight="500">
                  Buy/Sell orders when price reached scheduled price
                </Text>
              </Box>

              <IconButton
                p="10px"
                bg="transparent"
                color="secondary.400"
                alignSelf="center"
                minW="unset"
                minH="unset"
                h="fit-content"
                aria-label="refresh button"
                icon={<RepeatIcon color="currentColor" />}
                // onClick={() => Emitter.emit("refetch_p2p", {})}
                onClick={refetch}
              />
            </HStack>
          </HStack>

          <Group mt="0" w="100%" py="22px">
            <Card bg={bg} w="100%" p="8px" overflow="hidden">
              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                  key={`tx-list-${when(isLoading, "loading", "loaded")}`}
                  initial={{ opacity: 0, scale: 0.98 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.98 }}
                >
                  {isLoading && <PageLoading isLoading={isLoading} />}
                  {!isLoading && (
                    <Stack>
                      {(orders ?? []).map((tx: any, id: number) => (
                        <TransactionItem
                          mt="0 !important"
                          w="100%"
                          key={`transaction-${id}`}
                          unit={when(tx?.action === "sell", tx?.coin, tx?.currency)}
                          type={when(tx?.action === "sell", "debit", "credit")}
                          value={tx?.amount}
                          createdAt={tx?.createdAt}
                          description={`${capitalize(tx?.action ?? "buy")} when ${toCoinLocale(tx?.coin ?? "btc")} ${when(
                            (tx?.direction ?? "down") === "down",
                            "falls below",
                            "goes above"
                          )} ${currencyFormat("usd").format(+(tx?.spotPrice ?? 0))}`}
                          onClick={() => handleItemClick(tx?._id)}
                        />
                      ))}
                    </Stack>
                  )}

                  {!isLoading && !isFetching && !hasOrders && <EmptyCrate py="140px" type="transaction" />}
                </motion.div>
              </AnimatePresence>
            </Card>

            <VStack>
              {!isLoading && hasOrders && (
                <Paginator
                  totalCount={totalCount}
                  page={currentPage}
                  limit={limit}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              )}
            </VStack>
          </Group>
        </Box>
      </MainLayoutContainer>
    </PageMotion>
  );
}
