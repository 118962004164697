import { Redirect, RouteComponentProps } from "@reach/router";

interface Props extends RouteComponentProps {
  to: string;
  isRelative?: boolean;
  noThrow?: boolean;
}

export default function NoMatch({ to, isRelative = false, noThrow = false, uri }: Props) {
  if (uri == null) return null;

  const cleanedURI = uri.slice(-1) === "/" ? uri.slice(0, uri.length - 1) : uri;

  return <Redirect noThrow={noThrow} from="*" to={isRelative === true ? `${cleanedURI}/${to}` : to} />;
}
