import { useCallback } from "react";
import {
  BitcoinSVG,
  EthereumSVG,
  CeloSVG,
  UsdtSVG,
  UsdcSVG,
  StellarSVG,
  RippleSVG,
  CeurSVG,
  CusdSVG,
  NairaSVG,
  CedisSVG,
  KenyaSVG,
  MaticSVG,
  CameroonSVG,
  BeninSVG,
  BurkinaFasoSVG,
  IvoryCoastSVG,
  TogoSVG,
  SenegalSVG,
  CongoSVG,
  SouthAfricaSVG,
  PYUsdSVG,
  USDCBaseSVG,
  TonSVG,
} from "assets";
import { useColorModeValue, Icon, Box } from "@chakra-ui/react";
import useSelector from "./useSelector";
import { selectUser } from "store/slices";
import { when } from "utils";

import toLower from "lodash/toLower";

// import toPrecision from "util/toPrecision";
// import formatter from "util/formatter";
// import constants from "config/constant";

export const supportedCoinType = [
  "ngn",
  "ghs",
  "kes",
  "btc",
  "tbtc",
  "eth",
  "teth",
  "usdc",
  "usdt",
  "pyusd",
  "usdc-base",
  "celo",
  "ceur",
  "cusd",
  "xrp",
  "matic",
  "xlm",
  "xof",
  "xaf",
  "cdf",
  "zar",
  "ton",
] as const;

export type SupportedCoinType = (typeof supportedCoinType)[number];

type CoinInfoType = {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

type CoinInfoTypeVariant = { [country: string]: CoinInfoType };

const RippleIcon = (props: any) => {
  const rippleColor = useColorModeValue("#23292f", "white");
  return <Icon as={RippleSVG} color={rippleColor} {...props} />;
};

const CameroonIcon = (props: any) => {
  return <Box as={CameroonSVG} transform="scale(1.5)" />;
};

const BeninIcon = (props: any) => {
  return <Box as={BeninSVG} transform="scale(1.5)" />;
};
const BurkinaFasoIcon = (props: any) => {
  return <Box as={BurkinaFasoSVG} transform="scale(1.5)" />;
};
const IvoryCoastIcon = (props: any) => {
  return <Box as={IvoryCoastSVG} transform="scale(1.5)" />;
};
const TogoIcon = (props: any) => {
  return <Box as={TogoSVG} transform="translate(10px, 0px) scale(1.68)" />;
};
const SenegalIcon = (props: any) => {
  return <Box as={SenegalSVG} transform="scale(1.5)" />;
};
const CongoIcon = (props: any) => {
  return <Box as={CongoSVG} transform="translate(6px, 4px) scale(1.7)" />;
};
const SouthAfricaIcon = (props: any) => {
  return <Box as={SouthAfricaSVG} transform="translate(0px, 0px) scale(1.7)" />;
};
// const TonIcon = (props: any) => {
//   return <Box as={TonSVG} transform="translate(0px, 0px) scale(1)" />;
// };

export default function useCoin(coin?: SupportedCoinType) {
  const { profile } = useSelector(selectUser);
  const { country: cntry } = profile ?? {};

  const getWalletInfo = useCallback(
    (coin: SupportedCoinType) => {
      const map: Record<SupportedCoinType, CoinInfoType | CoinInfoTypeVariant> = {
        ngn: { label: "Naira", icon: NairaSVG },
        ghs: { label: "Cedis", icon: CedisSVG },
        kes: { label: "Shillings", icon: KenyaSVG },
        btc: { label: "Bitcoin", icon: BitcoinSVG },
        tbtc: { label: "Bitcoin", icon: BitcoinSVG },
        eth: { label: "Ethereum", icon: EthereumSVG },
        teth: { label: "Ethereum", icon: EthereumSVG },
        usdc: { label: "USDC", icon: UsdcSVG },
        usdt: { label: "USD Tether", icon: UsdtSVG },
        celo: { label: "Celo", icon: CeloSVG },
        cusd: { label: "cUSD", icon: CusdSVG },
        ceur: { label: "cEUR", icon: CeurSVG },
        xrp: { label: "Ripple", icon: RippleIcon },
        xlm: { label: "Stellar", icon: StellarSVG },
        matic: { label: "Polygon", icon: MaticSVG },
        pyusd: { label: "Paypal USD", icon: PYUsdSVG },
        ton: { label: "TON", icon: TonSVG },
        "usdc-base": { label: "Base Chain", icon: USDCBaseSVG },
        xaf: {
          cameroon: {
            label: "Cameroon - XAF",
            icon: CameroonIcon,
          },
          default: {
            // label: "Central African Franc",
            label: "Cameroon - XAF",
            icon: CameroonIcon,
          },
        },
        xof: {
          togo: {
            label: "Togo - XOF",
            icon: TogoIcon,
          },
          benin: {
            label: "Benin - XOF",
            // label: "West African Franc",
            icon: BeninIcon,
          },
          senegal: {
            label: "Senegal - XOF",
            icon: SenegalIcon,
          },
          "burkina faso": {
            label: "Burkina Faso - XOF",
            icon: BurkinaFasoIcon,
            // icon: TogoIcon,
          },
          "ivory caost": {
            label: "Cote D'Ivoire - XOF",
            icon: IvoryCoastIcon,
          },
          "cote d'ivoire": {
            label: "Cote D'Ivoire - XOF",
            icon: IvoryCoastIcon,
          },
          default: {
            label: "West African Franc",
            // icon: BeninIcon,
            // icon: BurkinaFasoIcon,
            // icon: pickRandom([TogoIcon, BeninIcon, SenegalIcon, BurkinaFasoIcon, IvoryCoastIcon]),
            icon: SenegalIcon,
          },
        },
        cdf: { label: "Congolese Franc", icon: CongoIcon },
        zar: { label: "South African Rand", icon: SouthAfricaIcon },
      };

      const info = map[coin ?? "ngn"] ?? {};
      const isVariant = !(Object.hasOwn(info, "label") && Object.hasOwn(info, "icon"));
      const _country = toLower(cntry ?? "default");

      // console.log("[useCoin]", coin, info, isVariant, _country);

      // forcefully pick the default when current user's country and currency pair are not found
      const country = when(isVariant && Object.hasOwn(info, _country), _country, "default");

      // console.log("Wallet Info", typeof info, country);
      return when<CoinInfoType>(isVariant, (info as CoinInfoTypeVariant)[country], info as CoinInfoType);
    },
    [cntry]
  );

  const info = getWalletInfo(coin ?? "ngn");

  return { ...info, getWalletInfo };
}

// function pickRandom<T>(arr: T[]): T {
//   const random_value = Math.floor(Math.random() * arr.length);
//   return arr[random_value];
// }
