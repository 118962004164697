import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Heading,
  Input,
  Stack,
  Text,
  VStack,
  useUpdateEffect,
} from "@chakra-ui/react";
import { ConditionalRender, FadeInOut, FormInfo, Icon, InputLabel, Link, Select, useModalView } from "components";
import { useGetAirtimePlansQuery, useGetBanksByCountryQuery, useGetUserBankDetailsQuery, useResolveAccountMutation } from "apis";
import { useColor, useDebounce, useRedebounce, useSelector, useSignupInputCheck, useValidatePhoneNumber } from "hooks";
import { selectAuth, selectUser } from "store/slices";

import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import toLower from "lodash/toLower";
import { IStateExtension } from "./Form";
import isEqual from "lodash/isEqual";
import { when } from "utils";

type CheckableInputType = {
  bvn?: string | undefined;
};

interface StepOneProps extends IStateExtension {}

export function OtherStepOne(props: StepOneProps) {
  const { state, set } = props;
  const { onNext } = useModalView();

  const color = useColor();
  const debounce = useDebounce();
  const { profile } = useSelector(selectUser);
  const { checks } = useSelector(selectAuth);

  const country = useMemo(() => (!!profile?.country ? String(profile?.country).toLowerCase() : "nigeria"), [profile]);

  const { data: networks, isLoading: isNetworksLoading } = useGetAirtimePlansQuery(country.toLowerCase(), {
    skip: ["nigeria"].includes(country.toLowerCase()),
  });
  const { data: userSavedBank, isLoading: isBankLoading } = useGetUserBankDetailsQuery({});
  const { makeCheck, check } = useSignupInputCheck();

  // const [refresh, setRefresh] = useState(false);

  const bvnData = useMemo(() => checks?.bvn?.bvnData, [checks?.bvn?.bvnData]);
  const isChecking = useCallback((type: "bvn") => check(type).status === "fetching", [check]);
  const isAlreadyInUse = useCallback((type: "bvn") => check(type).inUse, [check]);

  const isNigerian = useMemo(() => when(!!profile?.country, toLower(profile?.country) === "nigeria", false), [profile]);
  const collectBvn = useMemo(() => isNigerian && !profile?.bvn, [isNigerian, profile?.bvn]);

  const isBVNInvalid = useMemo(() => !!collectBvn && !!state?.bvn && state?.bvn.length < 11, [collectBvn, state?.bvn]);

  const filteredBank =
    userSavedBank && userSavedBank.bank.filter(({ type, country: c }) => type === "bank" && toLower(c) === toLower(country));

  const momos = useMemo(
    () => (userSavedBank?.bank ?? []).filter((bank) => !!bank?.type && bank?.type === "mobilemoney"),
    [userSavedBank]
  );

  const countryCodeMap: Record<string, string> = {
    nigeria: "ng",
    ghana: "gh",
    kenya: "ke",
    "united kingdom": "gb",
    canada: "ca",
    "south africa": "za",
  };

  // console.log("Bank details", filteredBank);

  const [resolveBank, { data: resolvedBank, isLoading: isResolvingBank, reset /*error, isError*/ }] = useResolveAccountMutation();
  const { data, isLoading } = useGetBanksByCountryQuery(countryCodeMap[String(country).toLowerCase()], { skip: !country });

  console.log("BankUniq", data, country);

  const banks = useMemo(() => {
    const sort = (list: any[]) => sortBy(list, ({ bankName }) => bankName?.toLowerCase());
    if (country === "nigeria" && data) return sort(data["bankPayloads"]);
    if (country === "ghana" && data) return sort(data["ghanaBankPayloads"]);
    return [];
  }, [data, country]);

  const bankAccountName = useMemo(() => resolvedBank?.account_name, [resolvedBank]);

  const use_momo = useMemo(() => ["ghana"].includes(country.toLowerCase()), [country]);

  // console.log("banks", banks, isLoading);

  const handleCheckableInput = useCallback(
    (update: CheckableInputType) => {
      set(update);

      if (!!update?.bvn && update?.bvn?.length === 11) debounce(() => makeCheck("bvn", update?.bvn!), "makeBVNCheck", 500)();
    },
    [makeCheck, debounce, set]
  );

  const debounceResolveBank = useCallback(
    (bankCode: string, bankAccountNumber: string) =>
      debounce(
        () =>
          resolveBank({
            bankCode,
            bankAccountNumber,
          }),
        "ResolveBank",
        2500
      )(),
    [debounce, resolveBank]
  );

  const handleBankName = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    const [bankCode, bankName] = String(value).split("-");

    set({ bankCode, bankName, bankAccountName: bankName });

    if (!!state?.bankAccountNumber && !!bankCode && state?.bankAccountNumber.length === 10 && country === "nigeria")
      debounceResolveBank(bankCode, state?.bankAccountNumber);
  };

  const handleNetwork = (e: any) => {
    e.preventDefault();
    const value = e.target.value;

    set({
      bankName: value,
      network: value,
      bankType: "mobilemoney",
    });
  };

  useEffect(() => {
    if (country.toLowerCase() !== "ghana") {
      set({ bankAccountName: undefined });
    }
  }, [country, set]);

  const handleBank = (event: any) => {
    const [bankName, bankAccountNumber, bankId] = event.target.value.split("-");
    set({ bankName, bankAccountNumber, bankId });
    reset();
    // state.bankName = split[0];
    // state.bankAccountNumber = split[1];
    // state.bankId = split[2];
    // setRefresh(!refresh);
  };

  const handleAccountNumber = (e: any) => {
    e.preventDefault();
    const value = e.target.value as string;
    set({ bankAccountNumber: value });
    // set({ bankAccountNumber: value, bankAccountName: undefined });
    reset();

    if (!!state?.bankCode && value && value.length === 10 && country === "nigeria") debounceResolveBank(state?.bankCode, value);
  };

  useEffect(() => {
    if (resolvedBank)
      set({
        bankAccountName: resolvedBank?.account_name,
      });

    // eslint-disable-next-line
  }, [resolvedBank]);

  const isDisabled = useMemo(
    () =>
      (isEmpty(filteredBank) &&
        !use_momo &&
        !(state?.bankCode && state?.bankName && state?.bankAccountNumber && state?.bankAccountName)) ||
      (!isEmpty(filteredBank) && !(state?.bankName && state?.bankId && state?.bankAccountNumber)) ||
      (isEmpty(momos) && !!use_momo && !(state?.bankName && state?.bankAccountNumber && state?.network)) ||
      (!isEmpty(momos) && !!use_momo && !(state?.bankName && state?.bankId && state?.bankAccountNumber)) ||
      (toLower(country) === "ghana" && !state?.branchName) ||
      (isNigerian && collectBvn && !state?.bvn) ||
      (isNigerian && collectBvn && isBVNInvalid),
    [filteredBank, state, country, isNigerian, collectBvn, isBVNInvalid, use_momo, momos]
  );

  console.log("Networks", networks);

  return (
    <Stack p="8px">
      <Heading my="38px !important" as="h6" fontSize="sm">
        Step 1/3 - Bank Verification
      </Heading>
      <ConditionalRender shouldRender={!!collectBvn}>
        <FormControl mb="32px !important">
          <InputLabel isInvalid={isBVNInvalid} isLoading={isChecking("bvn")} htmlFor="bvn">
            Bank Verification Number (BVN)
          </InputLabel>
          <Input
            isInvalid={isBVNInvalid}
            maxLength={11}
            // disabled={!state?.docType}
            name="bvn"
            defaultValue={state?.bvn ?? profile?.bvn}
            id="bvn"
            onChange={(e) => handleCheckableInput({ bvn: String(e.target.value).trim() })}
          />

          <Box>
            {state?.bvn && isBVNInvalid && <FormWarning info={"BVN is an 11-digit number"} isValid={!isBVNInvalid} />}
            {state?.bvn && !isChecking("bvn") && !isBVNInvalid && bvnData && (
              <FormWarning
                info={when(
                  isAlreadyInUse("bvn") && !isChecking("bvn"),
                  `BVN verification failed`,
                  // `${join([bvnData?.firstName, bvnData?.lastName], " ")}`
                  "Good to go"
                )}
                isValid={!isAlreadyInUse("bvn")}
              />
            )}
          </Box>
        </FormControl>
      </ConditionalRender>

      {isEmpty(filteredBank) && !isEmpty(banks) && !use_momo && (
        <FormControl mb="32px !important">
          <InputLabel htmlFor="bankName" isLoading={isLoading}>
            Bank Name
          </InputLabel>

          <Select
            placeholder="Select Bank"
            name="bankName"
            id="bankName"
            value={`${state?.bankCode}-${state?.bankName}` ?? ""}
            onChange={handleBankName}
          >
            {!isEmpty(banks) &&
              banks.map((country, i) => (
                <option key={i} value={`${country?.bankCode}-${country?.bankName}`}>
                  {country?.bankName}
                </option>
              ))}
          </Select>
        </FormControl>
      )}

      {isEmpty(momos) && !isEmpty(networks) && !!use_momo && (
        <FormControl mb="32px !important">
          <InputLabel htmlFor="bankName" isLoading={isNetworksLoading}>
            Bank Name / Network
          </InputLabel>

          <Select placeholder="Select Network" name="network" id="network" value={state?.bankName} onChange={handleNetwork}>
            {(networks ?? []).map(({ name, operatorId }) => (
              <option key={operatorId} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      {!isEmpty(momos) && !!use_momo && (
        <FormControl mb="32px !important">
          <InputLabel isLoading={isBankLoading}>Bank Name / Network</InputLabel>

          <Select placeholder="Select Bank" onChange={handleBank}>
            {momos?.map(({ _id, bankAccountName, bankName, bankAccountNumber }: any) => (
              <option
                key={_id}
                onClick={() => handleBankName(bankAccountName)}
                value={`${bankAccountName}-${bankAccountNumber}-${_id}`}
              >
                {bankAccountName}, {bankName}
              </option>
            ))}
          </Select>

          <FormHelperText fontWeight="500">
            <HStack>
              <Text color={color("grey.300", "grey.200")}>Add new network</Text>
              <Link ml="3px !important" type="button" to={"/account/momo/add"} color={"secondary.400"} fontWeight="500">
                here
              </Link>
            </HStack>
          </FormHelperText>
        </FormControl>
      )}

      {!isEmpty(filteredBank) && !use_momo && (
        <FormControl mb="32px !important">
          <InputLabel isLoading={isBankLoading}>Bank</InputLabel>

          <Select placeholder="Select Bank" onChange={handleBank}>
            {filteredBank?.map(({ _id, bankAccountName, bankName, bankAccountNumber }: any) => (
              <option
                key={_id}
                onClick={() => handleBankName(bankAccountName)}
                value={`${bankAccountName}-${bankAccountNumber}-${_id}`}
              >
                {bankAccountName}, {bankName}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl mb="32px !important">
        <InputLabel isLoading={isResolvingBank} htmlFor="acccountNumber">
          Account Number
        </InputLabel>
        <Input
          placeholder="Enter Account Number"
          id="acccountNumber"
          name="acccountNumber"
          defaultValue={state?.bankAccountNumber}
          value={state?.bankAccountNumber}
          onChange={handleAccountNumber}
          disabled={!isEmpty(filteredBank) || !isEmpty(momos)}
        />
      </FormControl>
      {!!country && toLower(country) !== "nigeria" && (
        <FormControl mb="32px !important">
          <InputLabel htmlFor="branchName">Bank Branch</InputLabel>
          <Input
            placeholder="Enter Branch"
            id="branchName"
            name="branchName"
            defaultValue={state?.branchName}
            onChange={(e) => set({ branchName: e.target.value })}
          />
        </FormControl>
      )}
      {!isEmpty(filteredBank) && toLower(country) !== "nigeria" && !isBankLoading && (
        <FormControl mb="32px !important">
          <InputLabel htmlFor="bankAcctName">Account Name</InputLabel>
          <Input
            id="bankAcctName"
            placeholder="Account Name"
            defaultValue={bankAccountName ?? state?.bankAccountName}
            disabled={!isEmpty(filteredBank)}
          />
        </FormControl>
      )}
      {isEmpty(filteredBank) && !!country && toLower(country) === "nigeria" && !isBankLoading && (
        <FormControl mb="32px !important">
          <InputLabel htmlFor="bankAcctName">Account Name</InputLabel>
          <Input id="bankAcctName" placeholder="Account Name" value={bankAccountName ?? state?.bankAccountName} disabled />
        </FormControl>
      )}
      {/* <FormControl mb="32px !important">
        <InputLabel>Account Number</InputLabel>
        <Input
          placeholder="Enter Bank Account Number"
          // value={ bankAccountNumber }
          defaultValue={state?.bankAccountNumber}
        />
      </FormControl> */}
      <VStack mt="34px !important" px={{ base: "0", md: "46px" }} w="100%">
        <Button minW="unset" w="100%" onClick={onNext} fontFamily="var(--bitmama-fonts-heading)" disabled={isDisabled}>
          Save and Continue
        </Button>
        {/* <Button variant="transparent" textDecoration="underline" minW="unset" w="100%">
          Cancel
        </Button> */}
      </VStack>
    </Stack>
  );
}

export function FrancStepOne(props: StepOneProps) {
  const { state, set } = props;
  const { onNext } = useModalView();

  const { profile } = useSelector(selectUser);

  const prevInput = useRef({});
  const debounce = useRedebounce();

  const country = useMemo(() => (!!profile?.country ? String(profile?.country).toLowerCase() : "nigeria"), [profile]);
  const { data: userBanks } = useGetUserBankDetailsQuery({});
  const { data: plans } = useGetAirtimePlansQuery(country ? country.toLowerCase() : "nigeria");

  const { validate, isWrongNetwork } = useValidatePhoneNumber(country);
  const momos = useMemo(() => (userBanks?.bank ?? []).filter((bank) => !!bank?.type && bank?.type === "mobilemoney"), [userBanks]);

  const alreadyExist = useMemo(
    () =>
      !!state?.bankAccountNumber &&
      (momos ?? []).findIndex(
        (momo) =>
          momo?.bankAccountNumber === state?.bankAccountNumber ||
          (state?.bankAccountNumber ?? "").includes(momo?.bankAccountNumber ?? "") ||
          (momo?.bankAccountNumber ?? "").includes(state?.bankAccountNumber ?? "")
      ) !== -1,
    [momos, state?.bankAccountNumber]
  );

  const isDisabled = useMemo(
    () =>
      !(state?.network && state?.bankAccountNumber) ||
      (!!state?.bankAccountNumber && state.bankAccountNumber.length < 5) ||
      alreadyExist ||
      isWrongNetwork,
    [state, alreadyExist, isWrongNetwork]
  );

  useUpdateEffect(() => {
    console.log("Phone Number", state?.bankAccountNumber);
    if (
      !!state?.network &&
      !!state?.bankAccountNumber &&
      String(state?.bankAccountNumber).length >= 9 &&
      !isEqual(prevInput.current, { network: state?.network, phone: state?.bankAccountNumber })
    ) {
      debounce(
        () => validate({ network: toLower(state?.network!), phoneNumber: state?.bankAccountNumber! }),
        "validatePhone",
        500
      )();

      prevInput.current = { network: state?.network, phone: state?.bankAccountNumber };
    }
  }, [prevInput, state]);

  return (
    <Stack p="8px">
      <Heading my="38px !important" as="h6" fontSize="sm">
        Step 1/3 - Bank Verification
      </Heading>

      <FormControl mb="28px !important">
        <InputLabel htmlFor="network">Network Type</InputLabel>
        <Select
          placeholder="Select Network Type"
          name="network"
          id="network"
          value={state?.network}
          onChange={(e) => set({ network: e.target.value, bankType: "mobilemoney", _bankType: "mobilemoney" })}
        >
          {(plans ?? []).map(({ name, operatorId }) => (
            <option key={operatorId} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl mb="28px !important">
        <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>

        <Input
          type="text"
          isRequired
          placeholder="Enter Phone Number"
          id="phoneNumber"
          min={0}
          value={state?.bankAccountNumber}
          name="bankAccountNumber"
          onChange={(e) => set({ bankAccountNumber: e.target.value })}
        />

        {alreadyExist && (
          <FormInfo
            mt="10px"
            isInvalid={alreadyExist}
            info=""
            description="Phone number already exists"
            _description={{ ml: "0 !important" }}
          />
        )}

        {isWrongNetwork && (
          <FormInfo
            mt="10px"
            isInvalid={isWrongNetwork}
            info=""
            description="Wrong phone network"
            _description={{ ml: "0 !important" }}
          />
        )}
      </FormControl>

      <VStack mt="34px !important" px={{ base: "0", md: "46px" }} w="100%">
        <Button minW="unset" w="100%" onClick={onNext} fontFamily="var(--bitmama-fonts-heading)" disabled={isDisabled}>
          Save and Continue
        </Button>
        {/* <Button variant="transparent" textDecoration="underline" minW="unset" w="100%">
          Cancel
        </Button> */}
      </VStack>
    </Stack>
  );
}

function FormWarning(props: { info: string; isValid: boolean }) {
  const { info, isValid } = props;

  const color = useColor();

  return (
    <FormHelperText fontSize="sm" fontWeight="500" color={when(!isValid, "error", color("primary.700", "secondary.400"))}>
      <HStack alignItems="flex-start">
        <FadeInOut key={`password-is-${when(isValid, "valid", "invalid")}`}>
          {!isValid && <Icon type="shieldOutlined" color="inherit" />}
          {isValid && <Icon type="verifiedUser" color="inherit" />}
        </FadeInOut>
        <Text>{info}</Text>
      </HStack>
    </FormHelperText>
  );
}
