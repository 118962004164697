import { Box, Button, Heading, HStack, Stack, Text, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
import { EarnTopRightPatternSVG } from "assets";
import { Icon, IconNames, Link, LinkProps, MainLayoutContainer, PageMotion, Topbar } from "components";
import { useColor } from "hooks";
import { PageProps } from "interfaces";
import { StakingOptionsModal } from "ui";
import { when } from "utils";

// import ManInPic from "assets/images/man-fococlipping-standard 1.png";
import EarnIllustration from "assets/images/earn-illustration.png";
import { SidePageProvider } from "contexts";
import { isProd } from "config";

interface EarnOptionsPageProps extends PageProps {}
type LinkType = { icon: IconNames; label: string; link: string; onClick?: () => void; display?: string };

export default function EarnOptionsPage(props: EarnOptionsPageProps) {
  return (
    <PageMotion key="Earn">
      <Topbar pageTitle="Earn" {...props} />

      <MainLayoutContainer pb="60px">
        <SidePageProvider>
          <EarnOptions {...props} />
        </SidePageProvider>
      </MainLayoutContainer>
    </PageMotion>
  );
}

function EarnOptions(props: EarnOptionsPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const bColor = useColorModeValue("grey.150", "dark.border");

  const shadow = useColorModeValue("0px 2px 8px rgba(229, 229, 229, 0.47)", "0px 2px 8px rgba(9, 9,9, 4.7)");

  const { links, staking } = useLinks();

  const handleBack = () => {
    window.history.back();
  };

  return (
    <VStack maxW="540px" alignItems="flex-start">
      <Box>
        <Box mb="42px">
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={handleBack}
            px="2px !important"
          >
            Back
          </Button>

          <Text mt="12px" as="h4" fontSize="24px" fontWeight="bold">
            Earn
          </Text>
          <Text mt="12px" as="h6" fontSize="14px" fontWeight="500">
            With Earn feature, you can lock your Crypto Coins over a duration of time and get good interests
          </Text>
        </Box>

        <Box as="img" src={EarnIllustration} w="100%" />

        <Stack mt={{ base: "38px", "2sm": "40px" }}>
          {links.map((link, i) => (
            <EarnLink
              to={link.link}
              icon={link.icon}
              label={link.label}
              mb="8px !important"
              arrowIcon="arrowRight"
              key={`partner-link-${i}`}
              borderBottomColor={bColor}
              onClick={link?.onClick}
              borderRadius="10px"
              boxShadow={shadow}
              display={link?.display ?? "inline-block"}
              // padding="12px 16px"
            />
          ))}
        </Stack>

        <StakingOptionsModal isOpen={staking.isOpen} onClose={staking.onClose} allowPinchZoom />
      </Box>
    </VStack>
  );
}

function useLinks() {
  const stakingDisclosure = useDisclosure();

  const links: LinkType[] = [
    { icon: "stakingFilled", label: "Staking", link: "#", onClick: stakingDisclosure.onOpen },
    { icon: "savingsFilled", label: "Yield", link: "/earn/yield", display: when(isProd, "none", "inline-block") },
    { icon: "defiFilled", label: "De-fi Savings (Coming soon)", link: "#" },
  ];

  return { links, staking: stakingDisclosure };
}

interface EarnLinkProps extends Omit<LinkProps, "to"> {
  label: string;
  to?: string;
  icon?: string;
  noBorderLink?: boolean;
  arrowIcon?: "chevronRightArrow" | "arrowRight";
  use_scheme?: boolean;
}

function EarnLink(props: EarnLinkProps) {
  const { label, to, icon, arrowIcon, noBorderLink, ...xprops } = props;

  const color = useColor();

  // const bc = useColorModeValue("grey.100", "dark.border");

  // const borderColor = when(!!noBorderLink, "transparent", bc);
  const as = when<any>(!!to, Link, "button");
  const arrow = when(!!arrowIcon, arrowIcon as EarnLinkProps["arrowIcon"], "chevronRightArrow");

  return (
    <Link
      as={as}
      to={to ?? ""}
      mt="0 !important"
      p="18px 16px"
      // border="1px solid transparent"
      // borderBottomColor={borderColor}
      _hover={{ textDecoration: "none" }}
      pos="relative"
      overflow="hidden"
      bg={color("#022A1E", "secondary.500")}
      color={"white"}
      borderRadius="10px"
      {...xprops}
    >
      <HStack justifyContent="space-between">
        {/* <HStack>
          {icon && <Icon type={icon as any} />}

          <Text textDecoration="none" ml="12px !important">
            {label}
          </Text>
        </HStack> */}

        <Stack>
          <Heading fontSize="22px">{label}</Heading>
          <Text fontWeight="500" fontSize="sm">
            Explore
          </Text>
        </Stack>

        <Icon type={arrow as any} color="inherit" />

        <Box as={EarnTopRightPatternSVG} pos="absolute" top="-6px" right="0" />
      </HStack>
    </Link>
  );
}
