import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";
import { CardTypeIndicator, CreditCard, CreditCardType, useModalView, ViewSwitcherChildProps } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { useDefaultStyle } from "hooks";
import { join } from "lodash";
import { PropsWithChildren, useMemo } from "react";
import { switchStyle } from "utils";
import { StepProps } from "./RequestVirtualCard";

interface StepTwoProps extends StepProps, ViewSwitcherChildProps {}

export default function StepTwo(props: StepTwoProps) {
  const { state, set, user } = props;

  const { hasNext, onNext, currentView } = useModalView();

  const color = useColorModeValue("gray.600", "grey.100");

  const handleClick = (type: CreditCardType) => {
    set({ card: type });
    onNext();
  };

  const options = ["black", "pink", "green"] as CreditCardType[];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "credit-card-radio",
    defaultValue: "black",
    onChange: (value: CreditCardType) => {
      set({ cardType: value });
    },
  });

  const group = getRootProps();

  return (
    <Box py="40px">
      <Flex flexDir={{ base: "column", "2sm": "row" }}>
        <Heading as="h6" fontSize="md" fontWeight="600" color={color}>
          Step {currentView + 1}
        </Heading>
        <Text ml={{ "2sm": "28px !important" }} fontSize="14px" fontWeight="500">
          Select a Card color and click on the Card to continue
        </Text>
      </Flex>

      <HStack mt="60px" {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <CreditCardRadio cardType={value} key={value} mr="20px !important" {...radio}>
              {value}
            </CreditCardRadio>
          );
        })}
      </HStack>

      <Stack mt="40px" px="4px" py="42px" overflow="hidden">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`${state?.cardType ?? "black"}-credit-card`}
            initial={{ opacity: 0.8, scale: 0.98, y: 30, rotate: "-4deg" }}
            animate={{ opacity: 1, scale: 1, y: 0, rotate: "0deg" }}
            exit={{ opacity: 0, scale: 0.98, y: -30, rotate: "-4deg" }}
          >
            <CreditCard
              type={state?.cardType ?? "black"}
              brand={state?.brand ?? "mastercard"}
              name={join([user?.firstName, user?.lastName], " ")}
              onClick={() => handleClick(state?.cardType ?? "black")}
              isDisabled={!hasNext}
              isSelected={state?.card === `pink`}
            />
          </motion.div>
        </AnimatePresence>

        <Button
          my="42px !important"
          minW="320px"
          w="320px"
          mx={`calc((384px - 320px) / 2) !important`}
          disabled={!hasNext}
          onClick={() => handleClick(state?.cardType ?? "black")}
        >
          Continue
        </Button>
      </Stack>

      {/* <WishlistModal isOpen={true} onClose={() => {}} /> */}
    </Box>
  );
}

interface CreditCardRadioProps extends UseRadioProps, Omit<BoxProps, "onChange"> {
  cardType: CreditCardType;
}

function CreditCardRadio(props: PropsWithChildren<CreditCardRadioProps>) {
  const { cardType, ...xprops } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const { colorMode } = useColorMode();

  const { shadow, borderColor } = useDefaultStyle();

  const styles = useMemo(() => {
    const map: Record<CreditCardType, { color: string }> = {
      black: {
        color: switchStyle(colorMode, { dark: "#333", light: "#000000" }),
      },
      pink: {
        color: switchStyle(colorMode, { dark: "#8E1273", light: "#8E1273" }),
      },
      green: {
        color: switchStyle(colorMode, { dark: "primary.default", light: "primary.default" }),
      },
    };

    return map[cardType];
  }, [cardType, colorMode]);

  return (
    <Box as="label" {...xprops}>
      <input {...input} />

      <HStack>
        <Box
          {...checkbox}
          pos="relative"
          borderRadius="50%"
          boxSize="20px"
          border="1px solid transparent"
          bg="#CACBCB"
          _after={{
            content: '""',
            pos: "absolute",
            boxSize: "10px",
            bg: "transparent",
            border: "3px solid #fff",
            top: "50%",
            left: "50%",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
          _checked={{
            bg: styles.color,
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
          _focus={{
            shadow,
            borderColor,
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
          _hover={{
            _after: {
              border: "2px solid #fff",
              transform: "translate(-50%, -50%) scale(1.3)",
              transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
            },
          }}
          _active={{
            _after: {
              border: "3px solid #fff",
              transform: "translate(-50%, -50%) scale(0.8)",
              transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
            },
          }}
          transition="all .3s cubic-bezier(0.4, 0, 0.2, 1)"
        />
        <HStack>
          <CardTypeIndicator type={cardType as any} _label={{ fontSize: "14px" }} />
        </HStack>
      </HStack>
    </Box>
  );
}
