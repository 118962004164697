import { CoinInfo, CoinInfoList, Group, PageLoading } from "components";
import { useSidePage } from "contexts";
import { useWalletBalance } from "hooks";

export default function FiatPanel() {
  const { onOpen } = useSidePage();

  const { fiats, isLoading } = useWalletBalance();

  console.log("Fiats", fiats);

  return (
    <Group my={{ base: "16px", "3sm": "32px" }} gridGap="4px" pos="relative" minH={{ base: "inherit", "3sm": "300px" }}>
      {isLoading && <PageLoading isLoading={isLoading} pos="absolute" top="46px" left="50%" transform="translateX(-50%)" />}

      <CoinInfoList isLoading={isLoading}>
        {fiats.map((fiat, index) => (
          <CoinInfo
            p={{ base: "16px 13px", "2sm": "18px 28px" }}
            key={`coininfo-${fiat?.coin}`}
            index={index}
            coin={fiat?.coin as any}
            w="100%"
            value={fiat?.balance?.$numberDecimal}
            onClick={() => onOpen({ key: "Fiat", data: { coin: fiat?.coin } })}
          />
        ))}
      </CoinInfoList>
    </Group>
  );
}
