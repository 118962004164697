import { Box, Button, Heading, Text, VStack, useColorModeValue, useDisclosure, useRadioGroup } from "@chakra-ui/react";

import { TransactionHub } from "../TransactionHub";
import { ScreenMapperProps, useP2PSession } from "..";
import { HeaderInfo } from "../HeaderInfo";
import { useParams } from "@reach/router";
// import { ConditionalRender, Messanger } from "components";
import { useCallback, useMemo } from "react";

// import toLower from "lodash/toLower";
// import { usePartialState } from "hooks";
import usePusher from "hooks/usePusher";
import { Emitter } from "libs";
import { FooterInfo } from "../FooterInfo";
import { Group, Icon, MainLayoutContainer, Radio, Steps, useModalView } from "components";
import { usePartialState, useUpdateP2POrder } from "hooks";
import { ConfirmationModal } from "ui";

interface DefaultScreenProps extends ScreenMapperProps {
  mapKey: string;
  getStatus: (role: string) => string;
}

export function DefaultScreen(props: DefaultScreenProps) {
  // const { /*getStatus, counterParties, isLoading */ setHideBackButton  } = props;

  const { ref } = useParams();

  const applyContractUpdate = useCallback((data: any) => {
    // setData(data);
    Emitter.emit("refetch_p2p_trade_session", data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tradeUpdateHandlers = useMemo(
    () => ({
      "trade-update": {
        register: true,
        do: applyContractUpdate,
      },
      // "test-event": {
      //   register: true,
      //   do: (d) => {
      //     console.log({ d });
      //   },
      // },
      // "chat-update": {
      //   register: true,
      //   do: watchChats,
      // },
    }),
    [applyContractUpdate]
  );

  usePusher({
    channelName: ref,
    handlers: tradeUpdateHandlers,
    endpoint: "p2p/realtime/trade/auth",
  });

  return (
    <Box pos="relative">
      <Steps hideIndicator>
        <DefaultScreenIndex {...props} />
        <P2PDisputeReasonsView {...props} />
      </Steps>
    </Box>
  );
}

function DefaultScreenIndex(props: DefaultScreenProps) {
  const { setChatState } = useP2PSession();

  // const [tradeId, frozenRef] = String(ref ?? "").split("-");

  // const [chatState, setChatState] = usePartialState({
  //   chatOpened: false,
  //   chatInFocus: false,
  //   unReadChats: [],
  //   isSupportOn: false,
  // });

  // const tradeOwner = useMemo(() => counterParties?.find((cp) => toLower(cp.role) === "tradeowner"), [counterParties]);
  // const trader = useMemo(() => counterParties?.find((cp) => toLower(cp.role) === "trader"), [counterParties]);

  // const isSessionActive = useMemo(
  //   () =>
  //     getStatus("trader") &&
  //     getStatus("trader") !== "cancelled&pending" &&
  //     getStatus("trader") !== "closed&fulfilled" &&
  //     getStatus("trader") !== "timeout&pending",
  //   [getStatus]
  // );

  return (
    <Box pos="relative">
      <HeaderInfo {...props} />

      <TransactionHub
        onChatClick={() => setChatState({ chatOpened: true })}
        onChatOff={(update?: any) => setChatState({ chatOpened: false, ...update })}
        {...props}
      />

      <FooterInfo {...props} />

      {/* <ConditionalRender shouldRender={!!tradeId && !!frozenRef && !isLoading}>
        <VStack my="44px">
          <Box display={["none", "none", "block"]}>
            <Messanger
              sessionActive={isSessionActive}
              tradeId={tradeId}
              frozenRef={frozenRef}
              trader={trader}
              tradeOwner={tradeOwner}
              chatState={chatState}
              setChatState={setChatState}
              // userInitialisedChat={chatInitialised}
            />
          </Box>
        </VStack>
      </ConditionalRender> */}
    </Box>
  );
}

function P2PDisputeReasonsView(props: DefaultScreenProps) {
  const { setHideBackButton } = props;
  const [state, set] = usePartialState<{ label: string; value: string }>();

  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { onPrev } = useModalView();
  const { tradeId, frozenRef } = useP2PSession();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { updateOrder, isUpdating } = useUpdateP2POrder(tradeId, frozenRef);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onOpen();
  };

  const previousView = () => {
    onPrev();
    setHideBackButton(false);
  };

  const disputeTrade = async () => {
    const result = (await updateOrder("DISPUTE", state?.label ?? "Payment was not received")) as any;
    if (!!result && !result?.error) {
      previousView();
    }
  };

  return (
    <MainLayoutContainer maxW={["426px"]} m="0" px="4px" py="4px">
      {/* <ConditionalRender shouldRender={!hideBackButton}> */}
      <Button
        size="sm"
        minW="fit-content"
        maxW="fit-content"
        variant="link"
        leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
        onClick={previousView}
        px="2px !important"
      >
        Back
      </Button>
      {/* </ConditionalRender> */}

      <Box as="form" pos="relative" onSubmit={handleSubmit}>
        <Box my="20px">
          <Heading as="h4" fontSize="24px" fontWeight="bold">
            Dispute Trade
          </Heading>
          <Text mt="10px" fontSize="16px" fontWeight="500" fontFamily="var(--bitmama-fonts-heading)">
            Kindly select a reason for placing trade to dispute mode
          </Text>
        </Box>

        <RadioOptionGroup value={state?.value ?? ""} onChange={set} />

        <VStack>
          <Button type="submit" disabled={!state?.value || isUpdating} isLoading={isUpdating}>
            Submit
          </Button>
        </VStack>
      </Box>

      <ConfirmationModal isOpen={isOpen} onClose={onClose} title="Confirm Trade Dispute" onConfirm={disputeTrade}>
        <VStack>
          <Text fontSize="14px" fontWeight="500" textAlign="center" my="32px">
            Are you sure you want to dispute this order?
          </Text>
        </VStack>
      </ConfirmationModal>
    </MainLayoutContainer>
  );
}

interface RadioOptionGroupProps {
  value?: string;
  onChange?: (option: { label: string; value: string }) => void;
}

function RadioOptionGroup(props: RadioOptionGroupProps) {
  const { value, onChange } = props;

  const options = [
    { label: "Buyer did not make payment for trade", value: "buyer_did_not_pay" },
    { label: "Seller is yet to release coins after confirm payment", value: "seller_yet_to_release_coin" },
    { label: "Seller cancelled trade after I made payment", value: "seller_cancelled_trade" },
    { label: "Buyer cancelled trade after I made payment", value: "buyer_cancelled_trade" },
    { label: "Trader not responding after trade initiated", value: "trader_not_responding" },
    { label: "Incomplete payment from buyer on the trade", value: "incomplete_payment_from_buyer" },
    { label: "Incomplete coin released from seller on the trade", value: "incomplete_coin_released_from_seller" },
    { label: "Other", value: "other" },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "p2p-dispute-radio-group",
    defaultValue: value,
    onChange: (value: string) => {
      const option = options.find((opt) => opt?.value === value);
      onChange && !!option && onChange(option);
    },
  });

  const group = getRootProps();

  return (
    <Group p="4px" mt="46px !important" {...group} gridGap="10px" fontSize="8px">
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value });
        return (
          <Radio key={value} _after={{ opacity: 0 }} _label={{ fontSize: "md" }} {...radio}>
            {label}
          </Radio>
        );
      })}
    </Group>
  );
}
