import { FC, useMemo, useState, createContext, useContext, PropsWithChildren, useCallback } from "react";
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, ModalProps, ModalContentProps } from "@chakra-ui/modal";
import {
  Box,
  //   Text,
  //   VStack,
  //   HStack,
  //   Button,
  //   Heading,
  ModalBody,
  BoxProps,
  useColorModeValue,
  ButtonProps,
  VStack,
  Heading,
  Text,
  Button,
  IconButton,
  IconButtonProps,
  TextProps,
  //   ButtonProps,
  //   Progress,
  //   ProgressProps,
} from "@chakra-ui/react";
import { motion, MotionProps, AnimatePresence } from "framer-motion";
import { RocketSVG } from "assets";
import hasChildWithName from "utils/hasChildWithName";
import { filterChildren, renderComponent, when } from "utils";
import Icon from "components/Icon/Icon";

// import { ReactComponent as SuccessIllustration } from "assets/svgs/success-illustration.svg";

// // import injectProps from "utils/injectProps";
// import renderComponent from "utils/renderComponent";
// // import hasChildWithName from "utils/hasChildWithName";
// // import filterChildren from "utils/filterChildren";

const MotionModal = motion(Modal);
const MotionModalBody = motion(ModalBody);
const MotionModalContent = motion(ModalContent);

interface Props extends MotionProps, ModalProps {
  success?: boolean;
  title?: string;
  showCloseButton?: boolean;
  //   _progress?: ProgressProps;
  _content?: MotionProps | BoxProps;
  _mobileSuccessStyles?: ModalContentProps;
  _successStyles?: ModalContentProps;
}

interface SuccessViewProps {
  description?: string;
  actions?: {
    label: string;
    onClick: (e: any) => void;
    props?: ButtonProps;
  }[];
  _description?: TextProps;
}

export interface IState {
  showTitle: boolean;
  showProgress: boolean;
  showCloseButton: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  progress: number; // 0 - 100 percentage value
  success?: {
    description: string;
    actions?: {
      label: string;
      onClick: (e: any) => void;
      props?: ButtonProps;
    }[];
    _description?: TextProps;
  };
}

interface IContext {
  updateModal: (state: Partial<IState>) => void;
  onClose: () => void;
}

export interface InjectAbstractProps {
  updateParentState: (newState: Partial<IState>) => void;
}

const AbstractModalContext = createContext<IContext | null>(null);

export function BackButton(props: Omit<IconButtonProps, "aria-label">) {
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.div initial={{ opacity: 0, scale: 0.98 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.98 }}>
        <IconButton
          minW="fit-content"
          minH="fit-content"
          size="sm"
          variant="link"
          aria-label="back"
          top="26px"
          left="26px"
          icon={<Icon type="circleLeftArrow" />}
          pos="absolute"
          {...props}
        />
      </motion.div>
    </AnimatePresence>
  );
}

function DefaultSuccessView(props: SuccessViewProps) {
  const { description, actions } = props;

  return (
    <VStack p={{ base: "20px 24px", "2sm": "48px" }}>
      <Box mb="24px !important" as={RocketSVG} />

      <Heading
        mt={{ base: "0 !important", "2sm": "initial" }}
        as="h4"
        fontSize="24px"
        fontWeight="600"
        mb={{ base: "20px !important", "2sm": "30px !important" }}
      >
        Success
      </Heading>

      <VStack mt="0 !important">
        <Text
          maxW="320px"
          mb={{ base: "20px !important", "2sm": "40px !important" }}
          color="brand.greyText"
          fontWeight="500"
          textAlign="center"
        >
          {description}
        </Text>
      </VStack>

      <VStack
        px={{ base: "20px", "2sm": "108px" }}
        mb={{ base: "20px !important", "2sm": "40px !important" }}
        w="100%"
        justifyContent="center"
      >
        {actions?.map((action, i) => (
          <Button key={i} variant="solid" minW="unset" maxW="unset" w="100%" onClick={action?.onClick} {...action?.props}>
            {action?.label}
          </Button>
        ))}
      </VStack>
    </VStack>
  );
}

export function AbstractModalSuccessView(props: PropsWithChildren<any>) {
  return <>{props.children}</>;
}

const AbstractModal: FC<Props> = (props) => {
  const {
    title,
    onClose,
    children,
    _content,
    _mobileSuccessStyles,
    // _progress,
    showCloseButton,
    ...xprops
  } = props;

  const bg = useColorModeValue("white", "dark.cardBg");

  const initialState = useMemo(
    () => ({
      showTitle: true,
      showCloseButton: true,
      isSuccess: false,
      isLoading: false,
      progress: 0,
      showProgress: true,
      success: {
        description: "Your wallet has been funded with N5,000 Naira",
        actions: [
          {
            label: "Perform another transaction",
            onClick: () => {
              _setState(initialState);
            },
          },
          {
            label: "Go back to Home",
            props: { variant: "transparent" },
            onClick: () => {
              handleClose();
            },
          },
        ],
      },
    }),

    //eslint-disable-next-line
    []
  );

  const [state, _setState] = useState<IState>(initialState);

  const setState = useCallback((newState: Partial<IState>) => {
    console.log("%cUPDATE MODAL WAS CALLED", "color:red");
    if (newState?.isSuccess) {
      _setState((state) => ({
        ...state,
        ...newState,
        showCloseButton: false,
        showTitle: false,
        success: {
          ...state?.success,
          ...newState?.success,
          description: newState?.success?.description! ?? state?.success?.description,
          actions: newState?.success?.actions ?? state?.success?.actions,
        },
      }));
      return;
    }
    _setState((state) => ({ ...state, ...newState }));
    return;
  }, []);

  const handleClose = useCallback(() => {
    setState(initialState);
    onClose();

    //eslint-disable-next-line
  }, [initialState, onClose]);

  const hasCustomSuccessView = useMemo(() => hasChildWithName("AbstractModalSuccessView", children), [children]);
  const hasBackButton = useMemo(() => hasChildWithName("BackButton", children), [children]);

  const renderViews = useCallback(
    (props: InjectAbstractProps) => {
      if (!state?.isSuccess) return filterChildren(children, ["AbstractModalSuccessView"], props);
    },
    [state?.isSuccess, children]
  );

  const renderBackButton = useMemo(() => {
    if (state?.isSuccess && hasBackButton) return renderComponent(children, "BackButton");
    return;
  }, [children, state?.isSuccess, hasBackButton]);

  const renderCustomSuccessView = useMemo(() => {
    if (state?.isSuccess && hasCustomSuccessView) return renderComponent(children, "AbstractModalSuccessView");
    return;
  }, [children, state?.isSuccess, hasCustomSuccessView]);

  const mobileSuccesStyles = when<ModalContentProps>(
    state?.isSuccess,
    {
      position: { base: "absolute", "1sm": "initial" },
      bottom: { base: 0, "1sm": "initial" },
      margin: { base: 0, "1sm": "initial" },
      borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },

      ..._mobileSuccessStyles,
    },
    {
      ...(_content as ModalContentProps),
    }
  );

  return (
    <AbstractModalContext.Provider
      value={{
        onClose: handleClose,
        updateModal: setState,
      }}
    >
      <MotionModal isCentered closeOnOverlayClick={true} onClose={onClose} motionPreset="slideInBottom" {...xprops}>
        <ModalOverlay bg="#0A16412f" opacity={0.15} />

        <MotionModalContent
          bg={bg}
          maxW="640px"
          // py="18px"
          // px="6px"
          backdropFilter="blur(100px)"
          borderRadius="20px"
          transition={{ type: "spring", stiffness: 100 }}
          _content={{
            overflowX: "hidden",
            ..._content,
          }}
          // {..._content}
          {...mobileSuccesStyles}
        >
          {showCloseButton && (
            <ModalCloseButton
              top="30px"
              right="26px"
              boxSize="32px"
              color="#88A0AF"
              _focus={{ border: "none" }}
              onClick={onClose}
            />
          )}

          {renderBackButton}
          {hasBackButton && <Box mt={{ base: 0, "1sm": "20px" }} />}

          <MotionModalBody py="0" px="0" transition={{ type: "spring", stiffness: 0 }} overflow="overlay">
            <AnimatePresence exitBeforeEnter initial={false}>
              <motion.div
                key={`abstract-modal-${state?.isSuccess ? "views" : "success"}`}
                initial={{ opacity: 0, scale: 0.98 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.98 }}
              >
                {renderViews({
                  updateParentState: setState,
                })}

                {renderCustomSuccessView}

                {state?.isSuccess && !hasCustomSuccessView && <DefaultSuccessView {...state?.success} />}
              </motion.div>
            </AnimatePresence>
          </MotionModalBody>
        </MotionModalContent>
      </MotionModal>
    </AbstractModalContext.Provider>
  );
};

export function useAbstractModal() {
  const context = useContext(AbstractModalContext) as IContext;
  if (!context) throw new Error("Must be used within a abstract modal");
  return context;
}

BackButton.displayName = "BackButton";
AbstractModalSuccessView.displayName = "AbstractModalSuccessView";
export default AbstractModal;
