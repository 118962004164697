import { Box, BoxProps, HStack, Text, TextProps, useRadio, UseRadioProps } from "@chakra-ui/react";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import { useDefaultStyle } from "hooks";
import { PropsWithChildren } from "react";

export interface RadioProps extends UseRadioProps, Omit<BoxProps, "onChange"> {
  _label?: TextProps;
  _radioWrapper?: BoxProps;
  asText?: boolean;
}

export function Radio(props: PropsWithChildren<RadioProps>) {
  const { asText = true, children, _label, _checked, _focus, _hover, _after, _active, _radioWrapper, ...xprops } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const { shadow, borderColor } = useDefaultStyle();

  return (
    <Box as="label" {...xprops}>
      <input {...input} />

      <HStack {..._radioWrapper}>
        <Box
          {...checkbox}
          pos="relative"
          borderRadius="50%"
          boxSize="20px"
          border="1px solid transparent"
          bg="#CACBCB"
          _after={{
            content: '""',
            pos: "absolute",
            boxSize: "10px",
            bg: "transparent",
            border: "3px solid #fff",
            top: "50%",
            left: "50%",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
            ..._after,
          }}
          _checked={{
            ..._checked,
            bg: "secondary.400",
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
            _after: { opacity: 1 },
          }}
          _focus={{
            ..._focus,
            shadow,
            borderColor,
            transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
          _hover={{
            ..._hover,
            _after: {
              border: "2px solid #fff",
              transform: "translate(-50%, -50%) scale(1.3)",
              transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
            },
          }}
          _active={{
            ..._active,
            _after: {
              border: "3px solid #fff",
              transform: "translate(-50%, -50%) scale(0.8)",
              transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
            },
          }}
          transition="all .3s cubic-bezier(0.4, 0, 0.2, 1)"
        />
        <ConditionalRender shouldRender={asText}>
          <HStack>
            <Text letterSpacing="0.33px" fontSize={{ base: "12px", "2sm": "14px" }} fontWeight="500" {..._label}>
              {children}
            </Text>
          </HStack>
        </ConditionalRender>

        <ConditionalRender shouldRender={!asText}>{children}</ConditionalRender>
      </HStack>
    </Box>
  );
}
