import { useToast, useDisclosure } from "@chakra-ui/react";
import { useGetUserCardDetailMutation } from "apis";
import configs from "config";
import { CardDetailsRo } from "interfaces";
import { useEffect, useState } from "react";
import getError from "utils/getError";
import ls from "utils/secureStorage";

export default function useCardDetails(id: string, pin: string, input: any, resetPin: (() => void) | null = null, skip = false) {
  const toast = useToast();

  // loading should only be done once, refetching should have an
  // effect of second load using isFetching which means, isLoading
  // will simulate the isLoading and isFetching if we were using
  // the getUserCardDetailsQuery and not the mutation.
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: isPinOpen, onClose: onPinClose, onOpen: onPinOpen } = useDisclosure();

  // This is a trick to do optimistic update without flickering changes in the UI.
  // It makes the refetch update the card data without actually loosing the current
  // data in the process.
  const [cachedCard, setCache] = useState<CardDetailsRo>();

  const [getCardDetails, { data: card, isLoading: isFetching, isSuccess, isError: isCardError, error, reset }] =
    useGetUserCardDetailMutation();

  const refetch = async () => {
    const result = await getCardDetails({ id, pin, ...input });
    setCache((result as any)?.data);
    console.log("Refetch result", cachedCard);
  };

  useEffect(() => {
    if (!skip && (!pin || (!!pin && pin?.length < 6))) onPinOpen();
  }, [pin, onPinOpen, skip]);

  useEffect(() => {
    if (!skip && !!pin && pin?.length === 6) {
      setIsLoading(true);
      getCardDetails({ id, pin, ...input });
    }
    // eslint-disable-next-line
  }, [pin, skip]);

  useEffect(() => {
    if (isSuccess && card && isLoading) {
      ls.set(configs.TEMP_PIN, pin);
      setIsLoading(false);
      setCache(card);
    }

    if (isCardError && error) {
      setIsLoading(false);
      const message = getError((error as any)?.data?.error);

      if (String(message).toLowerCase().includes("transaction pin is incorrect")) {
        reset();
        resetPin && resetPin();
        onPinOpen();
      }
    }

    // eslint-disable-next-line
  }, [toast, error, isCardError, isSuccess, pin, card, onPinOpen, resetPin, reset]);

  return { card: cachedCard, isLoading, isFetching, isPinOpen, onPinClose, onPinOpen, isCardError, refetch };
}
