import { Box, BoxProps } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { useGetEarnWalletQuery } from "apis";
import { Group, StakedAmount, Wallet, WalletActions, WalletActionsButton } from "components";
import { isTesting, isProd } from "config";
import { useAppConfig } from "contexts/appconfig.context";
import { useDisclosures, usePartialState } from "hooks";
import { PageProps } from "interfaces";
import { useMemo } from "react";
import FeatureUnavailableModal from "ui/Common/Modals/FeatureUnavailable";
import { when } from "utils";
import StakingTransactions from "./StakingTransactions";

interface StakingFeatureProps extends PageProps, BoxProps {
  _staking?: BoxProps;
}

interface IState {
  coin: string;
}

type ModalTypes = "featureUnavailable";

export default function StakingFeature(props: StakingFeatureProps) {
  const { _staking, ...xprops } = props;

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const [state, set] = usePartialState<IState>({ coin: isTesting ? "tbtc" : "btc" });

  const { data: wallets, isLoading: isLoadingWallets } = useGetEarnWalletQuery({});

  const { appfigs } = useAppConfig();
  const { stakingConfig } = appfigs ?? {};

  const isTransferDisabled = useMemo(
    () => (!!stakingConfig?.disableFundStakingWallet && !!stakingConfig?.disableWithdrawFromStakingWallet) ?? false,
    [stakingConfig]
  );

  const selectedWallet = useMemo(
    () => (state?.coin ? wallets?.find((w) => w?.unit === state?.coin) : undefined),
    [state?.coin, wallets]
  );

  const balance = useMemo(() => +selectedWallet?.balance?.$numberDecimal! ?? 0, [selectedWallet]);
  const amountAtStake = useMemo(() => selectedWallet?.amountAtStake, [selectedWallet]);
  const amountAtYield = useMemo(() => selectedWallet?.amountAtYield, [selectedWallet]);

  const handleTransfer = () => {
    if (isTransferDisabled) open("featureUnavailable")();
    else navigate(`/earn/transfer/${state?.coin}`);
  };

  return (
    <Group mt="0" {...xprops}>
      <Group mt="0" px={{ base: "0", "2sm": "10px", "4sm": "20px" }} {..._staking}>
        <Wallet
          isLoading={isLoadingWallets}
          value={when(isNaN(balance), 0, balance)}
          w="100%"
          minW="unset"
          maxW="unset"
          currency={state?.coin! as any}
          label="Earn Wallet"
          scheme="earn"
          availableCurrencies={wallets?.map((w) => w.unit)}
          onCurrencyChange={(coin) => set({ coin })}
        >
          <WalletActions>
            <WalletActionsButton variant="outline" scheme="earn" onClick={handleTransfer}>
              Transfer
            </WalletActionsButton>
            <WalletActionsButton
              variant="solid"
              scheme="earn"
              // onClick={() =>
              //   navigate(`/earn/staking/subscribe?coin=${state?.coin}${when(params.has("type"), `&${params.toString()}`, "")}`)
              // }
              onClick={() => navigate(`/earn/options`)}
            >
              Earn Now
            </WalletActionsButton>
          </WalletActions>
        </Wallet>

        <Box mt="44px !important">
          <StakedAmount coin={state?.coin!} amount={amountAtStake?.volume ?? 0} />
          <StakedAmount
            coin={state?.coin!}
            styleType="info"
            onClick={() => navigate(`/earn/earnings/${state?.coin}${when(params.has("type"), `?${params.toString()}`, "")}`)}
          />
          <StakedAmount
            coin={state?.coin!}
            styleType="yield"
            amount={amountAtYield?.volume ?? 0}
            onClick={() => navigate(`/earn/yield`)}
            display={when(isProd, "none", "block")}
          />
        </Box>
      </Group>

      <StakingTransactions coin={state?.coin!} />

      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </Group>
  );
}
