import { useMemo, useCallback } from "react";

import { useGetWalletsQuery } from "apis";
import { SupportedCoinType } from "./useCoin";
import { useEventListener } from "./useInlineWebPush";
import { isFiat } from "utils";

const maskedCoins = [
  "usdt-tron",
  "usdt-matic",
  "usdc-matic",
  "usdc-stellar",
  "usdc-tron",
  "usdc-algo",
  "usdt-algo",
  "usdt-celo",
  "usdc-celo",
];

const useWalletBalance = () => {
  const { data: wallets, isLoading, refetch } = useGetWalletsQuery({});
  useEventListener("refetch_wallet", () => refetch());

  const currencies = useMemo(() => {
    if (wallets) {
      return {
        cryptos: wallets?.addresses?.filter((a) => a?.address && a?.coin !== "usdc-base"),
        // fiats: wallets?.addresses?.filter((a) => !a?.address && a?.coin),
        fiats: wallets?.addresses?.filter((a) => isFiat(a?.coin ?? "")),
      };
    }
  }, [wallets]);

  // console.log("CURRENCIES", currencies);

  const cryptos = useMemo(() => currencies?.cryptos?.filter((c) => !maskedCoins.includes(c?.coin)), [currencies]);

  const getCoin = useCallback(
    (coin: SupportedCoinType) => {
      if (wallets) {
        return wallets?.addresses?.find((a) => a?.coin === coin);
      }
    },
    [wallets]
  );

  const getBalance = useCallback((coin: SupportedCoinType) => +(getCoin(coin)?.balance?.$numberDecimal ?? 0), [getCoin]);

  return {
    isLoading,
    cryptos: cryptos ?? [],
    fiats: currencies?.fiats ?? [],
    getCoin,
    getBalance,
    refetch,
  };
};

export default useWalletBalance;
