import { Box, BoxProps, Divider, Input, InputGroup, InputLeftElement, InputProps, InputRightElement } from "@chakra-ui/react";
import { Icon, RadixSelectProps } from "components";
import { RadixSelect } from "components/Select/RadixSelect";
import { PropsWithChildren, useMemo } from "react";
// import Select from "components/Select/Select";
import useMeasure from "react-use-measure";
import { when } from "utils";

interface PhoneInputProps extends InputProps {
  onMax?: () => void;
  isLoading?: boolean;
  countryValue?: string;
  onCountryChange?: (coin: string) => void;
  _rightEl?: BoxProps;
  _select?: RadixSelectProps;
}

export default function PhoneInput(props: PropsWithChildren<PhoneInputProps>) {
  const { children, countryValue, isLoading, onMax, onCountryChange, _rightEl, _select, ...xprops } = props;

  // const spr = when(!!children, "180px", "48px");
  const mpr = when(!!onMax, "66px", "48px");
  // const pr = when(!!onMax && !!children, spr, mpr);

  // const mr = when(!!children, "118px", "18px");

  const [ref] = useMeasure({ scroll: false });
  const [, rightElBounds] = useMeasure({ scroll: false });
  const [leftElRef, leftElBounds] = useMeasure({ scroll: false });

  // const handleCoinChange = (e: any) => {
  //   e.preventDefault();
  //   onCountryChange && onCountryChange(e.target.value);
  // };

  // @ts-ignore
  // eslint-disable-next-line
  const rightElProps = useMemo(() => rightElBounds, [rightElBounds]);

  const margin = 12;
  const max_button_w = 28;
  const divider_w = 1;
  //   const dropdow_button_w = 83;
  const rightElSpacing = useMemo(() => rightElBounds.width + margin, [rightElBounds]);
  const leftElSpacing = useMemo(() => leftElBounds.width + margin, [leftElBounds]);

  // max button padding right value
  const max_mr = useMemo(
    () => when(!!children && !!onMax, `${rightElSpacing + margin / 2}px`, "18px"),
    [rightElSpacing, children, onMax]
  );

  const dropdown_mr = useMemo(() => when(!!children, `${leftElSpacing + margin}px`, "18px"), [leftElSpacing, children]);

  // input element padding right value
  const input_pr = when(!!onMax && !!children, `calc(${max_mr} + ${max_button_w + margin / 3}px)`, mpr);
  const input_pl = when(!!children, `calc(${dropdown_mr} + ${divider_w + margin}px)`, mpr);
  //   const input_pl = when(!!children, `calc(${dropdown_mr} + ${max_button_w + margin / 3}px)`, mpr);

  console.log("rightElBounds", max_mr);

  return (
    <Box pos="relative" ref={ref}>
      <InputGroup>
        <InputLeftElement
          ref={leftElRef}
          h="100%"
          // top="-2px"
          top="0px"
          w="fit-content"
          // visibility="hidden"
          // right="18px"
          left={`${margin}px`}
          children={
            // children && (
            //   <CSelect value={countryValue ?? ""} onChange={handleCoinChange} {..._select}>
            //     {children}
            //   </CSelect>
            // )

            children && (
              <RadixSelect
                placeholder="Select"
                aria-label="country options"
                hide_value
                defaultValue="empty"
                value={countryValue}
                onChange={onCountryChange}
                _trigger={{ minH: "36px", h: "36px", pl: "10px", borderRadius: "8px" } as any}
                _content={{
                  position: "popper",
                  side: "top",
                  style: {
                    width: "120%",
                    maxHeight: "500px",
                  },
                }}
                {..._select}
              >
                {children}
              </RadixSelect>
            )
          }
        />

        <Input pl={input_pl} pr={input_pr} w="100%" type="number" {...xprops} />
        <Divider
          orientation="vertical"
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          borderLeftWidth={`${divider_w}px`}
          h="50%"
          borderColor="grey.300"
          left={dropdown_mr}
        />

        <InputRightElement
          h="100%"
          w="fit-content"
          // right={max_mr}
          right={{ base: "12px", "2sm": max_mr }}
          children={
            //   <Button w={`${max_button_w}px`} variant="max" onClick={onMax} isLoading={isLoading} disabled={isLoading}>
            //     Max
            //   </Button>
            <Icon type="userCircleAdd" boxSize={`${max_button_w}px`} />
          }
          {..._rightEl}
        />
      </InputGroup>
    </Box>
  );
}
