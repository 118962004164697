import {
  Box,
  BoxProps,
  Button,
  CloseButton,
  Divider,
  Grid,
  HStack,
  Heading,
  ModalProps,
  RadioGroupProps,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import { AbstractModal, Card, CopyRefCode, Icon, InputLabel, RadioProps, Steps, useModalView } from "components";

import Confetti from "assets/images/confetti.png";

import { when } from "utils";
import { useCallback, useMemo, useRef } from "react";
import { RocketSVG } from "assets";
import { useColor, useDefaultStyle, usePartialState, useSelector } from "hooks";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import { useCreateWishlistMutation } from "apis";
import { useLocation } from "@reach/router";
import { selectUser } from "store/slices";

interface WishlistModalProps extends Omit<ModalProps, "children"> {
  _content?: BoxProps;
  wishlistId: string;
  gifts?: { value: string; label: string }[];

  message?: string;
}

interface WishlistContentProps extends BoxProps {
  onClose?: () => void;
  showSupport?: boolean;
  wishlistId: string;
  gifts?: { value: string; label: string }[];
  message?: string;
  content_for?: "christmas" | "valentine";
}

interface SuperbProps extends BoxProps {
  onClose?: () => void;
}

interface ShareViewProps extends BoxProps {
  onClose?: () => void;
}

interface RadioItemProps extends RadioProps {
  label: string;
}
interface RadioItemGroupProps extends Omit<RadioGroupProps, "children"> {
  gifts?: { value: string; label: string }[];
  onChange?: (value: string) => void;
}

export default function WishlistModal(props: WishlistModalProps) {
  const { _content, gifts, onClose, wishlistId, message, ...xprops } = props;
  //   const bColor = useColorModeValue("grey.150", "dark.border");

  return (
    <AbstractModal
      onClose={onClose}
      _content={{
        maxW: "624px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
      }}
      {...xprops}
    >
      <Steps hideIndicator>
        <WishlistContent message={message} gifts={gifts} wishlistId={wishlistId} onClose={onClose} {..._content} />
        <Superb onClose={onClose} />
        <ShareView onClose={onClose} />
      </Steps>
    </AbstractModal>
  );
}

export function WishlistContent(props: WishlistContentProps) {
  const { onClose, gifts, showSupport, wishlistId, message, content_for = "valentine", ...xprops } = props;

  const toast = useToast();
  const { onNext } = useModalView();

  const [state, set] = usePartialState<{ label: string }>();

  const [create, { isLoading }] = useCreateWishlistMutation();

  console.log("Wishlist State", state);

  const content_map = useMemo(() => {
    const map: Record<typeof content_for, { title: string; description: string }> = {
      christmas: {
        title: "HO! HO! HO!",
        description: "Choose an item to add to your wishlist, and it just might come true",
      },
      valentine: {
        title: "LOVE IS IN THE AIR",
        description: "Choose an item to add to your wishlist, and it just might come true",
      },
    };

    return map[content_for];
  }, [content_for]);

  const handleClose = (e: any) => {
    e.preventDefault();
    !!onClose && onClose();
  };

  const handleNext = async (e: any) => {
    e.preventDefault();

    if (!wishlistId || !state?.label) return;

    const result = await create({
      label: state?.label,
      wishlistId,
    }).unwrap();

    if (!!result) {
      toast({
        title: "Success",
        description: "We've added your wishlist",
        position: "bottom-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onNext();
    }
  };

  const isDisabled = useMemo(() => !state?.label || isLoading, [state, isLoading]);

  return (
    <Box p={{ base: "32px 40px", "1sm": "44px 40px" }} pos="relative" {...xprops}>
      <Box as="img" src={Confetti} pos="absolute" left="0" top="0" w="100%" pointerEvents="none" />
      <VStack mb="60px !important" pos="relative">
        <Icon type="christmasBell" boxSize="54px" />

        {/* <Heading mt="24px !important" mb="10px !important" as="h4" fontSize="24px">
            Feature not available
          </Heading> */}
        <Heading
          mt="16px !important"
          mb="10px !important"
          as="h4"
          fontSize="32px"
          textAlign="center"
          //   fontFamily="var(--bitmama-fonts-heading)"
        >
          {content_map.title}
        </Heading>
        <Text fontWeight="500" textAlign="center">
          {when(!!message, message, content_map.description)}
        </Text>
      </VStack>

      <RadioItemGroup gifts={gifts} onChange={(value) => set({ label: value })} />

      <VStack mt="32px !important" px="46px" w="100%">
        <Button
          minW="unset"
          w="100%"
          maxW="399px"
          onClick={handleNext}
          isLoading={isLoading}
          disabled={isDisabled}
          isDisabled={isDisabled}
          fontFamily="var(--bitmama-fonts-heading)"
        >
          Select and Continue
        </Button>
        <Button
          size="sm"
          variant="link"
          mt="20px !important"
          minW="unset"
          fontSize="18px"
          textDecoration="underline"
          fontFamily="var(--bitmama-fonts-heading)"
          onClick={handleClose}
          isDisabled={isLoading}
          disabled={isLoading}
        >
          Skip
        </Button>
      </VStack>
    </Box>
  );
}

export function Superb(props: SuperbProps) {
  const { onClose } = props;
  const color = useColor();

  const { onNext } = useModalView();

  const handleNext = (e: any) => {
    e.preventDefault();
    onNext();
  };

  return (
    <VStack p={{ base: "32px 40px", "1sm": "44px 40px" }} pos="relative">
      <VStack maxW="408px">
        <Box mb="10px !important" as={RocketSVG} />

        <Heading as="h4" fontSize="32px" fontWeight="600" mb="20px !important" fontFamily="var(--bitmama-fonts-heading)">
          Superb!
        </Heading>

        <Text color={color("black", "white")} mb="10px !important" fontWeight="500" textAlign="center">
          Share your wishlist and invite your friends to Bitmama, and give them a chance to win exciting gifts on Bitmama.
        </Text>
      </VStack>

      <VStack maxW="408px" mt="24px !important" w="100%">
        <Button
          mt="16px !important"
          minW="unset"
          w="100%"
          fontWeight="600"
          border="1px solid transparent"
          fontFamily="var(--bitmama-fonts-heading)"
          // borderColor={color()}
          onClick={handleNext}
          // _hover={{ color: hoverColor }}
        >
          Share My Wishlist
        </Button>
        <Button
          mt="28px !important"
          variant="link"
          minW="unset"
          maxW="fit-content"
          w="100%"
          color={color("primary.default", "secondary.400")}
          fontWeight="500"
          textDecoration="underline"
          border="1px solid transparent"
          onClick={onClose}
          // borderColor={color()}
          // onClick={onClose}
          // _hover={{ color: hoverColor }}
        >
          Go back to Home
        </Button>
      </VStack>
    </VStack>
  );
}

export function ShareView(props: ShareViewProps) {
  const location = useLocation();
  const { profile } = useSelector(selectUser);

  const link = `${location?.origin}/signup?refCode=${profile?.refCode}`;
  console.log("Location", location, link);

  const linkValue = `
    I've just added my wish to the Bitmama Wishlist for this festive season. Join me by signing up and sharing yours too 🎉!

    ${link}
  `;
  return (
    <VStack>
      <HStack w="100%" p="20px 40px" justifyContent="space-between">
        <Heading as="h5" fontFamily="var(--bitmama-fonts-heading)" fontSize="24px">
          Share with friends
        </Heading>
        <CloseButton onClick={props?.onClose} />
      </HStack>

      <Divider />

      <VStack w="100%" px="40px" my="40px !important">
        <HStack justifyContent="space-between" gridGap={{ base: "32px", "4sm": "36px" }}>
          <WhatsappShareButton placeholder="" url={linkValue}>
            <ShareButton icon="whatsappRect" label="Whatsapp" />
          </WhatsappShareButton>

          {/* <TwitterShareButton placeholder="" url={linkValue}>
            <ShareButton icon="instagramRect" label="Instagram" />
          </TwitterShareButton> */}

          <FacebookShareButton placeholder="" url={linkValue} hashtag="bitmama">
            <ShareButton icon="facebookRect" label="Facebok" />
          </FacebookShareButton>
        </HStack>

        <Stack w="100%" mt="20px !important">
          <InputLabel fontFamily="var(--bitmama-fonts-heading)" _text={{ fontWeight: 600 }}>
            Copy link
          </InputLabel>
          <CopyRefCode
            link={link}
            title={link}
            copyText="Click to copy"
            _container={{ w: "100%" }}
            _input={{ size: "sm", pointerEvents: "none", borderColor: "transparent", _hover: { borderColor: "transparent" } }}
          />
        </Stack>
      </VStack>
    </VStack>
  );
}

function RadioItemGroup(props: RadioItemGroupProps) {
  const { gifts, onChange } = props;
  const { value, getRadioProps, getRootProps } = useRadioGroup({
    onChange,
  });

  console.log("Selected values", value);

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap="8px" placeContent="center" {...getRootProps()}>
      {gifts?.map((gift, i) => (
        <RadioItem key={gift?.label} label={gift?.value} {...getRadioProps({ value: gift?.label })} />
      ))}
    </Grid>
  );
}

function RadioItem(props: RadioItemProps) {
  const { label } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } = useRadio(props);

  const availableBgs = useMemo(() => ["red.100", "#D9FFDD", "#FFFDCA", "#DDEDFF", "#FFF7D9"], []);
  const random_bg = useCallback(() => {
    const rnd = Math.round(Math.random() * availableBgs.length);
    const bg = availableBgs[rnd];
    return when(!!bg, bg, "red.100");
  }, [availableBgs]);

  const bg = useRef(random_bg());

  return (
    <Box
      as="label"
      w="100%"
      minH="110px"
      maxH="110px"
      bg={bg.current}
      display="flex"
      justifyContent="center"
      alignItems="center"
      p="18px"
      pos="relative"
      cursor="pointer"
      borderRadius="12px"
      borderWidth="1.5px"
      borderColor={when(state?.isChecked, "primary.default", "#C7B7B7")}
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      {state?.isChecked && <Icon type="checkRounded" pos="absolute" right="10px" top="10px" {...getCheckboxProps()} />}
      <Text
        color="gray.700"
        textAlign="center"
        {...getLabelProps()}
        fontFamily="var(--bitmama-fonts-heading)"
        fontWeight="600"
        textTransform="capitalize"
      >
        {label}
      </Text>
    </Box>
  );
}

interface ShareButtonProps {
  icon: string;
  label: string;
  onClick?: () => void;
}

function ShareButton(props: ShareButtonProps) {
  const { icon, label, onClick } = props;

  const { shadow, borderColor } = useDefaultStyle();
  const filterColor = useColorModeValue("#E5E5E5", "#434242");

  return (
    <VStack onClick={onClick}>
      <Card
        as="button"
        boxSize={{ base: "32px", smx: "35px", "2sm": "38px" }}
        // borderRadius="50%"
        bg="transparent"
        display="flex"
        p="0"
        alignItems="center"
        justifyContent="center"
        _focus={{ shadow, borderColor, transition: "all .4s cubic-bezier(0.4, 0, 0.2, 1)" }}
        filter={`drop-shadow(0px 4px 7px ${filterColor})`}
      >
        <Box as={() => <Icon boxSize={{ base: "32px", "2sm": "35px" }} color="secondary.400" type={icon as any} />} />
      </Card>
      <Text fontSize={{ base: "10px", "1sm": "12px" }} fontWeight="500">
        {label}
      </Text>
    </VStack>
  );
}
