import { Box, Stack, useColorModeValue } from "@chakra-ui/react";
import { useGetGreenboxTransactionsQuery } from "apis";
import { Card, EmptyCrate, Group, PageLoading, Paginator, Title, TitleBar, TransactionItem } from "components";
import { useSidePage } from "contexts";
import { AnimatePresence, motion } from "framer-motion";
import { usePartialState } from "hooks";
import { GreenboxTransactionRo } from "interfaces";
import { useMemo } from "react";
import { toQueryString, when } from "utils";

export default function FundWithdrawTransactions(props: any) {
  const bg = useColorModeValue("#F2F4F3", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const [queries, set] = usePartialState({
    page: 1,
    limit: 10,
    sortBy: "createdAt:desc",
    actionType: "funding,withdrawal",
  });

  const { data, isLoading, isFetching } = useGetGreenboxTransactionsQuery(toQueryString(queries));

  const totalCount = useMemo(() => data?.totalCount ?? 0, [data]);
  const transactions = useMemo(() => data?.records, [data]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);

  const handlePageChange = (page: number) => {
    set({ page });
  };

  const { onOpen } = useSidePage();

  const handlePreviewOpen = (data: GreenboxTransactionRo) => {
    onOpen({ key: "PreviewTransactions", params: { id: data._id! } });
  };

  return (
    <Group mt="0" w="100%" {...props}>
      <TitleBar>
        <Title isLoading={isFetching} fontSize="18px">
          Greenbox Wallet Transactions
        </Title>

        {/* <TitleBarActions>
          <Link to="/home" textDecoration="underline">
            See All
          </Link>
        </TitleBarActions> */}
      </TitleBar>
      <Card bg={bg} w="100%" p="8px" overflow="hidden">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading py="140px" isLoading={isLoading} />}
            {!isLoading && (
              <Stack>
                {(transactions ?? []).map((tx, id) => (
                  <TransactionItem
                    mt="0 !important"
                    w="100%"
                    key={`transaction-${id}`}
                    unit={tx?.unit}
                    type={when(tx?.actionType === "funding", "credit", "debit")}
                    value={tx?.value}
                    createdAt={tx?.createdAt}
                    description={tx?.description}
                    onClick={() => handlePreviewOpen(tx)}
                  />
                ))}
              </Stack>
            )}

            {!isLoading && !isFetching && !hasTx && <EmptyCrate py="140px" type="transaction" />}
          </motion.div>
        </AnimatePresence>
      </Card>

      <Box w="100%">
        <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount} />
      </Box>
    </Group>
  );
}
