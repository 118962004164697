import { BoxProps, FormControl, HStack, useColorModeValue, Text } from "@chakra-ui/react";
import InputLabel from "components/InputLabel/InputLabel";
import CryptoTxInput from "components/Input/CryptoTxInput";
import { useColor, useDebounce, usePartialState, useWalletBalance } from "hooks";
import { PageProps } from "interfaces";
import { selectCountryCurrency, toCoinLocale } from "utils";
import { useCallback } from "react";
import { Select } from "components/Select/Select";
import { Option } from "components";

interface P2PFilterProps<T> extends BoxProps, PageProps {
  filters: Partial<T>;
  set: (updates: Partial<T>) => void;

  setView?: (index: number) => void;
  currentView?: number;

  initialState?: Partial<IState>;
}

interface IState {
  amount: string;
  amountCurrency: string;
  currency: string;
  paymentMethod: string;
  listings: "all" | "own" | "others";
}

// const listingPathMap = (filter: string) => {
//   const map: Record<string, string> = {
//     all: "",
//     own: "/own",
//     others: "/others",
//   };

//   return map[filter];
// };

// const adsTypeMap = (type: "buy" | "sell") => {
//   const map = {
//     buy: "selling",
//     sell: "buying",
//   };

//   return map[type];
// };

export function P2PFilter<T>(props: P2PFilterProps<T>) {
  const { user, set: setFilter, filters, initialState } = props;
  const color = useColor();
  const labelColor = useColorModeValue("grey.400", "grey.150");

  const debounce = useDebounce();
  const { fiats /*, isLoading: isWalletLoading */ } = useWalletBalance();
  const defaultFiat = selectCountryCurrency(user?.country ?? "nigeria");
  const [state, set] = usePartialState<IState>({
    amountCurrency: defaultFiat,
    currency: defaultFiat,
    paymentMethod: "all",
    ...(initialState ?? {}),
  });

  const setQueries = useCallback(
    (updates: any) => setFilter({ ...filters, queries: { ...(filters as any)?.queries, ...updates } }),
    [setFilter, filters]
  );

  const handleAmount = (value: string) => {
    set({ amount: value });
    setFilter({ amount: value } as any);
    if (!!value && value.length > 0) {
      const run = () => setQueries({ fiatRanges: `${value},40`, page: 1 });
      debounce(() => run(), "setAmountFilter", 500)();
    } else {
      setQueries({ fiatRanges: undefined, page: 1 });
    }
  };

  // const handleCurrency = (value: string) => {
  //   set({ currency: value });
  //   setQueries({ currency: value, page: 1, range: undefined });
  //   if (!!(filters as any)?.amount) {
  //     setQueries({ fiatRanges: `${value},40` });
  //   }
  // };

  const handlePaymentMethod = (value: string) => {
    set({ paymentMethod: value });
    if (value !== "all") setQueries({ paymentOption: value, page: 1 });
    else setQueries({ paymentOption: undefined, page: 1 });
  };

  // const handleListings = (value: string) => {
  //   set({ listings: value as IState["listings"] });
  //   const endpoint = listingPathMap(value);
  //   setFilter({ endpoint, queries: { ...(filters as any)?.queries, page: 1 } } as any);

  //   if (value === "own" && (currentView ?? 0) !== 1) setView && setView(1); // show my advertisements instead of all listings.
  //   if (value !== "own" && (currentView ?? 0) === 1) setView && setView(0); // switch back to all listings if 'all' or 'others' is selected.
  // };

  return (
    <HStack
      w={{ sm: "100%", "2sm": "100%", "3sm": "100%", md: "fit-content" }}
      alignItems="flex-end"
      justifyContent="space-between"
      mt="28px"
      mb="42px"
    >
      <HStack
        w={{ sm: "100%", "2sm": "100%", "3sm": "100%", md: "fit-content" }}
        flexDir={{ sm: "column", "2sm": "column", "3sm": "row", md: "row" }}
      >
        <Text
          display={{ "3sm": "none", md: "inline-block" }}
          mt="18px !important"
          mr="16px !important"
          fontSize="20px"
          color={color("grey.500", "white")}
          fontWeight="500"
          alignSelf={{ sm: "flex-start", "2sm": "flex-start", "3sm": "center", md: "center" }}
          mb={{ sm: "20px !important", "2sm": "20px !important", "3sm": "0px !important", md: "0 !important" }}
        >
          Filter
        </Text>

        <HStack
          px="4px"
          w="100%"
          ml="0 !important"
          justifyContent="flex-start"
          flexDir={{ sm: "column", "2sm": "row", "3sm": "row", md: "row" }}
        >
          <FormControl
            ml={{ sm: "0 !important", "2sm": "0 !important", "3sm": "10px !important" }}
            mt={{ sm: "0 !important", "3sm": "inherit" }}
            w={{ sm: "100%", md: "320px" }}
          >
            <InputLabel color={labelColor}>Amount</InputLabel>
            <CryptoTxInput
              //   isLoading={isLoadingRate}
              //   isInvalid={isInsufficientBalance}
              placeholder={`Enter Amount`}
              coinValue={state?.amountCurrency ?? "ngn"}
              value={state?.amount ?? ""}
              onCoinChange={(coin) => set({ amountCurrency: coin })}
              onChange={(e) => handleAmount(e.target.value)}
              _select={{ fontSize: "14px" }}
            >
              {(fiats ?? [])?.map((fiat: any) => (
                <Option key={`p2p-filter-amount-${fiat?.coin}`} value={fiat?.coin} coin={fiat?.coin as any}>
                  {toCoinLocale(fiat?.coin)}
                </Option>
              ))}
            </CryptoTxInput>
          </FormControl>

          {/* <FormControl maxW="128px">
          <InputLabel color={labelColor}>Currency</InputLabel>
          <Select fontSize="14px" value={state?.currency ?? ""} onChange={(e) => handleCurrency(e.target.value)}>
            {(fiats ?? [])?.map((fiat) => (
              <option key={`p2p-filter-currency-${fiat?.coin}`} value={fiat?.coin}>
                {toCoinLocale(fiat?.coin)}
              </option>
            ))}
          </Select>
        </FormControl> */}

          <FormControl
            ml={{ sm: "0 !important", "2sm": "10px !important", "3sm": "10px !important" }}
            mt={{ sm: "16px !important", "2sm": "0px !important", "3sm": "0 !important" }}
            maxW={{ sm: "100%", md: "178px" }}
          >
            <InputLabel color={labelColor}>Payment Method</InputLabel>
            <Select fontSize="14px" value={state?.paymentMethod ?? ""} onChange={(e) => handlePaymentMethod(e.target.value)}>
              <option value="all">All Payments</option>
              <option value="bank">Bank Transfer</option>
              <option value="bitmama">Bitmama</option>
            </Select>
          </FormControl>
        </HStack>
      </HStack>

      {/* // <FormControl maxW="168px">
      //   <Select fontSize="14px" value={state?.listings ?? ""} onChange={(e) => handleListings(e.target.value)}>
      //     <option value="all">All Listings</option>
      //     <option value="own">My Listings</option>
      //     <option value="others">Other Listings</option>
      //   </Select>
      // </FormControl> */}
    </HStack>
  );
}
