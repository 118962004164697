import { Box, Stack, useColorModeValue } from "@chakra-ui/react";
import { useGetWalletTransationsV2Query } from "apis";
import {
  Group,
  Wallet,
  WalletActions,
  WalletActionsButton,
  useModalView as useSteps,
  PriceChart,
  TitleBar,
  Title,
  Card,
  PageLoading,
  TransactionItem,
  Paginator,
  EmptyCrate,
} from "components";
import { useSidePage } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { AnimatePresence, motion } from "framer-motion";
import { usePartialState, useWalletBalance } from "hooks";
import { useEffect, useMemo } from "react";
import { toQueryString, when } from "utils";

interface CryptoOverviewProps {
  coin: string;
  setCoin: (coin: string) => void;
}

export default function CryptoOverview(props: CryptoOverviewProps) {
  const { coin: props_coin } = props;
  const { data, params, updateSidePageConfigs, onClose: closeSidePage } = useSidePage();
  const { setCurrentView } = useSteps();
  const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const coin = useMemo(() => props_coin ?? params?.coin, [props_coin, params]);

  const { appfigs } = useAppConfig();
  const { depositConfig, withdrawalConfig } = appfigs ?? {};

  console.log("Sidepage data", { data, params, coin });

  const { getBalance, isLoading } = useWalletBalance();
  const balance = useMemo(() => getBalance(coin as any), [getBalance, coin]);

  const [queries, set] = usePartialState(
    {
      page: 1,
      limit: 6,
      unit: coin,
      sortBy: "createdAt:desc",
    },
    [coin]
  );

  const { data: tx, isLoading: isTxLoading, isFetching } = useGetWalletTransationsV2Query(toQueryString(queries));

  const totalCount = useMemo(() => tx?.totalCount, [tx]);
  const transactions = useMemo(() => tx?.transactions, [tx]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);
  const passedLimit = useMemo(() => (totalCount ?? 0) > queries.limit!, [totalCount, queries.limit]);

  const isCryptoDepositDisabled = useMemo(() => !!depositConfig?.disableCryptoDeposit, [depositConfig]);
  const isCryptoWithdrawalDisabled = useMemo(() => !!withdrawalConfig?.disableCryptoWithdrawal, [withdrawalConfig]);

  console.log("Fiat Tx Data", tx);

  const handlePageChange = (page: number) => {
    set({ page });
  };

  useEffect(() => {
    updateSidePageConfigs({
      _back: {
        text: "Close",
        onClick: closeSidePage,
      },
    });

    //eslint-disable-next-line
  }, []);

  console.log("Crypto Details Params", { coin });

  return (
    <Group px="0" mt="22px">
      <Wallet
        isLoading={isLoading}
        value={balance}
        w="100%"
        minW="unset"
        maxW="unset"
        currency={coin as any}
        _navigations={{ display: "none" }}
      >
        <WalletActions>
          <WalletActionsButton disabled={!!isCryptoWithdrawalDisabled} variant="outline" onClick={() => setCurrentView(1)}>
            Withdraw
          </WalletActionsButton>
          <WalletActionsButton disabled={!!isCryptoDepositDisabled} variant="solid" onClick={() => setCurrentView(2)}>
            Fund
          </WalletActionsButton>
        </WalletActions>
      </Wallet>

      {!["matic"].includes(coin) && (
        <PriceChart
          mt="40px !important"
          coin={coin}
          showDateFilter={false}
          showMarkups={false}
          showSelector={false}
          _info={{ p: 0 }}
        />
      )}

      <Group mt="82px !important">
        <TitleBar>
          <Title isLoading={isFetching} fontSize="18px">
            History
          </Title>

          {/* {hasTx && passedLimit && (
            <TitleBarActions>
              <Link to="#">See All</Link>
            </TitleBarActions>
          )} */}
        </TitleBar>
        <Card bg={bg} w="100%" p="8px" overflow="hidden">
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div
              key={`tx-list-${when(isTxLoading, "loading", "loaded")}`}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.98 }}
            >
              {isTxLoading && <PageLoading isLoading={isTxLoading} />}
              {!isTxLoading && (
                <Stack>
                  {(transactions ?? []).map((tx: any, id: any) => (
                    <TransactionItem
                      mt="0 !important"
                      w="100%"
                      key={`transaction-${id}`}
                      unit={tx?.unit}
                      type={tx?.type}
                      value={tx?.value}
                      createdAt={tx?.createdAt}
                      description={tx?.description}
                    />
                  ))}
                </Stack>
              )}

              {!isTxLoading && !isFetching && !hasTx && <EmptyCrate type="transaction" />}
            </motion.div>
          </AnimatePresence>
        </Card>

        {passedLimit && (
          <Box w="100%">
            <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
          </Box>
        )}
      </Group>
    </Group>
  );
}
