import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  FormControl,
  HStack,
  IconButton,
  IconButtonProps,
  Text,
  TextProps,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { SquareLeftArrowSVG, SquareRightArrowSVG, WalletPattern1SVG, WalletPattern2SVG } from "assets";
import { motion, MotionConfig } from "framer-motion";
import { ReactNode, useMemo, useState } from "react";
import { currencyFormat, isFiat, renderComponent, toCoinLocale, toPrecision, when, wrap } from "utils";

import { CircularLoader, CoinIcon, ConditionalRender, Icon, Option, RadixSelect } from "components";
import { SchemeType, SupportedCoinType, useCoin, useColor, usePartialState, useWalletSchemes } from "hooks";
import { omit } from "lodash";
import hasChildWithName from "utils/hasChildWithName";

import isEmpty from "lodash/isEmpty";
import ls from "utils/secureStorage";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export const transition = {
  type: "spring",
  mass: 5,
  stiffness: 1000,
  damping: 50,
  restDelta: 0.0001,
};

export interface WalletProps extends BoxProps {
  currency: SupportedCoinType;
  value?: ReactNode;
  label?: string;
  scheme?: SchemeType;
  tokens?: SupportedCoinType[];
  availableCurrencies?: string[];
  onCurrencyChange?: (currency: string) => void;
  isLoading?: boolean;
  tooltip?: string;
  canBeHidden?: boolean;
  _outline?: ButtonProps & { label?: string };
  _solid?: ButtonProps & { label?: string };
  _navigations?: Omit<ButtonProps, "right" | "left"> & { right?: ButtonProps; left?: ButtonProps };
}

export interface WalletActionsProps extends BoxProps {}
export interface WalletActionsButtonProps extends ButtonProps {
  scheme?: SchemeType;
}
type WalletComponentTypes = "WalletActions" | "WalletLabel" | "WalletValue";

export interface WalletLabelProps extends Omit<BoxProps, "onChange"> {
  currency: SupportedCoinType;
  label?: string;
  color?: string;
  iconColor?: string;
  tokens?: SupportedCoinType[];
  onChange?: (coin: string) => void;
}

export interface WalletValueProps extends TextProps {
  currency: SupportedCoinType;
  value: string | number;
  canBeHidden?: boolean;
}

interface SquareButtonProps extends IconButtonProps {}

const MotionText = motion<any>(Text);

export function WalletActions(props: WalletActionsProps) {
  const { children, ...xprops } = props;
  // const color = useColorModeValue(iconColor ?? "primary.900", "primary.default");

  return (
    <HStack mt="24px" {...xprops}>
      {children}
    </HStack>
  );
}

export function WalletActionsButton(props: WalletActionsButtonProps) {
  const { scheme, variant = "outline", children, ...xprops } = props;

  const schemes = useWalletSchemes(scheme ?? "default");

  const variantScheme = (schemes["button"] ?? {})![variant as string] ?? {};

  return (
    <Button
      size="sm"
      minW="110px"
      minH="32px"
      border="1px solid transparent"
      borderColor="primary.900"
      color={scheme ? schemes.outlineBtnColor : "inherit"}
      colorScheme={scheme ? schemes.colorScheme : "primary"}
      fontSize="12px"
      fontFamily="var(--bitmama-fonts-heading)"
      _hover={{ color: "primary.default" }}
      _active={{ borderColor: "primary.default" }}
      {...variantScheme}
      {...xprops}
    >
      {children}
    </Button>
  );
}

export function WalletLabel(props: WalletLabelProps) {
  const { currency, label, color, iconColor, tokens, onChange } = props;
  // const color = useColorModeValue(iconColor ?? "primary.900", "primary.default");
  const sw_color = useColor();

  const isSelectable = !!tokens && Array.isArray(tokens);

  const handleChange = (coin: string) => {
    onChange && onChange(coin);
  };

  return (
    <HStack>
      {!isSelectable && <CoinIcon coin={currency} color={iconColor} />}

      <Text fontSize="14px" fontWeight="600" color={color}>
        {label}
      </Text>

      <ConditionalRender shouldRender={isSelectable}>
        <FormControl ml="8px !important" w="fit-content">
          <RadixSelect
            use_coin_icon
            value={currency}
            aria-label="Coin"
            placeholder="Select Coin"
            onChange={handleChange}
            _trigger={{
              bg: sw_color("primary.800", "dark.cardBg"),
              maxW: "144px !important",
              maxH: "30px !important",
              color: sw_color("white !important", "white !important"),
              pl: "12.8px",
              borderRadius: "4px",
              _hover: { bg: sw_color("primary.900", "dark.cardBg"), color: "white" },
              // _focus: { bg: "white", shadow, borderColor },
            }}
          >
            {isSelectable &&
              ((tokens as SupportedCoinType[]) ?? []).map((coin) => (
                <Option key={coin} value={coin} coin={coin as any}>
                  {toCoinLocale(coin)}
                </Option>
              ))}
          </RadixSelect>
        </FormControl>
      </ConditionalRender>
    </HStack>
  );
}

export function WalletValue(props: WalletValueProps) {
  const { currency, value, canBeHidden = false, ...xprops } = props;

  type HiddenType = { isHidden: boolean };

  // const ref = useAnimatedText(+value, currency, transition);
  const isHidden = useMemo(() => {
    const result = ls.get("TOTAL_VALUE");
    if (!!result) return canBeHidden && (JSON.parse(result) as HiddenType).isHidden;
    return canBeHidden;
  }, [canBeHidden]);

  const [state, set] = usePartialState<HiddenType>({ isHidden }, [isHidden]);

  const label = useMemo(() => {
    let lab: string = "none";
    if (isFiat(currency)) {
      lab = currencyFormat(currency as any).format(+value);
    } else {
      lab = toPrecision(+value, currency) as string;
    }
    return when<string>(!!state?.isHidden, `******`, lab);
  }, [value, currency, state]);

  const toggleVisibility = () => {
    set({ isHidden: !state?.isHidden });
    ls.set("TOTAL_VALUE", JSON.stringify({ isHidden: !state?.isHidden }));
  };

  return (
    <HStack maxW="98%" alignItems="center" {...xprops}>
      <MotionText
        fontFamily="var(--bitmama-fonts-heading)"
        fontSize={{ base: "18px", sm: "20px", "2sm": "24px", md: "28px", lg: "32px" }}
        fontWeight="700"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {label}
      </MotionText>

      <ConditionalRender shouldRender={canBeHidden}>
        <IconButton
          mt={when(!!state?.isHidden, "0px !important", "0px !important")}
          aria-label="Hide Value"
          h="fit-content"
          w="fit-content"
          minH="unset"
          minW="unset"
          bg="transparent"
          _hover={{ bg: "transparent" }}
          icon={when(!state?.isHidden, <ViewOffIcon />, <ViewIcon />)}
          onClick={toggleVisibility}
        />
      </ConditionalRender>
    </HStack>
  );
}

export default function Wallet(props: WalletProps) {
  const {
    children,
    currency,
    label,
    availableCurrencies,
    value,
    isLoading,
    scheme = "default",
    _outline,
    _solid,
    _navigations,
    canBeHidden,
    onCurrencyChange,
    tokens,
    tooltip,
    ...xprops
  } = props;

  const { label: name } = useCoin(currency);

  const text = when(isFiat(currency), `${name} Wallet`, `${toCoinLocale(currency)} Wallet`);

  // const [v, setV] = useState(0.000362923);

  const schemes = useWalletSchemes(scheme);

  const render = (componentType: WalletComponentTypes) => renderComponent(children, componentType, { scheme });
  const hasChild = (componentType: WalletComponentTypes) => hasChildWithName(componentType, children);

  const [[page], setPage] = useState([0, 0]);

  const totalPages = useMemo(() => Math.ceil((availableCurrencies ?? []).length / 1), [availableCurrencies]);

  // console.log("Available Currencies", availableCurrencies);

  // const currencyIndex = wrap(0, totalPages, page);

  const paginate = (newDirection: number) => {
    const newPage = page + newDirection;
    const currencyIndex = wrap(0, totalPages, newPage);
    if (!isEmpty(availableCurrencies) && !!onCurrencyChange) onCurrencyChange((availableCurrencies ?? [])[currencyIndex]);
    setPage([newPage, newDirection]);
  };

  return (
    <MotionConfig transition={transition}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        bg={schemes.bg}
        p="28px 40px"
        minW={{ base: "280px", smx: "290px", "1sm": "440px" }}
        maxW={{ base: "initial", "3sm": "max-content" }}
        width="100%"
        minH="204px"
        borderRadius="20px"
        {...xprops}
        pos="relative"
        overflow="hidden"
      >
        {isLoading && <CircularLoader pos="absolute" top="35px" right="12px" color={schemes.loaderColor} />}
        <Box as={WalletPattern2SVG} color={schemes.patternColor} pos="absolute" left={0} bottom={0} opacity={0.3} />
        <Box as={WalletPattern1SVG} color={schemes.patternColor} pos="absolute" right={0} top={0} opacity={0.3} />
        <ConditionalRender shouldRender={!!tooltip}>
          <Tooltip label={tooltip ?? "Now you see me 😒"} placement="top" hasArrow bg={schemes.patternColor}>
            <Box color={"red.500"} pos="absolute" right="44px" top="35px" zIndex={999}>
              <Icon type="infoRounded" color="#f2f2f278" _hover={{ color: "white" }} />
            </Box>
          </Tooltip>
        </ConditionalRender>
        <ConditionalRender shouldRender={totalPages > 1}>
          <SquareButton
            left="32px"
            aria-label="prev button"
            icon={<SquareLeftArrowSVG />}
            color={schemes?.buttonColor}
            onClick={() => paginate(-1)}
            {...omit(_navigations, ["right", "left"])}
            {..._navigations?.left}
          />
          <SquareButton
            right="32px"
            aria-label="next button"
            icon={<SquareRightArrowSVG />}
            color={schemes?.buttonColor}
            onClick={() => paginate(1)}
            {...omit(_navigations, ["right", "left"])}
            {..._navigations?.right}
          />
        </ConditionalRender>
        <VStack w="85%">
          <WalletLabel label={label ?? text} currency={currency} tokens={tokens} onChange={onCurrencyChange} {...schemes?.label} />

          <WalletValue
            currency={currency}
            value={value as any}
            mt="20px !important"
            canBeHidden={canBeHidden}
            color={schemes?.textColor}
          />
        </VStack>
        {!hasChild("WalletActions") && (
          <HStack mt="24px">
            <Button
              size="sm"
              minW="110px"
              minH="32px"
              borderColor="primary.900"
              variant="outline"
              color={schemes.outlineBtnColor}
              colorScheme={schemes.colorScheme}
              fontSize="12px"
              fontFamily="var(--bitmama-fonts-heading)"
              _hover={{ color: "primary.default" }}
              _active={{ borderColor: "primary.default" }}
              // onClick={() => setV(100050)}
              {...schemes?.outline}
              {..._outline}
            >
              {_outline?.label ?? "Withdraw"}
            </Button>
            <Button
              size="sm"
              minW="110px"
              minH="32px"
              variant="solid"
              colorScheme="primary"
              fontSize="12px"
              fontFamily="var(--bitmama-fonts-heading)"
              // onClick={() => setV(4000000)}
              {...schemes?.solid}
              {..._solid}
            >
              {_solid?.label ?? "Fund"}
            </Button>
          </HStack>
        )}
        {hasChild("WalletActions") && render("WalletActions")}
      </Box>
    </MotionConfig>
  );
}

function SquareButton(props: SquareButtonProps) {
  // const {icon} = props;
  const hoverColor = useColorModeValue("white", "white");
  const hoverBg = useColorModeValue("primary.default", "secondary.400");

  return (
    <IconButton
      size="sm"
      minH="fit-content"
      minW="fit-content"
      h="fit-content"
      borderRadius="10px"
      bg="transparent"
      color="primary.default"
      pos="absolute"
      top="50%"
      transform="scale(1) translateY(-50%)"
      transition="var(--transition)"
      _active={{
        transform: "scale(.9) translateY(-50%)",
        transition: "var(--transition)",
      }}
      _hover={{
        bg: hoverBg,
        color: hoverColor,
        transition: "var(--transition)",
      }}
      {...props}
    />
  );
}

WalletActions.displayName = "WalletActions";

// export function useAnimatedText(target: number, currency: string, trans: any = {}) {
//   const transition = {
//     type: "spring",
//     duration: 2,
//     // mass: 1,
//     stiffness: 1000,
//     damping: 1000,
//     restDelta: 0.0001,
//   };

//   const ref = useRef<HTMLElement>(null);
//   const value = useSpring(target, transition);

//   const label = useCallback(
//     (val: number) => {
//       if (isFiat(currency)) return currencyFormat(currency as any).format(val);
//       return toPrecision(val, currency) as string;
//     },
//     [currency]
//   );

//   useEffect(() => {
//     ref.current!.innerText = label(target);

//     return value.onChange((v) => {
//       ref.current!.innerText = label(v);
//     });
//   });
//   useEffect(() => value.set(target), [target]);

//   return ref;
// }
