import { Box, Button, Heading, ModalContentProps, ModalProps, VStack } from "@chakra-ui/react";
import { AbstractModal, Icon, IconNames } from "components";
import { useMemo } from "react";
// import { isTesting } from "config";
// import { useSidePage } from "contexts";

type Variant = "delete" | "default" | "enable";
interface VariantReturnType {
  icon?: IconNames;
  title: string;
}
interface ConfirmationModalProps extends ModalProps {
  labels?: string[];
  onConfirm?: () => void;
  closeOnConfirm?: boolean;
  disableConfirmation?: boolean;
  variant?: Variant;
  icon?: IconNames;
  title?: string;
  _content?: ModalContentProps;
}

export default function Confirmation(props: ConfirmationModalProps) {
  const {
    title,
    variant,
    icon,
    labels,
    onConfirm,
    isOpen,
    onClose,
    closeOnConfirm = true,
    disableConfirmation,
    _content,
    children,
    ...xprops
  } = props;
  //   const bColor = useColorModeValue("grey.150", "dark.border");

  const info = useMemo(() => {
    const map: Record<Variant, VariantReturnType> = {
      delete: { icon: "trashFilled", title: "Confirm Delete?" },
      enable: { icon: "playFilled", title: "Confirm Enable?" },
      default: { title: "Confirmation" },
    };
    return map[variant ?? "default"];
  }, [variant]);

  const confirmLabel = useMemo(() => (labels && labels[0] && labels[0]) ?? "Confirm", [labels]);
  const closeLabel = useMemo(() => (labels && labels[1] && labels[1]) ?? "Close", [labels]);

  const handleConfirm = () => {
    onConfirm && onConfirm();
    closeOnConfirm && onClose();
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        position: { base: "absolute", "1sm": "relative" },
        ..._content,
      }}
      {...xprops}
    >
      <Box p={{ base: "32px 40px", "1sm": "44px 80px" }}>
        <VStack>
          {(!!info?.icon || !!icon) && <Icon type={info.icon ?? icon ?? "account"} boxSize="44px" />}
          <Heading as="h5" fontSize="20px">
            {title ?? info.title}
          </Heading>
        </VStack>

        {children}

        <VStack mt="24px !important" px="46px" w="100%">
          <Button minW="unset" w="100%" disabled={disableConfirmation} onClick={handleConfirm}>
            {confirmLabel}
          </Button>
          <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose}>
            {closeLabel}
          </Button>
        </VStack>
      </Box>
    </AbstractModal>
  );
}
