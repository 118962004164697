import { Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import { useUploadP2PPaymentProofMutation } from "apis";
import { AbstractModal, Uploader, useAbstractModal } from "components";
import { usePartialState, useSelector } from "hooks";
import { useMemo } from "react";
import { selectUser } from "store/slices";
import { uploadFile } from "utils";

interface UploadP2PPaymentProofProps extends Omit<ModalProps, "children"> {}

interface IState {
  paymentProof: File;
  isUploading: boolean;
}

export function UploadP2PPaymentProof(props: UploadP2PPaymentProofProps) {
  const { isOpen, onClose } = props;

  return (
    <AbstractModal isOpen={isOpen} onClose={onClose}>
      <UploadView {...props} />
    </AbstractModal>
  );
}

function UploadView(props: UploadP2PPaymentProofProps) {
  const { onClose } = props;

  const { ref } = useParams();
  const [id, frozenRef] = String(ref).split("-");
  const { profile } = useSelector(selectUser);
  const [state, set, reset] = usePartialState<IState>();
  const { updateModal } = useAbstractModal();

  const [uploadProof, { isLoading }] = useUploadP2PPaymentProofMutation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log({ ...state, id, frozenRef });

    if (!state?.paymentProof) return;

    try {
      set({ isUploading: true });
      const res = await uploadFile(state?.paymentProof, `${profile?.username}-waiver-${id}`);
      set({ isUploading: false });
      //   console.log("Upload Result", res);
      const result = await uploadProof({ id, frozenRef, paymentProof: res?.location }).unwrap();
      //   !!res?.location && (await uploadProof({ id: depositData?._id!, paymentUploadUrl: res?.location }));
      if (!!result && !(result as any)?.error) {
        updateModal &&
          updateModal({
            isSuccess: true,
            success: {
              description: `Your payment proof has been uploaded successfully`,
            },
          });

        reset();
      }
    } catch (error) {
      set({ isUploading: false });
    }
  };

  const isDisabled = useMemo(() => !state?.paymentProof || !!state?.isUploading || isLoading, [state, isLoading]);

  return (
    <VStack as="form" p={{ base: "40px 20px", "2sm": "45px 50px", "3sm": "45px 100px" }} onSubmit={handleSubmit}>
      <VStack maxW={{ base: "100%", "2sm": "320px" }} m="0 auto" mb={{ base: "25px", "2sm": "40px" }}>
        <Heading as="h6" fontSize="18px">
          Upload Proof of Payment
        </Heading>
        <Text textAlign="center" mt="20px">
          Kindly upload proof of payment.
        </Text>
      </VStack>

      <Uploader onFiles={(files) => set({ paymentProof: files[0] })} />

      <VStack mt={{ base: "37px !important", "2sm": "74px !important" }} w={{ base: "100%", "2sm": "initial" }}>
        <Button
          minW={{ base: "100%", "2sm": "330px", "4sm": "350px" }}
          w={{ base: "100%", "2sm": "initial" }}
          onClick={handleSubmit}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={state?.isUploading}
          disabled={isDisabled}
        >
          Upload
        </Button>
        <Button
          variant="transparent"
          textDecoration="underline"
          minW="unset"
          w="100%"
          onClick={onClose}
          disabled={state?.isUploading || isLoading}
        >
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}
