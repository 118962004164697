import { Box, BoxProps, Button, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import Span from "components/Common/Span";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import { useColor } from "hooks";
import { useMemo } from "react";
import { when } from "utils";

import { ReactComponent as Trophy } from "assets/svg/trophy-cup.svg";

export interface RewardInfoProps extends BoxProps {
  claimed?: boolean;
  title?: string;
  description?: string;
  onClaimClick?: () => void;
  isClaiming?: boolean;
}

export function RewardInfo(props: RewardInfoProps) {
  const { claimed = false, title, description, onClaimClick, isClaiming = false } = props;
  const color = useColor();

  return (
    <Box
      w="fit-content"
      //   maxW="340px"
      border="1px solid transparent"
      borderColor={color("#d9d9d9", "#CACBCA")}
      borderRadius="10px"
      p="20px 16px"
      bg="transparent"
      _hover={{
        bg: color("#efefef", "rgba(255 255 255 / 5%)"),
      }}
    >
      {!!claimed && <RewardInfoTag isEarned={claimed} />}

      <HStack mt="0px" gridGap="12px">
        {/* <BadgeIcon badge="newBitStar" /> */}
        <Box as={Trophy} boxSize="68px" />

        <Stack my="12px !important">
          <Text color={color("black", "white")} fontFamily="var(--bitmama-fonts-heading)" fontSize="15px" fontWeight="600">
            {title ?? "$0.05 USDT"}
          </Text>
          <Text mt="4px !important" fontSize="14px" fontWeight="500">
            {description ?? "Earn this $0.05 reward by referring up to 2 new active users on Bitmama"}
          </Text>
        </Stack>
      </HStack>

      <ConditionalRender shouldRender={!claimed}>
        <VStack mt="16px">
          <Button w="100%" size="sm" onClick={onClaimClick} isLoading={isClaiming} isDisabled={isClaiming}>
            Claim
          </Button>
        </VStack>
      </ConditionalRender>
    </Box>
  );
}

interface InfoTagProps extends BoxProps {
  isEarned?: boolean;
}

export function RewardInfoTag(props: InfoTagProps) {
  const { isEarned = false, ...xprops } = props;

  const color = useColor();

  const bg = useMemo(() => {
    if (!isEarned) return color("#CFDAD9", "#CFDAD9");
    return color("#E2F2DA", "#E2F2DA");
  }, [isEarned, color]);

  return (
    <Box w="fit-content" p="6px 14px" borderRadius="20px" bg={bg} color="black" fontSize="12px" fontWeight="600" {...xprops}>
      {when(isEarned, "Earned", "Unearned")}
      <Span ml="4px">{when(isEarned, "🎁", "🎁")}</Span>
    </Box>
  );
}
