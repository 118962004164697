import { useCallback, useMemo } from "react";
import { Box, Button, FormControl, FormHelperText, Grid, HStack, Heading, Input, Stack, Text, VStack } from "@chakra-ui/react";
import { ConditionalRender, FadeInOut, Icon, InputLabel, Select, useModalView } from "components";
import { VerificationForm } from "interfaces";
import { calculateAge, when } from "utils";
import { useColor, useRedebounce, useSelector, useSignupInputCheck } from "hooks";
import { selectAuth, selectUser } from "store/slices";
import { toLower } from "lodash";
import { useAppConfig } from "contexts/appconfig.context";

type CheckableInputType = {
  bvn?: string | undefined;
};

export default function StepOne({ idTypes, set, handleChange, formState, onClose }: VerificationForm) {
  const { /*nameOnId,*/ ninNumber, idNumber, dateOfBirth, docType, bvn } = formState;

  const { checks } = useSelector(selectAuth);
  const { profile } = useSelector(selectUser);
  const { onNext } = useModalView();

  const { appfigs } = useAppConfig();
  const { kycConfig } = appfigs ?? {};

  const debounce = useRedebounce();
  const { makeCheck, check } = useSignupInputCheck();

  const bvnData = useMemo(() => checks?.bvn?.bvnData, [checks?.bvn?.bvnData]);
  const isChecking = useCallback((type: "bvn") => check(type).status === "fetching", [check]);
  const isAlreadyInUse = useCallback((type: "bvn") => check(type).inUse, [check]);

  const age = useMemo(() => when(!!dateOfBirth, calculateAge(new Date(dateOfBirth)), 0), [dateOfBirth]);

  const minAge = useMemo(
    () => when(!!kycConfig && !!kycConfig?.minAgeForKYCVerification, kycConfig?.minAgeForKYCVerification!, 18),
    [kycConfig]
  );
  const maxAge = useMemo(
    () => when(!!kycConfig && !!kycConfig?.maxAgeForKYCVerification, kycConfig?.maxAgeForKYCVerification!, 70),
    [kycConfig]
  );
  const isAgeInavid = useMemo(() => when(!!dateOfBirth, age < minAge || age > maxAge, false), [age, dateOfBirth, minAge, maxAge]);

  const isNigerian = useMemo(() => when(!!profile?.country, toLower(profile?.country) === "nigeria", false), [profile]);

  const isNationalId = useMemo(() => {
    if (!docType) return false;
    const idType = String(docType).split("-")[1];
    if (!!idType && ["national_id", "nimc_slip"].includes(idType) && isNigerian) return true;
    return false;
  }, [docType, isNigerian]);

  const nationIdFieldName = useMemo(() => when(isNationalId, "ninNumber", "idNumber"), [isNationalId]);

  // console.clear();
  // console.log("Is National ID", isNationalId);

  console.log("national FORM STATE", formState, age, isAgeInavid);

  // const handleDocTypeChange = (e: any) => {
  //   const idType = String(e.target.value).split("-")[1];
  //   console.log("national doctype", idType, e.target.value);
  //   if (["primary-national_id", "primary-nimc_slip"].includes(e.target.value)) {
  //     console.log("is actually a nation id", idType, ninNumber, idNumber);
  //     set({ ninNumber, idNumber: ninNumber });
  //   }
  //   handleChange(e);
  // };

  // const isDisabled = Boolean(
  //   (!(dateOfBirth && docType) && !isNigerian && !nameOnId) || isAgeInavid /*|| (isNigerian && !ninNumber) */
  // );

  const collectDob = useMemo(() => (isNigerian && !profile?.dateOfBirth) || !isNigerian, [isNigerian, profile?.dateOfBirth]);
  const collectBvn = useMemo(() => isNigerian && !profile?.bvn, [isNigerian, profile?.bvn]);

  const isBVNInvalid = useMemo(() => !!collectBvn && !!bvn && bvn.length < 11, [collectBvn, bvn]);

  const handleCheckableInput = useCallback(
    (update: CheckableInputType) => {
      set(update);

      if (!!update?.bvn && update?.bvn?.length === 11) debounce(() => makeCheck("bvn", update?.bvn!), "makeBVNCheck", 500)();
    },
    [makeCheck, debounce, set]
  );

  const isDisabled = useMemo(() => {
    if (
      (isNigerian && !(docType && (idNumber || ninNumber))) ||
      isAgeInavid ||
      (collectDob && !dateOfBirth) ||
      (collectBvn && !bvn) ||
      isBVNInvalid ||
      isChecking("bvn") ||
      isAlreadyInUse("bvn")
    )
      return true;
    else if ((!isNigerian && !(docType && idNumber)) || (collectDob && !dateOfBirth)) return true;
    else return false;
  }, [
    isNigerian,
    dateOfBirth,
    idNumber,
    docType,
    ninNumber,
    isAgeInavid,
    collectDob,
    collectBvn,
    bvn,
    isBVNInvalid,
    isChecking,
    isAlreadyInUse,
  ]);

  return (
    <Stack p="8px">
      <Heading my="38px !important" as="h6" fontSize="sm">
        Step 1/3 - ID Card Details
      </Heading>

      <Grid mb="32px !important" templateColumns="repeat(2, 1fr)" gap="16px">
        <FormControl>
          <InputLabel htmlFor="nameOnId">First Name</InputLabel>
          <Input id="firstname" defaultValue={profile?.firstName} name="firstname" disabled />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="nameOnId">Last Name</InputLabel>
          <Input id="lastname" defaultValue={profile?.lastName} name="lastname" disabled />
        </FormControl>
      </Grid>

      <FormControl mb="32px !important">
        <InputLabel htmlFor="docType">ID Type</InputLabel>
        <Select placeholder="Select ID Type" id="docType" name="docType" onChange={handleChange} defaultValue={docType}>
          {idTypes?.map(({ category, name, value }) => (
            <option value={`${category}-${value}`} key={value}>
              {name}
            </option>
          ))}
        </Select>

        {(formState.docType === "primary-international_passport" || formState.docType === "primary-driver_licence") && (
          <Text display="block" mt="8px" as="span">
            Note: ID should be at least 6 months from expiration
          </Text>
        )}
      </FormControl>

      {/* <ConditionalRender shouldRender={!isNigerian}>
        <FormControl mb="32px !important">
          <InputLabel htmlFor="nameOnId">Name On ID</InputLabel>
          <Input id="lastname" value={nameOnId} name="nameOnId" onChange={(e) => set({ nameOnId: e.target.value })} />
        </FormControl>
      </ConditionalRender> */}

      {/* <FormControl mb="32px !important">
        <InputLabel htmlFor="nameOnId">Name on ID</InputLabel>
        <Input id="nameOnId" defaultValue={nameOnId} name="nameOnId" onChange={handleChange} />
      </FormControl> */}

      {formState.docType === "primary-kra_certificate" && (
        <FormControl mb="32px !important">
          <InputLabel htmlFor="kraPin">KRA Pin</InputLabel>
          <Input id="kraPin" defaultValue={ninNumber} name="ninNumber" onChange={handleChange} />
        </FormControl>
      )}

      {/* {formState.docType !== "primary-kra_certificate" && !isNationalId && isNigerian && (
        <FormControl mb="32px !important">
          <InputLabel htmlFor="ninNumber">NIN (National Identity Number)</InputLabel>
          <Input disabled={!formState?.docType} name="ninNumber" defaultValue={ninNumber} id="ninNumber" onChange={handleChange} />
        </FormControl>
      )} */}

      {formState.docType !== "primary-kra_certificate" && (
        <FormControl mb="32px !important">
          <InputLabel htmlFor={nationIdFieldName}>
            {when(isNationalId, "NIN (National Identity Number)", "ID Card Number")}
          </InputLabel>
          <Input
            disabled={!formState?.docType}
            name={nationIdFieldName}
            defaultValue={when(isNationalId, ninNumber, idNumber)}
            id={nationIdFieldName}
            onChange={(e) =>
              when(
                !isNationalId,
                (e: any) => handleChange(e),
                (e: any) => set({ ninNumber: String(e.target.value).trim(), idNumber: String(e.target.value).trim() })
              )(e)
            }
          />
        </FormControl>
      )}

      <ConditionalRender shouldRender={!!collectBvn}>
        <FormControl mb="32px !important">
          <InputLabel isInvalid={isBVNInvalid} isLoading={isChecking("bvn")} htmlFor="bvn">
            Bank Verification Number (BVN)
          </InputLabel>
          <Input
            isInvalid={isBVNInvalid}
            maxLength={11}
            disabled={!formState?.docType}
            name="bvn"
            defaultValue={profile?.bvn ?? bvn}
            id="bvn"
            onChange={(e) => handleCheckableInput({ bvn: String(e.target.value).trim() })}
          />

          <Box>
            {bvn && isBVNInvalid && <FormWarning info={"BVN is an 11-digit number"} isValid={!isBVNInvalid} />}
            {bvn && !isChecking("bvn") && !isBVNInvalid && bvnData && (
              <FormWarning
                info={when(
                  isAlreadyInUse("bvn") && !isChecking("bvn"),
                  `BVN verification failed`,
                  // `${join([bvnData?.firstName, bvnData?.lastName], " ")}`
                  "Good to go"
                )}
                isValid={!isAlreadyInUse("bvn")}
              />
            )}
          </Box>
        </FormControl>
      </ConditionalRender>

      <FormControl mb="12px !important">
        <InputLabel htmlFor="dateOfBirth">Date of birth</InputLabel>
        <Input
          type="date"
          disabled={!collectDob}
          defaultValue={profile?.dateOfBirth ?? dateOfBirth}
          id="dateOfBirth"
          name="dateOfBirth"
          onChange={handleChange}
        />
      </FormControl>

      {isAgeInavid && (
        <Text fontSize="14px" color="error">
          PS: Only users between the ages of 18 - 70 are able to complete Level 2 Verification
        </Text>
      )}

      <VStack mt="48px !important" px="46px" w="100%">
        <Button minW="unset" w="100%" onClick={onNext} fontFamily="var(--bitmama-fonts-heading)" disabled={isDisabled}>
          Save and Continue
        </Button>

        <Button
          variant="transparent"
          textDecoration="underline"
          minW="unset"
          w="100%"
          onClick={onClose}
          //   isLoading={isCancelling}
          //   disabled={isSubmiting || isCancelling}
        >
          Cancel
        </Button>
      </VStack>
    </Stack>
  );
}

function FormWarning(props: { info: string; isValid: boolean }) {
  const { info, isValid } = props;

  const color = useColor();

  return (
    <FormHelperText fontSize="sm" fontWeight="500" color={when(!isValid, "error", color("primary.700", "secondary.400"))}>
      <HStack alignItems="flex-start">
        <FadeInOut key={`password-is-${when(isValid, "valid", "invalid")}`}>
          {!isValid && <Icon type="shieldOutlined" color="inherit" />}
          {isValid && <Icon type="verifiedUser" color="inherit" />}
        </FadeInOut>
        <Text>{info}</Text>
      </HStack>
    </FormHelperText>
  );
}
