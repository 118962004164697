import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { navigate, useParams } from "@reach/router";
import { useFundEarnWalletMutation, useGetEarnWalletQuery, useWithdrawEarnWalletMutation } from "apis";
import { FormInfo, Icon, InputLabel, MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { useBuiltInFn, useDisclosures, usePartialState, useWalletBalance } from "hooks";
import { AppAnalyticEvent, PageProps } from "interfaces";
import { Emitter } from "libs";
import { useMemo } from "react";
import { SubmitPinModal, SuccessModal } from "ui";
import { toCoinLocale, toPrecision, when } from "utils";

interface TransferEarningsPageProps extends PageProps {}

interface IState {
  from: "main" | "earn";
  to: "main" | "earn";
  amount: string;
  pin: string;
}

type ModalTypes = "pin" | "success";

export default function TransferEarningsPage(props: TransferEarningsPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { coin } = useParams();
  const { appfigs } = useAppConfig();
  const { stakingConfig } = appfigs ?? {};

  const isFundingDisabled = useMemo(() => !!stakingConfig?.disableFundStakingWallet ?? false, [stakingConfig]);
  const isWithdrawalDisabled = useMemo(() => !!stakingConfig?.disableWithdrawFromStakingWallet ?? false, [stakingConfig]);

  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const { goBack } = useBuiltInFn();
  const [state, set] = usePartialState<IState>({
    from: "earn",
    to: "main",
  });

  const { getBalance, isLoading: isLoadingBalance } = useWalletBalance();

  const { data: wallets, isLoading: isLoadingWallets } = useGetEarnWalletQuery({});

  const [_withdrawFunds, { isLoading, isSuccess }] = useWithdrawEarnWalletMutation();
  const [_fundWallet, { isLoading: isFunding, isSuccess: isFundingSuccess }] = useFundEarnWalletMutation();

  const selectedWallet = useMemo(() => (!!coin ? wallets?.find((w) => w?.unit === coin) : undefined), [coin, wallets]);

  const balance = useMemo(
    () => (state?.from === "main" ? getBalance(coin) : selectedWallet?.balance?.$numberDecimal),
    [coin, getBalance, selectedWallet, state?.from]
  );

  const isInsufficientBalance = useMemo(() => !!state?.amount && +state?.amount > +(balance ?? 0), [state?.amount, balance]);

  const handleBack = () => {
    goBack();
  };

  const alternate = (target: string) => {
    const map: Record<string, string> = {
      to: "from",
      from: "to",
      earn: "main",
      main: "earn",
    };

    return map[target];
  };

  const handleChange = (target: "to" | "from", value: string) => {
    const alterKey = alternate(target);
    const alterValue = alternate(value);
    set({ [target]: value, [alterKey]: alterValue });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    open("pin")();
  };

  const handleSuccess = () => {
    console.log("HANDLE SUCCESS CLICKED");

    navigate(`/earn/staking?coin=${coin}`);
    close("success")();
  };

  const handleTransfer = async (pin?: string) => {
    close("pin")();
    const reqData = {
      amount: +state?.amount!,
      coin: coin,
      pin: pin ?? state?.pin,
    };
    // console.log(reqData);

    Emitter.emit(AppAnalyticEvent.TRANSFER_FUNDS_STAKING_INITIATED, reqData);

    const result = await when(
      state?.to === "main",
      _withdrawFunds,
      _fundWallet
    )({ ...reqData, to: state?.to, from: state?.from }).unwrap();

    if (!!result) Emitter.emit(AppAnalyticEvent.TRANSFER_FUNDS_STAKING_SUBMITTED, reqData);

    // if (state?.to === "main") _withdrawFunds({ ...reqData });
    // if (state?.to === "earn") _fundWallet({ ...reqData });
  };

  const successMessage = useMemo(() => {
    const map = {
      earn: `You've successfully funded your Earn wallet with ${toPrecision(+(state?.amount ?? 0), coin)}`,
      main: `Withdrawal of ${toPrecision(+(state?.amount ?? 0), coin)} into your Main wallet was successful`,
    };
    return map[state?.to ?? "earn"];
  }, [state?.to, coin, state?.amount]);

  const isDisabled = useMemo(
    () => !(coin && state?.from && state?.to && state?.amount) || isInsufficientBalance || isLoading || isFunding,
    [state, isInsufficientBalance, isLoading, isFunding, coin]
  );

  useUpdateEffect(() => {
    if (isSuccess || isFundingSuccess) open("success")();
  }, [isSuccess, isFundingSuccess]);

  return (
    <PageMotion key="Transfer">
      <Topbar pageTitle="Earn" {...props} />

      <MainLayoutContainer pb="60px">
        <VStack alignItems="flex-start">
          <Box w="100%" maxW="container.sm" pr={{ base: "40px", "2sm": "80px" }}>
            <SidePageProvider>
              <Box mb="42px">
                <Button
                  size="sm"
                  minW="fit-content"
                  maxW="fit-content"
                  variant="link"
                  leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                  onClick={handleBack}
                  px="2px !important"
                >
                  Back
                </Button>

                <Stack justifyContent="space-between">
                  <Text mt="12px" as="h4" fontSize="24px" fontWeight="bold" fontFamily="var(--bitmama-fonts-heading)">
                    Transfer funds
                  </Text>
                  <Text fontWeight="500">Transfer funds from your Earn wallet to your main wallet</Text>
                </Stack>

                <Stack mt="44px !important" as="form" onSubmit={handleSubmit}>
                  <FormControl mb="40px !important">
                    <InputLabel fontSize="16px">From</InputLabel>
                    <Select value={state?.from ?? ""} onChange={(e) => handleChange("from", e.target.value)}>
                      {!isFundingDisabled && <option value="main">Main</option>}
                      {!isWithdrawalDisabled && <option value="earn">Earn</option>}
                    </Select>
                  </FormControl>

                  <FormControl mb="40px !important">
                    <HStack justifyContent="space-between">
                      <InputLabel fontSize="16px">To</InputLabel>
                    </HStack>

                    <Select value={state?.to ?? ""} onChange={(e) => handleChange("to", e.target.value)}>
                      {/* <option value="main">Main</option>
                      <option value="earn">Earn</option> */}

                      {!isWithdrawalDisabled && <option value="main">Main</option>}
                      {!isFundingDisabled && <option value="earn">Earn</option>}
                    </Select>
                  </FormControl>

                  <FormControl mb="40px !important">
                    <HStack justifyContent="space-between">
                      <InputLabel fontSize="16px">Coin</InputLabel>
                    </HStack>

                    <Select disabled>
                      <option value={coin}>{toCoinLocale(coin)}</option>
                    </Select>
                  </FormControl>

                  <FormControl mb="40px !important">
                    <HStack justifyContent="space-between">
                      <InputLabel isLoading={isLoadingWallets || isLoadingBalance} fontSize="16px">
                        Amount
                      </InputLabel>
                    </HStack>
                    <Input
                      mb="10px"
                      type="number"
                      isInvalid={isInsufficientBalance}
                      placeholder="Enter Amount"
                      value={state?.amount ?? ""}
                      onChange={(e) => set({ amount: e.target.value })}
                    />

                    {isInsufficientBalance && (
                      <FormHelperText fontWeight="500" color="error">
                        Insufficient Balance
                      </FormHelperText>
                    )}
                    {coin && <FormInfo info="Available Balance: " description={toPrecision(+(balance ?? 0), coin)} />}
                  </FormControl>

                  <VStack mt="46px !important">
                    <Button
                      maxW="320px"
                      fontFamily="var(--bitmama-fonts-heading)"
                      isLoading={isLoading || isFunding}
                      type="submit"
                      disabled={isDisabled}
                    >
                      Submit
                    </Button>
                  </VStack>
                </Stack>
              </Box>
            </SidePageProvider>
          </Box>
        </VStack>

        <SubmitPinModal
          isOpen={isOpen("pin")}
          onClose={close("pin")}
          onPin={(pin) => set({ pin })}
          onSubmit={() => handleTransfer()}
          onComplete={(pin) => handleTransfer(pin)}
        />

        <SuccessModal isOpen={isOpen("success")} onClose={close("success")} onContinue={handleSuccess} message={successMessage} />
      </MainLayoutContainer>
    </PageMotion>
  );
}
