import { Box, BoxProps, Button, ButtonProps, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { dataURLtoFile, when } from "utils";

import toLower from "lodash/toLower";

const videoConstraints = {
  width: 600,
  height: 600,
  facingMode: "user",
};

interface PhotoCaptureProps extends BoxProps {
  filename: string;
  onCapture?: (file: File | null, recapture?: boolean) => void;
  _capture?: ButtonProps;
  _upload?: ButtonProps;
}

export function PhotoCapture(props: PhotoCaptureProps) {
  const { filename, onCapture, _capture, _upload, ...xprops } = props;
  const [preview, setPreview] = useState<string | null>(null);

  const webcamRef = useRef<any>(null);

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    // console.log("Picture", pictureSrc);
    const decodedFile = dataURLtoFile(pictureSrc, toLower(filename));
    setPreview(pictureSrc);
    !!onCapture && onCapture(decodedFile, false);
  }, [onCapture, setPreview, filename]);

  const recapture = () => {
    setPreview(null);
    !!onCapture && onCapture(null, true);
  };

  return (
    <Box w="100%" id="photoCapture" border="1px solid transparent" borderColor="#A1A3A2" borderRadius="20px" p="28px" {...xprops}>
      <VStack maxW="300px" w="100%" m="0 auto">
        <Text fontSize="sm" fontWeight="600" textAlign="center">
          Kindly look straight into the camera, and then click the capture button below to take a selfie
        </Text>

        <Box boxSize="160px" borderRadius="50%" my="28px !important" bg="black" overflow="hidden">
          {when(
            !preview,
            <Webcam
              audio={false}
              height={600}
              ref={webcamRef}
              width={600}
              screenshotQuality={1}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />,
            <Box as={"img"} src={preview!} alt="capture preview" objectFit="contain" />
          )}
        </Box>

        <HStack gridGap="20px">
          <Button
            fontSize="sm"
            minW="unset"
            minH="38px"
            w="100%"
            bg="secondary.400"
            shadow="2xl"
            fontFamily="var(--bitmama-fonts-heading)"
            textTransform="unset"
            onClick={() =>
              when(
                !preview,
                () => capture(),
                () => recapture()
              )()
            }
            // isDisabled={!!preview}
            {..._capture}
          >
            {when(!preview, "Capture", "Re-capture")}
          </Button>
          <Button
            fontSize="sm"
            variant="outline"
            minW="unset"
            minH="38px"
            w="100%"
            border="1px solid transparent"
            borderColor="primary.default"
            fontFamily="var(--bitmama-fonts-heading)"
            {..._upload}
          >
            Upload
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
