import { useMemo, useState } from "react";
import { Box, Button, FormControl, Heading, HStack, Stack, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useChangePasswordMutation } from "apis";
import { InputLabel, PasswordInput } from "components";
import { useIsPasswordValid } from "hooks";
import { SuccessModal } from "ui";

export default function ChangePassword(props: any) {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [state, setState] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [_changePassword, { isLoading }] = useChangePasswordMutation();

  const isPasswordValid = useIsPasswordValid(state.password);

  const passwordMatch = state.password === state.confirmPassword;

  const isValidLength = useMemo(() => !!state?.password && state?.password?.length >= 8, [state?.password]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { confirmPassword, oldPassword, password } = state;
    try {
      const response: any = await _changePassword({
        confirmPassword,
        oldPassword,
        password,
      });

      if (response.data) {
        // toast({
        //   position: "bottom-right",
        //   title: "Success",
        //   description: response.data?.message,
        //   status: "success",
        //   duration: 9000,
        //   isClosable: true,
        // });
        onOpen();

        navigate("/account/profile?tab=security");
      }
    } catch (error: any) {
      toast({
        position: "bottom-right",
        title: "Error",
        description: error?.error.confirmPassword || error?.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={{ base: "0", "3sm": "6px" }}>
      <HStack mb="46px">
        <Heading as="h6" fontSize="md" color="secondary.400">
          Change Password
        </Heading>
      </HStack>

      <Stack as="form" onSubmit={handleSubmit}>
        <FormControl mb="20px !important">
          <InputLabel htmlFor="oldPassword">Current Password</InputLabel>
          <PasswordInput isRequired id="oldPassword" name="oldPassword" onChange={handleChange} />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel htmlFor="password">New Password</InputLabel>
          <PasswordInput isRequired id="password" name="password" onChange={handleChange} />

          <Box mt="10px">
            {!isValidLength && (
              <Text color="error" fontSize="14px">
                Password must have at least a minimum length of 8
              </Text>
            )}
            {!isPasswordValid() && (
              <Text color="error" fontSize="14px">
                Password must have at least one uppercase letter, number and a special character
              </Text>
            )}
          </Box>
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
          <PasswordInput isRequired id="confirmPassword" name="confirmPassword" onChange={handleChange} />

          {!passwordMatch && state.confirmPassword.length >= 8 && (
            <Text mt="10px" color="error" fontSize="14px">
              Passwords do not match
            </Text>
          )}
        </FormControl>

        <VStack mt="40px !important">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={
              state.oldPassword.length < 5 ||
              state.password.length < 5 ||
              state.confirmPassword.length < 5 ||
              isLoading ||
              !isPasswordValid() ||
              !passwordMatch
            }
            minW={{ base: "100%", "4sm": "400px" }}
          >
            Update
          </Button>

          <SuccessModal isOpen={isOpen} onClose={onClose} message="Your Password has been updated" />
        </VStack>
      </Stack>
    </Box>
  );
}
