import { Box, Button, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { Icon, MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";

import { StakingFeature } from "ui";

interface StakingPageProps extends PageProps {}

export default function StakingPage(props: StakingPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion key="Staking">
      <Topbar pageTitle="Earn" {...props} />

      <MainLayoutContainer pb="60px">
        <VStack alignItems="flex-start">
          <Box>
            <SidePageProvider>
              <Box mb="42px">
                <Button
                  size="sm"
                  minW="fit-content"
                  maxW="fit-content"
                  variant="link"
                  leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                  onClick={goBack}
                  px="2px !important"
                >
                  Back
                </Button>

                <Box>
                  <Text mt="12px" as="h4" fontSize="24px" fontWeight="bold">
                    Earn
                  </Text>
                  <Text fontSize="14px">
                    With Earn feature, you can lock your Crypto Coins over a duration of time and get good interests
                  </Text>
                </Box>
              </Box>

              <StakingFeature />
            </SidePageProvider>
          </Box>
        </VStack>
      </MainLayoutContainer>
    </PageMotion>
  );
}
