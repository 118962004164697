import { Box, TabPanel, Tabs, Heading, Text, Select, useColorModeValue, Button } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Icon, TabPanels, Topbar } from "components";
import { PageProps } from "interfaces";
import { useMemo, useState } from "react";
import { CancelledPanel, PendingPanel, CompletedPanel, DisputePanel } from "ui";
import { useColor } from "hooks";
// import AdvertisementsPanel from "ui/P2P/Panels/Advertisements";

interface MyTradesPanelProps extends PageProps {}

// interface MyTabProps extends TabProps {
//   tabName: "pending" | "cancelled" | "completed" | "advertisements";
// }

export default function MyTradesPanel(props: MyTradesPanelProps) {
  const location = useLocation();
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const searchParams = useMemo(() => new URLSearchParams(location?.search as string), [location?.search]);

  const tabMap = {
    pending: 0,
    indispute: 1,
    cancelled: 2,
    completed: 3,
    // advertisements: 4,
  };

  const tab = useMemo(() => searchParams.get("tab")?.split(":")[1], [searchParams]);

  const [tabIndex, changeTab] = useState((tabMap as any)[tab as string] ?? 0);
  const [presentTab, setTab] = useState(tab);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", `myTrades:${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleSelect = (e: any) => {
    const index = e.target.selectedIndex;
    changeTab(index);
    setTab(e.target.value);
    searchParams.set("tab", `myTrades:${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  const cardBg = useColorModeValue("#FFF", "#1E1E1E8f");
  const color = useColor();
  const shadow = useColorModeValue("0px 2px 8px rgba(229, 229, 229, 0.47)", "0");
  return (
    <>
      <Topbar pageTitle="P2P" {...props} />

      <Box mt="45px" px={{ sm: "20px", "3sm": "40px", md: "40px" }}>
        <Button
          size="sm"
          minW="fit-content"
          maxW="fit-content"
          variant="link"
          leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
          onClick={() => navigate(-1)}
          px="0px !important"
        >
          Back
        </Button>
        <Heading as="h4" fontSize="24px" fontWeight="700" color={color("#041815", "white")}>
          Trade History
        </Heading>

        <Tabs py="32px" variant="ghost" index={tabIndex} onChange={handleTabChange}>
          {/* <TabList justifyContent="flex-start" gridGap="40px" mt="30px">
            <MyTab tabName="pending">Pending Trades</MyTab>
            <MyTab tabName="cancelled">Cancelled Trades</MyTab>
            <MyTab tabName="completed">Completed Trades</MyTab>
          <MyTab tabName="advertisements">Your Advertisements</MyTab>
          </TabList> */}
          <Text color={color("grey.default", "white")} fontWeight="500" mb="5px">
            Filter
          </Text>
          <Select
            onChange={handleSelect}
            fontWeight="600"
            width="360px"
            bg={cardBg}
            borderRadius="5px"
            boxShadow={shadow}
            value={presentTab}
          >
            <option value="pending">Pending Trades</option>
            <option value="indispute">Dispute Trades</option>
            <option value="cancelled">Cancelled Trades</option>
            <option value="completed">Completed Trades</option>
            {/* <option value="myAds">My Advertisement</option> */}
          </Select>

          <TabPanels index={tabIndex} onChangeIndex={handleSelect}>
            <TabPanel px={["2", "0", "0", "1"]}>
              <PendingPanel />
            </TabPanel>
            <TabPanel px={["2", "0", "0", "1"]}>
              <DisputePanel />
            </TabPanel>
            <TabPanel px={["0", "0", "0", "1"]}>
              <CancelledPanel />
            </TabPanel>
            <TabPanel px={["0", "0", "0", "1"]}>
              <CompletedPanel />
            </TabPanel>
            {/* <TabPanel px={["0", "0", "0", "1"]}>
              <AdvertisementsPanel />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}

// function MyTab(props: MyTabProps) {
//   const { tabName } = props;
//   // const selectedColor = useColorModeValue("primary.default", "secondary.default");

//   const info = useMemo(() => {
//     const map: Record<MyTabProps["tabName"], { icon: string; label: string; color: string; bg: string }> = {
//       pending: { icon: "timer", label: "Pending Trades", color: "#F4A51D", bg: "rgba(244, 165, 29, 0.1)" },
//       cancelled: { icon: "cancel", label: "Cancelled Trades", color: "#ED1C00", bg: "rgba(237, 28, 0, 0.1)" },
//       completed: { icon: "lockCheck", label: "Completed Trades", color: "#6FBE45", bg: "rgba(111, 190, 69, 0.1)" },
//       advertisements: { icon: "documentDelite", label: "Your Advertisements", color: "#31B7A9", bg: "rgba(49, 183, 169, 0.1)" },
//     };

//     return map[tabName];
//   }, [tabName]);
//   return (
//     <Tab
//       p="16px 30px"
//       fontSize="18px"
//       color="grey.300"
//       fontWeight="500"
//       borderRadius="6px"
//       //   fontFamily="var(--bitmama-fonts-heading)"
//       willChange="auto"
//       border="1px solid transparent"
//       borderColor={info.color}
//       transition="all .4s cubic-bezier(0.4, 0, 0.2, 1)"
//       _selected={{
//         fontSize: "24px",
//         color: info.color,
//         bg: info.bg,
//         transition: "all .4s cubic-bezier(0.4, 0, 0.2, 1)",
//         borderColor: "transparent",
//       }}
//       {...props}
//     >
//       <HStack>
//         <Icon type={info.icon as any} />
//         <Text fontSize="14px" fontWeight="500" color={info.color}>
//           {info.label}
//         </Text>
//       </HStack>
//     </Tab>
//   );
// }
