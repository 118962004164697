import { Box, HStack, Text } from "@chakra-ui/react";
import { EarningsPatternSVG } from "assets";
import CircleLoader from "components/Loader/CircularLoader";
import FadeInOut from "components/Motion/FadeInOut";
import { SupportedCoinType, useCoin } from "hooks";
import { toCoinLocale, toPrecision, when } from "utils";

interface StakingBalanceProps {
  balance: number;
  coin: SupportedCoinType;
  isLoading?: boolean;
}

export default function StakingBalance(props: StakingBalanceProps) {
  const { isLoading, balance, coin } = props;
  const { icon } = useCoin(coin);

  return (
    <Box p="26px 24px" borderRadius="20px" w="100%" minH="160px" bg="primary.900" pos="relative" overflow="hidden">
      <HStack>
        <Box as={icon} boxSize="32px" />
        <Text fontFamily="var(--bitmama-fonts-heading)" fontSize="16px" color="white">
          Staked Amount
        </Text>
      </HStack>

      <HStack mt="36px" color="white" alignItems="flex-end">
        <Text fontSize="52px" fontWeight="700" lineHeight="40px">
          {when(balance <= 0, "0.00", toPrecision(balance, coin, false))}
        </Text>
        <Text>{toCoinLocale(coin)}</Text>
      </HStack>

      <Box as={EarningsPatternSVG} pos="absolute" top="0" right="0" />
      <FadeInOut key={`wallet-${when(!!isLoading, "loading", "loaded")}`}>
        {!!isLoading && <CircleLoader size="22px" pos="absolute" top="14px" right="163.5px" />}
      </FadeInOut>
    </Box>
  );
}
