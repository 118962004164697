import { Box, BoxProps, Stack, Text, Flex, Center } from "@chakra-ui/react";
import { BankInfoCardPatternSVG, DeleteSVG } from "assets";

interface BankInfoCardProps extends BoxProps {
  bankName?: string;
  accountName?: string;
  accountNumber?: string;
  onDelete?: () => void;
  onDeleteLoading?: boolean;
}

export default function BankInfoCard(props: BankInfoCardProps) {
  const { bankName, accountName, accountNumber, onDelete, onDeleteLoading, ...xprops } = props;

  return (
    <Box
      maxW="370px"
      w="100%"
      minH="208px"
      pos="relative"
      borderRadius="34px"
      overflow="hidden"
      p="32px 36px"
      bg="linear-gradient(70.66deg, #0D4740 23.46%, #1B9B48 84.63%)"
      {...xprops}
    >
      <Stack color="white" fontFamily="var(--bitmama-fonts-heading)" pos="relative" zIndex="6" alignItems="space-between">
        <Flex justifyContent="space-between">
          <Text>{bankName ?? "Access Bank"}</Text>

          {onDeleteLoading ? (
            ""
          ) : (
            <Center
              onClick={onDelete}
              transition="all 300ms ease-in-out"
              _hover={{
                boxShadow: "0px 0px 0px 1px #FBFBFB",
              }}
              alignItems="center"
              cursor="pointer"
              bgColor="#FBFBFB"
              width="28px"
              height="28px"
              borderRadius="full"
            >
              <DeleteSVG />
            </Center>
          )}
        </Flex>
        <Box mt="48px !important">
          <Text>{accountName ?? "Michael Okon"}</Text>
          <Text mt="8px">{accountNumber}</Text>
        </Box>
      </Stack>
      <Box as={BankInfoCardPatternSVG} pos="absolute" top="0" left="0" />
    </Box>
  );
}
