import usePartialState from "./usePartialState";

interface IState<T> {
  /** The last modal name that was set either open or close */
  modal: T;
  /** Shows if the last set modal is open */
  isOpen: boolean;
}

interface IReturnTypes<T> {
  onOpen: (modalName: T) => void;
  onClose: (modalName: T) => void;
  open: (modalName: T) => () => void;
  close: (modalName: T) => () => void;
  isOpen: (modalName: T) => boolean;

  /** @InternalState Indicates if any modal is open */
  _is_open: boolean;

  /** The last modal name that was set either open or close */
  modal?: T;
}

export default function useDisclosures<T>(): IReturnTypes<T> {
  const [state, set] = usePartialState<IState<T>>({ isOpen: false });

  const open = (modalName: T) => () => {
    set({ modal: modalName, isOpen: true });
  };

  // console.log("Disclosures", { state });

  const close = (modalName: T) => () => {
    set({ modal: modalName, isOpen: false });
  };

  const onOpen = (modalName: T) => open(modalName)();
  const onClose = (modalName: T) => close(modalName)();

  const isOpen = (modalName: T) => state?.modal === modalName && state?.isOpen!;

  return { onOpen, onClose, isOpen, open, close, modal: state?.modal, _is_open: state?.isOpen! };
}
