import { Box, Button, Heading, TabList, TabPanel, Tabs, useColorModeValue } from "@chakra-ui/react";
import { navigate, useLocation, useNavigate } from "@reach/router";
import { Icon, MainLayoutContainer, PageMotion, Steps, Tab, TabPanels } from "components";
import { PageProps } from "interfaces";
import { useState } from "react";
import { ProfilePersonalPanel, ProfileSecurityPanel } from "ui";
import { NameChange } from "Pages";

interface ProfileProps extends PageProps {}

export default function Profile(props: ProfileProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <PageMotion subpage key="AccountProfile" overflowY="hidden" maxH="initial">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <Heading mt="10px" as="h5" fontSize="xl">
            Profile Info
          </Heading>
        </Box>

        <Steps hideIndicator>
          <ProfileTabs {...props} />
          <NameChange {...props} />
        </Steps>
      </MainLayoutContainer>
    </PageMotion>
  );
}

function ProfileTabs(props: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    personal: 0,
    security: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", Object.keys(tabMap)[index]);
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <Box px="8px">
      <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange} mt="18px !important">
        <TabList borderRadius="8px">
          <Tab>Personal Details</Tab>
          <Tab>Security Details</Tab>
        </TabList>

        <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
          <TabPanel px={{ base: "0" }}>
            <ProfilePersonalPanel {...props} />
          </TabPanel>

          <TabPanel px={{ base: "0" }}>
            <ProfileSecurityPanel {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
