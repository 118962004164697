import { Box, BoxProps, Text, TextProps, useColorModeValue, VStack } from "@chakra-ui/react";
import {
  EmptyGamificationChallengeIllustration,
  EmptyGamificationIllustration,
  EmptyNotificationIllustration,
  EmptyTransactionIllustration,
} from "assets";
import { useColor } from "hooks";
import { useMemo } from "react";
import { when } from "utils";

interface EmptyCrateProps extends BoxProps {
  description?: string;
  type: "transaction" | "wallet" | "earn" | "notification" | "p2p" | "greenbox" | "achievement" | "achievement_challenge";
  midfix?: string;
  _desc?: TextProps;
  title?: string;
}

export default function EmptyCrate(props: EmptyCrateProps) {
  const { type = "transaction", midfix, description, title, _desc, children, ...xprops } = props;

  const mode_color = useColor();
  // const bg = useColorModeValue("white", "dark.cardBg");
  const color = useColorModeValue("grey.500", "grey.150");

  const defaultDescription = useMemo(() => {
    const map: Record<EmptyCrateProps["type"], string> = {
      transaction: "You are yet to complete a Transaction",
      wallet: "No wallet",
      earn: `You don't have a ${when(!!midfix, midfix, "")} subscription`,
      notification: `No Notifications`,
      p2p: "No listings",
      greenbox: "No Greenbox created yet",
      achievement: "You don’t have any rewards yet. Complete Challenges to claim rewards.",
      achievement_challenge: "We will notify you once this feature is ready.",
    };

    return map[type];
  }, [type, midfix]);

  const bg = useMemo(() => {
    const map: Record<EmptyCrateProps["type"] | string, string> = {
      [type]: mode_color("white", "dark.cardBg"),
      achievement: mode_color("#f8f9f5", "#f8f9f529"),
    };
    return map[type];
  }, [type, mode_color]);

  const Illustration = useMemo(() => {
    const map: Record<
      string,
      React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    > = {
      transaction: EmptyTransactionIllustration,
      notification: EmptyNotificationIllustration,
      p2p: EmptyTransactionIllustration,
      greenbox: EmptyTransactionIllustration,
      achievement: EmptyGamificationIllustration,
      achievement_challenge: EmptyGamificationChallengeIllustration,
      default: EmptyTransactionIllustration,
    };

    return map[type ?? "default"];
  }, [type]);

  return (
    <VStack mt="0" py="60px" {...xprops}>
      <VStack maxW="380px">
        <Box as={Illustration} color={bg} />
        {!!title && (
          <Text mt="20px !important" fontSize="20px" fontWeight="600" color="black" textAlign="center">
            {title}
          </Text>
        )}
        <Text fontSize="16px" fontWeight="600" color={color} textAlign="center" {..._desc}>
          {description ?? defaultDescription}
        </Text>

        {children && children}
      </VStack>
    </VStack>
  );
}
