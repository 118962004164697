import { uniq } from "lodash";
import Emitter, { EventTypes } from "./Emitter";

export class EventManager extends Map<keyof EventTypes, (arg: any) => void> {
  static store = new EventManager();

  static addEvent<K extends keyof EventTypes>(key: K, func: (arg: EventTypes[K]) => void): boolean {
    if (this.store.has(key)) this.removeEvent(key);
    const em = this.store.set(key, func);

    if (em) {
      //   this.subscribeEvents();
      return true;
    }
    return false;
  }

  static removeEvent<K extends keyof EventTypes>(key: K) {
    if (this.store.has(key)) {
      Emitter.off(key, () => {});
      return this.store.delete(key);
    }
    return false;
  }

  static subscribeEvents() {
    if (this.store.size < 1) return;
    const keys: string[] = [];

    this.store.forEach((func, key) => {
      keys.push(key);
      Emitter.on(key, func);
    });

    console.log(`\x1b[33m[EventManager::Store]\x1b[0m \x1b[35m%s`, uniq(keys), "listeners mounted");
    // console.log(`\x1b[33m[EventManager::Store]\x1b[0m \x1b[35m%s\x1b[0m`, "", "listeners mounted");
    // console.table(
    //   keys.map((key) => ({ event: key })),
    //   ["event"]
    // );
  }

  static unsubscribeEvents() {
    const keys: string[] = [];
    this.store.forEach((_, key) => {
      keys.push(key);
      Emitter.off(key, () => {});
    });

    console.log(`\x1b[33m[EventManager::Store]\x1b[0m \x1b[35m%s\x1b[0m`, uniq(keys), "listeners will be removed");
    // console.log(`\x1b[33m[EventManager::Store]\x1b[0m \x1b[35m%s\x1b[0m`, "listeners will be removed");
    // console.table(
    //   keys.map((key) => ({ event: key })),
    //   ["event"]
    // );
    this.store.clear();
  }
}
