import { Box } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";

import toLower from "lodash/toLower";
import { P2PPendingTradeCounterPartRo } from "interfaces";
import { TransactionHub } from "../TransactionHub";
import { LeaveReview } from "../LeaveReview";
import { ScreenMapperProps } from "..";
import { HeaderInfo } from "../HeaderInfo";

export function OrderCompletedScreen(props: ScreenMapperProps) {
  const { counterParties, contractState, user, adsType } = props;
  // const { onCopy } = useCopy(data?.id ?? "");
  //   const [id, frozenRef] = (ref ?? "").split("-") ?? [];

  const tradeOwner = useMemo(() => (counterParties ?? [])?.find((cp) => toLower(cp.role) === "tradeowner"), [counterParties]);
  const trader = useMemo(() => (counterParties ?? [])?.find((cp) => toLower(cp.role) === "trader"), [counterParties]);

  const getStatus = useCallback(
    (role: string) => {
      const map: Record<string, P2PPendingTradeCounterPartRo | undefined> = { trader, tradeowner: tradeOwner };
      return `${contractState}&${map[role]?.obligationStatus}`;
    },
    [trader, tradeOwner, contractState]
  );

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  console.log("Screen Mapper", getStatus("trader"), appropriateSessionOwner);

  const mapKey = useMemo(
    () => `${appropriateSessionOwner?.role}&${adsType}&${getStatus(appropriateSessionOwner?.role ?? "trader")}`,
    [appropriateSessionOwner, adsType, getStatus]
  );

  return (
    <Box>
      <HeaderInfo mapKey={mapKey} {...props} />

      <TransactionHub mapKey={mapKey} {...props} />

      <LeaveReview {...(props as any)} />
    </Box>
  );
}
