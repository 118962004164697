import { Box } from "@chakra-ui/react";
import { Steps, useModalView as useSteps } from "components";
import { Level2FormState, PageProps } from "interfaces";

import StepOne from "./StepOne";
import { StepThreeNigerian, StepThreeOthers } from "./StepThree";
import { StepTwoNigerian, StepTwoOthers } from "./StepTwo";
import { useIDType, useSelector } from "hooks";
import React, { useCallback, useMemo, useState } from "react";
import { selectUser } from "store/slices";
import { when } from "utils";

import toLower from "lodash/toLower";

interface Level2VerificationFormProps extends PageProps {
  refetchStatus?: () => void;
  onClose?: () => void;
}

export default function Level2VerificationForm(props: Level2VerificationFormProps) {
  const { refetchStatus, onClose } = props;
  const { profile } = useSelector(selectUser);
  const { idTypes } = useIDType(profile!);

  const { setCurrentView } = useSteps();

  const isNigerian = useMemo(() => when(!!profile?.country, toLower(profile?.country) === "nigeria", false), [profile]);

  const [formState, setFormState] = useState<Level2FormState>({
    category: "",
    dateOfBirth: "",
    docLink: "",
    docName: "",
    docType: "",
    type: "",
    individualLink: "",
    nameOnId: "",
    // ninNumber: "",
    kycLevel: "two",
    status: "loading",
    supportingDocLink: null,
    supportingDocType: null,
    idFile: null,
    backPageIdFile: null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: String(value).trim(),
    });
  };

  const set = useCallback(
    (update: Partial<Level2FormState>) => setFormState({ ...formState, ...update }),
    [setFormState, formState]
  );

  // console.log("ID types", idTypes);

  return (
    <Box mt="28px">
      <Steps hideIndicator>
        <StepOne onClose={onClose} set={set} idTypes={idTypes as any} handleChange={handleChange} formState={formState} />

        {/* 
        /////////////////
        /// Step Two 
        */}
        {isNigerian && <StepTwoNigerian set={set} handleChange={handleChange} formState={formState} />}
        {!isNigerian && <StepTwoOthers set={set} handleChange={handleChange} formState={formState} />}

        {/* 
        /////////////////
        /// Step Three 
        */}
        {isNigerian && (
          <StepThreeNigerian
            set={set}
            handleChange={handleChange}
            formState={formState}
            onClose={() => setCurrentView(0)}
            refetchStatus={refetchStatus}
          />
        )}
        {!isNigerian && (
          <StepThreeOthers
            set={set}
            handleChange={handleChange}
            formState={formState}
            onClose={() => setCurrentView(0)}
            refetchStatus={refetchStatus}
          />
        )}
        {/* <SuccessView onClose={() => hasPrev && onPrev()} /> */}
      </Steps>
    </Box>
  );
}
