import { useGetAppConfigQuery } from "apis";
import { useCallback, useMemo } from "react";
import { useCharlaChat } from "./useCharlaChat";
import { useEbanqo } from "./useEbanqo";
import { useIntercomChat } from "./useIntercom";
import { useChatwoot } from "./useChatwoot";
import { useAppConfig } from "contexts/appconfig.context";

// const chat_types = ['ebanqo', 'intercom', 'charla'] as const;

export function useSupport() {
  const appConfig = useGetAppConfigQuery(null, { skip: false });
  console.log({ appConfig });

  const { appfigs = {} } = useAppConfig();

  const { bootWithProps: ebwp, boot: ebt } = useEbanqo();
  const { bootWithProps: cbwp, boot: cbt } = useCharlaChat();
  const { bootWithProps: ibwp, boot: ibt } = useIntercomChat();
  const { bootWithProps: cwbwp, boot: cwbt } = useChatwoot();

  const chatType = useMemo(() => {
    const csChat = appfigs?.generalConfig?.csChat ?? "intercom";
    // if (appfigs?.generalConfig?.enableChatbotEnrollment || isTesting) return csChat;
    // if (appfigs?.generalConfig?.enableChatbotEnrollment || isTesting) return "ebanqo";
    // if (appfigs?.generalConfig?.enableChatbotEnrollment || isTesting) return "intercom";
    // return "intercom";
    return csChat;
    // return "chatwoot";
  }, [appfigs?.generalConfig]);

  const bootWithProps = useCallback(() => {
    if (chatType === "intercom") return ibwp();
    if (chatType === "charla") return cbwp();
    if (chatType === "ebanqo") return ebwp();
    if (chatType === "chatwoot") return cwbwp();
    return ibwp();
  }, [chatType, ebwp, ibwp, cbwp, cwbwp]);

  const boot = useCallback(
    (props?: Record<string, any>) => {
      if (chatType === "intercom") return ibt(props);
      if (chatType === "charla") return cbt(props);
      if (chatType === "ebanqo") return ebt(props);
      if (chatType === "chatwoot") return cwbt(props);
      return ibt(props);
    },
    [chatType, ebt, ibt, cbt, cwbt]
  );

  return { bootWithProps, boot };
}
