import { Button, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { ShowCustomerSupport } from "components";
import configs from "config";
import { useDispatch } from "hooks";
import { IntercomProvider } from "react-use-intercom";
import { getProfile } from "store/slices";
import { Reset2FAModal } from "ui";

export default function Forgot2FA() {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const logoColor = useColorModeValue("primary.default", "secondary.500");

  const handleOpen = () => {
    // setStep("");
    onOpen();
    dispatch(getProfile());
  };

  return (
    <IntercomProvider appId={configs.INTERCOM_TOKEN}>
      <Button
        minH="44px"
        color={logoColor}
        bg="rgba(49, 183, 169, 0.2)"
        variant="max"
        p="11.5px 12px"
        minW="fit-content"
        onClick={handleOpen}
      >
        Reset 2FA
      </Button>

      <Reset2FAModal isOpen={isOpen} onClose={onClose} />
      <ShowCustomerSupport />
    </IntercomProvider>
  );
}
