import { Box, Button, Center, Text, Heading } from "@chakra-ui/react";
import { useModalView } from "components";
import { useColor } from "hooks";

interface StepProps {
  onClose: () => void;
  onContinue: () => void;
}
const StepOne = ({ onClose, onContinue }: StepProps) => {
  const { onNext } = useModalView();

  const color = useColor();

  const handleClose = () => {
    onClose();
    onContinue();
  };

  return (
    <Box>
      <Heading textAlign="center" color={color("#303131", "white")} fontSize={{ base: "24px", md: "32px" }} fontWeight="600">
        Card label
      </Heading>

      <Text
        margin={{ base: "18px 0", md: "24px auto 32px" }}
        fontSize="16px"
        color={color("#303131", "white")}
        lineHeight="21.28px"
        letterSpacing="-0.5px"
        textAlign="center"
        maxWidth="290px"
        fontWeight="500"
        fontFamily="var(--bitmama-fonts-heading)"
      >
        Would you like to add a card label to your card being created. This will enable you easily identify your cards
      </Text>

      <Center gridGap="16px" mt={{ base: "8px" }}>
        <Button
          color="#0D4740"
          minW="159px"
          border="1px solid #0D4740"
          bgColor="#fff"
          _hover={{ bg: "initial" }}
          onClick={handleClose}
          minH={{ base: "56px" }}
          _focusVisible={{
            boxShadow: "none",
          }}
          _focus={{
            boxShadow: "none",
          }}
        >
          No
        </Button>

        <Button onClick={onNext} minW="159px" minH={{ base: "56px" }}>
          Yes
        </Button>
      </Center>
    </Box>
  );
};

export default StepOne;
