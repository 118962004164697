import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { Link } from "components";
import CardAgreement from "ui/Cards/Modals/CardAgreement";

const PolicyTerms = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box mt="15px !important">
      <Text textAlign="center" fontWeight="500" fontSize="14px">
        By using Bitmama, you agree to our{" "}
        <a href="https://bitmama.io/terms" target="_blank" rel="noreferrer">
          <Text as="span" color="secondary.400" textDecoration="underline">
            Terms of service
          </Text>
        </a>
        ,{"  "}
        <br />
        <a href="https://bitmama.io/privacy" target="_blank" rel="noreferrer">
          <Text as="span" color="secondary.400" textDecoration="underline">
            Privacy Policy
          </Text>
        </a>
        {"  "}
        and {"   "}
        <Link to="" onClick={onOpen}>
          <Text as="span" color="secondary.400" textDecoration="underline">
            Card Holder Agreement.
          </Text>
        </Link>
      </Text>

      <CardAgreement isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default PolicyTerms;
