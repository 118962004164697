import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  useToast,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { Icon, InputLabel, useModalView } from "components";
import { PageProps } from "interfaces";
import { useRequestNameChangeMutation } from "apis";
import { navigate } from "@reach/router";

const NameChange = ({ user }: PageProps) => {
  const toast = useToast();
  const { setCurrentView } = useModalView();

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    reason: "",
  });

  const handleChange = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const [_requestNameChange, { isLoading }] = useRequestNameChangeMutation();

  const handleSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { firstName, lastName, reason } = state;

    try {
      const response: any = await _requestNameChange({
        lastName,
        firstName,
        reason,
      });

      if (response.data) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: response?.data?.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        navigate("/account/profile");
      }
    } catch (error: any) {
      console.log(error, "error");
      toast({
        position: "bottom-right",
        title: "Error",
        description: error?.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  // reset the view when component unmounts
  useEffect(() => {
    return () => {
      setCurrentView(0);
    };
  }, [setCurrentView]);

  return (
    <Box my="40px">
      <HStack mb="46px">
        <IconButton
          size="xs"
          minW="fit-content"
          maxW="fit-content"
          minH="fit-content"
          maxH="fit-content"
          p="0 !important"
          bg="transparent"
          variant="transparent"
          aria-label="back button"
          icon={<Icon boxSize="20px" type="circleLeftArrow" />}
          onClick={() => setCurrentView(0)}
        />
        <Heading as="h6" fontSize="sm" color="secondary.400">
          Name Change
        </Heading>
      </HStack>

      <Stack as="form" onSubmit={handleSubmit}>
        <FormControl mb="20px !important">
          <InputLabel>Full Name</InputLabel>
          <Input defaultValue={`${user?.firstName} ${user?.lastName} `} disabled />
        </FormControl>

        <Text mb="1rem !important">New Name</Text>

        <FormControl mb="20px !important">
          <InputLabel htmlFor="firstName">First Name</InputLabel>
          <Input isRequired placeholder="Enter First Name" id="firstName" name="firstName" onChange={handleChange} />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel htmlFor="lastName">Last Name</InputLabel>
          <Input isRequired placeholder="Enter Last Name" id="lastName" name="lastName" onChange={handleChange} />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel htmlFor="reason">Reason for Name Change</InputLabel>
          <Textarea isRequired as={Input} placeholder="Enter Details" id="reason" name="reason" onChange={handleChange} />
        </FormControl>

        <VStack mt="40px !important">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!(state.firstName.length >= 2) || !(state.lastName.length >= 2) || !(state.reason.length >= 3) || isLoading}
          >
            Update
          </Button>
        </VStack>
      </Stack>
    </Box>
  );
};

export default NameChange;
