import { Box, BoxProps, HStack, useColorModeValue, useRadio, useRadioGroup } from "@chakra-ui/react";
import configs, { isTesting } from "config";
import { useMemo } from "react";
import { toCoinLocale } from "utils";

type VariantType = "solid" | "transparent" | "outline";

interface RadioCoinProps extends BoxProps {
  variant?: VariantType;
}

interface PeerCoinsProps extends BoxProps {
  value?: string;
  variant?: VariantType;
  onPeerChange?: (value: string) => void;
  _coin?: BoxProps;
}

export default function PeerCoins(props: PeerCoinsProps) {
  const { value, variant, onPeerChange, _coin, ...xprops } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "peer-to-peer-tokens",
    value: !!value ? value : isTesting ? "tbtc" : "btc",
    defaultValue: isTesting ? "tbtc" : "btc",
    onChange: (value) => {
      onPeerChange && onPeerChange(value);
    },
  });

  const group = getRootProps();

  return (
    <HStack overflowX="scroll" {...group} {...xprops}>
      {configs.supportedPeerCoins.map((coin) => {
        const radio = getRadioProps({ value: coin });
        return (
          <RadioCoin variant={variant} key={coin} {...(radio as any)} {..._coin}>
            {toCoinLocale(coin)}
          </RadioCoin>
        );
      })}
    </HStack>
  );
}

function RadioCoin(props: RadioCoinProps) {
  const { variant, ...xprops } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const color = useColorModeValue("inherit", "white");

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const styles = useMemo(() => {
    const map: Record<VariantType, BoxProps> = {
      solid: {
        _checked: {
          fontWeight: "bold",
          bg: "rgba(255, 255, 255, 0.1)",
          color: "#E8C229",
          transition: "all .2s cubic-bezier(0.4, 0, 0.2, 1)",
        },
      },
      outline: {
        borderRadius: 0,
        color: "grey.400",
        px: 4,
        _checked: {
          fontWeight: "bold",
          bg: "rgba(255, 255, 255, 0.1)",
          color: "primary.default",
          border: "1px solid transparent",
          borderBottomColor: "primary.default",
          transition: "all .2s cubic-bezier(0.4, 0, 0.2, 1)",
        },
      },
      transparent: {
        _checked: {
          fontWeight: "bold",
          bg: "transparent",
          color: "#64a743",
          transition: "all .2s cubic-bezier(0.4, 0, 0.2, 1)",
          borderBottom: "2px solid #64a743",
          borderRadius: "none",
        },
      },
    };

    return map[variant ?? "solid"];
  }, [variant]);

  return (
    <Box as="label" {...xprops}>
      <input {...input} />
      <Box
        {...checkbox}
        color={color}
        fontSize="14px"
        fontWeight="500"
        cursor="pointer"
        borderRadius="6px"
        willChange="auto"
        transition="all .2s cubic-bezier(0.4, 0, 0.2, 1)"
        // _focus={{
        //   boxShadow: "outline",
        // }}
        px={5}
        py={3}
        {...styles}
      >
        {props.children}
      </Box>
    </Box>
  );
}
