import { useCallback, useEffect, useRef } from "react";
import { uuid } from "utils";

const useInlineScript = (inlineScript: string, scriptAttrTag: string, skip: boolean) => {
  const loaded = useRef(false);
  const scriptId = useRef("");

  const injectScript = useCallback(
    (props?: { inlineScript?: string; id?: string }) => {
      let script: HTMLScriptElement | null = null;

      let existingInjectedScript = document.querySelector(`script[inline-attr='${scriptAttrTag}']`) as HTMLScriptElement;
      if (existingInjectedScript) {
        existingInjectedScript.innerHTML = props?.inlineScript || inlineScript;
        script = existingInjectedScript;
        scriptId.current = script.id;
        loaded.current = true;
      } else {
        scriptId.current = props?.id ?? uuid(15);
        const script = document.createElement("script");
        if (!loaded.current) {
          script.id = scriptId.current;
          script.setAttribute("inline-attr", scriptAttrTag);
          script.innerHTML = props?.inlineScript || inlineScript;
          document.body.appendChild(script);
          loaded.current = true;
        }
      }
      return script;
    },
    [inlineScript, scriptAttrTag]
  );

  useEffect(() => {
    if (skip === true) {
    } else {
      const script = injectScript();

      return () => {
        if (loaded.current && script) document.body.removeChild(script);
      };
    }
  }, [inlineScript, skip, scriptAttrTag, injectScript]);

  const removeScript = useCallback(() => {
    let scriptInjected = document.querySelector(`script#${scriptId}`);
    if (scriptInjected) {
      scriptInjected.parentNode?.removeChild(scriptInjected);
    }
  }, []);

  return {
    scriptId,
    injectScript,
    removeScript,
  };
};

export default useInlineScript;
