import { Stack, useColorModeValue } from "@chakra-ui/react";
import { useGetStakingsQuery } from "apis";
import {
  Card,
  EarnTransactionItem,
  EmptyCrate,
  FadeInOut,
  Group,
  Link,
  PageLoading,
  Title,
  TitleBar,
  TitleBarActions,
} from "components";
import { usePartialState } from "hooks";
import { isEmpty } from "lodash";
// import { isEmpty } from "lodash";
import { useMemo } from "react";
import { toQueryString, when } from "utils";

interface StakingTransactionsProps {
  coin: string;
}

export default function StakingTransactions(props: StakingTransactionsProps) {
  const { coin } = props;
  const bg = useColorModeValue("rgba(242, 244, 243, 0.5)", "transparent");

  const [queries] = usePartialState({
    page: 1,
    limit: 5,
    unit: coin,
    sortBy: "createdAt:desc",
    actionType: "staking_in,staking_out",
    // status: filter === "all" ? null : filter,
  });

  const { data: stakings, isLoading } = useGetStakingsQuery(toQueryString(queries));

  // const totalCount = useMemo(() => data?.totalCount, [data]);
  const transactions = useMemo(() => stakings?.records, [stakings]);

  console.log("Earnings Data", transactions);

  // const handlePageChange = (page: number) => {
  //   set({ page });
  // };

  return (
    <Group mt="0" w="100%">
      <TitleBar>
        <Title>Transactions</Title>

        <TitleBarActions>
          <Link to="#" fontSize="sm" fontWeight="600" textDecoration="underline" color="secondary.400">
            See All
          </Link>
        </TitleBarActions>
      </TitleBar>
      <Card w="100%" p="8px" overflow="hidden" bg={bg}>
        <FadeInOut key={`tx-list-${when(isLoading, "loading", "loaded")}`}>
          {isLoading && <PageLoading isLoading={isLoading} />}
          {!isLoading && (
            <Stack>
              {(transactions ?? []).map((tx, id) => (
                <EarnTransactionItem
                  mt="0 !important"
                  w="100%"
                  key={`transaction-${id}`}
                  unit={tx?.unit}
                  status={tx?.status as any}
                  value={tx?.value}
                  createdAt={tx?.createdAt}
                  description={tx?.description}
                />
              ))}
            </Stack>
          )}
          {!isLoading && isEmpty(transactions) && <EmptyCrate type="transaction" />}
        </FadeInOut>
      </Card>
    </Group>
  );
}
