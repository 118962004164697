import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  Input,
  Stack,
  useColorModeValue,
  useToast,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useAddBankMutation, useGetAirtimePlansQuery, useGetUserBankDetailsQuery } from "apis";
import { FormInfo, Icon, InputLabel, MainLayoutContainer, PageMotion, Select } from "components";
import { useBuiltInFn, useI18n, useRedebounce, useValidatePhoneNumber } from "hooks";
import { PageProps } from "interfaces";
import React, { useMemo, useRef, useState } from "react";
import { when } from "utils";

import isEqual from "lodash/isEqual";
import toLower from "lodash/toLower";
import { currencyMap } from "hooks/useCurrency";

interface AddBankProps extends PageProps {}

export default function AddBank(props: AddBankProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const { goBack } = useBuiltInFn();

  const { geti18nTitle } = useI18n();
  const title = useMemo(() => geti18nTitle("account.mobilemoney"), [geti18nTitle]);

  return (
    <PageMotion subpage key="AccountAddBank" overflowY="scroll">
      <MainLayoutContainer
        // px="140px"
        px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }}
        py="10px"
      >
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize={{ base: "18px", "3sm": "24px" }}>
              {title}
            </Heading>
          </HStack>
        </Box>

        <Form {...props} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

type StateType = Partial<{ network: string; bankAccountNumber: string; bankType: string; _bankType: string }>;

function Form(props: AddBankProps) {
  const { user } = props;
  const toast = useToast();

  const { geti18nTitle } = useI18n();

  const country = when(!!user, toLower(user?.country!), "nigeria");

  console.log("Country", country);

  const prevInput = useRef({});

  // const { data } = useGetUserBankDetailsQuery({});

  const { data: plans } = useGetAirtimePlansQuery(country ? country.toLowerCase() : "nigeria");
  const { data: userBanks, refetch } = useGetUserBankDetailsQuery({});
  const [_addMomoBank, { isLoading }] = useAddBankMutation();

  console.log("Plans", plans);

  const title = useMemo(() => geti18nTitle("account.mobilemoney"), [geti18nTitle]);
  const momos = useMemo(() => (userBanks?.bank ?? []).filter((bank) => !!bank?.type && bank?.type === "mobilemoney"), [userBanks]);

  const [state, setState] = useState<StateType>({
    bankType: "mobilemoney",
    _bankType: "mobilemoney",
  });

  const { validate, data: validationInfo, isWrongNetwork } = useValidatePhoneNumber(country);
  const debounce = useRedebounce();

  // console.clear();
  console.log("Validation Info", validationInfo);

  const alreadyExist = useMemo(
    () =>
      !!state?.bankAccountNumber &&
      (momos ?? []).findIndex(
        (momo) =>
          momo?.bankAccountNumber === state?.bankAccountNumber ||
          (state?.bankAccountNumber ?? "").includes(momo?.bankAccountNumber ?? "") ||
          (momo?.bankAccountNumber ?? "").includes(state?.bankAccountNumber ?? "")
      ) !== -1,
    [momos, state?.bankAccountNumber]
  );
  console.log("User Banks", momos, state);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleAddMomo = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    try {
      const response: any = await _addMomoBank({
        ...state,
        country: currencyMap[country!.toLowerCase()].iso2,
      });

      if (response.data) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        refetch();
        navigate("/account/momo");
      }
    } catch (error: any) {
      toast({
        position: "bottom-right",
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const isDisabled = useMemo(
    () =>
      !(state?.network && state?.bankAccountNumber) ||
      (!!state?.bankAccountNumber && state.bankAccountNumber.length < 5) ||
      isLoading ||
      alreadyExist ||
      isWrongNetwork,
    [state, isLoading, alreadyExist, isWrongNetwork]
  );

  useUpdateEffect(() => {
    console.log("Phone Number", state?.bankAccountNumber);
    if (
      !!state?.network &&
      !!state?.bankAccountNumber &&
      String(state?.bankAccountNumber).length >= 9 &&
      !isEqual(prevInput.current, { network: state?.network, phone: state?.bankAccountNumber })
    ) {
      // console.log("Initiated validation", {
      //   country,
      //   network: state?.network,
      //   phone: state?.bankAccountNumber,
      // });
      // validate({ country: countryInTwoLetterCode, network: state?.network!, phoneNumber: state?.bankAccountNumber! });
      debounce(
        () => validate({ network: toLower(state?.network!), phoneNumber: state?.bankAccountNumber! }),
        "validatePhone",
        500
      )();

      prevInput.current = { network: state?.network, phone: state?.bankAccountNumber };
    }
  }, [prevInput, state]);

  return (
    <Stack as="form" my="42px" onSubmit={handleAddMomo}>
      <HStack mb="36px">
        <Heading as="h6" fontSize="sm" color="secondary.400">
          Add {title}
        </Heading>
      </HStack>

      <FormControl mb="28px !important">
        <InputLabel htmlFor="network">Network Type</InputLabel>
        <Select placeholder="Select Network Type" name="network" id="network" onChange={handleChange}>
          {(plans ?? []).map(({ name, operatorId }) => (
            <option key={operatorId} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl mb="28px !important">
        <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>

        <Input
          type="number"
          isRequired
          placeholder="Enter Phone Number"
          id="phoneNumber"
          min={0}
          name="bankAccountNumber"
          onChange={handleChange}
        />

        {alreadyExist && (
          <FormInfo
            mt="10px"
            isInvalid={alreadyExist}
            info=""
            description="Phone number already exists"
            _description={{ ml: "0 !important" }}
          />
        )}

        {isWrongNetwork && (
          <FormInfo
            mt="10px"
            isInvalid={isWrongNetwork}
            info=""
            description="Wrong phone network"
            _description={{ ml: "0 !important" }}
          />
        )}
      </FormControl>

      <VStack mt="40px !important" fontFamily="var(--bitmama-fonts-heading)">
        <Button type="submit" disabled={isDisabled} isLoading={isLoading} minW={{ base: "100%", smx: "330px", "4sm": "400px" }}>
          Save {title}
        </Button>
      </VStack>
    </Stack>
  );
}
