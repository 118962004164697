import { Box } from "@chakra-ui/react";
import BottomBar from "components/MobileNavbar/MobileNavbar";

const Main = (props: any) => {
  return (
    <Box
      w="100%"
      pos="relative"
      left={props.is1024px ? "unset" : "var(--sideNavWidth)"}
      maxW={props.is1024px ? "100%" : "calc(100% - var(--sideNavWidth))"}
      minH="100vh"
      overflow-y="scroll"
    >
      {/* <Navbar /> */}

      <Box>{props.children}</Box>

      <>
        <BottomBar is1024px={props.is1024px} />
      </>
    </Box>
  );
};

export default Main;
