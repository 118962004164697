import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import orderBy from "lodash/orderBy";
import { fetchAllTrades, fetchCompletedTrades, fetchTradesByStatus, selectTrade, updateTradeType } from "store/slices";
import { when } from "utils";

import isEmpty from "lodash/isEmpty";

type PaginationType = { page: number; limit: number };

const known_trade_history_types = ["open", "indispute", "cancelled", "timeout", "completed", "all"] as const;

export function useTradeHistory(pagination: PaginationType, _type?: (typeof known_trade_history_types)[number]) {
  const dispatch = useDispatch();
  const state = useSelector(selectTrade);

  // interpret type 'cancelled,timedout' to 'cancelled', leave others as is.
  const type = !!_type ? when(_type.includes("cancelled"), "cancelled", _type) : state.type;

  const status = (state as any)[type].status;
  const count = useMemo(() => (state as any)[type].count, [state, type]);
  const data = useMemo(() => (state as any)[type].data ?? state?.trades?.data, [state, type]);
  const isLoading = useMemo(() => status === "fetching" || status === "loading", [status]);
  const isFetching = useMemo(() => isLoading && !isEmpty(data), [isLoading, data]);

  /*eslint-disable */
  const getTrades = useCallback(() => {
    if ((_type ?? type) === "all") dispatch((fetchAllTrades as any)(pagination));
    if ((_type ?? type) === "completed") dispatch((fetchCompletedTrades as any)(pagination));
    if ((_type ?? type) === "cancelled") dispatch((fetchTradesByStatus as any)({ type: "cancelled,timeout", ...pagination }));
    if ((_type ?? type) !== "all" && (_type ?? type) !== "completed")
      dispatch((fetchTradesByStatus as any)({ type: _type ?? type, ...pagination }));
  }, [type, dispatch, pagination?.page, _type]);

  useEffect(() => getTrades(), [getTrades]);
  /*eslint-enable */

  const updateType = (type: string) => dispatch(updateTradeType(type));

  return {
    count,
    type,
    data: orderBy(data, ["createdAt"], ["desc"]),
    status,
    updateType,
    refetch: getTrades,
    isLoading,
    isFetching,
  };
}

export default useTradeHistory;
