import { Steps } from "components";
import { SidePageViewProps } from "contexts";
import { PageProps } from "interfaces";
import Crypto from "ui/Wallet/Features/Crypto/Crypto";

interface SubPageProps extends PageProps, SidePageViewProps {}

export default function EarnSubPages(props: SubPageProps) {
  return (
    <Steps hideIndicator>
      <Crypto context="EarnPages" />
    </Steps>
  );
}

EarnSubPages.displayName = "SidePageView";
