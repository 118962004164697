import { useState, useMemo } from "react";
import { Button, FormControl, Heading, Stack, Text, VStack, useToast, Box, UnorderedList, ListItem } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { nanoid } from "nanoid";
import { Uploader, useModalView } from "components";
import { useAddBankMutation, useLevelThreeVerificationMutation } from "apis";
import { uploadFile } from "utils";
import { IStateExtension } from "./Form";
import { useSelector } from "hooks";
import { selectUser } from "store/slices";

import toLower from "lodash/toLower";
import { useAppConfig } from "contexts/appconfig.context";
import { currencyMap } from "hooks/useCurrency";
import { useKyc } from "Pages";

interface StepThreeProps extends IStateExtension {
  goBack: () => void;
}

export default function StepThree(props: StepThreeProps) {
  const { state, goBack } = props;
  const { onNext, onPrev } = useModalView();
  const toast = useToast();
  const nanoId = nanoid(10);

  const { refetchStatus } = useKyc();

  const { appfigs } = useAppConfig();
  const { kycConfig } = appfigs ?? {};

  console.log("Level 3 State", state);

  const { profile } = useSelector(selectUser);
  const country = useMemo(() => (!!profile?.country ? String(profile?.country).toLowerCase() : "nigeria"), [profile]);
  // const countryCodeMap: Record<string, string> = {
  //   nigeria: "ng",
  //   ghana: "gh",
  //   kenya: "ke",
  // };

  const isUploadDisabled = useMemo(() => !!kycConfig && !!kycConfig?.disableDocumentUpload, [kycConfig]);

  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const [_verificationUpload, { isLoading /*, isSuccess, data */ }] = useLevelThreeVerificationMutation();
  const [addBank, { isLoading: isAddingBank }] = useAddBankMutation();

  const handleFile = async () => {
    if (!file) return;
    const filename = `id-docs-${nanoId}`;
    const isKenya = toLower(country) === "kenya";

    setLoading(true);
    let location = "";
    let bankId = state?.bankId;

    if (!state?.bankId && !isKenya) {
      const toProcess = [
        uploadFile(file!, filename),
        addBank({
          // ...state,
          // address: state?.street ?? state?.address ?? "",
          bankAccountNumber: state?.bankAccountNumber,
          bankCode: state?.bankCode,
          bankType: state?.bankType,
          country: currencyMap[country].iso2,
          network: state?.network,
        }).unwrap(),
      ];

      const fulfilled = await Promise.allSettled(toProcess);
      const [upload, bank] = fulfilled.filter((result) => result.status === "fulfilled").map((result) => (result as any)?.value);

      console.log("Upload result", { upload, bank });
      if (fulfilled.map((r) => r.status).includes("rejected")) {
        setLoading(false);
        return;
      }

      location = (upload as any)?.location;
      bankId = (bank as any)?.data?.newBankDetail?._id;
    } else {
      const res = await uploadFile(file, filename);
      location = res?.location;
      bankId = state?.bankId!;
    }

    const reqData = {
      ...state,
      link: location,
      bankStatementLink: location,
      bankId: state?.bankId ?? bankId,
      useBankId: isKenya ? true : undefined,
      country: toLower(country),
    };

    if (!!location) {
      state.bankStatementLink = location;
      onNext();

      try {
        const result = await _verificationUpload(reqData).unwrap();
        if (!!result) {
          toast({
            title: "Verification",
            description: "Your verification request has been sent",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "bottom-right",
          });

          !!refetchStatus && refetchStatus();
          navigate("/account/verification", { replace: true });
          goBack && goBack();
        }
        setLoading(false);
      } catch (error) {
        console.log(error, "the error");
        setLoading(false);
      }
    }

    setLoading(false);
  };

  // useEffect(() => {
  //   if (isSuccess) {
  // toast({
  //   title: "Verification",
  //   description: "Your verification request has been sent",
  //   status: "success",
  //   duration: 3000,
  //   isClosable: true,
  //   position: "bottom-right",
  // });

  //     navigate("/account/verification", { replace: true });
  //     goBack && goBack();
  //   }
  // }, [data, isSuccess, toast, goBack]);

  return (
    <Stack p="8px">
      <Stack my={{ base: "16px !important", md: "38px !important" }}>
        <Heading as="h6" fontSize="sm">
          Step 3/3 - Upload
        </Heading>

        <Box mt="16px">
          <Text fontSize="sm" fontWeight="600">
            Instructions:
          </Text>
          <UnorderedList mt="2px">
            <ListItem fontSize="14px" fontWeight="500" color="grey.500">
              Upload utility bill (electricity, waste or water) or a bank statment as proof of residence
            </ListItem>
          </UnorderedList>
        </Box>
      </Stack>

      <FormControl mb="32px !important">
        <Uploader isDisabled={isUploadDisabled} onFiles={(files) => setFile(files[0])} />
      </FormControl>

      <VStack mt={{ base: "16px !important", md: "34px !important" }} px={{ base: "0", md: "46px" }} w="100%">
        <Button
          onClick={handleFile}
          w="100%"
          minW={{ base: "initial", md: "400px" }}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={loading || isLoading || isAddingBank}
          disabled={loading || !file || isLoading || isAddingBank}
        >
          Next
        </Button>
        <Button
          variant="transparent"
          textDecoration="underline"
          minW={{ base: "initial", md: "400px" }}
          w="100%"
          onClick={onPrev}
          //   isLoading={isCancelling}
          //   disabled={isSubmiting || isCancelling}
        >
          Back
        </Button>
      </VStack>
    </Stack>
  );
}
