import configs from "config";
import Pusher from "pusher-js";
import { useCallback, useEffect, useRef } from "react";
import { useAuth } from "./useAuth";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER;
const pusherKey = configs.REACT_APP_PUSHER_KEY ?? "";
const pusherHost = configs.REACT_APP_PUSHER_HOST || "";

type HandlerType = Record<
  string,
  {
    register?: boolean;
    do: (...args: any[]) => void;
  }
>;

interface UsePusherProps {
  channelName: string;
  endpoint: string;
  firstRun?: (() => any) | boolean;
  handlers: HandlerType;
  skip?: boolean;
}

export default function usePusher({ channelName, endpoint, firstRun, handlers, skip }: UsePusherProps) {
  const pusherStream = useRef<Record<string, any>>({});

  const { auth } = useAuth();

  const initPusherAuth = useCallback(
    async (channelName: string, handlers: HandlerType, endpoint: string) => {
      if (!pusherStream.current[channelName]) {
        const origin = window.location.href;
        const pusher = new Pusher(pusherKey, {
          authEndpoint: `${baseUrl}/${endpoint}`,
          cluster: pusherCluster,
          ...(pusherHost && {
            cluster: undefined,
            wsHost: pusherHost,
            enabledTransports: ["ws", "wss"],
          }),
          auth: {
            params: { channelName },
            headers: { authorization: auth?.token, origin },
          },
        });
        const currentSub = pusher.subscribe(channelName);
        pusherStream.current = { ...pusherStream.current, [channelName]: currentSub };

        const binders = Object.keys(handlers);
        Array.isArray(binders) &&
          binders?.forEach((binding) => {
            if (handlers[binding]?.register) {
              pusherStream?.current[channelName]?.bind(binding, handlers[binding]?.do);
            }
          });
      } else {
      }
    },
    [auth?.token]
  );

  useEffect(() => {
    if (!skip) {
      if (auth?.token) {
        typeof firstRun === "function" && firstRun();
        initPusherAuth(channelName, handlers, endpoint);
      }
    }
    return () => {
      const bindings = Object.keys(pusherStream.current);
      Array.isArray(bindings) &&
        bindings?.forEach((binding) => {
          pusherStream.current[binding] && pusherStream.current[binding].unsubscribe(binding);
        });
      pusherStream.current = {};
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, auth?.token]);

  return null;
}
