import { useEffect, useRef } from "react";

const useScript = (resourceUrl: string, skip: boolean) => {
  const loaded = useRef(false);

  useEffect(() => {
    if (skip === true) {
    } else {
      const script = document.createElement("script");
      if (!loaded.current) {
        script.src = resourceUrl;
        script.async = true;
        document.body.appendChild(script);
        loaded.current = true;
      }

      return () => {
        if (loaded.current) document.body.removeChild(script);
      };
    }
  }, [resourceUrl, skip]);
};

export default useScript;

export const removeScript = (scriptToremove: string) => {
  let allsuspects = document.getElementsByTagName("script");
  for (let i = allsuspects.length; i >= 0; i--) {
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute("src") !== null &&
      allsuspects[i].getAttribute("src")?.indexOf(`${scriptToremove}`) !== -1
    ) {
      allsuspects[i].parentNode?.removeChild(allsuspects[i]);
    }
  }
};

export const appendScript = (scriptToAppend: string) => {
  try {
    removeScript(scriptToAppend);
  } catch (err) {}
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};

export const appendScriptPromise = (scriptToAppend: string) => {
  return new Promise((resolve, reject) => {
    try {
      removeScript(scriptToAppend);
    } catch (err) {
      reject(err);
    }
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
    resolve(script);
  });
};
