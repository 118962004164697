export enum AppAnalyticEvent {
  BUY_INITIATED = "Buy Initiated",
  BUY_SUBMITTED = "Buy Submitted",
  SELL_INITIATED = "Sell Initiated",
  SELL_SUBMITTED = "Sell Submitted",
  SWAP_INITIATED = "Swap Initiated",
  SWAP_SUBMITTED = "Swap Submitted",

  // ############################

  WITHDRAWAL_INITIATED = "Withdrawal Initiated",
  WITHDRAWAL_SUBMITTED = "Withdrawal Submitted",
  FUNDING_SUBMITTED = "Funding Submitted",
  FUNDING_INITIATED = "Funding Initiated",

  // ############################

  ADD_CARD_LABEL = "Add Card Label",
  CREATE_CARD_INITIATED = "Create Card Initiated",
  CREATE_CARD_SUBMITTED = "Create Card Submitted",
  CARD_FUNDING_SUBMITTED = "Card Funding Submitted",
  CARD_FUNDING_INITIATED = "Card Funding Initiated",
  CARD_WITHDRAWAL_SUBMITTED = "Card Withdrawal Submitted",
  CARD_WITHDRAWAL_INITIATED = "Card Withdrawal Initiated",
  REQUEST_CARD_STATEMENT = "Request Card Statement",

  // ############################

  FUND_GREENBOX_INITIATED = "Fund Greenbox Initiated",
  FUND_GREENBOX_SUBMITTED = "Fund Greenbox Submitted",
  CREATE_GREENBOX_INITIATED = "Create Greenbox Initiated",
  CREATE_GREENBOX_SUBMITTED = "Create Greenbox Submitted",
  WITHDRAWAL_GREENBOX_SUBMITTED = "Withdrawal Greenbox Submitted",
  WITHDRAWAL_GREENBOX_INITIATED = "Withdrawal Greenbox Initiated",
  CLAIMED_GREENBOX_INITIATED = "Claimed Greenbox Initiated",
  CLAIMED_GREENBOX_SUBMITTED = "Claimed Greenbox Submitted",

  // ############################

  BUY_AIRTIME_INITIATED = "Buy Airtime Initiated",
  BUY_AIRTIME_SUBMITTED = "Buy Airtime Submitted",
  BUY_DATA_SUBMITTED = "Buy Data Submitted",
  BUY_DATA_INITIATED = "Buy Data Initiated",

  // ############################

  EARN_CLICKED = "Earn Clicked",
  STAKING_CLICKED = "Staking Clicked",
  FIXED_STAKING_CLICKED = "Fixed Staking Clicked",
  FLEXIBLE_STAKING_CLICKED = "Flexible Staking Clicked",
  EARN_STAKING_INITIATED = "Earn Staking Initiated",
  EARN_STAKING_SUBMITTED = "Earn Staking Submitted",
  EARN_STAKING_ROLLED_OVER = "Earn Staking Rolled-over",
  EARN_STAKING_REDEEMED = "Earn Staking Redeemed",
  TRANSFER_FUNDS_STAKING_INITIATED = "Transfer Funds Staking Initiated",
  TRANSFER_FUNDS_STAKING_SUBMITTED = "Transfer Funds Staking Submitted",

  // ############################
  P2P_BUY_INITIATED = "P2P Buy Initiated",
  P2P_BUY_SUBMITTED = "P2P Buy Submitted",
  P2P_SELL_INITIATED = "P2P Sell Initiated",
  P2P_SELL_SUBMITTED = "P2P Sell Submitted",
  P2P_AD_CREATION_INITIATED = "P2P Ad Creation Initiated",
  P2P_AD_CREATION_SUBMITTED = "P2P Ad Creation Submitted",
  P2P_BUY_AD_CREATED = "P2P Buy Ad Created",
}
