import { Box, Button, FormControl, Heading, Input, ModalProps, Stack, VStack } from "@chakra-ui/react";
import { AbstractModal, FormInfo, InputLabel } from "components";
import { isPast } from "date-fns";
import { usePartialState } from "hooks";
import { useMemo } from "react";

interface RequestCardStatmentProps extends Omit<ModalProps, "children"> {}

interface IState {
  startDate?: string;
  endDate?: string;
}

export default function RequestCardStatment(props: RequestCardStatmentProps) {
  const { isOpen, onClose, ...xprops } = props;
  //   const { colorMode } = useColorMode();

  const [state, set] = usePartialState<IState>();

  const isStartDatePast = useMemo(() => !!state?.startDate && isPast(new Date(state?.startDate)), [state?.startDate]);

  const isEndDatePast = useMemo(() => !!state?.endDate && isPast(new Date(state?.endDate)), [state?.endDate]);

  const handleRequest = () => {};

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        position: { base: "absolute", "1sm": "relative" },
      }}
      {...xprops}
    >
      <Box p={{ base: "32px 40px", "1sm": "44px 80px" }}>
        <VStack>
          {/* <Icon type="verificationCompleted" boxSize="44px" /> */}
          <Heading as="h5" fontSize="2xl">
            Request Card Statement
          </Heading>
        </VStack>

        <Stack py="20px" my="20px" as="form" onSubmit={handleSubmit}>
          <FormControl>
            <InputLabel>Start Date</InputLabel>
            <Input type="date" value={state?.startDate ?? ""} onChange={(e) => set({ startDate: e.target.value })} />

            {isStartDatePast && (
              <FormInfo
                mt="10px"
                isInvalid={isStartDatePast}
                info=""
                description="The start date and time must not be earlier than today"
              />
            )}
          </FormControl>

          <FormControl mt="16px !important">
            <InputLabel>End Date</InputLabel>
            <Input type="date" value={state?.endDate ?? ""} onChange={(e) => set({ endDate: e.target.value })} />

            {isEndDatePast && (
              <FormInfo
                mt="10px"
                isInvalid={isEndDatePast}
                info=""
                description="The end date and time must not be earlier than today"
              />
            )}
          </FormControl>
        </Stack>

        <VStack px="46px" w="100%">
          <Button minW="unset" w="100%" onClick={handleRequest}>
            Send Request
          </Button>
          <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose}>
            Close
          </Button>
        </VStack>
      </Box>
    </AbstractModal>
  );
}
