import {
  HStack,
  PinInput as ChakraPinInput,
  PinInputProps as ChakraPinInputProps,
  PinInputField,
  useColorModeValue,
  PinInputFieldProps,
  BoxProps,
} from "@chakra-ui/react";
import { FC } from "react";

interface PinInputProps extends Omit<ChakraPinInputProps, "children"> {
  limit?: number;
  _field?: PinInputFieldProps;
  _container?: BoxProps;
}

const PinInput: FC<PinInputProps> = (props) => {
  const { limit, size, otp, type, mask, _field, _container } = props;

  const borderColor = useColorModeValue("#797A7A", "white");
  const borderFocusColor = useColorModeValue("secondary.500", "primary.400");

  return (
    <HStack justifyContent="space-evenly" {..._container}>
      <ChakraPinInput otp={otp} type={type} size={size ?? "lg"} placeholder="" {...props} autoFocus={true} mask={mask ?? true}>
        {Array(limit ?? 6)
          .fill(0)
          .map((_, i) => (
            <PinInputField
              key={`pin-input-${i}`}
              w="100%"
              autoComplete="off"
              border="1.5px dashed transparent"
              borderRadius="1px"
              borderColor="transparent"
              borderBottomColor={borderColor}
              _focus={{
                shadow: "outline",
                borderColor: "transparent",
                borderBottomColor: borderFocusColor,
              }}
              _hover={{
                borderColor: "transparent",
                borderBottomColor: borderFocusColor,
              }}
              {..._field}
            />
          ))}
      </ChakraPinInput>
    </HStack>
  );
};

export default PinInput;
