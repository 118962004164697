import {
  Button,
  ButtonProps,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import Icon from "components/Icon/Icon";
import { usePartialState } from "hooks";
import { isEqual } from "lodash";
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { filterChildren, filterChildrenWithKey, renderComponent } from "utils";

type ISidePageConfig = {
  key?: string;
  hasBackButton?: boolean;
  _back?: ButtonProps & { text?: string };

  /**
   * The use of data for passing around url params is deprecated.
   * Use params instead since you are guaranteed to always get the
   * params map back even after a page reload as long as the params
   * are still part of the browser url.
   * */
  data?: {
    [key: string]: any | null | undefined;
    tab?: string | null;
  };

  /**
   * Use params to pass around url parameters you need to preserve even
   * after a page reload.
   */
  params?: { [key: string]: string };
};

type GetViewPropsType = (key: string) => ISidePageConfig | null;

// interface ISidePageUpdateParams {
//   config?: Partial<ISidePageConfig>;
// }

interface ISidePageContext {
  onOpen: (config?: Partial<ISidePageConfig>) => void;
  onClose: () => void;
  isOpen: boolean;
  /**
   * The use of "data" field for passing around url params is deprecated.
   * Use params instead since you are guaranteed to always get the
   * params map back even after a page reload as long as the params
   * are still part of the browser url.
   * */
  data?: any;
  key: string;
  params?: { [key: string]: string };
  updateSidePageConfigs: (config: Partial<ISidePageConfig>) => void;
}

interface SidePageProviderProps {}

export interface SidePageViewProps extends ISidePageConfig {}

const SidePageContext = createContext<ISidePageContext | null>(null);

export function SidePageView(props: PropsWithChildren<SidePageViewProps>) {
  return <>{props?.children}</>;
}

export default function SidePageProvider(props: PropsWithChildren<SidePageProviderProps>) {
  const { children } = props;

  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location?.search), [location?.search]);

  // console.log("location", { location, params: params.toString() });

  const bg = useColorModeValue("grey.100", "dark.bg");
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const isPropsSet = useRef(false);
  const [state, set, reset] = usePartialState<ISidePageConfig>({});

  const { isOpen, onOpen, onClose } = useDisclosure();

  const setURLQueries = (map: { [key: string]: string }) => {
    const keys = Object.keys(map);
    keys.forEach((key) => {
      !!map[key] && params.set(key, map[key]);
    });
  };

  const handleOpen = (config?: Partial<ISidePageConfig>) => {
    config && set(config);

    // console.log("Set config", config);
    onOpen();

    const key = [config?.key];

    // update the subpage and coin in the url query if available.
    if (config?.data && Object.keys(config.data).includes("coin")) params.set("coin", config.data["coin"] as string);

    // TODO: Also check if 'tab' exist in config?.params, if it does, use it instead.
    if (config?.data && Object.keys(config.data).includes("tab")) key.push(config.data["tab"] as string);

    // check if tabs is in params, if it exists, override it here.
    // if (config?.params && Object.keys(config.params).includes("tab")) key.push(config.params["tab"] as string);
    config?.key && params.set("subpage", key.join(":"));
    !!config?.params && setURLQueries(config?.params);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleClose = () => {
    reset();
    onClose();

    // remove the 'subpage' url query.
    params.delete("subpage");

    // if (params.toString().length > 0) return navigate(`${location.pathname}?${params.toString()}`);
    navigate(`${location.pathname}`);
  };

  const handleUpdate = (config: Partial<ISidePageConfig>) => {
    // const updates = { ...state, ...config };
    set({ ...config });

    // console.clear();
    // console.log("Update Sidepage handler called", config);

    if (!!config?.params) {
      setURLQueries(config?.params);
      navigate(`${location.pathname}?${params.toString()}`);
    }
  };

  const view = useMemo(() => {
    const childs = renderComponent(children, "SidePageView");
    if (state?.key) return filterChildrenWithKey(childs, state.key)![0];
    return childs![0];
  }, [children, state?.key]);

  const viewProps = useMemo(() => view?.props, [view]);

  const getViewProps: GetViewPropsType = useCallback(
    (key: string) => {
      const childs = renderComponent(children, "SidePageView");
      const view = filterChildrenWithKey(childs, key)![0];
      if (!view) return null;
      return { ...view?.props!, key };
    },
    [children]
  );

  const filteredChildren = useMemo(() => filterChildren(children, ["SidePageView"]), [children]);

  //   console.log("Comparison", isEqual(viewProps, state));

  useEffect(() => {
    if (!isEqual(viewProps, state) && !isPropsSet.current) {
      set({ ...viewProps!, key: state?.key, data: state?.data, params: state?.params });
      isPropsSet.current = true;
    }

    return () => {
      isPropsSet.current = false;
      reset();
    };

    // eslint-disable-next-line
  }, [viewProps, state?.key, state?.data, state?.params]);

  // auto open the sidepage if the url has a 'subpage' query
  useEffect(() => {
    if (params.has("subpage")) {
      // Crypto:overview -> [Crypto, overview]
      const props = getViewProps(params.get("subpage")!.split(":")[0]);
      if (props) {
        // TODO: using data is deprecated for persisting url params across the
        // currently opened sidepage view, use params instead.
        // make sure to use params within the sidepage view relying on data to
        // pass url queries to the subpage view.
        const coin = params.get("coin");
        const tab = params.get("tab");

        let _params = {};
        params.forEach((v, k) => {
          // subpage keyValue is already be set in the props object by getViewsProps
          if (k !== "subpage") Object.assign(_params, { [k]: v });
        });

        // TODO: remove data object when the above todo is done.
        // console.log("subpage params", _params);
        set({ ...props, data: { coin, tab }, params: _params });
        onOpen();
      }
    }

    // eslint-disable-next-line
  }, [params]);

  return (
    <SidePageContext.Provider
      value={{
        isOpen,
        key: state?.key!,
        onClose: handleClose,
        onOpen: handleOpen,
        data: state?.data,
        params: state?.params,
        updateSidePageConfigs: handleUpdate,
      }}
    >
      {filteredChildren}

      <Drawer isOpen={isOpen} placement="right" onClose={handleClose} closeOnOverlayClick={false}>
        <DrawerOverlay bg="#3031317f" />
        <DrawerContent
          maxWidth={{ base: "520px", "3sm": "fit-content", "4sm": "640px" }}
          minW={{ base: "310px", "1sm": "480px", "3sm": "640px" }}
          bg={bg}
        >
          {state?.hasBackButton && (
            <HStack w="100%" p={{ base: "32px 8px", "2sm": "32px 20px", "4sm": "32px" }}>
              <Button
                size="sm"
                minW="fit-content"
                maxW="fit-content"
                variant="link"
                leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                onClick={handleClose}
                px="6px !important"
                {...state?._back}
              >
                {state?._back?.text ?? "Back"}
              </Button>
            </HStack>
          )}

          {view}
        </DrawerContent>
      </Drawer>
    </SidePageContext.Provider>
  );
}

export function useSidePage() {
  const context = useContext(SidePageContext);
  if (!context) throw new Error("Sidepage context not found");
  return context;
}

SidePageView.displayName = "SidePageView";
