import { BoxProps, HStack, Skeleton, Stack, Text, TextProps, useColorModeValue } from "@chakra-ui/react";
import CopyButton from "components/Button/Copy";
import Icon, { IconNames } from "components/Icon/Icon";
import { useCopy } from "hooks";
import { ReactNode, useMemo } from "react";
import { when } from "utils";

export interface DetailItemProps extends BoxProps {
  icon?: IconNames;
  title: string;
  description: ReactNode;
  isLoading?: boolean;
  _title?: TextProps;
  _description?: TextProps;
  isCopyable?: boolean;
  isEditable?: boolean;
  isLast?: boolean;
  obscure?: boolean;
  obscureFunc?: (value: string) => string;
}

export default function DetailItem(props: DetailItemProps) {
  const {
    icon,
    title,
    description,
    isLoading,
    isLast,
    isCopyable = false,
    _title,
    _description,
    isEditable,
    children,
    obscure,
    obscureFunc,
    ...xprops
  } = props;

  const { onCopy } = useCopy(description as any);

  //  const isComponent = useMemo(() => typeof description !== "string", [description]);

  const color = useColorModeValue("primary.default", "secondary.500");
  const borderColor = useColorModeValue("border", "dark.border");

  const obscured_desc = useMemo(() => {
    if (!description) return "XXX XXX XXX";
    if (!!obscureFunc) return obscureFunc(description.toString());

    const len = description.toString().length;
    return "x".repeat(len);
  }, [description, obscureFunc]);

  return (
    <Stack
      w="100%"
      p="20px 4px"
      borderBottom="1px solid transparent"
      borderBottomColor={when(!!isLast, "none", borderColor)}
      {...xprops}
    >
      <Skeleton
        isLoaded={!isLoading ? true : false}
        w="fit-content"
        mb={isLoading ? "2px" : 0}
        maxH={isLoading ? "12px" : "fit-content"}
      >
        <Text fontSize="14px" color="grey.400" fontWeight="500" {..._title}>
          {title}
        </Text>
      </Skeleton>

      <HStack justifyContent="space-between" w="100%">
        <Skeleton
          isLoaded={!isLoading ? true : false}
          w="fit-content"
          mb={isLoading ? "2px" : 0}
          maxH={isLoading ? "12px" : "fit-content"}
        >
          <HStack>
            {icon && <Icon type={icon} />}
            <Text color={color} fontWeight="600" mt="0 !important" {..._description}>
              {when(!!obscure, obscured_desc, description)}
            </Text>
          </HStack>
        </Skeleton>

        {isCopyable && (
          <Skeleton
            isLoaded={!isLoading ? true : false}
            w="fit-content"
            // mb={isLoading ? "2px" : 0}
            maxH={isLoading ? "12px" : "fit-content"}
          >
            <CopyButton onClick={onCopy} />
          </Skeleton>
        )}

        {isEditable && (
          <Skeleton isLoaded={!isLoading ? true : false} w="fit-content" maxH={isLoading ? "12px" : "fit-content"}>
            <>{children}</>
          </Skeleton>
        )}
      </HStack>
    </Stack>
  );
}
