import configs, { isProd } from "config";
import { useCallback, useEffect } from "react";
import { dehydrate, getProfile, rehydrate, selectAuth } from "store/slices";
import useDispatch from "./useDispatch";
import useSelector from "./useSelector";

import ls from "utils/secureStorage";
import { isSafeRedirect } from "utils/urls";

export function useAuth() {
  const dispatch = useDispatch();
  const { payload } = useSelector(selectAuth);

  const logout = useCallback(() => {
    dispatch(dehydrate());
  }, [dispatch]);

  //   const isSignedIn = useCallback(() => {
  //     const auth = ls.get(configs.AUTH_TOKEN_KEY);
  //     return auth?.isSignedIn && auth?.token;
  //   }, []);

  //   const authExpired = useCallback(() => {
  //     const auth = ls.get(configs.AUTH_TOKEN_KEY);
  //     return auth?.isSignedIn && auth?.token && new Date().getTime() > +auth?.exp;
  //   }, []);

  const makeRedirect = useCallback((toLogin = false) => {
    const redirectKeyName = "rto";
    const exceptions = [
      configs.paths.app,
      configs.paths.register,
      // configs.paths.PHONE_NUMBER,
      configs.paths.resetTwoFa,
      configs.paths.resetPassword,
      configs.paths.forgotPassword,
      configs.paths.verify,
      configs.paths.verifyEmail,
      configs.paths.verifyResetCode,
      configs.paths.verifyTwoFa,
      configs.paths.captureBVN,
      configs.paths.gbx,
    ];
    const currentPagePath = window.location.pathname;

    const navigate = (path: string) => ((window.location as any) = window.location.origin + path);
    const searchParam = new URLSearchParams(window?.location?.search ?? "");

    if (exceptions.includes(currentPagePath) || currentPagePath.startsWith(configs.paths.gbx)) {
      return;
    }

    // console.log("Current Path", currentPagePath);

    if (toLogin && !exceptions.includes(currentPagePath)) {
      // console.log("LOCATION :: to login", window?.location);
      const redirectUrl = currentPagePath;
      currentPagePath !== configs.paths.login &&
        currentPagePath !== "/login" &&
        currentPagePath !== configs.paths.register &&
        navigate(`${configs.paths.login}?${redirectKeyName}=${redirectUrl}`);
      return;
    }
    if (
      currentPagePath &&
      !searchParam.has(redirectKeyName) &&
      currentPagePath !== configs.paths.login &&
      currentPagePath !== configs.paths.register
    ) {
      return;
    }

    if (searchParam.has(redirectKeyName) && !currentPagePath?.includes(searchParam.get(redirectKeyName)!)) {
      const rto = searchParam.get(redirectKeyName);
      const isSafeRto = isSafeRedirect({ url: rto!, skip: !isProd });
      if (isSafeRto) {
        navigate(rto!);
        return;
      }
    }

    // if (
    //   currentPagePath !== constant.PAGES.LOGIN &&
    //   currentPagePath !== constant.PAGES.HOME &&
    //   currentPagePath !== currentPagePath &&
    //   !searchParam.has(redirectKeyName)
    // ) {
    //   navigate(currentPagePath);
    //   return;
    // }

    // console.log("LOCATION :: absolute", window?.location);

    !currentPagePath?.includes(configs.paths.dashboard) && navigate(configs.paths.dashboard);
    return;
  }, []);

  const hydrateAuth = useCallback(() => {
    const auth = ls.get(configs.AUTH_TOKEN_KEY);
    if ((auth?.exp && Date.now() > auth?.exp) || !auth?.token) {
      logout();
      makeRedirect(true);
      console.log("[auth] auth expired");
      return;
    }

    // on page load, checks the ls if user has token or his/her token hasn't expired,
    // then rehydrate (injects the auth payload in ls into our global auth store),
    // also we refetch the user's profile.
    dispatch(rehydrate()); // technically we don't need to do this again buh doing it anyways for insurance.
    dispatch(getProfile());
    makeRedirect();
    console.log("[auth] auth rehydrated");
    return;
  }, [logout, makeRedirect, dispatch]);

  const refetchProfile = useCallback(() => dispatch(getProfile()), [dispatch]);
  const rehydrateAuth = useCallback(() => dispatch(rehydrate()), [dispatch]);

  useEffect(() => {
    // if payload has no token, run the hydrateAuth;
    if (!payload?.token) hydrateAuth();
  }, [payload, hydrateAuth]);

  return { logout, auth: payload, refetchProfile, rehydrateAuth };
}
