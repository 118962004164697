import { Box, Button, Heading, HStack, useColorModeValue } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { Icon, MainLayoutContainer, PageMotion } from "components";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { AddFeature } from "ui";
// import { AddFormV1 } from "./_Add";

interface AddCryptoPriceAlertProps extends PageProps {}

export default function AddCryptoPriceAlert(props: AddCryptoPriceAlertProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountAddCryptoPriceAlert" overflowY="scroll">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              Crypto Price Alert / Auto Buy and Sell
            </Heading>

            <Button
              size="sm"
              // minW="fit-content"
              // maxW="fit-content"
              // variant="link"
              // leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
              onClick={() => navigate("/account/pricealerts/orders")}
            >
              Orders
            </Button>
          </HStack>
        </Box>

        <AddFeature {...props} />
        {/* <AddFormV1 {...props} /> */}
      </MainLayoutContainer>
    </PageMotion>
  );
}
