import { Box, BoxProps, Button, Grid, Heading, ModalProps, Text, TextProps, useColorModeValue, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useCreatePriceAlertOrderMutation, useGetCoinToUsdRateQuery, useGetRateQuery } from "apis";
import { AbstractModal, Steps, useAbstractModal } from "components";
import { useCurrency } from "hooks/useCurrency";
import { createPriceAlertOrderDto } from "interfaces";
import { ReactNode, useMemo } from "react";
import { IStateExtension } from "ui/Account/Features/CryptoAlert/Add";

import { currencyFormat, toCoinLocale, toPrecision, when } from "utils";

interface PriceAlertOrderPreviewProps extends Omit<ModalProps, "children">, IStateExtension {
  onSuccessClose?: () => void;
}

interface ItemProps extends BoxProps {
  info: string;
  description: ReactNode;
  _desc?: TextProps;
}

export function PriceAlertOrderPreview(props: PriceAlertOrderPreviewProps) {
  const { isOpen, onClose } = props;

  //   const handleClose = () => {
  //     // set({ pin: undefined });
  //     onClose();
  //   };

  return (
    <AbstractModal closeOnOverlayClick={false} closeOnEsc={false} isOpen={isOpen} onClose={onClose} _content={{ maxW: "620px" }}>
      <Steps hideIndicator>
        <Preview {...props} />
        {/* <Pin {...{ ...props, onClose: handleClose }} /> */}
      </Steps>
    </AbstractModal>
  );
}

// export function Pin(props: PriceAlertOrderPreviewProps) {
//   const { coin, state, set, onClose, reset, onSuccessClose } = props;

//   const { onPrev } = useModalView();
//   const { updateModal } = useAbstractModal();

//   const [withdraw, { isLoading: isWithdrawing, reset: resetApiState }] = useWithdrawCryptoMutation();

//   const isDisabled = useMemo(
//     () => !state?.pin || (!!state?.pin && state.pin.length < 6) || isWithdrawing,
//     [state?.pin, isWithdrawing]
//   );

//   const close = () => {
//     reset();
//     onClose();
//     updateModal({ isSuccess: false });
//     onSuccessClose();
//   };

//   const handleSubmit = async (e: any) => {
//     e.preventDefault();

//     const reqData: WithdrawCryptoDto = {
//       coin,
//       reason: "Withdrawal request",
//       amount: +state?.amount!,
//       destinationAddress: String(state.address).trim(),
//       destinationCoin: coin,
//       tagNumber: state?.tag,
//       memo: state?.memo,
//       ...state,
//       pin: state?.pin!,
//     };

//     const result = await withdraw(reqData as WithdrawCryptoDto).unwrap();
//     console.log("Withdrawal Result", result);

//     if (!!result) {
//       updateModal &&
//         updateModal({
//           isSuccess: true,
//           success: {
//             description: `Withdraw successful`,
//             actions: [
//               {
//                 label: "Close",
//                 onClick: () => {
//                   close();
//                 },
//               },
//             ],
//           },
//         });

//       reset();
//     }
//   };

//   useEffect(() => {
//     return () => {
//       resetApiState();
//     };
//   }, [resetApiState]);

//   return (
//     <VStack as="form" p={{ base: "32px 16px", md: "45px 100px" }} onSubmit={handleSubmit}>
//       <BackButton onClick={onPrev} />
//       <PinView onPin={(pin) => set({ pin })} />

//       <VStack mt="44px !important" px="46px" w="100%">
//         <Button minW={{ base: "100%", md: "400px" }} type="submit" disabled={isDisabled} isLoading={isWithdrawing}>
//           Submit
//         </Button>

//         <Button minW={{ base: "100%", md: "400px" }} variant="transparent" textDecoration="underline" w="100%" onClick={onClose}>
//           Cancel
//         </Button>
//       </VStack>
//     </VStack>
//   );
// }

function Preview(props: PriceAlertOrderPreviewProps) {
  const { state, reset, onClose, onSuccessClose } = props;

  //   const { onNext } = useModalView();
  const { updateModal } = useAbstractModal();

  const bcolor = useColorModeValue("#B7DFA280", "grey.150");

  const currency = useCurrency();

  const [_createOrder, { isLoading }] = useCreatePriceAlertOrderMutation();

  const { data: coinToUsd } = useGetCoinToUsdRateQuery(state?.coin ?? "btc", { skip: !state?.coin });
  const { data: coinData } = useGetRateQuery(`${state?.coin}ngn`, { skip: !state?.coin });

  // const actionType = useMemo(() => when(!!state?.direction && state?.direction === "up", "sell", "buy"), [state?.direction]);
  const actionType = useMemo(() => state?.scheduleType ?? "buy", [state?.scheduleType]);
  //   const actionVerb = useMemo(() => when(actionType === "sell", "sold", "bought"), [actionType]);
  const orderField = useMemo(() => when(actionType === "sell", "cryptoAmount", "fiatAmount"), [actionType]);

  const current_market_price_in_usd = useMemo(
    () => (coinToUsd?.exchangeRate ?? {})[actionType] ?? 0,
    [actionType, coinToUsd?.exchangeRate]
  );

  const current_fiat_market_price: number = useMemo(
    () => (coinData?.exchangeRate ?? {})[actionType] ?? 0,
    [actionType, coinData?.exchangeRate]
  );

  const coin_amount_in_fiat = useMemo(
    () => +(state?._temp_coin_amount ?? 0) * current_fiat_market_price,
    [state?._temp_coin_amount, current_fiat_market_price]
  );

  const real_spot_price = useMemo(
    () => +(state?.spotPrice ?? 0),
    //   when(
    //     actionType === "buy",
    //     current_market_price_in_usd - +(state?.spotPrice ?? 0),
    //     +(state?.spotPrice ?? 0) + current_market_price_in_usd
    //   ),
    [state?.spotPrice]
  );

  console.log("Rate ", coinToUsd, current_market_price_in_usd, coin_amount_in_fiat);

  const handleSuccessClose = () => {
    onClose();
    onSuccessClose && onSuccessClose();
    navigate(-1);
  };

  const createOrder = async () => {
    const amount = when(actionType === "sell", +(state?._temp_coin_amount ?? 0), coin_amount_in_fiat);

    const response: any = await _createOrder({
      ...(state as NonNullable<createPriceAlertOrderDto>),
      orderType: actionType,
      [orderField]: amount,
      currency,
      direction: state?.direction,
    });

    console.log("Order Response", response);

    if (!!response && !response?.error) {
      reset();
      updateModal({
        isSuccess: true,
        success: {
          description: `Your price alert and ${actionType} order for ${toCoinLocale(state?.coin ?? "btc")} have been set`,
          actions: [{ onClick: handleSuccessClose, label: "Close" }],
          _description: {
            maxW: "180px",
          },
        },
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await createOrder();
  };

  return (
    <VStack as="form" p={{ base: "32px 16px", md: "45px 100px" }} onSubmit={handleSubmit}>
      <VStack maxW="460px" m="0 auto" mb="40px">
        <Heading as="h6" fontSize="18px">
          Transaction Summary
        </Heading>

        <Box mt="40px !important">
          <Grid templateColumns="repeat(2, 1fr)">
            <Item
              info={`${toCoinLocale(state?.coin ?? "btc")} Quantity`}
              description={toPrecision(+(state?._temp_coin_amount ?? 0), state?.coin ?? "btc") as string}
              borderRightColor={bcolor}
              borderBottomColor={bcolor}
            />

            <Item
              info={`Amount in fiat`}
              description={currencyFormat("ngn").format(coin_amount_in_fiat)}
              borderBottomColor={bcolor}
            />

            <Item
              info={`Spot Price`}
              description={currencyFormat("usd").format(real_spot_price)}
              borderRightColor={bcolor}
              //   borderBottomColor={bcolor}
            />

            <Item
              info={`Type of Transaction`}
              description={actionType}
              //   borderBottomColor={bcolor}
              _desc={{ textTransform: "capitalize" }}
            />
          </Grid>
        </Box>
      </VStack>

      <VStack mt="14px !important" px="46px" w="100%">
        <Button
          type="submit"
          minW="unset"
          w="100%"
          fontFamily="var(--bitmama-fonts-heading)"
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Continue
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" isDisabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function Item(props: ItemProps) {
  const { info, description, _desc, ...xprops } = props;

  const icolor = useColorModeValue("grey.400", "grey.100");
  const dcolor = useColorModeValue("grey.800", "white");

  return (
    <VStack border="1px solid transparent" p={{ sm: "18px 24px", "3sm": "24px 30px" }} {...xprops}>
      <Text
        color={icolor}
        fontSize="14px"
        fontWeight="500"
        maxW="100%"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {info}
      </Text>
      <Text color={dcolor} fontSize="18px" fontWeight="500" textAlign="center" {..._desc}>
        {description}
      </Text>
    </VStack>
  );
}
