import { Box, HStack, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import {
  Card,
  EmptyCrate,
  Group,
  GroupProps,
  Icon,
  Link,
  PageLoading,
  // Paginator,
  Title,
  TitleBar,
  TitleBarActions,
} from "components";

import { useGetGreenboxTransactionsQuery } from "apis";
import { switchStyle, toQueryString, when } from "utils";
import { useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { usePartialState } from "hooks";
import { useSidePage } from "contexts";
import { GreenboxTransactionRo } from "interfaces";
import { formatDistance, parseISO } from "date-fns";

import capitalize from "lodash/capitalize";

export interface GreenBoxTransactionsProps extends GroupProps {}

export function GreenBoxTransactions(props: GreenBoxTransactionsProps) {
  //   const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");
  const bg = useColorModeValue("transparent", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const { onOpen } = useSidePage();

  const [queries] = usePartialState({
    page: 1,
    limit: 4,
    sortBy: "createdAt:desc",
    actionType: "box_in,box_out,box_cancel",
  });

  const { data, isLoading, isFetching } = useGetGreenboxTransactionsQuery(toQueryString(queries));
  console.log("Greenbox transactions", data);

  // const { data, isLoading, isFetching } = useGetWalletTransationsV2Query(toQueryString(queries));

  const totalCount = useMemo(() => data?.totalCount ?? 0, [data]);
  const transactions = useMemo(() => data?.records ?? [], [data]);
  const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);

  console.log("Transactions Data", data?.records);

  // const handlePageChange = (page: number) => {
  //   set({ page });
  // };

  return (
    <Group w="100%" {...props}>
      <TitleBar>
        <Title fontSize="18px" isLoading={isFetching && !isLoading}>
          Recent Greenbox
        </Title>

        {totalCount > (queries?.limit ?? 0) && (
          <TitleBarActions>
            <Link
              textDecoration="underline"
              onClick={() => {
                const handle_click = () => onOpen({ key: "AllGreenBoxTransactions" });
                onOpen({ key: "AllGreenBoxTransactions", _back: { onClick: handle_click } });
              }}
            >
              See All
            </Link>
          </TitleBarActions>
        )}
      </TitleBar>
      <Card bg={bg} shadow="none" w="100%" p="0" overflow="hidden">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isLoading && <PageLoading isLoading={isLoading} />}
            {/* {!isLoading && (
              <Stack>
                {(transactions as any ?? []).map((tx, id) => (
                  <TransactionItem
                    mt="0 !important"
                    p={{ base: "9px 0", "1sm": "12px", "2sm": "12px 16px" }}
                    w="100%"
                    key={`transaction-${id}`}
                    unit={tx?.unit}
                    type={tx?.type}
                    value={tx?.value}
                    createdAt={tx?.createdAt}
                    description={tx?.description}
                  />
                ))}
              </Stack>
            )} */}

            {!isLoading && (
              <Group m="0">
                {(transactions ?? []).map((tx, i) => (
                  <TransactionItem
                    key={`greenbox-transaction-${i}`}
                    {...tx}
                    onClick={() => onOpen({ key: "GreenBoxDetails", params: { id: tx?._id } })}
                  />
                ))}
              </Group>
            )}

            {!isLoading && !isFetching && !hasTx && <EmptyCrate py="140px" type="greenbox" />}
          </motion.div>
        </AnimatePresence>
      </Card>

      {/* <Box w="100%">
        <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
      </Box> */}
    </Group>
  );
}

interface TransctionItemProps extends Partial<GreenboxTransactionRo> {
  onClick: () => void;
}

function TransactionItem(props: TransctionItemProps) {
  const { status, operation, actionType, onClick, product } = props;

  const { colorMode } = useColorMode();
  console.log(`Expires in ${!!product?.expirationDate && parseISO(product?.expirationDate)}`);

  return (
    <HStack
      as="button"
      borderRadius="6px"
      p="12px 12px"
      justifyContent="space-between"
      _hover={{ bg: switchStyle(colorMode, { light: "#0000000f", dark: "#0000004d" }) }}
      onClick={onClick}
    >
      <HStack>
        <TransactionIcon operation={operation} actionType={actionType} />

        <Box display="flex" flexDir="column" alignItems="flex-start" ml="12px !important">
          <Text
            textDecoration="none"
            fontFamily="var(--bitmama-fonts-heading)"
            color={switchStyle(colorMode, { light: "primary.default", dark: "secondary.500" })}
          >
            {product?.title ?? "Bitmama giveaway"}
          </Text>

          {
            <Text fontSize="14px" textDecoration="none" color={switchStyle(colorMode, { light: "#797A7A", dark: "grey.50" })}>
              {when(
                !["expired", "cancelled", "claimed"].includes(status!),
                !!product?.expirationDate
                  ? `Expires in ${when(
                      !!product?.expirationDate,
                      formatDistance(parseISO(product?.expirationDate!), new Date()),
                      "3days"
                    )}`
                  : capitalize(status!),
                capitalize(status!)
              )}
            </Text>
          }
        </Box>
      </HStack>

      <Icon type="chevronRightArrow" />
    </HStack>
  );
}

function TransactionIcon(props: any) {
  const { actionType } = props;

  const bgColor = useMemo(() => {
    const map: Record<string, string> = {
      box_in: "secondary.400",
      box_out: "primary.default",
      box_cancel: "accent.400",
    };
    return map[actionType ?? "box_in"];
  }, [actionType]);
  return (
    <Box boxSize="44px" bg={bgColor} borderRadius="6.8px" display="flex" alignItems="center" justifyContent="center">
      <Icon type="roundedGift" color="grey.50" />
    </Box>
  );
}
