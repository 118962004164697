import { Box, Button, FormControl, Heading, Input, ModalProps, Text, VStack } from "@chakra-ui/react";
import { AbstractModal } from "components";

import { ReactComponent as SubtractedShieldSVG } from "assets/svg/subtracted-shield.svg";
import { useState } from "react";

interface Enter2FAProps extends Omit<ModalProps, "children"> {
  onContinue?: (pin?: string) => void;
  onPin?: (pin: string) => void;
  onResetOpen?: () => void;
}

export function Enter2FAModal(props: Enter2FAProps) {
  const { isOpen, onClose, onContinue, onPin, onResetOpen } = props;

  const [pin, setPin] = useState("");

  const handlePin = (_pin: string) => {
    //   if (_pin.length > 6) return;
    setPin(_pin);
    !!onPin && onPin(_pin);
  };

  return (
    <AbstractModal isOpen={isOpen} onClose={onClose} _content={{ maxW: "560px" }}>
      <VStack py="45px" px="60px">
        <VStack maxW="520px" m="0 auto">
          <Box boxSize="48px" mb="14px !important" as={SubtractedShieldSVG} />

          <Heading as="h4" fontSize="32px" mb="10px !important">
            Enter 2FA
          </Heading>
          <Text textAlign="center" mt="20px" fontWeight="500">
            Enter the 6 digit code generated from your Authenticator app
          </Text>

          <FormControl mt="38px !important">
            {/* <FormLabel htmlFor="code">One Time Code</FormLabel> */}
            <Input
              isRequired
              type="text"
              id="code"
              minW="unset"
              name="code"
              maxLength={6}
              value={pin}
              onChange={(e) => handlePin(e.target.value)}
              placeholder="Enter two factor authentication code"
            />
          </FormControl>
        </VStack>

        <VStack mt="48px !important" px="46px" w="100%">
          <Button minW="unset" w="100%" onClick={() => !!onContinue && onContinue(pin)} fontFamily="var(--bitmama-fonts-heading)">
            Continue
          </Button>
          <Button
            mt="20px !important"
            variant="link"
            minW="unset"
            w="fit-content"
            onClick={onResetOpen}
            color="secondary.500"
            fontFamily="var(--bitmama-fonts-heading)"
          >
            Reset 2FA
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
