import { Tab as CTab, TabProps as CTabProps, useColorModeValue } from "@chakra-ui/react";
import { useDefaultStyle } from "hooks";

interface TabProps extends CTabProps {}

export default function Tab(props: TabProps) {
  const { ...xprops } = props;
  const { shadow } = useDefaultStyle();
  const color = useColorModeValue("primary.800", "secondary.600");

  // const disabledStyles = useMemo(
  //   () => when<TabProps>(!!disabled || !!isDisabled, { pointerEvents: "none" }, {}),
  //   [disabled, isDisabled]
  // );

  // console.log("Tab Style", disabledStyles, { disabled, isDisabled });

  return (
    <CTab
      pos="relative"
      pl="0 !important"
      mr={{ base: "0", md: "20px" }}
      w="100%"
      py="18px"
      px="0"
      color="grey.500"
      fontWeight="400"
      fontSize={{ base: "16px", "3sm": "18px" }}
      borderRadius="4px 4px 0px 0px"
      _focus={{ shadow }}
      _selected={{
        color,
        fontWeight: 600,
        letterSpacing: "1.5px",
        // borderColor: props.borderColor,
        _before: {
          content: "''",
          pos: "absolute",
          h: "6px",
          w: "100%",
          bg: color,
          borderRadius: "50px",
          bottom: "0",
          left: 0,
        },
      }}
      {...xprops}
      borderColor="transparent"
    >
      {props.children}
    </CTab>
  );
}
