import { useMemo } from "react";
import { Button, Box, Text, ButtonProps, HStack, useColorMode } from "@chakra-ui/react";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import toPrecision from "utils/toPrecision";
import Icon from "components/Icon/Icon";
import { switchStyle, toCoinLocale } from "utils";
import { useCoin, useDefaultStyle } from "hooks";

interface StakedAmountProps extends ButtonProps {
  amount?: number;
  coin?: string;
  styleType?: "stake" | "info" | "yield";
}

export default function StakedAmount(props: StakedAmountProps) {
  const { styleType = "stake", amount, coin, onClick, ...xprops } = props;

  const { colorMode } = useColorMode();
  const { shadow } = useDefaultStyle();

  const { icon } = useCoin(coin as any);

  const vprops = useMemo(() => {
    const map = {
      stake: {
        bg: switchStyle(colorMode, { dark: "rgba(207, 233, 193, 0.1)", light: "rgba(207, 233, 193, 0.1)" }),
        color: switchStyle(colorMode, { dark: "white", light: "black" }),
        showCircle: true,
        showArrow: false,
        label: "Staked Amount",
        description: toPrecision(amount ?? 0, coin as string, true, 8, true),
        pointerEvents: "none",
        dir: "row",
        shadow: "none",
        icon: (props: any) => <Icon type="staking" boxSize="32px" {...props} />,
        iconColor: switchStyle(colorMode, { dark: "#6FBE45", light: "secondary.400" }),
        ml: "0",
      },
      yield: {
        bg: switchStyle(colorMode, { dark: "rgba(207, 233, 193, 0.1)", light: "rgba(207, 233, 193, 0.1)" }),
        color: switchStyle(colorMode, { dark: "white", light: "black" }),
        showCircle: true,
        showArrow: false,
        label: "Yield Amount",
        description: toPrecision(amount ?? 0, coin as string, true, 8, true),
        pointerEvents: "auto",
        dir: "row",
        shadow: "none",
        icon: (props: any) => <Icon type="yield" boxSize="32px" {...props} />,
        iconColor: switchStyle(colorMode, { dark: "#6FBE45", light: "#0C4A6E" }),
        ml: "0",
      },
      info: {
        bg: switchStyle(colorMode, { dark: "rgba(49, 183, 169, 0.05)", light: "rgba(49, 183, 169, 0.1)" }),
        showCircle: false,
        showArrow: true,
        color: switchStyle(colorMode, { dark: "white", light: "black" }),
        dir: "column",
        label: `${toCoinLocale(coin as any)} Earnings`,
        description: "See your most recent earnings on Bitmama",
        pointerEvents: "auto",
        icon: (props: any) => <Box as={icon} {...props} />,
        ml: "0",
      },
    };

    return map[styleType];
  }, [styleType, amount, coin, colorMode, icon]);

  return (
    <Button
      variant="unstyled"
      borderRadius="12px"
      w="100%"
      pos="relative"
      p={{ base: "28px 18px", "1sm": "28px 32px" }}
      bg={vprops.bg}
      overflow="hidden"
      my="14px"
      minH="fit-content"
      minW="fit-content"
      h="fit-content"
      display="block"
      pointerEvents={vprops.pointerEvents as any}
      _focus={{ shadow }}
      onClick={onClick}
      {...xprops}
    >
      <Box pos="absolute" top="50%" right="20px" transform="translateY(-50%)">
        {vprops.showArrow && (
          <Box as={ArrowRightIcon} color={switchStyle(colorMode, { dark: "secondary.400", light: "primary.default" })} />
        )}
        {!vprops.showArrow && (
          <Text fontSize="16px" textTransform="none">
            {vprops.description}
          </Text>
        )}
      </Box>

      <HStack>
        <Box as={vprops.icon} boxSize="32px" color={(vprops as any)?.iconColor} />

        <HStack alignItems="flex-start" color={vprops.color} flexDir={(vprops?.dir as any) ?? "row"}>
          <Text fontSize="14px" fontWeight="400" fontFamily="var(--bitmama-fonts-heading)" textTransform="none">
            {vprops.label}
          </Text>
          {vprops.showArrow && (
            <Text mt="0px !important" fontSize="14px" fontWeight="500" ml={`${vprops?.ml ?? 0} !important`} textTransform="none">
              {vprops.description}
            </Text>
          )}
        </HStack>
      </HStack>
    </Button>
  );
}
