import { ChakraProvider, createLocalStorageManager } from "@chakra-ui/react";
import { ThemeProvider } from "@mui/material";
import { ErrorBoundary } from "components";
import { CommandProvider, ErrorProvider, ModalsProvider, RegisterProgressProvider, TourGuideProvider } from "contexts";
import { AppConfigProvider } from "contexts/appconfig.context";
import { Provider as ReduxProvider } from "react-redux";
import store from "store";
// import SuccessProvider from "contexts/success.context";
import theme from "theme";
import muiTheme from "theme/muiTheme";

const manager = createLocalStorageManager("cachedTheme");

export default function Providers({ children }: any) {
  // const { actions } = useErrorStore();
  return (
    <ErrorBoundary>
      <ThemeProvider theme={muiTheme}>
        <ChakraProvider resetCSS theme={theme} colorModeManager={manager}>
          <ReduxProvider store={store}>
            <CommandProvider>
              <ErrorProvider>
                <AppConfigProvider>
                  <RegisterProgressProvider>
                    <TourGuideProvider>
                      <ModalsProvider>{children}</ModalsProvider>
                    </TourGuideProvider>
                  </RegisterProgressProvider>
                </AppConfigProvider>
              </ErrorProvider>
            </CommandProvider>
          </ReduxProvider>
        </ChakraProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
