import { Box, Button, Heading, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useGetGreenboxDetailsByCodeQuery, usePreClaimGreenboxMutation } from "apis";
import { RocketSVG } from "assets";
import { AbstractModal, EmptyCrate, Icon, IconNames, PageLoading, Title, TitleBar, useAbstractModal } from "components";
import configs from "config";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth, useSelector } from "hooks";
import capitalize from "lodash/capitalize";
import { useCallback, useMemo, useState } from "react";
import { toPrecision, when } from "utils";

import { selectUser } from "store/slices";
import ls from "utils/secureStorage";

const patternUrl: string = require("../../../assets/svg/greenbox-preclaim-pattern.svg").default;

// console.log("Pattern URL", patternUrl);

interface ClaimGreenBoxModalProps {
  isOpen: boolean;
  onClose: () => void;
}

// interface IState {
//   code: string;
//   success: boolean;
// }

export default function ClaimGreenBoxModal(props: ClaimGreenBoxModalProps) {
  const { isOpen, onClose } = props;

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "600px",
        // borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        borderRadius: "0px",
        position: { base: "absolute", "1sm": "initial" },
        padding: "20px",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
        overflow: "hidden",
        bg: `url("${patternUrl}")`,
        bgColor: "primary.default",
        backgroundSize: "cover",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <MainView {...props} />
      <SuccessView {...props} />
    </AbstractModal>
  );
}

function MainView(props: any) {
  const { onClose } = props;

  // const { code } = useParams();
  // const toast = useToast();
  const { auth } = useAuth();
  const { profile } = useSelector(selectUser);
  const { updateModal } = useAbstractModal();
  const [preclaimState, setPreclaimState] = useState<{
    status: "" | "preclaimed" | "claimed";
    claimLock: string;
    date: string;
  }>({
    status: "",
    claimLock: "",
    date: "0",
  });

  const isPreclaimed = useMemo(() => {
    return preclaimState.status === "preclaimed";
  }, [preclaimState.status]);

  const claimable: { code: string; claimLock?: string } = useMemo(() => {
    let _obj = ls.get(configs.GREENBOX_CODE_CACHE);
    if (!!_obj && typeof _obj === "string") _obj = JSON.parse(_obj) as { code: string; claimLock?: string };
    return _obj ?? null;
  }, []);

  const code = useMemo(() => window.location.pathname?.split("gbx/")[1] ?? claimable?.code ?? null, [claimable]);
  console.log("Code", code);

  const [preclaim, { isLoading: isPreclaiming }] = usePreClaimGreenboxMutation();

  const { data: greenbox, isLoading } = useGetGreenboxDetailsByCodeQuery(code, { skip: !code });

  console.log("Greenbox info", code, greenbox);

  const isGreenboxOwner = useMemo(() => greenbox?.userDetails?.userId === profile?._id, [greenbox, profile]);

  const isDisabled = useMemo(() => !code || isLoading || isPreclaiming, [code, isLoading, isPreclaiming]);

  const handleClose = () => {
    onClose();
    navigate(when(!!auth?.isSignedIn, `/greenbox`, `/login?rto=/greenbox`), { replace: true });
    ls.remove(configs.GREENBOX_CODE_CACHE);
  };

  const handleSignIn = useCallback(() => {
    onClose();
    navigate(when(!!auth?.isSignedIn, `/greenbox`, `/signup?rto=/greenbox`), { replace: true });
  }, [auth?.isSignedIn, onClose]);

  const openSuccessView = () => {
    updateModal &&
      updateModal({
        isSuccess: true,
        success: {
          // data,
          description: `You have received a Greenbox`,
        },
      });
  };

  const handleClaim = async () => {
    if (!greenbox || (greenbox && ["expired", "claimed", "cancelled"].includes(greenbox?.status))) {
      const coin = greenbox?.unit ?? "usdt";
      const path = when(!!auth?.isSignedIn, `/greenbox?coin=${coin}&subpage=SendAGreenBox`, "/login");
      // if (!!code && !auth?.isSignedIn) ls.set(configs.GREENBOX_CODE_CACHE, code);
      navigate(path, { replace: true });
      onClose();
      return;
    }

    if (!code) return;

    // the claimable object only exists when a user preclaimed while authorized.
    // it contains the greenbox code and claimLock required for the user to claim the greenbox.
    const date = new Date().toISOString();
    const result = await preclaim({ ...claimable, code }).unwrap();
    if (!!result) {
      console.log("Claim Result", result);
      if (!!result.claimLock) {
        const claimStatus = result?.isPreClaimed ? "preclaimed" : "claimed";
        ls.set(configs.GREENBOX_CODE_CACHE, JSON.stringify({ code, claimLock: result.claimLock, date, status: claimStatus }));
        setPreclaimState((prev) => ({
          ...prev,
          claimLock: String(result.claimLock || ""),
          date,
          status: claimStatus,
        }));
      }
      // when<any>(!!auth?.isSignedIn, openSuccessView(), navigate("/login", { replace: true }));
      if (!auth?.isSignedIn) {
        // toast({
        //   title: "Success",
        //   description: "You've successfully pre-claimed the greenbox",
        //   status: "success",
        //   duration: 3000,
        //   isClosable: true,
        //   position: "bottom-right",
        // });
        // navigate(`/login`, { replace: true });
        // onClose();
        // openSuccessView();
      }
      if (!!auth?.isSignedIn) openSuccessView();
    }
  };

  const bg = useColorModeValue("white", "dark.bg");

  const iconName = useMemo(() => {
    const map: Record<string, IconNames> = {
      [greenbox?.status ?? "active"]: "roundedGift",
      expired: "sadMoji",
      cancelled: "sadMoji",
      claimed: "sadMoji",
    };

    return map[greenbox?.status ?? "active"];
  }, [greenbox?.status]);

  const defaultDescription = useMemo(
    () =>
      when(
        !!auth?.isSignedIn,
        when(
          isGreenboxOwner,
          "You can't claim your own greenbox!",
          `Create your own GreenBox and share with your friends and family.`
        ),
        when(
          isPreclaimed,
          `Sign-in or create an account now to claim the gift before your reservation expires`,
          `Sign-in or create an account on Bitmama now to claim other gifts`
        )
      ),
    [auth?.isSignedIn, isGreenboxOwner, isPreclaimed]
  );

  const timeoutText = useMemo(() => when(isPreclaimed, ``, `Pre-claim your gift now before it is exhausted.`), [isPreclaimed]);

  const info = useMemo(() => {
    const map: Record<
      "pending" | "claimed" | "active" | "expired" | "cancelled",
      { title: string; description?: string; mainDescription?: string }
    > = {
      pending: { title: "This GreenBox is not yet active" },
      active: {
        title: when(!!auth?.isSignedIn, "You have received a Bitmama GreenBox!", ""),
        description: when(
          !!auth?.isSignedIn,
          `Claim your ${toPrecision(greenbox?.product?.amtPerUser!, greenbox?.unit!)} gift into your wallet before it expires`,
          defaultDescription
        ),
        mainDescription: when(
          isPreclaimed,
          ``,
          `@${greenbox?.userDetails?.username} is sending you and ${(greenbox?.product?.totalRecipients ?? 0) - 1} others a (${
            greenbox?.product?.title
          }) greenbox of ${toPrecision(+(greenbox?.product?.amtPerUser ?? 0), greenbox?.unit!)}`
          //   `There is a ${toPrecision(greenbox?.product?.amtPerUser!, greenbox?.unit!)} gift inside of this Greenbox`
        ),
      },
      expired: {
        title: `This GreenBox has been ${capitalize(greenbox?.status)}!`,
        description: defaultDescription,
      },
      cancelled: {
        title: `This GreenBox has been ${capitalize(greenbox?.status)}!`,
        description: defaultDescription,
      },
      claimed: {
        title: `This GreenBox has been ${capitalize(greenbox?.status)}!`,
        description: defaultDescription,
      },
    };

    return map[(greenbox?.status as "active") ?? "active"];
  }, [greenbox, isPreclaimed, defaultDescription, auth?.isSignedIn]);

  const submitButtonInfo = useMemo(() => {
    const map: Record<string, { label: string; disabled?: boolean }> = {
      active: {
        label: when(!!auth?.isSignedIn, "Claim Greenbox", when(isPreclaimed, `Continue`, "Pre-claim it now!")),
        disabled: isGreenboxOwner,
      },
      pending: { label: "Pending Activation", disabled: true },
      expired: { label: when(!!auth?.isSignedIn, "Create New", "Get Started") },
      claimed: { label: when(!!auth?.isSignedIn, "Create New", "Get Started") },
      cancelled: { label: when(!!auth?.isSignedIn, "Create New", "Get Started") },
      default: { label: when(!!auth?.isSignedIn, "Create New", "Get Started") },
    };

    return map[greenbox?.status ?? "default"];
  }, [greenbox?.status, isPreclaimed, auth?.isSignedIn, isGreenboxOwner]);

  return (
    <Box minH="570px" bg={bg} borderRadius={{ base: "30px 30px 0px 0px", "1sm": "14px" }}>
      {/* <Box as={GreenPreClaimPatternSVG} /> */}

      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={when(isLoading, "loading", "loaded")}
          initial={{ opacity: 0, scale: 0.98 }}
          exit={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <VStack>
            {isLoading && !greenbox && <PageLoading my="140px !important" isLoading={isLoading} />}

            {!isLoading && !!greenbox && !isGreenboxOwner && (
              <VStack p="48px 68px">
                <Icon boxSize="54px" type={iconName} color="secondary.500" />

                {(["expired", "cancelled"].includes(greenbox?.status! ?? "active") || !auth?.isSignedIn) && (
                  <Title
                    my="20px !important"
                    lineHeight="28.8px"
                    textAlign="center"
                    fontSize="24px"
                    fontWeight="700"
                    fontFamily="var(--bitmama-fonts-heading)"
                  >
                    {when(
                      !auth?.isSignedIn,
                      when(
                        isPreclaimed,
                        "You are almost there 🎊 ",
                        when(!!greenbox && !["expired", "claimed", "cancelled"].includes(greenbox?.status), "Hurray 🎊", "Oops!")
                      ),
                      "Ooops!"
                    )}
                  </Title>
                )}

                <TitleBar maxW="280px">
                  <Title
                    lineHeight="28.8px"
                    textAlign="center"
                    fontSize="24px"
                    fontWeight="700"
                    fontFamily="var(--bitmama-fonts-heading)"
                  >
                    {when(isPreclaimed, "", info?.title)}
                  </Title>
                </TitleBar>

                <VStack
                  maxW="380px"
                  mt={when(!auth?.isSignedIn, "0px !important", "32px !important")}
                  mb="50px !important"
                  textAlign="center"
                >
                  {!!info?.mainDescription && (
                    <Text fontSize="lg" fontWeight="700">
                      {info?.mainDescription}
                    </Text>
                  )}

                  {!!info?.description && (
                    <Text mt="54px !important" fontSize="18px" fontWeight="500">
                      {info?.description}
                    </Text>
                  )}
                </VStack>

                {!auth?.isSignedIn && greenbox?.status === "active" && (
                  <Text fontSize="sm" fontWeight="500" color="error">
                    {timeoutText}
                  </Text>
                )}

                <VStack maxW="320px" w="100%">
                  <Button
                    minW="unset"
                    minH={{ base: "55px", md: "74px" }}
                    maxW="unset"
                    maxH="fit-content"
                    w="100%"
                    onClick={when(isPreclaimed, handleSignIn, handleClaim)}
                    isLoading={isPreclaiming}
                    disabled={submitButtonInfo?.disabled || isDisabled}
                    _hover={{}}
                  >
                    {submitButtonInfo?.label}
                  </Button>
                  <Button
                    minW="unset"
                    minH={{ base: "55px", md: "74px" }}
                    maxW="unset"
                    w="100%"
                    variant="outline"
                    textDecoration="underline"
                    onClick={handleClose}
                    disabled={isPreclaiming || isLoading}
                    _hover={{}}
                  >
                    Close
                  </Button>
                </VStack>
              </VStack>
            )}

            {((!isLoading && !greenbox) || (!isLoading && isGreenboxOwner)) && (
              <VStack p="48px 68px">
                {/* <Icon boxSize="54px" type={"sadMoji"} color="secondary.500" /> */}

                <Title
                  //   my="20px !important"
                  lineHeight="28.8px"
                  textAlign="center"
                  fontSize="24px"
                  fontWeight="700"
                  fontFamily="var(--bitmama-fonts-heading)"
                >
                  Ooops!
                </Title>

                <EmptyCrate py="0px" type="greenbox" description={when(isGreenboxOwner, "", "Greenbox not found")} />

                <Text my="40px !important" maxW="320px" textAlign="center" fontSize="18px" fontWeight="500">
                  {defaultDescription}
                </Text>

                <VStack maxW="320px" w="100%">
                  <Button
                    minW="unset"
                    minH={{ base: "55px", md: "74px" }}
                    maxW="unset"
                    maxH="fit-content"
                    w="100%"
                    onClick={handleClaim}
                    isLoading={isPreclaiming}
                    disabled={submitButtonInfo?.disabled || isDisabled}
                    _hover={{}}
                  >
                    {submitButtonInfo?.label}
                  </Button>
                  <Button
                    minW="unset"
                    minH={{ base: "55px", md: "74px" }}
                    maxW="unset"
                    w="100%"
                    variant="outline"
                    textDecoration="underline"
                    onClick={handleClose}
                    disabled={isPreclaiming || isLoading}
                    _hover={{}}
                  >
                    Close
                  </Button>
                </VStack>
              </VStack>
            )}
          </VStack>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}

function SuccessView(props: ClaimGreenBoxModalProps) {
  const { onClose } = props;

  // const { modalState } = useAbstractModal();

  const bg = useColorModeValue("white", "dark.bg");

  const handleClose = () => {
    onClose();
    ls.remove(configs.GREENBOX_CODE_CACHE);
    navigate(`/greenbox`, { replace: true });
  };

  // console.log("Modal State", modalState);

  return (
    <VStack bg={bg} p={{ base: "20px 24px", "2sm": "48px" }} borderRadius={{ base: "30px 30px 0px 0px", "1sm": "14px" }}>
      <Box mb="24px !important" as={RocketSVG} />

      <Heading
        mt={{ base: "0 !important", "2sm": "initial" }}
        as="h4"
        fontSize="24px"
        fontWeight="600"
        mb={{ base: "20px !important", "2sm": "30px !important" }}
      >
        Success
      </Heading>

      <VStack mt="0 !important">
        <Text
          maxW="320px"
          mb={{ base: "20px !important", "2sm": "40px !important" }}
          color="brand.greyText"
          fontWeight="500"
          textAlign="center"
        >
          You have received a Greenbox
        </Text>
      </VStack>

      <VStack
        px={{ base: "20px", "2sm": "108px" }}
        mb={{ base: "20px !important", "2sm": "40px !important" }}
        w="100%"
        justifyContent="center"
      >
        <Button
          variant="solid"
          minW="unset"
          maxW="unset"
          w="100%"
          onClick={handleClose}
          rightIcon={<Icon type="claimButtonIcon" color="grey.50" />}
          _hover={{}}
        >
          View your Greenbox Wallet
        </Button>
        <Button variant="outline" minW="unset" maxW="unset" w="100%" onClick={handleClose}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

SuccessView.displayName = "AbstractModalSuccessView";
