import { Button, FormControl, Stack, useDisclosure, useUpdateEffect, VStack } from "@chakra-ui/react";
import { useBuyCryptoRateMutation } from "apis";
import { CryptoTxInput, FormInfo, InputLabel, Option, PageLoading } from "components";
import { isTesting } from "config";
import { useSidePage } from "contexts";
import { useCoin, usePartialState, useTicker, useWalletBalance } from "hooks";
import { useCurrency } from "hooks/useCurrency";
import useRedebounce from "hooks/useDebounce";
import { PageProps } from "interfaces";
import { capitalize } from "lodash";
import { useMemo } from "react";
import BuyCryptoPreview from "ui/Dashboard/Modals/BuyCryptoPreview";
import { currencyFormat, toCoinLocale } from "utils";

interface BuyCryptoFormProps extends PageProps {}

export interface IState {
  fiat: string;
  crypto: string;
  changeRef: "fiat" | "crypto";

  rate: number;
  fiatAmount: string;
  cryptoAmount: string;
}

export default function BuyCryptoForm(props: BuyCryptoFormProps) {
  const { /*user,*/ isFetchingUser } = props;

  const redebounce = useRedebounce();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { params } = useSidePage();

  const crypto_param = useMemo(() => {
    const value = params?.crypto;
    if (!value || value === "btc") return isTesting ? "tbtc" : "btc";
    return value;
  }, [params]);

  const currency = useCurrency();

  // const defaultFiat = selectCountryCurrency(user?.country ?? "nigeria");

  const [state, set, reset] = usePartialState<IState>(
    {
      crypto: crypto_param,
      fiat: currency,
      changeRef: "fiat",
    },
    [currency]
  );

  const { fiats, cryptos, getBalance, isLoading: isWalletLoading } = useWalletBalance();
  const [_buyCryptoRate, { data: buyRate, isLoading: isLoadingRate }] = useBuyCryptoRateMutation();

  // const cryptos = useMemo(
  //   () =>
  //     _cryptos
  //       .map((asset) => {
  //         if (["tbtc", "teth"].includes(asset?.coin))
  //           return {
  //             ...asset,
  //             coin: asset.coin.slice(1),
  //           };
  //         else return asset;
  //       })
  //       .sort((a, b) => a.coin.localeCompare(b.coin)),
  //   [_cryptos]
  // );

  // console.log("Cryptos", _cryptos, cryptos);

  const { getWalletInfo } = useCoin();

  const { ticker, getTicker } = useTicker(state?.crypto!, state?.fiat!);
  const rate = useMemo(() => buyRate?.rate, [buyRate]);
  const fee = useMemo(() => buyRate?.fee, [buyRate]);

  const label = useMemo(() => getWalletInfo((state?.fiat ?? "ngn") as any)?.label, [getWalletInfo, state?.fiat]);
  const balance = useMemo(() => getBalance((state?.fiat ?? "ngn") as any) ?? 0, [getBalance, state?.fiat]);
  const isInsufficientBalance = useMemo(() => !!state?.fiatAmount && +state?.fiatAmount > +balance, [state?.fiatAmount, balance]);
  const isPageLoading = useMemo(() => isFetchingUser || isWalletLoading, [isFetchingUser, isWalletLoading]);
  const isDisabled = useMemo(
    () =>
      !(state?.fiat && state?.fiatAmount && state?.crypto && state.cryptoAmount) ||
      isInsufficientBalance ||
      isPageLoading ||
      isLoadingRate,
    [state, isPageLoading, isLoadingRate, isInsufficientBalance]
  );

  const checkBuyRate = (price: number, _ticker?: string, _?: { fiat?: string; coin?: string }) => {
    const reqData = {
      coin: _?.coin ?? state?.crypto,
      currency: _?.fiat ?? state?.fiat,
      buyPrice: price,
      price: price,
      ticker: _ticker ?? ticker,
    };

    _buyCryptoRate({ ...reqData });
  };

  const handleFiat = (value: string) => {
    set({ fiat: value, changeRef: "fiat" });
    if (+(state?.fiatAmount ?? 0) <= 0) return;
    const ticker = getTicker(state?.crypto ?? "btc", value);
    redebounce(() => checkBuyRate(1, ticker, { fiat: value }), "checkBuyRate", 500)();
  };

  const handleCrypto = (value: string) => {
    set({ crypto: value, changeRef: "fiat" });
    if (+(state?.cryptoAmount ?? 0) <= 0) return;
    const ticker = getTicker(value, state?.fiat ?? "ngn");
    redebounce(() => checkBuyRate(+state?.cryptoAmount!, ticker, { coin: value }), "checkBuyRate", 500)();
  };

  const handleFiatAmount = (value: string) => {
    set({ fiatAmount: value, changeRef: "fiat" });
    redebounce(() => checkBuyRate(+value), "checkBuyRate", 500)();
  };

  const handleCryptoAmount = (value: string) => {
    set({ cryptoAmount: value, changeRef: "crypto" });
    redebounce(() => checkBuyRate(1), "checkBuyRate", 500)();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onOpen();
  };

  // track 'fiat' | 'crypto' input changes and calc the
  // the coresponding rate, then update the input field value;
  useUpdateEffect(() => {
    if (rate && state?.fiatAmount && state?.changeRef === "fiat") {
      set({ rate, cryptoAmount: String(parseFloat(state?.fiatAmount) / rate) });
    }
    if (rate && state?.cryptoAmount && state?.changeRef === "crypto") {
      set({ rate, fiatAmount: String(parseFloat(state?.cryptoAmount) * rate) });
    }
  }, [rate, state?.fiatAmount, state?.cryptoAmount, state?.changeRef]);

  return (
    <>
      {isPageLoading && <PageLoading isLoading={isPageLoading} />}
      {!isPageLoading && (
        <Stack as="form" py="52px" onSubmit={handleSubmit}>
          <FormControl>
            <InputLabel htmlFor="receive">You Receive</InputLabel>
            <CryptoTxInput
              id="receive"
              placeholder={`Enter ${toCoinLocale(state?.crypto ?? "btc")} Amount`}
              coinValue={state?.crypto}
              value={state?.cryptoAmount ?? ""}
              onCoinChange={(coin) => handleCrypto(coin)}
              onChange={(e) => handleCryptoAmount(e.target.value)}
              // min={0}
            >
              {(cryptos ?? [])?.map((crypto) => (
                <Option key={`buy-crypto-${crypto?.coin}`} value={crypto?.coin} coin={crypto?.coin as any}>
                  {toCoinLocale(crypto?.coin)}
                </Option>
              ))}
            </CryptoTxInput>
          </FormControl>
          <FormControl mt="40px !important">
            <InputLabel htmlFor="buy">You Pay</InputLabel>
            <CryptoTxInput
              id="buy"
              isLoading={isLoadingRate}
              isInvalid={isInsufficientBalance}
              placeholder={`${capitalize(label)} Equivalent`}
              coinValue={state?.fiat}
              value={state?.fiatAmount ?? ""}
              onMax={() => handleFiatAmount(String(Math.floor(balance)))}
              onCoinChange={(coin) => handleFiat(coin)}
              onChange={(e) => handleFiatAmount(e.target.value)}
              // min={0}
              // _rightEl={{
              //   right: { base: "99px", "2sm": "118px" },
              // }}
            >
              {(fiats ?? [])?.map((fiat) => (
                <Option key={`buy-crypto-${fiat?.coin}`} value={fiat?.coin} coin={fiat?.coin as any}>
                  {toCoinLocale(fiat?.coin)}
                </Option>
              ))}
            </CryptoTxInput>

            <FormInfo
              isInvalid={isInsufficientBalance}
              mt="10px"
              info="Available Balance: "
              description={`${currencyFormat(state?.fiat! as any).format(balance)}`}
            />
          </FormControl>

          <VStack mt="60px !important">
            <Button type="submit" isDisabled={isDisabled} minW={{ base: "100%", smx: "330px", "4sm": "400px" }}>
              Buy
            </Button>
          </VStack>
        </Stack>
      )}

      <BuyCryptoPreview state={state} rate={rate} fee={fee} isOpen={isOpen} onClose={onClose} reset={reset} />
    </>
  );
}
