import { Box, BoxProps, Divider, HStack, Heading, ModalProps, Progress, Stack, Text, VStack } from "@chakra-ui/react";

import { AbstractModal, BadgeIcon, ConditionalRender, LevelFrame } from "components";
import { useColor } from "hooks";
import { BadgeRo, BadgeUserLevelRo } from "interfaces";
import { useMemo } from "react";
import { when } from "utils";

interface BadgeDetailsModalProps extends Omit<ModalProps, "children"> {
  isLocked?: boolean;
  details?: BadgeRo;
  imgUrl?: string;
}

export default function BadgeDetailsModal(props: BadgeDetailsModalProps) {
  const { isLocked, details: d, imgUrl, ...xprops } = props;
  //   const bColor = useColorModeValue("grey.150", "dark.border");

  //   const handleRemind = (e: any) => {
  //     e.preventDefault();
  //     ls.set(configs.TWO_FA_REMIND, "remind");
  //     props.onClose();
  //   };

  const has_many_levels = useMemo(() => (d?.gameDetails?.levelTarget?.length ?? 1) > 1, [d?.gameDetails]);
  const has_single_level = useMemo(() => (d?.gameDetails?.levelTarget?.length ?? 1) === 1, [d?.gameDetails]);

  const next_level = useMemo(() => {
    const level = (d?.gameDetails?.levelTarget ?? []).find((l) => +(l?.level ?? 1) === (d?.nextLevel ?? d?.lastLevel));
    return level;
  }, [d]);

  const level_progress = (
    ul?: BadgeUserLevelRo & { img?: string; target: number; unit: string; current_level: number; next_level: number }
  ) => {
    const {
      point = 0,
      target = 100,
      unit = "USD",
      level = "1",
      // current_level = 1,
      // next_level = 1,
      progressStatus = "pending",
      img,
    } = ul ?? {};

    console.log(`Level ${level}`, { point, target, level });

    const curr_point = +point.toFixed(2);
    const max_point = Math.floor(target);
    const is_completed = progressStatus === "completed";
    // const is_current_level = current_level === +level || next_level === +level;
    const is_current_level = progressStatus === "started";

    const in_progress_text = `${when(
      is_current_level,
      curr_point.toLocaleString(),
      "-"
    )} / ${max_point.toLocaleString()} ${unit} to level ${level}`;

    return {
      max_point,
      curr_point: when(is_current_level || is_completed, curr_point, 0),
      text: when(is_completed, `Level ${level} Unlocked`, in_progress_text),
      is_locked: curr_point < max_point,
      is_current_level,
      img_url: img,
    };
  };

  const user_levels = useMemo(() => d?.userLevels?.data ?? [], [d?.userLevels]);
  const all_levels_unlocked = useMemo(() => (d?.gameDetails?.levelTarget ?? []).length <= (d?.userLevels?.data ?? []).length, [d]);

  const levels = useMemo(() => {
    const _l = d?.gameDetails?.levelTarget ?? [];
    return _l.map((l) => {
      const user_level = user_levels.find((ul) => ul?.level === l?.level);
      const params = {
        ...(user_level ?? {}),
        point: user_level?.point ?? d?.currentPoint,
        level: l?.level,
        target: l?.target ?? 100,
        unit: d?.gameDetails?.rewardUnit,
        current_level: d?.lastLevel ?? 1,
        next_level: d?.nextLevel ?? 1,
        img: when((l?.logoUrl ?? 0).length > 1, l?.logoUrl, undefined),
      } as any;

      const result = level_progress(params);
      console.log(d?.gameDetails?.label, { point: user_level?.point ?? d?.currentPoint, ...result });
      return result;
    });
  }, [user_levels, d]);

  const _description = useMemo(() => {
    const userLevels = d?.userLevels?.data ?? [];
    const is_next_level_completed =
      userLevels.findIndex((u) => u?.level === next_level?.level && u?.progressStatus === "completed") !== -1;

    if (!!all_levels_unlocked && !!next_level && !!next_level?.earnedDescription && is_next_level_completed)
      return next_level?.earnedDescription;
    else return next_level?.description ?? d?.description;
  }, [all_levels_unlocked, next_level, d]);

  // const is_on_last_level = useMemo(() => {
  //   const badge_levels_count = levels?.length ?? 1;
  //   const last_level = d?.lastLevel ?? 1;

  //   if (!!last_level && badge_levels_count === last_level) return true;
  //   return false;
  // }, [d, levels]);

  return (
    <AbstractModal
      _content={{
        maxW: "560px",
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        margin: 0,
        bottom: "0",
        // position: { base: "absolute", "1sm": "relative" },
      }}
      {...xprops}
    >
      <Box
        w="100%"
        p={{ base: "32px 40px", "1sm": "44px 40px" }}
        css={{
          "::-webkit-scrollbar": {
            display: "none",
          },

          scrollbarWidth: "none",
        }}
      >
        <VStack mb="20px !important">
          <Heading mb="10px !important" as="h5" fontSize="20px">
            {d?.gameDetails?.name ?? "New Bitstar Badge"}
          </Heading>

          <Box pos="relative">
            <BadgeIcon
              boxSize="112px"
              imgUrl={next_level?.logoUrl ?? imgUrl}
              badge="newBitStar"
              locked={isLocked}
              use_image={!!next_level?.logoUrl || !!imgUrl}
            />
            {!isLocked && !has_single_level && (
              <LevelFrame level={d?.lastLevel ?? 1} pos="absolute" bottom="-10px" left="50%" transform="translate(-50%, 0%)" />
            )}
          </Box>

          <Text maxW="248px" mt="14px !important" fontWeight="500" fontSize="md" textAlign="center">
            {_description ?? "You earned this badge for performing your first transaction on Bitmama"}
          </Text>
        </VStack>

        <Divider />

        <VStack mt="24px !important" px="46px" py="20px" w="100%" maxH="300px">
          <Heading mb="10px !important" as="h5" fontSize="20px">
            {when(has_many_levels, "Your Progress", when(all_levels_unlocked, "Badge Unlocked 🎉", "Your Progress"))}
          </Heading>

          <ConditionalRender shouldRender={has_many_levels || (has_single_level && !all_levels_unlocked)}>
            <Stack w="100%" gridGap="16px" pb="50px">
              {/* <LevelProgress isLocked={level_one?.is_locked} content={level_one?.text} progressLevel={+(level_one?.curr_prog ?? 0)} />
            <LevelProgress isLocked={level_two?.is_locked} content={level_two?.text} progressLevel={+(level_two?.curr_prog ?? 0)} />
            <LevelProgress
              isLocked={level_three?.is_locked}
              content={level_three?.text}
              progressLevel={+(level_three?.curr_prog ?? 0)}
            /> */}

              {levels?.map((l, i) => (
                <LevelProgress
                  key={i}
                  isLocked={l?.is_locked}
                  content={l?.text}
                  progressLevel={l?.curr_point}
                  maxProgress={l?.max_point}
                  isCurrentLevel={l?.is_current_level}
                  imgUrl={l?.img_url}
                />
              ))}
            </Stack>
          </ConditionalRender>
        </VStack>
      </Box>
    </AbstractModal>
  );
}

interface LevelProgressProps extends BoxProps {
  isLocked?: boolean;
  content?: string;
  progressLevel?: number;
  maxProgress?: number;
  isCurrentLevel?: boolean;
  imgUrl?: string;
}

function LevelProgress(props: LevelProgressProps) {
  const {
    isLocked = true,
    isCurrentLevel = false,
    content = "Level 1 Unlocked!",
    progressLevel = 100,
    maxProgress = 100,
    imgUrl,
    ...xprops
  } = props;
  const color = useColor();

  return (
    <HStack w="100%" gridGap="12px" {...xprops}>
      <Box pos="relative">
        <BadgeIcon boxSize="48px" imgUrl={imgUrl} use_image={!!imgUrl} badge="newBitStar" locked={isLocked} use_lock_icon />
        {/* {isLocked && (
          <Box
            boxSize="100%"
            pos="absolute"
            clipPath="polygon(50% 1%, 92% 25%, 92% 75%, 50% 99%, 7% 75%, 8% 25%)"
            bg="#ffffffa3"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
        )} */}
      </Box>
      <Stack w="100%">
        <Text fontWeight="500" color={when(isLocked && !isCurrentLevel, color("grey", "grey"), color("black", "white"))}>
          {content}
        </Text>
        <Progress
          h="8px"
          w="100%"
          // mt="22px"
          value={progressLevel}
          max={maxProgress}
          borderRadius="20px"
          bg={color("#EBEBEB", "#3635358f")}
          sx={{
            "[role=progressbar]": {
              bg: color("primary.default", "secondary.400"),
            },
          }}
        />
      </Stack>
    </HStack>
  );
}
