import { Box, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import { Rating } from "@mui/material";
import { Icon } from "components";

interface ReviewsPanelProps {}

export function ReviewsPanel(props: ReviewsPanelProps) {
  return (
    <Box my="26px" px="68px">
      <Heading as="h5" fontSize="18px">
        Ratings and Reviews (25)
      </Heading>

      <Stack my="30px" gridGap="18px">
        {Array(4)
          .fill(0)
          .map((_, i) => (
            <Review key={`review-${i}`} />
          ))}
      </Stack>
    </Box>
  );
}

function Review(props: any) {
  return (
    <Box p="14px 24px" bg="rgba(207, 218, 217, 0.3)" borderRadius="6px">
      <HStack justifyContent="space-between">
        <HStack>
          <Icon type="msg" />

          <Box ml="10px">
            <Text fontSize="14px" fontWeight="600" fontFamily="var(--bitmama-fonts-heading)">
              Sodiq
            </Text>
            <Text fontSize="14px" fontWeight="500">
              Nice Buyer. Fast selling and reasonable price.
            </Text>
          </Box>
        </HStack>

        <Stack alignSelf="flex-end" alignItems="flex-end">
          <Rating size="small" name="half-rating" defaultValue={2.5} precision={0.5} />
          <Text mt="0 !important" fontSize="14px" color="#797A7A" fontWeight="600">
            3/5 stars
          </Text>
        </Stack>
      </HStack>
    </Box>
  );
}
