import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Heading,
  HStack,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Input,
  VStack,
  Checkbox,
  useColorModeValue,
  FormHelperText,
  useMediaQuery,
  Center,
  useUpdateEffect,
} from "@chakra-ui/react";
import Recaptcha from "react-google-recaptcha";
// import GoogleLogin from "react-google-login";
import {
  FadeInOut,
  Group,
  Icon,
  InputLabel,
  Link,
  Logo,
  Span,
  Steps,
  Version,
  useModalView as useSteps,
  BackButton,
  RadixSelect,
  Option,
} from "components";

import BitcoinPNG from "assets/images/bitcoin.png";

import PasswordInput from "components/Input/PasswordInput";
import { useModals, useRegistrationProgress } from "contexts";
import { useColor, useDebounce, useDisclosures, useDispatch, usePartialState, useSelector, useSignupInputCheck } from "hooks";
import { resetAuth, selectAuth, signup } from "store/slices";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation } from "@reach/router";
import configs from "config";
import { useGetCountriesQuery } from "apis";
import { isEqual, join } from "lodash";
import { checkFirstLetter, getUTM, when } from "utils";
import { PageProps, SignUpDto } from "interfaces";
import { AuthImage, AuthProgress, PolicyTerms, WhyBVNModal } from "ui";
import { useAppConfig } from "contexts/appconfig.context";

type CheckableInputType = Partial<{ email: string; phone: string; username: string; bvn: string }>;
interface IFormState extends SignUpDto {
  current_step: number;
  confirmPassword: string;
}

interface SignUpProps extends PageProps {}
interface StepProps extends SignUpProps, IStateExtension {}

interface IStateExtension {
  set: (update: Partial<IFormState> | ((prev: Partial<IFormState>) => Partial<IFormState>)) => void;
  state: Partial<IFormState>;
}

export default function Signup(props: { path: string }) {
  const pColor = useColorModeValue("grey.500", "grey.150");
  const dividerColor = useColorModeValue("grey.300", "grey.800");
  const loginColor = useColorModeValue("primary.default", "secondary.500");
  const hColor = useColorModeValue("#303131", "white");
  const logoColor = useColorModeValue("primary.default", "secondary.500");

  const location = useLocation();
  const { appfigs } = useAppConfig();

  const { signupConfig } = appfigs ?? {};

  console.log("Signup Config", signupConfig, appfigs);

  const params = new URLSearchParams(location.search);
  const [form, set] = usePartialState<IFormState>({
    refCode: params.get("refCode") ?? "",
    acceptPromotions: true,
    current_step: 0,
  });

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  const [isMobile] = useMediaQuery("(max-width: 1023px)");

  const isNigeria = useMemo(() => ["nigeria"].includes(String(form?.country ?? "").toLowerCase()), [form?.country]);

  return (
    <Box
      minH="100vh"
      pos="relative"
      sx={{
        "--w": isLargerThan1080 ? "580px" : "480px",
      }}
    >
      <AuthImage isMobile={isMobile} />

      <Box pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
        <AuthProgress />
        <Group maxWidth="580px" margin="auto" padding={{ sm: "32px 20px 43px", "2sm": "80px 20px 43px" }} overflowY="scroll">
          <Logo fontSize="54px" mb={{ sm: "12px", "2sm": "52px" }} _name={{ w: "110px" }} color={logoColor} />

          <Box /*mb="40px !important"*/>
            <Box display="flex" alignItems="center">
              <Heading fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
                Hello! Create your Account{" "}
              </Heading>
              <Span>
                <Box as="img" boxSize="48px" src={BitcoinPNG} />
              </Span>
            </Box>
            {/* <HStack>
              <Text color={pColor} fontSize="18px" mt="14px">
                {when(
                  form?.current_step! === 0,
                  "Welcome to Bitmama! Let’s get started.",
                  "Fill in your details below to get started"
                )}
              </Text>
            </HStack> */}
          </Box>

          <Steps hideIndicator>
            <StepOne {...props} state={form} set={set} />

            {isNigeria && !!signupConfig?.enableBVNSignUp && <StepTwoNigeria {...props} state={form} set={set} />}
            {!isNigeria && !!signupConfig?.enableBVNSignUp && <StepTwoOthers {...props} state={form} set={set} />}

            {!signupConfig?.enableBVNSignUp && <StepTwoOthers {...props} state={form} set={set} />}
          </Steps>

          <Divider borderColor={dividerColor} />

          <VStack mt="0 !important">
            {/* <Text fontSize="14px" color={pColor}>
                OR
              </Text>

              <GoogleLogin
                clientId={configs.GOOGLE_CLIENT_ID}
                onSuccess={googleSignIn}
                onFailure={googleSignInFailed}
                cookiePolicy={"single_host_origin"}
                render={(props) => (
                  <GoogleButton
                    type="button"
                    text="Sign up with Google"
                    mt="14px !important"
                    onClick={props.onClick}
                    disabled={props?.disabled ?? isSigningInViaSocial}
                  />
                )}
              /> */}

            <Center fontWeight="600" mt="34px !important" flexWrap="wrap">
              <Text fontWeight="500" color={pColor}>
                Already have a Bitmama Account?{" "}
              </Text>
              <Link ml="14px !important" to="/login" color={loginColor} textDecoration="underline">
                Log In
              </Link>
            </Center>

            <PolicyTerms />

            <Version my="20px !important" />
          </VStack>
        </Group>
      </Box>
    </Box>
  );
}

function StepOne(props: StepProps) {
  const { state, set } = props;
  const { onNext } = useSteps();

  const { setProgress } = useRegistrationProgress();

  const pColor = useColorModeValue("grey.500", "grey.150");

  const { data: countries, isLoading } = useGetCountriesQuery();

  const sortedCountries = useMemo(
    () => [...(countries ?? [])].sort((a, b) => a?.countryLabel?.localeCompare(b?.countryLabel)),
    [countries]
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onNext();
    set({ current_step: 1 });
  };

  const isDisabled = useMemo(() => !state?.country || isLoading, [state?.country, isLoading]);

  useEffect(() => {
    setProgress(0);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <HStack mb="40px !important">
        <Text color={pColor} fontSize="18px" mt="14px">
          Welcome to Bitmama! Let’s get started.
        </Text>
      </HStack>

      <Stack p="4px" as="form" gridGap="24px" onSubmit={handleSubmit}>
        <Text fontWeight={500}>First of all, tell us which Country you are in</Text>

        <FormControl>
          <InputLabel isLoading={isLoading}>Select Country</InputLabel>
          {/* <Select
            isRequired
            placeholder="Select Country"
            value={state?.country}
            onChange={(e) => set({ country: e.target.value })}
            textTransform="capitalize"
          >
            {(countries ?? []).map((cn, i) => (
              <option key={`country-option-${i}`} value={cn?.country}>
                {cn?.countryLabel}
              </option>
            ))}
          </Select> */}

          <RadixSelect
            use_image_icon
            aria-label="Select Country"
            placeholder="Select Country"
            value={state?.country}
            onChange={(value) => set({ country: value })}
            _trigger={{ w: "100%", fontSize: { base: "14px", "2sm": "16px" } }}
          >
            {(sortedCountries ?? []).map((cn, i) => (
              <Option key={`country-${cn.country}`} value={cn?.country} image={cn?.flag}>
                {cn.countryLabel}
              </Option>
            ))}
          </RadixSelect>
        </FormControl>

        <VStack mt="60px !important" mb="66px !important">
          <Button
            minW="initial"
            maxW="399px"
            width="100%"
            rightIcon={<Icon type="arrowRight" color="white" />}
            type="submit"
            disabled={isDisabled}
          >
            Continue
          </Button>
        </VStack>
      </Stack>
    </>
  );
}

type ModalTypes = "whyBVN";
function StepTwoNigeria(props: StepProps) {
  const { state: form, set } = props;

  const { setProgress } = useRegistrationProgress();

  const { onOpen } = useModals();
  const { onPrev } = useSteps();

  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const pColor = useColorModeValue("grey.500", "grey.150");
  const ckbScheme = useColorModeValue("primary", "secondary");

  const location = useLocation();
  const debounce = useDebounce();
  const dispatch = useDispatch();

  const recaptchaRef = useRef<any>(null);
  const { status, checks } = useSelector(selectAuth);
  const params = new URLSearchParams(location.search);
  const { makeCheck, check } = useSignupInputCheck();

  const color = useColor();

  const { data: countries } = useGetCountriesQuery();

  // const [signInViaSocial, { isLoading: isSigningInViaSocial }] = useSignUpViaSocialMutation();

  // const passwordPattern = useMemo(() => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/, []);
  const passwordPattern = useMemo(() => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[=#@$!%*?&_-])[A-Za-z\d=#@$!%*?&_-]{8,}$/, []);
  const emailPattern = useMemo(() => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i, []);
  const usernamePattern = useMemo(() => /^([a-z0-9]*){4,}$/, []);
  // const namePattern = useMemo(() => /^[A-Za-z]+$/, []);
  // const usernamePattern = useMemo(() => /^([a-z0-9]+([._]?[a-z0-9]+)*){4,}$/, []);

  const country = useMemo(
    () => (!!form?.country ? countries?.find((c) => c.country === form?.country) : undefined),
    [form?.country, countries]
  );

  const isNigeria = useMemo(() => ["nigeria"].includes(String(country?.country ?? "").toLowerCase()), [country]);

  console.log("is Nigeria", isNigeria);

  const isPasswordValid = useMemo(
    () => !!form?.password && RegExp(passwordPattern).test(form?.password),
    [form?.password, passwordPattern]
  );

  const isPasswordMatch = useMemo(
    () => !!form?.password && !!form?.confirmPassword && form.password === form.confirmPassword,
    [form?.password, form.confirmPassword]
  );

  const isUsernameValid = useMemo(
    () =>
      !!form?.username &&
      checkFirstLetter(form?.username) &&
      RegExp(usernamePattern).test(form?.username) &&
      form.username.length >= 4,
    [form?.username, usernamePattern]
  );

  const isEmailValidFn = useCallback((email: string) => !!email && RegExp(emailPattern).test(email), [emailPattern]);
  const isEmailValid = useMemo(() => !!form?.email && isEmailValidFn(form?.email), [form?.email, isEmailValidFn]);

  const isBVNInvalid = useMemo(() => isNigeria && !!form?.bvn && form?.bvn.length < 11, [isNigeria, form?.bvn]);

  const isChecking = useCallback((type: "email" | "phone" | "username" | "bvn") => check(type).status === "fetching", [check]);
  const isAlreadyInUse = useCallback((type: "email" | "phone" | "username" | "bvn") => check(type).inUse, [check]);

  // eslint-disable-next-line
  const bvnData = useMemo(() => checks?.bvn?.bvnData, [checks?.bvn?.bvnData]);
  console.log("BVN Data", bvnData);

  // TODO: test email, phone and username before sending a check request
  const handleCheckableInput = useCallback(
    (update: CheckableInputType) => {
      set(update);

      if (!!update?.email && isEmailValidFn(update.email))
        debounce(() => makeCheck("email", update?.email!), "makeEmailCheck", 1000)();
      if (!!update?.phone) debounce(() => makeCheck("phone", update?.phone!), "makePhoneCheck", 1000)();
      if (!!update?.username && isUsernameValid)
        debounce(() => makeCheck("username", update?.username!), "makeUsernameCheck", 1000)();
      if (!!update?.bvn && update?.bvn?.length === 11) debounce(() => makeCheck("bvn", update?.bvn!), "makeBVNCheck", 500)();
    },
    [makeCheck, debounce, isUsernameValid, set, isEmailValidFn]
  );

  const attemptedSignup = useRef(false);
  const isSigningUp = useMemo(() => status === "signing_up", [status]);
  const isSuccess = useMemo(() => status === "success", [status]);

  const isDisabled = useMemo(
    () =>
      !(form?.email && form?.country && form?.password && form?.username && isPasswordValid && isUsernameValid && isEmailValid) ||
      isSigningUp ||
      isBVNInvalid ||
      isAlreadyInUse("bvn") ||
      isAlreadyInUse("email") ||
      isAlreadyInUse("username") ||
      isChecking("username") ||
      !isPasswordMatch,
    [form, isPasswordValid, isUsernameValid, isSigningUp, isBVNInvalid, isAlreadyInUse, isPasswordMatch, isEmailValid, isChecking]
  );

  useEffect(() => {
    if (!isSigningUp && isSuccess) {
      // console.log("GOT HERE, TO SUCCESS");
      onOpen("signup");
      dispatch(resetAuth());
      // navigate(configs.paths.dashboard);
    }
  }, [isSuccess, isSigningUp, onOpen, dispatch]);

  console.log("UTM", getUTM());

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log({ form });

    const utm = getUTM();

    const input = {
      ...form,
      firstName: bvnData?.firstName,
      lastName: bvnData?.lastName,
      phone: `${country?.countryCode}${bvnData?.phone?.slice(1)}`,
      qrRef: params.get("qrRef") ?? undefined,
      srcMode: params.get("srcMode") ?? utm?.src_mode ?? undefined,
      srcRef: params.get("srcRef") ?? utm?.src_ref ?? undefined,
    };

    if (navigator.onLine === true) {
      const captch = await recaptchaRef.current?.executeAsync();
      recaptchaRef.current?.reset();
      console.log(captch, "captch");
      captch && (attemptedSignup.current = true);
      dispatch(signup({ ...input, humanKey: captch }));
    }
  };

  console.log("Nigerian State", form);

  useEffect(() => {
    setProgress(1);

    // eslint-disable-next-line
  }, []);

  useUpdateEffect(() => {
    const phone = String(bvnData?.phone ?? "").slice(1);
    if (!isEqual(form?.phone, phone)) {
      handleCheckableInput({ phone });
    }
  }, [form?.phone, bvnData?.phone]);

  // const googleSignIn = async (res: any) => {
  //   const googleId = {
  //     idToken: res.tokenId,
  //   };
  //   const result = (await signInViaSocial(googleId)) as { data: any };
  //   console.log("Google Signup result", result);
  // };

  // const googleSignInFailed = (res: any) => console.log("GOOGLE SIGNIN FAILURE", res);

  return (
    <>
      <HStack mb="40px !important">
        <BackButton pos="unset" mt="4px" ml="4px" onClick={onPrev} />
        <Text color={pColor} fontSize="18px" mt="14px">
          Fill in your details below to get started
        </Text>
      </HStack>

      <Stack as="form" autoComplete="false" gridGap="24px" onSubmit={handleSubmit}>
        <FormControl>
          <InputLabel isInvalid={(!!form?.email && isAlreadyInUse("bvn")) || isBVNInvalid} isLoading={isChecking("bvn")}>
            BVN (Bank Verification Number)
          </InputLabel>
          <Input
            isRequired
            isInvalid={isBVNInvalid || (!!form?.email && isAlreadyInUse("bvn"))}
            variant="filled"
            placeholder="Enter Number"
            type="text"
            maxLength={11}
            value={form?.bvn ?? ""}
            onChange={(e) => handleCheckableInput({ bvn: e.target.value })}
          />

          <Box p="4px">
            <Link mt="8px" type="button" onClick={open("whyBVN")}>
              <HStack>
                <Icon type="infoRounded" boxSize="22px" color={color("primary.default", "secondary.500")} />
                <Text fontSize="13px" fontWeight="500" color={color("primary.default", "secondary.500")}>
                  Why we need your BVN
                </Text>
              </HStack>
            </Link>
            {!!form?.bvn && isBVNInvalid && <FormWarning info={"BVN is an 11-digit number"} isValid={!isBVNInvalid} />}
            {!!form?.bvn && !isChecking("bvn") && !isBVNInvalid && bvnData && (
              <FormWarning
                info={when(
                  isAlreadyInUse("bvn") && !isChecking("bvn"),
                  `BVN verification failed`,
                  `${join([bvnData?.firstName, bvnData?.lastName], " ")}`
                )}
                isValid={!isAlreadyInUse("bvn")}
              />
            )}
          </Box>
        </FormControl>

        <FormControl>
          <InputLabel isInvalid={(!!form?.email && isAlreadyInUse("email")) || !isEmailValid} isLoading={isChecking("email")}>
            Email Address
          </InputLabel>
          <Input
            isRequired
            isInvalid={(!!form?.email && isAlreadyInUse("email")) || !isEmailValid}
            variant="filled"
            placeholder="Enter Email"
            type="email"
            autoComplete="none"
            value={form?.email ?? ""}
            onChange={(e) => handleCheckableInput({ email: e.target.value })}
          />

          <Box>
            {!!form?.email && !isChecking("email") && isEmailValid && (
              <FormWarning
                info={when(isAlreadyInUse("email"), `Email already in use`, "Your email is good to go")}
                isValid={!isAlreadyInUse("email")}
              />
            )}
          </Box>
        </FormControl>

        <FormControl>
          <InputLabel isLoading={isChecking("username")}>Choose a Username</InputLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<Text fontFamily="var(--bitmama-fonts-heading)">@</Text>} />
            <Input
              isRequired
              pl="48px"
              variant="filled"
              type="text"
              value={form?.username ?? ""}
              pattern="^[a-z0-9]+([._]?[a-z0-9]+)*$"
              onChange={(e) => handleCheckableInput({ username: e.target.value })}
            />
          </InputGroup>

          <Box>
            {!!form?.username && form?.username?.length < 4 && (
              <FormWarning info="Minimum of 4 characters" isValid={!!form?.username && form?.username?.length >= 4} />
            )}
            {!!form?.username && form?.username?.length >= 4 && !isChecking("username") && isUsernameValid && (
              <FormWarning
                info={when(isAlreadyInUse("username"), `Username already in use`, "Your username is good to go")}
                isValid={!isAlreadyInUse("username")}
              />
            )}

            {!!form?.username && checkFirstLetter(form.username) && form?.username?.length >= 4 && !isUsernameValid && (
              <FormWarning info="Username must be an alphanumeric in lowercase" isValid={isUsernameValid} />
            )}

            {!!form?.username && !checkFirstLetter(form.username) && (
              <FormWarning info="Username must start with an alphabet" isValid={false} />
            )}
          </Box>
        </FormControl>

        {/* <FormControl>
        <InputLabel isLoading={isLoadingCountries}>Select Country</InputLabel>
        <Select isRequired placeholder="Choose an option" value={form?.country} onChange={(e) => set({ country: e.target.value })}>
          {(countries ?? []).map((cn, i) => (
            <option value={cn?.country}>{capitalize(cn?.country)}</option>
          ))}
        </Select>
      </FormControl> */}

        {/* <FormControl>
          <InputLabel>Phone Number</InputLabel>
          <InputGroup>
            {!!country && (
              <InputLeftElement
                pl="24px"
                pointerEvents="none"
                children={<Text fontFamily="var(--bitmama-fonts-heading)">{country?.countryCode}</Text>}
              />
            )}
            <Input
              isRequired
              type="number"
              disabled={!form?.country}
              pl={when(!!country, "70px", "18px")}
              variant="filled"
              value={form?.phone ?? ""}
              onChange={(e) => handleCheckableInput({ phone: e.target.value })}
            />
          </InputGroup>
        </FormControl> */}

        <FormControl>
          <InputLabel>Password</InputLabel>
          <PasswordInput
            isRequired
            isInvalid={!!form?.password && !isPasswordValid}
            placeholder="Enter Password"
            value={form?.password ?? ""}
            onChange={(e) => set({ password: e.target.value })}
            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          />

          {!!form?.password && form?.password?.length < 8 && (
            <FormWarning info="Minimum of 8 characters" isValid={form?.password?.length >= 8} />
          )}

          {!!form?.password && (
            <FormHelperText
              fontSize="sm"
              fontWeight="500"
              color={when(!isPasswordValid, "error", color("primary.700", "secondary.400"))}
            >
              <HStack>
                <FadeInOut key={`password-is-${when(isPasswordValid, "valid", "invalid")}`}>
                  {!isPasswordValid && <Icon type="shieldOutlined" color="inherit" />}
                  {isPasswordValid && <Icon type="verifiedUser" color="inherit" />}
                </FadeInOut>
                <Text>Password must contain Uppercase, Lowercase and a Special character</Text>
              </HStack>
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <InputLabel isInvalid={!isPasswordMatch}>Confirm Password</InputLabel>
          <PasswordInput
            isRequired
            isInvalid={!!form?.confirmPassword && !isPasswordMatch}
            placeholder="Enter Password"
            value={form?.confirmPassword ?? ""}
            onChange={(e) => set({ confirmPassword: e.target.value })}
            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          />

          {!!form?.confirmPassword && (
            <FormWarning info={when(!isPasswordMatch, "Passwords didn't match", "Passwords match")} isValid={isPasswordMatch} />
          )}
        </FormControl>

        <FormControl>
          <InputLabel>Referral Code (Optional)</InputLabel>
          <Input
            variant="filled"
            placeholder="Enter Code"
            value={form?.refCode ?? params.get("refCode") ?? ""}
            onChange={(e) => set({ refCode: e.target.value })}
          />

          <Checkbox
            size="lg"
            mt="20px"
            colorScheme={ckbScheme}
            alignItems="flex-start"
            isChecked={form?.acceptPromotions ?? false}
            onChange={(e) => set({ acceptPromotions: e.target.checked })}
            sx={{
              ".chakra-checkbox__label": { fontSize: "16px" },
              ".chakra-checkbox__control": { mt: "6px" },
            }}
          >
            Be the first to receive updates on new features, perks, product releases, discounts and offers
          </Checkbox>
        </FormControl>

        <VStack mt="60px !important" mb="66px !important">
          <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />;
          <Button
            minW="initial"
            maxW="399px"
            width="100%"
            rightIcon={<Icon type="arrowRight" color="white" />}
            type="submit"
            isLoading={isSigningUp}
            disabled={isDisabled}
          >
            Continue
          </Button>
        </VStack>
      </Stack>

      <WhyBVNModal isOpen={isOpen("whyBVN")} onClose={close("whyBVN")} />
    </>
  );
}

function StepTwoOthers(props: StepProps) {
  const { state: form, set } = props;

  const { onOpen } = useModals();
  const { onPrev } = useSteps();

  const { setProgress } = useRegistrationProgress();

  const color = useColor();

  const pColor = useColorModeValue("grey.500", "grey.150");
  const ckbScheme = useColorModeValue("primary", "secondary");

  const location = useLocation();
  const debounce = useDebounce();
  const dispatch = useDispatch();

  const recaptchaRef = useRef<any>(null);
  const { status } = useSelector(selectAuth);
  const params = new URLSearchParams(location.search);
  const { makeCheck, check } = useSignupInputCheck();

  const { data: countries } = useGetCountriesQuery();

  // const [signInViaSocial, { isLoading: isSigningInViaSocial }] = useSignUpViaSocialMutation();

  // const passwordPattern = useMemo(() => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/, []);
  const passwordPattern = useMemo(() => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[=#@$!%*?&_-])[A-Za-z\d=#@$!%*?&_-]{8,}$/, []);
  const usernamePattern = useMemo(() => /^([a-z0-9]*){4,}$/, []);
  const namePattern = useMemo(() => /^[A-Za-z]+$/, []);
  const emailPattern = useMemo(() => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i, []);

  // const usernamePattern = useMemo(() => /^([a-z0-9]+([._]?[a-z0-9]+)*){4,}$/, []);

  const country = useMemo(
    () => (!!form?.country ? countries?.find((c) => c.country === form?.country) : undefined),
    [form?.country, countries]
  );

  const isPasswordValid = useMemo(
    () => !!form?.password && RegExp(passwordPattern).test(form?.password),
    [form?.password, passwordPattern]
  );
  const isNameValid = useCallback(
    (field: keyof IFormState) => !!form[field] && RegExp(namePattern).test((form as any)[field]!),
    [form, namePattern]
  );

  const isPasswordMatch = useMemo(
    () => !!form?.password && !!form?.confirmPassword && form.password === form.confirmPassword,
    [form?.password, form.confirmPassword]
  );

  const isUsernameValid = useMemo(
    () => !!form?.username && checkFirstLetter(form?.username) && RegExp(usernamePattern).test(form?.username),
    [form?.username, usernamePattern]
  );

  const isEmailValidFn = useCallback((email: string) => !!email && RegExp(emailPattern).test(email), [emailPattern]);
  const isEmailValid = useMemo(() => !!form?.email && isEmailValidFn(form?.email), [form?.email, isEmailValidFn]);

  const isChecking = useCallback((type: "email" | "phone" | "username") => check(type).status === "fetching", [check]);
  const isAlreadyInUse = useCallback((type: "email" | "phone" | "username") => check(type).inUse, [check]);

  // eslint-disable-next-line
  // const bvnData = useMemo(() => checks?.bvn?.bvnData, [checks?.bvn?.bvnData]);
  // console.log("BVN Data", bvnData);

  // TODO: test email, phone and username before sending a check request
  const handleCheckableInput = useCallback(
    (update: CheckableInputType) => {
      set(update);

      if (!!update?.email && isEmailValidFn(update.email))
        debounce(() => makeCheck("email", update?.email!), "makeEmailCheck", 1000)();
      if (!!update?.phone) debounce(() => makeCheck("phone", update?.phone!), "makePhoneCheck", 1000)();
      if (!!update?.username && isUsernameValid)
        debounce(() => makeCheck("username", update?.username!), "makeUsernameCheck", 1000)();
    },
    [makeCheck, debounce, isUsernameValid, set, isEmailValidFn]
  );

  const attemptedSignup = useRef(false);
  const isSigningUp = useMemo(() => status === "signing_up", [status]);
  const isSuccess = useMemo(() => status === "success", [status]);

  const isDisabled = useMemo(
    () =>
      !(
        form?.firstName &&
        form?.lastName &&
        form?.email &&
        form?.country &&
        form?.password &&
        form?.phone &&
        form?.username &&
        isPasswordValid &&
        isUsernameValid &&
        isEmailValid
      ) ||
      isSigningUp ||
      isChecking("username") ||
      (!!form?.firstName && !isNameValid("firstName")) ||
      (!!form?.lastName && !isNameValid("lastName")) ||
      (!!form?.otherName && !isNameValid("otherName")) ||
      !isPasswordMatch,
    [form, isPasswordValid, isUsernameValid, isSigningUp, isNameValid, isEmailValid, isChecking, isPasswordMatch]
  );

  useEffect(() => {
    setProgress(1);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isSigningUp && isSuccess) {
      // console.log("GOT HERE, TO SUCCESS");
      onOpen("signup");
      dispatch(resetAuth());
      // navigate(configs.paths.dashboard);
    }
  }, [isSuccess, isSigningUp, onOpen, dispatch]);

  // console.log("UTM", getUTM());

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log({ form });

    const utm = getUTM();

    const input = {
      ...form,
      phone: `${country?.countryCode}${form?.phone}`,
      qrRef: params.get("qrRef") ?? undefined,
      srcMode: params.get("srcMode") ?? utm?.src_mode ?? undefined,
      srcRef: params.get("srcRef") ?? utm?.src_ref ?? undefined,
    };

    if (navigator.onLine === true) {
      const captch = await recaptchaRef.current?.executeAsync();
      recaptchaRef.current?.reset();
      console.log(captch, "captch");
      captch && (attemptedSignup.current = true);
      dispatch(signup({ ...input, humanKey: captch }));
    }
  };

  // const googleSignIn = async (res: any) => {
  //   const googleId = {
  //     idToken: res.tokenId,
  //   };
  //   const result = (await signInViaSocial(googleId)) as { data: any };
  //   console.log("Google Signup result", result);
  // };

  // const googleSignInFailed = (res: any) => console.log("GOOGLE SIGNIN FAILURE", res);

  return (
    <>
      <HStack mb="40px !important">
        <BackButton pos="unset" mt="4px" ml="4px" onClick={onPrev} />
        <Text color={pColor} fontSize="18px" mt="14px">
          Fill in your details below to get started
        </Text>
      </HStack>

      <Stack as="form" gridGap="24px" onSubmit={handleSubmit}>
        <Grid templateColumns="repeat(2, 1fr)" gap={{ sm: "17px", "3sm": "20px" }}>
          <FormControl>
            <InputLabel>First Name</InputLabel>
            <Input
              variant="filled"
              isRequired
              isInvalid={!!form?.firstName && !isNameValid("firstName")}
              placeholder="Enter First Name"
              value={form?.firstName ?? ""}
              pattern="^[A-Za-z]+$"
              onChange={(e) => set({ firstName: e.target.value })}
            />

            {!!form?.firstName && !isNameValid("firstName") && (
              <FormWarning
                info={when(!isNameValid("firstName"), `Firstname must be an alphabetic character`, "Your firstname is valid")}
                isValid={isNameValid("firstName")}
              />
            )}
          </FormControl>

          <FormControl>
            <InputLabel>Last Name</InputLabel>
            <Input
              isRequired
              variant="filled"
              isInvalid={!!form?.lastName && !isNameValid("lastName")}
              placeholder="Enter Last Name"
              pattern="^[A-Za-z]+$"
              value={form?.lastName ?? ""}
              onChange={(e) => set({ lastName: e.target.value })}
            />

            {!!form?.lastName && !isNameValid("lastName") && (
              <FormWarning
                info={when(!isNameValid("lastName"), `Lastname must be an alphabetic character`, "Your lastname is valid")}
                isValid={isNameValid("lastName")}
              />
            )}
          </FormControl>
        </Grid>

        <FormControl>
          <InputLabel>Other Names</InputLabel>
          <Input
            variant="filled"
            placeholder="Enter Other Name"
            value={form?.otherName ?? ""}
            isInvalid={!!form?.otherName && !isNameValid("otherName")}
            pattern="^[A-Za-z]+$"
            onChange={(e) => set({ otherName: e.target.value })}
          />

          {!!form?.otherName && !isNameValid("otherName") && (
            <FormWarning
              info={when(!isNameValid("otherName"), `Othername must be an alphabetic character`, "Your othername is valid")}
              isValid={isNameValid("otherName")}
            />
          )}
        </FormControl>

        <FormControl>
          <InputLabel isInvalid={(!!form?.email && isAlreadyInUse("email")) || !isEmailValid} isLoading={isChecking("email")}>
            Email Address
          </InputLabel>
          <Input
            isRequired
            isInvalid={(!!form?.email && isAlreadyInUse("email")) || !isEmailValid}
            variant="filled"
            placeholder="Enter Email"
            type="email"
            value={form?.email ?? ""}
            onChange={(e) => handleCheckableInput({ email: e.target.value })}
          />

          <Box>
            {!!form?.email && !isChecking("email") && isEmailValid && (
              <FormWarning
                info={when(isAlreadyInUse("email"), `Email already in use`, "Your email is good to go")}
                isValid={!isAlreadyInUse("email")}
              />
            )}
          </Box>
        </FormControl>

        <FormControl>
          <InputLabel isLoading={isChecking("username")}>Choose a Username</InputLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<Text fontFamily="var(--bitmama-fonts-heading)">@</Text>} />
            <Input
              isRequired
              pl="48px"
              variant="filled"
              type="text"
              value={form?.username ?? ""}
              pattern="^[a-z0-9]+([._]?[a-z0-9]+)*$"
              onChange={(e) => handleCheckableInput({ username: e.target.value })}
            />
          </InputGroup>

          <Box>
            {!!form?.username && form?.username?.length < 4 && (
              <FormWarning info="Minimum of 4 characters" isValid={!!form?.username && form?.username?.length >= 4} />
            )}
            {!!form?.username && form?.username?.length >= 4 && !isChecking("username") && isUsernameValid && (
              <FormWarning
                info={when(isAlreadyInUse("username"), `Username already in use`, "Your username is good to go")}
                isValid={!isAlreadyInUse("username")}
              />
            )}

            {!!form?.username && checkFirstLetter(form.username) && form?.username?.length >= 4 && !isUsernameValid && (
              <FormWarning info="Username must be an alphanumeric in lowercase" isValid={isUsernameValid} />
            )}

            {!!form?.username && !checkFirstLetter(form.username) && (
              <FormWarning info="Username must start with an alphabet" isValid={false} />
            )}
          </Box>
        </FormControl>

        {/* <FormControl>
          <InputLabel isLoading={isLoadingCountries}>Select Country</InputLabel>
          <Select
            isRequired
            placeholder="Choose an option"
            value={form?.country}
            onChange={(e) => set({ country: e.target.value })}
          >
            {(countries ?? []).map((cn, i) => (
              <option value={cn?.country}>{capitalize(cn?.country)}</option>
            ))}
          </Select>
        </FormControl> */}

        <FormControl>
          <InputLabel>Phone Number</InputLabel>
          <InputGroup>
            {!!country && (
              <InputLeftElement
                pl="24px"
                pointerEvents="none"
                children={<Text fontFamily="var(--bitmama-fonts-heading)">{country?.countryCode}</Text>}
              />
            )}
            <Input
              isRequired
              type="number"
              disabled={!form?.country}
              pl={when(!!country, "70px", "18px")}
              variant="filled"
              value={form?.phone ?? ""}
              onChange={(e) => handleCheckableInput({ phone: e.target.value })}
            />
          </InputGroup>
        </FormControl>

        <FormControl>
          <InputLabel>Password</InputLabel>
          <PasswordInput
            isRequired
            isInvalid={!!form?.password && !isPasswordValid}
            placeholder="Enter Password"
            value={form?.password ?? ""}
            onChange={(e) => set({ password: e.target.value })}
            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          />

          {!!form?.password && form?.password?.length < 8 && (
            <FormWarning info="Minimum of 8 characters" isValid={form?.password?.length >= 8} />
          )}

          {!!form?.password && (
            <FormHelperText
              fontSize="sm"
              fontWeight="500"
              color={when(!isPasswordValid, "error", color("primary.700", "secondary.400"))}
            >
              <HStack>
                <FadeInOut key={`password-is-${when(isPasswordValid, "valid", "invalid")}`}>
                  {!isPasswordValid && <Icon type="shieldOutlined" color="inherit" />}
                  {isPasswordValid && <Icon type="verifiedUser" color="inherit" />}
                </FadeInOut>
                <Text>Password must contain Uppercase, Lowercase and a Special character</Text>
              </HStack>
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <InputLabel isInvalid={!isPasswordMatch}>Confirm Password</InputLabel>
          <PasswordInput
            isRequired
            isInvalid={!!form?.confirmPassword && !isPasswordMatch}
            placeholder="Enter Password"
            value={form?.confirmPassword ?? ""}
            onChange={(e) => set({ confirmPassword: e.target.value })}
            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          />

          {!!form?.confirmPassword && (
            <FormWarning info={when(!isPasswordMatch, "Passwords didn't match", "Passwords match")} isValid={isPasswordMatch} />
          )}
        </FormControl>

        <FormControl>
          <InputLabel>Referral Code (Optional)</InputLabel>
          <Input
            variant="filled"
            placeholder="Enter Code"
            value={form?.refCode ?? params.get("refCode") ?? ""}
            onChange={(e) => set({ refCode: e.target.value })}
          />

          <Checkbox
            size="lg"
            mt="20px"
            colorScheme={ckbScheme}
            alignItems="flex-start"
            isChecked={form?.acceptPromotions ?? false}
            onChange={(e) => set({ acceptPromotions: e.target.checked })}
            sx={{
              ".chakra-checkbox__label": { fontSize: "16px" },
              ".chakra-checkbox__control": { mt: "6px" },
            }}
          >
            Be the first to receive updates on new features, perks, product releases, discounts and offers
          </Checkbox>
        </FormControl>

        <VStack mt="60px !important" mb="66px !important">
          <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />;
          <Button
            minW="initial"
            maxW="399px"
            width="100%"
            rightIcon={<Icon type="arrowRight" color="white" />}
            type="submit"
            isLoading={isSigningUp}
            disabled={isDisabled}
          >
            Continue
          </Button>
        </VStack>
      </Stack>
    </>
  );
}

export function FormWarning(props: { info: string; isValid: boolean }) {
  const { info, isValid } = props;

  const color = useColor();

  return (
    <FormHelperText fontSize="sm" fontWeight="500" color={when(!isValid, "error", color("primary.700", "secondary.400"))}>
      <HStack alignItems="flex-start">
        <FadeInOut key={`password-is-${when(isValid, "valid", "invalid")}`}>
          {!isValid && <Icon type="shieldOutlined" color="inherit" />}
          {isValid && <Icon type="verifiedUser" color="inherit" />}
        </FadeInOut>
        <Text>{info}</Text>
      </HStack>
    </FormHelperText>
  );
}

// TODO: move all the form validators and handlers into a hook, to be reused
export function useFormHandler(state: IStateExtension) {
  // TODO: DO SOMETHING HERE.
}
