import { Box, Button, FormControl, HStack, Select, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { navigate, useLocation, useParams } from "@reach/router";
import {
  AddButton,
  EmptyCrate,
  FadeInOut,
  Icon,
  InputLabel,
  MainLayoutContainer,
  PageLoading,
  PageMotion,
  Paginator,
  StakeInfo,
  StakingBalance,
  Topbar,
} from "components";
import { SidePageProvider } from "contexts";
import { PageProps } from "interfaces";

import { toCoinLocale, toQueryString, when } from "utils";
import { useBuiltInFn } from "hooks";
import { useGetEarnWalletQuery, useGetStakingsQuery } from "apis";
import { useMemo, useState } from "react";
import { isEmpty } from "lodash";

interface CoinEarningsPageProps extends PageProps {}

interface IQueryState {
  page: number;
  limit: number;
  unit: string;
  sortBy: string; // i.e --> "createdAt:desc"
  actionType: string; // i.e --> "staking_in,staking_out"
  status: string | null; // i.e --> "active" | "terminated" ...
  currentStatus: string | null; // same input as status 👆;
}

export default function CoinEarningsPage(props: CoinEarningsPageProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { coin } = useParams();
  const { search } = useLocation();
  const { goBack } = useBuiltInFn();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [filter, setFilter] = useState("all");

  const [queries, setQueries] = useState<Partial<IQueryState>>({
    page: 1,
    limit: 5,
    unit: coin,
    // sortBy: "createdAt:desc",
    actionType: "staking_in,staking_out",
    // status: filter === "all" ? null : filter,
    // currentStatus: null,
  });

  const { data: wallets, isLoading: isLoadingWallets } = useGetEarnWalletQuery({});

  const selectedWallet = useMemo(() => (coin ? wallets?.find((w) => w?.unit === coin) : undefined), [coin, wallets]);

  // const balance = useMemo(() => +selectedWallet?.balance?.$numberDecimal! ?? 0, [selectedWallet]);
  const amountAtStake = useMemo(() => selectedWallet?.amountAtStake, [selectedWallet]);

  const { data: stakings, isLoading, isFetching } = useGetStakingsQuery(toQueryString(queries));

  const totalCount = useMemo(() => stakings?.totalCount, [stakings]);
  const transactions = useMemo(() => stakings?.records, [stakings]);

  const handleBack = () => {
    goBack();
  };

  const handleFilterChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;

    setFilter(value);

    if (value === "all") setQueries({ ...queries, status: null, currentStatus: null });
    else if (value === "active") setQueries({ ...queries, currentStatus: value, status: null });
    else setQueries({ ...queries, status: value, currentStatus: null });
  };

  return (
    <PageMotion key="CoinEarnings">
      <Topbar pageTitle="Earn" {...props} />

      <MainLayoutContainer pb="60px">
        <VStack alignItems="flex-start">
          <Box w="100%" maxW="container.sm">
            <SidePageProvider>
              <Box mb="42px">
                <Button
                  size="sm"
                  minW="fit-content"
                  maxW="fit-content"
                  variant="link"
                  leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                  onClick={handleBack}
                  px="2px !important"
                >
                  Back
                </Button>

                <HStack justifyContent="space-between">
                  <Text mt="12px" as="h4" fontSize="18px" fontWeight="bold" fontFamily="var(--bitmama-fonts-heading)">
                    All {toCoinLocale(coin)} Earnings
                  </Text>

                  <AddButton
                    size="xs"
                    onClick={() => navigate(`/earn/staking/subscribe${when(params.has("type"), `?${params.toString()}`, "")}`)}
                  >
                    Subscribe
                  </AddButton>
                </HStack>
              </Box>

              <StakingBalance isLoading={isLoadingWallets} coin={coin} balance={amountAtStake?.volume ?? 0} />

              <FormControl mt="38px !important">
                <InputLabel isLoading={isFetching} fontSize="16px" mb="10px">
                  Filter
                </InputLabel>
                <Select placeholder="Select" value={filter} onChange={handleFilterChange}>
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="redeemed">Redeemed</option>
                  <option value="terminated">Terminated</option>
                </Select>
              </FormControl>

              <Stack mt="56px !important" gridGap="8px">
                <FadeInOut key={`stakings-${when(isLoading, "loading", "loaded")}`}>
                  {isLoading && <PageLoading isLoading={isLoading} />}

                  {!isLoading &&
                    (transactions ?? []).map((tx, i) => (
                      <StakeInfo
                        mb="16px"
                        key={`${coin}-stake-info-${i}`}
                        coin={coin}
                        title={toCoinLocale(tx?.unit)}
                        subtitle={tx?.product?.tenor ? `${tx?.product?.tenor} day${tx?.product?.tenor > 1 ? "s" : ""}` : undefined}
                        status={(tx?.currentStatus?.length > 1 ? tx?.currentStatus : tx?.status) as any}
                        onClick={() => navigate(`/earn/earnings/${tx?.unit}/${tx?._id}`)}
                      >
                        {tx?.currentStatus?.length > 1 ? tx?.currentStatus : tx?.status}
                      </StakeInfo>
                    ))}

                  {!isLoading && isEmpty(transactions) && <EmptyCrate type="earn" />}
                </FadeInOut>
              </Stack>

              {!isLoading && !isEmpty(transactions) && (
                <VStack>
                  <Paginator
                    totalCount={totalCount}
                    limit={queries.limit}
                    page={queries.page}
                    onPageChange={(page: number) => setQueries({ ...queries, page })}
                  />
                </VStack>
              )}
            </SidePageProvider>
          </Box>
        </VStack>
      </MainLayoutContainer>
    </PageMotion>
  );
}
