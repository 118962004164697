export const randomFixedInteger = (length: number) => {
  const power10minus1 = 10 ** (length - 1);
  const power10 = 10 ** length;
  let rand = Math.floor(power10minus1 + Math.random() * (power10 - power10minus1 - 1));
  if (String(rand).slice(0, 1) === "0") {
    rand = Math.floor(Math.random() * 899999 + 100000);
  }
  return rand;
};

export const randomSequentialId = () => {
  const uid = new Date().getTime().toString(36);
  return uid;
};

export const uuid = (len: number = 8) => {
  return `${randomSequentialId()}-${randomFixedInteger(len)}`;
};
