import { Box, Button, HStack, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { useGetPlayToEarnWalletBalancesQuery, useGetWalletTransationsV2Query } from "apis";
import {
  Group,
  Wallet,
  WalletActions,
  WalletActionsButton,
  useModalView as useSteps,
  PageLoading,
  //   Paginator,
  //   EmptyCrate,
} from "components";
import configs from "config";
import { useSidePage } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { AnimatePresence, motion } from "framer-motion";
import { useColor, usePartialState } from "hooks";
import { useEffect, useMemo } from "react";
import { toQueryString, when } from "utils";
import { IPlayToEarnState } from "./PlayToEarn";

import AkiverseImg from "assets/images/akiverse.png";
import BGSPredictOne from "assets/images/bgs-predict-2-win-1.png";
import BGSPredictTwo from "assets/images/bgs-predict-2-win-2.png";
import { usePlayToEarnWalletBalance } from "hooks/usePlayToEarnWalletBalance";

interface PlayToEarnOverviewProps {
  state: Partial<IPlayToEarnState>;
  set: (update: Partial<IPlayToEarnState>) => void;
}

export default function PlayToEarnOverview(props: PlayToEarnOverviewProps) {
  const { state, set } = props;
  const { data, params, updateSidePageConfigs, onClose: closeSidePage } = useSidePage();
  const { setCurrentView } = useSteps();
  //   const bg = useColorModeValue("rgba(226, 242, 218, 0.1)", "linear-gradient(356deg, #13ffca11, #696a6a12)");

  const { data: wallets } = useGetPlayToEarnWalletBalancesQuery();
  console.log("Play to earn wallets", wallets);

  const { coin } = state;

  const { appfigs } = useAppConfig();
  const { depositConfig, withdrawalConfig } = appfigs ?? {};

  console.log("Sidepage data", { data, params, coin });

  const { getBalance, isLoading } = usePlayToEarnWalletBalance();
  const balance = useMemo(() => getBalance(coin as any), [getBalance, coin]);

  const [queries /*, set_query*/] = usePartialState({
    page: 1,
    limit: 6,
    unit: coin,
    sortBy: "createdAt:desc",
  });

  const { data: tx, isLoading: isTxLoading } = useGetWalletTransationsV2Query(toQueryString(queries));

  //   const totalCount = useMemo(() => tx?.totalCount, [tx]);
  //   const transactions = useMemo(() => tx?.transactions, [tx]);
  //   const hasTx = useMemo(() => (transactions ?? []).length > 0, [transactions]);
  //   const passedLimit = useMemo(() => (totalCount ?? 0) > queries.limit!, [totalCount, queries.limit]);

  const isCryptoDepositDisabled = useMemo(() => !!depositConfig?.disableCryptoDeposit, [depositConfig]);
  const isCryptoWithdrawalDisabled = useMemo(() => !!withdrawalConfig?.disableCryptoWithdrawal, [withdrawalConfig]);

  console.log("Fiat Tx Data", tx);

  //   const handlePageChange = (page: number) => {
  //     set_query({ page });
  //   };

  useEffect(() => {
    updateSidePageConfigs({
      _back: {
        text: "Close",
        onClick: closeSidePage,
      },
    });

    //eslint-disable-next-line
  }, []);

  return (
    <Group px="0" mt="22px">
      <Wallet
        isLoading={isLoading}
        value={balance}
        w="100%"
        minW="unset"
        maxW="unset"
        scheme="default"
        tokens={configs.stableCoins as any}
        currency={coin as any}
        _navigations={{ display: "none" }}
        onCurrencyChange={(coin) => set({ coin })}
      >
        <WalletActions>
          <WalletActionsButton disabled={!!isCryptoWithdrawalDisabled} variant="outline" onClick={() => setCurrentView(1)}>
            Withdraw
          </WalletActionsButton>
          <WalletActionsButton disabled={!!isCryptoDepositDisabled} variant="solid" onClick={() => setCurrentView(2)}>
            Fund
          </WalletActionsButton>
        </WalletActions>
      </Wallet>

      <Group mt="42px !important">
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tx-list-${when(isTxLoading, "loading", "loaded")}`}
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
          >
            {isTxLoading && <PageLoading isLoading={isTxLoading} />}

            {!isTxLoading && (
              <Stack>
                {(games ?? []).map((game, id: any) => (
                  <Game key={id} {...game} />
                ))}
              </Stack>
            )}

            {/* {!isTxLoading && !isFetching && !hasTx && <EmptyCrate type="transaction" />} */}
          </motion.div>
        </AnimatePresence>

        {/* {passedLimit && (
          <Box w="100%">
            <Paginator limit={queries.limit!} onPageChange={handlePageChange} page={queries.page!} totalCount={totalCount ?? 0} />
          </Box>
        )} */}

        {/* <PlayToEarnTransactions /> */}
      </Group>
    </Group>
  );
}

const games = [
  { title: "Akiverse", img: AkiverseImg, description: "Multiple Player Mode", sub_description: "Win up $50 USDT and more" },
  {
    title: "BGS - Predict 2 Win",
    img: BGSPredictOne,
    description: "Multiple Player Mode",
    sub_description: "Win up $50 USDT and more",
  },
  {
    title: "BGS - Predict 2 Win",
    img: BGSPredictTwo,
    description: "Multiple Player Mode",
    sub_description: "Win up $50 USDT and more",
  },
];

function Game(props: any) {
  const { img, title = "Akiverse", description = "Multiple Player Mode", sub_description = "Win up $50 USDT and more" } = props;

  const sw_color = useColor();

  return (
    <Box borderRadius="10px" w="100%" p="16px" bg={sw_color("#ECFDF5", "#ECFDF5")}>
      <HStack gridGap="20px">
        <Box flex="2" borderRadius="10px">
          <Image src={img} alt={title} w="100%" h="100%" objectFit="cover" />
        </Box>

        <Stack h="100%" flex="3" gridGap="12px">
          <Heading fontSize="24px">{title}</Heading>
          <Box>
            <Text fontWeight="600" fontSize="18px">
              {description}
            </Text>
            <Text fontWeight="500" fontSize="14px">
              {sub_description}
            </Text>
          </Box>

          <HStack>
            <Button size="sm">Play Now</Button>
            <Button variant="transparent" size="sm">
              Rules
            </Button>
          </HStack>
        </Stack>
      </HStack>
    </Box>
  );
}
