import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ApiResponse,
  AppStatus,
  P2PCompletedTradeRo,
  P2PCompletedTradesRo,
  P2PListingRo,
  P2PListingTradeRo,
  P2PPendingTradeRo,
  P2PPendingTradesRo,
} from "interfaces";
import { RootState } from "store";
import { get } from "utils";
import { wrapBaseApi } from "utils/apiWrapper";

interface IState {
  status: AppStatus;
  type: string;

  trades: {
    type: string;
    status: AppStatus;
    count: number;
    data: P2PPendingTradeRo[];
  };
  open: {
    status: AppStatus;
    type: string;
    count: number;
    data: P2PPendingTradeRo[];
  };
  indispute: {
    status: AppStatus;
    type: string;
    count: number;
    data: P2PPendingTradeRo[];
  };
  cancelled: {
    status: AppStatus;
    type: string;
    count: number;
    data: P2PPendingTradeRo[];
  };
  completed: {
    status: AppStatus;
    type: string;
    count: number;
    data: P2PCompletedTradeRo[];
  };

  bestRate: {
    buying: {
      status: AppStatus;
      data: P2PListingTradeRo[];
      totalCount: number;
    };
    selling: {
      status: AppStatus;
      data: P2PListingTradeRo[];
      totalCount: number;
    };
  };
}

const initialState: IState = {
  status: "idle",
  type: "pending",
  trades: {
    status: "idle",
    type: "pending",
    count: 0,
    data: [],
  },
  open: {
    status: "idle",
    type: "pending",
    count: 0,
    data: [],
  },
  indispute: {
    status: "idle",
    type: "indispute",
    count: 0,
    data: [],
  },
  cancelled: {
    status: "idle",
    type: "cancelled",
    count: 0,
    data: [],
  },
  completed: {
    status: "idle",
    type: "completed",
    count: 0,
    data: [],
  },

  bestRate: {
    buying: {
      status: "idle",
      data: [],
      totalCount: 0,
    },
    selling: {
      status: "idle",
      data: [],
      totalCount: 0,
    },
  },
};

const transformCompletedTradesData = (data: any) => ({
  ...data,
  adsType: data?.counterParty?.role === "buyer" ? "buying" : "selling",
  fiat: data?.originalParty?.role === "seller" ? data?.destinationUnit : data?.sourceUnit,
  token: data?.originalParty?.role === "seller" ? data?.sourceUnit : data?.coin,
  amount: data?.currencyValue,
  contractState: "closed",
});

const transformTradesWithStatusData = (data: any) => ({
  fiat: data?.currency,
  token: data?.coin,
  parties: data?.counterParties,
  ...data,
});

type PaginationType = { page: number; limit: number };

export const fetchAllTrades = createAsyncThunk("trade/fetchAllTrades", async (pagination: PaginationType, { rejectWithValue }) => {
  try {
    console.log("PAGINATION", pagination);

    const urls = [
      `/p2p/flexiListing/flexiTrades/completed?limit=${pagination.limit / 2}&page=${pagination.page}&sortBy=createdAt:desc`,
      `/p2p/flexiListing/flexiTrades/own?limit=${pagination.limit / 2}&page=${pagination.page}&sortBy=createdAt:desc`,
    ];

    const toRun: any[] = [];
    urls.forEach((url) => {
      toRun.push(get(wrapBaseApi(`${url}`)));
    });

    const res = await Promise.all(toRun);

    const result = res.map((r) => r?.data?.data);

    console.log("ALL TRADES", result);
    return result;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchTradesByStatus = createAsyncThunk(
  "trade/fetchTradesByStatus",
  async (input: PaginationType & { type: string }, { rejectWithValue }) => {
    try {
      const res = await get<ApiResponse<P2PPendingTradesRo>>(
        wrapBaseApi(
          `/p2p/flexiListing/flexiTrades/own?contractState=${input.type}&limit=${input.limit}&page=${input.page}&sortBy=createdAt:desc`
        )
      );

      console.log("TRADES BY STATUS", res?.data);
      return { ...res?.data, type: input.type };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCompletedTrades = createAsyncThunk(
  "trade/fetchCompletedTrades",
  async (pagination: PaginationType, { rejectWithValue }) => {
    try {
      const res = await get<ApiResponse<P2PCompletedTradesRo>>(
        wrapBaseApi(
          `/p2p/flexiListing/flexiTrades/completed?limit=${pagination.limit}&page=${pagination.page}&sortBy=createdAt:desc`
        )
      );

      // console.log("COMPLETED TRADES", res.data);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBestRates = createAsyncThunk(
  "trade/fetchBestRates",
  async (input: { coin: string; currency: string; type: "buying" | "selling" }, { rejectWithValue }) => {
    const { coin, currency, type } = input;
    type FnReturnType = Promise<ApiResponse<P2PListingRo>>;

    try {
      let commonPath = `/p2p/flexiListing/?page=1&limit=1&adsStatus=open&adsType=${type}&coin=${coin}`;
      if (currency) commonPath += `&currency=${currency}`;

      const toRunInParallel: FnReturnType[] = [
        get<ApiResponse<P2PListingRo>>(wrapBaseApi(`${commonPath}&sortBy=price:asc&dynamic=true`)),
        get<ApiResponse<P2PListingRo>>(wrapBaseApi(`${commonPath}&sortBy=unitCost:asc&dynamic=false`)),
      ];

      const [dynamic, fixed] = await Promise.all(toRunInParallel);

      const totalCount = (dynamic?.data?.totalCount ?? 0) + (fixed?.data?.totalCount ?? 0);
      const result = [...(dynamic?.data?.trades ?? []), ...(fixed?.data?.trades ?? [])];

      console.log("BEST RATES", result);
      return { totalCount, result, type, coin };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    updateTradeType: (state, action) => {
      state.trades.type = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllTrades.fulfilled, (state, { payload }) => {
      state.trades.status = "success";
      state.trades.count =
        payload[0]?.totalCount && payload[1]?.totalCount && Number(payload[0]?.totalCount + payload[1]?.totalCount);

      payload[0]?.trades &&
        payload[1]?.trades &&
        (state.trades.data = [
          ...payload[0]?.trades?.map(transformCompletedTradesData),
          ...payload[1]?.trades?.map(transformTradesWithStatusData),
        ].filter(Boolean));
    });
    builder.addCase(fetchAllTrades.pending, (state, action) => {
      state.trades.status = "fetching";
    });
    builder.addCase(fetchAllTrades.rejected, (state, action) => {
      state.trades.status = "error";
    });

    // Trades by status
    builder.addCase(fetchTradesByStatus.fulfilled, (state, { payload }) => {
      const type = payload.type.includes("cancelled") ? "cancelled" : payload.type;
      (state as any)[type].status = "success";
      (state as any)[type].count = payload!.totalCount;
      (state as any)[type].data = payload!.trades?.map(transformTradesWithStatusData);
    });
    builder.addCase(fetchTradesByStatus.pending, (state, action) => {
      // console.log("Trade History Pending", action);
      const fetchType = action.meta.arg.type;
      const type = fetchType.includes("cancelled") ? "cancelled" : fetchType;
      state.type = type;
      (state as any)[type].status = "fetching";
    });
    builder.addCase(fetchTradesByStatus.rejected, (state, action) => {
      state.trades.status = "error";
    });

    // Completed Trades
    builder.addCase(fetchCompletedTrades.fulfilled, (state, { payload }) => {
      state.trades.status = "success";
      state.completed.count = payload!.totalCount;
      state.completed.data = payload!.trades?.map(transformCompletedTradesData);
    });
    builder.addCase(fetchCompletedTrades.pending, (state, action) => {
      state.trades.status = "fetching";
    });
    builder.addCase(fetchCompletedTrades.rejected, (state, action) => {
      state.trades.status = "error";
    });

    //////////////////////////////////////////////////////
    /// BEST RATE
    builder.addCase(fetchBestRates.fulfilled, (state, { payload }) => {
      state.bestRate[payload.type].status = "success";
      state.bestRate[payload.type].totalCount = payload!.totalCount;
      state.bestRate[payload.type].data = payload!.result;
    });
    builder.addCase(fetchBestRates.pending, (state, action) => {
      state.trades.status = "fetching";
    });
    builder.addCase(fetchBestRates.rejected, (state, action) => {
      state.trades.status = "error";
    });
  },
});

export const selectTrade = (state: RootState) => state.trade;

export const { actions, reducer: TradeReducer } = tradeSlice;

export const { updateTradeType } = actions;
