import { Box, Grid, HStack, Skeleton, Stack, Text, TextProps, useColorMode } from "@chakra-ui/react";
import { CreditCardPatternSVG, VisaSVG, MasterSVG, ChristmasPatternSVG, ChristmasCrystalsSVG } from "assets";
import Card, { CardProps } from "components/Card/Card";
import Icon from "components/Icon/Icon";
import Logo from "components/Logo/Logo";
import { useDefaultStyle } from "hooks";
import { capitalize, chunk } from "lodash";
import { useMemo } from "react";
import { currencyFormat, switchStyle, when } from "utils";

import toLower from "lodash/toLower";
import { CardRo } from "interfaces";

export type CardType = "black" | "pink" | "green";
export type CardBrand = "visa" | "mastercard";

interface CreditCardProps extends CardProps {
  type: CardType;
  number?: string;
  name?: string;
  cvv?: string;
  isLoading?: boolean;
  validity?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  balance?: string;
  status?: string;
  brand?: string;
  label?: string;
  its_christmas?: boolean;
  is_obfuscated?: boolean;
  /**
   * Old card type
   * tier1 | ... | tierN
   */
  cardType?: CardRo["cardType"];
}

interface CardTypeIndicatorProps {
  type: CardType;
  _label?: TextProps;
}

interface OrnamentProps {
  type: CardType;
  its_christmas?: boolean;
  brand_name?: string;
}

type OrnamentStyleType = { bg: string; color: string };

export default function CreditCard(props: CreditCardProps) {
  const { type: _type, cardType, label, its_christmas = false, brand, ...xprops } = props;

  const { shadow, borderColor } = useDefaultStyle();

  const type = useMemo(() => {
    const map: Record<string, CardType> = {
      [_type]: _type,
      tier1: "black",
      tier2: "pink",
      tier3: "green",
      tier4: "black",
      tier5: "black",
      black: "black",
      pink: "pink",
      green: "green",
    };

    return map[cardType?.type ?? _type ?? "black"];
  }, [cardType, _type]);

  return (
    <Box w="fit-content">
      <Card
        as="button"
        minH="218px"
        minW="384px"
        p="0"
        bg="white"
        borderRadius="16px"
        border="1px solid transparent"
        _focus={{ shadow, borderColor }}
        mb="8px"
        overflow="hidden"
        cursor={brand?.toLowerCase() === "mastercard" ? "not-allowed" : "pointer"}
        {...xprops}
      >
        <Grid minH="100%" templateColumns=".1fr 1fr">
          <Ornament its_christmas={its_christmas} type={type} brand_name={brand} />
          <CreditCardBody {...props} its_christmas={its_christmas} type={type} />
        </Grid>
      </Card>

      <Text fontWeight="500" textAlign="center" textTransform="capitalize" fontSize={{ base: "14px", md: "16px" }}>
        {label}
      </Text>
    </Box>
  );
}

export function CardTypeIndicator(props: CardTypeIndicatorProps) {
  const { type, _label } = props;

  const { colorMode } = useColorMode();

  const styles = useMemo(() => {
    const map: Record<CardType, { color: string }> = {
      black: {
        color: switchStyle(colorMode, { dark: "#333", light: "#000000" }),
      },
      pink: {
        color: switchStyle(colorMode, { dark: "#8E1273", light: "#8E1273" }),
      },
      green: {
        color: switchStyle(colorMode, { dark: "primary.default", light: "primary.default" }),
      },
    };

    return map[type];
  }, [type, colorMode]);

  return (
    <HStack color={styles.color}>
      <Icon type="diagonalDot" color="inherit" />
      <Text color="inherit" fontSize="10px" fontFamily="var(--bitmama-fonts-heading)" {..._label}>
        {capitalize(type)}
      </Text>
    </HStack>
  );
}

function CreditCardBody(props: CreditCardProps) {
  const { type, brand, number, name, cvv, validity, isLoading, balance, status, is_obfuscated, its_christmas } = props;

  const { colorMode } = useColorMode();

  const cardNum = useMemo(() => {
    if (!is_obfuscated) return number?.replaceAll("*", "X");
    const last4 = number?.slice(-4) ?? "2345";
    return String("XXXX").repeat(3).concat(last4);
  }, [number, is_obfuscated]);

  const cardExp = useMemo(
    () => (validity ? (validity.includes("undefined") ? validity?.replaceAll("undefined", "XX") : validity) : undefined),
    [validity]
  );

  const styles = useMemo(() => {
    const map: Record<CardType, { color: string }> = {
      black: {
        color: switchStyle(colorMode, { dark: "#000000", light: "#000000" }),
      },
      pink: {
        color: switchStyle(colorMode, { dark: "primary.default", light: "primary.default" }),
      },
      green: {
        color: switchStyle(colorMode, { dark: "primary.default", light: "primary.default" }),
      },
    };

    return map[type];
  }, [type, colorMode]);

  const statusColor = useMemo(() => {
    const map: Record<string, string> = {
      active: switchStyle(colorMode, { dark: "secondary.500", light: "secondary.400" }),
      frozen: switchStyle(colorMode, { dark: "error", light: "error" }),
    };
    return map[status ?? "active"];
  }, [status, colorMode]);

  const defaultBrandName = "master";
  const brandName = useMemo(() => when(!!brand, toLower(brand).includes("visa") ? "visa" : "master", defaultBrandName), [brand]);

  const brandIcon = useMemo(() => {
    const map: Record<typeof brandName, typeof VisaSVG> = {
      visa: VisaSVG,
      master: MasterSVG,
    };

    return map[brandName];
  }, [brandName]);

  console.log("Credit Card balance: ", balance);

  return (
    <Box
      w="100%"
      px="24px"
      py="18px"
      color={styles.color}
      pos="relative"
      opacity={brandName === "master" ? 0.4 : 1}
      pointerEvents={brandName === "master" ? "none" : "auto"}
    >
      {!!its_christmas && <Box pos="absolute" zIndex={0} top="0" right="0" as={ChristmasCrystalsSVG} />}
      <HStack pos="relative" w="100%" justifyContent="space-between" zIndex={2}>
        <Logo
          fontSize="32px"
          _name={{ w: "3.8pc", ml: "4px !important" }}
          its_christmas={its_christmas}
          _cap={{ left: "-15px", bottom: "15px" }}
        />

        <Box maxW="40px" maxH="28px" as={brandIcon} />
      </HStack>

      <HStack pos="relative" justifyContent="flex-end" zIndex={2}>
        {!status && (
          <Text fontSize="14px" fontWeight="700" fontFamily="var(--bitmama-fonts-heading)" color="secondary.500">
            {currencyFormat("usd").format(+(balance ?? 0))}
          </Text>
        )}
        {status && (
          <Text
            fontSize="12px"
            fontWeight="700"
            fontFamily="var(--bitmama-fonts-heading)"
            color={statusColor}
            textTransform="uppercase"
          >
            {status}
          </Text>
        )}
      </HStack>

      <Stack mt="62px" pos="relative" zIndex={2}>
        <HStack fontWeight="700" fontFamily="var(--bitmama-fonts-heading)" w="100%">
          {chunk(cardNum ?? "XXXXXXXXXXXX2309", 4).map((cc) => (
            <Skeleton mt="0 !important" isLoaded={!isLoading} fontFamily="inherit">
              <Text color={styles.color} fontFamily="inherit" m="0 !important" ml="0 !important">
                {cc}
              </Text>
            </Skeleton>
          ))}
        </HStack>

        <HStack>
          <HStack>
            <Text color="inherit" fontSize="10px" fontWeight="500">
              Expires
            </Text>
            <Icon ml="2px !important" type="roundedRightArrow" color="inherit" boxSize="12px" />
          </HStack>

          <Text mt="-2px !important" color="inherit" fontSize="12px" fontFamily="var(--bitmama-fonts-heading)">
            {cardExp ?? "11/25"}
          </Text>
        </HStack>

        <Text w="fit-content" fontSize="12px" fontFamily="var(--bitmama-fonts-heading)">
          {cvv ? `CVV: ${cvv}` : "CVV"}
        </Text>

        <HStack justifyContent="space-between">
          <Text fontWeight="700" fontSize="12px" textTransform="uppercase">
            {name ?? "RUTH JOHNSON"}
          </Text>

          <CardTypeIndicator type={type} />
        </HStack>
      </Stack>
    </Box>
  );
}

function Ornament(props: OrnamentProps) {
  const { type = "black", its_christmas } = props;

  const { colorMode } = useColorMode();

  const styles = useMemo(() => {
    const map: Record<CardType, OrnamentStyleType> = {
      black: {
        bg: switchStyle(colorMode, { dark: "#000000", light: "#000000" }),
        color: switchStyle(colorMode, { dark: "gray.100", light: "gray.100" }),
      },
      pink: {
        bg: switchStyle(colorMode, { dark: "#8E1273", light: "#8E1273" }),
        color: switchStyle(colorMode, { dark: "gray.100", light: "gray.100" }),
      },
      green: {
        bg: switchStyle(colorMode, { dark: "primary.default", light: "primary.default" }),
        color: switchStyle(colorMode, { dark: "gray.100", light: "gray.100" }),
      },
    };

    return map[type];
  }, [type, colorMode]);

  return (
    <Box
      w="100%"
      bg={styles?.bg}
      color={styles?.color}
      fontFamily="var(--bitmama-fonts-heading)"
      pos="relative"
      opacity={props.brand_name?.toLowerCase() === "mastercard" ? 0.4 : 1}
      pointerEvents="none"
    >
      <Text fontSize="10px" color="inherit" transform="rotate(-90deg)" pos="relative" top="50px" whiteSpace="nowrap">
        Virtual Card
      </Text>

      {!its_christmas ? <Box as={CreditCardPatternSVG} /> : <Box pos="relative" bottom="-45%" as={ChristmasPatternSVG} />}
    </Box>
  );
}
