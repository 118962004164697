import { Box, Divider, Grid, TabList, TabPanel, Tabs } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Group, Tab, TabPanels, Wallet } from "components";
import { useModals } from "contexts";
import { useEventListener, usePartialState, useWalletBalance } from "hooks";
import { PageProps } from "interfaces";
import { useMemo, useState } from "react";
import { CryptoPanel, FiatPanel, WalletTransactions } from "ui";
// import { selectCountryCurrency } from "utils";

import toLower from "lodash/toLower";
import { useAppConfig } from "contexts/appconfig.context";
import { currencyMap, useCurrency } from "hooks/useCurrency";

interface WalletProps extends PageProps {}

export default function WalletPage(props: WalletProps) {
  const { user } = props;
  const { open } = useModals();
  const location = useLocation();

  const { appfigs } = useAppConfig();

  const { getBalance, isLoading, fiats } = useWalletBalance();

  // const defaultCurrency = useMemo(() => selectCountryCurrency(user?.country ?? "nigeria"), [user]);

  const _currency = useCurrency();
  const { depositConfig, withdrawalConfig } = appfigs ?? {};
  const [{ currency }, setCurrency] = usePartialState<{ currency: string }>({ currency: _currency }, [_currency]);
  const coin = useMemo(() => currency ?? _currency, [_currency, currency]);

  const availableCurrencies = useMemo(() => (fiats ?? []).map((fiat) => fiat.coin), [fiats]);

  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    fiat: 0,
    crypto: 1,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const isFiatDepositDisabled = useMemo(() => !!depositConfig?.disableFiatDeposit, [depositConfig]);
  const isFiatWithdrawalDisabled = useMemo(() => !!withdrawalConfig?.disableFiatWithdrawal, [withdrawalConfig]);

  const canFundWallet = useMemo(() => {
    const country_name = toLower(user?.country ?? "nigeria");
    const map = currencyMap[country_name] ?? currencyMap["default"];
    return map.currency === coin;
  }, [user, coin]);

  const canWithdrawWallet = useMemo(() => {
    const country_name = toLower(user?.country ?? "nigeria");
    const opt = currencyMap[country_name] ?? currencyMap["default"];
    return opt?.currency === coin && opt?.enabled;
  }, [user, coin]);

  const handleTabChange = (index: number) => {
    changeTab(index);
    searchParams.set("tab", Object.keys(tabMap)[index]);
    navigate(`/wallet?${searchParams.toString()}`);
  };

  useEventListener("refetch_wallet", () => getBalance(coin as any));
  const balance = useMemo(() => getBalance(coin as any), [getBalance, coin]);

  // console.log("Default Currency", defaultCurrency);

  return (
    <Box pos="relative">
      <Grid
        display={{ base: "block", "1sm": "grid" }}
        templateColumns={{ base: "1fr", "3sm": "1fr .1fr", "4sm": "1fr .1fr 1fr" }}
        gap={{ base: "0", md: "20px", lg: "100px" }}
      >
        <Group mt="0" mb="8px">
          <Wallet
            alignSelf="center"
            currency={coin as any}
            availableCurrencies={availableCurrencies}
            value={balance ?? 0}
            isLoading={isLoading}
            _outline={{ disabled: !canWithdrawWallet || isFiatWithdrawalDisabled, onClick: open("fiat:withdraw", { coin }) }}
            _solid={{ disabled: !canFundWallet || isFiatDepositDisabled, onClick: open("fiat:fund", { coin }) }}
            onCurrencyChange={(currency) => setCurrency({ currency })}
          />

          <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange} mt={{ base: "24px !important", md: "42px !important" }}>
            <TabList borderRadius="8px">
              <Tab>Fiat Wallet</Tab>
              <Tab>Crypto Wallet</Tab>
            </TabList>
            <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
              <TabPanel px={["2", "0", "0", "1"]}>
                <FiatPanel {...(props as any)} />
              </TabPanel>
              <TabPanel px={["0", "0", "0", "1"]}>
                <CryptoPanel {...(props as any)} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Group>

        <Box p={{ base: "0", "3sm": "20px" }} pos="relative">
          <Divider orientation="vertical" pos="absolute" left="50%" transform="translateX(-50%)" />
        </Box>

        <Box>
          <WalletTransactions pos="sticky" top="126px" />
        </Box>
      </Grid>
    </Box>
  );
}
