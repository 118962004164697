import { Box, BoxProps, FormControl, HStack, Text } from "@chakra-ui/react";
import { GreenBoxWalletPatternSVG } from "assets";
import configs from "config";
import { currencyFormat, toCoinLocale } from "utils";
import { WalletActionsButton, WalletActionsButtonProps } from "./Wallet";

import CircularLoader from "../Loader/CircularLoader";
import { useDefaultStyle } from "hooks";
import { RadixSelect } from "components/Select/RadixSelect";
import { Option } from "components";

interface GreenBoxWalletProps extends Omit<BoxProps, "onChange"> {
  coin?: string;
  isLoading?: boolean;
  value?: string | number;
  onChange?: (coin: string) => void;
  onFund?: () => void;
  onWithdraw?: () => void;
  _fund?: WalletActionsButtonProps;
  _withdraw?: WalletActionsButtonProps;
}

export function GreenBoxWallet(props: GreenBoxWalletProps) {
  const { coin, value = 0, onChange, onFund, onWithdraw, isLoading, _fund, _withdraw, ...xprops } = props;

  const { shadow, borderColor } = useDefaultStyle();

  return (
    <Box bg="#9FD483" borderRadius="20px" p="40px 28px" maxH="232px" pos="relative" overflow="hidden" {...xprops}>
      <HStack w="100%" justifyContent="space-between">
        <Box>
          <HStack>
            <Text color="#0D4740" fontSize="sm" fontWeight="300" fontFamily="var(--bitmama-fonts-heading)">
              Your Balance
            </Text>

            {isLoading && <CircularLoader color="accent.800" />}
          </HStack>
          <Text color="#0D4740" fontSize="4xl" fontWeight="700" fontFamily="var(--bitmama-fonts-heading)">
            {currencyFormat("usd").format(+value)}
          </Text>
        </Box>

        <FormControl w="fit-content">
          <RadixSelect
            use_coin_icon
            value={coin}
            aria-label="Coin"
            placeholder="Select Coin"
            onChange={(value) => {
              onChange && onChange(value);
            }}
            _trigger={{
              bg: "white !important",
              maxW: "144px !important",
              color: "#0D4740 !important",
              _hover: { bg: "white", borderColor },
              _focus: { bg: "white", shadow, borderColor },
            }}
          >
            {configs.stableCoins.map((coin) => (
              <Option key={coin} value={coin} coin={coin as any}>
                {toCoinLocale(coin)}
              </Option>
            ))}
          </RadixSelect>
        </FormControl>
      </HStack>

      <Box as={GreenBoxWalletPatternSVG} pos="absolute" bottom="0" left="0" />

      <HStack mt="28px" justifyContent="center">
        <ActionButton variant="outline" onClick={onWithdraw} {..._withdraw}>
          Withdraw
        </ActionButton>
        <ActionButton variant="solid" onClick={onFund} {..._fund}>
          Fund
        </ActionButton>
      </HStack>
    </Box>
  );
}

interface ActionButtonProps extends WalletActionsButtonProps {}

function ActionButton(props: ActionButtonProps) {
  const { children, ...xprops } = props;
  return (
    <WalletActionsButton scheme="greenBox" minW="170px" minH="56px" fontSize="16px" {...xprops}>
      {children}
    </WalletActionsButton>
  );
}
