import { useColorModeValue } from "@chakra-ui/react";

export default function useDefaultStyle() {
  const shadow = useColorModeValue("outline", "darkOutline");
  const errShadow = useColorModeValue("errOutline", "darkErrOutline");
  const borderColor = useColorModeValue("primary.400", "secondary.600");
  const errBorderColor = useColorModeValue("error", "error");
  // const fgpColor = useColorModeValue("black", "white"); // foreground primary color
  return { errShadow, shadow, borderColor, errBorderColor };
}
