import configs, { isProd, isStaging } from "config";
import { useEffect, useRef, useState } from "react";
import { selectUser } from "store/slices";
import { post } from "utils";
import { urlBase64ToUint8Array } from "utils/base64";
import ls from "utils/secureStorage";
import { useAuth } from "./useAuth";
import useSelector from "./useSelector";

// const clientUrl = process.env.REACT_APP_CLIENT_URL;

export function useServiceWorkerReg() {
  const { auth } = useAuth();
  const [isSubbing, setSubbing] = useState(false);
  const env = useRef<"-staging" | "-prod" | "">("");

  const { profile } = useSelector(selectUser);

  type WebSubbedType = { [key: string]: boolean };

  const log = (message: string, ...args: any[]) => console.log(`[useServiceWorker] :: ${message}`, ...args);

  const getSubscription = async (registration: ServiceWorkerRegistration): Promise<PushSubscription> => {
    let subscription = await registration.pushManager.getSubscription();

    if (!subscription) {
      // Some older chrome version doesn’t accept the base64-encoded (string) vapidPublicKey yet
      // urlBase64ToUint8Array() is defined in utils/base64.ts, makes it backward compatible
      const convertedVapidKey = urlBase64ToUint8Array(configs.VAPID_PUBLIC_KEY);

      // Subscribe the user, we are not strictly honoring userVisibleOnly: we will also be sending invisible pushes,
      // but otherwise Chrome will not allow us to setup a subscription.
      return await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      });
    }
    return subscription;
  };

  const sendUserSubscription = async (dayStr: string, subscription: PushSubscription) => {
    if (!profile && isSubbing) return;

    log("Start sending user subscription...");

    try {
      const subscriptionURL = String(configs.BASE_URL).concat("/notifications/webpush/subscribe");
      setSubbing(true);

      // TODO: add device info, and user location data to the payload.
      const payload = { username: profile?.username, userId: profile?._id, subscription };
      const response = await post<any, any>(subscriptionURL, payload);

      if (!!response && !!profile) ls.set(configs.PUSH_STORE_KEY, JSON.stringify({ [dayStr]: true }));
      log("Successfully sent user subscription [ok!]", response);

      setSubbing(false);
    } catch (error) {
      console.log(`[${useServiceWorkerReg.name}::sendUserSubscription] ::: Failed to send subscription to the backend`);
      setSubbing(false);
    }
  };

  const registerSubscription = async (webSubbed: WebSubbedType | null) => {
    const dayStr = new Date().toJSON().slice(0, 10);
    if (!webSubbed || (!!webSubbed && !webSubbed[dayStr])) {
      try {
        const registration = await navigator.serviceWorker.ready;
        log("user registration", registration);

        const subscription = await getSubscription(registration);
        log("user subscription", subscription);

        if (!!subscription) await sendUserSubscription(dayStr, subscription);

        return subscription;
      } catch (error: any) {
        console.log(`[${useServiceWorkerReg.name}::registerSubscription] ::: ${error.message}`);
        setSubbing(false);
      }
    }
  };

  //   console.group("useServiceWorker");

  useEffect(() => {
    try {
      (async () => {
        if ("serviceWorker" in navigator && auth?.isSignedIn) {
          if (isProd) env.current = "-prod";
          if (isStaging) env.current = "-staging";

          const swFilename = String("push").concat(env.current).concat(".js");
          const scriptPath = String("./").concat(swFilename);

          log("Service Worker Script Path", scriptPath);

          navigator.serviceWorker.register(scriptPath, { scope: "/" });
          const webSubbedStr = ls.get(configs.PUSH_STORE_KEY);
          const webSubbed: WebSubbedType | null = !!webSubbedStr ? JSON.parse(webSubbedStr) : null;
          // Let's check if the browser supports notifications
          if (!("Notification" in window)) {
            console.error("This browser does not support desktop notification");
          }
          // Let's check whether notification permissions have already been granted
          else if (Notification.permission === "granted") {
            log("Permission to receive notifications has been granted");
            await registerSubscription(webSubbed);
          }
          // Otherwise, we need to ask the user for permission
          else if (Notification.permission !== "denied") {
            Notification.requestPermission(async function (permission) {
              // If the user accepts, let's create a notification
              if (permission === "granted") {
                log("Permission to receive notifications has been granted");
                await registerSubscription(webSubbed);
              }
            });
          }
        }
      })();
    } catch {}

    // eslint-disable-next-line
  }, [auth]);

  //   console.groupEnd();

  return { isSubbing };
}
