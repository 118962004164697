import { Box, BoxProps, Button, Heading, ModalProps, Text, TextProps, useColorModeValue, VStack } from "@chakra-ui/react";
import { RocketSVG } from "assets";
import { AbstractModal } from "components";
// import { isTesting } from "config";
// import { useSidePage } from "contexts";

interface SuccessModalProps extends Omit<ModalProps, "children"> {
  onContinue?: () => void;
  closeOnContinue?: boolean;
  message: string;
  _content?: BoxProps;
  _contentWrapper?: BoxProps;
  _messageProps?: TextProps;
  children?: React.ReactNode;
}

export default function Success(props: SuccessModalProps) {
  const {
    message,
    isOpen,
    onClose,
    onContinue,
    closeOnContinue = true,
    children,
    _content,
    _contentWrapper,
    _messageProps,
    ...xprops
  } = props;
  const bColor = useColorModeValue("grey.500", "gray.100");
  const successMessageColor = useColorModeValue("grey.500", "grey.150");
  const hoverColor = useColorModeValue("black", "white");

  const handleContinue = () => {
    onContinue && onContinue();
    closeOnContinue && onClose();
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "520px",
        position: { base: "absolute", "1sm": "initial" },
        bottom: 0,
        margin: { base: "0", "1sm": "initial" },
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
        ..._content,
      }}
      {...xprops}
    >
      <VStack p={{ base: "24px 0px", "2sm": "48px" }} {..._contentWrapper}>
        <Box mb="24px !important" as={RocketSVG} />

        <Heading as="h4" fontSize="24px" fontWeight="600" mb="30px !important">
          Success
        </Heading>

        <VStack>
          <Text
            maxW="340px"
            color={successMessageColor}
            mb="40px !important"
            fontWeight="500"
            textAlign="center"
            {..._messageProps}
          >
            {message}
          </Text>
        </VStack>

        {!children ? (
          <VStack mt="24px !important" px="46px" w="100%">
            <Button minW="unset" w="100%" onClick={handleContinue}>
              Continue
            </Button>
            <Button
              mt="16px !important"
              variant="outline"
              minW="unset"
              w="100%"
              border="1px solid transparent"
              borderColor={bColor}
              onClick={onClose}
              _hover={{ color: hoverColor }}
            >
              Close
            </Button>
          </VStack>
        ) : (
          <>{children}</>
        )}
      </VStack>
    </AbstractModal>
  );
}
