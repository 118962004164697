import { BoxProps, CircularProgress, useColorModeValue, VStack } from "@chakra-ui/react";
import Card from "components/Card/Card";
import { AnimatePresence, Variants } from "framer-motion";
import { when } from "utils";

const list: Variants = {
  visible: {
    opacity: 1,
    scale: 1,
    transformOrigin: "top",
    rotateX: "0deg",
    transition: {
      type: "spring",
      bounce: 0.46,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.6,
    transition: {
      type: "spring",
      bounce: 0.46,
    },
  },
};

interface PageLoadingProps extends BoxProps {
  isLoading?: boolean;
}

export default function PageLoading(props: PageLoadingProps) {
  const { isLoading = false, ...xprops } = props;

  const color = useColorModeValue("primary.400", "secondary.300");

  const py = when(isLoading, "40px", "0");
  const key = when(isLoading, "page-loading", "page-loaded");

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {isLoading && (
        <VStack py={py} {...xprops}>
          <Card key={key} p="26px" initial="hidden" animate="visible" exit="hidden" variants={list}>
            <CircularProgress isIndeterminate size="24px" color={color} />
          </Card>
        </VStack>
      )}
    </AnimatePresence>
  );
}
