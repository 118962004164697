import {
  Box,
  BoxProps,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink as BCL,
  BreadcrumbLinkProps as BCLProps,
  FormControl,
  HStack,
  Image,
  List,
  ListItem,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useGetMinimumCryptoDepositQuery } from "apis";
import {
  ConditionalRender,
  CopyButton,
  EmptyCrate,
  FormInfo,
  Group,
  InputLabel,
  Select,
  SupportButton,
  Title,
  TitleBar,
  useModalView as useSteps,
} from "components";
import { useSidePage } from "contexts";
import { useCopy, useDisclosures, useWalletBalance } from "hooks";
import { WalletAddressRo } from "interfaces";
import { ReactNode, useEffect, useMemo, useState } from "react";
import CopyCryptoAddressWarningModal from "ui/Wallet/Modals/CopyCryptoAddressWarning";
import { toCoinLocale, toPrecision, when } from "utils";

interface DepositProps {}

interface DepositViewProps {
  coin: string;
  // meta?: { enabled: boolean; min: number; max: number };
  // isLoading?: boolean;
}

type ModalTypes = "copy_warning";

export default function Deposit(props: DepositProps) {
  const { params } = useSidePage();
  const { setCurrentView } = useSteps();
  const [is_consented, setConsented] = useState(false);
  const coin = useMemo(() => params?.coin ?? "btc", [params]);
  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const { data, isLoading } = useGetMinimumCryptoDepositQuery(coin);
  console.log("Minimum crypto deposit", data);

  const renderView = useMemo(() => {
    const map: Record<string, ReactNode> = {
      [coin]: <DefaultDepositView coin={coin} />,
      usdt: <USDTDepositView coin={coin} />,
      usdc: <USDCDepositView coin={coin} />,
    };
    return map[coin];
  }, [coin]);

  useEffect(() => {
    if (!is_consented && ["ton"].includes(coin)) {
      open("copy_warning")();
    }
  }, [is_consented, coin, open]);

  return (
    <Box id="crypto-deposit" px={{ base: "20px", "1sm": "40px" }} {...props} overflowY="scroll">
      <TitleBar>
        <Title fontSize="20px">{toCoinLocale(coin)} Wallet</Title>
      </TitleBar>

      <Breadcrumb
        fontFamily="var(--bitmama-fonts-heading)"
        separator=">"
        sx={{
          "span[role=presentation]": {
            color: "secondary.500",
            fontWeight: 500,
          },
        }}
      >
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => setCurrentView(0)}>{toCoinLocale(coin)}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>Deposit</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      {renderView}

      {!isLoading && (
        <FormInfo
          mb="40px"
          fontSize={{ base: "14px", "1sm": "16px" }}
          isInvalid={false}
          info="Minimum deposit amount: "
          description={toPrecision(data?.min ?? 0, coin)}
          _info={{ fontWeight: "500" }}
        />
      )}

      <FormInfo
        mb="40px"
        fontSize={{ base: "14px", "1sm": "16px" }}
        isInvalid={true}
        info="PS: "
        description="Kindly note that we do not support the BSC Network"
        _info={{ color: "error", fontStyle: "italic", fontWeight: "bold" }}
      />

      <CopyCryptoAddressWarningModal
        isOpen={isOpen("copy_warning")}
        onClose={() => {
          close("copy_warning")();
          setConsented(true);
        }}
      />
    </Box>
  );
}

interface BreadcrumbLinkProps extends BCLProps {}
interface CopyFieldProps extends BoxProps {
  value: string;
  label?: string;
}

function DefaultDepositView(props: DepositViewProps) {
  const { coin } = props;

  const { getCoin } = useWalletBalance();
  const coinData = useMemo(() => getCoin(coin as any), [coin, getCoin]);

  return (
    <Group bg="rgba(49, 183, 169, 0.05)" p={{ base: "42px 20px", "1sm": "42px 32px" }} borderRadius="20px">
      <List
        sx={{
          li: {
            fontWeight: "500",
            fontSize: "grey.500",
            mb: "36px",
            "&:last-child": {
              mb: "0",
            },
          },
        }}
      >
        <ListItem mb="14px !important">1. Copy the address or scan the QR code</ListItem>
        {!!coinData?.address && <CopyField mb={when(["xrp", "xlm"].includes(coin), "14px", "36px")} value={coinData?.address} />}
        {coin === "xlm" && coinData?.memo && <CopyField mb="36px" value={coinData?.memo} label={`Memo: ${coinData?.memo}`} />}
        {coin === "ton" && coinData?.memo && <CopyField mb="36px" value={coinData?.memo} label={`Memo: ${coinData?.memo}`} />}
        {coin === "xrp" && coinData?.tagNumber && (
          <CopyField mb="36px" value={coinData?.tagNumber} label={`Tag Number: ${coinData?.tagNumber}`} />
        )}

        <VStack mb="70px">
          <Image src={coinData?.qrCode} />
        </VStack>

        {coin === "xlm" && (
          <ListItem>
            2. Kindly include the memo in your transaction. NB: This is an extension of your Bitmama wallet and it helps us identify
            and verify the fund owner
          </ListItem>
        )}

        <ListItem>
          {when(coin === "xlm", "3", "2")}. Once the transaction is completed, you can click on the link to see the transaction
          details on explorer.
        </ListItem>
      </List>
    </Group>
  );
}

function USDCDepositView(props: DepositViewProps) {
  const { coin } = props;

  const [network, setNetwork] = useState("ethereum");

  const { getCoin } = useWalletBalance();
  const ethereum = useMemo(() => getCoin(coin as any), [coin, getCoin]);
  const polygon = useMemo(() => getCoin("usdc-matic" as any), [getCoin]);
  const stellar = useMemo(() => getCoin("usdc-stellar" as any), [getCoin]);
  const tron = useMemo(() => getCoin("usdc-tron" as any), [getCoin]);
  const base = useMemo(() => getCoin("usdc-base" as any), [getCoin]);
  const algorand = useMemo(() => getCoin("usdc-algo" as any), [getCoin]);
  const celo = useMemo(() => getCoin("usdc-celo" as any), [getCoin]);

  const coinData = useMemo(() => {
    const map = {
      ethereum,
      polygon,
      stellar,
      tron,
      base,
      algorand,
      celo,
    };
    return (map as any)[network] as WalletAddressRo;
  }, [ethereum, polygon, stellar, tron, base, network, algorand, celo]);

  return (
    <Group>
      <FormControl maxW="xs">
        <InputLabel fontWeight="500">Network</InputLabel>
        <Select placeholder="Select Network" value={network ?? ""} onChange={(e) => setNetwork(e.target.value)}>
          <option value="tron">Tron (TRC20)</option>
          <option value="ethereum">Ethereum (ERC20)</option>
          <option value="polygon">Polygon</option>
          <option value="stellar">Stellar</option>
          <option value="base">Base Chain</option>
          <option value="celo">Celo</option>
          {/* <option value="algorand">Algorand</option> */}
        </Select>
      </FormControl>

      <ConditionalRender shouldRender={!!coinData}>
        <Group bg="rgba(49, 183, 169, 0.05)" p="42px 32px" borderRadius="20px">
          <List
            sx={{
              li: {
                fontWeight: "500",
                fontSize: "grey.500",
                mb: "36px",
                "&:last-child": {
                  mb: 0,
                },
              },
            }}
          >
            <ListItem mb="14px !important">1. Copy the address or scan the QR code</ListItem>
            <CopyField mb={when(["stellar", "algorand"].includes(network), "14px", "36px")} value={coinData?.address ?? ""} />

            {network === "stellar" && coinData?.memo && (
              <CopyField mb="36px" value={coinData?.memo} label={`Memo: ${coinData?.memo}`} />
            )}

            {network === "algorand" && coinData?.note && (
              <CopyField mb="36px" value={coinData?.note} label={`Note: ${coinData?.note}`} />
            )}

            <VStack mb="70px">
              <Image src={coinData?.qrCode} />
            </VStack>

            <ListItem>
              2. Once the transaction is completed, you can click on the link to see the transaction details on explorer.
            </ListItem>
          </List>
        </Group>
      </ConditionalRender>

      <ConditionalRender shouldRender={!coinData}>
        <VStack my="56px !important">
          <EmptyCrate py="28px" type="transaction" description={"Wallet not found"} />
          <SupportButton />
        </VStack>
      </ConditionalRender>
    </Group>
  );
}

function USDTDepositView(props: DepositViewProps) {
  const { coin } = props;

  const [network, setNetwork] = useState("trc20");

  const { getCoin } = useWalletBalance();
  const erc20 = useMemo(() => getCoin(coin as any), [coin, getCoin]);
  const tron = useMemo(() => getCoin("usdt-tron" as any), [getCoin]);
  const polygon = useMemo(() => getCoin("usdt-matic" as any), [getCoin]);
  const algorand = useMemo(() => getCoin("usdt-algo" as any), [getCoin]);
  const celo = useMemo(() => getCoin("usdt-celo" as any), [getCoin]);

  const coinData = useMemo(() => {
    const map = {
      tron,
      erc20,
      polygon,
      algorand,
      celo,
    };
    return (map as any)[network] as WalletAddressRo;
  }, [erc20, tron, polygon, network, algorand, celo]);

  console.log("Coin Data", coinData);

  return (
    <Group>
      <FormControl maxW="xs">
        <InputLabel fontWeight="500">Network</InputLabel>
        <Select placeholder="Select Network" value={network ?? ""} onChange={(e) => setNetwork(e.target.value)}>
          <option value="tron">Tron (TRC20)</option>
          <option value="erc20">Ethereum (ERC20)</option>
          <option value="polygon">Polygon</option>
          <option value="celo">Celo</option>
          {/* <option value="algorand">Algorand</option> */}
        </Select>
      </FormControl>

      <ConditionalRender shouldRender={!!coinData}>
        <Group bg="rgba(49, 183, 169, 0.05)" p="42px 32px" borderRadius="20px">
          <List
            sx={{
              li: {
                fontWeight: "500",
                fontSize: "grey.500",
                mb: "36px",
                "&:last-child": {
                  mb: 0,
                },
              },
            }}
          >
            <ListItem mb="14px !important">1. Copy the address or scan the QR code</ListItem>
            <CopyField mb={when(["algorand"].includes(network), "14px", "36px")} value={coinData?.address ?? ""} />

            {network === "algorand" && coinData?.note && (
              <CopyField mb="36px" value={coinData?.note} label={`Note: ${coinData?.note}`} />
            )}

            <VStack mb="70px">
              <Image src={coinData?.qrCode} />
            </VStack>

            <ListItem>
              2. Once the transaction is completed, you can click on the link to see the transaction details on explorer.
            </ListItem>
          </List>
        </Group>
      </ConditionalRender>

      <ConditionalRender shouldRender={!coinData}>
        <VStack my="56px !important">
          <EmptyCrate py="28px" type="transaction" description={"Wallet not found"} />
          <SupportButton />
        </VStack>
      </ConditionalRender>
    </Group>
  );
}

function CopyField(props: CopyFieldProps) {
  const { value, label, ...xprops } = props;

  const { onCopy } = useCopy(value);

  return (
    <Box mb="14px" p={{ base: "20px 14px", "1sm": "20px" }} bg="white" borderRadius="20px" {...xprops}>
      <HStack>
        <CopyButton fontSize="14px" onClick={onCopy}>
          Click to copy
        </CopyButton>
        <Text
          fontWeight="500"
          color="grey.500"
          sx={{
            lineBreak: "anywhere",
          }}
        >
          {when(!!label, label, value)}
        </Text>
      </HStack>
    </Box>
  );
}

function BreadcrumbLink(props: BreadcrumbLinkProps) {
  const { children, ...xprops } = props;

  const color = useColorModeValue("secondary.400", "secondary.400");
  return (
    <BCL fontSize="14px" color={color} {...xprops}>
      {children}
    </BCL>
  );
}
