import { motion } from "framer-motion";
import { Box, VStack } from "@chakra-ui/react";
import Span from "components/Common/Span";
import Link from "components/Link/Link";
import { useModals } from "contexts";
const patternUrl: string = require("../../assets/images/val-bg-pattern.png").default;

const MotionBox = motion(Box);

// 2. Defining Variants
const marqueeVariants = {
  animate: {
    x: ["0%", "-100%"],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 17.5,
        ease: "linear",
      },
    },
  },
};

export function Marquee(props: any) {
  const { open } = useModals();

  return (
    <Box
      p="20px 0px"
      top="0"
      w="100%"
      h="var(--banner-h)"
      position="relative"
      zIndex="100"
      justifyContent="center"
      alignItems="center"
      display="flex"
      fontWeight="500"
      bg={`url("${patternUrl}")`}
      bgColor="#D71B48"
      backgroundSize="cover"
      mb="40px"
    >
      <Box w="100%" overflowX="hidden">
        <MotionBox whiteSpace="nowrap" variants={marqueeVariants} animate="animate">
          <Box>
            {Array(10)
              .fill(0)
              .map((_, i) => (
                <Span mx="20px" key={`item-${i}`} color="white">
                  Win a wishlist item this Valentine from Bitmama
                </Span>
              ))}
          </Box>
        </MotionBox>
      </Box>

      <VStack pos="absolute" h="100%" p="10px 40px" bg="#D71B48" right="0" alignItems="center" justifyContent="center">
        <Link color="white" fontWeight="500" onClick={open("christmas_updates")}>
          Learn More
        </Link>
      </VStack>
    </Box>
  );
}
