import { Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { Link as RouterLink } from "@reach/router";
import { useDefaultStyle } from "hooks";
import { FC, useMemo } from "react";
import { when } from "utils";

export interface LinkProps extends ChakraLinkProps {
  to?: string;
  disabled?: boolean;
  testId?: string;
}

const Link: FC<LinkProps> = (props) => {
  const { to, disabled, testId, ...xprops } = props;
  const { shadow } = useDefaultStyle();

  const as = useMemo(() => when<any>(!to, "button", when<any>(!!disabled, "button", RouterLink)), [to, disabled]);

  return (
    <ChakraLink
      cursor="pointer"
      as={as}
      data-testid={testId ?? "custom-link"}
      borderRadius="2px"
      display="inline-block"
      disabled={disabled}
      // isDisabled={disabled} // not supported
      _disabled={{ opacity: 0.4 }}
      _focus={{ shadow }}
      type="button"
      to={props?.to}
      {...xprops}
    >
      {props.children}
    </ChakraLink>
  );
};

export default Link;
