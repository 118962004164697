import { useMemo } from "react";
import { Box, BoxProps } from "@chakra-ui/layout";

import {
  HomeSVG,
  WalletSVG,
  P2pSVG,
  SwapSVG,
  CardsSVG,
  EarnSVG,
  AccountSVG,
  LogoutSVG,
  ActiveHomeSVG,
  ActiveWalletSVG,
  ActiveP2pSVG,
  ActiveCardsSVG,
  ActiveSwapSVG,
  ActiveAccountSVG,
  ActiveEarnSVG,
  CircleLeftArrowSVG,
  BuySVG,
  SellSVG,
  BcplSVG,
  StakingSVG,
  CircleMoreSVG,
  ChevronRightArrowSVG,
  UploadSVG,
  PdfSVG,
  CopySVG,
  GoogleSVG,
  ArrowRightSVG,
  CoffeeSVG,
  CallingSVG,
  SaleSVG,
  AirtimeAndDataSVG,
  BillsSVG,
  CryptoAlertSVG,
  InfoSVG,
  AccountProfileSVG,
  AccountBanksSVG,
  AccountMobileMoneySVG,
  AccountPartnerSVG,
  AccountVerificationSVG,
  AccountRewardSVG,
  AccountSupportSVG,
  FacebookSVG,
  TwitterSVG,
  WhatsappSVG,
  AccountJoinPartnerSVG,
  AccountResourcesSVG,
  AccountRegulationsSVG,
  AccountLeaderboardSVG,
  MedalSVG,
  FilterSVG,
  CheckBoxSVG,
  VerificationFailedSVG,
  VerificationPendingSVG,
  VerificationCompletedSVG,
  AddCircleSVG,
  ConvertSVG,
  SwapArrowSVG,
  VerifiedUserSVG,
  TimerSVG,
  CancelSVG,
  LockCheckSVG,
  DocumentDeliteSVG,
  MoneySVG,
  MoneyReceiveSVG,
  EditFilledSVG,
  PlayFilledSVG,
  TimerFilledSVG,
  TrashFilledSVG,
  EyeFilledSVG,
  FaqFilledSVG,
  StakingFilledSVG,
  SavingsFilledSVG,
  DefiFilledSVG,
  FixedStakingFilledSVG,
  FlexiStakingFilledSVG,
  BitcoinSquareFilledSVG,
  ShieldOutlinedSVG,
  TimeCircleSVG,
  RoundedRightArrowSVG,
  DiagonalDotSVG,
  NotificationSVG,
  SupportSVG,
  AccountLogoutSVG,
  AccountDefaultCurrencySVG,
  FailedSVG,
  PlusSVG,
  MoreVertSVG,
  AccountPriceAlertSVG,
  DeleteAccountSVG,
  DisputeSVG,
  ChatSVG,
  InAppCallSVG,
  SendOutlineSVG,
  SendFilledSVG,
  StarOutlineSVG,
  CheckSVG,
  ArrowUpSVG,
  ArrowDownSVG,
  MsgSVG,
  SendGreenBoxSVG,
  ClaimGreenBoxSVG,
  RoundedGiffBoxSVG,
  ClaimButtonIconSVG,
  ErrorMojiSVG,
  SadMojiSVG,
  ChevronDownArrowSVG,
  InfoErrorSVG,
  BoltSVG,
  CameraSVG,
  WarningSVG,
  InfoErrorRoundedSVG,
  MessageCircleSVG,
  SwapSwitchSVG,
  InfoRoundedSVG,
  AchievementsSVG,
  BitStarLevel1SVG,
  TourSVG,
  ChristmasBellsSVG,
  RoundedCheckSVG,
  FacebookRectSVG,
  WhatsappRectSVG,
  InstagramRectSVG,
  PiggyBankSVG,
  YieldSVG,
  PlayToEarnSVG,
  AutoBuySellSVG,
  AngryFaceEmojiSVG,
  ConfusedFaceEmojiSVG,
  NeutralFaceEmojiSVG,
  SlightlySmilingFaceEmojiSVG,
  GrinningFaceEmojiSVG,
  WhatsappOutlineSVG,
  UserCircleAddSVG,
} from "assets";
import { useColorMode } from "@chakra-ui/react";
import { switchStyle } from "utils";

export type IconNames =
  | "home"
  | "wallet"
  | "p2p"
  | "pdf"
  | "cards"
  | "swap"
  | "account"
  | "logout"
  | "earn"
  | "buy"
  | "sell"
  | "copy"
  | "google"
  | "staking"
  | "bcpl"
  | "circleMore"
  | "filledHome"
  | "filledWallet"
  | "filledP2p"
  | "filledCards"
  | "filledSwap"
  | "filledAccount"
  | "filledEarn"
  | "upload"
  | "coffee"
  | "calling"
  | "sale"
  | "arrowRight"
  | "circleLeftArrow"
  | "roundedRightArrow"
  | "diagonalDot"
  | "airtimeAndData"
  | "cryptoAlert"
  | "bills"
  | "info"
  | "chevronRightArrow"
  | "chevronDownArrow"
  | "accountProfile"
  | "accountBank"
  | "accountMomo"
  | "accountPartner"
  | "accountVerification"
  | "accountReward"
  | "accountSupport"
  | "facebook"
  | "twitter"
  | "whatsapp"
  | "joinPartner"
  | "resources"
  | "regulations"
  | "leaderboard"
  | "medal"
  | "filter"
  | "checkBox"
  | "addCircle"
  | "verificationFailed"
  | "verificationPending"
  | "verificationCompleted"
  | "convert"
  | "swapArrow"
  | "verifiedUser"
  | "timer"
  | "cancel"
  | "lockCheck"
  | "documentDelite"
  | "money"
  | "moneyReceive"
  | "editFilled"
  | "timerFilled"
  | "playFilled"
  | "trashFilled"
  | "eyeFilled"
  | "faqFilled"
  | "stakingFilled"
  | "savingsFilled"
  | "defiFilled"
  | "fixedStakingFilled"
  | "flexiStakingFilled"
  | "bitcoinSquareFilled"
  | "shieldOutlined"
  | "timeCircle"
  | "notification"
  | "support"
  | "defaultCurrency"
  | "accountLogout"
  | "failed"
  | "moreVert"
  | "accountPriceAlert"
  | "deleteAccount"
  | "plus"
  | "dispute"
  | "chat"
  | "inAppCall"
  | "sendOutline"
  | "sendFilled"
  | "check"
  | "arrowUp"
  | "arrowDown"
  | "msg"
  | "starOutline"
  | "sendGreenbox"
  | "roundedGift"
  | "claimButtonIcon"
  | "errorMoji"
  | "sadMoji"
  | "claimGreenbox"
  | "infoError"
  | "infoRounded"
  | "infoErrorRounded"
  | "bolt"
  | "camera"
  | "warning"
  | "messageCircle"
  | "swapSwitch"
  | "achievements"
  | "newBitStar_level_1"
  | "christmasBell"
  | "checkRounded"
  | "facebookRect"
  | "instagramRect"
  | "whatsappRect"
  | "whatsappOutline"
  | "tour"
  | "piggyBank"
  | "yield"
  | "playToEarn"
  | "autoBuySell"
  | "emoji_angryFace"
  | "emoji_confusedFace"
  | "emoji_neutralFace"
  | "emoji_slightlySmilingFace"
  | "emoji_grinningWithBigEyeFace"
  | "userCircleAdd";

export interface IconProps extends BoxProps {
  type: IconNames;
}

type MapType = Record<
  IconNames,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
>;

export default function Icon(props: IconProps) {
  const { type, ...xprops } = props;

  const { colorMode: mode } = useColorMode();
  const color = switchStyle(mode, {
    light: "primary.800",
    dark: "secondary.400",
  });

  const MappedIcon = useMemo(() => {
    const map: MapType = {
      // sidebar icons
      home: HomeSVG,
      wallet: WalletSVG,
      p2p: P2pSVG,
      cards: CardsSVG,
      swap: SwapSVG,
      account: AccountSVG,
      earn: EarnSVG,
      logout: LogoutSVG,
      // active sidebar icons
      filledHome: ActiveHomeSVG,
      filledWallet: ActiveWalletSVG,
      filledP2p: ActiveP2pSVG,
      filledCards: ActiveCardsSVG,
      filledSwap: ActiveSwapSVG,
      filledAccount: ActiveAccountSVG,
      filledEarn: ActiveEarnSVG,
      // app icons
      circleLeftArrow: CircleLeftArrowSVG,
      roundedRightArrow: RoundedRightArrowSVG,
      buy: BuySVG,
      sell: SellSVG,
      bcpl: BcplSVG,
      staking: StakingSVG,
      circleMore: CircleMoreSVG,
      diagonalDot: DiagonalDotSVG,
      chevronRightArrow: ChevronRightArrowSVG,
      chevronDownArrow: ChevronDownArrowSVG,
      upload: UploadSVG,
      pdf: PdfSVG,
      copy: CopySVG,
      google: GoogleSVG,
      arrowRight: ArrowRightSVG,
      coffee: CoffeeSVG,
      calling: CallingSVG,
      sale: SaleSVG,
      msg: MsgSVG,
      verifiedUser: VerifiedUserSVG,
      shieldOutlined: ShieldOutlinedSVG,
      notification: NotificationSVG,
      support: SupportSVG,

      bills: BillsSVG,
      cryptoAlert: CryptoAlertSVG,
      airtimeAndData: AirtimeAndDataSVG,

      infoError: InfoErrorSVG,
      infoRounded: InfoRoundedSVG,
      infoErrorRounded: InfoErrorRoundedSVG,
      messageCircle: MessageCircleSVG,
      checkRounded: RoundedCheckSVG,

      piggyBank: PiggyBankSVG,
      yield: YieldSVG,
      playToEarn: PlayToEarnSVG,
      autoBuySell: AutoBuySellSVG,

      info: InfoSVG,
      addCircle: AddCircleSVG,
      convert: ConvertSVG,
      swapArrow: SwapArrowSVG,
      timer: TimerSVG,
      cancel: CancelSVG,
      lockCheck: LockCheckSVG,
      documentDelite: DocumentDeliteSVG,
      money: MoneySVG,
      moneyReceive: MoneyReceiveSVG,
      editFilled: EditFilledSVG,
      playFilled: PlayFilledSVG,
      timerFilled: TimerFilledSVG,
      timeCircle: TimeCircleSVG,
      trashFilled: TrashFilledSVG,
      eyeFilled: EyeFilledSVG,
      faqFilled: FaqFilledSVG,
      stakingFilled: StakingFilledSVG,
      savingsFilled: SavingsFilledSVG,
      defiFilled: DefiFilledSVG,
      fixedStakingFilled: FixedStakingFilledSVG,
      flexiStakingFilled: FlexiStakingFilledSVG,
      bitcoinSquareFilled: BitcoinSquareFilledSVG,

      facebookRect: FacebookRectSVG,
      whatsappRect: WhatsappRectSVG,
      instagramRect: InstagramRectSVG,

      sadMoji: SadMojiSVG,
      sendGreenbox: SendGreenBoxSVG,
      claimGreenbox: ClaimGreenBoxSVG,
      roundedGift: RoundedGiffBoxSVG,
      claimButtonIcon: ClaimButtonIconSVG,

      // --- Account Icons --- //
      accountProfile: AccountProfileSVG,
      accountBank: AccountBanksSVG,
      accountMomo: AccountMobileMoneySVG,
      accountPartner: AccountPartnerSVG,
      accountVerification: AccountVerificationSVG,
      accountReward: AccountRewardSVG,
      accountSupport: AccountSupportSVG,
      accountPriceAlert: AccountPriceAlertSVG,
      accountLogout: AccountLogoutSVG,
      deleteAccount: DeleteAccountSVG,
      achievements: AchievementsSVG,
      facebook: FacebookSVG,
      twitter: TwitterSVG,
      whatsapp: WhatsappSVG,
      whatsappOutline: WhatsappOutlineSVG,

      joinPartner: AccountJoinPartnerSVG,
      resources: AccountResourcesSVG,
      regulations: AccountRegulationsSVG,
      leaderboard: AccountLeaderboardSVG,
      defaultCurrency: AccountDefaultCurrencySVG,
      medal: MedalSVG,

      filter: FilterSVG,
      checkBox: CheckBoxSVG,
      check: CheckSVG,

      chat: ChatSVG,
      dispute: DisputeSVG,
      inAppCall: InAppCallSVG,

      sendOutline: SendOutlineSVG,
      sendFilled: SendFilledSVG,
      starOutline: StarOutlineSVG,
      arrowUp: ArrowUpSVG,
      arrowDown: ArrowDownSVG,

      plus: PlusSVG,
      failed: FailedSVG,
      errorMoji: ErrorMojiSVG,
      moreVert: MoreVertSVG,
      verificationFailed: VerificationFailedSVG,
      verificationPending: VerificationPendingSVG,
      verificationCompleted: VerificationCompletedSVG,

      bolt: BoltSVG,
      camera: CameraSVG,
      warning: WarningSVG,
      swapSwitch: SwapSwitchSVG,
      tour: TourSVG,
      christmasBell: ChristmasBellsSVG,
      userCircleAdd: UserCircleAddSVG,

      /// Gamification Badge Icon
      newBitStar_level_1: BitStarLevel1SVG,

      // --- Emoji Icons --- //
      emoji_angryFace: AngryFaceEmojiSVG,
      emoji_confusedFace: ConfusedFaceEmojiSVG,
      emoji_neutralFace: NeutralFaceEmojiSVG,
      emoji_slightlySmilingFace: SlightlySmilingFaceEmojiSVG,
      emoji_grinningWithBigEyeFace: GrinningFaceEmojiSVG,
    };

    return map[type];
  }, [type]);

  return <Box color={color} as={MappedIcon} {...xprops} />;
}
