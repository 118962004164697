import { AttachmentIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  CloseButton,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";

import {
  useGetTradeChatsV2Mutation,
  useInitTradeChatMutation,
  useLeaveTradeChatMutation,
  useRingTraderMutation,
  useSendTradeChatMutation,
} from "apis";
import configs from "config";
import { format, parseISO } from "date-fns";
import useRedebounce from "hooks/useDebounce";
import Pusher from "pusher-js";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { diffOfTwoDates, when } from "utils";
import uploadFile from "utils/do";
import ls from "utils/secureStorage";

import Card from "components/Card/Card";
import { ConditionalRender } from "components/ConditionalRender/ConditionalRender";
import Icon from "components/Icon/Icon";
import Uploader from "components/Uploader/Uploader";
import { motion } from "framer-motion";
import { useColor, usePartialState, useSelector } from "hooks";
import isEmpty from "lodash/isEmpty";
import unique from "lodash/uniqBy";
import { selectUser } from "store/slices";
import { ConfirmationModal } from "ui";

const MessageInput = (props: any) => {
  const { onOpen, isUploading, onSubmit } = props;
  const inputBg = useColorModeValue("#F2F4F3", "dark.cardBg");
  const textColor = useColorModeValue("grey.500", "grey.100");

  return (
    <InputGroup size="md">
      <InputLeftElement w="40px" pl="1rem">
        <IconButton
          maxW="fit-content"
          size="sm"
          w="28px"
          h="28px"
          minW="28px"
          minH="28px"
          variant="transparent"
          aria-label="attachment"
          // onClick={uploadAttachment}
          icon={<AttachmentIcon />}
          onClick={onOpen}
        />
      </InputLeftElement>
      <Input
        minH="53px"
        bg={inputBg}
        borderRadius="5px"
        border="none"
        pl="3rem"
        pr="3.2rem"
        type="text"
        placeholder="Type your message"
        _placeholder={{
          color: textColor,
          fontWeight: "500",
          fontSize: "14px",
          fontFamily: "var(--bitmama-fonts-body)",
        }}
        {...props}
      />
      <InputRightElement w="fit-content" pr="1rem">
        <IconButton
          maxW="fit-content"
          size="sm"
          w="28px"
          h="28px"
          minW="28px"
          minH="28px"
          variant="transparent"
          aria-label="attachment"
          onClick={() => onSubmit()}
          isLoading={isUploading}
          disabled={isUploading}
          icon={<Icon type="sendFilled" boxSize="20px" mr="2px" />}
          // color={textColor}
        />
      </InputRightElement>
    </InputGroup>
  );
};

const MessangerHeader = ({ ringUser, init, tradingParty, chatParties, isChatsOver, unReads }: any) => {
  // const ringTrader = (e: any) => {
  //   try {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     ringUser && ringUser();
  //   } catch (err) {
  //     // do something with error here
  //   }
  // };
  // const onlineUsers = chatParties.length ? chatParties.join(", ") : tradingParty;
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" bg="#F1F1F1" p="20px 24px">
      <Box bg="#F1F1F1">
        <Text fontSize="20px" color="#4E4B66" fontFamily="var(--bitmama-fonts-heading)">
          {/* {onlineUsers}{" "} */}
          Chat
          {unReads?.length ? (
            <Badge ml="1" mt="-4" colorScheme="red">
              {unReads.length} unread
            </Badge>
          ) : null}
        </Text>
      </Box>
      <Box borderRadius="8px" h="fit-content" transform="translateY(-10%)">
        {init && !isChatsOver ? (
          <IconButton
            // _focus={{ shadow: "0 0 0 3px var(--focusColor)" }}
            // _active={{ shadow: "0 0 0 3px var(--focusColor)" }}
            size="sm"
            minW="32px"
            minH="32px"
            maxW="unset"
            maxH="unset"
            variant="transparent"
            aria-label="contact"
            visibility="hidden"
            // onClick={ringTrader}
            icon={<Icon type="moreVert" />}
          />
        ) : null}
      </Box>
    </Box>
  );
};

const Message = forwardRef((props: any, ref: any) => {
  const { isSender, children, data, isAdmin, type } = props;
  const colorMap: Record<string, string> = {
    true: "var(--sendColor)",
    false: "var(--receiveColor)",
    sysmsg: "#bcbec059",
    admin: "#f8dadd",
  };

  const borderRadiusMap: Record<string, string> = {
    true: "16px 0px 16px 16px",
    false: "0px 16px 16px 16px",
    sysmsg: "0px 16px",
  };

  console.log("MESSAGE DATA", props);

  return (
    <Box
      alignSelf={type === "sysmsg" ? "center" : "flex-end"}
      p={type === "sysmsg" ? "6px 14px" : "14px"}
      bg={colorMap[type] || colorMap[isAdmin ? "admin" : "null"] || colorMap[isSender ?? true]}
      w="fit-content"
      borderRadius={borderRadiusMap[type] || [isSender ?? true]}
      ref={ref}
    >
      {isSender ? null : (
        <Box alignSelf="flex-start" p={"6px 0px"} color={isAdmin ? "#db4655" : "#8ba149"} w="fit-content" ref={ref}>
          {data.username}
        </Box>
      )}

      {data?.type === "file" && (
        <Stack alignItems="flex-end">
          <Image
            src={data?.message}
            alt={data?.caption ?? ""}
            maxW="80px"
            h="100px"
            borderRadius="10px"
            overflow="hidden"
            objectFit="cover"
            onClick={() => window && window?.open(data?.message, "_blank")}
          />
          {data?.caption && (
            <Text textAlign="right" fontSize="sm" ref={ref}>
              {data?.caption}
            </Text>
          )}
        </Stack>
      )}

      {data?.type !== "file" && (
        <Text fontSize="sm" ref={ref}>
          {children}
        </Text>
      )}
    </Box>
  );
});

const MessageGroup = forwardRef((props: any, ref: any) => {
  const positionMap: Record<string, string> = {
    true: "flex-end",
    false: "flex-start",
    sysmsg: "center",
  };
  const { children, isSender, date, type } = props;

  const renderChildren = () =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) return React.cloneElement(child, { isSender } as any);
    });

  return (
    <Stack
      maxW={type === "sysmsg" ? "100%" : "70%"}
      alignItems={positionMap[type] || positionMap[isSender ?? true]}
      alignSelf={positionMap[type] || positionMap[isSender ?? true]}
      pb="20px"
    >
      {renderChildren()}
      {date && children && (
        <Text fontSize="xs" mt="8px" ref={ref}>
          {format(parseISO(date), "HH:mm")}
        </Text>
      )}
    </Stack>
  );
});

const MessangerBody = ({ chats = [], setChatStateFromGlobal }: any) => {
  const redebounce = useRedebounce();
  const { profile } = useSelector(selectUser);
  const focusDOM = useRef();
  const userActuallyScrolledUp = useRef(false);
  const chatBoxRef = useRef<any>();

  const _pinMsg = [
    `This trade is currently under dispute and our support team will
  initiate a three way conversation to resolve this issue.`,
  ];

  const pinMsg = chats.filter((c: any) => c.pin);
  const _chats = chats.filter((c: any) => !c.pin);

  function scrollToLastMessage() {
    if (userActuallyScrolledUp.current) return; // do not interrupt user's scroll;
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight;
  }

  const username = profile?.username;

  const messageGroup: any[] = [];
  let benchedChat: any[] = [];
  let trailingChat = _chats[0];

  const checkDimensions = (firstLoad: any) => {
    const lastItemInView = checkInView(chatBoxRef?.current, focusDOM?.current, true);
    if (lastItemInView) {
      userActuallyScrolledUp.current = false;
      setChatStateFromGlobal((p: any) => ({
        ...p,
        chatInFocus: true,
        unReadChats: [],
        chatOpened: true,
      }));
    } else {
      userActuallyScrolledUp.current = true;
      redebounce(
        () => {
          userActuallyScrolledUp.current = false;
        },
        `resetScrollPivileges`,
        5000
      )();
      if (!firstLoad) setChatStateFromGlobal((p: any) => ({ ...p, chatInFocus: false }));
    }
  };

  function checkInView(container: any, element: any, partial: any) {
    if (!container) return false;
    if (!element) return false;

    //Get container properties
    let cTop = container.scrollTop;
    let cBottom = cTop + container.clientHeight;

    //Get element properties
    let eTop = element.getBoundingClientRect().top - container.getBoundingClientRect().top;
    let eBottom = eTop + element.clientHeight;

    //Check if in view
    let isTotal = eTop >= cTop && eBottom <= cBottom;
    let isPartial = partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));

    //Return outcome
    return isTotal || isPartial;
  }

  useEffect(() => {
    console.log({ chats: chats.length });
    if (chats?.length && chatBoxRef.current) {
      scrollToLastMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats?.length]);

  useEffect(() => {
    if (chatBoxRef.current) {
      checkDimensions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatBoxRef?.current]);

  const reclaimBenchChat = () => {
    if (benchedChat.length > 1) {
      messageGroup.push({
        grouped: true,
        chats: benchedChat,
        type: benchedChat[0].type,
        username: benchedChat[0]?.username || "",
        messageTime: benchedChat[benchedChat.length - 1]?.messageTime,
        chatId: Math.random() * 349995343,
      });
    } else {
      messageGroup.push(benchedChat[0]);
    }
    benchedChat = [];
  };

  _chats.forEach((c: any, index: number) => {
    if (index) {
      if (c.type === trailingChat.type && diffOfTwoDates(c.messageTime, trailingChat?.messageTime) < 3600) {
        if (c.username === trailingChat?.username) {
        } else {
          reclaimBenchChat();
        }
      } else {
        reclaimBenchChat();
      }
    }
    trailingChat = c;
    benchedChat.push(trailingChat);
    if (index === _chats.length - 1) reclaimBenchChat();
  });

  const msgGroupLen = messageGroup.length;

  return (
    <div ref={chatBoxRef}>
      <Stack
        p="14px 18px"
        maxH="420px"
        overflowY={"overlay" as any}
        pb="0 !important"
        onScroll={checkDimensions}
        __css={{
          "::-webkit-scrollbar": {
            w: "2px",
          },
          "::-webkit-scrollbar-thumb": {
            bg: "rgba(0,0,0,.1)",
            borderRadius: "12px",
          },
          "::-webkit-scrollbar-track": {
            background: "transparent",
          },
        }}
      >
        {pinMsg.map((pM: any) => (
          <Box key={pM.chatId} bg="var(--orange)" p="14px" borderRadius="8px" pos="sticky" top="0" h="fit-content">
            <Text fontSize="sm">{pM.message || _pinMsg[0]}</Text>
          </Box>
        ))}

        <Stack pt="80px" gridGap="20px">
          {messageGroup.map((msgG, index) =>
            msgG.grouped ? (
              <MessageGroup
                type={msgG.type}
                username={msgG.username}
                isSender={msgG.username === username}
                date={msgG.messageTime}
                key={msgG.chatId}
              >
                {msgG.chats.map((c: any) => (
                  <Message
                    {...(index === msgGroupLen - 1 && {
                      ref: focusDOM,
                      tabIndex: -1,
                    })}
                    type={c.type}
                    format={c.format}
                    reads={c.reads}
                    key={c.chatId}
                    data={c}
                  >
                    {c.message}
                  </Message>
                ))}
              </MessageGroup>
            ) : (
              <MessageGroup
                type={msgG.type}
                username={msgG.username}
                isSender={msgG.username === username}
                date={msgG.messageTime}
                key={msgG.chatId}
              >
                <Message
                  type={msgG.type}
                  data={msgG}
                  format={msgG.format}
                  {...(index === msgGroupLen - 1 && {
                    ref: focusDOM,
                    tabIndex: -1,
                  })}
                  reads={msgG.reads}
                >
                  {msgG.message}
                </Message>
              </MessageGroup>
            )
          )}

          {/* <MessageGroup isSender={false} date={msgG.messageTime}>
          <Message>
            This trade is currently under dispute and our support team will
            initiate a three way conversation to resolve this issue.
          </Message>
        </MessageGroup> */}
        </Stack>
      </Stack>
    </div>
  );
};

const MessangerFooter = ({ send, init, initMessage, addAttachment }: any) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [file, setFile] = useState(null);
  const [msg, setMsg] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onSubmit = async () => {
    try {
      let image;
      if (file) {
        setIsUploading(true);
        image = (await uploadFile(file, `p2p-chat-attachment-${Date.now()}`)).location;
        setIsUploading(false);
      }

      if (msg && image && send) {
        send({ message: image, caption: msg, fileType: "file" });
      } else if (!msg && image && send) {
        send({ message: image, fileType: "file" });
      } else if (msg && !image && send) {
        send({ message: msg, fileType: "text" });
      }
      // const msg = e.target.querySelector("input").value;

      // send && send({ message: msg, fileType: "text" });
      // e.target.reset();

      setFile(null);
      setMsg(null);
    } catch (err) {
      // do something with error here
    }
  };

  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();

    await onSubmit();
  };

  // const uploadAttachment = (file) => {
  //   try {
  //     // e.preventDefault();
  //     // e.stopPropagation();
  //     // send file to cloud, obtain url
  //     console.log("attachment", file);
  //     const imgageUrl = "s3.blah_blah_blah";
  //     const fileType = "file" || "media" || "image" || "audio" || "video";
  //     if (2 + 2 === 5) send && send({ message: imgageUrl, fileType });
  //     // e.target.reset();
  //   } catch (err) {
  //     // do something with error here
  //   }
  // };

  return (
    <>
      {file && (
        <Box bg="#edf3f1" w="100%" p="10px 18px">
          <HStack justifyContent="space-between">
            <Image src={(file as any).preview} h="60px" borderRadius="4px" overflow="hidden" shadow="base" objectFit="contain" />

            <IconButton
              _focus={{ shadow: "0 0 0 3px var(--focusColor)" }}
              _active={{ shadow: "0 0 0 3px var(--focusColor)" }}
              size="xs"
              variant="ghost"
              aria-label="attachment"
              // onClick={uploadAttachment}
              icon={<CloseIcon boxSize="10px" />}
              onClick={() => setFile(null)}
            />
          </HStack>
        </Box>
      )}
      <Box
        as="form"
        bg="transparent"
        // shadow="0px -2px 4px rgba(0, 0, 0, 0.05)"
        p="14px 18px"
        pb="20px"
        onSubmit={handleSubmit}
        mt="0 !important"
      >
        <HStack>
          {init ? (
            <>
              <Box w="100%" p="14px" borderRadius="8px" pos="sticky" top="0" h="fit-content">
                <Button minW={{ sm: "100%", "2sm": "400px", "3sm": "400px", md: "400px" }} fontSize="sm" onClick={send}>
                  {initMessage || "Chat"}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <MessageInput
                onOpen={onOpen}
                isLoading={isUploading}
                value={msg ?? ""}
                onSubmit={onSubmit}
                onChange={(e: any) => setMsg(e.target.value)}
              />
              {/* <Button
                fontWeight="400"
                fontSize="xs"
                _focus={{ shadow: "0 0 0 3px var(--focusColor)" }}
                _active={{ shadow: "0 0 0 3px var(--focusColor)" }}
                variant="solid"
                size="sm"
                borderRadius="6px"
                bg="#FAFAFA"
                type="submit"
                isLoading={isUploading}
                disabled={isUploading}
              >
                Send
              </Button> */}
            </>
          )}
        </HStack>
        <MessagingFileUpload isOpen={isOpen} onClose={onClose} onAdd={setFile} />
      </Box>
    </>
  );
};

const MotionBox = motion(Box);

export const Messanger = ({
  sessionActive,
  tradeId,
  frozenRef,
  tradeOwner,
  trader,
  chatState: chatStateFromGlobal,
  setChatState: setChatStateFromGlobal,
}: // userInitialisedChat,
any) => {
  const { profile } = useSelector(selectUser);
  const toast = useToast();

  const channelName = `${tradeId}-${frozenRef}`;
  const [chatState, setChatState] = useState("");
  const currentParties = useRef<any[]>([]);

  console.log("[PUSHER] Chat state", chatState);

  const color = useColor();
  const initInProgress = useRef(false);

  const isSendingCache = useRef<any>();
  const tradeChat = useRef<any>();
  const [_sendTradeChat, { isLoading: isSending }] = useSendTradeChatMutation();
  const [_initTradeChat] = useInitTradeChatMutation();
  const [_leaveTradeChat] = useLeaveTradeChatMutation();
  const [_ringTrader] = useRingTraderMutation();
  const [getTradeChats] = useGetTradeChatsV2Mutation();
  const [chats, setChats] = useState<any[]>([]);
  const [isLoadingChats, setIsLoadingChats] = useState(false);

  let tradingParty = tradeOwner;
  if (tradeOwner?.user?.userId === profile?.userId) {
    tradingParty = trader;
  }

  const chatStatus = useMemo(() => {
    return {
      is: (status: any) => chatState.includes(status),
    };
  }, [chatState]);

  const initPusherAuth = async (isPartial = true) => {
    if (!tradeChat.current) {
      const auth = ls.get(configs.AUTH_TOKEN_KEY);
      const token = auth.token;
      const pusherHost = configs.REACT_APP_PUSHER_HOST || "";

      const pusher = new Pusher(configs.REACT_APP_PUSHER_KEY, {
        authEndpoint: `${configs.REACT_APP_API_ENDPOINT}/p2p/chats/auth`,
        cluster: configs.REACT_APP_PUSHER_CLUSTER,
        ...(pusherHost && {
          cluster: undefined,
          wsHost: pusherHost,
          enabledTransports: ["ws", "wss"],
        }),
        auth: {
          params: { channelName },
          headers: { authorization: token },
        },
      });
      tradeChat.current = pusher.subscribe(channelName);
      tradeChat.current?.members?.each(function (member: any) {
        var userInfo = member.info;
        const allButThisParty = currentParties.current.filter((u) => u.username === userInfo.username);
        currentParties.current = [userInfo].concat(allButThisParty);
      });

      tradeChat.current.bind("chat-update", (data: any) => {
        if (isPartial) {
          setChatStateFromGlobal({
            chatInFocus: true,
            unReadChats: [],
            chatOpened: true,
          });
          setChatState("init active");
        }

        setChats((prev: any) => unique([...prev, data], "chatId"));
      });
      // setChatStateFromGlobal((p: any) => ({
      // ...p,
      // chatInFocus: true,
      // unReadChats: [],
      // chatOpened: true,
      // }));

      !isPartial &&
        setChatStateFromGlobal({
          chatInFocus: true,
          unReadChats: [],
          chatOpened: true,
        });
    }
  };

  const initializeChat = async () => {
    if (!chatStatus.is("init")) {
      const initResp: any = await _initTradeChat({ channelName });
      if (initResp?.error?.data?.error?.msg) {
        return toast({
          position: "top-right",
          title: "Opps",
          description: String(initResp?.error?.data?.error?.msg),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (initResp?.data?.data?.session === "init") {
        setChatState((prev) => {
          let newSt = (String(prev).replace(/init/g, "").trim() + " init ").trim();
          return (
            String(newSt)
              .replace(/active/g, "")
              .trim() + " active "
          ).trim();
        });
      } else if (initResp?.data?.data?.session === "active") {
        setChatState((prev) => {
          let newSt = (String(prev).replace(/init/g, "").trim() + " init ").trim();
          return (
            String(newSt)
              .replace(/active/g, "")
              .trim() + " active "
          ).trim();
        });
      } else {
        return setChatState((prev) => {
          let newSt = (String(prev).replace(/init/g, "").trim() + "").trim();
          return (
            String(newSt)
              .replace(/active/g, "")
              .trim() + " closed "
          ).trim();
        });
      }
      const chats = await getTradeChats({ channelName });

      setChats((chats as any)?.data);
      await initPusherAuth();
    }
  };

  // const isSupportOn = useMemo(() => !!chatStateFromGlobal?.isSupportOn, [chatStateFromGlobal]);
  // const chatPosition = useMemo(() => when<string>(isSupportOn, "left", "right"), [isSupportOn]);

  const isChatsOver = useMemo(() => {
    let check = false;
    const chatsLen = chats?.length;
    if (!sessionActive) return true;
    if (chatsLen) {
      if (chats[chatsLen - 1].command === "closechat") check = true;
    }
    return check;
    // eslint-disable-next-line
  }, [chats, chats?.length, sessionActive]);

  useEffect(() => {
    initMessenger();
    return () => {
      _leaveTradeChat({ channelName }).catch((err) => {});
      setChatStateFromGlobal((p: any) => ({ ...p, chatInFocus: false }));
      tradeChat.current && tradeChat.current.unsubscribe(channelName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reinitialize when new chats drops when chat box was not in focus
  useEffect(() => {
    if (chatStateFromGlobal.unReadChats?.length) initMessenger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatStateFromGlobal.unReadChats?.length]);

  const initMessenger = async () => {
    if (!chatStatus.is("active") && sessionActive && !initInProgress.current) {
      initInProgress.current = true;
      setIsLoadingChats(true);
      try {
        const chats = await getTradeChats({ channelName });
        if ((chats as any)?.error) {
          const err = (chats as any).error;
          if (err?.data?.data?.session === "notFound") {
          }
          initPusherAuth(true);
        } else if ((chats as any).data) {
          setChatState((prev) => {
            let newSt = (String(prev).replace(/init/g, "").trim() + " init ").trim();
            return (
              String(newSt)
                .replace(/active/g, "")
                .trim() + " active "
            ).trim();
          });
          setChats((chats as any).data);

          console.log("[PUSHER] Got to the initialisation point");
          initPusherAuth(false);
        }
      } catch (e) {
        console.log("[PUSHER] An error occurrred initialising the pusher", e);
      } finally {
        setIsLoadingChats(false);
        initInProgress.current = false;
        console.log("[PUSHER] tryCatch block resolved");
      }
    }
  };

  if (isLoadingChats) return null; // might want to create a loading interface here
  if (isChatsOver && isEmpty(chats)) return null;

  const sendChat = async ({ message, fileType = "text", caption }: any) => {
    try {
      if (!message) return;
      if (isSending && isSendingCache.current === message) return;
      isSendingCache.current = message;

      const msg = await _sendTradeChat({
        message,
        caption,
        channelName,
        fileType,
      });

      if ((msg as any).error) {
        if ((msg as any)?.error?.data?.error) {
          return toast({
            position: "top-right",
            title: "Message not sent",
            description: String((msg as any)?.error?.data?.error),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        isSendingCache.current = undefined;
        setChatStateFromGlobal((p: any) => ({
          ...p,
          chatInFocus: true,
          unReadChats: [],
          chatOpened: true,
        }));
      }
    } catch (error) {
      if ((error as any)?.data?.error) {
        return toast({
          position: "top-right",
          title: "Message not sent",
          description: String((error as any)?.data?.error),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const ringUser = async () => {
    try {
      let userToRing = tradeOwner;
      if (tradeOwner.user?.userId === profile?.userId) {
        userToRing = trader;
      }
      const msg = await _ringTrader({
        userIdToRing: userToRing?.user?.userId || "",
        channelName,
      });

      if ((msg as any).error) {
        // do something with error here
        // (msg as any).error.data.error - string
        if ((msg as any)?.error?.data?.error) {
          return toast({
            position: "top-right",
            title: "Opps",
            description: String((msg as any)?.error?.data?.error),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      // do something with error here
      // msg.error.data.error - string
      if ((error as any)?.data?.error) {
        return toast({
          position: "top-right",
          title: "Opps",
          description: String((error as any)?.data?.error),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    // <AnimatePresence exitBeforeEnter={true}>
    <MotionBox
      key={when(!!chatStateFromGlobal?.chatOpened, "opened", "closed")}
      initial={{ opacity: 0, y: 100, scale: 0.98 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      // exit={{ opacity: 0, y: 100, scale: 0.98 }}
      // style={{ position: "fixed", bottom: "30px", right: "30px" }}
      position="fixed"
      bottom={{ sm: "90px", md: "30px" }}
      right={{ sm: "10px", md: "30px" }}
      minW={{ sm: "calc(100% - 20px)", "3sm": "680px", md: "520px" }}
      transition={{
        staggerChildren: 0.5,
        opacity: { type: "spring", stiffness: 100 },
        y: { type: "spring", stiffness: 100 },
        scale: { type: "spring", stiffness: 100 },
      }}
      zIndex={{ sm: "99999" }}
    >
      <ConditionalRender
        shouldRender={(!!chatStateFromGlobal?.chatOpened && chatStatus.is("active")) || !!chatStateFromGlobal?.chatOpened}
      >
        <Box
          minW={{ sm: "100%", md: "520px" }}
          // pos="fixed"
          // bottom="30px"
          // right="30px"
          // zIndex="999999999999999999"
          // {...{ [chatPosition]: when(isSupportOn, "calc(var(--sideNavWidth) + 30px)", "30px") }}
        >
          <CloseButton
            bg={color("white", "black")}
            pos="absolute"
            top="-50px"
            borderRadius="50px"
            _hover={{ bg: color("white", "black") }}
            onClick={() => setChatStateFromGlobal({ chatOpened: false })}
            // {...{ [chatPosition]: "0" }}
            right="0"
          />
          <Card
            p="0"
            __css={{
              "--orange": "#FADBA0",
              "--receiveColor": "#F6FEDE",
              "--sendColor": "#F5FEFF",
            }}
            shadow="0px 0px 50px 1px rgba(0,0,0,.2)"
            bg="white"
            // h="fit-content"
            borderRadius="14px"
            overflow="hidden"
            w="100%"
            // h="100%"
            // minH="500px"
            // minW="520px"
            // pos="fixed"
            // bottom="30px"
            // right="30px"
            pos="relative"
          >
            <Stack h="100%" gridTemplateRows=".4fr 1fr .3fr">
              <MessangerHeader
                init={chatStatus.is("init")}
                ringUser={ringUser}
                isChatsOver={isChatsOver}
                tradingParty={tradingParty?.user?.username}
                chatParties={currentParties.current}
                unReads={chatStateFromGlobal?.unReadChats}
              />

              <MessangerBody chats={chats} setChatStateFromGlobal={setChatStateFromGlobal} />

              {!isChatsOver && !chatStatus.is("init") ? (
                <MessangerFooter send={initializeChat} init initMessage={`Chat with trader`} />
              ) : isChatsOver ? null : (
                <MessangerFooter send={sendChat} />
              )}
            </Stack>
          </Card>
        </Box>
      </ConditionalRender>
    </MotionBox>
    // </AnimatePresence>
  );
};

function MessagingFileUpload(props: any) {
  const { isOpen, onClose, isLoading, onAdd, ...xprops } = props;

  const [state, set] = usePartialState<{ files: File[] }>({ files: [] });

  console.log("attachment", state);

  const isDisabled = useMemo(() => !state?.files || (state?.files && isEmpty(state.files)), [state?.files]);

  const addAttachment = async () => {
    if (!isDisabled) {
      const file = (state?.files ?? [])[0];
      onAdd && onAdd(file);
      onClose();
    }
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      title="Add Attachments"
      onConfirm={async () => await addAttachment()}
      {...xprops}
    >
      <Box>
        <VStack justify="center" w="100%" p={["26px 0", "26px 0", "26px"]} gridGap="12px">
          <Uploader maxFiles={1} files={state?.files} onFiles={(files) => set({ files })} changeButtonText="Change attachment" />
          {/* <Text m="0 !important" color="#4E4B66" fontSize=".8em">
            Upload .PDF/.JPG/.JPEG/.PNG file and no more than 5M
          </Text> */}
        </VStack>
      </Box>
    </ConfirmationModal>
  );
}
