import { MainLayoutContainer, PageMotion, Topbar } from "components";
import { SidePageProvider } from "contexts";
import CardsPage from "./Cards";

import ls from "utils/secureStorage";
import configs from "config";
import { useEffect } from "react";
import { PageProps } from "interfaces";
// import { Box, VStack } from "@chakra-ui/react";

export default function CardIndex(props: PageProps) {
  // remove cached pin when cards page unmount
  useEffect(() => {
    return () => {
      ls.remove(configs.TEMP_PIN);
    };
  }, []);

  return (
    <PageMotion key="Cards">
      <Topbar pageTitle="Cards" {...props} />

      <MainLayoutContainer pb="60px">
        {/* <VStack alignItems="flex-start">
          <Box maxW="container.lg"> */}
        <SidePageProvider>
          <CardsPage />

          {/* <Fiat key="Fiat" hasBackButton />
          <Crypto key="Crypto" hasBackButton /> */}
        </SidePageProvider>
        {/* </Box>
        </VStack> */}
      </MainLayoutContainer>
    </PageMotion>
  );
}
