import { Text, VStack } from "@chakra-ui/react";
import { Link } from "@reach/router";
import { useDeleteBankMutation, useGetUserBankDetailsQuery } from "apis";
import { AddButton, BankInfoCard } from "components";
import { PageProps } from "interfaces";

interface BanksProps extends PageProps {}

export default function Banks(props: BanksProps) {
  const { data, refetch } = useGetUserBankDetailsQuery({});

  const [_deleteBank, { isLoading }] = useDeleteBankMutation();

  const handleDelete = async (id: string) => {
    await _deleteBank(id);
    refetch();
  };

  return (
    <VStack my="40px">
      {data && data?.bank.filter(({ type }) => type === "bank").length === 0 ? (
        <EmptyState />
      ) : (
        data?.bank
          .filter(({ type }) => !type || type === "bank")
          .map(({ _id, bankName, bankAccountName, bankAccountNumber }) => (
            <BankInfoCard
              key={_id}
              bankName={bankName}
              accountName={bankAccountName}
              accountNumber={bankAccountNumber}
              onDeleteLoading={isLoading}
              onDelete={() => handleDelete(_id)}
            />
          ))
      )}
    </VStack>
  );
}

// eslint-disable-next-line
function EmptyState() {
  return (
    <VStack py="140px">
      <Text color="grey.300" fontWeight="bold">
        No Banks added
      </Text>
      <Text color="grey.300" fontWeight="500">
        Click ‘Add bank’ to get started
      </Text>

      <Link to="/account/banks/add">
        <AddButton>Add Bank</AddButton>
      </Link>
    </VStack>
  );
}
