import { Box, CloseButton, HStack, Text } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { useShortcutKeys } from "hooks";
import { Emitter } from "libs";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { when } from "utils";

const CommandContext = createContext({});

export function CommandProvider(props: PropsWithChildren<any>) {
  const { children } = props;

  useShortcutKeys([], () => {});

  const [visible, setVisible] = useState(false);
  const [commands, setCommands] = useState<string[]>([]);

  useEffect(() => {
    const handleCommand = (event: { commands: string[] }) => {
      const { commands } = event;
      if (commands.at(0) !== "/") return;

      setVisible(false);
      console.log("[COMMAND]: ", event.commands);
      const actual = commands.filter((cmd) => cmd !== "/");
      setCommands(actual);
    };

    const handleClearCmd = () => {
      setVisible(false);
      setCommands([]);
    };

    Emitter.on("command_down", handleCommand);
    Emitter.on("command_cleared", handleClearCmd);
    return () => {
      Emitter.on("command_down", handleCommand);
      Emitter.off("command_cleared", handleClearCmd);
    };
  }, []);

  return (
    <CommandContext.Provider value={{}}>
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div
          key={when(visible, "visible", "invisible")}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          //   style={{ position: "fixed" }}
        >
          {visible && (
            <Box bg="grey.150" pos="relative" zIndex={100000} minW="100px" p="10px" pr="40px">
              <HStack>
                <Text p="4px" borderRadius="2px" bg="gray.100">
                  /
                </Text>
                <Text color={"black"}>{commands}</Text>
                <CloseButton pos="absolute" right="10px" color="black" />
              </HStack>
            </Box>
          )}
        </motion.div>
      </AnimatePresence>

      {children}
    </CommandContext.Provider>
  );
}
