import {
  Box,
  Button,
  Center,
  FormControl,
  Grid,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import Recaptcha from "react-google-recaptcha";
import { Group, Logo, Span, Icon, InputLabel, PageLoading, ConditionalRender, ShowCustomerSupport } from "components";
import { getProfile, rehydrate, selectAuth } from "store/slices";
import { useColor, useDispatch, usePartialState, usePersistentCountdown, useSelector } from "hooks";
import { MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ls from "utils/secureStorage";
import configs from "config";
import { AuthPayload, dehydrate, requestEmailCode, requestPhoneCode, sendEmailCode, sendPhoneCode } from "store/slices/auth";
import { RootState } from "store";
import { when } from "utils";
import { AnimatePresence, motion } from "framer-motion";
import { navigate } from "@reach/router";
import { SendCodeDto } from "interfaces";
import { AuthImage, AuthProgress } from "ui";
import { IntercomProvider } from "react-use-intercom";

import toLower from "lodash/toLower";
import { useRegistrationProgress } from "contexts";

interface PhoneVerificationProps {
  verification: string[];
  captchRef: MutableRefObject<any>;
  isMobile: boolean;
}
interface EmailVerificationProps {
  verification: string[];
  captchRef: MutableRefObject<any>;
  isMobile: boolean;
}

export default function Verification(props: { path: string }) {
  const hColor = useColorModeValue("#303131", "white");
  const pColor = useColorModeValue("black", "grey.150");
  const logoColor = useColorModeValue("primary.default", "secondary.500");
  const emailAndPhonecolor = useColorModeValue("primary.default", "secondary.400");
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { setProgress } = useRegistrationProgress();

  const dispatch = useDispatch();
  const recaptchaRef = useRef<any>(null);

  const {
    auth: { payload },
    user: { status, profile },
  } = useSelector((state: RootState) => ({ auth: state?.auth, user: state?.user }));
  const { verification } = (payload ?? {}) as AuthPayload;

  const isNigerian = useMemo(() => toLower(profile?.country!) === "nigeria", [profile?.country]);
  const isLoadingProfile = useMemo(() => status === "fetching", [status]);

  const logout = useCallback(() => {
    dispatch(dehydrate());
    const win: Window = window;
    win.location = "/login";
  }, [dispatch]);

  useEffect(() => {
    const auth = (ls.get(configs.AUTH_TOKEN_KEY) as AuthPayload) ?? undefined;

    ////////////////////
    // TODO: check if auth?.exp is not past before rehydrating.
    // if is past, then navigate back to login.
    //
    // Current blocker for this task is that exp doesn't come with
    // failed login data, refer to the auth.ts slice for more details.

    if (String(auth).length > 0 || !!auth?.token) {
      dispatch(rehydrate());
      dispatch(getProfile());
    } else {
      navigate(configs.paths.login);
    }
  }, [dispatch]);

  useEffect(() => {
    // const auth = (ls.get(configs.AUTH_TOKEN_KEY) as AuthPayload) ?? undefined;
    if (
      !!payload?.token &&
      payload?.isSignedIn &&
      payload?.isEmailVerified &&
      payload?.isVerified &&
      new Date().getTime() < (+payload?.exp! ?? 0)
    ) {
      // navigate(configs.paths.dashboard);
      navigate(configs.paths.enableTwoFa);
    }
  }, [payload]);

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const [isLessThan1023] = useMediaQuery("(max-width: 1023px)");
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  useEffect(() => {
    setProgress(2);
    // eslint-disable-next-line
  }, []);

  return (
    <IntercomProvider appId={configs.INTERCOM_TOKEN}>
      <Box
        minH="100vh"
        pos="relative"
        sx={{
          "--w": isLargerThan1080 ? "580px" : "480px",
        }}
      >
        <AuthImage isMobile={isLessThan1023} />

        <Box minH="100vh" pos="relative" w={isLessThan1023 ? "100%" : "calc(100% - var(--w))"} ml="auto">
          <AuthProgress />
          <Group maxWidth="580px" margin="auto" padding={{ sm: "32px 20px 43px", "2sm": "80px 20px 43px" }}>
            <Box>
              <Button
                size="sm"
                minW="fit-content"
                maxW="fit-content"
                variant="link"
                leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
                onClick={() => navigate(-1)}
                px="2px !important"
                mb="40px"
              >
                Back
              </Button>
              <Logo fontSize="54px" mb="24px" _name={{ w: "110px" }} color={logoColor} />
            </Box>

            <Box mb="40px !important">
              <Heading fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor}>
                Security Verification
              </Heading>
              <Text color={pColor} fontSize={{ sm: "14px", "2sm": "18px" }} fontWeight="500" mt="14px">
                To secure your account, kindly complete the following verification
              </Text>

              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                  key={`profile-${when(isLoadingProfile, "loading", "loaded")}`}
                  initial={{ opacity: 0, scale: 0.98 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.98 }}
                >
                  {!isLoadingProfile && (
                    <>
                      <Text color={pColor} fontSize={{ sm: "14px", "2sm": "18px" }} fontWeight="500" mt="24px">
                        <ConditionalRender shouldRender={!isNigerian}>
                          Click on the Send Code button to receive an SMS OTP sent to{" "}
                          <Span color={emailAndPhonecolor} fontWeight="500">
                            {profile?.phone}
                          </Span>{" "}
                          {"and an "}Email OTP sent to{" "}
                          <Span color={emailAndPhonecolor} fontWeight="500">
                            {profile?.email}
                          </Span>
                        </ConditionalRender>
                        <ConditionalRender shouldRender={isNigerian}>
                          Click on the Send Code button to receive an{" "}
                          {verification?.includes("phone") && (
                            <>
                              {" "}
                              SMS OTP sent to
                              <Span color={emailAndPhonecolor} fontWeight="500">
                                {profile?.phone}
                              </Span>
                              , and an
                            </>
                          )}{" "}
                          Email OTP sent to{" "}
                          <Span color={emailAndPhonecolor} fontWeight="500">
                            {profile?.email}
                          </Span>
                        </ConditionalRender>
                      </Text>
                    </>
                  )}
                </motion.div>
              </AnimatePresence>
            </Box>

            <Stack maxW="560px" gridGap="24px">
              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                  key={`profile-${when(isLoadingProfile, "loading", "loaded")}`}
                  initial={{ opacity: 0, scale: 0.98 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.98 }}
                >
                  {isLoadingProfile && <PageLoading isLoading={isLoadingProfile} />}

                  {!isLoadingProfile && (
                    <>
                      <PhoneVerification isMobile={isMobile} verification={verification ?? []} captchRef={recaptchaRef} />
                      <EmailVerification isMobile={isMobile} verification={verification ?? []} captchRef={recaptchaRef} />
                    </>
                  )}
                </motion.div>
              </AnimatePresence>

              <VStack mt="100px !important">
                <Button p="10px" variant="link" onClick={logout}>
                  Logout
                </Button>
              </VStack>

              <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />
            </Stack>
          </Group>
        </Box>

        <ShowCustomerSupport />
      </Box>
    </IntercomProvider>
  );
}

// let phoneRenderCount = 0; // temporarily fix to make sure we don't use the default count time value when `nextRequestInSecs` exist;
const PHONE_VERIFICATION_CACHE_KEY = "pvtck";
function PhoneVerification(props: PhoneVerificationProps) {
  const { verification, captchRef, isMobile } = props;
  const color = useColorModeValue("primary.default", "secondary.400");

  const [form, set] = usePartialState<Omit<SendCodeDto, "humanKey">>({});

  const dispatch = useDispatch();
  const { verify } = useSelector(selectAuth);
  // const prevRequestInSecs = useRef<number>(0);
  const { status, send } = verify?.phone;

  const [delivery, setDelivery] = useState<"call" | "sms" | "whatsapp">("sms");

  // const { time, setTime, Timer, completed: isTimerCompleted } = useCountDownTimer(null);

  const { startCountdown, toTimeString, timedout, started } = usePersistentCountdown(PHONE_VERIFICATION_CACHE_KEY);

  const isLoading = useMemo(() => status === "loading", [status]);
  const isSending = useMemo(() => send?.status === "loading", [send]);

  const toggleDelivery = () => {
    if (delivery === "call") setDelivery("sms");
    else setDelivery("call");
  };

  const toggleWhatsappDelivery = async () => {
    setDelivery("whatsapp");
    await handleRequest("whatsapp");
  };

  const btnLabel = useMemo(() => (delivery === "call" ? "Dial" : "Send Code"), [delivery]);

  const handleRequest = async (channel?: string) => {
    const captcha = await captchRef.current?.executeAsync();
    captchRef.current?.reset();
    const result = await dispatch(requestPhoneCode({ delivery: (channel ?? delivery) as any, humanKey: captcha }));
    // setDate(new Date(new Date().setSeconds(600)));
    console.log("Phone verification result", result);
    if (!!result) {
      startCountdown(600);
    }
  };

  // useUpdateEffect(() => {
  //   console.log({ nextRequestInSecs, prevRequestInSecs, time, isTimerCompleted });
  //   if (nextRequestInSecs && (!isEqual(nextRequestInSecs, prevRequestInSecs.current) || !time)) {
  //     const next = (new Date().getTime() / 1000 + nextRequestInSecs) * 1000;
  //     startCountdown(nextRequestInSecs);

  //     const sec = new Date(next);
  //     setTime(sec);
  //     console.log("Next request in secs", sec.getSeconds());
  //     // dispatch(resetPhoneRequestTime());
  //     prevRequestInSecs.current = nextRequestInSecs;
  //   }
  //   if (!nextRequestInSecs && (prevRequestInSecs.current === 0 || !time)) {
  //     const next = new Date((new Date().getTime() / 1000 + 600) * 1000);
  //     prevRequestInSecs.current = 600;
  //     setTime(next);
  //   }
  //   // phoneRenderCount++;
  // }, [nextRequestInSecs, isTimerCompleted, setTime, time, prevRequestInSecs]);

  const handlePhoneVerification = async () => {
    const captcha = await captchRef.current?.executeAsync();
    captchRef.current?.reset();
    dispatch(sendPhoneCode({ code: form?.code!, humanKey: captcha }));
  };

  const isDisabled = useMemo(() => !form?.code || isLoading || isSending, [form, isLoading, isSending]);

  return (
    <ConditionalRender shouldRender={verification.includes("phone")}>
      <FormControl mb="60px !important">
        <InputLabel color={color}>Phone Code (via {delivery})</InputLabel>
        <Grid templateColumns={isMobile ? "none" : "1fr .2fr"} alignItems="flex-start" gap="40px">
          <Box>
            <InputGroup>
              <InputRightElement
                pr={when(delivery !== "call", "60px", !!started && !timedout ? "60px" : "20px")}
                children={
                  <Button
                    minW="fit-content"
                    p="10px !important"
                    variant="max"
                    isLoading={isLoading}
                    onClick={() => handleRequest()}
                    disabled={!timedout && started}
                  >
                    {when<ReactNode>(
                      timedout || !started,
                      btnLabel,
                      // <Timer color="inherit" textTransform="lowercase" />
                      toTimeString()
                    )}
                  </Button>
                }
              />
              <Input
                pr={when(
                  ["sms", "whatsapp"].includes(delivery),
                  "120px !important",
                  !!started && !timedout ? "120px !important" : "68px !important"
                )}
                value={form?.code ?? ""}
                onChange={(e) => set({ code: e.target.value })}
                placeholder="Enter Phone Code Verification"
              />
            </InputGroup>

            <HStack justifyContent="space-between">
              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                  key={`delivery-${when(delivery === "sms", "sms", "call")}`}
                  initial={{ opacity: 0, scale: 0.98 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.98 }}
                >
                  <HStack
                    fontSize="14px"
                    fontWeight="600"
                    mt="10px !important"
                    as="a"
                    href="#"
                    color={color}
                    onClick={toggleDelivery}
                    textDecoration="underline"
                    minH="28px"
                    pointerEvents={when(!timedout && started, "none", "auto")}
                    opacity={when(!timedout && started, ".4", "1")}
                    cursor={when(!timedout && started, "no-drop", "pointer")}
                  >
                    {["sms", "whatsapp"].includes(delivery) && (
                      <>
                        <Icon type="calling" color={color} />
                        <Text>Call me via phone instead</Text>
                      </>
                    )}

                    {delivery === "call" && <Text>Send code via sms.</Text>}
                  </HStack>
                </motion.div>
              </AnimatePresence>

              <HStack
                fontSize="14px"
                fontWeight="600"
                mt="10px !important"
                as="a"
                href="#"
                color={color}
                onClick={toggleWhatsappDelivery}
                textDecoration="underline"
                minH="28px"
                pointerEvents={when(!timedout && started, "none", "auto")}
                opacity={when(!timedout && started, ".4", "1")}
                cursor={when(!timedout && started, "no-drop", "pointer")}
              >
                <>
                  <Icon type="whatsappOutline" color={color} boxSize="20px" />
                  <Text>Send via Whatsapp</Text>
                </>
              </HStack>
            </HStack>

            {isMobile && (
              <Center mt="17px">
                <Button
                  p="0"
                  borderRadius="50%"
                  boxSize="56px"
                  minW="unset"
                  minH="unset"
                  maxH="unset"
                  maxW="unset"
                  isLoading={isSending}
                  disabled={isDisabled}
                  onClick={handlePhoneVerification}
                >
                  <Icon type="arrowRight" boxSize="32px" color="white" />
                </Button>
              </Center>
            )}
          </Box>

          {!isMobile && (
            <Button
              p="0"
              borderRadius="50%"
              boxSize="56px"
              minW="unset"
              minH="unset"
              maxH="unset"
              maxW="unset"
              isLoading={isSending}
              disabled={isDisabled}
              onClick={handlePhoneVerification}
            >
              <Icon type="arrowRight" boxSize="32px" color="white" />
            </Button>
          )}
        </Grid>
      </FormControl>
    </ConditionalRender>
  );
}

const EMAIL_VERIFICATION_CACHE_KEY = "evtck";
function EmailVerification(props: EmailVerificationProps) {
  const { verification, captchRef, isMobile } = props;
  const color = useColor();
  const lcolor = useColorModeValue("primary.default", "secondary.400");

  const [form, set] = usePartialState<Omit<SendCodeDto, "humanKey">>({});

  const dispatch = useDispatch();
  const { verify } = useSelector(selectAuth);
  // const prevRequestInSecs = useRef<number>(0);
  const { status, send } = verify?.email ?? {};

  // const { time, setTime, Timer, completed: isTimerCompleted } = useCountDownTimer(null);
  const { startCountdown, toTimeString, timedout, started } = usePersistentCountdown(EMAIL_VERIFICATION_CACHE_KEY);

  const isLoading = useMemo(() => status === "loading", [status]);
  const isSending = useMemo(() => send?.status === "loading", [send]);

  const handleRequest = async () => {
    const captcha = await captchRef.current?.executeAsync();
    captchRef.current?.reset();
    const result = await dispatch(requestEmailCode({ humanKey: captcha }));
    // setTime(new Date(new Date().setSeconds(600)));
    startCountdown(600);
    console.log("Email verification result", result);
  };

  // useUpdateEffect(() => {
  //   console.log({ nextRequestInSecs, prevRequestInSecs, time, isTimerCompleted });
  //   if (nextRequestInSecs && (!isEqual(nextRequestInSecs, prevRequestInSecs.current) || !time)) {
  //     startCountdown(nextRequestInSecs);
  //     const next = (new Date().getTime() / 1000 + nextRequestInSecs) * 1000;
  //     const sec = new Date(next);
  //     setTime(sec);
  //     console.log("Next email request in secs", sec.getSeconds());
  //     prevRequestInSecs.current = nextRequestInSecs;
  //   }
  // }, [nextRequestInSecs, isTimerCompleted, setTime, time, prevRequestInSecs]);

  const handlePhoneVerification = async () => {
    const captcha = await captchRef.current?.executeAsync();
    captchRef.current?.reset();
    dispatch(sendEmailCode({ code: form?.code!, humanKey: captcha }));
  };

  const isDisabled = useMemo(() => !form?.code || isLoading || isSending, [form, isLoading, isSending]);

  return (
    <ConditionalRender shouldRender={verification.includes("email")}>
      <FormControl>
        <InputLabel color={lcolor}>Email Code</InputLabel>
        <Grid templateColumns={isMobile ? "none" : "1fr .2fr"} alignItems="flex-start" gap="40px">
          <Box>
            <InputGroup>
              <InputRightElement
                pr="60px"
                children={
                  <Button
                    minW="fit-content"
                    p="10px !important"
                    variant="max"
                    isLoading={isLoading}
                    onClick={handleRequest}
                    disabled={!timedout && started}
                  >
                    {when<ReactNode>(
                      timedout || !started,
                      "Send Code",
                      // <Timer color="inherit" textTransform="lowercase" />
                      toTimeString()
                    )}
                  </Button>
                }
              />
              <Input
                pr="120px !important"
                placeholder="Enter Email Code Verification"
                value={form?.code ?? ""}
                onChange={(e) => set({ code: e.target.value })}
              />
            </InputGroup>

            {isMobile && (
              <Center mt="17px">
                <Button
                  p="0"
                  borderRadius="50%"
                  boxSize="56px"
                  minW="unset"
                  minH="unset"
                  maxH="unset"
                  maxW="unset"
                  isLoading={isSending}
                  disabled={isDisabled}
                  onClick={handlePhoneVerification}
                >
                  <Icon type="arrowRight" boxSize="32px" color="white" />
                </Button>
              </Center>
            )}
          </Box>

          {!isMobile && (
            <Button
              p="0"
              borderRadius="50%"
              boxSize="56px"
              minW="unset"
              minH="unset"
              maxH="unset"
              maxW="unset"
              isLoading={isSending}
              disabled={isDisabled}
              onClick={handlePhoneVerification}
            >
              <Icon type="arrowRight" boxSize="32px" color="white" />
            </Button>
          )}
        </Grid>

        <ConditionalRender shouldRender={!!started}>
          <Box mt="74px" borderRadius="10px" p="22px 18px" bg={color("#ebebeb", "#272727a1")}>
            <Text fontWeight="500">
              The OTP Code has just been sent to your Email Address. Check your Spam folder in case you do not see it in your inbox
            </Text>
          </Box>
        </ConditionalRender>
      </FormControl>
    </ConditionalRender>
  );
}
