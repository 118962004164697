import { Emitter } from "libs";
import { useEffect, useRef } from "react";
import useRedebounce from "./useDebounce";

export function useShortcutKeys(shortcutSequences: string[], cb: () => void) {
  const callback = useRef(cb || (() => ({})));
  const pressedSequences = useRef<string[]>([]);
  const emittedSequences = useRef<string[]>([]);

  const redebounce = useRedebounce();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event;
      const newPressedSequences = pressedSequences.current.concat(key);
      emittedSequences.current = emittedSequences.current.concat(key);

      Emitter.emit("command_down", { commands: emittedSequences.current });
      redebounce(
        () => {
          const prevSequences = emittedSequences.current;
          emittedSequences.current = [];
          Emitter.emit("command_cleared", { commands: prevSequences });
        },
        "reset-emitted-shortcut-sequence",
        3000
      )();

      const matchedSequence = shortcutSequences.join("-") === newPressedSequences.join("-");

      const clearSequence = (delay = 2000) => {
        redebounce(
          () => {
            pressedSequences.current = [];
          },
          "reset-shortcut-sequence",
          delay
        )();
      };

      if (matchedSequence) {
        if (typeof callback.current === "function") {
          callback.current();
        }

        pressedSequences.current = [];
      } else {
        const matchStart = newPressedSequences[0] === shortcutSequences[0];
        const matchingLength = shortcutSequences.length > newPressedSequences.length;
        const isNextChar = shortcutSequences.join("-").startsWith(newPressedSequences.join("-"));

        if (matchStart && matchingLength && isNextChar) {
          pressedSequences.current = newPressedSequences;
          // Clear the stored sequence after 3 seconds

          clearSequence(3000);
        } else {
          clearSequence(0.001);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [cb, redebounce, shortcutSequences, pressedSequences]);

  const setShortcutCallback = (newCallback: any) => {
    callback.current = newCallback;
  };

  return { setShortcutCallback };
}
