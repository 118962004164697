import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Grid,
  GridProps,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCancelTradeMutation, useGetCoinToUsdRateQuery, useHideListingMutation } from "apis";
import FormInfo from "components/FormInfo/FormInfo";
import Icon, { IconNames } from "components/Icon/Icon";
import { P2PListingTradeRo } from "interfaces";
import { useMemo } from "react";
import { currencyFormat, toCoinLocale, when } from "utils";

import isEmpty from "lodash/isEmpty";
import { navigate } from "@reach/router";
import { ConfirmationModal } from "ui";
import { useDisclosures } from "hooks";

import capitalize from "lodash/capitalize";

interface MyTradeAdvertimentItemProps extends Omit<GridProps, "id">, P2PListingTradeRo {}
interface ValueProps extends P2PListingTradeRo {}
interface MethodProps extends P2PListingTradeRo {}
interface ActionProps extends P2PListingTradeRo {}
interface ActionButtonProps extends ButtonProps {
  label?: string;
  actionFor: "edit" | "timer" | "enable" | "delete" | "disable" | "cancel";
}

type ActionInfo = {
  icon: IconNames;
  hideIcon?: boolean;
  label: string;
  bg: string;
  color: string;
  borderColor: string;
  _focus?: BoxProps["_focus"];
};

export default function AdvertimentItem(props: MyTradeAdvertimentItemProps) {
  return (
    <Grid
      p="28px 28px"
      templateColumns=".8fr .5fr 1fr"
      border="1px solid transparent"
      borderBottomColor="grey.150"
      alignItems="center"
      {...props}
    >
      <Value {...props} />
      <Method {...props} />
      <Actions {...props} />
    </Grid>
  );
}

export function MobileAdvertimentItem(props: MyTradeAdvertimentItemProps) {
  return (
    <Grid
      p="20px 0px"
      templateColumns="1fr .3fr"
      border="1px solid transparent"
      borderBottomColor="grey.150"
      alignItems="center"
      gap="10px"
      {...props}
    >
      <Stack>
        <Value {...props} />
        <Method {...props} />
      </Stack>

      <HStack>
        <Actions {...props} />
      </HStack>
    </Grid>
  );
}

type ModalTypes = "delete" | "enable";
function Actions(props: ActionProps) {
  const { _id, hide } = props;

  const toast = useToast();

  const { isOpen, open, close } = useDisclosures<ModalTypes>();

  const [cancelTrade, { isLoading: isCancelling }] = useCancelTradeMutation();
  const [hideListing, { isLoading: isHiding }] = useHideListingMutation();

  const visibilityVerb = useMemo(() => (hide === false ? "disable" : `enable`), [hide]);

  const handleDeleteAd = async () => {
    const result = await cancelTrade(_id);
    if (!(result as any)?.error) {
      toast({
        position: "bottom-right",
        title: "Success",
        description: "Listing deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEnableAd = async () => {
    console.log("VisibilityVerb", visibilityVerb);
    const action = hide ? "unhide" : "hide";
    const result = await hideListing({ id: _id, visibility: action });
    if (!(result as any)?.error) {
      toast({
        position: "bottom-right",
        title: "Success",
        description: `Listing ${visibilityVerb}d successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        position: "bottom-right",
        title: "Success",
        description: `Unable to ${visibilityVerb} listing, please try again`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <HStack flexDir={{ sm: "column", "3sm": "row", md: "row" }} justifyContent="center">
      <ActionButton actionFor="edit" onClick={() => navigate(`/p2p/edit/${_id}`)} />
      <ActionButton actionFor="timer" />
      <ActionButton actionFor={visibilityVerb} isLoading={isHiding} disabled={isHiding} onClick={open("enable")} />
      <ActionButton actionFor="delete" isLoading={isCancelling} disabled={isCancelling} onClick={open("delete")} />

      <ConfirmationModal
        variant="delete"
        isOpen={isOpen("delete")}
        onClose={close("delete")}
        onConfirm={handleDeleteAd}
        labels={["Delete Ad"]}
      >
        <VStack mt="24px" mb="40px">
          <Text mt="0 !important" fontWeight="500">
            Are you sure you want to delete this ad?
          </Text>
        </VStack>
      </ConfirmationModal>

      <ConfirmationModal
        variant="enable"
        isOpen={isOpen("enable")}
        onClose={close("enable")}
        onConfirm={handleEnableAd}
        title={`Confirm ${capitalize(visibilityVerb)}?`}
        labels={[`${capitalize(visibilityVerb)} Ad`]}
      >
        <VStack mt="24px" mb="40px">
          <Text mt="0 !important" fontWeight="500">
            Are you sure you want to {visibilityVerb} this ad?
          </Text>
        </VStack>
      </ConfirmationModal>
    </HStack>
  );
}

function Value(props: ValueProps) {
  const { coin, adsType, dynamic, tradeOption, price, currency, _id, hide } = props;
  const status = hide ? "INACTIVE" : "ACTIVE";
  const reMapCoin = (coin: string) => {
    const map: Record<string, string> = {
      [coin]: coin,
      tbtc: "btc",
      teth: "eth",
      usdt: "usd",
      cusd: "usd",
    };
    return map[coin];
  };

  const adsTypeMap = (type: string) => {
    const map: Record<string, string> = {
      selling: "buy",
      buying: "sell",
    };
    return map[type];
  };

  const { data: rate } = useGetCoinToUsdRateQuery(reMapCoin(coin ?? "btc"), { skip: (coin ?? "btc").includes("usd") });

  const exRate = useMemo(() => {
    const r = rate?.exchangeRate ?? {};
    if (!isEmpty(r)) return (r as any)[adsTypeMap(adsType ?? "buying")] as number;
    return 1;
  }, [adsType, rate]);

  const unitCost = useMemo(() => {
    if (dynamic) return coin?.includes("usd") ? 1 * (price ?? 0) : exRate * (price ?? 0);
    return tradeOption?.unitCost;
  }, [coin, price, dynamic, exRate, tradeOption]);

  const priceLabel = useMemo(
    () =>
      `${currencyFormat((currency as any) ?? "ngn").format(unitCost ?? 0)} ${toCoinLocale(currency ?? "ngn")}/${toCoinLocale(
        coin ?? "btc"
      )}`,
    [unitCost, currency, coin]
  );

  return (
    <HStack gridGap="40px">
      <Icon display={{ sm: "none", "3sm": "block", md: "block" }} type="moneyReceive" />
      <Stack ml={{ sm: "0 !important", "3sm": "initial", md: "initial" }}>
        <Box>
          <Text fontFamily="var(--bitmama-fonts-heading)" fontSize="14px" fontWeight="600">
            {/* 783.62 NGN/bitUSD */}
            {priceLabel}
          </Text>
          <Text mt="0 !important" fontSize="14px" fontWeight="500">
            Reference: {_id}
          </Text>
        </Box>

        <Box>
          <FormInfo info="Price that you pay:" description={priceLabel} />
          <FormInfo info="STATUS:" description={status} isInvalid={status === "INACTIVE" ? true : false} />
        </Box>
      </Stack>
    </HStack>
  );
}

function Method(props: MethodProps) {
  const { paymentOption } = props;

  const label = (source: string) => {
    const map: Record<string, string> = {
      // [method]: `${capitalize(method)}`,
      external: "Bank Transfer",
      internal: "Bitmama Wallet",
    };

    return map[source];
  };

  return (
    <Stack justifyContent={{ sm: "flex-start", "3sm": "center", md: "center" }}>
      <Box textAlign={{ sm: "left", "3sm": "center", md: "center" }}>
        <HStack
          alignItems={{ sm: "flex-start", "3sm": "center", md: "center" }}
          justifyContent={{ sm: "flex-start", "3sm": "center", md: "center" }}
        >
          {(paymentOption ?? []).map((opt, i) => (
            <Text key={`payment-option-${i}`} fontFamily="var(--bitmama-fonts-heading)" fontSize="14px" fontWeight="600">
              {label(opt?.source)}
            </Text>
          ))}
        </HStack>
        <Text mt="0 !important" fontSize="14px" fontWeight="500">
          Completion time: 0s
        </Text>
      </Box>

      <HStack
        ml={{ sm: "0 !important", "3sm": "inherit", md: "inherit" }}
        justifyContent={{ sm: "flex-start", "3sm": "center", md: "center" }}
      >
        <Box boxSize="10px" borderRadius="50%" bg="primary.900" />
        <Text fontSize="12px" fontWeight="500">
          bitmama
        </Text>
      </HStack>
    </Stack>
  );
}

export function ActionButton(props: ActionButtonProps) {
  const { label, actionFor, ...xprops } = props;

  const color = useColorModeValue("black", "white");

  const info = useMemo(() => {
    const map: Record<ActionButtonProps["actionFor"], ActionInfo> = {
      edit: { icon: "editFilled", label: "Edit Ad", borderColor: "#0D4740", color, bg: "transparent" },
      timer: { icon: "timerFilled", label: "Timer", borderColor: "#0D4740", color, bg: "transparent" },
      enable: {
        icon: "playFilled",
        label: "Enable",
        borderColor: "rgba(49, 183, 169, 0.5)",
        color,
        bg: "rgba(49, 183, 169, 0.2)",
      },
      disable: {
        icon: "playFilled",
        label: "Disable",
        borderColor: "rgba(237, 28, 0, 0.37)",
        color,
        bg: "rgba(237, 28, 0, 0.05)",
      },
      delete: {
        icon: "trashFilled",
        label: "Delete",
        borderColor: "rgba(237, 28, 0, 0.37)",
        color,
        bg: "rgba(237, 28, 0, 0.05)",
        _focus: { shadow: "error", borderColor: "rgba(237, 28, 0, 0.37)" },
      },
      cancel: {
        icon: "editFilled",
        hideIcon: true,
        label: "Cancel",
        borderColor: "rgba(49, 183, 169, 0.5)",
        color,
        bg: "rgba(49, 183, 169, 0.2)",
      },
    };

    return map[actionFor];
  }, [actionFor, color]);

  return (
    <Button
      p={{ sm: "6px", "3sm": "14px", md: "14px" }}
      variant="max"
      minW="fit-content"
      minH="38px"
      borderRadius="6px"
      fontSize={{ sm: "12px", "3sm": "14px", md: "14px" }}
      fontWeight="500"
      textTransform="capitalize"
      rightIcon={when(
        actionFor !== "timer",
        when(!!info?.hideIcon, undefined, <Icon type={info.icon} />),
        <Text fontSize="14px" fontWeight="600">
          OFF
        </Text>
      )}
      leftIcon={when(actionFor === "timer", when(!!info?.hideIcon, undefined, <Icon type={info.icon} />), undefined)}
      {...info}
      {...xprops}
      _focus={{ ...info?._focus, ...xprops?._focus }}
    >
      {label ?? info.label}
    </Button>
  );
}
