import { Box, Button, Heading, HStack, List, ListItem, OrderedList, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { Icon, MainLayoutContainer, PageMotion } from "components";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";

interface RulesProps extends PageProps {}

export default function Rules(props: RulesProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountRules" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              Bitmama Partners
            </Heading>
          </HStack>
        </Box>

        <RulesAndRegulations {...props} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

function RulesAndRegulations(props: RulesProps) {
  const rColor = useColorModeValue("grey.600", "gray.200");
  return (
    <Stack as="form" my="24px">
      <Stack mb="36px">
        <Heading as="h6" fontSize="sm" color="secondary.400">
          Rules
        </Heading>
        <Text fontSize="sm" fontWeight="500">
          Kindly read through the rules for referrals and affiliate marketing below
        </Text>
      </Stack>

      <Box p="28px 22px" w="100%" borderRadius="20px" bg="rgba(49, 183, 169, 0.05)">
        <OrderedList
          sx={{
            li: {
              my: "10px",
              fontWeight: "600",
              color: rColor,
            },
          }}
        >
          <ListItem>Verify to level 3 to become an affiliate</ListItem>
          <ListItem>All earnings can be accessed from your crypto wallets on Bitmama.</ListItem>
          <ListItem>You can only earn when the referral completes a transaction.</ListItem>
          <ListItem>The commission rates can be reviewed at our discretion.</ListItem>
          <ListItem>
            Here are the transactions you can earn on <br />
            <List
              sx={{
                li: {
                  fontStyle: "italic",
                },
              }}
            >
              <ListItem>i. Buy and sell- 2% per transaction </ListItem>
              <ListItem>ii. Swap- 3% per transaction</ListItem>
              <ListItem>
                iii. BCPL- 15% of our 3% earnings on the loan interest to be earned when the referral pays back the loan
              </ListItem>
              <ListItem>iv. Card transactions- $0.06</ListItem>
            </List>
          </ListItem>
        </OrderedList>
      </Box>
    </Stack>
  );
}
