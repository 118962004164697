import { Box, Button, HStack, Heading, Image, List, ListItem, ListItemProps, ModalProps, Text, VStack } from "@chakra-ui/react";
import { AbstractModal } from "components";
import { useColor } from "hooks";
import PyUSDImage from "assets/images/pyusd-coin-image.png";
import { navigate } from "@reach/router";
const patternUrl: string = require("../../../assets/svg/greenbox-preclaim-pattern.svg").default;

interface Props extends Omit<ModalProps, "children"> {}

export function PyusdFeatureUpdate(props: Props) {
  const { isOpen, onClose } = props;

  const color = useColor();

  const goToWallet = () => {
    onClose();
    navigate(`/home?subpage=Buy&crypto=pyusd`);
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "540px",
        // borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        borderRadius: "0px",
        position: { base: "absolute", "1sm": "initial" },
        padding: "20px",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
        overflow: "hidden",
        bg: `url("${patternUrl}")`,
        bgColor: "primary.default",
        backgroundSize: "cover",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <Box minH="570px" bg={color("white", "dark.bg")} borderRadius={{ base: "30px 30px 0px 0px", "1sm": "14px" }}>
        <VStack p="30px">
          <Image src={PyUSDImage} alt="pyusd image" borderRadius="14px" overflow="hidden" objectFit="contain" />

          <Box py="16px">
            <Heading as="h6" my="10px" fontSize="lg" fontFamily="var(--bitmama-fonts-heading)" textAlign="center">
              Introducing the new PayPal USD (PYUSD) stablecoin on Bitmama
            </Heading>

            <Box my="30px">
              <Text fontWeight="600" fontSize="18px">
                Enhance your trading experience by
              </Text>

              <List>
                <Item>Funding your PYUSD wallet through the Buy feature.</Item>
                <Item>Fund your virtual card</Item>
                {/* <Item>Create a Greenbox and lots more!</Item> */}
              </List>
            </Box>
          </Box>

          <VStack mt="12px !important">
            <Button onClick={goToWallet}>Get Started</Button>
            <Button variant="outline" mt="16px !important" onClick={onClose}>
              Close
            </Button>
          </VStack>
        </VStack>
      </Box>
    </AbstractModal>
  );
}

interface ItemProps extends ListItemProps {}

function Item(props: ItemProps) {
  const { children } = props;
  return (
    <ListItem my="14px">
      <HStack alignItems="flex-start">
        {/* <Icon type="filledCards" transform="rotate(-27deg)" minW="34px" /> */}
        <Text>📌</Text>
        <Text fontWeight="500">{children}</Text>
      </HStack>
    </ListItem>
  );
}
