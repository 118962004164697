/** Local cache in memory at runtime */
const localcache = new Map<string, HTMLImageElement>();

const reflectWithStatus = <T>(p: Promise<T>) =>
  p.then(
    (v) => ({ v, status: "fulfilled" }),
    (e) => ({ e, status: "rejected" })
  );

export function getOrAdd(url: string) {
  const normalizedUrl = String(url);
  if (localcache.has(normalizedUrl)) return { image: localcache.get(normalizedUrl), url };

  localcache.set(normalizedUrl, new Image());
  const cachedImage = localcache.get(normalizedUrl);
  cachedImage!.src = normalizedUrl;

  console.log("[PreloadImage::GetOrAdd]", normalizedUrl);
  return { image: cachedImage!, url };
}

function loadImage(url: string) {
  const cachedImage = getOrAdd(url);
  console.log("[PreloadImage::loadImage]", cachedImage);
  const promise = new Promise<typeof cachedImage>((resolve, reject) => {
    if (!cachedImage.image?.complete) {
      cachedImage.image?.addEventListener("load", () => resolve(cachedImage));
      cachedImage.image?.addEventListener("error", (error) => {
        console.log("[PreloadImage::loadImage] Image Preload Failed: ", error);
        reject(error);
      });
    }

    resolve(cachedImage);
  });

  return promise;
}

async function loadImages(urls: string[]) {
  const promises = urls.map((url) => reflectWithStatus(loadImage(url)));
  return Promise.all(promises).then((results) => {
    return results.map((r) => {
      if (r?.status !== "fulfilled") {
        console.error("[PreloadImage::loadImages] Unable to preload one or two images");
      }

      return r;
    });
  });
}

export const preloadImages = (urls: string[]) => loadImages(urls);
