import { useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { navigate, useParams } from "@reach/router";
import { useGetCoinToUsdRateQuery, useGetPriceAlertByIdQuery, useUpdatePriceAlertMutation } from "apis";
import { Icon, InputLabel, MainLayoutContainer, MovementRadioGroup, PageMotion } from "components";
import configs from "config";
import { useBuiltInFn, usePartialState } from "hooks";
import { PageProps, PriceAlertRo } from "interfaces";
import { toCoinLocale, when } from "utils";

interface EditCryptoPriceAlertProps extends PageProps {}

export default function EditCryptoPriceAlert(props: EditCryptoPriceAlertProps) {
  const stroke = useColorModeValue("primary.600", "secondary.200");
  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountAddCryptoPriceAlert" overflowY="scroll">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize="xl">
              Crypto Price Alert
            </Heading>
          </HStack>
        </Box>

        <Form {...props} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

function Form(props: EditCryptoPriceAlertProps) {
  const toast = useToast();
  const { id } = useParams();
  const [_updatePriceAlert, { isLoading }] = useUpdatePriceAlertMutation();

  const { data } = useGetPriceAlertByIdQuery(id);

  const defaultCoin = data && data.ticker.split("usdt")[0];
  const [payload, setPayload] = usePartialState<PriceAlertRo & { coin: string }>({
    coin: defaultCoin,
    direction: undefined,
    price: undefined,
    type: undefined,
  });

  // const { direction, price, type } = payload;

  const handleChange = (event: any) => {
    setPayload({
      ...payload,
      [event.target.name]: event.target.value,
    });
  };

  const { data: coinData } = useGetCoinToUsdRateQuery(!defaultCoin ? "btc" : defaultCoin);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const response: any = await _updatePriceAlert({
      id,
      ticker: `${defaultCoin}usdt`,
      ...payload,
    });

    console.log(response, "THE DATA");

    if (response) {
      toast({
        title: "Success",
        status: "success",
        duration: 4000,
        description: "Price alerts updated succesfully",
        isClosable: true,
        position: "bottom-right",
      });

      navigate("/account/pricealerts");
    }
  };

  const isDisabled = useMemo(() => !(payload?.direction || payload?.price || payload?.type) || isLoading, [payload, isLoading]);

  return (
    <Stack my="18px">
      <HStack mb="36px">
        <Box>
          <Heading as="h4" fontSize="sm">
            Edit Crypto Price Alert
          </Heading>
        </Box>
      </HStack>

      {data && (
        <Stack as="form" onSubmit={handleSubmit}>
          <FormControl mb="28px !important">
            <InputLabel htmlFor="coin">Coin Type</InputLabel>

            <Select
              defaultValue={defaultCoin}
              defaultChecked
              placeholder="Select Coin"
              id="coin"
              name="coin"
              onChange={handleChange}
              disabled
            >
              {configs.priceAlertCoins.map((coin) => (
                <option key={`${coin}`} value={coin}>
                  {toCoinLocale(coin)}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* <FormControl mb="28px !important">
            <InputLabel htmlFor="direction">Movement</InputLabel>

            <Select
              placeholder="Select Movement"
              defaultValue={data?.direction ?? ""}
              id="direction"
              name="direction"
              onChange={handleChange}
            >
              <option value="up">Above</option>
              <option value="down">Below</option>
            </Select>
          </FormControl> */}

          <FormControl mb="28px !important">
            <InputLabel mb="16px" htmlFor="coin">
              Select Movement
            </InputLabel>

            <MovementRadioGroup
              // defaultValue={payload?.type}
              value={when(!!data?.direction && !payload?.direction, data?.direction, payload?.direction)}
              onChange={(direction) => setPayload({ direction })}
            />
          </FormControl>

          <FormControl mb="28px !important">
            <InputLabel htmlFor="type">Alert</InputLabel>

            <Select placeholder="Select Alert Type" defaultValue={data?.type} id="type" name="type" onChange={handleChange}>
              <option value="price">Price</option>
              <option value="percentage">Percentage</option>
            </Select>
          </FormControl>

          <FormControl mb="28px !important">
            <InputLabel htmlFor="price">
              {when((payload?.type ?? data?.type) === "price", "Price in USD", "Percentage (%)")}
            </InputLabel>
            <Input
              defaultValue={when(data?.type === "price", Math.abs(+(data.price ?? 0)), data?.delta)}
              step="any"
              type="number"
              placeholder="Enter Amount"
              id="price"
              name="price"
              onChange={handleChange}
              isRequired
            />

            <Text as="span" display="block" mt="8px">
              Current price in USD: {Number(coinData?.exchangeRate.buy ?? 0).toFixed(2)} USD
            </Text>
          </FormControl>

          <VStack mt="40px !important" fontFamily="var(--bitmama-fonts-heading)">
            <Button
              isLoading={isLoading}
              // disabled={!direction || !price || !type || isLoading}
              disabled={isDisabled}
              type="submit"
              minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
            >
              Update Price Alert
            </Button>
          </VStack>
        </Stack>
      )}
    </Stack>
  );
}
