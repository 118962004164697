import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { useColor, useDisclosures, useUpdateP2POrder } from "hooks";
import { P2PSessionRo, PageProps } from "interfaces";
import { useMemo } from "react";
import { ConfirmationModal } from "ui";
import { toPrecision } from "utils";
import toLower from "lodash/toLower";
// import { useParams } from "@reach/router";
import { useP2PSession } from ".";

type ModalTypes = "release";

interface FooterInfoProps extends PageProps, Partial<P2PSessionRo> {
  mapKey: string;
  isLoading?: boolean;
}

interface UseActionMapperProps extends FooterInfoProps {
  disclosures: ReturnType<typeof useDisclosures<ModalTypes>>;
}

export function FooterInfo(props: FooterInfoProps) {
  //   const { coin, user, counterParties, adsType } = props;
  //   const { label, icon } = useCoin((coin as any) ?? "btc");

  const disclosures = useDisclosures<ModalTypes>();
  const actionsView = useActionsMapper({ ...props, disclosures });

  //   const tradeOwner = useMemo(() => counterParties?.find((cp) => toLower(cp.role) === "tradeowner"), [counterParties]);

  //   const tradeType = useMemo(() => {
  //     const map: Record<"trader" | "tradeowner", { [key: string]: string }> = {
  //       trader: { selling: "Buy", buying: "Sell" },
  //       tradeowner: { selling: "Sell", buying: "Buy" },
  //     };
  //     return map[tradeOwner?.user?.userId === user?.userId ? "tradeowner" : "trader"][adsType!];
  //   }, [adsType, tradeOwner, user?.userId]);

  return (
    <VStack maxW={["420px"]} mx="auto" mt="12px" justifyContent="space-between">
      {/* <Heading as="h4" fontSize="24px" fontWeight="bold" display="flex" alignItems="center">
        {tradeType} {label} <Box as={icon} ml="10px" boxSize="32px" />
      </Heading> */}
      {actionsView}
    </VStack>
  );
}

function TraderReleaseAction(props: UseActionMapperProps) {
  const { disclosures, volume, coin, contractState, isLoading, counterParties, user } = props;
  const { isOpen, close, open } = disclosures;

  // const { ref } = useParams();
  const color = useColor();
  // const [id, frozenRef] = String(ref).split("-");

  const { tradeId: id, frozenRef } = useP2PSession();

  const appropriateSessionOwner = useMemo(() => {
    return counterParties?.find((cp) => cp.user.userId === user?.userId);
  }, [counterParties, user]);

  const ownerRole = useMemo(() => appropriateSessionOwner?.role, [appropriateSessionOwner]);

  const trader = useMemo(
    () => (counterParties ?? [])?.find((cp) => toLower(cp.role) === (ownerRole === "trader" ? "tradeowner" : "trader")),
    [counterParties, ownerRole]
  );

  const currentTradeOwnerStatus = useMemo(() => `${contractState}&${trader?.obligationStatus}`, [contractState, trader]);
  const isPaid = useMemo(() => currentTradeOwnerStatus === "open&fulfilled", [currentTradeOwnerStatus]);

  const { updateOrder, isUpdating } = useUpdateP2POrder(id, frozenRef);

  return (
    <VStack>
      <Box my="54px !important">
        <Heading as="h5" fontSize="lg">
          Note:
        </Heading>
        <Box as="ul" ml="16px" p="16px">
          <Text as="li" fontWeight="500" mb="8px" lineHeight="28px">
            Only release coins when you have received full payment from the buyer.
          </Text>
          <Text as="li" fontWeight="500" lineHeight="28px">
            Confirm that the name of the buyer matches with name on bitmama.
          </Text>
        </Box>
      </Box>

      <Text
        textAlign="center"
        mb="20px !important"
        color="error"
        fontWeight="700"
        fontFamily="var(--bitmama-fonts-heading)"
        lineHeight="17px"
      >
        PS: Your Coin is held in escrow until payment from Buyer is confirmed
      </Text>

      <Button
        minW="200px"
        // maxW="200px"
        w="100%"
        // bg="#6FBE45"
        bg={color("primary.default", "#6FBE45")}
        fontFamily="var(--bitmama-fonts-heading)"
        disabled={!isPaid || isUpdating || isLoading}
        isLoading={isUpdating}
        onClick={open("release")}
      >
        Release Coins
      </Button>

      <ConfirmationModal
        isOpen={isOpen("release")}
        onClose={close("release")}
        onConfirm={() => updateOrder("RELEASETRADE")}
        title="Confirm Release"
        _content={{ maxW: "700px" }}
      >
        <VStack m="40px auto" maxW="400px">
          <Text fontSize="16px" fontWeight="500" textAlign="center" color={color("black", "white")}>
            Please confirm that you have received payment from @{trader?.user?.username ?? ""} and want to release{" "}
            {toPrecision(volume ?? 0, coin ?? "btc")}{" "}
          </Text>
        </VStack>
      </ConfirmationModal>
    </VStack>
  );
}

function useActionsMapper(props: UseActionMapperProps) {
  const { mapKey } = props;

  const renderView = useMemo(() => {
    const map: Record<string, JSX.Element> = {
      // Map views for users selling to a buy listing. PS: Sell tab items.
      "trader&buying&open&fulfilled": <TraderReleaseAction {...props} />,
      "trader&buying&open&pending": <TraderReleaseAction {...props} />,
      "trader&buying&closed&fulfilled": <></>,

      "tradeowner&selling&open&pending": <TraderReleaseAction {...props} />,
    };

    return map[mapKey];
  }, [mapKey, props]);

  return renderView;
}
