import { Box, Button, Heading, HStack, TabList, TabPanel, Tabs, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Icon, MainLayoutContainer, PageMotion, Tab, TabPanels } from "components";
import { useBuiltInFn } from "hooks";
import { PageProps } from "interfaces";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HistoryFilterModal, HistoryInvitePanel, HistoryRewardPanel } from "ui";

interface HistoryProps extends PageProps {}

export default function History(props: HistoryProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { goBack } = useBuiltInFn();

  return (
    <PageMotion subpage key="AccountRules" overflowY="hidden">
      <MainLayoutContainer px={{ base: "20px", "2sm": "80px", "4sm": "40px", md: "40px", xl: "0px" }} py="10px">
        <Box>
          <Button
            size="sm"
            minW="fit-content"
            maxW="fit-content"
            variant="link"
            leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
            onClick={goBack}
          >
            Back
          </Button>

          <HStack justifyContent="space-between" mt="10px">
            <Heading as="h5" fontSize={{ base: "18px", "3sm": "24px" }}>
              Bitmama Partners
            </Heading>

            <Button
              p="4px"
              h="fit-content"
              minW="fit-content"
              minH="fit-content"
              maxW="fit-content"
              variant="transparent"
              rightIcon={<Icon type="filter" />}
              onClick={onOpen}
            >
              Filter
            </Button>
          </HStack>
        </Box>

        <Box my="24px">
          <HistoryTabs {...props} />
        </Box>

        <HistoryFilterModal isOpen={isOpen} onClose={onClose} />
      </MainLayoutContainer>
    </PageMotion>
  );
}

function HistoryTabs(props: any) {
  const location = useLocation();
  const cachedLocation = useRef(location);
  const searchParams = useMemo(() => new URLSearchParams(location?.search as string), [location]);

  const tabMap: Record<string, number> = useMemo(
    () => ({
      invite: 0,
      reward: 1,
    }),
    []
  );

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("tab") as any] ?? 0);

  const handleTabChange = useCallback(
    (index: number) => {
      changeTab(index);
      searchParams.set("tab", Object.keys(tabMap)[index]);
      navigate(`?${searchParams.toString()}`);
    },
    [changeTab, searchParams, tabMap]
  );

  useEffect(() => {
    const index = tabMap[searchParams.get("tab") as string] ?? 0;
    if (!isEqual(location, cachedLocation.current) && tabIndex !== index) {
      if (searchParams.has("tab")) handleTabChange(index);
      if (!searchParams.has("tab") || !searchParams.get("tab")) handleTabChange(0);

      cachedLocation.current = location;
    }
  }, [location, handleTabChange, tabIndex, tabMap, searchParams]);

  return (
    <VStack w="100%">
      <Tabs w="100%" variant="ghost" index={tabIndex} onChange={handleTabChange} mt="42px !important">
        <TabList borderRadius="8px" justifyContent="center" gap="16px">
          <Tab>Invite History</Tab>
          <Tab>Reward History</Tab>
        </TabList>
        <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
          <TabPanel px={["2", "0", "0", "1"]}>
            <HistoryInvitePanel {...props} />
          </TabPanel>
          <TabPanel px={["0", "0", "0", "1"]}>
            <HistoryRewardPanel {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
