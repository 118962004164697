const fonts = {
  // heading: "'PolySans Trial', sans-serif",
  heading: "'ClashDisplay', sans-serif",
  // heading: "'PolySans Bulky Wide', sans-serif",
  // body: "'Neue Haas Grotesk Display Pro', sans-serif",
  body: "'Inter Tight', sans-serif",
  mono: "'Quicksand', mono",
};

export default fonts;
