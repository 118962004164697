import { Box, BoxProps, Button, useColorMode, useColorModeValue, VStack } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import Logo from "../Logo/Logo";
import SidebarLink from "./SidebarLink";
import styles from "./SidebarLink.module.scss";
import "./SidebarLink.module.scss";

import { RouterProps } from "@reach/router";
import Icon from "../Icon/Icon";
import { switchStyle, when } from "utils";
import { useSidePage } from "contexts";
import { Version } from "components/Version/Version";
import { useAuth, useDisclosures } from "hooks";
import { useAppConfig } from "contexts/appconfig.context";
import { FeatureUnavailableModal } from "ui";
import { Emitter } from "libs";
import { AppAnalyticEvent } from "interfaces";

interface SidebarProps extends BoxProps {
  location: RouterProps["location"];
  is1024px: boolean;
}

const HomeIcon = () => <Icon type="home" color="gray.300" />;
const WalletIcon = () => <Icon type="wallet" color="gray.300" />;
const P2pIcon = () => <Icon type="p2p" color="gray.300" />;
const SwapIcon = () => <Icon type="swap" color="gray.300" />;
const CardsIcon = () => <Icon type="cards" color="gray.300" />;
const EarnIcon = () => <Icon type="earn" color="gray.300" />;
const AccountIcon = () => <Icon type="account" color="gray.300" />;
const FaqIcon = () => <Icon type="faqFilled" color="gray.300" />;

const ActiveHomeIcon = () => {
  const color = useColorModeValue("primary.800", "secondary.400");
  return <Icon type="filledHome" color={color} />;
};
const ActiveWalletIcon = () => {
  const color = useColorModeValue("primary.800", "secondary.400");
  return <Icon type="filledWallet" color={color} />;
};
const ActiveP2pIcon = () => {
  const color = useColorModeValue("primary.800", "secondary.400");
  return <Icon type="filledP2p" color={color} />;
};

const ActiveSwapIcon = () => {
  const color = useColorModeValue("primary.800", "secondary.400");
  return <Icon type="filledSwap" color={color} />;
};
const ActiveCardsIcon = () => {
  const color = useColorModeValue("primary.800", "secondary.400");
  return <Icon type="filledCards" color={color} />;
};
const ActiveEarnIcon = () => {
  const color = useColorModeValue("primary.800", "secondary.400");
  return <Icon type="filledEarn" color={color} />;
};
const ActiveAccountIcon = () => {
  const color = useColorModeValue("primary.800", "secondary.400");
  return <Icon type="filledAccount" color={color} />;
};

const Sidebar: FC<SidebarProps> = (props) => {
  const { is1024px, ...xprops } = props;

  const { logout } = useAuth();

  const { webengage } = useAppConfig();

  const onLogout = () => {
    webengage?.user?.logout();
    logout();
  };

  const { isOpen, open, close } = useDisclosures<ModalTypes>();

  const pageLinks = usePageLinks({ open });

  const { colorMode: mode } = useColorMode();

  const bg = switchStyle(mode, {
    light: "#072420",
    dark: "dark.cardBg", // "primary.700"
  });

  // const logout = () => {
  //   localStorage.clear();

  //   const win: Window = window;
  //   win.location = "/login";
  // };

  const isCurrent = useCallback(
    (path: string) => {
      const pathname = props.location?.pathname.match(/(\w)+/g),
        name = path.match(/(\w)+/g);

      return ((name && pathname && pathname[0]?.includes(name[0])) || props.location?.pathname === path) ?? false;
    },
    [props?.location]
  );

  return (
    <Box
      bg={bg}
      display={is1024px ? "none" : "block"}
      minW="var(--sideNavWidth)"
      maxW="var(--sideNavWidth)"
      py="26px"
      minH="100vh"
      pos="fixed"
      top="0"
      bottom="0"
      // overflowX="scroll"
      // overflowY="auto"
      overflow="auto"
      // visibility="hidden"
      _hover={{
        overflowY: "scroll",
      }}
      className={styles.sidebar}
      // css={{
      //   "&::-webkit-scrollbar": {
      //     width: "3px",
      //     height: "3px",
      //   },
      //   "&::-webkit-scrollbar-thumb": {
      //     background: "#EFF0F6",
      //   },
      //   "&::-webkit-scrollbar-track": {
      //     background: "#F2F2F2",
      //   },
      // }}
      {...xprops}
    >
      <VStack justifyContent="center" alignItems="center" w="100%">
        <Logo _icon={{ fontSize: "48px" }} _name={{ fontSize: "100px" }} color="white" />
      </VStack>

      <VStack alignItems="flex-start" w="100%" mt="50px" overflowY="hidden" overflowX="hidden">
        {pageLinks.map((link, i) => (
          <SidebarLink key={i} {...link} disabled={!!link?.disabled} isCurrent={isCurrent(link.to)} />
        ))}

        <SidebarLink icon={FaqIcon} label="FAQs" to="#" onClick={() => window.open("https://bitmama.io/faq")} />

        <Button
          mt="16px !important"
          leftIcon={<Icon type="logout" />}
          variant="ghost"
          p="16px 64px"
          w="100%"
          minH="62px"
          maxH="fit-content"
          h="fit-content"
          borderRadius="0px"
          justifyContent="flex-start"
          color="gray.100"
          fontSize="sm"
          onClick={onLogout}
          _hover={{
            bg: "#EFF0F6",
            color: bg,
          }}
        >
          Log Out
        </Button>

        <VStack w="100%">
          <Version my="20px !important" color="primary.700" />
        </VStack>
      </VStack>

      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </Box>
  );
};

type ModalTypes = "featureUnavailable";

function usePageLinks(props: { open: (type: ModalTypes) => () => void }) {
  // const { open } = props!;
  const { onOpen } = useSidePage();

  const { appfigs, countryfigs } = useAppConfig();
  const { swapConfig /*, p2pConfig*/ } = appfigs ?? {};
  const { cards } = countryfigs ?? {};

  console.log("Country Figs", countryfigs);

  const pageLinks = useMemo(
    () => [
      {
        activeIcon: ActiveHomeIcon,
        icon: HomeIcon,
        label: "Home",
        to: "/home",
      },
      {
        activeIcon: ActiveWalletIcon,
        icon: WalletIcon,
        label: "Wallet",
        to: "/wallet",
      },
      // {
      //   activeIcon: ActiveP2pIcon,
      //   icon: P2pIcon,
      //   label: "P2P",
      //   to: "/p2p",
      //   disabled: when(!!p2pConfig, !!p2pConfig?.disableP2PApp, false),
      // },
      {
        activeIcon: ActiveSwapIcon,
        icon: SwapIcon,
        label: "Swap",
        to: "#",
        onClick: () => onOpen({ key: "Swap" }),
        disabled: when(!!swapConfig, !!swapConfig?.disableSwaps, false),
      },
      {
        activeIcon: ActiveCardsIcon,
        icon: CardsIcon,
        label: "Cards",
        to: "/cards",
        disabled: !cards,
      },
      {
        activeIcon: ActiveEarnIcon,
        icon: EarnIcon,
        label: "Earn",
        to: "/earn",
        afterClick: () => {
          Emitter.emit(AppAnalyticEvent.EARN_CLICKED, { clicked: true });
        },
      },
      {
        activeIcon: ActiveAccountIcon,
        icon: AccountIcon,
        label: "Account",
        to: "/account",
      },
    ],
    [swapConfig, onOpen, cards]
  );

  return pageLinks;
}

export default Sidebar;

export {
  HomeIcon,
  WalletIcon,
  P2pIcon,
  SwapIcon,
  CardsIcon,
  AccountIcon,
  ActiveHomeIcon,
  ActiveWalletIcon,
  ActiveP2pIcon,
  ActiveSwapIcon,
  ActiveCardsIcon,
  ActiveEarnIcon,
  ActiveAccountIcon,
};
