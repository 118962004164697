import { useEffect } from "react";
import { useSupport } from "hooks";

export function ShowCustomerSupport(props: any) {
  const { bootWithProps } = useSupport();

  useEffect(() => {
    bootWithProps();
    // eslint-disable-next-line
  }, []);
  return <></>;
}
