import { Box, Button, Heading, ModalProps, Text, VStack } from "@chakra-ui/react";
import { AbstractModal } from "components";

import { ReactComponent as SubtractedShieldSVG } from "assets/svg/subtracted-shield.svg";
import { Link } from "@reach/router";
interface JoinPartnersProps extends Omit<ModalProps, "children"> {}

export default function JoinPartners(props: JoinPartnersProps) {
  const { isOpen, onClose } = props;

  // const handleSubmit = () => {
  //   onClose();
  // };

  return (
    <AbstractModal isOpen={isOpen} onClose={onClose} _content={{ maxW: "560px" }}>
      <VStack py="45px" px={{ base: "16px", md: "60px" }}>
        <VStack maxW="520px" m="0 auto">
          <Box boxSize="48px" mb="14px !important" as={SubtractedShieldSVG} />

          <Heading as="h4" fontSize="32px" mb="28px !important">
            Join Program
          </Heading>
          <Text textAlign="center" mt="20px">
            {/* You need to have joined the Bitmama Partners Program to get access to this feature */}
            You need to verify your account to be a Partner on Bitmama
          </Text>
        </VStack>

        <VStack mt="38px !important" gridGap="16px" px={{ base: "0", md: "46px" }} w="100%">
          <Link to="/account/verification">
            <Button minW={{ base: "300px", md: "400px" }} w="100%" onClick={onClose} fontFamily="var(--bitmama-fonts-heading)">
              Verify
            </Button>
          </Link>

          <Button minW={{ base: "300px", md: "400px" }} variant="transparent" textDecoration="underline" onClick={onClose}>
            Close
          </Button>
        </VStack>
      </VStack>
    </AbstractModal>
  );
}
