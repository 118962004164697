import { useValdatePhoneMutation } from "apis";
import { useCallback, useMemo } from "react";
import toLower from "lodash/toLower";
import { when } from "utils";
import { currencyMap } from "./useCurrency";

export function useValidatePhoneNumber(country: string) {
  const [_validate, { data, isLoading: isValidating, error }] = useValdatePhoneMutation();

  const countryTwoLetterCode = useMemo(() => {
    return currencyMap[toLower(country!)].iso2;
  }, [country]);

  const formatPhone = useCallback(
    (number: string) => {
      const countryCode = currencyMap[toLower(country!)].code;
      let phone = when(
        String(number ?? "").startsWith("0") || String(number ?? "").startsWith("+"),
        String(number).slice(1),
        number
      );
      const hasCountryCode = String(number).startsWith(countryCode) || String(number).startsWith(countryCode.slice(1));
      phone = when(hasCountryCode, phone.slice(countryCode.length - 1), phone);
      console.log("Has Country Code: " + hasCountryCode, number, countryCode, phone);
      return countryCode.concat(phone ?? "");
    },
    [country]
  );

  const isWrongNetwork = useMemo(
    () =>
      String((error ?? ({} as any))?.data?.error ?? "")
        .toLowerCase()
        .includes("wrong phone network"),
    [error]
  );
  console.log("Phone validation data", isWrongNetwork, data, error);

  // extract function arg type from _validate;
  type ArgType = Parameters<typeof _validate>[0];

  const validate = useCallback(
    (arg: Omit<ArgType, "country">) =>
      _validate({ ...arg, country: countryTwoLetterCode, phoneNumber: formatPhone(arg.phoneNumber) }),
    [_validate, countryTwoLetterCode, formatPhone]
  );

  return { validate, isValidating, data, isWrongNetwork, formatPhone };
}
