import { Box, BoxProps, HStack, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { useCopy, useDefaultStyle } from "hooks";
import { CardProps } from "components/Card/Card";
import { Icon, Card, CopyRefCode } from "components";
import { useMemo } from "react";
import { when } from "utils";
// import { when } from "utils";
interface ShareActionsProps extends BoxProps {
  link?: string;
  title?: string;
  value?: string;
  copyText?: string;
}

interface ShareButtonProps extends CardProps {
  icon: string;
  label: string;
}

export default function ShareActions(props: ShareActionsProps) {
  const { link, title, value, copyText, ...xprops } = props;

  // const is_value_set = useRef(false);

  const URL = "https://dashboard.bitmama.io";

  const _value = value ?? title ?? ""; // fallback
  const _link = useMemo(() => when(!!link, link!, `${URL}/signup?refCode=${_value}`), [link, _value]);
  // const _link = useMemo(() => `${URL}/signup?refCode=${_value}`, [_value]);
  const { onCopy: onLinkCopy, value: linkValue } = useCopy(_link);

  // useEffect(
  //   () => {
  //     if (!!_link && !is_value_set.current) setValue(_link);
  //   },

  //   //eslint-disable-next-line
  //   [is_value_set]
  // );

  return (
    <Stack {...xprops}>
      <CopyRefCode link={_value} title={title} copyText={copyText} />

      <VStack my="44px !important">
        <Text fontWeight="500" fontSize="lg" mb="20px !important">
          Or share via
        </Text>

        <HStack justifyContent="space-between" gridGap={{ base: "32px", "4sm": "36px" }}>
          <FacebookShareButton placeholder="" url={linkValue} hashtag="bitmama">
            <ShareButton icon="facebook" label="Facebok" />
          </FacebookShareButton>

          <TwitterShareButton placeholder="" url={linkValue}>
            <ShareButton icon="twitter" label="Twitter" />
          </TwitterShareButton>

          <WhatsappShareButton placeholder="" url={linkValue}>
            <ShareButton icon="whatsapp" label="Whatsapp" />
          </WhatsappShareButton>

          <>
            <ShareButton onClick={onLinkCopy} icon="copy" label="Copy Link" />
          </>
        </HStack>
      </VStack>
    </Stack>
  );
}

function ShareButton(props: ShareButtonProps) {
  const { icon, label, onClick } = props;

  const { shadow, borderColor } = useDefaultStyle();
  const filterColor = useColorModeValue("#E5E5E5", "#434242");

  return (
    <VStack onClick={onClick}>
      <Card
        as="button"
        boxSize={{ base: "32px", smx: "35px", "2sm": "38px" }}
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        _focus={{ shadow, borderColor, transition: "all .4s cubic-bezier(0.4, 0, 0.2, 1)" }}
        filter={`drop-shadow(0px 4px 7px ${filterColor})`}
      >
        <Box as={() => <Icon boxSize={{ base: "32px", "2sm": "35px" }} color="secondary.400" type={icon as any} />} />
      </Card>
      <Text fontSize={{ base: "10px", "1sm": "12px" }} fontWeight="500" color="grey.300">
        {label}
      </Text>
    </VStack>
  );
}
