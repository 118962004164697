import { useRef, useState } from "react";
import { Box, Button, Center, Heading, HStack, VStack, Text, useColorModeValue, useToast, useMediaQuery } from "@chakra-ui/react";
import { Card, CopyButton, Group, Logo } from "components";

import ReCAPTCHA from "react-google-recaptcha";
import configs from "config";
import { useReset2FAMutation } from "apis";
import { navigate, useLocation } from "@reach/router";
import { useCopy } from "hooks";
import ls from "utils/secureStorage";
import { AuthImage } from "ui";

export default function Reset2FA(props: { path: string }) {
  const recaptchaRef = useRef<any>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [params] = useState(searchParams.toString());
  const hColor = useColorModeValue("#303131", "white");
  const logoColor = useColorModeValue("primary.default", "secondary.500");
  const [selected, setSelected] = useState("");
  const toast = useToast();
  const tColor = useColorModeValue("white", "black");

  const handleSelect = (value: string) => {
    setSelected(value);
  };

  const [dataCode, setDataCode] = useState({
    qrCodeLink: "",
    secret: "",
    token: "",
    exp: "",
  });

  const { onCopy } = useCopy(dataCode?.qrCodeLink ?? "");
  const [_reset2FA, { isLoading }] = useReset2FAMutation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      if (navigator.onLine === true) {
        const humanKey = await recaptchaRef.current?.executeAsync();

        const response: any = await _reset2FA({
          params,
          humanKey,
          renew: String(selected).toLowerCase().includes("activate"),
        }).unwrap();

        if (!!response) {
          console.log("Got a 2FA respose", response);
          setDataCode(response.data);
          toast({
            position: "bottom-right",
            title: "Success",
            description: response.data.message,
            status: "success",
            duration: 9000,
            isClosable: true,
          });

          // if (!!window) (window as any).location = "/login";

          if (selected === "disabled") {
            localStorage.clear();
            navigate(configs.paths.login, { replace: true });
          }
        }
      } else {
        toast({
          position: "bottom-right",
          title: "Network Error!!",
          description: "Please check your network connection",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {}
  };

  const handleLogin = () => {
    ls.set(configs.AUTH_TOKEN_KEY, { token: dataCode.token, isSignedIn: true, verification: [], exp: dataCode.exp });
    window.location.replace(configs.paths.dashboard);
  };

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const [isMobile] = useMediaQuery("(max-width: 1023px)");

  const [is425] = useMediaQuery("(max-width: 425px)");

  return (
    <Box
      minH="100vh"
      pos="relative"
      sx={{
        "--w": isLargerThan1080 ? "580px" : "480px",
      }}
    >
      <AuthImage isMobile={isMobile} />

      <Box minH="100vh" pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
        <Group maxWidth="523px" width="100%" margin="auto" padding="92px 20px 43px">
          <Center width="100%" alignItems={is425 ? "flex-start" : "center"} flexDir="column" as="form" onSubmit={handleSubmit}>
            <Logo fontSize="54px" mb="16px" _name={{ w: "110px" }} color={logoColor} />

            <Box>
              <Heading fontSize="32px" color={hColor}>
                Reset 2FA
              </Heading>

              <Text fontWeight="500" color={hColor} fontSize="16px" margin="29px 0 45px">
                Select an option to continue
              </Text>
            </Box>

            <>
              {!dataCode.qrCodeLink ? (
                <>
                  <HStack spacing="17px">
                    <Button
                      borderRadius="10px"
                      minH="43px"
                      fontSize="12px"
                      minW="129px"
                      padding="0"
                      bg={selected === "disable" ? "rgba(237, 28, 0, 0.2)" : "#CACBCB"}
                      color="#0D4740"
                      _hover={{ bg: "rgba(237, 28, 0, 0.2)" }}
                      _focus={{
                        bg: "rgba(237, 28, 0, 0.2)",
                        border: "none",
                      }}
                      onClick={() => handleSelect("disable")}
                    >
                      DISABLE OLD 2FA
                    </Button>

                    <Button
                      borderRadius="10px"
                      minH="43px"
                      fontSize="12px"
                      padding="0"
                      minW="129px"
                      bg={selected === "activate" ? "#E2F2DA" : "#CACBCB"}
                      color="#0D4740"
                      _hover={{ bg: "#E2F2DA" }}
                      _focus={{
                        border: "none",
                        bg: "#E2F2DA",
                      }}
                      onClick={() => handleSelect("activate")}
                    >
                      ACTIVATE NEW 2FA
                    </Button>
                  </HStack>
                </>
              ) : (
                <>
                  <Card
                    mt="26px"
                    borderRadius="20px"
                    textAlign="center"
                    py="20px"
                    w="100%"
                    shadow="0px 4px 10px rgba(67, 55, 55, 0.12)"
                  >
                    <VStack>
                      <Text fontSize="14px" fontWeight="600" maxW="234px">
                        Scan this QR Code in the Google Authenticator App
                      </Text>

                      <Box
                        mt="34px !important"
                        mb="42px !important"
                        bgRepeat="no-repeat"
                        bgSize="contain"
                        bgImage={`url(${dataCode.qrCodeLink})`}
                        height="217px"
                        width="217px"
                      />

                      <Text fontSize="12px" fontWeight="600" maxW="242px">
                        If you are unable to scan the QR code, please enter the code below manually into the App
                      </Text>

                      <Box borderRadius="8px" p={{ base: "12px 10px", "2sm": "12px 28px" }} bg="secondary.400">
                        <HStack>
                          <Text
                            fontSize={{ base: "10px", "2sm": "14px" }}
                            fontWeight="600"
                            color={tColor}
                            maxWidth={{ base: "170px", "2sm": "270px" }}
                            width="100%"
                          >
                            {dataCode?.secret}
                          </Text>
                          <CopyButton color="primary.800" onClick={onCopy} />
                        </HStack>
                      </Box>
                    </VStack>
                  </Card>
                </>
              )}
            </>

            <Center mt="54px" width="100%">
              {dataCode?.qrCodeLink ? (
                <Button
                  minW="initial"
                  maxW="399px"
                  width="100%"
                  type="button"
                  fontSize={{ base: "12px", "2sm": "16px" }}
                  onClick={handleLogin}
                  disabled={isLoading || !selected}
                >
                  I have kept my secret safely, let's continue
                </Button>
              ) : (
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading || !selected}
                  minW="initial"
                  maxW="399px"
                  width="100%"
                >
                  Proceed
                </Button>
              )}
            </Center>

            {/* <Link mt="160px !important" to="/" fontWeight="500" fontSize="sm" textDecoration="underline">
              Back to Login
            </Link> */}

            <Button
              variant="link"
              mt="160px !important"
              fontWeight="500"
              fontSize="sm"
              textDecoration="underline"
              onClick={() => ((window.location as any) = "/")}
            >
              Back to Login
            </Button>
          </Center>
        </Group>
      </Box>
      <ReCAPTCHA ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />
    </Box>
  );
}
