export function useIovationBlackbox() {
  const getBlackbox = () => {
    if (typeof window.IGLOO.getBlackbox !== "function") return null;
    var data = window.IGLOO.getBlackbox();
    if (data.finished) return data.blackbox;
    return null;
  };

  return { getBlackbox };
}
