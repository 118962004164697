import { useGetGreenboxWalletBalancesQuery } from "apis";
import { useCallback } from "react";

export function useGreenboxWalletBalance() {
  const { data: wallets, isLoading, refetch } = useGetGreenboxWalletBalancesQuery();

  const getCoin = useCallback(
    (coin: string) => {
      if (wallets) {
        return wallets?.find((a) => a?.unit === coin);
      }
    },
    [wallets]
  );

  const getBalance = useCallback((coin: string) => +(getCoin(coin)?.balance?.$numberDecimal ?? 0), [getCoin]);

  return {
    isLoading,
    getCoin,
    getBalance,
    refetch,
  };
}
