import { Box, BoxProps } from "@chakra-ui/react";

interface SpanProps extends BoxProps {}

export default function Span(props: SpanProps) {
  return (
    <Box as="span" display="inline-block" {...props}>
      {props.children}
    </Box>
  );
}
