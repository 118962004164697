import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Heading,
  Stack,
  Text,
  useColorMode,
  useMediaQuery,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Group, InputLabel, Logo, Link } from "components";
import Recaptcha from "react-google-recaptcha";
import PasswordInput from "components/Input/PasswordInput";
import { useCallback, useMemo, useRef } from "react";
import { usePartialState } from "hooks";
import { navigate, useLocation } from "@reach/router";
import { useResetPasswordMutation } from "apis";
import configs from "config";
import { switchStyle } from "utils";
import { AuthImage } from "ui";
import { LeftArrowSVG } from "assets";

interface IState {
  newPassword: string;
  confirmPassword: string;
}

export default function ResetPassword(props: { path: string }) {
  const location = useLocation();

  const toast = useToast();
  const recaptchaRef = useRef<any>(null);
  const [state, set] = usePartialState<IState>({});

  const params = useMemo(() => new URLSearchParams(location?.search).toString(), [location]);

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const { colorMode } = useColorMode();
  const passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const captcha = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();

    const reqData = {
      params,
      password: state?.newPassword,
      confirmPassword: state?.confirmPassword,
      humanKey: captcha,
    };

    const result = await resetPassword(reqData).unwrap();
    // console.log("Reset Result", result);
    if (!!result && (result?.status as unknown as string) === "success") {
      console.log("Reset Result", result);
      toast({
        position: "bottom-right",
        title: "Success",
        description: "Password successfully changed.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate(configs.paths.login, { replace: true });
    }
  };

  const isPasswordsInvalid = useMemo(
    () => !!state?.newPassword && !!state?.confirmPassword && state?.newPassword !== state?.confirmPassword,
    [state]
  );

  const isPasswordValid = useCallback(
    (password: string) => password && RegExp(passwordPattern).test(password),
    // eslint-disable-next-line
    []
  );

  const isDisabled = useMemo(
    () =>
      !(
        state?.newPassword &&
        state?.confirmPassword &&
        isPasswordValid(state?.newPassword) &&
        isPasswordValid(state?.confirmPassword)
      ) ||
      !params ||
      isPasswordsInvalid ||
      isLoading,
    [state, isLoading, isPasswordValid, isPasswordsInvalid, params]
  );

  const [isMobile] = useMediaQuery("(max-width: 1023px)");
  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");
  return (
    <Box
      minH="100vh"
      pos="relative"
      sx={{
        "--w": isLargerThan1080 ? "580px" : "480px",
      }}
    >
      <AuthImage isMobile={isMobile} />

      <Box pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
        <Group maxWidth="700px" margin="auto" padding={{ sm: "32px 20px 43px", "2sm": "80px 20px 43px" }}>
          {isMobile && (
            <Link to="/login" mb="24px">
              <LeftArrowSVG />
            </Link>
          )}

          <Logo
            fontSize="46px"
            _name={{ boxSize: "80px" }}
            color={switchStyle(colorMode, { light: "primary.default", dark: "secondary.500" })}
          />

          <Box mb="40px !important">
            <Heading
              fontSize={{ sm: "24px", "2sm": "32px" }}
              color={switchStyle(colorMode, { light: "#303131", dark: "grey.100" })}
            >
              Reset your Password
            </Heading>
            <Text color={switchStyle(colorMode, { light: "grey.800", dark: "grey.100" })} fontSize="18px" mt="14px">
              Enter your new password
            </Text>
          </Box>

          <Stack as="form" maxW="526px" gridGap="24px" onSubmit={handleSubmit}>
            <FormControl>
              <InputLabel>New Password</InputLabel>
              <PasswordInput
                isRequired
                isInvalid={!!state?.newPassword && !isPasswordValid(state?.newPassword)}
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                value={state?.newPassword ?? ""}
                onChange={(e) => set({ newPassword: e.target.value })}
                placeholder="Enter Password"
              />

              {!!state?.newPassword && !isPasswordValid(state?.newPassword) && (
                <FormHelperText fontSize="xs" color="red.400">
                  Password must have at least one uppercase letter, and number and a special character
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <InputLabel>Confirm Password</InputLabel>
              <PasswordInput
                isRequired
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                isInvalid={(state?.confirmPassword && !isPasswordValid(state?.confirmPassword)) || isPasswordsInvalid}
                value={state?.confirmPassword ?? ""}
                onChange={(e) => set({ confirmPassword: e.target.value })}
                placeholder="Enter Password Again"
              />

              {!!state?.confirmPassword && !isPasswordValid(state?.confirmPassword) && (
                <FormHelperText fontSize="xs" color="red.400">
                  Password must have at least one uppercase letter, and number and a special character
                </FormHelperText>
              )}
              {!!state?.confirmPassword && isPasswordValid(state?.confirmPassword) && isPasswordsInvalid && (
                <FormHelperText fontSize="xs" color="red.400">
                  Passwords do not match
                </FormHelperText>
              )}
            </FormControl>

            <VStack w="100%" mt="66px !important" alignItems="center">
              <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />

              <Button type="submit" minW={{ base: "100%", tb: "400px" }} width="100%" isLoading={isLoading} disabled={isDisabled}>
                Submit
              </Button>
            </VStack>

            {/* <Divider></Divider> */}
          </Stack>
        </Group>
      </Box>
    </Box>
  );
}
