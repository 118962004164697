import Crypto from "crypto-js";

interface IGenerateHash {
  plainText: string;
  secretKey: string;
}

export function generateSha256({ plainText, secretKey }: IGenerateHash) {
  return String(Crypto?.HmacSHA256(plainText, secretKey));
}

const MD5Func = Crypto?.MD5;
type GenerateMD5Args = Parameters<typeof MD5Func>;

export function generateMD5(...args: GenerateMD5Args) {
  return String(Crypto?.MD5(...args));
}
