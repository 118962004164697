import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Heading,
  HStack,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { navigate, useParams } from "@reach/router";
import { useBuySellListingMutation, useGetCoinToUsdRateQuery, useGetSellListingByIdQuery } from "apis";
import { AmountInput, Card, FormInfo, InputLabel, PageLoading, PaymentMethod, Select, ShareActions, Username } from "components";
import { useCoin, useDisclosures, usePartialState } from "hooks";
import { AppAnalyticEvent, P2PListingTradeRo, P2PSellToBuyListingRo } from "interfaces";
import { useMemo } from "react";
import { clamp, currencyFormat, deduction, toCoinLocale, toPrecision, when } from "utils";

import toLower from "lodash/toLower";
import { SuccessModal } from "ui";
import { Emitter } from "libs";

interface BuyAdFeatureProps {}
interface InfoHubProps extends Partial<P2PListingTradeRo> {}

interface FormProps extends Partial<P2PListingTradeRo>, IStateExtension {
  unitCost?: number;
}
interface DetailsProps extends Partial<P2PListingTradeRo> {
  unitCost?: number;
}

interface IStateExtension {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  reset: () => void;
}

interface IState {
  method: string;
  amount: string;
  volume: string;
}
const floatString = (n: string | number): number | string =>
  (n && (Number.isInteger(n) ? Number(n).toFixed(2) : n.toString())) ?? "";

export default function BuyAdFeature(props: BuyAdFeatureProps) {
  const { id } = useParams();

  const [state, set, reset] = usePartialState<IState>();
  const { data, isLoading } = useGetSellListingByIdQuery(id);
  const reMapCoin = (coin: string) => {
    const map: Record<string, string> = {
      [coin]: coin,
      tbtc: "btc",
      teth: "eth",
      usdt: "usd",
      cusd: "usd",
    };
    return map[coin];
  };

  const { data: rate } = useGetCoinToUsdRateQuery(reMapCoin(data?.coin ?? "btc"), { skip: (data?.coin ?? "btc").includes("usd") });

  const exRate = useMemo(() => +(rate?.exchangeRate?.buy ?? 1), [rate]);

  const unitCost = useMemo(() => {
    if (data?.dynamic) return data?.coin?.includes("usd") ? 1 * data?.price : exRate * data?.price;
    return data?.tradeOption?.unitCost;
  }, [data, exRate]);

  console.log("Data", data, rate);

  return (
    <Box w="100%">
      {isLoading && <PageLoading my="60px" isLoading={isLoading} />}

      {!isLoading && (
        <>
          <InfoHub {...data} />

          <Grid
            my="40px"
            display={{ sm: "flex", md: "grid" }}
            flexDir={{ sm: "column", md: "inherit" }}
            templateColumns="repeat(2,1fr)"
            gap="22px"
          >
            <PaymentDetails {...{ ...data, state, set, reset, unitCost }} />
            <AdDetails unitCost={unitCost} {...data} />
          </Grid>
        </>
      )}
    </Box>
  );
}

type ModalTypes = "success";

function PaymentDetails(props: FormProps) {
  const { id } = useParams();
  const { state, set, paymentOption, coin, currency, unitCost, tradeOption, unfrozenBalance, currentVolume, userId } = props;

  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const [makeBuy, { isLoading: isBuying }] = useBuySellListingMutation();

  const options = useMemo(
    () =>
      (paymentOption ?? []).map((opt) => {
        const label = toLower(`${opt?.label ?? ""} ${opt?.receivingIntoLabel ?? ""} ${opt?.payingToLabel ?? ""}`);
        if (label.includes("bitmama") && opt?.source === "internal") return { key: "bitmamaWallet", label: "Bitmama Wallet" };
        if (label.includes("changera") && opt?.source === "external") return { key: "changera", label: "Changera ID" };
        if (label.includes("bank") && opt?.source === "external") return { key: "bankTransfer", label: "Bank Transfer" };
        return { key: "bankTransfer", label: "Bank Transfer" };
      }),
    [paymentOption]
  );

  const fee = useMemo(
    () =>
      (floatString(
        isNaN(deduction(parseFloat(state?.amount ?? ""))) ? "0.00" : deduction(parseFloat(state?.amount ?? ""))
      ) as string) ?? "0.00",
    [state?.amount]
  );
  const maximumFilling = useMemo(() => {
    if (+(currentVolume?.$numberDecimal ?? 0) < +(tradeOption?.maximumFilling ?? 0)) return +(currentVolume?.$numberDecimal ?? 0);
    else return +(tradeOption?.maximumFilling ?? 0);
  }, [currentVolume, tradeOption]);

  const isMinimumGreaterThanUnfrozenBalance = useMemo(
    () => (tradeOption?.minimumFilling ?? 0) > clamp(unfrozenBalance ?? 0, 0, tradeOption?.maximumFilling ?? 0),
    [tradeOption, unfrozenBalance]
  );

  const isCoinAmountInvalid = useMemo(
    () =>
      (!!state?.amount && parseFloat(state.amount ?? "") < +(tradeOption?.minimumFilling ?? 0)) ||
      (!!state?.amount && parseFloat(state.amount ?? "") > maximumFilling),
    [state, tradeOption, maximumFilling]
  );

  const isVolumeInvalid = useMemo(
    () =>
      (!!state?.volume && +(state?.volume ?? 0) < Number((tradeOption?.minimumFilling ?? 0) * (unitCost ?? 0))) ||
      (!!state?.volume && +(state?.volume ?? 0) > Number(maximumFilling * (unitCost ?? 0))),
    [unitCost, maximumFilling, tradeOption, state?.volume]
  );

  const isAmountGreaterThanAvailableVolume = useMemo(
    () => !!state?.amount && +(state?.amount ?? 0) > +(currentVolume?.$numberDecimal ?? 0),
    [state?.amount, currentVolume]
  );

  const isCoinAmountLessThanMinimumAmount = useMemo(
    () => !!state?.amount && parseFloat(state?.amount) - parseFloat(fee) < (tradeOption?.minimumFilling ?? 0),
    // eslint-disable-next-line
    [state?.amount, fee, tradeOption]
  );

  const isDisabled = useMemo(
    () =>
      isCoinAmountInvalid ||
      !(state?.method && state?.volume && state?.amount) ||
      isCoinAmountLessThanMinimumAmount ||
      isBuying ||
      !state?.method,
    [isCoinAmountInvalid, state, isCoinAmountLessThanMinimumAmount, isBuying]
  );

  const handleVolume = (e: string) => {
    const amount = toPrecision(floatString(Number(parseFloat(e) / (unitCost ?? 0))) as number, coin ?? "btc", false) as string;
    set({ amount, volume: e });
  };

  const handleAmount = (e: string) => {
    const volume = floatString(Number(parseFloat(e) * (unitCost ?? 0))) as string;
    const method = options?.[0]?.key;
    set({ volume, amount: e, method });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const eventData = { currency, coin, buyer: userId, method: state?.method };
    Emitter.emit(AppAnalyticEvent.P2P_BUY_INITIATED, eventData);

    const result = (await makeBuy({
      id,
      volume: parseFloat(state?.amount ?? ""),
      useInternalWallet: state.method === "bitmamaWallet",
    })) as { data: P2PSellToBuyListingRo };

    const data = result?.data;
    if (!!data) Emitter.emit(AppAnalyticEvent.P2P_BUY_SUBMITTED, eventData);

    console.log("Buy Result", data, result);
    if (!!data && data.tradeStatus === "inSession") navigate(`/p2p/order/${data?._id}-${data?.tradeRef}`);
    if (!!data && data.paymentStatus === "paid") open("success")();
  };

  return (
    <Card
      as="form"
      w="100%"
      p={{ sm: "20px", "2sm": "44px 48px", "3sm": "44px 48px", md: "44px 48px" }}
      borderRadius={{ sm: "8px", md: "16px" }}
      onSubmit={handleSubmit}
    >
      <Heading as="h6" fontSize="20px" fontWeight="600" mb="38px" display="none">
        Payment Details
      </Heading>

      <FormControl mb="34px">
        <InputLabel>Amount of {toCoinLocale(coin ?? "btc")}*</InputLabel>
        <AmountInput
          isInvalid={isCoinAmountLessThanMinimumAmount || isCoinAmountInvalid}
          isDisabled={isMinimumGreaterThanUnfrozenBalance}
          value={isNaN(+(state?.amount ?? "")) ? "" : state.amount}
          onChange={(e) => handleAmount(e.target.value)}
        />

        {isCoinAmountInvalid && (
          <FormHelperText fontSize="sm" mt="10px" fontWeight="500" color="error">
            Coin amount must not exceed the {tradeOption?.minimumFilling} {toCoinLocale(coin ?? "ngn")} min and {maximumFilling}{" "}
            {toCoinLocale(coin ?? "ngn")} max range
          </FormHelperText>
        )}

        {isAmountGreaterThanAvailableVolume && (
          <FormInfo
            isInvalid={isAmountGreaterThanAvailableVolume}
            info=""
            mt="10px"
            description="Amount is greater than the available order volume"
            _description={{ ml: "0 !important" }}
          />
        )}

        {/* <FormHelperText>
          Max {toCoinLocale(coin ?? "btc")} amount: {toPrecision(tradeOption?.maximumFilling ?? 0, coin ?? "btc")}
        </FormHelperText>
        <FormHelperText>
          Balance: {toPrecision(tradeOption?.maximumFilling ?? 0, coin ?? "btc")}
        </FormHelperText> */}
      </FormControl>

      <FormControl mb="34px">
        <InputLabel>Amount of {toCoinLocale(currency ?? "ngn")}*</InputLabel>
        <AmountInput
          isInvalid={isVolumeInvalid}
          isDisabled={isMinimumGreaterThanUnfrozenBalance}
          value={isNaN(+(state?.volume ?? "")) ? "" : Number(state?.volume).toFixed(2)}
          placeholder="0.00"
          onChange={(e) => handleVolume(e.target.value)}
        />
      </FormControl>

      <FormControl mb="34px">
        <InputLabel>Select Payment option</InputLabel>
        <Select
          /* placeholder="Select Payment Option" */ value={state?.method ?? ""}
          onChange={(e) => set({ method: e.target.value })}
        >
          {options?.map((opt) => (
            <option key={`${opt?.key}-payment-option`} value={opt?.key}>
              {opt?.label}
            </option>
          ))}
        </Select>
      </FormControl>

      <VStack mt="100px">
        <Button
          minW={{ sm: "100px", "2sm": "400px", "3sm": "400px", md: "400px" }}
          type="submit"
          isLoading={isBuying}
          disabled={isDisabled}
        >
          Buy {toCoinLocale(coin ?? "btc")}
        </Button>
        <Button
          minW={{ sm: "100px", "2sm": "400px", "3sm": "400px", md: "400px" }}
          variant="transparent"
          textDecoration="underline"
          disabled={isBuying}
        >
          Cancel
        </Button>
      </VStack>

      <SuccessModal
        isOpen={isOpen("success")}
        onClose={close("success")}
        message="Your trade was successful"
        onContinue={() => navigate("/wallet")}
      />
    </Card>
  );
}

function AdDetails(props: DetailsProps) {
  const {
    _id,
    unitCost,
    userData,
    presence,
    currency = "ngn",
    coin = "btc",
    tradeOption,
    currentVolume,
    paymentOption,
    country,
    terms,
    timeout,
    unfrozenBalance,
  } = props;
  const receivingPaymentMethods = paymentOption?.map((opt) => opt.label);
  const paymentMethodIsWallet = receivingPaymentMethods?.includes("Bitmama Wallet");
  const color = useColorModeValue("black", "white");

  const price = useMemo(
    () => `${currencyFormat(currency as any).format(unitCost ?? 0)} ${toCoinLocale(currency)}/${toCoinLocale(coin)}`,
    [unitCost, coin, currency]
  );

  const availableOrderVolume = useMemo(
    () => Math.min(unfrozenBalance ?? 0, +(currentVolume?.$numberDecimal ?? 0)),
    [unfrozenBalance, currentVolume]
  );

  const limits = useMemo(
    () =>
      `${toPrecision(+(tradeOption?.minimumFilling ?? 0), coin)} - ${toPrecision(
        +(tradeOption?.maximumFilling ?? currentVolume?.$numberDecimal ?? 0),
        coin
      )}`,
    [tradeOption, currentVolume, coin]
  );

  const isMinimumGreaterThanUnfrozenBalance = useMemo(
    () => (tradeOption?.minimumFilling ?? 0) > clamp(unfrozenBalance ?? 0, 0, tradeOption?.maximumFilling ?? 0),
    [tradeOption, unfrozenBalance]
  );

  const methods = useMemo(() => {
    return (paymentOption ?? [])?.map((option) => {
      const map: Record<string, string> = {
        external: "Bank Transfer",
        internal: "Bitmama Wallet",
      };

      return map[option?.source];
    });
  }, [paymentOption]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const copyableLink = useMemo(() => {
    const host = window.location.host;
    return `${host}/p2p/buy/${_id}`;
  }, [_id]);

  return (
    <Card
      as="form"
      w="100%"
      p={{ sm: "20px", "2sm": "44px 48px", "3sm": "44px 48px", md: "44px 48px" }}
      borderRadius={{ sm: "8px", md: "16px" }}
      onSubmit={handleSubmit}
    >
      <HStack mb="38px">
        <Heading as="h6" fontSize="20px" fontWeight="600">
          Ad Details
        </Heading>

        {isMinimumGreaterThanUnfrozenBalance && (
          <Badge colorScheme="red" p="4px 10px" fontSize="10px" borderRadius="12px">
            Trade is busy
          </Badge>
        )}
      </HStack>

      <FormInfo
        mb="40px"
        fontSize="md"
        info="Seller:"
        description={
          <Username username={`@${userData?.username}`} presence={(presence?.status as any) ?? "online"} dir="horizontal" />
        }
      />
      <FormInfo mb="40px" fontSize="md" info="Price:" description={price} _description={{ color }} />
      <FormInfo mb="40px" fontSize="md" info="Limits:" description={limits} _description={{ color }} />
      <FormInfo
        mb="40px"
        fontSize="md"
        info="Available Order Volume:"
        description={toPrecision(availableOrderVolume, coin)}
        _description={{ color }}
      />
      <FormInfo
        mb="40px"
        fontSize="md"
        info="Payment methods:"
        description={
          <HStack>
            {methods.map((method, i) => (
              <PaymentMethod key={`payment-method-${i}`}>{method}</PaymentMethod>
            ))}
          </HStack>
        }
        _description={{ color }}
      />
      <FormInfo
        mb="40px"
        fontSize="md"
        info="Location:"
        description={country}
        _description={{ color, textTransform: "capitalize" }}
      />
      <FormInfo
        mb={when(!!terms, "40px", "80px")}
        fontSize="md"
        info="Payment window:"
        description={paymentMethodIsWallet ? "Instant" : `${timeout} minutes`}
        _description={{ color }}
      />
      {!!terms && <FormInfo mb="80px" fontSize="md" info="Terms:" description={terms} _description={{ color }} />}

      <Divider />

      <ShareActions mt="48px" title="Share this Ad" copyText="Copy Ad Link" link={copyableLink} />
    </Card>
  );
}

function InfoHub(props: InfoHubProps) {
  const { coin, userData } = props;
  const { label } = useCoin(coin as any);

  return (
    <Box p="46px 48px" w="100%" borderRadius="16px" minH="138px" bg="#041815">
      <VStack>
        <HStack flexDir={{ sm: "column", "2sm": "row", "3sm": "row", md: "row" }}>
          <Heading as="h6" fontSize="24px" color="white">
            Buy {label} from
          </Heading>

          <Username username={`@${userData?.username}`} dir="horizontal" hidePresence _link={{ color: "white" }} />
        </HStack>
      </VStack>
    </Box>
  );
}
