import {
  Box,
  Button,
  Heading,
  HStack,
  List,
  ListItem,
  ListItemProps,
  ModalProps,
  TabList,
  TabPanel,
  Tabs,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { CreditCardIllustrationSVG } from "assets";
import { AbstractModal, Icon, Tab, TabPanels } from "components";
import { useAuth, useSelector } from "hooks";
import { useMemo, useState } from "react";
import { selectUser } from "store/slices";
import { commaStrToArr, switchStyle } from "utils";

import toLower from "lodash/toLower";
import { useGetCardConfigQuery } from "apis";
import { useAppConfig } from "contexts/appconfig.context";

interface CardAgreementProps extends Omit<ModalProps, "children"> {
  creationFee?: number;
  shouldConsent?: boolean;
  setAgreement?: () => void;
}

export default function CardAgreement(props: CardAgreementProps) {
  const { shouldConsent, setAgreement, creationFee = 3 } = props;
  const { colorMode } = useColorMode();

  const { auth } = useAuth();
  const { appfigs = {} } = useAppConfig();
  const { cardConfig } = appfigs;

  // const { data: firstRequestCheck } = useGetFirstRequestCheckQuery({});

  const { profile } = useSelector(selectUser);
  const { data: config } = useGetCardConfigQuery(null, { skip: !auth?.isSignedIn });
  const minimumAmount = useMemo(() => config?.funding?.min ?? 5, [config]);

  const isNigerian = useMemo(() => toLower(profile?.country) === "nigeria", [profile]);

  const canAgree = useMemo(() => !!shouldConsent && !!setAgreement, [shouldConsent, setAgreement]);

  const cardCost = useMemo(() => {
    // if (firstRequestCheck && firstRequestCheck?.firstTime) return 0;
    // if (firstRequestCheck && firstRequestCheck?.fee) return firstRequestCheck?.fee;
    return 3;
  }, []);

  const handleAgreement = () => {
    if (canAgree) setAgreement!();
  };

  const [tabIndex, changeTab] = useState(0);

  const handleTabChange = (index: number) => {
    changeTab(index);
  };

  const terms = useMemo(() => {
    const enabled_prov = commaStrToArr(cardConfig?.enabledCreateProviders ?? "p3");

    const map: Record<string, { label: string; component: any }> = {
      p3: { label: "Mastercard", component: MastercardP3Terms },
      p4: { label: "Visa", component: VisaP4CardTerms },
      p5: { label: "Visa II", component: VisaP5CardTerms },
    };

    const providers = Object.keys(map)
      .filter((key) => enabled_prov.includes(key))
      .map((key) => map[key]);
    return providers;
  }, [cardConfig]);

  console.log("Terms", terms);

  return (
    <AbstractModal {...props}>
      <VStack px="60px" my="42px">
        <Box as={CreditCardIllustrationSVG} />
        <VStack my="24px !important" color={switchStyle(colorMode, { dark: "secondary.400", light: "primary.700" })}>
          <Heading as="h4" fontSize="24px" color="inherit">
            Bitmama Virtual Cards
          </Heading>
          <Text mt="14px !important" textDecoration="underline" fontFamily="var(--bitmama-fonts-heading)" color="inherit">
            Terms & conditions
          </Text>
        </VStack>

        {terms.length === 1 &&
          terms.map(({ component: Comp }, idx) => <Comp {...{ isNigerian, cardCost, creationFee, minimumAmount }} />)}

        {terms.length > 1 && (
          <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange}>
            <TabList maxW="container.sm" borderRadius="8px" justifyContent="space-between">
              {/* <Tab>VISA</Tab> */}
              {/* <Tab>Mastercard</Tab> */}
              {terms.map((term, idx) => (
                <Tab key={`tab-${idx}`}>{term.label}</Tab>
              ))}
            </TabList>
            <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
              {terms.map(({ component: Comp }, idx) => (
                <TabPanel key={`panel-${idx}`} px={["2", "0", "0", "1"]}>
                  <Comp {...{ isNigerian, cardCost, creationFee, minimumAmount }} />
                </TabPanel>
              ))}
              {/* <TabPanel px={["2", "0", "0", "1"]}>
                <VisaP5CardTerms {...{ isNigerian, cardCost, creationFee, minimumAmount }} />
              </TabPanel>
              <TabPanel px={["0", "0", "0", "1"]}>
                <MastercardP3Terms {...{ isNigerian, cardCost, creationFee, minimumAmount }} />
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        )}

        {!canAgree && (
          <Button mt="42px !important" onClick={props.onClose}>
            Close
          </Button>
        )}
        {canAgree && (
          <VStack mt="42px !important" px="46px" w="100%">
            <Button minW="unset" w="100%" onClick={handleAgreement}>
              Agree
            </Button>
            <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={props.onClose}>
              Close
            </Button>
          </VStack>
        )}
      </VStack>
    </AbstractModal>
  );
}

function VisaP5CardTerms(props: any) {
  // const { isNigerian, cardCost, creationFee, minimumAmount } = props;
  return (
    <List>
      <Item>You need to create a card profile to create a card. Don’t worry😓. It’s a one time thing😉</Item>
      <Item>You can create up to three (3) cards</Item>
      <Item>First card creation fee is $5 while the subsequent card creation fee is free</Item>
      <Item>2% or a minimum of $1 fee for every funding on your card</Item>
      <Item>
        For transactions in other currencies other than USD, a 1% of transaction amount + $0.30 is charged as conversion fee
      </Item>
      <Item>After three failed transactions due to insufficient funds, your card is frozen automatically</Item>
    </List>
  );
}

function VisaP4CardTerms(props: any) {
  const { isNigerian, cardCost, creationFee, minimumAmount } = props;
  return (
    <List>
      {/* <Item>Available to only Nigerian users</Item> */}
      {isNigerian && <Item>NIN (National Identification Number) is needed to create your card for compliance reasons</Item>}
      <Item>2% or a minimum of $1 fee for every funding on card </Item>
      {/* <Item>Maximum funding amount is $10,000</Item> */}
      <Item>Card creation fee is ${cardCost ?? creationFee} which includes the maintenance fee for the first month</Item>
      {/* <Item>Minimum amount of funding on card is ${minimumAmount}</Item> */}
      <Item>Card funding at card creation is a static amount of $5 while subsequent funding is a minimum of ${minimumAmount}</Item>
      <Item>
        Monthly maintenance fee of $0.42 applies on each card you create. This is due at the end of the month effective from the
        subsequent month of card creation
      </Item>
      {/* <Item>Maximum amount of card holding is $10,000</Item> */}
      <Item>
        Card creation is limited to a maximum of 5. This includes both active and frozen cards but excludes deleted cards.
      </Item>
      <Item>A conversion fee of 3.2% is charged on transactions in currencies other than USD with a $0.50 charge</Item>
    </List>
  );
}

function MastercardP3Terms(props: any) {
  const { isNigerian, cardCost, creationFee, minimumAmount } = props;
  return (
    <List>
      {/* <Item>Available to only Nigerian users</Item> */}
      {isNigerian && <Item>NIN (National Identification Number) is needed to create your card for compliance reasons</Item>}
      <Item>2% or a minimum of $1 fee for every funding on card </Item>
      {/* <Item>Maximum funding amount is $10,000</Item> */}
      <Item>Card creation fee is ${cardCost ?? creationFee} which includes the maintenance fee for the first month</Item>
      <Item>Minimum amount of funding on card is ${minimumAmount}</Item>
      <Item>
        Monthly maintenance fee of $0.42 applies on each card you create. This is due at the end of the month effective from the
        subsequent month of card creation
      </Item>
      {/* <Item>Maximum amount of card holding is $10,000</Item> */}
      <Item>
        Card creation is limited to a maximum of 5. This includes both active and frozen cards but excludes deleted cards.
      </Item>
      <Item>A conversion fee of 3.2% is charged on transactions in currencies other than USD with a $0.50 charge</Item>
    </List>
  );
}

interface ItemProps extends ListItemProps {}

function Item(props: ItemProps) {
  const { children } = props;
  return (
    <ListItem my="14px">
      <HStack alignItems="flex-start">
        <Icon type="filledCards" transform="rotate(-27deg)" minW="34px" />
        <Text fontWeight="500">{children}</Text>
      </HStack>
    </ListItem>
  );
}
