import { useMemo, useState } from "react";
import { Box, Button, HStack, Text } from "@chakra-ui/react";
import {
  navigate,
  // useLocation
} from "@reach/router";
import { format, parseISO } from "date-fns";
import { iPhoneSVG } from "assets";
import {
  Card,
  CardProps,
  CoinInfo,
  CoinInfoList,
  Group,
  Icon,
  PageLoading,
  Paginator,
  Title,
  TitleBar,
  TitleBarActions,
} from "components";
import { useDefaultStyle } from "hooks";
import { useGetAffiliateEarningsQuery, useGetAffiliateEarningSummaryQuery } from "apis";
import { toPrecision, toQueryString } from "utils";

interface HistoryRewardPanelProps {}

export default function HistoryRewardPanel(props: HistoryRewardPanelProps) {
  // const location = useLocation();

  // The ?${params.toString()} part in the `onClick` event is needed so we can
  // preserve the current tab from screen to screen and back.
  // const params = useMemo(() => new URLSearchParams(location?.search), [location]);

  const handleClick = (id: string | number) => {
    // if (params.has("tab")) {
    //   navigate(`/account/partners/history/${id}?${params.toString()}`);
    //   return;
    // }
    navigate(`/account/partners/history/reward/${id}`);
    return;
  };

  const { data: summaryData, isLoading } = useGetAffiliateEarningSummaryQuery({});

  const combinedEarns = useMemo(() => summaryData?.combinedEarns, [summaryData]);

  const bonuses = useMemo(() => {
    const keys = Object.keys(combinedEarns ?? {});
    return keys?.map((key) => ({ coin: key, bonus: combinedEarns[key] }));
  }, [combinedEarns]);

  const [state, setState] = useState({
    page: 1,
    limit: 5,
  });

  const queries = useMemo(
    () =>
      toQueryString({
        ...state,
      }),
    [state]
  );

  const { data } = useGetAffiliateEarningsQuery(queries);

  return (
    <Group mt="22px !important" gridGap="4px" pos="relative">
      {isLoading && <PageLoading isLoading={isLoading} />}

      {!isLoading && (
        <CoinInfoList isLoading={isLoading}>
          {bonuses.map(({ bonus, coin }) => (
            <CoinInfo w="100%" key={`coininfo-${coin}`} coin={coin as any} anotherValue={bonus} />
          ))}
        </CoinInfoList>
      )}

      <Group mt="52px !important" mb="35px">
        {data && data.earnings.length > 0 && (
          <>
            <TitleBar mb="28px">
              <Title>Earnings</Title>
              <TitleBarActions>
                <Button variant="link" fontSize="16px" color="secondary.400">
                  See All
                </Button>
              </TitleBarActions>
            </TitleBar>
            {data?.earnings.map(({ _id, unit, createdAt, user, description, referredUserId, signedValue }) => (
              <PartnerHistoryItem
                key={`history-item-${_id}`}
                onClick={() => handleClick(_id)}
                fullName={referredUserId.fullName}
                createdAt={createdAt}
                description={description}
                amount={signedValue.$numberDecimal}
                unit={unit}
              />
            ))}

            <Paginator
              mt="40px !important"
              totalCount={data?.totalCount}
              page={state.page}
              limit={state.limit}
              onPageChange={(page: number) => setState({ ...state, page })}
            />
          </>
        )}
      </Group>
    </Group>
  );
}

interface PartnerHistoryItemProps extends CardProps {
  fullName: string;
  createdAt: string;
  description: string;
  unit: string;
  amount: string;
}

function PartnerHistoryItem(props: PartnerHistoryItemProps) {
  const { shadow, borderColor } = useDefaultStyle();
  const { fullName, amount, unit, createdAt, description } = props;

  return (
    <Card
      as="button"
      w="100%"
      px="34px"
      py="10px"
      border="1px solid transparent"
      _focus={{ shadow, borderColor }}
      mb="10px !important"
      {...props}
    >
      <Box pos="relative">
        <HStack>
          <Text fontSize="14px" fontWeight="500">
            {fullName}
          </Text>
        </HStack>

        <Box textAlign="left">
          <Text fontSize="14px" fontWeight="500">
            <Text as="span" color="secondary.500">
              {toPrecision(Number(amount), unit, true, 8, true)}
            </Text>{" "}
            {description}
          </Text>
        </Box>

        <HStack mt="">
          <Box as={iPhoneSVG} />
          <Text fontSize="14px" fontWeight="500">
            {format(parseISO(createdAt), "dd-MM-yyyy")}
          </Text>
        </HStack>

        <Icon type="arrowRight" pos="absolute" right="0px" top="50%" transform="translateY(-50%)" />
      </Box>
    </Card>
  );
}
