import { useState, useMemo } from "react";
import { ButtonProps } from "@chakra-ui/react";

interface useStepConfig {
  nextButtonProps?: ButtonProps[];
  backButtonProps?: ButtonProps[];
}

export default function useStep(max = 1, config: useStepConfig = {}, restart = false) {
  // const _direction = useRef<number>(1);
  const [currentStep, setCurrentStep] = useState(0);

  const [direction, setDirection] = useState(1);

  // console.log("SOL", max, config);

  const onNext = () => {
    if (currentStep < max - 1) {
      setCurrentStep(currentStep + 1);
      // _direction.current = 1;
      setDirection(1);
    } else {
      if (restart) setCurrentStep(0);
      // _direction.current = -1;
      setDirection(-1);
    }
  };

  const onPrev = () => {
    if (currentStep <= max - 1 && currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      if (restart) setCurrentStep(max - 1);
    }

    // _direction.current = -1;
    setDirection(-1);
  };

  const setStep = (step: number) => {
    if (step > currentStep) setDirection(1);
    if (step < currentStep) setDirection(-1);
    setCurrentStep(step);
  };

  const hasPrev = useMemo(() => currentStep <= max - 1 && currentStep > 0, [currentStep, max]);
  const hasNext = useMemo(() => currentStep + 1 <= max - 1, [currentStep, max]);

  const nextButtonProps = useMemo(() => config?.nextButtonProps && config.nextButtonProps[currentStep], [currentStep, config]);

  const backButtonProps = useMemo(() => config?.backButtonProps && config.backButtonProps[currentStep], [currentStep, config]);

  return {
    currentStep,
    hasPrev,
    hasNext,
    onNext,
    onPrev,
    setCurrentStep: setStep,
    nextButtonProps,
    backButtonProps,
    // direction: _direction.current,
    direction,
  };
}
