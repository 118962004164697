import { Box /*, Stack */, TabList, TabPanel, Tabs, useColorModeValue, Tab } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { Group, MainLayoutContainer, TabPanels, Title, TitleBar } from "components";
import configs from "config";
import { SidePageViewProps, useSidePage } from "contexts";
import { useCoin } from "hooks";
import { useState } from "react";
import StakingFeature from "ui/Earn/Features/Staking";
import { AffiliateWidget } from "./AffiliateWidget";
// import EarnSubPage from "ui/Earn/Panels/EarnSubPage";
import CryptoOverview from "./CryptoOverview";

interface CryptoPanelsTabsProps {
  coin: string;
  context?: string;
}

interface CryptoPanelsProps extends SidePageViewProps {
  context?: string;
}

export default function CryptoPanels(props: CryptoPanelsProps) {
  const { context } = props;

  const { params } = useSidePage();
  const { label } = useCoin(params?.coin ?? ("btc" as any));

  return (
    <Box px={{ base: "0", "1sm": "32px" }} {...props} overflowY="scroll">
      <TitleBar px={{ base: "20px", "1sm": "0" }}>
        <Title fontSize="20px">{label} Wallet</Title>
      </TitleBar>

      <MainLayoutContainer maxW="2xl" mt="28px">
        <Group px="0" mt="0">
          <CryptoTabs coin={params?.coin as any} context={context} />
        </Group>
      </MainLayoutContainer>
    </Box>
  );
}

function CryptoTabs(props: CryptoPanelsTabsProps) {
  const { coin, context } = props;
  const location = useLocation();

  const searchParams = new URLSearchParams(location?.search as string);

  const tabMap = {
    overview: 0,
    bcpl: 1,
    affiliates: 2,
    earn: 3,
  };

  const [tabIndex, changeTab] = useState((tabMap as any)[searchParams.get("subpage")?.split(":")[1] as any] ?? 0);

  const handleTabChange = (index: number) => {
    changeTab(index);
    const key = context ?? "Crypto";
    searchParams.set("subpage", `${key}:${Object.keys(tabMap)[index]}`);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <Tabs variant="ghost" index={tabIndex} onChange={handleTabChange}>
      <TabList borderRadius="8px" justifyContent="space-evenly">
        <CryptoTab>Overview</CryptoTab>
        {/* <CryptoTab>BCPL</CryptoTab> */}
        <CryptoTab>Affiliates</CryptoTab>
        {configs.supportedStakableCoins.includes(coin) && <CryptoTab>Earn</CryptoTab>}
      </TabList>

      <TabPanels index={tabIndex} onChangeIndex={handleTabChange}>
        <TabPanel px={{ base: "0", "1sm": "24px", "2sm": "32px", "4sm": 4 }}>
          <CryptoOverview coin={coin} setCoin={() => {}} />
        </TabPanel>
        {/* <TabPanel px={{ base: "0", "1sm": "24px", "2sm": "32px", "4sm": "48px" }}>
          <Wallet w="100%" minW="unset" maxW="unset" currency={coin as any} label="Loan Wallet" scheme="loan" />
        </TabPanel> */}
        <TabPanel px={{ base: "0", "1sm": "24px", "2sm": "32px", "4sm": 4 }}>
          <AffiliateWidget coin={coin} />
        </TabPanel>
        {configs.supportedStakableCoins.includes(coin) && (
          <TabPanel px={{ base: "0", "1sm": "24px", "2sm": "32px", "4sm": 4 }}>
            <StakingFeature mt="22px" px="0" _staking={{ px: "0" }} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}

function CryptoTab({ children, ...xprops }: any) {
  const selectColor = useColorModeValue("secondary.500", "primary.500");
  return (
    <Tab
      padding={{ base: 0, "1sm": "inherit" }}
      color="grey.500"
      willChange="font-weight"
      _selected={{ color: selectColor, fontWeight: "600", textDecoration: "underline" }}
      {...xprops}
    >
      {children}
    </Tab>
  );
}
