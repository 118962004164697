import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import { useGetEarnWalletQuery, useGetStakingPlansQuery, useStakeMutation, useCalcReturnMutation } from "apis";
import { SubmitPinModal, SuccessModal } from "ui";
import {
  DetailItem,
  FormInfo,
  Icon,
  InputLabel,
  MainLayoutContainer,
  PageMotion,
  Steps,
  Topbar,
  useModalView as useSteps,
} from "components";
import configs from "config";
import { SidePageProvider } from "contexts";
import { useBuiltInFn, useDisclosures, usePartialState } from "hooks";
import { AppAnalyticEvent, CalcReturnsRo, PageProps } from "interfaces";
import { capitalize } from "lodash";
import { useMemo } from "react";
import { toCoinLocale, toPrecision, when } from "utils";
import { Emitter } from "libs";

interface SubscribePageProps extends PageProps {}

interface FormProps extends SubscribePageProps {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  params: URLSearchParams;
}

interface IState {
  coin: string;
  subType: string;
  amount: string;
  pin: string;
  duration: string;
  autoRollover: string;

  // returns state is set before going to the next(confirmation) page.
  returns: CalcReturnsRo;
}

type ModalTypes = "pin" | "success";

export default function SubscribePage(props: SubscribePageProps) {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location?.search), [location]);

  const [state, set] = usePartialState<IState>({
    coin: params.get("coin") ?? undefined,
    subType: params.get("type") ?? undefined,
    autoRollover: "no",
  });

  //  const apr = useMemo(() => +returns?.apr, [returns]);

  //  const fee = useMemo(() => {
  //    const reward = (+(state?.amount ?? 0) * (apr * +(state?.duration ?? 0))) / 365;
  //    if (returns?.fee?.factor === "percentage") return reward * (+returns?.fee?.rate / 100);
  //    if (returns?.fee?.factor === "fixed") return +returns?.fee?.fixed;
  //    return 0;
  //  }, [state?.amount, apr, state?.duration, returns?.fee]);

  //  const rewards = useMemo(
  //    () => (+(state?.amount ?? 0) * (apr * +(state?.duration ?? 0))) / 365 - fee,
  //    [state?.amount, apr, state?.duration, fee]
  //  );

  return (
    <PageMotion key="Subscribe">
      <Topbar pageTitle="Earn" {...props} />

      <MainLayoutContainer pb="60px">
        <VStack alignItems="flex-start">
          <Box maxW="container.sm" w="100%" pr={{ base: "40px", "2sm": "80px" }}>
            <SidePageProvider>
              <Box w="100%">
                <Steps hideIndicator>
                  <Form state={state} set={set} params={params} />
                  <Confirm state={state} set={set} params={params} />
                </Steps>
              </Box>
            </SidePageProvider>
          </Box>
        </VStack>
      </MainLayoutContainer>
    </PageMotion>
  );
}

function Form(props: FormProps) {
  const { state, set } = props;

  const { hasNext, onNext } = useSteps();
  const { goBack } = useBuiltInFn();

  const stroke = useColorModeValue("primary.600", "secondary.200");

  const coin = useMemo(() => {
    const map = {
      [state?.coin ?? "tbtc"]: state?.coin ?? "btc",
      tbtc: "btc",
      teth: "eth",
    };

    return map[state?.coin ?? "tbtc"];
  }, [state?.coin]);

  const { data: wallets } = useGetEarnWalletQuery({});
  const { data: plans, isLoading: isLoadingPlans } = useGetStakingPlansQuery({ skip: !state?.coin });

  const [_calcReturns, { isLoading: isCalculating }] = useCalcReturnMutation();

  const plan = useMemo(() => (!!plans ? plans[coin] : undefined), [plans, coin]);

  const selectedWallet = useMemo(
    () => (!!state?.coin ? wallets?.find((w) => w?.unit === state?.coin) : undefined),
    [state?.coin, wallets]
  );

  const balance = useMemo(() => selectedWallet?.balance?.$numberDecimal ?? 0, [selectedWallet]);

  const isInsufficientBalance = useMemo(() => !!state?.amount && +state?.amount > +balance, [state?.amount, balance]);

  const isLessThanMinimum = useMemo(
    () => !!state?.amount && !!plan?.minimum && +state?.amount < plan?.minimum,
    [state?.amount, plan]
  );

  const handleBack = () => {
    goBack();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const result = await _calcReturns({
        amount: state?.amount as string,
        coin: state?.coin as string,
        duration: +(state?.duration ?? 0),
        flexible: state?.subType !== "fixed",
        autoRenew: state?.autoRollover === "yes",
      }).unwrap();

      console.log("Result", result);
      if (!!result) {
        set({ returns: result });
        !!hasNext && onNext();
      }
    } catch (error) {}

    // if (hasNext) onNext();
  };

  const isDisabled = useMemo(
    () =>
      !(state?.coin && state?.duration && state?.autoRollover && state?.amount && state?.subType) ||
      isCalculating ||
      isInsufficientBalance ||
      isLessThanMinimum,
    [state, isInsufficientBalance, isLessThanMinimum, isCalculating]
  );

  return (
    <Box p="4px" mb="42px">
      <Button
        size="sm"
        minW="fit-content"
        maxW="fit-content"
        variant="link"
        leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
        onClick={handleBack}
        px="2px !important"
      >
        Back
      </Button>

      <HStack justifyContent="space-between">
        <Text mt="12px" as="h4" fontSize="24px" fontWeight="bold" fontFamily="var(--bitmama-fonts-heading)">
          Staking
        </Text>
      </HStack>

      <Stack mt="44px !important" as="form" onSubmit={handleSubmit}>
        <FormControl mb="40px !important">
          <InputLabel fontSize="16px">Coin</InputLabel>
          <Select
            placeholder="Select Coin"
            value={state?.coin ?? ""}
            onChange={(e) => {
              set({ coin: e.target.value });
              navigate(`/earn/staking/subscribe?coin=${e.target.value}`, { replace: true });
            }}
          >
            {configs.supportedStakableCoins.map((coin) => (
              <option key={`stakable-coin-${coin}`} value={coin}>
                {toCoinLocale(coin)}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl mb="40px !important">
          <InputLabel isLoading={isLoadingPlans} fontSize="16px">
            Subscription Type
          </InputLabel>
          <Select placeholder="Select Type" value={state?.subType ?? ""} onChange={(e) => set({ subType: e.target.value })}>
            {plan?.supported?.map((type: string) => (
              <option key={`subscription-type-${type}`} value={type}>
                {capitalize(type)}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl mb="40px !important">
          <HStack justifyContent="space-between">
            <InputLabel fontSize="16px">Amount</InputLabel>

            {state?.coin && !isNaN(+balance) && (
              <FormInfo info="Earn Wallet balance: " description={toPrecision(+balance, state?.coin!)} color="primary.900" />
            )}
          </HStack>
          <Input
            type="number"
            // min={plan?.minimum ?? 0}
            isInvalid={isInsufficientBalance}
            placeholder="Enter amount"
            value={state?.amount ?? ""}
            onChange={(e) => set({ amount: e.target.value })}
          />

          {state?.coin && plan?.minimum && (
            <FormHelperText fontWeight="500" color={when(isLessThanMinimum, "error", "gray.500")}>
              Minimum Amount to stake: {toPrecision(plan?.minimum ?? 0, state?.coin)}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl mb="40px !important">
          <HStack justifyContent="space-between">
            <InputLabel isLoading={isLoadingPlans} fontSize="16px">
              {when(state?.subType === "fixed", "Duration", "Duration (days)")}
            </InputLabel>
          </HStack>

          {state?.subType === "fixed" && (
            <Select placeholder="Select Duration" value={state?.duration ?? ""} onChange={(e) => set({ duration: e.target.value })}>
              {plan?.tenor?.map((tenor) => (
                <option key={`tenor-${tenor}`} value={tenor}>
                  {tenor} days
                </option>
              ))}
            </Select>
          )}

          {state?.subType === "flexible" && (
            <>
              <Input
                min={1}
                max={365}
                step={1}
                placeholder="Enter duration"
                value={state?.duration ?? ""}
                onChange={(e) =>
                  set({
                    duration: `${isNaN(Math.ceil(+e.target.value)) ? 1 : Math.ceil(+e.target.value)}`,
                  })
                }
              />
              <FormHelperText fontWeight="500">Must be a value between 1 and 365</FormHelperText>
            </>
          )}

          {/* <FormHelperText fontWeight="bold">Possible reward: 0.00345BTC</FormHelperText> */}
        </FormControl>

        <FormControl mb="40px !important">
          <HStack justifyContent="space-between">
            <InputLabel fontSize="16px">Automatic Rollover</InputLabel>
          </HStack>
          <Select value={state?.autoRollover ?? ""} onChange={(e) => set({ autoRollover: e.target.value })}>
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </Select>
        </FormControl>

        <FormInfo
          info="Note: "
          description="Indicate if this subscription should rollover automatically when the selected duration ends. This means that your new principal will be old principal plus earned interest"
          _wrapper={{ alignItems: "flex-start" }}
          _info={{ color: "red.500", fontWeight: "bold" }}
          _description={{ color: "black", fontWeight: "500", fontStyle: "italic" }}
        />

        <VStack mt="46px !important">
          <Button
            // maxW="320px"
            minW={{ base: "100%", "1sm": "330px" }}
            type="submit"
            fontFamily="var(--bitmama-fonts-heading)"
            disabled={isDisabled}
            isLoading={isCalculating}
          >
            Subscribe
          </Button>
        </VStack>
      </Stack>
    </Box>
  );
}

function Confirm(props: FormProps) {
  const { state, set, params } = props;
  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const { hasPrev, onPrev } = useSteps();
  const stroke = useColorModeValue("primary.600", "secondary.200");

  const [_stake, { isLoading, isSuccess }] = useStakeMutation();

  const stake = async (pin: string) => {
    const reqData = {
      amount: +(state?.amount ?? 0),
      coin: state?.coin,
      pin: pin ?? state?.pin,
      duration: +(state?.duration ?? 0),
      flexible: state?.subType === "flexible" ? true : false,
      autoRenew: state?.autoRollover === "yes" ? true : false,
    };

    Emitter.emit(AppAnalyticEvent.EARN_STAKING_INITIATED, reqData);
    const result = await _stake({ ...reqData }).unwrap();
    if (!!result) Emitter.emit(AppAnalyticEvent.EARN_STAKING_SUBMITTED, reqData);
  };

  const apr = useMemo(() => Number(+(state?.returns?.apr ?? 0)).toFixed(2), [state?.returns]);

  const fee = useMemo(() => {
    if (state?.returns?.fee?.factor === "percentage") return +(state?.amount ?? 0) * (+(state?.returns?.fee?.rate ?? 0) / 100);
    else if (state?.returns?.fee?.factor !== "percentage") return +(state?.returns?.fee?.rate ?? 0);
    else return 0;
  }, [state]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    open("pin")();
  };

  const handleContinue = () => {
    navigate(`/earn/earnings/${params.get("coin")}`);
  };

  useUpdateEffect(() => {
    if (isSuccess) {
      open("success")();
    }
  }, [isSuccess, state?.coin]);

  return (
    <Box p="4px" mb="42px" w="100%">
      <Button
        size="sm"
        minW="fit-content"
        maxW="fit-content"
        variant="link"
        leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
        onClick={() => !!hasPrev && onPrev()}
        px="2px !important"
      >
        Back
      </Button>

      <HStack justifyContent="space-between">
        <Text mt="12px" as="h4" fontSize="24px" fontWeight="bold" fontFamily="var(--bitmama-fonts-heading)">
          Confirmation
        </Text>
      </HStack>

      <Box as="form" w="100%" onSubmit={handleSubmit}>
        <HStack gridGap="18px">
          <DetailItem title="Coin Type" description={toCoinLocale(state?.coin ?? "btc")} />
          <DetailItem title="Annual Percentage Rate" description={`${+apr * 100}%`} />
        </HStack>

        <HStack gridGap="18px">
          <DetailItem title="Duration" description={`${state?.duration} days`} />
          <DetailItem title="Type" description={state?.subType ?? "fixed"} _description={{ textTransform: "uppercase" }} />
        </HStack>

        <HStack gridGap="18px">
          <DetailItem title="Automatic Rollover" description={state?.autoRollover} _description={{ textTransform: "uppercase" }} />
          <DetailItem title="Reward" description={toPrecision(state?.returns?.expectedReturns ?? 0, state?.coin ?? "btc")} />
        </HStack>
        <HStack gridGap="18px">
          <DetailItem flex="0.5" title="Fee Charge" description={toPrecision(fee, state?.coin ?? "btc")} />
        </HStack>

        <VStack mt="46px !important">
          <Button
            // maxW="320px"
            minW={{ base: "100%", "1sm": "330px" }}
            type="submit"
            fontFamily="var(--bitmama-fonts-heading)"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </VStack>
      </Box>

      <SubmitPinModal
        isOpen={isOpen("pin")}
        onClose={close("pin")}
        onPin={(pin) => set({ pin })}
        onSubmit={(pin) => {
          stake(pin);
          close("pin")();
        }}
        onComplete={(pin) => {
          stake(pin);
          close("pin")();
        }}
      />

      <SuccessModal
        isOpen={isOpen("success")}
        onClose={close("success")}
        onContinue={handleContinue}
        message={`You've successfully staked ${toPrecision(+(state?.amount ?? 0), state?.coin!)}`}
      />
    </Box>
  );
}
