import { useCallback } from "react";

export default function useIsPasswordValid(value: string) {
  const passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  const isPasswordValid = useCallback(
    () => value && RegExp(passwordPattern).test(value),
    // eslint-disable-next-line
    [value]
  );

  return isPasswordValid;
}
