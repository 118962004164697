import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  // FormHelperText,
  Grid,
  Heading,
  HStack,
  // Image,
  Stack,
  Text,
  TextProps,
  useColorMode,
  useColorModeValue,
  useUpdateEffect,
  VStack,
} from "@chakra-ui/react";
import {
  AmountInput,
  Card,
  CircularLoader,
  ConditionalRender,
  FormInfo,
  Icon,
  InputLabel,
  Link,
  MainLayoutContainer,
  PeerCoins,
  Select,
  Steps,
  Username,
  useModalView as useSteps,
} from "components";

// import BitcoinIMG from "assets/images/bitcoin.png";
import Input from "components/Input/Input";
import AdPreviewModal from "../Modals/Ads/AdPreview";
import { useCoin, useColor, useDisclosures, useP2PBestRates, usePartialState, useSelector, useWalletBalance } from "hooks";
import { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { currencyFormat, switchStyle, toCoinLocale, toPrecision, when } from "utils";
import { isTesting } from "config";

import omit from "lodash/omit";
import toLower from "lodash/toLower";
import toUpper from "lodash/toUpper";
import { useCreateBuyListingMutation, useGetCoinToUsdRateQuery, useGetRateQuery, useGetSavedBanksQuery } from "apis";
import { AppAnalyticEvent, CoinToUSD, PageProps } from "interfaces";
import { selectUser } from "store/slices";
import { ConfirmationModal, SuccessModal } from "ui";
import { navigate, useLocation } from "@reach/router";

import isEqual from "lodash/isEqual";
import { useAppConfig } from "contexts/appconfig.context";
import { useCurrency } from "hooks/useCurrency";
import { Method } from "components/P2P/TableItem";
import { capitalize } from "lodash";
import Textarea from "components/Input/Textarea";
import { Emitter } from "libs";

interface EditableAdProps {
  initialState?: Partial<IState>;
  onSubmit?: (data: any) => Promise<void>;
  isLoading?: boolean;
  data?: any;
}

interface CreateAdFeatureProps extends PageProps, EditableAdProps {
  setPageInfo: (update: Partial<{ title: string; hide: boolean; onBack: () => void }>) => void;
}

interface FormProps extends IStateExtension, EditableAdProps {
  isCheckingCoinToUsdRate: boolean;
  unitCost: number;
  coinToUsdData?: CoinToUSD;
  setPageInfo: CreateAdFeatureProps["setPageInfo"];
}

interface ControlHubProps extends FormProps {}

export interface IStateExtension {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  reset: () => void;
}

interface IPaymentOption {
  method: string; // bankTransfer | changeraId - (left out for momentarily) | bitmamaWallet
  receivingIntoLabel: string; // same as bank name
  receivingIntoAddress: string; // same as account number;
  accountName: string; // bank account name
  saveRecord: boolean;
  payingToLabel: string; // same as bankName, used when state?.type is buy;
}

interface IState {
  type: string;
  coin: string;
  dynamic: boolean;
  priceType: string;
  price: string;

  methods: string[];
  volume: string;
  token: string;
  sourceUnit: string;
  destinationUnit: string;
  totalCost: string;
  acceptPartialFilling: boolean;
  useInternalWallet: boolean;
  country: string;
  location: string;
  minimumFilling: string;
  maximumFilling: string;
  unitCost: string;
  timeout: number;
  terms: string;
  instructions: string;

  selectedMethod: string;
  paymentOptions: IPaymentOption[];

  cantSaveRecord: boolean;
}

type ModalTypes = "confirm" | "success" | "preview";

const floatString = (n: string | number): number | string =>
  (n && (Number.isInteger(n) ? Number(n).toFixed(2) : n.toString())) ?? "";

const listingTypeMap: Record<string, "destinationUnit" | "sourceUnit"> = {
  buy: "destinationUnit",
  sell: "sourceUnit",
};

const sourceMap: Record<string, string> = {
  tbtc: "btc",
  btc: "btc",
  eth: "eth",
  teth: "eth",
  usdt: "usd",
  celo: "celo",
  cusd: "usd",
};

const dynamicsMap = (() => {
  const map: Record<string, "price" | "unitCost"> = {
    true: "price",
    false: "unitCost",
  };

  return map;
})();

// const mapSrcUnitField = (dict: any, type: string) => {
//   const buyMap: Record<string, "destinationUnit" | "sourceUnit"> = {
//     sell: "destinationUnit",
//     buy: "sourceUnit",
//   };
//   return dict[buyMap[type]];
// };

export default function CreateAdFeature(props: CreateAdFeatureProps) {
  const { initialState, onSubmit, isLoading, data, setPageInfo } = props;

  // const adType_cache = useRef("buy");
  const { search } = useLocation();
  const URLParams = useMemo(() => new URLSearchParams(search), [search]);
  const prevInitialState = useRef<typeof initialState>({});

  const currency = useCurrency();

  console.log("URL Params", URLParams.toString(), URLParams.get("adsType"));

  const [state, set, reset] = usePartialState<IState>(
    {
      type: "buy",
      methods: [],
      dynamic: true,
      priceType: "dynamic",
      coin: isTesting ? "tbtc" : "btc",
      token: isTesting ? "tbtc" : "btc",
      sourceUnit: currency ?? "ngn",
      destinationUnit: "btc",
      // totalCost: 0,
      acceptPartialFilling: true,
      useInternalWallet: false,
      timeout: 15,
      paymentOptions: [],

      ...initialState,
    },
    [currency]
  );

  const { data: coinToUsdData, isFetching: isCheckingCoinToUsdRate } = useGetCoinToUsdRateQuery(sourceMap[state?.token!], {
    skip: state?.token?.includes("usd"),
  });

  console.log("CreateAd State", state);

  const unitCost = useMemo(() => {
    if (state?.dynamic)
      return Number(
        (state?.token?.includes("usd") ? 1 : ((coinToUsdData?.exchangeRate as any) ?? {})[state?.type!]) * +(state?.price ?? 0)
      );
    return Number(state[dynamicsMap[String(state?.dynamic)]]);
  }, [state, coinToUsdData]);

  useEffect(() => {
    if (!isEqual(prevInitialState.current, initialState)) {
      set({ ...initialState });
    }
  }, [initialState, prevInitialState, set]);

  const formProps = useMemo(
    () => ({
      isCheckingCoinToUsdRate,
      unitCost,
      state,
      set,
      reset,
      coinToUsdData,
      onSubmit,
      isLoading,
      data,
    }),
    [isCheckingCoinToUsdRate, unitCost, state, set, reset, coinToUsdData, onSubmit, isLoading, data]
  );

  // const { handleType } = useFormHandlers({ ...props, ...(formProps as any) });

  useEffect(() => {
    if (!isEqual(prevInitialState.current?.type, state?.type))
      setPageInfo({ title: `Create ${capitalize(state?.type ?? "buy")} Ad` });
  }, [state?.type, prevInitialState.current?.type, setPageInfo]);

  // useEffect(() => {
  //   const ads_type_param = URLParams.get("adsType");
  //   if (!!ads_type_param && !isEqual(ads_type_param, state?.type)) {
  //     console.log("Param Info", { type: state?.type!, ads: ads_type_param });
  //     handleType(ads_type_param ?? state?.type!);
  //   }

  //   // eslint-disable-next-line
  // }, [URLParams.get("adsType")]);

  return (
    <Steps hideIndicator>
      <StepOne setPageInfo={setPageInfo} {...formProps} />
      <ListingPreview setPageInfo={setPageInfo} {...formProps} />
    </Steps>
  );
}

function StepOne(props: FormProps) {
  return (
    <Box w="100%">
      <ControlHub {...props} />

      <Grid
        my="40px"
        display={{ sm: "flex", md: "grid" }}
        flexDir={{ sm: "column", md: "inherit" }}
        templateColumns={{ sm: "1fr", "3sm": "repeat(2,1fr)", md: "repeat(2,1fr)" }}
        gap="22px"
      >
        <Form {...props} />
        <Details {...props} />
      </Grid>
    </Box>
  );
}

function ListingPreview(props: FormProps) {
  const { state, reset, unitCost, onSubmit, data: updatedData, setPageInfo } = props;
  const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const stroke = useColorModeValue("primary.600", "secondary.200");

  const { id, onPrev } = useSteps();
  // const { id, onPrev } = useSteps(undefined, "modal-view-0");
  console.log("Selected Context ID", id);
  const { profile } = useSelector(selectUser);

  const { mapSrcUnitField } = useFormValidationAndInfo(props);

  const totalCost = useMemo(() => Number(unitCost * +(state?.volume ?? 0)), [unitCost, state?.volume]);
  const [createBuy, { isLoading, data }] = useCreateBuyListingMutation();

  const handleConfirm = async () => {
    const reqData = { ...state, totalCost, country: profile?.country, paymentOption: state?.paymentOptions };

    const submit = !!onSubmit ? onSubmit : createBuy;
    try {
      if (!onSubmit) Emitter.emit(AppAnalyticEvent.P2P_AD_CREATION_INITIATED, reqData);
      const result: any = await submit(reqData);
      if (!!result) {
        if (!!result?.error) return;
        console.log("Result", result);
        open("success")();
        if (!onSubmit) Emitter.emit(AppAnalyticEvent.P2P_AD_CREATION_SUBMITTED, reqData);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleContinue = () => {
    reset();
    close("success")();
    navigate("/p2p");
  };

  const handlePrev = () => {
    onPrev();
    setPageInfo({ title: `Create ${capitalize(state?.type ?? "buy")} Ad`, onBack: () => navigate(-1), hide: false });
  };

  const currency = useMemo(() => mapSrcUnitField(state?.type!) ?? "ngn", [state?.type, mapSrcUnitField]);

  const conversion_pair = useMemo(
    () => `${toCoinLocale(mapSrcUnitField(state?.type!) ?? "btc")}/${toCoinLocale(!state?.dynamic ? state?.token! : "usd")}`,
    [state, mapSrcUnitField]
  );

  const total_volume_fiat_value = useMemo(() => {
    if (!!state?.dynamic) return totalCost;
    else return totalCost;
  }, [state, totalCost]);

  const exchange_range = useMemo(
    () =>
      `${toPrecision(+(state?.minimumFilling ?? 0), state?.token ?? "btc", false)} - ${toPrecision(
        +(state?.maximumFilling ?? 0),
        state?.token ?? "btc",
        true
      )}`,
    [state?.minimumFilling, state?.maximumFilling, state?.token]
  );

  return (
    <MainLayoutContainer maxW={["520px"]} m="0" px="0">
      <Box mb="42px">
        <Button
          size="sm"
          minW="fit-content"
          maxW="fit-content"
          variant="link"
          leftIcon={<Icon type="circleLeftArrow" color={stroke} />}
          // onClick={() => navigate(-1)}
          onClick={handlePrev}
          px="2px !important"
        >
          Back
        </Button>

        <Heading mt="12px" as="h4" fontSize="24px" fontWeight="bold">
          Preview {capitalize(state?.type ?? "Buy")} Ad
        </Heading>
      </Box>
      <Card p="45px 48px" w="100%">
        <DetailItem title={`${capitalize(state?.type)}er:`}>
          <HStack>
            <Username username={profile?.username ?? ""} dir="horizontal" />
          </HStack>
        </DetailItem>
        <DetailItem
          title="Price:"
          description={`${Number(unitCost).toLocaleString()} ${conversion_pair}`}
          _description={{ color: "accent.300" }}
        />
        <DetailItem title="Range:" description={`${exchange_range}`} />
        <DetailItem title="Available:" description={toPrecision(+(state?.volume ?? 0), state?.token ?? "btc")} />
        <DetailItem
          title={`Total Volume (${toCoinLocale(mapSrcUnitField(state?.type!) ?? "ngn")}):`}
          description={currencyFormat(currency as any).format(+(total_volume_fiat_value ?? 0))}
        />
        <DetailItem title="Location:" description={profile?.country ?? ""} />
        <DetailItem title="Payment Method:">
          <HStack>
            {([state?.selectedMethod] ?? []).map((option, i) => (
              <Method key={`p2p-payment-method-${i}`}>{option}</Method>
            ))}
          </HStack>
        </DetailItem>
        <DetailItem
          title="Payment Window:"
          description={when(state?.selectedMethod === "bitmamaWallet", "Instant", `${state?.timeout} minutes`)}
          borderBottomColor={when(!state?.terms, "transparent", "inherit")}
        />
        {!!state?.terms && (
          <DetailItem
            flexDir="column"
            alignItems="flex-start"
            title="Terms and Conditions:"
            description={state?.terms}
            _description={{ ml: "0 !important" }}
            borderBottomColor="transparent"
          />
        )}

        <ConditionalRender shouldRender={state?.selectedMethod !== "bitmamaWallet"}>
          <FormInfo
            isInvalid
            mt="50px"
            info={`Please ensure payment is made within ${state?.timeout}:00 mins, else transaction would be cancelled.`}
            leftElement={<Icon type="infoErrorRounded" boxSize="30px" />}
            _lElement={{ mr: "4px" }}
          />
        </ConditionalRender>
      </Card>

      <VStack my="62px">
        <Button isLoading={isLoading} disabled={isLoading} onClick={open("confirm")} fontFamily="var(--bitmama-fonts-heading)">
          Proceed to Publish
        </Button>
        <Button variant="transparent" textDecoration="underline" onClick={handlePrev}>
          Cancel
        </Button>
      </VStack>

      <ConfirmationModal isOpen={isOpen("confirm")} onClose={close("confirm")} onConfirm={handleConfirm}>
        <VStack py="40px">
          <Text fontWeight="500">Are you sure you want to proceed?</Text>
        </VStack>
      </ConfirmationModal>

      <SuccessModal
        isOpen={isOpen("success")}
        onClose={close("success")}
        onContinue={handleContinue}
        message={(data ?? updatedData)?.message ?? ""}
      />
    </MainLayoutContainer>
  );
}

function Details(props: FormProps) {
  const { state, set, reset, onSubmit, isLoading: isSubmitting, setPageInfo } = props;

  const { onNext, onPrev } = useSteps();
  const { colorMode } = useColorMode();

  // const { goBack } = useBuiltInFn();
  const { isOpen, open, close } = useDisclosures<ModalTypes>();

  const { bankInfo, handleMethod, handleBankInfo, isDisabled } = useFormHandlers({ ...props });

  const { data: _savedBanks, isLoading: isBanksLoading } = useGetSavedBanksQuery(null, { skip: state?.type !== "sell" });
  const savedBanks = useMemo(() => _savedBanks?.banks ?? [], [_savedBanks]);

  const ckbScheme = useColorModeValue("primary", "secondary");

  const handleSelectedAccount = (bankId: string) => {
    const bank = savedBanks?.find((bank) => String(bank._id) === String(bankId));
    console.log("Saved Bank", bank);
    handleMethod("bankTransfer");
    handleBankInfo({
      accountName: bank?.bankAccountName,
      receivingIntoLabel: bank?.bankName,
      receivingIntoAddress: bank?.bankAccountNumber,
      saveRecord: !!bank?.rawData?.saveRecord,
    });
    // set({ cantSaveRecord: true });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleNext = () => {
    onNext();
    setPageInfo({ title: `Preview ${capitalize(state?.type ?? "buy")} Ad`, onBack: onPrev, hide: true });
  };

  return (
    <Card
      as="form"
      w="100%"
      p={{ sm: "20px", "2sm": "44px 48px", "3sm": "44px 48px", md: "44px 48px" }}
      borderRadius={{ sm: "8px", md: "16px" }}
      onSubmit={handleSubmit}
    >
      <Heading as="h6" fontSize="20px" fontWeight="600" mb="38px">
        Payment
      </Heading>
      <FormControl mb="34px">
        <InputLabel>Payment Method</InputLabel>
        <Select
          placeholder="Select Payment Method"
          value={state?.selectedMethod ?? ""}
          onChange={(e) => handleMethod(e.target.value)}
        >
          <option value="bitmamaWallet">Bitmama Wallet</option>
          {state?.type === "sell" && <option value="selectAccounts">Select from saved accounts</option>}
          <option value="bankTransfer">Bank Transfer</option>
        </Select>
      </FormControl>

      <ConditionalRender shouldRender={state?.selectedMethod === "bankTransfer" && state?.type !== "buy"}>
        <FormControl mb="34px">
          <InputLabel>Account Name</InputLabel>
          <Input
            placeholder="Enter Account Name"
            value={bankInfo?.accountName ?? ""}
            onChange={(e) => handleBankInfo({ accountName: e.target.value })}
          />
        </FormControl>

        <FormControl mb="34px">
          <InputLabel>Bank Name</InputLabel>
          <Input
            placeholder="Enter Bank Name"
            value={bankInfo?.receivingIntoLabel ?? ""}
            onChange={(e) => handleBankInfo({ receivingIntoLabel: e.target.value })}
          />
        </FormControl>

        <FormControl mb="34px">
          <InputLabel>Account Number</InputLabel>
          <Input
            placeholder="Enter Account Number"
            value={bankInfo?.receivingIntoAddress ?? ""}
            onChange={(e) => handleBankInfo({ receivingIntoAddress: e.target.value })}
          />

          {!state?.cantSaveRecord && (
            <Checkbox
              size="lg"
              mt="20px"
              colorScheme={ckbScheme}
              isChecked={bankInfo?.saveRecord ?? false}
              onChange={(e) => handleBankInfo({ saveRecord: e.target.checked })}
              sx={{
                ".chakra-checkbox__label": { fontSize: "16px" },
              }}
            >
              Save Record
            </Checkbox>
          )}
        </FormControl>
      </ConditionalRender>

      <ConditionalRender shouldRender={state?.selectedMethod === "selectAccounts" && state?.type !== "buy"}>
        <FormControl mb="34px">
          <InputLabel isLoading={isBanksLoading}>Select account</InputLabel>
          <Select
            placeholder="Select an account"
            value={state?.selectedMethod ?? ""}
            onChange={(e) => handleSelectedAccount(e.target.value)}
          >
            {savedBanks.map((bank, i) => (
              <option key={bank._id} value={bank?._id}>
                {bank.bankName} {bank?.bankAccountNumber}
              </option>
            ))}
          </Select>
        </FormControl>
      </ConditionalRender>

      <ConditionalRender shouldRender={state?.selectedMethod !== "bitmamaWallet"}>
        <FormControl mb="34px">
          <InputLabel>Payment Window</InputLabel>
          <Select
            placeholder="Select Payment Method"
            value={state?.timeout ?? ""}
            onChange={(e) => set({ timeout: +e.target.value })}
          >
            <option value="15">15 minutes</option>
            <option value="30">30 minutes</option>
          </Select>
        </FormControl>
      </ConditionalRender>

      <Box mt="80px" mb="38px">
        <Heading as="h6" fontSize="20px" fontWeight="600">
          Terms and Conditions
        </Heading>
        <Text fontSize="14px" mt="8px !important" fontWeight="500">
          Let people who interact with your Ad see your terms and conditions for this Ad
        </Text>

        <FormControl mt="14px">
          <Textarea
            minH="180px"
            minW="100%"
            placeholder="Set Terms and Conditions"
            value={state?.terms}
            onChange={(e) => set({ terms: e.target.value })}
          />
        </FormControl>
      </Box>

      <VStack mt="100px">
        <Button
          px="20px"
          mb="58px !important"
          variant="transparent"
          minW="fit-content"
          minH="64px"
          // bg={switchStyle(colorMode, { light: "#FAF3D4f6", dark: "#FAF3D4f6" })}
          bg="transparent"
          color={switchStyle(colorMode, { light: "primary.default", dark: "primary.default" })}
          borderRadius="16px"
          fontSize="14px"
          leftIcon={<Icon type="eyeFilled" color="primary.default" />}
          onClick={open("preview")}
          disabled={isDisabled}
        >
          Preview Ad
        </Button>
        <Button
          // size={{ sm: "sm", "2sm": "lg", "3sm": "lg", md: "lg" }}
          minW={{ sm: "100px", "2sm": "400px", "3sm": "400px", md: "400px" }}
          isLoading={isSubmitting}
          disabled={isDisabled || isSubmitting}
          onClick={handleNext}
        >
          {!!onSubmit ? "Update Ad" : `Create ${capitalize(state?.type ?? "buy")} Ad`}
        </Button>
        <Button
          // size={{ sm: "sm", "2sm": "lg", "3sm": "lg", md: "lg" }}
          minW={{ sm: "100px", "2sm": "400px", "3sm": "400px", md: "400px" }}
          variant="transparent"
          textDecoration="underline"
          onClick={() => reset()}
        >
          Clear
        </Button>
      </VStack>

      {/* <VStack py="200px">
        <Button
          px="20px"
          variant="transparent"
          minW="fit-content"
          minH="64px"
          bg={switchStyle(colorMode, { light: "#FAF3D4f6", dark: "#FAF3D4f6" })}
          color={switchStyle(colorMode, { light: "primary.default", dark: "primary.default" })}
          borderRadius="16px"
          fontSize="18px"
          rightIcon={<Icon type="eyeFilled" color="primary.default" />}
          onClick={open("preview")}
          disabled={isDisabled}
        >
          Preview your Ad
        </Button>
      </VStack> */}

      <AdPreviewModal
        isOpen={isOpen("preview")}
        onClose={close("preview")}
        state={state}
        set={set}
        reset={reset}
        isUpdate={!!onSubmit}
        onPost={open("confirm")}
      />
    </Card>
  );
}

function Form(props: FormProps) {
  const { state, set, isCheckingCoinToUsdRate, coinToUsdData } = props;

  const {
    rate,
    rateData,
    markUp,
    markDown,
    totalCost,
    fiatPrice,
    // isPriceInvalid,
    isFixedPriceInvalid,
    isDynamicPriceInvalid,
    isCheckingRate,
    isUpdatingRate,
    isMinimumInvalid,
    isMaximumInvalid,
    isInsufficientBalance,
    isBalanceLoading,
    currency,
    isFiatBalanceInsufficient,
    fiat_balance,
    mapUnitField,
    mapSrcUnitField,
    setTotalCost,
    setVolume,
    balance,
  } = useFormValidationAndInfo(props);

  const { label } = useCoin(currency as any);

  const getMaximumFilling = () => {
    return Number(state.volume);
  };

  const getCurrentMarketRate = () => {
    if (!state.dynamic && !!rateData?.exchangeRate)
      return `Current market rate: 1 ${toCoinLocale(!state?.dynamic ? state?.token! : "usd")} = ${Number(
        ((rateData?.exchangeRate as any) ?? {})[state?.type!]
      ).toLocaleString()} ${toUpper(mapSrcUnitField(state?.type!))}`;

    if (!!coinToUsdData?.exchangeRate)
      return `At your rate: 1 ${toCoinLocale(state?.token!)} = ${Number(
        (state?.token?.includes("usd") ? 1 : ((coinToUsdData?.exchangeRate as any) ?? {})[state?.type!]) * +(state?.price ?? 0)
      ).toLocaleString()} ${toUpper(mapSrcUnitField(state?.type!))}`;
  };

  const available_balance = useMemo(() => {
    if (!!state?.type && state?.type !== "buy") return toPrecision(balance, state?.token ?? "btc");
    else return currencyFormat(currency as any).format(fiat_balance);
  }, [state?.type, balance, currency, fiat_balance, state?.token]);

  // const should_show_dynamic_fields = useMemo(
  //   () => (!!state?.dynamic && !!fiatPrice) || !state?.dynamic,
  //   [state?.dynamic, fiatPrice]
  // );

  const setMaximumFilling = (val: string) => {
    const max = getMaximumFilling();
    if (+val > max) return String(max);
    return val;
  };

  const setMinimumFilling = (val: string) => {
    const max = getMaximumFilling();
    if (+val > max) return String(max);
    return val;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  // console.clear();
  // console.log("Form State", state, ticker, getCurrentMarketRate(), coinToUsdData);

  return (
    <Card
      as="form"
      w="100%"
      p={{ sm: "20px", "2sm": "44px 48px", "3sm": "44px 48px", md: "44px 48px" }}
      borderRadius={{ sm: "8px", md: "16px" }}
      onSubmit={handleSubmit}
    >
      <Heading as="h6" fontSize="20px" fontWeight="600" mb="38px">
        Price
      </Heading>

      <ConditionalRender shouldRender={!!state?.dynamic}>
        <FormControl mb={"34px"}>
          <InputLabel isInvalid={isDynamicPriceInvalid} isLoading={isUpdatingRate || isCheckingCoinToUsdRate}>
            {/* {!state.dynamic ? "Price" : "Fiat to USD"}: */}
            {when(
              !!state?.dynamic,
              `${toCoinLocale(currency ?? "ngn")} to USD rate`,
              `${toCoinLocale(state?.token ?? "btc")} Price that buyer will see`
            )}
            :
          </InputLabel>
          <Input
            inputMode="decimal"
            type="number"
            isRequired
            disabled={!state?.dynamic}
            isInvalid={isDynamicPriceInvalid}
            min={!state?.dynamic ? String(markDown ?? rate) : 0}
            max={!state?.dynamic ? String(markDown ?? rate) : Infinity}
            placeholder={`Enter price ${toCoinLocale(mapSrcUnitField(state?.type!) ?? "btc")}/${toCoinLocale(
              !state?.dynamic ? state?.token! : "usd"
            )}`}
            onChange={(e) =>
              set({
                [dynamicsMap[String(state?.dynamic)]]: e.target.value,
              })
            }
            // value={floatString(state[dynamicsMap[String(state?.dynamic!)]] ?? "") ?? ""}
            // value={Number(state[dynamicsMap[String(state?.dynamic!)]] ?? "").toFixed(2)}
            value={fiatPrice}
            endAdornment={
              <Box pr="40px">
                <Text fontWeight="500">{toCoinLocale(mapSrcUnitField(state?.type!) ?? "ngn")}/USD</Text>
              </Box>
            }
          />

          {!isCheckingRate && <FormHelperText fontWeight="500">{getCurrentMarketRate()}</FormHelperText>}

          <ConditionalRender shouldRender={isDynamicPriceInvalid && !!state.dynamic}>
            <FormHelperText fontSize="sm" color="red.500" fontWeight="500">{`Price must be between ${Number(
              markDown
            ).toLocaleString()} ${toUpper(mapSrcUnitField(state?.type!))} and ${Number(markUp).toLocaleString()} ${toUpper(
              mapSrcUnitField(state?.type!)
            )}`}</FormHelperText>
          </ConditionalRender>
        </FormControl>
      </ConditionalRender>

      {/* <ConditionalRender shouldRender={should_show_dynamic_fields}> */}
      <FormControl mb="34px">
        <InputLabel
          isLoading={isBalanceLoading}
          isInvalid={+(state?.volume ?? 0) < 0 || isInsufficientBalance || isFiatBalanceInsufficient}
        >
          Amount of {toCoinLocale(state?.token ?? "btc")} you want to {state?.type}
        </InputLabel>
        <Input
          pr="96px"
          type="number"
          min={0}
          // max={9999999999999999999}
          disabled={!!state?.dynamic && !fiatPrice}
          isRequired
          isInvalid={+(state?.volume ?? 0) < 0 || isInsufficientBalance || isFiatBalanceInsufficient}
          placeholder="Enter Amount"
          value={floatString(state?.volume ?? "")}
          // onChange={(e) => set({ volume: e.target.value })}
          onChange={(e) => setVolume(e.target.value)}
          endAdornment={
            // <Box w="fit-content" pr="60px">
            //   <Text fontWeight="500">
            //     {toCoinLocale(state?.token!)}/{toUpper(mapSrcUnitField(state?.type!))}
            //   </Text>
            // </Box>
            <Box w="fit-content" pr="40px">
              <Text fontWeight="500">{toCoinLocale(state?.token!)}</Text>
            </Box>
          }
        />

        <ConditionalRender shouldRender={state?.type !== "buy"}>
          <FormInfo
            isInvalid={isInsufficientBalance || isFiatBalanceInsufficient}
            mt="10px"
            info="Available Balance: "
            description={available_balance}
          />
        </ConditionalRender>
      </FormControl>
      {/* </ConditionalRender> */}

      {/* <ConditionalRender shouldRender={should_show_dynamic_fields}> */}
      <FormControl mb={when(!!state?.dynamic, "56px", "34px")}>
        <InputLabel isInvalid={isFiatBalanceInsufficient || isFixedPriceInvalid}>Total {label} value coin</InputLabel>
        <Input
          pr="96px"
          disabled={!!state?.dynamic || (!state?.dynamic && !state?.volume)}
          // type="number"
          placeholder="0.00"
          isInvalid={isFiatBalanceInsufficient || isFixedPriceInvalid}
          // value={!isNaN(+totalCost) ? currencyFormat((mapSrcUnitField(state?.type!) as any) ?? "ngn").format(+totalCost) : ""}
          value={!isNaN(+totalCost) ? totalCost : ""}
          onChange={(e) => setTotalCost(e.target.value)}
          endAdornment={
            <Box w="fit-content" pr="40px">
              <Text fontWeight="500">{toUpper(mapSrcUnitField(state?.type!))}</Text>
            </Box>
            // <Box w="fit-content" pr="60px">
            //   <Text fontWeight="500">
            //     {toCoinLocale(state?.token!)}/{toUpper(mapSrcUnitField(state?.type!))}
            //   </Text>
            // </Box>
          }
        />

        {!!state?.volume && isFixedPriceInvalid && (
          <FormHelperText fontSize="sm" color="red.500" fontWeight="500">{`Price must be between ${Number(
            markDown
          ).toLocaleString()} ${toUpper(mapSrcUnitField(state?.type!))} and ${Number(markUp).toLocaleString()} ${toUpper(
            mapSrcUnitField(state?.type!)
          )}`}</FormHelperText>
        )}

        <ConditionalRender shouldRender={state?.type === "buy" && state?.selectedMethod === "bitmamaWallet"}>
          <FormInfo isInvalid={isFiatBalanceInsufficient} mt="10px" info="Available Balance: " description={available_balance} />
        </ConditionalRender>
      </FormControl>
      {/* </ConditionalRender> */}

      <ConditionalRender shouldRender={!state?.dynamic}>
        <FormControl mb="56px">
          <InputLabel isInvalid={isDynamicPriceInvalid} isLoading={isUpdatingRate || isCheckingCoinToUsdRate}>
            {/* {!state.dynamic ? "Price" : "Fiat to USD"}: */}
            {toCoinLocale(state?.token ?? "btc")} Price that buyer will see:
          </InputLabel>
          <Input
            inputMode="decimal"
            type="number"
            isRequired
            disabled={!state?.dynamic}
            isInvalid={isDynamicPriceInvalid}
            min={!state?.dynamic ? String(markDown ?? rate) : 0}
            max={!state?.dynamic ? String(markDown ?? rate) : Infinity}
            placeholder={`Enter price ${toCoinLocale(mapSrcUnitField(state?.type!) ?? "btc")}/${toCoinLocale(
              !state?.dynamic ? state?.token! : "usd"
            )}`}
            onChange={(e) =>
              set({
                [dynamicsMap[String(state?.dynamic)]]: e.target.value,
              })
            }
            // value={floatString(state[dynamicsMap[String(state?.dynamic!)]] ?? "") ?? ""}
            // value={Number(state[dynamicsMap[String(state?.dynamic!)]] ?? "").toFixed(2)}
            value={fiatPrice}
            endAdornment={
              <Box pr="40px">
                <Text fontWeight="500">{toCoinLocale(mapSrcUnitField(state?.type!) ?? "ngn")}</Text>
              </Box>
            }
          />

          {!isCheckingRate && <FormHelperText fontWeight="500">{getCurrentMarketRate()}</FormHelperText>}

          <ConditionalRender shouldRender={isDynamicPriceInvalid && !!state.dynamic}>
            <FormHelperText fontSize="sm" color="red.500" fontWeight="500">{`Price must be between ${Number(
              markDown
            ).toLocaleString()} ${toUpper(mapSrcUnitField(state?.type!))} and ${Number(markUp).toLocaleString()} ${toUpper(
              mapSrcUnitField(state?.type!)
            )}`}</FormHelperText>
          </ConditionalRender>
        </FormControl>
      </ConditionalRender>

      <Box mb="38px">
        <Heading as="h6" fontSize="20px" fontWeight="600" mb="16px">
          Amount
        </Heading>
        <Divider />
      </Box>

      <FormControl mt="50px" mb="34px">
        <InputLabel isInvalid={isMinimumInvalid}>Enter Minimum Limit </InputLabel>
        <AmountInput
          isRequired
          isDisabled={!state?.volume}
          isInvalid={
            isMinimumInvalid ||
            (!!state.minimumFilling &&
              !!state.maximumFilling &&
              parseFloat(state.minimumFilling) >= parseFloat(state.maximumFilling))
          }
          min={0}
          max={getMaximumFilling()}
          placeholder={`Enter minimum ${toCoinLocale(mapUnitField(state?.type!))} amount`}
          value={state?.minimumFilling ?? ""}
          onChange={(e) => set({ minimumFilling: setMinimumFilling(e.target.value) })}
        />

        {!!state.minimumFilling &&
          !!state.maximumFilling &&
          parseFloat(state.minimumFilling) >= parseFloat(state.maximumFilling) && (
            <FormHelperText fontSize="sm" color="red.400" fontWeight="500">
              Value must not be greater than or equal to the maximum limit
            </FormHelperText>
          )}

        {isMinimumInvalid && (
          <FormHelperText fontSize="sm" color="red.400" fontWeight="500">
            Value must be greater than 0 {toCoinLocale(state?.token!)} and less than {state.volume} {toCoinLocale(state?.token!)}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl mb="34px">
        <InputLabel isInvalid={isMaximumInvalid}>Enter Maximum Limit </InputLabel>
        <AmountInput
          isRequired
          isDisabled={!state?.volume}
          isInvalid={isMaximumInvalid}
          max={getMaximumFilling()}
          min={floatString(state?.minimumFilling ?? "0") ?? 0}
          placeholder={`Enter maximum ${toCoinLocale(mapUnitField(state?.type!))} amount`}
          value={state?.maximumFilling ?? ""}
          onChange={(e) => set({ maximumFilling: setMaximumFilling(e.target.value) })}
          onMax={() => set({ maximumFilling: setMaximumFilling(state?.volume ?? "") })}
        />

        {isMaximumInvalid && !!state?.token && (
          <FormHelperText fontSize="sm" color="red.400" fontWeight="500">
            Value must be greater than 0 {toCoinLocale(state?.token!)} and less than or equal to {state.volume}{" "}
            {toCoinLocale(state?.token!)}
          </FormHelperText>
        )}
      </FormControl>

      {/* <VStack mt="100px">
        <Button variant="transparent" textDecoration="underline" onClick={() => reset()}>
          Clear
        </Button>
      </VStack> */}
    </Card>
  );
}

const adsTypeMap = (type: string) => {
  const map: Record<string, "selling" | "buying"> = {
    buy: "selling",
    sell: "buying",
  };
  return map[type];
};

function ControlHub(props: ControlHubProps) {
  const { state, set, coinToUsdData } = props;

  const { search } = useLocation();

  const uparams = useMemo(() => new URLSearchParams(search), [search]);
  const ads_type_param = useMemo(() => uparams.get("adsType"), [uparams]);

  const currency = useCurrency();
  const { appfigs } = useAppConfig();
  const { p2pConfig } = appfigs ?? {};

  const { handleType, handleDynamics } = useFormHandlers({ ...props });

  const defaultType: "sell" | "buy" = useMemo(() => {
    if (!!p2pConfig && !!p2pConfig?.disableBuyListing) return "sell";
    if (!!p2pConfig && !!p2pConfig?.disableSellListing) return "buy";
    return (ads_type_param as any) ?? "buy";
  }, [p2pConfig, ads_type_param]);

  console.log("Search", defaultType);

  const alternate = useMemo(() => (state?.type === "buy" ? "sell" : "buy"), [state?.type]);

  const isBuyOrSellDisabled = useMemo(
    () => (!!p2pConfig && !!p2pConfig.disableBuyListing) || (!!p2pConfig && !!p2pConfig?.disableSellListing),
    [p2pConfig]
  );

  const isSelected = (value: string) =>
    (value === "fixed" && state?.dynamic! === false) || (value === "dynamic" && state?.dynamic! === true);
  const bColor = useCallback((dynamic: boolean) => when(dynamic, "secondary.400", "white"), []);

  const { data: usdToCurrency } = useGetRateQuery(`usd${currency}`, { skip: !currency });

  const tokenRate = useMemo(() => {
    if (state?.token?.includes("usd")) return 1;
    return ((coinToUsdData?.exchangeRate as any) ?? {})[state?.type ?? "buy"];
  }, [coinToUsdData, state?.type, state?.token]);

  const typeRate = useMemo(() => ((usdToCurrency?.exchangeRate as any) ?? {})[state?.type ?? "buy"], [usdToCurrency, state?.type]);

  const { bestRates, isLoading } = useP2PBestRates(state?.token!, currency, adsTypeMap(state?.type!));

  const price = useMemo(() => bestRates[0]?.unitCost ?? 0, [bestRates]);

  const marketPrice = useMemo(
    () => when(!!bestRates[0], price, (tokenRate ?? 0) * (typeRate ?? 0)),
    [bestRates, price, tokenRate, typeRate]
  );

  const isUserTypeChange = useRef(false);

  useEffect(() => {
    if (!isEqual(state?.type, defaultType) && !isUserTypeChange.current) {
      handleType(defaultType);
    }
  }, [state?.type, defaultType, isUserTypeChange, handleType]);

  // console.clear();
  // console.log("Usd To Ngn", typeRate, tokenRate, currency, marketPrice);

  return (
    <VStack>
      <Box p="20px 40px" w="100%" borderRadius="16px" minH="298px" bg="#041815">
        <Stack>
          <Box
            p="26px 14px"
            minW="220px"
            borderRadius="10px"
            bg="rgba(255, 255, 255, 0.1)"
            minH="100%"
            fontFamily="var(--bitmama-fonts-heading)"
          >
            <VStack>
              <HStack>
                {/* <Image boxSize="48px" src={BitcoinIMG} alt="bitcoin image" /> */}
                <Text ml="0 !important" fontSize="16px" fontWeight="600" color="white">
                  Market Buying Price:
                </Text>
              </HStack>

              <VStack mt="0 !important">
                {when(
                  isLoading,
                  <CircularLoader />,
                  <Text mt="0 !important" fontSize="28px" fontWeight="700" color="white">
                    {toCoinLocale((currency as any) ?? "ngn")} {Number(marketPrice).toLocaleString()}
                  </Text>
                )}
              </VStack>
            </VStack>
          </Box>

          <VStack>
            <HStack color="white" mt="16px !important">
              {["dynamic", "fixed"].map((d) => (
                <Button
                  key={`dynamic-${d}`}
                  minW="150px"
                  minH="42px"
                  variant="outline"
                  borderRadius="10px"
                  fontSize="14px"
                  fontWeight="600"
                  borderColor={bColor(isSelected(d))}
                  onClick={() => handleDynamics(!state?.dynamic, d)}
                >
                  {d} price
                </Button>
              ))}
            </HStack>
          </VStack>

          <VStack ml="0 !important">
            <HStack my="16px">
              {!isBuyOrSellDisabled && (
                <Link
                  // ml="22px !important"
                  // to="#"
                  fontWeight="600"
                  color="secondary.500"
                  textDecoration="underline"
                  onClick={() => {
                    isUserTypeChange.current = true;
                    handleType(alternate);
                  }}
                  textTransform="capitalize"
                >
                  {alternate} Instead
                </Link>
              )}
            </HStack>
          </VStack>
        </Stack>
      </Box>

      <HStack color="white" py="12px">
        <PeerCoins
          variant="outline"
          value={state?.token}
          onPeerChange={(coin) =>
            set({
              [listingTypeMap[state?.type!]]: coin,
              token: coin,
            })
          }
        />
      </HStack>
    </VStack>
  );
}

function useFormValidationAndInfo(props: FormProps) {
  const { set, state, unitCost, coinToUsdData } = props;

  const currency = useCurrency();

  const { getBalance, isLoading: isBalanceLoading } = useWalletBalance();

  const mapUnitField = (type: string) => {
    return (state as IState)[listingTypeMap[type]];
  };

  const mapSrcUnitField = useCallback(
    (type: string) => {
      const buyMap: Record<string, "destinationUnit" | "sourceUnit"> = {
        sell: "destinationUnit",
        buy: "sourceUnit",
      };
      return state[buyMap[type]];
    },
    [state]
  );

  const ticker = useMemo(() => {
    if (state?.dynamic!) return `usd${toLower(mapSrcUnitField(state?.type!))}`;
    return `${sourceMap[state?.token!]}${toLower(mapSrcUnitField(state?.type!))}`;
  }, [state, mapSrcUnitField]);

  console.log("Ticker", ticker, state?.token, mapSrcUnitField(state?.type!));

  const { data: rateData, isLoading: isCheckingRate, isFetching: isUpdatingRate } = useGetRateQuery(ticker);

  const rate = useMemo(
    () => Number((rateData && ((rateData?.exchangeRate as any) ?? {})[state?.type!]) ?? 1),
    [rateData, state?.type]
  );

  const totalCost = useMemo(() => {
    if (!!state?.dynamic) return Number(unitCost * +(state?.volume ?? 0)).toFixed(2);
    else return state?.totalCost ?? "";
  }, [unitCost, state?.volume, state?.totalCost, state?.dynamic]);

  const fiatPrice = useMemo(() => {
    if (!!state?.dynamic) return state[dynamicsMap[String(state?.dynamic!)]] ?? "";
    else return Number(state[dynamicsMap[String(state?.dynamic!)]] ?? "").toFixed(2);
  }, [state]);

  const getDerivedCost = useCallback(
    (totalCost: number, volume: number) => {
      let rate = ((coinToUsdData?.exchangeRate ?? {}) as any)[state?.type!] ?? 1;
      rate = when((state?.token ?? "")?.includes("usd"), 1, rate);
      const dynamic_price = totalCost / (rate * volume);
      if (!!state?.dynamic) return dynamic_price;
      return totalCost / volume;
    },
    [coinToUsdData, state]
  );

  const derived_cost = useMemo(
    () => getDerivedCost(+(state?.totalCost ?? 0), +(state?.volume ?? 0)),
    [state?.totalCost, state?.volume, getDerivedCost]
  );

  // const markUp = useMemo(() => rate * 2, [rate]);
  // const markDown = useMemo(() => rate * 0.4, [rate]);

  console.log("Rate ", rate);

  const markUp = useMemo(() => {
    if (!!state?.dynamic) return rate * 1.4;
    else return rate * +(state?.volume ?? 0) * 1.4;
  }, [state?.dynamic, state?.volume, rate]);

  const markDown = useMemo(() => {
    if (!!state?.dynamic) return rate * 0.6;
    else return rate * +(state?.volume ?? 0) * 0.6;
  }, [state?.dynamic, state?.volume, rate]);

  const isDynamicPriceInvalid = useMemo(
    () =>
      ((!!(state as IState)[dynamicsMap[String(state?.dynamic)]] &&
        +(state as IState)[dynamicsMap[String(state?.dynamic)]] < markDown) ||
        +(state as IState)[dynamicsMap[String(state?.dynamic)]] > markUp) &&
      !!state?.dynamic,
    [state, markDown, markUp]
  );

  const isFixedPriceInvalid = useMemo(
    () => ((!!totalCost && +(totalCost ?? 0) < markDown) || +(totalCost ?? 0) > markUp) && !state?.dynamic,
    [totalCost, markDown, markUp, state?.dynamic]
  );

  const isPriceInvalid = useMemo(
    () => when(!!state?.dynamic, isDynamicPriceInvalid, isFixedPriceInvalid),
    [state?.dynamic, isFixedPriceInvalid, isDynamicPriceInvalid]
  );

  const setTotalCost = (value: string) => {
    const derived_cost = when(!!state?.volume && +value > 0, getDerivedCost(+value, +(state?.volume ?? 0)), undefined);
    console.log("Derived Cost", derived_cost);
    set({ totalCost: value, [dynamicsMap[String(state?.dynamic)]]: derived_cost });
  };

  const setVolume = (value: string) => {
    const derived_cost = when(!!state?.totalCost && +value > 0, getDerivedCost(+(state?.totalCost ?? 0), +value), undefined);
    console.log("Derived Cost", derived_cost);
    const fiat_price = when(!!state?.dynamic, state?.price ?? "", String(derived_cost ?? ""));
    set({ volume: value, [dynamicsMap[String(state?.dynamic)]]: fiat_price });
  };

  const isMinimumInvalid = useMemo(
    () =>
      (!!state?.minimumFilling && parseFloat(state?.minimumFilling) <= 0) ||
      (!!state?.minimumFilling && parseFloat(state.minimumFilling) >= parseFloat((state?.volume as string) ?? 0)),
    [state]
  );

  const isMaximumInvalid = useMemo(
    () =>
      (!!state?.maximumFilling && !!state.volume && parseFloat(state?.maximumFilling) > parseFloat(state?.volume)) ||
      (!!state?.maximumFilling && parseFloat(state?.maximumFilling) <= 0),
    [state]
  );

  const token_balance = useMemo(() => getBalance((state?.token ?? "btc") as any) ?? 0, [getBalance, state?.token]);
  const fiat_balance = useMemo(() => getBalance(currency as any) ?? 0, [getBalance, currency]);

  const isInsufficientBalance = useMemo(
    () => !!state?.volume && !!state?.type && state?.type !== "buy" && +state?.volume > +token_balance,
    [state?.volume, state?.type, token_balance]
  );

  const isFiatBalanceInsufficient = useMemo(
    () => !!state?.type && state?.type === "buy" && state?.selectedMethod === "bitmamaWallet" && +(totalCost ?? 0) > +fiat_balance,
    [totalCost, state?.selectedMethod, state?.type, fiat_balance]
  );

  return {
    rate,
    rateData,
    isPriceInvalid,
    isFixedPriceInvalid,
    isDynamicPriceInvalid,
    markUp,
    markDown,
    isCheckingRate,
    isUpdatingRate,
    ticker,
    mapUnitField,
    mapSrcUnitField,
    totalCost,
    fiatPrice,
    setTotalCost,
    setVolume,
    isMinimumInvalid,
    isMaximumInvalid,
    currency,
    balance: token_balance,
    fiat_balance: fiat_balance,
    isInsufficientBalance,
    isBalanceLoading,
    isFiatBalanceInsufficient,
    derived_cost,
  };
}

function useFormHandlers(stateEngine: IStateExtension & FormProps) {
  const { state, set } = stateEngine;

  const {
    isPriceInvalid,
    isMaximumInvalid,
    isMinimumInvalid,
    balance,
    isInsufficientBalance,
    isFiatBalanceInsufficient,
    isBalanceLoading,
  } = useFormValidationAndInfo({
    ...stateEngine,
  });

  const bankInfo = useMemo(() => state?.paymentOptions?.find((opt) => opt?.method === "bankTransfer"), [state?.paymentOptions]);

  const handleBankInfo = (updates: Partial<IPaymentOption>) => {
    // In case we have multiple payment options, make to keep them
    const otherOptions = state?.paymentOptions?.filter((opt) => opt?.method !== "bankTransfer") ?? [];

    if (state?.type! === "buy") {
      const obj = { ...omit(updates, ["receivingIntoLabel"]), method: "bankTransfer" };
      if (!!updates?.receivingIntoLabel) Object.assign(obj, { payingToLabel: updates.receivingIntoLabel });
      set({ paymentOptions: [...otherOptions, obj as any] });
    }
    if (state?.type! === "sell") {
      const obj = { ...bankInfo, ...updates, method: "bankTransfer" };
      set({ paymentOptions: [...otherOptions, obj as IPaymentOption] });
    }
  };

  const handleMethod = (method: string, type?: string) => {
    if (!method || (!!method && !!type && method === state?.selectedMethod && type === state?.type)) return;
    // make sure to keep other existing methods and payment options;
    const otherMethods = state?.methods?.filter((m) => m !== method) ?? [];
    // const otherOptions = state?.paymentOptions?.filter((opt) => opt?.method !== "bankTransfer") ?? [];

    const switcher: Record<string, () => void> = {
      bitmamaWallet: () => {
        set({ selectedMethod: method, useInternalWallet: true, methods: [...otherMethods, method], paymentOptions: [] });
      },
      bankTransfer: () => {
        if ((type ?? state?.type) === "buy") {
          set({
            selectedMethod: method,
            useInternalWallet: false,
            methods: [...otherMethods, method],
            paymentOptions: [{ payingToLabel: "Bank Transfer", method } as any],
          });
        }
        if ((type ?? state?.type) === "sell") {
          set({ selectedMethod: method, methods: [...otherMethods, method], useInternalWallet: false, paymentOptions: [] });
        }
      },
      changeraId: () => {
        set({ selectedMethod: method, methods: [...otherMethods, method], useInternalWallet: false });
      },
      selectAccounts() {
        set({ selectedMethod: method });
      },
    };

    switcher[method]();
  };

  const handleType = (type: string) => {
    if (state.type === type) return;
    if (state.type !== type)
      set({
        type,
        sourceUnit: state.destinationUnit,
        destinationUnit: state.sourceUnit,
        maximumFilling: state?.volume,
      });

    !!state?.selectedMethod && handleMethod(state.selectedMethod, type);
  };

  const handleDynamics = (val: boolean, name: string) => {
    if (val === state?.dynamic || state?.priceType === name) return;
    if (val === true) set({ dynamic: true, unitCost: undefined, price: state?.unitCost, priceType: name });
    if (val === false) set({ dynamic: false, unitCost: state?.price, price: undefined, priceType: name });
  };

  // const isMinimumInvalid = useMemo(
  //   () =>
  //     (!!state?.minimumFilling && parseFloat(state?.minimumFilling) <= 0) ||
  //     (!!state?.minimumFilling && parseFloat(state.minimumFilling) >= parseFloat((state?.volume as string) ?? 0)),
  //   [state]
  // );

  // const isMaximumInvalid = useMemo(
  //   () =>
  //     (!!state?.maximumFilling && !!state.volume && parseFloat(state?.maximumFilling) > parseFloat(state?.volume)) ||
  //     (!!state?.maximumFilling && parseFloat(state?.maximumFilling) <= 0),
  //   [state]
  // );

  const isDisabled = useMemo(
    () =>
      !(
        state[dynamicsMap[String(state?.dynamic)]] &&
        +(state?.volume ?? 0) &&
        +(state?.minimumFilling ?? 0) &&
        +(state?.maximumFilling ?? 0)
      ) ||
      isMinimumInvalid ||
      isMaximumInvalid ||
      isPriceInvalid ||
      !state?.selectedMethod ||
      isInsufficientBalance ||
      isFiatBalanceInsufficient ||
      isBalanceLoading ||
      (!!state?.minimumFilling && !!state?.maximumFilling && +state.minimumFilling >= +state.maximumFilling) ||
      (state?.type === "sell" &&
        state?.selectedMethod === "bankTransfer" &&
        !(bankInfo?.accountName && bankInfo?.receivingIntoAddress && bankInfo?.receivingIntoLabel)),
    [
      state,
      isMinimumInvalid,
      isMaximumInvalid,
      bankInfo,
      isPriceInvalid,
      isInsufficientBalance,
      isBalanceLoading,
      isFiatBalanceInsufficient,
    ]
  );

  return {
    bankInfo,
    handleMethod,
    handleBankInfo,
    handleType,
    handleDynamics,
    isMinimumInvalid,
    isMaximumInvalid,
    isDisabled,
    balance,
    isInsufficientBalance,
  };
}

interface DetailItemProps extends Omit<BoxProps, "title"> {
  // children?: any;
  title: ReactNode;
  description?: ReactNode;

  _title?: TextProps;
  _description?: BoxProps;
}

function DetailItem(props: DetailItemProps) {
  const { children, title, description, _title, _description, ...xprops } = props;
  const color = useColor();

  const tColor = color("black", "white");

  return (
    <HStack
      w="100%"
      p="20px 4px"
      borderBottom="1px solid transparent"
      borderBottomColor={color("border", "dark.border")}
      {...xprops}
    >
      <Text fontSize="16px" color="grey.400" fontWeight="500" {..._title}>
        {title}
      </Text>

      {!children && (
        <Text color={tColor} fontWeight="600" fontSize="15px" mt="0 !important" {..._description}>
          {description}
        </Text>
      )}

      {!!children && children}
    </HStack>
  );
}

export function ControlHubV1(props: ControlHubProps) {
  const { state, set, coinToUsdData } = props;

  const currency = useCurrency();
  const { appfigs } = useAppConfig();
  const { p2pConfig } = appfigs ?? {};

  const { handleType, handleDynamics } = useFormHandlers({ ...props });

  const defaultType: "sell" | "buy" = useMemo(() => {
    if (!!p2pConfig && !!p2pConfig?.disableBuyListing) return "sell";
    if (!!p2pConfig && !!p2pConfig?.disableSellListing) return "buy";
    return "buy";
  }, [p2pConfig]);

  const alternate = useMemo(() => (state?.type === "buy" ? "sell" : "buy"), [state?.type]);

  const isBuyOrSellDisabled = useMemo(
    () => (!!p2pConfig && !!p2pConfig.disableBuyListing) || (!!p2pConfig && !!p2pConfig?.disableSellListing),
    [p2pConfig]
  );

  const isSelected = (value: string) =>
    (value === "fixed" && state?.dynamic! === false) || (value === "dynamic" && state?.dynamic! === true);
  const bColor = useCallback((dynamic: boolean) => when(dynamic, "secondary.400", "white"), []);

  const { data: usdToCurrency } = useGetRateQuery(`usd${currency}`, { skip: !currency });

  const tokenRate = useMemo(() => {
    if (state?.token?.includes("usd")) return 1;
    return ((coinToUsdData?.exchangeRate as any) ?? {})[state?.type ?? "buy"];
  }, [coinToUsdData, state?.type, state?.token]);

  const typeRate = useMemo(() => ((usdToCurrency?.exchangeRate as any) ?? {})[state?.type ?? "buy"], [usdToCurrency, state?.type]);

  const { bestRates, isLoading } = useP2PBestRates(state?.token!, currency, adsTypeMap(state?.type!));

  const price = useMemo(() => bestRates[0]?.unitCost ?? 0, [bestRates]);

  const marketPrice = useMemo(
    () => when(!!bestRates[0], price, (tokenRate ?? 0) * (typeRate ?? 0)),
    [bestRates, price, tokenRate, typeRate]
  );

  const isUserTypeChange = useRef(false);

  useUpdateEffect(() => {
    if (!isEqual(state?.type, defaultType) && !isUserTypeChange.current) {
      handleType(defaultType);
    }
  }, [state?.type, defaultType, isUserTypeChange]);

  // console.clear();
  // console.log("Usd To Ngn", typeRate, tokenRate, currency, marketPrice);

  return (
    <Box p="46px 48px" w="100%" borderRadius="16px" minH="298px" bg="#041815">
      <HStack justifyContent="space-between">
        <Stack>
          <HStack>
            <Heading as="h6" fontSize="20px" color="white">
              You are about to create an Ad to {state?.type} BTC
            </Heading>

            {!isBuyOrSellDisabled && (
              <Link
                ml="22px !important"
                // to="#"
                color="secondary.500"
                textDecoration="underline"
                onClick={() => {
                  isUserTypeChange.current = true;
                  handleType(alternate);
                }}
                textTransform="capitalize"
              >
                Create {alternate} Ad Instead?
              </Link>
            )}
          </HStack>

          <HStack color="white" py="12px">
            <Text fontFamily="var(--bitmama-fonts-heading)" fontSize="14px">
              Change Coin:
            </Text>
            <PeerCoins
              value={state?.token}
              onPeerChange={(coin) =>
                set({
                  [listingTypeMap[state?.type!]]: coin,
                  token: coin,
                })
              }
            />
          </HStack>

          <HStack color="white" mt="40px !important">
            {["fixed", "dynamic"].map((d) => (
              <Button
                key={`dynamic-${d}`}
                minW="100px"
                minH="26px"
                variant="outline"
                borderColor={bColor(isSelected(d))}
                onClick={() => handleDynamics(!state?.dynamic, d)}
              >
                {d} price
              </Button>
            ))}
            {/* <Button minW="100px" minH="26px" variant="outline" borderColor={bColor(false)} onClick={() => set({ dynamic: false })}>
              Fixed price
            </Button>
            <Button minW="100px" minH="26px" variant="outline" borderColor={bColor} onClick={() => set({ dynamic: true })}>
              Dynamic price
            </Button> */}
          </HStack>
        </Stack>

        <Box p="32px 14px" minW="220px" borderRadius="10px" bg="#E2F2DA" minH="100%">
          <VStack>
            <HStack>
              {/* <Image boxSize="48px" src={BitcoinIMG} alt="bitcoin image" /> */}
              <Text ml="0 !important" fontSize="14px" fontWeight="600" color="black">
                Market Buying Price
              </Text>
            </HStack>

            <VStack mt="32px !important">
              <Text fontSize="14px" fontWeight="500" color="secondary.400">
                Best Rate
              </Text>
              {when(
                isLoading,
                <CircularLoader />,
                <Text mt="0 !important" fontSize="24px" fontWeight="600" fontFamily="var(--bitmama-fonts-heading)" color="black">
                  {currencyFormat((currency as any) ?? "ngn").format(marketPrice)}
                </Text>
              )}
            </VStack>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
}
