import { useCallback, useMemo } from "react";
import { selectUser } from "store/slices";
import useInlineScript from "./useInlineScript";
import useSelector from "./useSelector";
import { getGuestID } from "utils";
import configs from "config";

const websiteToken = configs.CHATWOOT_WEBTOKEN;

const chatScript = ({ websiteToken, userData }: { websiteToken?: string; userData?: Record<string, any> }) => {
  window.chatwootSettings = {
    hideMessageBubble: false,
    position: "right", // This can be left or right
    locale: "en", // Language to be set
    type: "standard", // [standard, expanded_bubble]

    // type: "expanded_bubble",
    // launcherTitle: "Chat with us",
  };

  //   let userDataString = "";
  //   const includeUserData = false;
  //   if (userData && includeUserData) {
  //     const userDataEntries = Object.entries(userData).map(([key, value]) => `${key}: '${value}'`);
  //     userDataString = `${userDataEntries.join(", ")}`;
  //   }

  //   let queryObj: Record<string, any> = userData
  //     ? {
  //         fullname: userData?.fullname,
  //         form_id: "identifier",
  //         identifier: userData?.userId,
  //         ...sharedQuery,
  //       }
  //     : { ...sharedQuery };

  //   const userDataEntries = Object.entries(queryObj).map(([key, value]) => `${key}=${value}`);
  //   const queryUrl = `?${userDataEntries.join("&")}`;

  return `
(function(d,t) {
      var BASE_URL="https://woot.apri.xyz/";
      var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      g.src=BASE_URL+"/packs/js/sdk.js";
      s.parentNode.insertBefore(g,s);
      g.async=!0;
      g.onload=function(){
        window.chatwootSDK.run({
          websiteToken: '${websiteToken}',
          baseUrl: BASE_URL
        })
      }
    })(document,"script");
`;
};

export function useChatwoot() {
  const { profile: user } = useSelector(selectUser);

  const guestId = getGuestID();
  console.log("Guest Chatwoot ID", guestId);
  const fullname = !!user?.firstName ? `${String(`${user?.firstName || ""} ${user?.lastName || ""}`).trim()}` : "Guest";

  const bootConfig = useMemo(
    () => ({
      name: user?.firstName ?? "Guest",
      fullname,
      userId: user?.userId ?? guestId,
      email: user?.email,
      phone: user?.phone || undefined,
      createdAt: user?.createdAt,
      utmSource: "web",
    }),
    [user, fullname, guestId]
  );

  const { injectScript } = useInlineScript(chatScript({ websiteToken, userData: bootConfig }), "cs-chat", true);

  const boot = useCallback(
    (_props: any) => {
      injectScript({ inlineScript: chatScript({ websiteToken, userData: bootConfig }) });
    },
    [injectScript, bootConfig]
  );

  const bootWithProps = useCallback(() => {
    return boot(bootConfig);
  }, [bootConfig, boot]);

  const minimize = useCallback(() => {
    const chatbotContainer = document.querySelector(".ebanqo-widget-wrapperV2");
    if (chatbotContainer) {
      (chatbotContainer as any).style.display = "";
    }
  }, []);

  const maximize = useCallback(() => {
    const chatbotContainer = document.querySelector(".ebanqo-widget-wrapperV2");
    if (chatbotContainer) {
      (chatbotContainer as any).style.display = "block";
    }
  }, []);

  const shutdown = useCallback(() => {
    minimize();
    const chatbotPrompt = document.querySelector(".initial-prompt-message-wrapper");
    if (chatbotPrompt) {
      (chatbotPrompt as any).style.display = "";
    }
  }, [minimize]);

  const restore = useCallback(() => {
    maximize();
    const chatbotPrompt = document.querySelector(".initial-prompt-message-wrapper");
    if (chatbotPrompt) {
      (chatbotPrompt as any).style.display = "flex";
    }
  }, [maximize]);

  const logout = useCallback(() => {
    // we are unable to access the chat iframe...due to security policy
    // provider should expose a logout api
  }, []);

  return { bootWithProps, boot, shutdown, minimize, maximize, logout, restore };
}
