import { usePartialState } from "hooks";
import { createContext, useContext } from "react";

type ContextValueType = {
  state: Partial<IState>;
  setProgress: (value: number) => void;
  set: (update: Partial<IState>) => void;
  reset: () => void;
};
const RegistrationProgressContext = createContext<ContextValueType | null>(null);

interface IState {
  max: number;
  progress: number;
}

export function RegisterProgressProvider(props: any) {
  const { children } = props;

  const [state, set, reset] = usePartialState<IState>();

  const handleProgress = (value: number) => {
    const max = state?.max ?? 10;
    set({ progress: (value / max) * 100 });
  };

  return (
    <RegistrationProgressContext.Provider value={{ state, set, reset, setProgress: handleProgress }}>
      {children}
    </RegistrationProgressContext.Provider>
  );
}

export function useRegistrationProgress(maxProcedures?: number) {
  const context = useContext(RegistrationProgressContext);
  if (!context) throw new Error("useRegistrationProgress should be used within the RegistrationProgressProvider");
  if (!!maxProcedures && !context?.state?.max) context.set({ max: maxProcedures });
  return context;
}
