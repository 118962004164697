import { Box, Button, Heading, ModalProps, Stack, useColorModeValue, VStack } from "@chakra-ui/react";
import { AbstractModal, AccountLink, IconNames } from "components";

interface StakingOptionsModalProps extends Omit<ModalProps, "children"> {}
type LinkType = { icon: IconNames; label: string; link: string; onClick?: () => void };

export default function StakingOptions(props: StakingOptionsModalProps) {
  const { isOpen, onClose } = props;
  const bColor = useColorModeValue("grey.150", "dark.border");

  const { links } = useLinks(onClose);

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxW: "600px",
        borderRadius: { base: "30px 30px 0px 0px", "1sm": "20px" },
        position: { base: "absolute", "1sm": "initial" },
        padding: "0",
        margin: { base: "0", "1sm": "initial" },
        bottom: 0,
      }}
    >
      <Box p={{ base: "38px 20px", "2sm": "44px 80px" }}>
        <VStack>
          <Heading as="h5" fontSize="20px">
            Choose an option
          </Heading>
        </VStack>

        <Stack mt={{ base: "26px", "2sm": "40px" }}>
          {links.map((link, i) => (
            <AccountLink
              to={link.link}
              bg="transparent"
              icon={link.icon}
              label={link.label}
              mt="8px !important"
              arrowIcon="arrowRight"
              key={`partner-link-${i}`}
              borderBottomColor={bColor}
              onClick={link?.onClick}
            />
          ))}
        </Stack>

        <VStack mt="24px !important" px="46px" w="100%">
          <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose}>
            Close
          </Button>
        </VStack>
      </Box>
    </AbstractModal>
  );
}

function useLinks(onClose: () => void) {
  const links: LinkType[] = [
    {
      icon: "fixedStakingFilled",
      label: "Fixed Staking",
      link: "/earn/staking/subscribe?type=fixed",
      onClick: () => {
        // onOpen({ key: "EarnPages", data: { coin: isTesting ? "tbtc" : "btc", tab: "earn" } });
        onClose();
      },
    },
    { icon: "flexiStakingFilled", label: "Flexible Staking", link: "/earn/staking/subscribe?type=flexible" },
  ];

  return { links };
}
