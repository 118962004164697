import { MainLayoutContainer, PageMotion, Topbar } from "components";
// import { SidePageProvider } from "contexts";
import { PageProps } from "interfaces";
import { SessionFeature } from "ui";

interface P2PSessionProps extends PageProps {}

export default function P2PSession(props: P2PSessionProps) {
  return (
    <PageMotion key="P2PSession">
      <Topbar pageTitle="P2PSession" {...props} />

      <MainLayoutContainer pb="60px">
        <SessionFeature {...props} />
      </MainLayoutContainer>
    </PageMotion>
  );
}
